import React from 'react'
import {
  Navigate,
  Routes,
  Route,
  useLocation,
  matchPath,
} from 'react-router-dom'
import Login from '../pages/Login'
import Home from '../pages/Home'
import NotFound from '../pages/NotFound'
import AppSelect from '../pages/AppSelect'
import { useIsAuthenticated } from 'utils/hooks'
import PasswordResetPage from 'pages/PasswordResetPage'
import EmailRetrievePage from 'pages/EmailRetrievePage'
import EmailReport from 'pages/reports/EmailReport'

function ToplevelRoutes({
  setIsReportPage,
}: {
  setIsReportPage: any
}): JSX.Element | null {
  const SetReport = () => {
    setIsReportPage(true)
    return <></>
  }
  const isAuthenticated = useIsAuthenticated()
  const location = useLocation()
  const analyticsMatchPath = matchPath(
    { path: '/analytics/client/:clientName/*' },
    location.pathname
  )
  const leadsMatchPath = matchPath(
    { path: '/leads/client/:clientName/*' },
    location.pathname
  )
  const marketingPath = matchPath(
    { path: '/targeted-marketing/client/:clientName/*' },
    location.pathname
  )
  const buyOnlineMatchPath = matchPath(
    { path: '/reserve-online/client/:clientName/*' },
    location.pathname
  )
  const analyticsClientName = analyticsMatchPath
    ? analyticsMatchPath.params.clientName
    : null
  const leadsClientName = leadsMatchPath
    ? leadsMatchPath.params.clientName
    : null
  const marketingClientName = marketingPath
    ? marketingPath.params.clientName
    : null

  const buyOnlineClientName = buyOnlineMatchPath
    ? buyOnlineMatchPath.params.clientName
    : null

  if (
    analyticsClientName !== null ||
    leadsClientName !== null ||
    marketingClientName !== null ||
    buyOnlineClientName !== null
  ) {
    // this prevents top level routes from rendering if the route
    // contains clientName and the route is another sub-tree handled by
    // /leads/*, or /analytics/*
    if (!isAuthenticated) {
      return <Navigate to={'/login'} />
    }
    return null
  }
  const HomeComponent = isAuthenticated ? <Home /> : <Navigate to={'/login'} />
  return (
    <Routes>
      <Route path="/" element={<Navigate to={'/login'} />} />
      <Route path="/login" element={<Login />} />
      <Route path="/resetpwd" element={<PasswordResetPage />} />
      <Route path="/forgotemail" element={<EmailRetrievePage />} />
      <Route path="/home" element={HomeComponent} />
      <Route path="/client/:clientName/home" element={<AppSelect />} />
      <Route
        path="/reports/emailReport"
        element={
          <>
            <SetReport />
            <EmailReport />
          </>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
export default ToplevelRoutes
