import React, { useEffect, useReducer } from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { useSubscription } from '@apollo/client'
import { useAtom } from 'jotai'
import BaseLayout from 'components/common/layout/BaseLayout'
import { LeadsStore, initialState as initialLeadsState } from 'store/leadsStore'
import {
  AnalyticsStore,
  initialState as initialAnalyticsState,
} from 'store/analyticsStore'
import { analyticsReducer } from 'store/reducers'
import { leadsReducer } from 'store/reducers'
import { useMatch } from 'react-router-dom'
import hydrationStore from '../../store/HydrationStore'
import { snackbarConfigAtom } from 'store/atoms'
import PROSPECT_ADDED_SUBSCRIPTION from 'graphql/nexus-subscriptions/prospectAdded'

const PREFIX = 'LeadsApp'

const classes = {
  link: `${PREFIX}-link`,
  snackbar: `${PREFIX}-snackbar`,
}

const LeadsAppStyled = styled('div')(({ theme }) => ({
  [`& .${classes.link}`]: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },

  [`& .${classes.snackbar}`]: {
    margin: '40px 40px 0px 0px',
  },
}))

function LeadsApp(): JSX.Element | null {
  const navigate = useNavigate()
  const [snackbarConfig, setSnackbarConfig] = useAtom(snackbarConfigAtom)
  const [leadsState, dispatch] = useReducer(leadsReducer, initialLeadsState)
  const [analyticsState, analyticsDispatch] = useReducer(
    analyticsReducer,
    initialAnalyticsState
  )
  const value = { leadsState, dispatch }
  const leadsMatchPath = useMatch('leads/client/:clientName/*')
  const leadsOverviewMatchPath = useMatch('leads/client/:clientName/overview')
  const leadsClientName = leadsMatchPath
    ? leadsMatchPath?.params?.clientName
    : null

  // Prospect added subscription
  const { data: subscriptionData } = useSubscription(
    PROSPECT_ADDED_SUBSCRIPTION,
    {
      variables: {
        clientName: leadsClientName,
        token: hydrationStore.token || '',
      },
      skip: !leadsMatchPath || !leadsClientName,
    }
  )
  const prospectAddedData = subscriptionData?.prospectAdded

  const handleNavigate = (): void => {
    if (leadsClientName) {
      navigate(`/leads/client/${leadsClientName}/overview`)
      setSnackbarConfig({ ...snackbarConfig, open: false })
    }
  }

  useEffect(() => {
    if (prospectAddedData) {
      setSnackbarConfig({
        ...snackbarConfig,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        classes: classes.snackbar,
        closeButton: true,
        open: true,
        message: leadsOverviewMatchPath ? (
          'A new lead has been registered!'
        ) : (
          <span>
            A new lead has been registered! See{' '}
            <span className={classes.link} onClick={handleNavigate}>
              here
            </span>
          </span>
        ),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prospectAddedData])

  if (!leadsMatchPath) {
    return null
  }
  return (
    <LeadsAppStyled>
      <AnalyticsStore.Provider
        value={{ analyticsState, dispatch: analyticsDispatch }}
      >
        <LeadsStore.Provider value={value}>
          <BaseLayout />
        </LeadsStore.Provider>
      </AnalyticsStore.Provider>
    </LeadsAppStyled>
  )
}

export default LeadsApp
