import React, { ReactElement, useCallback } from 'react'
import {
  OptionMap,
  SetState,
  SharedFilterArgs,
} from '../../analytics/locations-filters/Shared'
import { LocationsSelectMultiple } from '../../analytics/locations-filters/components/LocationsSelectMultiple'
import { ITLocationSelection } from '../../analytics/locations-filters/Shared'

interface ITCitiesFilterArgs extends SharedFilterArgs {
  cities: OptionMap<string>
  selection: ITLocationSelection
  setSelection: SetState<ITLocationSelection>
}

export default function ITCitiesFilter({
  cities,
  selection,
  setSelection,
  larger = false,
  industryTrendsSelect = false,
  locationsFilterSelect = false,
  leadsLocationSelect = false,
}: ITCitiesFilterArgs): ReactElement | null {
  const handleSelect = useCallback(
    (cities?: string[]): void => {
      setSelection((prev) => {
        if (cities && cities.length > 0) {
          return {
            country: prev.country,
            cities,
            us: {
              ...prev.us,
            },
            can: {
              ...prev.can,
            },
          }
        }

        return {
          ...prev,
          cities,
        }
      })
    },
    [setSelection]
  )

  return (
    <LocationsSelectMultiple<string>
      field="cities"
      name="Cities"
      items={cities}
      selected={selection?.cities || []}
      onSelect={handleSelect}
      locationsFilterSelect={locationsFilterSelect}
      industryTrendsSelect={industryTrendsSelect}
      leadsLocationSelect={leadsLocationSelect}
      larger={larger}
    />
  )
}
