import React, { useContext, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Input from '@mui/material/Input'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { GET_CLIENT_RESERVATION_INTEGRATION } from '../../../graphql/nexus-queries/reservationIntegration'
import {
  AdditionalBuilder,
  useGetClientReservationIntegrationQuery,
  useUpdateOnlineReservationIntegrationInfoMutation,
} from '../../../graphql/gen-types'
import { AppStore } from '../../../store'
import Spinner from '../../common/misc/Spinner'
import { isEmailInvalid, uuidValidateV4 } from '../../../utils/functions'
import { OverflowInputLabel } from '../../common/custom-inputs/OverflowInputLabel'
import { useEventTracker } from '../../../hooks/tracking'
import { trackReservationIntegrationForm } from '../reservationTrackEvents'
import { ValidationTypes } from '../../../utils/formValidation'

const PREFIX = 'ReservationIntegrationForm'

const classes = {
  formRoot: `${PREFIX}-formRoot`,
  inputIcon: `${PREFIX}-inputIcon`,
  disabledInput: `${PREFIX}-disabledInput`,
}

const Root = styled('form')(({ theme }) => ({
  [`&.${classes.formRoot}`]: {
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.light
        : theme.palette.primary.main,
  },

  [`& .${classes.inputIcon}`]: {
    marginRight: 4,
    paddingBottom: 6,
  },

  [`& .${classes.disabledInput}`]: {
    pointerEvents: 'none',
  },
}))

interface FormState {
  signingAgreementTemplateId: string | null | undefined
  stripeAccountId: string | null | undefined
  depositAmount: number | null | undefined
  nonRefundableAmount: number | null | undefined
  anewgoFee: number | null | undefined
  primaryBuyOnlineContactEmail: string | null | undefined
  primaryBuyOnlineContactName: string | null | undefined
  daysToProveFinancing: number | null | undefined
  envelopeExpireDays: number | null | undefined
  additionalBuilders: AdditionalBuilder[]
}

interface ErrorsState {
  signingAgreementTemplateIdError: string
  stripeAccountIdError: string
  depositAmountError: string
  nonRefundableAmountError: string
  anewgoFeeError: string
  primaryBuyOnlineContactEmailError: string
  primaryBuyOnlineContactNameError: string
  costValuesInvalid: string
  daysToProveFinancingError: string
  envelopeExpireDaysError: string
  additionalBuildersError: Record<string, string>[] | null[]
}

const prepareNumberValue = (value: null | undefined | string) => {
  if (value !== null && value !== undefined && value !== '') {
    return parseInt(value)
  }

  return value
}

interface ReservationIntegrationFormArgs {
  isUserAnewgoAdmin: boolean | null
}
const ReservationIntegrationForm: React.FC<ReservationIntegrationFormArgs> = ({
  isUserAnewgoAdmin,
}) => {
  const { appState } = useContext(AppStore)
  const track = useEventTracker()

  const { data, loading } = useGetClientReservationIntegrationQuery({
    variables: {
      clientId: appState?.selectedClient?.id || 0,
    },
  })

  const reservationIntegrationInfo =
    data?.getReservationIntegration?.reservationIntegrationInfo

  const [formErrors, setFormErrors] = useState<ErrorsState>({
    signingAgreementTemplateIdError: '',
    stripeAccountIdError: '',
    depositAmountError: '',
    nonRefundableAmountError: '',
    anewgoFeeError: '',
    primaryBuyOnlineContactEmailError: '',
    primaryBuyOnlineContactNameError: '',
    costValuesInvalid: '',
    daysToProveFinancingError: '',
    envelopeExpireDaysError: '',
    additionalBuildersError: [],
  })
  const [values, setValues] = React.useState<FormState>({
    signingAgreementTemplateId: null,
    stripeAccountId: null,
    depositAmount: null,
    nonRefundableAmount: null,
    anewgoFee: null,
    primaryBuyOnlineContactEmail: null,
    primaryBuyOnlineContactName: null,
    daysToProveFinancing: null,
    envelopeExpireDays: null,
    additionalBuilders: [],
  })

  useEffect(() => {
    if (!loading) {
      setValues({
        signingAgreementTemplateId:
          reservationIntegrationInfo?.signingAgreementTemplateId || null,
        stripeAccountId: reservationIntegrationInfo?.stripeAccountId || null,
        depositAmount: Number.isInteger(
          reservationIntegrationInfo?.depositAmount
        )
          ? reservationIntegrationInfo?.depositAmount
          : null,
        nonRefundableAmount: Number.isInteger(
          reservationIntegrationInfo?.nonRefundableAmount
        )
          ? reservationIntegrationInfo?.nonRefundableAmount
          : null,
        anewgoFee: Number.isInteger(reservationIntegrationInfo?.anewgoFee)
          ? reservationIntegrationInfo?.anewgoFee
          : null,
        primaryBuyOnlineContactEmail:
          reservationIntegrationInfo?.primaryBuyOnlineContactEmail || '',
        primaryBuyOnlineContactName:
          reservationIntegrationInfo?.primaryBuyOnlineContactName || '',
        daysToProveFinancing:
          reservationIntegrationInfo?.daysToProveFinancing || null,
        envelopeExpireDays:
          reservationIntegrationInfo?.envelopeExpireDays || null,
        additionalBuilders:
          reservationIntegrationInfo?.additionalBuilders || [],
      })
    }
  }, [loading, reservationIntegrationInfo])

  const calculateValidMoniesAmounts = (
    deposit: number,
    nonRefundable: number,
    anewgoFee: number
  ): boolean => {
    return nonRefundable + anewgoFee <= deposit
  }

  useEffect(() => {
    if (
      values.depositAmount &&
      values.nonRefundableAmount &&
      values.anewgoFee
    ) {
      const isInvalid = !calculateValidMoniesAmounts(
        values.depositAmount,
        values.nonRefundableAmount,
        values.anewgoFee
      )
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        costValuesInvalid: isInvalid
          ? 'Deposit Amount is less than Anewgo Fee and Non-Refundable amounts combined'
          : '',
      }))
    }
    if (
      !values.depositAmount &&
      values.nonRefundableAmount &&
      values.anewgoFee
    ) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        costValuesInvalid:
          'Deposit Amount is less than Anewgo Fee and Non-Refundable amounts combined',
      }))
    }
  }, [values.depositAmount, values.nonRefundableAmount, values.anewgoFee])

  const [
    updateOnlineReservationIntegrationInfoMutation,
  ] = useUpdateOnlineReservationIntegrationInfoMutation()

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string,
    index?: number,
    field?: string
  ) => {
    const value = event.target.value
    let error = ''
    if (type === ValidationTypes.Email) {
      if (value !== '' && isEmailInvalid(value)) {
        error = 'Please enter a valid email address.'
      } else if (value.length > 255) {
        error = 'Please enter an address less than 255 characters in length.'
      }
    } else if (type === ValidationTypes.PositiveNumber) {
      if (parseInt(value) <= 0) {
        error = 'Value has to be greater than 0'
      }
    } else if (type === ValidationTypes.UUID) {
      if (value !== '' && !uuidValidateV4(value)) {
        error = 'Please enter a valid UUID format'
      }
    } else if (type === ValidationTypes.String) {
      if (value.length > 255) {
        error = 'Value can have maximum of 255 characters'
      }
    }

    if (index !== undefined && field !== undefined) {
      setFormErrors((prevValues) => {
        const newVal = [...prevValues[event.target.name + 'Error']]
        newVal[index] = {
          ...newVal[index],
          [field]: error,
        }
        return {
          ...prevValues,
          [event.target.name + 'Error']: newVal,
        }
      })
      setValues((prevValues) => {
        const newVal = [...prevValues[event.target.name]]
        newVal[index] = {
          ...newVal[index],
          [field]: event?.target?.value,
        }
        return {
          ...prevValues,
          [event.target.name]: newVal,
        }
      })
    } else {
      setFormErrors((prevValues) => ({
        ...prevValues,
        [event.target.name + 'Error']: error,
      }))
      setValues((prevValues) => ({
        ...prevValues,
        [event.target.name]:
          type === ValidationTypes.PositiveNumber
            ? prepareNumberValue(event.target.value)
            : event?.target?.value?.trim()
            ? event?.target?.value
            : null,
      }))
    }
  }

  const handleBlur = (field: string, subfield?: string, index?: number) => {
    if (subfield !== undefined && index !== undefined) {
      if (formErrors[field + 'Error']?.[index]?.[subfield]) {
        return
      }
    } else if (formErrors[field + 'Error']) {
      return
    }

    const fieldValue = Array.isArray(values[field])
      ? values[field].map((item: AdditionalBuilder) => {
          const itemCopy = { ...item }
          delete itemCopy.__typename
          return itemCopy
        })
      : values[field] || null

    trackReservationIntegrationForm(track, field, fieldValue)

    updateOnlineReservationIntegrationInfoMutation({
      variables: {
        clientId: appState?.selectedClient?.id || 0,
        input: {
          [field]: fieldValue,
        },
      },
      refetchQueries: [
        {
          query: GET_CLIENT_RESERVATION_INTEGRATION,
          variables: { clientId: appState?.selectedClient?.id },
        },
      ],
    }).catch((error) => {
      if (field === 'signingAgreementTemplateId') {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          signingAgreementTemplateIdError: error.message,
        }))
      }
    })
  }

  return loading ? (
    <Spinner message={'Loading...'} />
  ) : (
    <Root className={classes.formRoot}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormControl margin="normal" fullWidth variant="standard">
            <OverflowInputLabel htmlFor="stripeAccountId">
              Stripe Account ID
            </OverflowInputLabel>
            <Input
              disabled
              className={classes.disabledInput}
              id="stripeAccountId"
              value={values.stripeAccountId ?? ''}
              name="stripeAccountId"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl margin="normal" fullWidth variant="standard">
            <OverflowInputLabel htmlFor="signingAgreementTemplateId">
              DocuSign TemplateId
            </OverflowInputLabel>
            <Input
              disabled={!isUserAnewgoAdmin}
              className={!isUserAnewgoAdmin ? classes.disabledInput : ''}
              id="signingAgreementTemplateId"
              value={values.signingAgreementTemplateId ?? ''}
              name="signingAgreementTemplateId"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e, ValidationTypes.UUID)
              }
              onBlur={() => handleBlur('signingAgreementTemplateId')}
            />
            {formErrors.signingAgreementTemplateIdError && (
              <FormHelperText
                error={!!formErrors.signingAgreementTemplateIdError}
                id="email-help"
              >
                {formErrors.signingAgreementTemplateIdError}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="flex-end">
            <Box flexGrow={1}>
              <FormControl margin="normal" fullWidth variant="standard">
                <OverflowInputLabel htmlFor="depositAmount">
                  Online Reservation Deposit Amount
                </OverflowInputLabel>
                <Input
                  startAdornment={<AttachMoneyIcon />}
                  type="number"
                  id="depositAmount"
                  value={values.depositAmount ?? ''}
                  name="depositAmount"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(e, ValidationTypes.PositiveNumber)
                  }
                  onBlur={() => handleBlur('depositAmount')}
                />
                {formErrors.depositAmountError && (
                  <FormHelperText
                    error={!!formErrors.depositAmountError}
                    id="user-name-help"
                  >
                    {formErrors.depositAmountError}
                  </FormHelperText>
                )}
                {formErrors.costValuesInvalid && (
                  <FormHelperText
                    error={!!formErrors.costValuesInvalid}
                    id="user-name-help"
                  >
                    {formErrors.costValuesInvalid}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="flex-end">
            <Box flexGrow={1}>
              <FormControl
                margin="normal"
                fullWidth
                variant="standard"
                error={!!formErrors.nonRefundableAmountError}
              >
                <OverflowInputLabel htmlFor="nonRefundableAmount">
                  Online Reservation Non Refundable Fee
                </OverflowInputLabel>
                <Input
                  startAdornment={<AttachMoneyIcon />}
                  disabled={!values.depositAmount}
                  className={!values.depositAmount ? classes.disabledInput : ''}
                  type="number"
                  id="nonRefundableAmount"
                  value={values.nonRefundableAmount ?? ''}
                  name="nonRefundableAmount"
                  error={!!formErrors.nonRefundableAmountError}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(e, ValidationTypes.PositiveNumber)
                  }
                  onBlur={() => handleBlur('nonRefundableAmount')}
                />
                {formErrors.nonRefundableAmountError && (
                  <FormHelperText
                    error={!!formErrors.nonRefundableAmountError}
                    id="nonRefundableAmountError-help"
                  >
                    {formErrors.nonRefundableAmountError}
                  </FormHelperText>
                )}
                {!values.depositAmount && (
                  <FormHelperText
                    id="nonRefundableAmountDepositValue-help"
                    error
                  >
                    Enter a down payment amount.
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="flex-end">
            <Box flexGrow={1}>
              <FormControl margin="normal" fullWidth variant="standard">
                <OverflowInputLabel htmlFor="anewgoFee">
                  Online Reservation Anewgo Fee
                </OverflowInputLabel>
                <Input
                  startAdornment={<AttachMoneyIcon />}
                  disabled
                  className={classes.disabledInput}
                  type="number"
                  id="anewgoFee"
                  value={values.anewgoFee ?? ''}
                  name="anewgoFee"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(e, ValidationTypes.PositiveNumber)
                  }
                  onBlur={() => handleBlur('anewgoFee')}
                />
              </FormControl>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="flex-end">
            <Box flexGrow={1}>
              <FormControl
                margin="normal"
                fullWidth
                variant="standard"
                error={!!formErrors.daysToProveFinancingError}
              >
                <OverflowInputLabel htmlFor="daysToProveFinancing">
                  Days to Provide Proof of Financing
                </OverflowInputLabel>
                <Input
                  type="number"
                  id="daysToProveFinancing"
                  value={values.daysToProveFinancing ?? ''}
                  name="daysToProveFinancing"
                  error={!!formErrors.daysToProveFinancingError}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(e, ValidationTypes.PositiveNumber)
                  }
                  onBlur={() => handleBlur('daysToProveFinancing')}
                />
                {formErrors.daysToProveFinancingError && (
                  <FormHelperText
                    error={!!formErrors.daysToProveFinancingError}
                    id="user-name-help"
                  >
                    {formErrors.daysToProveFinancingError}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="flex-start">
            <Box flexGrow={1}>
              <FormControl
                margin="normal"
                fullWidth
                variant="standard"
                error={!!formErrors.daysToProveFinancingError}
              >
                <OverflowInputLabel htmlFor="envelopeExpireDays">
                  Envelope expiration days
                </OverflowInputLabel>
                <Input
                  type="number"
                  id="envelopeExpireDays"
                  value={values.envelopeExpireDays ?? 180}
                  name="envelopeExpireDays"
                  error={!!formErrors.envelopeExpireDaysError}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(e, ValidationTypes.PositiveNumber)
                  }
                  onBlur={() => handleBlur('envelopeExpireDays')}
                />
                {formErrors.envelopeExpireDaysError && (
                  <FormHelperText
                    error={!!formErrors.envelopeExpireDaysError}
                    id="user-name-help"
                  >
                    {formErrors.envelopeExpireDaysError}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Builder contacts:</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            variant="standard"
            margin="normal"
            fullWidth
            error={!!formErrors.primaryBuyOnlineContactEmailError}
          >
            <OverflowInputLabel
              htmlFor="primaryBuyOnlineContactEmail"
              error={!!formErrors.primaryBuyOnlineContactEmailError}
            >
              Primary builder E-Mail
            </OverflowInputLabel>
            <Input
              error={!!formErrors.primaryBuyOnlineContactEmailError}
              id="primaryBuyOnlineContactEmail"
              value={values.primaryBuyOnlineContactEmail ?? ''}
              name="primaryBuyOnlineContactEmail"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e, ValidationTypes.Email)
              }
              onBlur={() => handleBlur('primaryBuyOnlineContactEmail')}
              type="email"
              required
            />
            {formErrors.primaryBuyOnlineContactEmailError && (
              <FormHelperText
                error={!!formErrors.primaryBuyOnlineContactEmailError}
                id="email-help"
              >
                {formErrors.primaryBuyOnlineContactEmailError}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            variant="standard"
            margin="normal"
            fullWidth
            error={!!formErrors.primaryBuyOnlineContactNameError}
          >
            <OverflowInputLabel htmlFor="primaryBuyOnlineContactName">
              Primary builder Full Name
            </OverflowInputLabel>
            <Input
              id="primaryBuyOnlineContactName"
              value={values.primaryBuyOnlineContactName ?? ''}
              name="primaryBuyOnlineContactName"
              error={!!formErrors.primaryBuyOnlineContactNameError}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e, ValidationTypes.String)
              }
              onBlur={() => handleBlur('primaryBuyOnlineContactName')}
            />
            {formErrors.primaryBuyOnlineContactNameError && (
              <FormHelperText
                error={!!formErrors.primaryBuyOnlineContactNameError}
                id="email-help"
              >
                {formErrors.primaryBuyOnlineContactNameError}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        {values.additionalBuilders.map((builder, i) => (
          <>
            <Grid item xs={12} sm={6}>
              {formErrors.additionalBuildersError[i]?.email}
              <FormControl
                variant="standard"
                margin="normal"
                fullWidth
                error={!!formErrors.additionalBuildersError[i]?.email}
              >
                <OverflowInputLabel
                  htmlFor="additionalBuilders"
                  error={!!formErrors.additionalBuildersError[i]?.email}
                >
                  Additional Builder {i ? i + 1 : ''} E-Mail
                </OverflowInputLabel>
                <Input
                  error={!!formErrors.additionalBuildersError[i]?.email}
                  id="additionalBuilders"
                  value={builder.email ?? ''}
                  name="additionalBuilders"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(e, ValidationTypes.Email, i, 'email')
                  }
                  onBlur={() => handleBlur('additionalBuilders', 'email', i)}
                  type="email"
                  required
                />
                {formErrors.additionalBuildersError[i]?.email && (
                  <FormHelperText
                    error={!!formErrors.additionalBuildersError[i]?.email}
                  >
                    {formErrors.additionalBuildersError[i]?.email}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                margin="normal"
                fullWidth
                error={!!formErrors.additionalBuildersError[i]?.name}
              >
                <OverflowInputLabel htmlFor="additionalBuilders">
                  Additional Builder {i ? i + 1 : ''} Full Name
                </OverflowInputLabel>
                <Input
                  id="additionalBuilders"
                  value={builder.name ?? ''}
                  name="additionalBuilders"
                  error={!!formErrors.additionalBuildersError[i]?.name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(e, ValidationTypes.String, i, 'name')
                  }
                  onBlur={() => handleBlur('additionalBuilders', 'name', i)}
                />
                {formErrors.additionalBuildersError[i]?.name && (
                  <FormHelperText
                    error={!!formErrors.additionalBuildersError[i]?.name}
                  >
                    {formErrors.additionalBuildersError[i]?.name}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </>
        ))}
      </Grid>
    </Root>
  )
}

export default ReservationIntegrationForm
