import React, { ReactElement, ReactNode } from 'react'
import Grid from '@mui/material/Grid'
import MenuDialog from '../../../common/by-plan/MenuDialog'
import CountryFilter from './CountryFilter'
import StateFilter from './us/StateFilter'
import CountyFilter from './us/CountyFilter'
import MetroFilter from './us/MetroFilter'
import ZipCodeFilter from './us/ZipCodeFilter'
import ProvinceFilter from './can/ProvinceFilter'
import DistrictFilter from './can/DistrictFilter'
import PostCodeFilter from './can/PostCodeFilter'
import { useCountries } from '../hooks/useCountries'
import { LocationSelection, LocationSelectionResult, SetState } from '../Shared'

export type FilterProps = {
  selection: LocationSelection
  setSelection: SetState<LocationSelection>
  industryTrendsSelect?: boolean
  locationsFilterSelect?: boolean
  leadsLocationSelect?: boolean
  [key: string]: unknown
}

type FiltersDialogProps = {
  sm: boolean
  xs: boolean
  xsSm: boolean
  selection: LocationSelection
  locationSelection: LocationSelectionResult
  filterProps: FilterProps
  children: ReactNode
}

export default function FiltersDialog({
  sm,
  xs,
  xsSm,
  selection,
  locationSelection,
  children,
  filterProps,
}: FiltersDialogProps): ReactElement | null {
  const {
    countries,
    us: { states, counties, metros, zipCodes },
    can: { provinces, districts, postCodes },
  } = locationSelection
  const { us, can } = useCountries(selection, countries)

  const displayFilters = (
    <>
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        direction="row"
      >
        <CountryFilter countries={countries} {...filterProps} />
      </Grid>
      {us && (
        <>
          <Grid
            container
            item
            justifyContent="center"
            alignItems="flex-start"
            direction="row"
          >
            <Grid item>
              <StateFilter states={states} {...filterProps} />
            </Grid>
            <Grid item>
              <CountyFilter counties={counties} {...filterProps} />
            </Grid>
          </Grid>
          <Grid
            container
            item
            justifyContent="center"
            alignItems="flex-start"
            direction="row"
          >
            <Grid item>
              <MetroFilter metros={metros} {...filterProps} />
            </Grid>
            <Grid item>
              <ZipCodeFilter zipCodes={zipCodes} {...filterProps} />
            </Grid>
          </Grid>
        </>
      )}
      {can && (
        <>
          <Grid
            container
            item
            justifyContent="center"
            alignItems="flex-start"
            direction="row"
          >
            <Grid item>
              <ProvinceFilter provinces={provinces} {...filterProps} />
            </Grid>
            <Grid item>
              <DistrictFilter districts={districts} {...filterProps} />
            </Grid>
          </Grid>
          <Grid
            container
            item
            justifyContent="center"
            alignItems="flex-start"
            direction="row"
          >
            <Grid item xs={6} />
            <Grid item xs={6}>
              <PostCodeFilter postCodes={postCodes} {...filterProps} />
            </Grid>
          </Grid>
        </>
      )}
      {children}
    </>
  )

  return sm ? (
    displayFilters
  ) : (
    <MenuDialog
      alwaysShowButton
      buttonSize={!xs && xsSm ? 'small' : 'medium'}
      buttonText="Filters"
      testId="location-filters-dialog"
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        spacing={1}
      >
        {displayFilters}
      </Grid>
    </MenuDialog>
  )
}
