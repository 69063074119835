import React, { useEffect, useContext, ReactElement } from 'react'

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'

import TableFooter from '@mui/material/TableFooter'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { AppStore } from 'store'
import Typography from '@mui/material/Typography'

import {
  ResponsiveRow,
  ResponsiveCell,
  MobileHeader,
  MobileRow,
  MobileRowContent,
  ResponsiveTableHead,
} from './ResponsiveTable.styles'
import TablePagination from '@mui/material/TablePagination'
export * from './ResponsiveTable.styles'

export const DEFAULT_INITIAL_ROWS_PER_PAGE = 10
export const DEFAULT_RESPONSE_SIZE = 'md'
export const DEFAULT_ROW_HEIGHT = 'inherit'

export interface ResponsiveTableStyleProps {
  alignText?: 'left' | 'right' | 'center' | 'inherit'
  responseSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | number
  rowHeight?: number | string
}

interface ResponsiveTableProps<T> {
  data: T[]
  columns: (JSX.Element | null | string)[]
  generateRow?: (dataRow: T, index?: number) => JSX.Element | null
  row?: (dataRow: T, index?: number) => unknown[]
  responseSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | number | undefined
  rowHeight?: number | string
  onRowClick?: (row: unknown) => void
  dense?: boolean
  initialRows?: number
  applyRowStyling?: (row: unknown) => boolean
  rowStyle?: Record<string, unknown>
  disableRow?: (row: unknown) => boolean
  rowsPerPageOptions?: number[]
  customPagination: ReactElement
}

/**
 * This table contains pagination and is responsive.
 * @param param - props data
 */
export default function ResponsiveTable<T>({
  data,
  columns,
  generateRow,
  row,
  responseSize,
  rowHeight,
  onRowClick,
  dense = false,
  initialRows = DEFAULT_INITIAL_ROWS_PER_PAGE,
  applyRowStyling,
  rowStyle,
  disableRow,
  rowsPerPageOptions = [5, 10, 25],
  customPagination,
}: ResponsiveTableProps<T>): JSX.Element {
  const theme = useTheme()
  const isResponseSize = useMediaQuery(
    theme.breakpoints.down(responseSize || DEFAULT_RESPONSE_SIZE)
  )

  const { appState } = useContext(AppStore)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(initialRows)
  const size = dense ? 'small' : 'medium'
  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const wrapHeader = (
    head: JSX.Element | null | string,
    i: number
  ): JSX.Element | null => {
    if (typeof head === 'string' || head instanceof String) {
      return (
        <ResponsiveCell
          align="center"
          key={`table-head-${i}`}
          responseSize={responseSize}
          rowHeight={rowHeight}
        >
          <Typography>{head as string}</Typography>
        </ResponsiveCell>
      )
    } else {
      return head
    }
  }

  const wrapRows = (data: unknown[] | undefined) =>
    data?.map((d, i) => (
      <ResponsiveCell
        key={`table-row-${(d as { id?: string | number })?.id || i}`}
        align="center"
        component="th"
        scope="row"
        responseSize={responseSize}
        rowHeight={rowHeight}
      >
        <Typography>{d as string}</Typography>
      </ResponsiveCell>
    ))

  // When data changes, reset to first page to prevent over indexing.
  useEffect(() => setPage(0), [data.length])

  // For small, set rowsPerPage to 1 if it is not already
  if (isResponseSize && rowsPerPage !== 1) {
    setRowsPerPage(1)
    // When upscaling from small to larger display, set rowsPerPage to 5
  } else if (!isResponseSize && rowsPerPage === 1) {
    setRowsPerPage(initialRows)
    setPage(0) // reset page to 0 to avoid over indexing
  }

  const startIndex = page * rowsPerPage
  const endIndex = startIndex + rowsPerPage

  const pageData = customPagination ? data : data.slice(startIndex, endIndex)

  return (
    <Paper>
      <TableContainer>
        <Table aria-label="simple table" size={size}>
          {!isResponseSize && (
            <ResponsiveTableHead
              onClick={(e: React.MouseEvent): void => e.stopPropagation()} // Prevent onclick event from propogating to parent, prevent opening modal on header click.
            >
              <TableRow>{columns.map((col, i) => wrapHeader(col, i))}</TableRow>
            </ResponsiveTableHead>
          )}
          <TableBody>
            {pageData.map((dataRow, index) => {
              let rowStyles = {}
              if (rowStyle && applyRowStyling && applyRowStyling(dataRow)) {
                rowStyles = { ...rowStyle }
              }
              const rowDisabled = disableRow && disableRow(dataRow)
              if (onRowClick && !rowDisabled) {
                rowStyles['cursor'] = 'pointer'
              }
              if (rowDisabled) {
                rowStyles['color'] = 'gray'
                if (appState.darkMode) {
                  rowStyles['backgroundColor'] = '#212121'
                } else {
                  rowStyles['backgroundColor'] = 'lightGray'
                }
              }
              return (
                <ResponsiveRow
                  data-testid="ResponsiveTableRow"
                  key={`${index}`}
                  onClick={(): void => onRowClick && onRowClick(dataRow)}
                  style={rowStyles}
                  responseSize={responseSize}
                >
                  {/* Conditionally render row headers for responsive table */}
                  {isResponseSize && (
                    <MobileHeader
                      onClick={(e: React.MouseEvent): void =>
                        e.stopPropagation()
                      }
                    >
                      <MobileRow>
                        {columns.map((col, i) => wrapHeader(col, i))}
                      </MobileRow>
                    </MobileHeader>
                  )}
                  {/* For small factors, add additional TableRow wrapper for responsive table  */}
                  {!isResponseSize ? (
                    wrapRows(row?.(dataRow, index)) ||
                    generateRow?.(dataRow, index)
                  ) : (
                    <MobileRowContent
                      onClick={(): void => onRowClick && onRowClick(dataRow)}
                    >
                      {wrapRows(row?.(dataRow, index)) ||
                        generateRow?.(dataRow, index)}
                    </MobileRowContent>
                  )}
                </ResponsiveRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {customPagination || (
        <TableFooter>
          <TableRow>
            <TablePagination
              component="div"
              count={data.length}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={isResponseSize ? [] : rowsPerPageOptions} // For small screen, do not display rowsPerPage
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      )}
    </Paper>
  )
}
