import { gql } from '@apollo/client'

export const ADD_EXCLUDED_IP = gql`
  mutation addExcludedIp(
    $clientName: String!
    $ips: [String]!
    $ipCidrNotation: String!
  ) {
    addExcludedIp(
      clientName: $clientName
      ips: $ips
      ipCidrNotation: $ipCidrNotation
    )
  }
`

export const REMOVE_EXCLUDED_IP = gql`
  mutation removeExcludedIp($clientName: String!, $ipCidrNotation: String!) {
    removeExcludedIp(clientName: $clientName, ipCidrNotation: $ipCidrNotation)
  }
`
