import React, { useEffect, useRef, useState } from 'react'

import { styled } from '@mui/material/styles'

import {
  Elevation,
  PaginatedPlansQuery,
  Plan,
  usePaginatedPlansQuery,
  PaginatedPlansQueryVariables,
} from 'graphql/gen-types'
import BoundaryObserver from '../layout/BoundaryObserver'
import { ProspectPlan } from './FavoriteCard'
import Grid from '@mui/material/Grid'
import { SortOptions } from 'components/common/custom-inputs/SortSelect'
import { CommunityPlanElevation } from '../../leads/prospect-page/dialogs/ProspectPlanSuggestionsDialog'
import { PlanElevationCard } from './PlanElevationCard'
import Skeleton from '@mui/material/Skeleton'
import { sortPlansByMetric } from './planHelpers'
import { verifyUserAuthError } from '../../../utils/authorizationHelpers'
import { Unauthorized } from '../../auth/Unauthorized'

const PREFIX = 'PlanElevationsGrid'

const classes = {
  root: `${PREFIX}-root`,
}

const Root = styled('div')({
  [`&.${classes.root}`]: {
    WebkitOverflowScrolling: 'touch',
    width: '100%',
    height: '100%',
  },
})

export type { ProspectPlan } // bubble type (interface) to higher component
type PlanTypes = Plan | ProspectPlan

interface PlanElevationsGridProps {
  selectedMetric: SortOptions
  search?: string
  paginatedPlansArgs: PaginatedPlansQueryVariables
  selectPlans: (plans: CommunityPlanElevation[]) => void
  deselectPlans: (plans: CommunityPlanElevation[]) => void
  selectedPlans: CommunityPlanElevation[]
  height: number
}

const PlanElevationsGrid = ({
  search,
  selectedMetric,
  paginatedPlansArgs,
  selectPlans,
  deselectPlans,
  height = 0,
  selectedPlans,
}: PlanElevationsGridProps): JSX.Element => {
  const parentContainerRef = useRef(null)
  const [plans, setPlans] = useState<PlanTypes[]>([])
  const [done, setDone] = useState(false)

  const { data, loading, error, fetchMore } = usePaginatedPlansQuery({
    variables: paginatedPlansArgs,
  })

  const filteredPlans = (plans: Plan[]): PlanTypes[] => {
    return plans?.filter(
      (plan: Plan) =>
        plan?.name?.toLowerCase().includes(search?.toLowerCase() || '') || false
    )
  }

  useEffect(() => {
    if (data?.paginatedPlans?.plans) {
      const planElevations = data.paginatedPlans.plans
        .map((plan) =>
          plan?.elevations?.map((elevation) => ({
            ...plan,
            elevations: [elevation],
          }))
        )
        .flat()
      const sortedPlans = filteredPlans(
        planElevations as Plan[]
      )?.sort((planA, planB) => sortPlansByMetric(planA, planB, selectedMetric))
      setPlans(sortedPlans)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedMetric, search])

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return <Unauthorized message={error.toString()} imageName={'Plans'} />
    }
    console.error(error)
    return <div>Error while loading plans</div>
  }

  const loadMore = (): void => {
    if (done) {
      return
    }
    if (fetchMore) {
      fetchMore({
        variables: {
          ...paginatedPlansArgs,
          offset: plans?.length,
        },
        updateQuery: (
          previousResult: PaginatedPlansQuery,
          { fetchMoreResult }
        ) => {
          fetchMoreResult = fetchMoreResult as PaginatedPlansQuery
          if (
            fetchMoreResult &&
            fetchMoreResult.paginatedPlans?.plans?.length === 0
          ) {
            setDone(true)
            return previousResult
          }
          const newPlans: PlanTypes[] = [
            ...((previousResult?.paginatedPlans?.plans as PlanTypes[]) || []),
            ...((fetchMoreResult?.paginatedPlans?.plans as PlanTypes[]) || []),
          ]

          // this object that is returned must be the same shape
          // as what is stored in Apollo's cache
          const retval = Object.assign({}, previousResult, {
            paginatedPlans: {
              __typename: 'PaginatedPlans',
              cursor: {
                __typename: 'PaginateCursor',
                offset: newPlans.length || 0,
              },
              plans: newPlans,
            },
          })

          return retval
        },
      })
    }
  }

  // r e n d e r
  return (
    <Root className={classes.root} ref={parentContainerRef}>
      {loading ? (
        <Skeleton width={'100%'} height={'100%'} />
      ) : (
        <Grid container justifyContent="center" spacing={2}>
          {plans?.map((plan: PlanTypes, i: number) => {
            // Return null if plan has no elevations
            if (!plan?.elevations?.length) {
              return null
            }

            // Render plan elevation card item
            return (
              <Grid item key={`plan-${plan.id}-${i}`}>
                <PlanElevationCard
                  selectPlans={selectPlans}
                  deselectPlans={deselectPlans}
                  plan={plan}
                  frontPageMetric={selectedMetric}
                  elevation={plan.elevations[0] as Elevation}
                />
              </Grid>
            )
          })}
          <BoundaryObserver
            root={parentContainerRef.current}
            loading={loading}
            loadMore={loadMore}
            done={false}
          />
        </Grid>
      )}
    </Root>
  )
}

export default PlanElevationsGrid
