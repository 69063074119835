import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

export const PaperStyled = styled(Paper)({
  padding: 16,
  marginTop: 16,
})

export const TitleStyled = styled(Typography)({
  textAlign: 'center',
})
