import React, { useContext, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import {
  User,
  EmailCampaign,
  EmailCampaignCohort,
  useGetUsersQuery,
} from '../../../../graphql/gen-types'
import ResponsiveTable, {
  ResponsiveCell,
} from 'components/common/tables/ResponsiveTable'
import EmailCohortTable from '../components/EmailCohortTable'
import { AppStore } from 'store'
import { isDefined } from 'utils/functions'
import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@mui/material/Tooltip'

interface EmailCampaignSectionThreeProps {
  classes: any
  emailCampaign: EmailCampaign
  handleTestEmailSend: () => void
  selectedCohort: EmailCampaignCohort[]
  setSelectedCohort: (cohort: EmailCampaignCohort[]) => void
  setSharing: (userIds: number[]) => void
  setTestEmail: (testEmail: string) => void
  setTestEmailDialogOpen: (open: boolean) => void
  testEmail: string | undefined
  testEmailDialogOpen: boolean
}

export default function EmailCompositionModalStepOne({
  classes,
  emailCampaign,
  handleTestEmailSend,
  selectedCohort,
  setSelectedCohort,
  setSharing,
  setTestEmailDialogOpen,
  setTestEmail,
  testEmail,
  testEmailDialogOpen,
}: EmailCampaignSectionThreeProps): JSX.Element {
  const { appState } = useContext(AppStore)
  const { selectedClient } = appState
  const clientName = selectedClient?.altName || ''
  const [allUsers, setAllUsers] = useState<User[]>([])
  const [selectedUsers, setSelectedUsers] = useState<User[]>([])

  const { data } = useGetUsersQuery({
    variables: {
      clientName,
    },
  })
  useEffect(() => {
    const users = data?.colonnadeUsers?.filter(isDefined) || []
    setAllUsers(users)
  }, [data?.colonnadeUsers])

  const getIds = (select: User[]) =>
    select.map((user) => user.id).filter(isDefined)
  const userSelected = (user: User) =>
    getIds(selectedUsers).indexOf(user.id || 0)
  const handleUserClick = (user: User) => {
    const newSelected = [...selectedUsers]
    const index = userSelected(user)

    if (user && index === -1) {
      newSelected.push(user)
    } else if (index !== -1) {
      newSelected.splice(index, 1)
    }
    setSelectedUsers(newSelected)
    setSharing(getIds(newSelected))
  }
  const handleSelectAll = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation()
    let newSelected = allUsers
    if (selectedUsers.length === allUsers.length) {
      newSelected = []
    }
    setSelectedUsers(newSelected)
    setSharing(getIds(newSelected))
  }

  const generateRow = (user: User, index: number | undefined): JSX.Element => {
    return (
      <>
        <ResponsiveCell responseSize="sm" align="center">
          {user.firstName} {user.lastName}
        </ResponsiveCell>
        <ResponsiveCell responseSize="sm" align="center">
          {user.email}
        </ResponsiveCell>
        <ResponsiveCell responseSize="sm" align="center">
          <Checkbox checked={userSelected(user) !== -1}></Checkbox>
        </ResponsiveCell>
      </>
    )
  }
  const columns: JSX.Element[] = [
    <ResponsiveCell responseSize="sm" align="center" key="header-cell-name">
      Name
    </ResponsiveCell>,
    <ResponsiveCell responseSize="sm" align="center" key="header-cell-email">
      Email
    </ResponsiveCell>,
    <ResponsiveCell responseSize="sm" align="center" key="header-cell-selected">
      Selected
      <Tooltip title="Select All">
        <Checkbox
          checked={selectedUsers.length === allUsers.length}
          onClick={handleSelectAll}
        ></Checkbox>
      </Tooltip>
    </ResponsiveCell>,
  ]

  return (
    <>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          style={{ position: 'relative', marginBottom: '15px' }}
        >
          <Typography variant="h6" color="primary">
            Sharing
          </Typography>
          <Typography style={{ marginBottom: '15px' }}>
            Share this email campaign with other users. Campaign will be
            viewable to users in the Email Campaign shared tab.
          </Typography>
          <ResponsiveTable
            data={allUsers}
            generateRow={generateRow}
            columns={columns}
            initialRows={5}
            onRowClick={(row: unknown): void => handleUserClick(row as User)}
            responseSize={'sm'}
            dense
          ></ResponsiveTable>
        </Grid>
        <Grid item xs={12} style={{ position: 'relative' }}>
          <Typography
            variant="h6"
            color="primary"
            style={{ marginBottom: '15px' }}
          >
            Cohort Selection
          </Typography>
          <Button
            className={classes.sentTestButton}
            variant="contained"
            color="primary"
            onClick={() => setTestEmailDialogOpen(true)}
          >
            Send Test Email
          </Button>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: '20px' }}>
          {emailCampaign?.cohort?.length ? (
            <EmailCohortTable
              cohort={emailCampaign?.cohort as EmailCampaignCohort[]}
              selectedCohort={selectedCohort}
              onChange={setSelectedCohort}
            />
          ) : (
            <span>This Email Campaign has no assigned leads.</span>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Typography>
            Campaign will be sent to {selectedCohort.length} leads.
          </Typography>
        </Grid>
      </Grid>
      <Dialog
        open={testEmailDialogOpen}
        onClose={(): void => setTestEmailDialogOpen(false)}
        aria-labelledby="test-email-title"
        aria-describedby="test-email-message"
      >
        {testEmailDialogOpen && (
          <div>
            <DialogTitle id="test-email-title">Send a Test Email</DialogTitle>
            <DialogContent style={{ minWidth: '500px' }}>
              <FormControl className={classes.formControl} variant="standard">
                <TextField
                  variant="standard"
                  data-testid="emailCompSubject"
                  id="emailCompSubject"
                  label={'Recipient Email Address'}
                  required
                  value={testEmail}
                  onChange={(ev): void => setTestEmail(ev.target.value)}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(): void => setTestEmailDialogOpen(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                disabled={!testEmail}
                data-testid="test-email-button"
                color="primary"
                onClick={handleTestEmailSend}
                autoFocus
              >
                Send
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    </>
  )
}
