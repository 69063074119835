import React, { useContext, useEffect, useRef } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import {
  CommonQueryFilter,
  useGetVisitorLocationsQuery,
  VisitorLocation,
} from 'graphql/gen-types'
import { AppStore } from 'store'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import { StyledGrid, classes } from './VisitorLocations.styles'
import ComponentCard from '../../common/layout/ComponentCard'

const COMPONENT_TITLE = 'Visitor Locations'
const COMPONENT_HEIGHT_LG = 700
const COMPONENT_HEIGHT_SM = 400
const GEO_LOCATION_FETCH_LIMIT = 10000

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY || ''

interface VisitorLocationsProps {
  filter: CommonQueryFilter
}

export default function VisitorLocations({
  filter,
}: VisitorLocationsProps): JSX.Element {
  const mapContainerRef = useRef<HTMLDivElement | null>(null)

  const theme = useTheme()

  const componentCardHeight = useMediaQuery(theme.breakpoints.down('md'))
    ? COMPONENT_HEIGHT_SM
    : COMPONENT_HEIGHT_LG

  const { data, loading, error } = useGetVisitorLocationsQuery({
    variables: {
      filter: { ...filter, limit: GEO_LOCATION_FETCH_LIMIT },
    },
  })

  const visitorLocations: VisitorLocation[] = data?.getVisitorLocations || []

  // Format data into geoJSON object array
  const geoJSON = visitorLocations.map((visitorLocation) => {
    return {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Point',
        coordinates: [visitorLocation.longitude, visitorLocation.latitude],
      },
    }
  })

  // Render map
  useEffect(() => {
    if (!mapContainerRef.current) {
      return
    }

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      doubleClickZoom: false,
      center: [-78.8354547, 35.6499533], // starting position
      style: 'mapbox://styles/lion-keng/cklv43yd829e618obmuofh4qx',
      maxZoom: 10,
      minZoom: 3,
    })

    map.on('load', function () {
      map.addSource('visitors-data', {
        type: 'geojson',
        cluster: true,
        clusterRadius: 5,
        data: {
          type: 'FeatureCollection',
          features: geoJSON as any[],
        },
      })

      map.addLayer({
        id: 'visitors-heat',
        type: 'heatmap',
        source: 'visitors-data',
        maxzoom: 15,
        paint: {
          // assign color values be applied to points depending on their density
          'heatmap-color': [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0,
            'rgba(33,102,172,0)',
            0.25,
            'rgba(189, 189, 255,0.7)',
            0.5,
            'rgba(209,229,240,0.7)',
            0.75,
            'rgba(239,138,98,0.7)',
            1,
            'rgba(178,24,43,0.8)',
          ],
          // increase radius as zoom increases
          'heatmap-radius': {
            default: 15,
            stops: [
              [3, 12],
              [4, 15],
              [5, 19],
              [6, 20],
              [7, 20],
              [8, 40],
              [9, 50],
              [9.5, 80],
              [10, 100],
            ],
          },
          // decrease opacity to transition into the circle layer
          'heatmap-opacity': {
            default: 1,
            stops: [[0.45, 1]],
          },
        },
      })
    })
  })

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized
          message={error.toString()}
          imageName={'VisitorLocations'}
        />
      )
    }
    return <div>{error.toString()}</div>
  }

  return (
    <ComponentCard
      title={COMPONENT_TITLE}
      height={componentCardHeight}
      result={{ loading, error }}
    >
      <StyledGrid
        container
        justifyContent="center"
        className={classes.gridRoot}
      >
        <div className={classes.parent}>
          <div className={classes.map} ref={mapContainerRef} />
        </div>
      </StyledGrid>
    </ComponentCard>
  )
}
