import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Button, { ButtonProps } from '@mui/material/Button'

export interface LoadingButtonProps extends ButtonProps {
  loading: boolean
}

const LoadingButton: React.FC<LoadingButtonProps> = ({
  children,
  loading,
  disabled = false,
  ...props
}) => {
  return (
    <Button
      {...props}
      style={{
        pointerEvents: loading ? 'none' : 'auto',
      }}
      disabled={loading ? true : disabled}
    >
      {children}
      {loading && (
        <CircularProgress
          size="1em"
          style={{
            marginLeft: '.5em',
            color: 'inherit',
          }}
        />
      )}
    </Button>
  )
}

export default LoadingButton
