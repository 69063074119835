import React, { createRef, useContext, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { useLocation } from 'react-router-dom'
import { AppStore } from 'store'
import {
  Client,
  useGetClientQuery,
  useGetHomeSelectionByFavoriteIdLazyQuery,
  useGetHomeSelectionLazyQuery,
} from 'graphql/gen-types'

import MyHome, {
  HomeSelection,
} from '../../components/leads/favorite-details/MyHome'
import ComponentCard from 'components/common/layout/ComponentCard'
import { classes, Root } from './FavoriteDetails.styles'

const ALL_LOCATIONS_ID = Number.MAX_SAFE_INTEGER
const HEADER_HT = 70
const ALL_PLAN_TYPES = 'All Home Types'
const ALL_LOCATIONS_OPTION = {
  value: ALL_LOCATIONS_ID,
  label: 'All Locations',
}

export const uiConfig = {
  appRef: null,
  bathroomRange: [0, Number.MAX_SAFE_INTEGER],
  bedroomRange: [0, Number.MAX_SAFE_INTEGER],
  buyerLoginDialogOpen: false,
  adblockMessageOpen: false,
  closingDateFilter: null,
  communitiesMapBounds: {
    southBound: 0,
    eastBound: 0,
    offsetLat: 0,
    offsetLng: 0,
  },
  compareFavoritesOpen: false,
  contactDialogOpen: false,
  exteriorMenuOpen: false,
  favMenuOpen: false,
  floorplanMenuOpen: false,
  fnum: 1,
  headerHeight: HEADER_HT,
  highlightSgtLots: false,
  homeTypeFilter: ALL_PLAN_TYPES,
  iconSize: 40,
  leftHeaderRef: null,
  loading: false,
  locationFilter: [ALL_LOCATIONS_OPTION],
  midHeaderRef: null,
  mirror: false,
  mobile: false,
  mortgageCalculatorDownPayment: 0,
  mortgageCalculatorOpen: false,
  mortgageCalculatorRate: [3.5],
  mortgageCalculatorTerm: [30],
  planNameFilter: null,
  plansMenuOpen: false,
  priceRange: [0, Number.MAX_SAFE_INTEGER],
  scrollableContainerRef: createRef(),
  rightHeaderRef: null,
  signInDialogOpen: false,
  small: false,
  sqftRange: [0, Number.MAX_SAFE_INTEGER],
  tgtCommunities: [],
  filteredCommunities: null,
  tgtCities: [],
  tgtRegion: '',
  userProfile: null, // this comes from a form input, not db
  localAgent: null, // a locally set agent profile
}

function useRouteQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search)
}

export default function FavoriteDetails(): JSX.Element {
  const { appState } = useContext(AppStore)
  const { selectedClient } = appState
  const clientName = selectedClient?.altName || ''
  const [selection, setSelection] = useState<HomeSelection>()
  const [client, setClient] = useState<Client>()

  const query = useRouteQuery()
  const prospectId = query.get('prospectId') || ''
  const favoriteId = parseInt(query.get('favoriteId') || '0') || undefined
  const homeId = query.get('homeId') || undefined

  const decodedProspectId = decodeURI(prospectId)

  /* Get Home Selection Queries */
  const [
    getHomeSelectionQuery,
    { data: homeSelectionResult },
  ] = useGetHomeSelectionLazyQuery({ fetchPolicy: 'network-only' })

  const [
    getHomeSelectionByFavoriteIdQuery,
    { data: homeSelectionByFavoriteIdResult },
  ] = useGetHomeSelectionByFavoriteIdLazyQuery({ fetchPolicy: 'network-only' })

  useEffect(() => {
    if (selection === undefined && homeId) {
      getHomeSelectionQuery({
        variables: {
          clientName,
          prospectIdentifier: decodedProspectId,
          homeIdentifier: homeId,
        },
      })

      if (homeSelectionResult?.getHomeSelection?.json) {
        setSelection(homeSelectionResult.getHomeSelection.json as HomeSelection)
      }
    } else if (selection === undefined && favoriteId) {
      getHomeSelectionByFavoriteIdQuery({
        variables: {
          clientName,
          favoriteIdentifier: favoriteId,
        },
      })

      if (homeSelectionByFavoriteIdResult?.getHomeSelection?.json) {
        setSelection(
          homeSelectionByFavoriteIdResult.getHomeSelection.json as HomeSelection
        )
      }
    }
  }, [
    clientName,
    decodedProspectId,
    favoriteId,
    getHomeSelectionByFavoriteIdQuery,
    getHomeSelectionQuery,
    homeId,
    homeSelectionByFavoriteIdResult?.getHomeSelection,
    homeSelectionResult?.getHomeSelection,
    selection,
  ])

  /* Get Client */
  const getClientResult = useGetClientQuery({
    variables: {
      clientName,
    },
  })

  useEffect(() => {
    const clientByName = getClientResult?.data?.clientByName as Client
    if (clientByName) {
      setClient(clientByName)
    }
  }, [getClientResult])

  // TODO: implement proper is inventory with no plan elevation
  // const isInventoryWithNoPlanElevation =
  //   pathname && pathname.indexOf('inventory/myhome') !== -1
  const isInventoryWithNoPlanElevation = false
  return selection && client ? (
    <Root className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <ComponentCard>
            <MyHome
              variant="favorite"
              client={client}
              selection={selection}
              prospectId={prospectId}
              homeIdentifier={homeId}
              favoriteIdentifier={favoriteId}
              isInventoryWithNoPlanElevation={isInventoryWithNoPlanElevation}
              uiConfig={uiConfig}
            />
          </ComponentCard>
        </Grid>
      </Grid>
    </Root>
  ) : (
    <div>Loading...</div>
  )
}
