import React, { useEffect, useState } from 'react'
import {
  CommonQueryFilter,
  PopularFloorPlanOptions,
  usePopularFloorPlanOptionsQuery,
} from 'graphql/gen-types'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import ResponsiveTable from 'components/common/tables/ResponsiveTable'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'

export interface FloorPlanOptionTableProps {
  filter: CommonQueryFilter
  industryTrends: boolean
}

const NUM_DISPLAY_ROWS = 5
const RESPONSE_SIZE = 'sm'
const ROW_HEIGHT = '6rem'

const FloorPlanOptionTable = ({
  filter,
  industryTrends,
}: FloorPlanOptionTableProps): JSX.Element => {
  const [floorPlanOptions, setFloorPlanOptions] = useState<
    PopularFloorPlanOptions[]
  >([])

  const title = industryTrends
    ? 'Industry Floorplan Options'
    : 'Floorplan Options'

  const { data, loading, error } = usePopularFloorPlanOptionsQuery({
    variables: {
      filter: {
        ...filter,
        clientName: industryTrends ? '' : filter.clientName,
        limit: 100,
      },
    },
  })
  // TODO: Look into this StackOverflow issue as a possible solution... should not pass a stringified object to useEffect...
  // https://stackoverflow.com/questions/55808749/use-object-in-useeffect-2nd-param-without-having-to-stringify-it-to-json
  useEffect(() => {
    if (data) {
      const { popularFloorPlanOptions } = data
      setFloorPlanOptions(popularFloorPlanOptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)])

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized
          message={error.toString()}
          imageName={'IndustryTrendsFloorPlanOptionChart'}
        />
      )
    }
    console.error(error)
    return <div>Error while loading industry floorplan options trends</div>
  }

  if (loading) {
    return <div>loading...</div>
  }

  const ResponsiveTableRow = ({
    row,
  }: {
    row: PopularFloorPlanOptions
    index?: number
  }): JSX.Element => {
    return (
      <>
        <TableCell align="center" component="th" scope="row">
          <Typography noWrap>{row.floorPlanOptionName}</Typography>
        </TableCell>
        <TableCell align="center">{row.popularity}</TableCell>
      </>
    )
  }

  const tableHeadCells: (JSX.Element | null)[] = [
    <TableCell align="center" key="header-cell-name">
      Name
    </TableCell>,
    <TableCell align="center" key="header-cell-popularity">
      Popularity
    </TableCell>,
  ]

  return (
    <div>
      <Typography
        variant="h5"
        color="primary"
        style={{ paddingBottom: '10px' }}
      >
        {title}
      </Typography>
      <ResponsiveTable
        data={floorPlanOptions}
        generateRow={(dataRow, index): JSX.Element => (
          <ResponsiveTableRow row={dataRow} index={index} />
        )}
        columns={tableHeadCells}
        responseSize={RESPONSE_SIZE}
        initialRows={NUM_DISPLAY_ROWS}
      ></ResponsiveTable>
    </div>
  )
}

export default FloorPlanOptionTable
