import { gql } from '@apollo/client'

export const UPDATE_COLONNADE_USER = gql`
  mutation UPDATE_COLONNADE_USER(
    $callerUserId: Int!
    $userId: Int!
    $input: UpdateColonnadeUserInput!
  ) {
    updateColonnadeUser(
      callerUserId: $callerUserId
      userId: $userId
      input: $input
    )
  }
`
