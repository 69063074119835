import React, { useContext, useEffect, useState, useRef } from 'react'
import useFetchImage from '../../analytics/popular-lots/UseFetchImage.hook'
import LotLegend from './LotLegend'
import HeatMapScale from './HeatMapScale'
import { Lot, Maybe, Siteplan, SvgShape } from '../../../graphql/gen-types'
import { AppStore } from '../../../store'
import { useResizeObserver } from 'utils/hooks'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import LotLeadsTable from './LotLeadsTable'
import InteractiveSiteplanGeoJson from './InteractiveSiteplanGeoJson'
import SVGSitePlan from './SVGSiteplan'
import { isDefined } from 'utils/functions'
import { GOLDEN_RATIO } from '../../../constants'
import { classes, Root } from './InteractiveSiteplan.styles'
interface SiteplanProps {
  siteplan: Siteplan
  colorMode?: boolean
  heatMapMode?: boolean
  highlightMode?: boolean
  interactive?: boolean
  borderColor?: string
  borderWidth?: number
  hideText?: boolean
  hideLegend?: boolean
  hideScale?: boolean
  scaleImage?: number
  width?: number
  height?: number
  grayscale?: number
  maxGridPopularity?: number
  minGridPopularity?: number
  onLotClick?: (lot: Lot) => void
  enableZoom?: boolean
}

export default function InteractiveSiteplan({
  siteplan,
  hideText = false,
  hideLegend = false,
  colorMode = false,
  heatMapMode = false,
  interactive = false,
  hideScale = false,
  highlightMode = false,
  borderColor = '',
  borderWidth = 2,
  width,
  height,
  grayscale = 0.8,
  minGridPopularity,
  maxGridPopularity,
  onLotClick,
  enableZoom = false,
}: SiteplanProps): JSX.Element {
  const GRAY_FILL = '#d3d3d3'
  const DEFAULT_WIDTH = 320
  const DEFAULT_HEIGHT = DEFAULT_WIDTH * GOLDEN_RATIO

  const { appState } = useContext(AppStore)
  const rootRef = useRef<HTMLDivElement | null>(null)
  const [rootWidth] = useResizeObserver(rootRef)

  const theme = useTheme()
  const smallSize = useMediaQuery(theme.breakpoints.down('sm'))
  const largeSize = useMediaQuery(theme.breakpoints.down('lg'))

  const { image, loading } = useFetchImage(
    appState?.selectedClient?.directoryName,
    siteplan.src
  )
  // This is needed because of collections.
  // If the siteplan svg has shapes but we can not match a lot with any shape it belongs to another collection.
  const initialLots = siteplan.popularLots?.filter(
    (lot: Maybe<Lot>) =>
      !!lot &&
      !(
        !siteplan?.svg?.shapes.filter(
          (shape: SvgShape) => shape?.attributes?.dataName === lot.dataName
        )[0] &&
        siteplan?.svg &&
        siteplan?.svg?.shapes.length > 0
      )
  ) as Lot[]

  const [filteredLots, setFilteredLots] = useState<Lot[] | undefined | null>(
    initialLots
  )
  const [heatMapModeOn, setHeatMapModeOn] = useState(heatMapMode)
  const [colorModeOn, setColorModeOn] = useState(colorMode)
  const [imageWidth, setImageWidth] = useState(
    width || image?.width || DEFAULT_WIDTH
  )
  const [imageHeight, setImageHeight] = useState(
    height || image?.height || DEFAULT_HEIGHT
  )
  const [mapWidth, setMapWidth] = useState(width || DEFAULT_WIDTH)
  const [mapHeight, setMapHeight] = useState(height || DEFAULT_HEIGHT)
  const [scale, setScale] = useState(0)

  const isGeoJson = !!siteplan.geoInfo

  const [filteredStatuses, setFilteredStatuses] = useState<Array<string>>([])

  const popularityArray =
    (filteredLots?.map(
      (lot: Lot) => (lot && lot.popularity) || 0
    ) as number[]) || []
  const maxPopularity =
    maxGridPopularity !== undefined
      ? maxGridPopularity
      : popularityArray.length > 0
      ? Math.max(...popularityArray)
      : 0
  const minPopularity =
    minGridPopularity !== undefined
      ? minGridPopularity
      : popularityArray.length > 0
      ? Math.min(...popularityArray)
      : 0

  const onFiltersChange = (code: string) => {
    filteredStatuses.includes(code)
      ? setFilteredStatuses(
          filteredStatuses.filter((status) => status !== code)
        )
      : setFilteredStatuses([...filteredStatuses, code])
  }
  const onLotLegendModeChange = (heatMapOn: boolean) => {
    setHeatMapModeOn(heatMapOn)
    setColorModeOn(!heatMapOn)
  }

  useEffect(() => {
    const allLots =
      siteplan.lots
        ?.filter(isDefined)
        ?.filter(
          (lot) =>
            !filteredStatuses.includes(lot.salesStatus) &&
            !(
              !siteplan?.svg?.shapes.filter(
                (shape) => shape?.attributes?.dataName === lot.dataName
              )[0] && siteplan?.svg?.shapes?.length
            )
        ) || []
    const popularLots =
      siteplan?.popularLots
        ?.filter(isDefined)
        ?.filter(
          (lot) =>
            !filteredStatuses.includes(lot.salesStatus) &&
            !(
              !siteplan?.svg?.shapes.filter(
                (shape) => shape?.attributes?.dataName === lot.dataName
              )[0] && siteplan?.svg?.shapes?.length
            )
        ) || []
    const mergedLots = allLots?.map((lot) => {
      const popLot = popularLots?.find((popLot) => popLot.id === lot.id)
      return { ...popLot, ...lot }
    })
    setFilteredLots(mergedLots)
  }, [filteredStatuses, siteplan])

  useEffect(() => {
    if (image) {
      const wd = hideLegend ? rootWidth : window.innerWidth * 0.5
      const ht = hideLegend ? rootWidth * 0.618 : window.innerHeight * 0.5
      setImageWidth(wd)
      setImageHeight(ht)
      const wdScale = wd / image.width
      const htScale = ht / image.height
      setScale(Math.min(wdScale, htScale))
    } else {
      const wd = width || rootRef.current?.clientWidth || DEFAULT_WIDTH
      setMapWidth(wd)
      setMapHeight(wd * GOLDEN_RATIO)
    }
  }, [hideLegend, image, rootWidth, rootRef.current?.clientWidth, width])

  const rootClass = smallSize
    ? classes.mobileRoot
    : hideLegend
    ? classes.simpleRoot
    : classes.gridRoot

  const size = smallSize ? 25 : largeSize ? 40 : 80
  return (
    <Root className={rootClass} ref={rootRef}>
      {!hideLegend && (
        <div className={classes.section}>
          <LotLegend
            filtersEnabled={true}
            onChange={(code: string) => onFiltersChange(code)}
            legend={siteplan.lotLegend}
            lots={siteplan.lots as Lot[]}
            heatMapMode={heatMapModeOn}
            onLotLegendModeChange={onLotLegendModeChange}
            salesStatusesToFilterOut={filteredStatuses}
          />
          <LotLeadsTable lots={filteredLots || []} onLotClick={onLotClick} />
          <strong className={classes.strongFont}>
            Note: Expand Date Range to view a longer popularity and leads
            history.
          </strong>
          {heatMapModeOn && !hideScale && smallSize && (
            <HeatMapScale
              maxPopularity={maxPopularity}
              minPopularity={minPopularity}
              size={size}
              zeroColor={GRAY_FILL}
            />
          )}
        </div>
      )}
      <div className={classes.section}>
        {/* Siteplan */}
        {image && !isGeoJson ? (
          <SVGSitePlan
            siteplan={siteplan}
            lots={filteredLots || []}
            image={image}
            width={imageWidth}
            height={imageHeight}
            scale={scale}
            grayScale={grayscale}
            borderColor={borderColor}
            borderWidth={borderWidth}
            loading={loading}
            colorMode={colorModeOn}
            heatMapMode={heatMapModeOn}
            highlightMode={highlightMode}
            maxPopularity={maxPopularity}
            minPopularity={minPopularity}
            onLotClick={onLotClick}
            enableZoom={enableZoom}
          />
        ) : null}
        {/* Wait for rooRef to be defined to allow proper initial render size for mapbox */}
        {isGeoJson && rootRef.current ? (
          <InteractiveSiteplanGeoJson
            siteplan={siteplan}
            lots={filteredLots || []}
            maxPopularity={maxPopularity}
            minPopularity={minPopularity}
            width={mapWidth}
            height={mapHeight}
            heatMap={heatMapModeOn}
            interactive={interactive}
            onLotClick={onLotClick}
          />
        ) : null}
        {heatMapModeOn && !hideScale && !smallSize && (
          <HeatMapScale
            maxPopularity={maxPopularity}
            minPopularity={minPopularity}
            size={size}
            zeroColor={GRAY_FILL}
          />
        )}
      </div>
    </Root>
  )
}
