import React, { useContext, useState } from 'react'
import ComponentCard from 'components/common/layout/ComponentCard'
import {
  EmailCampaign,
  EmailCampaignCohortStatus,
  EmailCampaignCohort,
  useGetEmailCampaignsQuery,
} from 'graphql/gen-types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ResponsiveTable, {
  ResponsiveCell,
} from 'components/common/tables/ResponsiveTable'
import { formatDateTime } from 'utils/functions'
import EmailCampaignDetailsModal from './modals/EmailCampaignDetailsModal'
import { AppStore } from 'store'
import { AuthContext } from 'auth/AuthContext'
import EmailCompositionModal from './modals/EmailCompositionModal'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { ANEWGO_ADMIN_ROLE_ID } from '../../../constants'
import TabPanel from 'components/common/layout/TabPanel'
import FollowUpEmailModal from './modals/FollowUpEmailModal'

const COMPONENT_TITLE = 'Email Campaigns'
const RESPONSE_SIZE = 'sm'

export const EmailCampaignStatusTitles: Record<string, string> = {
  [EmailCampaignCohortStatus.None]: 'None',
  [EmailCampaignCohortStatus.Sent]: 'Sent',
  [EmailCampaignCohortStatus.Delivered]: 'Delivered',
  [EmailCampaignCohortStatus.Opened]: 'Opened',
  [EmailCampaignCohortStatus.Clicked]: 'Clicked Link',
  [EmailCampaignCohortStatus.CreatedMyHome]: 'Create My Home',
}

const unOpened = [
  EmailCampaignCohortStatus.None,
  EmailCampaignCohortStatus.Sent,
] as string[]

export default function EmailCampaigns(): JSX.Element {
  const { user } = useContext(AuthContext)
  const { appState } = useContext(AppStore)
  const { selectedClient } = appState
  const clientName = selectedClient?.altName || ''
  const [open, setOpen] = useState(false)
  const [followUpEmailOpen, setFollowUpEmailOpen] = useState(false)
  const [selectedLeads, setSelectedLeads] = useState<EmailCampaignCohort[]>([])
  const [index, setIndex] = useState('owned')
  const [
    selectedActiveCampaign,
    setSelectedActiveCampaign,
  ] = useState<EmailCampaign>()
  const [
    selectedDraftCampaign,
    setSelectedDraftCampaign,
  ] = useState<EmailCampaign>()

  const emailCampaignsResult = useGetEmailCampaignsQuery({
    variables: {
      clientName,
    },
  })

  const sharedCampaignsResult = useGetEmailCampaignsQuery({
    variables: {
      clientName,
      shared: true,
    },
  })

  const handleActiveRowClick = (row: unknown) => {
    const campaign = row as EmailCampaign
    setSelectedActiveCampaign(campaign)
    setOpen(true)
  }

  const handleDraftRowClick = (row: unknown) => {
    const campaign = row as EmailCampaign
    setSelectedDraftCampaign(campaign)
  }

  const handleSubmit = () => {
    setOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleFollowUp = (leads: EmailCampaignCohort[]) => {
    setSelectedLeads(leads)
    setFollowUpEmailOpen(true)
  }

  const handleEmailCompClose = () => {
    setSelectedDraftCampaign(undefined)
  }

  const handleTabChange = (tabValue: string) => {
    setIndex(tabValue)
  }

  function generateRow(
    dataRow: EmailCampaign | null,
    index: number | undefined
  ): JSX.Element | null {
    if (!dataRow) {
      return null
    }
    const campaign = dataRow
    const product = '-'

    const numLeads = campaign.cohort?.length || 0
    const numAccessed =
      campaign.cohort?.filter(
        (lead) =>
          !unOpened.includes(lead?.status || EmailCampaignCohortStatus.None)
      ).length || 0
    return (
      <>
        <ResponsiveCell responseSize={RESPONSE_SIZE} align="center">
          {campaign.name}
        </ResponsiveCell>
        <ResponsiveCell responseSize={RESPONSE_SIZE} align="center">
          {product}
        </ResponsiveCell>
        <ResponsiveCell responseSize={RESPONSE_SIZE} align="center">
          {numLeads}
        </ResponsiveCell>
        <ResponsiveCell responseSize={RESPONSE_SIZE} align="center">
          {`${Math.round((numAccessed / numLeads) * 100) || 0}%`}
        </ResponsiveCell>
        <ResponsiveCell align="center">
          {formatDateTime(campaign.createdAt, 'MM/dd/yyy')}
        </ResponsiveCell>
        <ResponsiveCell align="center">
          {campaign.active ? 'Active' : 'Disabled'}
        </ResponsiveCell>
      </>
    )
  }

  const columns: JSX.Element[] = [
    <ResponsiveCell
      responseSize={RESPONSE_SIZE}
      align="center"
      key="header-cell-name"
    >
      Name
    </ResponsiveCell>,
    <ResponsiveCell
      responseSize={RESPONSE_SIZE}
      align="center"
      key="header-cell-product"
    >
      Product
    </ResponsiveCell>,
    <ResponsiveCell
      responseSize={RESPONSE_SIZE}
      align="center"
      key="header-cell-leads"
    >
      Leads
    </ResponsiveCell>,
    <ResponsiveCell
      responseSize={RESPONSE_SIZE}
      align="center"
      key="header-cell-progress"
    >
      Progress
    </ResponsiveCell>,
    <ResponsiveCell
      responseSize={RESPONSE_SIZE}
      align="center"
      key="header-cell-created"
    >
      Created
    </ResponsiveCell>,
    <ResponsiveCell
      responseSize={RESPONSE_SIZE}
      align="center"
      key="header-cell-active"
    >
      Active
    </ResponsiveCell>,
  ]

  const sharedCampaigns =
    (sharedCampaignsResult.data?.getEmailCampaigns as EmailCampaign[]) || []
  const campaigns =
    (emailCampaignsResult.data?.getEmailCampaigns as EmailCampaign[]) || []

  const activeCampaigns = campaigns.filter((campaign) => campaign?.active)
  const draftCampaigns = campaigns.filter((campaign) => !campaign?.active)

  return (
    <ComponentCard title={COMPONENT_TITLE}>
      {draftCampaigns.length ? (
        <EmailCompositionModal
          emailCampaignId={selectedDraftCampaign?.id}
          open={selectedDraftCampaign !== undefined}
          onClose={handleEmailCompClose}
        />
      ) : (
        ''
      )}

      {selectedActiveCampaign && (
        <>
          <EmailCampaignDetailsModal
            emailCampaign={selectedActiveCampaign}
            open={open}
            onClose={handleClose}
            onSubmit={handleSubmit}
            onFollowUp={handleFollowUp}
          />
          <FollowUpEmailModal
            emailCampaignId={selectedActiveCampaign?.id}
            leads={selectedLeads}
            open={followUpEmailOpen}
            onClose={() => setFollowUpEmailOpen(false)}
          />
        </>
      )}

      <Tabs
        value={index}
        onChange={(e, value) => handleTabChange(value)}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        <Tab value="owned" label="My Campaigns" />
        <Tab
          value="shared"
          label={
            user.roleId === ANEWGO_ADMIN_ROLE_ID ? 'View All' : 'Shared With Me'
          }
        />
      </Tabs>
      <TabPanel value="owned" index={index}>
        {draftCampaigns.length ? (
          <>
            <Box p={2}>
              <Typography variant="h5" color="primary">
                Drafts
              </Typography>
            </Box>

            <ResponsiveTable
              data={draftCampaigns}
              generateRow={generateRow}
              columns={columns}
              responseSize={RESPONSE_SIZE}
              onRowClick={handleDraftRowClick}
              initialRows={5}
            />
          </>
        ) : (
          ''
        )}

        <Box p={2}>
          <Typography variant="h5" color="primary">
            Active
          </Typography>
        </Box>

        <ResponsiveTable
          data={activeCampaigns}
          generateRow={generateRow}
          columns={columns}
          responseSize={RESPONSE_SIZE}
          onRowClick={handleActiveRowClick}
          initialRows={5}
        />
      </TabPanel>
      <TabPanel value="shared" index={index}>
        <Box p={2}>
          <Typography variant="h5" color="primary">
            Shared
          </Typography>
        </Box>

        <ResponsiveTable
          data={sharedCampaigns}
          generateRow={generateRow}
          columns={columns}
          responseSize={RESPONSE_SIZE}
          onRowClick={handleActiveRowClick}
          initialRows={5}
        />
      </TabPanel>
    </ComponentCard>
  )
}
