import { styled } from '@mui/material/styles'
const PREFIX = 'EmailReport'

export const classes = {
  eho: `${PREFIX}-eho`,
  root: `${PREFIX}-root`,
  footer: `${PREFIX}-footer`,
  footerMiddleTab: `${PREFIX}-footerMiddleTab`,
  header: `${PREFIX}-header`,
  leftHeader: `${PREFIX}-leftHeader`,
  rightHeader: `${PREFIX}-rightHeader`,
  builderLogoContainer: `${PREFIX}-builderLogoContainer`,
  disclaimerContent: `${PREFIX}-disclaimerContent`,
  componentContainer: `${PREFIX}-componentContainer`,
  page: `${PREFIX}-page`,
}

export const Root = styled('div')(() => ({
  [`& .${classes.eho}`]: {
    paddingLeft: 8,
    gridColumn: '1 / 2',
    '& img': {
      height: 40,
      width: 'auto',
    },
  },

  [`& .${classes.root}`]: {
    height: 'inherit',
  },

  [`& .${classes.footer}`]: {
    height: PAGE_FOOTER_HT,
    display: 'grid',
    gridTemplateColumns: '100px 1fr 100px',
    gridTemplateRows: '1fr',
  },

  [`& .${classes.footerMiddleTab}`]: {
    fontWeight: 'bold',
    textAlign: 'center',
  },

  [`& .${classes.header}`]: {
    height: PAGE_HEADER_HT,
    borderBottom: `2px solid`,
    gridArea: 'header',
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '200px 1fr',
    gridTemplateAreas: `
      "leftHeader rightHeader"
    `,
  },

  [`& .${classes.leftHeader}`]: {
    height: 'inherit',
    gridArea: 'leftHeader',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },

  [`& .${classes.rightHeader}`]: {
    gridArea: 'rightHeader',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    paddingRight: 16,
    paddingBottom: 8,
  },

  [`& .${classes.builderLogoContainer}`]: {
    height: '100%',
    maxHeight: '80px',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    alignItems: 'center',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: '50% 50%',
    },
  },

  [`& .${classes.disclaimerContent}`]: {
    width: 'inherit',
  },

  [`& .${classes.componentContainer}`]: {
    marginTop: '0.5em',
    marginBottom: '0.5em',
    padding: '0em',
    justifyContent: 'center',
  },

  [`& .${classes.page}`]: {
    width: PAGE_WIDTH, // at 72dpi
    height: PAGE_HEIGHT,
    display: 'grid',
    gridTemplateRows: '100px 1fr 40px',
    gridTemplateColumns: '1fr',
    gridTemplateAreas: `
      "header"
      "content"
      "footer"
    `,
    pageBreakAfter: 'always',
  },
}))

const PAGE_HEADER_HT = 100
const PAGE_FOOTER_HT = 50

const PAGE_WIDTH = 734 // at 72 dpi 8 1/2"
const PAGE_HEIGHT = 1000 // 11"
