import React, { useState } from 'react'
import { useAtom } from 'jotai'
import { useParams } from 'react-router-dom'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import {
  Prospect,
  useSetProspectClientRatingMutation,
} from '../../../graphql/gen-types'
import { snackbarConfigAtom } from 'store/atoms'

export interface ProspectClientRatingInputProps {
  prospect: Prospect | undefined | null
}

export default function ProspectClientRatingInput({
  prospect,
}: ProspectClientRatingInputProps): JSX.Element {
  const { clientName } = useParams()
  const [snackbarConfig, setSnackbarConfig] = useAtom(snackbarConfigAtom)
  const [rating, setRating] = useState<string>(prospect?.clientRating || '')

  // Hook for mutating prospect activities
  const [setProspectClientRating] = useSetProspectClientRatingMutation({
    update(cache, response) {
      if (prospect?.email) {
        cache.modify({
          id: `LeadRanking:${prospect.email}`,
          fields: {
            clientRating() {
              return response.data?.setProspectClientRating?.clientRating
            },
          },
        })
      }
    },
  })

  const handleChange = (
    e: React.ChangeEvent<{
      name: string | undefined
      value: unknown
    }>
  ) => {
    e.preventDefault()
    e.stopPropagation()
    setRating(e.target.value as string)
  }

  // On blur of input, send mutation
  const handleBlur = () => {
    if (rating !== prospect?.clientRating) {
      setProspectClientRating({
        variables: {
          clientName: clientName,
          prospectEmail: prospect?.email || '',
          clientRating: rating,
        },
      })
        .then((result) => {
          setSnackbarConfig({
            ...snackbarConfig,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            autoHideDuration: 6000,
            open: true,
            message: 'Prospect ranking updated.',
            severity: 'success',
          })
        })
        .catch((err) => {
          setSnackbarConfig({
            ...snackbarConfig,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            autoHideDuration: 6000,
            open: true,
            message: err.message.replace(/.*[Ee]rror: /, ''),
            severity: 'error',
          })
        })
    }
  }

  return (
    <FormControl variant="outlined">
      <Tooltip
        title={'Custom field to set a custom lead rating value.'}
        placement="top-end"
      >
        <TextField
          id="outlined-basic"
          label="Rating"
          variant="outlined"
          onChange={handleChange}
          value={rating}
          size="small"
          data-testid="RatingInput"
          onBlur={handleBlur}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleBlur()
            }
          }}
        />
      </Tooltip>
    </FormControl>
  )
}
