import { styled } from '@mui/material/styles'

const PREFIX = 'ColonnadeApp'

export const classes = {
  root: `${PREFIX}-root`,
  centered: `${PREFIX}-paper`,
}

export const StyledRoot = styled('div')(() => ({
  [`&.${classes.root}`]: {
    height: '100vh',
    width: '100vw',
    overflowY: 'hidden',
    overflowX: 'hidden',
  },

  [`& .${classes.centered}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
