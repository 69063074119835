import ComponentCard from 'components/common/layout/ComponentCard'
import ResponsiveTable, {
  ResponsiveCell,
} from 'components/common/tables/ResponsiveTable'
import { LEADS_APP } from '../../../constants'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppStore } from 'store'
import { AppActionType } from 'store/reducers'
import { displayDateTime } from 'utils/functions'
import {
  CommonQueryFilter,
  LeadByElevation,
  useLeadsByElevationQuery,
} from '../../../graphql/gen-types'
interface LeadsByElevationProps {
  filter: CommonQueryFilter
}

const COMPONENT_TITLE = 'Leads Interested in Plan Elevation'

export default function LeadsByElevation({
  filter,
}: LeadsByElevationProps): JSX.Element {
  const navigate = useNavigate()
  const { appState } = useContext(AppStore)
  const appStore = useContext(AppStore)

  const { selectedClient } = appState
  const clientName = selectedClient?.altName

  const { data, error, loading } = useLeadsByElevationQuery({
    variables: {
      filter: {
        common: filter,
        leads: {},
      },
    },
  })

  const generateRow = (
    lead: LeadByElevation,
    index: number | undefined
  ): JSX.Element => {
    const prospect = lead?.prospect

    return (
      <React.Fragment>
        <ResponsiveCell align="center" responseSize="md">
          {prospect?.name || '-'}
        </ResponsiveCell>
        <ResponsiveCell align="center" responseSize="md">
          {prospect?.email || lead.user}
        </ResponsiveCell>
        <ResponsiveCell align="center" responseSize="md">
          {lead?.numFavorited}
        </ResponsiveCell>
        <ResponsiveCell align="center" responseSize="md">
          {lead?.numBrowsed}
        </ResponsiveCell>
        <ResponsiveCell align="center" responseSize="md">
          {displayDateTime(lead?.lastActivity)}
        </ResponsiveCell>
      </React.Fragment>
    )
  }

  const columns: JSX.Element[] = [
    <ResponsiveCell align="center" key="header-cell-name" responseSize="md">
      Name
    </ResponsiveCell>,
    <ResponsiveCell align="center" key="header-cell-email" responseSize="md">
      Email
    </ResponsiveCell>,
    <ResponsiveCell
      align="center"
      key="header-cell-favorited"
      responseSize="md"
    >
      Times Favorited
    </ResponsiveCell>,
    <ResponsiveCell align="center" key="header-cell-viewed" responseSize="md">
      Times Viewed
    </ResponsiveCell>,
    <ResponsiveCell
      align="center"
      key="header-cell-last-activity"
      responseSize="md"
    >
      Last Activity
    </ResponsiveCell>,
  ]

  const handleLeadClick = (user: string | undefined) => {
    appStore.dispatch({
      type: AppActionType.SET_SELECTED_APP,
      payload: LEADS_APP,
    })
    user && navigate(`/leads/client/${clientName}/prospect?prospectId=${user}`)
  }

  const leadsByElevation = data?.leadsByElevation || []
  // Remove leads with no prospect data
  const filteredLeads = leadsByElevation.filter(
    (leadByElevation) => leadByElevation.prospect !== null
  )
  return (
    <ComponentCard
      title={COMPONENT_TITLE}
      subtitle={'Click a lead to navigate to lead details'}
      result={{ data: filteredLeads, error, loading }}
    >
      <ResponsiveTable
        data={filteredLeads}
        generateRow={generateRow}
        columns={columns}
        initialRows={5}
        onRowClick={(row: unknown): void =>
          handleLeadClick((row as LeadByElevation)?.user || undefined)
        }
        responseSize={'md'}
      ></ResponsiveTable>
    </ComponentCard>
  )
}
