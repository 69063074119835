import { styled } from '@mui/material/styles'

const PREFIX = 'PopularCommunities'

export const classes = {
  componentContainer: `${PREFIX}-componentContainer`,
}

export const StyledTabPanel = styled('div')(({ theme }) => ({
  [`& .${classes.componentContainer}`]: {
    marginTop: '1em',
    marginBottom: '1em',
    justifyContent: 'center',
  },
}))
