import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

const PREFIX = 'CustomXAxisTick'

export const classes = {
  typography: `${PREFIX}-typography`,
}

export const StyledTypography = styled(Typography)(({ theme }) => ({
  // TODO adjust this when we stablize styling
  [`&.${classes.typography}`]: {
    fontSize: 12,
    textAlign: 'center',
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.contrastText
        : theme.palette.primary.main,
    overflow: 'auto',
  },
}))
