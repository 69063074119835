import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'

const PREFIX = 'EditUserDialog'

export const classes = {
  editUserDialog: `${PREFIX}-editUserDialog`,
  editUserFirstNameInputField: `${PREFIX}-editUserFirstNameInputField`,
  editUserLastNameInputField: `${PREFIX}-editUserLastNameInputField`,
  formControlEditUser: `${PREFIX}-formControlEditUser`,
}

export const StyledDialog = styled(Dialog)(() => ({
  [`& .MuiDialog-paper`]: {
    minWidth: '500px',
  },

  [`& .${classes.editUserFirstNameInputField}`]: {
    width: 'calc(100% - 10px)',
  },

  [`& .${classes.editUserLastNameInputField}`]: {
    marginLeft: '10px',
  },

  [`& .${classes.formControlEditUser}`]: {
    marginBottom: '20px',
    width: '100%',
  },
}))
