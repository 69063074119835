import React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import {
  CommonQueryFilter,
  useVisitorTimesQuery,
  VisitorTime,
} from '../../../graphql/gen-types'
import ComponentCard from 'components/common/layout/ComponentCard'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { range, formatHour } from 'utils/functions'
import Tooltip from '@mui/material/Tooltip'
import Grid from '@mui/material/Grid'

const PREFIX = 'VisitorTimes'

const classes = {
  container: `${PREFIX}-container`,
  legendContainer: `${PREFIX}-legendContainer`,
  col: `${PREFIX}-col`,
  label: `${PREFIX}-label`,
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.container}`]: {
    display: 'grid',
    gridColumnGap: `${COL_GAP}%`,
    [theme.breakpoints.down('sm')]: {
      gridColumnGap: `${COL_GAP * 2}%`,
    },
    // gridRowGap: '0.1em',
    gridTemplateColumns: range(NUM_COL)
      .map(() => `${(100 - COL_GAP * (NUM_COL - 1)) / NUM_COL}%`)
      .join(' '),
    gridTemplateRows: range(NUM_HOUR)
      .map(() => 'min-content')
      .join(' '),
    width: '100%',
  },

  [`& .${classes.legendContainer}`]: {
    justifyContent: 'center',
    display: 'grid',
    gridColumnGap: `${COL_GAP}%`,
    [theme.breakpoints.down('sm')]: {
      gridColumnGap: `${COL_GAP * 2}%`,
    },
    gridTemplateColumns: range(LEGEND_NUM_COL)
      .map(() => `${(100 - COL_GAP * (LEGEND_NUM_COL - 1)) / LEGEND_NUM_COL}%`)
      .join(' '),
    gridTemplateRows: 'min-content',
    width: '100%',
  },

  [`& .${classes.col}`]: {
    border: `0.1px solid ${
      theme.palette.mode === 'dark' ? 'gray' : 'lightgray'
    }`,
    height: '1.1em',
    backgroundColor: 'lightgray',
    transition: 'all .1s ease-in-out',
    '&:hover': {
      transform: 'scale(1.3)',
      border: '2px solid gray',
    },
    cursor: 'pointer',
  },

  [`& .${classes.label}`]: {
    height: '1.1em',
    width: '2.5em',
  },
}))

const COL_GAP = 0.5
const NUM_COL = 8
const LEGEND_NUM_COL = 5
const NUM_HOUR = 24

const COMPONENT_TITLE = 'Visitor Times'
const weekDays = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat']
const weekDaysLong = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

interface VisitorTimesProps {
  filter: CommonQueryFilter
}

function VisitorTimes({ filter }: VisitorTimesProps): JSX.Element {
  const { data, loading, error } = useVisitorTimesQuery({
    variables: {
      filter,
      minuteUTCDifference: new Date().getTimezoneOffset(), // this is minutes how much difference we have from UTC. Can be -/+
    },
  })

  return (
    <ComponentCard
      title={COMPONENT_TITLE}
      subtitle="Visitors By Time of Day"
      result={{ data: data?.visitorTimes, loading, error }}
    >
      <HeatmapChart data={data?.visitorTimes || []} />
    </ComponentCard>
  )
}

interface HeatmapChartProps {
  data: VisitorTime[]
}

function HeatmapChart({ data }: HeatmapChartProps): JSX.Element {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('md'))
  let max = 0

  const colors = ['#92bfed', '#71a6e3', '#4884d4', '#2261c3', '#003380']
  const SCALE_GRANULARITY = colors.length

  const hourlyData = Array(24)
    .fill(0)
    .map((x, i) => {
      const dayCountValue: VisitorTime = {
        hour: 0,
        dayCounts: [],
      }

      data.forEach(({ hour, dayCounts }) => {
        if (hour === i) {
          dayCountValue['hour'] = hour
          dayCountValue['dayCounts'] = [
            ...dayCountValue.dayCounts,
            ...dayCounts,
          ]
        }
      })

      return dayCountValue
    })

  // Fill byDay with 0's on missing days
  const dayCountsByHour = hourlyData.map((hourData) =>
    Array(7)
      .fill(0)
      .map((x, i) => {
        if (hourData.dayCounts?.length) {
          for (const { day_index, day_count } of hourData.dayCounts) {
            if (day_index === i) {
              return day_count
            }
          }
        }

        return 0
      })
  )

  // calculate max
  hourlyData.forEach(({ dayCounts }) => {
    dayCounts?.forEach(({ day_count }) => {
      max = Math.max(max, day_count || 0) // update max
    })
  })

  return (
    <StyledGrid container justifyContent="center" spacing={3}>
      <Grid item xs={12}>
        <div className={classes.container}>
          {dayCountsByHour.map((hourCountByDay, hour) => {
            const rows = hourCountByDay?.map((value, day) => {
              const count = value || 0

              const tip = (
                <div key={`tip-${hour}-${day}`}>
                  <p key={`tip-p-day-${hour}-${day}`}>{`${
                    weekDaysLong[day]
                  } ${formatHour(hour)}`}</p>
                  <p
                    key={`tip-p-count-${hour}-${day}`}
                  >{`Visitors: ${count}`}</p>
                </div>
              )

              return (
                <Tooltip
                  title={tip}
                  enterTouchDelay={1}
                  key={`tooltip-${hour}-${day}`}
                  placement="top"
                >
                  <div
                    className={classes.col}
                    style={{
                      backgroundColor: count
                        ? colors[
                            Math.floor(
                              (count / (max + 0.1)) * SCALE_GRANULARITY
                            )
                          ]
                        : 'darkgray',
                    }}
                    key={`bar-${hour}-${day}`}
                  ></div>
                </Tooltip>
              )
            })

            return (
              <React.Fragment key={`visitor-hour-fragment-${hour}`}>
                {/* Count Bar */}
                {rows}
                {/* Hour Label */}
                {
                  <div className={classes.label} key={`hr-label-${hour}`}>
                    <Typography
                      variant={'subtitle2'}
                      key={`hr-label-type-${hour}`}
                    >
                      {!(hour % 2) && `${formatHour(hour)}`}
                    </Typography>
                  </div>
                }
              </React.Fragment>
            )
          })}
          {/* Day of Week Label */}
          {weekDays.map((weekDay) => (
            <div className={classes.label} key={`day-label-${weekDay}`}>
              <Typography
                variant={sm ? 'subtitle2' : 'subtitle1'}
                key={`day-label-type-${weekDay}`}
              >
                {weekDay}
              </Typography>
            </div>
          ))}
        </div>
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <div className={classes.legendContainer}>
              {/* Legend */}
              {colors.map((color, i) => (
                <div key={`legendColor-container-${color}`}>
                  <div
                    className={classes.col}
                    style={{
                      backgroundColor: color,
                    }}
                    key={`legendColor-${color}`}
                  ></div>
                  <Typography key={`legendColor-typography-${color}`}>
                    {`${Math.round((i * max) / colors.length) + 1}
                    ${
                      // Only render range on big displays
                      !sm
                        ? `- ${Math.round(((i + 1) * max) / colors.length)}`
                        : ''
                    }`}
                  </Typography>
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </StyledGrid>
  )
}

export default VisitorTimes
