import {
  CanCityRegionInfo,
  CityInfo,
  CityRegionInfo,
  UsCityRegionInfo,
} from '../graphql/gen-types'

type Optional<T> = T | null | undefined

export const cityCountry = (city: Optional<CityInfo>): Optional<string> => {
  if (!city?.region) return undefined
  return city.region?.__typename?.replace('CityRegionInfo', '')
}

type CityFormatter = {
  US: (city: CityInfo, full: boolean) => string
  CAN: (city: CityInfo, full: boolean) => string
  Default: (city: CityInfo) => string
  Pick: (city: Optional<CityInfo>) => Optional<CityInfo>
}

type CityRegionFormatter = {
  US: (region: UsCityRegionInfo) => Optional<string>
  CAN: (region: CanCityRegionInfo) => Optional<string>
  Default: (region: CityRegionInfo) => Optional<string>
  Pick: (city: Optional<CityInfo>) => Optional<CityRegionInfo>
}

type CityRegionNumberFormatter = {
  US: (region: UsCityRegionInfo) => Optional<number>
  CAN: (region: CanCityRegionInfo) => Optional<number>
  Default: (region: CityRegionInfo) => Optional<number>
  Pick: (city: Optional<CityInfo>) => Optional<CityRegionInfo>
}

type CityPostCodeFormatter = {
  CAN: (region: CanCityRegionInfo) => Optional<string>
  Default: (region: CityRegionInfo) => Optional<string>
  Pick: (city: Optional<CityInfo>) => Optional<CityRegionInfo>
}

type CityZipCodeFormatter = {
  US: (region: UsCityRegionInfo) => Optional<number>
  Default: (region: CityRegionInfo) => Optional<number>
  Pick: (city: Optional<CityInfo>) => Optional<CityRegionInfo>
}

type IFormatter =
  | CityFormatter
  | CityRegionFormatter
  | CityRegionNumberFormatter
  | CityPostCodeFormatter
  | CityZipCodeFormatter

const cityAddressFormatters: CityFormatter = {
  US: (city) => {
    const region = city.region as UsCityRegionInfo
    return `${city.customName || city.name}, ${region.stateCode} ${
      region.zipCode
    }`
  },
  CAN: (city) => {
    const region = city.region as CanCityRegionInfo
    return `${city.customName || city.name}, ${region.provinceCode} ${
      region.postCode
    }`
  },
  Default: (city) => `${city.customName || city.name}`,
  Pick: (city) => city,
}

export const cityFormatters: Record<string, IFormatter> = {
  address: cityAddressFormatters,
  region: {
    US: (region) => region.metroCustomName || region.metroName,
    CAN: (region) => region.districtName,
    Default: () => '',
    Pick: (city) => city?.region,
  } as CityRegionFormatter,
  stateProvince: {
    US: (region) => region.stateName,
    CAN: (region) => region.provinceName,
    Default: () => '',
    Pick: (city) => city?.region,
  } as CityRegionFormatter,
  countyDistrictName: {
    US: (region) => region.countyName,
    CAN: (region) => region.districtName,
    Default: () => '',
    Pick: (city) => city?.region,
  } as CityRegionFormatter,
  countyDistrictCode: {
    US: (region) => region.countyName,
    CAN: (region) => region.districtName,
    Default: () => '',
    Pick: (city) => city?.region,
  } as CityRegionFormatter,
  postCode: {
    CAN: (region) => region.postCode,
    Default: () => '',
    Pick: (city) => city?.region,
  } as CityPostCodeFormatter,
  zipCode: {
    US: (region) => region.zipCode,
    Default: () => null,
    Pick: (city) => city?.region,
  } as CityZipCodeFormatter,
}

function cityFormatterFn<T>(
  city: Optional<CityInfo>,
  formatter: IFormatter
): Optional<T> {
  const value = city && formatter?.Pick(city)
  if (!value) return undefined
  const country = cityCountry(city)
  const countryFormatter =
    (country && formatter?.[country]) || formatter.Default
  return countryFormatter?.(value)
}

export const cityFormatter: Record<
  string,
  (city: Optional<CityInfo>) => string | number
> = {
  address: (city) => cityFormatterFn(city, cityFormatters.address) || '',
  regionName: (city) => cityFormatterFn(city, cityFormatters.region) || '',
  stateProvinceName: (city) =>
    cityFormatterFn(city, cityFormatters.stateProvince) || '',
  countyDistrictName: (city) =>
    cityFormatterFn(city, cityFormatters.countyDistrictName) || '',
  countyDistrictCode: (city) =>
    cityFormatterFn(city, cityFormatters.countyDistrictCode) || 0,
  zipCode: (city) => cityFormatterFn(city, cityFormatters.zipCode) || '',
  postCode: (city) => cityFormatterFn(city, cityFormatters.postCode) || '',
}
