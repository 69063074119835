import React from 'react'
import { SessionFilter, useSessionsSummaryTrendsQuery } from 'graphql/gen-types'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import ComponentCard from 'components/common/layout/ComponentCard'
import SessionTrendsPanel from './SessionTrendsPanel'

const COMPONENT_SKELETON_HEIGHT = 500

interface SessionSummaryTrendsProps {
  filter: SessionFilter
}

export default function SessionsSummaryTrends(
  props: SessionSummaryTrendsProps
): JSX.Element {
  // Fetch data
  const { data, loading, error } = useSessionsSummaryTrendsQuery({
    variables: {
      filter: props.filter,
    },
  })
  const sessionsSummaryTrends = data?.sessionsSummaryTrends || []

  // Handle Error
  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized
          message={error.toString()}
          imageName={'SessionsSummaryTrends'}
        />
      )
    }
    return <div>{error.toString()}</div>
  }

  // Render
  return (
    <ComponentCard
      result={{ data: sessionsSummaryTrends, error, loading }}
      skeletonHeight={COMPONENT_SKELETON_HEIGHT}
    >
      <SessionTrendsPanel data={sessionsSummaryTrends} isIndustry={false} />
    </ComponentCard>
  )
}
