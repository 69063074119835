import React from 'react'
import { styled } from '@mui/material/styles'
import Modal from '@mui/material/Modal'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'

const PREFIX = 'ProgressModal'

const classes = {
  paper: `${PREFIX}-paper`,
  modal: `${PREFIX}-modal`,
}

const ModalStyled = styled(Modal)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1),
    width: theme.spacing(30),
  },

  [`& .${classes.modal}`]: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}))

type ProgressModalProps = {
  title: string
  open: boolean
}

export default function ProgressModal({
  title,
  open,
}: ProgressModalProps): JSX.Element {
  return (
    <ModalStyled
      // `disableBackdropClick` is removed by codemod.
      // You can find more details about this breaking change in [the migration guide](https://mui.com/guides/migration-v4/#modal)
      aria-labelledby="progress-modal-dialog"
      aria-describedby="progress-dialog"
      open={open}
    >
      <div className={clsx(classes.paper, classes.modal)}>
        <LinearProgress />
        <Typography variant="subtitle1">{title}</Typography>
      </div>
    </ModalStyled>
  )
}
