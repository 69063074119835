import React, { useRef, useLayoutEffect } from 'react'
import { ClientIdAndName, Scheme } from 'graphql/gen-types'
import { SchemeSwatch } from '@anewgo/interactive-exterior-ng'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { CustomXAxisTickStyled, classes } from './CustomXAxisTick.styles'

export interface CustomXAxisTickProps {
  data: Array<Scheme | null> | undefined
  client?: ClientIdAndName
  onHeightChange(height: number): void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [prop: string]: any //for properties provided by recharts
}

export function CustomXAxisTick(props: CustomXAxisTickProps): JSX.Element {
  const {
    x: tickX,
    y: tickY,
    width: chartWidth,
    payload,
    data,
    client,
    onHeightChange,
  } = props

  const targetRef = useRef<HTMLDivElement>(null)

  // prevent too many re-renders
  let resizeTimer: ReturnType<typeof setTimeout>
  const RESET_TIMEOUT = 100

  const filteredData = data?.filter((datum) => datum !== null) || []
  const datum = filteredData.find((datum) => datum?.id === payload?.value)

  const insetWidth = (chartWidth / filteredData.length) * 0.7 // 70% of tick width
  const insetHeight = (insetWidth * 9) / 16 // 16:9 ratio

  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down('sm'))

  //set XAxis height after first render
  useLayoutEffect(() => {
    onResize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // recalcualte XAxis height depending on size
  const onResize = (): void => {
    if (targetRef?.current) {
      onHeightChange(targetRef.current?.offsetHeight || 0)
    }
  }

  //run onResize when browser is resized
  window.addEventListener('resize', () => {
    clearInterval(resizeTimer)
    resizeTimer = setTimeout(onResize, RESET_TIMEOUT)
  })

  return (
    <CustomXAxisTickStyled
      transform={`translate(${tickX - insetWidth / 2},${tickY})`}
      height="100%"
    >
      <foreignObject
        width={insetWidth}
        height="100%"
        xmlns="http://www.w3.org/1999/xhtml"
      >
        <div ref={targetRef}>
          <div style={{ height: insetHeight }}>
            <SchemeSwatch
              directoryName={client?.directoryName}
              materials={datum?.materials || []}
            />
          </div>
          {!xs && (
            <Typography className={classes.typography}>
              Scheme: {datum?.name}
            </Typography>
          )}
        </div>
      </foreignObject>
    </CustomXAxisTickStyled>
  )
}
