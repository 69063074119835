import React, { useState, useEffect } from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertProps } from '@mui/material/Alert'

function Alert(props: AlertProps): JSX.Element {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export const SubscriptionLevelInvalidNotif = (): JSX.Element => {
  // state management constants
  const [
    subscriptionErrorSnackBarOpen,
    setSubscriptionErrorSnackBarOpen,
  ] = useState<boolean>(false)
  const [
    susbcriptionErrorMessage,
    setSubscriptionErrorMessage,
  ] = useState<string>('')

  // Susbcription Level Error snackbar useEffect.
  // this mostly is trigerred when a user hard typed the url which they can't access due to insufficient restriction.
  // Look for `if (!PRO_OR_ENTERPRISE_SUBSCRIBED)` in Locations.tsx for example.
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const subscriptionLevelErrorMessage =
      urlParams.get('subscriptionLevelErrorMessage') || ''

    // if we have an error message, open snackbar.
    if (subscriptionLevelErrorMessage.length) {
      setSubscriptionErrorSnackBarOpen(true)
      setSubscriptionErrorMessage(subscriptionLevelErrorMessage)
    }
  }, [])

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={subscriptionErrorSnackBarOpen}
      onClose={() => setSubscriptionErrorSnackBarOpen(false)}
      autoHideDuration={6000}
    >
      <Alert
        onClose={() => setSubscriptionErrorSnackBarOpen(false)}
        severity="error"
      >
        {susbcriptionErrorMessage}
      </Alert>
    </Snackbar>
  )
}
