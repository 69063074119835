import { gql } from '@apollo/client'

export const REQUEST_SUBSCRIPTION_UPGRADE = gql`
  mutation REQUEST_SUBSCRIPTION_UPGRADE(
    $callerUserId: Int!
    $requestedSubscriptionLevel: ColonnadeSubscriptionLevelsEnum!
  ) {
    requestSubscriptionUpgrade(
      callerUserId: $callerUserId
      requestedSubscriptionLevel: $requestedSubscriptionLevel
    )
  }
`
