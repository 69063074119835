import { ProspectReservation } from '../../../graphql/gen-types'

export const isReservationDone = (
  reservation: ProspectReservation
): boolean => {
  return (
    !!reservation.paymentDate &&
    !!reservation.signatureDate &&
    !!reservation.secondaryBuyerReservations?.every(
      (reservation) => !!reservation.signatureDate
    )
  )
}
