import { gql } from '@apollo/client'
import { cityFieldsMinimal } from '../../../../graphql/nexus-queries/city'

export const ADD_HOME_SELECTION = gql`
  mutation AddHomeSelection($clientName: String!, $input: HomeSelectionInput!) {
    addHomeSelection(clientName: $clientName, input: $input)
  }
`

export const CLIENT_REQUIRES_REGISTRATION = gql`
  query RequireRegistration($clientName: String!) {
    customization(clientName: $clientName, name: "requireRegister") {
      id
      name
      value
    }
  }
`

export const COMMUNITY_LITE_2 = gql`
  query GetCommunityByNameLite2($clientName: String!, $communityName: String!) {
    communityByName(clientName: $clientName, communityName: $communityName) {
      id
      name
      thumb
      bonafide
      buildYourLot
      caption
      colormtd
      description
      pricing
      logo
      longitude
      latitude
      address
      sortType
      sortOrder
      cityLocation(clientName: $clientName) {
        ...CityFieldsMinimal
      }
      agents(clientName: $clientName) {
        id
        email
        phone
        firstName
        lastName
      }
      siteplans(clientName: $clientName, active: true) {
        id
        communityId
      }
      primarySiteplan(clientName: $clientName, active: true) {
        id
        lotMetric
      }
      imgs(clientName: $clientName) {
        id
        src
      }
      stdFeatureCategories(clientName: $clientName) {
        id
        name
        features(clientName: $clientName, communityName: $communityName) {
          id
          name
        }
      }
    }
  }
  ${cityFieldsMinimal}
`

export const ELEVATION_COLORS = gql`
  query GetElevationLayers(
    $clientName: String!
    $elevationId: Int!
    $planId: Int!
    $communityId: Int
  ) {
    elevation(
      clientName: $clientName
      elevationId: $elevationId
      planId: $planId
      communityId: $communityId
    ) {
      id
      planId
      caption
      communityId
      base
      thumb
      layers(clientName: $clientName) {
        materialId
        name
        src
      }
      materialPalettes(
        clientName: $clientName
        planId: $planId
        communityId: $communityId
      ) {
        communityId
        planId
        elevationId
        materialId
        materialCategory
        name
        palettes {
          id
          name
          selections(clientName: $clientName) {
            id
            name
            hex
            swatch
            type
            masonryLib
            colorId
            brickId
            stoneId
            customOverlayId
          }
        }
      }
      schemes(
        clientName: $clientName
        planId: $planId
        communityId: $communityId
      ) {
        id
        name
        materials(clientName: $clientName) {
          id
          materialId
          materialCategory
          material
          name
          hex
          type
          swatch
          masonryLib
          colorId
          brickId
          stoneId
          customOverlayId
          paletteIds(clientName: $clientName, communityId: $communityId)
        }
      }
    }
  }
`

export const HOME_SELECTION = gql`
  query GetHomeSelection(
    $clientName: String!
    $prospectIdentifier: String!
    $homeIdentifier: String!
  ) {
    getHomeSelection(
      clientName: $clientName
      prospectIdentifier: $prospectIdentifier
      homeIdentifier: $homeIdentifier
    ) {
      json
    }
  }
`

export const HOME_SELECTION_BY_FAVORITE_ID = gql`
  query GetHomeSelectionByFavoriteId(
    $clientName: String!
    $favoriteIdentifier: Int
  ) {
    getHomeSelection(
      clientName: $clientName
      favoriteIdentifier: $favoriteIdentifier
    ) {
      json
    }
  }
`

export const COMMUNITY_INTERIORS_WITH_OPTIONS = gql`
  query interiors($clientName: String!, $interior: InteriorInput!) {
    interiors(clientName: $clientName, interior: $interior) {
      id
      name
      baseImageSrc
      disclaimer
      elementTrees {
        id
        name
        options {
          id
          name
          overlaySwatchSrc
          hex
          cost
        }
      }
    }
  }
`

export const PLAN = gql`
  query GetPlanByName(
    $clientName: String!
    $communityName: String!
    $planName: String!
    $active: Boolean
  ) {
    planByName(
      clientName: $clientName
      communityName: $communityName
      planName: $planName
    ) {
      ...PlanFields
      exteriorAssets(clientName: $clientName) {
        src
        listIndex
        videoSrc
      }
      interiorPhotos(clientName: $clientName) {
        src
        listIndex
      }
      elevations(clientName: $clientName, active: $active) {
        ...ElevationFields
        collection(clientName: $clientName) {
          id
          communityId
          name
          siteplan(clientName: $clientName, active: true) {
            id
            name
          }
        }
        communities(
          clientName: $clientName
          excludeDefaultCommunity: true
          active: true
        ) {
          ...CommunityFields
        }
      }
    }
  }
`

export const STORIES_BY_ELEVATION = gql`
  query GetFloorplansByElevationId(
    $clientName: String!
    $communityId: Int!
    $planId: Int!
    $elevationId: Int!
  ) {
    floorplansByElevationId(
      clientName: $clientName
      communityId: $communityId
      planId: $planId
      elevationId: $elevationId
    ) {
      planId
      communityId
      elevationId
      defaultFloor
      stories {
        id
        communityId
        planId
        elevationId
        name
        fnum
        optional
        floorplans(clientName: $clientName) {
          id
          groupId
          name
          base
          optional
          standard
          cost
          size
          bed
          bath
          listIndex
          src
          src2
          labelSvg
          fnum
        }
        fpGroups(clientName: $clientName) {
          id
          name
          groupType
          primary(clientName: $clientName) {
            id
            groupId
            standard
            name
          }
          members(clientName: $clientName) {
            id
            name
            groupId
            standard
            fnum
            alternate(clientName: $clientName) {
              id
              src
              src2
              labelSvg
              cost
              size
              bed
              bath
            }
          }
        }
      }
    }
  }
`

export const CLIENT_BY_ID = gql`
  query GetClientById($clientId: Int!) {
    clientById(clientId: $clientId) {
      id
      name
      altName
      directoryName
      disclaimer
      trackingId
      logo
      website
    }
  }
`

export const CLIENT_BY_NAME = gql`
  query GetClient($clientName: String!) {
    clientByName(clientName: $clientName) {
      id
      name
      altName
      directoryName
      disclaimer
      trackingId
      logo
      website
      sgtVendors(clientName: $clientName) {
        id
        icon
        logo
        name
        extendedData
      }
    }
  }
`

export const BUILDER_APP_CONFIG = gql`
  query GetBuilderAppConfig($clientName: String!) {
    builderAppConfig(clientName: $clientName)
    clientCustomizations(clientName: $clientName) {
      id
      name
      value
    }
  }
`

export const LOT = gql`
  query GetLotWithInventory($clientName: String!, $lotId: Int!) {
    lot(clientName: $clientName, lotId: $lotId) {
      ...LotFields
      inventory(clientName: $clientName) {
        ...InventoryFields
        virTourUrl
        photos(clientName: $clientName) {
          id
          src
          listIndex
        }
        mlsPhotos
        includedFloorplans(clientName: $clientName) {
          id
          groupId
          standard
          name
        }
        plan(clientName: $clientName) {
          ...PlanFields
        }
        elevation(clientName: $clientName) {
          ...ElevationFields
        }
      }
    }
  }
`

export const REQUEST_SGT_APPOINTMENT = gql`
  mutation RequestSgtAppointments(
    $clientName: String!
    $prospectSgtApptInput: ProspectSgtApptInput!
    $timezoneOffset: Int!
  ) {
    requestSgtAppointment(
      clientName: $clientName
      prospectSgtApptInput: $prospectSgtApptInput
      timezoneOffset: $timezoneOffset
    ) {
      id
      prospectId
      inventoryId
      preferredDate
      createdOn
    }
  }
`

export const GET_PROSPECT_SGT_APPOINTMENTS = gql`
  query GetProspectSgtAppointments(
    $clientName: String!
    $prospectId: Int!
    $inventoryId: Int!
  ) {
    prospectSgtAppointments(
      clientName: $clientName
      prospectId: $prospectId
      inventoryId: $inventoryId
    ) {
      preferredDate
    }
  }
`

export const COMMUNITY_NAME = gql`
  query GetCommunityName($clientName: String!, $communityId: Int!) {
    community(clientName: $clientName, communityId: $communityId) {
      id
      name
    }
  }
`

export const ELEVATION_STORIES_ONLY = gql`
  query GetElevationWithStoriesOnly(
    $clientName: String!
    $communityId: Int
    $planId: Int
    $elevationId: Int!
  ) {
    elevation(
      clientName: $clientName
      communityId: $communityId
      planId: $planId
      elevationId: $elevationId
    ) {
      id
      communityId
      planId
      stories(clientName: $clientName) {
        id
        communityId
        planId
        elevationId
        name
        fnum
        optional
      }
    }
  }
`

export const LOT_INVENTORY_EXTERIOR_ONLY = gql`
  query LotWithInventoryExteriorConfigurationOnly(
    $clientName: String!
    $lotId: Int!
  ) {
    lot(clientName: $clientName, lotId: $lotId) {
      id
      inventory(clientName: $clientName) {
        id
        exteriorConfiguration(clientName: $clientName) {
          inventoryId
          scheme {
            id
            name
            materials(clientName: $clientName) {
              id
              materialId
              materialCategory
              material
              name
              hex
              type
              swatch
              masonryLib
              colorId
              brickId
              stoneId
              customOverlayId
            }
          }
          paletteElementSelections {
            inventoryId
            elementId
            paletteId
            paletteSelection {
              id
            }
          }
        }
      }
    }
  }
`

export const LOT_SITEPLAN_INFO = gql`
  query GetLotWithSiteplanInfo($clientName: String!, $lotId: Int!) {
    lot(clientName: $clientName, lotId: $lotId) {
      ...LotFields
      siteplanName(clientName: $clientName)
      siteplanInfo {
        lotId
        siteplanId
        x
        y
      }
    }
  }
`

export const SCHEME = gql`
  query GetScheme($clientName: String!, $communityId: Int, $schemeId: Int!) {
    scheme(
      clientName: $clientName
      communityId: $communityId
      schemeId: $schemeId
    ) {
      id
      name
      materials(clientName: $clientName) {
        id
        materialId
        materialCategory
        material
        name
        hex
        type
        swatch
        masonryLib
        colorId
        brickId
        stoneId
        customOverlayId
      }
    }
  }
`

export const SITEPLAN_LITE = gql`
  query GetSiteplanLite($clientName: String!, $id: Int!) {
    siteplanById(clientName: $clientName, id: $id) {
      id
      name
      lotFontSize
      lotMetric
      lotWidth
      lotHeight
      master
      src
      ...SiteplanSVGFields
      lotLegend(clientName: $clientName) {
        id
        code
        name
        hex
      }
      hotspots {
        id
        siteplanId
        name
        x
        y
        description
        thumb
        assets {
          id
          listIndex
          src
          description
        }
      }
    }
  }
`
