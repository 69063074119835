import React, { ReactElement } from 'react'
import { PopularityMetric } from '../../../../graphql/gen-types'
import PopularityMetricSelect from '../../../common/custom-inputs/PopularityMetricSelect'
import { SetState } from '../Shared'

interface PopularityMetricFilterArgs {
  popularityMetric: PopularityMetric
  setPopularityMetric: SetState<PopularityMetric>
  onSelectedPopularityMetric?: (metric: PopularityMetric) => void
}

export default function PopularityMetricFilter({
  popularityMetric,
  setPopularityMetric,
  onSelectedPopularityMetric,
}: PopularityMetricFilterArgs): ReactElement | null {
  return (
    <PopularityMetricSelect
      metric={popularityMetric}
      setMetric={(selectedMetric) => {
        setPopularityMetric(selectedMetric)
        onSelectedPopularityMetric?.(selectedMetric)
      }}
      defaultAvailableMetrics={[
        PopularityMetric.Frequency,
        PopularityMetric.Duration,
      ]}
    />
  )
}
