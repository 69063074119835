import * as React from 'react'
import Grid from '@mui/material/Grid'

import { AppStore } from 'store'

import { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { CommonQueryFilter } from 'graphql/gen-types'
import LeadsByProduct from 'components/leads/leads-by-product/LeadsByProduct'
import EmailCampaigns from 'components/leads/leads-by-product/EmailCampaigns'
import { userHasSubscriptionAccess } from 'utils/authorizationHelpers'
import { ColonnadeSubscriptionLevelsEnum } from '../graphql/gen-types'
import { STARTER_INVALID_FEATURE_MESSAGE } from '../constants'
import { classes, Root } from './MarketingAutomation.styles'
const GRID_SPACING = 2

function MarketingAutomation(): JSX.Element {
  const { appState } = useContext(AppStore)
  const { selectedClient } = appState
  const clientName = selectedClient?.altName || ''

  const PRO_OR_ENTERPRISE_SUBSCRIBED = userHasSubscriptionAccess([
    ColonnadeSubscriptionLevelsEnum.Pro,
    ColonnadeSubscriptionLevelsEnum.Enterprise,
  ])

  const commonFilter: CommonQueryFilter = {
    clientName: clientName,
    limit: 10000,
    startTime: new Date().getTime() - 86400 * 30 * 6 * 1000, // 6 months of range, in milliseconds
    endTime: new Date().getTime(),
  }

  if (!PRO_OR_ENTERPRISE_SUBSCRIBED) {
    return (
      <Navigate
        to={`/analytics/client/${clientName}/overview?subscriptionLevelErrorMessage=${STARTER_INVALID_FEATURE_MESSAGE}`}
      />
    )
  }

  return (
    <Root className={classes.home}>
      <Grid
        container
        className={classes.componentContainer}
        spacing={GRID_SPACING}
      >
        <Grid item xs={12}>
          <LeadsByProduct filter={commonFilter} />
        </Grid>
        <Grid item xs={12}>
          <EmailCampaigns />
        </Grid>
      </Grid>
    </Root>
  )
}

export default MarketingAutomation
