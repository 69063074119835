import React, { useReducer } from 'react'
// import { useSubscription } from '@apollo/client'
import BaseLayout from 'components/common/layout/BaseLayout'
import { LeadsStore, initialState as initialLeadsState } from 'store/leadsStore'
import {
  AnalyticsStore,
  initialState as initialAnalyticsState,
} from 'store/analyticsStore'
import { analyticsReducer } from 'store/reducers'
import { leadsReducer } from 'store/reducers'
import { useMatch } from 'react-router-dom'

function MarketingApp(): JSX.Element | null {
  const [leadsState, dispatch] = useReducer(leadsReducer, initialLeadsState)
  const [analyticsState, analyticsDispatch] = useReducer(
    analyticsReducer,
    initialAnalyticsState
  )
  const value = { leadsState, dispatch }
  const marketingMatchPath = useMatch('targeted-marketing/client/:clientName/*')

  if (!marketingMatchPath) {
    return null
  }
  return (
    <React.Fragment>
      <AnalyticsStore.Provider
        value={{ analyticsState, dispatch: analyticsDispatch }}
      >
        <LeadsStore.Provider value={value}>
          <BaseLayout />
        </LeadsStore.Provider>
      </AnalyticsStore.Provider>
    </React.Fragment>
  )
}

export default MarketingApp
