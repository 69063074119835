import React, { CSSProperties } from 'react'
import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Alert from '@mui/material/Alert'

const PREFIX = 'ComponentCard'

const classes = {
  root: `${PREFIX}-root`,
  disabledRoot: `${PREFIX}-disabledRoot`,
  subtitle: `${PREFIX}-subtitle`,
  title: `${PREFIX}-title`,
  reportTitle: `${PREFIX}-reportTitle`,
  message: `${PREFIX}-message`,
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    [theme.breakpoints.down('md')]: {
      padding: 8,
    },
    flexGrow: 1,
    height: '100%',
    width: '100%',
    textAlign: 'center',
  },

  [`&.${classes.disabledRoot}`]: {
    padding: 24,
    [theme.breakpoints.down('md')]: {
      padding: 8,
    },
    flexGrow: 1,
    height: '100%',
    width: '100%',
    textAlign: 'center',
    color:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.5) !important'
        : 'rgba(0, 0, 0, 0.38) !important',
  },

  [`& .${classes.subtitle}`]: {
    fontWeight: 500,
    padding: '0px 0px 16px 16px',
    marginLeft: 8,
    marginTop: '-12px',
    textAlign: 'left',
  },

  [`& .${classes.title}`]: {
    fontWeight: 500,
    padding: 16,
    marginLeft: 8,
    textAlign: 'left',
  },

  [`& .${classes.reportTitle}`]: {
    fontWeight: 500,
    marginLeft: 8,
    textAlign: 'left',
  },

  [`& .${classes.message}`]: {
    paddingBottom: 16,
  },
}))

type QueryResult = {
  data?: unknown[] | undefined | null
  loading: boolean | undefined
  error: Error | undefined
}

type ComponentCardProps = {
  title?: JSX.Element | string
  titleStyle?: CSSProperties
  subtitle?: JSX.Element | string
  alert?: {
    severity: 'error' | 'warning' | 'info' | 'success'
    message: JSX.Element | string
  }
  message?: JSX.Element | string
  height?: number | string
  width?: number
  children?: React.ReactNode | React.ReactNode[]
  style?: CSSProperties
  result?: QueryResult | undefined
  skeletonHeight?: number
  disabled?: boolean | false
  className?: any
  report?: boolean
}

/**
 * The ComponentCard is a Paper wrapper for all components, intended for use in displaying most data within Colonnade.
 * To use as a container, pass the title and child elements to be displayed.
 * To display a message, such as an error message, Loading text, or other message, provide a value for message.
 * This component can also automatically render Loading / Error / No Data cards. To do this, provide a query result (in the shape of {data, loading, error}) to result.
 *  - This will conditionally render loading, error, no data, or populated data (the child element) result. If result is not provided, it will just act as a container and always render the child.
 * @param title - Title of the Paper component
 * @param subtitle - Subtitle of the Paper component
 * @param alert - MUI ALert will be displayed in the top of the Paper component, with severity and text.
 * @param message - Optional message. Can be string (reccomended) or JSX.Element.
 * @param height - Max height
 * @param children - children components
 * @param result - Query result in the shape of {data, loading, error}. If provided, the Card will conditionally render with default Loading / Error / No Data / Normally depending on the values of the result.
 */
export default function ComponentCard({
  title,
  titleStyle,
  subtitle,
  alert,
  message,
  height,
  width,
  children,
  style,
  result,
  skeletonHeight,
  disabled,
  report,
}: ComponentCardProps): JSX.Element {
  // If results are passed, assume the component should display it's stock state
  if (result && returnStockComponentCard(result)) {
    return (
      <StockComponentCard
        title={title}
        subtitle={subtitle}
        result={result}
        skeletonHeight={skeletonHeight}
      />
    )
  }

  return (
    <Box height={height} width={width}>
      <StyledPaper
        style={style}
        className={disabled ? classes.disabledRoot : classes.root}
      >
        {alert && <Alert severity={alert.severity}>{alert.message}</Alert>}
        {title && (
          <Typography
            className={report ? classes.reportTitle : classes.title}
            variant="h5"
            color={disabled ? undefined : 'primary'}
            style={titleStyle}
          >
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography
            className={classes.subtitle}
            variant="subtitle2"
            color="textSecondary"
          >
            {subtitle}
          </Typography>
        )}
        {message && (
          <Typography className={classes.message} variant="h6">
            {message}
          </Typography>
        )}
        {children}
      </StyledPaper>
    </Box>
  )
}

function returnStockComponentCard({
  data,
  loading,
  error,
}: QueryResult): boolean {
  return loading || !!error || (!!data && data.length === 0)
}

function StockComponentCard({
  title,
  subtitle,
  result,
  skeletonHeight,
}: {
  title: JSX.Element | string | undefined
  subtitle: JSX.Element | string | undefined
  result: QueryResult
  skeletonHeight: number | string | undefined
}): JSX.Element {
  const { data, loading, error } = result
  // Return the component with loading, error, or 0 result
  if (loading) {
    return (
      <ComponentCard title={title} subtitle={subtitle}>
        <Skeleton
          variant="rectangular"
          width={'100%'}
          height={skeletonHeight || 400}
        />
      </ComponentCard>
    )
  } else if (error) {
    return (
      <ComponentCard
        title={title}
        subtitle={subtitle}
        message={error.toString()}
      ></ComponentCard>
    )
  } else if (data && data?.length === 0) {
    return (
      <ComponentCard
        title={title}
        subtitle={subtitle}
        message="No Data to Display."
      ></ComponentCard>
    )
  }
  // If unexpected result comes
  return (
    <ComponentCard
      title={title}
      subtitle={subtitle}
      message="Unexpected error occured. Please report or try again later."
    ></ComponentCard>
  )
}
