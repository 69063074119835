import React from 'react'
import Backdrop from '@mui/material/Backdrop'
import hydrationStore from '../store/HydrationStore'
import { classes, StyledModal, StyledDiv } from './TokenExpiredModal.styles'

export default function TokenExpiredModal({
  message,
  timer,
}: {
  message?: string
  timer?: number
}): JSX.Element {
  setTimeout(() => {
    hydrationStore.clearAll()
    window.location.reload()
  }, timer || 3000)

  return (
    <div>
      <StyledModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={true}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <StyledDiv className={classes.paper}>
          {message || 'Your login token has expired.'} Please log in again. You
          will be redirected in {timer ? timer / 1000 : 3} seconds.
        </StyledDiv>
      </StyledModal>
    </div>
  )
}
