import { styled } from '@mui/material/styles'
import { FILTER_BAR_DOUBLE_HEIGHT } from 'components/common/filter-bar/FilterBar.styles'
import { MAIN_APP_BAR_HEIGHT } from '../../constants'

const LOCATION_FILTER_BAR_HEIGHT = FILTER_BAR_DOUBLE_HEIGHT + 5
const LOCATION_FILTER_BAR_HEIGHT_XS = 52 // Account for larger multi select. The way we handle xs must be changed (moved to modal)

const PREFIX = 'IndustryTrends'

export const classes = {
  root: `${PREFIX}-root`,
  filters: `${PREFIX}-filters`,
  formControl: `${PREFIX}-formControl`,
  chips: `${PREFIX}-chips`,
  chip: `${PREFIX}-chip`,
  noLabel: `${PREFIX}-noLabel`,
  selectCountries: `${PREFIX}-selectCountries`,
  selectCities: `${PREFIX}-selectCities`,
  selectCitiesLarger: `${PREFIX}-selectCitiesLarger`,
  selectCounty: `${PREFIX}-selectCounty`,
  selectPostCode: `${PREFIX}-selectPostCode`,
  selectRegion: `${PREFIX}-selectRegion`,
  selectRegionLarger: `${PREFIX}-selectRegionLarger`,
  selectState: `${PREFIX}-selectState`,
  selectZip: `${PREFIX}-selectZip`,
  componentContainer: `${PREFIX}-componentContainer`,
  FloorPlanOptionChart: `${PREFIX}-FloorPlanOptionChart`,
}

export const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',
    position: 'relative',
    width: '100%',
    // This is done to make space for the expanding Locations Filter. This shoud be
    // done in a better way, but with the expanding size of the filter appBar I do not know how.
    // Perhaps always have the filter in a modal, and the bar has a button to open it only
    height: `calc(100vh - ${
      MAIN_APP_BAR_HEIGHT + LOCATION_FILTER_BAR_HEIGHT
    }px)`,
    [theme.breakpoints.down('lg')]: {
      height: `calc(100vh - ${
        MAIN_APP_BAR_HEIGHT + LOCATION_FILTER_BAR_HEIGHT_XS
      }px)`,
    },
    paddingTop: '24px',
  },

  [`& .${classes.filters}`]: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.formControl}`]: {
    margin: `0px ${theme.spacing(1)}`,
    minWidth: 130,
    maxWidth: 300,
  },

  [`& .${classes.chips}`]: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  [`& .${classes.chip}`]: {
    margin: 2,
  },

  [`& .${classes.noLabel}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.selectCountries}`]: {
    minWidth: 150,
    maxWidth: 160,
  },

  [`& .${classes.selectCities}`]: {
    maxWidth: 220,
  },

  [`& .${classes.selectCitiesLarger}`]: {
    minWidth: 150,
  },

  [`& .${classes.selectCounty}`]: {
    minWidth: 150,
    maxWidth: 200,
  },

  [`& .${classes.selectPostCode}`]: {
    minWidth: 150,
    maxWidth: 200,
  },

  [`& .${classes.selectRegion}`]: {
    maxWidth: 200,
  },

  [`& .${classes.selectRegionLarger}`]: {
    minWidth: 150,
    maxWidth: 200,
  },

  [`& .${classes.selectState}`]: {
    minWidth: 150,
    maxWidth: 200,
  },

  [`& .${classes.selectZip}`]: {
    minWidth: 150,
    maxWidth: 200,
  },

  [`& .${classes.componentContainer}`]: {
    marginTop: '1em',
    marginBottom: '1em',
    padding: '2em',
    [theme.breakpoints.down('md')]: {
      padding: '0.5em',
    },
    justifyContent: 'center',
  },

  [`& .${classes.FloorPlanOptionChart}`]: {
    paddingTop: '20px',
  },
}))
