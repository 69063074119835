import React, { useContext, useState } from 'react'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import ButtonGroup from '@mui/material/ButtonGroup'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import { useRestoreDeletedOnlineReservationMutation } from '../../../graphql/gen-types'
import { AppStore } from '../../../store'
import { gql } from '@apollo/client'
import { useEventTracker } from '../../../hooks/tracking'
import { ReservationTrackEvents } from '../reservationTrackEvents'
import {
  classes,
  StyledPopover,
} from './ReservationsRestoreDeletionPopoverStyles'

interface ReservationsRestoreDeletionPopoverArgs {
  popoverAnchor: (EventTarget & SVGSVGElement) | null
  closeRestoreDeletedPopOver: () => void
  reservationIdToRestore: string | null
}
const ReservationsRestoreDeletionPopover: React.FC<ReservationsRestoreDeletionPopoverArgs> = ({
  popoverAnchor,
  closeRestoreDeletedPopOver,
  reservationIdToRestore,
}): JSX.Element => {
  const { appState } = useContext(AppStore)
  const track = useEventTracker()
  const [snackBarState, setSnackBarState] = useState<{
    type: 'success' | 'error'
    message: string
  } | null>()
  const clientName = appState.selectedClient?.altName || ''
  const [
    restoreDeletedOnlineReservation,
  ] = useRestoreDeletedOnlineReservationMutation()

  const handleRestoreReservation = async (reservationId: string | null) => {
    try {
      track(
        ReservationTrackEvents.CLICKED_YES_IN_DELETION_RESTORATION_POPOVER,
        {
          reservationId: reservationIdToRestore,
        }
      )
      const result = await restoreDeletedOnlineReservation({
        variables: {
          clientName,
          reservationId,
        },
        update(cache, { data }) {
          cache.writeFragment({
            id: `ProspectReservation:${data?.restoreDeletedOnlineReservation}`,
            fragment: gql`
              fragment UpdateProspectReservation on ProspectReservation {
                isDeleted
              }
            `,
            data: {
              isDeleted: false,
            },
          })
        },
      })

      closeRestoreDeletedPopOver()

      const { restoreDeletedOnlineReservation: resId } = result?.data || {}

      if (resId) {
        setSnackBarState({
          type: 'success',
          message: `Reservation ${reservationId} was successfully restored!`,
        })
        return
      }

      setSnackBarState({
        type: 'error',
        message: `Reservation ${reservationId} does not exist!`,
      })
    } catch (e) {
      setSnackBarState({
        type: 'error',
        message: `Could not restore reservation!`,
      })
      console.error(e)
    }
  }

  const handleSnackBarClose = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackBarState(null)
  }

  const handleClickCancelButton = () => {
    track(
      ReservationTrackEvents.CLICKED_CANCEL_IN_DELETION_RESTORATION_POPOVER,
      {
        reservationId: reservationIdToRestore,
      }
    )
    closeRestoreDeletedPopOver()
  }

  return (
    <>
      <Snackbar
        autoHideDuration={6000}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        onClose={handleSnackBarClose}
        open={!!snackBarState}
      >
        <div>
          {snackBarState && (
            <Alert severity={snackBarState.type}>{snackBarState.message}</Alert>
          )}
        </div>
      </Snackbar>
      <StyledPopover open={Boolean(popoverAnchor)} anchorEl={popoverAnchor}>
        <div className={classes.restoreDeletedPopoverContext}>
          <Typography variant="body1">
            Are you sure you want to restore this reservation from deleted?
          </Typography>
          <ButtonGroup className={classes.restoreDeletedPopoverButtonHolder}>
            <Button onClick={handleClickCancelButton}>Cancel</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleRestoreReservation(reservationIdToRestore)}
            >
              Yes
            </Button>
          </ButtonGroup>
        </div>
      </StyledPopover>
    </>
  )
}

export default ReservationsRestoreDeletionPopover
