import React, { useEffect, useState } from 'react'
import { Plan, Elevation } from 'graphql/gen-types'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import Input from '@mui/material/Input'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import { PlanProduct } from '../SelectProductModal'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

interface ElevationMultiSelectProps {
  plan: Plan
  elevations: Elevation[]
  selectedElevations: Elevation[]
  onSelectPlanProduct?: (planProduct: PlanProduct) => void
}
export default function ElevationMultiSelect({
  plan,
  elevations,
  selectedElevations,
  onSelectPlanProduct,
}: ElevationMultiSelectProps): JSX.Element {
  const [selected, setSelected] = useState<string[]>([])
  const elevCaptions = elevations.map((e) => e.caption || '')
  const getSelectedElevations = (selected: string[]) =>
    elevations.filter((e) => selected.includes(e?.caption || ''))

  useEffect(() => {
    const selectedElevationCaptions = selectedElevations.map(
      (e) => e.caption || ''
    )
    setSelected(selectedElevationCaptions)
  }, [selectedElevations])

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const values: string[] = event.target.value as string[]
    const selectAllIndex = values.indexOf('select-all')

    let selectValues = values
    // if click select-all
    if (selectAllIndex !== -1) {
      // remove select-all string
      values.splice(selectAllIndex, 1)
      // select / deselect all
      if (selected.length === elevCaptions.length) {
        selectValues = []
      } else {
        selectValues = elevCaptions
      }
      // Handle normal select/deselect of elevation
    } else {
      selectValues = values
    }
    setSelected(selectValues)
    onSelectPlanProduct &&
      onSelectPlanProduct({
        plan: plan,
        elevations: getSelectedElevations(selectValues),
      })
  }

  const selectedLabel = (selected: string[]) => {
    return `${selected[0]}${
      selected.length > 1 ? ` and ${selected.length - 1} others` : ''
    }`
  }

  return (
    <FormControl>
      <InputLabel id="demo-mutiple-checkbox-label">Elevations</InputLabel>
      <Select
        variant="standard"
        style={{ minWidth: 180 }}
        // labelId="demo-mutiple-checkbox-label"
        multiple
        value={selected}
        onChange={handleChange}
        input={<Input />}
        renderValue={(selected) => selectedLabel(selected as string[])}
        MenuProps={MenuProps}
      >
        <MenuItem key={'select-all'} value={'select-all'}>
          <Checkbox checked={selected.length === elevCaptions.length} />
          <ListItemText primary="Select All" />
        </MenuItem>
        <Divider variant="fullWidth" component="li" />
        {elevCaptions.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={selected.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
