import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'

const PREFIX = 'ProspectProfile'

export const classes = {
  container: `${PREFIX}-container`,
  itemContainer: `${PREFIX}-itemContainer`,
  item: `${PREFIX}-item`,
  gridRoot: `${PREFIX}-gridRoot`,
  title: `${PREFIX}-title`,
  list: `${PREFIX}-list`,
  checkboxList: `${PREFIX}-checkboxList`,
  avatarItem: `${PREFIX}-avatarItem`,
  avatar: `${PREFIX}-avatar`,
  check: `${PREFIX}-check`,
  rankIcon: `${PREFIX}-rankIcon`,
  pointer: `${PREFIX}-pointer`,
  statusListItem: `${PREFIX}-statusListItem`,
  clientRating: `${PREFIX}-clientRating`,
  label: `${PREFIX}-label`,
  hotLeadStatus: `${PREFIX}-hotLeadStatus`,
  stats: `${PREFIX}-stats`,
  value: `${PREFIX}-value`,
}

export const ProspectProfileStyled = styled(Paper)(({ theme }) => ({
  [`&.${classes.container}`]: { padding: 16 },

  [`& .${classes.itemContainer}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },

  [`& .${classes.item}`]: {
    padding: '8px 16px 8px 16px',
  },

  [`& .${classes.gridRoot}`]: {
    flexGrow: 1,
    paddingLeft: '1em',
    height: '90%',
  },

  [`& .${classes.title}`]: {
    textAlign: 'center',
  },

  [`& .${classes.list}`]: {
    '& li': {
      paddingTop: 2,
      paddingBottom: 0,
    },
  },

  [`& .${classes.checkboxList}`]: {
    '& li': {
      paddingLeft: 0,
      paddingBottom: 0,
      paddingTop: 0,
    },
    '& div': {
      margin: 2,
    },
  },

  [`& .${classes.avatarItem}`]: {
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
    },
  },

  [`& .${classes.avatar}`]: {
    width: 60,
    height: 60,
    marginRight: 12,
    marginBottom: 12,
  },

  [`& .${classes.check}`]: {
    height: 20,
  },

  [`& .${classes.rankIcon}`]: {
    width: 60,
    height: 60,
  },

  [`& .${classes.pointer}`]: {
    cursor: 'pointer',
  },

  [`& .${classes.statusListItem}`]: {
    marginTop: '10px',
  },

  [`& .${classes.clientRating}`]: {
    marginTop: '10px',
  },

  [`& .${classes.label}`]: {
    margin: 0,
  },

  [`& .${classes.hotLeadStatus}`]: {
    color: 'red',
  },

  [`& .${classes.stats}`]: {
    marginTop: '8px',
    minWidth: '250px',
  },

  [`& .${classes.value}`]: {
    fontWeight: 'bold',
  },
}))
