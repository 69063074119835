import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'

const PREFIX = 'Overview'

export const classes = {
  componentContainer: `${PREFIX}-componentContainer`,
}

export const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.componentContainer}`]: {
    marginTop: '1em',
    marginBottom: '1em',
    padding: '2em',
    [theme.breakpoints.down('md')]: {
      padding: '0em',
    },
    justifyContent: 'center',
  },
}))
