import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'

const PREFIX = 'PopularCommunitiesMap'

export const classes = {
  marker: `${PREFIX}-marker`,
  gridRoot: `${PREFIX}-gridRoot`,
}

export const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.marker}`]: {
    border: '1px solid rgba(132,132,132,1)',
    borderRadius: '50%',
    cursor: 'pointer',
    padding: 0,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  [`&.${classes.gridRoot}`]: {
    flexGrow: 1,
    marginTop: 16,
    height: '80%',
  },
}))
