import { createContext } from 'react'
import { ApolloQueryResult } from '@apollo/client'

/**
 * AuthContext is the Context for storing authentication related data
 */

export interface UserProps {
  firstName: string
  lastName: string
  roleId: number
  subscriptionLevel: string
  userId: number
  clientName: string
  userEmail: string
}

export type AuthenticateFn = (
  email: string,
  pwd: string
) => Promise<string | null>
export type IsExistingEmailFn = (
  email: string
) => Promise<ApolloQueryResult<any> | null>
export type VerifyTokenFn = (
  token: string
) => Promise<ApolloQueryResult<any> | null>
export type HandleSignOutFn = () => void
interface AuthContextProps {
  authenticated: boolean
  user: UserProps
  accessToken: string | null
  authenticate: AuthenticateFn
  isExistingEmail: IsExistingEmailFn
  verifyToken: VerifyTokenFn
  handleSignOut: HandleSignOutFn
}

export const initialState = {
  authenticated: false, // to check if authenticated or not
  user: {} as UserProps, // store all the user details
  accessToken: null,
  authenticate: async (email: string, pwd: string): Promise<string | null> => {
    return null
  },
  isExistingEmail: async (
    email: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<ApolloQueryResult<any> | null> => {
    return null
  },
  verifyToken: async (
    token: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<ApolloQueryResult<any> | null> => {
    return null
  },
  handleSignOut: (): void => {
    //handleSignOut
  },
}

export const AuthContext = createContext({} as AuthContextProps)
export const AuthProvider = AuthContext.Provider
export const AuthConsumer = AuthContext.Consumer
