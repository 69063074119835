import React, { useContext, useEffect, useRef, useState } from 'react'
import Grid from '@mui/material/Grid'
import InteractiveSiteplan from './InteractiveSiteplan'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import HeatMapScale from './HeatMapScale'
import { Community, Lot, Maybe, Siteplan } from '../../../graphql/gen-types'
import SiteplanDetailsDialog from './SiteplanDetailsDialog'
import { COMMUNITIES_BY_IDS_SITEPLANS_INFO } from '../../../graphql/nexus-queries/communitiesByIds'
import { AppStore } from '../../../store'
import { useResizeObserver } from 'utils/hooks'
import { getPath, groupBy, isDefined } from '../../../utils/functions'
import { GOLDEN_RATIO } from '../../../constants'
import { classes, Root } from './SiteplanGrid.styles'

interface SiteplanGridProps {
  communityIds?: Array<number>
  communities?: Array<Community>
  handleCommunitySelect?: (community: Community) => void
  filter?: Record<string, unknown>
  clientName?: string
}

const SiteplanGrid: React.FC<SiteplanGridProps> = ({
  communityIds,
  communities,
  handleCommunitySelect,
  filter,
  clientName,
}: SiteplanGridProps) => {
  const GRAY_FILL = '#d3d3d3'

  const theme = useTheme()
  const smallSize = useMediaQuery(theme.breakpoints.down('md'))
  const rootRef = useRef(null)
  const [rootWidth] = useResizeObserver(rootRef)
  const [width, setWidth] = useState<number>((rootWidth / 2) * 0.75)
  const [height, setHeight] = useState<number>(
    (rootWidth / 2) * 0.75 * GOLDEN_RATIO
  )
  const [maxPopularity, setMaxPopularity] = useState<number>(0)
  const [minPopularity, setMinPopularity] = useState<number>(0)
  const [open, setOpen] = useState(false)
  const [selectedCommunity, setSelectedCommunity] = useState<Community>()
  const [selectedCommunities, setSelectedCommunities] = useState<Community[]>(
    communities || []
  )
  const { appState } = useContext(AppStore)
  const authClient = appState.apolloClient

  const cardRef = useRef<React.RefObject<unknown>>(null)

  const handleCardClick = (community: Community) => {
    if (handleCommunitySelect) {
      handleCommunitySelect(community)
    } else {
      setSelectedCommunity(community)
      setOpen(true)
    }
  }

  useEffect(() => {
    if (!communities?.length) {
      authClient
        .query({
          fetchPolicy: 'no-cache',
          query: COMMUNITIES_BY_IDS_SITEPLANS_INFO,
          variables: {
            clientName,
            communityIds,
            filter,
          },
        })
        .then((result) => {
          setSelectedCommunities(result?.data?.communities)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityIds])

  useEffect(() => {
    if (selectedCommunities?.length > 0) {
      const popularityArray = selectedCommunities
        .map((community: Community) => {
          return community?.siteplans
            ?.map(
              (siteplan: Siteplan) =>
                siteplan !== null &&
                siteplan.popularLots?.map(
                  (lot: Maybe<Lot>) => (lot && lot.popularity) || 0
                )
            )
            .flat()
        })
        .flat()
        .filter(
          (popularity: number | false | undefined | null) => !!popularity
        ) as number[]

      if (popularityArray.length > 0) {
        const newMinPopularity = Math.min(...popularityArray)
        const newMaxPopularity = Math.max(...popularityArray)
        setMaxPopularity(newMaxPopularity)
        setMinPopularity(
          newMinPopularity < newMaxPopularity ? newMinPopularity : 0
        )
      } else {
        setMaxPopularity(0)
        setMinPopularity(0)
      }
    }
  }, [selectedCommunities])

  useEffect(() => {
    if (rootWidth) {
      const wd = (rootWidth / 2) * 0.75
      setWidth(wd)
      setHeight(wd * GOLDEN_RATIO)
    } else {
      const wd = (rootWidth / 2) * 0.75
      setWidth(wd)
      setHeight(wd * GOLDEN_RATIO)
    }
  }, [rootWidth])

  if (!selectedCommunities?.length) {
    return <div>Loading...</div>
  }

  return (
    <Root className={classes.root} ref={rootRef}>
      <HeatMapScale
        maxPopularity={maxPopularity}
        minPopularity={minPopularity}
        size={smallSize ? 25 : 80}
        zeroColor={GRAY_FILL}
      />

      <Grid
        container
        justifyContent="center"
        spacing={2}
        className={classes.container}
        alignItems="center"
        style={{ marginTop: 10 }}
      >
        {selectedCommunities?.map((community: Community) => {
          const siteplans = community.siteplans
          /*
          The master community has just one siteplan.
          If our community has multiple siteplans,
          it means the community has master siteplan and collections.
          */
          const isMasterCommunityCollection = siteplans.length > 1
          if (isMasterCommunityCollection) {
            const masterSiteplan = siteplans.filter(
              (siteplan: Siteplan) => siteplan.master
            )[0]
            return masterSiteplan ? (
              <MasterSitePlanGridComponent
                key={`siteplan${masterSiteplan.name}`}
                community={community}
                siteplan={masterSiteplan}
                minPopularity={minPopularity}
                maxPopularity={maxPopularity}
                width={width}
                height={height}
                cardRef={cardRef}
                onClick={handleCardClick}
              />
            ) : null
          } else if (siteplans.length === 1) {
            const siteplan = siteplans[0]
            return (
              <SitePlanGridComponent
                key={`siteplan${siteplan.name}`}
                community={community}
                siteplan={siteplan}
                minPopularity={minPopularity}
                maxPopularity={maxPopularity}
                width={width}
                height={height}
                cardRef={cardRef}
                onClick={handleCardClick}
              />
            )
          } else {
            return null
          }
        })}
      </Grid>
      {open && selectedCommunity && (
        <SiteplanDetailsDialog
          open={open}
          setOpen={setOpen}
          community={selectedCommunity}
          handleCommunitySelect={setSelectedCommunity}
        />
      )}
    </Root>
  )
}

function MasterSitePlanGridComponent({
  cardRef,
  community,
  siteplan,
  minPopularity,
  maxPopularity,
  width,
  height,
  onClick,
}: {
  cardRef: React.MutableRefObject<unknown>
  community: Community
  siteplan: Siteplan
  minPopularity: number
  maxPopularity: number
  width: number
  height: number
  onClick: (community: Community) => void
}): JSX.Element {
  return (
    <Grid
      item
      key={`mastersiteplan-${community.id}`}
      sm={12}
      md={6}
      lg={4}
      xl={3}
    >
      <Card
        className={classes.card}
        onClick={() => onClick(community)}
        ref={cardRef}
      >
        <CardHeader title={community.name} />
        <CardContent className={classes.cardContent}>
          <InteractiveSiteplan
            siteplan={siteplan}
            hideScale={true}
            maxGridPopularity={maxPopularity}
            minGridPopularity={minPopularity}
            heatMapMode={true}
            hideLegend={true}
            width={width}
            height={height}
          />
        </CardContent>
      </Card>
    </Grid>
  )
}

function SitePlanGridComponent({
  cardRef,
  community,
  siteplan,
  minPopularity,
  maxPopularity,
  width,
  height,
  onClick,
}: {
  cardRef: React.MutableRefObject<unknown>
  community: Community
  siteplan: Siteplan
  minPopularity: number
  maxPopularity: number
  width: number
  height: number
  onClick: (community: Community) => void
}): JSX.Element {
  const lots = siteplan.lots?.filter(isDefined) || []
  const lotsBySalesStatus = groupBy(lots, 'salesStatus')
  const totalLots = lots.length
  return (
    <Grid item key={`siteplan-${community.id}`} sm={12} md={6} lg={4} xl={3}>
      <Card
        className={classes.card}
        onClick={() => onClick(community)}
        ref={cardRef}
      >
        <CardHeader
          title={`${community.name}${
            siteplan.name && siteplan.name !== community.name
              ? ' - ' + siteplan.name
              : ''
          }`}
        />
        <CardContent className={classes.cardContent}>
          <InteractiveSiteplan
            siteplan={community.siteplans[0]}
            hideScale={true}
            maxGridPopularity={maxPopularity}
            minGridPopularity={minPopularity}
            heatMapMode={true}
            hideLegend={true}
            width={width}
            height={height}
          />

          <Typography
            variant="h6"
            style={{ textAlign: 'center', marginBottom: 6 }}
          >
            Lots Statistics
          </Typography>
          <Grid container direction="row" alignItems="center" style={{}}>
            <Grid item xs={7}>
              <Grid container direction="column" alignItems="center">
                {siteplan.lotLegend?.map((row) => {
                  return (
                    <div
                      style={{ height: 22, fontWeight: 700, fontSize: 14 }}
                      key={`siteplanGridLegendInfo${row?.name}`}
                    >
                      {row?.name}:
                    </div>
                  )
                })}
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid container direction="column" alignItems="center">
                {siteplan.lotLegend?.map((row) => {
                  const typeCount = getPath(
                    row?.code || '',
                    'length'
                  )(lotsBySalesStatus) as number
                  let percent = null
                  if (totalLots && typeCount) {
                    percent = typeCount / (totalLots / 100)
                  }
                  return percent ? (
                    <div
                      style={{ height: 22, fontSize: 14 }}
                      key={`siteplanGridLegendInfoPercent${row?.name}`}
                    >
                      {Math.round(percent)}%
                    </div>
                  ) : (
                    <div
                      style={{ height: 22, fontSize: 14 }}
                      key={`siteplanGridLegendInfoPercent${row?.name}`}
                    >
                      0%
                    </div>
                  )
                })}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default SiteplanGrid
