import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import Locations from 'pages/analytics/Locations'
import Plans from 'pages/analytics/Plans'
import Plan from 'pages/analytics/Plan'
import Overview from 'pages/analytics/Overview'
import SalesCenter from 'pages/analytics/SalesCenter'
import SalesCenterCommunity from 'pages/analytics/SalesCenterCommunity'
import IndustryTrends from 'pages/analytics/IndustryTrends'

import Leads from 'pages/leads/Leads'
import LeadDetail from 'pages/leads/LeadDetails'
import LeadsAnalytics from 'pages/leads/LeadsAnalytics'
import AgentAssignments from 'pages/admin/AgentAssignments'
import MarketingAutomation from 'pages/MarketingAutomation'

import BuyOnlineIntegration from 'pages/buy-online/BuyOnlineIntegration'
import BuyOnlineReservations from 'pages/buy-online/BuyOnlineReservations'
import BuyOnlineAnalytics from 'pages/buy-online/BuyOnlineAnalytics'

import Admin from 'pages/admin/Admin'
import FavoriteDetails from 'pages/leads/FavoriteDetails'
import { useIsAuthenticated } from 'utils/hooks'
import AuthorizedRoute from 'components/utils/AuthorizedRoute'
import {
  ANALYTICS_OVERVIEW_AUTH,
  ANALYTICS_SUBMODULE_AUTH,
  LEAD_ANALYTICS_OVERVIEW_AUTH,
  LEAD_OVERVIEW_AUTH,
  MARKETING_AUTH,
} from '../constants'

export const ANALYTICS_OVERVIEW_ROUTE = '/analytics/client/:clientName/overview'
export const ANALYTICS_LEADS_ROUTE = '/analytics/client/:clientName/leads'
export const ANALYTICS_LOCATION_ROUTE =
  '/analytics/client/:clientName/report/locations'
export const ANALYTICS_REPORT_ROUTE = '/analytics/client/:clientName/report'
export const ANALYTICS_ADMIN_ROUTE = '/analytics/client/:clientName/admin'
export const ANALYTICS_SALESCENTER_ROUTE =
  '/analytics/client/:clientName/salescenter'
export const ANALYTICS_TRENDS_ROUTE =
  '/analytics/client/:clientName/industryTrends'

export const LEADS_BASE_ROUTE = '/leads/client/:clientName'
export const LEADS_ANALYTICS_ROUTE = '/leads/client/:clientName/analytics'
export const LEADS_ADMIN_ROUTE = '/leads/client/:clientName/admin'
export const LEADS_AGENTS_ROUTE = '/leads/client/:clientName/agentAssignments'
export const LEADS_FAVORITE_DETAILS_ROUTE =
  '/leads/client/:clientName/prospect/favorite-details'

export const MARKETING_AUTOMATION_ROUTE =
  '/targeted-marketing/client/:clientName/email-campaign'

export const BUY_ONLINE_INTEGRATION_ROUTE =
  '/reserve-online/client/:clientName/integration'
export const BUY_ONLINE_RESERVATIONS_ROUTE =
  '/reserve-online/client/:clientName/reservations'
export const BUY_ONLINE_ANALYTICS_ROUTE =
  '/reserve-online/client/:clientName/analytics'

function ClientRoutes(): JSX.Element {
  const isAuthenticated = useIsAuthenticated()
  if (!isAuthenticated) {
    return (
      <Routes>
        <Route path="/analytics/client/*" element={<Navigate to="/login" />} />
        <Route path="/leads/client/*" element={<Navigate to="/login" />} />
        <Route
          path="/reserve-online/client/*"
          element={<Navigate to="/login" />}
        />
        <Route
          path="/targeted-marketing/client/*"
          element={<Navigate to="/login" />}
        />
      </Routes>
    )
  }

  return (
    <Routes>
      <Route
        path={ANALYTICS_OVERVIEW_ROUTE}
        element={
          <AuthorizedRoute {...ANALYTICS_OVERVIEW_AUTH}>
            <Overview />
          </AuthorizedRoute>
        }
      />
      <Route
        path={ANALYTICS_LEADS_ROUTE}
        element={
          <AuthorizedRoute {...ANALYTICS_OVERVIEW_AUTH}>
            <Leads />
          </AuthorizedRoute>
        }
      />
      <Route
        path={ANALYTICS_LOCATION_ROUTE}
        element={
          <AuthorizedRoute
            {...ANALYTICS_SUBMODULE_AUTH}
            redirectTo="./../../overview"
          >
            <Locations />
          </AuthorizedRoute>
        }
      />
      <Route path={ANALYTICS_REPORT_ROUTE}>
        <Route
          path="plans"
          element={
            <AuthorizedRoute
              {...ANALYTICS_SUBMODULE_AUTH}
              redirectTo="./../../overview"
            >
              <Plans />
            </AuthorizedRoute>
          }
        />
        <Route
          path="plan"
          element={
            <AuthorizedRoute
              {...ANALYTICS_SUBMODULE_AUTH}
              redirectTo="./../../overview"
            >
              <Plan />
            </AuthorizedRoute>
          }
        />
      </Route>
      <Route path={ANALYTICS_ADMIN_ROUTE} element={<Admin />} />
      <Route path={ANALYTICS_SALESCENTER_ROUTE}>
        <Route
          path="overview"
          element={
            <AuthorizedRoute
              {...ANALYTICS_SUBMODULE_AUTH}
              redirectTo="./../../overview"
            >
              <SalesCenter />
            </AuthorizedRoute>
          }
        />
        <Route
          path="community"
          element={
            <AuthorizedRoute
              {...ANALYTICS_SUBMODULE_AUTH}
              redirectTo="./../../overview"
            >
              <SalesCenterCommunity />
            </AuthorizedRoute>
          }
        />
      </Route>
      <Route
        path={ANALYTICS_TRENDS_ROUTE}
        element={
          <AuthorizedRoute
            {...ANALYTICS_SUBMODULE_AUTH}
            redirectTo="./../../overview"
          >
            <IndustryTrends />
          </AuthorizedRoute>
        }
      />
      <Route path={LEADS_BASE_ROUTE}>
        <Route
          path="overview"
          element={
            <AuthorizedRoute
              {...LEAD_OVERVIEW_AUTH}
              redirectTo="./../analytics"
            >
              <Leads />
            </AuthorizedRoute>
          }
        />
        <Route
          path="prospect"
          element={
            <AuthorizedRoute
              {...LEAD_OVERVIEW_AUTH}
              redirectTo="./../analytics"
            >
              <LeadDetail />
            </AuthorizedRoute>
          }
        />
      </Route>
      <Route
        path={LEADS_ANALYTICS_ROUTE}
        element={
          <AuthorizedRoute {...LEAD_ANALYTICS_OVERVIEW_AUTH}>
            <LeadsAnalytics />
          </AuthorizedRoute>
        }
      />

      <Route path={LEADS_ADMIN_ROUTE} element={<Admin />} />
      <Route
        path={LEADS_AGENTS_ROUTE}
        element={
          <AuthorizedRoute {...LEAD_ANALYTICS_OVERVIEW_AUTH}>
            <AgentAssignments />
          </AuthorizedRoute>
        }
      />
      <Route
        path={LEADS_FAVORITE_DETAILS_ROUTE}
        element={<FavoriteDetails />}
      />
      <Route
        path={MARKETING_AUTOMATION_ROUTE}
        element={
          <AuthorizedRoute {...MARKETING_AUTH}>
            <MarketingAutomation />
          </AuthorizedRoute>
        }
      />
      <Route
        path={BUY_ONLINE_INTEGRATION_ROUTE}
        element={<BuyOnlineIntegration />}
      />
      <Route
        path={BUY_ONLINE_RESERVATIONS_ROUTE}
        element={<BuyOnlineReservations />}
      />
      <Route
        path={BUY_ONLINE_ANALYTICS_ROUTE}
        element={<BuyOnlineAnalytics />}
      />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  )
}

export default ClientRoutes
