import TimesSeriesLineChart from 'components/common/time-series/TimeSeriesLineChart'
import {
  FloorplanTrend,
  CommonQueryFilter,
  PopularityMetric,
} from 'graphql/gen-types'
import React from 'react'
import { addZeroCountDates } from 'components/common/time-series/TimeSeriesFormatter'
import ComponentCard from 'components/common/layout/ComponentCard'

export interface FloorplanTrendsProps {
  filter: CommonQueryFilter
  areaChart?: boolean | false
  floorplansRaw: FloorplanTrend[]
  loading: boolean
  error: Error | undefined
  height?: number | null
  width?: number | null
}

export default function FloorplanTrends({
  filter,
  areaChart,
  floorplansRaw,
  loading,
  error,
  height,
  width,
}: FloorplanTrendsProps): JSX.Element {
  const yAxisDataKey = 'value'

  const floorplans = floorplansRaw.map((floorplan) => {
    return {
      clientName: floorplan.clientName,
      planId: floorplan.planId,
      planName: floorplan.planName,
      floorplanName: floorplan.floorplanName,
      dailyMetrics: addZeroCountDates(
        floorplan.dailyMetrics,
        filter.metric,
        new Date(filter.startTime),
        new Date(filter.endTime)
      ),
    } as FloorplanTrend
  })

  const cardContent =
    filter.metric === PopularityMetric.Duration ? (
      <div>Floorplan Trends does not support the 'By Duration' metric.</div>
    ) : (
      <TimesSeriesLineChart
        data={floorplans}
        yAxisDataKey={yAxisDataKey}
        tooltipDataKey={'floorplanName'}
        xAxisDomain={{
          lower: filter.startTime,
          upper: filter.endTime,
        }}
        metric={filter.metric}
        areaChart={areaChart}
        height={height}
        width={width}
      />
    )

  return (
    <ComponentCard
      title="Floorplan Trends"
      result={{
        data: floorplansRaw,
        error,
        loading,
      }}
      skeletonHeight={400}
    >
      {cardContent}
    </ComponentCard>
  )
}
