import { useEffect } from 'react'
import {
  LocationSelection,
  LocationSelectionResult,
  OptionMap,
  SetState,
} from '../Shared'

export function prepareSelection<T, R>(
  values: OptionMap<T>,
  selected: R,
  has: { changed: boolean }
): R {
  if (!selected && values.size === 1) {
    has.changed = true
    return values.keys().next().value
  }
  return selected
}

export default function usePrepareLocationSelection(
  locations: LocationSelectionResult,
  selection: LocationSelection,
  setSelection: SetState<LocationSelection> | undefined
): void {
  return useEffect(() => {
    if (!setSelection || !locations?.cities?.size) return
    const has = { changed: false }
    const preparedSelection = {
      cities: prepareSelection(locations.cities, selection.cities, has),
      country: prepareSelection(locations.countries, selection.country, has),
      us: {
        state: prepareSelection(locations.us.states, selection.us?.state, has),
        county: prepareSelection(
          locations.us.counties,
          selection.us?.county,
          has
        ),
        metro: prepareSelection(locations.us.metros, selection.us?.metro, has),
        zipCode: prepareSelection(
          locations.us.zipCodes,
          selection.us?.zipCode,
          has
        ),
      },
      can: {
        province: prepareSelection(
          locations.can.provinces,
          selection.can?.province,
          has
        ),
        district: prepareSelection(
          locations.can.districts,
          selection.can?.district,
          has
        ),
        postCode: prepareSelection(
          locations.can.postCodes,
          selection.can?.postCode,
          has
        ),
      },
    }
    if (has.changed) setSelection(preparedSelection)
  }, [locations, selection, setSelection])
}
