import React from 'react'
import chroma from 'chroma-js'

interface HeatMapScaleProps {
  minPopularity: number
  maxPopularity: number
  size?: number
  zeroColor?: string
}

const HeatMapScale = ({
  maxPopularity,
  minPopularity,
  size,
  zeroColor,
}: HeatMapScaleProps): JSX.Element => {
  const finalMinPopularity = minPopularity < maxPopularity ? minPopularity : 0
  const scale = chroma
    .scale(['#fff33b', '#fdc70c', '#f3903f', '#ed683c', '#e93e3a'])
    .correctLightness()
  const scaleRange = maxPopularity - finalMinPopularity
  const scaleSteps = scaleRange < 10 ? scaleRange : 10

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        {!!maxPopularity &&
          scale.colors(scaleSteps).map((color, i) => (
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
              key={`heatMapScaleColor${color}`}
            >
              <div
                style={{
                  backgroundColor:
                    i === 0 && zeroColor && finalMinPopularity === 0
                      ? zeroColor
                      : color,
                  width: size ? size : 80,
                  height: size ? size : 80,
                }}
              />
              {i === 0
                ? finalMinPopularity
                : (i * (scaleRange / scaleSteps) + finalMinPopularity).toFixed(
                    0
                  )}
            </div>
          ))}
        {!maxPopularity && (
          <div>
            Maximum Popularity is currently set to 0. Please extend the
            popularity range to view more data.
          </div>
        )}
      </div>
    </>
  )
}

export default HeatMapScale
