import { styled } from '@mui/material/styles'
import { MAIN_APP_BAR_HEIGHT } from '../../constants'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

const LEAD_DETAILS_FILTER_BAR = 156
const PREFIX = 'Leads'

export const classes = {
  root: `${PREFIX}-root`,
  componentContainer: `${PREFIX}-componentContainer`,
}

export const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',
    width: '100%',
    height: `calc(100vh - ${LEAD_DETAILS_FILTER_BAR + MAIN_APP_BAR_HEIGHT}px)`,
    [theme.breakpoints.down('md')]: {
      height: `calc(100vh - 243px)`,
    },
  },

  [`& .${classes.componentContainer}`]: {
    justifyContent: 'center',
    marginTop: '1em',
    padding: '2em',
    [theme.breakpoints.down('md')]: {
      padding: '0em',
    },
  },
}))

export const StyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#f50057',
  },
})

export const StyledTab = styled(Tab)({
  color: 'rgba(255, 255, 255, .7)',
  '&.Mui-selected': {
    color: '#fff',
  },
})
