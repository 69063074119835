import React from 'react'
import { Prospect } from '../../../graphql/gen-types'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import Box from '@mui/material/Box'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import { formatDateTime, humanTimeDuration } from 'utils/functions'
import Divider from '@mui/material/Divider'
import { LEAD_SCORE_OUT_OF } from 'constants/index'
import {
  ProspectStatisticsStyled,
  classes,
} from './styles/ProspectStatistics.styles'

const COMPONENT_TITLE = 'Engagement'

interface ProspectStatisticsProps {
  data: Prospect | undefined | null
  loading?: boolean
  error?: Error | undefined
}

function ProspectStatistics({
  data,
  loading,
  error,
}: ProspectStatisticsProps): JSX.Element {
  const stats = data?.statistics

  if (loading) {
    return (
      <ProspectStatisticsStyled>
        <Typography variant="h5" className={classes.title}>
          {COMPONENT_TITLE}
        </Typography>
        Loading...
      </ProspectStatisticsStyled>
    )
  }

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized
          message={error.toString()}
          imageName={'ProspectStatistics'}
        ></Unauthorized>
      )
    }
    return <div>{error.toString()}</div>
  }

  if (!stats) {
    return (
      <ProspectStatisticsStyled className={classes.container}>
        <Typography variant="h5" className={classes.title}>
          {COMPONENT_TITLE}
        </Typography>
        No Engagement Data
      </ProspectStatisticsStyled>
    )
  }
  return (
    <ProspectStatisticsStyled className={classes.container}>
      <Typography variant="h5" className={classes.title}>
        {COMPONENT_TITLE}
      </Typography>
      <Typography variant="subtitle2" align="justify" className={classes.title}>
        <strong>Engagement Score</strong> is calculated using values below. The
        higher the score, the more active and engaged the lead is.
      </Typography>
      <Typography variant="subtitle2" align="justify" className={classes.title}>
        A <strong>Hot Lead</strong> indicates high activity and interest early
        in the sales process.
      </Typography>
      <List dense={true} disablePadding={false} className={classes.list}>
        <Box m={1}>
          <Divider></Divider>
        </Box>

        <ListItem>
          <ListItemText className={classes.label}>
            <Typography>Registration</Typography>
          </ListItemText>
          <ListItemSecondaryAction>
            <Typography className={classes.value}>
              {formatDateTime(data?.registrationDate, 'MMM do yyyy')}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem>
          <ListItemText className={classes.label}>
            <Typography>First Visit</Typography>
          </ListItemText>
          <ListItemSecondaryAction>
            <Typography className={classes.value}>
              {formatDateTime(stats.firstSeen, 'MMM do yyyy')}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem>
          <ListItemText className={classes.label}>
            <Typography>Last Visit</Typography>
          </ListItemText>
          <ListItemSecondaryAction>
            <Typography className={classes.value}>
              {formatDateTime(stats.lastSeen, 'MMM do yyyy')}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem>
          <ListItemText className={classes.label}>
            <Typography>Number of Visits</Typography>
          </ListItemText>
          <ListItemSecondaryAction>
            <Typography className={classes.value}>
              {stats.numSessions}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem>
          <ListItemText className={classes.label}>
            <Typography>Avg Time/Visit</Typography>
          </ListItemText>
          <ListItemSecondaryAction>
            <Typography className={classes.value}>
              {humanTimeDuration(stats.averageDuration)}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem>
          <ListItemText className={classes.label}>
            <Typography>Total Duration</Typography>
          </ListItemText>
          <ListItemSecondaryAction>
            <Typography className={classes.value}>
              {humanTimeDuration(stats.totalDuration)}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>

        <Box m={1}>
          <Divider></Divider>
        </Box>

        <ListItem>
          <ListItemText className={classes.label}>
            <Typography>Communities Viewed</Typography>
          </ListItemText>
          <ListItemSecondaryAction>
            <Typography className={classes.value}>
              {stats?.communitiesStatistics?.length || 0}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem>
          <ListItemText className={classes.label}>
            <Typography>Plans Viewed</Typography>
          </ListItemText>
          <ListItemSecondaryAction>
            <Typography className={classes.value}>
              {stats?.plansStatistics?.length || 0}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem>
          <ListItemText className={classes.label}>
            <Typography>Plans Favorited</Typography>
          </ListItemText>
          <ListItemSecondaryAction>
            <Typography className={classes.value}>
              {data?.favorites?.length}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem>
          <ListItemText className={classes.label}>
            <Typography>My Home Pages Viewed</Typography>
          </ListItemText>
          <ListItemSecondaryAction>
            <Typography className={classes.value}>
              {stats.numMyHomePageVisited}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem>
          <ListItemText className={classes.label}>
            <Typography>Shared Plans</Typography>
          </ListItemText>
          <ListItemSecondaryAction>
            <Typography className={classes.value}>
              {stats.numPlansShared}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem>
          <ListItemText className={classes.label}>
            <Typography>Contacted Builder</Typography>
          </ListItemText>
          <ListItemSecondaryAction>
            <Typography className={classes.value}>
              {stats.numContactsUs}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem>
          <ListItemText className={classes.label}>
            <Typography>Inventory Tour Appointments</Typography>
          </ListItemText>
          <ListItemSecondaryAction>
            <Typography className={classes.value}>
              {stats.numAppointments}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>

        <Box m={1}>
          <Divider></Divider>
        </Box>

        <ListItem>
          <ListItemText className={classes.label}>
            <Typography>Hot Lead</Typography>
          </ListItemText>
          <ListItemSecondaryAction>
            <Typography className={classes.value}>
              {stats.hotLead ? 'Yes' : 'No'}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem>
          <ListItemText className={classes.label}>
            <Typography>Engagement Score</Typography>
          </ListItemText>
          <ListItemSecondaryAction>
            <Typography className={classes.value}>
              {`${Math.round(
                (stats.score || 0) * LEAD_SCORE_OUT_OF
              )}/${LEAD_SCORE_OUT_OF}`}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </ProspectStatisticsStyled>
  )
}

export default ProspectStatistics
