import React, { useState, SyntheticEvent, useContext } from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import jwtDecode from 'jwt-decode'
import { useNavigate, useLocation } from 'react-router-dom'
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar'
import SnackbarContent from '@mui/material/SnackbarContent'
import { SET_PWD_MUTATION } from 'graphql/mutation/resetPassword'
import hydrationStore from 'store/HydrationStore'
import { AppStore } from '../store'
import { classes, Root } from './PasswordResetPage.styles'
interface DecodedTokenInterface {
  userId: number
  userEmail: string
  firstName: string
  lastName: string
  clientName: string
  directoryName: string
  roleId: number
  iat: number
  exp: number
}

interface PasswordResetStateInterface {
  loading: boolean
  password: string
  verifyPassword: string
  passwordErrorMessage: string
  isPasswordValid: boolean
  errorMessageOpen: boolean
}

const passwordResetPageInitialState: PasswordResetStateInterface = {
  loading: false,
  password: '',
  verifyPassword: '',
  passwordErrorMessage: 'Must be 8 characters or more',
  isPasswordValid: false,
  errorMessageOpen: false,
  snackbarErrorMessage: null,
  snackBarErrorOpen: false,
}

type ErrorMessageProps = {
  open: boolean
  message: string
  handleCloseErrorMessage: (
    event: SyntheticEvent<unknown, Event>,
    reason: SnackbarCloseReason
  ) => void
  classes: Record<string, string>
}

const ErrorMessage = (props: ErrorMessageProps): JSX.Element => {
  const { open, message, handleCloseErrorMessage } = props
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={10000}
      open={open}
      onClose={handleCloseErrorMessage}
    >
      <SnackbarContent
        className={classes.errorSnackbarContent}
        message={message}
      />
    </Snackbar>
  )
}

const PasswordResetPage = (): JSX.Element => {
  const location = useLocation()
  const navigate = useNavigate()
  const [state, setState] = useState<PasswordResetStateInterface>(
    passwordResetPageInitialState
  )
  const { appState } = useContext(AppStore)
  const authClient = appState.apolloClient

  const loginBodyStyle = !state.loading
    ? classes.loginBody
    : `${classes.loginBody} ${classes.loadingLoginBody}`

  /**
   * @description - validates the password and verify password
   *              - storage the token to our localStorage
   *              - call api to reset the password
   */
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    // min 8 letter password
    if (state.password.length < 8) {
      setState({
        ...state,
        errorMessageOpen: true,
        passwordErrorMessage: 'Password must be at least 8 characters long.',
      })
      return
    }

    // validate
    if (state.password !== state.verifyPassword) {
      setState({
        ...state,
        errorMessageOpen: true,
        passwordErrorMessage: 'Password and Verified Password are not the same',
      })
      return
    }

    // decode the token
    const emailToken = location.search.replace('?token=', '')
    const decodedToken: DecodedTokenInterface = jwtDecode(emailToken)

    // store the token in local storage
    hydrationStore.token = emailToken

    // api call for reset password
    authClient
      .mutate({
        mutation: SET_PWD_MUTATION,
        variables: {
          email: decodedToken.userEmail,
          clientName: decodedToken.clientName,
          password: state.password,
          source: 'colonnade',
        },
      })
      .then((result) => {
        if (result.data.setPassword) {
          navigate('/login')
        } else {
          console.error('fail to reset password')
        }
      })
      .catch((err: Error) => {
        setState({
          ...state,
          snackbarErrorOpen: true,
          snackbarErrorMessage: err.message.replace(/.*[Ee]rror: /, ''),
        })
      })
  }

  return (
    <Root className={classes.root}>
      <div className={classes.login}>
        {state.loading && <LinearProgress />}
        <div className={loginBodyStyle}>
          <div className={classes.imageContainer}>
            <img
              className={classes.logoImg}
              src="https://res.cloudinary.com/renderinghouse/image/upload/anewgo/assets/custom/anewgo-logo.png"
              alt="Anewgo Logo"
            />
          </div>
          <form
            id="change-password-form"
            onSubmit={(e): void => handleSubmit(e)}
          >
            <div>
              <Typography
                classes={{
                  root: `${classes.resetPasswordHeader} ${classes.typography}`,
                }}
                variant="h5"
              >
                Reset Password
              </Typography>
              <TextField
                autoFocus
                classes={{ root: classes.textField }}
                margin="normal"
                id="password"
                type="password"
                variant="outlined"
                label="New Password"
                value={state.password}
                error={state.isPasswordValid} // TODO, should be a function that verifies the password
                helperText={state.passwordErrorMessage}
                onChange={(evt): void => {
                  setState({
                    ...state,
                    password: evt.target.value,
                    passwordErrorMessage: '',
                    errorMessageOpen: false,
                    snackbarErrorOpen: false,
                  })
                }}
              />
              <TextField
                classes={{ root: classes.textField }}
                margin="normal"
                id="verifyPassword"
                type="password"
                variant="outlined"
                label="Verify New Password"
                value={state.verifyPassword}
                error={state.password !== state.verifyPassword}
                onChange={(evt): void => {
                  setState({
                    ...state,
                    verifyPassword: evt.target.value,
                    passwordErrorMessage: '',
                    errorMessageOpen: false,
                    snackbarErrorOpen: false,
                  })
                }}
              />
              <Button
                classes={{ root: classes.button }}
                variant="contained"
                size="large"
                color="primary"
                type="submit"
              >
                Reset Password
              </Button>
            </div>
          </form>
        </div>
      </div>
      <ErrorMessage
        open={state.snackbarErrorOpen || state.errorMessageOpen}
        message={state.snackbarErrorMessage || state.passwordErrorMessage}
        handleCloseErrorMessage={(): void =>
          setState({ ...state, errorMessageOpen: false })
        }
        classes={classes}
      />
    </Root>
  )
}

export default PasswordResetPage
