import React, { useState, useContext, useMemo } from 'react'
import { styled } from '@mui/material/styles'
import FilterBar from 'components/common/filter-bar/FilterBar'
import MobileFilterBar from '../../common/filter-bar/MobileFilterBar'
import { useGetAllLocationsQuery, Location } from '../../../graphql/gen-types'
import { AppStore } from 'store'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { pink } from '@mui/material/colors'
import { useLocationSelection } from '../../industry-trends/hooks/useLocationSelection'
import { useCountries } from '../locations-filters/hooks/useCountries'
import CountryFilter from '../locations-filters/components/CountryFilter'
import StateFilter from '../locations-filters/components/us/StateFilter'
import ProvinceFilter from '../locations-filters/components/can/ProvinceFilter'
import usePrepareLocationSelection from '../../industry-trends/hooks/usePrepareLocationSelection'
import { ITLocationSelection, SetState } from '../locations-filters/Shared'
import ITMetroFilter from '../../industry-trends/components/us/MetroFilter'
import ITDistrictFilter from '../../industry-trends/components/can/DistrictFilter'
import ITCitiesFilter from '../../industry-trends/components/CitiesFilter'

const PREFIX = 'IndustryTrendsFilter'

const classes = {
  formControl: `${PREFIX}-formControl`,
  selectCountries: `${PREFIX}-selectCountries`,
  selectCities: `${PREFIX}-selectCities`,
  selectCounty: `${PREFIX}-selectCounty`,
  selectRegion: `${PREFIX}-selectRegion`,
  selectState: `${PREFIX}-selectState`,
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  minWidth: 150,
  [theme.breakpoints.down('sm')]: {
    minWidth: 100,
  },

  [`& .${classes.formControl}`]: {
    margin: `0px ${theme.spacing(1)}`,
    minWidth: 130,
    maxWidth: 300,
    [theme.breakpoints.down('sm')]: {
      minWidth: 150,
      maxWidth: 220,
    },
  },
}))

type IndustryTrendsFilterProps = {
  selection: ITLocationSelection
  setSelection: SetState<ITLocationSelection>
}

export default function IndustryTrendsFilter({
  selection,
  setSelection,
}: IndustryTrendsFilterProps): JSX.Element {
  const [
    showBuilderLocationsOnly,
    setShowBuilderLocationsOnly,
  ] = useState<boolean>(true)
  const { appState } = useContext(AppStore)
  const { selectedClient } = appState
  const clientName = selectedClient?.altName || ''
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down(900))

  const locationsQuery = useGetAllLocationsQuery()
  const locations = useMemo(
    () => (locationsQuery?.data?.getAllLocations as Location[]) || [],
    [locationsQuery?.data?.getAllLocations]
  )

  const locationSelection = useLocationSelection(
    locations,
    selection,
    showBuilderLocationsOnly,
    clientName
  )
  usePrepareLocationSelection(locationSelection, selection, setSelection)
  const {
    countries,
    cities,
    us: { states, metros },
    can: { provinces, districts },
  } = locationSelection

  const { us, can } = useCountries(selection, countries)

  const filterProps = {
    selection,
    setSelection,
    industryTrendsSelect: true,
  }

  const locationFilter = () => (
    <React.Fragment>
      <StyledGrid xs={6} md={3} lg={2} item>
        <FormControlLabel
          control={
            <Checkbox
              data-testid="showOnlyMyLocationCheckbox"
              checked={showBuilderLocationsOnly}
              onChange={(event) => {
                const checked = event.target.checked
                setSelection({ us: {}, can: {} })
                setShowBuilderLocationsOnly(checked)
              }}
              sx={{
                color: pink[800],
                '&.Mui-checked': {
                  color: pink[600],
                },
              }}
            />
          }
          label="Show only my locations"
        />
      </StyledGrid>
      <StyledGrid xs={6} md={3} lg={1} item>
        <CountryFilter countries={countries} {...filterProps} />
      </StyledGrid>
      {us && (
        <>
          <StyledGrid xs={6} md={3} lg={1} item>
            <StateFilter states={states} {...filterProps} />
          </StyledGrid>
          <StyledGrid xs={6} md={3} lg={1} item>
            <ITMetroFilter metros={metros} {...filterProps} />
          </StyledGrid>
        </>
      )}
      {can && (
        <>
          <StyledGrid xs={6} md={3} lg={1} item>
            <ProvinceFilter provinces={provinces} {...filterProps} />
          </StyledGrid>
          <StyledGrid xs={6} md={3} lg={1} item>
            <ITDistrictFilter districts={districts} {...filterProps} />
          </StyledGrid>
        </>
      )}
      <StyledGrid xs={6} md={3} lg={1} item>
        <ITCitiesFilter cities={cities} {...filterProps} />
      </StyledGrid>
    </React.Fragment>
  )

  if (sm) {
    return <MobileFilterBar filters={locationFilter} />
  }

  return (
    <FilterBar
      customFiltersRow={
        <Grid container justifyContent="space-evenly" alignItems="center">
          {locationFilter()}
        </Grid>
      }
    />
  )
}
