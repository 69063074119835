import { LotInsetSvg } from '@anewgo/interactive-siteplan-core'
import {
  useGetLotWithSiteplanInfoQuery,
  useGetSiteplanLiteQuery,
  Lot,
  Community,
  Client,
  Siteplan,
} from '../../../../graphql/gen-types'
import React, { useEffect, useState } from 'react'

type LotInsetSVGWrapperProps = {
  client: Client
  community: Community
  lot: Lot
  width: number
  height: number
}

export default function LotInsetSVGWrapper({
  client,
  community,
  lot,
  width,
  height,
}: LotInsetSVGWrapperProps): JSX.Element | null {
  const clientName = client.directoryName || ''
  const [siteplan, setSiteplan] = useState<Siteplan>()
  // TODO: make sure doesn't cause re-renders (either infinite or multiple on load)
  const getLotWithSiteplanInfoResult = useGetLotWithSiteplanInfoQuery({
    variables: {
      clientName,
      lotId: lot.id,
    },
  })
  const lotWithSiteplan = getLotWithSiteplanInfoResult?.data?.lot

  const getSiteplanLiteResult = useGetSiteplanLiteQuery({
    skip: !lotWithSiteplan?.siteplanInfo?.siteplanId,
    variables: {
      clientName,
      id: lotWithSiteplan?.siteplanInfo?.siteplanId || 0,
    },
  })

  useEffect(() => {
    const siteplanResult =
      (getSiteplanLiteResult?.data?.siteplanById as Siteplan) || undefined
    if (siteplanResult !== undefined) {
      setSiteplan(siteplanResult)
    }
  }, [getSiteplanLiteResult])

  return siteplan ? (
    <LotInsetSvg
      directoryName={clientName}
      siteplan={siteplan}
      lot={lotWithSiteplan as Lot}
      width={width}
      height={height}
    />
  ) : null
}
