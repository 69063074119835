import React from 'react'
import {
  useCamberPopularColorSchemesQuery,
  CommonQueryFilter,
  ClientIdAndName,
  Elevation,
  Scheme,
} from 'graphql/gen-types'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import PopularColors from './PopularColors'

interface CamberPopularColorsProps {
  client: ClientIdAndName | null
  filter: CommonQueryFilter
  communityName?: string
  containerHeight: number
  planId?: number
  elevation?: Elevation | null
}

function CamberPopularColors(props: CamberPopularColorsProps): JSX.Element {
  const { data, loading, error } = useCamberPopularColorSchemesQuery({
    variables: {
      filter: props.filter,
    },
  })

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized message={error.toString()} imageName={'PopularColors'} />
      )
    }
    console.error(error)
    return <div>Error while loading popular colors</div>
  }

  const popularColorSchemes: Scheme[] = data?.camberPopularColorSchemes as Scheme[]
  return (
    <PopularColors
      {...props}
      popularColorSchemes={popularColorSchemes}
      loading={loading}
      error={error}
    />
  )
}

export default CamberPopularColors
