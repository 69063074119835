import React from 'react'
import chroma from 'chroma-js'
import clsx from 'clsx'

import withStyles from '@mui/styles/withStyles'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'

import {
  classes,
  StyledSwitch,
  LotLegendDiv,
  StyledTypography,
  SwitchContainerDiv,
  LotLegendyEntryDiv,
  BulletSpan,
} from './LotLegend.styles'
import { getPath, groupBy } from '../../../utils/functions'
import {
  Lot,
  LotLegendEntry,
  Maybe,
  LotSalesStatus,
} from '../../../graphql/gen-types'

interface ColoredBulletProps {
  hex: string
  radius: number
}

const ColoredBullet = ({ hex, radius }: ColoredBulletProps): JSX.Element => {
  const backgroundColor = `#${hex}`
  const style = {
    backgroundColor,
    width: `${radius * 2}px`,
    height: `${radius * 2}px`,
    border: '',
    borderRadius: `${radius}px`,
  }
  if (chroma(backgroundColor).luminance() > 0.8) {
    style.border = '1px solid #cdcdcd'
  }

  return <BulletSpan className={classes.bulletRoot} style={style} />
}

const CustomColorCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#2196f3',
    },
    padding: 0,
    position: 'absolute',
    right: 0,
  },
  checked: {
    '&$disabled': {
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
  disabled: { color: 'rgba(0, 0, 0, 0.26)' },
})((props: Record<string, unknown>) => <Checkbox color="default" {...props} />)

interface LotLegendProps {
  legend: Maybe<LotLegendEntry>[] | null | undefined
  heatMapMode: boolean
  onLotLegendModeChange: (heatMapModeOn: boolean) => void
  lots: Array<Lot>
  onChange: (code: string) => void
  salesStatusesToFilterOut: Array<string>
  filtersEnabled: boolean
}

const LotLegend = ({
  legend,
  heatMapMode = false,
  onLotLegendModeChange,
  lots,
  filtersEnabled = true,
  onChange,
  salesStatusesToFilterOut = [],
}: LotLegendProps): JSX.Element => {
  const lotsForSalesStatus = groupBy(lots || [], 'salesStatus')

  const handleClick = (code: string) => {
    onChange(code)
  }

  const renderLegend = () => {
    return legend?.map((legendItem) => {
      if (!legendItem) return null
      const { code, hex, name } = legendItem
      const numberOfLots = getPath(code, 'length')(lotsForSalesStatus)
      if (numberOfLots && code !== LotSalesStatus.Unreleased) {
        const excluded = salesStatusesToFilterOut.includes(code)
        const parentClass = filtersEnabled
          ? clsx(classes.lotLegendEntry, classes.lotLegendEntrySelectable)
          : classes.lotLegendEntry
        return (
          <LotLegendyEntryDiv
            key={code}
            className={parentClass}
            onClick={
              numberOfLots && filtersEnabled
                ? () => handleClick(code)
                : undefined
            }
          >
            {!heatMapMode && <ColoredBullet hex={hex} radius={10} />}
            <StyledTypography
              variant="overline"
              className={clsx(classes.typographyStandard, classes.inlineItem)}
            >
              {name} <span>({numberOfLots || 0})</span>
            </StyledTypography>
            {filtersEnabled && (
              <CustomColorCheckbox
                className={classes.checkBox}
                checked={!excluded}
                disabled={!numberOfLots}
                size="small"
                color="primary"
              />
            )}
          </LotLegendyEntryDiv>
        )
      } else {
        return <div key={code} />
      }
    })
  }

  return (
    <LotLegendDiv className={classes.lotLegendRoot}>
      <SwitchContainerDiv className={classes.switchContainer}>
        <Typography variant="body2"> Lot Status</Typography>
        <StyledSwitch
          className={classes.switch}
          onChange={() => onLotLegendModeChange(!heatMapMode)}
          checked={heatMapMode}
        />
        <Typography variant="body2"> Heatmap</Typography>
      </SwitchContainerDiv>
      <LotLegendyEntryDiv
        className={clsx(
          classes.lotLegendEntry,
          lots.length && filtersEnabled && classes.lotLegendEntrySelectable
        )}
      >
        {!heatMapMode && <div style={{ width: 24 }} />}
        <StyledTypography
          variant="overline"
          className={clsx(classes.typographyStandard, classes.inlineItem)}
        >
          Total ({lots.length})
        </StyledTypography>
      </LotLegendyEntryDiv>
      {renderLegend()}
    </LotLegendDiv>
  )
}

export default LotLegend
