import React from 'react'

import {
  CommonQueryFilter,
  usePopularElevationSpecsQuery,
  usePopularIndustryElevationSpecsQuery,
} from 'graphql/gen-types'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import ComponentCard from 'components/common/layout/ComponentCard'
import ElevationSpecificationsPanel from '../analytics/popular-elevations/ElevationSpecificationsPanel'

const COMPONENT_TITLE = 'Industry Elevation Specifications'
const INDUSTRY_ELEVATION_LIMIT = 100
const COMPONENT_SKELETON_HEIGHT = 500

interface PopularElevationSpecsProps {
  filter: CommonQueryFilter
}

// Functional Component
export default function IndustrySpecifications(
  props: PopularElevationSpecsProps
): JSX.Element {
  const clientSpecifications = usePopularElevationSpecsQuery({
    variables: {
      filter: props.filter,
    },
  })
  const industrySpecifications = usePopularIndustryElevationSpecsQuery({
    variables: {
      filter: {
        ...props.filter,
        clientName: '',
        limit: INDUSTRY_ELEVATION_LIMIT, // make sure to limit industry results.
      },
    },
  })

  const error = industrySpecifications.error
  const loading = industrySpecifications.loading

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized
          message={error.toString()}
          imageName={'PopularElevationSpecsDisplay'}
        ></Unauthorized>
      )
    }
    return <div>{error.toString()}</div>
  }

  return (
    <ComponentCard
      title={COMPONENT_TITLE}
      skeletonHeight={COMPONENT_SKELETON_HEIGHT}
      result={{ loading, error }}
    >
      <ElevationSpecificationsPanel
        data={clientSpecifications.data?.popularElevationSpecs}
        industryData={
          industrySpecifications.data?.popularIndustryElevationSpecs
        }
        isIndustry={true}
      />
    </ComponentCard>
  )
}
