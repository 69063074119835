import React from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import Input from '@mui/material/Input'
import { Community } from '../../../graphql/gen-types'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MIN_WIDTH = 170
const MAX_WIDTH = 220
const menuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 15 + ITEM_PADDING_TOP,
    },
  },
}
export interface CommunitiesSelectProps {
  selectedCommunities: Array<Community>
  filteredCommunities: Array<Community>
  onCommunityChange: (communityIds: Array<number>) => void
}

export default function CommunitiesSelect({
  selectedCommunities,
  filteredCommunities,
  onCommunityChange,
}: CommunitiesSelectProps): JSX.Element {
  const handleChange = (e: SelectChangeEvent<number[]>) => {
    e.stopPropagation()
    onCommunityChange(e.target.value as number[])
  }

  return (
    <FormControl variant="standard">
      <InputLabel htmlFor="select-multiple-communities">
        Select Communities
      </InputLabel>
      <Select
        variant="standard"
        style={{
          minWidth: MIN_WIDTH,
          maxWidth: MAX_WIDTH,
        }}
        multiple
        value={selectedCommunities.map((comm) => comm.id)}
        onChange={(e) => handleChange(e)}
        input={<Input id="select-multiple-communities" />}
        renderValue={() => {
          const commNames: string[] = selectedCommunities.map(
            (filtComm) => filtComm.name || ''
          )
          return commNames.join(', ')
        }}
        MenuProps={menuProps}
      >
        {filteredCommunities.map((filtComm) => (
          <MenuItem key={filtComm.id} value={filtComm.id}>
            <Checkbox
              checked={selectedCommunities.some(
                (comm) => comm.id === filtComm.id
              )}
            />
            <ListItemText primary={filtComm.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
