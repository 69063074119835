import { useState, useEffect } from 'react'
import { ANEWGO_CDN_BASE } from '../../../constants'
export interface UseFetchImageResult {
  image?: HTMLImageElement | null
  loading: boolean
  error?: string | null
}

export default function useFetchImage(
  directoryName?: string | null,
  imageSrc?: string | null
): UseFetchImageResult {
  const url = `${ANEWGO_CDN_BASE}/app/${directoryName}/images/siteplans/${imageSrc}`
  const [image, setImage] = useState<HTMLImageElement | null>()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>()

  useEffect(() => {
    const fetchImage = async (): Promise<void> => {
      const img = new Image()
      img.onload = (): void => {
        setImage(img)
        setLoading(false)
      }
      img.onerror = (err): void => {
        setLoading(false)
        setError(`failed to fetch ${url}:`)
      }
      img.src = url
    }

    if (directoryName && imageSrc) {
      setLoading(true)
      fetchImage()
    }
  }, [directoryName, imageSrc, url])

  return { loading, error, image }
}
