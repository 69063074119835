import { styled } from '@mui/material/styles'

const PREFIX = 'Unauthorized'

export const classes = {
  backgroundImage: `${PREFIX}-backgroundImage`,
  root: `${PREFIX}-root`,
  messageBar: `${PREFIX}-messageBar`,
}

export const UnauthorizedStyled = styled('div')(() => ({
  [`& .${classes.backgroundImage}`]: {
    height: '100%',
    width: '100%',
    zIndex: 0,
    filter: 'blur(8px)',
  },

  [`&.${classes.root}`]: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },

  [`& .${classes.messageBar}`]: {
    position: 'absolute',
    top: '45%',
    left: '5%',
    zIndex: 1,
    width: '90%',
    display: 'flex',
    justifyContent: 'center',
  },
}))
