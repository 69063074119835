import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import { AutocompleteGetTagProps } from '@mui/material/useAutocomplete'
import React, { ChangeEvent } from 'react'

const PREFIX = 'MultiOptionsAutocomplete'

const classes = {
  root: `${PREFIX}-root`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    minWidth: '200px',
    maxWidth: `calc(100% - ${theme.spacing(2)})`,
    width: (props: { fullWidth: boolean }) =>
      props.fullWidth ? '100%' : 'unset',
  },
}))

export interface MultiOptionAutoCompleteProps<T> {
  inputLabel: string
  options: T[]
  selectedOptions: T[]
  setSelectedOptions: (newOptions: T[]) => void
  optionLabelAccessor: (option: T) => string
  fullWidth?: boolean
  disabled?: boolean
  limitTags?: number
}

export function MultiOptionAutoComplete<T>({
  inputLabel,
  options,
  selectedOptions,
  setSelectedOptions,
  optionLabelAccessor,
  fullWidth = false,
  disabled,
  limitTags = 5,
}: MultiOptionAutoCompleteProps<T>): JSX.Element {
  const onSelectedOptionsChange = (event: ChangeEvent<unknown>, data: T[]) => {
    event.stopPropagation()
    setSelectedOptions(data)
  }

  const tagRenderer = (value: T[], getTagProps: AutocompleteGetTagProps) =>
    value.map((option: T, index: number) => {
      return (
        <Chip
          color="primary"
          label={optionLabelAccessor(option)}
          {...getTagProps({ index })}
        />
      )
    })
  return (
    <Root className={classes.root} onClick={(e) => e.stopPropagation()}>
      <Autocomplete
        onClick={(e) => e.stopPropagation()}
        multiple
        disabled={disabled}
        fullWidth={fullWidth}
        limitTags={limitTags}
        options={options}
        value={selectedOptions}
        onChange={onSelectedOptionsChange}
        getOptionLabel={optionLabelAccessor}
        isOptionEqualToValue={(option, value) =>
          optionLabelAccessor(option) === optionLabelAccessor(value)
        }
        renderInput={(params) => (
          <TextField {...params} label={inputLabel} variant="outlined" />
        )}
        renderTags={tagRenderer}
      />
    </Root>
  )
}
