import * as React from 'react'

interface LeadsInsightIf {
  width: number
  height: number
}

function AnalyticsInsightIcon(props: LeadsInsightIf): JSX.Element {
  const { width, height } = props
  return (
    <svg x="0px" y="0px" viewBox="0 0 512 512" width={width} height={height}>
      <rect
        x="44.02"
        y="362.54"
        fill={'#FFDB0C'}
        width="81.78"
        height="108.31"
      />
      <rect
        x="158.08"
        y="247.42"
        fill={'#FFA900'}
        width="81.78"
        height="223.43"
      />
      <rect
        x="386.2"
        y="167.72"
        fill={'#DE0418'}
        width="81.78"
        height="303.13"
      />
      <rect
        x="272.14"
        y="296.47"
        fill={'#FF6536'}
        width="81.78"
        height="174.39"
      />
      <rect y="455.85" fill={'#808080'} width="512" height="30" />
      <rect x="256" y="455.85" fill={'#666666'} width="256" height="30" />
      <g>
        <rect
          x="126.944"
          y="122.38"
          transform="matrix(-0.7104 -0.7038 0.7038 -0.7104 99.6216 447.8122)"
          fill={'#333333'}
          width="30"
          height="162.06"
        />

        <rect
          x="193.93"
          y="155.369"
          transform="matrix(-0.9187 -0.395 0.395 -0.9187 423.9098 428.0067)"
          fill={'#333333'}
          width="124.162"
          height="30.001"
        />

        <rect
          x="355.064"
          y="44.511"
          transform="matrix(-0.7485 -0.6631 0.6631 -0.7485 560.5194 473.5928)"
          fill={'#333333'}
          width="29.999"
          height="171.996"
        />
      </g>
      <circle fill={'#E6E6E6'} cx="84.91" cy="260.97" r="40" />
      <circle fill={'#B3B3B3'} cx="198.97" cy="145.85" r="40" />
      <circle fill={'#4D4D4D'} cx="427.09" cy="66.15" r="40" />
      <circle fill={'#808080'} cx="313.03" cy="194.89" r="40" />
    </svg>
  )
}

export default AnalyticsInsightIcon
