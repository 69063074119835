import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
export const COMPONENT_HEIGHT = 350

const PREFIX = 'ProspectStatistics'

export const classes = {
  container: `${PREFIX}-container`,
  gridRoot: `${PREFIX}-gridRoot`,
  title: `${PREFIX}-title`,
  list: `${PREFIX}-list`,
  row: `${PREFIX}-row`,
  label: `${PREFIX}-label`,
  value: `${PREFIX}-value`,
  pointer: `${PREFIX}-pointer`,
}

export const ProspectStatisticsStyled = styled(Paper)(({ theme }) => ({
  [`&.${classes.container}`]: {
    padding: 16,
  },

  [`& .${classes.gridRoot}`]: {
    flexGrow: 1,
    paddingLeft: '1em',
    height: '90%',
  },

  [`& .${classes.title}`]: {
    textAlign: 'center',
  },

  [`& .${classes.list}`]: {
    '& div': {
      paddingTop: 2,
      paddingBottom: 0,
    },
  },

  [`& .${classes.row}`]: {
    display: 'flex',
  },

  [`& .${classes.label}`]: {
    margin: 0,
  },

  [`& .${classes.value}`]: {
    fontWeight: 'bold',
  },

  [`& .${classes.pointer}`]: {
    cursor: 'pointer',
  },
}))
