import { styled } from '@mui/material/styles'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
export { default as ResponsiveCell } from './ResponsiveCell'
export { default as ResponsiveRow } from './ResponsiveRow'

export const MobileHeader = styled('td')(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'light'
      ? '#f6f6f6'
      : theme.palette.background.default,
}))

export const MobileRow = styled('td')(() => ({
  display: 'grid',
  maxHeight: '48px', // Keeps dividing lines in-line with row
  padding: 0,
}))

export const MobileRowContent = styled('td')(() => ({
  flexGrow: 1,
  padding: '1px 0px',
}))

export const ResponsiveTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#282828' : '#f6f6f6',
}))

export const ResponsiveTablePagination = styled(TablePagination)(() => ({
  '& .MuiTablePagination-spacer': {
    flex: 'none',
  },
  '& .MuiTablePagination-displayedRows': {
    width: 100,
  },
}))
