import { gql } from '@apollo/client'

const PROSPECT_ADDED_SUBSCRIPTION = gql`
  subscription OnProspectAdded($clientName: String!, $token: String!) {
    prospectAdded(clientName: $clientName, token: $token) {
      id
      clientName
    }
  }
`

export default PROSPECT_ADDED_SUBSCRIPTION
