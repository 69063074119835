import React, { useState, useContext, useEffect } from 'react'
import DateFnsAdapter from '@date-io/date-fns'
import {
  Community,
  useCommunitiesQuery,
  SpecFilter,
  PaginatedPlansQueryVariables,
  ColonnadeSubscriptionLevelsEnum,
} from 'graphql/gen-types'
import { AppStore } from 'store'
import { AnalyticsStore } from 'store/analyticsStore'
import PlansFilterBar from 'components/common/by-plan/PlansFilterBar'
import PlansGrid from 'components/common/by-plan/PlansGrid'
import { SortOptions } from 'components/common/custom-inputs/SortSelect'
import Grid from '@mui/material/Grid'
import { Navigate } from 'react-router-dom'
import { STARTER_INVALID_FEATURE_MESSAGE } from '../../constants'
import { userHasSubscriptionAccess } from 'utils/authorizationHelpers'
import { classes, Root } from './Plans.styles'
const dateFns = new DateFnsAdapter()

// Number of plans retrieved on each "page" (infinite scrolling)
const PAGINATION_AMOUNT = 24 // 24 has many factors, decent size

function Plans(): JSX.Element {
  const { appState } = useContext(AppStore)
  const { analyticsState } = useContext(AnalyticsStore)
  const { selectedClient } = appState
  const { startDate, endDate } = analyticsState
  const selectedClientName = selectedClient ? selectedClient.altName : ''
  const [communities, setCommunities] = useState<Community[]>([])

  const [filter, setFilter] = useState({})
  const [sortBy, setSortBy] = useState<SortOptions>(SortOptions.Popularity)
  const [search, setSearch] = useState('')
  const [selectedCommunityIds, setSelectedCommunityIds] = useState<number[]>([])

  const handleCommunitiesChange = (communityIds: number[]): void => {
    setSelectedCommunityIds(communityIds)
  }

  const PRO_OR_ENTERPRISE_SUBSCRIBED = userHasSubscriptionAccess([
    ColonnadeSubscriptionLevelsEnum.Pro,
    ColonnadeSubscriptionLevelsEnum.Enterprise,
  ])

  const communityIds = selectedCommunityIds.length
    ? selectedCommunityIds
    : communities.map((comm) => comm.id)

  const paginatedPlansArgs: PaginatedPlansQueryVariables = {
    clientName: selectedClientName,
    // if no communities are selected, show for all communties.
    communityIds,
    limit: PAGINATION_AMOUNT,
    offset: 0,
    sortBy: 'cost', // items get resorted by popularity later
    filter: filter,
    commonFilter: {
      startTime: dateFns.format(startDate, 'MM-dd-yyyy-HH:mm:ss'),
      endTime: dateFns.format(endDate, 'MM-dd-yyyy-HH:mm:ss'),
      clientName: selectedClientName,
      communityIdCollection: communityIds,
    },
    selectedMetrics: ['popularity', 'duration', 'frequency', 'browsingData'],
    sortOrder: 'ASC',
  }

  // Retrieve and set communities array. This is all of the communities the client has
  const communitiesQuery = useCommunitiesQuery({
    variables: {
      clientName: selectedClientName,
    },
    skip: !selectedClientName,
  })

  useEffect(() => {
    if (communitiesQuery.data && communitiesQuery.data.communities) {
      setCommunities(communitiesQuery.data.communities as Community[])
    }
  }, [communitiesQuery.data])

  if (!PRO_OR_ENTERPRISE_SUBSCRIBED) {
    const selectedClientName = selectedClient ? selectedClient.altName : ''
    return (
      <Navigate
        to={`/analytics/client/${selectedClientName}/overview?subscriptionLevelErrorMessage=${STARTER_INVALID_FEATURE_MESSAGE}`}
      />
    )
  }

  return (
    <Root>
      <Grid container>
        <PlansFilterBar
          onFilterChange={(newFilter: SpecFilter): void => {
            setFilter(newFilter)
          }}
          onSearchChange={(newSearch: string): void => {
            setSearch(newSearch)
          }}
          onSortChange={(newSortBy: SortOptions): void => {
            setSortBy(newSortBy)
          }}
          onCommunitiesChange={handleCommunitiesChange}
          communities={communities}
          selectedCommunityIds={selectedCommunityIds}
        />
      </Grid>
      <Grid container>
        {/* This page is unique in that it's child component handles infinite scrolling, so the parent .root div here does not scroll. */}
        <div className={classes.root}>
          {communities.length > 0 && (
            <PlansGrid
              search={search}
              paginatedPlansArgs={paginatedPlansArgs}
              selectedMetric={sortBy}
            />
          )}
        </div>
      </Grid>
    </Root>
  )
}

export default Plans
