import { gql } from '@apollo/client'

const RESERVATION_INTEGRATION_UPDATE = gql`
  subscription OnUpdateReservationIntegration(
    $clientName: String!
    $token: String!
  ) {
    getReservationIntegrationUpdate(clientName: $clientName, token: $token) {
      id
      reservationIntegrationInfo {
        id
        stripeAccountReady
      }
    }
  }
`

const CLIENT_PROSPECT_RESERVATION_UPDATE = gql`
  subscription getClientProspectReservationsUpdate(
    $clientName: String!
    $token: String!
  ) {
    getClientProspectReservationsUpdate(
      clientName: $clientName
      token: $token
    ) {
      id
      favoriteId
      reservationStatus
      rejectReason
      errorArray
      prospectId
      primaryBuyerId
      signatureDate
      paymentDate
      termsAndConditionsAcceptDate
      paymentsTransactionId
      signTransactionId
      secondaryBuyerReservations {
        prospectId
        signatureDate
        signTransactionId
      }
    }
  }
`

export { RESERVATION_INTEGRATION_UPDATE, CLIENT_PROSPECT_RESERVATION_UPDATE }
