import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import { CommunityPlanElevation } from '../../leads/prospect-page/dialogs/ProspectPlanSuggestionsDialog'
import {
  PaginatedPlansQueryVariables,
  Plan,
  usePaginatedPlansQuery,
} from '../../../graphql/gen-types'
import { SortOptions } from '../custom-inputs/SortSelect'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { sortPlansByMetric } from './planHelpers'
import { verifyUserAuthError } from '../../../utils/authorizationHelpers'
import { Unauthorized } from '../../auth/Unauthorized'
import { classes, StyledGrid } from './PlanElevationTransfetList.styles'

function getExclusive(
  a: CommunityPlanElevation[],
  b: CommunityPlanElevation[]
) {
  return a.filter((value) => b.indexOf(value) === -1)
}

function intersection(
  a: CommunityPlanElevation[],
  b: CommunityPlanElevation[]
) {
  return a.filter((value) => b.indexOf(value) !== -1)
}

function union(a: CommunityPlanElevation[], b: CommunityPlanElevation[]) {
  return [...a, ...getExclusive(b, a)]
}

interface PlanElevationsTransferListProps {
  selectedMetric: SortOptions
  search?: string
  paginatedPlansArgs: PaginatedPlansQueryVariables
  selectPlans: (plans: CommunityPlanElevation[]) => void
  selectedPlans: CommunityPlanElevation[]
}

export default function PlanElevationsTransferList({
  selectedMetric,
  search,
  paginatedPlansArgs,
  selectPlans,
  selectedPlans,
}: PlanElevationsTransferListProps): JSX.Element {
  const [checked, setChecked] = useState<CommunityPlanElevation[]>([])
  const [options, setOptions] = useState<CommunityPlanElevation[]>([])

  const { data, loading, error } = usePaginatedPlansQuery({
    variables: paginatedPlansArgs,
  })

  /**
   * Filters by search term and orders by popularity
   */
  const filteredPlans = (plans: Plan[]): Plan[] => {
    return plans?.filter(
      (plan: Plan) =>
        plan?.name?.toLowerCase().includes(search?.toLowerCase() || '') || false
    )
  }

  useEffect(() => {
    if (data?.paginatedPlans?.plans) {
      const planElevations = data.paginatedPlans.plans
        .map((plan) =>
          plan?.elevations?.map((elevation) => ({
            ...plan,
            elevations: [elevation],
          }))
        )
        .flat()
      const sortedPlans = filteredPlans(
        planElevations as Plan[]
      )?.sort((planA, planB) => sortPlansByMetric(planA, planB, selectedMetric))

      const communityPlanElevations =
        (sortedPlans
          .map((plan) =>
            plan.elevations?.map((elevation) =>
              elevation?.communities
                ?.filter((community) => community?.active)
                .map((community) => ({
                  planName: plan.name || '',
                  communityId: community?.id,
                  communityName: community?.name || '',
                  elevationCaption: elevation?.caption || '',
                  elevationId: elevation.id,
                  planId: plan.id,
                  elevationThumb: elevation?.thumb || '',
                  uniqueId: `${plan.id}:${community?.id}:${elevation?.caption}`,
                }))
            )
          )
          .flat(2) as CommunityPlanElevation[]) || []
      setOptions(communityPlanElevations)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedMetric, search])

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return <Unauthorized message={error.toString()} imageName={'Plans'} />
    }
    console.error(error)
    return <div>Error while loading plans</div>
  }

  const optionsChecked = intersection(checked, options)
  const selectedChecked = intersection(checked, selectedPlans)

  const handleToggle = (value: CommunityPlanElevation) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]
    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const numberOfChecked = (items: CommunityPlanElevation[]) =>
    intersection(checked, items).length

  const handleToggleAll = (items: CommunityPlanElevation[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(getExclusive(checked, items))
    } else {
      setChecked(union(checked, items))
    }
  }

  const handleCheckedSelected = () => {
    selectPlans(selectedPlans.concat(optionsChecked))
    setOptions(getExclusive(options, optionsChecked))
    setChecked(getExclusive(checked, optionsChecked))
  }

  const handleCheckedOptions = () => {
    const selectedCheckedWithoutAI = selectedChecked.filter(
      (item) => !item.isAIRecommended
    )

    setOptions(options.concat(selectedCheckedWithoutAI))
    selectPlans(getExclusive(selectedPlans, selectedChecked))
    setChecked(getExclusive(checked, selectedChecked))
  }

  const customList = (
    title: React.ReactNode,
    items: CommunityPlanElevation[]
  ) => (
    <>
      <div className={classes.header}>
        <Checkbox
          onClick={handleToggleAll(items)}
          checked={
            numberOfChecked(items) === items.length && items.length !== 0
          }
          indeterminate={
            numberOfChecked(items) !== items.length &&
            numberOfChecked(items) !== 0
          }
          disabled={items.length === 0}
        />
        <div className={classes.title}>
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="subtitle2">{`${numberOfChecked(items)}/${
            items.length
          } selected`}</Typography>
        </div>
      </div>
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((cpe: CommunityPlanElevation) => {
          const labelId = `transfer-list-all-item-${cpe.uniqueId}-label`

          return (
            <ListItem
              key={cpe.uniqueId}
              role="listitem"
              button
              onClick={handleToggle(cpe)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(cpe) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${cpe.planName} ${cpe.elevationCaption} ${cpe.communityName}`}
              />
            </ListItem>
          )
        })}
        <ListItem />
      </List>
    </>
  )

  return loading ? (
    <Skeleton width={'100vw'} height={'80vh'} />
  ) : (
    <StyledGrid
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item className={classes.listContainer}>
        {customList('Choices', options)}
      </Grid>
      <Grid item>
        <Grid container direction="row" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedOptions}
            disabled={selectedChecked.length === 0}
          >
            &#9651;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedSelected}
            disabled={optionsChecked.length === 0}
            data-testid="ChooseSelected"
          >
            &#9661;
          </Button>
        </Grid>
      </Grid>
      <Grid item className={classes.listContainer} data-testid="ChosenList">
        {customList('Chosen', selectedPlans)}
      </Grid>
    </StyledGrid>
  )
}
