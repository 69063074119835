import React, { useMemo } from 'react'
import Typography from '@mui/material/Typography'
import FloorplanImage from '../components/FloorplanImage'
import LineSeparator from '../components/LineSeparator'
import { siteplans } from '@anewgo/utils'
import { API_KEY } from '../constants'
import { mirrorElevationImage, hasBaseFloorplan } from '../utils'
import {
  Client,
  Community,
  Elevation,
  Plan,
  ElevationFloorplans,
} from 'graphql/gen-types'
import {
  FloorName,
  FpImageContainer,
  FpOptTextContainer,
  FpSection,
  FpSectionTitle,
  InstructionTextDark,
  OptsSelectedSection,
} from '../MyHome.styles'
import { DEFAULT_FP_PAGE_SECTION_ORDER, FLOORPLAN_DESIGN } from '../constants'
import { HomeSelection, MyHomePageVariants } from '../MyHome'
const { isInventoryLot } = siteplans

type MyHomeFloorplanProps = {
  variant?: MyHomePageVariants
  uiConfig: any
  builderAppConfig: any
  selection: HomeSelection
  client: Client
  community: Community
  plan: Plan
  elevation: Elevation
  floorplans: ElevationFloorplans
  onRequestToChange: (target: string) => void
  onSetFloor: (fnum: number) => void
}

export default function MyHomeFloorplan({
  uiConfig,
  variant,
  builderAppConfig,
  selection,
  client,
  community,
  plan,
  elevation,
  floorplans,
  onRequestToChange,
  onSetFloor,
}: MyHomeFloorplanProps): JSX.Element | null {
  let floorCount = 0
  const isMyHome = variant === 'myHome'
  const isEditable = selection.lot && !isInventoryLot(selection.lot) && isMyHome
  const handleRequestToChange = (target: string) => {
    onRequestToChange(target)
  }

  const fpPageSectionChoices = useMemo(() => {
    const defaultOrder = DEFAULT_FP_PAGE_SECTION_ORDER
    const sectionOrder =
      builderAppConfig?.page?.floorplan?.sectionOrder || defaultOrder
    return sectionOrder
  }, [builderAppConfig])
  const hasFloorplans = useMemo(() => {
    return (
      fpPageSectionChoices.includes(FLOORPLAN_DESIGN) &&
      floorplans?.stories?.length
    )
  }, [fpPageSectionChoices, floorplans])

  if (!hasFloorplans) {
    return null
  }

  const floorplanSections = floorplans?.stories?.map((story: any) => {
    const optsSelected =
      Object.keys(selection.fpOptSelections)?.length &&
      selection.fpOptSelections[story.fnum]
    if (!hasBaseFloorplan(story, optsSelected)) {
      return null
    }
    floorCount += 1
    const width = uiConfig.mobile
      ? window.innerWidth * 0.8
      : window.innerWidth * 0.7 - 64 // padding * 2
    const height = 0.66667 * width
    const mirrorImageDisplay = mirrorElevationImage(elevation, uiConfig)
    return (
      <FpSection key={`fpSection-${story.name}`}>
        <OptsSelectedSection>
          <FloorName variant="h4">{story.name}</FloorName>
          {optsSelected ? (
            <React.Fragment>
              <Typography variant="h5">Selected Options:</Typography>
              {optsSelected.map((opt: any) => {
                const { cost, size } = opt
                let secondaryText
                const pricingEnabled = community?.pricing
                if (cost && pricingEnabled) {
                  secondaryText = cost
                    .toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })
                    .split('.')[0]
                }
                if (size && size > 0) {
                  secondaryText += ` | +${size} SQ FT`
                }
                return (
                  <FpOptTextContainer key={`opts-${opt.name}`}>
                    <Typography variant="h6">{opt.name}</Typography>
                    {secondaryText && (
                      <Typography variant="h6">{secondaryText}</Typography>
                    )}
                  </FpOptTextContainer>
                )
              })}
            </React.Fragment>
          ) : null}
        </OptsSelectedSection>
        <FpImageContainer
          style={{ cursor: isEditable ? 'pointer' : 'initial' }}
          onClick={() => {
            if (isEditable) {
              onSetFloor(story.fnum)
              handleRequestToChange('FLOORPLAN_DESIGN')
            }
          }}
        >
          <FloorplanImage
            interactive={false}
            client={client}
            community={community}
            communityName={community.name}
            communityPricing={community.pricing}
            planName={plan.name}
            elevation={elevation}
            stories={floorplans.stories}
            plan={plan}
            story={story}
            fpGroups={story.fpGroups}
            fnum={story.fnum}
            floorplans={story.floorPlans}
            elevationId={elevation.id}
            fpOptSelections={selection.fpOptSelections} // can be undefined
            width={width}
            height={height}
            mirrored={mirrorImageDisplay}
            apiKey={API_KEY}
          />
          {isEditable && (
            <InstructionTextDark>click image to design</InstructionTextDark>
          )}
        </FpImageContainer>
      </FpSection>
    )
  })
  const storiesTitle =
    floorCount > 1
      ? `Floorplan: ${floorCount} stories`
      : 'Floorplan: single story'
  return (
    <React.Fragment>
      <FpSectionTitle>
        <LineSeparator title={`${storiesTitle}`} />
      </FpSectionTitle>
      {floorplanSections}
    </React.Fragment>
  )
}
