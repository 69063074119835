import { LocationsSelect } from '../LocationsSelect'
import { ReactElement, useCallback } from 'react'
import React from 'react'
import {
  LocationSelection,
  OptionMap,
  SetState,
  SharedFilterArgs,
} from '../../Shared'

interface MetroFilterArgs extends SharedFilterArgs {
  metros: OptionMap<number>
  selection: LocationSelection
  setSelection: SetState<LocationSelection>
}

export default function MetroFilter({
  metros,
  selection,
  setSelection,
  larger = false,
  industryTrendsSelect = false,
  locationsFilterSelect = false,
  leadsLocationSelect = false,
}: MetroFilterArgs): ReactElement | null {
  const handleSelect = useCallback(
    (region?: number): void => {
      setSelection((prev) => {
        return {
          country: prev.country,
          us: {
            state: prev.us?.state,
            county: prev.us?.county,
            metro: region,
          },
          can: {},
        }
      })
    },
    [setSelection]
  )

  return (
    <LocationsSelect<number>
      field="region"
      name="Region"
      items={metros}
      selected={selection.us?.metro}
      onSelect={handleSelect}
      locationsFilterSelect={locationsFilterSelect}
      industryTrendsSelect={industryTrendsSelect}
      leadsLocationSelect={leadsLocationSelect}
      larger={larger}
    />
  )
}
