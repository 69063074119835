import { gql } from '@apollo/client'

export const GET_CLIENT_RESERVATION_INTEGRATION = gql`
  query GET_CLIENT_RESERVATION_INTEGRATION($clientId: Int!) {
    getReservationIntegration(clientId: $clientId) {
      id
      reservationIntegrationInfo {
        id
        stripeAccountId
        stripeAccountReady
        depositAmount
        nonRefundableAmount
        anewgoFee
        signingAgreementTemplateId
        allowMultipleReservations
        reservationConfig
        primaryBuyOnlineContactEmail
        primaryBuyOnlineContactName
        daysToProveFinancing
        envelopeExpireDays
        onboardingComplete
        additionalBuilders {
          name
          email
        }
      }
    }
  }
`

export const GET_ALL_PROSPECT_RESERVATIONS = gql`
  query GET_ALL_PROSPECT_RESERVATIONS(
    $clientName: String!
    $filter: OnlineReservationFilterInput
  ) {
    getOnlineReservations(clientName: $clientName, filter: $filter) {
      id
      primaryBuyerId
      favoriteId
      reservationStatus
      prospectId
      rejectReason
      isDeleted
      createdAt
      totalPrice
      favorite {
        id
        elevation(clientName: $clientName) {
          id
          planName
          caption
        }
        community(clientName: $clientName) {
          id
          name
        }
        lot(clientName: $clientName) {
          id
          name
        }
      }
      prospect {
        id
        email
        firstName
        lastName
        phone
        name
      }
      errorArray
      signatureDate
      paymentDate
      signTransactionId
      paymentsTransactionId
      secondaryBuyerReservations {
        signatureDate
        signTransactionId
        prospectId
        prospect {
          id
          email
          name
          firstName
          lastName
          phone
        }
      }
    }
  }
`

export const ADD_ONLINE_RESERVATION_INTEGRATION = gql`
  mutation ADD_ONLINE_RESERVATION_INTEGRATION($clientId: Int!) {
    createReservationIntegration(clientId: $clientId)
  }
`

export const REJECT_RESERVATION = gql`
  mutation REJECT_RESERVATION(
    $clientName: String!
    $reservationId: UUID!
    $rejectReason: String
  ) {
    rejectOnlineReservation(
      clientName: $clientName
      reservationId: $reservationId
      rejectReason: $rejectReason
    ) {
      id
      reservationStatus
    }
  }
`
export const UPDATE_ONLINE_RESERVATION_INTEGRATION_INFO = gql`
  mutation UPDATE_ONLINE_RESERVATION_INTEGRATION_INFO(
    $clientId: Int!
    $input: UpdateOnlineReservationIntegrationInput!
  ) {
    updateReservationIntegrationInfo(clientId: $clientId, input: $input) {
      id
      reservationIntegrationInfo {
        id
        stripeAccountId
        depositAmount
        nonRefundableAmount
        anewgoFee
        signingAgreementTemplateId
        allowMultipleReservations
        reservationConfig
        primaryBuyOnlineContactEmail
        primaryBuyOnlineContactName
        daysToProveFinancing
        envelopeExpireDays
        additionalBuilders {
          email
          name
        }
      }
    }
  }
`
export const CREATE_STRIPE_ACCOUNT = gql`
  mutation CREATE_STRIPE_ACCOUNT($clientId: Int!) {
    createStripeAccount(clientId: $clientId)
  }
`

export const GET_DOCUMENT_PREVIEW_URL = gql`
  query GET_DOCUMENT_PREVIEW_URL($clientId: Int!) {
    getDocuSignTemplatePreview(clientId: $clientId)
  }
`

export const GET_BUYER_DOCUMENT_LINK = gql`
  query GET_BUYER_DOCUMENT_LINK($clientName: String!, $reservationId: UUID!) {
    getDocumentLinkForBuilder(
      clientName: $clientName
      reservationId: $reservationId
    )
  }
`

export const UPDATE_RESERVATION_CUSTOM_TEMPLATE_FIELDS = gql`
  mutation UPDATE_RESERVATION_CUSTOM_TEMPLATE_FIELDS(
    $clientId: Int!
    $templateId: String!
  ) {
    updateReservationCustomTemplateFields(
      clientId: $clientId
      templateId: $templateId
    )
  }
`

export const TOGGLE_COMMUNITY_BUY_ONLINE_ENABLED = gql`
  mutation toggleCommunityByOnlineEnabled(
    $clientName: String!
    $communityId: Int!
    $enabled: Boolean!
  ) {
    toggleCommunityBuyOnlineEnabled(
      clientName: $clientName
      communityId: $communityId
      enabled: $enabled
    )
  }
`

export const TOGGLE_BUY_ONLINE_ENABLED = gql`
  mutation toggleByOnlineEnabled($clientId: Int!, $enabled: Boolean!) {
    toggleBuyOnlineEnabled(clientId: $clientId, enabled: $enabled)
  }
`

export const TOGGLE_ALLOW_MULTIPLE_RESERVATIONS = gql`
  mutation toggleAllowMultipleReservations(
    $clientId: Int!
    $enabled: Boolean!
  ) {
    toggleAllowMultipleReservations(clientId: $clientId, enabled: $enabled)
  }
`

export const ADD_OR_UPDATE_COMMUNITY_BUY_ONLINE_CUSTOM_CONFIGURATION_TEMPLATE_VALUES = gql`
  mutation ADD_OR_UPDATE_COMMUNITY_BUY_ONLINE_CUSTOM_CONFIGURATION_TEMPLATE_VALUES(
    $clientName: String!
    $communityId: Int!
    $configValues: JSON!
  ) {
    addOrUpdateCommunityBuyOnlineCustomConfigTemplateValues(
      clientName: $clientName
      communityId: $communityId
      configValues: $configValues
    )
  }
`

export const GET_COMMUNITY_BUY_ONLINE_CUSTOM_CONFIG = gql`
  query GET_COMMUNITY_BUY_ONLINE_CUSTOM_CONFIG(
    $clientName: String!
    $communityId: Int!
  ) {
    getCommunityBuyOnlineCustomConfig(
      clientName: $clientName
      communityId: $communityId
    ) {
      communityId
      buyOnlineConfig
      buyOnlineEnabled
    }
  }
`

export const DELETE_ONLINE_RESERVATION = gql`
  mutation DELETE_ONLINE_RESERVATION(
    $clientName: String!
    $reservationId: UUID!
  ) {
    deleteOnlineReservation(
      clientName: $clientName
      reservationId: $reservationId
    )
  }
`

export const HAS_COMMUNITY_IN_PROGRESS_RESERVATION = gql`
  query HAS_COMMUNITY_IN_PROGRESS_RESERVATION(
    $clientName: String!
    $communityId: Int!
  ) {
    hasCommunityInProgressReservation(
      clientName: $clientName
      communityId: $communityId
    )
  }
`

export const IS_LOT_SIGN_LOCKED = gql`
  query IS_LOT_SIGN_LOCKED(
    $clientName: String!
    $communityId: Int!
    $lotId: Int!
  ) {
    getIsLotSignLocked(
      clientName: $clientName
      communityId: $communityId
      lotId: $lotId
    ) {
      isLocked
      lockedBy
    }
  }
`

export const LOCK_LOT_SIGN = gql`
  mutation LOCK_LOT_SIGN(
    $clientName: String!
    $communityId: Int!
    $lotId: Int!
    $prospectEmail: String!
  ) {
    lockLotSign(
      clientName: $clientName
      communityId: $communityId
      lotId: $lotId
      prospectEmail: $prospectEmail
    )
  }
`
