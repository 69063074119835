import { styled } from '@mui/material/styles'

const PREFIX = 'SiteplanGrid'

export const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  card: `${PREFIX}-card`,
  cardContent: `${PREFIX}-cardContent`,
}

export const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    paddingTop: '3em',
  },

  [`& .${classes.container}`]: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      margin: '0',
    },
  },

  [`& .${classes.card}`]: {
    width: '100%',
    cursor: 'pointer',
  },

  [`& .${classes.cardContent}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}))
