import { styled } from '@mui/material/styles'
import { Paper } from '@mui/material'

const PREFIX = 'ProspectSessionsOverTimeChard'

export const classes = {
  container: `${PREFIX}-loader`,
  title: `${PREFIX}-title`,
}

export const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.container}`]: {
    [theme.breakpoints.down('md')]: {
      height: 400,
    },
    padding: 16,
  },
  [`& .${classes.title}`]: {
    textAlign: 'center',
    color: '#1F7396',
  },
}))
