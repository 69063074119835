import React, { useContext, useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import ComponentCard from 'components/common/layout/ComponentCard'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import InputLabel from '@mui/material/InputLabel'
import {
  useSetNewLeadAgentNotificationConfigMutation,
  useGetNewLeadAgentNotificationConfigQuery,
} from 'graphql/gen-types'
import { snackbarConfigAtom } from 'store/atoms'
import { AppStore } from 'store'

const COMPONENT_TITLE = 'New Lead Notifications'
const ON_SUCCESS_MESSAGE = 'Notification Configuration Set.'
const SKELETON_HEIGHT = 136

export default function NewProspectNotificationManager(): JSX.Element {
  const { appState } = useContext(AppStore)
  const clientName = appState.selectedClient?.altName || ''
  const [snackbarConfig, setSnackbarConfig] = useAtom(snackbarConfigAtom)

  const [enabled, setEnabled] = useState(false)
  const [
    setNewLeadAgentNotificationConfig,
    { error: mutationError },
  ] = useSetNewLeadAgentNotificationConfigMutation()

  const { data, error, loading } = useGetNewLeadAgentNotificationConfigQuery({
    variables: { clientName },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    const config = data?.getNewLeadAgentNotificationConfig
    if (config) {
      setEnabled(config.enabled)
    }
  }, [data?.getNewLeadAgentNotificationConfig])

  const handleReceiveNotificationChange = (value: string): void => {
    setEnabled(value === 'enable')
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    // Call mutation to edit config
    setNewLeadAgentNotificationConfig({
      variables: {
        clientName,
        enabled,
      },
    })

    if (mutationError) {
      setSnackbarConfig({
        ...snackbarConfig,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        autoHideDuration: 6000,
        open: true,
        message: mutationError.message,
        severity: 'error',
      })
    } else {
      setSnackbarConfig({
        ...snackbarConfig,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        autoHideDuration: 6000,
        open: true,
        message: ON_SUCCESS_MESSAGE,
        severity: 'success',
      })
    }
  }

  return (
    <ComponentCard
      title={COMPONENT_TITLE}
      result={{ error, loading }}
      skeletonHeight={SKELETON_HEIGHT}
    >
      <Box style={{ paddingBottom: 16 }}>
        <Grid container justifyContent="flex-start">
          <Typography variant="subtitle1">
            Manage New Lead notifications.
          </Typography>
        </Grid>
      </Box>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} justifyContent="space-evenly">
          <Grid item xs={12} sm={10} md={10} lg={5}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="receive-select-label">
                Receive Notifications
              </InputLabel>
              <Select
                variant="standard"
                labelId="demo-simple-select-outlined-label"
                onChange={(event: SelectChangeEvent<string>) =>
                  handleReceiveNotificationChange(event.target.value)
                }
                data-testid="new-prospect-notification-select"
                value={enabled ? 'enable' : 'disable'}
                label="Receive Notifications"
              >
                <MenuItem value={'enable'}>Enabled</MenuItem>
                <MenuItem value={'disable'}>Disabled</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              data-testid="new-prospect-notification-submit"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </ComponentCard>
  )
}
