import * as React from 'react'
import Grid from '@mui/material/Grid'
import InfiniteScroll from 'components/common/layout/InfiniteScroll'
import { AppStore } from 'store'
import VisitorTrends from 'components/leads/analytics/VisitorTrends'
import LeadsFilterBar, {
  ProspectScoreSelection,
  ProspectStatusSelection,
} from 'components/leads/filter-bar/LeadsFilterBar'
import { useContext, useState, useEffect } from 'react'
import {
  Community,
  CommonQueryFilter,
  useLeadRankingsQuery,
  LeadsQueryFilter,
  LeadRanking,
  DateFilterField,
  LeadScores,
} from 'graphql/gen-types'
import { AnalyticsStore } from 'store/analyticsStore'
import LeadsByCommunityChart from '../../components/leads/LeadsByCommunityChart'
import LeadRankingsChart from 'components/leads/LeadRankingsChart'
import VisitorTimes from 'components/leads/analytics/VisitorTimes'
import { classes, Root } from './LeadsAnalytics.syles'
import { useCommonQueryFilter } from '../../components/analytics/locations-filters/hooks/useCommonQueryFilter'
import { LocationSelection } from '../../components/analytics/locations-filters/components/Shared'
import useLocationFilters from 'components/analytics/locations-filters/hooks/useLocationFilters'
const GRID_SPACING = 2

export const filterByScore = (
  data: LeadRanking[],
  selectedScores: ProspectScoreSelection[]
): LeadRanking[] => {
  const selectedScoreValues = selectedScores.map(
    (score: ProspectScoreSelection) => score.value
  )
  // If no filter is selected or all values are selected return data
  if (selectedScoreValues.length === 3 || selectedScoreValues.length === 0) {
    return data
  } else {
    return data.filter((lead: LeadRanking) => {
      const score = lead?.score || 0
      if (selectedScoreValues.includes(LeadScores.Less_30) && score <= 0.3) {
        return true
      } else if (
        selectedScoreValues.includes(LeadScores.Between_30_60) &&
        score > 0.3 &&
        score <= 0.6
      ) {
        return true
      } else {
        return (
          selectedScoreValues.includes(LeadScores.Greater_60) && score > 0.6
        )
      }
    })
  }
}

export const filterByStatus = (
  data: LeadRanking[],
  selectedStatuses: ProspectStatusSelection[]
): LeadRanking[] => {
  if (selectedStatuses.length === 0) {
    return data
  }
  return data.slice().filter((lead: LeadRanking): boolean => {
    const selectedStatusValues = selectedStatuses.map(
      (statusOption: ProspectStatusSelection): string | undefined | null =>
        statusOption.value
    )
    return selectedStatusValues.includes(lead?.status)
  })
}

function LeadsAnalytics(): JSX.Element {
  const { appState } = useContext(AppStore)
  const { analyticsState } = useContext(AnalyticsStore)
  const { selectedClient } = appState
  const { startDate, endDate } = analyticsState
  const clientName = selectedClient?.altName || ''

  const [selectedCommunities, setSelectedCommunities] = useState<Community[]>(
    []
  )
  const [locationSelection, setLocationSelection] = useState<LocationSelection>(
    {
      us: {},
      can: {},
    }
  )

  const [selectedScores, setSelectedScores] = useState<
    ProspectScoreSelection[]
  >([])
  const [selectedStatuses, setSelectedStatuses] = useState<
    ProspectStatusSelection[]
  >([])
  const [leadRankings, setLeadRankings] = useState<LeadRanking[]>([])

  const { allCommunitiesData } = useLocationFilters({
    clientName,
    startDate,
    endDate,
  })

  const visitorsFilter: CommonQueryFilter = useCommonQueryFilter({
    clientName: clientName,
    startDate,
    endDate,
    selection: {
      us: {},
      can: {},
    }, // communityIdCollection is enough.
    communityIdCollection:
      selectedCommunities.length > 0 &&
      allCommunitiesData.length !== selectedCommunities.length // If all is selected, send undefined.
        ? selectedCommunities.map((comm) => comm.id)
        : undefined,
  })

  const commonFilter: CommonQueryFilter = useCommonQueryFilter({
    clientName: clientName,
    startDate,
    endDate,
    selection: locationSelection,
    limit: 10000,
    communities:
      selectedCommunities.length > 0
        ? selectedCommunities.map((comm: Community) => comm.id)
        : undefined,
  })

  const leadsFilter: LeadsQueryFilter = {
    common: {
      ...visitorsFilter,
      startTime: startDate.getTime(),
      endTime: endDate.getTime(),
    },
    leads: { dateFilterField: DateFilterField.LastSeen },
  }

  const { data, loading, error } = useLeadRankingsQuery({
    variables: {
      filter: leadsFilter,
    },
  })
  const leadRankingsData = data?.leadRankings
  const clientEmail = selectedClient?.email

  useEffect(() => {
    if (leadRankingsData?.leads) {
      const filteredData = leadRankingsData.leads.filter(
        (lead) => lead?.prospectId
      )
      setLeadRankings(filteredData as LeadRanking[])
    }
  }, [clientEmail, leadRankingsData])

  const displayLeads = filterByScore(
    filterByStatus(leadRankings, selectedStatuses),
    selectedScores
  )

  const sectionComponents = [
    <Root>
      <Grid
        container
        className={classes.componentContainer}
        spacing={GRID_SPACING}
      >
        <Grid item xs={12} lg={12}>
          <VisitorTrends
            filter={visitorsFilter}
            commonFilter={commonFilter}
            selectedCommunities={selectedCommunities}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={6}>
          <VisitorTimes filter={commonFilter} />
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.componentContainer}
        spacing={GRID_SPACING}
      >
        <Grid item lg={6}>
          <LeadRankingsChart
            chartData={displayLeads}
            result={{
              loading: loading,
              error: error,
              data: displayLeads,
            }}
          />
        </Grid>
        <Grid item lg={6}>
          <LeadsByCommunityChart
            chartData={displayLeads}
            communities={selectedCommunities}
            result={{
              loading: loading,
              error: error,
              data: displayLeads,
            }}
          />
        </Grid>
      </Grid>
    </Root>,
  ]

  return (
    <React.Fragment>
      <LeadsFilterBar
        onSelectedScores={setSelectedScores}
        onSelectedStatuses={setSelectedStatuses}
        onSetLeadChartSelectedCommunities={(communities) =>
          setSelectedCommunities(communities || [])
        }
        onLocationSelection={setLocationSelection}
      />
      <Grid container>
        <InfiniteScroll
          sectionComponents={sectionComponents}
          rootless={false}
        />
      </Grid>
    </React.Fragment>
  )
}

export default LeadsAnalytics
