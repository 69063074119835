import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'

const PREFIX = 'RejectReservationModal'

export const classes = {
  closeButton: `${PREFIX}-closeButton`,
  container: `${PREFIX}-container`,
  textArea: `${PREFIX}-textArea`,
}

export const StyledDialog = styled(Dialog)(() => ({
  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: 0,
    top: 4,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  [`& .${classes.container}`]: {
    background: 'transparent',
  },
  [`& .${classes.textArea}`]: {
    margin: '0 24px 8px',
    width: 'calc(100% - 48px)',
  },
}))
