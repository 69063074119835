import { LocationsSelect } from '../LocationsSelect'
import { ReactElement, useCallback } from 'react'
import React from 'react'
import {
  LocationSelection,
  OptionMap,
  SetState,
  SharedFilterArgs,
} from '../Shared'

interface PostCodeFilterArgs extends SharedFilterArgs {
  postCodes: OptionMap<string>
  selection: LocationSelection
  setSelection: SetState<LocationSelection>
}

export default function PostCodeFilter({
  postCodes,
  selection,
  setSelection,
  larger = false,
  industryTrendsSelect = false,
  locationsFilterSelect = false,
  leadsLocationSelect = false,
}: PostCodeFilterArgs): ReactElement | null {
  const handleSelect = useCallback(
    (postCode?: string): void => {
      setSelection((prev) => ({
        country: prev.country,
        us: {},
        can: {
          ...prev.can,
          postCode: postCode,
        },
      }))
    },
    [setSelection]
  )

  return (
    <LocationsSelect<string>
      field="postcode"
      name="Post code"
      items={postCodes}
      selected={selection.can?.postCode}
      onSelect={handleSelect}
      locationsFilterSelect={locationsFilterSelect}
      industryTrendsSelect={industryTrendsSelect}
      leadsLocationSelect={leadsLocationSelect}
      larger={larger}
    />
  )
}
