import React, { useContext } from 'react'
import { Prospect, ContactMethodType } from '../../../graphql/gen-types'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import Avatar from '@mui/material/Avatar'
import Checkbox from '@mui/material/Checkbox'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import ProspectStatusSelect from 'components/common/custom-inputs/ProspectStatusSelect'
import { AuthContext } from 'auth/AuthContext'
import ProspectClientRatingInput from 'components/common/custom-inputs/ProspectClientRatingInput'
import IconButton from '@mui/material/IconButton'
import InfoIcon from '@mui/icons-material/Info'
import DateFnsAdapter from '@date-io/date-fns'
import { LeadRanking } from '../../../graphql/gen-types'
import { LEAD_SCORE_OUT_OF } from 'constants/index'
import { getNamesFromFullName } from '../../../utils/functions'
import { getProspectLocation } from 'utils/functions'
import Tooltip from '@mui/material/Tooltip'

import { ProspectProfileStyled, classes } from './styles/ProspectProfile.styles'
import Skeleton from '@mui/material/Skeleton'

const dateFns = new DateFnsAdapter()

function formatDateTime(
  date: string | number | Date | null | undefined
): string {
  return dateFns.format(new Date(date || new Date()), 'MM-dd-yyyy')
}

const COMPONENT_TITLE = 'Profile'
const PIC_PREAMBLE =
  'https://res.cloudinary.com/renderinghouse/image/upload/v1610726971/anewgo/prospects'
interface ProspectProfileProps {
  data: Prospect | undefined | null
  rankingData?: LeadRanking | undefined | null
  loading?: boolean
  error?: Error | undefined
  size?: 'large' | 'small' | undefined
}

function ProspectProfile({
  data,
  rankingData,
  loading,
  error,
  size = 'large',
}: ProspectProfileProps): JSX.Element {
  const prospect = data
  const { user } = useContext(AuthContext)
  const { roleId } = user
  const [firstName, lastName] = getNamesFromFullName(prospect?.name)

  if (loading) {
    return (
      <ProspectProfileStyled className={classes.container}>
        <Typography variant="h5" className={classes.title}>
          {COMPONENT_TITLE}
        </Typography>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={230}
          data-testid="ProfileDataLoading"
        />
      </ProspectProfileStyled>
    )
  }

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized
          message={error.toString()}
          imageName={'ProspectProfile'}
        ></Unauthorized>
      )
    }
    return <div>{error.toString()}</div>
  }
  const picUrl = prospect?.picture ? `${PIC_PREAMBLE}/${prospect?.picture}` : ''
  const stats = rankingData

  const displayStats: JSX.Element = (
    <div className={classes.stats}>
      {stats?.hotLead !== undefined && (
        <ListItem>
          <Typography variant="body1">
            Hot Lead:{' '}
            {stats?.hotLead ? (
              <span className={classes.hotLeadStatus}>Yes</span>
            ) : (
              'No'
            )}
          </Typography>
          <Tooltip
            arrow
            title="Leads with high activity and interest early in the sales process. These are leads that registered within the past two months, never contacted the builder, and have engagement scores similar to leads that do click the contact us button."
          >
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </ListItem>
      )}
      {stats?.score !== undefined && (
        <ListItem>
          <ListItemText className={classes.label}>
            <Typography>
              Engagement Score:{' '}
              <span className={classes.value}>{`${Math.round(
                (stats.score || 0) * LEAD_SCORE_OUT_OF
              )}/${LEAD_SCORE_OUT_OF}`}</span>
              <Tooltip
                arrow
                title="Measurement of user browsing and interaction with the My Home App. The higher the score, the more active and engaged the lead is."
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Typography>
          </ListItemText>
        </ListItem>
      )}
    </div>
  )
  return size === 'large' ? (
    <ProspectProfileStyled className={classes.container}>
      <Typography variant="h5" className={classes.title}>
        {COMPONENT_TITLE}
      </Typography>
      <div className={classes.itemContainer}>
        <div>
          <List dense={true} disablePadding={false} className={classes.list}>
            <ListItem className={classes.avatarItem}>
              <ListItemAvatar>
                <Avatar src={picUrl} className={classes.avatar} />
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{ variant: 'h6' }}
                primary={`${firstName}${lastName ? ` ${lastName}` : ''}`}
              />
            </ListItem>
            <ListItem>
              <Typography variant="body1">
                {prospect?.email || 'No email address'}
              </Typography>
            </ListItem>
            {prospect?.phone ? (
              <ListItem>
                <Typography variant="h6">{prospect.phone}</Typography>
              </ListItem>
            ) : null}

            {prospect?.statistics?.country ? (
              <ListItem>
                <Typography variant="body1">
                  {getProspectLocation(prospect)}
                </Typography>
              </ListItem>
            ) : null}

            {rankingData && displayStats}
          </List>
        </div>
        <div
          className={classes.item}
          style={{
            visibility: prospect?.preferredContactMethods ? 'unset' : 'hidden',
          }}
        >
          <Typography variant="h6">Preferred Contact</Typography>
          <List
            dense={true}
            disablePadding={true}
            className={classes.checkboxList}
          >
            <ListItem>
              <Checkbox
                size="small"
                className={classes.check}
                checked={
                  prospect?.preferredContactMethods?.includes(
                    ContactMethodType.Email
                  ) || false
                }
                disabled
              ></Checkbox>
              <ListItemText primary="Email"></ListItemText>
            </ListItem>
            <ListItem>
              <Checkbox
                size="small"
                className={classes.check}
                checked={
                  prospect?.preferredContactMethods?.includes(
                    ContactMethodType.Phone
                  ) || false
                }
                disabled
              ></Checkbox>
              <ListItemText primary="Phone"></ListItemText>
            </ListItem>
            <ListItem>
              <Checkbox
                size="small"
                className={classes.check}
                checked={
                  prospect?.preferredContactMethods?.includes(
                    ContactMethodType.Text
                  ) || false
                }
                disabled
              ></Checkbox>
              <ListItemText primary="Text"></ListItemText>
            </ListItem>
            <ListItem>
              <Checkbox
                size="small"
                className={classes.check}
                checked={
                  prospect?.preferredContactMethods?.includes(
                    ContactMethodType.VideoConference
                  ) || false
                }
                disabled
              ></Checkbox>
              <ListItemText primary="Video Conference"></ListItemText>
            </ListItem>
          </List>
        </div>
        <div className={classes.item}>
          <Typography variant="h6" className={classes.statusListItem}>
            <ProspectStatusSelect
              prospectId={data?.id}
              prospectStatus={data?.status}
              prospectEmail={data?.email}
              disabled={roleId > 4}
            />
          </Typography>
          <Typography variant="h6" className={classes.clientRating}>
            <ProspectClientRatingInput prospect={data} />
          </Typography>
        </div>
      </div>
    </ProspectProfileStyled>
  ) : (
    <ProspectProfileStyled className={classes.container}>
      <List dense={true} disablePadding={false} className={classes.list}>
        <ListItem className={classes.avatarItem}>
          <ListItemAvatar>
            <Avatar src={picUrl} className={classes.avatar} />
          </ListItemAvatar>
          <List>
            <ListItem>
              <Typography variant="h6">
                {firstName}
                {lastName ? ` ${lastName}` : ''}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body1">{prospect?.email || ''}</Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body1">{prospect?.phone || ''}</Typography>
            </ListItem>
            {prospect?.statistics?.country ? (
              <ListItem>
                <Typography variant="body1">
                  {getProspectLocation(prospect)}
                </Typography>
              </ListItem>
            ) : null}
            <ListItem>
              <Typography variant="body1">
                Registered: {formatDateTime(prospect?.registrationDate) || ''}
              </Typography>
            </ListItem>
            {rankingData && displayStats}
          </List>
        </ListItem>
      </List>
    </ProspectProfileStyled>
  )
}

export default ProspectProfile
