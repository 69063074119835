import { gql } from '@apollo/client'

export const SEND_INSIGHTS_ONLINE_RESERVATION = gql`
  mutation sendInsightsOnlineReservationInquiry(
    $clientName: String!
    $userEmail: EmailAddress!
    $userPhone: String
  ) {
    sendInsightsOnlineReservationInquiry(
      clientName: $clientName
      userEmail: $userEmail
      userPhone: $userPhone
    )
  }
`
