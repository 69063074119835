import React from 'react'
import Typography from '@mui/material/Typography'
import DateRangeIcon from '@mui/icons-material/DateRange'
import Divider from '@mui/material/Divider'
import { classes, Root } from './DateDisplay.styles'
interface DateDisplayProps {
  label: string
  date: Date
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

function DateDisplay({ label, date, onClick }: DateDisplayProps): JSX.Element {
  const getFormattedDate = (date: Date) => {
    let dd: string | number = date.getDate()
    let mm: string | number = date.getMonth() + 1
    const yyyy = date.getFullYear()
    if (dd < 10) {
      dd = '0' + dd
    }

    if (mm < 10) {
      mm = '0' + mm
    }

    return mm + '/' + dd + '/' + yyyy
  }

  return (
    <Root className={classes.dateDisplay} onClick={onClick}>
      {label}
      <div style={{ display: 'flex', marginLeft: 10 }}>
        <DateRangeIcon />
        <Typography variant="subtitle1" style={{ marginLeft: 10 }}>
          {getFormattedDate(date)}
        </Typography>
      </div>
      <Divider />
    </Root>
  )
}

export default DateDisplay
