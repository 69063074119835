import React from 'react'
import { Lot } from 'graphql/gen-types'
import ResponsiveTable from 'components/common/tables/ResponsiveTable'
import ResponsiveCell from '../tables/ResponsiveTable/ResponsiveCell'
import { classes, Root } from './LotLeadsTable.styles'
interface LotLeadsTableProps {
  lots: Lot[]
  onLotClick?: (lot: Lot) => void
}

const LotLeadsTable = ({
  lots,
  onLotClick,
}: LotLeadsTableProps): JSX.Element => {
  const openLotLeadsDialog = (row: unknown) => {
    const lot = row as Lot
    if (onLotClick) {
      onLotClick(lot)
    }
  }

  const columns = [
    <ResponsiveCell align="center" key="FirstNameHeader">
      <strong>Lot name</strong>
    </ResponsiveCell>,

    <ResponsiveCell align="center" key="LastNameHeader">
      <strong>Popularity</strong>
    </ResponsiveCell>,

    <ResponsiveCell align="center" key="EmailHeader">
      <strong>Leads Count</strong>
    </ResponsiveCell>,
  ]

  const generateRow = (dataRow: Lot): JSX.Element => {
    return (
      <>
        <ResponsiveCell align="center" key="row-cell-user-firstName">
          {dataRow?.name || '-'}
        </ResponsiveCell>
        <ResponsiveCell align="center" key="row-cell-user-lastName">
          {dataRow?.popularity || '0'}
        </ResponsiveCell>
        <ResponsiveCell align="center" key="row-cell-user-email">
          {dataRow?.leadsCount || '0'}
        </ResponsiveCell>
      </>
    )
  }

  const orderedRows = lots.sort((a, b) => {
    if (!a.leadsCount && !b.leadsCount) {
      return 0
    }
    if (!a.leadsCount) {
      return 1
    }
    if (!b.leadsCount) {
      return -1
    }
    if (a.leadsCount > b.leadsCount) {
      return -1
    }
    if (a.leadsCount < b.leadsCount) {
      return 1
    }

    return 0
  })

  return (
    <Root className={classes.tableContainer}>
      <ResponsiveTable
        data={orderedRows || []}
        columns={columns}
        generateRow={generateRow}
        onRowClick={openLotLeadsDialog}
        dense
        initialRows={5}
        rowsPerPageOptions={[]}
      />
    </Root>
  )
}

export default LotLeadsTable
