import React, { ReactElement, ReactNode } from 'react'
import { Community } from 'graphql/gen-types'
import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import DistrictFilter from './components/can/DistrictFilter'
import MetroFilter from './components/us/MetroFilter'
import CitiesFilter from './components/CitiesFilter'
import CommunitiesFilter from './components/CommunitiesFilter'
import FiltersDialog, { FilterProps } from './components/FiltersDialog'
import { useLocationSelection } from './hooks/useLocationSelection'
import { useCountries } from './hooks/useCountries'
import usePrepareLocationSelection from './hooks/usePrepareLocationSelection'
import { LocationSelection, SetState } from './Shared'

interface LocationsFiltersProps {
  classes?: any
  expanded: boolean
  allCommunities: Community[]
  filteredCommunities: Community[]
  selection: LocationSelection
  filterProps: FilterProps
  selectedCommunities: Community[] | undefined
  setSelectedCommunities: SetState<Community[] | undefined>
  setOpenDialog: SetState<boolean>
  setDialogMessage: SetState<string>
  onSelectedCommunities?: (communities: Community[] | undefined) => void
  onLocationSelection?: SetState<LocationSelection>
  children?: ReactNode
}

function LocationsFilters({
  expanded,
  allCommunities,
  filteredCommunities,
  selection,
  filterProps,
  selectedCommunities,
  setSelectedCommunities,
  setOpenDialog,
  setDialogMessage,
  onSelectedCommunities,
  onLocationSelection,
  children,
}: LocationsFiltersProps): ReactElement | null {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down('xs'))
  const xsSm = useMediaQuery(theme.breakpoints.down(760))
  const sm = useMediaQuery(theme.breakpoints.down(900))
  const md = useMediaQuery(theme.breakpoints.down(1150))

  // Update the values for location selection depending on the other location fields
  // For example, only display cities within the selected region.
  const locationSelection = useLocationSelection(allCommunities, selection)
  usePrepareLocationSelection(locationSelection, selection, onLocationSelection)
  const {
    countries,
    cities,
    us: { metros },
    can: { districts },
  } = locationSelection

  const { us, can } = useCountries(selection, countries)

  const filters = (
    <FiltersDialog
      sm={sm}
      xs={xs}
      xsSm={xsSm}
      selection={selection}
      locationSelection={locationSelection}
      filterProps={filterProps}
    >
      {md && (
        <Grid
          container
          item
          justifyContent="center"
          alignItems="flex-start"
          direction="row"
        >
          <Grid item>
            <CitiesFilter cities={cities} {...filterProps} larger />
          </Grid>
          <Grid item>{children}</Grid>
        </Grid>
      )}
      {sm && (
        <CommunitiesFilter
          filteredCommunities={filteredCommunities}
          selectedCommunities={selectedCommunities}
          setSelectedCommunities={setSelectedCommunities}
          setOpenDialog={setOpenDialog}
          setDialogMessage={setDialogMessage}
          onSelectedCommunities={onSelectedCommunities}
        />
      )}
    </FiltersDialog>
  )

  if (!expanded || md) return filters
  return (
    <>
      {us && <MetroFilter metros={metros} {...filterProps} />}
      {can && <DistrictFilter districts={districts} {...filterProps} />}
      <CitiesFilter cities={cities} {...filterProps} />
      {children}
      {filters}
    </>
  )
}

export default LocationsFilters
