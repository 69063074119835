import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import useMediaQuery from '@mui/material/useMediaQuery'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'

import { Prospect, Agent, useProspectQuery } from 'graphql/gen-types'
import ProspectProfile from 'components/leads/prospect-page/ProspectProfile'
import ProspectFavorites from 'components/leads/prospect-page/ProspectFavorites'
import ProspectAgentAssign from '../prospect-page/ProspectAgentAssign'
import { StyledDialog, classes } from './LiveLeadDetailModal.styles'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import { useAtom } from 'jotai'
import { snackbarConfigAtom } from 'store/atoms'

const initialProspect: Prospect = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  statistics: null,
  isFirstSignIn: false,
  isRegistered: false,
  colonnadeAgents: [],
}

export interface LeadDetailModalProps {
  data?: Prospect | null
  open: boolean
  onClose: () => void
  agents: Agent[]
}

/**
 * Modal for adding new milestones.
 */
export default function LeadDetailModal({
  data,
  open,
  onClose,
  agents,
}: LeadDetailModalProps): JSX.Element {
  const theme = useTheme()
  const [snackbarConfig, setSnackbarConfig] = useAtom(snackbarConfigAtom)

  const fullWidth = useMediaQuery(theme.breakpoints.down('md'))
  const extraWidth = useMediaQuery(theme.breakpoints.up('lg'))

  const [extendedProspect, setExtendedProspect] = useState(initialProspect)
  const { clientName } = useParams()

  const { data: extendedProspectData, loading, error } = useProspectQuery({
    skip: !data?.email,
    variables: {
      clientName: clientName!,
      email: data?.email || '',
    },
  })
  // TODO: Look into this StackOverflow issue as a possible solution... should not pass a stringified object to useEffect...
  // https://stackoverflow.com/questions/55808749/use-object-in-useeffect-2nd-param-without-having-to-stringify-it-to-json
  useEffect(() => {
    if (extendedProspectData?.colonnadeApiProspectByEmail) {
      setExtendedProspect(extendedProspectData.colonnadeApiProspectByEmail)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(extendedProspectData?.colonnadeApiProspectByEmail)])

  useEffect(() => {
    if (error && verifyUserAuthError(error.toString())) {
      setSnackbarConfig({
        ...snackbarConfig,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        autoHideDuration: 6000,
        open: true,
        message:
          'Insufficient permission to access this feature. Some details may not be available.',
        severity: 'error',
      })
    }
  }, [error])

  if (error) return <div>{error.toString()}</div>

  const isInvalidProspect = data?.id === undefined
  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xl"
      fullWidth={fullWidth}
      scroll="paper"
    >
      <DialogTitle id="form-dialog-title">Lead Details</DialogTitle>
      <Typography
        className={classes.subtitle}
        variant="subtitle2"
        color="textSecondary"
      >
        Check back tomorrow for customer engagement analysis
      </Typography>
      <DialogContent
        classes={{
          root: extraWidth ? classes.extendedContent : classes.content,
        }}
      >
        <Grid container spacing={2}>
          {isInvalidProspect && (
            <Grid item xs={12} lg={12}>
              <Alert severity="warning">
                This Lead has not been updated yet. Additional details are not
                available. Please try again later.
              </Alert>
            </Grid>
          )}
          <Grid item xs={12} md={12} lg={12}>
            <ProspectAgentAssign
              prospect={data}
              agents={agents}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <ProspectProfile data={data} />
          </Grid>
        </Grid>
        <Grid container className={classes.componentContainer}>
          <Grid item xs={12} md={12} lg={12}>
            <ProspectFavorites
              hideNotBrowsedBanner
              data={extendedProspect}
              loading={loading}
              error={error}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}
