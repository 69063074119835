import { ReactElement, useCallback } from 'react'
import React from 'react'
import CommunitiesSelect from '../../../common/custom-inputs/CommunitiesSelect'
import { Community } from '../../../../graphql/gen-types'
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import { SetState } from '../Shared'

const SELECTED_COMMUNITIES_LIMIT = 10

interface CommunitiesFilterArgs {
  filteredCommunities: Community[]
  selectedCommunities: Community[] | undefined
  setSelectedCommunities: SetState<Community[] | undefined>
  setOpenDialog: SetState<boolean>
  setDialogMessage: SetState<string>
  onSelectedCommunities?: (communities: Community[] | undefined) => void
}

export const useCommunitiesSelection = ({
  setSelectedCommunities,
  onSelectedCommunities,
  setOpenDialog,
  setDialogMessage,
}: {
  setSelectedCommunities: SetState<Community[] | undefined>
  setOpenDialog: SetState<boolean>
  setDialogMessage: SetState<string>
  onSelectedCommunities?: (communities: Community[] | undefined) => void
}) =>
  useCallback(
    (comms: Community[]) => {
      if (comms.length > SELECTED_COMMUNITIES_LIMIT) {
        setOpenDialog(true)
        setDialogMessage(
          `Maximum number of concurrent selections is ${SELECTED_COMMUNITIES_LIMIT}. Please deselect a community and try again.`
        )
      } else {
        setSelectedCommunities(comms)
        onSelectedCommunities?.(comms)
      }
    },
    [
      setSelectedCommunities,
      onSelectedCommunities,
      setOpenDialog,
      setDialogMessage,
    ]
  )

export default function CommunitiesFilter({
  filteredCommunities,
  selectedCommunities,
  setSelectedCommunities,
  setOpenDialog,
  setDialogMessage,
  onSelectedCommunities,
}: CommunitiesFilterArgs): ReactElement | null {
  const theme = useTheme()
  const handleCommunitiesSelection = useCommunitiesSelection({
    setSelectedCommunities,
    onSelectedCommunities,
    setOpenDialog,
    setDialogMessage,
  })

  const handleSelect = useCallback(
    (commIds: number[]): void => {
      const comms = filteredCommunities.filter((comm) => {
        return commIds.some((commId) => commId === comm.id)
      })
      handleCommunitiesSelection(comms)
    },
    [filteredCommunities, handleCommunitiesSelection]
  )

  return (
    <Grid style={{ marginTop: theme.spacing(1) }}>
      <CommunitiesSelect
        selectedCommunities={selectedCommunities || []}
        filteredCommunities={filteredCommunities}
        onCommunityChange={handleSelect}
      />
    </Grid>
  )
}
