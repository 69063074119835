import { styled } from '@mui/material/styles'

const PREFIX = 'ErrorPage'

export const classes = {
  root: `${PREFIX}-root`,
  backgroundImage: `${PREFIX}-backgroundImage`,
  message: `${PREFIX}-message`,
}

export const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
    position: 'relative',
    zIndex: theme.zIndex.drawer + 2,
  },

  [`& .${classes.backgroundImage}`]: {
    position: 'absolute',
    top: 0,
    left: 0,
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },

  [`& .${classes.message}`]: {
    color: '#e4f5ff',
    textShadow: '1px 1px 2px #022534',
    textAlign: 'center',
    zIndex: 1,
  },
}))
