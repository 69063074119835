import { LocationsSelect } from '../LocationsSelect'
import { ReactElement, useCallback } from 'react'
import React from 'react'
import {
  LocationSelection,
  OptionMap,
  SetState,
  SharedFilterArgs,
} from '../../Shared'

interface ZipCodeFilterArgs extends SharedFilterArgs {
  zipCodes: OptionMap<number>
  selection: LocationSelection
  setSelection: SetState<LocationSelection>
}

export default function ZipCodeFilter({
  zipCodes,
  selection,
  setSelection,
  larger = false,
  industryTrendsSelect = false,
  locationsFilterSelect = false,
  leadsLocationSelect = false,
}: ZipCodeFilterArgs): ReactElement | null {
  const handleSelect = useCallback(
    (zip?: number): void => {
      setSelection((prev) => ({
        country: prev.country,
        us: {
          ...prev.us,
          zipCode: zip,
        },
        can: {},
      }))
    },
    [setSelection]
  )

  return (
    <LocationsSelect<number>
      field="zip"
      name="Zip"
      items={zipCodes}
      selected={selection.us?.zipCode}
      onSelect={handleSelect}
      locationsFilterSelect={locationsFilterSelect}
      industryTrendsSelect={industryTrendsSelect}
      leadsLocationSelect={leadsLocationSelect}
      larger={larger}
    />
  )
}
