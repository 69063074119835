import React, { useContext, useEffect, useRef, useState } from 'react'
import { useAtom } from 'jotai'
import { useTheme } from '@mui/material/styles'
import clsx from 'clsx'
import AppBar from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import useMediaQuery from '@mui/material/useMediaQuery'
import MuiAlert from '@mui/material/Alert'
import ArrowForwardIcon from '@mui/icons-material/KeyboardBackspace'
import CloseIcon from '@mui/icons-material/Close'
import { AppStore } from 'store'
import { AuthContext } from 'auth/AuthContext'
import { getImagePrefix, getAssetsPrefix } from '@anewgo/utils'
import { checkIsEmail, isDefined } from 'utils/functions'
import { uploadForm } from 'utils/fileUploadHelpers'
import {
  Community,
  Elevation,
  EmailCampaign,
  EmailCampaignCohort,
  EmailCampaignInput,
  LeadByProduct,
  Lot,
  Plan,
  useCommunitiesWithImagesQuery,
  useGetEmailCampaignQuery,
  usePlansWithImagesQuery,
  useCreateEmailCampaignMutation,
  useDeleteEmailCampaignMutation,
  useSendEmailCampaignMutation,
  useUpdateEmailCampaignMutation,
} from '../../../../graphql/gen-types'
import EmailCompositionModalStepOne from './EmailCompositionModalStepOne'
import EmailCompositionModalStepTwo from './EmailCompositionModalStepTwo'
import EmailCompositionModalStepThree from './EmailCompositionModalStepThree'
import { snackbarConfigAtom } from 'store/atoms'
import GET_EMAIL_CAMPAIGNS from 'graphql/nexus-queries/getEmailCampaigns'
import { DialogStyled, classes } from './EmailCompositionModal.styles'

interface EmailCampaignProps {
  emailCampaignId?: number
  selectedLeads?: LeadByProduct[]
  searchTerms?: { community?: string; plan?: string }
  open: boolean
  onClose: () => void
}

export default function EmailComposition({
  emailCampaignId,
  selectedLeads,
  searchTerms,
  open,
  onClose,
}: EmailCampaignProps): JSX.Element {
  const theme = useTheme()
  const { user } = useContext(AuthContext)
  const { appState } = useContext(AppStore)
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const clientName = appState.selectedClient?.altName || ''
  const directoryName = appState.selectedClient?.directoryName || ''

  const dialogContentRef = useRef<HTMLDivElement | null>(null)

  const [newEmailCampaignId, setNewEmailCampaignId] = useState<number | null>(
    null
  )
  const [emailCampaign, setEmailCampaign] = useState<EmailCampaign>(
    {} as EmailCampaign
  )

  const [activeSection, setActiveSection] = useState<number>(1)

  const [warn, setWarn] = useState('')
  const [deleteCampaignWarnOpen, setDeleteCampaignWarnOpen] = useState(false)
  const [testEmailDialogOpen, setTestEmailDialogOpen] = useState(false)
  const [testEmail, setTestEmail] = useState<string>()
  const [openImportDialog, setOpenImportDialog] = useState(false)
  const [importMode, setImportMode] = useState<string>('mainImage')
  const [imagesToImport, setImagesToImport] = useState<string[]>([])
  const [availableImages, setAvailableImages] = useState<string[]>([])
  const [selectedCommunity, setSelectedCommunity] = useState<Community>()
  const [selectedPlan, setSelectedPlan] = useState<Plan>()
  const [selectedElevation, setSelectedElevation] = useState<Elevation>()
  const [selectedLot, setSelectedLot] = useState<Lot>()
  const [selectedCohort, setSelectedCohort] = useState<EmailCampaignCohort[]>(
    []
  )
  const [sharing, setSharing] = useState<number[]>([])

  const [snackbarConfig, setSnackbarConfig] = useAtom(snackbarConfigAtom)

  const { data: emailCampaignData } = useGetEmailCampaignQuery({
    variables: {
      clientName,
      emailCampaignId: emailCampaignId || newEmailCampaignId || 1,
    },
    skip: !emailCampaignId && !newEmailCampaignId,
  })

  const { data: plansData } = usePlansWithImagesQuery({
    variables: {
      clientName,
    },
  })

  const { data: communitiesData } = useCommunitiesWithImagesQuery({
    variables: {
      clientName,
      active: true,
    },
  })

  // all mutations
  const [createEmailCampaignMutation] = useCreateEmailCampaignMutation()
  const [deleteEmailCampaignMutation] = useDeleteEmailCampaignMutation()
  const [updateEmailCampaignMutation] = useUpdateEmailCampaignMutation()
  const [sendEmailCampaignMutation] = useSendEmailCampaignMutation()

  const [emailCampaignUpdates, setEmailCampaignUpdates] = useState(
    {} as EmailCampaignInput
  )

  useEffect(() => {
    // This useEffect will update the "emailCampaign" state based on the query results for getEmailCampaign
    // When creating a brand new Email Campaign, the getEmailCampaign query will be skipped UNTIL the user moves
    // to Step 2, at which point createEmailCampaign will be run and getEmailCampaign will no longer be skipped.
    // This useEffect will also set the selectedCommunity, selectedLot, selectedPlan, selectedElevation, and selectedCohort
    // based on the IDs set in the email_campaigns table.
    if (emailCampaignData?.getEmailCampaign) {
      setEmailCampaign(emailCampaignData?.getEmailCampaign as EmailCampaign)
      if (
        emailCampaignData?.getEmailCampaign?.communityId &&
        communitiesData?.communities
      ) {
        const savedCommunity = communitiesData?.communities.find(
          (comm) =>
            comm?.id === emailCampaignData?.getEmailCampaign?.communityId
        )
        if (savedCommunity !== undefined && savedCommunity !== null) {
          setSelectedCommunity(savedCommunity as Community)
          if (
            emailCampaignData?.getEmailCampaign?.lotId &&
            savedCommunity.siteplans
          ) {
            let savedLot
            savedCommunity.siteplans.forEach((siteplan) => {
              const findLot = siteplan?.lots?.find(
                (lot) => lot?.id === emailCampaignData?.getEmailCampaign?.lotId
              )
              if (findLot) {
                savedLot = findLot
              }
            })

            if (savedLot !== undefined && savedLot !== null) {
              setSelectedLot(savedLot as Lot)
            }
          }
        }
      }

      if (emailCampaignData?.getEmailCampaign?.planId && plansData?.plans) {
        const savedPlan = plansData?.plans.find(
          (plan) => plan?.id === emailCampaignData?.getEmailCampaign?.planId
        )
        if (savedPlan !== undefined && savedPlan !== null) {
          setSelectedPlan(savedPlan as Plan)
          if (
            emailCampaignData?.getEmailCampaign?.elevationId &&
            savedPlan.elevations
          ) {
            const savedElev = savedPlan.elevations.find(
              (elev) =>
                elev?.id === emailCampaignData?.getEmailCampaign?.elevationId
            )
            if (savedElev !== undefined && savedElev !== null) {
              setSelectedElevation(savedElev as Elevation)
            }
          }
        }
      }

      if (emailCampaignData?.getEmailCampaign?.cohort) {
        setSelectedCohort(
          emailCampaignData?.getEmailCampaign?.cohort as EmailCampaignCohort[]
        )
      }
    }
  }, [
    communitiesData,
    emailCampaignData,
    plansData,
    setEmailCampaign,
    setSelectedCohort,
  ])

  useEffect(() => {
    // This useEffect is responsible for setting the availableImages state, which is an array that tracks
    // all images that are available for "importing" from other data sources. Whenever a new community,
    // plan, elevation, or lot is selected, the availableImages will need to be recalculated to potentially
    // include new images from those selections, or to exclude images that are related to old selections.
    const images: string[] = []
    if (selectedCommunity) {
      if (selectedCommunity.thumb) {
        images.push(
          `${getAssetsPrefix(directoryName)}/custom/${selectedCommunity.thumb}`
        )
      }
      if (selectedCommunity.imgs) {
        const commImgs = selectedCommunity.imgs.map(
          (commImg) => `${getImagePrefix(directoryName)}/${commImg?.src}`
        )
        if (commImgs.length > 0) {
          images.push(...commImgs)
        }
      }
      if (selectedCommunity.mainPhotos) {
        const mainPhotos = selectedCommunity.mainPhotos.map(
          (commMainPhoto) =>
            `${getImagePrefix(directoryName)}/${commMainPhoto?.src}`
        )
        if (mainPhotos.length > 0) {
          images.push(...mainPhotos)
        }
      }
      if (selectedCommunity.overviewPhotos) {
        const overviewPhotos = selectedCommunity.overviewPhotos.map(
          (overviewPhoto) =>
            `${getImagePrefix(directoryName)}/${overviewPhoto?.src}`
        )
        if (overviewPhotos.length > 0) {
          images.push(...overviewPhotos)
        }
      }
    }

    if (selectedPlan) {
      if (selectedElevation?.thumb) {
        images.push(
          `${getImagePrefix(directoryName)}/${selectedElevation?.thumb}`
        )
      }

      if (selectedPlan.exteriorAssets) {
        const exteriorPrefix = `${getAssetsPrefix(
          directoryName
        )}/photos/exterior/0_0_${selectedPlan.id}_extPhotos`
        const exteriorAssets = selectedPlan.exteriorAssets?.map(
          (exteriorAsset) => `${exteriorPrefix}/${exteriorAsset?.src}`
        )
        if (exteriorAssets.length > 0) {
          images.push(...exteriorAssets)
        }
      }

      if (selectedPlan.interiorPhotos) {
        const interiorPrefix = `${getAssetsPrefix(
          directoryName
        )}/photos/interior/0_0_${selectedPlan.id}_intPhotos`
        const interiorPhotos = selectedPlan.interiorPhotos?.map(
          (interiorPhoto) => `${interiorPrefix}/${interiorPhoto?.src}`
        )
        if (interiorPhotos.length > 0) {
          images.push(...interiorPhotos)
        }
      }
    }

    if (selectedLot?.inventory) {
      const inventoryPrefix = `${getAssetsPrefix(directoryName)}/${
        selectedLot?.inventory.photoFolder
      }`
      if (selectedLot.inventory?.photos) {
        const inventoryPhotos = selectedLot.inventory?.photos?.map(
          (inventoryPhoto) => `${inventoryPrefix}/${inventoryPhoto?.src}`
        )
        if (inventoryPhotos.length > 0) {
          images.push(...inventoryPhotos)
        }
      }

      if (selectedLot?.inventory?.elevation?.thumb) {
        images.push(
          `${getImagePrefix(directoryName)}/${
            selectedLot?.inventory?.elevation?.thumb
          }`
        )
      }
    }

    setAvailableImages(images)
  }, [
    directoryName,
    selectedCommunity,
    selectedPlan,
    selectedElevation,
    selectedLot,
  ])

  const triggerSnackbar = (
    message: string,
    severity: 'error' | 'success' | 'info' | 'warning' | undefined
  ) => {
    setSnackbarConfig({
      ...snackbarConfig,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      autoHideDuration: 6000,
      open: true,
      message,
      severity,
    })
  }

  const handleBreadcrumb = (section: number): void => {
    if (section !== 1 && !emailCampaign?.id && !newEmailCampaignId) {
      // No-op: Email Campaign hasn't been created yet.
      return
    }
    if (warn) {
      setWarn('')
    }
    setActiveSection(section)
    scrollToTop()
  }

  const handleDeleteCampaign = (): void => {
    deleteEmailCampaignMutation({
      variables: {
        clientName,
        emailCampaignId:
          newEmailCampaignId !== null ? newEmailCampaignId : emailCampaign?.id,
      },
      refetchQueries: [
        {
          query: GET_EMAIL_CAMPAIGNS,
          variables: { clientName },
        },
      ],
    })
      .then(() => {
        triggerSnackbar('Successfully deleted Email Campaign!', 'success')
        setDeleteCampaignWarnOpen(false)
        handleClose()
      })
      .catch((error) => {
        console.error('failed to DELETE_EMAIL_CAMPAIGN', error)
        setWarn(
          "Failed to delete email campaign. Please check your internet connection and notify an admin if that doesn't seem to be the issue."
        )
      })
  }

  const handleSaveCampaign = async (
    disableSaveCampaignSuccessMessage?: boolean
  ): Promise<void> => {
    const updatedEmailCampaign = {
      ownerId: user.userId,
      name: emailCampaignUpdates?.name,
      replyTo: emailCampaignUpdates?.replyTo,
      replyToName: emailCampaignUpdates?.replyToName,
      subject: emailCampaignUpdates?.subject,
      opener: emailCampaignUpdates?.opener,
      sections: emailCampaignUpdates?.sections,
      signature: emailCampaignUpdates?.signature,
      communityId:
        selectedCommunity?.id ||
        (emailCampaign?.communityId && !selectedCommunity ? null : undefined),
      planId:
        selectedPlan?.id ||
        (emailCampaign?.planId && !selectedPlan ? null : undefined),
      elevationId:
        selectedElevation?.id ||
        (emailCampaign?.elevationId && !selectedElevation ? null : undefined),
      lotId:
        selectedLot?.id ||
        (emailCampaign?.lotId && !selectedLot ? null : undefined),
      templateId: emailCampaignUpdates?.templateId,
      searchCommunity: searchTerms?.community || '',
      searchPlan: searchTerms?.plan || '',
    }

    if (
      activeSection === 2 &&
      (!updatedEmailCampaign.replyTo ||
        !checkIsEmail(updatedEmailCampaign.replyTo)) &&
      (!emailCampaign?.replyTo || !checkIsEmail(emailCampaign?.replyTo))
    ) {
      setWarn('Please enter a valid "Reply To" email address.')
      return
    }

    if (
      activeSection === 1 &&
      !emailCampaignUpdates?.name &&
      !emailCampaign?.name
    ) {
      setWarn('Please name this email campaign before proceeding.')
      return
    }

    try {
      if (emailCampaign?.id || newEmailCampaignId !== null) {
        const cohort = emailCampaign?.cohort
          ? emailCampaign?.cohort
              .map((cohortMember) => cohortMember?.prospectId)
              .filter((prospectId) =>
                selectedCohort.find(
                  (selectedCohortMember) =>
                    selectedCohortMember?.prospectId === prospectId
                )
              )
              .filter(isDefined)
          : undefined

        if (!cohort || cohort.length === 0) {
          setWarn(
            'Please select at least one lead to send this email campaign to.'
          )
          return
        }
        await updateEmailCampaignMutation({
          variables: {
            clientName,
            emailCampaignId:
              newEmailCampaignId !== null
                ? newEmailCampaignId
                : emailCampaign?.id,
            emailCampaign: updatedEmailCampaign,
            cohort,
            sharing,
          },
          refetchQueries: [
            {
              query: GET_EMAIL_CAMPAIGNS,
              variables: { clientName },
            },
          ],
        })
      } else {
        const cohort = selectedLeads
          ? selectedLeads
              .map((leadByProduct) => leadByProduct.prospect?.id)
              .filter(isDefined)
          : undefined

        if (!cohort || cohort.length === 0) {
          setWarn(
            'Please select at least one lead to send this email campaign to.'
          )
          return
        }
        const result = await createEmailCampaignMutation({
          variables: {
            clientName,
            emailCampaign: {
              ...updatedEmailCampaign,
              templateId: updatedEmailCampaign.templateId || 1,
            },
            cohort,
          },
          refetchQueries: [
            {
              query: GET_EMAIL_CAMPAIGNS,
              variables: { clientName },
            },
          ],
        })
        if (result?.data?.createEmailCampaign?.id) {
          setNewEmailCampaignId(result?.data?.createEmailCampaign?.id)
        }
      }
      if (warn) {
        setWarn('')
      }
      if (activeSection === 1) {
        setActiveSection(2)
      } else if (activeSection === 2) {
        setActiveSection(3)
      }
      scrollToTop()
      if (!disableSaveCampaignSuccessMessage) {
        triggerSnackbar('Saved Email Campaign!', 'success')
      }
      setEmailCampaignUpdates({})
    } catch (error) {
      console.error('failed to UPDATE_EMAIL_CAMPAIGN', error)
      setWarn(
        "Failed to save email campaign. Please check your internet connection and notify an admin if that doesn't seem to be the issue."
      )
    }
  }

  const handleClose = () => {
    onClose()
    setTimeout(() => {
      setActiveSection(1)
      scrollToTop()
      setNewEmailCampaignId(null)
      setEmailCampaign({} as EmailCampaign)
      setEmailCampaignUpdates({})
      setSelectedCommunity(undefined)
      setSelectedCohort([])
      setSelectedPlan(undefined)
      setSelectedElevation(undefined)
      setSelectedLot(undefined)
      setTestEmail(undefined)
      setWarn('')
      setAvailableImages([])
    }, 300)
  }

  const handleDropboxChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    uploadTarget: string
  ) => {
    const files = event.target.files
    if (!files || !files[0]) {
      // no-op
      return
    }
    try {
      const formData = new FormData()
      formData.append('userFiles', files[0])
      formData.append('clientDirName', directoryName)
      formData.append('tgtDir', 'images/')

      uploadForm(formData).then(async (response) => {
        if (response.status >= 400) {
          response.json().then((data) => console.error(data))
          triggerSnackbar(`${files[0].name} image upload failed.`, 'error')
        }
        // Have to wait for response.json() to finish in order to receive res.send data
        if (response.status === 200) {
          const responseData = await response.json()
          if (responseData.length && responseData[0].fileName) {
            const uploadedImageUrl = `${getImagePrefix(directoryName)}/${
              responseData[0].fileName
            }`
            const updatedEmailCampaign = {} as EmailCampaignInput
            if (uploadTarget === 'mainImage') {
              updatedEmailCampaign.mainImage = uploadedImageUrl
            } else {
              const index = parseInt(uploadTarget.substring(4), 10) - 1
              const mySection =
                emailCampaign?.sections?.find(
                  (sect) => sect?.index === index
                ) || {}
              const newImagesArray = mySection?.images
                ? [
                    ...mySection?.images?.map((image) => image.src),
                    uploadedImageUrl,
                  ]
                : [uploadedImageUrl]
              const updatedSection = {
                index,
                images: [...new Set(newImagesArray)],
              }
              updatedEmailCampaign.sections = [updatedSection]
            }
            try {
              await updateEmailCampaignMutation({
                variables: {
                  clientName,
                  emailCampaignId:
                    newEmailCampaignId !== null
                      ? newEmailCampaignId
                      : emailCampaign?.id,
                  emailCampaign: updatedEmailCampaign,
                },
              })
              triggerSnackbar('Image uploaded successfully!', 'success')
            } catch (error) {
              console.error('failed to UPDATE_EMAIL_CAMPAIGN', error)
              setWarn(
                "Failed to upload image. Please check your internet connection and notify an admin if that doesn't seem to be the issue."
              )
            }
          }
        }
      })
    } catch (ex) {
      triggerSnackbar('Failed to upload image', 'error')
    }
  }

  const handleEmailCampaignSubmit = async () => {
    if (selectedCohort.length === 0) {
      setWarn('Please select at least one lead to send this email campaign to.')
      return
    }
    const disableSaveCampaignSuccessMessage = true
    await handleSaveCampaign(disableSaveCampaignSuccessMessage)

    if (!emailCampaign?.replyToName) {
      setWarn('Please enter a "Reply To" name.')
      return
    }

    if (!emailCampaign?.replyTo || !checkIsEmail(emailCampaign?.replyTo)) {
      setWarn('Please enter a valid "Reply To" email address.')
      return
    }

    if (!emailCampaign?.subject) {
      setWarn('Please enter a Subject.')
      return
    }

    try {
      await sendEmailCampaignMutation({
        variables: {
          clientName,
          emailCampaignId: emailCampaign?.id,
          ownerId: user.userId,
        },
        refetchQueries: [
          {
            query: GET_EMAIL_CAMPAIGNS,
            variables: { clientName },
          },
        ],
      })
      triggerSnackbar('Sent email campaign successfully!', 'success')
      handleClose()
    } catch (error) {
      console.error('failed to SEND_EMAIL_CAMPAIGN', error)
      setWarn(
        "Failed to send email(s). Please check your internet connection and notify an admin if that doesn't seem to be the issue."
      )
    }
  }

  const handleTestEmailSend = async () => {
    if (!testEmail || !checkIsEmail(testEmail)) {
      triggerSnackbar('Please enter a valid test email address.', 'warning')
      return
    }

    if (!emailCampaign?.replyToName) {
      triggerSnackbar('Please enter a "Reply To" name.', 'warning')
      return
    }

    if (!emailCampaign?.replyTo || !checkIsEmail(emailCampaign?.replyTo)) {
      triggerSnackbar(
        'Please enter a valid "Reply To" email address.',
        'warning'
      )
      return
    }

    if (!emailCampaign?.subject) {
      triggerSnackbar('Please enter a Subject.', 'warning')
      return
    }

    try {
      await sendEmailCampaignMutation({
        variables: {
          clientName,
          emailCampaignId: emailCampaign?.id,
          ownerId: user.userId,
          testEmailAddress: testEmail,
        },
      })
      triggerSnackbar('Sent test email successfully!', 'success')
      setTestEmailDialogOpen(false)
    } catch (error) {
      console.error('failed to SEND_EMAIL_CAMPAIGN', error)
      setWarn(
        "Failed to send test email. Please check your internet connection and notify an admin if that doesn't seem to be the issue."
      )
    }
  }

  const handleImportClick = (importMode: string) => {
    setImportMode(importMode)
    setOpenImportDialog(true)
  }

  const handleImportDialogClose = () => {
    setImagesToImport([])
    setOpenImportDialog(false)
  }

  const handleImportImageClick = (img: string) => {
    let newImagesToImportArray = imagesToImport.slice()
    const imageAlreadySelected = imagesToImport.find(
      (importImg) => importImg === img
    )
    if (imageAlreadySelected) {
      newImagesToImportArray = newImagesToImportArray.filter(
        (newImg) => newImg !== img
      )
    } else {
      if (importMode === 'mainImage') {
        // Only one image can be selected at a time in this mode.
        newImagesToImportArray = []
      }
      newImagesToImportArray.push(img)
    }
    setImagesToImport(newImagesToImportArray)
  }

  const handleSectionImageImport = async (): Promise<void> => {
    if (imagesToImport.length === 0) {
      // no-op
      return
    }
    const updatedEmailCampaign = {} as EmailCampaignInput
    if (importMode === 'mainImage') {
      updatedEmailCampaign.mainImage = imagesToImport[0]
    } else {
      const index = parseInt(importMode.substring(4), 10) - 1
      const mySection =
        emailCampaign?.sections?.find((sect) => sect?.index === index) || {}
      const newImagesArray = mySection?.images
        ? [...mySection?.images?.map((image) => image.src), ...imagesToImport]
        : [...imagesToImport]
      const updatedSection = {
        index,
        images: [...new Set(newImagesArray)],
      }
      updatedEmailCampaign.sections = [updatedSection]
    }
    try {
      await updateEmailCampaignMutation({
        variables: {
          clientName,
          emailCampaignId:
            newEmailCampaignId !== null
              ? newEmailCampaignId
              : emailCampaign?.id,
          emailCampaign: updatedEmailCampaign,
        },
      })
      triggerSnackbar('Imported images successfully!', 'success')
      handleImportDialogClose()
    } catch (error) {
      console.error('failed to UPDATE_EMAIL_CAMPAIGN', error)
      setWarn(
        "Failed to import image(s). Please check your internet connection and notify an admin if that doesn't seem to be the issue."
      )
    }
  }

  const scrollToTop = (): void => {
    if (dialogContentRef) {
      const currentRef = dialogContentRef.current
      if (currentRef && typeof currentRef.scrollTo === 'function') {
        currentRef.scrollTo(0, 0)
      }
    }
  }

  return (
    <>
      <DialogStyled
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth={true}
        fullScreen={fullScreen}
        scroll="paper"
        disableEnforceFocus
      >
        <div className={classes.titleSection}>
          <Typography variant="h5" color="primary" id="form-dialog-title">
            Compose Email Campaign
          </Typography>
          <Typography
            className={classes.subtitle}
            variant="subtitle2"
            color="textSecondary"
          >
            Create an email campaign to send to the selected cohort of leads.
          </Typography>
          <Grid container direction="column" spacing={1}>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={
                <ArrowForwardIcon classes={{ root: classes.stepsSeparator }} />
              }
              classes={{ root: classes.breadcrumbs }}
            >
              <div onClick={() => handleBreadcrumb(1)}>
                <Avatar
                  className={
                    activeSection === 1
                      ? classes.stepsCounterActive
                      : classes.stepsCounter
                  }
                >
                  1
                </Avatar>
                <Typography
                  variant="body1"
                  className={
                    activeSection === 1
                      ? classes.stepsLabelActive
                      : classes.stepsLabel
                  }
                >
                  Details
                </Typography>
              </div>
              <div onClick={() => handleBreadcrumb(2)}>
                <Avatar
                  className={
                    activeSection === 2
                      ? classes.stepsCounterActive
                      : classes.stepsCounter
                  }
                  style={
                    !emailCampaign?.id && !newEmailCampaignId
                      ? { cursor: 'default' }
                      : undefined
                  }
                >
                  2
                </Avatar>
                <Typography
                  variant="body1"
                  className={
                    activeSection === 2
                      ? classes.stepsLabelActive
                      : classes.stepsLabel
                  }
                >
                  Content
                </Typography>
              </div>
              <div onClick={() => handleBreadcrumb(3)}>
                <Avatar
                  className={
                    activeSection === 3
                      ? classes.stepsCounterActive
                      : classes.stepsCounter
                  }
                  style={
                    !emailCampaign?.id && !newEmailCampaignId
                      ? { cursor: 'default' }
                      : undefined
                  }
                >
                  3
                </Avatar>
                <Typography
                  variant="body1"
                  className={
                    activeSection === 3
                      ? classes.stepsLabelActive
                      : classes.stepsLabel
                  }
                >
                  Send
                </Typography>
              </div>
            </Breadcrumbs>
            <Grid item>
              {warn ? <MuiAlert severity="warning">{warn}</MuiAlert> : null}
            </Grid>
          </Grid>
        </div>
        <DialogContent ref={dialogContentRef}>
          <Grid container direction="column" spacing={1}>
            <div
              className={
                activeSection === 1
                  ? clsx(classes.activeSection, classes.section)
                  : clsx(classes.inactiveSection, classes.section)
              }
            >
              <EmailCompositionModalStepOne
                classes={classes}
                communitiesData={communitiesData}
                plansData={plansData}
                emailCampaign={emailCampaign}
                emailCampaignUpdates={emailCampaignUpdates}
                selectedCommunity={selectedCommunity}
                selectedPlan={selectedPlan}
                selectedElevation={selectedElevation}
                selectedLot={selectedLot}
                setEmailCampaignUpdates={setEmailCampaignUpdates}
                setSelectedCommunity={setSelectedCommunity}
                setSelectedPlan={setSelectedPlan}
                setSelectedElevation={setSelectedElevation}
                setSelectedLot={setSelectedLot}
              />
            </div>
            <div
              className={
                activeSection === 2
                  ? clsx(classes.activeSection, classes.section)
                  : clsx(classes.inactiveSection, classes.section)
              }
            >
              <EmailCompositionModalStepTwo
                classes={classes}
                clientName={clientName}
                emailCampaign={emailCampaign}
                emailCampaignUpdates={emailCampaignUpdates}
                setEmailCampaignUpdates={setEmailCampaignUpdates}
                handleDropboxChange={handleDropboxChange}
                handleImportClick={handleImportClick}
                setWarn={setWarn}
              />
            </div>
            <div
              className={
                activeSection === 3
                  ? clsx(classes.activeSection, classes.section)
                  : clsx(classes.inactiveSection, classes.section)
              }
            >
              <EmailCompositionModalStepThree
                classes={classes}
                emailCampaign={emailCampaign}
                handleTestEmailSend={handleTestEmailSend}
                selectedCohort={selectedCohort}
                setSelectedCohort={setSelectedCohort}
                setSharing={setSharing}
                setTestEmail={setTestEmail}
                setTestEmailDialogOpen={setTestEmailDialogOpen}
                testEmail={testEmail}
                testEmailDialogOpen={testEmailDialogOpen}
              />
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!emailCampaign?.id && !newEmailCampaignId}
            className={classes.button}
            onClick={() => setDeleteCampaignWarnOpen(true)}
            variant="contained"
            color="secondary"
          >
            Delete
          </Button>
          {activeSection === 3 && (
            <Button
              className={clsx(classes.button, classes.purpleButton)}
              onClick={(): Promise<void> => handleSaveCampaign()}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          )}
          <Button
            className={classes.button}
            onClick={handleClose}
            type="submit"
            variant="contained"
          >
            Cancel
          </Button>
          {activeSection !== 3 && (
            <Button
              data-testid="emailCampaignSaveAndContinueButton"
              className={clsx(classes.button, classes.purpleButton)}
              onClick={(): Promise<void> => handleSaveCampaign()}
              variant="contained"
              color="primary"
            >
              Save and Continue
            </Button>
          )}
          {activeSection === 3 && (
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleEmailCampaignSubmit}
            >
              Send
            </Button>
          )}
        </DialogActions>
      </DialogStyled>
      <DialogStyled
        fullScreen
        open={openImportDialog}
        onClose={handleImportDialogClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth={true}
        scroll="paper"
      >
        <AppBar className={classes.importDialogAppBar}>
          <Toolbar>
            <IconButton
              data-testid="emailCampaignImportImageCloseButton"
              edge="start"
              color="inherit"
              onClick={handleImportDialogClose}
              aria-label="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.importDialogTitle}>
              Import Images for Email Campaign
            </Typography>
            <Button
              autoFocus
              color="inherit"
              disabled={imagesToImport.length === 0}
              onClick={handleSectionImageImport}
            >
              Import Selected Images
            </Button>
          </Toolbar>
        </AppBar>
        {availableImages.length > 0 ? (
          <div className={classes.importImagesContainer}>
            {availableImages.length > 0
              ? availableImages
                  .filter((img) => {
                    if (importMode === 'mainImage') {
                      return img !== emailCampaign?.mainImage
                    } else {
                      const index = parseInt(importMode.substring(4), 10) - 1
                      const mySection =
                        emailCampaign?.sections?.find(
                          (sect) => sect?.index === index
                        ) || {}
                      return !mySection?.images?.find(
                        (image) => image.src === img
                      )
                    }
                  })
                  .map((img) => (
                    <div
                      key={img}
                      className={
                        imagesToImport.find((importImg) => importImg === img)
                          ? clsx(
                              classes.importImageDiv,
                              classes.activeImportImageDiv
                            )
                          : classes.importImageDiv
                      }
                    >
                      <img
                        className={classes.importImage}
                        src={img}
                        alt={`${img} Import`}
                        style={{ cursor: 'pointer' }}
                        onClick={(): void => handleImportImageClick(img)}
                      />
                    </div>
                  ))
              : null}
          </div>
        ) : (
          <Typography
            variant="h6"
            color="primary"
            style={{ marginTop: '16px', marginLeft: '14px' }}
          >
            No Images Available to Import
          </Typography>
        )}
      </DialogStyled>
      <DialogStyled
        open={deleteCampaignWarnOpen}
        onClose={(): void => setDeleteCampaignWarnOpen(false)}
        aria-labelledby="campaign-delete-title"
        aria-describedby="campaign-delete-message"
      >
        {deleteCampaignWarnOpen !== false && (
          <div>
            <DialogTitle id="campaign-delete-title">
              Delete this email campaign?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="campaign-delete-message">
                This email campaign will be deleted permanently.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(): void => setDeleteCampaignWarnOpen(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                data-testid="delete-campaign-button"
                color="secondary"
                onClick={handleDeleteCampaign}
                autoFocus
              >
                Delete
              </Button>
            </DialogActions>
          </div>
        )}
      </DialogStyled>
    </>
  )
}
