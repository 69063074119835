import React, { useEffect } from 'react'
import { RouteAuthorization } from '../../constants'
import { getUnauthorizedAccessReason } from '../../utils/authorizationHelpers'
import { snackbarConfigAtom } from 'store/atoms'
import { useAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'

export interface AuthorizedRouteProps extends RouteAuthorization {
  children: JSX.Element
  redirectTo?: string
}

export default function AuthorizedRoute({
  subscription,
  privileges,
  redirectTo,
  children,
}: AuthorizedRouteProps): JSX.Element {
  const navigate = useNavigate()
  const [, setSnackbarConfig] = useAtom(snackbarConfigAtom)
  const unauthorized = getUnauthorizedAccessReason(privileges, subscription)

  useEffect(() => {
    if (unauthorized) {
      setSnackbarConfig({
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        autoHideDuration: 6000,
        open: true,
        message: unauthorized,
        severity: 'error',
      })

      navigate(redirectTo || '/')
    }
  }, [unauthorized, subscription, privileges, redirectTo])

  return unauthorized ? <></> : children
}
