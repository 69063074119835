import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'

export const SessionTrendsContainer = styled(Grid)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('xl')]: {
    height: 400,
  },
  [theme.breakpoints.down('lg')]: {
    height: 250,
  },
  [theme.breakpoints.down('sm')]: {
    height: 200,
  },
  height: 400,
}))
