import { CommonQueryFilter } from '../../../../graphql/gen-types'
import { useMemo } from 'react'
import { LocationSelection } from '../Shared'

type CommonQueryFilterArgs = {
  clientName: string
  startDate: Date
  endDate: Date
  selection: LocationSelection
  [key: string]: unknown
}

export function cleanFilter<T>(filter: T): Exclude<T, undefined> {
  return Object.fromEntries(
    Object.entries(filter).filter(([, value]) => value !== undefined)
  ) as Exclude<T, undefined>
}

export const useCommonQueryFilter = ({
  clientName,
  startDate,
  endDate,
  selection,
  ...args
}: CommonQueryFilterArgs): Exclude<CommonQueryFilter, undefined> => {
  return useMemo(
    () =>
      cleanFilter<CommonQueryFilter>({
        clientName,
        startTime: startDate.getTime(),
        endTime: endDate.getTime(),
        country: selection.country || undefined,
        cities:
          selection.cities && selection.cities.length > 0
            ? selection.cities
            : undefined,
        ...(args || {}),

        state: selection.us?.state ? [selection.us.state] : undefined,
        county: selection.us?.county ? [selection.us.county] : undefined,
        metro: selection.us?.metro ? [selection.us.metro as number] : undefined,
        zip: selection.us?.zipCode ? [selection.us.zipCode] : undefined,

        province: selection.can?.province
          ? [selection.can.province]
          : undefined,
        district: selection.can?.district
          ? [selection.can.district as number]
          : undefined,
        postCode: selection.can?.postCode
          ? [selection.can.postCode]
          : undefined,
      }),
    [clientName, startDate, endDate, selection, args]
  )
}
