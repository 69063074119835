import React, { useState, useEffect, useContext } from 'react'
import {
  LeadButton,
  Container,
  Title,
  LeadsTable,
} from './styles/VisitorTrends.styles'
import { useTheme } from '@mui/material/styles'
import {
  VisitorTrend,
  useVisitorTrendsQuery,
  CommonQueryFilter,
  LeadsQueryFilter,
  useLeadRankingsQuery,
  DateFilterField,
  LeadRanking,
  Community,
} from '../../../graphql/gen-types'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import DateFnsAdapter from '@date-io/date-fns'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import { AppStore } from 'store'
import ResponsiveTable, {
  ResponsiveCell,
} from 'components/common/tables/ResponsiveTable'
import Skeleton from '@mui/material/Skeleton'
import { useNavigate } from 'react-router-dom'
import { AnalyticsStore } from 'store/analyticsStore'
import { AppActionType } from 'store/reducers'
import { LEAD_SCORE_OUT_OF } from 'constants/index'
import { formatDateTime } from 'utils/functions'
import { ComposedChart } from 'recharts'
import { LabelList } from 'recharts'
import { LeadKeys } from '../lead-rankings/LeadRankingTable'

const DATE_FORMAT = 'MM-dd-yyyy'
const dateFns = new DateFnsAdapter()

const COMPONENT_TITLE = 'Monthly User Trends'

interface VisitorTrendsProps {
  filter: CommonQueryFilter
  commonFilter: CommonQueryFilter
  selectedCommunities: Community[]
  height?: number | null
  width?: number | null
  report?: boolean
}

interface VisitorTrendsWithPctRegistered extends VisitorTrend {
  percentRegistered: number
}

const initialState: VisitorTrend[] = []

function VisitorTrends(props: VisitorTrendsProps): JSX.Element {
  const navigate = useNavigate()
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.down('lg'))
  const { dispatch } = useContext(AnalyticsStore)
  const [visitorTrends, setVisitorTrends] = useState(initialState)
  const [value, setValue] = React.useState(0)
  const [displayUsersMonth, setDisplayUsersMonth] = useState<Date | null>(null)
  const [leadsFilter, setLeadsFilter] = useState<LeadsQueryFilter>({
    common: { ...props.commonFilter },
    leads: {},
  })
  const [leads, setLeads] = useState<LeadRanking[] | null>(null)
  const { appState } = useContext(AppStore)
  const { selectedClient } = appState
  const clientName = selectedClient?.altName
  const clientEmail = selectedClient?.email

  // Note: if clientDomain ends up being something generic like "gmail.com",
  // all leads with that email domain will be filtered out even though they probably aren't builder agents.
  const clientDomain = clientEmail
    ? clientEmail.substring(clientEmail.lastIndexOf('@'))
    : undefined

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number
  ): void => {
    setValue(newValue)
  }

  const viewUsersOnLeadsOverviewPage = () => {
    if (!displayUsersMonth) {
      return
    }
    const dateCopy = new Date(displayUsersMonth.getTime())
    const startTime = dateCopy.getTime()
    const endTime = new Date(dateCopy.setMonth(dateCopy.getMonth() + 1))

    dispatch({
      type: AppActionType.SET_DATE_RANGE,
      payload: {
        startDate: new Date(startTime),
        endDate: endTime,
      },
    })
    navigate(`/leads/client/${clientName}/overview`, {
      state: {
        dateFilterField: DateFilterField.FirstSeen,
        communities: props.selectedCommunities,
        cities: props.commonFilter.cities,

        country: props.commonFilter.country || undefined,

        state: props.commonFilter.state
          ? props.commonFilter.state[0]
          : undefined,
        county: props.commonFilter.county
          ? props.commonFilter.county[0]
          : undefined,
        metro: props.commonFilter.metro
          ? props.commonFilter.metro[0]
          : undefined,
        zip: props.commonFilter.zip ? props.commonFilter.zip[0] : undefined,

        province: props.commonFilter.province
          ? props.commonFilter.province[0]
          : undefined,
        district: props.commonFilter.district
          ? props.commonFilter.district[0]
          : undefined,
        postCode: props.commonFilter.postCode
          ? props.commonFilter.postCode[0]
          : undefined,
      },
    })
  }

  const { data, loading, error } = useVisitorTrendsQuery({
    variables: {
      filter: {
        common: props.filter,
        clientDomain: clientDomain || '',
      },
    },
  })

  const { data: leadsData, loading: leadsLoading } = useLeadRankingsQuery({
    variables: {
      filter: leadsFilter,
      clientName: clientName || '',
    },
    skip: !leadsFilter.leads?.dateFilterField,
  })

  useEffect(() => {
    if (leadsData?.leadRankings) {
      // creating a copy of the array before trying to apply sort
      const leadsDataRankings = [...leadsData.leadRankings.leads]
      leadsDataRankings.sort((a: LeadRanking, b: LeadRanking) =>
        compareLeads(a, b, 'score', 'desc')
      )
      setLeads(leadsDataRankings)
    }
  }, [leadsData])

  useEffect(() => {
    if (data?.visitorTrends) {
      setVisitorTrends(data.visitorTrends)
    }
  }, [data])

  useEffect(() => {
    if (!displayUsersMonth) {
      return
    }
    const dateCopy = new Date(displayUsersMonth.getTime())
    const startTime = dateCopy.getTime()
    const endTime = dateCopy.setMonth(dateCopy.getMonth() + 1)
    const leadsFilter: LeadsQueryFilter = {
      common: {
        ...props.commonFilter,
        startTime,
        endTime,
      },
      leads: {
        dateFilterField: DateFilterField.FirstSeen,
      },
    }
    setLeadsFilter(leadsFilter)
  }, [displayUsersMonth, props.commonFilter])

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized
          message={error.toString()}
          imageName={'VisitorTrends'}
        ></Unauthorized>
      )
    }
  }

  if (loading || error || visitorTrends.length === 0) {
    const displayText =
      (loading && 'Loading...') ||
      (visitorTrends.length === 0 && 'No Data to Display.')
    return (
      <Container>
        <Title variant="h5">{COMPONENT_TITLE}</Title>
        <Typography>{displayText}</Typography>
      </Container>
    )
  }

  // Format date field
  const formattedData = getFormattedData(visitorTrends)

  function LeadTableRow({ lead }: { lead: LeadRanking }): JSX.Element {
    return (
      <React.Fragment>
        <ResponsiveCell align="center">
          {Math.round((lead.score || 0) * LEAD_SCORE_OUT_OF)}
        </ResponsiveCell>
        <ResponsiveCell align="left">{lead.name}</ResponsiveCell>
        <ResponsiveCell align="left">{lead.user}</ResponsiveCell>
        <ResponsiveCell align="center">
          {formatDateTime(
            lead?.registrationDate || lead.firstSeen,
            DATE_FORMAT
          )}
        </ResponsiveCell>
        <ResponsiveCell align="center">
          {formatDateTime(lead.lastSeen, DATE_FORMAT)}
        </ResponsiveCell>
      </React.Fragment>
    )
  }

  const columns = [
    <ResponsiveCell align="center" key={1}>
      <strong>Score</strong>
    </ResponsiveCell>,

    <ResponsiveCell align="left" key={2}>
      <strong>Name</strong>
    </ResponsiveCell>,

    <ResponsiveCell align="left" key={3}>
      <strong>Email</strong>
    </ResponsiveCell>,

    <ResponsiveCell align="center" key={4}>
      <strong>Registered</strong>
    </ResponsiveCell>,

    <ResponsiveCell align="center" key={5}>
      <strong>Last Seen</strong>
    </ResponsiveCell>,
  ]

  if (props.report) {
    return (
      <Container>
        <Title variant="h5">{COMPONENT_TITLE}</Title>
        <MonthlyBarChart
          data={formattedData}
          value="Anonymous"
          height={props.height}
          width={props.width}
        ></MonthlyBarChart>
      </Container>
    )
  }

  return (
    <Container>
      <Title variant="h5">{COMPONENT_TITLE}</Title>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Registered Users" {...tabProperties(0)} />
        <Tab label="User Usage Statistics" {...tabProperties(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        {loading ? (
          <Skeleton
            variant="rectangular"
            width={'100%'}
            height={md ? 250 : 400}
          />
        ) : (
          <div
            style={
              !md ? { display: 'grid', gridTemplateColumns: '1fr 1fr' } : {}
            }
          >
            <MonthlyBarChart
              data={formattedData}
              value="Registered"
              onBarClick={(month) => setDisplayUsersMonth(month)}
              height={props.height}
              width={props.width}
            />

            <>
              {leadsLoading && (
                <Skeleton
                  variant="rectangular"
                  width={'100%'}
                  height={md ? 250 : 400}
                  style={md ? { marginTop: 30 } : {}}
                />
              )}
              {!leadsLoading && (
                <>
                  {!leads && (
                    <Typography
                      variant="h4"
                      style={{ textAlign: 'center', marginTop: '10%' }}
                    >
                      Select a bar to display leads.
                    </Typography>
                  )}
                  {leads && leads.length > 0 && (
                    <LeadsTable>
                      {!leadsLoading ? (
                        <ResponsiveTable
                          data={leads}
                          columns={columns}
                          generateRow={(
                            lead: LeadRanking,
                            index?: number
                          ): JSX.Element => <LeadTableRow lead={lead} />}
                          responseSize={'md'}
                          dense={true}
                        />
                      ) : (
                        <Skeleton
                          variant="rectangular"
                          width={'100%'}
                          height={md ? 250 : 400}
                        />
                      )}
                      <LeadButton
                        variant="contained"
                        color="primary"
                        onClick={viewUsersOnLeadsOverviewPage}
                      >
                        Go to Leads Overview
                      </LeadButton>
                    </LeadsTable>
                  )}
                  {leads && leads.length <= 0 && (
                    <Typography
                      variant="h4"
                      style={{ textAlign: 'center', marginTop: '10%' }}
                    >
                      No data for current selection. Select different bar to
                      display leads.
                    </Typography>
                  )}
                </>
              )}
            </>
          </div>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MonthlyBarChart
          data={formattedData}
          value="Anonymous"
          height={props.height}
          width={props.width}
        ></MonthlyBarChart>
      </TabPanel>
    </Container>
  )
}

function getFormattedData(
  data: VisitorTrend[]
): VisitorTrendsWithPctRegistered[] {
  const formattedData: VisitorTrendsWithPctRegistered[] = []

  data.forEach((row): void => {
    const date = row?.date || null
    const registered = row?.registered || 0
    const anonymous = row?.anonymous || 0
    const cumulativeRegistered = row?.cumulativeRegistered || 0
    const percentRegistered = anonymous
      ? Math.round((registered / anonymous) * 100)
      : 100
    // Only add valid days

    const day = 15 // set day to be 15th. Helps resolve issues with ISO dates.
    const [year, month] = date?.split('-') || []
    if (date && year && month && day) {
      const dateObj = new Date(`${year}-${month}-${day}`)
      formattedData.push({
        date: dateFns.format(dateObj, 'MMM yyyy'),
        anonymous,
        registered,
        cumulativeRegistered,
        percentRegistered,
      })
    }
  })
  return formattedData
}

interface MonthlyBarChartProps {
  value: 'Anonymous' | 'Registered'
  data: VisitorTrendsWithPctRegistered[]
  onBarClick?: (month: Date) => void
  height?: number | null
  width?: number | null
}

const CustomTooltip = ({
  active,
  payload,
  label,
}: Record<string, any>): JSX.Element | null => {
  if (active && payload && payload.length) {
    const anonymous = payload[0]
    const registered = payload[1]
    const cumulativeRegistered = payload[2]
    const percentRegistered = Math.round(
      (registered.value / anonymous.value) * 100
    )
    return (
      <Paper
        elevation={3}
        style={{
          padding: '10px',
        }}
      >
        <Typography variant="h5">{label}</Typography>
        <p
          style={{ color: anonymous.color }}
        >{`${anonymous.name} : ${anonymous.value}`}</p>
        <p
          style={{ color: registered.color }}
        >{`${registered.name} : ${registered.value}`}</p>
        <p
          style={{ color: cumulativeRegistered?.color }}
        >{`${cumulativeRegistered?.name} : ${cumulativeRegistered?.value}`}</p>
        <p style={{ color: 'black' }}>
          {`% Registered Users : ${percentRegistered}`}%
        </p>
      </Paper>
    )
  }

  return null
}

function MonthlyBarChart(props: MonthlyBarChartProps): JSX.Element {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('md'))
  const md = useMediaQuery(theme.breakpoints.down('lg'))
  const fillColor =
    theme.palette.mode === 'dark'
      ? theme.palette.primary.light
      : theme.palette.primary.dark

  const displayName =
    props.value === 'Anonymous' ? 'Anonymous Users' : 'Registered Users'
  const dataName = props.value === 'Anonymous' ? 'anonymous' : 'registered'
  const renderColorfulLegendText = (
    value: Record<string, string>
  ): JSX.Element => {
    return (
      <span
        style={{
          color: fillColor,
        }}
      >
        {value}
      </span>
    )
  }

  const renderCustomizedLabel = (props: {
    x: number
    y: number
    width: number
    value: string
  }): JSX.Element => {
    const { x, y, width, value } = props

    return (
      <g>
        <text
          x={x + width / 2} // a way to center our text.
          y={y - 22} // 22 cartesian coordinate plan value away(up) from the bar chart.
          fill={theme.palette.warning.dark}
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {value}%
        </text>
      </g>
    )
  }

  return (
    <Grid container justifyContent="center" spacing={0}>
      <ResponsiveContainer
        width={props.width || '100%'}
        height={props.height || (md ? 250 : 400)}
        style={{ marginLeft: '-50px' }}
      >
        <ComposedChart
          data={props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            tick={{
              fill: fillColor,
            }}
            angle={-15}
            textAnchor="end"
          />
          <YAxis
            tick={{
              fill: fillColor,
            }}
          />
          <Tooltip
            content={props.value === 'Anonymous' ? <CustomTooltip /> : null}
            labelStyle={{ color: 'black' }}
            position={sm && { x: 35, y: 190 }}
          />
          {props.value === 'Anonymous' && (
            <Legend
              formatter={renderColorfulLegendText}
              wrapperStyle={
                md
                  ? { position: 'relative', top: -60 }
                  : { position: 'relative', top: -20 }
              }
            />
          )}
          <Legend
            formatter={renderColorfulLegendText}
            wrapperStyle={{ position: 'relative', top: -20 }}
          />
          <Bar
            style={props.onBarClick && { cursor: 'pointer' }}
            dataKey={dataName}
            stackId="anoAndRegisteredUsers"
            fill={theme.palette.primary.main}
            name={displayName}
            onClick={(e: any) => {
              if (props.onBarClick && e.date) {
                // add 01 (day) for firefox
                const month = new Date(`01 ${e.date}`)
                props.onBarClick(month)
              }
            }}
          />
          {props.value === 'Anonymous' && (
            <Bar
              style={props.onBarClick && { cursor: 'pointer' }}
              dataKey={`registered`}
              stackId="anoAndRegisteredUsers"
              fill={theme.palette.warning.dark}
              name={'Registered Users'}
              onClick={(e: any) => {
                if (props.onBarClick && e.date) {
                  // add 01 (day) for firefox
                  const month = new Date(`01 ${e.date}`)
                  props.onBarClick(month)
                }
              }}
            >
              {!md && (
                <LabelList
                  dataKey="percentRegistered"
                  position="top"
                  content={renderCustomizedLabel}
                />
              )}
            </Bar>
          )}
          {props.value === 'Anonymous' && (
            <Bar
              style={props.onBarClick && { cursor: 'pointer' }}
              dataKey={`cumulativeRegistered`}
              fill={theme.palette.success.main}
              name={'Cumulative Registered Users'}
              onClick={(e: any) => {
                if (props.onBarClick && e.date) {
                  // add 01 (day) for firefox
                  const month = new Date(`01 ${e.date}`)
                  props.onBarClick(month)
                }
              }}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </Grid>
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps): JSX.Element {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ marginTop: '2em' }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function tabProperties(index: number): Record<string, string> {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function compareLeads(
  leadA: LeadRanking,
  leadB: LeadRanking,
  byField: LeadKeys,
  order: 'asc' | 'desc' = 'asc',
  type?: 'number' | 'string' | 'date' | 'array' | undefined
): number {
  let compare = 0
  const orderFactor = order === 'asc' ? 1 : -1
  const a = leadA[byField]
  const b = leadB[byField]

  // null check
  if (!a || !b) {
    return a ? -1 : 1
  }
  // Resolve some types by field name
  // This check must be done first since the date fields are also strings.
  if (byField === 'firstSeen' || byField === 'lastSeen') {
    compare = new Date(a).getTime() - new Date(b).getTime()
  }
  // Resolve some types by type checking
  if (typeof a === 'string' && typeof b === 'string') {
    compare = a.localeCompare(b, 'en', { sensitivity: 'base' })
  }
  if (typeof a === 'number' && typeof b === 'number') {
    compare = a - b
  }
  if (Array.isArray(a) && Array.isArray(b)) {
    compare = a.length - b.length
  }

  return compare * orderFactor
}

export default VisitorTrends
