import React, { Dispatch } from 'react'
import { AnalyticsState, AppAction } from './reducers'
import DateFnsAdapter from '@date-io/date-fns'

const dateFns = new DateFnsAdapter()

interface AnalyticsContextProps {
  analyticsState: AnalyticsState
  dispatch: Dispatch<AppAction>
}

/**
 * Usually we will have a 1-1 relationship between signed-in user and client.
 * However, in some cases, such as RH user, or a user with multiple clients
 * It can be a 1-n user to client mapping.
 *
 */
const endDate = new Date()
// set to yesterday
endDate.setDate(endDate.getDate() - 1)

export const initialState: AnalyticsState = {
  startDate: dateFns.startOfDay(dateFns.addDays(dateFns.date(new Date()), -7)),
  endDate: dateFns.endOfDay(new Date()),
  selectedTabs: ['Overview'],
}

export const AnalyticsStore: React.Context<AnalyticsContextProps> = React.createContext(
  {} as AnalyticsContextProps
)
