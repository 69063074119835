import { alpha, styled } from '@mui/material/styles'
import Switch from '@mui/material/Switch'
import { pink } from '@mui/material/colors'
import Typography from '@mui/material/Typography'

const PREFIX = 'LotLegend'

export const classes = {
  tableContainer: `${PREFIX}-tableContainer`,
  bulletRoot: `${PREFIX}-bulletRoot`,
  lotLegendRoot: `${PREFIX}-lotLegendRoot`,
  checkBox: `${PREFIX}-checkBox`,
  inlineItem: `${PREFIX}-inlineItem`,
  lotLegendEntry: `${PREFIX}-lotLegendEntry`,
  lotLegendEntrySelectable: `${PREFIX}-lotLegendEntrySelectable`,
  lotLegendEntrySelected: `${PREFIX}-lotLegendEntrySelected`,
  switch: `${PREFIX}-switch`,
  switchContainer: `${PREFIX}-switchContainer`,
  typographyStandard: `${PREFIX}-typographyStandard`,
}

export const BulletSpan = styled('span')(() => ({
  [`&.${classes.bulletRoot}`]: {
    boxSizing: 'border-box',
  },
}))

export const LotLegendyEntryDiv = styled('div')(({ theme }) => ({
  [`&.${classes.lotLegendEntry}`]: {
    display: 'flex',
    padding: '3px 4px 0px 4px',
    position: 'relative',
    width: '93%',
    margin: 'auto',
  },
  [`&.${classes.lotLegendEntrySelectable}`]: {
    transitionDuration: '0.15s',
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: '#eee',
    },
  },
  [`&.${classes.lotLegendEntrySelected}`]: {
    backgroundColor: '#eee',
  },
}))

export const SwitchContainerDiv = styled('div')(({ theme }) => ({
  [`&.${classes.switchContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export const StyledTypography = styled(Typography)(({ theme }) => ({
  [`&.${classes.typographyStandard}`]: {
    color: theme.palette.mode === 'dark' ? 'white' : 'gray',
    fontSize: '12px',
    marginTop: '-3px',
    userSelect: 'none',
  },
  [`&.${classes.inlineItem}`]: {
    marginLeft: '4px',
  },
}))

export const LotLegendDiv = styled('div')(({ theme }) => ({
  [`&.${classes.lotLegendRoot}`]: {
    backgroundColor: theme.palette.mode === 'dark' ? '#282828' : 'white',
    position: 'relative',
    margin: '8px',
    padding: '0px 0px 7px 0px',
    border: '1px solid #cdcdcd',
    borderRadius: '6px',
    width: '340px',
    [theme.breakpoints.down('md')]: {
      width: '250px',
    },
  },
  [`&. ${classes.checkBox}`]: {
    padding: 0,
    position: 'absolute',
    right: 0,
  },

  [`&. ${classes.switch}`]: {
    position: 'relative',
    bottom: '8px',
  },
}))

export const StyledSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: pink[600],
    '&:hover': {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: pink[600],
  },
}))
