import React from 'react'

interface IconProps {
  width?: number
  height?: number
}

function SalesInsight(props: IconProps): JSX.Element {
  const { width, height } = props
  return (
    <svg
      enableBackground="new 0 0 512.011 512.011"
      height={height || 512}
      viewBox="0 0 512.011 512.011"
      width={width || 512}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <circle cx="188.506" cy="45" fill="#ffddce" r="45" />
        <path
          d="m233.506 45c0 24.81-20.19 45-45 45v-90c24.81 0 45 20.19 45 45z"
          fill="#ffccbd"
        />
        <circle cx="323.506" cy="45" fill="#d69a8b" r="45" />
        <path
          d="m368.506 45c0 24.81-20.19 45-45 45v-90c24.81 0 45 20.19 45 45z"
          fill="#c28678"
        />
        <path
          d="m121.006 190v-55c0-24.853 20.147-45 45-45h45c24.853 0 45 20.147 45 45v55z"
          fill="#13b8e5"
        />
        <path
          d="m256.006 135v55h-67.5v-100h22.5c24.85 0 45 20.15 45 45z"
          fill="#00a4dc"
        />
        <path
          d="m256.006 190v-55c0-24.853 20.147-45 45-45h45c24.853 0 45 20.147 45 45v55z"
          fill="#f47160"
        />
        <path
          d="m391.006 135v55h-67.5v-100h22.5c24.85 0 45 20.15 45 45z"
          fill="#fc5832"
        />
        <g>
          <path
            d="m211.006 382.736v83.264c0 5.61 3.131 10.751 8.114 13.326l60 31c9.923 5.127 21.886-2.06 21.886-13.326v-114.264l-45-10z"
            fill="#e8e1de"
          />
          <path
            d="m301.006 382.74v114.26c0 11.27-11.96 18.45-21.89 13.33l-23.11-11.94v-125.65z"
            fill="#dbd1cc"
          />
          <path
            d="m76.01 256.093 134.996 126.643h90l135-126.736-180-9.953z"
            fill="#f5f2f1"
          />
          <path d="m436.006 256-135 126.74h-45v-136.69z" fill="#e8e1de" />
          <path
            d="m436.006 256v-60c0-8.284-6.716-15-15-15h-330c-8.284 0-15 6.716-15 15 .001 12.785.005 60.093.005 60.093z"
            fill="#e8e1de"
          />
          <path
            d="m436.006 196v60l-180 .05v-75.05h165c8.28 0 15 6.72 15 15z"
            fill="#dbd1cc"
          />
        </g>
      </g>
    </svg>
  )
}

export default SalesInsight
