import {
  cloudinaryPrefix,
  getImagePrefix,
  getAssetsPrefix,
} from '@anewgo/utils'
import {
  Client,
  Elevation,
  Floorplan,
  Interior,
  Inventory,
  Prospect,
} from 'graphql/gen-types'

type getInteriorUrlReturn = {
  engineUrl: string
  selectionsForInterior: Record<number, InteriorSelection> | undefined
}

type InteriorSelections = {
  interiorId: number
  packageId: number
  elementId: number
  interiorSelections?: Record<number, InteriorSelection>
}

export type InteriorSelection = {
  mat: unknown
  sel: { id: number }
}

export const getInteriorUrl = (
  clientName: string,
  interior: Interior,
  interiorDesignSelections: Array<InteriorSelections>
): getInteriorUrlReturn => {
  const interiorId = interior.id

  const interiorDesignSelection = interiorDesignSelections.find(
    (item) => item.interiorId === interiorId
  )
  const selectionsForInterior =
    interiorDesignSelection?.interiorSelections || {}

  let engineUrl = ''

  // No selections
  if (!selectionsForInterior) {
    engineUrl = `${cloudinaryPrefix}/w_300/app/${clientName}/images/${interior.thumbnailSrc}`
  } else {
    // const matsForHemi = Object.keys(selectionsForInterior).toString()
    const optsForHemi =
      selectionsForInterior &&
      Object.values(selectionsForInterior)
        .map((val) => {
          return val.sel.id
        })
        .toString()
    engineUrl = `${process.env.REACT_APP_HEMI_ENGINE}/api/interior/client/${clientName}?interiorId=${interiorId}&opts=${optsForHemi}&key=123`
  }
  // Default return
  return { engineUrl, selectionsForInterior }
}

export const userIdentifier = (prospect: Prospect | null | undefined): string =>
  prospect ? `${prospect.name}<${prospect.email}>` : ''

export const formatCost = (value: number): string =>
  value
    .toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    .split('.')[0]

export const getDisclaimerText = (client: Client): string => {
  if (!client) {
    return ''
  }
  const disclaimer = client?.disclaimer
  const tgtDisclaimer =
    disclaimer ||
    'Elevations of a home may vary and we reserve the right to substitute and /or ' +
      'modify design and materials, in our sole opinion and without notice. Please see ' +
      'your actual home purchase agreement for additional information, disclosures and ' +
      "disclaimers related to the home and its features. Plans are artist's renderings " +
      `and may contain options which are not standard on all models. ${client.name} reserves ` +
      'the right to make changes to plans and elevations without prior notice. Stated ' +
      'dimensions and square footage are approximate and should not be used as ' +
      "representation of the home's precise or actual size. Any statement, verbal or " +
      "written, regarding 'under air' or 'finished area' or any other description or " +
      'modifier of the square footage size of any home is a shorthand description of ' +
      'the manner in which the square footage was estimated and should not be construed ' +
      'to indicate certainty. Garage sizes may vary from home to home and may not ' +
      `accommodate all vehicles. Visit ${client.website} or see a New Home Consultant for ` +
      'further details and important legal disclaimers. This is not an offer in states ' +
      'where prior registration is required. Void where prohibited by law.'
  return tgtDisclaimer
}

export const determineGaragePositionCaption = (
  elevation: Elevation,
  uiConfig: Record<string, unknown>
): string => {
  if (elevation && elevation.garagePosition && !elevation.mirrorElevationId) {
    if (elevation.garagePosition === 'LEFT') {
      if (uiConfig.mirror) {
        return 'Right'
      } else {
        return 'Left'
      }
    } else if (elevation.garagePosition === 'RIGHT') {
      if (uiConfig.mirror) {
        return 'Left'
      } else {
        return 'Right'
      }
    }
  }
  return ''
}

/**
 * This function is used to return a copy of elevationColors updated with
 * content from mirrorElevationColors. Use this function when your current
 * elevation doesn't have content (layers, schemes, materialPalettes, base)
 * and can re-use the content from the mirror elevation.
 * @param {*} elevationColors
 * @param {*} mirrorElevationColors
 */
export const applyMirrorContent = (
  elevationColors: Elevation,
  mirrorElevationColors: Elevation
): [Elevation, boolean] => {
  // we could potentially have a stricter check
  if (!mirrorElevationColors || elevationColors.layers?.length) {
    return [elevationColors, false]
  }
  const elevationColorsCopy = JSON.parse(
    JSON.stringify(elevationColors)
  ) as Elevation
  // borrow content from mirror elevation
  elevationColorsCopy.id = mirrorElevationColors.id
  elevationColorsCopy.thumb = mirrorElevationColors.thumb
  elevationColorsCopy.base = mirrorElevationColors.base
  elevationColorsCopy.layers = mirrorElevationColors.layers
  elevationColorsCopy.materialPalettes = mirrorElevationColors.materialPalettes
  if (elevationColors?.schemes?.length === 0) {
    elevationColorsCopy.schemes = mirrorElevationColors.schemes
  }
  return [elevationColorsCopy, true] // set to true, i.e. using mirror
}
export const mirrorElevationImage = (
  elevation: Elevation,
  uiConfig: any
): boolean =>
  (!elevation.mirrorElevationId &&
    !uiConfig?.mirror &&
    elevation.garagePosition &&
    elevation.defaultGaragePosition &&
    elevation.garagePosition !== elevation.defaultGaragePosition) ||
  (!elevation.mirrorElevationId &&
    uiConfig?.mirror &&
    elevation.garagePosition === elevation.defaultGaragePosition)

/**
 * For a given floor/story, and its associated selected options, determine
 * if this floor has a base floorplan. This function is called if you have a
 * floor that is optional and you want to know if an optional base is selected
 * @param {*} story - the floor in question
 * @param {*} optsSelected - the selected options for this floor
 */
export const hasBaseFloorplan = (
  story: { floorplans: Floorplan[] }, // temporary typing. Not sure which type it is.
  optsSelected: any
): boolean => {
  // see if we have a true base
  const floorplans: Floorplan[] = story?.floorplans
  if (floorplans.filter((fp) => fp.base && !fp.optional).length > 0) {
    return true
  }
  // see if we have an optional base selected
  const optionalBases = floorplans.filter((fp) => fp.base && fp.optional)
  let optionalBaseSelected = false
  optionalBases.forEach((optionalBase) => {
    if (
      optsSelected &&
      optsSelected.find((opt: any) => opt.id === optionalBase.id)
    ) {
      optionalBaseSelected = true
    }
  })
  return optionalBaseSelected
}

// TODO: type
export const combineInteriorSelections = (
  selections: any,
  standalone: any
): any => {
  if (standalone?.length > 0) {
    standalone.map((def: any) =>
      Object.assign(
        {},
        def,
        selections.find((actual: any) => actual.interiorId === def.interiorId)
      )
    )
  } else {
    return selections
  }
}

export const getInventoryImage = (
  directoryName: string, // client directory
  elevation: Elevation,
  inventory: Inventory | null
): string => {
  const inventoryPhoto = (inventory?.photos && inventory.photos[0]) || undefined
  let inventoryPhotoUrl = inventoryPhoto
    ? `${getAssetsPrefix(directoryName)}/inventory-${inventory?.id}/${
        inventoryPhoto.src
      }`
    : ''

  if (!inventoryPhotoUrl && elevation) {
    inventoryPhotoUrl = `${getImagePrefix(directoryName)}/${elevation.thumb}`
  }

  return inventoryPhotoUrl
}
