import React, { useMemo, useRef } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import chroma from 'chroma-js'
import { SchemeSwatch } from '@anewgo/interactive-exterior-ng'
import {
  getImagePrefix,
  texturesPrefix,
  anewgoCloudinaryPrefix,
  siteplans,
} from '@anewgo/utils'
import { useResizeObserver } from 'utils/hooks'
import { EVT_CLICKED_REQUEST_SGT_APPOINTMENT } from '../constants/eventTracking'
import { DEFAULT_FP_PAGE_SECTION_ORDER, HOMESITE_SELECT } from '../constants'

import {
  Client,
  Community,
  Elevation,
  Layer,
  Lot,
  Maybe,
  Prospect,
  SchemeElement,
  useGetSchemeQuery,
} from 'graphql/gen-types'

// TODO: this seems like it may not work how intended.
import { cloneDeep } from '@apollo/client/utilities'
// import cloneDeep from 'lodash.clonedeep'

import {
  CategoryTitle,
  ColorDisplayArea,
  ColorGrid,
  ColorSubtext,
  ColorSwatch,
  ColorSwatchContainer,
  ElevationSection2,
  FancyTitle,
  FancyTitleContainer,
  IconButtonStyled,
  InstructionText,
  LotInsetContainer,
  LotInsetDisplay,
  LotInsetWrapper,
  SchemeInfo,
  SchemeName,
  SchemeSwathWrapper,
  SmallColorSubtext,
  SpecsPanelText,
} from '../MyHome.styles'
import { HomeSelection, MyHomePageVariants } from '../MyHome'
import LotInsetSVGWrapper from '../components/LotInsetSVGWrapper'
import { cityFormatter } from '../../../../utils/formatters'
const { isInventoryLot } = siteplans

type MyHomeElevations2Props = {
  variant?: MyHomePageVariants
  client: Client
  builderAppConfig: any // These any are JSON types in GraphQL, not sure best way to resolve.
  selection: HomeSelection
  prospect: Prospect | undefined
  community: Community
  elevationColors: Elevation
  onRequestToChange: (target: string) => void
  onShowSignIn: () => void
  onDialogVisible: (visible: boolean) => void
  onTrackEvent?: (event: string, data: unknown) => void
}

export default function MyHomeElevation2({
  variant,
  client,
  builderAppConfig,
  selection,
  prospect,
  community,
  elevationColors,
  onRequestToChange,
  onShowSignIn,
  onDialogVisible,
  onTrackEvent,
}: MyHomeElevations2Props): JSX.Element {
  const directoryName = client?.directoryName || ''

  const lotInsetSectionRef = useRef(null)
  const [lotInsetSectionWidth] = useResizeObserver(lotInsetSectionRef)

  const lot = selection.lot
  const inventory = lot?.inventory
  const isMyHome = variant === 'myHome'
  const isInventory = lot && isInventoryLot(lot)
  const isEditable = !isInventory && isMyHome

  // get full scheme from selection.
  const getSchemeResult = useGetSchemeQuery({
    variables: {
      clientName: directoryName,
      schemeId: selection.scheme?.id || 0,
    },
    skip: !selection.scheme?.id,
  })

  const selectedScheme =
    selection.scheme?.id &&
    getSchemeResult.data?.scheme?.length &&
    getSchemeResult.data?.scheme[0]

  const handleRequestToChange = (target: string) => {
    onRequestToChange(target)
  }

  const handleShowSignIn = () => {
    onShowSignIn()
  }

  const handleDialogVisible = (visible: boolean) => {
    onDialogVisible(visible)
  }

  const handleTrackEvent = (event: string, data: unknown) => {
    onTrackEvent && onTrackEvent(event, data)
  }

  const fpPageSectionChoices = useMemo(() => {
    const defaultOrder = DEFAULT_FP_PAGE_SECTION_ORDER
    const sectionOrder =
      builderAppConfig?.page?.floorplan?.sectionOrder || defaultOrder
    return sectionOrder
  }, [builderAppConfig])

  const hasSiteplan = useMemo(() => {
    return fpPageSectionChoices.includes(HOMESITE_SELECT)
  }, [fpPageSectionChoices])

  const displayHomesiteSelection = () => {
    const { cityLocation } = community
    let address2 = ''
    if (lot && lot.cityName && lot.stateCode && (lot.zip || lot.postCode)) {
      address2 = `${lot.cityName}, ${lot.stateCode} ${lot.zip || lot.postCode}`
    } else if (cityLocation) {
      address2 = cityFormatter.address(cityLocation) as string
    }
    const lotSize = lot?.size || 0
    const localStyles = {
      lotInsetContainer: {
        width: lotInsetSectionWidth || undefined,
        height: lotInsetSectionWidth || undefined,
      },
    }
    return (
      <div
        // TODO: resolve className={classes.lotInsetSection}
        ref={lotInsetSectionRef}
      >
        <FancyTitleContainer>
          <FancyTitle>
            <span>Homesite Selection</span>
          </FancyTitle>
        </FancyTitleContainer>
        {lot && (
          <LotInsetContainer style={localStyles.lotInsetContainer}>
            {lotInsetDisplay(lotInsetSectionWidth, lot, lotSize, address2)}
          </LotInsetContainer>
        )}
        {!lot && community.primarySiteplan && (
          <Typography
            color="textSecondary"
            variant="h5"
            style={{ textAlign: 'center' }}
          >
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => handleRequestToChange('HOMESITE_SELECT')}
            >
              No Homesite Selected
              {isEditable ? '  -- Click Here to View Siteplan' : ''}
            </div>
          </Typography>
        )}
      </div>
    )
  }

  const displayColors = () => {
    const myColors = selection?.colorSelections || []
    const { colormtd } = community
    const extConfigScheme = lot?.inventory?.exteriorConfiguration?.scheme
    const tgtScheme = extConfigScheme || selectedScheme

    const hasColorSelections = Object.entries(myColors).length

    const hasSchemeSelections =
      (colormtd === 'SCHEME' || colormtd === 'HYBRID') &&
      (tgtScheme || hasColorSelections)

    const hasPaletteSelections =
      colormtd === 'PALETTE' && Object.keys(myColors).length > 0

    if (!hasSchemeSelections && !hasPaletteSelections) {
      return (
        <Typography
          color="textSecondary"
          variant="h5"
          style={{ textAlign: 'center' }}
        >
          {!isEditable ? (
            'No Exterior Colors Selected'
          ) : (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => handleRequestToChange('EXTERIOR_DESIGN')}
            >
              {`No Exterior Colors Selected -- -- Click Here to Design`}
            </div>
          )}
        </Typography>
      )
    }
    if (tgtScheme) {
      const materials: SchemeElement[] = (
        cloneDeep(tgtScheme.materials) || []
      ).filter((m): m is SchemeElement => m !== null && m !== undefined)
      if (colormtd === 'HYBRID') {
        // override color selections
        Object.keys(myColors).forEach((materialId) => {
          const tgtMaterialId = parseInt(materialId, 10)
          const selection = myColors[materialId] // color override
          const i = materials.findIndex(
            (mat) => mat?.materialId === tgtMaterialId
          )
          if (i !== -1) {
            materials[i].swatch = selection?.swatch
            materials[i].hex = selection?.hex
            materials[i].name = selection?.name
            materials[i].masonryLib = selection?.masonryLib
          }
        })
      }
      return (
        <React.Fragment>
          <SchemeInfo>
            <SchemeSwathWrapper>
              <SchemeSwatch
                directoryName={directoryName}
                materials={materials}
              />
            </SchemeSwathWrapper>
            <SchemeName>
              <Typography variant="h5">Scheme: {tgtScheme.name}</Typography>
            </SchemeName>
          </SchemeInfo>
          <ColorDisplayArea>
            {displayColorSwatches(materials, elevationColors.layers || [])}
          </ColorDisplayArea>
        </React.Fragment>
      )
    } else {
      const { layers, materialPalettes } = elevationColors
      // normalize color selections
      const normalizedColorSelections: SchemeElement[] = []
      Object.keys(myColors).forEach((materialId) => {
        const tgtMaterialId = parseInt(materialId, 10)
        const selection = myColors[materialId]
        const materialPalette = materialPalettes?.find(
          (palette) => palette?.materialId === tgtMaterialId
        )
        const layer = layers?.find(
          (layer) => layer?.materialId === tgtMaterialId
        )
        normalizedColorSelections.push({
          materialId: tgtMaterialId,
          swatch: selection?.swatch,
          masonryLib: selection?.masonryLib,
          type: selection?.type,
          hex: selection?.hex,
          material: layer?.name,
          name: selection?.name,
          materialCategory: materialPalette?.materialCategory,
        } as SchemeElement)
      })
      return (
        <ColorDisplayArea>
          {displayColorSwatches(
            normalizedColorSelections,
            elevationColors.layers || []
          )}
        </ColorDisplayArea>
      )
    }
  }

  const displayColorSwatches = (
    materials: SchemeElement[],
    layers: Maybe<Layer>[]
  ) => {
    // sort BODY, ACCENT, ROOF, TRIM, OTHERS
    const body: SchemeElement[] = []
    const accent: SchemeElement[] = [] // including roof
    const trim: SchemeElement[] = [] // including others

    if (materials.length === 0) {
      return null
    }
    materials.forEach((material) => {
      if (
        material.materialCategory === 'BODY' &&
        layers.find((layer) => layer?.materialId === material.materialId) !==
          undefined
      ) {
        body.push(material)
      } else if (
        material.materialCategory === 'ACCENT' ||
        material.materialCategory === 'ROOF'
      ) {
        if (
          layers.find((layer) => layer?.materialId === material.materialId) !==
          undefined
        ) {
          accent.push(material)
        }
      } else if (
        layers.find((layer) => layer?.materialId === material.materialId) !==
        undefined
      ) {
        trim.push(material)
      }
    })
    return (
      <React.Fragment>
        {displayCategoryColors('BODY', body, 'rgba(0,0,0,0.18)')}
        {displayCategoryColors('ACCENT', accent, 'rgba(0,0,0,0.12)')}
        {displayCategoryColors('TRIM', trim, 'rgba(0,0,0,0.05)')}
      </React.Fragment>
    )
  }

  const displayCategoryColors = (
    title: string,
    materials: SchemeElement[],
    color: string
  ) => {
    return (
      <ColorGrid>
        <CategoryTitle color={color}>
          <Typography variant="h6">{title}</Typography>
        </CategoryTitle>
        {materials.map((material, i) => (
          <ColorSwatchContainer key={`${i}-color`}>
            {material.swatch !== null && (
              <ColorSwatch
                style={{
                  backgroundImage: `url(${
                    material.masonryLib
                      ? texturesPrefix
                      : getImagePrefix(directoryName)
                  }/${material.swatch})`,
                }}
              />
            )}
            {material.hex !== null && (
              <ColorSwatch style={__getColorSwatchStyle(material.hex || '')} />
            )}
            <Typography
              variant="subtitle1"
              // TODO: resolve classes={{ subtitle1: classes.colortext }}
            >
              {`${material.material}`}
            </Typography>
            <ColorSubtext variant="caption">{`${material.name}`}</ColorSubtext>
            {material.type && (
              <SmallColorSubtext variant="caption">
                {`${material.type}`}
              </SmallColorSubtext>
            )}
          </ColorSwatchContainer>
        ))}
      </ColorGrid>
    )
  }

  const __getColorSwatchStyle = (hex: string) => {
    const lum = chroma(`#${hex}`).luminance()
    if (lum > 0.8) {
      return { backgroundColor: `#${hex}`, border: `1px solid #CDCDCD` }
    }
    return { backgroundColor: `#${hex}` }
  }

  const lotInsetDisplay = (
    width: number,
    lot: Lot,
    lotSize: number,
    address2: string
  ) => {
    // const insetDim = width * 0.5 || LOT_INSET_MIN_DIMENSION
    const sgtData = lot?.inventory?.sgtData
    const sgtVendors = client?.sgtVendors || []
    const sgtVendorData =
      sgtData &&
      sgtVendors &&
      sgtVendors.find((vendor) => vendor?.id === sgtData?.sgtVendorId)

    const { premium } = lot || {}
    const premiumToDisplay =
      premium &&
      premium
        .toLocaleString('en-US', { style: 'currency', currency: 'USD' })
        .split('.')[0]

    return (
      <LotInsetDisplay>
        <SpecsPanelText variant="h5" style={{ fontWeight: 'bold' }}>
          Lot {lot.name}
        </SpecsPanelText>
        {lot.address ? (
          <Typography variant="h5">{lot.address}</Typography>
        ) : null}
        {address2 ? (
          <SpecsPanelText variant="h5">{address2}</SpecsPanelText>
        ) : null}
        {premiumToDisplay ? (
          <Typography variant="h6">{`Lot Premium: ${premiumToDisplay}`}</Typography>
        ) : null}
        {lot && (
          <LotInsetWrapper
            style={{ cursor: isEditable ? 'pointer' : 'initial' }}
            onClick={() =>
              isEditable ? handleRequestToChange('HOMESITE_SELECT') : null
            }
          >
            {sgtData && sgtVendorData && sgtVendorData.icon ? (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  transform: 'translate(50%, -50%)',
                }}
              >
                <IconButtonStyled
                  aria-label="Request Tour Appointment"
                  onClick={(ev) => {
                    prospect ? handleDialogVisible(true) : handleShowSignIn()
                    handleTrackEvent(EVT_CLICKED_REQUEST_SGT_APPOINTMENT, {
                      community: { id: community.id, name: community.name },
                      lot: { id: lot.id, name: lot.name, address: lot.address },
                      inventoryId: lot.inventory?.id,
                      isSgtHome: !!lot.inventory?.sgtData,
                    })
                  }}
                  size="large"
                >
                  <img
                    alt=""
                    style={{ width: '150%' }}
                    src={`${anewgoCloudinaryPrefix}/assets/custom/${sgtVendorData.icon}`}
                  />
                </IconButtonStyled>
              </div>
            ) : null}
            <LotInsetSVGWrapper
              client={client}
              lot={lot}
              community={community}
              height={width * 0.5}
              width={width * 0.5}
            />
            {isEditable && (
              <InstructionText>click image to change</InstructionText>
            )}
          </LotInsetWrapper>
        )}
        {lotSize && community?.primarySiteplan?.lotMetric ? (
          <Typography variant="h5">{`${lotSize} ${community?.primarySiteplan?.lotMetric}`}</Typography>
        ) : null}
        {inventory?.appointmentsEnabled &&
        (!!inventory.sgtData || (community?.agents?.length || []) > 0) ? (
          <React.Fragment>
            <Button
              style={{ marginTop: 10 }}
              color="primary"
              variant={'contained'}
              // TODO: resolve className={classes.scheduleTourBtn}
              onClick={(ev) => {
                prospect ? handleDialogVisible(true) : handleShowSignIn()
                handleTrackEvent(EVT_CLICKED_REQUEST_SGT_APPOINTMENT, {
                  community: { id: community.id, name: community.name },
                  lot: { id: lot.id, name: lot.name, address: lot.address },
                  inventoryId: lot.inventory?.id,
                  isSgtHome: !!lot.inventory?.sgtData,
                })
              }}
            >
              Request a Tour
            </Button>
          </React.Fragment>
        ) : null}
      </LotInsetDisplay>
    )
  }

  return (
    <ElevationSection2>
      <div>
        <FancyTitleContainer>
          <FancyTitle>
            <span>Exterior Colors</span>
          </FancyTitle>
        </FancyTitleContainer>
        {displayColors()}
      </div>
      {hasSiteplan &&
        community.bonafide &&
        community.primarySiteplan &&
        displayHomesiteSelection()}
    </ElevationSection2>
  )
}
