import { styled } from '@mui/material/styles'

const PREFIX = 'JourneyBar'

export const classes = {
  journeyBarContainerVert: `${PREFIX}-journeyBarContainerVert`,
  iconContainer: `${PREFIX}-iconContainer`,
  iconContainerVert: `${PREFIX}-iconContainerVert`,
  iconDisabled: `${PREFIX}-iconDisabled`,
  circleIcon: `${PREFIX}-circleIcon`,
  iconPointer: `${PREFIX}-iconPointer`,
  iconPointerVert: `${PREFIX}-iconPointerVert`,
  progressBarMain: `${PREFIX}-progressBarMain`,
  progressBarMainVert: `${PREFIX}-progressBarMainVert`,
  progressBarProgress: `${PREFIX}-progressBarProgress`,
  progressBarProgressVert: `${PREFIX}-progressBarProgressVert`,
  stepper: `${PREFIX}-stepper`,
  stepperVert: `${PREFIX}-stepperVert`,
  stepVert: `${PREFIX}-stepVert`,
}

export const Root = styled('div')(({ theme: Theme }) => ({
  [`&.${classes.journeyBarContainerVert}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },

  [`& .${classes.iconContainer}`]: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
  },

  [`& .${classes.iconContainerVert}`]: {
    flexFlow: 'row',
  },

  [`& .${classes.iconDisabled}`]: {
    opacity: 0.54,
  },

  [`& .${classes.circleIcon}`]: {
    background: 'black',
    padding: 7,
    borderRadius: '50%',
    border: '2px solid gray',
  },

  [`& .${classes.iconPointer}`]: {
    marginTop: -10,
    background: 'gray',
    width: 2,
    height: 40,
  },

  [`& .${classes.iconPointerVert}`]: {
    width: 40,
    height: 2,
    margin: '0 0 0 -10px',
  },

  [`& .${classes.progressBarMain}`]: {
    height: 50,
    background: 'linear-gradient(to right, #fff33b, #e93e3a)',
    border: '1px solid gray',
  },

  [`& .${classes.progressBarMainVert}`]: {
    width: 50,
    overflow: 'hidden',
    background: 'linear-gradient(to bottom, #fff33b, #e93e3a)',
    border: '1px solid gray',
  },

  [`& .${classes.progressBarProgress}`]: {
    margin: 'auto',
    height: '70%',
    backgroundColor: 'transparent',
    backgroundImage: `url('${process.env.PUBLIC_URL}/images/arrow-right.svg')`,
    backgroundSize: 'contain',
    backgroundRepeat: 'repeat-x',
    backgroundPosition: 'right',
  },

  [`& .${classes.progressBarProgressVert}`]: {
    width: '70%',
    height: '100%',
    margin: 'auto',
    transition: 'transform .4s linear',
    backgroundImage: `url('${process.env.PUBLIC_URL}/images/arrow-down.svg')`,
    backgroundSize: 'contain',
    backgroundRepeat: 'repeat-y',
    backgroundPosition: 'bottom',
  },

  [`& .${classes.stepper}`]: {
    marginTop: -10,
    padding: 0,
    backgroundColor: 'transparent',
  },

  [`& .${classes.stepperVert}`]: {
    margin: '0 0 0 -10px',
  },

  [`& .${classes.stepVert}`]: {
    padding: '15px 0 15px 0',
  },
}))
