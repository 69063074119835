import React from 'react'
import { ProspectScheme } from 'graphql/gen-types'
import { Maybe } from 'graphql/jsutils/Maybe'

import ResponsiveTable, {
  ResponsiveCell,
} from 'components/common/tables/ResponsiveTable'

export default function SchemesTable({
  schemes,
}: {
  schemes: Maybe<ProspectScheme>[] | undefined | null
}): JSX.Element {
  const columns = [
    <ResponsiveCell responseSize="md" align="center" key={2}>
      <strong>Color Scheme</strong>
    </ResponsiveCell>,
  ]

  const generateRow = (
    dataRow: ProspectScheme | undefined | null,
    index?: number
  ): JSX.Element => {
    return (
      <>
        <ResponsiveCell
          responseSize="md"
          align="center"
          key="row-cell-scheme-name"
        >
          {dataRow?.scheme?.name || 'No name'}
        </ResponsiveCell>
      </>
    )
  }

  return (
    <ResponsiveTable
      data={schemes || []}
      columns={columns}
      generateRow={generateRow}
      dense={true}
    />
  )
}
