import React, { useState } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import Divider from '@mui/material/Divider'
import ExitToApp from '@mui/icons-material/ExitToApp'
import Assignment from '@mui/icons-material/Assignment'
import Mail from '@mui/icons-material/Mail'
import LeadsInsightIcon from 'components/icons/LeadsInsightIcon'
import AnalyticsInsightIcon from 'components/icons/AnalyticsInsightIcon'
import hydrationStore from 'store/HydrationStore'
import { ANALYTICS_APP, BUY_ONLINE_APP, LEADS_APP } from '../../../../constants'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'

import { isAnewgoMember } from 'utils/authorizationHelpers'
import { MenuIf } from '../MainDrawerContent'
// import jwtDecode from 'jwt-decode'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import BuyOnlineIcon from '../../../icons/BuyOnlineIcon'
import { EMAIL_CAMPAIGN_INDEX, SIGN_OUT_INDEX } from '../constants'

export default function MarketingMenu({
  selectedMenuIndex,
  selectedClientName,
  classes,
  navigate,
  handleSignOut,
  handleInsightClick,
  handleSelectBuilderClick,
  roleId,
  handleDrawerToggle,
}: MenuIf): JSX.Element {
  const [openMarketing, setOpenMarketing] = useState(true)
  const handleMarketingClick = (): void => setOpenMarketing(!openMarketing)

  const handleMarketingMenuClick = (
    index: React.SetStateAction<number>
  ): void => {
    // re-route
    let route = ''
    switch (index) {
      case EMAIL_CAMPAIGN_INDEX:
        route = `targeted-marketing/client/${selectedClientName}/email-campaign`
        break
      case SIGN_OUT_INDEX:
        route = '/login'
        hydrationStore.clearAll() // clear all local storage variables
        handleSignOut() // reInitialize props
        break
      default:
        route = '/'
        break
    }
    navigate(route)

    if (handleDrawerToggle) {
      handleDrawerToggle()
    }
  }

  // const hydratedToken = hydrationStore.token || ''
  // const { colonnadeRoleId } = jwtDecode(hydratedToken)

  return (
    <>
      <List
        component="nav"
        aria-labelledby="leads-reports"
        subheader={
          <ListSubheader component="div" id="leads-reports">
            Targeted Marketing
          </ListSubheader>
        }
      >
        <List>
          <ListItem
            button
            onClick={handleMarketingClick}
            classes={{ selected: classes.selected }}
          >
            <ListItemIcon>
              <Assignment />
            </ListItemIcon>
            <ListItemText primary="Targeted Marketing" />
            {openMarketing ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openMarketing} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                classes={{ selected: classes.selected }}
                button
                onClick={(): void =>
                  handleMarketingMenuClick(EMAIL_CAMPAIGN_INDEX)
                }
                selected={selectedMenuIndex === EMAIL_CAMPAIGN_INDEX}
              >
                <ListItemIcon>
                  <Mail />
                </ListItemIcon>
                <ListItemText primary={'Email Campaign'} />
              </ListItem>
            </List>
          </Collapse>
        </List>
      </List>
      <Divider />
      <List>
        <ListItem
          button
          classes={{ selected: classes.selected }}
          onClick={() => handleInsightClick(ANALYTICS_APP)}
        >
          <ListItemIcon classes={{ root: classes.iconNormalColor }}>
            <AnalyticsInsightIcon width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={'Analytics Insights'} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          button
          classes={{ selected: classes.selected }}
          onClick={() => handleInsightClick(LEADS_APP)}
        >
          <ListItemIcon classes={{ root: classes.iconNormalColor }}>
            <LeadsInsightIcon width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={'Leads Insights'} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          button
          classes={{ selected: classes.selected }}
          onClick={() => handleInsightClick(BUY_ONLINE_APP)}
        >
          <ListItemIcon classes={{ root: classes.iconNormalColor }}>
            <BuyOnlineIcon width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={'Reserve Online'} />
        </ListItem>
      </List>
      <Divider />
      <List>
        {isAnewgoMember(roleId) && (
          <ListItem
            classes={{ selected: classes.selected }}
            button
            onClick={(): void => handleSelectBuilderClick()}
          >
            <ListItemIcon>
              <KeyboardReturnIcon />
            </ListItemIcon>
            <ListItemText primary={'Select Builder'} />
          </ListItem>
        )}
        <ListItem
          button
          classes={{ selected: classes.selected }}
          onClick={(): void => handleMarketingMenuClick(SIGN_OUT_INDEX)}
        >
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary={'Sign Out'} />
        </ListItem>
      </List>
    </>
  )
}
