import { TrackAction } from '@anewgo/tracking/lib/types'

export class ReservationTrackEvents {
  static CLICKED_CONTINUE_BUTTON_IN_GETTING_STARTED_DIALOG =
    'Clicked Continue button in Getting Started Dialog'
  static CLICKED_CONNECT_WITH_STRIPE = 'Clicked Connect with Stripe'
  static CLICKED_UPDATE_CUSTOM_TEMPLATE_FIELDS =
    'Clicked Update Custom Template Fields'
  static SWITCH_ENABLE_BUY_ONLINE = 'Switch Enable Buy Online'
  static CLICKED_CANCEL_IN_CONFIRMATION_TO_DISABLE_BUY_ONLINE_DIALOG =
    'Clicked Cancel in Confirmation to disable Buy Online Dialog'
  static CLICKED_DISABLE_BUY_ONLINE_IN_CONFIRMATION_TO_DISABLE_BUY_ONLINE_DIALOG =
    'Clicked Disable Buy Online in Confirmation to disable Buy Online Dialog'
  static SELECT_COMMUNITY_IN_COMMUNITY_SPECIFIC_DOCUMENT_CONFIGURATION_SECTION =
    'Select Community in Community Specific Document Configuration Section'
  static CLICKED_SIGN_RESERVATION = 'Clicked Sign Reservation'
  static CLICKED_REJECT_RESERVATION = 'Clicked Reject Reservation'
  static SIGNED_IN_DOCUSIGN = 'Signed in Docusign'
  static DECLINED_IN_DOCUSIGN = 'Declined in Docusign'
  static CLICKED_CANCEL_IN_REJECT_DIALOG = 'Clicked Cancel in Reject Dialog '
  static CLICKED_CONFIRM_AND_CONTINUE_IN_REJECT_DIALOG =
    'Clicked Confirm and Continue in Reject Dialog '
  static CLICKED_DELETE_RESERVATION = 'Clicked Delete Reservation'
  static CLICKED_RESTORE_DELETED_RESERVATION =
    'clicked Restore Deleted Reservation'
  static CLICKED_CANCEL_IN_DELETION_POPOVER =
    'Clicked Cancel in Deletion Popover'
  static CLICKED_CANCEL_IN_DELETION_RESTORATION_POPOVER =
    'Clicked Cancel in Deletion Restoration Popover'
  static CLICKED_YES_IN_DELETION_POPOVER = 'Clicked Yes in Deletion Popover'
  static CLICKED_YES_IN_DELETION_RESTORATION_POPOVER =
    'Clicked Yes in Deletion Restoration Popover'
  static CLICKED_RESET_FILTER = 'Clicked Reset Filter'
  static SWITCH_INCLUDE_DELETED_FILTER = 'Switch Include Deleted filter'
  static CHANGE_BUYER_EMAIL_OR_NAME_FILTER = 'Change Buyer email or name filter'
  static CHANGE_COMMUNITY_FILTER = 'Change Community filter'
  static CHANGE_STATUS_FILTER = 'Change Status Filter'
  static CHANGED_DOCUSIGN_TEMPLATE_ID = 'Changed Docusign template id'
  static CHANGED_CONTACT_EMAIL = 'Changed Contact email'
  static CHANGED_CONTACT_FULL_NAME = 'Changed Contact full name'
  static CHANGED_RESERVATION_DEPOSIT_AMOUNT =
    'Changed Reservation deposit amount'
  static CHANGED_RESERVATION_NON_REFUNDABLE_FEE =
    'Changed Reservation non refundable fee'
  static CHANGED_RESERVATION_ANEWGO_FEE = 'Changed reservation anewgo fee'
  static CHANGED_PROVIDE_PROOF_OF_FINANCING =
    'Changed Provide proof of financing'
}

export const trackReservationIntegrationForm = (
  track: TrackAction,
  formKey: string,
  value: string | number
) => {
  let trackTitle = null
  switch (formKey) {
    case 'signingAgreementTemplateId':
      trackTitle = ReservationTrackEvents.CHANGED_DOCUSIGN_TEMPLATE_ID
      break
    case 'primaryBuyOnlineContactEmail':
      trackTitle = ReservationTrackEvents.CHANGED_CONTACT_EMAIL
      break
    case 'primaryBuyOnlineContactName':
      trackTitle = ReservationTrackEvents.CHANGED_CONTACT_FULL_NAME
      break
    case 'depositAmount':
      trackTitle = ReservationTrackEvents.CHANGED_RESERVATION_DEPOSIT_AMOUNT
      break
    case 'nonRefundableAmount':
      trackTitle = ReservationTrackEvents.CHANGED_RESERVATION_NON_REFUNDABLE_FEE
      break
    case 'anewgoFee':
      trackTitle = ReservationTrackEvents.CHANGED_RESERVATION_ANEWGO_FEE
      break
    case 'daysToProveFinancing':
      trackTitle = ReservationTrackEvents.CHANGED_PROVIDE_PROOF_OF_FINANCING
      break
  }

  if (trackTitle) {
    track(trackTitle, { value })
  }
}
