import TimesSeriesLineChart from 'components/common/time-series/TimeSeriesLineChart'
import {
  useGetSchemeTrendsQuery,
  SchemeTrend,
  CommonQueryFilter,
} from 'graphql/gen-types'
import React, { useEffect, useRef, useState } from 'react'
import { addZeroCountDates } from 'components/common/time-series/TimeSeriesFormatter'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import ComponentCard from 'components/common/layout/ComponentCard'
import { useMediaQuery, useTheme } from '@mui/material'

export interface SchemeTrendsType {
  filter: CommonQueryFilter
  areaChart?: boolean | false
  height?: number | null
  width?: number | null
}

const CHART_HEIGHT = 300 // base height of graph
const LEGEND_ELEMENT_WIDTH = 120 // single legend element width
const LEGEND_ELEMENT_HEIGHT = 85 // single legend element height
const LEGEND_OFFSET = 75 // sum of padding (or margin) from right and left

export default function SchemeTrends({
  filter,
  areaChart,
  height,
  width,
}: SchemeTrendsType): JSX.Element {
  const { data, loading, error } = useGetSchemeTrendsQuery({
    variables: {
      filter: filter,
    },
  })

  const [schemes, setSchemes] = useState<SchemeTrend[]>([])

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [heightCount, setHeight] = useState()
  const ref = useRef<HTMLDivElement>()
  const baseHeight = height || CHART_HEIGHT
  const elementWidth = LEGEND_ELEMENT_WIDTH * schemes?.length

  // if screen width is small just for one element per row
  const rowsCount =
    ref?.current?.offsetWidth > 280
      ? Math.ceil(elementWidth / (ref?.current?.offsetWidth - LEGEND_OFFSET))
      : schemes.length

  useEffect(() => {
    const schemesRaw = data?.getSchemeTrends.schemes || []
    const _schemes = schemesRaw.map((schemeTrend: SchemeTrend) => {
      return {
        ...schemeTrend,
        dailyMetrics: addZeroCountDates(
          schemeTrend.dailyMetrics,
          filter.metric,
          new Date(filter.startTime),
          new Date(filter.endTime)
        ),
      } as SchemeTrend
    })
    setSchemes(_schemes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.getSchemeTrends])

  useEffect(() => {
    if (ref?.current?.offsetWidth && isMobile) {
      setHeight(baseHeight + rowsCount * LEGEND_ELEMENT_HEIGHT)
    }
  }, [rowsCount])

  const yAxisDataKey = 'value'

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized message={error.toString()} imageName={'SchemeTrends'} />
      )
    }
    console.error(error)
    return <div>Error while loading scheme trends</div>
  }

  return loading || schemes.length > 0 ? (
    <div ref={ref}>
      <ComponentCard
        title="Scheme Trends"
        result={{ data: schemes, error, loading }}
        skeletonHeight={500}
        height={heightCount}
      >
        <TimesSeriesLineChart
          data={schemes}
          yAxisDataKey={yAxisDataKey}
          tooltipDataKey={'schemeName'}
          xAxisDomain={{
            lower: filter.startTime,
            upper: filter.endTime,
          }}
          useCustomLegend={true}
          metric={filter.metric}
          areaChart={areaChart}
          height={height}
          width={width}
        />
      </ComponentCard>
    </div>
  ) : (
    <></>
  )
}
