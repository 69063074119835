import React, { useContext, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { useQuery } from '@apollo/client'
import Alert from '@mui/material/Alert'
import FormControl from '@mui/material/FormControl'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import ListItem from '@mui/material/ListItem'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Grid from '@mui/material/Grid'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'

import LoadingButton from '../../common/custom-inputs/LoadingButton'
import {
  Community,
  useUpdateReservationCustomTemplateFieldsMutation,
  useAddOrUpdateCommunityBuyOnlineCustomConfigurationTemplateValuesMutation,
  useGetCommunityBuyOnlineCustomConfigLazyQuery,
  useToggleCommunityByOnlineEnabledMutation,
} from '../../../graphql/gen-types'
import { AppStore } from '../../../store'
import {
  GET_CLIENT_RESERVATION_INTEGRATION,
  GET_COMMUNITY_BUY_ONLINE_CUSTOM_CONFIG,
  HAS_COMMUNITY_IN_PROGRESS_RESERVATION,
} from '../../../graphql/nexus-queries/reservationIntegration'
import ReservationIntegrationDisableForCommunityDialog from './ReservationIntegrationDisableForCommunityDialog'
import { useEventTracker } from '../../../hooks/tracking'
import { ReservationTrackEvents } from '../reservationTrackEvents'

const PREFIX = 'ReservationIntegrationCustomDocumentFieldManagement'

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  formRoot: `${PREFIX}-formRoot`,
  mainDiv: `${PREFIX}-mainDiv`,
  wrapperDiv: `${PREFIX}-wrapperDiv`,
  wrapperDivInput: `${PREFIX}-wrapperDivInput`,
  list: `${PREFIX}-list`,
  listItem: `${PREFIX}-listItem`,
  listItemText: `${PREFIX}-listItemText`,
  contentDisallowed: `${PREFIX}-contentDisallowed`,
  helpButton: `${PREFIX}-helpButton`,
  progressBar: `${PREFIX}-progressBar`,
  disclaimer: `${PREFIX}-disclaimer`,
  dynamicDiv: `${PREFIX}-dynamicDiv`,
  updateButton: `${PREFIX}-updateButton`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    position: 'relative',
  },

  [`& .${classes.paper}`]: {
    position: 'relative',
    overflow: 'hidden',
    padding: 20,
    margin: '1.5em 0',
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.light
        : theme.palette.primary.main,
  },

  [`& .${classes.formRoot}`]: {
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.light
        : theme.palette.primary.main,
  },

  [`& .${classes.mainDiv}`]: {
    display: 'flex',
  },

  [`& .${classes.wrapperDiv}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 10,
    height: 60,
    [theme.breakpoints.down('md')]: {
      height: 'initial',
      flexDirection: 'column',
    },
  },

  [`& .${classes.wrapperDivInput}`]: {
    marginRight: '12px',
    minWidth: MIN_WIDTH,
    maxWidth: MAX_WIDTH,
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },

  [`& .${classes.list}`]: {
    marginLeft: 20,
    float: 'left',
  },

  [`& .${classes.listItem}`]: {
    padding: 0,
  },

  [`& .${classes.listItemText}`]: {
    margin: 0,
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.light
        : theme.palette.primary.main,
  },

  [`& .${classes.contentDisallowed}`]: {
    filter: 'blur(3px)',
    pointerEvents: 'none',
  },

  [`& .${classes.helpButton}`]: {
    display: 'flex',
    marginLeft: 'auto',
  },

  [`& .${classes.progressBar}`]: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },

  [`& .${classes.disclaimer}`]: {
    '& a': {
      color: theme.palette.primary.main,
    },
    marginBottom: 10,
  },

  [`& .${classes.dynamicDiv}`]: {
    marginTop: 50,
  },

  [`& .${classes.updateButton}`]: {
    marginTop: 16,
  },
}))

const MIN_WIDTH = 250
const MAX_WIDTH = 400

export interface ReservationIntegrationCustomDocumentFieldManagementProps {
  templateCustomFields: string[]
  templateId: string
  communities: Community[]
}

const ReservationIntegrationCustomDocumentFieldManagement: React.FC<ReservationIntegrationCustomDocumentFieldManagementProps> = ({
  templateCustomFields,
  templateId,
  communities,
}) => {
  const sortedTemplateCustomFields = [...templateCustomFields].sort()
  const [
    updateReservationCustomTemplateFields,
  ] = useUpdateReservationCustomTemplateFieldsMutation()
  const [
    addOrUpdateCommunityReservationCustomTemplateFieldsValues,
  ] = useAddOrUpdateCommunityBuyOnlineCustomConfigurationTemplateValuesMutation()
  const [
    getCommunityBuyOnlineCustomConfig,
    { data: communityConfigData },
  ] = useGetCommunityBuyOnlineCustomConfigLazyQuery()
  const [
    toggleCommunityBuyOnlineEnabled,
  ] = useToggleCommunityByOnlineEnabledMutation()
  const { appState } = useContext(AppStore)
  const clientName = appState?.selectedClient?.altName || ''
  const track = useEventTracker()

  const [isFetchingCustomFields, setIsFetchingCustomFields] = useState<boolean>(
    false
  )
  const [values, setValues] = useState({})
  const [isBuyOnlineEnabled, setIsBuyOnlineEnabled] = useState(false)

  const [selectedCommunityId, setSelectedCommunityId] = useState<number>(0)
  const [openedInProgressDialog, setOpenedInProgressDialog] = useState<boolean>(
    false
  )
  const [
    customTemplateFieldsErrorMessage,
    setCustomTemplateFieldsErrorMessage,
  ] = useState('')

  const {
    loading: loadingCommunityInProgressData,
    data: communityInProgressData,
  } = useQuery(HAS_COMMUNITY_IN_PROGRESS_RESERVATION, {
    skip: !selectedCommunityId,
    variables: {
      clientName: clientName,
      communityId: selectedCommunityId,
    },
  })

  const hasCurrentCommunityResInProgress =
    communityInProgressData?.hasCommunityInProgressReservation

  useEffect(() => {
    if (sortedTemplateCustomFields) {
      setValues(
        sortedTemplateCustomFields?.reduce((acc, curr) => {
          return {
            ...acc,
            [curr]:
              communityConfigData?.getCommunityBuyOnlineCustomConfig
                ?.buyOnlineConfig?.communityTemplateValues?.[curr] || null,
          }
        }, {})
      )
      setIsBuyOnlineEnabled(
        communityConfigData?.getCommunityBuyOnlineCustomConfig
          ?.buyOnlineEnabled || false
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateCustomFields, communityConfigData])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues((prevValues) => ({
      ...prevValues,
      [event.target.name]: event.target.value || null,
    }))
  }

  const handleBlur = () => {
    if (selectedCommunityId !== 0) {
      addOrUpdateCommunityReservationCustomTemplateFieldsValues({
        variables: {
          clientName,
          communityId: selectedCommunityId,
          configValues: values,
        },
        refetchQueries: [
          {
            query: GET_COMMUNITY_BUY_ONLINE_CUSTOM_CONFIG,
            variables: {
              clientName: appState?.selectedClient?.altName,
              communityId: selectedCommunityId,
            },
          },
        ],
      })
    }
  }

  const handleCommunityChange = (event: SelectChangeEvent<string | number>) => {
    const value = event.target.value as number
    track(
      ReservationTrackEvents.SELECT_COMMUNITY_IN_COMMUNITY_SPECIFIC_DOCUMENT_CONFIGURATION_SECTION,
      {
        communityId: value,
      }
    )
    if (value) {
      getCommunityBuyOnlineCustomConfig({
        variables: {
          clientName: clientName,
          communityId: value,
        },
      })
    }
    setSelectedCommunityId(value || 0)
  }

  const closeInProgressDialog = () => {
    setOpenedInProgressDialog(false)
  }

  const handleCloseInProgressDialog = () => {
    track(
      ReservationTrackEvents.CLICKED_CANCEL_IN_CONFIRMATION_TO_DISABLE_BUY_ONLINE_DIALOG,
      {
        communityId: selectedCommunityId,
      }
    )
    closeInProgressDialog()
  }

  const handleOpenInProgressDialog = () => {
    setOpenedInProgressDialog(true)
  }

  const handleConfirmInProgressDialog = () => {
    track(
      ReservationTrackEvents.CLICKED_DISABLE_BUY_ONLINE_IN_CONFIRMATION_TO_DISABLE_BUY_ONLINE_DIALOG,
      {
        communityId: selectedCommunityId,
      }
    )
    closeInProgressDialog()
    handleToggleCommunityBuyOnlineEnabled(false)
  }

  const handleToggleBuyOnline = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    track(ReservationTrackEvents.SWITCH_ENABLE_BUY_ONLINE, {
      isEnable: event.target.checked,
      communityId: selectedCommunityId,
    })
    if (hasCurrentCommunityResInProgress && !event.target.checked) {
      handleOpenInProgressDialog()
    } else {
      handleToggleCommunityBuyOnlineEnabled(event.target.checked)
    }
  }

  const handleToggleCommunityBuyOnlineEnabled = (checked: boolean) => {
    if (selectedCommunityId !== 0) {
      toggleCommunityBuyOnlineEnabled({
        variables: {
          clientName,
          communityId: selectedCommunityId,
          enabled: checked,
        },
        refetchQueries: [
          {
            query: GET_COMMUNITY_BUY_ONLINE_CUSTOM_CONFIG,
            variables: {
              clientName: appState?.selectedClient?.altName,
              communityId: selectedCommunityId,
            },
          },
        ],
      }).then((res) => {
        setIsBuyOnlineEnabled(
          res?.data?.toggleCommunityBuyOnlineEnabled || false
        )
      })
    }
  }

  let selectOptions = communities.map((community) => (
    <MenuItem key={community.id} value={community.id}>
      <ListItemText primary={community.name} />
    </MenuItem>
  ))
  selectOptions = [
    <MenuItem key={'no community selected'} value={0}>
      <ListItemText primary={'None'} />
    </MenuItem>,
    ...selectOptions,
  ]

  const handleUpdateCustomTemplateFields = () => {
    setCustomTemplateFieldsErrorMessage('')
    track(ReservationTrackEvents.CLICKED_UPDATE_CUSTOM_TEMPLATE_FIELDS, {})
    setIsFetchingCustomFields(true)
    updateReservationCustomTemplateFields({
      variables: {
        clientId: appState?.selectedClient?.id || 0,
        templateId,
      },
      refetchQueries: [
        {
          query: GET_CLIENT_RESERVATION_INTEGRATION,
          variables: { clientId: appState?.selectedClient?.id },
        },
      ],
    })
      .catch((error) => {
        if (error.message.includes('Signer with role')) {
          setCustomTemplateFieldsErrorMessage(error.message)
        }
      })
      .finally(() => {
        setIsFetchingCustomFields(false)
      })
  }

  const generateTemplateCustomFieldInputs = (fields: string[]) => {
    return fields.map((field) => (
      <Grid item xs={12} sm={6} key={field}>
        <FormControl margin="normal" fullWidth variant="standard">
          <InputLabel htmlFor="field">{field}</InputLabel>
          <Input
            id={`${field}${values[field]}` ?? ''}
            value={values[field] ?? ''}
            name={field}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormControl>
      </Grid>
    ))
  }
  return (
    <Root>
      <Card className={classes.paper}>
        <CardHeader title="Community Specific Document Configuration" />
        <CardContent>
          <div className={classes.wrapperDiv}>
            <FormControl className={classes.wrapperDivInput} variant="standard">
              <InputLabel htmlFor="select-multiple-communities">
                Select Community
              </InputLabel>
              <Select
                variant="standard"
                value={selectedCommunityId || ''}
                onChange={handleCommunityChange}
                input={<Input id="select-community" />}
              >
                {selectOptions}
              </Select>
            </FormControl>
            <LoadingButton
              variant="contained"
              color="primary"
              size="small"
              className={classes.updateButton}
              loading={isFetchingCustomFields}
              disabled={isFetchingCustomFields}
              onClick={handleUpdateCustomTemplateFields}
            >
              Update Custom Template Fields
            </LoadingButton>
          </div>
          <div>
            {customTemplateFieldsErrorMessage !== '' && (
              <Alert severity="error" icon={false}>
                {customTemplateFieldsErrorMessage}
              </Alert>
            )}
            {!selectedCommunityId && (
              <>
                <Alert
                  severity="info"
                  icon={false}
                  className={classes.disclaimer}
                >
                  * If the DocuSign template has been updated with additional
                  fields, please click the "Update Custom Template Fields"
                  button above to generate the latest collection of template
                  custom fields.
                  <br />
                  {`Please select a community to configure the custom template field
              values for that community. Currently, ${
                sortedTemplateCustomFields?.length > 0
                  ? `the custom template fields
              that are configurable are the following:`
                  : `you have no custom template fields configured.`
              }`}
                </Alert>
                <List dense={true} className={classes.list}>
                  {sortedTemplateCustomFields?.map((field) => (
                    <ListItem dense className={classes.listItem} key={field}>
                      <ListItemText
                        primary={field}
                        className={classes.listItemText}
                      />
                    </ListItem>
                  ))}
                </List>
              </>
            )}
            {selectedCommunityId !== 0 && (
              <form className={classes.formRoot}>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={loadingCommunityInProgressData}
                      checked={isBuyOnlineEnabled}
                      onChange={handleToggleBuyOnline}
                    />
                  }
                  label="Enable Reserve Online for this community"
                />
                {sortedTemplateCustomFields?.length > 0 && (
                  <Grid container spacing={3}>
                    {values &&
                      generateTemplateCustomFieldInputs(
                        sortedTemplateCustomFields
                      )}
                  </Grid>
                )}
              </form>
            )}
          </div>
        </CardContent>
      </Card>
      <ReservationIntegrationDisableForCommunityDialog
        opened={openedInProgressDialog}
        onClose={handleCloseInProgressDialog}
        onConfirm={handleConfirmInProgressDialog}
      />
    </Root>
  )
}

export default ReservationIntegrationCustomDocumentFieldManagement
