import React, { useMemo } from 'react'
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import chroma from 'chroma-js'
import StopIcon from '@mui/icons-material/Stop'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { Community, LeadRanking } from '../../graphql/gen-types'
import {
  CustomTooltipStyled,
  PercentInTooltip,
} from './LeadRankingsChart.styles'
import {
  CustomLabelProps,
  CustomLegendProps,
} from 'components/common/charts/chart-types'
import ComponentCard from '../common/layout/ComponentCard'
import Grid from '@mui/material/Grid'

type QueryResult = {
  data?: unknown[] | undefined | null
  loading: boolean | undefined
  error: Error | undefined
}

interface LeadsByCommunityChartProps {
  chartData: LeadRanking[]
  communities: Community[]
  result: QueryResult
  report?: boolean
}

interface ChartData {
  name: string | null | undefined
  value: number
  id?: number
}

type CustomTooltipProps = {
  payload?: Payload[]
  active?: boolean
}

type Payload = {
  payload: ChartData
  name: string
  value: number
}

const renderCustomizedLabel = (data: CustomLabelProps): JSX.Element | null => {
  const { cx, cy, midAngle, innerRadius, outerRadius, percent } = data
  // This snippet of code and calculation comes from here:
  // http://recharts.org/en-US/examples/PieChartWithCustomizedLabel
  const RADIAN = Math.PI / 180
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  // If the pie slice is going to be small (10% or less), we are not going to display the percent
  // text because it can overlap into other slices.
  if (percent * 100 <= 10) {
    return null
  }

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
    >
      {`${
        percent * 100 < 1
          ? (percent * 100).toFixed(2)
          : (percent * 100).toFixed(0)
      }%`}
    </text>
  )
}

const LeadsByCommunityChart = ({
  chartData,
  communities,
  result,
  report,
}: LeadsByCommunityChartProps): JSX.Element => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down('sm'))
  const md = useMediaQuery(theme.breakpoints.down('lg'))
  const lg = useMediaQuery(theme.breakpoints.down('xl'))
  let width = 500 // default width
  if (xs || report) {
    width = 300
  } else if (md) {
    width = 400
  } else if (lg) {
    width = 430
  }

  let dataToDisplay: ChartData[] = communities
    .map((community: Community) => ({
      name: community.name,
      id: community.id,
      value: chartData.filter(
        (lead: LeadRanking) =>
          lead.communities &&
          lead.communities
            .map((comm) => comm?.communityId)
            .includes(community.id)
      ).length,
    }))
    .filter((comm: ChartData) => comm.value > 0)

  const totalValue: number = useMemo(() => {
    return dataToDisplay.reduce((acc, data) => (data.value || 0) + acc, 0)
  }, [dataToDisplay])

  const renderLegend = ({ payload }: CustomLegendProps): JSX.Element | null => {
    if (!payload) {
      return null
    }
    return (
      <ul style={{ listStyleType: 'none' }}>
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{ color: entry.color }}>
            <StopIcon
              style={{
                verticalAlign: 'top',
                height: '100%',
                marginTop: '-2px',
              }}
            />
            {`${
              entry.payload.percent * 100 < 1
                ? (entry.payload.percent * 100).toFixed(2)
                : (entry.payload.percent * 100).toFixed(0)
            }% (${entry.payload.value}) `}
            {entry.value}
          </li>
        ))}
      </ul>
    )
  }

  const CustomTooltip = (data: CustomTooltipProps): JSX.Element | null => {
    const { active, payload } = data
    if (!payload) {
      return null
    }
    if (active) {
      return (
        <CustomTooltipStyled>
          <PercentInTooltip>{`${
            (payload[0].value / totalValue) * 100 < 1
              ? ((payload[0].value / totalValue) * 100).toFixed(2)
              : ((payload[0].value / totalValue) * 100).toFixed(0)
          }%`}</PercentInTooltip>
          <h4 style={{ paddingTop: '0px' }}>{payload[0].name}</h4>
          <p className="label">{`Number of leads: ${payload[0].value}`}</p>
        </CustomTooltipStyled>
      )
    }

    return null
  }

  const communitiesToDisplay = report ? 3 : 10
  // If there are more than 10 communities display just top 10
  // The rest will be displayed as Other communities
  if (dataToDisplay.length > communitiesToDisplay) {
    const other: ChartData = {
      name: `Other communities (${
        dataToDisplay.length - communitiesToDisplay
      })`,
      value: dataToDisplay
        .slice(communitiesToDisplay, dataToDisplay.length)
        .map((community) => community.value)
        .reduce((total: number, value: number) => total + value),
    }
    dataToDisplay = dataToDisplay.slice(0, communitiesToDisplay)
    dataToDisplay = [...dataToDisplay, other].sort(
      (a: ChartData, b: ChartData) => b.value - a.value
    )
  }
  const scale = chroma.scale(['#0088FE', '#00C49F', '#FFBB28', '#FF8042'])
  const colors = scale.colors(dataToDisplay.length)

  return (
    <ComponentCard
      title="Leads by community"
      skeletonHeight={report ? 300 : 700}
      result={result}
    >
      <ResponsiveContainer
        width={width}
        height={report ? 300 : 700}
        id="last-report-item"
      >
        <Grid container justifyContent="center">
          <PieChart width={width} height={report ? 300 : 700}>
            <Pie
              data={dataToDisplay}
              dataKey="value"
              label={renderCustomizedLabel}
              labelLine={false}
            >
              {dataToDisplay.map((entry: ChartData, index: number) => (
                <Cell key={`cell-${index}`} fill={colors[index]} />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
            <Legend
              content={renderLegend}
              wrapperStyle={{
                textAlign: 'left',
                display: 'flex',
                justifyContent: 'center',
                padding: 0,
              }}
              layout="horizontal"
              verticalAlign="bottom"
              height={report ? 100 : 300}
            />
          </PieChart>
        </Grid>
      </ResponsiveContainer>
    </ComponentCard>
  )
}

export default LeadsByCommunityChart
