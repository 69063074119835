import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
  Community,
  Agent,
  useLeadRankingsQuery,
  CommonQueryFilter,
  LeadsQueryFilter,
  LeadFilter,
  OrderBy,
  Order,
  Pagination,
} from 'graphql/gen-types'
import { useTheme } from '@mui/material/styles'
import ComponentCard from '../../common/layout/ComponentCard'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import LeadRankingTable from './LeadRankingTable'
import Grid from '@mui/material/Grid'
import RepeatIcon from '@mui/icons-material/Repeat'

import useMediaQuery from '@mui/material/useMediaQuery'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { LeadsStore } from '../../../store/leadsStore'
import { AppActionType } from '../../../store/reducers'
import { LeadsFilter } from 'pages/leads/Leads'

const COMPONENT_TITLE = 'Returning Leads'

interface ReturningLeadsProps {
  communities?: Community[]
  agents: Agent[]
  commonFilter: CommonQueryFilter
  leadsFilter: LeadsFilter
  isAdmin?: boolean
}

const initialOrderBy: OrderBy = {
  order: Order.Desc,
  sortBy: 'lastSeen',
}

function ReturningLeadsDisplay({
  agents,
  commonFilter,
  leadsFilter,
  isAdmin,
}: ReturningLeadsProps): JSX.Element {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('md'))
  const { leadsState, dispatch } = useContext(LeadsStore)
  const defaultReturningFilter =
    leadsState?.defaultLeadFilters?.returningLeadsFilter ||
    (isAdmin ? LeadFilter.None : LeadFilter.MyLeadsOnly)

  const [orderBy, setOrderBy] = useState<OrderBy>(initialOrderBy)
  const [pagination, setPagination] = useState<Pagination>({
    limit: 10,
    offset: 0,
  })

  const [leadFilter, setLeadFilter] = useState<LeadFilter>(
    defaultReturningFilter
  )

  const filter: LeadsQueryFilter = useMemo(
    () => ({
      common: { ...commonFilter },
      leads: {
        returningLeads: true,
        lead: leadFilter,
        ...leadsFilter,
      },
    }),
    [commonFilter, leadsFilter, leadFilter]
  )

  // Fetch LeadRankings
  const { data, loading, error, refetch } = useLeadRankingsQuery({
    variables: {
      filter,
      pagination,
      orderBy,
    },
  })

  useEffect(() => {
    refetch()
  }, [orderBy, pagination, filter])

  const { leads, totalCount } = data?.leadRankings || { totalCount: 0 }

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized
          message={error.toString()}
          imageName={'LeadRankings'}
        ></Unauthorized>
      )
    }
    return <div>Error while loading returning leads</div>
  }

  const header: JSX.Element = (
    <>
      {COMPONENT_TITLE}
      <RepeatIcon style={{ marginLeft: '5px', marginBottom: '-4px' }} />
      <Grid
        container
        alignItems="center"
        justifyContent={'flex-end'}
        spacing={1}
        style={{ marginBottom: sm ? '-4px' : '-14px' }}
      >
        <Grid
          item
          xs={12}
          md={5}
          lg={3}
          style={{ position: 'relative', marginTop: sm ? '0px' : '-32px' }}
        >
          <FormControl>
            <InputLabel id="returning-leads-filter">Lead Filter</InputLabel>
            <Select
              labelId="returning-leads-filter"
              label="Lead Filter"
              value={leadFilter}
              onChange={(event) => {
                setLeadFilter(event.target.value)
                dispatch({
                  type: AppActionType.SET_RETURNING_LEADS_FILTER,
                  payload: event.target.value,
                })
              }}
            >
              <MenuItem value={LeadFilter.None}>None</MenuItem>
              <MenuItem value={LeadFilter.Unassigned}>
                Unassigned Leads
              </MenuItem>
              <MenuItem value={LeadFilter.MyLeadsOnly}>My Leads Only</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  )

  // Return component with data
  return (
    <div style={{ marginBottom: '20px' }}>
      <ComponentCard
        title={header}
        subtitle="Leads active with the last 7 days"
        result={{ data: leads, loading, error }}
      >
        <LeadRankingTable
          data={leads}
          agents={agents}
          highlightLeads={false}
          showcaseRecentlyActive
          pagination={pagination}
          setPagination={setPagination}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          dataTotalCount={totalCount}
        />
      </ComponentCard>
    </div>
  )
}

export default ReturningLeadsDisplay
