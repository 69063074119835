import React, { useContext } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { Lot, LotLead, useGetLotLeadsQuery } from 'graphql/gen-types'
import { AnalyticsStore } from 'store/analyticsStore'
import ResponsiveTable from 'components/common/tables/ResponsiveTable'
import DateFnsAdapter from '@date-io/date-fns'
import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { AppStore } from 'store'
import { AppActionType } from '../../../store/reducers'
import { useNavigate } from 'react-router-dom'
import { GOLDEN_RATIO, LEADS_APP } from '../../../constants'
import LotTrends from 'components/analytics/lot-trends/LotTrends'
import { getNamesFromFullName } from '../../../utils/functions'
import ResponsiveCell from '../tables/ResponsiveTable/ResponsiveCell'

const dateFns = new DateFnsAdapter()

interface LotLeadsDialogProps {
  lot: Lot | null
  onClose: () => void
  clientName: string
}

const LotLeadsDialog = ({
  lot,
  onClose,
  clientName,
}: LotLeadsDialogProps): JSX.Element => {
  const WIDTH = 450
  const HEIGHT = WIDTH * GOLDEN_RATIO
  const appStore = useContext(AppStore)
  const navigate = useNavigate()

  const navigateToLeadDetails = (row: unknown) => {
    const lotLead = row as LotLead
    const email = lotLead.prospect?.email
    if (!email) {
      return
    }
    const route = `/leads/client/${clientName}/prospect?prospectId=${encodeURIComponent(
      email
    )}`
    appStore.dispatch({
      type: AppActionType.SET_SELECTED_APP,
      payload: LEADS_APP,
    })
    navigate(route)
  }

  const columns = [
    <ResponsiveCell align="left" key="FirstNameHeader">
      <strong>First Name</strong>
    </ResponsiveCell>,

    <ResponsiveCell align="left" key="LastNameHeader">
      <strong>Last Name</strong>
    </ResponsiveCell>,

    <ResponsiveCell align="left" key="EmailHeader">
      <strong>Email</strong>
    </ResponsiveCell>,

    <ResponsiveCell align="center" key="LikedHeader">
      <strong>Selected</strong>
    </ResponsiveCell>,
  ]

  const generateRow = (dataRow: LotLead): JSX.Element | null => {
    const [firstName, lastName] = getNamesFromFullName(dataRow?.prospect?.name)

    return (
      <>
        <ResponsiveCell align="left" key="row-cell-user-firstName">
          {firstName || '-'}
        </ResponsiveCell>
        <ResponsiveCell align="left" key="row-cell-user-lastName">
          {lastName || '-'}
        </ResponsiveCell>
        <ResponsiveCell align="left" key="row-cell-user-email">
          {dataRow?.prospect?.email || '-'}
        </ResponsiveCell>
        <ResponsiveCell align="center" key="row-cell-favorite-timestamp">
          {dataRow?.date
            ? dateFns.format(new Date(dataRow?.date), "MMM do hh:mm aaaaa'm'")
            : 'No data available'}
        </ResponsiveCell>
      </>
    )
  }

  const { analyticsState } = useContext(AnalyticsStore)
  const { endDate, startDate } = analyticsState

  const { data, loading } = useGetLotLeadsQuery({
    variables: {
      filter: {
        endTime: endDate,
        startTime: startDate,
        communityId: lot?.communityId,
        clientName,
      },
      siteplanId: lot?.siteplanInfo?.siteplanId || 0,
      lotId: lot?.id || 0,
    },
    skip: !lot,
  })

  const rows = data?.getLotLeads || []
  return (
    <Dialog open={!!lot} onClose={() => onClose()} maxWidth={'xl'}>
      <DialogTitle>Lot {lot?.name}</DialogTitle>
      <DialogContent>
        <LotTrends lot={lot} clientName={clientName} />
        <Typography style={{ margin: '16px 0 5px 0' }} variant="h6">
          Leads
        </Typography>
        {loading ? (
          <Skeleton variant="rectangular" width={WIDTH} height={HEIGHT} />
        ) : rows.length > 0 ? (
          <>
            <ResponsiveTable
              data={rows}
              columns={columns}
              generateRow={generateRow}
              onRowClick={navigateToLeadDetails}
            />
          </>
        ) : (
          <Box>
            <Grid container justifyContent="center">
              <Typography>No leads for selected Lot</Typography>
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LotLeadsDialog
