import {
  ANALYTICS_ADMIN_ROUTE,
  ANALYTICS_LOCATION_ROUTE,
  ANALYTICS_OVERVIEW_ROUTE,
  ANALYTICS_REPORT_ROUTE,
  ANALYTICS_SALESCENTER_ROUTE,
  ANALYTICS_TRENDS_ROUTE,
  BUY_ONLINE_ANALYTICS_ROUTE,
  BUY_ONLINE_INTEGRATION_ROUTE,
  BUY_ONLINE_RESERVATIONS_ROUTE,
  LEADS_ADMIN_ROUTE,
  LEADS_AGENTS_ROUTE,
  LEADS_ANALYTICS_ROUTE,
  LEADS_BASE_ROUTE,
  MARKETING_AUTOMATION_ROUTE,
} from 'routing/AppRoutes'

export const OVERVIEW_INDEX = 1
export const REPORTS_BY_LOCATION_INDEX = 2
export const REPORTS_BY_PLAN_INDEX = 3
export const REPORTS_BY_SALESCENTER_INDEX = 4
export const ADMIN_INDEX = 5
export const LEADS_REPORT_INDEX = 7
export const AGENT_PROSPECT_INDEX = 8
export const INDUSTRY_TREND_INDEX = 9
export const EMAIL_CAMPAIGN_INDEX = 1
export const INTEGRATION_INDEX = 1
export const RESERVATIONS_INDEX = 2
export const ANALYTICS_INDEX = 3
export const SIGN_OUT_INDEX = 6

export const leadsDrawerRoutesMenuIndexes = [
  {
    route: `${LEADS_BASE_ROUTE}/overview`,
    index: OVERVIEW_INDEX,
  },
  {
    route: `${LEADS_BASE_ROUTE}/prospect`,
    index: OVERVIEW_INDEX,
  },
  {
    route: LEADS_ANALYTICS_ROUTE,
    index: LEADS_REPORT_INDEX,
  },
  {
    route: LEADS_AGENTS_ROUTE,
    index: AGENT_PROSPECT_INDEX,
  },
  {
    route: LEADS_ADMIN_ROUTE,
    index: ADMIN_INDEX,
  },
]

export const analyticsDrawerMenuIndexes = [
  {
    route: ANALYTICS_LOCATION_ROUTE,
    index: REPORTS_BY_LOCATION_INDEX,
  },
  {
    route: `${ANALYTICS_REPORT_ROUTE}/plan`,
    index: REPORTS_BY_PLAN_INDEX,
  },
  {
    route: `${ANALYTICS_REPORT_ROUTE}/plans`,
    index: REPORTS_BY_PLAN_INDEX,
  },
  {
    route: `${ANALYTICS_SALESCENTER_ROUTE}/overview`,
    index: REPORTS_BY_SALESCENTER_INDEX,
  },
  {
    route: `${ANALYTICS_SALESCENTER_ROUTE}/community`,
    index: REPORTS_BY_SALESCENTER_INDEX,
  },
  {
    route: ANALYTICS_SALESCENTER_ROUTE,
    index: REPORTS_BY_SALESCENTER_INDEX,
  },
  {
    route: ANALYTICS_ADMIN_ROUTE,
    index: ADMIN_INDEX,
  },
  {
    route: ANALYTICS_OVERVIEW_ROUTE,
    index: OVERVIEW_INDEX,
  },
  {
    route: ANALYTICS_TRENDS_ROUTE,
    index: INDUSTRY_TREND_INDEX,
  },
]

export const marketingDrawerMenuIndexes = [
  {
    route: MARKETING_AUTOMATION_ROUTE,
    index: EMAIL_CAMPAIGN_INDEX,
  },
]

export const buyOnlineDrawerMenuIndexes = [
  {
    route: BUY_ONLINE_INTEGRATION_ROUTE,
    index: INTEGRATION_INDEX,
  },
  {
    route: BUY_ONLINE_RESERVATIONS_ROUTE,
    index: RESERVATIONS_INDEX,
  },
  {
    route: BUY_ONLINE_ANALYTICS_ROUTE,
    index: ANALYTICS_INDEX,
  },
]
