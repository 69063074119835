import React, { useEffect } from 'react'
import Typography from '@mui/material/Typography'
import {
  PopularMaterialColors,
  Color,
  ProspectMaterialColor,
  Texture,
  CustomOverlay,
  Maybe,
} from 'graphql/gen-types'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Tab from '@mui/material/Tab'
import useMediaQuery from '@mui/material/useMediaQuery'
import ComponentCard from 'components/common/layout/ComponentCard'
import MaterialColorTable from 'components/common/tables/MaterialColorTable'
import { useTheme } from '@mui/material/styles'

const COMPONENT_TITLE = 'Material Color Selections'
// Max items to display on a single table.

interface MaterialColorsProps {
  data: Maybe<PopularMaterialColors>[] | Maybe<ProspectMaterialColor>[]
  loading?: boolean
  error?: Error | undefined
}

type MaterialColorDisplay = {
  clientName: string
  materialId: number
  materialName: string
  materialCategory: string
  colorId: number
  colorName: string
  paletteSelectionId: number
  brickId: number
  stoneId: number
  colorMasonryLib: boolean
  customOverlayId: number
  source: string
  color: Color
  texture: Texture
  customOverlay: CustomOverlay
  popularity?: number
}

function MaterialColorsDisplay({
  data,
  loading,
  error,
}: MaterialColorsProps): JSX.Element {
  const [tabValue, setTabValue] = React.useState(0)
  const theme = useTheme()
  const smallSize = useMediaQuery(theme.breakpoints.down('md'))
  const [selectValue, setSelectValue] = React.useState<string>('')
  const handleSelectChange = (event: SelectChangeEvent) => {
    setSelectValue(event.target.value as string)
  }

  // {} is not a valid TS type but unfortunately this is the MUI onChange IF
  const handleChange = (
    // eslint-disable-next-line
    event: React.ChangeEvent<{}>,
    newValue: number
  ): void => {
    setTabValue(newValue)
  }

  /**
   * Get distinct material names array
   */
  const getDistinctMaterials = (data: PopularMaterialColors[]): string[] => {
    const distinct = {}
    data.forEach((row) => {
      if (row?.materialName) {
        distinct[row.materialName] = true
      }
    })
    return Object.keys(distinct)
  }

  const materialColorsDisplay = data as MaterialColorDisplay[]

  const distinctMaterials = getDistinctMaterials(materialColorsDisplay)

  useEffect(() => {
    setSelectValue(materialColorsDisplay[0]?.materialName || '')
  }, [data, materialColorsDisplay])

  const filterComponent = smallSize ? (
    <>
      <Select
        variant="standard"
        value={selectValue}
        onChange={handleSelectChange}
        style={{ marginBottom: theme.spacing(1) }}
      >
        {distinctMaterials.map((key, i) => {
          return (
            <MenuItem value={key} key={`materials-menu-item${key}-${i}`}>
              {key}
            </MenuItem>
          )
        })}
      </Select>
      <MaterialColorTable
        materialColors={materialColorsDisplay.filter(
          (row) => row.materialName === selectValue
        )}
      />
    </>
  ) : (
    <React.Fragment>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        {distinctMaterials.map((key, i) => {
          return <Tab key={key} label={key} />
        })}
      </Tabs>

      {distinctMaterials.map((key, i) => {
        const materialData = materialColorsDisplay.filter(
          (row) => row.materialName === key
        )
        return (
          <TabPanel key={key} value={tabValue} index={i}>
            <MaterialColorTable materialColors={materialData} />
          </TabPanel>
        )
      })}
    </React.Fragment>
  )

  return (
    <ComponentCard
      title={COMPONENT_TITLE}
      result={{ data, loading, error }}
      skeletonHeight={500}
    >
      {distinctMaterials && distinctMaterials.length > 0 ? (
        // Only render tabs or select when there is data to display.
        filterComponent
      ) : (
        <TabPanel value={0} index={0}>
          <Typography>No Data to Display.</Typography>
        </TabPanel>
      )}
    </ComponentCard>
  )
}

// type DisplayColor = {
//   id: number
//   name: string
//   hex: string
//   swatch: string
// }

interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: number
  value: number
}

function TabPanel(props: TabPanelProps): JSX.Element {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

export default MaterialColorsDisplay
