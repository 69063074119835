import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Dialog from '@mui/material/Dialog'
import { SEND_PASSWORD_RESET_EMAIL } from 'graphql/mutation/resetPassword'
import { useMutation } from '@apollo/client'

const PREFIX = 'ForgotPassword'

const classes = {
  forgotPasswordDiv: `${PREFIX}-forgotPasswordDiv`,
  typography: `${PREFIX}-typography`,
  textField: `${PREFIX}-textField`,
  button: `${PREFIX}-button`,
  dialogContent: `${PREFIX}-dialogContent`,
  divModal: `${PREFIX}-divModal`,
  modalButton: `${PREFIX}-modalButton`,
  signBackIn: `${PREFIX}-signBackIn`,
  passwordResetMessage: `${PREFIX}-passwordResetMessage`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.forgotPasswordDiv}`]: {
    transform: 'translateY(-900px) !important',
  },

  [`& .${classes.typography}`]: {
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },

  [`& .${classes.textField}`]: {
    width: '100%',
  },

  [`& .${classes.button}`]: {
    marginTop: theme.spacing(4),
    width: '100%',
  },

  [`& .${classes.signBackIn}`]: {
    marginTop: '80px',
  },

  [`& .${classes.passwordResetMessage}`]: {
    marginTop: '40px',
    padding: '10px',
  },
}))

const StyledDialog = styled(Dialog)(() => ({
  [`&.${classes.dialogContent}`]: {
    width: '400px',
    margin: 'auto',
  },

  [`& .${classes.divModal}`]: {
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },

  [`& .${classes.modalButton}`]: {
    marginTop: '20px',
  },
}))

type HandleForgotPasswordClickFn = () => void
interface ForgotPasswordInterface {
  handleForgotPasswordClick: HandleForgotPasswordClickFn
}

interface ForgotPasswordStateInterface {
  email: string
  isPasswordResetSent: boolean
  isModalOpen: boolean
  modalMessage: string
}

const forgotPasswordInitialState = {
  email: '',
  isPasswordResetSent: false,
  isModalOpen: false,
  modalMessage: `Check your email for a link to reset your password. If it doesn't appear within a few minutes, check your spam folder.`,
}

const ForgotPassword = ({
  handleForgotPasswordClick: goBackToPrev,
}: ForgotPasswordInterface): JSX.Element => {
  const [state, setState] = useState<ForgotPasswordStateInterface>(
    forgotPasswordInitialState
  )
  const [sendPasswordResetEmail] = useMutation(SEND_PASSWORD_RESET_EMAIL)

  /**
   * @description - calls api for the nexus server to send an reset password link to the email provided.
   *              - if success, change some state values.
   *
   */
  const handleForgotPasswordSubmit = async (
    e: React.MouseEvent<unknown>
  ): Promise<void> => {
    e.preventDefault()
    sendPasswordResetEmail({
      variables: {
        email: state.email,
        source: 'colonnade',
      },
    })
      .then(() => {
        setState({
          ...state,
          isModalOpen: true,
          email: '',
        })
      })
      .catch(() => {
        console.error('Failed to mutate SEND_PASSWORD_RESET_EMAIL_COLONNADE')

        setState({
          ...state,
          isModalOpen: true,
          modalMessage: `System failure, please contact anewgo admin member.`,
        })
      })
  }

  return (
    <Root className={classes.forgotPasswordDiv}>
      <StyledDialog
        aria-labelledby="customized-dialog-title"
        open={state.isModalOpen}
        className={classes.dialogContent}
      >
        <div className={classes.divModal}>
          <h2>Confirmation</h2>
          <Typography gutterBottom>{state.modalMessage}</Typography>
          <Button
            autoFocus
            onClick={() => {
              setState({ ...state, isModalOpen: false })
              goBackToPrev()
            }}
            color="primary"
            variant="contained"
            className={classes.modalButton}
          >
            CLOSE
          </Button>
        </div>
      </StyledDialog>
      {state.isPasswordResetSent ? (
        <div>
          <Typography className={classes.signBackIn}>
            <Button onClick={(): unknown => goBackToPrev()} variant="contained">
              Return To Sign In
            </Button>
          </Typography>
        </div>
      ) : (
        <div>
          <Typography
            classes={{
              root: classes.typography,
            }}
            variant="h5"
          >
            Forgot Password
          </Typography>
          <Typography
            classes={{
              root: classes.typography,
            }}
            variant="subtitle1"
          >
            <Link
              href="#"
              onClick={(): unknown => goBackToPrev()}
              underline="hover"
            >
              Go back
            </Link>
          </Typography>

          <TextField
            autoFocus
            classes={{ root: classes.textField }}
            margin="normal"
            id="Email"
            data-testid="Email"
            autoComplete="Email"
            variant="outlined"
            label="Email"
            value={state.email}
            onChange={(evt): void => {
              setState({ ...state, email: evt.target.value })
            }}
          />
          <Button
            data-testid="submitButton"
            classes={{ root: classes.button }}
            variant="contained"
            size="large"
            color="primary"
            onClick={(e): unknown => handleForgotPasswordSubmit(e)}
          >
            submit
          </Button>
        </div>
      )}
    </Root>
  )
}

export default ForgotPassword
