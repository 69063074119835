import React from 'react'
import Typography from '@mui/material/Typography'
import { PopularInteriorSelections } from 'graphql/gen-types'
import ResponsiveTable, {
  ResponsiveCell,
} from 'components/common/tables/ResponsiveTable'
import Grid from '@mui/material/Grid/Grid'

import { getSelectedElement } from './InteriorSelections'
import { CLOUDINARY_PREFIX } from '../../../constants'
import { StyledAvatar, classes } from './InteriorSelectionsTable..styles'

const NUM_DISPLAY_ROWS = 5
const RESPONSE_SIZE = 'sm'

interface InteriorSelectionsTableProps {
  data: PopularInteriorSelections[]
}

export default function InteriorSelectionsTable({
  data,
}: InteriorSelectionsTableProps): JSX.Element {
  const tableHeadCells: JSX.Element[] = [
    <ResponsiveCell align="center" key="header-cell-element">
      Element
    </ResponsiveCell>,
    <ResponsiveCell align="center" key="header-cell-list-element">
      List Element
    </ResponsiveCell>,
    <ResponsiveCell align="center" key="header-cell-selection">
      Option
    </ResponsiveCell>,
    <ResponsiveCell align="center" key="header-cell-image">
      Swatch
    </ResponsiveCell>,
    <ResponsiveCell align="center" key="header-cell-popularity">
      Popularity
    </ResponsiveCell>,
  ]
  return (
    <ResponsiveTable
      data={data}
      columns={tableHeadCells}
      generateRow={(dataRow, index): JSX.Element => (
        <ResponsiveTableRow row={dataRow} index={index} />
      )}
      responseSize={RESPONSE_SIZE}
      initialRows={NUM_DISPLAY_ROWS}
    />
  )
}

function ResponsiveTableRow({
  row,
  index,
}: {
  row: PopularInteriorSelections
  index?: number
}): JSX.Element {
  const interior = row.interior
  const clientName = interior?.clientName
  const element = interior?.elementTrees?.find(
    (el) => el?.id === row?.listElementId
  )
  const listElement = interior?.elementTrees?.find(
    (el) => el?.id === row?.optionSelectionListElementId
  )
  const option = listElement?.options?.find(
    (op) => op?.id === row?.optionSelectionId
  )
  const imgSrc = getInteriorOptionUrl(clientName, option?.overlaySwatchSrc, 38) // 38px is size of avatar.
  const hex = option?.hex ? `#${option?.hex}` : ''

  return (
    <>
      <ResponsiveCell align="center" component="th" scope="row">
        <Typography>{element?.name || '-'}</Typography>
      </ResponsiveCell>
      <ResponsiveCell align="center" component="th" scope="row">
        <Typography>{listElement?.name || '-'}</Typography>
      </ResponsiveCell>
      <ResponsiveCell align="center" component="th" scope="row">
        <Typography>{option?.name || '-'}</Typography>
      </ResponsiveCell>
      <ResponsiveCell align="center" component="th" scope="row">
        <Grid container justifyContent="center">
          <div style={{ width: '40px' }}>
            <StyledAvatar
              src={imgSrc}
              alt={`${option?.name?.charAt(0)}`} // Show the first character of the color name if swatch image doesn't load.
              className={classes.avatar}
              style={{ backgroundColor: hex }}
            >
              {' '}
            </StyledAvatar>
          </div>
        </Grid>
      </ResponsiveCell>
      <ResponsiveCell align="center" component="th" scope="row">
        <Typography>{row.popularity || '-'}</Typography>
      </ResponsiveCell>
    </>
  )
}

const getInteriorOptionUrl = (
  clientName: string | undefined | null,
  overlaySwatchSrc: string | undefined | null,
  width = 300
): string => {
  return clientName && overlaySwatchSrc
    ? `${CLOUDINARY_PREFIX}/w_${width}/app/${clientName}/images/${overlaySwatchSrc}`
    : ''
}
