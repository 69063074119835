/**
 * This file contains some functions that takes functions as params. For now,
 * we disable eslint for those use cases. An improvement would be to make use
 * of generic types.
 */

import clsx from 'clsx'
import DateFnsAdapter from '@date-io/date-fns'
import { murmurX64Hash128 } from '@fingerprintjs/fingerprintjs'
import { Maybe, Prospect } from 'graphql/gen-types'
import { HomeSelection } from 'components/leads/favorite-details/MyHome'
import chroma from 'chroma-js'
import { version as uuidVersion, validate as uuidValidate } from 'uuid'

const dateFns = new DateFnsAdapter()
const anewgoEmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

/**
 * Creates a functional pipeline operating from left to right. Returns a function
 * which feeds its input into the first function in the pipeline, then feeds that
 * function's output into the second function, and so on until it returns the last
 * function's output. For example, `pipe(f1, f2, f3)(x)` is equivalent to
 * `f3(f2(f1(x)))`.
 */
// eslint-disable-next-line
export const pipe = (...fns: Function[]) => (first: unknown): unknown => {
  return fns.reduce((last, fn) => fn(last), first)
}

/**
 * Returns the value of a property on a context, looked up by bracket notation.
 * getProp returns undefined if the context value is undefined. For example,
 * `getProp('foo')(undefined)` will not crash since it will check for a falsy
 * context value before attempting to access 'foo'.
 *
 * Note: we generally use `getPath` instead of `getProp`, even when the "path" is
 * one key in length.
 */
// eslint-disable-next-line
export const apply = (fn: Function) => (context: unknown): unknown => {
  return context && fn(context)
}

/**
 * Returns the value of a property on a context, looked up by bracket notation.
 * getProp returns undefined if the context value is undefined. For example,
 * `getProp('foo')(undefined)` will not crash since it will check for a falsy
 * context value before attempting to access 'foo'.
 *
 * Note: we generally use `getPath` instead of `getProp`, even when the "path" is
 * one key in length.
 */
// eslint-disable-next-line
export const getProp = (key: string): Function => {
  return apply((context: { [x: string]: unknown }) => context[key])
}

/**
 * Returns the value of a property "path" on a context, looked up by bracket notation.
 * Essentially a functional pipeline of getProp calls. For example,
 * `getPath('people', 0, 'name')(data)` is equivalent to
 * `data && data.people && data.people[0] && data.people[0].name`.
 */
export const getPath = (...keys: string[]) => (context: unknown): unknown => {
  return pipe(...keys.map(getProp))(context)
}

/**
 * Capitalize all words in a string.
 * Will capitilize the first character in each word.
 * Words are split/defined by the `delim` paramter.
 * On return, words are joined using the `join` parameter.
 * This allows the capitilization of strings such as 'capitalize-me' into `Capitalize Me'
 *
 * @param str - string in any format
 * @param delim - string. Default ' '
 * @param join - string. Default ' '
 */
export const capitalize = (str: string, delim = ' ', join = ' '): string => {
  const words = str.split(delim)
  return words
    .map(
      (word) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase()
    )
    .join(join)
}

/**
 * Check if a given string is a valid email address
 *
 * @param input - string in any format
 */
export const checkIsEmail = (input: string): boolean => {
  const check = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    input
  )
  return check
}

/**
 *  Merge default component styles with overrides (from props)
 *
 * Example:
 *   const default = useDefaultStyles()
 *   const overrides = useStyles()
 *   const styles = overrides ? mergeStyles(default, overrides) : default
 *
 * @param defaultStyles
 * @param overrideStyles
 */
// FUNCTION IS NOT USED WITH NEW MATERIAL-UI 5
export const mergeStyles = (
  defaultStyles: Record<string, string>,
  overrideStyles: Partial<Record<string, string>>
): Record<string, string> => {
  const merged: Record<string, string> = {}
  for (const styleKey of Object.keys(defaultStyles)) {
    merged[styleKey] = clsx(defaultStyles[styleKey], overrideStyles[styleKey])
  }
  return merged
}

/**
 * Returns a human readable time format for input seconds.
 * @param input Int seconds
 * @param exact Boolean. True to return every level of detail from Days to Seconds.
 *    False to only return the greatest non-zero time chunk and one level of finer detail. For example, 1 hr 3 min instead of 1 hr 3min 45 sec
 */
export function humanTimeDuration(input: number, exact = false): string {
  const secondsPer = {
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  }

  const day = Math.floor(input / secondsPer.day)
  const dayLabel = day > 1 ? 'd' : 'd'
  input %= secondsPer.day
  const hr = Math.floor(input / secondsPer.hour)
  const hrLabel = day > 1 ? 'hr' : 'hr'
  input %= secondsPer.hour
  const min = Math.floor(input / secondsPer.minute)
  const minLabel = day > 1 ? 'm' : 'm'
  input %= secondsPer.minute
  const sec = Math.floor(input / secondsPer.second)
  const secLabel = day > 1 ? 's' : 's'
  input %= secondsPer.second

  // Return exact value
  if (exact === true) {
    return `${day}${dayLabel} ${hr}${hrLabel} ${min}${minLabel} ${sec}${secLabel}` // Days Hours Minutes Seconds

    // Else only show one finer level of detail
  } else if (day) {
    return `${day}${dayLabel} ${hr}${hrLabel}` // Days Hours
  } else if (hr) {
    return `${hr}${hrLabel} ${min}${minLabel}` // Hours Minutes
  } else if (min) {
    return `${min}${minLabel} ${sec}${secLabel}` // Minutes Seconds
  } else {
    return `${sec}${secLabel}` // Seconds
  }
}

export const snakeCaseToTitleCase = (s: string): string =>
  s
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
    .join(' ')

// eslint-disable-next-line
export const groupBy = (array: Array<object>, key: string) => {
  const groups = {}
  // eslint-disable-next-line
  array.forEach((item: any) => {
    const itemKey = item && item[key]
    if (groups[itemKey]) {
      groups[itemKey].push(item)
    } else {
      groups[itemKey] = [item]
    }
  })

  return groups
}

export function readableTimeFormat(input: number | undefined | null): string {
  const x = !input ? 0 : input
  function __seconds(s: number): string {
    if (s > 0) {
      return `${s}s`
    }
    return ''
  }

  if (x < 60) {
    return `${x}s`
  }
  const hrs = Math.floor(x / 3600)
  const mins = hrs > 0 ? Math.floor((x % 3600) / 60) : Math.floor(x / 60)
  const secs = x - hrs * 3600 - mins * 60
  if (hrs > 0) {
    return `${hrs}hr ${mins}m ${secs}s`
  }
  return `${mins}m ${__seconds(secs)}`
}

export function formatDateTime(
  date: string | number | Date | null | undefined,
  format: string
): string {
  return dateFns.format(new Date(date || new Date()), format)
}

export function displayDateTime(
  date: string | number | Date | null | undefined
): string {
  return formatDateTime(date, 'ha MMM do yyyy')
}

/**
 * Generates home identifier from selection.
 */
export const generateHomeIdentifier = (selection: HomeSelection): string => {
  const values = []
  values.push(selection?.community?.name)
  values.push(selection?.community?.id)
  values.push(selection?.plan?.name)
  values.push(selection?.plan?.id)
  values.push(selection?.elevation?.caption)
  values.push(selection?.elevation?.id)
  values.push(selection?.lot?.id)
  if (selection?.lot?.inventory) {
    values.push(selection?.lot?.inventory?.id)
  }
  // using murmurX64Hash128 is not recommended, although trying to import default front fingerprintjs is not working well.
  return murmurX64Hash128(values.join(''), 31)
}

export function getEmailFromProspectId(prospectId?: string): string {
  if (!prospectId) {
    return ''
  }
  // extract email from brackets
  // First Last<email@email.com>
  const bracketedEmail = /<(.*)>/g.exec(prospectId)
  let email = bracketedEmail && bracketedEmail[1] // get email (or null)
  if (email) {
    email = email.replace(/ /g, '+')
  }

  // If no email match exists, return prospectId. This can be the raw email on occasion.
  return email || prospectId
}

export function getNameFromProspectId(prospectId?: string): string {
  if (!prospectId) {
    return ''
  }
  // extract email from brackets
  // First Last<email@email.com>
  const nameMatch = /^(.*)<.*>/g.exec(prospectId)
  const name = nameMatch && nameMatch[1] // get name (or null)

  // If no name match exists, return prospectId.
  return name || prospectId
}

/**
 * Generate number array [0, 1, ..., n-1] of length `length`
 * @param length length of array
 * @returns number[] of length `length`
 */
export function range(length: number): number[] {
  return Array(length)
    .fill(0)
    .map((zero, index) => index)
}

/**
 * Formats 0-23 hour into readable format. ex. 12pm
 * @param hour integer to convert. 0-23
 * @returns string
 */
export function formatHour(hour: number): string {
  const timeOfDay = hour < 12 ? 'am' : 'pm'
  const displayHour = hour % 12

  return `${displayHour || 12}${timeOfDay}`
}

/**
 * Get the name of a prospect. Try to get  `name`, then `firstName LastName`. Returns
 * @param prospect prospect name to get
 * @param ifEmpty String to return if undefined. Empty string by default.
 * @returns string
 */
export function getProspectName(
  prospect: Prospect | undefined | null,
  ifEmpty = ''
): string {
  let name = ifEmpty
  // If undefined, return ifEmpty
  if (!prospect) {
    return name
  }
  // Return name or first last if they exist.
  if (prospect.name) {
    name = prospect.name
  } else if (prospect.firstName || prospect.lastName) {
    name = [prospect.firstName || '', prospect.lastName || ''].join(' ')
  }
  return name
}

/**
 * Get the name of a prospect. Try to get  `name`, then `firstName LastName`. Returns City, State, Country.
 * Will return unknown City, unknown State, unknown Country
 */
export function getProspectLocation(
  prospect: Prospect | undefined | null,
  ifEmpty = '-'
): string {
  const stats = prospect?.statistics
  // If undefined, return ifEmpty
  if (!stats || !stats?.country) {
    return ifEmpty
  }
  return `${stats?.city || 'unknown City'}, ${
    stats?.state || 'unknown State'
  }, ${stats?.country || 'unknown Country'}`
}

/**
 * Remove the Maybe<T> wrapper for an item.
 * @param item type
 * @returns boolean, true if item is not undefined or null
 */
export function isDefined<T>(item: Maybe<T> | undefined | null): item is T {
  return item !== undefined && item !== null
}

/**
 * Returns a hex color of either black or white depending on the luminance of the passed hex value.
 * @param hex String hex calue of the background to get text color for.
 * @returns hex String
 */
export function getTextColor(hex: string): string {
  const lum = chroma(hex).luminance()
  return lum > 0.7 ? '#000000' : '#ffffff'
}

/*
 * Trims the length of input string. ex. "very long text" -> "very lo..."
 * @param str string to trim
 * @param maxLength maximum length to trim. Default 50
 * @param ending string to append. Default "..."
 * @returns trimmed string, with ending appended.
 */
export function trimLength(
  str: string | undefined,
  maxLength = 50,
  ending = '...'
): string {
  if (str?.length && str.length > maxLength) {
    return str.substr(0, maxLength) + ending
  } else {
    return str || ''
  }
}

/**
 * Get prospect "tag." This is any fields we want to search a prospect by; all values in name / firstName / lastName / email.
 * These values are joined together and space separated.
 * @param prospect to get tag
 * @returns string
 */
export const getProspectTag = (
  prospect: Prospect | undefined | null
): string => {
  const p = prospect
  const prospecTag = (
    (p?.email || '') +
    (p?.name || '') +
    (p?.firstName || '') +
    (p?.lastName + '')
  ).toLowerCase()
  return prospecTag
}

/**
 *
 * @param email string to verify
 * @returns boolean, true if email string is a valid email
 */
export function isEmailInvalid(email: string): boolean {
  return !email.match(anewgoEmailRegex)
}

/**
 * Is name of a removed / archived type from the dashboard.
 * Regex matched any `z_`, `archived`, or `removed` in the name provided.
 * @param name - Either plan name or elevation caption
 * @returns Boolean is "archived"
 */
export function isArchived(name?: string | undefined): boolean {
  return (name?.match(/z_|archived|removed/gi)?.length || 0) > 0 || false
}

/**
 * Is name of a removed / archived type from the dashboard.
 * Regex matched any `z_`, `archived`, or `removed` in the name provided.
 * @param name - Either plan name or elevation caption
 * @returns Object is "archived"
 */
export function getNamesFromFullName(name?: string | null): [string, string] {
  const nameParts = name?.split(' ')
  const lastName = nameParts?.pop() ?? ''
  const firstName = nameParts?.join(' ') ?? ''
  return [firstName, lastName]
}

/**
 * Validate UUID version 4
 * @param uuid - UUID
 */
export const uuidValidateV4 = (uuid: string): boolean =>
  uuidValidate(uuid) && uuidVersion(uuid) === 4

export const excludeProps = (
  prop: PropertyKey,
  ...keys: PropertyKey[]
): boolean => !keys.includes(prop)

/**
 * It gets all cookies and parse them into object.
 * @returns Object of all cookies
 */
export const getCookies = (): Record<string, string> => {
  const cookies = document.cookie.split(';')
  const cookiesObject = {}
  cookies.forEach((row) => {
    const rowParts = row.split('=')
    cookiesObject[rowParts[0]] = rowParts[1]
  })

  return cookiesObject
}
