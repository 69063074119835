import React, {
  MutableRefObject,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { useTheme } from '@mui/material/styles'
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  AreaChart,
  Area,
  Line,
  LineChart,
  CartesianGrid,
  Label,
} from 'recharts'
import DateFnsAdapter from '@date-io/date-fns'
import { PopularityMetric, TimeSeries } from 'graphql/gen-types'
import { getPalette } from '../misc/ColorPaletteGenerator'

import { CustomYAxisLabel } from 'components/common/charts/custom/CustomAxisLabel'
import CustomLegend from './TimeSeriesLegend'
import CustomToolTip from './TimeSeriesTooltip'
import { trimLength } from 'utils/functions'

const dateFns = new DateFnsAdapter()
const dateFormatter = (label: string | number): string => {
  return dateFns.format(new Date(Number(label)), 'MM/dd/yyyy')
}

export interface TimeSeriesLineChartProps {
  xAxisDomain: { lower: number; upper: number }
  data: TimeSeriesParent[]
  areaChart?: boolean | false
  yAxisDataKey: string
  tooltipDataKey: string
  useCustomLegend?: boolean | false
  metric?: PopularityMetric | null
  height?: number | null
  width?: number | null
  autoRescale?: boolean
}

export interface TimeSeriesParent {
  dailyMetrics?: TimeSeries | null | undefined
  strokeColor?: string | null | undefined
  swatchSrc?: string | null | undefined
  [prop: string]: unknown | Record<string, unknown>
}

export default function TimesSeriesLineChart({
  data,
  areaChart,
  yAxisDataKey,
  tooltipDataKey,
  xAxisDomain,
  useCustomLegend,
  metric,
  height: propsHeight,
  width: propsWidth,
  autoRescale = true,
}: TimeSeriesLineChartProps): JSX.Element | null {
  const theme = useTheme()
  const [height, targetRef] = useDivRescale()

  const yLabel =
    metric === PopularityMetric.Frequency ? 'Events' : 'Duration (s)'
  const strokeColor =
    theme.palette.primary[theme.palette.mode === 'dark' ? 'light' : 'dark']

  if (data.length === 0) return null

  const palette = getPalette(data.length)

  function renderAreaChart(): JSX.Element {
    return (
      <AreaChart>
        <XAxis
          dataKey="date"
          type="number"
          domain={[xAxisDomain.lower, xAxisDomain.upper]}
          allowDuplicatedCategory={false}
          tickFormatter={dateFormatter}
          stroke={strokeColor}
          style={{ fontSize: 14 }}
        />
        <YAxis
          dataKey={yAxisDataKey}
          stroke={strokeColor}
          style={{ fontSize: 14 }}
        >
          <Label
            value={yLabel}
            stroke={strokeColor}
            content={CustomYAxisLabel}
          />
        </YAxis>
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip
          labelStyle={{
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.secondary.contrastText
                : theme.palette.secondary.dark,
          }}
          labelFormatter={dateFormatter}
          content={useCustomLegend ? <CustomToolTip /> : undefined}
          wrapperStyle={{ fontSize: 14 }}
        />
        <Legend
          content={useCustomLegend ? <CustomLegend data={data} /> : undefined}
          wrapperStyle={{ fontSize: 14 }}
        />
        {data?.map((c: TimeSeriesParent, i) => {
          const fillColor = c?.strokeColor || palette[i]
          const extraDataToPass = getAdditionalPropsData(c)
          return (
            <Area
              dataKey={yAxisDataKey}
              stroke={fillColor}
              fill={fillColor}
              type="monotone"
              data={c?.dailyMetrics?.frames}
              name={trimLength(c[tooltipDataKey] as string)}
              key={`${c[tooltipDataKey]}-${i}`}
              id={extraDataToPass}
            />
          )
        })}
      </AreaChart>
    )
  }

  function renderLineChart(): JSX.Element {
    return (
      <LineChart>
        <XAxis
          dataKey="date"
          type="number"
          domain={[xAxisDomain.lower, xAxisDomain.upper]}
          allowDuplicatedCategory={false}
          tickFormatter={dateFormatter}
          stroke={strokeColor}
        />
        <YAxis dataKey={yAxisDataKey} stroke={strokeColor}>
          <Label
            value={yLabel}
            stroke={strokeColor}
            content={CustomYAxisLabel}
          />
        </YAxis>
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip
          labelFormatter={dateFormatter}
          content={useCustomLegend ? <CustomToolTip /> : undefined}
        />
        <Legend
          content={useCustomLegend ? <CustomLegend data={data} /> : undefined}
        />
        {data?.map((c: TimeSeriesParent, i) => {
          const fillColor = c?.strokeColor || palette[i]
          const extraDataToPass = getAdditionalPropsData(c)
          return (
            <Line
              dataKey={yAxisDataKey}
              stroke={fillColor}
              type="monotone"
              data={c?.dailyMetrics?.frames}
              name={c[tooltipDataKey]}
              key={c[tooltipDataKey]}
              id={extraDataToPass}
            />
          )
        })}
      </LineChart>
    )
  }

  return (
    <div
      ref={targetRef}
      style={
        autoRescale ? { height, maxHeight: '70vh' } : { maxHeight: '70vh' }
      }
    >
      <ResponsiveContainer
        width={propsWidth || '100%'}
        height={propsHeight || '90%'}
      >
        {areaChart ? renderAreaChart() : renderLineChart()}
      </ResponsiveContainer>
    </div>
  )
}

// In order to resolve the any type here requires a lot of refactoring. I do not think it is worth doing as of now.
// The additional props data is not properly typed.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getAdditionalPropsData(props: any): string | undefined {
  if (props?.swatchSrc) {
    return `swatchSrc:${props?.swatchSrc}`
  }
  if (props?.strokeColor) {
    return `strokeColor:${props?.strokeColor}`
  }
  if (props?.scheme?.id) {
    return `schemeId:${props?.scheme?.id}`
  }
  return undefined
}

// If reference object has undefined width, use this value.
const DEFAULT_WIDTH = 300

/**
 * rescales targetRef to ratio 16/9 if targetRef's height is available
 */
function useDivRescale(
  defaultHeight = 300
): readonly [number, MutableRefObject<HTMLDivElement | null>] {
  const [height, setHeight] = useState(defaultHeight)
  const targetRef = useRef<HTMLDivElement>(null)
  useLayoutEffect(() => {
    if (targetRef) {
      const newHeight =
        (targetRef?.current?.offsetWidth || DEFAULT_WIDTH) / 1.77 || 300
      if (newHeight !== height) {
        setHeight(newHeight)
      }
    }
  }, [height, setHeight])
  return [height, targetRef] as const
}

export const testingVariables = { getAdditionalPropsData }
