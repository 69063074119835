import React, { useContext, useEffect } from 'react'
import { AnalyticsStore } from '../../../store/analyticsStore'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import AppBar from '@mui/material/AppBar'
import { formatDateTime } from '../../../utils/functions'
import MenuDialog from '../by-plan/MenuDialog'
import MobileDatePicker from '@mui/lab/MobileDatePicker'
import { AppActionType } from '../../../store/reducers'
import DateFnsAdapter from '@date-io/date-fns'
import { quickDateSetter } from './FilterBar'
import TextField from '@mui/material/TextField'

export const MOBILE_FILTER_BAR_HEIGHT = 52

const dateFns = new DateFnsAdapter()

interface MobileFilterBarProps {
  filters: () => JSX.Element
}

function MobileFilterBar({ filters }: MobileFilterBarProps): JSX.Element {
  const { analyticsState, dispatch } = useContext(AnalyticsStore)
  const { startDate, endDate } = analyticsState
  const [quickDate, setQuickDate] = React.useState<string>('custom')
  const [newStartDate, setNewStartDate] = React.useState<Date>(startDate)
  const [newEndDate, setNewEndDate] = React.useState<Date>(endDate)

  const handleQuickDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = (event.target as HTMLInputElement).value
    setQuickDate(value)
    if (value !== 'custom') {
      const daysToSubtract = parseInt(value, 10)
      const quickStartDate = dateFns.startOfDay(
        dateFns.addDays(dateFns.date(new Date()), -daysToSubtract)
      )
      setNewStartDate(quickStartDate)
      setNewEndDate(dateFns.endOfDay(new Date()))
    }
  }

  useEffect(() => {
    quickDateSetter(startDate, endDate, quickDate, setQuickDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch({
      type: AppActionType.SET_DATE_RANGE,
      payload: {
        startDate: newStartDate,
        endDate: newEndDate,
      },
    })
  }, [dispatch, newEndDate, newStartDate])

  return (
    <AppBar position="sticky" style={{ backgroundColor: '#FFF' }}>
      <Paper>
        <Grid
          container
          justifyContent={'space-around'}
          alignItems={'center'}
          style={{ height: MOBILE_FILTER_BAR_HEIGHT }}
        >
          <Typography>
            {`${formatDateTime(startDate.toDateString(), 'MM/dd/yyyy')} - 
            ${formatDateTime(endDate.toDateString(), 'MM/dd/yyyy')}`}
          </Typography>
          <MenuDialog
            alwaysShowButton
            buttonSize={'medium'}
            buttonText="Filters"
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              spacing={1}
            >
              <FormControl
                component="fieldset"
                style={{ minWidth: 300 }}
                variant="standard"
              >
                <Typography
                  variant="body1"
                  color="primary"
                  style={{ textAlign: 'center', paddingTop: '2px' }}
                >
                  Date Range
                </Typography>
                <RadioGroup
                  aria-label="date presets"
                  name="datePresets"
                  value={quickDate}
                  onChange={handleQuickDateChange}
                  style={{
                    paddingLeft: '10px',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  <FormControlLabel
                    value="7"
                    control={<Radio />}
                    label="Last 7 Days"
                  />
                  <FormControlLabel
                    value="14"
                    control={<Radio />}
                    label="Last 14 Days"
                  />
                  <FormControlLabel
                    value="30"
                    control={<Radio />}
                    label="Last 30 Days"
                  />
                  <FormControlLabel
                    value="91"
                    control={<Radio />}
                    label="Last 3 Months"
                  />
                  <FormControlLabel
                    value="182"
                    control={<Radio />}
                    label="Last 6 Months"
                  />
                  <FormControlLabel
                    value="365"
                    control={<Radio />}
                    label="Last 365 Days"
                  />
                  <FormControlLabel
                    value="custom"
                    control={<Radio />}
                    label="Custom"
                  />
                </RadioGroup>
              </FormControl>
              <Grid item>
                <MobileDatePicker
                  autoOk
                  label="Start Date"
                  inputFormat="MM/dd/yyyy"
                  value={newStartDate}
                  maxDate={newEndDate}
                  maxDateMessage="Start Date should not be after End Date"
                  disabled={quickDate !== 'custom'}
                  onChange={(date: Date | null): void => {
                    if (date !== null) {
                      setNewStartDate(date)
                    }
                  }}
                  renderInput={(params) => (
                    <TextField variant="standard" {...params} />
                  )}
                />
              </Grid>
              <Grid item>
                <MobileDatePicker
                  autoOk
                  label="End Date"
                  inputFormat="MM/dd/yyyy"
                  value={newEndDate}
                  minDate={newStartDate}
                  minDateMessage="End Date should not be before Start Date"
                  maxDateMessage="End Date should not be after the current date"
                  disabled={quickDate !== 'custom'}
                  onChange={(date: Date | null): void => {
                    if (date !== null) {
                      const endOfDayDate = dateFns.endOfDay(date)
                      setNewEndDate(endOfDayDate)
                    }
                  }}
                  renderInput={(params) => (
                    <TextField variant="standard" {...params} />
                  )}
                />
              </Grid>
              {filters()}
            </Grid>
          </MenuDialog>
        </Grid>
      </Paper>
    </AppBar>
  )
}
export default MobileFilterBar
