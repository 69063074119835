import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import useMediaQuery from '@mui/material/useMediaQuery'

import { Community, ProductFilter } from 'graphql/gen-types'

import Typography from '@mui/material/Typography'
import FormGroup from '@mui/material/FormGroup'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import DisplaySelections from './components/DisplaySelections'

const PREFIX = 'SelectCommunityModal'

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  communityContent: `${PREFIX}-communityContent`,
  submitButton: `${PREFIX}-submitButton`,
}

const SelectCommunityModalStyled = styled(Dialog)(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    height: 450,
    padding: '0.5em 1.5em 1.5em 1.5em',
  },

  [`& .${classes.container}`]: {
    padding: '2em',
  },
  [`& .${classes.communityContent}`]: {
    padding: '44px',
  },
  [`& .${classes.submitButton}`]: {
    position: 'relative',
    marginLeft: '15px',
  },
}))

/**
 * Modal for adding selecting community
 */
export default function SelectCommunityModal({
  communities,
  selectedCommunities,
  open,
  onClose,
  onSubmit,
}: {
  communities: Community[]
  selectedCommunities: Community[]
  open: boolean
  onClose: () => void
  onSubmit: (communities: Community[], options: ProductFilter) => void
}): JSX.Element {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [communitiesSelection, setCommunitiesSelection] = useState<Community[]>(
    selectedCommunities
  )
  const [distanceRange, setDistanceRange] = useState<number>()

  const handleCommunitiesSelection = (communities: Community[]) => {
    setCommunitiesSelection(communities)
  }
  const handleDistanceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const distance = parseInt(e.target.value)
    setDistanceRange(distance >= 0 ? distance : 0)
  }

  const handleSubmit = () => {
    const options = {
      communityMatch: distanceRange ? false : true,
      distanceRange,
    }
    onSubmit(communitiesSelection, options)
  }

  const handleClose = () => {
    setCommunitiesSelection(selectedCommunities) // reset selection
    onClose()
  }

  return (
    <SelectCommunityModalStyled
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      fullWidth={true}
      fullScreen={fullScreen}
      scroll="paper"
    >
      <DialogTitle id="form-dialog-title">
        {/* div needed for proper dom nesting */}
        <div>
          <Typography variant="h5" color="primary">
            Select Location
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        {/* This is for selecting location. Not implemented right now. */}
        {/* <Grid container>
          <Grid item xs={12}>
            <Box p={3}>
              <Grid container justify="center">
                <Typography variant="h4" color="primary">
                  Location
                </Typography>
              </Grid>
              <Grid container justify="space-around">
                <TextField label="Country"></TextField>
                <TextField label="State"></TextField>
                <TextField label="City"></TextField>
                <TextField label="Zip"></TextField>
              </Grid>
            </Box>
          </Grid>
          <Box p={2}></Box>
        </Grid> */}
        <Grid
          className={classes.communityContent}
          container
          spacing={3}
          direction="row"
        >
          <Grid item sm={12} md={6}>
            <Grid container justifyContent="center" spacing={3}>
              <Typography variant="h5" color="primary">
                Community
              </Typography>
              <Grid item xs={12}>
                <CommunityMultiSelect
                  communities={communities}
                  selectedCommunities={communitiesSelection}
                  onSelectCommunities={handleCommunitiesSelection}
                ></CommunityMultiSelect>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} md={6}>
            <Box p={3}>
              <Paper className={classes.container}>
                <Grid container justifyContent="center" spacing={3}>
                  <Typography variant="h5" color="primary">
                    Selection Details
                  </Typography>
                  <Grid item xs={12}>
                    <Grid
                      container
                      justifyContent="center"
                      direction="row"
                      alignItems="center"
                    >
                      <Grid item xs={12}>
                        <DisplaySelections
                          selectionNames={communitiesSelection.map(
                            (comm) => comm.name || 'No Name'
                          )}
                        ></DisplaySelections>
                      </Grid>

                      <Grid item xs={12} md={8}>
                        <Grid
                          container
                          justifyContent="center"
                          direction="column"
                          alignItems="center"
                        >
                          <FormGroup>
                            <Typography
                              variant="h6"
                              style={{ marginTop: '14px' }}
                            >
                              Include Nearby Communities
                            </Typography>
                            <TextField
                              variant="standard"
                              label="Distance (mi)"
                              size="small"
                              type="number"
                              onChange={handleDistanceChange}
                              helperText="Include nearby communities."
                              value={distanceRange || 0}
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Close
        </Button>
        <span className={classes.submitButton}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Submit
          </Button>
        </span>
      </DialogActions>
    </SelectCommunityModalStyled>
  )
}

interface CommunityMultiSelectProps {
  communities: Community[]
  selectedCommunities: Community[]
  onSelectCommunities: (communities: Community[]) => void
}
function CommunityMultiSelect({
  communities,
  selectedCommunities,
  onSelectCommunities,
}: CommunityMultiSelectProps): JSX.Element {
  const handleAddCommunity = (community: Community): void => {
    const newselected = [...selectedCommunities]
    // push new community
    newselected.push(community)
    onSelectCommunities(newselected)
  }
  const handleRemoveCommunity = (community: Community): void => {
    // get index of community in selection array
    const index = selectedCommunities.map((c) => c.id).indexOf(community.id)

    // splice it out
    const newselected = [...selectedCommunities]
    index !== -1 && newselected.splice(index, 1)

    // update
    onSelectCommunities(newselected)
  }

  const isSelected = (community: Community) => {
    return selectedCommunities.map((c) => c.id).includes(community.id)
  }

  const handleChange = (community: Community) => {
    if (!isSelected(community)) {
      handleAddCommunity(community)
    } else {
      handleRemoveCommunity(community)
    }
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Paper className={classes.root}>
          <List>
            {communities.map((community, i) => {
              const labelId = `community-select-${community.id}`
              return (
                <ListItem
                  key={`${community.id}-${i}`}
                  button
                  onClick={() => handleChange(community)}
                >
                  <ListItemText id={labelId} primary={`${community.name}`} />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      onChange={() => handleChange(community)}
                      checked={isSelected(community)}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })}
          </List>
        </Paper>
      </Grid>
    </Grid>
  )
}
