import React, { useEffect, useState } from 'react'
import {
  PopularMaterialColors,
  CommonQueryFilter,
  usePopularMaterialColorsQuery,
} from 'graphql/gen-types'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Tab from '@mui/material/Tab'
import useMediaQuery from '@mui/material/useMediaQuery'
import ComponentCard from 'components/common/layout/ComponentCard'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import MaterialColorTable from 'components/common/tables/MaterialColorTable'
import Grid from '@mui/material/Grid/Grid'
import { useTheme } from '@mui/material/styles'

const COMPONENT_TITLE = 'Material Color Selections'

function IndustryMaterialColors({
  filter,
}: {
  filter: CommonQueryFilter
}): JSX.Element {
  const [clientMaterialColors, setClientMaterialColors] = useState<
    PopularMaterialColors[]
  >([])
  const [industryMaterialColors, setIndustryMaterialColors] = useState<
    PopularMaterialColors[]
  >([])
  const [tabValue, setTabValue] = React.useState(0)
  const theme = useTheme()
  const smallSize = useMediaQuery(theme.breakpoints.down('md'))
  const [selectValue, setSelectValue] = React.useState<string>('')

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setSelectValue(event.target.value as string)
  }

  const handleChange = (
    // eslint-disable-next-line
    event: React.ChangeEvent<{}>,
    newValue: number
  ): void => {
    setTabValue(newValue)
  }

  const {
    data: clientData,
    loading: clientLoading,
    error: clientError,
  } = usePopularMaterialColorsQuery({
    variables: {
      filter: {
        ...filter,
        limit: 500,
      },
    },
  })

  const {
    data: industryData,
    loading: industryLoading,
    error: industryError,
  } = usePopularMaterialColorsQuery({
    variables: {
      filter: {
        ...filter,
        limit: 500,
        clientName: '',
      },
    },
  })

  const error = clientError || industryError
  // TODO: Look into this StackOverflow issue as a possible solution... should not pass a stringified object to useEffect...
  // https://stackoverflow.com/questions/55808749/use-object-in-useeffect-2nd-param-without-having-to-stringify-it-to-json
  useEffect(() => {
    if (industryData?.popularMaterialColors) {
      setIndustryMaterialColors(
        industryData.popularMaterialColors as PopularMaterialColors[]
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(industryData)])
  // TODO: Look into this StackOverflow issue as a possible solution... should not pass a stringified object to useEffect...
  // https://stackoverflow.com/questions/55808749/use-object-in-useeffect-2nd-param-without-having-to-stringify-it-to-json
  useEffect(() => {
    if (clientData?.popularMaterialColors) {
      setClientMaterialColors(
        clientData.popularMaterialColors as PopularMaterialColors[]
      )
      setSelectValue(clientData.popularMaterialColors[0]?.materialName || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(clientData)])

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized
          message={error.toString()}
          imageName={'PopularMaterialColorsDisplay'}
        ></Unauthorized>
      )
    }
    return <div>{error.toString()}</div>
  }

  const getDistinctMaterials = (data: PopularMaterialColors[]): string[] => {
    const distinct = {}
    data.forEach((row) => {
      if (row?.materialName) {
        distinct[row.materialName] = true
      }
    })
    return Object.keys(distinct)
  }

  const distinctMaterials = getDistinctMaterials(clientMaterialColors)

  const filterComponent = smallSize ? (
    <>
      <Select
        variant="standard"
        value={selectValue}
        onChange={handleSelectChange}
        style={{ marginBottom: theme.spacing(1) }}
      >
        {distinctMaterials.map((key, i) => {
          return <MenuItem value={key}>{key}</MenuItem>
        })}
      </Select>
      <MaterialColorTable
        materialColors={clientMaterialColors.filter(
          (row) => row.materialName === selectValue
        )}
        title="Color Selections"
      />
      <MaterialColorTable
        materialColors={industryMaterialColors.filter(
          (row) => row.materialName === selectValue
        )}
        title="Industry Color Selections"
      />
    </>
  ) : (
    <React.Fragment>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        {distinctMaterials.map((key, i) => {
          return <Tab key={key} label={key} />
        })}
      </Tabs>

      {distinctMaterials.map((key, i) => {
        const clientMaterialData = clientMaterialColors.filter(
          (row) => row.materialName === key
        )
        const industryMaterialData = industryMaterialColors.filter(
          (row) => row.materialName === key
        )
        return (
          <TabPanel key={key} value={tabValue} index={i}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <MaterialColorTable
                  materialColors={clientMaterialData}
                  title="Color Selections"
                />
              </Grid>
              <Grid item xs={6}>
                <MaterialColorTable
                  materialColors={industryMaterialData}
                  title="Industry Color Selections"
                />
              </Grid>
            </Grid>
          </TabPanel>
        )
      })}
    </React.Fragment>
  )

  return (
    <ComponentCard
      title={COMPONENT_TITLE}
      result={{
        data: industryMaterialColors,
        loading: clientLoading && industryLoading,
        error,
      }}
      skeletonHeight={500}
    >
      {filterComponent}
    </ComponentCard>
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: number
  value: number
}

function TabPanel(props: TabPanelProps): JSX.Element {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

export default IndustryMaterialColors
