import React, { useContext, useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import clsx from 'clsx'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useMutation } from '@apollo/client'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import CheckIcon from '@mui/icons-material/Check'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import LibraryAddIcon from '@mui/icons-material/LibraryAdd'
import PersonIcon from '@mui/icons-material/Person'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { DELETE_COLONNADE_USER } from 'graphql/mutation/deleteColonnadeUser'
import { REQUEST_SUBSCRIPTION_UPGRADE } from 'graphql/mutation/requestSubscriptionUpgrade'
import { UPDATE_COLONNADE_USER } from 'graphql/mutation/updateColonnadeUser'
import USERS_QUERY from 'graphql/colonnade-queries/users'
import { AuthContext } from '../../../auth/AuthContext'
import hydrationStore from 'store/HydrationStore'
import { snackbarConfigAtom } from 'store/atoms'

import {
  useGetColonnadeClientSubscriptionInfoQuery,
  useGetRolesQuery,
  useGetUsersQuery,
  ColonnadeClientSubscriptionInfo,
  ColonnadeRole,
  ColonnadeSubscriptionLevelsEnum,
  User,
} from '../../../graphql/gen-types'

import { AppStore } from 'store'
import { snakeCaseToTitleCase } from '../../../utils/functions'
import AddUserDialog from './AddUserDialog'
import EditUserDialog from './EditUserDialog'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import { ANEWGO_ADMIN_ROLE_ID, ANEWGO_STAFF_ROLE_ID } from '../../../constants'
import { REQUEST_COLONNADE_LICENSE_UPGRADE } from 'graphql/auth-queries/authDb'
import { classes, Root } from './UserManagement.styles'
const DEFAULT_REQ_SUB_LABEL = 'Requested Subscription Level'

const initialRolesState: ColonnadeRole[] = []
const initialUsersState: User[] = []
const initialSubscriptionState: ColonnadeClientSubscriptionInfo = {}
const INITIAL_ROWS_PER_PAGE = 10

interface Header {
  key: string
  name: string
  lineHeight?: string
}

const headers: Header[] = [
  { key: 'firstName', name: 'First Name' },
  { key: 'lastName', name: 'Last Name' },
  { key: 'email', name: 'Email' },
  { key: 'status', name: 'Status' },
  { key: 'organizationRole', name: 'Organizational Role' },
  { key: 'role', name: 'Role', lineHeight: '39px' },
  { key: 'licensed', name: 'License', lineHeight: '36px' },
]

type sortOrders = 'asc' | 'desc'

export default function UserManagement(): JSX.Element {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('md'))

  const { appState } = useContext(AppStore)
  const { user } = useContext(AuthContext)
  const [snackbarConfig, setSnackbarConfig] = useAtom(snackbarConfigAtom)
  const clientId = hydrationStore.selectedClient?.id
  const { subscriptionLevel, userId } = user
  const clientName = appState.selectedClient?.altName || ''

  const [users, setUsers] = useState(initialUsersState)
  const [roles, setRoles] = useState(initialRolesState)
  const [subscriptionInfo, setSubscriptionInfo] = useState(
    initialSubscriptionState
  )
  const [addLicensesDialogOpen, setAddLicensesDialogOpen] = useState<boolean>(
    false
  )
  const [addUserDialogOpen, setAddUserDialogOpen] = useState<boolean>(false)
  const [maxLicensesDialogOpen, setMaxLicensesDialogOpen] = useState<boolean>(
    false
  )
  const [userToBeDeleted, setUserToBeDeleted] = useState<User | null>(null)
  const [userToBeEdited, setUserToBeEdited] = useState<User | null>(null)
  const [
    upgradeSubscriptionDialogOpen,
    setUpgradeSubscriptionDialogOpen,
  ] = useState<boolean>(false)
  const [requestedSubLevel, setRequestedSubLevel] = useState<string>('')
  const [requestedSubLevelLabel, setRequestedSubLevelLabel] = useState(
    DEFAULT_REQ_SUB_LABEL
  )
  const [requestedSubLevelError, setRequestedSubLevelError] = useState(false)
  const [
    numberOfRequestedLicense,
    setNumberOfRequestedLicense,
  ] = useState<number>(1)

  const [nameOrEmailFilter, setNameOrEmailFilter] = useState('')
  const [roleFilter, setRoleFilter] = useState<number | string>('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sortBy, setSortBy] = useState<string>('firstName')
  const [sortOrder, setSortOrder] = useState<sortOrders>('asc')

  // mutation hooks
  const [deleteColonnadeUser] = useMutation(DELETE_COLONNADE_USER)
  const [requestSubscriptionUpgrade] = useMutation(REQUEST_SUBSCRIPTION_UPGRADE)
  const [updateColonnadeUser] = useMutation(UPDATE_COLONNADE_USER)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const { data, loading, error } = useGetUsersQuery({
    variables: {
      clientName,
    },
  })

  const {
    data: rolesData,
    loading: rolesLoading,
    error: rolesError,
  } = useGetRolesQuery()

  const {
    data: subData,
    loading: subLoading,
    error: subError,
  } = useGetColonnadeClientSubscriptionInfoQuery({
    variables: {
      clientId: clientId || 0,
    },
    fetchPolicy: 'network-only',
    skip: !clientId,
  })

  const [requestColonnadeLicenseUpgrade] = useMutation(
    REQUEST_COLONNADE_LICENSE_UPGRADE
  )

  useEffect(() => {
    if (data && data.colonnadeUsers) {
      setUsers(data.colonnadeUsers)
    }
  }, [data])

  useEffect(() => {
    if (rolesData && rolesData.colonnadeRoles) {
      setRoles(rolesData.colonnadeRoles)
    }
  }, [rolesData])

  useEffect(() => {
    if (subData && subData.getColonnadeClientSubscriptionInfo) {
      setSubscriptionInfo(subData.getColonnadeClientSubscriptionInfo)
    }
  }, [subData])

  useEffect(() => {
    // For small, set rowsPerPage to 1 if it is not already
    if (sm && rowsPerPage !== 1) {
      setRowsPerPage(1)
      // When upscaling from small to larger display, set rowsPerPage to 5
    } else if (!sm && rowsPerPage === 1) {
      setRowsPerPage(INITIAL_ROWS_PER_PAGE)
      setPage(0) // reset page to 0 to avoid over indexing
    }
  }, [sm, rowsPerPage])

  const numberOfLicensedAgents = users.filter((user: User) => user.licensed)
    .length

  if (loading || rolesLoading || subLoading) {
    return <div>Loading...</div>
  }
  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized message={error.toString()} imageName={'UserManagement'} />
      )
    }
    console.error(error)
    return <div>Error while loading users</div>
  }
  if (rolesError) {
    if (verifyUserAuthError(rolesError.toString())) {
      return (
        <Unauthorized
          message={rolesError.toString()}
          imageName={'UserManagement'}
        />
      )
    }
    console.error(rolesError)
    return <div>Error while loading roles</div>
  }
  if (subError) {
    if (verifyUserAuthError(subError.toString())) {
      return (
        <Unauthorized
          message={subError.toString()}
          imageName={'UserManagement'}
        />
      )
    }
    console.error(subError)
    return <div>Error while loading subscription info</div>
  }

  const numberOfCurrentLicenses = subscriptionInfo.numberOfLicenses
    ? ` ${subscriptionInfo.numberOfLicenses - numberOfLicensedAgents}`
    : ' 0'

  const onRoleChange = (ev: any, colonnadeUser: User): void => {
    if (userId) {
      updateColonnadeUser({
        variables: {
          callerUserId: userId,
          userId: colonnadeUser.id,
          input: {
            roleId: ev.target.value,
          },
        },
        refetchQueries: [{ query: USERS_QUERY, variables: { clientName } }],
      })
        .then(() => {
          triggerSnackbar('Insights user updated successfully!', 'success')
        })
        .catch((err) => {
          console.log(err)
          triggerSnackbar(err.message.replace(/.*[Ee]rror: /, ''), 'error')
        })
    }
  }

  const handleDeleteUser = (): void => {
    if (userToBeDeleted && userId) {
      deleteColonnadeUser({
        variables: {
          callerUserId: userId,
          userId: userToBeDeleted.id,
        },
        refetchQueries: [{ query: USERS_QUERY, variables: { clientName } }],
      })
        .then(() => {
          triggerSnackbar('Insights user deleted successfully!', 'success')
        })
        .catch((err) => {
          console.log(err)
          triggerSnackbar(err.message.replace(/.*[Ee]rror: /, ''), 'error')
        })
    }
    setUserToBeDeleted(null)
  }

  const handleHeaderSortClick = (header: Header): void => {
    if (sortBy !== header.key) {
      setSortBy(header.key)
      setSortOrder('asc')
      return
    }
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
  }

  const handleLicenseChange = (colonnadeUser: User): void => {
    if (
      !colonnadeUser.licensed &&
      numberOfLicensedAgents >= (subscriptionInfo.numberOfLicenses || 0)
    ) {
      setMaxLicensesDialogOpen(true)
      return
    }
    if (userId) {
      updateColonnadeUser({
        variables: {
          callerUserId: userId,
          userId: colonnadeUser.id,
          input: {
            licensed: !colonnadeUser.licensed, // Opposite of current value
          },
        },
        refetchQueries: [{ query: USERS_QUERY, variables: { clientName } }],
      })
        .then(() => {
          triggerSnackbar('Insights user updated successfully!', 'success')
        })
        .catch((err) => {
          console.log(err)
          triggerSnackbar(err.message.replace(/.*[Ee]rror: /, ''), 'error')
        })
    }
  }

  const handleRequestedSubLevelChange = (ev: any): void => {
    const selectedRequestedSubLevel = ev.target.value
    setRequestedSubLevelError(false)
    setRequestedSubLevelLabel(DEFAULT_REQ_SUB_LABEL)
    setRequestedSubLevel(selectedRequestedSubLevel)
  }

  const handleRequestedLicenseChange = async (): Promise<void> => {
    const { data } = await requestColonnadeLicenseUpgrade({
      variables: {
        clientName,
        numberOfRequestedLicense,
        requesterEmail: user.userEmail,
      },
    })

    if (data.requestColonnadeLicenseUpgrade) {
      triggerSnackbar(
        'Sent a request for license upgrade! We will get in touch with you shortly.',
        'success'
      )
    } else {
      triggerSnackbar('Failed to request for license upgrade.', 'error')
    }

    setAddLicensesDialogOpen(false)
  }

  const handleRequestSubUpgrade = async (): Promise<void> => {
    if (!requestedSubLevel) {
      setRequestedSubLevelLabel(
        'Please choose a subscription level to request.'
      )
      setRequestedSubLevelError(true)
      return
    }

    requestSubscriptionUpgrade({
      variables: {
        callerUserId: userId,
        requestedSubscriptionLevel: requestedSubLevel,
      },
    })
      .then(() => {
        triggerSnackbar('Sent a request for subscription change!', 'success')
        setUpgradeSubscriptionDialogOpen(false)
        setRequestedSubLevel('')
      })
      .catch((err) => {
        console.log(err)
        triggerSnackbar(err.message.replace(/.*[Ee]rror: /, ''), 'error')
      })
  }

  const handleAddUser = (): void => {
    // don't allow to add user if number of user is greater than number of licenses.
    // this is a marketing strategy.
    if (users.length >= (subscriptionInfo?.numberOfLicenses || 0)) {
      triggerSnackbar(
        'Users has exceeded number of licenses. You need to request for more licenses in order to add more users.',
        'error'
      )
      setAddLicensesDialogOpen(true)
    } else {
      setAddUserDialogOpen(true)
    }
  }

  const triggerSnackbar = (
    message: string,
    severity: 'error' | 'success' | 'info' | 'warning' | undefined
  ) => {
    setSnackbarConfig({
      ...snackbarConfig,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      autoHideDuration: 6000,
      open: true,
      message,
      severity,
    })
  }

  const filteredUsers =
    nameOrEmailFilter || roleFilter
      ? users.filter((colonnadeUser: User) => {
          if (nameOrEmailFilter) {
            const name = `${colonnadeUser.firstName || ''}${
              colonnadeUser.lastName ? ` ${colonnadeUser.lastName}` : ''
            }`
            const namePasses = name
              .toLowerCase()
              .includes(nameOrEmailFilter.toLowerCase())
            const emailPasses =
              colonnadeUser.email &&
              colonnadeUser.email
                .toLowerCase()
                .includes(nameOrEmailFilter.toLowerCase())
            if (!namePasses && !emailPasses) {
              return false
            }
          }
          return !(roleFilter && colonnadeUser.roleId !== roleFilter)
        })
      : users

  const genTableHeader = (): JSX.Element => (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headers.map((header) => (
          <TableCell
            key={header.name}
            align="left"
            className={clsx(classes.tableHeadCell, classes.tableCell)}
            onClick={(): void => handleHeaderSortClick(header)}
          >
            {sm && (
              <span
                style={
                  header.lineHeight
                    ? { lineHeight: header.lineHeight }
                    : undefined
                }
              >
                {header.name}
              </span>
            )}
            {!sm && (
              <TableSortLabel
                active={sortBy === header.key}
                direction={sortBy === header.key ? sortOrder : 'asc'}
                onClick={(): void => handleHeaderSortClick(header)}
              >
                {header.name}
                {sortBy === header.key ? (
                  <span className={classes.visuallyHidden}>
                    {sortOrder === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
        <TableCell
          align="left"
          className={classes.tableCell}
          style={{ height: '81px' }}
        />
        <TableCell
          align="left"
          className={classes.tableCell}
          style={{ height: '81px' }}
        />
      </TableRow>
    </TableHead>
  )

  const genTableRow = (colonnadeUser: User): JSX.Element => {
    // If current row has anewgo roles
    const hasAnewgoRoles =
      colonnadeUser.roleId === ANEWGO_ADMIN_ROLE_ID ||
      colonnadeUser.roleId === ANEWGO_STAFF_ROLE_ID
    // authorized user is an anewgo admin
    const userIsAnewgoAdmin = user.roleId === ANEWGO_ADMIN_ROLE_ID
    const userIsAnewgoStaff = user.roleId === ANEWGO_STAFF_ROLE_ID
    return (
      <>
        <TableCell component="th" scope="row" className={classes.tableCell}>
          {colonnadeUser.firstName}
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          {colonnadeUser.lastName}
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          {colonnadeUser.email}
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          {colonnadeUser.status}
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          {colonnadeUser.organizationRole || (
            <span style={{ opacity: 0 }}>None</span>
          )}
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          <FormControl className={classes.formControl}>
            <Select
              classes={{ outlined: classes.smallSelect }}
              value={colonnadeUser.roleId}
              onChange={(ev): void => onRoleChange(ev, colonnadeUser)}
              inputProps={{
                name: `role-select-${colonnadeUser.id}`,
                id: `role-select-${colonnadeUser.id}`,
              }}
              disabled={
                // cannot edit self
                colonnadeUser.id === userId ||
                // cannot edit roles higher than self
                (colonnadeUser.roleId
                  ? colonnadeUser.roleId < user.roleId
                  : false)
              }
            >
              {roles.map((role) => {
                // Display all roles if authorized user is an anewgo admin
                if (userIsAnewgoAdmin) {
                  return (
                    <MenuItem key={role.id} value={role.id}>
                      {snakeCaseToTitleCase(role.role)}
                    </MenuItem>
                  )
                  // Display disabled anewgo admin
                } else if (userIsAnewgoStaff) {
                  return (
                    <MenuItem
                      key={role.id}
                      value={role.id}
                      disabled={role.role === 'ANEWGO_ADMIN'}
                    >
                      {snakeCaseToTitleCase(role.role)}
                    </MenuItem>
                  )
                  // Display disabled anewgo roles if this row has them
                } else if (hasAnewgoRoles) {
                  return (
                    <MenuItem
                      key={role.id}
                      value={role.id}
                      disabled={
                        role.role === 'ANEWGO_ADMIN' ||
                        role.role === 'ANEWGO_STAFF'
                      }
                    >
                      {snakeCaseToTitleCase(role.role)}
                    </MenuItem>
                  )
                  // Display non-anewgo roles normally
                } else if (
                  role.role !== 'ANEWGO_ADMIN' &&
                  role.role !== 'ANEWGO_STAFF'
                ) {
                  return (
                    <MenuItem key={role.id} value={role.id}>
                      {snakeCaseToTitleCase(role.role)}
                    </MenuItem>
                  )
                  // default case
                } else {
                  return null
                }
              })}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          <Button
            aria-label="licence"
            onClick={(): void => handleLicenseChange(colonnadeUser)}
            color={colonnadeUser.licensed ? 'primary' : 'inherit'}
            endIcon={colonnadeUser.licensed ? <CheckIcon /> : undefined}
            variant="outlined"
          >
            {colonnadeUser.licensed ? 'Licensed' : 'Unlicensed'}
          </Button>
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          <IconButton
            aria-label="delete"
            disabled={
              !colonnadeUser.roleId ||
              (colonnadeUser.roleId === ANEWGO_ADMIN_ROLE_ID &&
                user.roleId !== ANEWGO_ADMIN_ROLE_ID)
            }
            onClick={(): void => setUserToBeDeleted(colonnadeUser)}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          <IconButton
            aria-label="edit"
            disabled={
              !colonnadeUser.roleId ||
              (colonnadeUser.roleId === ANEWGO_ADMIN_ROLE_ID &&
                user.roleId !== ANEWGO_ADMIN_ROLE_ID)
            }
            onClick={(): void => setUserToBeEdited(colonnadeUser)}
            size="large"
          >
            <EditIcon />
          </IconButton>
        </TableCell>
      </>
    )
  }

  return (
    <Root>
      <AddUserDialog
        addUserDialogOpen={addUserDialogOpen}
        setAddUserDialogOpen={setAddUserDialogOpen}
        roles={roles}
        users={users}
      />
      <Dialog
        classes={{
          paper: classes.dialog,
        }}
        open={maxLicensesDialogOpen}
        onClose={(): void => setMaxLicensesDialogOpen(false)}
        aria-labelledby="max-licenses-title"
      >
        {maxLicensesDialogOpen && (
          <div>
            <DialogTitle id="max-licenses-title">
              <ErrorOutlineIcon className={classes.errorIcon} /> You have used
              all your available licenses.
            </DialogTitle>
            <DialogContent style={{ paddingLeft: '65px' }}>
              <DialogContentText>
                More can be aquired by upgrading to the next subscription tier,
                or by adding them to your current subscription tier.
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{ paddingBottom: '20px', paddingRight: '20px' }}
            >
              <Button
                variant="contained"
                onClick={(): void => setMaxLicensesDialogOpen(false)}
              >
                Maybe Later
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={(): void => {
                  setMaxLicensesDialogOpen(false)
                  setAddLicensesDialogOpen(true)
                }}
                autoFocus
              >
                Add Licenses
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={(): void => {
                  setMaxLicensesDialogOpen(false)
                  setUpgradeSubscriptionDialogOpen(true)
                }}
                autoFocus
              >
                Upgrade Subscription
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
      <Dialog
        classes={{
          paper: classes.dialog,
        }}
        open={addLicensesDialogOpen}
        onClose={(): void => setAddLicensesDialogOpen(false)}
        aria-labelledby="add-licenses-title"
      >
        {addLicensesDialogOpen && (
          <div>
            <DialogTitle id="alert-dialog-title">
              Request Additional License
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Typography variant="h6">
                  Current number of licenses:{' '}
                  {subscriptionInfo.numberOfLicenses}
                </Typography>
                <TextField
                  data-testid="licences-input"
                  id="standard-number"
                  label="Number of licenses to add"
                  variant="outlined"
                  type="number"
                  InputProps={{
                    inputProps: { min: '0' },
                  }}
                  className={classes.numberLicenseField}
                  value={numberOfRequestedLicense}
                  onChange={(e) =>
                    setNumberOfRequestedLicense(parseInt(e.target.value))
                  }
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(): void => setAddLicensesDialogOpen(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={handleRequestedLicenseChange}
                autoFocus
              >
                Submit
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
      <Dialog
        classes={{
          paper: classes.dialog,
        }}
        open={upgradeSubscriptionDialogOpen}
        onClose={(): void => setUpgradeSubscriptionDialogOpen(false)}
        aria-labelledby="upgrade-subscription-title"
      >
        {upgradeSubscriptionDialogOpen && (
          <div>
            <DialogTitle id="upgrade-subscription-title">
              Upgrade Subscription
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                className={classes.dialogText}
              >{`Current Subscription Level: ${snakeCaseToTitleCase(
                subscriptionInfo.subscriptionLevel || subscriptionLevel
              )}`}</DialogContentText>
              <FormControl
                className={classes.subscriptionFormControl}
                error={requestedSubLevelError}
              >
                <Select
                  classes={{ outlined: classes.smallSelect }}
                  value={requestedSubLevel}
                  onChange={handleRequestedSubLevelChange}
                  inputProps={{
                    name: 'requestedSub-select',
                    id: 'requestedSub-select',
                  }}
                  label={requestedSubLevelLabel}
                >
                  {Object.values(ColonnadeSubscriptionLevelsEnum)
                    .filter((value) => value !== 'NONE')
                    .map((value) => (
                      <MenuItem
                        key={value}
                        value={value}
                        disabled={subscriptionLevel.toUpperCase() === value}
                        selected={subscriptionLevel.toUpperCase() === value}
                      >
                        {snakeCaseToTitleCase(value)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(): void => setUpgradeSubscriptionDialogOpen(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={handleRequestSubUpgrade}
                autoFocus
              >
                Submit
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
      <Dialog
        open={userToBeDeleted !== null}
        onClose={(): void => setUserToBeDeleted(null)}
        aria-labelledby="user-delete-title"
        aria-describedby="user-delete-message"
      >
        {userToBeDeleted !== null && (
          <div>
            <DialogTitle id="user-delete-title">
              {`Remove user "${userToBeDeleted.firstName || ''}${
                userToBeDeleted.lastName ? ` ${userToBeDeleted.lastName}` : ''
              }"?`}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="user-delete-message">
                This user will be removed from your Insights Dashboard.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(): void => setUserToBeDeleted(null)}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                data-testid="remove-user-button"
                color="secondary"
                onClick={handleDeleteUser}
                autoFocus
              >
                Remove
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
      {userToBeEdited && (
        <EditUserDialog
          userToBeEdited={userToBeEdited}
          setUserToBeEdited={setUserToBeEdited}
          roles={roles}
          users={users}
        />
      )}
      <TableContainer component={Paper} className={classes.root}>
        <Typography
          variant="h5"
          className={clsx(
            classes.typography,
            classes.typographyTitle,
            classes.centered
          )}
        >
          User Management
        </Typography>
        <Typography
          variant="h6"
          className={clsx(classes.typography, classes.typographyTitle)}
        >
          <PersonIcon style={{ marginBottom: '-4px' }} />
          {users.length > 0 ? ` Users (${users.length})` : ' Users'}
        </Typography>
        {subscriptionInfo && (
          <Typography variant="subtitle1" className={classes.subtitle1}>
            Remaining Licenses:
            {numberOfCurrentLicenses}
          </Typography>
        )}
        <Grid container direction="row" spacing={1}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              className={classes.addUserButton}
              endIcon={<PersonAddIcon />}
              onClick={handleAddUser}
            >
              Add User
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              className={classes.addLicensesButton}
              endIcon={<LibraryAddIcon />}
              onClick={(): void => setAddLicensesDialogOpen(true)}
            >
              Add Licenses
            </Button>
          </Grid>
          <Grid item classes={{ root: classes.gridItemMarginRightAuto }}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              className={classes.subscriptionButton}
              endIcon={
                <DoubleArrowIcon style={{ transform: 'rotate(270deg)' }} />
              }
              onClick={(): void => setUpgradeSubscriptionDialogOpen(true)}
            >
              Upgrade Subscription
            </Button>
          </Grid>
          <Grid item>
            <TextField
              className={
                sm
                  ? classes.xsRoleFilterFormControl
                  : classes.roleFilterFormControl
              }
              data-testid="role-filter-select"
              id="role-filter-select"
              select
              label="Filter by role"
              value={roleFilter}
              onChange={(ev: any) => {
                setRoleFilter(ev.target.value)
                setPage(0)
              }}
              size="small"
            >
              <MenuItem key={0} value="">
                Show All
              </MenuItem>
              {roles.map((role) => (
                <MenuItem key={role.id} value={role.id}>
                  {snakeCaseToTitleCase(role.role)}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="nameOrEmailFilter"
              className={
                sm ? classes.xsNameOrEmailFilter : classes.nameOrEmailFilter
              }
              label="Filter by name or email"
              onChange={(ev: any) => {
                setNameOrEmailFilter(ev.target.value)
                setPage(0)
              }}
              size="small"
              variant="outlined"
              value={nameOrEmailFilter}
            />
          </Grid>
        </Grid>
        <Table className={classes.table} aria-label="simple table">
          {!sm && genTableHeader()}
          <TableBody>
            {filteredUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .sort((a, b) => {
                const primary = sortOrder === 'asc' ? a : b
                const secondary = sortOrder === 'asc' ? b : a
                if (sortBy !== 'role') {
                  return primary[sortBy]
                    ?.toString()
                    ?.localeCompare(secondary[sortBy]?.toString(), undefined, {
                      numeric: true,
                    })
                }
                const roleA = roles.find((role) => role.id === primary.roleId)
                const roleAName = roleA?.role || ''
                const roleB = roles.find((role) => role.id === secondary.roleId)
                const roleBName = roleB?.role || ''
                return roleAName.localeCompare(roleBName, undefined, {
                  numeric: true,
                })
              })
              .map((user: User) => (
                <TableRow
                  className={classes.tableRow}
                  key={`${user.firstName}${user.lastName}${user.email}`}
                >
                  {/* Conditionally render row headers for responsive table */}
                  {sm && genTableHeader()}
                  {/* For small factors, add additional TableRow wrapper for responsive table  */}
                  {!sm ? (
                    genTableRow(user)
                  ) : (
                    <TableRow className={classes.tableSubRow}>
                      {genTableRow(user)}
                    </TableRow>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={sm ? [] : [10, 25, 50]} // For small screen, do not display rowsPerPage
          component="div"
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Root>
  )
}
