import { SetStateAction } from 'react'

type LoginPageState = {
  email: string
  emailErrorMsg: string
  errorMessage: string
  errorMessageOpen: boolean
  password: string
  passwordErrorMsg: string
  loading: boolean
  validatedEmail: string | null
  isForgotEmail: boolean | null
  isForgotPassword: boolean
}
const initialState: LoginPageState = {
  email: '',
  emailErrorMsg: '',
  errorMessage: '',
  errorMessageOpen: false,
  password: '',
  passwordErrorMsg: '',
  loading: false,
  // This is initialized as null to show that the user hasn't interacted with the email yet
  validatedEmail: null,
  // This is initialized as null to show that the user hasn't interacted with forgot email yet
  isForgotEmail: null,
  isForgotPassword: false,
}

export default initialState

export const dismissErrorMessage = (): SetStateAction<LoginPageState> => (
  state: LoginPageState
): LoginPageState => {
  return {
    ...state,
    errorMessageOpen: false,
    loading: false,
  }
}

export const displayErrorMessage = (
  errorMessage: string
): SetStateAction<LoginPageState> => (
  state: LoginPageState
): LoginPageState => ({
  ...state,
  errorMessage,
  errorMessageOpen: true,
  loading: false,
})

export const setEmail = (email: string): SetStateAction<LoginPageState> => (
  state: LoginPageState
): LoginPageState => ({
  ...state,
  email,
})

export const setPassword = (
  password: string
): SetStateAction<LoginPageState> => (
  state: LoginPageState
): LoginPageState => ({
  ...state,
  password,
})
