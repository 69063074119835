import React, { ReactElement, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { SetState } from '../Shared'

interface AlertDialogProps {
  openDialog: boolean
  setOpenDialog: SetState<boolean>
  dialogMessage: string
}

export default function AlertDialog({
  openDialog,
  setOpenDialog,
  dialogMessage,
}: AlertDialogProps): ReactElement | null {
  return (
    <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
      <DialogTitle>Alert</DialogTitle>
      <DialogContent>
        <Typography>{dialogMessage}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const useAlertDialog = () => {
  const [openDialog, setOpenDialog] = useState(false)
  const [dialogMessage, setDialogMessage] = useState('')
  return {
    openDialog,
    setOpenDialog,
    dialogMessage,
    setDialogMessage,
  }
}
