// Constants
export const API_KEY = '376'
export const PRODUCT_OVERVIEW = 'PRODUCT_OVERVIEW'
export const FLOORPLAN_DESIGN = 'FLOORPLAN_DESIGN'
export const EXTERIOR_DESIGN = 'EXTERIOR_DESIGN'
export const HOMESITE_SELECT = 'HOMESITE_SELECT'
export const INTERIOR_DESIGN = 'INTERIOR_DESIGN'
export const CTA_SECTION = 'CTA_SECTION'

export const CONTACT_US_BUTTON_COLOR = '#5662f5'
export const MODAL_DRAWER_HEADER_HT = 60

export const DEFAULT_FP_PAGE_SECTION_ORDER = [
  PRODUCT_OVERVIEW,
  FLOORPLAN_DESIGN,
  EXTERIOR_DESIGN,
  INTERIOR_DESIGN,
  HOMESITE_SELECT,
  CTA_SECTION,
]

export const DEFAULT_MORTGAGE_DISCLAIMER_TEXT =
  'Calculator is intended for estimation purposes only. Estimated payment comprises principal and interest only. Your actual mortgage payment may vary depending on credit, taxes and insurance, as well as other factors.'
