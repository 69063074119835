import { useLocation } from 'react-router-dom'
import { Community, DateFilterField } from '../../../../graphql/gen-types'
import { LocationSelection } from '../Shared'

interface RouteStateLocationSelection {
  dateFilterField?: DateFilterField | null
  communities?: Community[] | null
  country?: string
  state?: string
  metro?: number
  county?: number
  zip?: number
  province?: string
  district?: number
  postCode?: string
  cities?: string[]
}

interface RouteStateLocationSelectionResult {
  location: {
    state: RouteStateLocationSelection | null
  }
  routeLocation: LocationSelection
}

export function useRouteStateLocationSelection(): RouteStateLocationSelectionResult {
  const location: {
    state: RouteStateLocationSelection | null
  } = useLocation()

  return {
    location,
    routeLocation: {
      cities: location?.state?.cities,
      country: location?.state?.country,
      us: {
        state: location?.state?.state,
        county: location?.state?.county,
        metro: location?.state?.metro,
        zipCode: location?.state?.zip,
      },
      can: {
        province: location?.state?.province,
        district: location?.state?.district,
        postCode: location?.state?.postCode,
      },
    },
  }
}
