import React, { useState, useEffect } from 'react'
import {
  Prospect,
  useAddProspectActivitiesMutation,
  useGetProspectActivitiesQuery,
  RecommendedPlan,
} from '../../../../graphql/gen-types'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'

import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepContent from '@mui/material/StepContent'
import StepButton from '@mui/material/StepButton'
import StepConnector from '@mui/material/StepConnector'
import StepLabel from '@mui/material/StepLabel'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import CloseIcon from '@mui/icons-material/Close'
import Collapse from '@mui/material/Collapse'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import DateFnsAdapter from '@date-io/date-fns'

import EditModal from './modals/EditModal'
import AddMilestoneModal from './modals/AddMilestoneModal'
import EditMilestoneModal from './modals/EditMilestoneModal'

import CommunitiesTable from './tables/CommunitiesTable'
import PlansTable from './tables/PlansTable'
import ElevationsTable from './tables/ElevationsTable'
import SchemesTable from './tables/SchemesTable'
import LoginTimesTable from './tables/LoginTimesTable'
import SGTAppointmentsTable from './tables/SGTAppointmentsTable'
import ProspectProfile from '../ProspectProfile'
import {
  ProspectPiplelineStyled,
  classes,
} from '../styles/ProspectPipeline.styles'
import StepIcon from '@mui/material/StepIcon'

const dateFns = new DateFnsAdapter()

const COMPONENT_TITLE = 'Customer Journey'
const ERROR_MESSAGE_DISPLAY_TIME = 10000

/**
 * Function used for re-ordering milestones
 * // Moves item in array from old to new index. Returns new array (Shallow copy)
 */
function arrayMove(
  arr: unknown[],
  fromIndex: number,
  toIndex: number
): unknown[] {
  const newArr = [...arr]
  const element = newArr[fromIndex]
  newArr.splice(fromIndex, 1)
  newArr.splice(toIndex, 0, element)
  return newArr
}

export type Milestone = {
  title: string
  date?: Date
  content?: JSX.Element | string | number | undefined | null
  notes: string
  plans?: RecommendedPlan[]
  inquiry?: string
}

const initialMilestones: Milestone[] = [
  { title: 'Initial Contact', content: null, notes: '' },
  { title: 'Qualify Buyer', content: null, notes: '' },
]

interface ProspectPiplelineProps {
  clientName: string
  email: string
  data?: Prospect | undefined | null
  loading?: boolean
  error?: Error | undefined
  onSelectStep?: (step: number) => void
  selectedStep?: number
}

const initialErrorMessage: JSX.Element | string = ''

function ProspectPipleline({
  clientName,
  email,
  data,
  loading,
  error,
  onSelectStep,
  selectedStep = 0,
}: ProspectPiplelineProps): JSX.Element {
  const [milestones, setMilestones] = useState(initialMilestones)
  const [activeStep, setActiveStep] = useState(selectedStep) // Step is the current milestone selected
  const [editIndex, setEditIndex] = useState(0) // Index is for editing the editable milestones

  // States for modals open/close
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [addMilestoneModalOpen, setAddMilestoneModalOpen] = useState(false)
  const [editMilestoneModalOpen, setEditMilestoneModalOpen] = useState(false)
  const [errorMessageOpen, setErrorMessageOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState(initialErrorMessage)

  const handleErrorMessage = (msg: string | JSX.Element): void => {
    setErrorMessage(msg)
    setErrorMessageOpen(true)
    setTimeout(() => setErrorMessageOpen(false), ERROR_MESSAGE_DISPLAY_TIME)
  }

  const staticMilestones: Milestone[] = [
    {
      title: 'Browsing History',
      content: (
        <>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography>
                Plans browsed include browsing done before and after account
                registration.
              </Typography>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Grid item xs={12}>
                <Typography variant="h6">Plans Browsed</Typography>
              </Grid>
              <Grid item xs={12}>
                <PlansTable
                  plans={data?.statistics?.plansBrowsed}
                  showCommunities={true}
                  showTimestamps={true}
                />
              </Grid>
            </Grid>
            {/* <Grid item xs={12} md={12} lg={6}>
              This Table is kind of unnessecary with the communities also being displayed in the plans table.
              <CommunitiesTable
                communities={data?.statistics?.communitiesBrowsed}
              />
            </Grid> */}
            <Grid item xs={12} md={12} lg={6}>
              <Grid item xs={12}>
                <Typography variant="h6">Popular Plans</Typography>
              </Grid>
              <Grid item xs={12}>
                <PlansTable
                  plans={data?.statistics?.plansStatistics}
                  showStatistics={true}
                  showTimestamps={false}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Grid item xs={12}>
                <Typography variant="h6">Popular Communities</Typography>
              </Grid>
              <Grid item xs={12}>
                <CommunitiesTable
                  communities={data?.statistics?.communitiesStatistics}
                  showStatistics={true}
                  showTimestamps={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      ),
      notes: '',
    },
    {
      title: 'Registration / Logins',
      date: data?.registrationDate,
      content: (
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <LoginTimesTable
              prospectSignIns={data?.statistics?.signIns || []}
              email={email}
            />
          </Grid>
        </Grid>
      ),
      notes: '',
    },
    {
      title: 'Active Favorites',
      content: (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>Active Favorites</Typography>
          </Grid>
          <Grid item xs={12}>
            <ElevationsTable
              elevations={data?.favorites?.map((fav) => {
                const elevation = fav?.plan?.elevations?.filter(
                  (elev) => elev?.id === fav?.elevationId
                )[0] // get first (only) match
                let bigQueryFavsMatch = null
                let timestamp = fav.lastUpdate
                if (!fav.lastUpdate && elevation) {
                  bigQueryFavsMatch = data?.statistics?.favorites
                    ?.filter(
                      (sFav) =>
                        sFav.planId === elevation.planId &&
                        sFav.elevationId === elevation.id
                    )
                    .map((match) => match.timestamp)
                }
                if (bigQueryFavsMatch && bigQueryFavsMatch.length > 0) {
                  timestamp = Math.max(...bigQueryFavsMatch)
                }
                return {
                  timestamp,
                  elevation: elevation,
                }
              })}
            />
          </Grid>
        </Grid>
      ),
      notes: '',
    },
    {
      title: 'Inventory Tours',
      content: (
        <Grid container spacing={3}>
          {data?.statistics?.appointmentRequests?.length ? (
            <>
              <Grid item xs={12}>
                <Typography>Tour Appointments</Typography>
              </Grid>
              <Grid item xs={12}>
                <SGTAppointmentsTable
                  appointments={data?.statistics?.appointmentRequests}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Typography>No Inventory Tours requested.</Typography>
            </Grid>
          )}
        </Grid>
      ),
      notes: '',
    },
    {
      title: 'My Home Page',
      content: (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>
              Plans viewed on 'My Home Page'. This shows a prospects increased
              interests in a plan
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <PlansTable
              plans={data?.statistics?.brochuresCreated}
              showTimestamps={true}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <SchemesTable schemes={data?.statistics?.selectedColorSchemes} />
          </Grid>
        </Grid>
      ),
      notes: '',
    },
    {
      title: 'Sales Kiosk',
      content: (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>
              Plans viewed on 'Sales Kiosk' and user login times.
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <PlansTable
              dataSource="Camber"
              plans={data?.statistics?.camberPlanElevation}
              showTimestamps={true}
            />
          </Grid>
        </Grid>
      ),
      notes: '',
    },
  ]
  const staticIndex = staticMilestones.length

  const displayMilestones = [...staticMilestones, ...milestones].filter(
    (value) => value
  )

  const editableIndex = (step: number): number =>
    step - staticIndex > 0 ? step - staticIndex : 0

  // Close all modals
  const closeModals = (): void => {
    setEditModalOpen(false)
    setAddMilestoneModalOpen(false)
    setEditMilestoneModalOpen(false)
  }

  const handleStep = (step: number) => (): void => {
    setActiveStep(step)
    onSelectStep && onSelectStep(step)
    setEditIndex(editableIndex(step))
  }

  const deselectSteps = (): void => {
    setActiveStep(displayMilestones.length) // set active step to over index - not selecting any
  }
  /* Handle Open onClick events */
  const handleOpenEditModal = (): void => {
    setEditModalOpen(true)
  }
  const handleOpenAddMilestoneModal = (): void => {
    setAddMilestoneModalOpen(true)
  }
  const handleOpenEditMilestoneModal = (): void => {
    setEditMilestoneModalOpen(true)
  }
  // Generic close modals handle
  const handleClose = (): void => {
    closeModals()
  }

  /* Handle modal Submit / Save */

  // Upsert modal changes and close
  const handleEditClose = (): void => {
    upsertChanges(milestones) // Upsert edit changes
    closeModals()
  }

  const createDeepCopy = (data: Milestone[]): Milestone[] => {
    return JSON.parse(JSON.stringify(data)) as Milestone[]
  }

  const handleAddMilestoneSubmit = (title: string): void => {
    const newMilestone: Milestone = {
      title: title,
      date: new Date(),
      content: null,
      notes: '',
    }
    const newMilestones = createDeepCopy(milestones)
    newMilestones.splice(newMilestones.length, 0, newMilestone)

    upsertChanges(newMilestones)
    closeModals()
  }

  const handleEditMilestoneSubmit = (newMilestone: Milestone): void => {
    const newMilestones = createDeepCopy(milestones)
    newMilestones[editIndex] = {
      ...newMilestone,
    }
    upsertChanges(newMilestones)
    closeModals()
  }

  const handleDeleteMilestone = (step: number): void => {
    deselectSteps()
    const deleteIndex = editableIndex(step)
    const newMilestones = createDeepCopy(milestones)
    newMilestones.splice(deleteIndex, 1)

    upsertChanges(newMilestones)
    closeModals()
  }

  const upsertChanges = async (newMilestones: Milestone[]): Promise<void> => {
    const { data } = await addProspectActivitiesMutation({
      variables: {
        clientName,
        email,
        activities: newMilestones,
      },
    })

    if (data?.addProspectActivities === true) {
      setMilestones(newMilestones)
    } else {
      // TODO: Handle Error, display alert
      const msg = 'Failed to update prospect milestone. Please try again later.'
      handleErrorMessage(msg)
    }
  }

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number
    newIndex: number
  }): void => {
    const newActivites = arrayMove(
      milestones,
      oldIndex,
      newIndex
    ) as Milestone[]
    // Do not set the moved icon as active. Leads to confusing UI
    // setActiveStep(newIndex + staticIndex) // set the moved item as active
    setMilestones(newActivites) // save new state. New state upserted in modal submit handler
  }

  // Fetch prospect activities, also known as milestones.
  const { data: activitiesData } = useGetProspectActivitiesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      clientName: clientName,
      email: email,
    },
  })

  // Hook for mutating prospect activities
  const [addProspectActivitiesMutation] = useAddProspectActivitiesMutation({
    variables: {
      clientName: clientName,
      email: email,
      activities: {},
    },
  })

  useEffect(() => {
    const activities = activitiesData?.getProspectActivities?.activities
    if (activities && activities.length > 0) {
      setMilestones(activities)
    }
  }, [activitiesData])

  if (loading) {
    return (
      <Paper className={classes.container}>
        <Typography variant="h5" className={classes.title}>
          {COMPONENT_TITLE}
        </Typography>
        <Typography>Loading...</Typography>
      </Paper>
    )
  }

  const SortableItem = SortableElement(
    ({ value, index }: { value: Milestone; index: number }) => (
      // ListItem is being dragged for resorting.
      // It should always display above other thingson screen, including modals
      <ListItem onClick={handleStep(index)} style={{ zIndex: 99999999 }}>
        <ListItem className={classes.editList}>
          <ListItemText>{value.title}</ListItemText>
        </ListItem>
      </ListItem>
    )
  )
  const SortableList = SortableContainer(
    ({ items }: { items: Milestone[] }) => {
      return (
        <List dense={true}>
          {items.map((value, index) => (
            <SortableItem
              key={`item-${value.title}-${index}`}
              index={index}
              value={value}
            />
          ))}
        </List>
      )
    }
  )

  return (
    <ProspectPiplelineStyled>
      <EditModal
        open={editModalOpen}
        onClose={handleEditClose}
        sortable={<SortableList items={milestones} onSortEnd={onSortEnd} />}
        unEditable={staticMilestones}
      />

      <AddMilestoneModal
        open={addMilestoneModalOpen}
        onClose={handleClose}
        onSubmit={handleAddMilestoneSubmit}
      />

      <EditMilestoneModal
        value={displayMilestones[activeStep]}
        step={activeStep}
        open={editMilestoneModalOpen}
        onClose={handleClose}
        onSubmit={handleEditMilestoneSubmit}
        onDelete={handleDeleteMilestone}
      />

      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
          <ProspectProfile
            data={data}
            loading={loading}
            size="small"
          ></ProspectProfile>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={errorMessageOpen}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={(): void => {
                    setErrorMessageOpen(false)
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <AlertTitle>Error</AlertTitle>
              {errorMessage}
            </Alert>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.root}>
            <Paper className={classes.container}>
              <Typography variant="h5" className={classes.title}>
                {COMPONENT_TITLE}
              </Typography>
              <Grid container>
                <Grid container justifyContent="flex-end">
                  <Button
                    className={classes.button}
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={handleOpenEditModal}
                  >
                    Edit
                  </Button>
                  <Button
                    className={classes.button}
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={handleOpenAddMilestoneModal}
                  >
                    Add Milestone
                  </Button>
                </Grid>
                {/* Stepper  */}
                <div className={classes.root}>
                  <Stepper
                    nonLinear
                    activeStep={activeStep}
                    orientation="vertical"
                  >
                    {displayMilestones.map((milestone, index) => (
                      <Step
                        key={`${milestone.title}-${index}`}
                        expanded={false}
                      >
                        <StepButton
                          onClick={handleStep(index)}
                          icon={<StepIcon active icon={index} />}
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                          >
                            <Grid item>
                              <StepLabel icon={<></>}>
                                <Typography>
                                  {milestone.title}
                                  <span
                                    style={{
                                      marginLeft: '1em',
                                      fontSize: '0.875rem',
                                    }}
                                  >
                                    {milestone?.date
                                      ? dateFns.format(
                                          new Date(milestone?.date),
                                          'MMM do yyyy'
                                        )
                                      : null}
                                  </span>
                                </Typography>
                              </StepLabel>
                            </Grid>
                            <Grid item>
                              {staticIndex <= index &&
                              !milestone.plans &&
                              index === activeStep ? (
                                <IconButton
                                  onClick={handleOpenEditMilestoneModal}
                                  size="small"
                                >
                                  <EditIcon fontSize="small"></EditIcon>
                                </IconButton>
                              ) : null}
                            </Grid>
                          </Grid>
                        </StepButton>
                        <StepContent>
                          {milestone.plans && (
                            <div style={{ display: 'grid' }}>
                              {milestone.plans.map((plan) => (
                                <Typography
                                  display="inline"
                                  variant="subtitle2"
                                  style={{ marginLeft: '1em' }}
                                >
                                  {`${plan.planName} ${plan.elevationCaption} at ${plan.communityName}`}
                                </Typography>
                              ))}
                            </div>
                          )}
                          {milestone.content || milestone.inquiry}
                          <Typography>{milestone.notes}</Typography>
                          <StepConnector
                            classes={{ line: classes.line }}
                          ></StepConnector>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </div>
              </Grid>
            </Paper>
          </div>
        </Grid>
      </Grid>
    </ProspectPiplelineStyled>
  )
}

export default ProspectPipleline
