import React, { useEffect, useState } from 'react'
import {
  useLeadTimeRankingQuery,
  LeadTimeRanking,
} from '../../../graphql/gen-types'
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  Area,
  CartesianGrid,
} from 'recharts'
import ComponentCard from 'components/common/layout/ComponentCard'
import { formatDateTime } from '../../../utils/functions'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { verifyUserAuthError } from '../../../utils/authorizationHelpers'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import { Unauthorized } from 'components/auth/Unauthorized'

const getMonthsAgo = (month: number, dateToCompare: Date): string => {
  return new Date(
    dateToCompare.getFullYear(),
    dateToCompare.getMonth() - month,
    dateToCompare.getDate()
  ).toLocaleDateString()
}

interface LeadTimeRankingProps {
  clientName: string
  leadEmail: string
}

function LeadTimeRankingChart({
  clientName,
  leadEmail,
}: LeadTimeRankingProps): JSX.Element {
  const [timeFrame, setTimeFrame] = useState<string>('month')
  const [rankings, setRankings] = useState<LeadTimeRanking[]>([])
  const [overallScore, setOverallScore] = useState(0)
  const { data, loading, error } = useLeadTimeRankingQuery({
    variables: {
      clientName,
      leadEmail,
      timeFrame,
    },
    skip: !clientName || !leadEmail,
  })

  const appendStartDate = (currentRankings: LeadTimeRanking[]) => {
    let monthAgo: string

    if (timeFrame === 'month') {
      monthAgo = getMonthsAgo(1, new Date(Date.now()))
    } else if (timeFrame === '3months') {
      monthAgo = getMonthsAgo(3, new Date(Date.now()))
    } else {
      monthAgo = getMonthsAgo(6, new Date(Date.now()))
    }

    // check if verify first date exist.
    if (
      currentRankings.length &&
      currentRankings[0].date &&
      monthAgo !== new Date(currentRankings[0].date).toLocaleDateString()
    ) {
      const newRankings = currentRankings
      newRankings.unshift({
        date: monthAgo,
        score: 0,
      })
      setRankings(newRankings)
    }
  }

  useEffect(() => {
    const rankingDataRaw = data?.leadTimeRanking || []
    const rankingData = rankingDataRaw.map((ranking) => ({
      score: ranking?.score ? Math.floor(ranking?.score * 100) : 0,
      date: formatDateTime(ranking.date, 'MM/dd/yyyy'),
    }))

    // We want to transfer recieved array to array where we have only every 7th day
    // or 30th for halfYear mode.
    const reversedArray = rankingData.slice().reverse()
    const step = timeFrame === 'halfYear' ? 30 : 7
    const stepsCount = Math.floor(reversedArray.length / step)
    const trimmedData = []
    // Use calculated steps count to push days into new array.
    // undefined check is for case of empty array.
    for (let i = 0; i <= stepsCount; i++) {
      if (typeof reversedArray[i * step] != 'undefined') {
        trimmedData.push(reversedArray[i * step])
      }
    }
    // In case of halfYear mode push last element aswell,
    // because a big interval could be skiped.
    if (
      timeFrame === 'halfYear' &&
      step * stepsCount < reversedArray.length - 1
    ) {
      trimmedData.push(reversedArray[reversedArray.length - 1])
    }
    if (rankingDataRaw.length > 0) {
      setOverallScore(
        Math.floor((rankingDataRaw[0].overallScore || 0) * 100) as number
      )
    }
    appendStartDate(trimmedData.reverse())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data?.leadTimeRanking)])

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized
          message={error.toString()}
          imageName={'ColorTrends'}
        ></Unauthorized>
      )
    }
    console.error(error)
    return <div>Error while loading color trends</div>
  }

  const componentTitle = (
    <Grid container alignItems="center" spacing={2} justifyContent="flex-start">
      <Grid item xs={12} md={4} lg={4}>
        <InputLabel id="time-frame-select">Time frame:</InputLabel>
        <Select
          variant="standard"
          id="time-frame-select"
          value={timeFrame}
          onChange={(e) => {
            setTimeFrame(e.target.value as string)
          }}
        >
          <MenuItem value={'month'}>Month</MenuItem>
          <MenuItem value={'3months'}>Three Months</MenuItem>
          <MenuItem value={'halfYear'}>Six months</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12} md={8} lg={8}>
        <Typography variant={'h5'}>Engagement Score over Time</Typography>
      </Grid>
    </Grid>
  )

  return (
    <ComponentCard
      title={componentTitle}
      result={{ data: rankings, error, loading }}
      skeletonHeight={400}
    >
      <Grid container>
        <Grid item xs={12} md={9} lg={9}>
          <ResponsiveContainer width="100%" height={400}>
            <AreaChart width={500} height={400} data={rankings}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="score"
                stroke="#8884d8"
                fill="#8884d8"
              />
            </AreaChart>
          </ResponsiveContainer>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Typography variant={'h5'}>Overall rating</Typography>
          <Typography variant={'h1'}>{overallScore}</Typography>
        </Grid>
      </Grid>
    </ComponentCard>
  )
}

export default LeadTimeRankingChart
export const testingVariables = { getMonthsAgo }
