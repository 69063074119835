import React, { useEffect, useRef, useState } from 'react'
import {
  PaginatedPlansQuery,
  Plan,
  Prospect,
  usePaginatedPlansLazyQuery,
  PaginatedPlansQueryVariables,
} from 'graphql/gen-types'
import BoundaryObserver from '../layout/BoundaryObserver'
import { PlanCard } from './PlanCard'
import { FavoriteCard, ProspectPlan } from './FavoriteCard'
import Grid from '@mui/material/Grid'
import { SortOptions } from 'components/common/custom-inputs/SortSelect'
import { sortPlansByMetric } from './planHelpers'
import Spinner from 'components/common/misc/Spinner'
import { classes, Root } from './PlansGrid.styles'
export type { ProspectPlan } // bubble type (interface) to higher component
type PlanTypes = Plan | ProspectPlan

interface PlansGridProps {
  hideNotBrowsedBanner?: boolean
  prefetchedPlans?: PlanTypes[]
  prospect?: Prospect | null
  selectedMetric: SortOptions
  loading?: boolean
  search?: string
  paginatedPlansArgs?: PaginatedPlansQueryVariables
  isFavorites?: boolean
}

const PlansGrid = ({
  hideNotBrowsedBanner,
  search,
  selectedMetric,
  prefetchedPlans,
  prospect,
  loading,
  paginatedPlansArgs,
  isFavorites,
}: PlansGridProps): JSX.Element => {
  const parentContainerRef = useRef(null)
  const [plans, setPlans] = useState<PlanTypes[] | undefined>(
    prefetchedPlans?.sort((planA, planB) =>
      sortPlansByMetric(planA, planB, selectedMetric)
    )
  )
  const [done, setDone] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)

  /**
   * Retrieve and set paginated plans
   * Query uses fetchMore to have pagination.
   */

  const [
    getPaginatedPlans,
    { data, loading: dataLoading, error, fetchMore },
  ] = usePaginatedPlansLazyQuery({
    variables: paginatedPlansArgs,
  })

  /**
   * Filters by search term and orders by popularity
   */
  const filteredPlans = (plans: Plan[]): PlanTypes[] => {
    return plans?.filter(
      (plan: Plan) =>
        plan?.name?.toLowerCase().includes(search?.toLowerCase() || '') || false
    )
  }

  useEffect(() => {
    if (data?.paginatedPlans?.plans && !prefetchedPlans) {
      const sortedPlans = filteredPlans(
        data?.paginatedPlans?.plans as Plan[]
      )?.sort((planA, planB) => sortPlansByMetric(planA, planB, selectedMetric))
      setPlans(sortedPlans)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedMetric, search])

  useEffect(() => {
    if (!prefetchedPlans) {
      getPaginatedPlans()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setPlans(prefetchedPlans)
  }, [prefetchedPlans])

  if (error) {
    return <div>{error.toString()}</div>
  }

  const loadMore = (): void => {
    if (done) {
      return
    }
    if (fetchMore) {
      setLoadingMore(true)
      fetchMore({
        variables: {
          ...paginatedPlansArgs,
          offset: plans?.length || 0,
        },
        updateQuery: (
          previousResult: PaginatedPlansQuery,
          { fetchMoreResult }
        ) => {
          fetchMoreResult = fetchMoreResult as PaginatedPlansQuery
          if (
            fetchMoreResult &&
            fetchMoreResult.paginatedPlans?.plans?.length === 0
          ) {
            setDone(true)
            setLoadingMore(false)
            return previousResult
          }
          const newPlans: PlanTypes[] = [
            ...((previousResult?.paginatedPlans?.plans as PlanTypes[]) || []),
            ...((fetchMoreResult?.paginatedPlans?.plans as PlanTypes[]) || []),
          ]

          // this object that is returned must be the same shape
          // as what is stored in Apollo's cache
          const retval = Object.assign({}, previousResult, {
            paginatedPlans: {
              __typename: 'PaginatedPlans',
              cursor: {
                __typename: 'PaginateCursor',
                offset: newPlans.length || 0,
              },
              plans: newPlans,
            },
          })
          setLoadingMore(false)
          return retval
        },
      })
    }
  }

  // r e n d e r
  return (
    <Root className={classes.root} ref={parentContainerRef}>
      {dataLoading ? (
        <Spinner message={'Loading...'} />
      ) : (
        <Grid container justifyContent="center" spacing={2}>
          {plans?.map((plan: PlanTypes, i: number) => {
            // Return null if plan has no elevations
            if (!plan?.elevations?.length) {
              return null
            }

            // Render plan card item
            return (
              <Grid item key={`plan-${plan.id}-${i}`}>
                {/* If prospect is passed, display favorites instead of plans. */}
                {prospect ? (
                  <FavoriteCard
                    hideNotBrowsedBanner={hideNotBrowsedBanner}
                    plan={plan as ProspectPlan}
                    prospect={prospect}
                    frontPageMetric={selectedMetric}
                  />
                ) : (
                  <PlanCard
                    plan={plan as ProspectPlan}
                    frontPageMetric={selectedMetric}
                  />
                )}
              </Grid>
            )
          })}
          <Grid item xs={12}>
            {loadingMore && <Spinner message={'Loading...'} />}
          </Grid>
          <BoundaryObserver
            root={parentContainerRef.current}
            loading={loading || dataLoading}
            loadMore={loadMore}
            done={false}
          />
        </Grid>
      )}
    </Root>
  )
}

export default PlansGrid
