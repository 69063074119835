import React, { useReducer } from 'react'
import BaseLayout from 'components/common/layout/BaseLayout'
import { AnalyticsStore, initialState } from 'store/analyticsStore'
import { analyticsReducer as AppReducer } from 'store/reducers'
import { useMatch } from 'react-router-dom'

function AnalyticsApp(): JSX.Element | null {
  const [analyticsState, dispatch] = useReducer(AppReducer, initialState)
  const analyticsMatchPath = useMatch('analytics/client/:clientName/*')
  const value = { analyticsState, dispatch }

  if (!analyticsMatchPath) {
    return null
  }

  return (
    <AnalyticsStore.Provider value={value}>
      <BaseLayout />
    </AnalyticsStore.Provider>
  )
}

export default AnalyticsApp
