import { gql } from '@apollo/client'

export const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation sendPasswordResetEmail($email: String!, $source: String) {
    sendPasswordResetEmail(email: $email, source: $source)
  }
`

export const SET_PWD_MUTATION = gql`
  mutation setPassword(
    $email: String!
    $password: String!
    $clientName: String!
    $source: String
  ) {
    setPassword(
      email: $email
      password: $password
      clientName: $clientName
      source: $source
    )
  }
`
