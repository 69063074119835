import React, { ReactNode, SyntheticEvent } from 'react'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { useTheme } from '@mui/material/styles'
import { IndustrySessionTrends } from 'graphql/gen-types'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Label,
} from 'recharts'
import DateFnsAdapter from '@date-io/date-fns'
import { CustomYAxisLabel } from 'components/common/charts/custom/CustomAxisLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import useMediaQuery from '@mui/material/useMediaQuery'
import { SessionTrendsContainer } from './SessionTrendsPanel.styles'
import { styled } from '@mui/material/styles'
import FormControl from '@mui/material/FormControl'
import { useState } from 'react'
import { Typography } from '@mui/material'

const dateFns = new DateFnsAdapter()

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
// Maps gql field names to readable names
const keySessionDisplayName: Record<string, string> = {
  sessions: 'Sessions',
  visitors: 'Visitors',
  registered: 'Registered Users',
  returnVisitors: 'Returning Visitors',
  newVisitors: 'New Visitors',
  averageDuration: 'Average Duration',
  bounceRate: 'Bounce Rate',
}

type SessionTrendsPanelProps = {
  children?: ReactNode | ReactNode[] | string | JSX.Element | null
  data: unknown[]
  isIndustry?: boolean | false
}

const PREFIX = 'SessionsMetricSelect'
const classes = {
  formControl: `${PREFIX}-formControl`,
  formControlMobile: `${PREFIX}-formControlMobile`,
  typography: `${PREFIX}-typography`,
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`&.${classes.formControl}`]: {
    margin: `0px ${theme.spacing(1)}`,
    float: 'right',
  },
  [`&.${classes.formControlMobile}`]: {
    margin: `0px ${theme.spacing(1)}`,
    display: 'inline-block',
  },
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  [`&.${classes.typography}`]: {
    fontWeight: 500,
    padding: '16px',
    marginLeft: '8px',
    textAlign: 'left',
    fontSize: '1.4993rem',
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    color: theme.palette.primary.main,
    letterSpacing: '0em',
  },
}))

export default function SessionTrendsPanel({
  data,
  isIndustry,
}: SessionTrendsPanelProps): JSX.Element {
  const [tabValue, setTabValue] = React.useState(0)
  const [selectValue, setSelectValue] = React.useState('sessions')
  const theme = useTheme()
  const smallSize = useMediaQuery(theme.breakpoints.down('md'))

  const keyDisplayName: Record<string, string> = {
    ...keySessionDisplayName,
  }

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setSelectValue(event.target.value as string)
  }

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: number
  ): void => {
    setTabValue(newValue)
  }

  // Get formatted data and render
  const [selectedData, setSelectedData] = useState('daily')

  const getData = (value: string) => {
    switch (value) {
      case 'daily':
        return getFormattedData(data as IndustrySessionTrends[])
      case 'monthly':
        return getFormattedDataByMonthOrYear(
          data as IndustrySessionTrends[],
          'MONTH'
        )
      case 'yearly':
        return getFormattedDataByMonthOrYear(
          data as IndustrySessionTrends[],
          'YEAR'
        )
      default:
        return []
    }
  }

  return (
    <>
      {smallSize ? (
        <>
          <StyledTypography className={classes.typography}>
            Session Trends
          </StyledTypography>
          <StyledFormControl
            className={classes.formControlMobile}
            variant="standard"
          >
            <Select
              style={{
                width: '120px',
              }}
              variant="standard"
              labelId="metric-select-label"
              id="metric-select"
              onChange={(event) => setSelectedData(event.target.value)}
              value={selectedData}
            >
              <MenuItem value={'daily'}>Daily</MenuItem>
              <MenuItem value={'monthly'}>Monthly</MenuItem>
              <MenuItem value={'yearly'}>Yearly</MenuItem>
            </Select>
            <Select
              variant="standard"
              value={selectValue}
              onChange={handleSelectChange}
              style={{
                marginBottom: theme.spacing(2),
                marginLeft: theme.spacing(2),
                width: '120px',
              }}
            >
              {Object.entries(keyDisplayName).map((entry, i) => {
                const [key, name] = entry
                return (
                  <MenuItem
                    value={key}
                    key={`sessions-trends-menu-item${key}-${i}`}
                  >
                    {name}
                  </MenuItem>
                )
              })}
            </Select>
          </StyledFormControl>
          <SessionsSummaryTrendsBarChart
            data={getData(selectedData)}
            val={selectValue}
            name={keyDisplayName[selectValue]}
            isIndustry={isIndustry}
          />
        </>
      ) : (
        <>
          <StyledTypography className={classes.typography}>
            Session Trends
            <StyledFormControl
              className={classes.formControl}
              variant="standard"
            >
              <Select
                style={{
                  width: '115px',
                }}
                variant="standard"
                labelId="metric-select-label"
                id="metric-select"
                onChange={(event) => setSelectedData(event.target.value)}
                value={selectedData}
              >
                <MenuItem value={'daily'}>Daily</MenuItem>
                <MenuItem value={'monthly'}>Monthly</MenuItem>
                <MenuItem value={'yearly'}>Yearly</MenuItem>
              </Select>
            </StyledFormControl>
          </StyledTypography>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab label="Sessions" />
            <Tab label="Visitors" />
            <Tab label="Registered" />
            <Tab label="Returning" />
            <Tab label="New Visitors" />
            <Tab label="Duration" />
            <Tab label="Bounce Rate" />
          </Tabs>
          {Object.entries(keyDisplayName).map((entry, i) => {
            const [key, name] = entry
            return (
              <TabPanel key={key} value={tabValue} index={i}>
                <SessionsSummaryTrendsBarChart
                  data={getData(selectedData)}
                  val={key}
                  name={name}
                  isIndustry={isIndustry}
                />
              </TabPanel>
            )
          })}
        </>
      )}
    </>
  )
}

interface TrendsChartProps {
  data: IndustrySessionTrends[]
  val: string
  name: string
  isIndustry?: boolean | false
}

function SessionsSummaryTrendsBarChart({
  data,
  val,
  name,
}: TrendsChartProps): JSX.Element {
  const theme = useTheme()
  const stroke =
    theme.palette.primary[theme.palette.mode === 'dark' ? 'light' : 'dark']
  const fill = theme.palette.primary.main

  const industryFill = 'midnightblue'

  const keyDisplayName: Record<string, string> = {
    ...keySessionDisplayName,
  }

  return (
    <SessionTrendsContainer container justifyContent="center">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          margin={{ top: 0, left: 5, right: 0, bottom: 0 }}
          data={data}
        >
          <svg>
            <defs>
              <linearGradient id="industryGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={industryFill} stopOpacity={0.33} />
                <stop
                  offset="95%"
                  stopColor={industryFill}
                  stopOpacity={0.25}
                />
              </linearGradient>
              <linearGradient id="primaryGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={fill} stopOpacity={0.5} />
                <stop offset="95%" stopColor={fill} stopOpacity={0.25} />
              </linearGradient>
            </defs>
          </svg>
          <XAxis
            dataKey="date"
            tick={{
              fill: stroke,
            }}
          />
          <YAxis
            tick={{
              fill: stroke,
            }}
          >
            <Label
              value={name}
              stroke={stroke}
              content={<CustomYAxisLabel />}
            />
          </YAxis>
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip labelStyle={{ color: 'black' }} />
          <Area
            name={keyDisplayName[val]}
            type="monotone"
            dataKey={val}
            stroke={fill}
            fillOpacity={1}
            fill="url(#primaryGradient)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </SessionTrendsContainer>
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: number
  value: number
}

function TabPanel(props: TabPanelProps): JSX.Element {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

function getFormattedData(
  data: IndustrySessionTrends[]
): IndustrySessionTrends[] {
  // Copy over values to formatted data array
  const formattedData: IndustrySessionTrends[] = []
  data.forEach((summ, i) => {
    formattedData[i] = { ...summ }
  })
  // Sort by date
  formattedData.sort((a, b) => {
    return new Date(a.date).getTime() - new Date(b.date).getTime()
  })
  // Format datetime into `Month Day`
  formattedData.forEach((summ, i) => {
    const dateToFormat =
      summ.date && typeof summ.date === 'string'
        ? summ.date.replace(/-/g, '/').replace(/T.+/, '')
        : summ.date
    const date = new Date(dateToFormat)
    const monthIndex = date.getMonth()
    const day = date.getDate()
    formattedData[i].date = `${months[monthIndex]} ${day}`
  })
  return formattedData
}

function getFormattedDataByMonthOrYear(
  data: IndustrySessionTrends[],
  monthOrYear: string
): IndustrySessionTrends[] {
  // Copy over values to formatted data array
  const formattedDataByMonthOrYear: IndustrySessionTrends[] = []
  data.forEach((row): void => {
    const date = row?.date || null
    const sessions = row?.sessions || 0
    const visitors = row?.visitors || 0
    const registered = row?.registered || 0
    const returnVisitors = row?.returnVisitors || 0
    const newVisitors = row?.newVisitors || 0
    const sumAverageDuration = row?.averageDuration || 0
    const sumBounceRate = row?.bounceRate || 0
    const day = 15 // set day to be 15th. Helps resolve issues with ISO dates.
    const [year, month] = date?.toString().split('-') || []
    const bounceRate = 0
    const averageDuration = 0
    if (date && year && month && day) {
      const dateObj = new Date(`${year}-${month}-${day}`)
      formattedDataByMonthOrYear.push({
        date:
          monthOrYear === 'MONTH'
            ? dateFns.format(dateObj, 'MMM yyyy')
            : dateFns.format(dateObj, 'yyyy'),
        sessions,
        visitors,
        registered,
        returnVisitors,
        newVisitors,
        sumAverageDuration,
        sumBounceRate,
        bounceRate,
        averageDuration,
      })
    }
  })
  return Object.values(
    formattedDataByMonthOrYear.reduce(
      (
        obj,
        {
          date,
          sessions,
          visitors,
          registered,
          returnVisitors,
          newVisitors,
          sumAverageDuration,
          sumBounceRate,
        }
      ) => {
        obj[date] = obj[date] || {
          date,
          sessions: 0,
          visitors: 0,
          registered: 0,
          returnVisitors: 0,
          newVisitors: 0,
          sumAverageDuration: 0,
          sumBounceRate: 0,
          count: 0,
          bounceRate: 0,
          averageDuration: 0,
        }
        obj[date].sessions += sessions
        obj[date].visitors += visitors
        obj[date].registered += registered
        obj[date].returnVisitors += returnVisitors
        obj[date].newVisitors += newVisitors
        obj[date].sumAverageDuration += sumAverageDuration
        obj[date].sumBounceRate += sumBounceRate
        obj[date].count += 1
        obj[date].averageDuration = (
          obj[date].sumAverageDuration / obj[date].count
        ).toFixed(2)
        obj[date].bounceRate = (
          obj[date].sumBounceRate / obj[date].count
        ).toFixed(2)
        return obj
      },
      {}
    )
  )
}
