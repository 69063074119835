import React, { useEffect, useState } from 'react'
import { getImagePrefix, getPlanSpecs } from '@anewgo/utils'
import { Community, Elevation, Plan } from 'graphql/gen-types'

import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { SortOptions } from 'components/common/custom-inputs/SortSelect'
import { CommunityPlanElevation } from '../../leads/prospect-page/dialogs/ProspectPlanSuggestionsDialog'
import { FlipCard } from './FlipCard'
import { MultiOptionAutoComplete } from '../custom-inputs/MultiOptionsAutocomplete'

const MAX_PLANCARD_MEDIA_HT = 200
export interface PlanElevationCardProps {
  plan: Plan
  elevation: Elevation
  frontPageMetric: SortOptions
  selectPlans: (plans: CommunityPlanElevation[]) => void
  deselectPlans: (plans: CommunityPlanElevation[]) => void
}

export const PlanElevationCard: React.FC<PlanElevationCardProps> = ({
  plan,
  elevation,
  frontPageMetric: metric,
  selectPlans,
  deselectPlans,
}) => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('md'))
  const [metricValue, setMetricValue] = useState<number>(0)
  const [communityElevations, setCommunityElevations] = useState<
    CommunityPlanElevation[]
  >([])
  const [selectedCommunities, setSelectedCommunities] = useState<Community[]>(
    []
  )

  const communities =
    (elevation?.communities?.filter(
      (community) => community?.active
    ) as Community[]) || []

  useEffect(() => {
    switch (metric) {
      case SortOptions.Frequency: {
        setMetricValue(plan?.popularityMetrics?.byFrequency || 0)
        break
      }
      case SortOptions.Duration: {
        setMetricValue(plan?.popularityMetrics?.byDuration?.total || 0)
        break
      }
      case SortOptions.Popularity: {
        setMetricValue(plan?.popularityMetrics?.byPopularity?.popularity || 0)
        break
      }
      case SortOptions.MyHomeHits: {
        setMetricValue(plan?.popularityMetrics?.byPopularity?.brochure || 0)
        break
      }
      case SortOptions.CtaHits: {
        setMetricValue(plan?.popularityMetrics?.byPopularity?.cta || 0)
        break
      }
      case SortOptions.Favorites: {
        setMetricValue(plan?.popularityMetrics?.byBrowsingData?.favorites || 0)
        break
      }
      case SortOptions.LeadFavorites: {
        setMetricValue(
          plan?.popularityMetrics?.byBrowsingData?.leadFavorites || 0
        )
        break
      }
      case SortOptions.LeadViews: {
        setMetricValue(plan?.popularityMetrics?.byBrowsingData?.leadViews || 0)
        break
      }
    }
  }, [plan, metric])

  // Get plan values
  const { bed, bath, size, cost } = getPlanSpecs([plan])
  const planTitle = plan?.displayName || plan?.name || ''
  const elevationTitle = elevation.caption || ''
  const title = `${planTitle} ${elevationTitle}`
  const thumb = elevation?.thumb || ''
  const img = `${getImagePrefix(
    plan.clientName,
    `c_scale,h_${MAX_PLANCARD_MEDIA_HT}`
  )}/${thumb}`

  const handleCommunitySelect = (communities: Community[]) => {
    if (communities.length === 0) {
      deselectPlans(communityElevations)
      setSelectedCommunities(communities)
      return
    }
    const selectedCommPlanElevations = communities.map((community) => ({
      planName: plan.name || '',
      communityId: community.id,
      communityName: community.name || '',
      elevationCaption: elevation?.caption || '',
      elevationId: elevation.id,
      planId: plan.id,
      elevationThumb: elevation?.thumb || '',
      bed: plan.bed,
      bath: plan.bath,
      size: plan.size,
      uniqueId: `${plan.id}:${community.id}:${elevation?.caption}`,
    }))
    selectPlans(selectedCommPlanElevations)
    setCommunityElevations(selectedCommPlanElevations)
    setSelectedCommunities(communities)
  }

  const shared = [
    <Typography variant="subtitle1" noWrap={true} key={`plan-title-${plan.id}`}>
      {title}
    </Typography>,
    <Typography variant="caption" key={`plan-cost-${plan.id}`}>
      {cost}
    </Typography>,
    <Typography variant="caption" component="h6" key={`plan-bed-${plan.id}`}>
      {bed}
    </Typography>,
    <Typography variant="caption" component="h6" key={`plan-bath-${plan.id}`}>
      {bath}
    </Typography>,
    <Typography variant="caption" component="h6" key={`plan-size-${plan.id}`}>
      {size}
    </Typography>,
    <Typography
      variant="caption"
      align="center"
      key={`plan-metric-${plan.id}`}
    >{`${metric}: ${metricValue}`}</Typography>,
  ]

  const backSide = (
    <MultiOptionAutoComplete
      inputLabel={'Select Community'}
      options={communities}
      selectedOptions={selectedCommunities}
      setSelectedOptions={handleCommunitySelect}
      optionLabelAccessor={(community): string => community.name || 'unknown'}
      fullWidth
    />
  )

  return (
    <>
      <FlipCard
        frontSide={<></>}
        backSide={backSide}
        shared={shared}
        media={
          !sm
            ? {
                title: title,
                url: img,
                altText: 'Plan',
                frontSideOnly: true,
              }
            : undefined
        }
        data-testid="PlanElevationCard"
        planElevationCardStyles
      />
    </>
  )
}
