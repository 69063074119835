import React, { useContext, useState } from 'react'
import { AppStore } from 'store'
import { CommonQueryFilter, PopularityMetric } from 'graphql/gen-types'
import { Root, classes } from './IndustryTrends.styles'
import { AnalyticsStore } from 'store/analyticsStore'

import IndustrySessionTrends from 'components/industry-trends/IndustrySessionTrends'
import Grid from '@mui/material/Grid'
import IndustrySpecifications from '../../components/industry-trends/IndustrySpecifications'
import FloorPlanOptionTable from 'components/industry-trends/IndustryFloorPlanOptionsTrends'
import IndustryTrendsFilter from 'components/analytics/industry-trends-filter/IndustryTrendsFilter'
import ComponentCard from 'components/common/layout/ComponentCard'
import IndustryMaterialColors from 'components/industry-trends/IndustryMaterialColors'
import IndustrySessionsSummary from 'components/industry-trends/IndustrySessionsSummary'
import { useCommonQueryFilter } from '../../components/industry-trends/hooks/useCommonQueryFilter'
import { ITLocationSelection } from '../../components/analytics/locations-filters/Shared'

// Minimum number of trends to display. If more than 5 communities are selected, display more, else minimum.
const MIN_TRENDS_DISPLAY = 5

function IndustryTrends(): JSX.Element {
  const { appState } = useContext(AppStore)
  const { selectedClient } = appState
  const { analyticsState } = useContext(AnalyticsStore)
  const { startDate, endDate } = analyticsState
  const clientName = selectedClient?.altName || ''

  // SELECTIONS STATE
  const [selection, setSelection] = useState<ITLocationSelection>({
    us: {},
    can: {},
  })

  const commonFilter: CommonQueryFilter = useCommonQueryFilter({
    startDate,
    endDate,
    clientName,
    selection,
    limit: MIN_TRENDS_DISPLAY,
    startTime: startDate,
    endTime: endDate,
  })

  const locationsFilterComp = (
    <IndustryTrendsFilter selection={selection} setSelection={setSelection} />
  )

  const popularityFilter: CommonQueryFilter = {
    ...commonFilter,
    metric: PopularityMetric.Frequency,
  }

  return (
    <Root>
      <React.Fragment>
        <Grid container>
          <Grid item xs={12}>
            {locationsFilterComp}
          </Grid>
          <Grid item xs={12} className={classes.root}>
            <Grid container spacing={4} className={classes.componentContainer}>
              <Grid item xs={12}>
                <IndustrySessionsSummary filter={commonFilter} />
              </Grid>
              <Grid item xs={12}>
                <ComponentCard
                  title={'Industry Floorplan Option Trends'}
                  skeletonHeight={600}
                >
                  <Grid
                    container
                    spacing={4}
                    className={classes.FloorPlanOptionChart}
                  >
                    <Grid item xs={12} lg={6}>
                      <FloorPlanOptionTable
                        filter={commonFilter}
                        industryTrends={true}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FloorPlanOptionTable
                        filter={commonFilter}
                        industryTrends={false}
                      />
                    </Grid>
                  </Grid>
                </ComponentCard>
              </Grid>
              <Grid item xs={12}>
                <IndustrySessionTrends filter={commonFilter} />
              </Grid>
              <Grid item xs={12}>
                <IndustrySpecifications filter={popularityFilter} />
              </Grid>
              <Grid item xs={12}>
                <IndustryMaterialColors filter={commonFilter} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    </Root>
  )
}

export default IndustryTrends
