import { styled } from '@mui/material/styles'
import Modal from '@mui/material/Modal'
const PREFIX = 'TokenExpiredModal'

export const classes = {
  modal: `${PREFIX}-modal`,
  paper: `${PREFIX}-paper`,
}

export const StyledModal = styled(Modal)(() => ({
  [`&.${classes.modal}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export const StyledDiv = styled('div')(({ theme }) => ({
  [`&.${classes.paper}`]: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    zIndex: 10000,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))
