import React from 'react'

import {
  CommonQueryFilter,
  usePopularElevationSpecsQuery,
} from 'graphql/gen-types'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import ComponentCard from 'components/common/layout/ComponentCard'
import ElevationSpecificationsPanel from './ElevationSpecificationsPanel'

const COMPONENT_TITLE = 'Popular Elevation Specifications'
const COMPONENT_SKELETON_HEIGHT = 500

interface PopularElevationSpecsProps {
  filter: CommonQueryFilter
}

// Functional Component
export default function ElevationSpecifications(
  props: PopularElevationSpecsProps
): JSX.Element {
  const { data, loading, error } = usePopularElevationSpecsQuery({
    variables: {
      filter: props.filter,
    },
  })

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized
          message={error.toString()}
          imageName="PopularElevationSpecsDisplay"
        />
      )
    }
    return <div>{error.toString()}</div>
  }

  return (
    <ComponentCard
      title={COMPONENT_TITLE}
      skeletonHeight={COMPONENT_SKELETON_HEIGHT}
      result={{ loading, error }}
    >
      <ElevationSpecificationsPanel data={data?.popularElevationSpecs} />
    </ComponentCard>
  )
}
