import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useCreateStripeAccountMutation } from '../../../graphql/gen-types'
import { AppStore } from '../../../store'
import LoadingButton from '../../common/custom-inputs/LoadingButton'
import { useEventTracker } from '../../../hooks/tracking'
import { ReservationTrackEvents } from '../reservationTrackEvents'

const PREFIX = 'ReservationIntegrationStripeButton'

const classes = {
  stripeButton: `${PREFIX}-stripeButton`,
  stripeConnectedText: `${PREFIX}-stripeConnectedText`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.stripeButton}`]: {
    backgroundColor: ({ stripeAccountId, stripeAccountReady }) =>
      stripeAccountId && !stripeAccountReady
        ? theme.palette.warning.dark
        : '#5757f9',
    textTransform: 'none',
  },

  [`& .${classes.stripeConnectedText}`]: {
    display: 'block',
    margin: '2px 0 0 4px',
    color: theme.palette.success.main,
  },
}))

const getStripeButtonTitle = (
  stripeAccountId: string | null | undefined,
  stripeAccountReady: boolean | undefined
) => {
  // If user does not have account
  if (!stripeAccountId && !stripeAccountReady) {
    return (
      <>
        Connect with <strong>Stripe</strong>
      </>
    )
  }

  // If user has account but not finished on boarding form
  if (stripeAccountId && !stripeAccountReady) {
    return (
      <>
        Finish <strong>Stripe</strong> set up
      </>
    )
  }

  // If user has account and finished on boarding form
  // Can adjust entered data
  if (stripeAccountId && stripeAccountReady) {
    return (
      <>
        Update <strong>Stripe</strong> account
      </>
    )
  }

  return null
}

interface ProfileTabPageProps {
  stripeAccountId: string | null | undefined
  stripeAccountReady: boolean
}

const ReservationIntegrationStripeButton = ({
  stripeAccountId,
  stripeAccountReady,
}: ProfileTabPageProps) => {
  const { appState } = useContext(AppStore)
  const track = useEventTracker()

  const [
    createStripeAccount,
    { loading: loadingCreatingStripeAccount },
  ] = useCreateStripeAccountMutation()

  // Methods
  const connectToStripe = async () => {
    track(ReservationTrackEvents.CLICKED_CONNECT_WITH_STRIPE, {})
    const { data } = await createStripeAccount({
      variables: {
        clientId: appState?.selectedClient?.id || 0,
      },
    })
    const stripeOnBoardingUrl = data?.createStripeAccount
    if (stripeOnBoardingUrl) {
      window.location.href = stripeOnBoardingUrl
    }
  }

  return (
    <Root>
      <LoadingButton
        variant="contained"
        color="primary"
        size="large"
        className={classes.stripeButton}
        onClick={connectToStripe}
        loading={loadingCreatingStripeAccount}
      >
        <span>{getStripeButtonTitle(stripeAccountId, stripeAccountReady)}</span>
      </LoadingButton>
      {stripeAccountId && stripeAccountReady && (
        <Typography variant="caption" className={classes.stripeConnectedText}>
          Stripe account setup is complete!
        </Typography>
      )}
    </Root>
  )
}

export default ReservationIntegrationStripeButton
