import React from 'react'
import {
  useCamberPopularLotsQuery,
  CommonQueryFilter,
  ClientIdAndName,
  Lot,
} from 'graphql/gen-types'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import PopularLots from './PopularLots'

interface CamberPopularLotsProps {
  client: ClientIdAndName | null
  filter: CommonQueryFilter
  communityName?: string
  containerHeight: number
}

function CamberPopularLots(props: CamberPopularLotsProps): JSX.Element {
  const { data, error, loading } = useCamberPopularLotsQuery({
    variables: {
      filter: props.filter,
    },
  })

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized message={error.toString()} imageName={'PopularColors'} />
      )
    }
    console.error(error)
    return <div>Error while loading popular lots</div>
  }

  const popularLots: Lot[] = data?.camberPopularLots as Lot[]
  return (
    <PopularLots
      {...props}
      popularLots={popularLots}
      loading={loading}
      error={error}
    />
  )
}

export default CamberPopularLots
