import React from 'react'
import Grid, { GridSize } from '@mui/material/Grid'
import DatePicker from '@mui/lab/DatePicker'
import TextField from '@mui/material/TextField'

interface ReservationFromToDatePickerProps {
  fromValue: Date | null
  onFromChange: (date: Date | null) => void
  toValue: Date | null
  onToChange: (date: Date | null) => void
  grid: { xs?: GridSize; sm?: GridSize; md?: GridSize; lg?: GridSize }
}

const ReservationFromToDatePicker: React.FC<ReservationFromToDatePickerProps> = ({
  fromValue,
  onFromChange,
  toValue,
  onToChange,
  grid,
}) => {
  return (
    <>
      <Grid item {...grid}>
        <DatePicker
          fullWidth
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="dense"
          id="date-picker-from"
          label="From"
          maxDate={toValue}
          maxDateMessage={'Date should not be after "To" date'}
          value={fromValue}
          onChange={onFromChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          renderInput={(params) => <TextField variant="standard" {...params} />}
        />
      </Grid>
      <Grid item {...grid}>
        <DatePicker
          fullWidth
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="dense"
          id="date-picker-to"
          label="To"
          minDate={fromValue}
          minDateMessage={'Date should not be before "From" date'}
          value={toValue}
          onChange={onToChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          renderInput={(params) => <TextField variant="standard" {...params} />}
        />
      </Grid>
    </>
  )
}

export default ReservationFromToDatePicker
