import { styled } from '@mui/material/styles'
import Popover from '@mui/material/Popover'

const PREFIX = 'ReservationIntegrationDeletionPopover'

export const classes = {
  restoreDeletedPopoverContext: `${PREFIX}-restoreDeletedPopoverContext`,
  restoreDeletedPopoverButtonHolder: `${PREFIX}-restoreDeletedPopoverButtonHolder`,
}

export const StyledPopover = styled(Popover)(() => ({
  [`& .${classes.restoreDeletedPopoverContext}`]: {
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  [`& .${classes.restoreDeletedPopoverButtonHolder}`]: {
    alignSelf: 'flex-end',
    marginTop: 15,
  },
}))
