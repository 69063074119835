import {
  ColonnadeRolePrivilegesEnum,
  ColonnadeSubscriptionLevelsEnum,
} from 'graphql/gen-types'

export const MAIN_APP_BAR_HEIGHT = 56
export const MAIN_DRAWER_WIDTH = 240
export const ANALYTICS_APP = 'ANALYTICS_APP'
export const LEADS_APP = 'LEADS_APP'
export const MARKETING_APP = 'MARKETING_APP'
export const BUY_ONLINE_APP = 'BUY_ONLINE_APP'

export const ANEWGO_ADMIN_ROLE_ID = 1
export const ANEWGO_STAFF_ROLE_ID = 2
export const ADMIN_ROLE_ID = 3
export const AGENT_ROLE_ID = 4
export const ANALYTICS_MEMBER_ROLE_ID = 5
export const SALES_ADMIN_ROLE_ID = 6

// BRANDING COLORS
export const BRANDING_COLOR_NAVY_BLUE = '#143959'
export const BRANDING_COLOR_MAIN_TEAL = '#1F7396'
export const BRANDING_COLOR_OCEAN_GREEN = '#27D3A8'
export const BRANDING_COLOR_PASTEL_YELLOW = '#F8E7AE'
export const BRANDING_COLOR_DARK_MODE_YELLOW = '#E6AC00'
export const BRANDING_COLOR_PURE_WHITE = '#FFFFFF'

// SUBSCRIPTION CONSTANTS
export const STARTER_INVALID_FEATURE_MESSAGE =
  'You  do not have the proper permissions for this feature. Please contact us for more information'

export const STARTER_OR_PRO_INVALID_FEATURE_MESSAGE =
  'Subscription level of ENTERPRISE is required for this feature. Please contact us for more information.'

export const CLOUDINARY_PREFIX =
  'https://res.cloudinary.com/renderinghouse/image/upload'

export const ANEWGO_CDN_BASE = `https://cdn.anewgo.com`

export const LEAD_SCORE_OUT_OF = 100
export const PLAN_SCORE_OUT_OF = 100

// GENERAL
export const GOLDEN_RATIO = 0.618 // Golden ratio 1 : phi = 1 : (1 + sqrt(5) / 2) = 1/1.618
export const ASPECT_RATIO = 0.5625 // Aspect ratio of 16:9 = 9/16

export interface RouteAuthorization {
  subscription: ColonnadeSubscriptionLevelsEnum[] | null
  privileges: ColonnadeRolePrivilegesEnum[] | null
}

const VALID_SUBSCRITPTIONS = [
  ColonnadeSubscriptionLevelsEnum.Starter,
  ColonnadeSubscriptionLevelsEnum.Pro,
  ColonnadeSubscriptionLevelsEnum.Enterprise,
]

export const LEAD_OVERVIEW_AUTH: RouteAuthorization = {
  privileges: [
    ColonnadeRolePrivilegesEnum.LeadRead,
    ColonnadeRolePrivilegesEnum.LeadReadWrite,
  ],
  subscription: VALID_SUBSCRITPTIONS,
}

export const LEAD_ANALYTICS_OVERVIEW_AUTH: RouteAuthorization = {
  privileges: [
    ColonnadeRolePrivilegesEnum.LeadRead,
    ColonnadeRolePrivilegesEnum.LeadReadWrite,
    ColonnadeRolePrivilegesEnum.AnalyticsRead,
  ],
  subscription: VALID_SUBSCRITPTIONS,
}

export const LEAD_ASSIGNMENTS_AUTH: RouteAuthorization = {
  privileges: [ColonnadeRolePrivilegesEnum.LeadReadWrite],
  subscription: VALID_SUBSCRITPTIONS,
}

export const ANALYTICS_OVERVIEW_AUTH: RouteAuthorization = {
  privileges: [ColonnadeRolePrivilegesEnum.AnalyticsRead],
  subscription: VALID_SUBSCRITPTIONS,
}

export const ANALYTICS_SUBMODULE_AUTH: RouteAuthorization = {
  privileges: [ColonnadeRolePrivilegesEnum.AnalyticsRead],
  subscription: [
    ColonnadeSubscriptionLevelsEnum.Pro,
    ColonnadeSubscriptionLevelsEnum.Enterprise,
  ],
}

export const MARKETING_AUTH: RouteAuthorization = {
  privileges: [
    ColonnadeRolePrivilegesEnum.MarketingRead,
    ColonnadeRolePrivilegesEnum.MarketingReadWrite,
  ],
  subscription: [
    ColonnadeSubscriptionLevelsEnum.Pro,
    ColonnadeSubscriptionLevelsEnum.Enterprise,
  ],
}
