import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'

const PREFIX = 'AddReportInfoDialog'

export const classes = {
  addReportInfoDialog: `${PREFIX}-addReportInfoDialog`,
  addReportInfoFirstNameInputField: `${PREFIX}-addReportInfoFirstNameInputField`,
  addReportInfoLastNameInputField: `${PREFIX}-addReportInfoLastNameInputField`,
  formControlAddReportInfo: `${PREFIX}-formControlAddReportInfo`,
  gridItem: `${PREFIX}-gridItem`,
}

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.addReportInfoDialog}`]: {
    minWidth: '500px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '90vw',
    },
  },

  [`& .${classes.addReportInfoFirstNameInputField}`]: {
    width: 'calc(100% - 10px)',
  },

  [`& .${classes.addReportInfoLastNameInputField}`]: {
    [theme.breakpoints.up('md')]: {
      marginLeft: '10px',
    },
  },

  [`& .${classes.formControlAddReportInfo}`]: {
    marginBottom: '20px',
    width: '100%',
  },
  [`& .${classes.gridItem}`]: {
    paddingLeft: '0px !important',
  },
}))
