import Box from '@mui/material/Box'
import React from 'react'

interface TabPanelProps<T> {
  children?: React.ReactNode
  dir?: string
  value: T
  index: T
}

export default function TabPanel<T>(props: TabPanelProps<T>): JSX.Element {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${value}`}
      aria-labelledby={`tab-${value}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  )
}
