import { styled } from '@mui/material/styles'
import { deepOrange } from '@mui/material/colors'

const PREFIX = 'UserInfo'

export const classes = {
  root: `${PREFIX}-root`,
  avatar: `${PREFIX}-avatar`,
  orange: `${PREFIX}-orange`,
}

export const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },

  [`& .${classes.avatar}`]: {
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.orange}`]: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
}))
