import React, { useContext, useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import ComponentCard from 'components/common/layout/ComponentCard'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import InputLabel from '@mui/material/InputLabel'
import {
  useSetLeadBrowseAgentNotificationConfigMutation,
  useGetLeadBrowseAgentNotificationConfigQuery,
} from 'graphql/gen-types'
import { snackbarConfigAtom } from 'store/atoms'
import { AppStore } from 'store'

const COMPONENT_TITLE = 'Assignment Notifications'
const ON_SUCCESS_MESSAGE = 'Notification Configuration Set.'
const SKELETON_HEIGHT = 136
type AssignmentNotificationManagerProps = {
  disabled?: boolean
}

export default function AssignmentNotificationManager({
  disabled,
}: AssignmentNotificationManagerProps): JSX.Element {
  const [snackbarConfig, setSnackbarConfig] = useAtom(snackbarConfigAtom)
  const { appState } = useContext(AppStore)
  const clientName = appState.selectedClient?.altName || ''
  const [enabled, setEnabled] = useState(false)
  const [frequency, setFrequency] = useState(1)

  const [
    setLeadBrowseAgentNotificationConfig,
    { error: mutationError },
  ] = useSetLeadBrowseAgentNotificationConfigMutation()

  const { data, error, loading } = useGetLeadBrowseAgentNotificationConfigQuery(
    {
      variables: { clientName },
      fetchPolicy: 'no-cache',
    }
  )

  useEffect(() => {
    const config = data?.getLeadBrowseAgentNotificationConfig
    if (config) {
      setEnabled(config.enabled)
      setFrequency(config?.frequency || 1)
    }
  }, [data?.getLeadBrowseAgentNotificationConfig])

  const handleEnabledChange = (value: string): void => {
    setEnabled(value === 'enable')
  }

  const handleFrequencyChange = (value: number): void => {
    setFrequency(value)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLeadBrowseAgentNotificationConfig({
      variables: {
        clientName,
        enabled,
        frequency,
      },
    })

    if (mutationError) {
      setSnackbarConfig({
        ...snackbarConfig,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        autoHideDuration: 6000,
        open: true,
        message: mutationError.message,
        severity: 'error',
      })
    } else {
      setSnackbarConfig({
        ...snackbarConfig,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        autoHideDuration: 6000,
        open: true,
        message: ON_SUCCESS_MESSAGE,
        severity: 'success',
      })
    }
  }

  return (
    <ComponentCard
      title={COMPONENT_TITLE}
      alert={
        disabled
          ? {
              severity: 'warning',
              message: (
                <span>
                  Your subscription level must be <strong>Pro</strong> or
                  <strong> Enterprise</strong> to access this feature.
                </span>
              ),
            }
          : undefined
      }
      result={{ loading, error }}
      skeletonHeight={SKELETON_HEIGHT}
      disabled={disabled}
    >
      <Box style={{ paddingBottom: 16 }}>
        <Grid container justifyContent="flex-start">
          <Typography variant="subtitle1">
            Manage Agent assignment notifications for browsing sessions.
          </Typography>
        </Grid>
      </Box>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} justifyContent="space-evenly">
          <Grid item xs={12} sm={10} md={10} lg={5}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="receive-select-label" disabled={disabled}>
                Receive Notifications
              </InputLabel>
              <Select
                variant="standard"
                labelId="demo-simple-select-outlined-label"
                onChange={(event: SelectChangeEvent<string>) =>
                  handleEnabledChange(event.target.value)
                }
                value={enabled ? 'enable' : 'disable'}
                label="Receive Notifications"
                disabled={disabled}
                data-testid="assignment-notification-enable"
              >
                <MenuItem value={'enable'}>Enabled</MenuItem>
                <MenuItem value={'disable'}>Disabled</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={10} md={10} lg={4}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="frequency-select-label" disabled={disabled}>
                Frequency
              </InputLabel>
              <Select
                variant="standard"
                labelId="frequency-select-label"
                onChange={(event: SelectChangeEvent<number>) =>
                  handleFrequencyChange(event.target.value as number)
                }
                value={frequency}
                label="Frequency"
                fullWidth
                disabled={disabled}
                data-testid="assignment-notification-frequency"
              >
                <MenuItem value={1}>Daily</MenuItem>
                <MenuItem value={7}>Weekly</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={disabled}
              data-testid="assignment-notification-submit"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </ComponentCard>
  )
}
