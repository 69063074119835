import React, { useContext, useEffect, useState } from 'react'
import DateFnsAdapter from '@date-io/date-fns'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import StaticDatePicker from '@mui/lab/StaticDatePicker'
import Grid from '@mui/material/Grid'

import { AppActionType } from 'store/reducers'
import { AnalyticsStore } from 'store/analyticsStore'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { Elevation } from 'graphql/gen-types'
import Autocomplete from '@mui/material/Autocomplete'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import DateDisplay from 'components/common/filter-bar/DateDisplay'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Box from '@mui/material/Box'
import { classes, StyledAppBar } from './FilterBar.styles'
import { identity } from 'lodash'

const MIN_DATE = new Date('1/1/2000')
const DATE_FORMAT = 'MM/dd/yyyy'

const daysAgoCalculator = (numberOfDays: number): number => {
  return dateFns
    .startOfDay(dateFns.addDays(dateFns.date(new Date()), numberOfDays))
    .getTime()
}

export const quickDateSetter = (
  startDate: Date,
  endDate: Date,
  quickDate: string,
  setQuickDate: (quickDate: string) => void
): void => {
  const today = dateFns.endOfDay(new Date())
  if (endDate.getTime() !== today.getTime()) {
    setQuickDate('custom')
    return
  }
  if (startDate.getTime() === daysAgoCalculator(-7)) {
    setQuickDate('7')
  } else if (startDate.getTime() === daysAgoCalculator(-14)) {
    setQuickDate('14')
  } else if (startDate.getTime() === daysAgoCalculator(-30)) {
    setQuickDate('30')
  } else if (startDate.getTime() === daysAgoCalculator(-91)) {
    setQuickDate('91')
  } else if (startDate.getTime() === daysAgoCalculator(-182)) {
    setQuickDate('182')
  } else if (startDate.getTime() === daysAgoCalculator(-365)) {
    setQuickDate('365')
  } else if (quickDate !== 'custom') {
    setQuickDate('custom')
  }
}

const dateFns = new DateFnsAdapter()

interface FilterBarProps {
  onElevationSelectChange?: (e: any, value: any) => void
  elevations?: Elevation[]
  planName?: string
  customFilters?: React.ReactNode
  customFiltersRow?: React.ReactNode
  hideDateSelect?: boolean | false
}

interface FilterBarDateInputProps {
  inputProps: TextFieldProps
  validate: (date: Date) => string | null
  onInputShow?: () => void
  onInputHide?: () => void
}

function FilterBarDateInput({
  inputProps,
  validate,
  onInputShow = identity,
  onInputHide = identity,
}: FilterBarDateInputProps): JSX.Element {
  const value = dateFns.parse(
    inputProps?.inputProps?.value as string,
    DATE_FORMAT
  )

  // Validate the value
  // If it's not valid, returns a string containing the error message
  // Otherwise returns null
  const validationResult = validate(value)

  useEffect(() => {
    onInputShow()
    return () => onInputHide()
  }, [])

  return (
    <TextField
      variant="standard"
      {...inputProps}
      error={!!validationResult}
      helperText={validationResult}
    />
  )
}

function FilterBar({
  onElevationSelectChange,
  elevations,
  planName,
  customFilters,
  customFiltersRow,
  hideDateSelect,
}: FilterBarProps): JSX.Element {
  const { analyticsState, dispatch } = useContext(AnalyticsStore)
  const { startDate, endDate } = analyticsState
  // The initialDialogQuickDate lets us know what to reset the quickDate state to if
  // the user clicks "Cancel" in the dialog.
  const [initialDialogQuickDate, setInitialDialogQuickDate] = useState<string>(
    ''
  )
  const [quickDate, setQuickDate] = useState<string>('custom')
  const [inputStartDate, setInputStartDate] = useState<Date>(startDate)
  const [inputEndDate, setInputEndDate] = useState<Date>(endDate)
  const [newStartDate, setNewStartDate] = useState<Date>(startDate)
  const [newEndDate, setNewEndDate] = useState<Date>(endDate)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const maxDate = dateFns.endOfDay(new Date())

  const [endDateValid, setEndDateValid] = useState<boolean>(true)
  const [startDateValid, setStartDateValid] = useState<boolean>(true)

  const theme = useTheme()
  const responsiveSize = useMediaQuery(theme.breakpoints.down('md'))

  const handleQuickDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = (event.target as HTMLInputElement).value
    setQuickDate(value)
    if (value !== 'custom') {
      const daysToSubtract = parseInt(value, 10)
      const quickStartDate = dateFns.startOfDay(
        dateFns.addDays(dateFns.date(new Date()), -daysToSubtract)
      )
      setNewStartDate(quickStartDate)
      setNewEndDate(dateFns.endOfDay(new Date()))
    }
  }

  const validateStartDate = (date: Date): string | null => {
    if (!dateFns.isValid(date)) {
      setStartDateValid(false)
      return 'Please enter a valid date'
    }

    if (dateFns.isBefore(date, MIN_DATE)) {
      setStartDateValid(false)
      return `Please enter a date after ${dateFns.format(
        dateFns.addDays(MIN_DATE, -1),
        DATE_FORMAT
      )}`
    }

    if (dateFns.isAfter(date, newEndDate)) {
      setStartDateValid(false)
      return `Please enter a date before ${dateFns.format(
        dateFns.addDays(newEndDate, 1),
        DATE_FORMAT
      )}`
    }

    setStartDateValid(true)
    return null
  }

  const validateEndDate = (date: Date): string | null => {
    if (!dateFns.isValid(date)) {
      setEndDateValid(false)
      return 'Please enter a valid date'
    }

    if (dateFns.isBefore(date, newStartDate)) {
      setEndDateValid(false)
      return `Please enter a date after ${dateFns.format(
        dateFns.addDays(newStartDate, -1),
        DATE_FORMAT
      )}`
    }

    if (dateFns.isAfter(date, maxDate)) {
      setEndDateValid(false)
      return `Please enter a date before ${dateFns.format(
        dateFns.addDays(maxDate, 1),
        DATE_FORMAT
      )}`
    }

    setEndDateValid(true)
    return null
  }

  const setDatesRange = (): void => {
    if (startDateValid && endDateValid) {
      setOpenDialog(false)
      setInputStartDate(newStartDate)
      setInputEndDate(newEndDate)
      dispatch({
        type: AppActionType.SET_DATE_RANGE,
        payload: {
          startDate: newStartDate,
          endDate: newEndDate,
        },
      })
    }
  }

  const cancelDateChange = (): void => {
    setNewStartDate(inputStartDate)
    setNewEndDate(inputEndDate)
    setOpenDialog(false)
    if (initialDialogQuickDate) {
      setTimeout(() => {
        // Reset the quickDate value back to the original one after the dialog closes
        setQuickDate(initialDialogQuickDate)
      }, 200)
    }
  }

  useEffect(() => {
    quickDateSetter(startDate, endDate, quickDate, setQuickDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const DateRangePicker: JSX.Element = (
    <div
      id="filter-bar-wrapper"
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        flex: '1 1 auto',
      }}
    >
      <div
        style={{ display: 'flex' }}
        onClick={(): void => {
          setOpenDialog(true)
          setInitialDialogQuickDate(quickDate)
        }}
        id="datesRangeContainer"
      >
        <DateDisplay label="Start Date" date={inputStartDate} />
        <DateDisplay label="End Date" date={inputEndDate} />
      </div>
      <Dialog
        open={openDialog}
        onClose={cancelDateChange}
        maxWidth="xl"
        fullScreen={responsiveSize}
      >
        {/* <DialogTitle>Set Date Range</DialogTitle> */}
        <DialogContent>
          <FormControl
            component="fieldset"
            style={{ minWidth: 300 }}
            variant="standard"
          >
            <Typography variant="h5" color="primary">
              Date Range
            </Typography>
            <RadioGroup
              aria-label="date presets"
              name="datePresets"
              value={quickDate}
              onChange={handleQuickDateChange}
              style={{
                paddingLeft: '10px',
                flexDirection: quickDate === 'custom' ? 'row' : 'column',
              }}
            >
              <FormControlLabel
                value="7"
                control={<Radio />}
                label="Last 7 Days"
              />
              <FormControlLabel
                value="14"
                control={<Radio />}
                label="Last 14 Days"
              />
              <FormControlLabel
                value="30"
                control={<Radio />}
                label="Last 30 Days"
              />
              <FormControlLabel
                value="91"
                control={<Radio />}
                label="Last 3 Months"
              />
              <FormControlLabel
                value="182"
                control={<Radio />}
                label="Last 6 Months"
              />
              <FormControlLabel
                value="365"
                control={<Radio />}
                label="Last 365 Days"
              />
              <FormControlLabel
                value="custom"
                control={<Radio />}
                label="Custom"
              />
            </RadioGroup>
          </FormControl>
          <Grid
            container
            spacing={0}
            style={{
              overflowY: 'hidden',
            }}
            alignItems="center"
            justifyContent="center"
          >
            {quickDate === 'custom' && (
              <>
                <Grid item xs={12} md={6} style={{ marginTop: '10px' }}>
                  <Grid
                    container
                    justifyContent="center"
                    direction="column"
                    alignItems="center"
                  >
                    <Typography variant="h5" color="primary">
                      Start Date
                    </Typography>

                    <Box className={classes.calenderContainer}>
                      <StaticDatePicker
                        showToolbar
                        allowSameDateSelection
                        displayStaticWrapperAs="desktop"
                        label="Start Date"
                        inputFormat={DATE_FORMAT}
                        value={newStartDate}
                        InputAdornmentProps={{ position: 'start' }}
                        minDate={MIN_DATE}
                        maxDate={newEndDate}
                        onChange={(date: Date | null): void => {
                          if (date !== null) {
                            setNewStartDate(date)
                          }
                        }}
                        renderInput={(params) => (
                          <FilterBarDateInput
                            onInputHide={() => setStartDateValid(true)}
                            inputProps={params}
                            validate={validateStartDate}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6} style={{ marginTop: '10px' }}>
                  <Grid
                    container
                    justifyContent="center"
                    direction="column"
                    alignItems="center"
                  >
                    <Typography variant="h5" color="primary">
                      End Date
                    </Typography>

                    <Box className={classes.calenderContainer}>
                      <StaticDatePicker
                        showToolbar
                        allowSameDateSelection
                        displayStaticWrapperAs="desktop"
                        label="End Date"
                        inputFormat={DATE_FORMAT}
                        value={newEndDate}
                        InputAdornmentProps={{ position: 'start' }}
                        maxDate={maxDate}
                        minDate={newStartDate}
                        onChange={(date: Date | null): void => {
                          if (date !== null) {
                            const endOfDayDate = dateFns.endOfDay(date)
                            setNewEndDate(endOfDayDate)
                            setEndDateValid(true)
                          }
                        }}
                        renderInput={(params) => (
                          <FilterBarDateInput
                            onInputHide={() => setEndDateValid(true)}
                            inputProps={params}
                            validate={validateEndDate}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={cancelDateChange}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={setDatesRange}
            disabled={!startDateValid || !endDateValid}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )

  return (
    <StyledAppBar position="sticky" className={classes.filterBar}>
      <Paper className={classes.paper}>
        {/* Grid Root */}
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          className={classes.gridRoot}
        >
          {/* Main (Top) Row */}
          <Grid item xs={12} className={classes.filterBarRow}>
            <Grid container wrap="wrap" maxWidth="100%">
              {!hideDateSelect && (
                <Grid
                  item
                  xs={12}
                  md={customFilters ? 6 : 12}
                  flexGrow={1}
                  flexShrink={1}
                >
                  <Grid container alignItems="center" justifyContent="center">
                    {DateRangePicker}
                  </Grid>
                </Grid>
              )}

              {/* Render customFilters if provided */}
              {customFilters && (
                <Grid item marginLeft="auto">
                  <Grid container alignItems="center" justifyContent="center">
                    {elevations && onElevationSelectChange && planName && (
                      <Autocomplete
                        id="combo-box-select-elevation"
                        options={elevations}
                        getOptionLabel={(elevation): string =>
                          `${planName} ${elevation.caption || ''}${
                            elevation.internalName
                              ? ` (${elevation.internalName})`
                              : ''
                          }`
                        }
                        style={{
                          marginTop: '5px',
                          paddingRight: '5px',
                          width: 270,
                        }}
                        onChange={onElevationSelectChange}
                        renderInput={(params): JSX.Element => (
                          <TextField
                            {...params}
                            label="Select Elevation"
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                    {customFilters}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* Render customFilterRow if provided */}
          {customFiltersRow && (
            <Grid item xs={12} className={classes.filterBarRow}>
              <Grid container alignItems="center" justifyContent="center">
                {customFiltersRow}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
    </StyledAppBar>
  )
}

export default FilterBar
