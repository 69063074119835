import { styled } from '@mui/material/styles'
import TableBody from '@mui/material/TableBody'

const PREFIX = 'ReservationIntegrationListBody'

export const classes = {
  successIcon: `${PREFIX}-successIcon`,
  errorIcon: `${PREFIX}-errorIcon`,
  rowActions: `${PREFIX}-rowActions`,
  noWrap: `${PREFIX}-noWrap`,
  inProgressText: `${PREFIX}-inProgressText`,
  approvedText: `${PREFIX}-approvedText`,
  rejectedText: `${PREFIX}-rejectedText`,
  tableRow: `${PREFIX}-tableRow`,
  tableRowDarkMode: `${PREFIX}-tableRowDarkMode`,
  tableRowDeleted: `${PREFIX}-tableRowDeleted`,
}

export const StyledTableBody = styled(TableBody)(({ theme }) => ({
  [`& .${classes.successIcon}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.errorIcon}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.rowActions}`]: {
    marginTop: 6,
  },

  [`& .${classes.noWrap}`]: {
    whiteSpace: 'nowrap',
  },

  [`& .${classes.inProgressText}`]: {
    color: theme.palette.warning.main,
  },

  [`& .${classes.approvedText}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.rejectedText}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.tableRow}`]: {
    '&:nth-child(even)': {
      backgroundColor: '#f9f9f9',
    },
    '&:hover': {
      backgroundColor: '#ededed',
      transition: 'background-color 350ms ease',
    },
    '& > td': {
      color: 'inherit',
    },
  },

  [`& .${classes.tableRowDarkMode}`]: {
    '&:nth-child(even)': {
      backgroundColor: '#303030',
    },
    '&:hover': {
      backgroundColor: '#303030',
      transition: 'background-color 350ms ease',
    },
    '& > td': {
      color: 'inherit',
    },
  },

  [`& .${classes.tableRowDeleted}`]: {
    opacity: '.7',
    filter: 'grayscale(100%)',
    cursor: 'not-allowed',
    '& > td': {
      color: '#b3b3b3',
    },
    '&:nth-child(even)': {
      opacity: '.7',
      filter: 'grayscale(100%)',
      color: '#b3b3b3',
    },
  },
}))
