import { styled } from '@mui/material/styles'
import { MAIN_APP_BAR_HEIGHT, MAIN_DRAWER_WIDTH } from '../../../constants'

const PREFIX = 'BaseLayout'

export const classes = {
  root: `${PREFIX}-root`,
  drawer: `${PREFIX}-drawer`,
  appBar: `${PREFIX}-appBar`,
  menuButton: `${PREFIX}-menuButton`,
  right: `${PREFIX}-right`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  content: `${PREFIX}-content`,
  toolbar: `${PREFIX}-toolbar`,
  typography: `${PREFIX}-typography`,
  separator: `${PREFIX}-separator`,
}

export const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
  },

  [`& .${classes.drawer}`]: {
    [theme.breakpoints.up('sm')]: {
      width: MAIN_DRAWER_WIDTH,
      flexShrink: 0,
    },
  },

  [`& .${classes.appBar}`]: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      zIndex: theme.zIndex.drawer + 1,
      /*
      width: `calc(100% - ${MAIN_DRAWER_WIDTH}px)`,
      marginLeft: MAIN_DRAWER_WIDTH,
      */
    },
  },

  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  [`& .${classes.right}`]: {
    position: 'absolute',
    right: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  [`& .${classes.drawerPaper}`]: {
    width: MAIN_DRAWER_WIDTH,
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    marginTop: `${MAIN_APP_BAR_HEIGHT}px`,
    width: `calc(100% - ${MAIN_DRAWER_WIDTH}px)`,
    height: `calc(100% - ${MAIN_APP_BAR_HEIGHT}px)`,
    backgroundColor: theme.palette.mode === 'dark' ? '#303030' : '#fafafa',
  },

  [`& .${classes.toolbar}`]: {
    minHeight: `${MAIN_APP_BAR_HEIGHT}px`,
  },

  [`& .${classes.typography}`]: {
    color: theme.palette.primary.contrastText,
  },

  [`& .${classes.separator}`]: {
    color: theme.palette.primary.contrastText,
  },
}))
