import { IPAD_PORTRAIT } from '@anewgo/utils'
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { excludeProps } from 'utils/functions'

const ICON_BUTTON_SZ = 50

export const AgentCardStyled = styled(Card)({
  maxWidth: 300,
  marginBottom: '2rem',
})

export const AgentCardTitle = styled(Typography)({
  fontSize: 14,
})

export const AgentCardPos = styled(Typography)({
  marginBottom: 12,
})

export const HeroImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
})

export const CommunityDescription = styled('div')({
  padding: 16,
})

export const CommunityLocation = styled(Typography)(({ theme }) => ({
  lineHeight: 2,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2rem',
  },
}))

export const CommunityLogo = styled('img')({
  float: 'right',
  width: 200,
  paddingBottom: 8,
  paddingLeft: 12,
})

export const CommunityDescriptionText = styled('div')({
  fontSize: 20,
  paddingLeft: 16,
}) as typeof Typography

export const SpecsPanelText = styled(Typography)(({ theme }) => ({
  lineHeight: 1.6,
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.2rem',
  },
}))

export const SpecsPanel = styled('div')(({ theme }) => ({
  padding: 32,
  gridColumnStart: 2,
  gridColumnEnd: 3,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  [theme.breakpoints.down('lg')]: {
    gridColumnStart: 1,
    gridColumnEnd: 1,
  },
}))

export const InstructionText = styled(Typography)({
  color: 'white',
  position: 'absolute',
  top: 0,
  right: 0,
  marginTop: 4,
  marginRight: 8,
  fontWeight: 600,
  textShadow: '0px 2px 40px #00000020, 0px 2px 5px #00000030',
})

export const InstructionTextDark = styled(InstructionText)({
  color: '#333',
})

export const ExteriorImage = styled('div')(({ theme }) => ({
  position: 'relative',
  gridColumnStart: 1,
  gridColumnEnd: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('lg')]: {
    gridColumnStart: 1,
    gridColumnEnd: 1,
    justifyContent: 'center',
  },
}))

export const FancyTitleContainer = styled('div')({
  height: 120,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const FancyTitle = styled('h2')(({ theme }) => ({
  marginBottom: 0,
  fontSize: '1.5rem',
  position: 'relative',
  textAlign: 'center',
  textTransform: 'uppercase',
  zIndex: 1,
  '&:before': {
    borderTop: `2px solid red`, // `2px solid ${theme.palette.bannerColor.main}`, // missing palette color. Red to display it clearly
    content: '""',
    margin: '0 auto',
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
    width: '100%',
  },
  '& span': {
    background: '#fff',
    padding: '0 8px',
  },
  margin: '0 auto',
  width: '80%',
  color: 'rgba(0,0,0,0.87)',
  fontFamily: 'Gotham Book, Avenir Light, sans-serif',
  fontWeight: 400,
  lineHeight: 1.33,
  [theme.breakpoints.down('md')]: {
    fontSize: '1.2rem',
  },
}))

export const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  borderRadius: '50%',
  border: '1px solid #CDCDCD',
  width: ICON_BUTTON_SZ,
  minWidth: ICON_BUTTON_SZ,
  height: ICON_BUTTON_SZ,
  marginRight: 8,
  backgroundColor: 'white !important',
  [`@media ${IPAD_PORTRAIT}`]: {
    width: ICON_BUTTON_SZ * 0.9,
    minWidth: ICON_BUTTON_SZ * 0.9,
    height: ICON_BUTTON_SZ * 0.9,
  },
  [theme.breakpoints.up('xl')]: {
    width: ICON_BUTTON_SZ * 1.8,
    minWidth: ICON_BUTTON_SZ * 1.8,
    height: ICON_BUTTON_SZ * 1.8,
  },
}))

export const LotInsetDisplay = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

export const LotInsetContainer = styled('div')({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',
})

export const LotInsetWrapper = styled('div')({
  position: 'relative',
})

export const SchemeInfo = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '360px 1fr',
  gridTemplateRows: '1fr',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '200px 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '120px 1fr',
  },
}))

export const SchemeSwathWrapper = styled('div')(({ theme }) => ({
  width: 360,
  height: 240,
  [theme.breakpoints.down('lg')]: {
    width: 200,
    height: 133,
  },
  [theme.breakpoints.down('sm')]: {
    width: 120,
    height: 90,
  },
}))

export const SchemeName = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    '& h5': {
      fontSize: '1.2rem',
    },
  },
}))

export const ColorDisplayArea = styled('div')({
  paddingTop: 32,
})

export const ColorSubtext = styled(Typography)({
  lineHeight: 1.4,
  textAlign: 'center',
})

export const SmallColorSubtext = styled(ColorSubtext)({
  lineHeight: 1.3,
  fontSize: '0.675rem',
})

export const ColorGrid = styled('div')(({ theme }) => ({
  display: 'grid',
  paddingBottom: 10,
  gridTemplateColumns: '140px repeat(auto-fill, minmax(140px, 1fr))',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '100px repeat(auto-fill, minmax(140px, 1fr))',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '50px repeat(auto-fill, minmax(160px, 1fr))',
  },
}))

export const ColorSwatch = styled('div')(({ theme }) => ({
  backgroundSize: 'contain',
  width: 100,
  height: 100,
  borderRadius: '50%',
  [theme.breakpoints.down('lg')]: {
    width: 70,
    height: 70,
  },
}))

export const ColorSwatchContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
})

export const CategoryTitle = styled('div', {
  shouldForwardProp: (prop) => excludeProps(prop, 'color'),
})(({ theme, color }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: color,
  [theme.breakpoints.down('lg')]: {
    '& h6': {
      fontSize: 16,
    },
  },
  [theme.breakpoints.down('sm')]: {
    gridColumnStart: 1,
    gridColumnEnd: 3,
  },
}))

export const FpSectionTitle = styled('div')(({ theme }) => ({
  paddingTop: 32,
  paddingLeft: 32,
  paddingRight: 32,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: 16,
    paddingRight: 16,
  },
}))

export const FpImageContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  maxHeight: 900,
})

export const FpOptTextContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
})

export const FloorName = styled(Typography)({
  fontFamily: 'Gotham Book, Avenir Light, sans-serif',
})

export const HomeSpecsContainer = styled('div')(({ theme }) => ({
  textShadow: '0px 2px 40px #00000020, 0px 2px 5px #00000030',
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'center',
  },
}))

export const HomeSpecs = styled('div')(({ theme }) => ({
  textAlign: 'right',
  [theme.breakpoints.down('lg')]: {
    textAlign: 'center',
  },
}))

export const HeaderSection = styled('div')(({ theme }) => ({
  padding: 8,
  [theme.breakpoints.down('sm')]: {
    padding: 4,
  },
}))

export const InteriorNameText = styled(Typography)({
  textAlign: 'left',
})

export const InteriorOptionsWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  gridColumn: 1,
  marginTop: 10,
  [theme.breakpoints.down('lg')]: {
    gridColumn: 1,
    gridRow: 3,
  },
}))

export const InteriorImageWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  gridColumn: 2,
  marginTop: 10,
  marginLeft: 25,
  [theme.breakpoints.down('lg')]: {
    gridColumn: 1,
    gridRow: 2,
    marginLeft: 0,
  },
  [theme.breakpoints.down('sm')]: {
    width: '90vw',
  },
}))

export const InterionImage = styled('img')({
  height: 'auto',
  width: '100%',
})

export const DisclaimerContent = styled('div')(({ theme }) => ({
  height: 'fit-content',
  paddingBottom: 10,
  [theme.breakpoints.down('md')]: {
    paddingBottom: 35,
  },
}))

const Section = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: '1fr',
  padding: 32,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: 16,
    paddingRight: 16,
  },
}))

export const SectionContainer = styled(Section)({
  gridTemplateRows: '100px 1fr',
  gridTemplateColumns: '1fr',
})

export const StdSectionContainer = styled(SectionContainer)({
  gridTemplateRows: '30px 1fr',
})

export const CommunitySectionContainer = styled(SectionContainer)({
  gridTemplateRows: '160px 1fr',
})

export const CommunitySection = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'minmax(320px, 0.4fr) 1fr',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr',
  },
  textAlign: 'left',
}))

export const ElevationSection = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: '1fr',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr',
  },
}))

export const ElevationSection2 = styled(Section)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: '1fr',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr',
  },
}))

export const InteriorSection = styled(Section)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr',
    gridTemplateRows: '0.1fr 1fr auto',
    paddingTop: 0,
  },
}))

export const OptsSelectedSection = styled('div')({
  paddingBottom: '1rem',
})

export const InteriorLineBreakSection = styled(SectionContainer)(
  ({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
      gridTemplateRows: '80px',
    },
  })
)

export const FpSection = styled('div')(({ theme }) => ({
  display: 'grid',
  padding: 32,
  gridTemplateColumns: 'minmax(480px, 30%) 1fr',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  [theme.breakpoints.down('lg')]: {
    '& img': {
      width: '70%',
    },
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '280px 1fr',
    '& h4': {
      fontSize: '1.6rem',
    },
    '& h5': {
      fontSize: '1rem',
    },
    '& h6': {
      fontSize: 12,
    },
    '& img': {
      width: '100%',
      padding: 32,
    },
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}))

export const MyHomeStyled = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  overflow: 'auto',
  WebkitOverflowScrolling: 'touch',
}))
