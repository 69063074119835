import { gql } from '@apollo/client'

const USERS_QUERY = gql`
  query GetUsers($clientName: String!) {
    colonnadeUsers(clientName: $clientName) {
      id
      firstName
      lastName
      email
      organizationRole
      status
      roleId
      licensed
    }
  }
`

export default USERS_QUERY
