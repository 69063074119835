import { useQuery } from '@apollo/client'
import { IsLotSignLockedQueryVariables } from 'graphql/gen-types'
import { IS_LOT_SIGN_LOCKED } from 'graphql/nexus-queries/reservationIntegration'

export const useIslotLocked = () => {
  const { refetch } = useQuery(IS_LOT_SIGN_LOCKED, {
    skip: true,
    fetchPolicy: 'network-only',
  })

  const callQuery = (variables: IsLotSignLockedQueryVariables) => {
    return refetch(variables)
  }

  return callQuery
}
