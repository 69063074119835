import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
import TextField from '@mui/material/TextField'
import { useTheme } from '@mui/material/styles'
import { Community, SpecFilter } from 'graphql/gen-types'
import SortSelect, {
  SortOptions,
} from 'components/common/custom-inputs/SortSelect'
import FilterButton from './FilterButton'
import FilterRange from './FilterRange'
import MenuDialog from './MenuDialog'
import FilterBar from 'components/common/filter-bar/FilterBar'
import MobileFilterBar from '../filter-bar/MobileFilterBar'
import CommunitiesSelect from '../custom-inputs/CommunitiesSelect'
import clsx from 'clsx'
import { classes, Root } from './PlansFilterBar.styles'
const SQFT_RANGE_MIN = 1250
const SQFT_RANGE_MAX = 7500
const SQFT_MULTIPLIER = 250

const COST_RANGE_MIN = 200000
const COST_RANGE_MAX = 1500000
const COST_MULTIPLIER = 100000

type plansFilterBarProps = {
  onFilterChange: (val: SpecFilter) => void
  onSearchChange: (val: string) => void
  onSortChange: (val: SortOptions) => void
  onCommunitiesChange?: (communityIds: number[]) => void
  communities?: Community[]
  selectedCommunityIds?: number[]
  filtersForSuggestPlans?: JSX.Element[]
  prefilledFilter?: SpecFilter
}

function PlansFilterBar({
  onFilterChange,
  onSearchChange,
  onSortChange,
  onCommunitiesChange,
  communities = [],
  selectedCommunityIds = [],
  filtersForSuggestPlans,
  prefilledFilter,
}: plansFilterBarProps): JSX.Element {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('md'))
  const md = useMediaQuery(theme.breakpoints.down('lg'))
  // const lg = useMediaQuery(theme.breakpoints.down('xl'))
  // const responseSize = lg
  const [search, setSearch] = useState('')
  const suggestPlans = !!filtersForSuggestPlans?.length

  const [minBed, setMinBed] = useState(0)
  const [minBath, setMinBath] = useState(0)
  const [minSqft, setMinSqft] = useState(0)
  const [maxSqft, setMaxSqft] = useState(0)
  const [minCost, setMinCost] = useState(0)
  const [maxCost, setMaxCost] = useState(0)
  const [sortBy, setSortBy] = useState<SortOptions>(SortOptions.Popularity)
  const selectedCommunities = communities.filter((comm) =>
    selectedCommunityIds.includes(comm.id)
  )

  useEffect(() => {
    const filter = {
      minBed: minBed,
      minBath: minBath,
      minSqft: minSqft,
      maxSqft: maxSqft,
      minCost: minCost,
      maxCost: maxCost,
    }
    onFilterChange(filter)
    // ignore eslint warning. onFilterChange is acceptable as a non dependency. Can cause infinite loop error with it.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minBed, minBath, minSqft, maxSqft, minCost, maxCost])

  useEffect(() => {
    onSearchChange(search)
  }, [onSearchChange, search])

  useEffect(() => {
    onSortChange(sortBy)
  }, [onSortChange, sortBy])

  useEffect(() => {
    if (prefilledFilter) {
      setMinBed(prefilledFilter.minBed || minBed)
      setMinBath(prefilledFilter.minBath || minBath)
      setMinSqft(prefilledFilter.minSqft || minSqft)
      setMaxSqft(prefilledFilter.maxSqft || maxSqft)
      setMinCost(prefilledFilter.minCost || minCost)
      setMaxCost(prefilledFilter.maxCost || maxCost)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefilledFilter])

  const displayButtonFiltersSuggestPlans = (): JSX.Element => {
    if (md) {
      return (
        <Grid
          container
          direction="row"
          spacing={3}
          className={classes.filterRow}
        >
          {filtersForSuggestPlans && (
            <Grid
              container
              spacing={3}
              className={classes.filterRow}
              sm={12}
              md={12}
            >
              {filtersForSuggestPlans.map((filterElement, i) => {
                return (
                  <Grid
                    key={i}
                    className={clsx(
                      classes.filterRow,
                      classes.suggestedPlansButtonMobile
                    )}
                  >
                    {filterElement}
                  </Grid>
                )
              })}
            </Grid>
          )}
          <Grid
            container
            spacing={3}
            className={classes.filterRow}
            sm={12}
            md={12}
          >
            <Grid
              item
              className={clsx(classes.filterRow, classes.bedButtonMobile)}
            >
              <FilterButton
                name="Beds"
                id="bed-menu"
                onChange={(value) => setMinBed(value)}
                min={0}
                max={5}
                textAppend="+"
                stepSize={1}
                value={minBed}
              />
            </Grid>
            <Grid
              item
              className={clsx(classes.filterRow, classes.bathButtonMobile)}
            >
              <FilterButton
                name="Baths"
                id="bath-menu"
                onChange={(value) => setMinBath(value)}
                min={0}
                max={5}
                textAppend="+"
                stepSize={1}
                value={minBath}
              />
            </Grid>
          </Grid>
        </Grid>
      )
    }
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        {filtersForSuggestPlans &&
          filtersForSuggestPlans.map((filterElement, i) => {
            return (
              <Grid key={i} style={{ marginTop: '8px' }} item>
                {filterElement}
              </Grid>
            )
          })}
        <Grid item>
          <FilterButton
            name="Beds"
            id="bed-menu"
            onChange={(value) => setMinBed(value)}
            min={0}
            max={5}
            textAppend="+"
            stepSize={1}
            value={minBed}
          />
        </Grid>
        <Grid item>
          <FilterButton
            name="Baths"
            id="bath-menu"
            onChange={(value) => setMinBath(value)}
            min={0}
            max={5}
            textAppend="+"
            stepSize={1}
            value={minBath}
          />
        </Grid>
      </Grid>
    )
  }

  /**
   * This function returns a React.Fragment of all the filters
   */
  const displayFilters = (): JSX.Element => {
    return (
      <React.Fragment>
        <Grid container justifyContent="center" alignItems="center">
          {suggestPlans && displayButtonFiltersSuggestPlans()}
          {!!communities.length && (
            <Grid
              className={classes.filterRow}
              justifyContent="center"
              container
              item
              xs={12}
              lg={2}
              xl={2}
            >
              <CommunitiesSelect
                selectedCommunities={selectedCommunities}
                filteredCommunities={communities}
                onCommunityChange={(communityIds) => {
                  onCommunitiesChange && onCommunitiesChange(communityIds)
                }}
              />
            </Grid>
          )}
          {!suggestPlans && (
            <Grid
              className={classes.filterRow}
              container
              item
              justifyContent="center"
              spacing={1}
              xs={12}
              sm={12}
              lg={2}
              xl={2}
            >
              <Grid item>
                <FilterButton
                  name="Beds"
                  id="bed-menu"
                  onChange={(value) => setMinBed(value)}
                  min={0}
                  max={5}
                  textAppend="+"
                  stepSize={1}
                  value={minBed}
                />
              </Grid>
              <Grid item>
                <FilterButton
                  name="Baths"
                  id="bath-menu"
                  onChange={(value) => setMinBath(value)}
                  min={0}
                  max={5}
                  textAppend="+"
                  stepSize={1}
                  value={minBath}
                />
              </Grid>
            </Grid>
          )}

          <Grid item xs={12} lg={2} xl={2}>
            <FilterRange
              name="SQFT"
              id="sqft-range"
              onMinChange={setMinSqft}
              onMaxChange={setMaxSqft}
              rangeMin={SQFT_RANGE_MIN}
              rangeMax={SQFT_RANGE_MAX}
              stepSize={SQFT_MULTIPLIER}
              valueMin={minSqft}
              valueMax={maxSqft}
            />
          </Grid>
          <Grid item xs={12} lg={3} xl={2}>
            <FilterRange
              name="Price"
              id="price-range"
              onMinChange={setMinCost}
              onMaxChange={setMaxCost}
              rangeMin={COST_RANGE_MIN}
              rangeMax={COST_RANGE_MAX}
              stepSize={COST_MULTIPLIER}
              isCurrency={true}
              valueMin={minCost}
              valueMax={maxCost}
            />
          </Grid>

          <Grid
            className={classes.filterRow}
            container
            item
            justifyContent="center"
            xs={12}
            lg={2}
            xl={2}
          >
            <SortSelect selectedOption={sortBy} setSelectedOption={setSortBy} />
          </Grid>
          <Grid
            className={classes.filterRow}
            container
            item
            justifyContent="center"
            xs={12}
            lg={1}
            xl={1}
          >
            <TextField
              variant="standard"
              label="Search"
              value={search}
              onChange={(e): void => {
                setSearch(e.target.value)
              }}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  return (
    <Root>
      {sm ? (
        <MobileFilterBar filters={displayFilters} />
      ) : (
        <FilterBar
          customFiltersRow={
            <React.Fragment>
              {/* When responsive size, display button and pop-up modal. Otherwise display menu items */}
              {md ? (
                <MenuDialog
                  buttonText={sm ? 'Filters' : undefined}
                  alwaysShowButton={true}
                >
                  {displayFilters()}
                </MenuDialog>
              ) : (
                displayFilters()
              )}
            </React.Fragment>
          }
        />
      )}
    </Root>
  )
}

export default PlansFilterBar
