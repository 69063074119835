import { styled } from '@mui/material/styles'
import { FILTER_BAR_DOUBLE_HEIGHT } from 'components/common/filter-bar/FilterBar.styles'

const PREFIX = 'FilterButton'

export const classes = {
  searchBar: `${PREFIX}-searchBar`,
  inputFilters: `${PREFIX}-inputFilters`,
  filterBar: `${PREFIX}-filterBar`,
  selectHeightLimit: `${PREFIX}-selectHeightLimit`,
  filterRow: `${PREFIX}-filterRow`,
  filterColumn: `${PREFIX}-filterColumn`,
  paper: `${PREFIX}-paper`,
  menuDialogue: `${PREFIX}-menuDialogue`,
  bedsBathsFilters: `${PREFIX}-bedsBathsFilters`,
  featuresButton: `${PREFIX}-featuresButton`,
  filtersContainer: `${PREFIX}-filtersContainer`,
  btnGroupFilterContainer: `${PREFIX}-btnGroupFilterContainer`,
  btnGroupMenuContainer: `${PREFIX}-btnGroupMenuContainer`,
  btnGroupSelection: `${PREFIX}-btnGroupSelection`,
  btnGroupSelected: `${PREFIX}-btnGroupSelected`,
  rangeFilterMenuContainer: `${PREFIX}-rangeFilterMenuContainer`,
  rangeFilterMenuTitle: `${PREFIX}-rangeFilterMenuTitle`,
  rangeFilterFormControl: `${PREFIX}-rangeFilterFormControl`,
  bedButtonMobile: `${PREFIX}-bedButtonMobile`,
  bathButtonMobile: `${PREFIX}-bathButtonMobile`,
  suggestedPlansButtonMobile: `${PREFIX}-suggestedPlansButtonMobile`,
}

export const Root = styled('div')(({ theme }) => ({
  width: '100%',
  [`& .${classes.searchBar}`]: {
    marginLeft: '10px',
  },

  [`& .${classes.inputFilters}`]: {
    marginTop: '10px',
  },

  [`& .${classes.filterBar}`]: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    height: `${PLANS_FILTER_BAR_HEIGHT}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xl')]: {
      justifyContent: 'space-evenly',
    },
    top: '56px',
  },

  [`& .${classes.selectHeightLimit}`]: {
    maxHeight: '50%',
  },

  [`& .${classes.filterRow}`]: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('lg')]: {
      margin: '4px 0px',
      justifyContent: 'space-evenly',
    },
  },

  [`& .${classes.filterColumn}`]: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  [`& .${classes.paper}`]: {
    width: '100%',
    height: '100%',
  },

  [`& .${classes.menuDialogue}`]: {
    width: '80%',
    height: '80%',
  },

  [`& .${classes.bedsBathsFilters}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },

  [`& .${classes.featuresButton}`]: {
    maxWidth: 200,
    minWidth: 110,
  },

  [`& .${classes.filtersContainer}`]: {
    gridArea: 'mainFilters',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  [`& .${classes.btnGroupFilterContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5rem',
  },

  [`& .${classes.btnGroupMenuContainer}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '2em',
    padding: '1rem',
    paddingTop: '1rem',
  },

  [`& .${classes.btnGroupSelection}`]: {
    cursor: 'pointer',
    width: 50,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    border: '1px solid #CDCDCD',
  },

  [`& .${classes.btnGroupSelected}`]: {
    cursor: 'pointer',
    width: 50,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    border: '1px solid #CDCDCD',
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    fontWeight: 'bolder',
  },

  [`& .${classes.rangeFilterMenuContainer}`]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 3,
    marginLeft: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
    },
  },

  [`& .${classes.rangeFilterMenuTitle}`]: {
    color: theme.palette.primary.main,
    minWidth: 45,
  },

  [`& .${classes.rangeFilterFormControl}`]: {
    margin: theme.spacing(0),
    minWidth: 100,
  },

  // custom styles for mobile suggested plans button.
  [`& .${classes.bedButtonMobile}`]: {
    [theme.breakpoints.down('lg')]: {
      marginRight: '110px',
    },
  },

  [`& .${classes.bathButtonMobile}`]: {
    [theme.breakpoints.down('lg')]: {
      marginRight: '100px',
    },
  },

  [`& .${classes.suggestedPlansButtonMobile}`]: {
    [theme.breakpoints.down('lg')]: {
      marginRight: '28px',
    },
  },
}))

export const PLANS_FILTER_BAR_HEIGHT = FILTER_BAR_DOUBLE_HEIGHT
