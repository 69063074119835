import { gql } from '@apollo/client'

export const ADD_COLONNADE_USER = gql`
  mutation ADD_COLONNADE_USER(
    $clientName: String!
    $input: AddColonnadeUserInput!
  ) {
    addColonnadeUser(clientName: $clientName, input: $input)
  }
`
