import React, { useContext, useState } from 'react'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import ButtonGroup from '@mui/material/ButtonGroup'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import { useDeleteOnlineReservationMutation } from '../../../graphql/gen-types'
import { AppStore } from '../../../store'
import { gql } from '@apollo/client'
import { useEventTracker } from '../../../hooks/tracking'
import { ReservationTrackEvents } from '../reservationTrackEvents'
import { classes, StyledPopover } from './ReservationsDeletionPopoverStyles'

interface ReservationsDeletionPopoverArgs {
  popoverAnchor: (EventTarget & SVGSVGElement) | null
  closeDeletePopover: () => void
  reservationIdToDelete: string | null
}
const ReservationsDeletionPopover: React.FC<ReservationsDeletionPopoverArgs> = ({
  popoverAnchor,
  closeDeletePopover,
  reservationIdToDelete,
}): JSX.Element => {
  const { appState } = useContext(AppStore)
  const track = useEventTracker()
  const [snackBarState, setSnackBarState] = useState<{
    type: 'success' | 'error'
    message: string
  } | null>()
  const clientName = appState.selectedClient?.altName || ''
  const [deleteOnlineReservation] = useDeleteOnlineReservationMutation()

  const handleDeleteReservation = async (reservationId: string | null) => {
    try {
      track(ReservationTrackEvents.CLICKED_YES_IN_DELETION_POPOVER, {
        reservationId: reservationIdToDelete,
      })
      const result = await deleteOnlineReservation({
        variables: {
          clientName,
          reservationId,
        },
        update(cache, { data }) {
          // Change isDeleted argument in cached prospectReservation to true
          cache.writeFragment({
            id: `ProspectReservation:${data?.deleteOnlineReservation}`,
            fragment: gql`
              fragment UpdateProspectReservation on ProspectReservation {
                isDeleted
              }
            `,
            data: {
              isDeleted: true,
            },
          })
        },
      })

      closeDeletePopover()

      const { deleteOnlineReservation: resId } = result?.data || {}

      if (resId) {
        setSnackBarState({
          type: 'success',
          message: `Reservation ${reservationId} was successfully deleted!`,
        })
        return
      }

      setSnackBarState({
        type: 'error',
        message: `Reservation ${reservationId} does not exist!`,
      })
    } catch (e) {
      setSnackBarState({
        type: 'error',
        message: `Could not delete reservation!`,
      })
      console.error(e)
    }
  }

  const handleSnackBarClose = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackBarState(null)
  }

  const handleClickCancelButton = () => {
    track(ReservationTrackEvents.CLICKED_CANCEL_IN_DELETION_POPOVER, {
      reservationId: reservationIdToDelete,
    })
    closeDeletePopover()
  }

  return (
    <>
      <Snackbar
        autoHideDuration={6000}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        onClose={handleSnackBarClose}
        open={!!snackBarState}
      >
        <div>
          {snackBarState && (
            <Alert severity={snackBarState.type}>{snackBarState.message}</Alert>
          )}
        </div>
      </Snackbar>
      <StyledPopover open={Boolean(popoverAnchor)} anchorEl={popoverAnchor}>
        <div className={classes.deletePopoverContext}>
          <Typography variant="body1">
            Are you sure you want to delete this reservation?
          </Typography>
          <ButtonGroup className={classes.deletePopoverButtonHolder}>
            <Button onClick={handleClickCancelButton}>Cancel</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleDeleteReservation(reservationIdToDelete)}
            >
              Yes
            </Button>
          </ButtonGroup>
        </div>
      </StyledPopover>
    </>
  )
}

export default ReservationsDeletionPopover
