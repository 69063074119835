import React, { useContext } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { EmailCampaign } from 'graphql/gen-types'
import { AppStore } from 'store'

export default function DisplayEmail({
  emailCampaign,
}: {
  emailCampaign: EmailCampaign
}): JSX.Element {
  const { appState } = useContext(AppStore)
  const { selectedClient } = appState
  const clientAltName = selectedClient?.altName || ''
  const clientLogo = selectedClient?.logo || ''

  const editorSettings = {
    branding: false,
    height: '400px',
    width: '100%',
    content_style: `  a {
      text-align: center;
    },
    img {
      display: block;
      margin: auto;
    }
    .galleryImg {
      display: inline-block;
      width: 32%;
      height: 130px;
      position: relative;
      cursor: pointer;
      margin-left: 0.3%;
    }
    .image {
      cursor: pointer;
      position: relative;
      margin-bottom: 15px;
      max-width: 100%;
      max-height: 900px;
    }
    `,
  }

  const builderLogo = clientLogo
    ? `https://res.cloudinary.com/renderinghouse/image/upload/c_scale,h_100/app/${clientAltName}/assets/custom/${encodeURI(
        clientLogo.replace('.svg', '.png')
      )}`
    : undefined

  const community = emailCampaign.community
  const plan = emailCampaign.plan
  const elevation = emailCampaign.elevation
  const inventory = emailCampaign.inventory
  const lot = emailCampaign.lot
  const isDev = process.env.NODE_ENV === 'development'

  let url = ''
  let planName = ''
  if (community?.name) {
    url += !isDev ? 'http://localhost:7000' : 'https://myhome.anewgo.com'
    url += `/client/demo/community/${encodeURI(community.name)}`
    if (plan && plan?.name && elevation) {
      url += `/plan/${encodeURI(plan.name)}?elevId=${elevation.id}`
      planName = `${plan.name} ${elevation.caption}`
    } else if (lot?.name && !inventory) {
      planName = `Lot ${lot.name}`
      url += `/siteplan`
    } else if (lot && inventory) {
      planName = lot.address ? `${lot.address}` : `Lot ${lot.name}`
      url += `/inventory?inventoryId=${inventory.id}`
    }
  }
  const template = (campaign: EmailCampaign) => `
    <h2>Hi [lead name]</h2>
    <br />
    <p>${campaign.opener || ''}</p>

    <a href="${url}" target="_blank" style="display:block;margin:0.4em 0 1.1875em;">
      <u>
        ${planName ? `${planName} at ` : ''}${campaign.community?.name || ''}
      </u>
    </a>
    ${
      campaign.mainImage
        ? `${`<img src="${
            campaign.mainImage || ''
          }" style="width:100%;padding-bottom:15px;" />
    <br />`}`
        : ''
    }
    ${
      campaign?.templateId === 2
        ? `<div>
          ${SectionTwoImgs()}
       </div>`
        : ''
    }
    ${campaign?.templateId !== 3 ? SectionContent(0) : ''}
    ${
      campaign.templateId === 3
        ? [0, 1, 2]
            .map(
              (index) => `
                ${SectionThreeImgs(index)}
                ${SectionContent(index)}
              `
            )
            .join('')
        : ''
    }
    ${templateThreeBody()}
    ${campaign.signature ? `<p>${campaign.signature || ''}</p>` : ''}
    ${
      builderLogo
        ? `<img src="${builderLogo}" alt={{../name}} style="max-width:100%;padding-bottom:15px;" />`
        : ``
    }
  `

  const SectionTwoImgs = () =>
    emailCampaign.sections?.[0]?.images
      ?.map(
        (img) => `
        <img class="galleryImg" src=${img.src} />`
      )
      .join('')
  const SectionThreeImgs = (index: number) =>
    emailCampaign.sections?.[index]?.images
      ?.map(
        (img) => `
        <div class="image">
          <img class="image" src=${img.src} />
        </div>`
      )
      .join('')

  const SectionContent = (index: number) =>
    `<p>${emailCampaign.sections?.[index]?.content || ''}</p>`

  const templateThreeBody = () =>
    (emailCampaign.templateId === 3 && emailCampaign.body) || ''
  return (
    <Editor
      value={template(emailCampaign) || undefined}
      init={editorSettings}
      disabled={true}
    />
  )
}
