import React from 'react'
import ComponentCard from 'components/common/layout/ComponentCard'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

const COMPONENT_TITLE = 'Import Leads'

export default function ImportLeads() {
  return (
    <ComponentCard title={COMPONENT_TITLE}>
      <Grid container justifyContent="flex-start">
        <Box style={{ paddingBottom: 16 }}>
          <Typography variant="subtitle1">
            Import leads from a CSV file
          </Typography>
        </Box>
      </Grid>
      <Grid container justifyContent="flex-end" spacing={2}>
        {/* Replace with file upload input when working on this functionality */}
        <Grid item xs={12}>
          <TextField
            data-testid="assignment-import-leads-input"
            fullWidth
            placeholder="Select a file ..."
            variant="standard"
            disabled
          />
        </Grid>
        <Grid container item xs={12} justifyContent="flex-end">
          <Button
            data-testid="assignment-import-leads-file-button"
            disabled
            variant="text"
            color="primary"
          >
            Select File
          </Button>
          <Button
            data-testid="assignment-import-leads-button"
            disabled
            variant="contained"
            color="primary"
          >
            Import
          </Button>
        </Grid>
      </Grid>
    </ComponentCard>
  )
}
