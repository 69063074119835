import TableSortLabel from '@mui/material/TableSortLabel'
import { styled } from '@mui/material/styles'

export const TableSortLabelStyled = styled(TableSortLabel)({
  '& .MuiTableSortLabel-root': {
    marginRight: '-14px',
  },

  '& .MuiTableSortLabel-active': {
    height: '12px',
  },

  '& .MuiTableSortLabel-icon': {
    fontWeight: 'bold',
  },
})

export const VisuallyHidden = styled('span')({
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: 1,
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  top: 20,
  width: 1,
})
