import React, { useContext, useEffect, useMemo, useState } from 'react'
import { AppStore } from 'store'
import {
  useGetAgentsQuery,
  Agent,
  useGetUsersQuery,
  ImportAgentError,
  User,
  useImportAgentsMutation,
  useImportProspectsToColonnadeAgentsMutation,
  useGetProspectsLazyQuery,
} from 'graphql/gen-types'
import ComponentCard from 'components/common/layout/ComponentCard'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Skeleton from '@mui/material/Skeleton'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Tooltip from '@mui/material/Tooltip'
import USERS_QUERY from 'graphql/colonnade-queries/users'
import ErrorIcon from '@mui/icons-material/Error'
import LoadingButton from '@mui/lab/LoadingButton'
import { useAtom } from 'jotai'
import { snackbarConfigAtom } from 'store/atoms'

const COMPONENT_TITLE = 'Import Agents From My Home App'

const getAgentInitials = (agent: Agent): string => {
  return (agent.lastName?.length > 0
    ? `${agent.firstName[0]}${agent.lastName[0]}`
    : agent.firstName.slice(0, 2)
  ).toUpperCase()
}

interface ImportAgentsRowProps {
  agent: Agent
  selected: boolean
  error?: string
  onSelect: (agent: Agent, checked: boolean) => void
}

function ImportAgentsRow({
  agent,
  selected,
  error,
  onSelect,
}: ImportAgentsRowProps) {
  const { appState } = useContext(AppStore)
  const clientName = appState.selectedClient?.altName || ''

  return (
    <Tooltip
      title={error || ''}
      disableFocusListener={!error}
      disableHoverListener={!error}
      disableTouchListener={!error}
    >
      <TableRow
        data-testid={`assignment-import-agent-${agent.id}`}
        hover
        role="checkbox"
        tabIndex={-1}
        key={agent.id}
        onClick={() => !error && onSelect(agent, !selected)}
        sx={{ cursor: 'pointer' }}
      >
        <TableCell>
          {error ? (
            <ErrorIcon color="error" />
          ) : (
            <Checkbox
              data-testid={`assignment-import-agent-${agent.id}-select`}
              checked={selected}
            />
          )}
        </TableCell>
        <TableCell>
          <Avatar
            src={`https://res.cloudinary.com/renderinghouse/image/upload/app/${clientName}/assets/agents/${agent.picture}`}
          >
            {getAgentInitials(agent)}
          </Avatar>
        </TableCell>
        <TableCell>
          {agent.firstName} {agent.lastName}
        </TableCell>
        <TableCell>{agent.email}</TableCell>
      </TableRow>
    </Tooltip>
  )
}

interface ImportAgentsDialogProps {
  open: boolean
  onClose: () => void
}

function ImportAgentsDialog({ open, onClose }: ImportAgentsDialogProps) {
  const { appState } = useContext(AppStore)
  const [snackbarConfig, setSnackbarConfig] = useAtom(snackbarConfigAtom)
  const clientName = appState.selectedClient?.altName || ''
  const {
    data: agentsData,
    loading: agentsLoading,
    error: agentsError,
  } = useGetAgentsQuery({
    variables: { clientName },
  })

  const {
    data: usersData,
    loading: usersLoading,
    error: usersError,
  } = useGetUsersQuery({
    variables: {
      clientName,
    },
  })

  const [importAgents] = useImportAgentsMutation()
  const [importProspects] = useImportProspectsToColonnadeAgentsMutation()
  const [refetchProspects] = useGetProspectsLazyQuery()

  const agents = useMemo(() => {
    if (agentsLoading || agentsError) return []
    return agentsData?.agentsByClient || []
  }, [agentsData, agentsLoading, agentsError])

  const users = useMemo(() => {
    if (usersLoading || usersError) return []
    return usersData?.colonnadeUsers || []
  }, [usersData, usersLoading, usersError])

  // Filter out agents that already exist in the app
  const filteredAgents = useMemo(
    () =>
      agents?.filter(
        (agent) => !users.some((user) => agent.email === user.email)
      ),
    [agents, users]
  )

  const [selectedAgents, setSelectedAgents] = useState<Agent[]>([])
  const [errors, setErrors] = useState(new Map())
  const [submitting, setSubmitting] = useState(false)

  const loading = agentsLoading || usersLoading

  // Unselect any agents that already exist, or that we can't import
  useEffect(() => {
    setSelectedAgents((selectedAgents) =>
      selectedAgents.filter(
        (agent) =>
          !errors.get(agent.id) &&
          !filteredAgents.some((filteredAgent) => agent.id === filteredAgent.id)
      )
    )
  }, [filteredAgents, errors])

  // When the component is opened and finishes loading, select all potential agents
  useEffect(() => {
    if (!loading) {
      setSelectedAgents(filteredAgents)
    }
    // We don't want this to run every time the filteredAgents array changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  if (loading) {
    return (
      <Dialog open onClose={onClose}>
        <DialogTitle>{COMPONENT_TITLE}</DialogTitle>
        <DialogContent>
          <Skeleton variant="rectangular" width={400} height={800} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" disabled>
            Submit
          </Button>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const triggerSnackbar = (
    message: string,
    severity: 'error' | 'success' | 'info' | 'warning' | undefined
  ) => {
    setSnackbarConfig({
      ...snackbarConfig,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      autoHideDuration: 6000,
      open: true,
      message,
      severity,
    })
  }

  const handleSelect = (agent: Agent, checked: boolean) => {
    if (checked) {
      setSelectedAgents((agents) => [...agents, agent])
    } else {
      setSelectedAgents((agents) =>
        agents.filter((selected) => selected.id !== agent.id)
      )
    }
  }

  const handleSubmit = async () => {
    setSubmitting(true)

    const newUsers = new Map<Agent['id'], User>()
    const errors = new Map<Agent['id'], ImportAgentError>()

    try {
      await importAgents({
        variables: {
          clientName,
          input: selectedAgents.map((agent) => ({
            agentId: agent.id,
            firstName: agent.firstName,
            lastName: agent.lastName,
            email: agent.email,
          })),
        },
        update(cache, data) {
          if (data.errors || !data?.data) return

          const results = data.data.importAgentsAsColonnadeUsers

          results.forEach((result) => {
            if (result.__typename === 'ImportAgentSuccess') {
              newUsers.set(result.agentId, result.user)
            } else if (result.__typename === 'ImportAgentError') {
              errors.set(result.agentId as any, result)
            }
          })

          cache.writeQuery({
            query: USERS_QUERY,
            variables: { clientName },
            data: {
              colonnadeUsers: [...users, ...newUsers.values()],
            },
          })
        },
      })

      const assignments = Array.from(
        newUsers.entries()
      ).map(([agentId, user]) => ({ agentId: agentId, userId: user.id! }))

      await importProspects({
        variables: {
          clientName,
          assignments,
        },
      })

      await refetchProspects({
        variables: {
          clientName,
        },
      })

      // Display notification for the user
      // These errors should only happen when importing
      // an agent that exists on a different client.
      // This should only really happen on the demo/rhtraining clients.
      if (errors.size > 0 && newUsers.size === 0) {
        // No new users, but some errors
        errors.forEach((error) => {
          triggerSnackbar(error.message, 'error')
        })
      } else if (errors.size > 0 && newUsers.size > 0) {
        // Some users got imported, others did not
        triggerSnackbar('Some agents could not be imported!', 'warning')
      } else {
        // All users imported correctly
        triggerSnackbar('Successfully imported agents!', 'success')
      }
    } catch (error) {
      // Unlock the UI and rethrow this error
      setSubmitting(false)
      throw error
    } finally {
      setErrors(errors)
      setSubmitting(false)
    }
  }

  const handleClose = () => {
    if (!submitting) {
      onClose()
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle>{COMPONENT_TITLE}</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table>
            <TableBody>
              {filteredAgents?.map((agent) => (
                <ImportAgentsRow
                  agent={agent}
                  onSelect={handleSelect}
                  selected={selectedAgents.some(
                    (selected) => selected.id === agent.id
                  )}
                  error={errors.get(agent.id)?.message}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          data-testid="assignment-import-agent-submit"
          variant="contained"
          color="primary"
          loadingPosition="start"
          disabled={selectedAgents.length === 0}
          loading={submitting}
          onClick={handleSubmit}
        >
          Submit
        </LoadingButton>
        <Button
          data-testid="assignment-import-agent-cancel"
          variant="text"
          disabled={submitting}
          onClick={handleClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default function ImportAgents() {
  const [dialogOpen, setDialogOpen] = useState(false)

  return (
    <>
      <ImportAgentsDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
      <ComponentCard title={COMPONENT_TITLE}>
        <Grid container justifyContent="flex-start">
          <Box style={{ paddingBottom: 16 }}>
            <Typography variant="subtitle1">
              Import agents who registered on My Home App
            </Typography>
          </Box>
        </Grid>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item xs={12}>
            {/* A hidden text field to keep the size consistent with Import Leads*/}
            <TextField
              fullWidth
              variant="standard"
              disabled
              style={{ visibility: 'hidden' }}
            />
          </Grid>
          <Grid item>
            <Button
              data-testid="assignment-import-agent-button"
              variant="contained"
              color="primary"
              onClick={() => setDialogOpen(true)}
            >
              Import
            </Button>
          </Grid>
        </Grid>
      </ComponentCard>
    </>
  )
}
