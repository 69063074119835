import React, { useEffect, useCallback, useState } from 'react'
import { Community, ReservationBuilderStatus } from '../../../graphql/gen-types'
import Grid from '@mui/material/Grid/Grid'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { debounce } from 'components/utils/debounce'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Root, classes } from './ReservationsListFiltersStyles'
import { filterChangeArgs } from './ReservationsListTypes'
import { ReservationTrackEvents } from '../reservationTrackEvents'
import { useEventTracker } from 'hooks/tracking'

interface ReservationsListFiltersProps {
  communities: Community[]
  onFilterChange: (filterArgs: filterChangeArgs) => void
}

const defaultFilterValues = {
  buyerInfo: '',
  communityId: -1,
  status: 'NONE',
  includeDeleted: false,
}

const debounceTime = 350

const ReservationsListFilters: React.FC<ReservationsListFiltersProps> = ({
  communities,
  onFilterChange,
}) => {
  const track = useEventTracker()
  const [buyerInfo, setBuyerInfo] = useState<string>(
    defaultFilterValues.buyerInfo
  )
  const [communityId, setCommunityId] = useState<number>(
    defaultFilterValues.communityId
  )
  const [status, setStatus] = useState<ReservationBuilderStatus | string>(
    defaultFilterValues.status
  )
  const [includeDeleted, setIncludeDeleted] = useState<boolean>(
    defaultFilterValues.includeDeleted
  )

  const handleDebouncedBuyerInfoChange = useCallback(
    debounce(
      (value: string) =>
        track(ReservationTrackEvents.CHANGE_BUYER_EMAIL_OR_NAME_FILTER, {
          value,
        }),
      debounceTime
    ),
    []
  )

  const handleBuyerInfoChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const value = event.target.value as string

    // This method only handle event tracking
    // Not send every value
    // But only if user stop typing
    handleDebouncedBuyerInfoChange(value)
    setBuyerInfo(value)
  }

  const handleCommunityChange = (event: SelectChangeEvent<number>) => {
    const communityId = event.target.value as number
    track(ReservationTrackEvents.CHANGE_COMMUNITY_FILTER, {
      communityId,
    })
    setCommunityId(communityId)
  }

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    const status = event.target.value as ReservationBuilderStatus | string
    track(ReservationTrackEvents.CHANGE_STATUS_FILTER, {
      status,
    })
    setStatus(status)
  }

  const handleStateChange = (
    event: React.ChangeEvent<{ checked: boolean }>
  ) => {
    const includeDeleted = event.target.checked
    track(ReservationTrackEvents.SWITCH_INCLUDE_DELETED_FILTER, {
      includeDeleted,
    })
    setIncludeDeleted(includeDeleted)
  }

  const handleChange = useCallback(
    debounce(
      (filterArgs: filterChangeArgs) => onFilterChange(filterArgs),
      debounceTime
    ),
    []
  )

  // Handle change filters
  // Call debounce method handleChange
  useEffect(() => {
    handleChange({
      buyerInfo: buyerInfo || null,
      communityId: communityId === -1 ? null : communityId,
      status: status === 'NONE' ? null : (status as ReservationBuilderStatus),
      includeDeleted: includeDeleted,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyerInfo, communityId, status, includeDeleted])

  const resetFilters = () => {
    track(ReservationTrackEvents.CLICKED_RESET_FILTER, {})
    setBuyerInfo(defaultFilterValues.buyerInfo)
    setCommunityId(defaultFilterValues.communityId)
    setStatus(defaultFilterValues.status)
    setIncludeDeleted(defaultFilterValues.includeDeleted)
  }

  return (
    <Root className={classes.root}>
      <Grid container spacing={2} columns={13}>
        <Grid item xs={12}>
          <Typography variant="body1" className={classes.filterHeader}>
            Filters:
          </Typography>
        </Grid>
        <Grid item xs={12} lg={4} xl={3}>
          <FormControl className={classes.input} variant="standard">
            <TextField
              variant="standard"
              value={buyerInfo}
              placeholder="John Doe, john.doe@anewgo.com"
              onChange={handleBuyerInfoChange}
              label="Buyer email or name"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <FormControl className={classes.input} variant="standard">
            <InputLabel>Community</InputLabel>
            <Select
              variant="standard"
              value={communityId}
              onChange={handleCommunityChange}
            >
              <MenuItem value={-1}>All Communities</MenuItem>
              {communities.map((community) => (
                <MenuItem value={community.id} key={community.id}>
                  {community.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <FormControl className={classes.input} variant="standard">
            <InputLabel>Status</InputLabel>
            <Select
              value={status}
              onChange={handleStatusChange}
              variant="standard"
            >
              <MenuItem value={'NONE'}>All Statuses</MenuItem>
              <MenuItem value={ReservationBuilderStatus.InProgress}>
                IN PROGRESS
              </MenuItem>
              <MenuItem value={ReservationBuilderStatus.ApprovedByBuilder}>
                APPROVED
              </MenuItem>
              <MenuItem value={ReservationBuilderStatus.RejectedByBuilder}>
                REJECTED BY BUILDER
              </MenuItem>
              <MenuItem value={ReservationBuilderStatus.RejectedByProspect}>
                REJECTED BY PROSPECT
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <FormControlLabel
            className={`${classes.input} ${classes.checkboxControl}`}
            control={
              <Checkbox checked={includeDeleted} onChange={handleStateChange} />
            }
            label="Include Deleted"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<RefreshIcon />}
            onClick={resetFilters}
          >
            Reset Filter
          </Button>
        </Grid>
      </Grid>
    </Root>
  )
}

export default ReservationsListFilters
