import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { ClientEmailReportInfo } from 'graphql/gen-types'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import hydrationStore from 'store/HydrationStore'
import ReportInfoTable from './report-info/ReportInfoTable'
import { GET_CLIENT_REPORT_INFO } from 'graphql/auth-queries/authDb'

const AdminSettings = (): JSX.Element => {
  const [emailReportsData, setEmailReportsData] = useState<
    ClientEmailReportInfo[]
  >([])
  const clientId = hydrationStore.selectedClient?.id

  const { data: reportInfo, loading, error } = useQuery(
    GET_CLIENT_REPORT_INFO,
    {
      variables: {
        clientId,
      },
    }
  )

  // set the report info once we have the data
  useEffect(() => {
    if (!reportInfo) {
      return
    }

    const {
      getClientEmailReportInfo,
    }: { getClientEmailReportInfo: ClientEmailReportInfo[] } = reportInfo
    setEmailReportsData(getClientEmailReportInfo)
  }, [reportInfo])

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized message={error.toString()} imageName={'LeadRankings'} />
      )
    }
    console.error(error)
  }

  return (
    <ReportInfoTable
      emailReportsData={emailReportsData}
      setEmailReportsData={setEmailReportsData}
    />
  )
}

export default AdminSettings
