import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

const PREFIX = 'PageReferralsTable'

export const classes = {
  customTooltip: `${PREFIX}-customTooltip`,
}

export const CustomTooltipStyled = styled('div')(({ theme }) => ({
  [`&.${classes.customTooltip}`]: {
    backgroundColor: theme.palette.background.default,
    border: '1px solid rgb(204, 204, 204)',
    margin: '0px',
    padding: '10px',
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.light
        : theme.palette.common.black,
  },
}))

export const HelpBlock = styled('div')({
  width: '100%',
  marginTop: '0.40em',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
})

export const HelpText = styled(Typography)({
  fontSize: '14px',
  marginLeft: '0.25em',
  marginTop: '0.1em',
})
