import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

import { PopularElevationSpec, Specification } from 'graphql/gen-types'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Label,
} from 'recharts'
import { IndustryTrendsYAxisLabel } from 'components/common/charts/custom/CustomAxisLabel'
import useMediaQuery from '@mui/material/useMediaQuery'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Tooltip } from 'recharts'

const INDUSTRY_COLOR = 'darkslateblue'

interface ElevationSpecificationsPanelProps {
  data: PopularElevationSpec | undefined
  industryData?: PopularElevationSpec
  isIndustry?: boolean | false
}

type SpecificationKey = 'bed' | 'bath' | 'size' | 'price'
type IndustrySpecificationKey =
  | 'industryBed'
  | 'industryBath'
  | 'industrySize'
  | 'industryPrice'

type MergedSpecificationKey = SpecificationKey | IndustrySpecificationKey

type Specifications = {
  bed: Specification[]
  bath: Specification[]
  size: Specification[]
  price: Specification[]
  industryBed?: Specification[]
  industryBath?: Specification[]
  industrySize?: Specification[]
  industryPrice?: Specification[]
}

type IndustrySpecification = {
  value: number
  count: number
  industryCount: number
}

const industryField = {
  bed: 'industryBed',
  bath: 'industryBath',
  size: 'industrySize',
  price: 'industryPrice',
}

const nameMap: Record<string, string> = {
  bed: 'Bedroom',
  bath: 'Bathroom',
  price: 'Price',
  size: 'Square Foot',
}

// Functional Component
export default function ElevationSpecificationsPanel({
  data,
  industryData,
  isIndustry,
}: ElevationSpecificationsPanelProps): JSX.Element {
  // Ensuring includeDurationZero is always bool even when not set / defined.
  const [tabValue, setTabValue] = React.useState(0)
  const [specifications, setSpecifications] = useState<Specifications>()
  const [selectValue, setSelectValue] = React.useState<MergedSpecificationKey>(
    'bed'
  )
  const theme = useTheme()
  const smallSize = useMediaQuery(theme.breakpoints.down('md'))

  const handleSelectChange = (
    event: SelectChangeEvent<MergedSpecificationKey>
  ) => {
    setSelectValue(event.target.value as MergedSpecificationKey)
  }

  const handleChange = (
    // eslint-disable-next-line
    event: React.ChangeEvent<{}>,
    newValue: number
  ): void => {
    setTabValue(newValue)
  }

  useEffect(() => {
    if (data) {
      const specs: Specifications = {
        bed: data.bed,
        bath: data.bath,
        size: data.size,
        price: data.price,
        industryBed: industryData?.bed,
        industryBath: industryData?.bath,
        industrySize: industryData?.size,
        industryPrice: industryData?.price,
      }

      setSpecifications(specs)
    }
  }, [data, industryData])

  // Price array is sometimes empty, since some clients don't contain price data
  const showPrice = specifications?.price && specifications?.price?.length > 0

  if (!specifications) {
    return <div />
  }

  return smallSize ? (
    <div>
      <Select
        variant="standard"
        value={selectValue}
        onChange={handleSelectChange}
        style={{ marginBottom: theme.spacing(1) }}
      >
        <MenuItem value={'bed'}>{nameMap['bed']}</MenuItem>
        <MenuItem value={'bath'}>{nameMap['bath']}</MenuItem>
        <MenuItem value={'size'}>{nameMap['size']}</MenuItem>
        {showPrice && <MenuItem value={'price'}>{nameMap['price']}</MenuItem>}
      </Select>
      <PopularPlanSpecChart
        data={specifications}
        field={selectValue}
        name={nameMap[selectValue]}
        isIndustry={isIndustry}
      />
    </div>
  ) : (
    <>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        <Tab label="Bedrooms" />
        <Tab label="Bathrooms" />
        <Tab label="Square Foot" />
        {showPrice && <Tab label="Price" />}
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        <PopularPlanSpecChart
          data={specifications}
          field={'bed'}
          name={nameMap['bed']}
          isIndustry={isIndustry}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <PopularPlanSpecChart
          data={specifications}
          field={'bath'}
          name={nameMap['bath']}
          isIndustry={isIndustry}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <PopularPlanSpecChart
          data={specifications}
          field={'size'}
          name={nameMap['size']}
          isIndustry={isIndustry}
        />
      </TabPanel>
      {/* Conditionally render price bar chart tab */}
      {showPrice && (
        <TabPanel value={tabValue} index={3}>
          <PopularPlanSpecChart
            data={specifications}
            field={'price'}
            name={'Price'}
            isIndustry={isIndustry}
          />
        </TabPanel>
      )}
    </>
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  name?: string
  index: number
  value: number
}

function TabPanel(props: TabPanelProps): JSX.Element {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

interface PopularSpecChartProps {
  data: Specifications
  field: MergedSpecificationKey
  name: string
  isIndustry?: boolean
}

function PopularPlanSpecChart({
  data,
  field,
  name,
  isIndustry,
}: PopularSpecChartProps): JSX.Element {
  const theme = useTheme()
  // chart axis stroke
  const axisColor =
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.87)'
      : 'rgba(0, 0, 0, 0.87)'

  // If no data, don't render
  if (!data) {
    return <Typography>No data to display.</Typography>
  }

  const clientSpecifications: Specification[] = data[field] || []
  const industrySpecifications: Specification[] =
    data[industryField[field]] || []

  // If no data, don't render
  if (
    !clientSpecifications.length ||
    (isIndustry && !industrySpecifications.length)
  ) {
    return <Typography>No data to display.</Typography>
  }

  // Merge specds with industry if in industry mode.
  const allSpecifications = isIndustry
    ? mergeIndustrySpecificationData(
        industrySpecifications,
        clientSpecifications
      )
    : clientSpecifications

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={allSpecifications}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <XAxis dataKey="value" tick={{ fill: axisColor }}></XAxis>
        <YAxis tick={{ fill: axisColor }}>
          <Label
            value="Popularity"
            stroke={axisColor}
            content={<IndustryTrendsYAxisLabel />}
          />
        </YAxis>
        {isIndustry && <Legend />}
        <Bar dataKey="count" fill={theme.palette.primary.main} name={`Count`} />
        {isIndustry && (
          <Bar
            dataKey="industryCount"
            fill={INDUSTRY_COLOR}
            name={`Industry Average`}
          />
        )}
      </BarChart>
    </ResponsiveContainer>
  )
}

function mergeIndustrySpecificationData(
  industryData: Specification[],
  clientData: Specification[]
): IndustrySpecification[] {
  return industryData.map((spec, i) => {
    const clientCount =
      clientData.find((clientSpec) => clientSpec.value === spec.value)?.count ||
      0

    return {
      value: spec.value,
      count: clientCount,
      industryCount: spec.count,
    }
  })
}
