import React, { useContext, useState } from 'react'
import { AppStore } from 'store'
import {
  Community,
  CommonQueryFilter,
  PopularityMetric,
  ColonnadeSubscriptionLevelsEnum,
} from 'graphql/gen-types'

import { AnalyticsStore } from 'store/analyticsStore'

import ColorTrends from 'components/analytics/color-trends/ColorTrends'
import CommunitiesTrends from 'components/analytics/communities-trends/CommunitiesTrends'
import TrussElevationTrends from 'components/analytics/elevation-trends/TrussElevationTrends'
import TrussFloorplanTrends from 'components/analytics/floorplan-trends/TrussFloorplanTrends'
import SchemeTrends from 'components/analytics/scheme-trends/SchemeTrends'
import VisitorLocations from 'components/analytics/visitor-locations/VisitorLocations'
import LocationsFiltersBar from '../../components/analytics/locations-filters/LocationsFiltersBar'
import { PopularElevationsWrapper } from 'components/analytics/popular-elevations/PopularElevations'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import SiteplanGrid from '../../components/common/siteplan/SiteplanGrid'
import { Navigate } from 'react-router-dom'
import { STARTER_INVALID_FEATURE_MESSAGE } from '../../constants'
import { userHasSubscriptionAccess } from 'utils/authorizationHelpers'
import RecentFavorites from 'components/analytics/recent-favorites/RecentFavorites'
import ComponentCard from 'components/common/layout/ComponentCard'
import { classes, Root } from './Locations.styles'
// Minimum number of trends to display. If more than 5 communities are selected, display more, else minimum.
const MIN_TRENDS_DISPLAY = 5

function Locations(): JSX.Element {
  const { appState } = useContext(AppStore)
  const { selectedClient } = appState
  const { analyticsState } = useContext(AnalyticsStore)
  const { startDate, endDate } = analyticsState
  const clientName = selectedClient?.altName || ''

  // SELECTIONS STATE
  const [selectedCommunities, setSelectedCommunities] = useState<
    Community[] | undefined
  >(undefined)
  const [
    selectedPopularityMetric,
    setSelectedPopularityMetric,
  ] = useState<PopularityMetric>(PopularityMetric.Frequency)
  const [areaChart, setAreaChart] = useState(false)

  const PRO_OR_ENTERPRISE_SUBSCRIBED = userHasSubscriptionAccess([
    ColonnadeSubscriptionLevelsEnum.Pro,
    ColonnadeSubscriptionLevelsEnum.Enterprise,
  ])

  if (!PRO_OR_ENTERPRISE_SUBSCRIBED) {
    const selectedClientName = selectedClient ? selectedClient.altName : ''
    return (
      <Navigate
        to={`/analytics/client/${selectedClientName}/overview?subscriptionLevelErrorMessage=${STARTER_INVALID_FEATURE_MESSAGE}`}
      />
    )
  }

  const limit =
    selectedCommunities && selectedCommunities?.length < MIN_TRENDS_DISPLAY
      ? MIN_TRENDS_DISPLAY
      : selectedCommunities?.length || 0

  const baseCommonFilter: CommonQueryFilter = {
    startTime: startDate,
    endTime: endDate,
    metric: selectedPopularityMetric,
    limit,
    clientName,
    communities: selectedCommunities?.length
      ? selectedCommunities.map((comm) => comm.id)
      : undefined,
  }

  const filter = {
    clientName: appState.selectedClient?.altName || '',
    startTime: analyticsState.startDate.getTime(),
    endTime: analyticsState.endDate.getTime(),
    metric: PopularityMetric.Popularity,
    limit: 150,
  }

  return (
    <Root>
      <React.Fragment>
        <Grid container>
          {/* If / When we want Infinite scrolling on this page, implement as seen in /Overview page. */}
          {/* <InfiniteScroll
            sectionComponents={sectionComponents}
            rootless={false}
          /> */}
          <Grid item xs={12}>
            <LocationsFiltersBar
              selectTopNCommunities={5}
              onSelectedCommunities={setSelectedCommunities}
              onSelectedPopularityMetric={setSelectedPopularityMetric}
              onAreaChartChange={setAreaChart}
            />
          </Grid>

          {!selectedCommunities !== undefined &&
          selectedCommunities?.length === 0 ? (
            <div className={classes.root}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                className={classes.componentContainer}
                style={{ minHeight: '300px' }}
              >
                <Grid item>
                  <ComponentCard>
                    <Typography variant="h6">
                      Select communities to view analytics.
                    </Typography>
                  </ComponentCard>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div className={classes.root}>
              <Grid
                container
                spacing={4}
                className={classes.componentContainer}
              >
                <Grid item xs={12}>
                  <VisitorLocations filter={baseCommonFilter} />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <RecentFavorites filter={baseCommonFilter} />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <CommunitiesTrends
                    filter={baseCommonFilter}
                    selectedCommunities={selectedCommunities}
                    areaChart={areaChart}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TrussElevationTrends
                    filter={baseCommonFilter}
                    areaChart={areaChart}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TrussFloorplanTrends
                    filter={baseCommonFilter}
                    areaChart={areaChart}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ColorTrends
                    filter={{
                      commonFilter: {
                        ...baseCommonFilter,
                        limit: 10,
                      },
                    }}
                    areaChart={areaChart}
                  />
                </Grid>
                <Grid item xs={12} lg={8}>
                  <SchemeTrends
                    filter={baseCommonFilter}
                    areaChart={areaChart}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PopularElevationsWrapper
                    directoryName={appState.selectedClient?.directoryName || ''}
                    filter={baseCommonFilter}
                  />
                </Grid>
                {selectedCommunities?.length && (
                  <Grid item xs={12}>
                    <SiteplanGrid
                      communityIds={selectedCommunities?.map(
                        (community: Community) => community.id
                      )}
                      filter={filter}
                      clientName={selectedClient?.altName || ''}
                    />
                  </Grid>
                )}
              </Grid>
            </div>
          )}
        </Grid>
      </React.Fragment>
    </Root>
  )
}

export default Locations
