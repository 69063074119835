import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import DateFnsAdapter from '@date-io/date-fns'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { classes, Root } from './EmailReport.styles'
import SessionsSummary from 'components/analytics/sessions/SessionsSummary'
import EngagementRate from 'components/analytics/sessions/EngagementRate'
import PopularCommunities, {
  POPULAR_COMMUNITIES_DISPLAY_LIST,
} from 'components/analytics/popular-communities/PopularCommunities'
import { getAssetsPrefix } from '@anewgo/utils'
import {
  BaseFilter,
  SessionFilter,
  CommonQueryFilter,
  PopularityMetric,
  LeadsQueryFilter,
  useLeadRankingsQuery,
  useGetClientReportInfoQuery,
  Client,
  useGetClientByIdQuery,
  DateFilterField,
  Community,
  useCommunitiesQuery,
  ColonnadeReportFrequencyEnum,
  ClientEmailReportInfo,
} from '../../graphql/gen-types'
import VisitorTrends from 'components/leads/analytics/VisitorTrends'
import LeadRankingsChart from 'components/leads/LeadRankingsChart'
import LeadsByCommunityChart from 'components/leads/LeadsByCommunityChart'
import { useLocation } from 'react-router-dom'

function useRouteQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search)
}

// interface EmailReportProps {}
const dateFns = new DateFnsAdapter()
const initialSubscriptionState: ClientEmailReportInfo[] = []

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function EmailReport(): JSX.Element {
  let pageIndex = 1
  let daysToSubtract = 1

  const query = useRouteQuery()
  const email = query.get('email') || ''
  const clientId = query.get('clientId') || '0'
  const [reportInfo, setReportInfo] = useState(initialSubscriptionState)
  const [client, setClient] = useState<Client>()
  const [
    leadChartSelectedCommunities,
    setLeadChartSelectedCommunities,
  ] = useState<Community[]>([])
  /* Get Client */
  const getClientResult = useGetClientByIdQuery({
    variables: {
      clientId: parseInt(clientId, 10),
    },
  })

  // set client
  useEffect(() => {
    const clientById = getClientResult?.data?.clientById as Client
    if (clientById) {
      setClient(clientById)
    }
  }, [getClientResult])

  const logoSrc =
    client && `${getAssetsPrefix(client.directoryName)}/custom/${client.logo}`

  const selectedClientName = client?.altName
  const { data: reportData } = useGetClientReportInfoQuery({
    variables: {
      clientId: parseInt(clientId, 10) || 0,
    },
    skip: !clientId,
  })

  // set report info data
  useEffect(() => {
    if (reportData && reportData.getClientEmailReportInfo) {
      setReportInfo(reportData.getClientEmailReportInfo)
    }
  }, [reportData])

  // Fetch all communities for client
  const communitiesQuery = useCommunitiesQuery({
    variables: { clientName: selectedClientName || '' },
    skip: !client,
  })

  // set communities data
  useEffect(() => {
    if (communitiesQuery?.data?.communities) {
      const filteredCommunities = communitiesQuery.data.communities.filter(
        (community) => community?.active === true
      ) as Community[]
      setLeadChartSelectedCommunities &&
        setLeadChartSelectedCommunities(filteredCommunities)
    }
  }, [communitiesQuery.data, setLeadChartSelectedCommunities])

  const subscriptionFrequency = reportInfo?.find(
    (info) => info?.email === email
  )?.frequency

  switch (subscriptionFrequency) {
    case ColonnadeReportFrequencyEnum.Never:
      throw new Error('Cannot generate report, frequency set to NEVER')
    case ColonnadeReportFrequencyEnum.Daily:
      daysToSubtract = 1
      break
    case ColonnadeReportFrequencyEnum.Weekly:
      daysToSubtract = 7
      break
    case ColonnadeReportFrequencyEnum.Monthly:
      daysToSubtract = 30
      break
    case ColonnadeReportFrequencyEnum.Annually:
      daysToSubtract = 365
      break
  }

  const endDate = dateFns.endOfDay(new Date())
  const startDate = dateFns.startOfDay(
    dateFns.addDays(dateFns.date(endDate), -daysToSubtract)
  )
  const startTime = dateFns.format(startDate, 'MM-dd-yyyy-HH:mm:ss')
  const endTime = dateFns.format(endDate, 'MM-dd-yyyy-HH:mm:ss')

  const visitorsFilter: CommonQueryFilter = {
    clientName: selectedClientName || '',
    startTime: '',
    endTime: '',
  }

  const leadsFilter: LeadsQueryFilter = {
    common: {
      ...visitorsFilter,
      startTime: startTime,
      endTime: endTime,
    },
    leads: { dateFilterField: DateFilterField.LastSeen },
  }

  const { data, loading, error } = useLeadRankingsQuery({
    variables: {
      filter: leadsFilter,
      clientName: selectedClientName || '',
    },
  })
  if (!selectedClientName) {
    return <Root>Loading...</Root>
  }

  const baseInfo: BaseFilter = {
    clientName: selectedClientName,
    dateTimeRange: {
      start: startTime,
      end: endTime,
    },
  }

  const baseCommonFilter: CommonQueryFilter = {
    clientName: selectedClientName,
    startTime,
    endTime,
    limit: 5,
    metric: PopularityMetric.Frequency,
  }
  const baseCommonPopularityFilter: CommonQueryFilter = {
    ...baseCommonFilter,
    metric: PopularityMetric.Popularity,
  }

  // Unchanged filters (for now)
  const sessionFilter: SessionFilter = {
    baseInfo: baseInfo,
    includeDurationZero: false,
  }

  const renderFooter = (pageCount: number) => {
    return (
      <Root>
        <div className={classes.footer}>
          <Typography variant="caption">
            Page {pageIndex++} of {pageCount}
          </Typography>
        </div>
      </Root>
    )
  }

  const renderHeader = () => {
    return (
      <Root>
        <div className={classes.header}>
          <div className={classes.leftHeader}>
            <div className={classes.builderLogoContainer}>
              <img alt="builder-logo" src={logoSrc} />
            </div>
          </div>
        </div>
      </Root>
    )
  }
  const GRID_SPACING = 1

  const sectionComponents = [
    <Root>
      <Grid
        container
        className={classes.componentContainer}
        spacing={GRID_SPACING}
      >
        <Grid item xs={12}>
          <SessionsSummary filter={sessionFilter} report />
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.componentContainer}
        spacing={GRID_SPACING}
      >
        <Grid item xs={12} md={12} lg={12}>
          <EngagementRate filter={sessionFilter} report />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <PopularCommunities
            filter={baseCommonPopularityFilter}
            mode={POPULAR_COMMUNITIES_DISPLAY_LIST}
            report
          />
        </Grid>
      </Grid>
    </Root>,
  ]
  const commonFilter: CommonQueryFilter = {
    clientName: selectedClientName,
    limit: 10000,
    startTime: startTime,
    endTime: endTime,
  }

  const leadRankingsData = data?.leadRankings

  const filteredData =
    leadRankingsData?.leads?.filter((lead) => lead?.prospectId) || []

  const leadsSectionComponents = [
    <Root>
      <Grid
        container
        className={classes.componentContainer}
        spacing={GRID_SPACING}
      >
        <Grid item xs={12} lg={12}>
          <VisitorTrends
            selectedCommunities={[]}
            filter={visitorsFilter}
            commonFilter={commonFilter}
            report
          />
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.componentContainer}
        spacing={GRID_SPACING}
      >
        <Grid item lg={6}>
          <LeadRankingsChart
            chartData={filteredData}
            result={{
              loading: loading,
              error: error,
              data: filteredData,
            }}
            report
          />
        </Grid>
        <Grid item lg={6}>
          <LeadsByCommunityChart
            chartData={filteredData}
            communities={leadChartSelectedCommunities}
            result={{
              loading: loading,
              error: error,
              data: filteredData,
            }}
            report
          />
        </Grid>
      </Grid>
    </Root>,
  ]

  const renderAnalyticsBody = () => <Grid container>{sectionComponents}</Grid>
  const renderLeadsBody = () => <Grid container>{leadsSectionComponents}</Grid>

  const renderReport = () => (
    <Root>
      <div className={classes.root}>
        <div className={classes.page} key={`analyticsPage`}>
          {renderHeader()}
          {renderAnalyticsBody()}
          {renderFooter(2)}
        </div>
        <div className={classes.page} key={`leadsPage`}>
          {renderHeader()}
          {renderLeadsBody()}
          {renderFooter(2)}
        </div>
      </div>
    </Root>
  )

  const brochureRoot = document.getElementById('root')
  return ReactDOM.createPortal(renderReport(), brochureRoot as Element)
}

export default EmailReport
