import { styled } from '@mui/material/styles'

const PREFIX = 'MainDrawerContent'

export const classes = {
  toolbar: `${PREFIX}-toolbar`,
  nested: `${PREFIX}-nested`,
  selected: `${PREFIX}-selected`,
  iconNormalColor: `${PREFIX}-iconNormalColor`,
  feedback: `${PREFIX}-feedback`,
  industryTrendsIcon: `${PREFIX}-industryTrendsIcon`,
  mainDrawer: `${PREFIX}-mainDrawer`,
}

export const Root = styled('div')(({ theme }) => ({
  // necessary for content to be below app bar
  [`& .${classes.toolbar}`]: {
    ...theme.mixins.toolbar,
    minHeight: '56px !important',
  },

  [`& .${classes.nested}`]: {
    paddingLeft: theme.spacing(4),
  },

  [`& .${classes.selected}`]: {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? '#0091e6 !important'
        : `${theme.palette.primary.light} !important`,
    color:
      theme.palette.mode === 'dark'
        ? `${theme.palette.primary.contrastText} !important`
        : `${theme.palette.primary.main} !important`,
  },

  [`& .${classes.iconNormalColor}`]: {
    color: 'rgba(0,0,0,1)',
  },

  [`& .${classes.feedback}`]: {
    backgroundColor: '#0052CC',
    color: '#FFF',
    display: 'block',
    width: '50%',
    margin: 'auto',
    marginBottom: '10px',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '#0052CC',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
    },
  },

  [`& .${classes.industryTrendsIcon}`]: {
    position: 'absolute',
    right: '20px',
    top: '19px',
    '&:hover': {
      color: 'skyblue',
      cursor: 'pointer',
    },
  },

  [`&.${classes.mainDrawer}`]: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
}))
