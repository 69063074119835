import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'

const PREFIX = 'PlanElevationsTransferList'

export const classes = {
  root: `${PREFIX}-root`,
  listContainer: `${PREFIX}-listContainer`,
  header: `${PREFIX}-header`,
  title: `${PREFIX}-title`,
  list: `${PREFIX}-list`,
  button: `${PREFIX}-button`,
}

export const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.listContainer}`]: {
    width: '100%',
  },

  [`& .${classes.header}`]: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  [`& .${classes.title}`]: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.list}`]: {
    width: '100%',
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(0.5, 1),
  },
}))
