import React from 'react'
import clsx from 'clsx'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import ListSubheader from '@mui/material/ListSubheader'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import {
  Community,
  Elevation,
  EmailCampaign,
  EmailCampaignInput,
  Lot,
  Plan,
  CommunitiesWithImagesQuery,
  PlansWithImagesQuery,
  Siteplan,
} from '../../../../graphql/gen-types'

interface EmailCampaignSectionOneProps {
  classes: any
  communitiesData: CommunitiesWithImagesQuery | undefined
  plansData: PlansWithImagesQuery | undefined
  emailCampaign: EmailCampaign
  emailCampaignUpdates: EmailCampaignInput
  setEmailCampaignUpdates: (updates: EmailCampaignInput) => void
  selectedCommunity?: Community
  selectedPlan?: Plan
  selectedElevation?: Elevation
  selectedLot?: Lot
  setSelectedCommunity: (community: Community | undefined) => void
  setSelectedPlan: (plan: Plan | undefined) => void
  setSelectedElevation: (elevation: Elevation | undefined) => void
  setSelectedLot: (lot: Lot | undefined) => void
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 450,
    },
  },
}

const template1PreviewImg =
  'https://res.cloudinary.com/renderinghouse/image/upload/w_300/anewgo/assets/custom/emailCampaignTemplate1-v2.png'
const template2PreviewImg =
  'https://res.cloudinary.com/renderinghouse/image/upload/w_300/anewgo/assets/custom/emailCampaignTemplate2-v2.png'
const template3PreviewImg =
  'https://res.cloudinary.com/renderinghouse/image/upload/w_300/anewgo/assets/custom/emailCampaignTemplate3-v2.png'

export default function EmailCompositionModalStepOne({
  classes,
  communitiesData,
  plansData,
  emailCampaign,
  emailCampaignUpdates,
  selectedCommunity,
  selectedPlan,
  selectedElevation,
  selectedLot,
  setEmailCampaignUpdates,
  setSelectedCommunity,
  setSelectedPlan,
  setSelectedElevation,
  setSelectedLot,
}: EmailCampaignSectionOneProps): JSX.Element {
  const handleChangeCommunity = (e: SelectChangeEvent<number | string>) => {
    e.stopPropagation()
    if (e.target.value === 'none') {
      setSelectedCommunity(undefined)
    }
    const value = e.target.value as number
    const communityToSelect = communitiesData?.communities?.find(
      (comm) => comm?.id === value
    ) as Community
    if (communityToSelect) {
      setSelectedCommunity(communityToSelect)
      setSelectedLot(undefined)
      if (!selectedPlan && !selectedElevation) {
        return
      }
      const communityPlan = communityToSelect?.plans?.find(
        (commPlan) => commPlan?.id === selectedPlan?.id
      )
      if (!communityPlan) {
        setSelectedPlan(undefined)
        setSelectedElevation(undefined)
      } else if (selectedElevation) {
        const communityElev = communityPlan?.elevations?.find(
          (commElev) => commElev?.id === selectedElevation?.id
        )
        if (!communityElev) {
          setSelectedElevation(undefined)
        }
      }
    }
  }

  const handleChangePlan = (e: SelectChangeEvent<number | string>) => {
    e.stopPropagation()
    if (e.target.value === 'none') {
      setSelectedPlan(undefined)
      setSelectedElevation(undefined)
    }
    const value = e.target.value as number
    const planToSelect = plansData?.plans?.find(
      (plan) => plan?.id === value
    ) as Plan
    if (planToSelect) {
      setSelectedPlan(planToSelect)
      setSelectedElevation(undefined)
      setSelectedLot(undefined)
    }
  }

  const handleChangeElevation = (e: SelectChangeEvent<number | string>) => {
    e.stopPropagation()
    if (e.target.value === 'none') {
      setSelectedElevation(undefined)
    }
    const value = e.target.value as number
    const elevToSelect = selectedPlan?.elevations?.find(
      (elev) => elev?.id === value
    ) as Elevation
    if (elevToSelect) {
      setSelectedElevation(elevToSelect)
      setSelectedLot(undefined)
    }
  }

  const handleChangeLot = (
    e: SelectChangeEvent<number | string>,
    props: React.ReactElement
  ) => {
    e.stopPropagation()
    if (e.target.value === 'none') {
      setSelectedLot(undefined)
    }
    const keyString = props.key as string
    const siteplanId = Number(
      keyString.substring(keyString.indexOf('$') + 1, keyString.indexOf('-'))
    )
    const lotId = e.target.value as number
    const lotSiteplan = selectedCommunity?.siteplans?.find(
      (siteplan) => siteplan?.id === siteplanId
    ) as Siteplan
    if (lotSiteplan) {
      const lotToSelect = lotSiteplan?.lots?.find((lot) => lot?.id === lotId)
      if (lotToSelect) {
        setSelectedPlan(undefined)
        setSelectedElevation(undefined)
        setSelectedLot(lotToSelect)
      }
    }
  }

  const handleNameChange = (input: string): void => {
    setEmailCampaignUpdates({ ...emailCampaignUpdates, name: input })
  }
  const handleTemplateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailCampaignUpdates({
      ...emailCampaignUpdates,
      templateId: event.target.value
        ? parseInt(event.target.value, 10)
        : undefined,
    })
  }

  const generateCommunitiesSelect = (): JSX.Element => {
    return (
      <>
        <InputLabel
          id="email-campaign-select-community-label"
          htmlFor="select-email-campaign-community"
        >
          Community
        </InputLabel>
        <Select
          variant="standard"
          data-testid="emailCampaignCommunitySelect"
          defaultValue=""
          classes={{ root: classes.select }}
          value={selectedCommunity?.id || ''}
          onChange={handleChangeCommunity}
          input={<Input id="select-email-campaign-community" />}
          renderValue={() => selectedCommunity?.name || ''}
          MenuProps={MenuProps}
          placeholder="Select a Community"
        >
          <MenuItem key="none" value={'none'}>
            <ListItemText primary="None" />
          </MenuItem>
          {communitiesData?.communities?.map((comm) => (
            <MenuItem key={comm?.id || '0'} value={comm?.id || '0'}>
              <ListItemText primary={comm?.name || ''} />
            </MenuItem>
          )) || (
            <MenuItem key="000" disabled value={'0'}>
              <ListItemText primary="No Active Communities Available" />
            </MenuItem>
          )}
        </Select>
      </>
    )
  }

  const generatePlanSelect = (): JSX.Element => (
    <>
      <InputLabel
        id="email-campaign-select-plan-label"
        htmlFor="select-email-campaign-plan"
      >
        Plan
      </InputLabel>
      <Select
        variant="standard"
        data-testid="emailCampaignPlanSelect"
        defaultValue=""
        classes={{ root: classes.select }}
        value={selectedPlan?.id || ''}
        onChange={handleChangePlan}
        input={<Input id="select-email-campaign-plan" />}
        renderValue={() =>
          selectedPlan?.displayName || selectedPlan?.name || ''
        }
        MenuProps={MenuProps}
        placeholder="Select a Plan"
      >
        <MenuItem key="none" value={'none'}>
          <ListItemText primary="None" />
        </MenuItem>
        {plansData?.plans
          ?.filter(
            (plan) =>
              !selectedCommunity ||
              selectedCommunity?.plans?.find(
                (commPlan) => commPlan?.id === plan?.id
              )
          )
          .map((plan) => (
            <MenuItem key={plan?.id || '0'} value={plan?.id || '0'}>
              <ListItemText primary={plan?.displayName || plan?.name || ''} />
            </MenuItem>
          )) || (
          <MenuItem key="000" disabled value={'0'}>
            <ListItemText primary="No Active Plans Available" />
          </MenuItem>
        )}
      </Select>
    </>
  )

  const generateElevationSelect = (): JSX.Element => (
    <>
      <InputLabel
        id="email-campaign-select-elev-label"
        htmlFor="select-email-campaign-elevation"
      >
        Elevation
      </InputLabel>
      <Select
        variant="standard"
        data-testid="emailCampaignElevationSelect"
        defaultValue=""
        disabled={!selectedPlan}
        classes={{ root: classes.select }}
        value={selectedElevation?.id || ''}
        onChange={handleChangeElevation}
        input={<Input id="select-email-campaign-elevation" />}
        renderValue={() =>
          selectedPlan && selectedElevation
            ? `${selectedPlan?.displayName || selectedPlan?.name} ${
                selectedElevation?.caption
              }`
            : ''
        }
        MenuProps={MenuProps}
        placeholder="Select an Elevation"
      >
        <MenuItem key="none" value={'none'}>
          <ListItemText primary="None" />
        </MenuItem>
        {selectedPlan?.elevations
          ?.filter((elev) => {
            if (!selectedCommunity) {
              return true
            }
            const communityPlan =
              selectedCommunity?.plans?.find(
                (commPlan) => commPlan?.id === selectedPlan?.id
              ) || undefined
            if (communityPlan !== undefined) {
              return communityPlan?.elevations?.find(
                (commElev) => commElev?.id === elev?.id
              )
            } else {
              return false
            }
          })
          .map((elev) => (
            <MenuItem key={elev?.id || '0'} value={elev?.id || '0'}>
              <ListItemText
                primary={
                  `${selectedPlan?.displayName || selectedPlan.name} ${
                    elev?.caption
                  }` || ''
                }
              />
            </MenuItem>
          )) || (
          <MenuItem key="000" disabled value={'0'}>
            <ListItemText primary="No Active Elevations Available" />
          </MenuItem>
        )}
      </Select>
    </>
  )

  const generateLotSelect = (): JSX.Element => (
    <>
      <InputLabel
        id="email-campaign-select-lot-label"
        htmlFor="select-email-campaign-lot"
      >
        Lot
      </InputLabel>
      <Select
        variant="standard"
        data-testid="emailCampaignLotSelect"
        defaultValue=""
        disabled={!selectedCommunity}
        classes={{ root: classes.select }}
        value={selectedLot?.id || ''}
        onChange={(e, props) => handleChangeLot(e, props)}
        input={<Input id="select-email-campaign-lot" />}
        renderValue={() => (selectedLot ? selectedLot.name : '')}
        MenuProps={MenuProps}
        placeholder="Select a Lot"
      >
        <MenuItem key="none" value={'none'}>
          <ListItemText primary="None" />
        </MenuItem>
        {selectedCommunity?.siteplans.map((siteplan) => {
          const numberOfSiteplans = selectedCommunity?.siteplans.length || 0
          const lots = siteplan?.lots
            ?.slice()
            .sort((a, b) => {
              const aName = a?.name || ''
              const bName = b?.name || ''
              return aName.localeCompare(bName || '', undefined, {
                numeric: true,
              })
            })
            .map((lot) => (
              <MenuItem
                key={`${siteplan?.id}-${lot?.id}` || '0'}
                value={lot?.id || '0'}
              >
                <ListItemText primary={lot?.name} />
              </MenuItem>
            ))
          if (numberOfSiteplans > 1) {
            return (
              <>
                <ListSubheader>{siteplan.name}</ListSubheader>
                {lots}
              </>
            )
          }
          return lots
        }) || (
          <MenuItem key="000" disabled value={'0'}>
            <ListItemText primary="No Lots Available" />
          </MenuItem>
        )}
      </Select>
    </>
  )

  return (
    <Grid container direction="column" spacing={1}>
      <Typography
        variant="h6"
        color="primary"
        style={{ marginLeft: '-4px', marginBottom: '10px' }}
      >
        Campaign Details
      </Typography>
      <FormControl className={classes.formControl} required variant="standard">
        <TextField
          variant="standard"
          data-testid="emailCompNameField"
          id="emailCompNameField"
          label={'Name'}
          required
          value={
            emailCampaignUpdates.name !== undefined
              ? emailCampaignUpdates.name
              : emailCampaign.name || ''
          }
          onChange={(ev): void => handleNameChange(ev.target.value)}
        />
      </FormControl>
      <Typography
        style={{ marginLeft: '-4px', marginBottom: '10px' }}
        variant="h6"
        color="primary"
      >
        Select a Product to Advertise
      </Typography>
      <FormControl
        variant="standard"
        className={clsx(classes.formControl, classes.inputFormControl)}
      >
        {generateCommunitiesSelect()}
      </FormControl>
      <FormControl
        variant="standard"
        className={clsx(classes.formControl, classes.inputFormControl)}
      >
        {generatePlanSelect()}
      </FormControl>
      {selectedPlan && (
        <FormControl
          variant="standard"
          className={clsx(classes.formControl, classes.inputFormControl)}
        >
          {generateElevationSelect()}
        </FormControl>
      )}
      {selectedCommunity && (
        <FormControl
          variant="standard"
          className={clsx(classes.formControl, classes.inputFormControl)}
        >
          {generateLotSelect()}
        </FormControl>
      )}
      <Typography
        variant="h6"
        color="primary"
        style={{ marginBottom: '16px', marginLeft: '-4px' }}
      >
        Choose an Email Template
      </Typography>
      <RadioGroup
        row
        aria-label="position"
        name="position"
        value={
          emailCampaignUpdates?.templateId?.toString() ||
          emailCampaign?.templateId?.toString() ||
          '1'
        }
        onChange={handleTemplateChange}
        style={{ paddingBottom: '20px' }}
      >
        <FormControlLabel
          className={classes.templatePreviewLabel}
          value="1"
          control={
            <Radio
              data-testid="emailCompTemplateRadio1"
              classes={{ root: classes.radioButton }}
              color="primary"
            />
          }
          label={
            <div>
              <div className={classes.templatePreviewText}>Template 1</div>
              <img
                className={classes.templatePreview}
                src={template1PreviewImg}
                alt="Template 1"
              />
              <div className={classes.templatePreviewText}>Single Image</div>
            </div>
          }
          labelPlacement="end"
        />
        <FormControlLabel
          className={classes.templatePreviewLabel}
          value="2"
          control={
            <Radio
              data-testid="emailCompTemplateRadio2"
              classes={{ root: classes.radioButton }}
              color="primary"
            />
          }
          label={
            <div>
              <div className={classes.templatePreviewText}>Template 2</div>
              <img
                className={classes.templatePreview}
                src={template2PreviewImg}
                alt="Template 2"
              />
              <div className={classes.templatePreviewText}>
                Main Image + Gallery
              </div>
            </div>
          }
          labelPlacement="end"
        />
        <FormControlLabel
          className={classes.templatePreviewLabel}
          value="3"
          control={
            <Radio
              data-testid="emailCompTemplateRadio3"
              classes={{ root: classes.radioButton }}
              color="primary"
            />
          }
          label={
            <div>
              <div className={classes.templatePreviewText}>Template 3</div>
              <img
                className={classes.templatePreview}
                src={template3PreviewImg}
                alt="Template 3"
              />
              <div className={classes.templatePreviewText}>
                Up to 3 sections with images
              </div>
            </div>
          }
          labelPlacement="end"
        />
      </RadioGroup>
    </Grid>
  )
}
