import { useMemo } from 'react'
import {
  createTheme,
  responsiveFontSizes,
  SimplePaletteColorOptions,
  Theme,
} from '@mui/material/styles'
import { BRANDING_COLOR_MAIN_TEAL } from '../constants'
/**
 * BRANDING COLORS
 * see definitions in ../constants
 */

/**
 * ALTERNATE BRANDING COLORS
 * Metallic SeaWeed #177e89
 * Midnight Green Eagle #084C61
 * CG Red #db3a34
 * Max Yellow Red #ffc857
 * Jet #323031
 */

const PRIMARY_COLOR: SimplePaletteColorOptions = {
  light: '#e4f5ff', // lighter shade of main
  main: BRANDING_COLOR_MAIN_TEAL,
  dark: '#094f6e', // darker shade of main
  contrastText: '#e4f5ff',
}

function useAppTheme(darkMode: boolean): Theme {
  return useMemo(() => {
    let theme = createTheme({
      palette: {
        primary: PRIMARY_COLOR,
        mode: darkMode ? 'dark' : 'light',
      },
    })
    theme = responsiveFontSizes(theme)
    return theme
  }, [darkMode])
}

export { useAppTheme }
