import { styled } from '@mui/material/styles'

const PREFIX = 'InteractiveSiteplan'

export const classes = {
  gridRoot: `${PREFIX}-gridRoot`,
  simpleRoot: `${PREFIX}-simpleRoot`,
  section: `${PREFIX}-section`,
  mobileRoot: `${PREFIX}-mobileRoot`,
  strongFont: `${PREFIX}-strongFont`,
}

export const Root = styled('div')(() => ({
  [`&.${classes.gridRoot}`]: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },

  [`&.${classes.simpleRoot}`]: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  [`& .${classes.section}`]: {
    width: 'auto',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'column',
  },

  [`&.${classes.mobileRoot}`]: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  [`& .${classes.strongFont}`]: {
    fontSize: 14,
  },
}))
