import React, { ReactElement } from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Input from '@mui/material/Input'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import clsx from 'clsx'
import { LocationsSelectTypes } from './LocationsSelect'
import { BuildStyles } from './LocationsSelect.styles'

const PREFIX = 'LocationsSelectMultiple'
const { classes, StyledFormControl } = BuildStyles(PREFIX)

export interface LocationsSelectMultipleProps<T extends LocationsSelectTypes> {
  field: string
  name: string
  items: Map<T, string>
  selected: T[]
  onSelect: (value?: T[]) => void
  larger?: boolean
  industryTrendsSelect?: boolean
  locationsFilterSelect?: boolean
  leadsLocationSelect?: boolean
}

export function LocationsSelectMultiple<T extends LocationsSelectTypes>({
  field,
  name,
  items,
  selected,
  onSelect,
  larger = false,
  industryTrendsSelect = false,
  locationsFilterSelect = false,
  leadsLocationSelect = false,
}: LocationsSelectMultipleProps<T>): ReactElement | null {
  return (
    <StyledFormControl
      className={classes.formControl}
      variant="standard"
      industryTrendsSelect={industryTrendsSelect}
      locationsFilterSelect={locationsFilterSelect}
      leadsLocationSelect={leadsLocationSelect}
    >
      <InputLabel htmlFor="select-region">Select {name}</InputLabel>
      <Select<T[]>
        variant="standard"
        data-testid={`${field}Select`}
        className={
          larger ? clsx(classes.select, classes.selectLarger) : classes.select
        }
        value={Array.isArray(selected) ? selected : [selected]}
        onChange={(e) => {
          const value = e.target.value
          onSelect?.((Array.isArray(value) ? value : [value]) as T[])
        }}
        input={<Input id={`select-multiple-${field}`} />}
        renderValue={(selected) =>
          Array.isArray(selected) ? selected.join(', ') : selected
        }
        multiple
      >
        {[...items.entries()].map(([key, value]) => (
          <MenuItem key={key} value={key}>
            <Checkbox checked={selected.indexOf(key) > -1} />
            <ListItemText primary={value} />
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  )
}
