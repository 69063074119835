import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { useMutation } from '@apollo/client'
import React, { useContext, useState } from 'react'
import { useAtom } from 'jotai'
import { ColonnadeRole, User } from 'graphql/gen-types'
import { UPDATE_COLONNADE_USER } from 'graphql/mutation/updateColonnadeUser'
import USERS_QUERY from 'graphql/colonnade-queries/users'
import { AppStore } from 'store'
import MuiAlert from '@mui/material/Alert'
import { AuthContext } from 'auth/AuthContext'
import { snackbarConfigAtom } from 'store/atoms'
import { classes, StyledDialog } from './EditUserDialog.styles'

interface EditUserDialogProps {
  userToBeEdited: User
  setUserToBeEdited: React.Dispatch<React.SetStateAction<User | null>>
  roles: ColonnadeRole[]
  users: User[]
}

export default function EditUserDialog(
  props: EditUserDialogProps
): JSX.Element {
  const { user } = useContext(AuthContext)

  const { appState } = useContext(AppStore)
  const clientName = appState.selectedClient?.altName || ''
  const [snackbarConfig, setSnackbarConfig] = useAtom(snackbarConfigAtom)

  const { userToBeEdited } = props
  const [firstName, setFirstName] = useState(userToBeEdited.firstName)

  const [lastName, setLastName] = useState(userToBeEdited.lastName)

  const [organizationRole, setOrganizationRole] = useState(
    userToBeEdited.organizationRole
  )

  const [warn, setWarn] = useState('')

  // mutation hook
  const [updateColonnadeUser] = useMutation(UPDATE_COLONNADE_USER)

  // Add User handlers
  const handleEditUserSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault()

    updateColonnadeUser({
      variables: {
        callerUserId: user.userId,
        userId: props.userToBeEdited.id,
        input: {
          firstName,
          lastName,
          organizationRole,
        },
      },
      refetchQueries: [{ query: USERS_QUERY, variables: { clientName } }],
    })
      .then((result) => {
        handleEditUserClose()
        if (!result?.data?.updateColonnadeUser) {
          setSnackbarConfig({
            ...snackbarConfig,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            autoHideDuration: 6000,
            open: true,
            message: 'Insights user update failed!',
            severity: 'error',
          })
        } else {
          setSnackbarConfig({
            ...snackbarConfig,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            autoHideDuration: 6000,
            open: true,
            message: 'Insights user updated successfully!',
            severity: 'success',
          })
        }
        clearFields()
        handleEditUserClose()
      })
      .catch((err) => {
        console.log(err)
        setSnackbarConfig({
          ...snackbarConfig,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          autoHideDuration: 6000,
          open: true,
          message: err.message.replace(/.*[Ee]rror: /, ''),
          severity: 'error',
        })
      })
  }

  const clearFields = (): void => {
    setFirstName('')
    setLastName('')
    setOrganizationRole('')
  }

  const handleEditUserClose = (): void => {
    setWarn('')
    props.setUserToBeEdited(null)
  }

  const handleFirstNameChange = (input: string): void => {
    setFirstName(input)
  }

  const handleLastNameChange = (input: string): void => {
    setLastName(input)
  }

  const handleOrganizationRoleChange = (input: string): void => {
    setOrganizationRole(input)
  }

  return (
    <StyledDialog
      open={!!props.userToBeEdited}
      onClose={handleEditUserClose}
      aria-labelledby="user-edit-title"
    >
      {props.userToBeEdited && (
        <div>
          <DialogTitle id="user-edit-title">Edit User</DialogTitle>
          <DialogContent>
            <form onSubmit={handleEditUserSubmit}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  {warn ? <MuiAlert severity="warning">{warn}</MuiAlert> : null}
                </Grid>
                <Grid container direction="row">
                  <Grid item xs={6}>
                    <FormControl
                      required
                      className={classes.formControlEditUser}
                      variant="standard"
                    >
                      <TextField
                        variant="standard"
                        data-testid="addUserFirstNameField"
                        id="addUserFirstNameField"
                        className={classes.editUserFirstNameInputField}
                        label={'First Name'}
                        required
                        value={firstName}
                        onChange={(ev): void =>
                          handleFirstNameChange(ev.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      required
                      className={classes.formControlEditUser}
                    >
                      <TextField
                        variant="standard"
                        data-testid="addUserLastNameField"
                        id="addUserLastNameField"
                        className={classes.editUserLastNameInputField}
                        label={'Last Name'}
                        required
                        value={lastName}
                        onChange={(ev): void =>
                          handleLastNameChange(ev.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl className={classes.formControlEditUser}>
                      <TextField
                        variant="standard"
                        data-testid="addUserOrganizationRoleField"
                        id="addUserOrganizationRoleField"
                        className={classes.editUserFirstNameInputField}
                        label={'Organization Role'}
                        value={organizationRole}
                        onChange={(ev): void =>
                          handleOrganizationRoleChange(ev.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between">
                <Button
                  onClick={handleEditUserClose}
                  variant="contained"
                  color="primary"
                >
                  Close
                </Button>
                <Button
                  aria-label="submit-button"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </Grid>
            </form>
          </DialogContent>
        </div>
      )}
    </StyledDialog>
  )
}
