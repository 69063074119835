import React, { useContext, useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import InfoIcon from '@mui/icons-material/Info'
import { useTheme } from '@mui/material/styles'

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  ResponsiveContainer,
} from 'recharts'

import { useQuery } from '@apollo/client'
import { GET_DROP_OF_RATE } from '../../../graphql/nexus-queries/reservationAnalytics'
import { AppStore } from '../../../store'
import ReservationFromToDatePicker from './ReservationFromToDatePicker'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import { UnauthorizedCard } from 'components/auth/Unauthorized'

const date = new Date()

const DropOfSteps: React.FC = () => {
  const theme = useTheme()
  const stroke =
    theme.palette.primary[theme.palette.mode === 'dark' ? 'light' : 'dark']
  const { appState } = useContext(AppStore)
  const clientName = appState?.selectedClient?.altName
  const [dateFrom, setDateFrom] = useState<Date | null>(
    new Date(date.getFullYear(), date.getMonth(), 1)
  )
  const [dateTo, setDateTo] = useState<Date | null>(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  )
  const [isPrimary, setIsPrimary] = useState<boolean>(true)

  const { data: rateQuery, error } = useQuery(GET_DROP_OF_RATE, {
    variables: {
      clientName,
      filter: {
        isPrimaryBuyer: isPrimary,
        fromDate: dateFrom?.toJSON().slice(0, 10),
        toDate: dateTo?.toJSON().slice(0, 10),
      },
    },
  })

  const dropOfRate = rateQuery?.getReservationsDropOfRate

  const handleDateFromChange = (date: Date | null) => {
    setDateFrom(date)
  }

  const handleDateToChange = (date: Date | null) => {
    setDateTo(date)
  }

  const handleBuyerTypeChange = (type: string) => {
    setIsPrimary(type === 'PRIMARY')
  }

  if (error && verifyUserAuthError(error.toString()))
    return (
      <UnauthorizedCard
        title="User Drop-Off Rate"
        message={error.toString()}
        imageName={'buyOnlineCommunitiesStatusOverview'}
      />
    )

  if (!dropOfRate) {
    return null
  }

  const chartData = [
    {
      name: 'Terms & Conditions',
      buyers: dropOfRate.termsAndCondition,
    },
    {
      name: 'Signed Document',
      buyers: dropOfRate.signedDocument,
    },
    {
      name: 'Completed',
      buyers: dropOfRate.completed,
    },
  ]

  if (isPrimary) {
    chartData.splice(2, 0, {
      name: 'Payment',
      buyers: dropOfRate.payment,
    })
  }

  const maxDroppedStepNumber = Math.max(...chartData.map((o) => o.buyers))
  const maxDroppedStep = chartData.find(
    (step) => step.buyers === maxDroppedStepNumber
  )?.name

  return (
    <Card>
      <CardHeader
        title={
          <>
            User Drop-Off Rate
            <Tooltip
              arrow
              title="For each reservation step, it will show how many buyers left reservations in that step"
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </>
        }
      />
      <CardContent>
        <Grid container spacing={3}>
          <ReservationFromToDatePicker
            fromValue={dateFrom}
            onFromChange={handleDateFromChange}
            toValue={dateTo}
            onToChange={handleDateToChange}
            grid={{ xs: 12, sm: 6, md: 3 }}
          />
          <Grid item xs={12} sm={6} md={6}>
            <FormControl margin="dense">
              <TextField
                value={isPrimary ? 'PRIMARY' : 'SECONDARY'}
                onChange={(e) =>
                  handleBuyerTypeChange(e.target.value as string)
                }
                data-testid="buyer-type-select"
                id="buyer-type-select"
                select
                label="Buyer type"
                size="small"
              >
                <MenuItem value="PRIMARY">Primary Buyer</MenuItem>
                <MenuItem value="SECONDARY">Secondary Buyer</MenuItem>
              </TextField>
            </FormControl>
          </Grid>
        </Grid>
        <ResponsiveContainer
          width="100%"
          height={300}
          style={{ margin: '29px 0 21px' }}
        >
          <BarChart
            width="100%"
            height={300}
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tick={{ fill: stroke }} />
            <YAxis allowDecimals={false} tick={{ fill: stroke }} />
            <ChartTooltip />
            <Bar dataKey="buyers" fill="#1F7396" />
          </BarChart>
        </ResponsiveContainer>
        <Alert severity="info">
          Most users dropped-off in the <strong>"{maxDroppedStep}"</strong>
          {' step.'}
        </Alert>
      </CardContent>
    </Card>
  )
}

export default DropOfSteps
