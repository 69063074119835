import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'

const PREFIX = 'SessionsSummaryDisplay'

export const classes = {
  gridRoot: `${PREFIX}-gridRoot`,
  gridRootReport: `${PREFIX}-gridRootReport`,
  reportPaper: `${PREFIX}-reportPaper`,
  paper: `${PREFIX}-paper`,
  sessionsSummaryValueCont: `${PREFIX}-sessionsSummaryValueCont`,
  sessionsSummaryValue: `${PREFIX}-sessionsSummaryValue`,
  sessionsSummaryLabel: `${PREFIX}-sessionsSummaryLabel`,
  sessionsSummaryLabelCont: `${PREFIX}-sessionsSummaryLabelCont`,
  percentPaper: `${PREFIX}-percentPaper`,
  positive: `${PREFIX}-positive`,
  negative: `${PREFIX}-negative`,
  arrow: `${PREFIX}-arrow`,
}

export const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.gridRoot}`]: {
    flexGrow: 1,
    margin: '0em auto 2em auto',
    [theme.breakpoints.down(1650)]: {
      maxWidth: '760px',
    },
  },

  [`& .${classes.gridRootReport}`]: {
    flexGrow: 1,
    [theme.breakpoints.down(1650)]: {
      maxWidth: '760px',
    },
  },

  [`& .${classes.reportPaper}`]: {
    height: '6em',
    width: '6.5em',
    textAlign: 'center',
    marginLeft: -1,
    marginTop: -1,
  },

  [`& .${classes.paper}`]: {
    [theme.breakpoints.down('xl')]: {
      width: '13.5em',
    },
    [theme.breakpoints.down('lg')]: {
      width: '13.5em',
    },
    [theme.breakpoints.down('md')]: {
      width: '13em',
    },
    [theme.breakpoints.down('sm')]: {
      width: '12em',
      height: '6em',
    },
    height: '7em',
    width: '13.5em',
    textAlign: 'center',
    marginLeft: -1,
    marginTop: -1,
  },

  [`& .${classes.sessionsSummaryValueCont}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '65%',
  },

  [`& .${classes.sessionsSummaryValue}`]: {
    color:
      theme.palette.primary[theme.palette.mode === 'dark' ? 'light' : 'dark'],
  },

  [`& .${classes.sessionsSummaryLabel}`]: {
    fontSize: 12,
    opacity: 0.85, // Acheives slightly lighter color
  },

  [`& .${classes.sessionsSummaryLabelCont}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '35%',
  },

  [`& .${classes.percentPaper}`]: {
    padding: 0,
    margin: 0,
    opacity: 0.8,
  },

  [`& .${classes.positive}`]: {
    color: theme.palette.mode === 'dark' ? 'lightgreen' : 'green',
    opacity: '0.9',
  },

  [`& .${classes.negative}`]: {
    color: theme.palette.mode === 'dark' ? 'salmon' : 'red',
    opacity: '0.9',
  },

  [`& .${classes.arrow}`]: {},
}))
