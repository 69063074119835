import Dialog from '@mui/material/Dialog'
import { styled } from '@mui/material/styles'

const PREFIX = 'LiveLeadDetailModal'

export const classes = {
  componentContainer: `${PREFIX}-componentContainer`,
  content: `${PREFIX}-content`,
  extendedContent: `${PREFIX}-extendedContent`,
  subtitle: `${PREFIX}-subtitle`,
}

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.componentContainer}`]: {
    justifyContent: 'center',
    marginTop: '1em',
    padding: '2em',
    [theme.breakpoints.down('md')]: {
      padding: '0em',
    },
  },

  [`& .${classes.content}`]: {
    minWidth: '440px',
  },

  [`& .${classes.extendedContent}`]: {
    minWidth: '800px',
  },

  [`& .${classes.subtitle}`]: {
    fontWeight: 500,
    padding: '0px 0px 16px 16px',
    marginLeft: 8,
    marginTop: '-12px',
    textAlign: 'left',
  },
}))
