// Common
export const EVT_USER_ALIAS = 'User Alias'
export const EVT_CLICKED_SIGN_IN = 'Clicked Sign In'
export const EVT_CLICKED_START = 'Clicked Start'
export const EVT_CLICKED_START_OVER = 'Clicked Start Over'
export const EVT_TOGGLED_STATUS_MENU = 'Toggled Status Menu'
export const EVT_TOGGLED_FAVORITES_MENU = 'Toggled Favorites Menu'
export const EVT_CLICKED_HEADER_TAB = 'Clicked Header Tab'
export const EVT_LINKED_TO_FAVORITE = 'Linked To Favorite'
export const EVT_DELETED_FAVORITE = 'Deleted Favorite'
export const EVT_TOGGLED_COMPARE_FAVORITES = 'Toggled Compare Favorites'
export const EVT_CLICKED_CONTACT_US = 'Clicked Contact Us'

// Interactive Floorplan events
export const EVT_CLICKED_FLOORPLAN_OPTION = 'Clicked Floorplan Option'

// Interactive Exterior events
export const EVT_CLICKED_PALETTE_COLOR = 'Clicked Palette Color'
export const EVT_CLICKED_COLOR_SCHEME = 'Clicked Color Scheme'
export const EVT_CLICKED_RESET_COLOR_SCHEME = 'Clicked Reset Color Scheme'

// Communities page (landing page)
export const EVT_TOGGLED_COMMUNITY_SELECTION = 'Toggled Community Selection'
export const EVT_CLICKED_COMMUNITY_QUICK_LINK = 'Clicked Community Quick Link'
export const EVT_CHANGED_COMMUNITIES_FILTER = 'Changed Communities Filter'
export const EVT_CLICKED_VIEW_PLANS = 'Clicked View Plans'

// Plans page (active community filters can be inferred from the route)
export const EVT_CHANGED_PLANS_FILTER = 'Changed Plans Filter'
export const EVT_FILTER_PLAN_TAGS = 'Filter Plan Tags'
export const EVT_CLICKED_RESERVE_HOME_BUTTON = 'Clicked Reserve Home Button'
export const EVT_CLICKED_PLANS_TAB = 'Clicked Plans Tab'
export const EVT_TOGGLED_PLAN_CARD_INFO = 'Toggled Plan Card Info'
export const EVT_LIKED_HOME = 'Liked Home'
export const EVT_CLICKED_PLAN_CARD = 'Clicked Plan Card'
export const EVT_CLICKED_QMI_CARD = 'Clicked QMI Card'
export const EVT_CLICKED_REQUEST_SGT_APPOINTMENT =
  'Clicked Request SGT Appointment'
export const EVT_SUBMITTED_REQUEST_SGT_APPOINTMENT_FORM =
  'Submitted Request SGT Appointment Form'
export const EVT_CLICKED_ELEVATION_FROM_PLAN_CARD =
  'Clicked Elevation From Plan Card'
export const EVT_CLICKED_COMMUNITY_FROM_PLAN_CARD =
  'Clicked Community From Plan Card'

// Community info page
export const EVT_COMMUNITY_IMAGE_VIEW = 'Community Image View'
export const EVT_CLICKED_COMMUNITY_POIS = 'Clicked Community POIs'

// Community POIs page
export const EVT_SET_COMMUNITY_POI_DISTANCE = 'Set Community POI Distance'
export const EVT_SET_COMMUNITY_POI_FILTER = 'Set Community POI Filter'
export const EVT_SET_COMMUNITY_POI_SEARCH_TERM = 'Set Community POI Search Term'

// Floorplan page
export const EVT_CLICKED_FLOORPLAN_TAB = 'Clicked Floorplan Tab'
export const EVT_CLICKED_VIRTUAL_TOUR = 'Clicked Virtual Tour'
export const EVT_CLICKED_CREATE_MY_BROCHURE = 'Clicked Create My Brochure'
export const EVT_MY_BROCHURE_SELECTED_COLORS = 'My Brochure Selected Colors'
export const EVT_MY_BROCHURE_SELECTED_FPOPTS = 'My Brochure Selected FpOpts'
export const EVT_MY_BROCHURE_SELECTED_INTERIORS =
  'My Brochure Selected Interiors'
export const EVT_MY_BROCHURE_INTERIOR_PACKAGE_OVERRIDE =
  'My Brochure Interior Package Override'
export const EVT_MY_BROCHURE_INTERIOR_SELECTIONS =
  'My Brochure Interior Selections'

// My Home page
export const EVT_CLICKED_MY_HOME_CTA = 'Clicked My Home CTA'
export const EVT_MY_HOME_CTA_SELECTED_COLORS = 'My Home CTA Selected Colors'
export const EVT_MY_HOME_CTA_SELECTED_FPOPTS = 'My Home CTA Selected FpOpts'
export const EVT_MY_HOME_CTA_SELECTED_INTERIORS =
  'My Home CTA Selected Interiors'
export const EVT_MY_HOME_CTA_INTERIOR_PACKAGE_OVERRIDE =
  'My Home CTA Interior Package Override'
export const EVT_MY_HOME_CTA_INTERIOR_SELECTIONS =
  'My Home CTA Interior Selections'

//Mortgage Calculator
export const EVT_OPENED_MORTGAGE_CALCULATOR = 'Opened Mortgage Calculator'
export const EVT_CLOSED_MORTGAGE_CALCULATOR = 'Closed Mortgage Calculator'
export const EVT_CHANGED_MORTGAGE_CALCULATOR_VALUE =
  'Changed Mortgage Calculator Value'

//Interactive Interior
export const EVT_CLICKED_INTERACTIVE_INTERIOR = 'Clicked Interactive Interior'
export const EVT_CHANGED_INTERACTIVE_INTERIOR_SELECTIONS =
  'Changed Interactive Interior Selections'
export const EVT_CHANGED_INTERACTIVE_PACKAGE_OVERRIDE =
  'Changed Interactive Package Override'
export const EVT_CHANGED_INTERACTIVE_INTERIOR = 'Changed Interactive Interior'
export const EVT_CHANGED_INTERACTIVE_PACKAGE = 'Changed Interactive Package'

//Clicked Image
export const EVT_CLICKED_IMAGE = 'Clicked Image'
export const EVT_CLICKED_VIEW_VIRTUAL_TOUR = 'Clicked View Virtual Tour'

//View Elevation and Inventory Pages
export const EVT_VIEW_ELEVATION_PAGE = 'View Elevation Page'
export const EVT_VIEW_INVENTORY_PAGE = 'View Inventory Page'

//Favorites Page
export const EVT_PROSPECT_SHARED_FAVORITE = 'Prospect Shared Favorite'
export const EVT_PROSPECT_SHARED_FAVORITE_WITH = 'Prospect Shared Favorite With'
