import React from 'react'
import Typography from '@mui/material/Typography'
import { Community, StdFeature, StdFeatureCategory } from 'graphql/gen-types'
import { styled } from '@mui/material/styles'

const Bullet = styled('span')({
  marginRight: '1rem',
})

const StdFeaturesPanel = styled('div')({
  marginTop: '4rem',
  width: '80%',
  margin: '0 auto',
})

const StdFeaturesList = styled('ul')({
  paddingTop: 0,
  marginTop: 0,
})

type StandardFeaturesListProps = {
  community: Community
  standardFeaturesDisplayName?: string
  displayTitle?: string
  brochurePage?: boolean | false
}

export default function StandardFeaturesList({
  community,
  standardFeaturesDisplayName,
  displayTitle,
  brochurePage,
}: StandardFeaturesListProps): JSX.Element | null {
  const { stdFeatureCategories } = community

  if (!stdFeatureCategories || stdFeatureCategories.length === 0) {
    return null
  }

  const bulletPoint = <Bullet>{`•`}</Bullet>
  const categories = stdFeatureCategories
    ?.filter((c): c is StdFeatureCategory => c !== undefined && c !== null) // remove nulls from
    .map((category, i) => {
      const features = category?.features
        ?.filter((f): f is StdFeature => f !== undefined && f !== null) // remove nulls from array
        .map((feature, j) => {
          return (
            <div key={`feat-${feature}-${j}`}>
              <Typography color="textSecondary" variant={'h6'} key={j}>
                {bulletPoint}
                {`${feature?.name}`}
              </Typography>
              {brochurePage && <br />}
            </div>
          )
        })
      return (
        <Typography
          color="textPrimary"
          variant={'h6'}
          key={`category-${category}-${i}`}
        >
          {category.name}
          <StdFeaturesList>{features}</StdFeaturesList>
        </Typography>
      )
    })
  return (
    <StdFeaturesPanel>
      {displayTitle && (
        <Typography variant="h4">
          {standardFeaturesDisplayName || 'Standard Features'}
        </Typography>
      )}
      {categories}
    </StdFeaturesPanel>
  )
}
