import React, { useContext, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import { useAtom } from 'jotai'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import useMediaQuery from '@mui/material/useMediaQuery'
import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import {
  EmailCampaign,
  EmailCampaignCohort,
  EmailCampaignCohortStatus,
  EmailCampaignInput,
  useDeleteEmailCampaignMutation,
  useUpdateEmailCampaignMutation,
  useGetEmailCampaignCohortStatusLazyQuery,
} from 'graphql/gen-types'

import ResponsiveTable, {
  ResponsiveCell,
} from 'components/common/tables/ResponsiveTable'
import {
  formatDateTime,
  getProspectName,
  getProspectTag,
} from 'utils/functions'
import { AppStore } from 'store'
import { snackbarConfigAtom } from 'store/atoms'
import GET_EMAIL_CAMPAIGNS from 'graphql/nexus-queries/getEmailCampaigns'
import { EmailCampaignStatusTitles } from '../EmailCampaigns'
import DisplayEmail from '../components/DisplayEmail'

const PREFIX = 'EmailCampaignDetailsModal'

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
}

const EmailCampaignDetailsModalStyled = styled(Dialog)(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    height: 450,
    padding: '0.5em 1.5em 1.5em 1.5em',
  },

  [`& .${classes.paper}`]: {
    padding: '1em',
  },
}))

const RESPONSE_SIZE = 'sm'

export default function EmailCampaignDetailsModal({
  emailCampaign,
  open,
  onClose,
  onSubmit,
  onFollowUp,
}: {
  emailCampaign: EmailCampaign
  open: boolean
  onClose: () => void
  onSubmit: () => void
  onFollowUp: (leads: EmailCampaignCohort[]) => void
}): JSX.Element {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const { appState } = useContext(AppStore)
  const { selectedClient } = appState
  const clientName = selectedClient?.altName || ''
  const xs = useMediaQuery(theme.breakpoints.down('sm'))

  const [name, setName] = useState(emailCampaign.name)
  const [selectedLeads, setSelectedLeads] = useState<EmailCampaignCohort[]>([])
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('')
  const [snackbarConfig, setSnackbarConfig] = useAtom(snackbarConfigAtom)

  const [
    getEmailCampaignDetails,
    {
      data: emailCampaignCohortData,
      loading: emailCampaignCohortLoading,
      error: emailCampaignCohortError,
    },
  ] = useGetEmailCampaignCohortStatusLazyQuery({
    variables: {
      clientName,
      emailCampaignId: emailCampaign.id,
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (open && emailCampaign.id) {
      getEmailCampaignDetails()
    }
  }, [emailCampaign.id, getEmailCampaignDetails, open])

  const handleSubmit = () => {
    handleUpdate()
    onSubmit()
  }
  const handleClose = () => {
    onClose()
  }

  const handleFollowUp = () => {
    onClose()
    onFollowUp(selectedLeads)
  }

  // Update name value if email campaign is updated
  useEffect(() => {
    setName(emailCampaign.name)
  }, [emailCampaign.name])

  const selectedLeadIds = selectedLeads?.map((lead) => lead.prospectId)

  const handleLeadSelect = (lead: EmailCampaignCohort) => {
    const index = selectedLeadIds.indexOf(lead.prospectId)
    const newSelectedLeads = [...selectedLeads]
    if (index === -1) {
      newSelectedLeads?.push(lead)
    } else {
      newSelectedLeads?.splice(index, 1)
    }
    setSelectedLeads(newSelectedLeads)
  }

  const [deleteEmailCampaignMutation] = useDeleteEmailCampaignMutation()
  const handleDelete = async () => {
    try {
      await deleteEmailCampaignMutation({
        variables: {
          clientName,
          emailCampaignId: emailCampaign.id,
        },
        refetchQueries: [
          {
            query: GET_EMAIL_CAMPAIGNS,
            variables: { clientName },
          },
        ],
      })
      triggerSnackbar('Successfully deleted Email Campaign!', 'success')
      handleClose()
    } catch (ex) {
      triggerSnackbar('Failed to delete email campaign.', 'error')
    }
  }
  const [updateEmailCampaignMutation] = useUpdateEmailCampaignMutation()

  const handleUpdate = async () => {
    const emailCampaignInput: EmailCampaignInput = {
      name,
    }
    try {
      await updateEmailCampaignMutation({
        variables: {
          clientName,
          emailCampaignId: emailCampaign.id,
          emailCampaign: emailCampaignInput,
        },
      })
      triggerSnackbar('Updated Email Campaign!', 'success')
    } catch (ex) {
      triggerSnackbar('Failed to update email campaign.', 'error')
    }
  }

  const triggerSnackbar = (
    message: string,
    severity: 'error' | 'success' | 'info' | 'warning' | undefined
  ) => {
    setSnackbarConfig({
      ...snackbarConfig,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      autoHideDuration: 6000,
      open: true,
      message,
      severity,
    })
  }

  const generateRow = (lead: EmailCampaignCohort): JSX.Element => {
    const isSelected = selectedLeadIds?.includes(lead.prospectId)
    return (
      <>
        <ResponsiveCell responseSize={RESPONSE_SIZE} align="center">
          {getProspectName(lead?.prospect, '-')}
        </ResponsiveCell>
        <ResponsiveCell responseSize={RESPONSE_SIZE} align="center">
          {lead?.prospect?.email}
        </ResponsiveCell>
        <ResponsiveCell responseSize={RESPONSE_SIZE} align="center">
          {lead?.lastFollowUp
            ? formatDateTime(lead.lastFollowUp, 'MM/dd/yyy h:mma')
            : '-'}
        </ResponsiveCell>
        <ResponsiveCell responseSize={RESPONSE_SIZE} align="center">
          {EmailCampaignStatusTitles[lead.status]}
        </ResponsiveCell>
        <ResponsiveCell responseSize={RESPONSE_SIZE} align="center">
          <Checkbox checked={isSelected} />
        </ResponsiveCell>
      </>
    )
  }

  const onRowClick = (dataRow: unknown) => {
    const lead = dataRow as EmailCampaignCohort
    handleLeadSelect(lead)
  }

  const columns: JSX.Element[] = [
    <ResponsiveCell
      responseSize={RESPONSE_SIZE}
      align="center"
      key="header-cell-name"
    >
      Name
    </ResponsiveCell>,
    <ResponsiveCell
      responseSize={RESPONSE_SIZE}
      align="center"
      key="header-cell-email"
    >
      Email
    </ResponsiveCell>,
    <ResponsiveCell
      responseSize={RESPONSE_SIZE}
      align="center"
      key="header-cell-followup"
    >
      Last Follow-up
    </ResponsiveCell>,
    <ResponsiveCell
      responseSize={RESPONSE_SIZE}
      align="center"
      key="header-cell-status"
    >
      Status
    </ResponsiveCell>,
    <ResponsiveCell
      responseSize={RESPONSE_SIZE}
      align="center"
      key="header-cell-selected"
      style={{ lineHeight: '42px' }}
    >
      Selected
    </ResponsiveCell>,
  ]

  const emailCampaignCohort = emailCampaignCohortData?.getEmailCampaign
  const totalDenominator = emailCampaignCohort?.cohort?.length || 1
  const numSent = emailCampaignCohort?.cohort?.length || 0
  const numOpened =
    emailCampaignCohort?.cohort?.filter(
      (lead) => lead?.status === EmailCampaignCohortStatus.Opened
    ).length || 0
  const numClicked =
    emailCampaignCohort?.cohort?.filter(
      (lead) => lead?.status === EmailCampaignCohortStatus.Clicked
    ).length || 0
  const numMyHome =
    emailCampaignCohort?.cohort?.filter(
      (lead) => lead?.status === EmailCampaignCohortStatus.CreatedMyHome
    ).length || 0

  // We want the numbers to be accumulative. Ex. a status of clicked should still count as opened
  const sent = numSent
  const opened = numOpened + numClicked + numMyHome
  const clicked = numClicked + numMyHome
  const myHome = numMyHome
  // Filter cohort
  const displayCohort = (emailCampaign.cohort || [])
    .filter((lead) =>
      getProspectTag(lead?.prospect).includes(search.toLowerCase())
    )
    .filter((lead) => !status || lead?.status === status)

  const StatisticsBox = (): JSX.Element => {
    if (emailCampaignCohortLoading) {
      return (
        <Box p={1}>
          <Skeleton variant="rectangular" height={120} />
        </Box>
      )
    } else if (emailCampaignCohortError) {
      return (
        <Box p={1}>
          <Typography>
            <strong>An error occurred while loading statistics.</strong>
          </Typography>
          <Typography>{emailCampaignCohortError.message}</Typography>
        </Box>
      )
    }
    return (
      <Box p={1}>
        <Grid container>
          <Grid container>
            <Grid item xs={6}>
              <Typography>
                <strong>Status</strong>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <strong>Total</strong>
            </Grid>
            <Grid item xs={3}>
              <strong>Percentage</strong>
            </Grid>
          </Grid>
        </Grid>
        <EmailStatistic
          name="Sent"
          number={sent}
          percent={sent / totalDenominator}
        />
        <EmailStatistic
          name="Opened"
          number={opened}
          percent={opened / totalDenominator}
        />
        <EmailStatistic
          name="Clicked Link"
          number={clicked}
          percent={clicked / totalDenominator}
        />
        <EmailStatistic
          name={xs ? 'My Home' : 'Created My Home'}
          number={myHome}
          percent={myHome / totalDenominator}
        />
      </Box>
    )
  }

  return (
    <EmailCampaignDetailsModalStyled
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      fullWidth={true}
      fullScreen={fullScreen}
      scroll="paper"
      disableEnforceFocus
      className={classes.root}
    >
      <DialogTitle id="form-dialog-title">
        {/* div needed for proper dom nesting */}
        <div>
          <Typography variant="h5" color="primary">
            Email Campaign - {emailCampaign.name}
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <TextField
                  variant="standard"
                  label="Campaign Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  size="small"
                />
              </Grid>
              <Grid item>
                <TextField
                  variant="standard"
                  label="Reply To"
                  value={emailCampaign.replyTo}
                  disabled={true}
                  size="small"
                />
              </Grid>
              <Grid item>
                <TextField
                  variant="standard"
                  label="Reply To Name"
                  value={emailCampaign.replyToName}
                  disabled={true}
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <DisplayEmail emailCampaign={emailCampaign} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Typography variant="h6">Lead Search Terms</Typography>
                  <Box p={2}>
                    <Typography>
                      <strong>Community:</strong>{' '}
                      {emailCampaign.searchCommunity || 'None'}
                    </Typography>
                    <Typography>
                      <strong>Plan / Inventory:</strong>{' '}
                      {emailCampaign.searchPlan || 'None'}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Typography variant="h6">Product Sent</Typography>
                  <Box p={2}>
                    <Typography>
                      <strong>Community:</strong>{' '}
                      {emailCampaign.community?.name || 'None'}
                    </Typography>
                    <Typography>
                      <strong>Lot:</strong>{' '}
                      {emailCampaign.lot?.address || 'None'}
                    </Typography>
                    <Typography>
                      <strong>Inventory:</strong>{' '}
                      {emailCampaign.inventory
                        ? `${
                            emailCampaign.inventory.lot?.address || 'No Address'
                          }, ${
                            emailCampaign.inventory.plan?.displayName ||
                            emailCampaign.inventory.plan?.name ||
                            'No Plan'
                          }, ${
                            emailCampaign.inventory.elevation?.caption ||
                            'No Elevation'
                          }`
                        : 'None'}
                    </Typography>
                    <Typography>
                      <strong>Plan:</strong>{' '}
                      {emailCampaign.plan?.displayName ||
                        emailCampaign.plan?.name ||
                        'None'}
                    </Typography>
                    <Typography>
                      <strong>Elevation:</strong>{' '}
                      {emailCampaign.elevation?.caption || 'None'}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Typography variant="h6">Statistics</Typography>
                  {StatisticsBox()}
                </Paper>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2} justifyContent="space-evenly">
                  <TextField
                    variant="standard"
                    label="Email / Name"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <FormControl variant="standard">
                    <InputLabel id="select-status-label">Status</InputLabel>
                    <Select
                      variant="standard"
                      value={status}
                      onChange={(e) => setStatus(e.target.value as string)}
                      autoWidth
                      style={{ width: '150px' }}
                      labelId="select-status-label"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {Object.entries(EmailCampaignStatusTitles).map(
                        ([key, value]) => (
                          <MenuItem
                            value={key}
                            key={`select-status-menu-item-${key}`}
                          >
                            {value}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {emailCampaign.cohort?.length ? (
                  <ResponsiveTable
                    data={
                      displayCohort
                        ? (displayCohort as EmailCampaignCohort[])
                        : []
                    }
                    generateRow={generateRow}
                    columns={columns}
                    onRowClick={onRowClick}
                    responseSize={RESPONSE_SIZE}
                    dense={true}
                  />
                ) : (
                  <Typography variant="h6">
                    No Leads in this Campaign
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="space-between" spacing={1}>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleDelete}
                >
                  Delete Campaign
                </Button>
              </Grid>
              <Grid item>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={selectedLeads.length < 1}
                    onClick={handleFollowUp}
                  >
                    Send Follow-Up
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          Close
        </Button>
        <span style={{ position: 'relative' }}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save
          </Button>
        </span>
      </DialogActions>
    </EmailCampaignDetailsModalStyled>
  )
}

interface EmailStatisticProps {
  name: string
  number: number
  percent: number
}

function EmailStatistic({
  name,
  number,
  percent,
}: EmailStatisticProps): JSX.Element {
  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography>
          <strong>{name}:</strong>
        </Typography>
      </Grid>
      <Grid item xs={3}>
        {number}
      </Grid>
      <Grid item xs={3}>
        {Math.round(percent * 100)}%
      </Grid>
    </Grid>
  )
}
