import React, { Dispatch } from 'react'
import { AppState, AppAction } from './reducers'
import { client } from '../graphql/client'

interface AppContextProps {
  appState: AppState
  dispatch: Dispatch<AppAction>
}

/**
 * Usually we will have a 1-1 relationship between signed-in user and client.
 * However, in some cases, such as RH user, or a user with multiple clients
 * It can be a 1-n user to client mapping.
 *
 */
export const initialState: AppState = {
  darkMode: false,
  appHeight: 0,
  selectedClient: null, // selectedClient that user is viewing
  selectedApp: null, // which app is the user viewing
  apolloClient: client(),
}

export const AppStore: React.Context<AppContextProps> = React.createContext(
  {} as AppContextProps
)
