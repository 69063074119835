import React, { useCallback, useEffect, useState, useMemo } from 'react'
import Typography from '@mui/material/Typography'

import { useMetaTags, useTitle } from '@anewgo/hooks'
import { siteplans, computeExteriorUrl } from '@anewgo/utils'
import LineSeparator from './components/LineSeparator'
import StandardFeaturesList from './components/StandardFeaturesList'
import Message from './components/Message'

import ProgressModal from './components/ProgressModal'
import ProspectSgtAppointmentsDialog from './components/ProspectSgtAppointmentsDialog'

import {
  combineInteriorSelections,
  getDisclaimerText,
  getInventoryImage,
} from './utils'
// import { useGATrackingId, usePageView, useEventTracker } from '../utils/hooks'

import {
  Agent,
  Client,
  Community,
  Elevation,
  ElevationFloorplans,
  Floorplan,
  Interior,
  Inventory,
  Lot,
  Plan,
  Scheme,
  useGetBuilderAppConfigQuery,
  useGetCommunityByNameLite2Query,
  useGetElevationLayersQuery,
  useGetFloorplansByElevationIdQuery,
  useInteriorsQuery,
  useProspectQuery,
  useRequireRegistrationQuery,
} from 'graphql/gen-types'

import { API_KEY } from './constants'

import MyHomeHeader from './sections/MyHomeHeader'
import MyHomeCommunity from './sections/MyHomeCommunity'
import MyHomeElevation from './sections/MyHomeElevation'
import MyHomeElevationColorsAndLot from './sections/MyHomeElevationColorsAndLot'
import MyHomeFloorplan from './sections/MyHomeFloorplan'
import MyHomeInterior from './sections/MyHomeInterior'
import { getEmailFromProspectId, getProspectName } from 'utils/functions'
// import { AppStore } from 'store'
// import { AppActionType } from 'store/reducers'
// import { waitForData } from 'tests/common'
// import ProspectProfile from '../prospect-page/ProspectProfile'
import {
  MyHomeStyled,
  DisclaimerContent,
  StdSectionContainer,
} from './MyHome.styles'

export interface HomeSelection {
  community: Community
  plan: Plan
  elevation: Elevation
  lot: Lot | null // can be null
  scheme?: Scheme
  colorSelections: any
  fpOptSelections: FloorplanOptionSelection
  standAloneInteriorDesignSelections: any[]
  interiorDesignSelections: any[]
}

type FloorplanOptionSelection = Record<number, Floorplan[]>

interface SetStoreFunctions {
  onAnonymyousProspect: (decodedProspectIdentifier: any) => void
  onSelectCommunity: (community: Community | undefined) => void
  onSelectHome: (selection: any) => void // selection should have { plan, elevation, lot, mirror: uiConfig.mirror, schemeForLot }
  onSelectScheme: (scheme: Scheme) => void
  onUpdateColorSelections: (colorSelections: any) => void
  onUpdateFloorplanSelections: (
    floorplanSelections: FloorplanOptionSelection
  ) => void
  onUpdateInterioDesignSelections: (interiorDesignSelections: any) => void
  onRestoreFromFavoriteList: () => void
  onSetTargetCommunities: (communities: Community[]) => void
  onSetFloor: (fnum: number) => void
  onSetContactDialogOpen: () => void
}

export type MyHomePageVariants = 'myHome' | 'brochure' | 'favorite'

export type MyHomeProps = {
  variant?: MyHomePageVariants
  client: Client
  selection: HomeSelection
  prospectId: string
  homeIdentifier?: string
  favoriteIdentifier?: number
  isInventoryWithNoPlanElevation: boolean
  uiConfig: any
  setStore?: SetStoreFunctions
  onRequestToChange?: (target: string) => void
  onClickSignIn?: () => void
  enableTracking?: boolean
  // This onTrackEvent can implement track() calls, but there is generateSelectionTracking() that I don't know how to implement yet.
  onTrackEvent?: (event: string, data: unknown) => void
}

const { isInventoryLot, availableSchemesForLot } = siteplans

const PAGE_TITLES: Record<MyHomePageVariants, string> = {
  myHome: 'My Home',
  brochure: 'Home Brochure',
  favorite: 'Favorite Details',
}

export default function MyHome({
  variant = 'myHome',
  client,
  selection,
  prospectId,
  homeIdentifier,
  favoriteIdentifier,
  isInventoryWithNoPlanElevation,
  uiConfig,
  setStore,
  onRequestToChange,
  onClickSignIn,
  enableTracking,
  onTrackEvent,
}: MyHomeProps): JSX.Element | null {
  const [openProgressModal, setOpenProgressModal] = useState(false)
  const [openMessage, setOpenMessage] = useState(false)
  const [, setHydrated] = useState(false)
  const [infoMessage, setInfoMessage] = useState('')
  const [progressModalTitle, setProgressModalTitle] = useState('')
  const [isDialogVisible, setIsDialogVisible] = useState(false)
  const [linkToBrochure, setLinkToBrochure] = useState(false)

  const clientName = client?.altName || ''
  const isInventory = selection.lot && isInventoryLot(selection.lot)
  const communityId = selection.community?.id || 0
  const communityName = selection.community?.name || ''
  const planName = selection.plan?.name || ''
  const elevation = selection.elevation // TODO: would this be the same ? Look into
  const elevationId = elevation.id
  const mirrorElevationId = elevation.mirrorElevationId || elevationId
  const planId = selection.plan?.id
  const lotId = selection.lot?.id

  const inventory: Inventory | null = selection.lot?.inventory || null

  const prospectEmail = getEmailFromProspectId(prospectId) // TODO: get prospect email
  const title = PAGE_TITLES[variant]

  /* Get Builder App Config */
  const builderAppConfigResult = useGetBuilderAppConfigQuery({
    variables: {
      clientName,
    },
  })
  const builderAppConfig = builderAppConfigResult?.data?.builderAppConfig as any

  /* Get Require Registration */
  const requireRegistrationResult = useRequireRegistrationQuery({
    variables: {
      clientName,
    },
  })
  const requireRegistration = requireRegistrationResult?.data
    ?.customization as any

  /* Get Prospect */
  const prospectResult = useProspectQuery({
    variables: {
      clientName,
      email: prospectEmail,
    },
  })

  const prospect =
    prospectResult?.data?.colonnadeApiProspectByEmail || undefined

  const isSignedIn = !!prospect // if prospect is set, user is signed in
  // TODO: Rename this Query. bad naming
  const getCommunityResult = useGetCommunityByNameLite2Query({
    variables: {
      clientName,
      communityName,
    },
  })
  const community = getCommunityResult?.data?.communityByName as Community

  // /* Get Plan from Selection (by name) */
  // const getPlanByNameResult = useGetPlanByNameQuery({
  //   variables: {
  //     clientName,
  //     communityName,
  //     planName,
  //     active: true, // TODO: remove this, is it not default true ?
  //   },
  // })
  const plan = selection.plan // getPlanByNameResult?.data?.planByName as Plan

  /* Get Elevation from Selection (by id) */
  // TODO: what is mirror?
  const getElevationColorsResult = useGetElevationLayersQuery({
    variables: {
      clientName,
      elevationId,
      planId,
      communityId,
    },
  })
  const elevationColors = getElevationColorsResult?.data?.elevation as Elevation
  const getMirrorElevationResult = useGetElevationLayersQuery({
    variables: {
      clientName,
      elevationId: mirrorElevationId,
      planId,
      communityId,
    },
  })
  const mirrorElevation = getMirrorElevationResult?.data?.elevation as Elevation

  /* Get Floorplans for Selection */
  const getFloorplansResult = useGetFloorplansByElevationIdQuery({
    variables: {
      clientName,
      elevationId,
      planId,
      communityId,
    },
  })
  const floorplans = getFloorplansResult?.data
    ?.floorplansByElevationId as ElevationFloorplans

  /* Get Interiors for Selection */
  const interiorsResult = useInteriorsQuery({
    variables: {
      clientName,
      interior: {
        // TODO: make sure can use selction like this
        communityId: selection.community?.id,
        planId: selection.plan?.id,
        elevationId: selection.elevation?.id,
      },
    },
  })
  const interiors = interiorsResult?.data?.interiors as Interior[]

  /* Get agent emails */
  const agentEmails =
    community?.agents
      ?.filter((a): a is Agent => a !== null && a !== undefined)
      ?.map((agent) => agent?.email) || []

  /*
   * HANDLERS
   */
  const handleShowSignIn = () => {
    onClickSignIn && onClickSignIn()
  }

  const handleRequestToChange = (target: string) => {
    onRequestToChange && onRequestToChange(target)
  }

  const handleSetFloor = (fnum: number) => {
    setStore?.onSetFloor(fnum)
  }

  const handleOpenContactDialog = () => {
    setStore?.onSetContactDialogOpen()
  }

  const handleTrackEvent = (event: string, data: unknown) => {
    enableTracking && onTrackEvent && onTrackEvent(event, data)
  }

  const planDisplayName = plan.displayName || plan.name
  useTitle({
    title: `Favorite Details${planDisplayName ? `: ${planDisplayName}` : ''}`,
  })
  useMetaTags({
    name: 'description',
    content:
      client &&
      community &&
      `Favorite Details${
        planDisplayName ? `: ${planDisplayName}` : ''
      }. This is a favorite for ${getProspectName(prospect)}${
        prospect?.email ? ` (${prospect?.email})` : ''
      }`,
  })

  useEffect(() => {
    if (!prospectId || (!homeIdentifier && !favoriteIdentifier)) {
      return // no-op
    }

    async function fetchData() {
      if (selection === undefined) {
        return
      }

      const {
        lot,
        scheme,
        colorSelections,
        fpOptSelections,
        interiorDesignSelections,
      } = selection
      const { standAloneInteriorDesignSelections } = selection

      const combinedInteriors = combineInteriorSelections(
        interiorDesignSelections,
        standAloneInteriorDesignSelections
      )

      const availableSchemes = availableSchemesForLot(lot) || []
      let schemeForLot: Scheme | undefined
      if (availableSchemes?.length === 1) {
        schemeForLot = elevationColors?.schemes
          ?.filter((s): s is Scheme => s !== null && s !== undefined) // filter undefined
          ?.find((scheme: Scheme) => scheme?.id === availableSchemes[0].id)
      }

      // setStore contains functions that are called to handle any global state handling.
      // Doing this allows the state to manipulated in different ways depending on which implementation is used.
      // Also, hopefully, means that changes to global state does not need to alter this page's general; functionality.
      // setStore?.onAnonymyousProspect(decodedProspectId) // decodedProspectId not defined
      setStore?.onSelectCommunity(community)
      setStore?.onSelectHome({
        plan,
        elevation,
        lot,
        mirror: uiConfig.mirror,
        schemeForLot,
      })

      setStore?.onUpdateColorSelections(colorSelections)
      setStore?.onUpdateFloorplanSelections(fpOptSelections)
      setStore?.onUpdateInterioDesignSelections(
        combinedInteriors ? [...combinedInteriors] : []
      )
      setStore?.onRestoreFromFavoriteList()
      setHydrated(true)
      // This is a messy way to ensure scheme or schemeForLot is not undefined.
      scheme !== undefined
        ? setStore?.onSelectScheme(scheme)
        : schemeForLot !== undefined && setStore?.onSelectScheme(schemeForLot)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prospectId, homeIdentifier, favoriteIdentifier])

  /* Set Meta Tags */
  const exteriorUrl = useMemo(() => {
    return client && community && plan && elevationColors
      ? computeExteriorUrl(
          API_KEY,
          client.altName,
          community.colormtd,
          community.name,
          client.directoryName,
          elevationColors,
          `${process.env.REACT_APP_HEMI_ENGINE}/api/exterior`,
          plan.name,
          inventory?.exteriorConfiguration?.scheme,
          selection?.colorSelections,
          900
        )
      : null
  }, [client, community, elevationColors, plan, selection, inventory])
  useMetaTags({ property: 'og:image', content: exteriorUrl })

  const hasInteriorData =
    selection?.interiorDesignSelections &&
    Object.keys(selection.interiorDesignSelections).length &&
    selection.interiorDesignSelections[0] !== undefined

  const inventoryPhotoUrl = getInventoryImage(
    client?.directoryName || '',
    elevation,
    inventory
  )

  // TODO: Should these be refactored to components? In new file, this file?
  const renderStandardFeatures = () => {
    const standardFeaturesDisplayName =
      builderAppConfig?.standardFeaturesDisplayName
    const stdFeatureCategories = community?.stdFeatureCategories
    if (!stdFeatureCategories || stdFeatureCategories.length === 0) {
      return null
    }
    return (
      <StdSectionContainer>
        <div>
          <LineSeparator
            title={standardFeaturesDisplayName || 'Standard Features'}
          />
        </div>
        <StandardFeaturesList community={community} />
      </StdSectionContainer>
    )
  }
  const renderDisclaimerSection = () => {
    const disclaimer = getDisclaimerText(client)
    return (
      <DisclaimerContent>
        <Typography
          variant="h6"
          style={{ fontWeight: 'bold', paddingLeft: 36, paddingTop: 32 }}
        >
          Disclaimer:
        </Typography>
        <Typography
          style={{ paddingRight: 36, paddingLeft: 36 }}
          variant="caption"
          component="p"
        >
          {disclaimer}
        </Typography>
      </DisclaimerContent>
    )
  }

  if (
    !selection ||
    !community ||
    !plan ||
    !elevation ||
    !elevationColors ||
    !mirrorElevation ||
    !floorplans ||
    !interiors
  ) {
    return null
  }

  // r e n d e r
  return (
    <MyHomeStyled>
      {plan && elevation && (
        <MyHomeHeader
          title={title}
          client={client}
          uiConfig={uiConfig}
          selection={selection}
          prospect={prospect}
          homeIdentifier={homeIdentifier}
          favoriteIdentifier={favoriteIdentifier}
          requireRegistration={requireRegistration}
          isSignedIn={isSignedIn}
          isInventoryWithNoPlanElevation={isInventoryWithNoPlanElevation}
          onClickSignIn={handleShowSignIn}
          onSetOpenMessage={setOpenMessage}
          onOpenContactDialog={handleOpenContactDialog}
          onSetOpenProgressModal={setOpenProgressModal}
          onSetInfoMessage={setInfoMessage}
          onSetProgressModalTitle={setProgressModalTitle}
        />
      )}
      <MyHomeCommunity
        uiConfig={uiConfig}
        selection={selection}
        client={client}
        community={community}
      />
      <MyHomeElevation
        uiConfig={uiConfig}
        selection={selection}
        client={client}
        community={community}
        plan={plan}
        elevation={elevation}
        elevationColors={elevationColors}
        mirrorElevation={mirrorElevation}
        onRequestToChange={handleRequestToChange}
      />
      <MyHomeElevationColorsAndLot
        variant={variant}
        builderAppConfig={builderAppConfig}
        selection={selection}
        prospect={prospect}
        client={client}
        community={community}
        elevationColors={elevationColors}
        onRequestToChange={handleRequestToChange}
        onShowSignIn={handleShowSignIn}
        onDialogVisible={setIsDialogVisible}
        onTrackEvent={handleTrackEvent}
      />
      <MyHomeFloorplan
        variant={variant}
        uiConfig={uiConfig}
        builderAppConfig={builderAppConfig}
        selection={selection}
        client={client}
        community={community}
        plan={plan}
        elevation={elevation}
        floorplans={floorplans}
        onRequestToChange={handleRequestToChange}
        onSetFloor={handleSetFloor}
      />
      {hasInteriorData && !isInventory ? (
        <MyHomeInterior
          client={client}
          selection={selection}
          interiors={interiors}
        />
      ) : null}
      {renderStandardFeatures()}
      {renderDisclaimerSection()}
      <ProgressModal open={openProgressModal} title={progressModalTitle} />
      <Message
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openMessage}
        autoHideDuration={3000}
        onClose={() => setOpenMessage(false)}
        variant="info"
        message={infoMessage}
      />
      {isDialogVisible && prospect && (
        <ProspectSgtAppointmentsDialog
          client={client}
          uiConfig={uiConfig}
          prospect={prospect}
          appointment={null}
          isOpen={isDialogVisible}
          setOpen={setIsDialogVisible}
          agentEmail={agentEmails}
          imgUrl={inventoryPhotoUrl}
          lot={selection?.lot as Lot}
          community={community}
        />
      )}
    </MyHomeStyled>
  )
}
