import React from 'react'
import {
  CommonQueryFilter,
  useIndustrySessionTrendsQuery,
} from 'graphql/gen-types'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import ComponentCard from 'components/common/layout/ComponentCard'
import IndustrySessionTrendsPanel from 'components/analytics/sessions/IndustrySessionsTrendsPanel'

const COMPONENT_SKELETON_HEIGHT = 500

interface IndustrySessionTrendsProps {
  filter: CommonQueryFilter
}

export default function IndustrySessionTrendsDisplay(
  props: IndustrySessionTrendsProps
): JSX.Element {
  // Fetch data
  const { data, loading, error } = useIndustrySessionTrendsQuery({
    variables: {
      filter: props.filter,
    },
  })
  const industrySessionTrends = data?.industrySessionTrends || []

  // Handle Error
  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized
          message={error.toString()}
          imageName={'SessionsSummaryTrends'}
        ></Unauthorized>
      )
    }
    return <div>{error.toString()}</div>
  }

  // Render
  return (
    <ComponentCard
      result={{ data: industrySessionTrends, error, loading }}
      skeletonHeight={COMPONENT_SKELETON_HEIGHT}
    >
      <IndustrySessionTrendsPanel
        data={industrySessionTrends}
        isIndustry={true}
      ></IndustrySessionTrendsPanel>
    </ComponentCard>
  )
}
