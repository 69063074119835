import ResponsiveTable, {
  ResponsiveCell,
} from 'components/common/tables/ResponsiveTable'
import React, { useState } from 'react'
import { EmailCampaignCohort } from '../../../../graphql/gen-types'
import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@mui/material/Tooltip'
import { formatDateTime, getProspectName } from 'utils/functions'
interface EmailCohortTableProps {
  cohort: EmailCampaignCohort[]
  selectedCohort: EmailCampaignCohort[]
  onChange: (cohort: EmailCampaignCohort[]) => void
}

export default function EmailCohortTable({
  cohort,
  selectedCohort,
  onChange,
}: EmailCohortTableProps): JSX.Element {
  const [selected, setSelected] = useState(
    selectedCohort.filter((lead) => lead?.prospect?.receiveCampaignEmails)
  )

  const filteredCohort = cohort.filter(
    (lead) => lead?.prospect?.receiveCampaignEmails
  )
  const selectedCohortEmails = selected.map((cohort) => cohort?.prospect?.email)
  const isSelected = (cohort: EmailCampaignCohort) =>
    selectedCohortEmails.includes(cohort?.prospect?.email)

  const handleProspectClick = (row: unknown) => {
    const cohortMember = (row as EmailCampaignCohort) || undefined
    const newSelected = [...selected]
    const index = selectedCohortEmails.indexOf(
      cohortMember?.prospect?.email || ''
    )

    if (
      cohortMember?.prospect?.email &&
      cohortMember?.prospect?.receiveCampaignEmails &&
      index === -1
    ) {
      newSelected.push(cohortMember)
    } else if (index !== -1) {
      newSelected.splice(index, 1)
    }
    setSelected(newSelected)
    onChange(newSelected)
  }

  const handleSelectAll = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation()
    let newSelected = filteredCohort
    if (selected.length === filteredCohort.length) {
      newSelected = []
    }
    setSelected(newSelected)
    onChange(newSelected)
  }

  const generateRow = (
    cohortMember: EmailCampaignCohort,
    index: number | undefined
  ): JSX.Element => {
    const name = getProspectName(cohortMember?.prospect || undefined, '-')
    return (
      <>
        <ResponsiveCell responseSize="sm" align="center">
          {name}
        </ResponsiveCell>
        <ResponsiveCell responseSize="sm" align="center">
          {cohortMember?.prospect?.email}
        </ResponsiveCell>
        <ResponsiveCell responseSize="sm" align="center">
          {cohortMember?.prospect?.status || '-'}
        </ResponsiveCell>
        <ResponsiveCell responseSize="sm" align="center">
          {formatDateTime(
            cohortMember?.prospect?.registrationDate,
            'MM/dd/yyy'
          )}
        </ResponsiveCell>
        <ResponsiveCell responseSize="sm" align="center">
          {cohortMember?.prospect?.receiveCampaignEmails ? 'TRUE' : 'FALSE'}
        </ResponsiveCell>
        <ResponsiveCell responseSize="sm" align="center">
          <Checkbox
            disabled={!cohortMember?.prospect?.receiveCampaignEmails}
            checked={isSelected(cohortMember)}
          ></Checkbox>
        </ResponsiveCell>
      </>
    )
  }

  const columns: JSX.Element[] = [
    <ResponsiveCell responseSize="sm" align="center" key="header-cell-name">
      Name
    </ResponsiveCell>,
    <ResponsiveCell responseSize="sm" align="center" key="header-cell-email">
      Email
    </ResponsiveCell>,
    <ResponsiveCell responseSize="sm" align="center" key="header-cell-status">
      Status
    </ResponsiveCell>,

    <ResponsiveCell responseSize="sm" align="center" key="header-cell-lastSeen">
      Registration Date
    </ResponsiveCell>,

    <ResponsiveCell
      responseSize="sm"
      align="center"
      key="header-cell-receiveEmails"
    >
      Subscribed to Emails
    </ResponsiveCell>,
    <ResponsiveCell responseSize="sm" align="center" key="header-cell-selected">
      Selected
      <Tooltip title="Select All">
        <Checkbox
          checked={selected.length === filteredCohort.length}
          onClick={handleSelectAll}
        ></Checkbox>
      </Tooltip>
    </ResponsiveCell>,
  ]

  return (
    <ResponsiveTable
      data={cohort}
      generateRow={generateRow}
      columns={columns}
      initialRows={5}
      onRowClick={(row: unknown): void => handleProspectClick(row)}
      responseSize={'sm'}
      dense={true}
    ></ResponsiveTable>
  )
}
