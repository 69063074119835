import { LocationsSelect } from '../LocationsSelect'
import { ReactElement, useCallback } from 'react'
import React from 'react'
import {
  LocationSelection,
  OptionMap,
  SetState,
  SharedFilterArgs,
} from '../../Shared'

interface CountyFilterArgs extends SharedFilterArgs {
  counties: OptionMap<number>
  selection: LocationSelection
  setSelection: SetState<LocationSelection>
}

export default function CountyFilter({
  counties,
  selection,
  setSelection,
  larger = false,
  industryTrendsSelect = false,
  locationsFilterSelect = false,
  leadsLocationSelect = false,
}: CountyFilterArgs): ReactElement | null {
  const handleSelect = useCallback(
    (countyCode?: number): void => {
      setSelection((prev) => {
        return {
          country: prev.country,
          us: {
            state: prev.us?.state,
            county: countyCode,
          },
          can: {},
        }
      })
    },
    [setSelection]
  )

  return (
    <LocationsSelect<number>
      field="county"
      name="County"
      items={counties}
      selected={selection.us?.county}
      onSelect={handleSelect}
      locationsFilterSelect={locationsFilterSelect}
      industryTrendsSelect={industryTrendsSelect}
      leadsLocationSelect={leadsLocationSelect}
      larger={larger}
    />
  )
}
