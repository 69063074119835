import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import LinearProgress from '@mui/material/LinearProgress'
import React, { useEffect, useRef, useState } from 'react'
import { LeadRanking, Prospect } from 'graphql/gen-types'
import Tooltip from '@mui/material/Tooltip'
import ClickAwayListener from '@mui/core/ClickAwayListener'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { getFavoritePlansWithDetails } from 'components/leads/prospect-page/ProspectFavorites'
import { classes, Root } from './Journeybar.styles'
import { ProspectBrochures } from 'graphql/gen-types'

const DEFAULT_RESPONSE_SIZE = 'md'
interface JourneyBarProps {
  steps: StepArr[]
  responseSize: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | number | undefined
}

export enum Orientation {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

type StepArr = {
  label: string
  info: string
  completed: boolean
}

const filterBrochures = (brochures: ProspectBrochures[]) => {
  return brochures?.filter((brochure) => !!brochure?.creationDate)
}

export function createStepsFromLeadRankingData(
  data?: LeadRanking | null,
  prospect?: Prospect | null
): StepArr[] {
  const prospectData = prospect || data?.prospect

  const filteredBrochures = prospectData?.brochures
    ? filterBrochures(prospectData.brochures)
    : []

  const steps = [
    {
      label: `Browsed ${data?.numCommunities || 0} Communities`,
      info: '',
      completed: !!data?.numCommunities,
    },
    {
      label: `Viewed ${
        prospectData?.statistics?.numMyHomePageVisited || 0
      } My Home Page`,
      info: '',
      completed: !!prospectData?.statistics?.numMyHomePageVisited,
    },
    {
      label: `Selected ${prospectData?.favorites?.length || 0} Favorites`,
      info: '',
      completed: !!prospectData?.favorites?.length,
    },
    {
      label: `Downloaded ${filteredBrochures.length || 0} Brochure`,
      info: '',
      completed: !!filteredBrochures.length,
    },
    {
      label: 'Contacted Us',
      info: '',
      completed: !!prospectData?.statistics?.numContactsUs,
    },
    {
      label: 'Scheduled Appt',
      info: '',
      completed: !!prospectData?.statistics?.numAppointments,
    },
    {
      label: 'Reserve Now',
      info: '',
      completed: false,
    },
  ]

  return steps
}

export function createStepsFromProspectData(
  data: Prospect | undefined | null
): StepArr[] {
  const favorites = data?.favorites || []
  const planStatistics = data?.statistics?.plansStatistics || []
  const favoritedEvents = data?.statistics?.favorites || []
  const favoritePlans = getFavoritePlansWithDetails(
    favorites,
    planStatistics,
    favoritedEvents
  )
  const favoritePlansNames = favoritePlans.map(
    (favorite) =>
      `${favorite?.elevations?.[0]?.planName} ${favorite?.elevations?.[0]?.caption}`
  )

  const filteredBrochures = data?.brochures
    ? filterBrochures(data.brochures)
    : []

  const steps = [
    {
      label: `Browsed ${
        data?.statistics?.communitiesStatistics?.length || 0
      } Communities`,
      info: '',
      completed: !!data?.statistics?.communitiesStatistics?.length,
    },
    {
      label: `Viewed ${
        data?.statistics?.numMyHomePageVisited || 0
      } My Home Page`,
      info: '',
      completed: !!data?.statistics?.numMyHomePageVisited,
    },
    {
      label: `Selected ${data?.favorites?.length || 0} Favorites`,
      info: favoritePlansNames.join(', '),
      completed: !!data?.favorites?.length,
    },
    {
      label: `Downloaded ${filteredBrochures.length || 0} Brochure`,
      info: '',
      completed: !!filteredBrochures.length,
    },
    {
      label: 'Contacted Us',
      info: '',
      completed: !!data?.statistics?.numContactsUs,
    },
    {
      label: 'Scheduled Appt',
      info: '',
      completed: !!data?.statistics?.numAppointments,
    },
    {
      label: 'Reserve Now',
      info: '',
      completed: false,
    },
  ]

  return steps
}

function calculateProgress(
  stepsArray: StepArr[],
  progressBarSize?: number
): number {
  const totalLength = stepsArray.length
  let completedLength = 0
  const segmentWidth =
    (progressBarSize && progressBarSize / (totalLength - 1)) || 0

  for (let i = totalLength - 1; i > 0; i--) {
    if (stepsArray[i].completed) {
      completedLength = i
      break
    }
  }

  return progressBarSize
    ? ((segmentWidth * completedLength) / progressBarSize) * 100
    : 0
}

export default function JourneyBar({
  steps,
  responseSize,
}: JourneyBarProps): JSX.Element {
  const theme = useTheme()
  const isResponseSize = useMediaQuery(
    theme.breakpoints.down(responseSize || DEFAULT_RESPONSE_SIZE)
  )

  const orientation = isResponseSize
    ? Orientation.Vertical
    : Orientation.Horizontal

  const stepperRef = useRef<HTMLHeadingElement>()
  const [progressBarSize, setProgressBarSize] = useState<number>()
  const [stepClickedMobile, setStepClickedMobile] = useState<number | null>(
    null
  )

  const progress = calculateProgress(steps, progressBarSize)

  // Adjust progress bar when new data are passed in component.
  // When data are updated text labels on step component can increase
  // size of stepper component and progress bar size needs to be recalculated.
  useEffect(() => {
    handleProgressBarSize(isResponseSize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps])

  // Handle window resize
  useEffect(() => {
    window.addEventListener('resize', () =>
      handleProgressBarSize(isResponseSize)
    )
    return () =>
      window.removeEventListener('resize', () =>
        handleProgressBarSize(isResponseSize)
      )
  })

  function handleProgressBarSize(isVertical?: boolean) {
    setProgressBarSize(
      isVertical ? getProgressBarSizeVertical() : getProgressBarSizeHorizontal()
    )
  }

  function getProgressBarSizeHorizontal() {
    const calcWidth =
      stepperRef?.current &&
      stepperRef?.current?.clientWidth -
        stepperRef.current?.children[0].clientWidth / 2 -
        stepperRef.current?.children[stepperRef.current?.children.length - 1]
          .clientWidth /
          2

    return calcWidth
  }

  function getProgressBarSizeVertical() {
    const calcHeight =
      stepperRef?.current &&
      stepperRef?.current?.clientHeight -
        stepperRef.current?.children?.[0]?.clientHeight

    return calcHeight
  }

  function JourneyIcon(completed: boolean): JSX.Element {
    return (
      <div
        className={`${classes.iconContainer} ${
          !completed && classes.iconDisabled
        } ${isResponseSize && classes.iconContainerVert}`}
      >
        <i className={classes.circleIcon} />
        <div
          className={`${classes.iconPointer} ${
            isResponseSize && classes.iconPointerVert
          }`}
        />
      </div>
    )
  }

  return (
    <ClickAwayListener onClickAway={() => setStepClickedMobile(null)}>
      <Root className={`${isResponseSize && classes.journeyBarContainerVert}`}>
        {!isResponseSize && (
          <LinearProgress
            style={{
              width: progressBarSize || '80%',
              marginLeft:
                (stepperRef.current &&
                  stepperRef.current?.children?.[0]?.clientWidth / 2) ||
                0,
            }}
            className={classes.progressBarMain}
            classes={{
              bar1Determinate: classes.progressBarProgress,
            }}
            variant="determinate"
            value={progress}
          />
        )}
        {isResponseSize && (
          <div
            className={classes.progressBarMainVert}
            style={{
              height: progressBarSize,
              marginTop:
                stepperRef.current &&
                stepperRef.current?.children?.[0]?.clientHeight / 2,
            }}
          >
            <div
              className={classes.progressBarProgressVert}
              style={{
                transform: `translateY(${progress - 100}%)`,
              }}
            />
          </div>
        )}
        <Stepper
          ref={stepperRef}
          className={`${classes.stepper} ${
            isResponseSize && classes.stepperVert
          }`}
          alternativeLabel={orientation === Orientation.Horizontal}
          connector={<></>}
          orientation={orientation}
        >
          {steps.map((step, index) => (
            <Step
              className={`${isResponseSize && classes.stepVert}`}
              completed={step.completed}
              key={step.label}
              onClick={() => {
                if (stepClickedMobile === index) {
                  setStepClickedMobile(null)
                } else {
                  setStepClickedMobile(index)
                }
              }}
            >
              {/* For touch device we need to control tooltip by click on the 
            step and use open prop on Tooltip component to show tooltip for clicked step */}
              <Tooltip
                {...(isResponseSize
                  ? { open: isResponseSize && index === stepClickedMobile }
                  : {})}
                title={step.info}
              >
                <StepLabel
                  StepIconComponent={() => JourneyIcon(step.completed)}
                >
                  {step.completed ? <strong>{step.label}</strong> : step.label}
                </StepLabel>
              </Tooltip>
            </Step>
          ))}
        </Stepper>
      </Root>
    </ClickAwayListener>
  )
}
