import React from 'react'
import ReservationEnvelopes from '../../components/buy-online/analytics/ReservationEnvelopes'
import { MAIN_APP_BAR_HEIGHT } from '../../constants'
import DropOfSteps from '../../components/buy-online/analytics/DropOfSteps'
import ReservationStatusRate from '../../components/buy-online/analytics/ReservationStatusRate'
import ApprovedRejectedRate from '../../components/buy-online/analytics/ApprovedRejectedRate'
import CommunitiesStatusOverview from '../../components/buy-online/analytics/CommunitiesStatusOverview'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'

const PREFIX = 'BuyOnlineReservations'

const classes = {
  root: `${PREFIX}-root`,
}

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',
    width: '100%',
    height: `calc(100vh - ${MAIN_APP_BAR_HEIGHT}px)`,
    padding: 20,
  },
}))

export default function BuyOnlineAnalytics(): JSX.Element {
  return (
    <StyledGrid container className={classes.root} spacing={2}>
      <Grid item container xs={12} spacing={2}>
        <Grid item lg={6} md={12}>
          <ReservationStatusRate />
        </Grid>
        <Grid item lg={6} md={12}>
          <ApprovedRejectedRate />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CommunitiesStatusOverview />
      </Grid>
      <Grid item xs={12}>
        <ReservationEnvelopes />
      </Grid>
      <Grid item xs={12}>
        <DropOfSteps />
      </Grid>
    </StyledGrid>
  )
}
