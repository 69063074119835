import { styled } from '@mui/material/styles'

const PREFIX = 'Admin'

export const classes = {
  componentContainer: `${PREFIX}-componentContainer`,
  root: `${PREFIX}-root`,
}

export const Root = styled('div')(({ theme }) => ({
  [`& .${classes.componentContainer}`]: {
    justifyContent: 'center',
    marginTop: '1em',
    padding: '2em',
    [theme.breakpoints.down('md')]: {
      padding: '0em',
    },
  },

  [`&.${classes.root}`]: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',
    width: '100%',
    height: `calc(100vh - 56px)`,
  },
}))
