import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import Avatar from '@mui/material/Avatar'
import TimelineIcon from '@mui/icons-material/Timeline'
import { SchemeSwatch } from '@anewgo/interactive-exterior-ng'
import { AppStore } from 'store'
import { SchemeTrend } from 'graphql/gen-types'

const PREFIX = 'CustomLegend'

const classes = {
  avatar: `${PREFIX}-avatar`,
  avatarFallback: `${PREFIX}-avatarFallback`,
  customLegendItem: `${PREFIX}-customLegendItem`,
  customLegendWrapper: `${PREFIX}-customLegendWrapper`,
  schemeSwatchWrapper: `${PREFIX}-schemeSwatchWrapper`,
  timelineIcon: `${PREFIX}-timelineIcon`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.avatar}`]: {
    border: '1px solid lightgray',
    display: 'inline-block',
    height: '28px',
    width: '28px',
    marginRight: '5px',
    verticalAlign: 'middle',
  },

  [`& .${classes.avatarFallback}`]: {
    display: 'none',
  },

  [`& .${classes.customLegendItem}`]: {
    display: 'inline-block',
    marginBottom: '4px',
    marginRight: '10px',
  },

  [`&.${classes.customLegendWrapper}`]: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      blockSize: 0,
    },
  },

  [`& .${classes.schemeSwatchWrapper}`]: {
    height: '60px',
    width: '100px',
    marginRight: '10px',
  },

  [`& .${classes.timelineIcon}`]: {
    marginRight: '4px',
    verticalAlign: 'middle',
  },
}))

export type Payload = {
  payload: { id: string }
  color: string
  name: string
  value: string
}
type CustomLegendProps = {
  payload?: Payload[]
  data?: Record<string, unknown>[]
}

const CustomLegend = ({ payload, data }: CustomLegendProps): JSX.Element => {
  const { appState } = useContext(AppStore)
  const { selectedClient } = appState
  const directoryName = selectedClient ? selectedClient.directoryName : ''
  return (
    <Root className={classes.customLegendWrapper}>
      {payload?.map((entry: Payload, index: number) => {
        let legendIcons
        const extraPayloadData = entry?.payload?.id
        if (extraPayloadData && extraPayloadData.startsWith('swatchSrc:')) {
          const swatchSrc = extraPayloadData.substring(10)
          legendIcons = (
            <React.Fragment>
              <TimelineIcon
                className={classes.timelineIcon}
                style={{ backgroundColor: 'none', color: entry.color }}
              />
              <Avatar
                src={swatchSrc}
                alt={`${entry.value?.charAt(0)}`} // Show the first character of the color name if swatch image doesn't load.
                className={classes.avatar}
              />
            </React.Fragment>
          )
        } else if (
          extraPayloadData &&
          extraPayloadData.startsWith('schemeId:')
        ) {
          const schemeId = extraPayloadData?.substring(9)
          const mySchemeTrend: SchemeTrend | undefined = data?.find(
            (schemeTrend: SchemeTrend) =>
              `${schemeTrend?.scheme?.id}` === schemeId
          )
          legendIcons = (
            <React.Fragment>
              {mySchemeTrend && (
                <div className={classes.schemeSwatchWrapper}>
                  <SchemeSwatch
                    directoryName={directoryName}
                    materials={mySchemeTrend?.scheme?.materials || []}
                  />
                </div>
              )}
              <TimelineIcon
                className={classes.timelineIcon}
                style={{ backgroundColor: 'none', color: entry.color }}
              />
            </React.Fragment>
          )
        } else {
          const strokeColor = extraPayloadData?.substring(12)
          let actualColorValue = entry.color
          let lineColor
          if (strokeColor && strokeColor !== entry.color) {
            // This means that an alternate color was used for the line, due to the color being too light or dark
            actualColorValue = strokeColor
            lineColor = entry.color
          }
          legendIcons = (
            <React.Fragment>
              {lineColor && (
                <TimelineIcon
                  className={classes.timelineIcon}
                  style={{ backgroundColor: 'none', color: lineColor }}
                />
              )}
              <Avatar
                style={{ backgroundColor: actualColorValue }}
                alt={`${entry.value?.charAt(0)}`} // Show the first character of the color name if swatch image doesn't load.
                classes={{
                  fallback: classes.avatarFallback,
                  root: classes.avatar,
                }}
              />
            </React.Fragment>
          )
        }
        return (
          <div key={`item-${index}`} className={classes.customLegendItem}>
            {legendIcons}
            {entry.value}
          </div>
        )
      })}
    </Root>
  )
}

export default CustomLegend
