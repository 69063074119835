import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'
import TableRow from '@mui/material/TableRow'
import LinearProgress from '@mui/material/LinearProgress'
import {
  OrderEnum,
  ReservationIntegrationArgs,
  ReservationIntegrationIdEnum,
} from './ReservationsListTypes'

import { StyledTableHead, classes } from './ReservationsListHeaderStyles'
const createColumn = (
  option: ReservationIntegrationArgs
): ReservationIntegrationArgs => {
  return {
    id: option.id,
    title: option.title,
    align: option.align || 'left',
    visible: option.visible ?? true,
    sortable: option.sortable ?? false,
  }
}

const getHeaderCols = (
  isUserAnewgoAdmin: boolean | null,
  isUserAdmin: boolean | null
): ReservationIntegrationArgs[] => [
  createColumn({
    id: ReservationIntegrationIdEnum.ACTIONS,
    title: 'Actions',
    align: 'right',
    visible: !!isUserAnewgoAdmin || !!isUserAdmin,
  }),
  createColumn({
    id: ReservationIntegrationIdEnum.RESERVATION_STATUS,
    title: 'Reservation Status',
    align: 'center',
    sortable: true,
  }),
  createColumn({
    id: ReservationIntegrationIdEnum.REJECT_REASON,
    title: 'Reject Reason',
    sortable: true,
  }),
  createColumn({
    id: ReservationIntegrationIdEnum.PAYMENT_STATUS,
    title: 'Payment Status',
    align: 'center',
    sortable: true,
  }),
  createColumn({
    id: ReservationIntegrationIdEnum.SIGNING_STATUS,
    title: 'Signing Status',
    align: 'center',
    sortable: true,
  }),
  createColumn({
    id: ReservationIntegrationIdEnum.CREATED_ON,
    title: 'Created On',
    sortable: true,
  }),
  createColumn({
    id: ReservationIntegrationIdEnum.BUYER,
    title: 'Buyer',
    sortable: true,
  }),
  createColumn({
    id: ReservationIntegrationIdEnum.BUYER_PHONE,
    title: 'Phone',
    sortable: true,
  }),
  createColumn({
    id: ReservationIntegrationIdEnum.BUYER_EMAIL,
    title: 'Email',
    sortable: true,
  }),
  createColumn({
    id: ReservationIntegrationIdEnum.COMMUNITY,
    title: 'Community',
    sortable: true,
  }),
  createColumn({
    id: ReservationIntegrationIdEnum.PLAN,
    title: 'Plan',
    sortable: true,
  }),
  createColumn({
    id: ReservationIntegrationIdEnum.ELEVATION,
    title: 'Elevation',
    sortable: true,
  }),
  createColumn({
    id: ReservationIntegrationIdEnum.LOT,
    title: 'Lot',
    sortable: true,
  }),
  createColumn({
    id: ReservationIntegrationIdEnum.TOTAL_PRICE,
    title: 'Total Price',
    sortable: true,
  }),
]

interface ReservationIntegrationListHeaderProps {
  orderBy: ReservationIntegrationIdEnum | undefined
  order: OrderEnum | undefined
  setSort: (key: ReservationIntegrationIdEnum) => void
  isUserAnewgoAdmin: boolean | null
  isUserAdmin: boolean | null
  isOnlineReservationFetching: boolean | null
}
const ReservationsListHeader: React.FC<ReservationIntegrationListHeaderProps> = ({
  orderBy,
  order,
  setSort,
  isUserAnewgoAdmin,
  isUserAdmin,
  isOnlineReservationFetching,
}) => (
  <StyledTableHead className={classes.tableHead}>
    {isOnlineReservationFetching && (
      <LinearProgress className={classes.loader} />
    )}
    <TableRow>
      {getHeaderCols(isUserAnewgoAdmin, isUserAdmin).map(
        (col) =>
          col.visible && (
            <TableCell align={col?.align || 'left'}>
              {col.sortable ? (
                <TableSortLabel
                  active={orderBy && orderBy === col.id}
                  direction={
                    orderBy && orderBy === col.id ? order : OrderEnum.DESC
                  }
                  onClick={() => setSort(col.id)}
                >
                  {col.title}
                </TableSortLabel>
              ) : (
                col.title
              )}
            </TableCell>
          )
      )}
    </TableRow>
  </StyledTableHead>
)

export default ReservationsListHeader
