import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import { useAtom } from 'jotai'
import { AppStore } from '../../../../store'
import { AnalyticsStore } from '../../../../store/analyticsStore'
import { SortOptions } from '../../../common/custom-inputs/SortSelect'
import {
  Community,
  Prospect,
  SpecFilter,
  useCommunitiesQuery,
  PaginatedPlansQueryVariables,
  useGetMfRecommendedPlansQuery,
  MfRecommendedPlansInput,
} from '../../../../graphql/gen-types'
import Grid from '@mui/material/Grid'
import PlansFilterBar from '../../../common/by-plan/PlansFilterBar'
import DateFnsAdapter from '@date-io/date-fns'
import { getImagePrefix } from '@anewgo/utils'
import PlanElevationsGrid from '../../../common/by-plan/PlanElevationsGrid'
import { useResizeObserver } from '../../../../utils/hooks'
import useMediaQuery from '@mui/material/useMediaQuery'
import PlanElevationsTransferList from '../../../common/by-plan/PlanElevationsTransferList'
import { SEND_RECOMMENDED_PLANS } from 'graphql/mutation/sendRecommendedPlans'
import { useMutation } from '@apollo/client'
import { AuthContext } from 'auth/AuthContext'
import { menuDialogConfigAtom, snackbarConfigAtom } from 'store/atoms'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import * as lodash from 'lodash'
import { AutoSuggestTopkPlanDialog } from './AutoSuggestTopkPlanDialog'
import { useTheme } from '@mui/material/styles'
import {
  ProspectPlanSuggestionsDialogStyled,
  classes,
} from './ProspectPlanSuggestionsDialog.styles'

const dateFns = new DateFnsAdapter()

interface ProspectPlansDialogProps {
  open: boolean
  setOpen: (open: boolean) => void
  prospect: Prospect
}

const defaultMinBeds = 3
const defaultMinBaths = 3

export interface CommunityPlanElevation {
  planName: string
  communityName: string
  elevationCaption: string
  uniqueId: string
  planId: number
  elevationId: number
  communityId: number
  elevationThumb: string
  bed?: number | null
  bath?: number | null
  size?: number | null
  isAIRecommended?: boolean
}

const ProspectPlanSuggestionsDialog = ({
  open,
  setOpen,
  prospect,
}: ProspectPlansDialogProps): JSX.Element => {
  const theme = useTheme()

  const xs = useMediaQuery(theme.breakpoints.down('sm'))
  const numberOfRecommendationsToPrepare = 10

  const favoritePlans =
    prospect?.favorites?.map((favorite) => favorite.plan) || []
  const prefilledFilter = {
    minBed: favoritePlans.length
      ? Math.min(
          ...favoritePlans.map((plan) =>
            Math.floor(plan?.bedMin || plan?.bed || 0)
          )
        )
      : defaultMinBeds,
    minBath: favoritePlans.length
      ? Math.min(
          ...favoritePlans.map((plan) =>
            Math.floor(plan?.bathMin || plan?.bath || 0)
          )
        )
      : defaultMinBaths,
    minSqft: favoritePlans.length
      ? Math.min(
          ...favoritePlans.map((plan) => plan?.sizeMin || plan?.size || 0)
        )
      : 0, // empty filter (Any)
    maxSqft: favoritePlans.length
      ? Math.max(
          ...favoritePlans.map((plan) => plan?.sizeMax || plan?.size || 0)
        )
      : 0, // empty filter (Any)
    minCost: favoritePlans.length
      ? Math.min(
          ...favoritePlans.map((plan) => plan?.costMin || plan?.cost || 0)
        )
      : 0, // empty filter (Any)
    maxCost: favoritePlans.length
      ? Math.max(
          ...favoritePlans.map((plan) => plan?.costMax || plan?.cost || 0)
        )
      : 0, // empty filter (Any)
  }
  const { appState } = useContext(AppStore)
  const { analyticsState } = useContext(AnalyticsStore)
  const { selectedClient } = appState
  const [snackbarConfig, setSnackbarConfig] = useAtom(snackbarConfigAtom)
  const rootRef = useRef<HTMLDivElement | null>(null)
  const actionsRef = useRef<HTMLDivElement | null>(null)
  const [, rootHeight] = useResizeObserver(rootRef)
  const [, actionsHeight] = useResizeObserver(actionsRef)
  const { startDate, endDate } = analyticsState
  const selectedClientName = selectedClient ? selectedClient.altName : ''
  const [communities, setCommunities] = useState<Community[]>([])

  const [filter, setFilter] = useState<SpecFilter>()
  const [sortBy, setSortBy] = useState<SortOptions>(SortOptions.Popularity)
  const [search, setSearch] = useState('')
  const [selectedItemsHeight, setSelectedItemsHeight] = useState<number>(0)
  const [selectedPlans, setSelectedPlans] = useState<CommunityPlanElevation[]>(
    []
  )

  // Matrix Factorization Recommend Plans state
  const [planCommunityOfLead, setPlanCommunityOfLead] = useState<
    MfRecommendedPlansInput[]
  >([])
  const [plansToExcludeForMf, setPlansToExcludeForMf] = useState<number[]>([])
  const [topK, setTopK] = useState<number>(0)
  const [topKDialogOpen, setTopKDialogOpen] = useState<boolean>(false)

  const [sendRecommendedPlansEmail] = useMutation(SEND_RECOMMENDED_PLANS)
  const { user } = useContext(AuthContext)
  const [, setMenuDialogConfig] = useAtom(menuDialogConfigAtom)

  const suggestedPlansQuery = useGetMfRecommendedPlansQuery({
    variables: {
      clientName: selectedClientName,
      filter: planCommunityOfLead,
      plansToBeExcluded: plansToExcludeForMf,
      topK: numberOfRecommendationsToPrepare,
      leadEmail: prospect.email || '',
    },
  })

  // Retrieve and set communities array. This is all of the communities the client has
  // An array of communityId's is used in fetching all plans.
  const communitiesQuery = useCommunitiesQuery({
    variables: {
      clientName: selectedClientName,
    },
    skip: !selectedClientName,
  })

  useEffect(() => {
    if (communitiesQuery.data && communitiesQuery.data.communities) {
      setCommunities(communitiesQuery.data.communities as Community[])
    }
  }, [communitiesQuery.data])

  // set parameters for the suggested plans
  useEffect(() => {
    if (prospect.favorites) {
      const newPlanCommunityOfLead = prospect.favorites
        .filter((favorite) => favorite.planId !== undefined)
        .map((favorite) => {
          // we just add 0 here because the type says undefined but we already filtered all undefined.
          // it is safe to assume planId will always be available.
          return {
            communityId: favorite.communityId,
            planId: favorite.planId || 0,
          }
        })
      const newPlanCommunityOfLeadNoDuplicate = lodash.uniqWith(
        newPlanCommunityOfLead,
        lodash.isEqual
      )

      // use the last 3 updated favorites to get the MfRecommended plans.
      const sortedNewPlanCommunityOfLeadNoDuplicate = lodash.orderBy(
        newPlanCommunityOfLeadNoDuplicate,
        ['lastUpdate'],
        ['desc']
      )

      setPlanCommunityOfLead(
        sortedNewPlanCommunityOfLeadNoDuplicate.slice(0, 3)
      )

      // after picking the last 3 updated, get the rest of planId because
      // we don't want to recommend that is already selected by the lead.
      const planIdsToExclude = sortedNewPlanCommunityOfLeadNoDuplicate
        .slice(3, sortedNewPlanCommunityOfLeadNoDuplicate.length)
        .map((item) => item.planId)
      setPlansToExcludeForMf(planIdsToExclude)
    }
  }, [prospect.favorites])

  const topKPlans = useMemo<CommunityPlanElevation[]>(() => {
    if (!suggestedPlansQuery?.data?.getMfRecommendedPlans) return []

    const { getMfRecommendedPlans } = suggestedPlansQuery.data
    const suggestedPlansWithUniqueId: CommunityPlanElevation[] = getMfRecommendedPlans.map(
      (item) => {
        return {
          planId: item.planId,
          planName: item.planName,
          communityId: item.communityId,
          communityName: item.communityName,
          elevationId: item.elevationId,
          elevationCaption: item.elevationCaption,
          elevationThumb: item.thumb,
          bed: item.bed,
          bath: item.bath,
          size: item.size,
          uniqueId: `${item.planId}:${item.communityId}:${item?.elevationCaption}`,
          isAIRecommended: true,
        }
      }
    )

    return suggestedPlansWithUniqueId
  }, [suggestedPlansQuery, suggestedPlansQuery.data])

  // select the suggested plans based on how many the agents wants.
  useEffect(() => {
    selectPlans(topKPlans.slice(0, topK))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topK])

  useEffect(() => {
    if (rootRef.current?.clientHeight && actionsRef.current?.clientHeight) {
      const newRootHeight = rootHeight || rootRef.current.clientHeight
      const newActionsHeight = actionsHeight || actionsRef.current.clientHeight
      setSelectedItemsHeight(newRootHeight * 0.9 - newActionsHeight)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootHeight, actionsHeight, rootRef?.current, actionsRef?.current])

  if (suggestedPlansQuery.error) {
    if (verifyUserAuthError(suggestedPlansQuery.error.toString())) {
      return (
        <Unauthorized
          message={suggestedPlansQuery.error.toString()}
          imageName={'ProspectPlanSuggestionsDialog'}
        ></Unauthorized>
      )
    }
  }

  const paginatedPlansArgs: PaginatedPlansQueryVariables = {
    clientName: selectedClientName,
    communityIds: communities.map((comm) => comm.id),
    limit: xs ? 1000 : 10,
    offset: 0,
    sortBy: 'cost',
    filter: filter || {},
    commonFilter: {
      startTime: dateFns.format(startDate, 'MM-dd-yyyy-HH:mm:ss'),
      endTime: dateFns.format(endDate, 'MM-dd-yyyy-HH:mm:ss'),
      clientName: selectedClientName,
    },
    selectedMetrics: ['popularity', 'duration', 'frequency', 'browsingData'],
    sortOrder: 'ASC',
  }

  const sendEmail = () => {
    const plans = selectedPlans.map((plan) => ({
      communityId: plan.communityId,
      communityName: plan.communityName,
      elevationCaption: plan.elevationCaption,
      elevationId: plan.elevationId,
      thumb: plan.elevationThumb,
      planId: plan.planId,
      planName: plan.planName,
      bed: plan.bed,
      bath: plan.bath,
      size: plan.size,
    }))
    const agentName =
      user.firstName && user.lastName
        ? `${user.firstName} ${user.lastName}`
        : user.firstName
    sendRecommendedPlansEmail({
      variables: {
        prospectName: prospect.name,
        prospectEmail: prospect.email,
        agentEmail: user.userEmail,
        agentName: agentName,
        clientName: selectedClientName,
        plans,
      },
    })
      .then((result) => {
        if (result.data.sendRecommendedPlansEmail) {
          setSnackbarConfig({
            ...snackbarConfig,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            autoHideDuration: 6000,
            open: true,
            message: 'Sent suggested plans to prospect.',
            severity: 'success',
          })
        } else {
          setSnackbarConfig({
            ...snackbarConfig,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            autoHideDuration: 6000,
            open: true,
            message: 'Failed to suggest plans to prospect.',
            severity: 'error',
          })
        }
      })
      .catch(() => {
        setSnackbarConfig({
          ...snackbarConfig,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          autoHideDuration: 6000,
          open: true,
          message: 'Failed to suggest plans to prospect.',
          severity: 'error',
        })
      })
  }

  const deselectPlan = (id: string) => {
    setSelectedPlans(selectedPlans.filter((plan) => plan.uniqueId !== id))
  }

  const handleFilterPreset = () => {
    setFilter(prefilledFilter)
  }

  const selectPlans = (plans: CommunityPlanElevation[]) => {
    const isPlansRecommended =
      plans.length && 'boolean' == typeof plans[0].isAIRecommended
        ? true
        : false

    // if we are switching from top 5 to top 3, wipe out all AI recommended plans first.
    let temporarySelectedPlans: CommunityPlanElevation[] = selectedPlans
    if (isPlansRecommended) {
      temporarySelectedPlans = selectedPlans.filter(
        (plan) => !(typeof plan.isAIRecommended === 'boolean')
      )
    }

    const newPlans = plans.filter(
      (plan) =>
        !temporarySelectedPlans
          .map((selectedPlan) => selectedPlan.uniqueId)
          .includes(plan.uniqueId)
    )

    const newSelectedPlans = [...newPlans, ...temporarySelectedPlans]

    setSelectedPlans(lodash.sortBy(newSelectedPlans, ['isAIRecommended']))
  }

  const handleChangeTopK = (newTopK: number): void => {
    setTopK(newTopK)
    setMenuDialogConfig({ open: false })
  }

  const deselectPlans = (plans: CommunityPlanElevation[]) => {
    const filteredPlans = selectedPlans.filter(
      (selectedPlan) =>
        !plans.map((plan) => plan.uniqueId).includes(selectedPlan.uniqueId)
    )
    setSelectedPlans(filteredPlans)
  }

  return (
    <ProspectPlanSuggestionsDialogStyled
      fullWidth
      maxWidth={'xl'}
      fullScreen={xs}
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogContent className={classes.dialogContent}>
        <AutoSuggestTopkPlanDialog
          handleChangeTopK={handleChangeTopK}
          topKDialogOpen={topKDialogOpen}
          setTopKDialogOpen={setTopKDialogOpen}
        />
        <Grid container>
          <Grid container>
            <PlansFilterBar
              filtersForSuggestPlans={[
                <Tooltip
                  title="Recommendation engine is missing required data."
                  placement="top"
                  disableFocusListener={topKPlans?.length > 0}
                  disableHoverListener={topKPlans?.length > 0}
                  disableTouchListener={topKPlans?.length > 0}
                >
                  <span>
                    <Button
                      variant="outlined"
                      onClick={() => setTopKDialogOpen(true)}
                      disabled={topKPlans?.length === 0}
                    >
                      Auto Recommend
                    </Button>
                  </span>
                </Tooltip>,
                <Button variant="outlined" onClick={handleFilterPreset}>
                  Prefill Filters
                </Button>,
              ]}
              onFilterChange={(newFilter: SpecFilter): void => {
                setFilter(newFilter)
              }}
              onSearchChange={(newSearch: string): void => {
                setSearch(newSearch)
              }}
              onSortChange={(newSortBy: SortOptions): void => {
                setSortBy(newSortBy)
              }}
              prefilledFilter={filter}
            />
          </Grid>
          {communities.length > 0 && (
            <>
              {xs ? (
                <PlanElevationsTransferList
                  paginatedPlansArgs={paginatedPlansArgs}
                  selectedMetric={sortBy}
                  search={search}
                  selectPlans={selectPlans}
                  selectedPlans={selectedPlans}
                />
              ) : (
                <Grid
                  ref={rootRef}
                  container
                  className={
                    xs ? classes.planSelectionMobile : classes.planSelection
                  }
                >
                  <Grid item>
                    <Paper className={classes.displayVertical}>
                      <Typography
                        variant="h5"
                        component="h2"
                        className={classes.textAlignCenter}
                      >
                        Available Plans
                      </Typography>
                      {/* This page is unique in that it's child component handles infinite scrolling, so the parent .root div here does not scroll. */}
                      <div className={classes.root}>
                        <PlanElevationsGrid
                          search={search}
                          height={selectedItemsHeight}
                          selectedMetric={sortBy}
                          selectPlans={selectPlans}
                          deselectPlans={deselectPlans}
                          selectedPlans={selectedPlans}
                          paginatedPlansArgs={paginatedPlansArgs}
                        />
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item className={classes.selectedItems}>
                    <Paper className={classes.displayVertical} elevation={0}>
                      <Typography
                        variant="h5"
                        component="h2"
                        className={classes.textAlignCenter}
                      >
                        Selected Plans
                      </Typography>
                      <div className={classes.overflowYAuto}>
                        {selectedPlans.map((cpe, i) => (
                          <Grow in={true} key={i}>
                            <Card className={classes.selectedItemCard}>
                              <CardContent
                                className={classes.selectedItemCardContent}
                              >
                                {cpe.isAIRecommended && (
                                  <Typography
                                    variant="caption"
                                    align="center"
                                    className={classes.aiRecommendedBanner}
                                  >
                                    Artificial Intelligence Recommendation
                                  </Typography>
                                )}

                                {!xs && (
                                  <img
                                    alt="elevation_thumbnail"
                                    src={`${getImagePrefix(
                                      selectedClient?.directoryName,
                                      `c_scale,h_200`
                                    )}/${cpe.elevationThumb}`}
                                  />
                                )}
                                <Typography
                                  variant="subtitle1"
                                  component="p"
                                  className={classes.bold}
                                >
                                  Community: {cpe.communityName}
                                </Typography>
                                <Typography variant="body1" component="p">
                                  Plan: {cpe.planName}
                                </Typography>
                                <Typography variant="body1" component="p">
                                  Elevation: {cpe.elevationCaption}
                                </Typography>
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="flex-start"
                                  alignItems="center"
                                >
                                  <Grid item xs={2}>
                                    <Typography variant="body2" component="p">
                                      Bed: {cpe.bed ? cpe.bed : 'NA'}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Typography variant="body2" component="p">
                                      Bath: {cpe.bath ? cpe.bath : 'NA'}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Typography variant="body2" component="p">
                                      Sqft: {cpe.size ? cpe.size : 'NA'}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </CardContent>
                              <CardActions
                                style={{
                                  display: 'flex',
                                  justifyContent: xs ? 'center' : 'flex-end',
                                }}
                              >
                                <Button
                                  onClick={() => deselectPlan(cpe.uniqueId)}
                                  variant="outlined"
                                  color="primary"
                                >
                                  Remove
                                </Button>
                              </CardActions>
                            </Card>
                          </Grow>
                        ))}
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions
        className={xs ? classes.mobileDialogButtons : classes.dialogButtons}
      >
        <div ref={actionsRef}>
          <Button
            className={classes.dialogButton}
            onClick={() => setOpen(false)}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            className={classes.dialogButton}
            onClick={() => {
              setOpen(false)
              sendEmail()
            }}
            color="primary"
            variant="contained"
            disabled={selectedPlans.length === 0}
          >
            Send e-mail
          </Button>
        </div>
      </DialogActions>
    </ProspectPlanSuggestionsDialogStyled>
  )
}

export default ProspectPlanSuggestionsDialog
