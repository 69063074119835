import { styled } from '@mui/material/styles'
const PREFIX = 'ReportInfoTable'

export const classes = {
  addLicensesButton: `${PREFIX}-addLicensesButton`,
  numberLicenseField: `${PREFIX}-numberLicenseField`,
  addReportInfoButton: `${PREFIX}-addReportInfoButton`,
  centered: `${PREFIX}-centered`,
  dialog: `${PREFIX}-dialog`,
  dialogText: `${PREFIX}-dialogText`,
  errorIcon: `${PREFIX}-errorIcon`,
  formControl: `${PREFIX}-formControl`,
  gridItemMarginRightAuto: `${PREFIX}-gridItemMarginRightAuto`,
  nameOrEmailFilter: `${PREFIX}-nameOrEmailFilter`,
  xsNameOrEmailFilter: `${PREFIX}-xsNameOrEmailFilter`,
  roleFilterFormControl: `${PREFIX}-roleFilterFormControl`,
  xsRoleFilterFormControl: `${PREFIX}-xsRoleFilterFormControl`,
  root: `${PREFIX}-root`,
  smallSelect: `${PREFIX}-smallSelect`,
  smallSelectLabel: `${PREFIX}-smallSelectLabel`,
  subscriptionButton: `${PREFIX}-subscriptionButton`,
  subtitle1: `${PREFIX}-subtitle1`,
  subtitle2: `${PREFIX}-subtitle2`,
  subscriptionFormControl: `${PREFIX}-subscriptionFormControl`,
  table: `${PREFIX}-table`,
  tableCell: `${PREFIX}-tableCell`,
  tableHead: `${PREFIX}-tableHead`,
  tableHeadCell: `${PREFIX}-tableHeadCell`,
  tableRow: `${PREFIX}-tableRow`,
  typography: `${PREFIX}-typography`,
  typographyTitle: `${PREFIX}-typographyTitle`,
  tableSubRow: `${PREFIX}-tableSubRow`,
  visuallyHidden: `${PREFIX}-visuallyHidden`,
}

export const Root = styled('div')(({ theme }) => ({
  [`& .${classes.addLicensesButton}`]: {
    margin: '5px 0px 0px 10px',
    width: '210px',
  },

  [`& .${classes.numberLicenseField}`]: {
    maxWidth: '300px',
    marginTop: '20px',
  },

  [`& .${classes.addReportInfoButton}`]: {
    margin: '5px 0px 0px 10px',
    width: '200px',
  },

  [`& .${classes.centered}`]: {
    textAlign: 'center',
  },

  [`& .${classes.dialog}`]: {
    minWidth: '350px',
  },

  [`& .${classes.dialogText}`]: {
    color: 'rgba(0, 0, 0, 0.67)',
  },

  [`& .${classes.errorIcon}`]: {
    color: '#f44336',
    height: '32px',
    width: '32px',
    verticalAlign: 'middle',
  },

  [`& .${classes.formControl}`]: {
    margin: `0px ${theme.spacing(1)}`,
    minWidth: 140,
    maxWidth: 300,
  },

  [`& .${classes.gridItemMarginRightAuto}`]: {
    // Allows following grid items to be right aligned to fill up available space
    marginRight: 'auto',
    // This minWidth only allows the following items to be on the same line if there is 90px of margin
    minWidth: '350px',
  },

  [`& .${classes.nameOrEmailFilter}`]: {
    float: 'right',
    margin: '5px 0px 10px 10px',
    width: '192px',
  },

  [`& .${classes.xsNameOrEmailFilter}`]: {
    float: 'left',
    margin: '5px 0px 10px 10px',
    width: '192px',
  },

  [`& .${classes.roleFilterFormControl}`]: {
    float: 'right',
    margin: '5px 5px 10px 5px',
    width: '140px',
  },

  [`& .${classes.xsRoleFilterFormControl}`]: {
    float: 'left',
    margin: '5px 5px 10px 5px',
    width: '140px',
  },

  [`& .${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: '10px',
    margin: 'auto',
    maxWidth: 1300,
  },

  [`& .${classes.smallSelect}`]: {
    padding: '10px 26px 10px 12px',
  },

  [`& .${classes.smallSelectLabel}`]: {
    transform: 'translate(14px, 12px) scale(1)',
  },

  [`& .${classes.subscriptionButton}`]: {
    width: '270px',
    margin: '5px 0px 10px 10px',
  },

  [`& .${classes.subtitle1}`]: {
    padding: '5px 10px',
  },

  [`& .${classes.subtitle2}`]: {
    paddingLeft: '10px',
  },

  [`& .${classes.subscriptionFormControl}`]: {
    marginTop: '8px',
    width: '370px',
  },

  [`& .${classes.table}`]: {
    marginTop: '20px',
  },

  [`& .${classes.tableCell}`]: {
    [theme.breakpoints.down('md')]: {
      display: 'block',
      textAlign: 'center',
    },
    lineHeight: '1rem',
    whiteSpace: 'nowrap',
  },

  [`& .${classes.tableHead}`]: {
    backgroundColor: theme.palette.action.hover,
  },

  [`& .${classes.tableHeadCell}`]: {
    [theme.breakpoints.up('md')]: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor:
          theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, 0.04)'
            : 'rgba(0, 0, 0, 0.04)',
      },
      transition: 'all .3s,border 0s',
    },
  },

  [`& .${classes.tableRow}`]: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor:
          theme.palette.mode === 'dark'
            ? theme.palette.primary.dark
            : theme.palette.primary.light,
      },
      transition: 'all .3s,border 0s',
    },
  },

  [`& .${classes.typography}`]: {
    padding: '10px',
  },

  [`& .${classes.typographyTitle}`]: {
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.contrastText
        : theme.palette.primary.main,
    padding: '10px',
  },

  [`& .${classes.tableSubRow}`]: {
    minWidth: `calc(100% - 119px)`,
  },

  [`& .${classes.visuallyHidden}`]: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))
