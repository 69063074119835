import React, { useRef } from 'react'
import { styled } from '@mui/material/styles'
// import { useEventTracker } from '../utils/hooks'
import clsx from 'clsx'
import Typography from '@mui/material/Typography'
import ModalDrawer from './ModalDrawer'
import Slider from './Slider'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import PlayArrow from '@mui/icons-material/PlayArrow'
import NumberFormat from 'react-number-format'
import { DEFAULT_MORTGAGE_DISCLAIMER_TEXT } from '../constants/index'
// import {
//   EVT_CHANGED_MORTGAGE_CALCULATOR_VALUE,
//   EVT_CLOSED_MORTGAGE_CALCULATOR,
//   EVT_OPENED_MORTGAGE_CALCULATOR,
// } from '../constants/eventTracking'
import { Prospect } from 'graphql/gen-types'
import { HomeSelection } from '../MyHome'

const PREFIX = 'MortgageCalculator'

const classes = {
  root: `${PREFIX}-root`,
  sliderFormControl: `${PREFIX}-sliderFormControl`,
  formControl: `${PREFIX}-formControl`,
  menuButton: `${PREFIX}-menuButton`,
  drawerTitle: `${PREFIX}-drawerTitle`,
  textField: `${PREFIX}-textField`,
  downPaymentFormControl: `${PREFIX}-downPaymentFormControl`,
  icon: `${PREFIX}-icon`,
  textArrowDiv: `${PREFIX}-textArrowDiv`,
  downInputLabel: `${PREFIX}-downInputLabel`,
  span: `${PREFIX}-span`,
  btn: `${PREFIX}-btn`,
  iconButtonText: `${PREFIX}-iconButtonText`,
  iconButton: `${PREFIX}-iconButton`,
  arrowIcon: `${PREFIX}-arrowIcon`,
  numberDisplay: `${PREFIX}-numberDisplay`,
  displayNumbersArea: `${PREFIX}-displayNumbersArea`,
  displayActionArea: `${PREFIX}-displayActionArea`,
}

const MortgageCalculatorStyled = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    cursor: 'pointer',
    height: 'inherit',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },

  [`& .${classes.sliderFormControl}`]: {
    padding: 4,
    width: '100%',
    marginBottom: '-10px',
    left: 0,
  },

  [`& .${classes.formControl}`]: {
    fontWeight: 700,
  },

  [`& .${classes.menuButton}`]: {
    fontSize: '0.885em',
    color: theme.palette.primary.main,
    fontWeight: 'bolder',
  },

  [`& .${classes.drawerTitle}`]: {
    fontWeight: 900,
    fontSize: '1.2 rem',
    paddingLeft: 32,
  },

  [`& .${classes.textField}`]: {
    marginTop: '10px',
  },

  [`& .${classes.downPaymentFormControl}`]: {
    width: '45%',
  },

  [`& .${classes.icon}`]: {
    marginLeft: '10px',
    color: '#074d96',
  },

  [`& .${classes.textArrowDiv}`]: {
    display: 'inline-flex',
  },

  [`& .${classes.downInputLabel}`]: {
    marginBottom: '50px',
  },

  [`& .${classes.span}`]: {
    cursor: 'pointer',
  },

  [`& .${classes.btn}`]: {
    height: '70%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingRight: 4,
    paddingLeft: 4,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },

  [`& .${classes.iconButtonText}`]: {
    fontSize: 10,
    [theme.breakpoints.down('md')]: {
      fontSize: 8,
    },
  },

  [`& .${classes.iconButton}`]: {
    position: 'relative',
    padding: '0px',
  },

  [`& .${classes.arrowIcon}`]: {
    position: 'relative',
    height: '20px',
    color: 'blue',
    [theme.breakpoints.down('md')]: {
      height: '15px',
    },
  },

  [`& .${classes.numberDisplay}`]: {
    display: 'grid',
    height: 'inherit',
    gridTemplateColumns: '1fr 30px',
    gridTemplateRows: '1fr',
    gridTemplateAreas: `"displayNumbersArea displayActionArea"`,
  },

  [`& .${classes.displayNumbersArea}`]: {
    gridArea: 'displayNumbersArea',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },

  [`& .${classes.displayActionArea}`]: {
    gridArea: 'displayActionArea',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

type MortgageCalculatorProps = {
  uiConfig: any
  prospect?: Prospect
  selection?: HomeSelection
  cost: number
  slideFrom?: 'top' | 'right'
  header?: boolean
  compareFavorites?: boolean
  myHome?: boolean
  floorPlan?: boolean
  onSetMortgageCalculatorOpen?: (open: boolean) => void
  onSetMortgageCalculatorRate?: (rate: number) => void
  onSetMortgageCalculatorTerm?: (term: number) => void
  onSetMortgageCalculatorDownPayment?: (downPayment: number) => void
}

function MortgageCalculator({
  uiConfig,
  prospect,
  selection,
  cost,
  slideFrom,
  header,
  compareFavorites,
  myHome,
  floorPlan,
  onSetMortgageCalculatorOpen,
  onSetMortgageCalculatorRate,
  onSetMortgageCalculatorTerm,
  onSetMortgageCalculatorDownPayment,
}: MortgageCalculatorProps): JSX.Element {
  // const track = useEventTracker()
  const {
    headerHeight,
    mortgageCalculatorDownPayment,
    mortgageCalculatorOpen,
    mortgageCalculatorRate,
    mortgageCalculatorTerm,
  } = uiConfig
  const dollarDownInputRef = useRef(mortgageCalculatorDownPayment)
  const percentDownInputRef = useRef<any>()
  const monthlyPaymentInputRef = useRef<any>()

  // let referrer = ''
  // if (myHome) {
  //   referrer = 'My Home'
  // } else if (floorPlan) {
  //   referrer = 'Floorplan Page'
  // } else if (compareFavorites) {
  //   referrer = 'Compare Favorites'
  // }

  const principal = cost
    .toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    })
    .slice(0, -3)

  percentDownInputRef.current = (dollarDownInputRef.current / cost) * 100

  const dollarFormattedDown = (percentDownInputRef.current / 100) * cost

  /**
    M = P [ i(1 + i)^n ] / [ (1 + i)^n – 1]
   */
  const calculateMortgagePayment = (
    cost: number,
    rate: number,
    term: number,
    downPaymentPercent: number
  ) => {
    const interest = rate / 100 / 12
    const totalPayments = term * 12
    const totalAmount = cost - (downPaymentPercent / 100) * cost
    const payment =
      (totalAmount * interest * Math.pow(1 + interest, totalPayments)) /
      (Math.pow(1 + interest, totalPayments) - 1)
    return payment.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  }

  monthlyPaymentInputRef.current = calculateMortgagePayment(
    cost,
    mortgageCalculatorRate,
    mortgageCalculatorTerm,
    percentDownInputRef.current
  )
  const toggleOpenClose = () => {
    onSetMortgageCalculatorOpen &&
      onSetMortgageCalculatorOpen(!mortgageCalculatorOpen)
    if (mortgageCalculatorOpen) {
      // track(EVT_CLOSED_MORTGAGE_CALCULATOR, {
      //   referrer,
      //   values: {
      //     cost,
      //     downPaymentAmount: dollarDownInputRef.current,
      //     downPaymentPercent: percentDownInputRef.current,
      //     monthlyPayment: monthlyPaymentInputRef.current,
      //     interest: mortgageCalculatorRate,
      //     term: mortgageCalculatorTerm,
      //   },
      //   prospect,
      //   ...selection,
      // })
    } else {
      // track(EVT_OPENED_MORTGAGE_CALCULATOR, {
      //   referrer,
      //   prospect,
      //   ...selection,
      // })
    }
  }

  const handleDollarDownPayment = (value: number, cost: number) => {
    const dollarToPercent = ((value / cost) * 100).toFixed(2)
    const newDownPayment = value
    const newDownPercent = parseFloat(dollarToPercent)
    const newMonthly = calculateMortgagePayment(
      cost,
      mortgageCalculatorRate,
      mortgageCalculatorTerm,
      newDownPercent
    )
    dollarDownInputRef.current = newDownPayment
    percentDownInputRef.current = newDownPercent
    monthlyPaymentInputRef.current = newMonthly
  }

  const NumberFormatCustom = (props: any) => {
    const { inputRef, ...other } = props
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        value={mortgageCalculatorDownPayment}
        onValueChange={(values) => {
          handleDollarDownPayment(values.floatValue || 0, cost)
        }}
        thousandSeparator
        prefix="$"
        isAllowed={(values) => values.floatValue || 0 <= cost}
      />
    )
  }

  const handlePercentDownPayment = (value: number, cost: number) => {
    const percentToDollar = ((value / 100) * cost).toFixed(2)
    const newDownPayment = parseFloat(percentToDollar)
    const newDownPercent = value
    const newMonthly = calculateMortgagePayment(
      cost,
      mortgageCalculatorRate,
      mortgageCalculatorTerm,
      newDownPercent
    )
    dollarDownInputRef.current = newDownPayment
    percentDownInputRef.current = newDownPercent
    monthlyPaymentInputRef.current = newMonthly
  }

  const handleInterestChange = (value: number) => {
    onSetMortgageCalculatorRate && onSetMortgageCalculatorRate(value)
    // track(EVT_CHANGED_MORTGAGE_CALCULATOR_VALUE, {
    //   formField: 'INTEREST',
    //   referrer,
    //   value: value,
    //   prospect,
    //   ...selection,
    // })
  }

  const handleTermChange = (value: number) => {
    onSetMortgageCalculatorTerm && onSetMortgageCalculatorTerm(value)
    // track(EVT_CHANGED_MORTGAGE_CALCULATOR_VALUE, {
    //   formField: 'TERM',
    //   referrer,
    //   value: value,
    //   prospect,
    //   ...selection,
    // })
  }

  const PercentFormatCustom = (props: any) => {
    const { inputRef, ...other } = props
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        value={percentDownInputRef.current.toFixed(2)}
        onValueChange={(values) => {
          handlePercentDownPayment(values.floatValue || 0, cost)
        }}
        prefix="%"
        isAllowed={(values) => values.floatValue || 0 <= 100}
      />
    )
  }

  const handleChangeDownPayment = () => {
    onSetMortgageCalculatorDownPayment &&
      onSetMortgageCalculatorDownPayment(dollarDownInputRef.current)
    // track(EVT_CHANGED_MORTGAGE_CALCULATOR_VALUE, {
    //   formField: 'DOWN_PAYMENT',
    //   referrer,
    //   value: {
    //     amount: dollarDownInputRef.current,
    //     percent: percentDownInputRef.current,
    //     monthlyPayment: monthlyPaymentInputRef.current,
    //   },
    //   prospect,
    //   ...selection,
    // })
  }

  const renderCalculatorForm = () => {
    return (
      <MortgageCalculatorStyled>
        {dollarFormattedDown <= cost && (
          <Typography classes={{ root: classes.drawerTitle }} variant="button">
            {principal} | {monthlyPaymentInputRef.current}/mo
          </Typography>
        )}
        {dollarFormattedDown > cost && (
          <Typography classes={{ root: classes.menuButton }} variant="button">
            lower down payment
          </Typography>
        )}
        {isNaN(dollarFormattedDown) && (
          <Typography classes={{ root: classes.menuButton }} variant="button">
            Enter a down payment
          </Typography>
        )}
        <br />
        <FormGroup
          style={{
            paddingLeft: '15px',
          }}
        >
          <div style={{ marginBottom: '10px' }}>
            <FormControl
              variant="standard"
              classes={{
                root: clsx(classes.formControl, classes.downPaymentFormControl),
              }}
            >
              <TextField
                variant="standard"
                label={'DOWN PAYMENT'}
                type="text"
                classes={{ root: classes.textField }}
                margin="normal"
                id="formatted-numberformat-input"
                InputProps={{
                  style: { marginTop: '30px' },
                  inputComponent: NumberFormatCustom,
                  onBlur: () => handleChangeDownPayment(),
                  onKeyDown: (event) => {
                    if (event.key === 'Enter') {
                      handleChangeDownPayment()
                    }
                  },
                }}
              />
            </FormControl>
            <FormControl
              variant="standard"
              classes={{
                root: clsx(classes.formControl, classes.downPaymentFormControl),
              }}
            >
              <TextField
                variant="standard"
                label={' '}
                type="text"
                classes={{ root: classes.textField }}
                margin="normal"
                id="formatted-percentformat-input"
                InputProps={{
                  style: { marginTop: '30px', marginLeft: '5px' },
                  inputComponent: PercentFormatCustom,
                  onBlur: () => handleChangeDownPayment(),
                  onKeyDown: (event) => {
                    if (event.key === 'Enter') {
                      handleChangeDownPayment()
                    }
                  },
                }}
              />
            </FormControl>
          </div>
          <br />
          <FormControl
            variant="standard"
            classes={{
              root: clsx(classes.formControl, classes.sliderFormControl),
            }}
          >
            <Slider
              width={'95%'}
              sliderLabel={'INTEREST'}
              domain={[2, 6]}
              step={0.01}
              initialValues={mortgageCalculatorRate}
              format={(d: number) => `${d / 1}%`}
              formatHandle={(d: number) => `${d / 1}%`}
              onChange={handleInterestChange}
              tickCount={3}
            />
          </FormControl>
          <br />
          <FormControl
            variant="standard"
            classes={{
              root: clsx(classes.formControl, classes.sliderFormControl),
            }}
          >
            <Slider
              width={'95%'}
              sliderLabel={'TERM'}
              domain={[10, 30]}
              step={5}
              initialValues={mortgageCalculatorTerm}
              format={(d: number) => `${d / 1} YR`}
              formatHandle={(d: number) => `${d / 1} YR`}
              onChange={handleTermChange}
              tickCount={3}
            />
          </FormControl>
        </FormGroup>
        <div style={{ margin: '10px 10px 0 10px' }}>
          <Typography
            style={{ fontSize: 8, display: 'block', lineHeight: 'normal' }}
            variant="caption"
          >
            {DEFAULT_MORTGAGE_DISCLAIMER_TEXT}
          </Typography>
        </div>
      </MortgageCalculatorStyled>
    )
  }

  // R E N D E R
  return (
    <React.Fragment>
      <div className={classes.root} onClick={toggleOpenClose}>
        {dollarFormattedDown <= cost && !compareFavorites && (
          <div className={classes.numberDisplay}>
            <div className={classes.displayNumbersArea}>
              <Typography
                classes={{ root: classes.menuButton }}
                variant="button"
              >
                {principal}
              </Typography>
              <Typography
                classes={{ root: classes.menuButton }}
                variant="button"
              >
                {monthlyPaymentInputRef.current}/mo
              </Typography>
            </div>
            <div className={classes.displayActionArea}>
              <IconButton
                classes={{ root: classes.iconButton }}
                disableRipple
                type="button"
                size="large"
              >
                <PlayArrow classes={{ root: classes.arrowIcon }} />
              </IconButton>
            </div>
          </div>
        )}
        {dollarFormattedDown > cost && (
          <Button variant="outlined" disableRipple>
            <Typography classes={{ root: classes.menuButton }} variant="button">
              lower down payment
            </Typography>
          </Button>
        )}
      </div>
      {!compareFavorites && (
        <ModalDrawer
          uiConfig={uiConfig}
          variant="side"
          slideFrom={slideFrom}
          header={header}
          style={{
            backgroundColor: 'rgba(234, 234, 234,1)',
            height: '425px',
            marginTop: headerHeight,
          }}
          open={mortgageCalculatorOpen}
          onSetOpen={() => {
            toggleOpenClose()
            onSetMortgageCalculatorOpen && onSetMortgageCalculatorOpen(true)
          }}
        >
          {renderCalculatorForm()}
        </ModalDrawer>
      )}
      {compareFavorites && <div>{renderCalculatorForm()}</div>}
    </React.Fragment>
  )
}

export default MortgageCalculator
