import { styled } from '@mui/material/styles'
const PREFIX = 'DateDisplay'

export const classes = {
  dateDisplay: `${PREFIX}-dateDisplay`,
}

export const Root = styled('div')(({ theme }) => ({
  [`&.${classes.dateDisplay}`]: {
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.contrastText
        : 'rgba(0, 0, 0, 0.54)',
    width: '160px',
    '&:hover': {
      color: 'black',
      cursor: 'pointer',
    },
  },
}))
