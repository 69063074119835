import React, { useRef, useState } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import ToplevelRoutes from '../routing/ToplevelRoutes'
import AnalyticsApp from './analytics/AnalyticsApp'
import LeadsApp from './leads/LeadsApp'
import { StyledRoot, classes } from './ColonnadeApp.styles'
import SnackbarNotification from 'components/common/notifications/SnackbarNotification'
import MarketingApp from './leads/MarketingApp'
import BuyOnlineApp from './buy-online/BuyOnlineApp'
import TokenExpireWrapper from './TokenExpireWrapper'

function ColonnadeApp(): JSX.Element {
  const rootRef = useRef(null)
  const [isReportPage, setIsReportPage] = useState(false)

  return (
    <>
      <TokenExpireWrapper>
        <CssBaseline />
        <StyledRoot
          className={isReportPage ? undefined : classes.root}
          ref={rootRef}
        >
          <>
            <SnackbarNotification />
            <AnalyticsApp />
            <LeadsApp />
            <MarketingApp />
            <BuyOnlineApp />
            <ToplevelRoutes setIsReportPage={setIsReportPage} />
          </>
        </StyledRoot>
      </TokenExpireWrapper>
    </>
  )
}

export default ColonnadeApp
