import React, { useEffect, useState } from 'react'

import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import clsx from 'clsx'
import { classes, Root } from './PlansFilterBar.styles'
type filterRangerProps = {
  name: string
  id: string
  onMinChange: (val: number) => void
  onMaxChange: (val: number) => void
  rangeMin: number
  rangeMax: number
  valueMin?: number
  valueMax?: number
  stepSize: number
  isCurrency?: boolean
}
function FilterRange({
  name,
  id,
  onMinChange,
  onMaxChange,
  rangeMin,
  rangeMax,
  valueMin = 0,
  valueMax = 0,
  stepSize,
  isCurrency = false,
}: filterRangerProps): JSX.Element {
  const [min, setMin] = useState(valueMin)
  const [max, setMax] = useState(valueMax)
  const [minHiMark, setMinHiMark] = useState(rangeMax)
  const [maxLoMark, setMaxLoMark] = useState(rangeMin)

  const handleMinChange = (value: number): void => {
    setMin(value)
    setMaxLoMark(value === 0 ? rangeMin : value)
    onMinChange(value)
  }
  const handleMaxChange = (value: number): void => {
    setMax(value)
    setMinHiMark(value === 0 ? rangeMax : value)
    onMaxChange(value)
  }

  const formatPrice = (price: number): string =>
    price
      .toLocaleString('en-US', { style: 'currency', currency: 'USD' })
      .split('.')[0]

  const minMenuValues: number[] = []
  const maxMenuValues: number[] = []
  for (let i = rangeMin; i <= minHiMark; i += stepSize) {
    minMenuValues.push(i)
  }
  for (let i = maxLoMark; i <= rangeMax; i += stepSize) {
    maxMenuValues.push(i)
  }

  useEffect(() => {
    if (valueMin !== 0) {
      const output = minMenuValues.reduce((prev, curr) =>
        Math.abs(curr - valueMin) < Math.abs(prev - valueMin) ? curr : prev
      )
      setMin(output)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueMin])

  useEffect(() => {
    if (valueMax !== 0) {
      const output = maxMenuValues.reduce((prev, curr) =>
        Math.abs(curr - valueMax) < Math.abs(prev - valueMax) ? curr : prev
      )
      setMax(output)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueMax])
  return (
    <Root>
      <div className={classes.rangeFilterMenuContainer}>
        <FormControl
          variant="standard"
          className={clsx(classes.rangeFilterFormControl)}
        >
          <InputLabel htmlFor={`${id}-min`}>{`${name} min`}</InputLabel>
          <Select
            variant="standard"
            value={min}
            onChange={(e): void => handleMinChange(e.target.value as number)}
            inputProps={{ name: 'min', id: `${id}-min` }}
            data-testid="minSelect"
            MenuProps={{ classes: { paper: classes.selectHeightLimit } }}
          >
            <MenuItem value={0}>
              <em>Any</em>
            </MenuItem>
            {minMenuValues.map((val: number) => (
              <MenuItem value={val} key={`min-${val}`}>
                {isCurrency ? formatPrice(val) : val}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          variant="standard"
          className={clsx(classes.rangeFilterFormControl)}
          style={{ marginLeft: '1rem' }}
        >
          <InputLabel htmlFor={`${id}-max`}>{`${name} max`}</InputLabel>
          <Select
            variant="standard"
            value={max}
            onChange={(e): void => handleMaxChange(e.target.value as number)}
            inputProps={{ name: 'max', id: `${id}-max` }}
            data-testid="maxSelect"
            MenuProps={{ classes: { paper: classes.selectHeightLimit } }}
          >
            <MenuItem value={0}>
              <em>Any</em>
            </MenuItem>
            {maxMenuValues.map((val: number) => (
              <MenuItem value={val} key={`min-${val}`}>
                {isCurrency ? formatPrice(val) : val}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </Root>
  )
}

export default FilterRange
