import { useMutation } from '@apollo/client'
import { Unauthorized } from 'components/auth/Unauthorized'
import {
  Agent,
  Prospect,
  ProspectStatus,
  useProspectAgentQuery,
  useUpdateProspectMutation,
} from 'graphql/gen-types'
import { ADD_AGENTS_PROSPECT } from 'graphql/mutation/addAgentsProspect'
import React from 'react'

import JwtDecode from 'jwt-decode'
import { useEffect, useState } from 'react'
import hydrationStore from 'store/HydrationStore'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { MultiOptionAutoComplete } from 'components/common/custom-inputs/MultiOptionsAutocomplete'
import { AGENT_ROLE_ID } from '../../../constants'
import TextField from '@mui/material/TextField'

interface ProspectAgentAssignProps {
  prospect?: Prospect | null
  agents?: Agent[]
  loading?: boolean
}

function ProspectAgentAssign({
  prospect,
  agents,
  loading,
}: ProspectAgentAssignProps): JSX.Element {
  const [selectedAgents, setSelectedAgents] = useState<Agent[]>([])
  const [filteredAgents, setFilteredAgents] = useState<Agent[]>([])

  const clientName = hydrationStore.selectedClient?.altName
  const hydratedToken = hydrationStore.token || ''
  const { colonnadeRoleId } = JwtDecode(hydratedToken)

  const [addAgentsProspect] = useMutation(ADD_AGENTS_PROSPECT)

  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down('sm'))

  const {
    data: agentData,
    loading: agentLoading,
    error: agentError,
  } = useProspectAgentQuery({
    skip: !prospect?.email,
    variables: {
      clientName: clientName || '',
      email: prospect?.email || '',
    },
    fetchPolicy: 'no-cache',
  })

  const [updateProspectMutation] = useUpdateProspectMutation({
    variables: {
      clientName: clientName || '',
      prospect: {
        id: 0,
      },
    },
  })

  useEffect(() => {
    if (agentData?.prospectByEmail?.agents && agents) {
      const ids = agentData?.prospectByEmail?.agents.map(
        (agent) => agent?.agentId
      )
      setSelectedAgents(agents.filter((agent) => ids.includes(agent.id)))
    }
  }, [agentData, agents])

  useEffect(() => {
    const unicateAgents: Agent[] = []
    agents?.forEach((agent) => {
      let exist = false
      unicateAgents.forEach((uAgent) => {
        if (uAgent.id === agent.id) {
          exist = true
        }
      })

      if (!exist) {
        unicateAgents.push(agent)
      }
    })

    setFilteredAgents(unicateAgents)
  }, [agents])

  const _handleAgentChange = (value: Agent[]): void => {
    const agentIds = value.map((agent) => agent.id)
    setSelectedAgents(value)
    addAgentsProspect({
      variables: {
        prospectEmail: prospect?.email,
        agentIds: agentIds,
        clientName: clientName || '',
        source: 'colonnade',
      },
    }).then(() => {
      if (prospect?.id) {
        updateProspectMutation({
          variables: {
            clientName: clientName || '',
            prospect: {
              id: prospect.id,
              status: ProspectStatus.Open,
            },
          },
        })
      }
    })
  }

  if (agentError) {
    if (verifyUserAuthError(agentError.toString())) {
      return (
        <Unauthorized
          message={agentError.toString()}
          imageName={'ProspectProfile'}
        ></Unauthorized>
      )
    }
    return <div>{agentError.toString()}</div>
  }

  return (
    <div style={{ maxWidth: xs ? '200px' : '350px', margin: '0 0 0 auto' }}>
      {agentLoading || loading ? (
        <TextField
          variant="outlined"
          label="Assign Agents"
          disabled
          placeholder="Loading..."
          InputLabelProps={{ shrink: true }}
        />
      ) : (
        filteredAgents &&
        filteredAgents.length !== 0 && (
          <MultiOptionAutoComplete
            inputLabel="Assign Agents"
            options={filteredAgents}
            limitTags={2}
            disabled={colonnadeRoleId === AGENT_ROLE_ID}
            selectedOptions={selectedAgents}
            setSelectedOptions={_handleAgentChange}
            optionLabelAccessor={(agent): string =>
              `${agent.firstName} ${agent.lastName}` || 'unknown'
            }
          />
        )
      )}
    </div>
  )
}

export default ProspectAgentAssign
