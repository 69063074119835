/* eslint-disable */
import { gql } from '@apollo/client'
import * as ApolloReactCommon from '@apollo/client'
import * as ApolloReactHooks from '@apollo/client'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: any
  /** The `Byte` scalar type represents byte value as a Buffer */
  Byte: any
  /** A field whose value is a Currency: https://en.wikipedia.org/wiki/ISO_4217. */
  Currency: any
  /** Custom scalar type for representing DateTime in multiple formats. Supported formats are string representing valid datetime or number representing unix timestamp in milliseconds */
  Date: any
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any
  /**
   * A string representing a duration conforming to the ISO8601 standard,
   * such as: P1W1DT13H23M34S
   * P is the duration designator (for period) placed at the start of the duration representation.
   * Y is the year designator that follows the value for the number of years.
   * M is the month designator that follows the value for the number of months.
   * W is the week designator that follows the value for the number of weeks.
   * D is the day designator that follows the value for the number of days.
   * T is the time designator that precedes the time components of the representation.
   * H is the hour designator that follows the value for the number of hours.
   * M is the minute designator that follows the value for the number of minutes.
   * S is the second designator that follows the value for the number of seconds.
   *
   * Note the time designator, T, that precedes the time value.
   *
   * Matches moment.js, Luxon and DateFns implementations
   * ,/. is valid for decimal places and +/- is a valid prefix
   */
  Duration: any
  /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
  EmailAddress: any
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  GUID: any
  /** A field whose value is a hexadecimal: https://en.wikipedia.org/wiki/Hexadecimal. */
  Hexadecimal: any
  /** A field whose value is a hex color code: https://en.wikipedia.org/wiki/Web_colors. */
  HexColorCode: any
  /** A field whose value is a CSS HSL color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#hsl()_and_hsla(). */
  HSL: any
  /** A field whose value is a CSS HSLA color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#hsl()_and_hsla(). */
  HSLA: any
  /** A field whose value is an International Bank Account Number (IBAN): https://en.wikipedia.org/wiki/International_Bank_Account_Number. */
  IBAN: any
  /** A field whose value is a IPv4 address: https://en.wikipedia.org/wiki/IPv4. */
  IPv4: any
  /** A field whose value is a IPv6 address: https://en.wikipedia.org/wiki/IPv6. */
  IPv6: any
  /** A field whose value is a ISBN-10 or ISBN-13 number: https://en.wikipedia.org/wiki/International_Standard_Book_Number. */
  ISBN: any
  /**
   * A string representing a duration conforming to the ISO8601 standard,
   * such as: P1W1DT13H23M34S
   * P is the duration designator (for period) placed at the start of the duration representation.
   * Y is the year designator that follows the value for the number of years.
   * M is the month designator that follows the value for the number of months.
   * W is the week designator that follows the value for the number of weeks.
   * D is the day designator that follows the value for the number of days.
   * T is the time designator that precedes the time components of the representation.
   * H is the hour designator that follows the value for the number of hours.
   * M is the minute designator that follows the value for the number of minutes.
   * S is the second designator that follows the value for the number of seconds.
   *
   * Note the time designator, T, that precedes the time value.
   *
   * Matches moment.js, Luxon and DateFns implementations
   * ,/. is valid for decimal places and +/- is a valid prefix
   */
  ISO8601Duration: any
  JSON: any
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any
  /** A field whose value is a JSON Web Token (JWT): https://jwt.io/introduction. */
  JWT: any
  /** A field whose value is a valid decimal degrees latitude number (53.471): https://en.wikipedia.org/wiki/Latitude */
  Latitude: any
  /** A local date string (i.e., with no associated timezone) in `YYYY-MM-DD` format, e.g. `2020-01-01`. */
  LocalDate: any
  /** A local time string (i.e., with no associated timezone) in 24-hr `HH:mm[:ss[.SSS]]` format, e.g. `14:25` or `14:25:06` or `14:25:06.123`.  This scalar is very similar to the `LocalTime`, with the only difference being that `LocalEndTime` also allows `24:00` as a valid value to indicate midnight of the following day.  This is useful when using the scalar to represent the exclusive upper bound of a time block. */
  LocalEndTime: any
  /** A local time string (i.e., with no associated timezone) in 24-hr `HH:mm[:ss[.SSS]]` format, e.g. `14:25` or `14:25:06` or `14:25:06.123`. */
  LocalTime: any
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  Long: any
  /** A field whose value is a valid decimal degrees longitude number (53.471): https://en.wikipedia.org/wiki/Longitude */
  Longitude: any
  /** A field whose value is a IEEE 802 48-bit MAC address: https://en.wikipedia.org/wiki/MAC_address. */
  MAC: any
  /** Floats that will have a value less than 0. */
  NegativeFloat: any
  /** Integers that will have a value less than 0. */
  NegativeInt: any
  /** A string that cannot be passed as an empty value */
  NonEmptyString: any
  /** Floats that will have a value of 0 or more. */
  NonNegativeFloat: any
  /** Integers that will have a value of 0 or more. */
  NonNegativeInt: any
  /** Floats that will have a value of 0 or less. */
  NonPositiveFloat: any
  /** Integers that will have a value of 0 or less. */
  NonPositiveInt: any
  Object: any
  /** A field whose value conforms with the standard mongodb object ID as described here: https://docs.mongodb.com/manual/reference/method/ObjectId/#ObjectId. Example: 5e5677d71bdc2ae76344968c */
  ObjectID: any
  /** A field whose value conforms to the standard E.164 format as specified in: https://en.wikipedia.org/wiki/E.164. Basically this is +17895551234. */
  PhoneNumber: any
  /** A field whose value is a valid TCP port within the range of 0 to 65535: https://en.wikipedia.org/wiki/Transmission_Control_Protocol#TCP_ports */
  Port: any
  /** Floats that will have a value greater than 0. */
  PositiveFloat: any
  /** Integers that will have a value greater than 0. */
  PositiveInt: any
  /** A field whose value conforms to the standard postal code formats for United States, United Kingdom, Germany, Canada, France, Italy, Australia, Netherlands, Spain, Denmark, Sweden, Belgium, India, Austria, Portugal, Switzerland or Luxembourg. */
  PostalCode: any
  /** A field whose value is a CSS RGB color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba(). */
  RGB: any
  /** A field whose value is a CSS RGBA color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba(). */
  RGBA: any
  /** The `SafeInt` scalar type represents non-fractional signed whole numeric values that are considered safe as defined by the ECMAScript specification. */
  SafeInt: any
  /** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Time: any
  /** Custom scalar type for representing DateTime in multiple formats. Supported formats are string representing valid datetime or number representing unix timestamp in milliseconds */
  Timestamp: any
  /** Floats that will have a value of 0 or more. */
  UnsignedFloat: any
  /** Integers that will have a value of 0 or more. */
  UnsignedInt: any
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: any
  /** A currency string, such as $21.25 */
  USCurrency: any
  /** A field whose value is a UTC Offset: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones */
  UtcOffset: any
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: any
  /** Represents NULL values */
  Void: any
  /** Zip Code custom scalar type */
  Zip: any
}

export type AddAgentInput = {
  firstName: Scalars['String']
  lastName: Scalars['String']
  email: Scalars['String']
  phone: Scalars['String']
  picture?: Maybe<Scalars['String']>
  listIndex: Scalars['Int']
}

export type AddBuilderPageInput = {
  template?: Maybe<BuilderPageTemplates>
  slogan?: Maybe<Scalars['String']>
  about?: Maybe<Scalars['String']>
  learnMore?: Maybe<Scalars['String']>
  learnMoreLinkText?: Maybe<Scalars['String']>
  learnMoreLinkUrl?: Maybe<Scalars['String']>
  heroSrc?: Maybe<Scalars['String']>
  altLogoSrc?: Maybe<Scalars['String']>
  primaryColor?: Maybe<Scalars['String']>
  secondaryColor?: Maybe<Scalars['String']>
}

export type AddCityInput = {
  name: Scalars['String']
  lat: Scalars['Float']
  lon: Scalars['Float']
  metroId?: Maybe<Scalars['Int']>
  countyId?: Maybe<Scalars['Int']>
  zipCode?: Maybe<Scalars['Int']>
  districtId?: Maybe<Scalars['Int']>
  postCode?: Maybe<Scalars['String']>
}

export type AddColonnadeUserInput = {
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  organizationRole?: Maybe<Scalars['String']>
  roleId: Scalars['Int']
  notify?: Maybe<Scalars['Boolean']>
}

export type AddColorInput = {
  vendorId: Scalars['Int']
  ident?: Maybe<Scalars['String']>
  name: Scalars['String']
  hex: Scalars['String']
  notes?: Maybe<Scalars['String']>
}

export type AddCommunityPoiInput = {
  address?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  latitude?: Maybe<Scalars['Float']>
  longitude?: Maybe<Scalars['Float']>
}

export type AddCustomMetroInput = {
  clientName: Scalars['String']
  metroId: Scalars['Int']
  customName?: Maybe<Scalars['String']>
}

export type AddCustomMetroPhotoInput = {
  clientName: Scalars['String']
  metroId: Scalars['Int']
  src: Scalars['String']
}

export type AddFloorplanInput = {
  planId: Scalars['Int']
  name: Scalars['String']
  storyId: Scalars['Int']
  base: Scalars['Boolean']
  optional: Scalars['Boolean']
  src: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  elevations: Array<ElevationAndCommunities>
  communityIds?: Maybe<Array<Scalars['Int']>>
  labelSVG?: Maybe<Scalars['JSON']>
  tags?: Maybe<Array<FloorplanTagInput>>
  portalTagIds?: Maybe<Array<Scalars['Int']>>
  externalId?: Maybe<Scalars['String']>
}

export type AddInteriorElementInput = {
  interiorElementId: Scalars['Int']
  renderIndex?: Maybe<Scalars['Int']>
  maskSrc?: Maybe<Scalars['String']>
  optionBlendMode?: Maybe<InteriorElementOptionType>
}

export type AddInteriorElementOptionInput = {
  interiorListElementId: Scalars['Int']
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  cost?: Maybe<Scalars['Float']>
  hex?: Maybe<Scalars['String']>
  overlaySwatchSrc?: Maybe<Scalars['String']>
  overlaySrc?: Maybe<Scalars['String']>
  listIndex?: Maybe<Scalars['Int']>
}

export type AddInteriorFromManifestInput = {
  name: Scalars['String']
  baseImageSrc?: Maybe<Scalars['String']>
  thumbnailSrc?: Maybe<Scalars['String']>
  internalName?: Maybe<Scalars['String']>
  elementTrees?: Maybe<Array<InteriorListElementTreeInput>>
  presets: Array<InteriorPresetInput>
}

export type AddInteriorInput = {
  name: Scalars['String']
  internalName?: Maybe<Scalars['String']>
  baseImageSrc?: Maybe<Scalars['String']>
  thumbnailSrc?: Maybe<Scalars['String']>
  disclaimer?: Maybe<Scalars['String']>
}

export type AddInteriorListElementInput = {
  interiorId: Scalars['Int']
  name: Scalars['String']
  parentInteriorListElementId?: Maybe<Scalars['Int']>
  elementType: InteriorElementListType
  interiorElementId?: Maybe<Scalars['Int']>
}

export type AddInteriorPresetInput = {
  interiorId: Scalars['Int']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
}

export type AddInteriorsElementsInput = {
  interiorId?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  optionBlendMode?: Maybe<Scalars['String']>
  maskSrc?: Maybe<Scalars['String']>
  listIndex?: Maybe<Scalars['Int']>
  renderIndex?: Maybe<Scalars['Int']>
  elementOptions: Array<AddInteriorsElementsOptionsInput>
}

export type AddInteriorsElementsOptionsInput = {
  elementId?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  hex?: Maybe<Scalars['String']>
  overlaySwatchSrc?: Maybe<Scalars['String']>
  overlaySrc?: Maybe<Scalars['String']>
  listIndex?: Maybe<Scalars['Int']>
  cost?: Maybe<Scalars['Float']>
  parentNestedElementName?: Maybe<Scalars['String']>
  parentNestedOption?: Maybe<Scalars['String']>
  parentElementId?: Maybe<Scalars['Int']>
  standard?: Maybe<Scalars['String']>
}

export type AddInventoryInput = {
  lotId: Scalars['Int']
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  mlsId?: Maybe<Scalars['Int']>
  photoFolder?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Int']>
  sqft?: Maybe<Scalars['Int']>
  beds?: Maybe<Scalars['Int']>
  baths?: Maybe<Scalars['Float']>
  features?: Maybe<Scalars['String']>
  mlsUrl?: Maybe<Scalars['String']>
  mlsStatus?: Maybe<MlsStatus>
  garageType?: Maybe<GarageType>
  garageCapacity?: Maybe<Scalars['Float']>
  floors?: Maybe<Scalars['Int']>
  homeStyleId?: Maybe<Scalars['Int']>
  homeType?: Maybe<HomeType>
  virTourUrl?: Maybe<Scalars['String']>
  schemeId?: Maybe<Scalars['Int']>
  closingDate?: Maybe<Scalars['Date']>
  constructionStatus?: Maybe<InventoryConstructionStatus>
}

export type AdditionalBuilder = {
  __typename?: 'AdditionalBuilder'
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export type AdditionalBuilderInput = {
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export type AdditionalBuyerInput = {
  firstName: Scalars['String']
  lastName: Scalars['String']
  phone: Scalars['String']
  email: Scalars['EmailAddress']
}

export type AddLotLegendEntryInput = {
  name: Scalars['String']
  code: LotSalesStatus
  hex: Scalars['String']
  listIndex?: Maybe<Scalars['Int']>
}

export type AddLotToSiteplanInput = {
  name: Scalars['String']
  dataName?: Maybe<Scalars['String']>
  salesStatus?: Maybe<LotSalesStatus>
  address?: Maybe<Scalars['String']>
  premium?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Float']>
  plan?: Maybe<Scalars['String']>
  garagePosition?: Maybe<GaragePosition>
  latitude?: Maybe<Scalars['Float']>
  longitude?: Maybe<Scalars['Float']>
  zip?: Maybe<Scalars['Zip']>
  siteplanInfo: LotSiteplanInfoInput
  geoJson?: Maybe<Scalars['JSON']>
}

export type AddMetroInput = {
  countryCode: Scalars['String']
  metroCode?: Maybe<Scalars['Int']>
  name: Scalars['String']
  metroState: Scalars['String']
}

export type AddOverlayInput = {
  elevationId: Scalars['Int']
  elementId: Scalars['Int']
  textureData: TextureDataInput
}

export type AddPaletteInput = {
  name: Scalars['String']
  materialIds?: Maybe<Array<Scalars['Int']>>
  paletteToDuplicateId?: Maybe<Scalars['Int']>
  internalName?: Maybe<Scalars['String']>
}

export type AddPlanPhotoInput = {
  clientName: Scalars['String']
  planId: Scalars['Int']
  src: Scalars['String']
  photoType: PhotoType
  videoSrc: Scalars['String']
}

export type AddSiteplanInput = {
  communityId: Scalars['Int']
  name: Scalars['String']
  displayName: Scalars['String']
  src?: Maybe<Scalars['String']>
  master?: Maybe<Scalars['Boolean']>
  siteplanType: SiteplanTypes
  svg?: Maybe<SvgInput>
  active?: Maybe<Scalars['Boolean']>
  neLatitude?: Maybe<Scalars['Float']>
  neLongitude?: Maybe<Scalars['Float']>
  swLatitude?: Maybe<Scalars['Float']>
  swLongitude?: Maybe<Scalars['Float']>
  geoJson?: Maybe<Scalars['JSON']>
}

export type AddSocialsInput = {
  builderPageId: Scalars['Int']
  facebook?: Maybe<Scalars['String']>
  instagram?: Maybe<Scalars['String']>
  twitter?: Maybe<Scalars['String']>
  linkedIn?: Maybe<Scalars['String']>
}

export type AddTextureInput = {
  library: TextureLibraryType
  status: LibraryStatus
  vendorId: Scalars['Int']
  type: Scalars['String']
  color: Scalars['String']
  src?: Maybe<Scalars['String']>
  c4d?: Maybe<Scalars['String']>
  alt1?: Maybe<Scalars['Int']>
  alt2?: Maybe<Scalars['Int']>
  client?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
}

export type AddUserInput = {
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  roleId: Scalars['Int']
  notify?: Maybe<Scalars['Boolean']>
}

export type AddUserResult = {
  __typename?: 'AddUserResult'
  id: Scalars['Boolean']
}

export type Agent = {
  __typename?: 'Agent'
  id: Scalars['Int']
  firstName: Scalars['String']
  lastName: Scalars['String']
  email: Scalars['String']
  phone?: Maybe<Scalars['String']>
  picture?: Maybe<Scalars['String']>
  listIndex?: Maybe<Scalars['Int']>
  communityId?: Maybe<Scalars['Int']>
}

export type AgentProspect = {
  __typename?: 'AgentProspect'
  agentId: Scalars['Int']
  prospectId: Scalars['Int']
  agentName: Scalars['String']
  prospectName: Scalars['String']
  agentEmail: Scalars['String']
  prospectEmail: Scalars['String']
  prospectPhone?: Maybe<Scalars['String']>
  clientName?: Maybe<Scalars['String']>
}

export type AllRoles = {
  __typename?: 'AllRoles'
  roles: Array<RoleInfo>
}

export type AlternateOptionInput = {
  key: Scalars['String']
  overlaySrc: Scalars['String']
  maskSrc?: Maybe<Scalars['String']>
  cost?: Maybe<Scalars['Int']>
}

export type AnewgoFloorplanTagCategory = {
  __typename?: 'AnewgoFloorplanTagCategory'
  id: Scalars['Int']
  name: Scalars['String']
  floorplanTags: Array<FloorplanTag>
}

export type AnewgoPlanTag = {
  __typename?: 'AnewgoPlanTag'
  id: Scalars['Int']
  name: Scalars['String']
}

export type AnewgoPortalPlanTag = {
  __typename?: 'AnewgoPortalPlanTag'
  id: Scalars['Int']
  categoryId: Scalars['Int']
  name: Scalars['String']
}

export enum ApiKeyPrivileges {
  Public = 'PUBLIC',
  AnewgoApps = 'ANEWGO_APPS',
  Protected = 'PROTECTED',
}

export type AppApiKey = {
  __typename?: 'AppApiKey'
  id?: Maybe<Scalars['Int']>
  apiKey: Scalars['String']
  appName?: Maybe<Scalars['String']>
  origins?: Maybe<Array<Maybe<Origin>>>
}

export enum AreaMetric {
  Sqft = 'SQFT',
  Sqm = 'SQM',
}

export type Asset = {
  __typename?: 'Asset'
  src?: Maybe<Scalars['String']>
  videoSrc?: Maybe<Scalars['String']>
  listIndex?: Maybe<Scalars['Int']>
}

export type AssignPaletteToMaterialsInput = {
  paletteId: Scalars['Int']
  materialIds?: Maybe<Array<Scalars['Int']>>
}

export type BaseFilter = {
  clientName: Scalars['String']
  dateTimeRange: DateTimeFilter
}

export type BatchUpdateElevationsInput = {
  communityExternalId: Scalars['String']
  planExternalId?: Maybe<Scalars['String']>
  updateElevationInput: UpdateElevationInput
}

export type BlendElement = {
  __typename?: 'BlendElement'
  id?: Maybe<Scalars['Int']>
  displayName?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  identifier?: Maybe<Scalars['String']>
  blendMode?: Maybe<BlendModeTypes>
  materialCategory?: Maybe<MaterialCategory>
  defaultMaterialCategory?: Maybe<MaterialCategory>
}

export enum BlendModeTypes {
  Multiply = 'MULTIPLY',
  Normal = 'NORMAL',
}

export type Browsing = {
  __typename?: 'Browsing'
  favorites?: Maybe<Scalars['Int']>
  leadFavorites?: Maybe<Scalars['Int']>
  leadViews?: Maybe<Scalars['Int']>
}

export type BuilderPage = {
  __typename?: 'BuilderPage'
  id?: Maybe<Scalars['Int']>
  template?: Maybe<BuilderPageTemplates>
  slogan?: Maybe<Scalars['String']>
  about?: Maybe<Scalars['String']>
  learnMore?: Maybe<Scalars['String']>
  learnMoreLinkText?: Maybe<Scalars['String']>
  learnMoreLinkUrl?: Maybe<Scalars['String']>
  heroSrc?: Maybe<Scalars['String']>
  altLogoSrc?: Maybe<Scalars['String']>
  published?: Maybe<Scalars['Boolean']>
  publishedDate?: Maybe<Scalars['Timestamp']>
  modified?: Maybe<Scalars['Boolean']>
  primaryColor?: Maybe<Scalars['String']>
  secondaryColor?: Maybe<Scalars['String']>
  aboutSections: Array<BuilderPageAboutSection>
  accolades: Array<BuilderPageAccolade>
  images: Array<BuilderPageImage>
  videos: Array<BuilderPageVideo>
}

export type BuilderPageAboutSectionsArgs = {
  clientName: Scalars['String']
}

export type BuilderPageAccoladesArgs = {
  clientName: Scalars['String']
}

export type BuilderPageImagesArgs = {
  clientName: Scalars['String']
}

export type BuilderPageVideosArgs = {
  clientName: Scalars['String']
}

export type BuilderPageAboutSection = {
  __typename?: 'BuilderPageAboutSection'
  id?: Maybe<Scalars['Int']>
  builderPageId?: Maybe<Scalars['Int']>
  title?: Maybe<Scalars['String']>
  about?: Maybe<Scalars['String']>
  imageSrc?: Maybe<Scalars['String']>
  listIndex?: Maybe<Scalars['Int']>
}

export type BuilderPageAboutSectionInput = {
  id?: Maybe<Scalars['Int']>
  builderPageId: Scalars['Int']
  title?: Maybe<Scalars['String']>
  about?: Maybe<Scalars['String']>
  imageSrc?: Maybe<Scalars['String']>
  listIndex?: Maybe<Scalars['Int']>
}

export type BuilderPageAccolade = {
  __typename?: 'BuilderPageAccolade'
  id?: Maybe<Scalars['Int']>
  builderPageId?: Maybe<Scalars['Int']>
  title?: Maybe<Scalars['String']>
  about?: Maybe<Scalars['String']>
  imageSrc?: Maybe<Scalars['String']>
  listIndex?: Maybe<Scalars['Int']>
}

export type BuilderPageAccoladeInput = {
  id?: Maybe<Scalars['Int']>
  builderPageId: Scalars['Int']
  title?: Maybe<Scalars['String']>
  about?: Maybe<Scalars['String']>
  imageSrc?: Maybe<Scalars['String']>
  listIndex?: Maybe<Scalars['Int']>
}

export type BuilderPageImage = {
  __typename?: 'BuilderPageImage'
  id?: Maybe<Scalars['Int']>
  builderPageId?: Maybe<Scalars['Int']>
  src?: Maybe<Scalars['String']>
  isSelected?: Maybe<Scalars['Boolean']>
  listIndex?: Maybe<Scalars['Int']>
}

export type BuilderPageImageInput = {
  id?: Maybe<Scalars['Int']>
  builderPageId: Scalars['Int']
  src?: Maybe<Scalars['String']>
  listIndex?: Maybe<Scalars['Int']>
  isSelected?: Maybe<Scalars['Boolean']>
}

export enum BuilderPageTemplates {
  MultimediaAll = 'MULTIMEDIA_ALL',
  MultimediaImages = 'MULTIMEDIA_IMAGES',
  Accolades = 'ACCOLADES',
  Standard = 'STANDARD',
  Informative = 'INFORMATIVE',
}

export type BuilderPageVideo = {
  __typename?: 'BuilderPageVideo'
  id?: Maybe<Scalars['Int']>
  builderPageId?: Maybe<Scalars['Int']>
  src?: Maybe<Scalars['String']>
  listIndex?: Maybe<Scalars['Int']>
}

export type BuilderPageVideoInput = {
  id?: Maybe<Scalars['Int']>
  builderPageId: Scalars['Int']
  src?: Maybe<Scalars['String']>
  listIndex?: Maybe<Scalars['Int']>
}

export type BuilderTag = {
  __typename?: 'BuilderTag'
  id: Scalars['Int']
  categoryId: Scalars['Int']
  name: Scalars['String']
}

export type BuilderTagAssignment = {
  __typename?: 'BuilderTagAssignment'
  clientId: Scalars['Int']
  categoryId: Scalars['Int']
  tagId: Scalars['Int']
  name?: Maybe<Scalars['String']>
  categoryName?: Maybe<Scalars['String']>
}

export type BuilderTagAssignmentInput = {
  categoryId: Scalars['Int']
  tagId: Scalars['Int']
}

export type BuilderTagCategory = {
  __typename?: 'BuilderTagCategory'
  id: Scalars['Int']
  name: Scalars['String']
}

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE',
}

export type CamberElevationTrend = {
  __typename?: 'CamberElevationTrend'
  clientName: Scalars['String']
  planId: Scalars['Int']
  elevationId: Scalars['Int']
  elevation?: Maybe<Elevation>
  dailyMetrics?: Maybe<TimeSeries>
}

export type CamberElevationTrends = {
  __typename?: 'CamberElevationTrends'
  clientName: Scalars['String']
  startTimestamp: Scalars['Timestamp']
  endTimestamp: Scalars['Timestamp']
  elevations?: Maybe<Array<CamberElevationTrend>>
}

export type CamberFloorplanTrend = {
  __typename?: 'CamberFloorplanTrend'
  clientName: Scalars['String']
  floorplanName: Scalars['String']
  dailyMetrics?: Maybe<TimeSeries>
}

export type CamberFloorplanTrends = {
  __typename?: 'CamberFloorplanTrends'
  clientName: Scalars['String']
  startTimestamp: Scalars['Timestamp']
  endTimestamp: Scalars['Timestamp']
  floorplans?: Maybe<Array<FloorplanTrend>>
}

export type CamberPlanElevation = {
  __typename?: 'camberPlanElevation'
  timestamp: Scalars['Timestamp']
  planName: Scalars['String']
  elevationCaption?: Maybe<Scalars['String']>
}

export type CamberPopularColorSchemesFilter = {
  baseInfo: BaseFilter
  communityName?: Maybe<Scalars['String']>
  elevationId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
  eventWeight?: Maybe<Scalars['Float']>
  myHomeWeight?: Maybe<Scalars['Float']>
  pdfWeight?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Int']>
}

export type CamberPopularMaterialColors = {
  __typename?: 'CamberPopularMaterialColors'
  clientName?: Maybe<Scalars['String']>
  materialId?: Maybe<Scalars['Int']>
  materialName?: Maybe<Scalars['String']>
  materialCategory?: Maybe<Scalars['String']>
  colorId?: Maybe<Scalars['Int']>
  colorName?: Maybe<Scalars['String']>
  paletteSelectionId?: Maybe<Scalars['Int']>
  brickId?: Maybe<Scalars['Int']>
  stoneId?: Maybe<Scalars['Int']>
  colorMasonryLib?: Maybe<Scalars['Boolean']>
  customOverlayId?: Maybe<Scalars['Int']>
  source?: Maybe<Scalars['String']>
  clicks?: Maybe<Scalars['Int']>
  brochure?: Maybe<Scalars['Int']>
  cta?: Maybe<Scalars['Int']>
  color?: Maybe<Color>
  texture?: Maybe<Texture>
  customOverlay?: Maybe<CustomOverlay>
  count?: Maybe<Scalars['Int']>
  popularity?: Maybe<Scalars['Int']>
}

export type CamberPopularMaterialColorsFilter = {
  baseInfo: BaseFilter
  communityName?: Maybe<Scalars['String']>
  communityId?: Maybe<Scalars['Int']>
  planName?: Maybe<Scalars['String']>
  planId?: Maybe<Scalars['Int']>
  elevationCaption?: Maybe<Scalars['String']>
  elevationId?: Maybe<Scalars['Int']>
  eventWeight?: Maybe<Scalars['Float']>
  myHomeWeight?: Maybe<Scalars['Float']>
  pdfWeight?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Int']>
}

export type CamberUsageSummary = {
  __typename?: 'CamberUsageSummary'
  clientName: Scalars['String']
  communityId: Scalars['Int']
  communityName?: Maybe<Scalars['String']>
  community?: Maybe<Community>
  pageViews?: Maybe<Scalars['Int']>
  brochure?: Maybe<Scalars['Int']>
  eventsCount?: Maybe<Scalars['Int']>
  registrations?: Maybe<Scalars['Int']>
  planClicks?: Maybe<Scalars['Int']>
  durationInfo?: Maybe<DurationInfo>
}

export type CamberUsersLogins = {
  __typename?: 'CamberUsersLogins'
  clientName: Scalars['String']
  startTimestamp: Scalars['Timestamp']
  endTimestamp: Scalars['Timestamp']
  logins?: Maybe<Array<Login>>
}

export type CanCityRegionInfo = {
  __typename?: 'CANCityRegionInfo'
  provinceId?: Maybe<Scalars['Int']>
  provinceCode?: Maybe<Scalars['String']>
  provinceName?: Maybe<Scalars['String']>
  districtId?: Maybe<Scalars['Int']>
  districtCode?: Maybe<Scalars['Int']>
  districtName?: Maybe<Scalars['String']>
  postCode?: Maybe<Scalars['String']>
}

export type CityInfo = {
  __typename?: 'CityInfo'
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  customName?: Maybe<Scalars['String']>
  src?: Maybe<Scalars['String']>
  lat?: Maybe<Scalars['Float']>
  lon?: Maybe<Scalars['Float']>
  communities: Array<Community>
  region?: Maybe<CityRegionInfo>
}

export type CityInfoCommunitiesArgs = {
  clientName: Scalars['String']
}

export type CityInfoRegionArgs = {
  clientName?: Maybe<Scalars['String']>
}

export type CityRegionInfo = UsCityRegionInfo | CanCityRegionInfo

export type Client = {
  __typename?: 'Client'
  id?: Maybe<Scalars['Int']>
  oldId?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  altName?: Maybe<Scalars['String']>
  directoryName?: Maybe<Scalars['String']>
  type?: Maybe<ClientType>
  contact?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  website?: Maybe<Scalars['String']>
  logo?: Maybe<Scalars['String']>
  disclaimer?: Maybe<Scalars['String']>
  address1?: Maybe<Scalars['String']>
  address2?: Maybe<Scalars['String']>
  interiorDisclaimer?: Maybe<Scalars['String']>
  status?: Maybe<ClientStatus>
  trackingId?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['Currency']>
  boylCapable?: Maybe<Scalars['Boolean']>
  marketplace?: Maybe<Scalars['Boolean']>
  communities: Array<Community>
  allPlans: Array<Plan>
  sgtVendors: Array<SgtVendor>
  builderTags: Array<BuilderTagAssignment>
  areaMetric?: Maybe<AreaMetric>
}

export type ClientCommunitiesArgs = {
  clientName?: Maybe<Scalars['String']>
  active?: Maybe<Scalars['Boolean']>
}

export type ClientAllPlansArgs = {
  clientName?: Maybe<Scalars['String']>
  buildYourLot?: Maybe<Scalars['Boolean']>
  active?: Maybe<Scalars['Boolean']>
}

export type ClientSgtVendorsArgs = {
  clientName?: Maybe<Scalars['String']>
}

export type ClientBuilderTagsArgs = {
  clientName: Scalars['String']
}

export type ClientApiKey = {
  __typename?: 'ClientApiKey'
  id?: Maybe<Scalars['Int']>
  apiKey: Scalars['String']
  clientId?: Maybe<Scalars['Int']>
}

export type ClientCommunitySubscription = {
  __typename?: 'ClientCommunitySubscription'
  clientSubscriptionId: Scalars['Int']
  app: FlagshipAppEnum
  registrationDate?: Maybe<Scalars['Date']>
  anniversaryDate?: Maybe<Scalars['Date']>
  communityId?: Maybe<Scalars['Int']>
  paymentPlan?: Maybe<FlagshipPaymentPlanEnum>
  notes?: Maybe<Scalars['String']>
}

export type ClientCommunitySubscriptionInput = {
  communityId: Scalars['Int']
  registrationDate?: Maybe<Scalars['Date']>
  anniversaryDate?: Maybe<Scalars['Date']>
  paymentPlan?: Maybe<FlagshipPaymentPlanEnum>
  notes?: Maybe<Scalars['String']>
}

export type ClientEmailReportInfo = {
  __typename?: 'ClientEmailReportInfo'
  reportId: Scalars['Int']
  clientId: Scalars['Int']
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  email: Scalars['String']
  frequency: ColonnadeReportFrequencyEnum
}

export type ClientFilter = {
  altNames?: Maybe<Array<Scalars['String']>>
  clientId?: Maybe<Array<Scalars['Int']>>
  sgtVendorId?: Maybe<Scalars['Int']>
}

export type ClientIdAndName = {
  __typename?: 'ClientIdAndName'
  id: Scalars['Int']
  altName: Scalars['String']
  name: Scalars['String']
  email?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  directoryName: Scalars['String']
  logo?: Maybe<Scalars['String']>
}

export type ClientPlanTag = {
  __typename?: 'ClientPlanTag'
  tagId: Scalars['Int']
  categoryId: Scalars['Int']
  count: Scalars['Int']
  tagName?: Maybe<Scalars['String']>
  categoryName?: Maybe<Scalars['String']>
}

export type ClientsForUser = {
  __typename?: 'ClientsForUser'
  id: Scalars['Int']
  clients?: Maybe<Array<Maybe<ClientIdAndName>>>
}

export type ClientSocials = {
  __typename?: 'ClientSocials'
  id?: Maybe<Scalars['Int']>
  facebook?: Maybe<Scalars['String']>
  instagram?: Maybe<Scalars['String']>
  twitter?: Maybe<Scalars['String']>
  linkedIn?: Maybe<Scalars['String']>
}

export enum ClientStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Pending = 'PENDING',
}

export type ClientSubscription = {
  __typename?: 'ClientSubscription'
  id?: Maybe<Scalars['Int']>
  clientId?: Maybe<Scalars['Int']>
  accountManagerEmail?: Maybe<Scalars['String']>
  divisionManagerEmail?: Maybe<Scalars['String']>
  anniversaryDate?: Maybe<Scalars['Date']>
  registrationDate?: Maybe<Scalars['Date']>
  notes?: Maybe<Scalars['String']>
  paymentPlan?: Maybe<FlagshipPaymentPlanEnum>
}

export type ClientSubscriptionInput = {
  accountManagerEmail?: Maybe<Scalars['String']>
  divisionManagerEmail?: Maybe<Scalars['String']>
  registrationDate?: Maybe<Scalars['Date']>
  anniversaryDate?: Maybe<Scalars['Date']>
  paymentPlan?: Maybe<FlagshipPaymentPlanEnum>
  notes?: Maybe<Scalars['String']>
}

export enum ClientType {
  Anewgo = 'ANEWGO',
  Builder = 'BUILDER',
  Developer = 'DEVELOPER',
  Realtor = 'REALTOR',
  Test = 'TEST',
  PropertyMgmt = 'PROPERTY_MGMT',
}

export type Collection = {
  __typename?: 'Collection'
  id: Scalars['Int']
  communityId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  siteplan?: Maybe<Siteplan>
}

export type CollectionSiteplanArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  active?: Maybe<Scalars['Boolean']>
}

export type ColonnadeAgentImport = {
  userId: Scalars['Int']
  agentId: Scalars['Int']
}

export type ColonnadeClientSubscriptionInfo = {
  __typename?: 'ColonnadeClientSubscriptionInfo'
  subscriptionLevel?: Maybe<ColonnadeSubscriptionLevelsEnum>
  numberOfLicenses?: Maybe<Scalars['Int']>
  subscriptionDate?: Maybe<Scalars['Date']>
  rolePrivilegeObject?: Maybe<Scalars['Object']>
  anniversaryNotificationState?: Maybe<Scalars['Boolean']>
}

export type ColonnadeProspectAssignment = {
  prospectId: Scalars['Int']
  userId: Scalars['Int']
}

export enum ColonnadeReportFrequencyEnum {
  Never = 'NEVER',
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Annually = 'ANNUALLY',
}

export type ColonnadeRole = {
  __typename?: 'ColonnadeRole'
  id: Scalars['Int']
  role: ColonnadeRolesEnum
}

export enum ColonnadeRolePrivilegesEnum {
  LeadRead = 'LEAD_READ',
  LeadReadWrite = 'LEAD_READ_WRITE',
  AnalyticsRead = 'ANALYTICS_READ',
  MarketingRead = 'MARKETING_READ',
  MarketingReadWrite = 'MARKETING_READ_WRITE',
  BuyonlineRead = 'BUYONLINE_READ',
  BuyonlineReadWrite = 'BUYONLINE_READ_WRITE',
}

export enum ColonnadeRolesEnum {
  AnewgoAdmin = 'ANEWGO_ADMIN',
  AnewgoStaff = 'ANEWGO_STAFF',
  Admin = 'ADMIN',
  Agent = 'AGENT',
  AnalyticsMember = 'ANALYTICS_MEMBER',
  SalesAdmin = 'SALES_ADMIN',
}

export enum ColonnadeSubscriptionLevelsEnum {
  None = 'NONE',
  Starter = 'STARTER',
  Pro = 'PRO',
  Enterprise = 'ENTERPRISE',
}

export type Color = {
  __typename?: 'Color'
  id: Scalars['Int']
  vendorId: Scalars['Int']
  identifier?: Maybe<Scalars['String']>
  name: Scalars['String']
  hex?: Maybe<Scalars['String']>
  notes?: Maybe<Scalars['String']>
  schemes: Array<Scheme>
}

export type ColorSchemesArgs = {
  clientName: Scalars['String']
}

export type ColorTheme = {
  __typename?: 'ColorTheme'
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  entries: Array<ColorThemeEntry>
}

export type ColorThemeEntriesArgs = {
  clientName?: Maybe<Scalars['String']>
}

export type ColorThemeEntry = {
  __typename?: 'ColorThemeEntry'
  id?: Maybe<Scalars['Int']>
  hex?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  rank?: Maybe<Scalars['Int']>
}

export type ColorTrend = {
  __typename?: 'ColorTrend'
  clientName?: Maybe<Scalars['String']>
  materialId?: Maybe<Scalars['Int']>
  materialName?: Maybe<Scalars['String']>
  materialCategory?: Maybe<Scalars['String']>
  colorId?: Maybe<Scalars['Int']>
  colorName?: Maybe<Scalars['String']>
  paletteSelectionId?: Maybe<Scalars['Int']>
  brickId?: Maybe<Scalars['Int']>
  stoneId?: Maybe<Scalars['Int']>
  colorMasonryLib?: Maybe<Scalars['Boolean']>
  customOverlayId?: Maybe<Scalars['Int']>
  clicks?: Maybe<Scalars['Int']>
  brochure?: Maybe<Scalars['Int']>
  cta?: Maybe<Scalars['Int']>
  color?: Maybe<Color>
  texture?: Maybe<Texture>
  customOverlay?: Maybe<CustomOverlay>
  count?: Maybe<Scalars['Int']>
  popularity?: Maybe<Scalars['Int']>
  dailyMetrics?: Maybe<TimeSeries>
}

export type ColorTrends = {
  __typename?: 'ColorTrends'
  clientName: Scalars['String']
  startTimestamp: Scalars['Timestamp']
  endTimestamp: Scalars['Timestamp']
  colors?: Maybe<Array<ColorTrend>>
}

export type ColorTrendsFilter = {
  commonFilter: CommonQueryFilter
  selectedMaterialId?: Maybe<Scalars['Int']>
}

export type CommonQueryFilter = {
  startTime: Scalars['Timestamp']
  endTime: Scalars['Timestamp']
  metric?: Maybe<PopularityMetric>
  limit?: Maybe<Scalars['Int']>
  clientName: Scalars['String']
  communityName?: Maybe<Scalars['String']>
  communityId?: Maybe<Scalars['Int']>
  planName?: Maybe<Scalars['String']>
  planId?: Maybe<Array<Maybe<Scalars['Int']>>>
  elevationCaption?: Maybe<Scalars['String']>
  elevationId?: Maybe<Scalars['Int']>
  country?: Maybe<Scalars['String']>
  state?: Maybe<Array<Maybe<Scalars['String']>>>
  metro?: Maybe<Array<Maybe<Scalars['Int']>>>
  county?: Maybe<Array<Maybe<Scalars['Int']>>>
  region?: Maybe<Array<Maybe<Scalars['String']>>>
  zip?: Maybe<Array<Maybe<Scalars['Int']>>>
  province?: Maybe<Array<Maybe<Scalars['String']>>>
  district?: Maybe<Array<Maybe<Scalars['Int']>>>
  postCode?: Maybe<Array<Maybe<Scalars['String']>>>
  cities?: Maybe<Array<Maybe<Scalars['String']>>>
  communities?: Maybe<Array<Maybe<Scalars['Int']>>>
  communityIdCollection?: Maybe<Array<Maybe<Scalars['Int']>>>
  planIdCollection?: Maybe<Array<Maybe<Scalars['Int']>>>
  elevationIdCollection?: Maybe<Array<Maybe<Scalars['Int']>>>
  clickWeight?: Maybe<Scalars['Int']>
  brochureWeight?: Maybe<Scalars['Int']>
  brochureWeightWithUser?: Maybe<Scalars['Int']>
  ctaWeight?: Maybe<Scalars['Int']>
  ascending?: Maybe<Scalars['Boolean']>
}

export type CommunitiesTrends = {
  __typename?: 'CommunitiesTrends'
  clientName: Scalars['String']
  startTimestamp: Scalars['Timestamp']
  endTimestamp: Scalars['Timestamp']
  communities?: Maybe<Array<CommunityTrend>>
}

export type CommunitiesTrendsFilter = {
  baseInfo: BaseFilter
  selectedCommunities?: Maybe<Array<Maybe<Scalars['String']>>>
  selectedPlanId?: Maybe<Scalars['Int']>
  selectedElevationId?: Maybe<Scalars['Int']>
  selectedElevationIdCollection?: Maybe<Array<Maybe<Scalars['Int']>>>
  metric?: Maybe<PopularityMetric>
  limit?: Maybe<Scalars['Int']>
}

export type Community = {
  __typename?: 'Community'
  id: Scalars['Int']
  masterCommunityId?: Maybe<Scalars['Int']>
  externalId?: Maybe<Scalars['String']>
  clientName: Scalars['String']
  buildYourLot?: Maybe<Scalars['Boolean']>
  bonafide?: Maybe<Scalars['Boolean']>
  metroId?: Maybe<Scalars['Int']>
  cityId?: Maybe<Scalars['Int']>
  communityType?: Maybe<CommunityType>
  countryCode?: Maybe<Scalars['String']>
  active?: Maybe<Scalars['Boolean']>
  address?: Maybe<Scalars['String']>
  caption?: Maybe<Scalars['String']>
  colormtd?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  latitude?: Maybe<Scalars['Float']>
  logo?: Maybe<Scalars['String']>
  logoWhite?: Maybe<Scalars['String']>
  longitude?: Maybe<Scalars['Float']>
  name?: Maybe<Scalars['String']>
  portalTags: Array<PortalCommunityTag>
  pricing?: Maybe<Scalars['Boolean']>
  sortType?: Maybe<Scalars['String']>
  sortOrder?: Maybe<Scalars['String']>
  tags: Array<CommunityTag>
  thumb?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  salesCenterActive?: Maybe<Scalars['Boolean']>
  client?: Maybe<Client>
  internalNotes?: Maybe<Scalars['String']>
  mapMarker?: Maybe<Scalars['String']>
  mapIcon?: Maybe<Scalars['String']>
  mapPoints: Array<Point>
  x?: Maybe<Scalars['Float']>
  y?: Maybe<Scalars['Float']>
  agents: Array<Agent>
  agentIds: Array<Scalars['Int']>
  colorTheme?: Maybe<ColorTheme>
  plans: Array<Plan>
  pmFloorplans: Array<PmFloorplan>
  floorplanElevations: Array<FloorplanElevation>
  fpGroupElevations: Array<FloorplanElevation>
  galleries: Array<CommunityGallery>
  inventories: Array<Inventory>
  homesites: Array<Homesite>
  lots: Array<Lot>
  cityLocation?: Maybe<CityInfo>
  imgs: Array<CommunityImg>
  highlights: Array<CommunityHighlight>
  mainPhotos: Array<CommunityMainPhoto>
  primarySiteplan?: Maybe<Siteplan>
  overviewPhotos: Array<CommunityOverviewPhoto>
  poiCategories: Array<CommunityPoiCategory>
  stdFeatureCategories: Array<StdFeatureCategory>
  palettes: Array<Palette>
  schemes: Array<Scheme>
  siteplans: Array<Siteplan>
  counties: Array<County>
  districts: Array<District>
}

export type CommunityClientArgs = {
  clientName?: Maybe<Scalars['String']>
}

export type CommunityMapPointsArgs = {
  clientName: Scalars['String']
}

export type CommunityAgentsArgs = {
  clientName: Scalars['String']
}

export type CommunityAgentIdsArgs = {
  clientName: Scalars['String']
}

export type CommunityColorThemeArgs = {
  clientName: Scalars['String']
}

export type CommunityPlansArgs = {
  clientName?: Maybe<Scalars['String']>
  active?: Maybe<Scalars['Boolean']>
}

export type CommunityPmFloorplansArgs = {
  clientName: Scalars['String']
}

export type CommunityFloorplanElevationsArgs = {
  clientName: Scalars['String']
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  floorplanId?: Maybe<Scalars['Int']>
}

export type CommunityFpGroupElevationsArgs = {
  clientName: Scalars['String']
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  fpGroupId?: Maybe<Scalars['Int']>
}

export type CommunityGalleriesArgs = {
  clientName: Scalars['String']
}

export type CommunityInventoriesArgs = {
  clientName: Scalars['String']
}

export type CommunityHomesitesArgs = {
  clientName: Scalars['String']
  quickMoveInOnly?: Maybe<Scalars['Boolean']>
}

export type CommunityLotsArgs = {
  clientName?: Maybe<Scalars['String']>
  quickMoveInOnly?: Maybe<Scalars['Boolean']>
  activeSiteplanOnly?: Maybe<Scalars['Boolean']>
}

export type CommunityCityLocationArgs = {
  clientName?: Maybe<Scalars['String']>
}

export type CommunityImgsArgs = {
  clientName: Scalars['String']
}

export type CommunityHighlightsArgs = {
  clientName: Scalars['String']
}

export type CommunityMainPhotosArgs = {
  clientName: Scalars['String']
}

export type CommunityPrimarySiteplanArgs = {
  clientName: Scalars['String']
  active?: Maybe<Scalars['Boolean']>
}

export type CommunityOverviewPhotosArgs = {
  clientName: Scalars['String']
}

export type CommunityPoiCategoriesArgs = {
  clientName: Scalars['String']
}

export type CommunityStdFeatureCategoriesArgs = {
  clientName: Scalars['String']
}

export type CommunityPalettesArgs = {
  clientName: Scalars['String']
  elevationId?: Maybe<Scalars['Int']>
}

export type CommunitySchemesArgs = {
  clientName: Scalars['String']
  elevationId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
}

export type CommunitySiteplansArgs = {
  clientName: Scalars['String']
  master?: Maybe<Scalars['Boolean']>
  active?: Maybe<Scalars['Boolean']>
}

export type CommunityCountiesArgs = {
  clientName: Scalars['String']
}

export type CommunityDistrictsArgs = {
  clientName: Scalars['String']
}

export type CommunityBuyOnlineConfig = {
  __typename?: 'CommunityBuyOnlineConfig'
  communityId: Scalars['Int']
  buyOnlineConfig?: Maybe<Scalars['JSON']>
  buyOnlineEnabled: Scalars['Boolean']
}

export type CommunityByLocation = {
  __typename?: 'CommunityByLocation'
  communityId: Scalars['Int']
  count: Scalars['Int']
}

export type CommunityElevationInfo = {
  __typename?: 'CommunityElevationInfo'
  id: Scalars['String']
  community: Community
  plan: Plan
  elevation: Elevation
}

export type CommunityElevationSchemeAssignment = {
  communityId: Scalars['Int']
  elevationId: Scalars['Int']
  planId?: Maybe<Scalars['Int']>
  schemeId: Scalars['Int']
}

export type CommunityFloorplan = {
  __typename?: 'CommunityFloorplan'
  communityId: Scalars['Int']
  planId: Scalars['Int']
  elevationId: Scalars['Int']
  floorplanId: Scalars['Int']
  cost?: Maybe<Scalars['Float']>
  externalId?: Maybe<Scalars['String']>
}

export type CommunityFloorplanAssignmentInput = {
  groupId?: Maybe<Scalars['Int']>
  floorplanId?: Maybe<Scalars['Int']>
  communityId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
}

export type CommunityFloorplanPriceInput = {
  communityId: Scalars['Int']
  planId: Scalars['Int']
  elevationId: Scalars['Int']
  floorplanId: Scalars['Int']
  cost: Scalars['Float']
}

export type CommunityGallery = {
  __typename?: 'CommunityGallery'
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  photos: Array<CommunityGalleryPhoto>
}

export type CommunityGalleryPhotosArgs = {
  clientName: Scalars['String']
}

export type CommunityGalleryPhoto = {
  __typename?: 'CommunityGalleryPhoto'
  id?: Maybe<Scalars['Int']>
  caption?: Maybe<Scalars['String']>
  src?: Maybe<Scalars['String']>
  listIndex?: Maybe<Scalars['Int']>
}

export type CommunityHighlight = {
  __typename?: 'CommunityHighlight'
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  photos: Array<CommunityHighlightPhoto>
}

export type CommunityHighlightPhotosArgs = {
  clientName: Scalars['String']
}

export type CommunityHighlightPhoto = {
  __typename?: 'CommunityHighlightPhoto'
  id?: Maybe<Scalars['Int']>
  src?: Maybe<Scalars['String']>
  listIndex?: Maybe<Scalars['Int']>
}

export type CommunityImg = {
  __typename?: 'CommunityImg'
  id?: Maybe<Scalars['Int']>
  src?: Maybe<Scalars['String']>
  listIndex?: Maybe<Scalars['Int']>
  communityId?: Maybe<Scalars['Int']>
  videoSrc?: Maybe<Scalars['String']>
}

export type CommunityMainPhoto = {
  __typename?: 'CommunityMainPhoto'
  id?: Maybe<Scalars['Int']>
  src?: Maybe<Scalars['String']>
  listIndex?: Maybe<Scalars['Int']>
}

export type CommunityOverviewPhoto = {
  __typename?: 'CommunityOverviewPhoto'
  id?: Maybe<Scalars['Int']>
  src?: Maybe<Scalars['String']>
  listIndex?: Maybe<Scalars['Int']>
}

export type CommunityPaletteAssignment = {
  communityId: Scalars['Int']
  paletteId: Scalars['Int']
}

export type CommunityPlanPricingInput = {
  communityId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  cost?: Maybe<Scalars['Int']>
}

export type CommunityPlanPricingResult = {
  __typename?: 'CommunityPlanPricingResult'
  pricingData?: Maybe<Scalars['String']>
  community?: Maybe<Scalars['String']>
  plan?: Maybe<Scalars['String']>
  elevation?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
}

export type CommunityPlanUpdateInput = {
  externalCommunityId?: Maybe<Scalars['String']>
  externalPlanId?: Maybe<Scalars['String']>
  externalElevationId?: Maybe<Scalars['String']>
  bed?: Maybe<Scalars['Int']>
  bath?: Maybe<Scalars['Float']>
  size?: Maybe<Scalars['Int']>
  cost?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
}

export type CommunityPlanUpdateResult = {
  __typename?: 'CommunityPlanUpdateResult'
  externalCommunityId?: Maybe<Scalars['String']>
  externalPlanId?: Maybe<Scalars['String']>
  externalElevationId?: Maybe<Scalars['String']>
  communityId?: Maybe<Scalars['Int']>
  communityName?: Maybe<Scalars['String']>
  planId?: Maybe<Scalars['Int']>
  planName?: Maybe<Scalars['String']>
  elevationId?: Maybe<Scalars['Int']>
  elevationInternalName?: Maybe<Scalars['String']>
  elevationCaption?: Maybe<Scalars['String']>
  bed?: Maybe<Scalars['Int']>
  bath?: Maybe<Scalars['Float']>
  size?: Maybe<Scalars['Int']>
  cost?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  updateStatus?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
}

export type CommunityPoi = {
  __typename?: 'CommunityPoi'
  id?: Maybe<Scalars['Int']>
  address?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  latitude?: Maybe<Scalars['Float']>
  longitude?: Maybe<Scalars['Float']>
}

export type CommunityPoiCategory = {
  __typename?: 'CommunityPoiCategory'
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  icon?: Maybe<Scalars['String']>
  pois: Array<CommunityPoi>
}

export type CommunityPoiCategoryPoisArgs = {
  clientName: Scalars['String']
}

export type CommunitySchemeAssignment = {
  communityId: Scalars['Int']
  schemeId: Scalars['Int']
}

export type CommunityTag = {
  __typename?: 'CommunityTag'
  id: Scalars['Int']
  categoryId: Scalars['Int']
  name: Scalars['String']
}

export type CommunityTagAssignment = {
  __typename?: 'CommunityTagAssignment'
  communityId: Scalars['Int']
  categoryId: Scalars['Int']
  tagId: Scalars['Int']
  name?: Maybe<Scalars['String']>
  categoryName?: Maybe<Scalars['String']>
}

export type CommunityTagAssignmentInput = {
  categoryId: Scalars['Int']
  tagId: Scalars['Int']
  communityId: Scalars['Int']
}

export type CommunityTagCategory = {
  __typename?: 'CommunityTagCategory'
  id: Scalars['Int']
  name: Scalars['String']
}

export type CommunityTrend = {
  __typename?: 'CommunityTrend'
  clientName: Scalars['String']
  communityName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
  planName?: Maybe<Scalars['String']>
  elevationId?: Maybe<Scalars['Int']>
  elevationCaption?: Maybe<Scalars['String']>
  dailyMetrics?: Maybe<TimeSeries>
  community?: Maybe<Community>
}

export enum CommunityType {
  Default = 'DEFAULT',
  PropertyManagement = 'PROPERTY_MANAGEMENT',
}

export enum ContactMethodType {
  Email = 'EMAIL',
  Phone = 'PHONE',
  Text = 'TEXT',
  VideoConference = 'VIDEO_CONFERENCE',
}

export enum Country {
  Us = 'US',
  Can = 'CAN',
}

export type County = {
  __typename?: 'County'
  id: Scalars['Int']
  code: Scalars['String']
  name: Scalars['String']
  state: State
}

export type Customization = {
  __typename?: 'Customization'
  id: Scalars['Int']
  name?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type CustomOverlay = {
  __typename?: 'CustomOverlay'
  id: Scalars['Int']
  clientName: Scalars['String']
  color?: Maybe<Scalars['String']>
  src?: Maybe<Scalars['String']>
}

export type CustTagsInput = {
  custTagId: Scalars['Int']
  custCategoryId: Scalars['Int']
}

export enum DateFilterField {
  FirstSeen = 'first_seen',
  LastSeen = 'last_seen',
}

/** Allows/forces to set query datetime range. */
export type DateTimeFilter = {
  start: Scalars['Timestamp']
  end: Scalars['Timestamp']
}

export type DayCounts = {
  __typename?: 'dayCounts'
  day_count: Scalars['Int']
  day_index: Scalars['Int']
}

export type DefaultInteriorElementOption = {
  __typename?: 'DefaultInteriorElementOption'
  interiorElementOptionId: Scalars['Int']
  interiorListElementId: Scalars['Int']
}

export type DefaultInteriorElementOptionInput = {
  interiorElementOptionId: Scalars['Int']
  interiorListElementId: Scalars['Int']
}

export type District = {
  __typename?: 'District'
  id: Scalars['Int']
  code: Scalars['Int']
  name: Scalars['String']
  province: Province
}

export type DurationInfo = {
  __typename?: 'DurationInfo'
  sessionCount?: Maybe<Scalars['Int']>
  total?: Maybe<Scalars['Int']>
  avg?: Maybe<Scalars['Int']>
  min?: Maybe<Scalars['Int']>
  max?: Maybe<Scalars['Int']>
  median?: Maybe<Scalars['Int']>
}

export type Elevation = {
  __typename?: 'Elevation'
  id: Scalars['Int']
  clientName?: Maybe<Scalars['String']>
  planId: Scalars['Int']
  communityName?: Maybe<Scalars['String']>
  communityId?: Maybe<Scalars['Int']>
  communityExternalId?: Maybe<Scalars['String']>
  planExternalId?: Maybe<Scalars['String']>
  externalId?: Maybe<Scalars['String']>
  collection?: Maybe<Collection>
  base?: Maybe<Scalars['String']>
  baseLastUpdateTime?: Maybe<Scalars['Timestamp']>
  bed?: Maybe<Scalars['Int']>
  computeBed?: Maybe<Scalars['Boolean']>
  bedMin?: Maybe<Scalars['Int']>
  bedMax?: Maybe<Scalars['Int']>
  defaultBedMin?: Maybe<Scalars['Int']>
  defaultBedMax?: Maybe<Scalars['Int']>
  defaultBed?: Maybe<Scalars['Int']>
  bath?: Maybe<Scalars['Float']>
  computeBath?: Maybe<Scalars['Boolean']>
  bathMin?: Maybe<Scalars['Float']>
  bathMax?: Maybe<Scalars['Float']>
  defaultBathMin?: Maybe<Scalars['Float']>
  defaultBathMax?: Maybe<Scalars['Float']>
  defaultBath?: Maybe<Scalars['Float']>
  cost?: Maybe<Scalars['Int']>
  computeCost?: Maybe<Scalars['Boolean']>
  costMin?: Maybe<Scalars['Int']>
  costMax?: Maybe<Scalars['Int']>
  defaultCostMin?: Maybe<Scalars['Int']>
  defaultCostMax?: Maybe<Scalars['Int']>
  defaultCost?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  computeSize?: Maybe<Scalars['Boolean']>
  sizeMin?: Maybe<Scalars['Int']>
  sizeMax?: Maybe<Scalars['Int']>
  defaultSizeMin?: Maybe<Scalars['Int']>
  defaultSizeMax?: Maybe<Scalars['Int']>
  defaultSize?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  defaultDescription?: Maybe<Scalars['String']>
  caption?: Maybe<Scalars['String']>
  defaultElevationId?: Maybe<Scalars['Int']>
  defaultFloor?: Maybe<Scalars['Int']>
  defaultGaragePosition?: Maybe<GaragePosition>
  internalName?: Maybe<Scalars['String']>
  internalNotes?: Maybe<Scalars['String']>
  thumb?: Maybe<Scalars['String']>
  mirrorElevationId?: Maybe<Scalars['Int']>
  mirrorRolePrimary?: Maybe<Scalars['Boolean']>
  planName?: Maybe<Scalars['String']>
  planDisplayName?: Maybe<Scalars['String']>
  standard?: Maybe<Scalars['Boolean']>
  garageHand?: Maybe<GarageHandType>
  colormtd?: Maybe<Scalars['String']>
  communities: Array<Community>
  homeCategories: Array<HomeCategory>
  homeStyles: Array<HomeStyle>
  layers: Array<Layer>
  materialPalettes: Array<MaterialPalette>
  overlays: Array<ElevationOverlay>
  schemes: Array<Scheme>
  stories: Array<Story>
  tags: Array<PlanTagElevation>
  communityElevationInfo?: Maybe<CommunityElevationInfo>
  floorplans: Array<Floorplan>
  fpGroups: Array<FloorplanGroup>
  inventories: Array<Inventory>
  bedRange?: Maybe<IntRange>
  bathRange?: Maybe<FloatRange>
  sizeRange?: Maybe<IntRange>
  costRange?: Maybe<IntRange>
  active?: Maybe<Scalars['Boolean']>
  excludedLots: Array<Lot>
  svgMirroring?: Maybe<Scalars['Boolean']>
  garagePosition?: Maybe<GaragePosition>
}

export type ElevationCollectionArgs = {
  clientName: Scalars['String']
}

export type ElevationColormtdArgs = {
  clientName: Scalars['String']
  communityName: Scalars['String']
}

export type ElevationCommunitiesArgs = {
  clientName: Scalars['String']
  excludeDefaultCommunity?: Maybe<Scalars['Boolean']>
  active?: Maybe<Scalars['Boolean']>
  planId?: Maybe<Scalars['Int']>
}

export type ElevationHomeCategoriesArgs = {
  clientName: Scalars['String']
}

export type ElevationHomeStylesArgs = {
  clientName: Scalars['String']
}

export type ElevationLayersArgs = {
  clientName: Scalars['String']
}

export type ElevationMaterialPalettesArgs = {
  clientName: Scalars['String']
  planId?: Maybe<Scalars['Int']>
  communityId?: Maybe<Scalars['Int']>
}

export type ElevationOverlaysArgs = {
  clientName: Scalars['String']
}

export type ElevationSchemesArgs = {
  clientName: Scalars['String']
  planId?: Maybe<Scalars['Int']>
  communityId?: Maybe<Scalars['Int']>
}

export type ElevationStoriesArgs = {
  clientName: Scalars['String']
  planId?: Maybe<Scalars['Int']>
  communityId?: Maybe<Scalars['Int']>
}

export type ElevationTagsArgs = {
  clientName: Scalars['String']
  planId?: Maybe<Scalars['Int']>
  communityId?: Maybe<Scalars['Int']>
  communityIds?: Maybe<Array<Scalars['Int']>>
}

export type ElevationCommunityElevationInfoArgs = {
  clientName: Scalars['String']
}

export type ElevationFloorplansArgs = {
  clientName: Scalars['String']
  planId?: Maybe<Scalars['Int']>
  communityId?: Maybe<Scalars['Int']>
}

export type ElevationFpGroupsArgs = {
  clientName: Scalars['String']
  planId?: Maybe<Scalars['Int']>
  communityId?: Maybe<Scalars['Int']>
}

export type ElevationInventoriesArgs = {
  clientName: Scalars['String']
  planId?: Maybe<Scalars['Int']>
  communityId?: Maybe<Scalars['Int']>
}

export type ElevationBedRangeArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
}

export type ElevationBathRangeArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
}

export type ElevationSizeRangeArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
}

export type ElevationCostRangeArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
}

export type ElevationExcludedLotsArgs = {
  clientName: Scalars['String']
}

export type ElevationAndCommunities = {
  elevationId?: Maybe<Scalars['Int']>
  communityIds?: Maybe<Array<Scalars['Int']>>
}

export type ElevationAssignment = {
  elevationId: Scalars['Int']
  planId: Scalars['Int']
  communityId: Scalars['Int']
}

export type ElevationAssignments = {
  elevationId: Scalars['Int']
  communities: Array<Scalars['Int']>
}

export type ElevationElement = {
  __typename?: 'ElevationElement'
  id: Scalars['Int']
  elementId?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  renderOrder?: Maybe<Scalars['Int']>
  src?: Maybe<Scalars['String']>
  lastUpdateTime?: Maybe<Scalars['Timestamp']>
}

export type ElevationElementInput = {
  elementId: Scalars['Int']
  src: Scalars['String']
  update?: Maybe<Scalars['Boolean']>
}

export type ElevationFloorplans = {
  __typename?: 'ElevationFloorplans'
  communityId: Scalars['Int']
  planId: Scalars['Int']
  elevationId: Scalars['Int']
  defaultFloor?: Maybe<Scalars['Int']>
  stories: Array<Story>
}

export type ElevationOverlay = {
  __typename?: 'ElevationOverlay'
  id: Scalars['Int']
  elevationId: Scalars['Int']
  elementId: Scalars['Int']
  brickId?: Maybe<Scalars['Int']>
  stoneId?: Maybe<Scalars['Int']>
  customOverlayId?: Maybe<Scalars['Int']>
  src: Scalars['String']
  texture: Texture
  material: BlendElement
}

export type ElevationOverlayTextureArgs = {
  clientName: Scalars['String']
}

export type ElevationOverlayMaterialArgs = {
  clientName: Scalars['String']
}

export type ElevationSpecs = {
  __typename?: 'ElevationSpecs'
  bed?: Maybe<Scalars['Int']>
  bath: Scalars['Float']
  size?: Maybe<Scalars['Float']>
  price?: Maybe<Scalars['Float']>
  count?: Maybe<Scalars['Int']>
  durationInfo?: Maybe<DurationInfo>
}

export type ElevationTrend = {
  __typename?: 'ElevationTrend'
  clientName: Scalars['String']
  planId: Scalars['Int']
  planName?: Maybe<Scalars['String']>
  elevationId: Scalars['Int']
  elevationCaption?: Maybe<Scalars['String']>
  elevation?: Maybe<Elevation>
  dailyMetrics?: Maybe<TimeSeries>
}

export type ElevationTrends = {
  __typename?: 'ElevationTrends'
  clientName: Scalars['String']
  startTimestamp: Scalars['Timestamp']
  endTimestamp: Scalars['Timestamp']
  elevations?: Maybe<Array<ElevationTrend>>
}

export type EmailCampaign = {
  __typename?: 'EmailCampaign'
  clientName: Scalars['String']
  id: Scalars['Int']
  ownerId: Scalars['Int']
  communityId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  inventoryId?: Maybe<Scalars['Int']>
  lotId?: Maybe<Scalars['Int']>
  templateId: Scalars['Int']
  name?: Maybe<Scalars['String']>
  mainImage?: Maybe<Scalars['String']>
  replyTo?: Maybe<Scalars['String']>
  replyToName?: Maybe<Scalars['String']>
  subject?: Maybe<Scalars['String']>
  opener?: Maybe<Scalars['String']>
  body?: Maybe<Scalars['String']>
  signature?: Maybe<Scalars['String']>
  active: Scalars['Boolean']
  lastUpdated?: Maybe<Scalars['Timestamp']>
  createdAt?: Maybe<Scalars['Timestamp']>
  cohort: Array<EmailCampaignCohort>
  sections: Array<EmailCampaignSection>
  community?: Maybe<Community>
  inventory?: Maybe<Inventory>
  lot?: Maybe<Lot>
  plan?: Maybe<Plan>
  elevation?: Maybe<Elevation>
  searchCommunity?: Maybe<Scalars['String']>
  searchPlan?: Maybe<Scalars['String']>
}

export type EmailCampaignCohortArgs = {
  clientName: Scalars['String']
}

export type EmailCampaignSectionsArgs = {
  clientName: Scalars['String']
}

export type EmailCampaignCommunityArgs = {
  clientName: Scalars['String']
}

export type EmailCampaignInventoryArgs = {
  clientName: Scalars['String']
}

export type EmailCampaignLotArgs = {
  clientName: Scalars['String']
}

export type EmailCampaignPlanArgs = {
  clientName: Scalars['String']
}

export type EmailCampaignElevationArgs = {
  clientName: Scalars['String']
}

export type EmailCampaignCohort = {
  __typename?: 'EmailCampaignCohort'
  id: Scalars['Int']
  emailCampaignId: Scalars['Int']
  prospectId: Scalars['Int']
  status: EmailCampaignCohortStatus
  lastFollowUp?: Maybe<Scalars['Timestamp']>
  active: Scalars['Boolean']
  prospect?: Maybe<Prospect>
}

export type EmailCampaignCohortProspectArgs = {
  clientName: Scalars['String']
}

export enum EmailCampaignCohortStatus {
  Sent = 'SENT',
  Delivered = 'DELIVERED',
  Opened = 'OPENED',
  Clicked = 'CLICKED',
  CreatedMyHome = 'CREATED_MY_HOME',
  None = 'NONE',
}

export type EmailCampaignInput = {
  ownerId?: Maybe<Scalars['Int']>
  templateId?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  mainImage?: Maybe<Scalars['String']>
  replyTo?: Maybe<Scalars['String']>
  replyToName?: Maybe<Scalars['String']>
  subject?: Maybe<Scalars['String']>
  opener?: Maybe<Scalars['String']>
  sections?: Maybe<Array<EmailCampaignSectionInput>>
  signature?: Maybe<Scalars['String']>
  communityId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  inventoryId?: Maybe<Scalars['Int']>
  lotId?: Maybe<Scalars['Int']>
  active?: Maybe<Scalars['Boolean']>
  searchCommunity?: Maybe<Scalars['String']>
  searchPlan?: Maybe<Scalars['String']>
}

export type EmailCampaignSection = {
  __typename?: 'EmailCampaignSection'
  id?: Maybe<Scalars['Int']>
  index?: Maybe<Scalars['Int']>
  content?: Maybe<Scalars['String']>
  images: Array<EmailCampaignSectionImage>
}

export type EmailCampaignSectionImagesArgs = {
  clientName?: Maybe<Scalars['String']>
}

export type EmailCampaignSectionImage = {
  __typename?: 'EmailCampaignSectionImage'
  id: Scalars['Int']
  emailCampaignSectionId: Scalars['Int']
  index: Scalars['Int']
  src: Scalars['String']
}

export type EmailCampaignSectionInput = {
  index?: Maybe<Scalars['Int']>
  content?: Maybe<Scalars['String']>
  images?: Maybe<Array<Scalars['String']>>
}

export type EmailDataAnniversaryNotif = {
  __typename?: 'EmailDataAnniversaryNotif'
  clientId?: Maybe<Scalars['Int']>
  receiveAnniversaryNotification?: Maybe<Scalars['Boolean']>
  accountManagerEmail?: Maybe<Scalars['String']>
  divisionManagerEmail?: Maybe<Scalars['String']>
  clientName?: Maybe<Scalars['String']>
  billingDate?: Maybe<Scalars['String']>
}

export type Engagement = {
  __typename?: 'Engagement'
  total?: Maybe<Scalars['Int']>
  community?: Maybe<Scalars['Float']>
  plan?: Maybe<Scalars['Float']>
  elevation?: Maybe<Scalars['Float']>
  inventory?: Maybe<Scalars['Float']>
  brochure?: Maybe<Scalars['Float']>
  tour?: Maybe<Scalars['Float']>
}

export type EnvelopesCreated = {
  __typename?: 'EnvelopesCreated'
  count: Scalars['Int']
}

export type Event = {
  __typename?: 'Event'
  id: Scalars['String']
  timestamp: Scalars['Timestamp']
  name: Scalars['String']
  contextPagePath: Scalars['String']
}

export type EventBasedTimeSeriesFrame = {
  __typename?: 'EventBasedTimeSeriesFrame'
  date: Scalars['Timestamp']
  value: Scalars['Float']
}

export type ExteriorTag = {
  __typename?: 'ExteriorTag'
  id: Scalars['Int']
  name: Scalars['String']
}

export type Favorite = {
  __typename?: 'Favorite'
  clientName: Scalars['String']
  communityId: Scalars['Int']
  communityName?: Maybe<Scalars['String']>
  planId: Scalars['Int']
  planName?: Maybe<Scalars['String']>
  elevationId: Scalars['Int']
  elevationCaption?: Maybe<Scalars['String']>
  date: Scalars['Timestamp']
  user?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  prospect?: Maybe<Prospect>
  elevation?: Maybe<Elevation>
}

export type Favorites = {
  __typename?: 'Favorites'
  clientName: Scalars['String']
  startTimestamp: Scalars['Timestamp']
  endTimestamp: Scalars['Timestamp']
  favorites?: Maybe<Array<Favorite>>
}

export type FieldArgs = {
  clientName?: Maybe<Scalars['String']>
  communityName?: Maybe<Scalars['String']>
  communityId?: Maybe<Scalars['Int']>
  planName?: Maybe<Scalars['String']>
  planId?: Maybe<Scalars['Int']>
  elevationCaption?: Maybe<Scalars['String']>
  elevationId?: Maybe<Scalars['Int']>
}

export type File = {
  __typename?: 'File'
  filename: Scalars['String']
  mimetype: Scalars['String']
  encoding: Scalars['String']
}

export enum FlagshipAppEnum {
  Camber = 'CAMBER',
  Truss = 'TRUSS',
}

export enum FlagshipPaymentPlanEnum {
  Monthly = 'MONTHLY',
  Annual = 'ANNUAL',
}

export enum FlagshipRolePrivilegesEnum {
  Public = 'PUBLIC',
  AnewgoApps = 'ANEWGO_APPS',
  Protected = 'PROTECTED',
  Private = 'PRIVATE',
  ClientAccount = 'CLIENT_ACCOUNT',
  System = 'SYSTEM',
  ColonnadePrivate = 'COLONNADE_PRIVATE',
}

export enum FlagshipRolesEnum {
  Superadmin = 'SUPERADMIN',
  Admin = 'ADMIN',
  ApiUser = 'API_USER',
  DesignMember = 'DESIGN_MEMBER',
  MarketingMember = 'MARKETING_MEMBER',
  Member = 'MEMBER',
  SalesAgent = 'SALES_AGENT',
}

export type FloatRange = {
  __typename?: 'FloatRange'
  min?: Maybe<Scalars['Float']>
  max?: Maybe<Scalars['Float']>
}

export type Floorplan = {
  __typename?: 'Floorplan'
  id: Scalars['Int']
  clientName: Scalars['String']
  communityName?: Maybe<Scalars['String']>
  planName?: Maybe<Scalars['String']>
  communityExternalId?: Maybe<Scalars['String']>
  planExternalId?: Maybe<Scalars['String']>
  elevationExternalId?: Maybe<Scalars['String']>
  communityId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  groupId?: Maybe<Scalars['Int']>
  storyId?: Maybe<Scalars['Int']>
  name: Scalars['String']
  src: Scalars['String']
  src2?: Maybe<Scalars['String']>
  labelSvg?: Maybe<Scalars['JSON']>
  labelLastUpdateTime?: Maybe<Scalars['Date']>
  fnum?: Maybe<Scalars['Int']>
  base: Scalars['Boolean']
  optional: Scalars['Boolean']
  standard?: Maybe<Scalars['Boolean']>
  communityCost?: Maybe<Scalars['Boolean']>
  cost?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  bed?: Maybe<Scalars['Int']>
  bath?: Maybe<Scalars['Float']>
  externalId?: Maybe<Scalars['String']>
  notes?: Maybe<Scalars['String']>
  listIndex?: Maybe<Scalars['Int']>
  renderIndex?: Maybe<Scalars['Int']>
  lastUpdateTime?: Maybe<Scalars['Date']>
  alternate?: Maybe<FloorplanAlternate>
  alternates: Array<FloorplanAlternate>
  communities: Array<Community>
  plans: Array<Plan>
  elevations: Array<FloorplanElevation>
  membership: Array<FloorplanMembership>
  tags: Array<FloorplanTag>
  ocrScan?: Maybe<FloorplanOcrScanResult>
  popularity?: Maybe<Scalars['Int']>
  elevationCaption?: Maybe<Scalars['String']>
}

export type FloorplanAlternateArgs = {
  clientName: Scalars['String']
  fpGroupId?: Maybe<Scalars['Int']>
}

export type FloorplanAlternatesArgs = {
  clientName: Scalars['String']
}

export type FloorplanCommunitiesArgs = {
  clientName: Scalars['String']
}

export type FloorplanPlansArgs = {
  clientName: Scalars['String']
}

export type FloorplanElevationsArgs = {
  clientName: Scalars['String']
  planId?: Maybe<Scalars['Int']>
  filters?: Maybe<FloorplanFilters>
}

export type FloorplanMembershipArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
}

export type FloorplanTagsArgs = {
  clientName: Scalars['String']
}

export type FloorplanAlternate = {
  __typename?: 'FloorplanAlternate'
  id: Scalars['Int']
  name?: Maybe<Scalars['String']>
  internalName?: Maybe<Scalars['String']>
  src?: Maybe<Scalars['String']>
  src2?: Maybe<Scalars['String']>
  labelSvg?: Maybe<Scalars['JSON']>
  cost?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  bed?: Maybe<Scalars['Int']>
  bath?: Maybe<Scalars['Float']>
}

export type FloorplanAlternateInput = {
  id?: Maybe<Scalars['Int']>
  floorplanId?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  internalName?: Maybe<Scalars['String']>
  cost?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  bed?: Maybe<Scalars['Int']>
  bath?: Maybe<Scalars['Float']>
  src?: Maybe<Scalars['String']>
  labelSvg?: Maybe<Scalars['JSON']>
}

export type FloorplanElevation = {
  __typename?: 'FloorplanElevation'
  elevationId: Scalars['Int']
  caption?: Maybe<Scalars['String']>
  internalName?: Maybe<Scalars['String']>
  floorplanId?: Maybe<Scalars['Int']>
  groupId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
  communityId?: Maybe<Scalars['Int']>
  standard?: Maybe<Scalars['Boolean']>
  externalId?: Maybe<Scalars['String']>
}

export type FloorplanFilters = {
  defaultCommunity?: Maybe<Scalars['Boolean']>
  communityId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  storyId?: Maybe<Scalars['Int']>
}

export type FloorplanGroup = {
  __typename?: 'FloorplanGroup'
  id: Scalars['Int']
  groupType: FloorplanGroupType
  autoselectLinkedChildParents?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  primary: Array<Floorplan>
  members: Array<Floorplan>
  communities: Array<Community>
  isAutoRenderIndex?: Maybe<Scalars['Boolean']>
  elevations: Array<FloorplanElevation>
}

export type FloorplanGroupPrimaryArgs = {
  clientName: Scalars['String']
}

export type FloorplanGroupMembersArgs = {
  clientName: Scalars['String']
}

export type FloorplanGroupCommunitiesArgs = {
  clientName: Scalars['String']
}

export type FloorplanGroupElevationsArgs = {
  clientName: Scalars['String']
  planId?: Maybe<Scalars['Int']>
  filters?: Maybe<FloorplanFilters>
}

export type FloorplanGroupMemberInput = {
  id: Scalars['Int']
  role: FloorplanMemberRole
  floorplanAltId?: Maybe<Scalars['Int']>
}

export enum FloorplanGroupType {
  Adaptable = 'ADAPTABLE',
  AdaptableOnMirror = 'ADAPTABLE_ON_MIRROR',
  Linked = 'LINKED',
  MultiheadAdaptable = 'MULTIHEAD_ADAPTABLE',
  MultiheadMutualExclusion = 'MULTIHEAD_MUTUAL_EXCLUSION',
  MutualExclusion = 'MUTUAL_EXCLUSION',
  ParentChild = 'PARENT_CHILD',
}

export type FloorplanInput = {
  id: Scalars['Int']
}

export enum FloorplanMemberRole {
  Primary = 'PRIMARY',
  Member = 'MEMBER',
}

export type FloorplanMembership = {
  __typename?: 'FloorplanMembership'
  name?: Maybe<Scalars['String']>
  groupId: Scalars['Int']
  groupType: FloorplanGroupType
  role: FloorplanMemberRole
}

export type FloorplanOcrScanResult = {
  __typename?: 'FloorplanOCRScanResult'
  detected: Array<Scalars['String']>
  missing: Array<Scalars['String']>
  lost: Array<FloorplanOcrScanResultTag>
}

export type FloorplanOcrScanResultTag = {
  __typename?: 'FloorplanOCRScanResultTag'
  id: Scalars['Int']
  name: Scalars['String']
}

export type FloorplanTag = {
  __typename?: 'FloorplanTag'
  id: Scalars['Int']
  floorplanId?: Maybe<Scalars['Int']>
  floorplanTagCategoryId: Scalars['Int']
  name: Scalars['String']
}

export type FloorplanTagCategoryUpdate = {
  __typename?: 'FloorplanTagCategoryUpdate'
  id: Scalars['Int']
  name: Scalars['String']
  floorplanTagCategoryId: Scalars['Int']
  oldCategoryId?: Maybe<Scalars['Int']>
}

export type FloorplanTagInput = {
  id?: Maybe<Scalars['Int']>
  floorplanId?: Maybe<Scalars['Int']>
  anewgoTagCategoryId?: Maybe<Scalars['Int']>
  floorplanTagId?: Maybe<Scalars['Int']>
  name: Scalars['String']
}

export type FloorplanTrend = {
  __typename?: 'FloorplanTrend'
  clientName: Scalars['String']
  planId?: Maybe<Scalars['Int']>
  planName?: Maybe<Scalars['String']>
  floorplanName: Scalars['String']
  dailyMetrics?: Maybe<TimeSeries>
}

export type FloorplanTrends = {
  __typename?: 'FloorplanTrends'
  clientName: Scalars['String']
  startTimestamp: Scalars['Timestamp']
  endTimestamp: Scalars['Timestamp']
  floorplans?: Maybe<Array<FloorplanTrend>>
}

export enum GarageHandType {
  Left = 'LEFT',
  Right = 'RIGHT',
  Unspecified = 'UNSPECIFIED',
}

export enum GaragePosition {
  Left = 'LEFT',
  Right = 'RIGHT',
}

export enum GarageType {
  FrontLoad = 'FRONT_LOAD',
  RearLoad = 'REAR_LOAD',
  SideLoad = 'SIDE_LOAD',
}

export type GenerateAppApiKeyInput = {
  appName?: Maybe<Scalars['String']>
  origins?: Maybe<Array<Scalars['String']>>
  privileges: Array<ApiKeyPrivileges>
}

export type GenerateClientApiKeyInput = {
  clientName: Scalars['String']
  privileges: Array<ApiKeyPrivileges>
}

export type HomeCategory = {
  __typename?: 'HomeCategory'
  id: Scalars['Int']
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  photos: Array<HomeCategoryPhoto>
  styles: Array<HomeStyle>
}

export type HomeCategoryPhotosArgs = {
  limit?: Maybe<Scalars['Int']>
}

export type HomeCategoryPhoto = {
  __typename?: 'HomeCategoryPhoto'
  id?: Maybe<Scalars['Int']>
  src?: Maybe<Scalars['String']>
  listIndex?: Maybe<Scalars['Int']>
  styleName?: Maybe<Scalars['String']>
  styleId?: Maybe<Scalars['Int']>
}

export type HomeSelection = {
  __typename?: 'HomeSelection'
  json?: Maybe<Scalars['JSON']>
  client?: Maybe<Client>
  community?: Maybe<Community>
  plan?: Maybe<Plan>
  prospect?: Maybe<Prospect>
  elevationColors?: Maybe<Elevation>
  mirrorElevation?: Maybe<Elevation>
  siteplan?: Maybe<Siteplan>
  interiors?: Maybe<Array<Interior>>
  floorplans?: Maybe<ElevationFloorplans>
  mortgage?: Maybe<ProspectFavoriteMortgage>
}

export type HomeSelectionInput = {
  prospectIdentifier: Scalars['String']
  homeIdentifier: Scalars['String']
  selection?: Maybe<Scalars['JSON']>
  source: Scalars['String']
}

export type Homesite = {
  __typename?: 'Homesite'
  inventoryId?: Maybe<Scalars['Int']>
  communityId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  mlsId?: Maybe<Scalars['Int']>
  mlsStatus?: Maybe<MlsStatus>
  price?: Maybe<Scalars['Int']>
  baths?: Maybe<Scalars['Float']>
  beds?: Maybe<Scalars['Int']>
  sqft?: Maybe<Scalars['Int']>
  garageType?: Maybe<GarageType>
  garageCapacity?: Maybe<Scalars['Float']>
  features?: Maybe<Scalars['String']>
  photoFolder?: Maybe<Scalars['String']>
  photos: Array<InventoryPhoto>
  closingDate?: Maybe<Scalars['Date']>
  constructionStatus?: Maybe<InventoryConstructionStatus>
  lotId?: Maybe<Scalars['Int']>
  externalId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  size?: Maybe<Scalars['Float']>
  salesStatus?: Maybe<LotSalesStatus>
  premium?: Maybe<Scalars['Int']>
  address?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['Zip']>
  postCode?: Maybe<Scalars['String']>
  countryCode?: Maybe<Scalars['String']>
  plan?: Maybe<Scalars['String']>
  garagePosition?: Maybe<GaragePosition>
  latitude?: Maybe<Scalars['Float']>
  longitude?: Maybe<Scalars['Float']>
}

export type HomesitePhotosArgs = {
  clientName: Scalars['String']
}

export type HomesiteUpdateResult = {
  __typename?: 'HomesiteUpdateResult'
  externalId?: Maybe<Scalars['String']>
  updateStatus?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
}

export type HomeStyle = {
  __typename?: 'HomeStyle'
  id: Scalars['Int']
  name: Scalars['String']
  alternateName?: Maybe<Scalars['String']>
  descr1?: Maybe<Scalars['String']>
  descr2?: Maybe<Scalars['String']>
  descr3?: Maybe<Scalars['String']>
  src?: Maybe<Scalars['String']>
  photos: Array<HomeStylePhoto>
}

export type HomeStylePhotosArgs = {
  limit?: Maybe<Scalars['Int']>
}

export type HomeStylePhoto = {
  __typename?: 'HomeStylePhoto'
  id?: Maybe<Scalars['Int']>
  src?: Maybe<Scalars['String']>
  listIndex?: Maybe<Scalars['Int']>
}

export enum HomeType {
  Apartments = 'APARTMENTS',
  Condominium = 'CONDOMINIUM',
  Duplex = 'DUPLEX',
  Other = 'OTHER',
  Quad = 'QUAD',
  SingleFamily = 'SINGLE_FAMILY',
  TownHome = 'TOWN_HOME',
  Triplex = 'TRIPLEX',
}

export enum IdentityProvider {
  Google = 'GOOGLE',
  Facebook = 'FACEBOOK',
  Magic = 'MAGIC',
}

export type IInteriorListElement = {
  id: Scalars['Int']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  cost?: Maybe<Scalars['Int']>
  interiorId: Scalars['Int']
  interiorElement?: Maybe<InteriorElement>
  listIndex?: Maybe<Scalars['Int']>
  parentInteriorListElementId?: Maybe<Scalars['Int']>
  elementType?: Maybe<InteriorElementListType>
  overlaySwatchSrc?: Maybe<Scalars['String']>
}

export type ImportAgentError = {
  __typename?: 'ImportAgentError'
  agentId: Scalars['Int']
  message: Scalars['String']
}

export type ImportAgentInput = {
  agentId: Scalars['Int']
  firstName: Scalars['String']
  lastName: Scalars['String']
  email: Scalars['String']
}

export type ImportAgentResult = ImportAgentSuccess | ImportAgentError

export type ImportAgentSuccess = {
  __typename?: 'ImportAgentSuccess'
  agentId: Scalars['Int']
  user: User
}

export type IndexChangeInput = {
  id: Scalars['Int']
  builderPageId: Scalars['Int']
  oldIndex: Scalars['Int']
  newIndex: Scalars['Int']
}

export type IndustrySessionSummary = {
  __typename?: 'IndustrySessionSummary'
  sessions?: Maybe<Scalars['Int']>
  visitors?: Maybe<Scalars['Int']>
  registered?: Maybe<Scalars['Int']>
  returnVisitors?: Maybe<Scalars['Int']>
  newVisitors?: Maybe<Scalars['Int']>
  averageDuration?: Maybe<Scalars['Float']>
  bounceRate?: Maybe<Scalars['Float']>
}

export type IndustrySessionTrends = {
  __typename?: 'IndustrySessionTrends'
  date?: Maybe<Scalars['Timestamp']>
  sessions?: Maybe<Scalars['Int']>
  visitors?: Maybe<Scalars['Int']>
  registered?: Maybe<Scalars['Int']>
  returnVisitors?: Maybe<Scalars['Int']>
  newVisitors?: Maybe<Scalars['Int']>
  averageDuration?: Maybe<Scalars['Float']>
  bounceRate?: Maybe<Scalars['Float']>
  industrySessions?: Maybe<Scalars['Int']>
  industryVisitors?: Maybe<Scalars['Int']>
  industryRegistered?: Maybe<Scalars['Int']>
  industryReturnVisitors?: Maybe<Scalars['Int']>
  industryNewVisitors?: Maybe<Scalars['Int']>
  industryAverageDuration?: Maybe<Scalars['Float']>
  industryBounceRate?: Maybe<Scalars['Float']>
  count?: Maybe<Scalars['Int']>
  sumAverageDuration?: Maybe<Scalars['Float']>
  sumBounceRate?: Maybe<Scalars['Float']>
  sumIndustryAverageDuration?: Maybe<Scalars['Float']>
  sumIndustryBounceRate?: Maybe<Scalars['Float']>
}

export type InquiryInput = {
  prospectEmail: Scalars['String']
  prospectName?: Maybe<Scalars['String']>
  prospectPhone?: Maybe<Scalars['String']>
  agentEmail?: Maybe<Scalars['String']>
  myHomeUrl?: Maybe<Scalars['String']>
  inquiry?: Maybe<Scalars['String']>
  communityId?: Maybe<Scalars['Int']>
  communitiesSelected?: Maybe<Scalars['String']>
  planName?: Maybe<Scalars['String']>
  lotName?: Maybe<Scalars['String']>
  lotAddress?: Maybe<Scalars['String']>
  favoritesUrl?: Maybe<Scalars['String']>
}

export type Interior = {
  __typename?: 'Interior'
  id: Scalars['Int']
  clientName: Scalars['String']
  interiorAssignments: Array<InteriorAssignment>
  elementTrees: Array<InteriorListElementTree>
  name: Scalars['String']
  internalName?: Maybe<Scalars['String']>
  baseImageSrc?: Maybe<Scalars['String']>
  thumbnailSrc?: Maybe<Scalars['String']>
  disclaimer?: Maybe<Scalars['String']>
  active: Scalars['Boolean']
}

export type InteriorElementTreesArgs = {
  excludeChildElements?: Maybe<Scalars['Boolean']>
}

export type InteriorAlternateGroup = {
  __typename?: 'InteriorAlternateGroup'
  id: Scalars['Int']
  primaryListElement: InteriorListElement
  memberListElement: InteriorListElement
  optionAlternates?: Maybe<Array<InteriorElementOptionAlternate>>
}

export type InteriorAssignment = {
  __typename?: 'InteriorAssignment'
  id?: Maybe<Scalars['Int']>
  interiorId?: Maybe<Scalars['Int']>
  communityId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
}

export type InteriorAssignmentFilterInput = {
  communityId?: Maybe<Scalars['Int']>
  interiorId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
}

export type InteriorAssignmentInput = {
  interiorId: Scalars['Int']
  communityId: Scalars['Int']
  planId: Scalars['Int']
  elevationId: Scalars['Int']
}

export type InteriorElement = {
  __typename?: 'InteriorElement'
  id: Scalars['Int']
  optionBlendMode?: Maybe<BlendModeTypes>
  maskSrc?: Maybe<Scalars['String']>
  renderIndex?: Maybe<Scalars['Int']>
}

export type InteriorElementInput = {
  optionBlendMode?: Maybe<BlendModeTypes>
  maskSrc?: Maybe<Scalars['String']>
  renderIndex?: Maybe<Scalars['Int']>
}

export enum InteriorElementListType {
  Basic = 'BASIC',
  Group = 'GROUP',
  Package = 'PACKAGE',
  PackageGroup = 'PACKAGE_GROUP',
}

export type InteriorElementOption = {
  __typename?: 'InteriorElementOption'
  id: Scalars['Int']
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  cost?: Maybe<Scalars['Int']>
  hex?: Maybe<Scalars['String']>
  interiorListElementId: Scalars['Int']
  overlaySrc?: Maybe<Scalars['String']>
  overlaySwatchSrc?: Maybe<Scalars['String']>
  listIndex?: Maybe<Scalars['Int']>
}

export type InteriorElementOptionAlternate = {
  __typename?: 'InteriorElementOptionAlternate'
  overlaySrc?: Maybe<Scalars['String']>
  cost?: Maybe<Scalars['Int']>
  maskSrc?: Maybe<Scalars['String']>
  primaryOption?: Maybe<InteriorElementOption>
  memberOption?: Maybe<InteriorElementOption>
}

export type InteriorElementOptionAlternateGroupInput = {
  interiorId: Scalars['Int']
  primaryListElementId: Scalars['Int']
  memberListElementId: Scalars['Int']
}

export type InteriorElementOptionAlternateInput = {
  interiorId: Scalars['Int']
  primaryElementOptionId: Scalars['Int']
  memberElementOptionId: Scalars['Int']
  elementOptionGroupId: Scalars['Int']
  overlaySrc?: Maybe<Scalars['String']>
  maskSrc?: Maybe<Scalars['String']>
  cost?: Maybe<Scalars['Int']>
}

export type InteriorElementOptionInput = {
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  cost?: Maybe<Scalars['Int']>
  hex?: Maybe<Scalars['String']>
  overlaySrc?: Maybe<Scalars['String']>
  overlaySwatchSrc?: Maybe<Scalars['String']>
  listIndex?: Maybe<Scalars['Int']>
  inPreset: Array<Scalars['String']>
  key?: Maybe<Scalars['String']>
  alternates?: Maybe<Array<AlternateOptionInput>>
  defaultElementListOptionId?: Maybe<Scalars['String']>
}

export enum InteriorElementOptionType {
  Color = 'COLOR',
  Image = 'IMAGE',
}

export type InteriorInput = {
  id?: Maybe<Scalars['Int']>
  communityId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  baseImageSrc?: Maybe<Scalars['String']>
  thumbnailSrc?: Maybe<Scalars['String']>
  disclaimer?: Maybe<Scalars['String']>
}

export type InteriorListElement = IInteriorListElement & {
  __typename?: 'InteriorListElement'
  id: Scalars['Int']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  cost?: Maybe<Scalars['Int']>
  interiorId: Scalars['Int']
  interiorElement?: Maybe<InteriorElement>
  listIndex?: Maybe<Scalars['Int']>
  parentInteriorListElementId?: Maybe<Scalars['Int']>
  elementType?: Maybe<InteriorElementListType>
  overlaySwatchSrc?: Maybe<Scalars['String']>
}

export type InteriorListElementTree = IInteriorListElement & {
  __typename?: 'InteriorListElementTree'
  id: Scalars['Int']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  cost?: Maybe<Scalars['Int']>
  interiorId: Scalars['Int']
  interiorElement?: Maybe<InteriorElement>
  listIndex?: Maybe<Scalars['Int']>
  parentInteriorListElementId?: Maybe<Scalars['Int']>
  elementType?: Maybe<InteriorElementListType>
  overlaySwatchSrc?: Maybe<Scalars['String']>
  defaultElementListOptionId?: Maybe<Scalars['Int']>
  children?: Maybe<Array<InteriorListElementTree>>
  options?: Maybe<Array<InteriorElementOption>>
}

export type InteriorListElementTreeOptionsArgs = {
  clientName?: Maybe<Scalars['String']>
}

export type InteriorListElementTreeInput = {
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  cost?: Maybe<Scalars['Int']>
  listIndex?: Maybe<Scalars['Int']>
  elementType?: Maybe<InteriorElementListType>
  interiorElement?: Maybe<InteriorElementInput>
  overlaySwatchSrc?: Maybe<Scalars['String']>
  children?: Maybe<Array<InteriorListElementTreeInput>>
  options?: Maybe<Array<InteriorElementOptionInput>>
  defaultElementListOptionId?: Maybe<Scalars['String']>
}

export type InteriorPreset = {
  __typename?: 'InteriorPreset'
  id: Scalars['Int']
  interiorId: Scalars['Int']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  interiorPresetAssignments: Array<InteriorPresetAssignment>
}

export type InteriorPresetAssignment = {
  __typename?: 'InteriorPresetAssignment'
  interiorListElement: InteriorListElement
  interiorElementOption: InteriorElementOption
}

export type InteriorPresetAssignmentInput = {
  interiorElementOptionId: Scalars['Int']
}

export type InteriorPresetInput = {
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  interiorName: Scalars['String']
}

export type IntRange = {
  __typename?: 'IntRange'
  min?: Maybe<Scalars['Int']>
  max?: Maybe<Scalars['Int']>
}

export type Inventory = {
  __typename?: 'Inventory'
  clientName?: Maybe<Scalars['String']>
  id: Scalars['Int']
  lotId: Scalars['Int']
  lot?: Maybe<Lot>
  planId?: Maybe<Scalars['Int']>
  plan?: Maybe<Plan>
  elevationId?: Maybe<Scalars['Int']>
  elevation?: Maybe<Elevation>
  communityId?: Maybe<Scalars['Int']>
  mlsId?: Maybe<Scalars['Int']>
  photoFolder?: Maybe<Scalars['String']>
  photos: Array<InventoryPhoto>
  price?: Maybe<Scalars['Int']>
  sqft?: Maybe<Scalars['Int']>
  beds?: Maybe<Scalars['Int']>
  baths?: Maybe<Scalars['Float']>
  features?: Maybe<Scalars['String']>
  mlsPhotos?: Maybe<Array<Scalars['String']>>
  mlsUrl?: Maybe<Scalars['String']>
  mlsStatus?: Maybe<MlsStatus>
  garageType?: Maybe<GarageType>
  garageCapacity?: Maybe<Scalars['Float']>
  floors?: Maybe<Scalars['Int']>
  homeStyleId?: Maybe<Scalars['Int']>
  homeType?: Maybe<HomeType>
  virTourUrl?: Maybe<Scalars['String']>
  schemeId?: Maybe<Scalars['Int']>
  closingDate?: Maybe<Scalars['Date']>
  constructionStatus?: Maybe<InventoryConstructionStatus>
  homeCategories: Array<HomeCategory>
  homeStyles: Array<HomeStyle>
  includedFloorplans: Array<Floorplan>
  exteriorConfiguration: InventoryExteriorConfiguration
  sgtData?: Maybe<InventorySgtData>
  appointmentsEnabled?: Maybe<Scalars['Boolean']>
  reserveHomeUrl?: Maybe<Scalars['String']>
  garagePosition?: Maybe<GaragePosition>
}

export type InventoryLotArgs = {
  clientName: Scalars['String']
}

export type InventoryPlanArgs = {
  clientName: Scalars['String']
}

export type InventoryElevationArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
}

export type InventoryPhotosArgs = {
  clientName: Scalars['String']
}

export type InventoryHomeCategoriesArgs = {
  clientName: Scalars['String']
}

export type InventoryHomeStylesArgs = {
  clientName: Scalars['String']
}

export type InventoryIncludedFloorplansArgs = {
  clientName: Scalars['String']
}

export type InventoryExteriorConfigurationArgs = {
  clientName: Scalars['String']
}

export type InventorySgtDataArgs = {
  clientName: Scalars['String']
}

export type InventoryAppointmentData = {
  id: Scalars['Int']
  address?: Maybe<Scalars['String']>
  imgUrl?: Maybe<Scalars['String']>
  inventoryUrl: Scalars['String']
}

export enum InventoryConstructionStatus {
  LotCreation = 'LOT_CREATION',
  ConstructionEffort = 'CONSTRUCTION_EFFORT',
  DrywallComplete = 'DRYWALL_COMPLETE',
  Foundation = 'FOUNDATION',
  Framing = 'FRAMING',
}

export type InventoryDataInput = {
  externalElevationId?: Maybe<Scalars['String']>
  schemeId?: Maybe<Scalars['Int']>
  price?: Maybe<Scalars['Int']>
  garageCapacity?: Maybe<Scalars['Float']>
  garageType?: Maybe<GarageType>
  sqft?: Maybe<Scalars['Int']>
  beds?: Maybe<Scalars['Int']>
  baths?: Maybe<Scalars['Float']>
  features?: Maybe<Scalars['String']>
  mlsId?: Maybe<Scalars['Int']>
  mlsStatus?: Maybe<MlsStatus>
  homeType?: Maybe<HomeType>
  homeStyle?: Maybe<Scalars['String']>
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
}

export type InventoryExteriorConfiguration = {
  __typename?: 'InventoryExteriorConfiguration'
  inventoryId: Scalars['Int']
  scheme?: Maybe<Scheme>
  paletteElementSelections: Array<PaletteElementSelection>
}

export type InventoryLotUpdateInput = {
  externalId: Scalars['String']
  lotData?: Maybe<LotDataInput>
  inventoryData?: Maybe<InventoryDataInput>
}

export type InventoryPhoto = {
  __typename?: 'InventoryPhoto'
  id: Scalars['Int']
  src: Scalars['String']
  listIndex?: Maybe<Scalars['Int']>
}

export type InventorySgtData = {
  __typename?: 'InventorySgtData'
  id: Scalars['Int']
  sgtVendorId?: Maybe<Scalars['Int']>
  sgtExternalId?: Maybe<Scalars['String']>
  sgtData?: Maybe<Scalars['JSON']>
}

export enum InventoryType {
  HomesiteOnly = 'HOMESITE_ONLY',
  HomesiteAndPlan = 'HOMESITE_AND_PLAN',
}

export type IpAddress = {
  __typename?: 'ipAddress'
  ip?: Maybe<Scalars['String']>
  ipCidrNotation?: Maybe<Scalars['String']>
}

export type Layer = {
  __typename?: 'Layer'
  materialId: Scalars['Int']
  materialCategory?: Maybe<Scalars['String']>
  name: Scalars['String']
  src?: Maybe<Scalars['String']>
}

export type LeadByElevation = {
  __typename?: 'LeadByElevation'
  clientName?: Maybe<Scalars['String']>
  user?: Maybe<Scalars['String']>
  events?: Maybe<Array<Maybe<LeadElevationInterestEvent>>>
  lastActivity?: Maybe<Scalars['Timestamp']>
  numBrowsed?: Maybe<Scalars['Int']>
  numFavorited?: Maybe<Scalars['Int']>
  prospect?: Maybe<Prospect>
}

export type LeadByProduct = {
  __typename?: 'LeadByProduct'
  clientName?: Maybe<Scalars['String']>
  user?: Maybe<Scalars['String']>
  lastSeen?: Maybe<Scalars['Timestamp']>
  score?: Maybe<Scalars['Float']>
  avgDistance?: Maybe<Scalars['Float']>
  minDistance?: Maybe<Scalars['Float']>
  maxDistance?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Int']>
  duration?: Maybe<Scalars['Int']>
  myHomeFrequency?: Maybe<Scalars['Int']>
  communities?: Maybe<Array<Maybe<Scalars['Int']>>>
  plans?: Maybe<Array<Maybe<Scalars['Int']>>>
  elevations?: Maybe<Array<Maybe<Scalars['Int']>>>
  prospect?: Maybe<Prospect>
}

export type LeadElevationInterestEvent = {
  __typename?: 'LeadElevationInterestEvent'
  action?: Maybe<Scalars['String']>
  timestamp?: Maybe<Scalars['Timestamp']>
}

export enum LeadFilter {
  None = 'NONE',
  Unassigned = 'UNASSIGNED',
  MyLeadsOnly = 'MY_LEADS_ONLY',
}

export type LeadRanking = {
  __typename?: 'LeadRanking'
  user: Scalars['String']
  clientName?: Maybe<Scalars['String']>
  lastSeen?: Maybe<Scalars['Timestamp']>
  firstSeen?: Maybe<Scalars['Timestamp']>
  date?: Maybe<Scalars['Timestamp']>
  sessions?: Maybe<Scalars['Int']>
  totalEvents?: Maybe<Scalars['Int']>
  totalDuration?: Maybe<Scalars['Float']>
  averageDuration?: Maybe<Scalars['Float']>
  brochuresCreated?: Maybe<Scalars['Int']>
  ctaClicks?: Maybe<Scalars['Int']>
  favorites?: Maybe<Scalars['Int']>
  signIns?: Maybe<Scalars['Int']>
  appointments?: Maybe<Scalars['Int']>
  contactsUs?: Maybe<Scalars['Int']>
  score?: Maybe<Scalars['Float']>
  hotLead?: Maybe<Scalars['Boolean']>
  country?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  communities?: Maybe<Array<Maybe<ProspectTopCommunity>>>
  plans?: Maybe<Array<Maybe<ProspectTopPlan>>>
  numCommunities?: Maybe<Scalars['Int']>
  numPlans?: Maybe<Scalars['Int']>
  prospectId?: Maybe<Scalars['Int']>
  phone?: Maybe<Scalars['String']>
  status?: Maybe<LeadStatus>
  registrationDate?: Maybe<Scalars['Timestamp']>
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  clientRating?: Maybe<Scalars['String']>
  favoriteCommunities?: Maybe<Array<Scalars['String']>>
}

export type LeadsByProductQueryFilter = {
  common?: Maybe<CommonQueryFilter>
  product?: Maybe<ProductFilter>
}

export enum LeadScores {
  Less_30 = 'LESS_30',
  Between_30_60 = 'BETWEEN_30_60',
  Greater_60 = 'GREATER_60',
}

export type LeadsFilter = {
  sessionsWeight?: Maybe<Scalars['Int']>
  eventsWeight?: Maybe<Scalars['Int']>
  durationWeight?: Maybe<Scalars['Int']>
  contactedWeight?: Maybe<Scalars['Int']>
  brochureWeight?: Maybe<Scalars['Int']>
  ctaWeight?: Maybe<Scalars['Int']>
  favWeight?: Maybe<Scalars['Int']>
  timeField?: Maybe<TimeField>
  emails?: Maybe<Array<Maybe<Scalars['String']>>>
  filterEmails?: Maybe<Array<Scalars['String']>>
  filterDomain?: Maybe<Scalars['String']>
  dateFilterField?: Maybe<DateFilterField>
  returningLeads?: Maybe<Scalars['Boolean']>
  lead?: Maybe<LeadFilter>
  search?: Maybe<Scalars['String']>
  scores?: Maybe<Array<LeadScores>>
  statuses?: Maybe<Array<LeadStatus>>
  allTimeLeads?: Maybe<Scalars['Boolean']>
}

export type LeadsQueryFilter = {
  common: CommonQueryFilter
  leads: LeadsFilter
}

export enum LeadStatus {
  New = 'NEW',
  Open = 'OPEN',
  InProgress = 'IN_PROGRESS',
  OpenDeal = 'OPEN_DEAL',
  Unqualified = 'UNQUALIFIED',
  AttemptedToContact = 'ATTEMPTED_TO_CONTACT',
  Connected = 'CONNECTED',
  BadTiming = 'BAD_TIMING',
  False = 'FALSE',
  Purchaser = 'PURCHASER',
}

export type LeadTimeRanking = {
  __typename?: 'leadTimeRanking'
  date?: Maybe<Scalars['String']>
  score?: Maybe<Scalars['Float']>
  overallScore?: Maybe<Scalars['Float']>
}

export type LeadTimeRankingQueryParams = {
  __typename?: 'leadTimeRankingQueryParams'
  clientName: Scalars['String']
  leadEmail?: Maybe<Scalars['String']>
  timeFrame?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['Timestamp']>
  endTime?: Maybe<Scalars['Timestamp']>
}

export type Library = {
  __typename?: 'Library'
  name: LibraryType
  vendors?: Maybe<Array<LibraryVendor>>
}

export enum LibraryStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  New = 'NEW',
  Seamless = 'SEAMLESS',
}

export enum LibraryType {
  Color = 'COLOR',
  Brick = 'BRICK',
  Stone = 'STONE',
}

export type LibraryVendor = {
  __typename?: 'LibraryVendor'
  id: Scalars['Int']
  name: Scalars['String']
  library: LibraryType
  colors?: Maybe<Array<Color>>
  textures?: Maybe<Array<Texture>>
}

/** Location type */
export type Location = {
  __typename?: 'Location'
  clientName?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
  regionCode?: Maybe<Scalars['String']>
  countryName?: Maybe<Scalars['String']>
  countryCode?: Maybe<Scalars['String']>
  latitude?: Maybe<Scalars['Float']>
  longitude?: Maybe<Scalars['Float']>
  postal?: Maybe<Scalars['String']>
  carrier?: Maybe<MobileCarrier>
  stateCode?: Maybe<Scalars['String']>
}

export type LocationArgs = {
  city?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
  metro?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
}

export type Login = {
  __typename?: 'login'
  date: Scalars['Timestamp']
  value: Scalars['Float']
}

export type Lot = {
  __typename?: 'Lot'
  id: Scalars['Int']
  clientName?: Maybe<Scalars['String']>
  communityId?: Maybe<Scalars['Int']>
  name: Scalars['String']
  dataName?: Maybe<Scalars['String']>
  salesStatus: LotSalesStatus
  externalId?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  premium?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Float']>
  plan?: Maybe<Scalars['String']>
  garagePosition?: Maybe<GaragePosition>
  inventory?: Maybe<Inventory>
  latitude?: Maybe<Scalars['Float']>
  longitude?: Maybe<Scalars['Float']>
  geoJson?: Maybe<Scalars['JSON']>
  metroName?: Maybe<Scalars['String']>
  cityName?: Maybe<Scalars['String']>
  stateCode?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['Zip']>
  postCode?: Maybe<Scalars['String']>
  countryCode?: Maybe<Scalars['String']>
  siteplanInfo?: Maybe<LotSiteplanInfo>
  siteplanName?: Maybe<Scalars['String']>
  excludedPlanElevations: Array<PlanElevation>
  availablePlanElevations: Array<PlanElevation>
  excludedSchemes: Array<Scheme>
  availableSchemes: Array<Scheme>
  siteplan?: Maybe<Siteplan>
  count?: Maybe<Scalars['Int']>
  popularity?: Maybe<Scalars['Float']>
  leadsCount?: Maybe<Scalars['Int']>
}

export type LotInventoryArgs = {
  clientName: Scalars['String']
}

export type LotSiteplanNameArgs = {
  clientName: Scalars['String']
}

export type LotExcludedPlanElevationsArgs = {
  clientName: Scalars['String']
}

export type LotAvailablePlanElevationsArgs = {
  clientName: Scalars['String']
}

export type LotExcludedSchemesArgs = {
  clientName: Scalars['String']
}

export type LotAvailableSchemesArgs = {
  clientName: Scalars['String']
}

export type LotClicks = {
  __typename?: 'LotClicks'
  count: Scalars['Int']
  day: Scalars['String']
}

export type LotDataInput = {
  externalId?: Maybe<Scalars['String']>
  salesStatus?: Maybe<LotSalesStatus>
  address?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['Zip']>
  size?: Maybe<Scalars['Float']>
  premium?: Maybe<Scalars['Int']>
  garagePosition?: Maybe<GaragePosition>
  latitude?: Maybe<Scalars['Float']>
  longitude?: Maybe<Scalars['Float']>
}

export type LotLead = {
  __typename?: 'LotLead'
  clientName: Scalars['String']
  date: Scalars['Timestamp']
  user?: Maybe<Scalars['String']>
  prospect?: Maybe<Prospect>
}

export type LotLegendEntry = {
  __typename?: 'LotLegendEntry'
  id: Scalars['Int']
  siteplanId: Scalars['Int']
  name: Scalars['String']
  code: LotSalesStatus
  hex: Scalars['String']
  listIndex?: Maybe<Scalars['Int']>
}

export enum LotMetrics {
  Acres = 'ACRES',
  Hectare = 'HECTARE',
  Sqft = 'SQFT',
  Sqm = 'SQM',
}

export enum LotSalesStatus {
  Available = 'AVAILABLE',
  Inventory = 'INVENTORY',
  Model = 'MODEL',
  Reserved = 'RESERVED',
  Sold = 'SOLD',
  Unavailable = 'UNAVAILABLE',
  UnderConstruction = 'UNDER_CONSTRUCTION',
  UnderContract = 'UNDER_CONTRACT',
  HomesiteOnly = 'HOMESITE_ONLY',
  Closed = 'CLOSED',
  Unreleased = 'UNRELEASED',
  BeingReserved = 'BEING_RESERVED',
  FutureInventory = 'FUTURE_INVENTORY',
}

export type LotSignLock = {
  __typename?: 'LotSignLock'
  isLocked: Scalars['Boolean']
  lockedBy?: Maybe<Scalars['String']>
}

export type LotSiteplanInfo = {
  __typename?: 'LotSiteplanInfo'
  lotId?: Maybe<Scalars['Int']>
  siteplanId?: Maybe<Scalars['Int']>
  x?: Maybe<Scalars['Float']>
  y?: Maybe<Scalars['Float']>
}

export type LotSiteplanInfoInput = {
  siteplanId: Scalars['Int']
  x?: Maybe<Scalars['Float']>
  y?: Maybe<Scalars['Float']>
}

export type MasterCommunity = {
  __typename?: 'MasterCommunity'
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  caption?: Maybe<Scalars['String']>
  caption2?: Maybe<Scalars['String']>
  logo?: Maybe<Scalars['String']>
  mapMarker?: Maybe<Scalars['String']>
  mainImage?: Maybe<Scalars['String']>
  active?: Maybe<Scalars['Boolean']>
  colorTheme?: Maybe<ColorTheme>
  communities: Array<Community>
  poiCategories: Array<CommunityPoiCategory>
  siteplan?: Maybe<Siteplan>
}

export type MasterCommunityColorThemeArgs = {
  clientName: Scalars['String']
}

export type MasterCommunityCommunitiesArgs = {
  clientName: Scalars['String']
}

export type MasterCommunityPoiCategoriesArgs = {
  clientName: Scalars['String']
}

export type MasterCommunitySiteplanArgs = {
  clientName: Scalars['String']
  active?: Maybe<Scalars['Boolean']>
}

export enum MaterialCategory {
  Accent = 'ACCENT',
  Body = 'BODY',
  Trim = 'TRIM',
  Roof = 'ROOF',
  Hardscape = 'HARDSCAPE',
}

export type MaterialPalette = {
  __typename?: 'MaterialPalette'
  materialId: Scalars['Int']
  communityId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  materialCategory?: Maybe<MaterialCategory>
  name: Scalars['String']
  displayName?: Maybe<Scalars['String']>
  palettes: Array<Palette>
}

export type MetroInfo = {
  __typename?: 'MetroInfo'
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  metroState?: Maybe<Scalars['String']>
  countryCode?: Maybe<Scalars['String']>
  customName?: Maybe<Scalars['String']>
  src?: Maybe<Scalars['String']>
  cities: Array<CityInfo>
  metroCode?: Maybe<Scalars['Int']>
}

export type MetroInfoCitiesArgs = {
  clientName?: Maybe<Scalars['String']>
}

export type MfRecommendedPlans = {
  __typename?: 'MFRecommendedPlans'
  planId: Scalars['Int']
  communityId: Scalars['Int']
  elevationId: Scalars['Int']
  planName: Scalars['String']
  communityName: Scalars['String']
  elevationCaption: Scalars['String']
  thumb: Scalars['String']
  bed?: Maybe<Scalars['Int']>
  bath?: Maybe<Scalars['Float']>
  size?: Maybe<Scalars['Int']>
}

export type MfRecommendedPlansInput = {
  planId: Scalars['Int']
  communityId: Scalars['Int']
}

export enum MlsStatus {
  Active = 'ACTIVE',
  NoShowings = 'NO_SHOWINGS',
  Contingent = 'CONTINGENT',
  Pending = 'PENDING',
  Sold = 'SOLD',
  Closed = 'CLOSED',
  Offmarket = 'OFFMARKET',
  Expired = 'EXPIRED',
  Withdrawn = 'WITHDRAWN',
}

/** MobileCarrier of mobile access */
export type MobileCarrier = {
  __typename?: 'MobileCarrier'
  name?: Maybe<Scalars['String']>
  mcc?: Maybe<Scalars['String']>
  mnc?: Maybe<Scalars['String']>
}

export type Mutation = {
  __typename?: 'Mutation'
  helloMutation?: Maybe<Scalars['String']>
  updateLots: Scalars['Boolean']
  sendLeadsInquiryEmail?: Maybe<Scalars['Boolean']>
  addClient?: Maybe<Client>
  addAgent?: Maybe<Community>
  addCollection?: Maybe<Scalars['Int']>
  addColor?: Maybe<Color>
  addCommunity?: Maybe<Community>
  addCommunityGallery?: Maybe<CommunityGallery>
  addCommunityGalleryPhoto?: Maybe<CommunityGalleryPhoto>
  addCommunityHighlight?: Maybe<CommunityHighlight>
  addCommunityHighlightPhoto?: Maybe<CommunityHighlightPhoto>
  addCommunityMainPhoto?: Maybe<CommunityMainPhoto>
  addCommunityOverviewPhoto?: Maybe<CommunityOverviewPhoto>
  addCommunityPoiCategory?: Maybe<CommunityPoiCategory>
  addCommunityPhoto?: Maybe<CommunityImg>
  addCommunityPoi?: Maybe<CommunityPoi>
  addCommunityTagCategory: Array<CommunityTagCategory>
  addCommunityTag?: Maybe<CommunityTag>
  addPortalCommunityTag?: Maybe<PortalCommunityTag>
  addPortalCommunityTagCategory: Array<PortalCommunityTagCategory>
  addBuilderTagCategory: Array<BuilderTagCategory>
  addBuilderTag?: Maybe<BuilderTag>
  addCommunitySchemeAssignments: Array<Scalars['Int']>
  addElevation?: Maybe<Scalars['Int']>
  addElevationElement?: Maybe<Scalars['Boolean']>
  addElevationElements?: Maybe<Scalars['Boolean']>
  addElevationOverlay?: Maybe<Scalars['Int']>
  addOrUpdateElevationOverlay: Array<ElevationOverlay>
  updateElevationOverlaySrc?: Maybe<ElevationOverlay>
  addElevationSchemeAssignments: Array<Scalars['Int']>
  addFloorplan?: Maybe<Floorplan>
  addFloorplanAlternate?: Maybe<Scalars['Int']>
  addFloorplanOptionTag?: Maybe<AnewgoFloorplanTagCategory>
  addFloorplanTags?: Maybe<Scalars['Int']>
  addFloorplanTagAssignment?: Maybe<Scalars['Boolean']>
  addFpGroup?: Maybe<FloorplanGroup>
  addHomeCategory?: Maybe<HomeCategory>
  addHomeCategoryPhoto?: Maybe<HomeCategoryPhoto>
  addHomeStylePhoto?: Maybe<HomeStylePhoto>
  addHomeStyleCategoryAssignment?: Maybe<HomeCategory>
  addInterior?: Maybe<Interior>
  setDefaultInteriorElementOption?: Maybe<DefaultInteriorElementOption>
  addBuilderPage?: Maybe<BuilderPage>
  updateBuilderPage?: Maybe<BuilderPage>
  updateSocials?: Maybe<ClientSocials>
  addSocials?: Maybe<ClientSocials>
  addBuilderPageImage?: Maybe<BuilderPageImage>
  updateImageSelection?: Maybe<BuilderPageImage>
  adjustBuilderTemplateImageSelections?: Maybe<Scalars['Boolean']>
  deleteBuilderPageImage?: Maybe<BuilderPageImage>
  deleteBuilderPageImages: Array<BuilderPageImage>
  updateBuilderPageImageIndex?: Maybe<Scalars['Boolean']>
  addBuilderPageVideo?: Maybe<BuilderPageVideo>
  deleteBuilderPageVideo?: Maybe<BuilderPageVideo>
  updateBuilderPageVideo?: Maybe<BuilderPageVideo>
  addBuilderPageAccolade?: Maybe<BuilderPageAccolade>
  deleteBuilderPageAccolade?: Maybe<Scalars['Int']>
  updateBuilderPageAccolade?: Maybe<BuilderPageAccolade>
  updateBuilderPageAccoladeIndex?: Maybe<Scalars['Boolean']>
  addBuilderPageSection?: Maybe<BuilderPageAboutSection>
  deleteBuilderPageSection?: Maybe<Scalars['Int']>
  updateBuilderPageSection?: Maybe<BuilderPageAboutSection>
  updateBuilderPageSectionIndex?: Maybe<Scalars['Boolean']>
  publishBuilderPage?: Maybe<Scalars['Boolean']>
  removeDefaultInteriorElementOption?: Maybe<DefaultInteriorElementOption>
  addInteriorsFromManifest: Array<Interior>
  duplicateInterior: Scalars['Int']
  addInteriorAssignment?: Maybe<InteriorAssignment>
  addInteriorElementOption?: Maybe<InteriorElementOption>
  addInteriorListElement?: Maybe<InteriorListElementTree>
  updateInteriorListElement?: Maybe<InteriorListElement>
  deleteInteriorListElement?: Maybe<Scalars['Int']>
  updateListElementListIndex?: Maybe<Scalars['Boolean']>
  addInteriorPreset?: Maybe<InteriorPreset>
  updateInteriorPreset?: Maybe<InteriorPreset>
  deleteInteriorPreset?: Maybe<Scalars['Int']>
  addInteriorElementOptionAlternateGroup?: Maybe<InteriorAlternateGroup>
  removeInteriorElementOptionAlternateGroup?: Maybe<Scalars['Int']>
  addInteriorElementOptionAlternate?: Maybe<InteriorElementOptionAlternate>
  updateInteriorElementOptionAlternate?: Maybe<InteriorElementOptionAlternate>
  removeInteriorElementOptionAlternate?: Maybe<RemovedInteriorElementOptionAlternate>
  addLibraryVendor?: Maybe<LibraryVendor>
  addInventory?: Maybe<Lot>
  addInventoryPhoto?: Maybe<Inventory>
  addLotToSiteplan: Array<Lot>
  addLotLegendEntry: Array<LotLegendEntry>
  addMasterCommunity?: Maybe<Scalars['Int']>
  addMasterSiteplan?: Maybe<Scalars['Int']>
  addPalette?: Maybe<Scalars['Int']>
  addPaletteAssignments: Array<Scalars['Int']>
  addPlan?: Maybe<Scalars['Int']>
  addPlanAsset?: Maybe<Scalars['Boolean']>
  addPlanTag?: Maybe<AnewgoPlanTag>
  addPlanTagCategory?: Maybe<PlanTagCategory>
  addPlanTagAssignment?: Maybe<Scalars['Boolean']>
  addPortalPlanTagAssignment?: Maybe<Scalars['Boolean']>
  addSiteplanHotspot: SiteplanHotspot
  addSiteplanHotspotAsset: Scalars['Int']
  addSgtVendor?: Maybe<SgtVendor>
  addSgtVendorBuilders?: Maybe<Scalars['Boolean']>
  addMetro?: Maybe<MetroInfo>
  addCity?: Maybe<CityInfo>
  deletePlanTagAssignment?: Maybe<Scalars['Boolean']>
  deletePortalPlanTagAssignment?: Maybe<Scalars['Boolean']>
  updatePlanTagCategoryName?: Maybe<PlanTagCategory>
  deleteBuilderPlanTagCategory?: Maybe<Array<ClientPlanTag>>
  deletePlanTag?: Maybe<AnewgoPlanTag>
  deleteSiteplanHotspot: Scalars['Boolean']
  deleteSiteplanHotspotAsset: Scalars['Boolean']
  addScheme?: Maybe<Scheme>
  addInventorySgtData?: Maybe<Scalars['Boolean']>
  addSiteplan?: Maybe<Community>
  addSiteplanInfo?: Maybe<Scalars['Int']>
  addStandardFeatureAssignment: Array<StdFeatureAssignment>
  addCommunityTagAssignment: Array<CommunityTagAssignment>
  addPortalCommunityTagAssignment: Array<PortalCommunityTagAssignment>
  addBuilderTagAssignment: Array<BuilderTagAssignment>
  addStandardFeatureCategory: Array<StdFeatureCategory>
  addStandardFeature?: Maybe<StdFeature>
  addTexture?: Maybe<Texture>
  addOverlay?: Maybe<Scalars['Int']>
  addToExcludedPlanElevations: Array<PlanElevation>
  addLotsToExcludedPlanElevations: Array<Lot>
  addToExcludedSchemes: Array<Scheme>
  addToIncludedFloorplans: Array<Floorplan>
  addFloorplanTag?: Maybe<FloorplanTag>
  addPortalFloorplanTag?: Maybe<PortalFloorplanTag>
  addPortalFloorplanTagSynonym?: Maybe<PortalFloorplanTagSynonym>
  assignAgents?: Maybe<Community>
  assignElevations?: Maybe<Plan>
  assignFloorplans?: Maybe<Scalars['Int']>
  assignFpGroup?: Maybe<Scalars['Int']>
  assignFpGroups?: Maybe<Scalars['Int']>
  assignPaletteToMaterials?: Maybe<Palette>
  assignSubcommunities?: Maybe<MasterCommunity>
  assignPortalFloorplanTag: PortalFloorplanTagAssignment
  assignCountyToCommunity: Scalars['Boolean']
  unassignCountyFromCommunity: Scalars['Boolean']
  assignDistrictToCommunity: Scalars['Boolean']
  unassignDistrictFromCommunity: Scalars['Boolean']
  deleteAgent?: Maybe<Array<Agent>>
  deleteCollection?: Maybe<Scalars['Int']>
  deleteCommunity?: Maybe<Scalars['Int']>
  deleteCommunityGallery?: Maybe<Scalars['Int']>
  deleteCommunityGalleryPhoto?: Maybe<Scalars['Int']>
  deleteCommunityHighlight?: Maybe<Scalars['Int']>
  deleteCommunityHighlightPhoto?: Maybe<Scalars['Int']>
  deleteCommunityMainPhoto?: Maybe<Scalars['Int']>
  deleteCommunityPhoto?: Maybe<Scalars['Int']>
  deleteCommunityPoi?: Maybe<Scalars['Int']>
  deleteCommunityPoiCategory?: Maybe<Scalars['Int']>
  deleteCustomMetroName?: Maybe<MetroInfo>
  deleteElevation?: Maybe<Scalars['Int']>
  deleteFloorplan?: Maybe<Array<FloorplanGroup>>
  deleteFloorplanAlternate?: Maybe<Scalars['Int']>
  deleteFpGroup?: Maybe<Scalars['Int']>
  deleteFloorplanTagAssignment?: Maybe<Scalars['Boolean']>
  deleteFloorplanTag?: Maybe<FloorplanTag>
  deletePortalFloorplanTag?: Maybe<PortalFloorplanTag>
  deletePortalFloorplanTagSynonym?: Maybe<PortalFloorplanTagSynonym>
  deleteFloorplanSvgLabels?: Maybe<Scalars['Boolean']>
  deleteElevationElement?: Maybe<Scalars['Boolean']>
  deleteElevationOverlay?: Maybe<Scalars['Int']>
  deleteHomeCategory?: Maybe<Scalars['Int']>
  deleteHomeCategoryPhoto?: Maybe<Scalars['Int']>
  deleteHomeStylePhoto?: Maybe<Scalars['Int']>
  deleteInterior?: Maybe<Scalars['Int']>
  deleteInteriorAssignment?: Maybe<InteriorAssignment>
  deleteInteriorElement?: Maybe<Scalars['Int']>
  deleteInteriorElementOption?: Maybe<Scalars['Int']>
  deleteInventory?: Maybe<Scalars['Int']>
  deleteInventoryPhoto?: Maybe<Scalars['Int']>
  importInventoryPhotos?: Maybe<Scalars['Boolean']>
  deleteLot?: Maybe<Scalars['Int']>
  deleteLotLegendEntry?: Maybe<Array<LotLegendEntry>>
  deleteMasterCommunity?: Maybe<Scalars['Int']>
  deletePalette?: Maybe<Scalars['Int']>
  deletePlan?: Maybe<Scalars['Int']>
  deletePlanPhoto?: Maybe<Scalars['String']>
  deletePMFloorplan?: Maybe<Scalars['Int']>
  deleteProspect?: Maybe<Scalars['Int']>
  deleteScheme?: Maybe<Scalars['Int']>
  deleteInventorySgtData?: Maybe<Scalars['Boolean']>
  deleteSgtVendor?: Maybe<Scalars['Boolean']>
  deleteSgtVendorBuilders?: Maybe<Scalars['Boolean']>
  deleteSiteplan?: Maybe<Scalars['Int']>
  deleteStandardFeatureCategory: Scalars['Int']
  deleteCommunityTagCategory: Scalars['Int']
  deletePortalCommunityTagCategory: Scalars['Int']
  deleteBuilderTagCategory: Scalars['Int']
  deleteStandardFeature: Scalars['Int']
  deleteCommunityTag: Scalars['Int']
  deletePortalCommunityTag: Scalars['Int']
  deleteBuilderTag: Scalars['Int']
  deleteMetro?: Maybe<Scalars['Boolean']>
  deleteCity?: Maybe<Scalars['Boolean']>
  duplicatePalette?: Maybe<Scalars['Int']>
  duplicateScheme?: Maybe<Scalars['Int']>
  exportElevations?: Maybe<Scalars['Boolean']>
  importCommunityData?: Maybe<Scalars['Boolean']>
  importElevations?: Maybe<Scalars['Boolean']>
  importPlan?: Maybe<Scalars['Int']>
  indexFloorplans?: Maybe<Scalars['Int']>
  indexStdFeatures?: Maybe<Scalars['Boolean']>
  indexStdFeatureCategories?: Maybe<Scalars['Boolean']>
  removeColor?: Maybe<Scalars['Int']>
  removeCommunitySchemeAssignments?: Maybe<Array<Scalars['Int']>>
  removeElevation?: Maybe<Scalars['Int']>
  removeElevationSchemeAssignments?: Maybe<Array<Scalars['Int']>>
  removeFromExcludedPlanElevations?: Maybe<Array<PlanElevation>>
  removeLotsFromExcludedPlanElevations?: Maybe<Array<Lot>>
  removeFromExcludedSchemes?: Maybe<Array<Scheme>>
  removeFromIncludedFloorplans?: Maybe<Array<Floorplan>>
  removeHomeStyleCategoryAssignment?: Maybe<HomeCategory>
  removeLibraryVendor?: Maybe<Scalars['Int']>
  removeOverlay?: Maybe<Scalars['Int']>
  removePaletteAssignments?: Maybe<Array<Scalars['Int']>>
  removePlan?: Maybe<Scalars['Int']>
  removeStandardFeatureAssignment?: Maybe<Array<Scalars['Int']>>
  removeCommunityTagAssignment?: Maybe<Array<Scalars['Int']>>
  removePortalCommunityTagAssignment?: Maybe<Array<Scalars['Int']>>
  removeBuilderTagAssignment?: Maybe<Array<Scalars['Int']>>
  removeTexture?: Maybe<Scalars['Int']>
  reorderSiteplanHotspotAssets: Scalars['Boolean']
  setFloorplanAsOptional?: Maybe<Scalars['Int']>
  setFloorplanAsStandard?: Maybe<Scalars['Int']>
  setInventoryPaletteSelections?: Maybe<Scalars['Boolean']>
  unassignFloorplanFromPlan?: Maybe<Array<FloorplanGroup>>
  unassignFloorplans?: Maybe<Scalars['Int']>
  unassignFpGroups?: Maybe<Scalars['Int']>
  unassignPortalFloorplanTag?: Maybe<Scalars['Boolean']>
  updateAgent?: Maybe<Array<Agent>>
  updateAgentOrder?: Maybe<Array<Agent>>
  updateBuilderAppConfig?: Maybe<Scalars['JSON']>
  updateCollection?: Maybe<Collection>
  updateColor?: Maybe<Color>
  updateCommunity?: Maybe<Community>
  updateCommunityInfo?: Maybe<Community>
  updateCommunityGalleryName?: Maybe<CommunityGallery>
  updateCommunityGalleryPhotoCaption?: Maybe<CommunityGalleryPhoto>
  updateCommunityGalleryPhotoSort?: Maybe<Array<CommunityGalleryPhoto>>
  updateCommunityHighlightName?: Maybe<CommunityHighlight>
  updateCommunityHighlightPhotoSort?: Maybe<Array<CommunityHighlightPhoto>>
  updateCommunityMainPhotoSort?: Maybe<Array<CommunityMainPhoto>>
  updateCommunityPhotoSort?: Maybe<Array<CommunityImg>>
  updateCommunityPoi?: Maybe<CommunityPoi>
  updateCommunityPoiCategory?: Maybe<CommunityPoiCategory>
  updateCustomCityName?: Maybe<CityInfo>
  updateCustomCityPhoto?: Maybe<CityInfo>
  updateCustomMetroName?: Maybe<MetroInfo>
  updateCustomMetroPhoto?: Maybe<MetroInfo>
  updateElevation?: Maybe<Elevation>
  updateElevations?: Maybe<Array<Elevation>>
  updateElevationElement?: Maybe<ElevationElement>
  updateElevationHomeCategories?: Maybe<Elevation>
  updateElevationHomeStyles?: Maybe<Elevation>
  updateFloorplan?: Maybe<Floorplan>
  updateCommunityFloorplanExternalIds?: Maybe<Scalars['Boolean']>
  updateFloorplanAlternate?: Maybe<FloorplanAlternate>
  updateFloorplanTag?: Maybe<FloorplanTag>
  updateFloorplanTagCategory?: Maybe<FloorplanTagCategoryUpdate>
  updatePortalFloorplanTagCategory?: Maybe<PortalFloorplanTagCategoryUpdate>
  updateFpGroup?: Maybe<FloorplanGroup>
  updateHomeCategory?: Maybe<HomeCategory>
  updateHomeCategoryPhotoSort?: Maybe<Array<HomeCategoryPhoto>>
  updateHomeStylePhotoSort?: Maybe<Array<HomeStylePhoto>>
  updateHomeCategoryPhotoStyle?: Maybe<HomeCategoryPhoto>
  updateInterior?: Maybe<Interior>
  updateInventoryHomeCategories?: Maybe<Inventory>
  updateInventoryHomeStyles?: Maybe<Inventory>
  updateInteriorElementOption?: Maybe<InteriorElementOption>
  updateInventory?: Maybe<Inventory>
  updateInventoryPhotoOrder?: Maybe<Inventory>
  updateInventorySgtData?: Maybe<InventorySgtData>
  updateLibraryVendor?: Maybe<LibraryVendor>
  updateLot?: Maybe<Lot>
  updateLotPosition?: Maybe<LotSiteplanInfo>
  updateLotLegendEntry?: Maybe<Siteplan>
  updateLotLegendEntryOrder?: Maybe<Siteplan>
  importLotLegendEntries?: Maybe<Siteplan>
  updateMasterCommunity?: Maybe<MasterCommunity>
  updateMasterSalesAppConfig?: Maybe<Scalars['String']>
  updateMaterial?: Maybe<BlendElement>
  updateMaterialCategory?: Maybe<BlendElement>
  updateMLSService?: Maybe<Scalars['Int']>
  updateOverlay?: Maybe<Scalars['Int']>
  updatePalette?: Maybe<Palette>
  updatePlan: Scalars['Boolean']
  updatePlanCollection?: Maybe<Scalars['Boolean']>
  updatePlanPhotoSort?: Maybe<Array<Photo>>
  updateScheme?: Maybe<Scheme>
  updateSgtVendor?: Maybe<SgtVendor>
  updateSiteplan?: Maybe<Siteplan>
  updateSiteplanHotspot: Scalars['Boolean']
  updateSiteplanHotspotAsset: Scalars['Boolean']
  updateSiteplanInfo?: Maybe<Scalars['Int']>
  updateStandardFeatureCategory?: Maybe<Scalars['Int']>
  updateCommunityTagCategory?: Maybe<Scalars['Int']>
  updatePortalCommunityTagCategory?: Maybe<Scalars['Int']>
  updateBuilderTagCategory?: Maybe<Scalars['Int']>
  updateStandardFeature?: Maybe<Scalars['Int']>
  updateCommunityTag?: Maybe<Scalars['Int']>
  updatePortalCommunityTag?: Maybe<Scalars['Int']>
  updateBuilderTag?: Maybe<Scalars['Int']>
  updateTexture?: Maybe<Texture>
  triggerColonnadeReloadForClientProspects?: Maybe<Scalars['Boolean']>
  updateElevationThumbnail: Scalars['Boolean']
  upsertPMFloorplan?: Maybe<PmFloorplan>
  updateMetro?: Maybe<MetroInfo>
  updateCity?: Maybe<CityInfo>
  recalculateAllElevationsLayersRenderOrder: Array<Elevation>
  setCustomization?: Maybe<Customization>
  updateClientInfo?: Maybe<Scalars['Int']>
  deleteClient?: Maybe<Scalars['String']>
  addProspectBrochure?: Maybe<Scalars['Boolean']>
  rejectProspectBrochure?: Maybe<Scalars['Boolean']>
  updateHomesiteByLotExtId?: Maybe<HomesiteUpdateResult>
  updateHomesitesByLotExtId: Array<HomesiteUpdateResult>
  updateCommunityPlanPricing?: Maybe<Array<Maybe<CommunityPlanPricingResult>>>
  updateCommunityPlansByExternalOrInternalIds: Array<CommunityPlanUpdateResult>
  updatePlansByExternalIdOrPlanId: Array<PlanUpdateResult>
  batchUpdateElevations: Scalars['Boolean']
  batchUpdateFloorplans: Scalars['Boolean']
  createNewReservation?: Maybe<Scalars['UUID']>
  signReservationDocument?: Maybe<Scalars['URL']>
  acceptReservationTermsAndConditions?: Maybe<Scalars['Boolean']>
  addAdditionalBuyerForReservation?: Maybe<Scalars['UUID']>
  createReservationPaymentUrl?: Maybe<Scalars['URL']>
  rejectOnlineReservationByProspect?: Maybe<ProspectReservation>
  addAgentProspect?: Maybe<Scalars['Boolean']>
  addFavoritesForProspect?: Maybe<Array<Maybe<Scalars['Int']>>>
  addHomeSelection?: Maybe<Scalars['Boolean']>
  upsertProspect?: Maybe<Prospect>
  addProspectReceivedLink?: Maybe<Array<ReceivedFavorites>>
  deleteProspectReceivedLink?: Maybe<Scalars['Boolean']>
  deleteFavoriteForProspect?: Maybe<Scalars['Boolean']>
  deleteFavoriteForProspectByEmail?: Maybe<Scalars['Boolean']>
  replaceFavoritesForProspect?: Maybe<Array<ProspectFavorite>>
  requestSgtAppointment: Array<ProspectSgtAppointment>
  sendInquiryToBuilder?: Maybe<Scalars['Boolean']>
  sendProspectRegistrationEmail?: Maybe<Scalars['Boolean']>
  sendShareFavoritesEmail?: Maybe<Scalars['Boolean']>
  sendWelcomeEmail?: Maybe<Scalars['Boolean']>
  updateFavoritesForProspect?: Maybe<Scalars['Boolean']>
  updateProspectContactInfos?: Maybe<Prospect>
  updateProspectPicture?: Maybe<Scalars['Boolean']>
  updateEmailCampaignLeadSubscriptionStatus?: Maybe<Scalars['Boolean']>
  colonnadeApiBatchAddAgentProspect?: Maybe<Scalars['Boolean']>
  deleteOnlineReservation?: Maybe<Scalars['UUID']>
  restoreDeletedOnlineReservation?: Maybe<Scalars['UUID']>
  createReservationIntegration?: Maybe<Scalars['Boolean']>
  rejectOnlineReservation?: Maybe<ProspectReservation>
  createStripeAccount: Scalars['URL']
  addProspectActivities?: Maybe<Scalars['Boolean']>
  sendRecommendedPlansEmail?: Maybe<Scalars['Boolean']>
  sendInsightsOnlineReservationInquiry?: Maybe<Scalars['Boolean']>
  sendInsightsMarketingAutomationInquiry?: Maybe<Scalars['Boolean']>
  setNewLeadAgentNotificationConfig?: Maybe<Scalars['Boolean']>
  setLeadBrowseAgentNotificationConfig?: Maybe<Scalars['Boolean']>
  setProspectClientRating?: Maybe<Prospect>
  setSubscriptionEndpoint?: Maybe<Scalars['Boolean']>
  testSubscriptionEndpoint?: Maybe<Scalars['Boolean']>
  updateProspect?: Maybe<Prospect>
  updateReservationIntegrationInfo: OnlineReservationIntegration
  createEmailCampaign?: Maybe<EmailCampaign>
  updateEmailCampaign?: Maybe<EmailCampaign>
  deleteEmailCampaign?: Maybe<Scalars['Boolean']>
  deleteEmailCampaignSectionImage?: Maybe<EmailCampaignSection>
  sendEmailCampaign?: Maybe<Scalars['Boolean']>
  sendFollowUpEmail?: Maybe<Scalars['Boolean']>
  toggleCommunityBuyOnlineEnabled: Scalars['Boolean']
  updateEmailCampaignLeadStatus?: Maybe<Scalars['Boolean']>
  toggleBuyOnlineEnabled: Scalars['Boolean']
  toggleAllowMultipleReservations: Scalars['Boolean']
  updateReservationCustomTemplateFields?: Maybe<Scalars['JSON']>
  addOrUpdateCommunityBuyOnlineCustomConfigTemplateValues?: Maybe<
    Scalars['JSON']
  >
  lockLotSign?: Maybe<Scalars['Boolean']>
  unlockLotSign?: Maybe<Scalars['Boolean']>
  assignProspectToColonnadeAgent?: Maybe<Scalars['Boolean']>
  unassignProspectFromColonnadeAgent?: Maybe<Scalars['Boolean']>
  importProspectsToColonnadeAgents?: Maybe<Scalars['Boolean']>
  updateColonnadeSubscriptionDate: Scalars['Boolean']
  updateUserClientRole?: Maybe<User>
  requestColonnadeLicenseUpgrade?: Maybe<Scalars['Boolean']>
  updateRoleAppConfig?: Maybe<Array<Maybe<Scalars['Int']>>>
  addFlagshipRoleAppConfig?: Maybe<Scalars['Boolean']>
  generateClientApiKey?: Maybe<ClientApiKey>
  generateAppApiKey?: Maybe<AppApiKey>
  deleteClientApiKey?: Maybe<Scalars['Int']>
  deleteAppApiKey?: Maybe<Scalars['Int']>
  updateOrigins?: Maybe<AppApiKey>
  addColonnadeUser?: Maybe<Scalars['Boolean']>
  importAgentsAsColonnadeUsers: Array<ImportAgentResult>
  addUser?: Maybe<AddUserResult>
  updateUser?: Maybe<Scalars['Boolean']>
  addOrUpdateClientSubscription?: Maybe<Scalars['Boolean']>
  addOrUpdateClientCommunitySubscription?: Maybe<Scalars['Boolean']>
  addColonnadeClientEmailReportInfo?: Maybe<ClientEmailReportInfo>
  updateColonnadeClientEmailReportInfo?: Maybe<ClientEmailReportInfo>
  deleteColonnadeClientEmailReportInfo: Scalars['Int']
  deleteClientCommunitySubscription?: Maybe<Scalars['Boolean']>
  deleteColonnadeUser?: Maybe<Scalars['Boolean']>
  requestSubscriptionUpgrade?: Maybe<Scalars['Boolean']>
  setColonnadeClientSubsLevel?: Maybe<Scalars['Boolean']>
  setColonnadeClientLicenses?: Maybe<Scalars['Boolean']>
  setColSubsAnnivNotif?: Maybe<Scalars['Boolean']>
  setRolePermission?: Maybe<Scalars['Boolean']>
  updateColonnadeUser?: Maybe<Scalars['Boolean']>
  deleteUser: Scalars['Int']
  addColonnadeSubscriptionClient: Scalars['Boolean']
  deleteColonnadeSubscriptionClient: Scalars['Boolean']
  upsertAssignDateAgentProspect: Scalars['String']
  sendForgotEmailNotifToAdmin?: Maybe<Scalars['Boolean']>
  sendForgotEmailNotifRetrieve?: Maybe<Scalars['Boolean']>
  sendPasswordResetEmail?: Maybe<Scalars['String']>
  setPassword?: Maybe<Scalars['Int']>
  addExcludedIp: Scalars['Boolean']
  removeExcludedIp: Scalars['Boolean']
}

export type MutationHelloMutationArgs = {
  name?: Maybe<Scalars['String']>
}

export type MutationUpdateLotsArgs = {
  clientName: Scalars['String']
  updateLotInputs: Array<UpdateLotInput>
}

export type MutationSendLeadsInquiryEmailArgs = {
  userFirstName: Scalars['String']
  userLastName: Scalars['String']
  userEmail: Scalars['String']
}

export type MutationAddClientArgs = {
  clientName: Scalars['String']
  altName: Scalars['String']
  directoryName: Scalars['String']
  type: ClientType
  areaMetric: AreaMetric
  contact: Scalars['String']
  email: Scalars['String']
  currency: Scalars['Currency']
}

export type MutationAddAgentArgs = {
  input: AddAgentInput
  communityId: Scalars['Int']
  clientName: Scalars['String']
}

export type MutationAddCollectionArgs = {
  clientName: Scalars['String']
  name: Scalars['String']
}

export type MutationAddColorArgs = {
  input: AddColorInput
}

export type MutationAddCommunityArgs = {
  clientName: Scalars['String']
  metroId?: Maybe<Scalars['Int']>
  cityId: Scalars['Int']
  name: Scalars['String']
}

export type MutationAddCommunityGalleryArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
  name: Scalars['String']
}

export type MutationAddCommunityGalleryPhotoArgs = {
  clientName: Scalars['String']
  galleryId: Scalars['Int']
  src: Scalars['String']
  caption: Scalars['String']
}

export type MutationAddCommunityHighlightArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
  name: Scalars['String']
}

export type MutationAddCommunityHighlightPhotoArgs = {
  clientName: Scalars['String']
  highlightId: Scalars['Int']
  src: Scalars['String']
}

export type MutationAddCommunityMainPhotoArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
  src: Scalars['String']
}

export type MutationAddCommunityOverviewPhotoArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
  src: Scalars['String']
  listIndex?: Maybe<Scalars['Int']>
}

export type MutationAddCommunityPoiCategoryArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  masterCommunityId?: Maybe<Scalars['Int']>
  name: Scalars['String']
  icon: Scalars['String']
}

export type MutationAddCommunityPhotoArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
  src: Scalars['String']
  videoSrc?: Maybe<Scalars['String']>
}

export type MutationAddCommunityPoiArgs = {
  clientName: Scalars['String']
  poiCategoryId: Scalars['Int']
  poi: AddCommunityPoiInput
}

export type MutationAddCommunityTagCategoryArgs = {
  clientName: Scalars['String']
  categoryName: Scalars['String']
}

export type MutationAddCommunityTagArgs = {
  clientName: Scalars['String']
  name: Scalars['String']
  categoryId: Scalars['Int']
}

export type MutationAddPortalCommunityTagArgs = {
  clientName: Scalars['String']
  name: Scalars['String']
  categoryId: Scalars['Int']
}

export type MutationAddPortalCommunityTagCategoryArgs = {
  clientName: Scalars['String']
  categoryName: Scalars['String']
}

export type MutationAddBuilderTagCategoryArgs = {
  categoryName: Scalars['String']
}

export type MutationAddBuilderTagArgs = {
  name: Scalars['String']
  categoryId: Scalars['Int']
}

export type MutationAddCommunitySchemeAssignmentsArgs = {
  clientName: Scalars['String']
  assignments: Array<CommunitySchemeAssignment>
}

export type MutationAddElevationArgs = {
  clientName: Scalars['String']
  planId: Scalars['Int']
  caption: Scalars['String']
  internalName?: Maybe<Scalars['String']>
  homeCategories: Array<Scalars['Int']>
}

export type MutationAddElevationElementArgs = {
  clientName: Scalars['String']
  elevationId: Scalars['Int']
  elementId: Scalars['Int']
  src: Scalars['String']
}

export type MutationAddElevationElementsArgs = {
  clientName: Scalars['String']
  elevationId: Scalars['Int']
  newElements: Array<ElevationElementInput>
}

export type MutationAddElevationOverlayArgs = {
  clientName: Scalars['String']
  elevationId: Scalars['Int']
  elementId: Scalars['Int']
  textureData: TextureDataInput
  src: Scalars['String']
}

export type MutationAddOrUpdateElevationOverlayArgs = {
  clientName: Scalars['String']
  overlayInputs: Array<OverlayInput>
}

export type MutationUpdateElevationOverlaySrcArgs = {
  clientName: Scalars['String']
  elevationOverlayId: Scalars['Int']
  src: Scalars['String']
}

export type MutationAddElevationSchemeAssignmentsArgs = {
  clientName: Scalars['String']
  assignments: Array<CommunityElevationSchemeAssignment>
}

export type MutationAddFloorplanArgs = {
  clientName: Scalars['String']
  input: AddFloorplanInput
}

export type MutationAddFloorplanAlternateArgs = {
  clientName: Scalars['String']
  floorplanAlternate: FloorplanAlternateInput
}

export type MutationAddFloorplanOptionTagArgs = {
  clientName: Scalars['String']
  floorplanOptionName: Scalars['String']
  floorplanId: Scalars['Int']
}

export type MutationAddFloorplanTagsArgs = {
  clientName: Scalars['String']
  floorplanId?: Maybe<Scalars['Int']>
  tags: Array<FloorplanTagInput>
}

export type MutationAddFloorplanTagAssignmentArgs = {
  clientName: Scalars['String']
  floorplanTagId: Scalars['Int']
  floorplanTagCategoryId: Scalars['Int']
  floorplanId: Scalars['Int']
}

export type MutationAddFpGroupArgs = {
  clientName: Scalars['String']
  planId: Scalars['Int']
  name?: Maybe<Scalars['String']>
  groupType: FloorplanGroupType
  members?: Maybe<Array<FloorplanGroupMemberInput>>
  elevationAssignments?: Maybe<Array<ElevationAndCommunities>>
  communityIds?: Maybe<Array<Scalars['Int']>>
  communityId?: Maybe<Scalars['Int']>
  autoselectLinkedChildParents?: Maybe<Scalars['Boolean']>
}

export type MutationAddHomeCategoryArgs = {
  name: Scalars['String']
}

export type MutationAddHomeCategoryPhotoArgs = {
  categoryId: Scalars['Int']
  src: Scalars['String']
  styleId?: Maybe<Scalars['Int']>
}

export type MutationAddHomeStylePhotoArgs = {
  homeStyleId: Scalars['Int']
  src: Scalars['String']
}

export type MutationAddHomeStyleCategoryAssignmentArgs = {
  categoryId: Scalars['Int']
  homestyleIds: Array<Scalars['Int']>
}

export type MutationAddInteriorArgs = {
  clientName: Scalars['String']
  interior: AddInteriorInput
}

export type MutationSetDefaultInteriorElementOptionArgs = {
  clientName: Scalars['String']
  defaultInteriorElementOptionInput: DefaultInteriorElementOptionInput
}

export type MutationAddBuilderPageArgs = {
  clientName: Scalars['String']
  addBuilderPageInput: AddBuilderPageInput
}

export type MutationUpdateBuilderPageArgs = {
  clientName: Scalars['String']
  updateBuilderPageInput: UpdateBuilderPageInput
}

export type MutationUpdateSocialsArgs = {
  clientName: Scalars['String']
  updateSocialsInput: UpdateSocialsInput
}

export type MutationAddSocialsArgs = {
  clientName: Scalars['String']
  addSocialsInput: AddSocialsInput
}

export type MutationAddBuilderPageImageArgs = {
  clientName: Scalars['String']
  builderPageImageInput: BuilderPageImageInput
}

export type MutationUpdateImageSelectionArgs = {
  clientName: Scalars['String']
  image: BuilderPageImageInput
}

export type MutationAdjustBuilderTemplateImageSelectionsArgs = {
  clientName: Scalars['String']
  maxImages: Scalars['Int']
  builderPageId: Scalars['Int']
}

export type MutationDeleteBuilderPageImageArgs = {
  clientName: Scalars['String']
  imageId: Scalars['Int']
}

export type MutationDeleteBuilderPageImagesArgs = {
  clientName: Scalars['String']
  imageIds: Array<Scalars['Int']>
}

export type MutationUpdateBuilderPageImageIndexArgs = {
  clientName: Scalars['String']
  builderPageImageIndexChangeInput: IndexChangeInput
}

export type MutationAddBuilderPageVideoArgs = {
  clientName: Scalars['String']
  builderPageVideoInput: BuilderPageVideoInput
}

export type MutationDeleteBuilderPageVideoArgs = {
  clientName: Scalars['String']
  videoId: Scalars['Int']
  builderPageId: Scalars['Int']
}

export type MutationUpdateBuilderPageVideoArgs = {
  clientName: Scalars['String']
  builderPageVideoInput: BuilderPageVideoInput
}

export type MutationAddBuilderPageAccoladeArgs = {
  clientName: Scalars['String']
  builderPageAccoladeInput: BuilderPageAccoladeInput
}

export type MutationDeleteBuilderPageAccoladeArgs = {
  clientName: Scalars['String']
  accoladeId: Scalars['Int']
}

export type MutationUpdateBuilderPageAccoladeArgs = {
  clientName: Scalars['String']
  builderPageAccoladeInput: BuilderPageAccoladeInput
}

export type MutationUpdateBuilderPageAccoladeIndexArgs = {
  clientName: Scalars['String']
  builderPageAccoladeIndexChangeInput: IndexChangeInput
}

export type MutationAddBuilderPageSectionArgs = {
  clientName: Scalars['String']
  builderPageSectionInput: BuilderPageAboutSectionInput
}

export type MutationDeleteBuilderPageSectionArgs = {
  clientName: Scalars['String']
  sectionId: Scalars['Int']
}

export type MutationUpdateBuilderPageSectionArgs = {
  clientName: Scalars['String']
  builderPageSectionInput: BuilderPageAboutSectionInput
}

export type MutationUpdateBuilderPageSectionIndexArgs = {
  clientName: Scalars['String']
  builderPageSectionIndexChangeInput: IndexChangeInput
}

export type MutationPublishBuilderPageArgs = {
  clientName: Scalars['String']
  id: Scalars['Int']
  socialsId?: Maybe<Scalars['Int']>
}

export type MutationRemoveDefaultInteriorElementOptionArgs = {
  clientName: Scalars['String']
  interiorListElementId?: Maybe<Scalars['Int']>
}

export type MutationAddInteriorsFromManifestArgs = {
  clientName: Scalars['String']
  interiors: Array<AddInteriorFromManifestInput>
}

export type MutationDuplicateInteriorArgs = {
  clientName: Scalars['String']
  interiorId: Scalars['Int']
}

export type MutationAddInteriorAssignmentArgs = {
  clientName: Scalars['String']
  input?: Maybe<Array<InteriorAssignmentInput>>
}

export type MutationAddInteriorElementOptionArgs = {
  clientName: Scalars['String']
  option: AddInteriorElementOptionInput
}

export type MutationAddInteriorListElementArgs = {
  clientName: Scalars['String']
  interiorListElement: AddInteriorListElementInput
}

export type MutationUpdateInteriorListElementArgs = {
  clientName: Scalars['String']
  interiorListElementId: Scalars['Int']
  interiorListElement: UpdateInteriorListElementInput
}

export type MutationDeleteInteriorListElementArgs = {
  clientName: Scalars['String']
  interiorListElementId: Scalars['Int']
}

export type MutationUpdateListElementListIndexArgs = {
  clientName: Scalars['String']
  interiorId: Scalars['Int']
  updateListElementListIndex: UpdateListElementListIndexInput
}

export type MutationAddInteriorPresetArgs = {
  clientName: Scalars['String']
  addInteriorPresetInput: AddInteriorPresetInput
  interiorPresetAssignments?: Maybe<Array<InteriorPresetAssignmentInput>>
}

export type MutationUpdateInteriorPresetArgs = {
  clientName: Scalars['String']
  updateInteriorPresetInput?: Maybe<UpdateInteriorPresetInput>
  interiorPresetAssignments?: Maybe<Array<InteriorPresetAssignmentInput>>
}

export type MutationDeleteInteriorPresetArgs = {
  clientName: Scalars['String']
  interiorPresetId: Scalars['Int']
}

export type MutationAddInteriorElementOptionAlternateGroupArgs = {
  clientName: Scalars['String']
  interiorListElementAlternateInput: InteriorElementOptionAlternateGroupInput
}

export type MutationRemoveInteriorElementOptionAlternateGroupArgs = {
  clientName: Scalars['String']
  interiorElementOptionAlternateGroupId: Scalars['Int']
}

export type MutationAddInteriorElementOptionAlternateArgs = {
  clientName: Scalars['String']
  interiorElementOptionAlternateInput: InteriorElementOptionAlternateInput
}

export type MutationUpdateInteriorElementOptionAlternateArgs = {
  clientName: Scalars['String']
  interiorElementOptionAlternateInput: InteriorElementOptionAlternateInput
}

export type MutationRemoveInteriorElementOptionAlternateArgs = {
  clientName: Scalars['String']
  removeInteriorElementOptionAlternateInput: RemoveInteriorElementOptionAlternateInput
}

export type MutationAddLibraryVendorArgs = {
  library: LibraryType
  name: Scalars['String']
}

export type MutationAddInventoryArgs = {
  clientName: Scalars['String']
  inventory: AddInventoryInput
}

export type MutationAddInventoryPhotoArgs = {
  clientName: Scalars['String']
  inventoryId: Scalars['Int']
  src: Scalars['String']
}

export type MutationAddLotToSiteplanArgs = {
  clientName: Scalars['String']
  siteplanId: Scalars['Int']
  lot: AddLotToSiteplanInput
  alsoAddInventory?: Maybe<Scalars['Boolean']>
}

export type MutationAddLotLegendEntryArgs = {
  clientName: Scalars['String']
  siteplanId: Scalars['Int']
  lotLegendEntry: AddLotLegendEntryInput
}

export type MutationAddMasterCommunityArgs = {
  clientName: Scalars['String']
  name: Scalars['String']
}

export type MutationAddMasterSiteplanArgs = {
  clientName: Scalars['String']
  masterCommunityId: Scalars['Int']
  src: Scalars['String']
}

export type MutationAddPaletteArgs = {
  clientName: Scalars['String']
  input: AddPaletteInput
}

export type MutationAddPaletteAssignmentsArgs = {
  clientName: Scalars['String']
  assignments: Array<CommunityPaletteAssignment>
}

export type MutationAddPlanArgs = {
  clientName: Scalars['String']
  name: Scalars['String']
  homeType: HomeType
}

export type MutationAddPlanAssetArgs = {
  clientName: Scalars['String']
  planId: Scalars['Int']
  src: Scalars['String']
  photoType: PhotoType
  videoSrc?: Maybe<Scalars['String']>
}

export type MutationAddPlanTagArgs = {
  clientName: Scalars['String']
  name: Scalars['String']
  categoryId: Scalars['Int']
}

export type MutationAddPlanTagCategoryArgs = {
  name: Scalars['String']
}

export type MutationAddPlanTagAssignmentArgs = {
  clientName: Scalars['String']
  input: PlanTagAssignmentInput
}

export type MutationAddPortalPlanTagAssignmentArgs = {
  clientName: Scalars['String']
  input: PlanTagAssignmentInput
}

export type MutationAddSiteplanHotspotArgs = {
  clientName: Scalars['String']
  siteplanId: Scalars['Int']
  input: SiteplanHotspotInput
}

export type MutationAddSiteplanHotspotAssetArgs = {
  clientName: Scalars['String']
  siteplanHotspotId: Scalars['Int']
}

export type MutationAddSgtVendorArgs = {
  name: Scalars['String']
}

export type MutationAddSgtVendorBuildersArgs = {
  sgtVendorBuilders?: Maybe<Array<SgtVendorBuilderInput>>
}

export type MutationAddMetroArgs = {
  input: AddMetroInput
}

export type MutationAddCityArgs = {
  input: AddCityInput
}

export type MutationDeletePlanTagAssignmentArgs = {
  clientName: Scalars['String']
  input: PlanTagAssignmentInput
}

export type MutationDeletePortalPlanTagAssignmentArgs = {
  clientName: Scalars['String']
  input: PlanTagAssignmentInput
}

export type MutationUpdatePlanTagCategoryNameArgs = {
  clientName: Scalars['String']
  name: Scalars['String']
  categoryId?: Maybe<Scalars['Int']>
}

export type MutationDeleteBuilderPlanTagCategoryArgs = {
  clientName: Scalars['String']
  categoryId: Scalars['Int']
}

export type MutationDeletePlanTagArgs = {
  clientName: Scalars['String']
  name: Scalars['String']
  categoryId: Scalars['Int']
}

export type MutationDeleteSiteplanHotspotArgs = {
  clientName: Scalars['String']
  siteplanHotspotId: Scalars['Int']
}

export type MutationDeleteSiteplanHotspotAssetArgs = {
  clientName: Scalars['String']
  siteplanHotspotId: Scalars['Int']
  listIndex: Scalars['Int']
}

export type MutationAddSchemeArgs = {
  clientName: Scalars['String']
  scheme: UpdateSchemeInput
  schemeElements: Array<SchemeElementInput>
  communityIds?: Maybe<Array<Scalars['Int']>>
}

export type MutationAddInventorySgtDataArgs = {
  clientName: Scalars['String']
  inventoryId: Scalars['Int']
}

export type MutationAddSiteplanArgs = {
  clientName: Scalars['String']
  input: AddSiteplanInput
}

export type MutationAddSiteplanInfoArgs = {
  clientName: Scalars['String']
  siteplanId: Scalars['Int']
  siteplanInfo: SiteplanInfoInput
}

export type MutationAddStandardFeatureAssignmentArgs = {
  clientName: Scalars['String']
  standardFeatureAssignmentInput?: Maybe<Array<StandardFeatureAssignmentInput>>
}

export type MutationAddCommunityTagAssignmentArgs = {
  clientName: Scalars['String']
  communityTagAssignmentInput?: Maybe<Array<CommunityTagAssignmentInput>>
}

export type MutationAddPortalCommunityTagAssignmentArgs = {
  clientName: Scalars['String']
  portalCommunityTagAssignmentInput?: Maybe<
    Array<PortalCommunityTagAssignmentInput>
  >
}

export type MutationAddBuilderTagAssignmentArgs = {
  clientName: Scalars['String']
  builderTagAssignmentInput?: Maybe<Array<BuilderTagAssignmentInput>>
}

export type MutationAddStandardFeatureCategoryArgs = {
  clientName: Scalars['String']
  categoryName: Scalars['String']
}

export type MutationAddStandardFeatureArgs = {
  clientName: Scalars['String']
  name: Scalars['String']
  categoryId: Scalars['Int']
}

export type MutationAddTextureArgs = {
  input: AddTextureInput
}

export type MutationAddOverlayArgs = {
  clientName: Scalars['String']
  color: Scalars['String']
  src: Scalars['String']
}

export type MutationAddToExcludedPlanElevationsArgs = {
  clientName: Scalars['String']
  lotId: Scalars['Int']
  planElevations: Array<PlanElevationInput>
}

export type MutationAddLotsToExcludedPlanElevationsArgs = {
  clientName: Scalars['String']
  lotIds: Array<Scalars['Int']>
  planElevation: PlanElevationInput
}

export type MutationAddToExcludedSchemesArgs = {
  clientName: Scalars['String']
  lotId: Scalars['Int']
  schemes: Array<SchemeInput>
}

export type MutationAddToIncludedFloorplansArgs = {
  clientName: Scalars['String']
  inventoryId: Scalars['Int']
  floorplans: Array<FloorplanInput>
}

export type MutationAddFloorplanTagArgs = {
  floorplanTagId: Scalars['Int']
  name: Scalars['String']
}

export type MutationAddPortalFloorplanTagArgs = {
  portalFloorplanTagCategoryId: Scalars['Int']
  name: Scalars['String']
}

export type MutationAddPortalFloorplanTagSynonymArgs = {
  portalFloorplanTagId: Scalars['Int']
  name: Scalars['String']
}

export type MutationAssignAgentsArgs = {
  assignedAgentIds: Array<Scalars['Int']>
  communityId: Scalars['Int']
  clientName: Scalars['String']
}

export type MutationAssignElevationsArgs = {
  clientName: Scalars['String']
  planId: Scalars['Int']
  elevationAssignments: Array<ElevationAssignments>
  elevationUnassignments: Array<ElevationAssignments>
}

export type MutationAssignFloorplansArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  floorplanId?: Maybe<Scalars['Int']>
  assignments: Array<CommunityFloorplanAssignmentInput>
}

export type MutationAssignFpGroupArgs = {
  clientName: Scalars['String']
  planId: Scalars['Int']
  fpGroupId: Scalars['Int']
  assignments: Array<CommunityFloorplanAssignmentInput>
}

export type MutationAssignFpGroupsArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  groupId?: Maybe<Scalars['Int']>
  assignments: Array<CommunityFloorplanAssignmentInput>
}

export type MutationAssignPaletteToMaterialsArgs = {
  clientName: Scalars['String']
  input: AssignPaletteToMaterialsInput
}

export type MutationAssignSubcommunitiesArgs = {
  communityIds: Array<Scalars['Int']>
  masterCommunityId: Scalars['Int']
  clientName: Scalars['String']
}

export type MutationAssignPortalFloorplanTagArgs = {
  clientName: Scalars['String']
  floorplanId: Scalars['Int']
  portalFloorplanTagId: Scalars['Int']
}

export type MutationAssignCountyToCommunityArgs = {
  clientName: Scalars['String']
  countyId: Scalars['Int']
  communityId: Scalars['Int']
}

export type MutationUnassignCountyFromCommunityArgs = {
  clientName: Scalars['String']
  countyId: Scalars['Int']
  communityId: Scalars['Int']
}

export type MutationAssignDistrictToCommunityArgs = {
  clientName: Scalars['String']
  districtId: Scalars['Int']
  communityId: Scalars['Int']
}

export type MutationUnassignDistrictFromCommunityArgs = {
  clientName: Scalars['String']
  districtId: Scalars['Int']
  communityId: Scalars['Int']
}

export type MutationDeleteAgentArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
  agentId: Scalars['Int']
}

export type MutationDeleteCollectionArgs = {
  clientName: Scalars['String']
  collectionId: Scalars['Int']
}

export type MutationDeleteCommunityArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
}

export type MutationDeleteCommunityGalleryArgs = {
  clientName: Scalars['String']
  galleryId: Scalars['Int']
}

export type MutationDeleteCommunityGalleryPhotoArgs = {
  clientName: Scalars['String']
  galleryId: Scalars['Int']
  galleryPhotoId: Scalars['Int']
}

export type MutationDeleteCommunityHighlightArgs = {
  clientName: Scalars['String']
  highlightId: Scalars['Int']
}

export type MutationDeleteCommunityHighlightPhotoArgs = {
  clientName: Scalars['String']
  highlightId: Scalars['Int']
  highlightPhotoId: Scalars['Int']
}

export type MutationDeleteCommunityMainPhotoArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
  mainPhotoId: Scalars['Int']
}

export type MutationDeleteCommunityPhotoArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
  src: Scalars['String']
}

export type MutationDeleteCommunityPoiArgs = {
  clientName: Scalars['String']
  poiId: Scalars['Int']
}

export type MutationDeleteCommunityPoiCategoryArgs = {
  clientName: Scalars['String']
  poiCategoryId: Scalars['Int']
}

export type MutationDeleteCustomMetroNameArgs = {
  clientName: Scalars['String']
  metroId: Scalars['Int']
}

export type MutationDeleteElevationArgs = {
  clientName: Scalars['String']
  planId: Scalars['Int']
  elevationId: Scalars['Int']
}

export type MutationDeleteFloorplanArgs = {
  clientName: Scalars['String']
  planId: Scalars['Int']
  floorplanId: Scalars['Int']
}

export type MutationDeleteFloorplanAlternateArgs = {
  clientName: Scalars['String']
  floorplanAltId: Scalars['Int']
}

export type MutationDeleteFpGroupArgs = {
  clientName: Scalars['String']
  fpGroupId: Scalars['Int']
}

export type MutationDeleteFloorplanTagAssignmentArgs = {
  clientName: Scalars['String']
  floorplanId: Scalars['Int']
  anewgoTagId: Scalars['Int']
}

export type MutationDeleteFloorplanTagArgs = {
  tagId: Scalars['Int']
}

export type MutationDeletePortalFloorplanTagArgs = {
  tagId: Scalars['Int']
}

export type MutationDeletePortalFloorplanTagSynonymArgs = {
  name: Scalars['String']
  tagId: Scalars['Int']
}

export type MutationDeleteFloorplanSvgLabelsArgs = {
  clientName: Scalars['String']
  floorplanId: Scalars['Int']
}

export type MutationDeleteElevationElementArgs = {
  clientName: Scalars['String']
  elevationId: Scalars['Int']
  elementId: Scalars['Int']
}

export type MutationDeleteElevationOverlayArgs = {
  clientName: Scalars['String']
  elevationOverlayId: Scalars['Int']
}

export type MutationDeleteHomeCategoryArgs = {
  categoryId: Scalars['Int']
}

export type MutationDeleteHomeCategoryPhotoArgs = {
  categoryId: Scalars['Int']
  categoryPhotoId: Scalars['Int']
}

export type MutationDeleteHomeStylePhotoArgs = {
  homeStylePhotoId: Scalars['Int']
}

export type MutationDeleteInteriorArgs = {
  clientName: Scalars['String']
  interiorId: Scalars['Int']
}

export type MutationDeleteInteriorAssignmentArgs = {
  clientName: Scalars['String']
  input?: Maybe<Array<InteriorAssignmentInput>>
}

export type MutationDeleteInteriorElementArgs = {
  clientName: Scalars['String']
  interiorElementId: Scalars['Int']
}

export type MutationDeleteInteriorElementOptionArgs = {
  clientName: Scalars['String']
  interiorElementOptionId: Scalars['Int']
}

export type MutationDeleteInventoryArgs = {
  clientName: Scalars['String']
  inventoryId: Scalars['Int']
}

export type MutationDeleteInventoryPhotoArgs = {
  clientName: Scalars['String']
  inventoryPhotoId: Scalars['Int']
}

export type MutationImportInventoryPhotosArgs = {
  clientName: Scalars['String']
  inventoryId: Scalars['Int']
  photoType: Scalars['String']
}

export type MutationDeleteLotArgs = {
  clientName: Scalars['String']
  lotId: Scalars['Int']
}

export type MutationDeleteLotLegendEntryArgs = {
  clientName: Scalars['String']
  lotLegendEntryId: Scalars['Int']
}

export type MutationDeleteMasterCommunityArgs = {
  clientName: Scalars['String']
  masterCommunityId: Scalars['Int']
}

export type MutationDeletePaletteArgs = {
  clientName: Scalars['String']
  paletteId: Scalars['Int']
}

export type MutationDeletePlanArgs = {
  clientName: Scalars['String']
  planId: Scalars['Int']
}

export type MutationDeletePlanPhotoArgs = {
  clientName: Scalars['String']
  src: Scalars['String']
  planId: Scalars['Int']
  photoType: PhotoType
}

export type MutationDeletePmFloorplanArgs = {
  clientName: Scalars['String']
  pmFloorplanId: Scalars['Int']
}

export type MutationDeleteProspectArgs = {
  clientName: Scalars['String']
  prospectId: Scalars['Int']
}

export type MutationDeleteSchemeArgs = {
  clientName: Scalars['String']
  schemeId: Scalars['Int']
}

export type MutationDeleteInventorySgtDataArgs = {
  clientName: Scalars['String']
  inventoryId: Scalars['Int']
}

export type MutationDeleteSgtVendorArgs = {
  sgtVendorId: Scalars['Int']
}

export type MutationDeleteSgtVendorBuildersArgs = {
  sgtVendorBuilders?: Maybe<Array<SgtVendorBuilderInput>>
}

export type MutationDeleteSiteplanArgs = {
  clientName: Scalars['String']
  siteplanId: Scalars['Int']
}

export type MutationDeleteStandardFeatureCategoryArgs = {
  clientName: Scalars['String']
  categoryName: Scalars['String']
  categoryId: Scalars['Int']
}

export type MutationDeleteCommunityTagCategoryArgs = {
  clientName: Scalars['String']
  categoryName: Scalars['String']
  categoryId: Scalars['Int']
}

export type MutationDeletePortalCommunityTagCategoryArgs = {
  clientName: Scalars['String']
  categoryName: Scalars['String']
  categoryId: Scalars['Int']
}

export type MutationDeleteBuilderTagCategoryArgs = {
  categoryId: Scalars['Int']
}

export type MutationDeleteStandardFeatureArgs = {
  clientName: Scalars['String']
  name: Scalars['String']
  categoryId: Scalars['Int']
}

export type MutationDeleteCommunityTagArgs = {
  clientName: Scalars['String']
  tagId: Scalars['Int']
}

export type MutationDeletePortalCommunityTagArgs = {
  clientName: Scalars['String']
  tagId: Scalars['Int']
}

export type MutationDeleteBuilderTagArgs = {
  tagId: Scalars['Int']
}

export type MutationDeleteMetroArgs = {
  id: Scalars['Int']
}

export type MutationDeleteCityArgs = {
  id: Scalars['Int']
}

export type MutationDuplicatePaletteArgs = {
  clientName: Scalars['String']
  input: AddPaletteInput
}

export type MutationDuplicateSchemeArgs = {
  clientName: Scalars['String']
  name: Scalars['String']
  schemeId: Scalars['Int']
}

export type MutationExportElevationsArgs = {
  clientName: Scalars['String']
  tgtCommunityIds?: Maybe<Array<Scalars['Int']>>
  tgtPlanId: Scalars['Int']
  srcCommunityId: Scalars['Int']
  srcPlanId: Scalars['Int']
  srcElevationIds: Array<Scalars['Int']>
}

export type MutationImportCommunityDataArgs = {
  clientName: Scalars['String']
  srcCommunityId: Scalars['Int']
  tgtCommunityId: Scalars['Int']
  dataTypes: Array<Scalars['String']>
}

export type MutationImportElevationsArgs = {
  clientName: Scalars['String']
  tgtCommunityId: Scalars['Int']
  tgtPlanId: Scalars['Int']
  communityId: Scalars['Int']
  planId: Scalars['Int']
  elevationIds: Array<Scalars['Int']>
}

export type MutationImportPlanArgs = {
  clientName: Scalars['String']
  tgtCommunityId: Scalars['Int']
  communityId: Scalars['Int']
  planId: Scalars['Int']
  elevationIds: Array<Scalars['Int']>
}

export type MutationIndexFloorplansArgs = {
  clientName: Scalars['String']
  floorplans: Array<FloorplanInput>
  indexType: Scalars['String']
}

export type MutationIndexStdFeaturesArgs = {
  clientName: Scalars['String']
  stdFeatures: Array<Scalars['Int']>
}

export type MutationIndexStdFeatureCategoriesArgs = {
  clientName: Scalars['String']
  stdFeatureCategories: Array<Scalars['Int']>
}

export type MutationRemoveColorArgs = {
  colorId: Scalars['Int']
}

export type MutationRemoveCommunitySchemeAssignmentsArgs = {
  clientName: Scalars['String']
  assignments: Array<CommunitySchemeAssignment>
}

export type MutationRemoveElevationArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
  planId: Scalars['Int']
  elevationId: Scalars['Int']
}

export type MutationRemoveElevationSchemeAssignmentsArgs = {
  clientName: Scalars['String']
  assignments: Array<CommunityElevationSchemeAssignment>
}

export type MutationRemoveFromExcludedPlanElevationsArgs = {
  clientName: Scalars['String']
  lotId: Scalars['Int']
  planElevations: Array<PlanElevationInput>
}

export type MutationRemoveLotsFromExcludedPlanElevationsArgs = {
  clientName: Scalars['String']
  lotIds: Array<Scalars['Int']>
  planElevation: PlanElevationInput
}

export type MutationRemoveFromExcludedSchemesArgs = {
  clientName: Scalars['String']
  lotId: Scalars['Int']
  schemes: Array<SchemeInput>
}

export type MutationRemoveFromIncludedFloorplansArgs = {
  clientName: Scalars['String']
  inventoryId: Scalars['Int']
  floorplans: Array<FloorplanInput>
}

export type MutationRemoveHomeStyleCategoryAssignmentArgs = {
  categoryId: Scalars['Int']
  homestyleIds: Array<Scalars['Int']>
}

export type MutationRemoveLibraryVendorArgs = {
  library: LibraryType
  vendorId: Scalars['Int']
}

export type MutationRemoveOverlayArgs = {
  clientName: Scalars['String']
  customOverlayId: Scalars['Int']
}

export type MutationRemovePaletteAssignmentsArgs = {
  clientName: Scalars['String']
  assignments: Array<CommunityPaletteAssignment>
}

export type MutationRemovePlanArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
  planId: Scalars['Int']
}

export type MutationRemoveStandardFeatureAssignmentArgs = {
  clientName: Scalars['String']
  standardFeatureAssignmentInput?: Maybe<Array<StandardFeatureAssignmentInput>>
}

export type MutationRemoveCommunityTagAssignmentArgs = {
  clientName: Scalars['String']
  communityTagAssignmentInput?: Maybe<Array<CommunityTagAssignmentInput>>
}

export type MutationRemovePortalCommunityTagAssignmentArgs = {
  clientName: Scalars['String']
  portalCommunityTagAssignmentInput?: Maybe<
    Array<PortalCommunityTagAssignmentInput>
  >
}

export type MutationRemoveBuilderTagAssignmentArgs = {
  clientName: Scalars['String']
  builderTagAssignmentInput?: Maybe<Array<BuilderTagAssignmentInput>>
}

export type MutationRemoveTextureArgs = {
  library: TextureLibraryType
  id: Scalars['Int']
}

export type MutationReorderSiteplanHotspotAssetsArgs = {
  clientName: Scalars['String']
  siteplanHotspotId: Scalars['Int']
  oldIndex: Scalars['Int']
  newIndex: Scalars['Int']
}

export type MutationSetFloorplanAsOptionalArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  floorplanId?: Maybe<Scalars['Int']>
  assignments: Array<CommunityFloorplanAssignmentInput>
}

export type MutationSetFloorplanAsStandardArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  floorplanId?: Maybe<Scalars['Int']>
  assignments: Array<CommunityFloorplanAssignmentInput>
}

export type MutationSetInventoryPaletteSelectionsArgs = {
  clientName: Scalars['String']
  inventoryId: Scalars['Int']
  assignments: Array<PaletteElementSelectionInput>
}

export type MutationUnassignFloorplanFromPlanArgs = {
  clientName: Scalars['String']
  planId: Scalars['Int']
  floorplanId: Scalars['Int']
}

export type MutationUnassignFloorplansArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  floorplanId?: Maybe<Scalars['Int']>
  assignments: Array<CommunityFloorplanAssignmentInput>
}

export type MutationUnassignFpGroupsArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  groupId?: Maybe<Scalars['Int']>
  assignments: Array<CommunityFloorplanAssignmentInput>
}

export type MutationUnassignPortalFloorplanTagArgs = {
  clientName: Scalars['String']
  floorplanId: Scalars['Int']
  portalFloorplanTagId: Scalars['Int']
}

export type MutationUpdateAgentArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  agent: UpdateAgentInput
}

export type MutationUpdateAgentOrderArgs = {
  clientName: Scalars['String']
  agents: Array<UpdateAgentInput>
}

export type MutationUpdateBuilderAppConfigArgs = {
  clientName?: Maybe<Scalars['String']>
  builderAppConfig?: Maybe<Scalars['JSON']>
}

export type MutationUpdateCollectionArgs = {
  clientName: Scalars['String']
  collectionId: Scalars['Int']
  name: Scalars['String']
}

export type MutationUpdateColorArgs = {
  input?: Maybe<UpdateColorInput>
}

export type MutationUpdateCommunityArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
  community: UpdateCommunityInput
}

export type MutationUpdateCommunityInfoArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
  communityInfo: UpdateCommunityInfoInput
}

export type MutationUpdateCommunityGalleryNameArgs = {
  clientName: Scalars['String']
  galleryId: Scalars['Int']
  name: Scalars['String']
}

export type MutationUpdateCommunityGalleryPhotoCaptionArgs = {
  clientName: Scalars['String']
  galleryPhotoId: Scalars['Int']
  caption: Scalars['String']
}

export type MutationUpdateCommunityGalleryPhotoSortArgs = {
  clientName: Scalars['String']
  galleryId: Scalars['Int']
  oldIndex: Scalars['Int']
  newIndex: Scalars['Int']
}

export type MutationUpdateCommunityHighlightNameArgs = {
  clientName: Scalars['String']
  highlightId: Scalars['Int']
  name: Scalars['String']
}

export type MutationUpdateCommunityHighlightPhotoSortArgs = {
  clientName: Scalars['String']
  highlightId: Scalars['Int']
  oldIndex: Scalars['Int']
  newIndex: Scalars['Int']
}

export type MutationUpdateCommunityMainPhotoSortArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
  oldIndex: Scalars['Int']
  newIndex: Scalars['Int']
}

export type MutationUpdateCommunityPhotoSortArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
  oldIndex: Scalars['Int']
  newIndex: Scalars['Int']
}

export type MutationUpdateCommunityPoiArgs = {
  clientName: Scalars['String']
  poi: UpdateCommunityPoiInput
}

export type MutationUpdateCommunityPoiCategoryArgs = {
  clientName: Scalars['String']
  poiCategory: UpdateCommunityPoiCategoryInput
}

export type MutationUpdateCustomCityNameArgs = {
  clientName: Scalars['String']
  cityId: Scalars['Int']
  customName?: Maybe<Scalars['String']>
}

export type MutationUpdateCustomCityPhotoArgs = {
  clientName: Scalars['String']
  cityId: Scalars['Int']
  src?: Maybe<Scalars['String']>
}

export type MutationUpdateCustomMetroNameArgs = {
  clientName: Scalars['String']
  metroId: Scalars['Int']
  customName?: Maybe<Scalars['String']>
}

export type MutationUpdateCustomMetroPhotoArgs = {
  clientName: Scalars['String']
  metroId: Scalars['Int']
  src?: Maybe<Scalars['String']>
}

export type MutationUpdateElevationArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
  elevationId: Scalars['Int']
  elevation: UpdateElevationInput
}

export type MutationUpdateElevationsArgs = {
  clientName: Scalars['String']
  planId: Scalars['Int']
  elevations: Array<UpdateElevationsInput>
}

export type MutationUpdateElevationElementArgs = {
  clientName: Scalars['String']
  elementId: Scalars['Int']
  elevationId: Scalars['Int']
  src: Scalars['String']
  lastUpdateTime?: Maybe<Scalars['Timestamp']>
}

export type MutationUpdateElevationHomeCategoriesArgs = {
  clientName: Scalars['String']
  elevationId: Scalars['Int']
  categoryIds?: Maybe<Array<Scalars['Int']>>
}

export type MutationUpdateElevationHomeStylesArgs = {
  clientName: Scalars['String']
  elevationId: Scalars['Int']
  styleIds?: Maybe<Array<Scalars['Int']>>
}

export type MutationUpdateFloorplanArgs = {
  clientName: Scalars['String']
  input: UpdateFloorplanInput
  communityId?: Maybe<Scalars['Int']>
}

export type MutationUpdateCommunityFloorplanExternalIdsArgs = {
  clientName: Scalars['String']
  input: Array<UpdateCommunityFloorplanExternalIdInput>
}

export type MutationUpdateFloorplanAlternateArgs = {
  clientName: Scalars['String']
  floorplanAlternate: FloorplanAlternateInput
}

export type MutationUpdateFloorplanTagArgs = {
  clientName: Scalars['String']
  tag: FloorplanTagInput
}

export type MutationUpdateFloorplanTagCategoryArgs = {
  oldCategoryId: Scalars['Int']
  newCategoryId: Scalars['Int']
  floorplanTagId: Scalars['Int']
}

export type MutationUpdatePortalFloorplanTagCategoryArgs = {
  oldCategoryId: Scalars['Int']
  newCategoryId: Scalars['Int']
  portalFloorplanTagId: Scalars['Int']
}

export type MutationUpdateFpGroupArgs = {
  clientName: Scalars['String']
  planId: Scalars['Int']
  fpGroupId: Scalars['Int']
  name?: Maybe<Scalars['String']>
  groupType: FloorplanGroupType
  members?: Maybe<Array<FloorplanGroupMemberInput>>
  communityId?: Maybe<Scalars['Int']>
  elevationIds: Array<Scalars['Int']>
  autoselectLinkedChildParents?: Maybe<Scalars['Boolean']>
}

export type MutationUpdateHomeCategoryArgs = {
  categoryId: Scalars['Int']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
}

export type MutationUpdateHomeCategoryPhotoSortArgs = {
  categoryId: Scalars['Int']
  oldIndex: Scalars['Int']
  newIndex: Scalars['Int']
}

export type MutationUpdateHomeStylePhotoSortArgs = {
  input?: Maybe<UpdateHomeStylePhotoSortInput>
}

export type MutationUpdateHomeCategoryPhotoStyleArgs = {
  id: Scalars['Int']
  newStyleId: Scalars['Int']
}

export type MutationUpdateInteriorArgs = {
  clientName: Scalars['String']
  interior: UpdateInteriorInput
}

export type MutationUpdateInventoryHomeCategoriesArgs = {
  clientName: Scalars['String']
  inventoryId: Scalars['Int']
  categoryIds?: Maybe<Array<Scalars['Int']>>
}

export type MutationUpdateInventoryHomeStylesArgs = {
  clientName: Scalars['String']
  inventoryId: Scalars['Int']
  styleIds?: Maybe<Array<Scalars['Int']>>
}

export type MutationUpdateInteriorElementOptionArgs = {
  clientName: Scalars['String']
  element: UpdateInteriorElementOptionInput
}

export type MutationUpdateInventoryArgs = {
  clientName: Scalars['String']
  inventory: UpdateInventoryInput
}

export type MutationUpdateInventoryPhotoOrderArgs = {
  clientName: Scalars['String']
  inventoryId: Scalars['Int']
  oldIndex: Scalars['Int']
  newIndex: Scalars['Int']
}

export type MutationUpdateInventorySgtDataArgs = {
  clientName: Scalars['String']
  inventoryId: Scalars['Int']
  inventorySgtData: UpdateInventorySgtDataInput
}

export type MutationUpdateLibraryVendorArgs = {
  input?: Maybe<UpdateLibraryVendorInput>
}

export type MutationUpdateLotArgs = {
  clientName: Scalars['String']
  lot: UpdateLotInput
}

export type MutationUpdateLotPositionArgs = {
  clientName: Scalars['String']
  input: UpdateLotPositionInput
}

export type MutationUpdateLotLegendEntryArgs = {
  clientName: Scalars['String']
  lotLegendEntry: UpdateLotLegendEntryInput
}

export type MutationUpdateLotLegendEntryOrderArgs = {
  clientName: Scalars['String']
  siteplanId: Scalars['Int']
  lotLegendEntryList: Array<UpdateLotLegendEntryInput>
}

export type MutationImportLotLegendEntriesArgs = {
  clientName: Scalars['String']
  sourceSiteplanId: Scalars['Int']
  targetSiteplanId: Scalars['Int']
}

export type MutationUpdateMasterCommunityArgs = {
  clientName: Scalars['String']
  masterCommunityId: Scalars['Int']
  masterCommunity: UpdateMasterCommunityInput
}

export type MutationUpdateMasterSalesAppConfigArgs = {
  clientName: Scalars['String']
  masterCommunityId: Scalars['Int']
  config: Scalars['String']
}

export type MutationUpdateMaterialArgs = {
  clientName: Scalars['String']
  materialId: Scalars['Int']
  displayName?: Maybe<Scalars['String']>
}

export type MutationUpdateMaterialCategoryArgs = {
  clientName: Scalars['String']
  materialId: Scalars['Int']
  materialCategory?: Maybe<MaterialCategory>
}

export type MutationUpdateMlsServiceArgs = {
  mlsName: Scalars['String']
  clientId: Scalars['Int']
  enableService: Scalars['Boolean']
}

export type MutationUpdateOverlayArgs = {
  clientName: Scalars['String']
  customOverlayId: Scalars['Int']
  color?: Maybe<Scalars['String']>
  src?: Maybe<Scalars['String']>
}

export type MutationUpdatePaletteArgs = {
  clientName: Scalars['String']
  paletteId: Scalars['Int']
  name?: Maybe<Scalars['String']>
  selections?: Maybe<Array<PaletteSelectionInput>>
  internalName?: Maybe<Scalars['String']>
}

export type MutationUpdatePlanArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  planId: Scalars['Int']
  plan: UpdatePlanInput
}

export type MutationUpdatePlanCollectionArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
  planId: Scalars['Int']
  elevationId?: Maybe<Scalars['Int']>
  collectionId?: Maybe<Scalars['Int']>
}

export type MutationUpdatePlanPhotoSortArgs = {
  clientName: Scalars['String']
  planId: Scalars['Int']
  oldIndex: Scalars['Int']
  newIndex: Scalars['Int']
  photoType: PhotoType
}

export type MutationUpdateSchemeArgs = {
  clientName: Scalars['String']
  schemeId: Scalars['Int']
  scheme: UpdateSchemeInput
  schemeElements: Array<SchemeElementInput>
  communityIds?: Maybe<Array<Scalars['Int']>>
}

export type MutationUpdateSgtVendorArgs = {
  sgtVendor?: Maybe<UpdateSgtVendorInput>
}

export type MutationUpdateSiteplanArgs = {
  clientName: Scalars['String']
  siteplan: UpdateSiteplanInput
}

export type MutationUpdateSiteplanHotspotArgs = {
  clientName: Scalars['String']
  siteplanHotspotId: Scalars['Int']
  input: SiteplanHotspotInput
}

export type MutationUpdateSiteplanHotspotAssetArgs = {
  clientName: Scalars['String']
  siteplanHotspotAssetId: Scalars['Int']
  input: SiteplanHotspotAssetInput
}

export type MutationUpdateSiteplanInfoArgs = {
  clientName: Scalars['String']
  siteplanId: Scalars['Int']
  siteplanInfo: SiteplanInfoInput
}

export type MutationUpdateStandardFeatureCategoryArgs = {
  clientName: Scalars['String']
  name: Scalars['String']
  categoryId: Scalars['Int']
}

export type MutationUpdateCommunityTagCategoryArgs = {
  clientName: Scalars['String']
  name: Scalars['String']
  categoryId: Scalars['Int']
}

export type MutationUpdatePortalCommunityTagCategoryArgs = {
  clientName: Scalars['String']
  name: Scalars['String']
  categoryId: Scalars['Int']
}

export type MutationUpdateBuilderTagCategoryArgs = {
  name: Scalars['String']
  categoryId: Scalars['Int']
}

export type MutationUpdateStandardFeatureArgs = {
  clientName: Scalars['String']
  name: Scalars['String']
  id: Scalars['Int']
}

export type MutationUpdateCommunityTagArgs = {
  clientName: Scalars['String']
  name: Scalars['String']
  id: Scalars['Int']
}

export type MutationUpdatePortalCommunityTagArgs = {
  clientName: Scalars['String']
  name: Scalars['String']
  id: Scalars['Int']
}

export type MutationUpdateBuilderTagArgs = {
  name: Scalars['String']
  id: Scalars['Int']
}

export type MutationUpdateTextureArgs = {
  input?: Maybe<UpdateTextureInput>
}

export type MutationTriggerColonnadeReloadForClientProspectsArgs = {
  clientId: Scalars['Int']
}

export type MutationUpdateElevationThumbnailArgs = {
  clientName: Scalars['String']
  elevationId: Scalars['Int']
  fileName: Scalars['String']
}

export type MutationUpsertPmFloorplanArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
  pmFloorplan: PmFloorplanInput
}

export type MutationUpdateMetroArgs = {
  input: UpdateMetroInput
}

export type MutationUpdateCityArgs = {
  input: UpdateCityInput
  metroId?: Maybe<Scalars['Int']>
}

export type MutationRecalculateAllElevationsLayersRenderOrderArgs = {
  clientName: Scalars['String']
}

export type MutationSetCustomizationArgs = {
  clientName: Scalars['String']
  name: Scalars['String']
  value: Scalars['String']
}

export type MutationUpdateClientInfoArgs = {
  clientName: Scalars['String']
  name: Scalars['String']
  value?: Maybe<Scalars['String']>
  boolValue?: Maybe<Scalars['Boolean']>
}

export type MutationDeleteClientArgs = {
  clientName: Scalars['String']
}

export type MutationAddProspectBrochureArgs = {
  clientName: Scalars['String']
  email?: Maybe<Scalars['String']>
  brochureUrl: Scalars['String']
  brochureUUID: Scalars['UUID']
}

export type MutationRejectProspectBrochureArgs = {
  brochureUUID: Scalars['UUID']
  error: Scalars['String']
}

export type MutationUpdateHomesiteByLotExtIdArgs = {
  clientName: Scalars['String']
  input: InventoryLotUpdateInput
}

export type MutationUpdateHomesitesByLotExtIdArgs = {
  clientName: Scalars['String']
  input: Array<InventoryLotUpdateInput>
}

export type MutationUpdateCommunityPlanPricingArgs = {
  clientName: Scalars['String']
  input: Array<CommunityPlanPricingInput>
}

export type MutationUpdateCommunityPlansByExternalOrInternalIdsArgs = {
  clientName: Scalars['String']
  updates: Array<CommunityPlanUpdateInput>
}

export type MutationUpdatePlansByExternalIdOrPlanIdArgs = {
  clientName: Scalars['String']
  updates: Array<PlanUpdateInput>
}

export type MutationBatchUpdateElevationsArgs = {
  clientName: Scalars['String']
  batchElevationInputs: Array<BatchUpdateElevationsInput>
}

export type MutationBatchUpdateFloorplansArgs = {
  clientName: Scalars['String']
  updateFloorplanInputs: Array<UpdateFloorplanInput>
}

export type MutationCreateNewReservationArgs = {
  clientName: Scalars['String']
  favoriteId: Scalars['Int']
  buyer: PrimaryBuyerInput
  additionalBuyer?: Maybe<AdditionalBuyerInput>
  totalPrice: Scalars['Int']
}

export type MutationSignReservationDocumentArgs = {
  clientName: Scalars['String']
  prospectUpdatedData: ProspectUpdatedDataInput
  reservationId: Scalars['UUID']
  totalPrice: Scalars['Int']
  brochureLink?: Maybe<Scalars['URL']>
}

export type MutationAcceptReservationTermsAndConditionsArgs = {
  clientName: Scalars['String']
  reservationId: Scalars['UUID']
}

export type MutationAddAdditionalBuyerForReservationArgs = {
  clientName: Scalars['String']
  token: Scalars['JWT']
}

export type MutationCreateReservationPaymentUrlArgs = {
  clientName: Scalars['String']
  reservationId: Scalars['UUID']
  exteriorImageUrl: Scalars['URL']
}

export type MutationRejectOnlineReservationByProspectArgs = {
  clientName: Scalars['String']
  reservationId: Scalars['UUID']
  declinedReason?: Maybe<Scalars['String']>
}

export type MutationAddAgentProspectArgs = {
  prospectEmail: Scalars['String']
  agent: UpsertAgentInput
  clientName: Scalars['String']
  source: Scalars['String']
}

export type MutationAddFavoritesForProspectArgs = {
  clientName: Scalars['String']
  email: Scalars['String']
  favorites: Array<ProspectFavoriteInput>
}

export type MutationAddHomeSelectionArgs = {
  clientName: Scalars['String']
  input: HomeSelectionInput
}

export type MutationUpsertProspectArgs = {
  clientName: Scalars['String']
  input: ProspectInput
}

export type MutationAddProspectReceivedLinkArgs = {
  senderProspectId: Scalars['Int']
  receiverProspectId: Scalars['Int']
  clientId: Scalars['Int']
}

export type MutationDeleteProspectReceivedLinkArgs = {
  senderProspectId: Scalars['Int']
  receiverProspectId: Scalars['Int']
  clientId: Scalars['Int']
}

export type MutationDeleteFavoriteForProspectArgs = {
  clientName: Scalars['String']
  id: Scalars['Int']
}

export type MutationDeleteFavoriteForProspectByEmailArgs = {
  clientName: Scalars['String']
  id: Scalars['Int']
  prospectEmail: Scalars['String']
}

export type MutationReplaceFavoritesForProspectArgs = {
  clientName: Scalars['String']
  email: Scalars['String']
  favorites: Array<ProspectFavoriteInput>
}

export type MutationRequestSgtAppointmentArgs = {
  clientName: Scalars['String']
  prospectSgtApptInput: ProspectSgtApptInput
  timezoneOffset: Scalars['Int']
}

export type MutationSendInquiryToBuilderArgs = {
  clientName: Scalars['String']
  input: InquiryInput
}

export type MutationSendProspectRegistrationEmailArgs = {
  clientName: Scalars['String']
  prospectId: Scalars['Int']
  source: Scalars['String']
  input?: Maybe<ProspectInput>
}

export type MutationSendShareFavoritesEmailArgs = {
  prospectName: Scalars['String']
  prospectEmail: Scalars['String']
  friendsEmails: Array<Scalars['String']>
  agentsEmails?: Maybe<Array<Scalars['String']>>
  notes?: Maybe<Scalars['String']>
  preferredContactMethod?: Maybe<Array<ContactMethodType>>
  clientName: Scalars['String']
  linkAddress: Scalars['String']
  clientLogoSrc?: Maybe<Scalars['String']>
}

export type MutationSendWelcomeEmailArgs = {
  clientName: Scalars['String']
  prospectId: Scalars['Int']
  communityId?: Maybe<Scalars['Int']>
  agentEmail?: Maybe<Scalars['String']>
  myHomeAppLink: Scalars['String']
}

export type MutationUpdateFavoritesForProspectArgs = {
  clientName: Scalars['String']
  input: Array<ProspectFavoriteInput>
}

export type MutationUpdateProspectContactInfosArgs = {
  id: Scalars['Int']
  phone: Scalars['String']
  preferredContactMethods: Array<ContactMethodType>
  name?: Maybe<Scalars['String']>
}

export type MutationUpdateProspectPictureArgs = {
  prospectEmail: Scalars['String']
  fileName: Scalars['String']
}

export type MutationUpdateEmailCampaignLeadSubscriptionStatusArgs = {
  clientName: Scalars['String']
  encryptedProspectId: Scalars['String']
  iv: Scalars['String']
  status: Scalars['Boolean']
}

export type MutationColonnadeApiBatchAddAgentProspectArgs = {
  prospectEmail: Scalars['String']
  agentIds: Array<Scalars['Int']>
  clientName: Scalars['String']
  source: Scalars['String']
}

export type MutationDeleteOnlineReservationArgs = {
  clientName: Scalars['String']
  reservationId: Scalars['UUID']
}

export type MutationRestoreDeletedOnlineReservationArgs = {
  clientName: Scalars['String']
  reservationId: Scalars['UUID']
}

export type MutationCreateReservationIntegrationArgs = {
  clientId: Scalars['Int']
}

export type MutationRejectOnlineReservationArgs = {
  clientName: Scalars['String']
  reservationId: Scalars['UUID']
  rejectReason?: Maybe<Scalars['String']>
}

export type MutationCreateStripeAccountArgs = {
  clientId: Scalars['Int']
}

export type MutationAddProspectActivitiesArgs = {
  clientName: Scalars['String']
  email: Scalars['String']
  activities: Scalars['JSON']
}

export type MutationSendRecommendedPlansEmailArgs = {
  prospectName: Scalars['String']
  prospectEmail: Scalars['String']
  agentEmail: Scalars['String']
  agentName: Scalars['String']
  clientName: Scalars['String']
  plans: Array<RecommendedPlan>
}

export type MutationSendInsightsOnlineReservationInquiryArgs = {
  clientName: Scalars['String']
  userEmail: Scalars['EmailAddress']
  userPhone?: Maybe<Scalars['String']>
}

export type MutationSendInsightsMarketingAutomationInquiryArgs = {
  clientName: Scalars['String']
  userEmail: Scalars['EmailAddress']
  userPhone?: Maybe<Scalars['String']>
}

export type MutationSetNewLeadAgentNotificationConfigArgs = {
  clientName: Scalars['String']
  enabled: Scalars['Boolean']
}

export type MutationSetLeadBrowseAgentNotificationConfigArgs = {
  clientName: Scalars['String']
  enabled: Scalars['Boolean']
  frequency: Scalars['Int']
}

export type MutationSetProspectClientRatingArgs = {
  clientName: Scalars['String']
  prospectEmail: Scalars['String']
  clientRating: Scalars['String']
}

export type MutationSetSubscriptionEndpointArgs = {
  clientName: Scalars['String']
  endpoint: Scalars['String']
}

export type MutationTestSubscriptionEndpointArgs = {
  clientName: Scalars['String']
  subscriptionLevel?: Maybe<Scalars['String']>
}

export type MutationUpdateProspectArgs = {
  clientName: Scalars['String']
  prospect: UpdateProspectInput
}

export type MutationUpdateReservationIntegrationInfoArgs = {
  clientId: Scalars['Int']
  input: UpdateOnlineReservationIntegrationInput
}

export type MutationCreateEmailCampaignArgs = {
  clientName: Scalars['String']
  emailCampaign: EmailCampaignInput
  cohort?: Maybe<Array<Scalars['Int']>>
}

export type MutationUpdateEmailCampaignArgs = {
  clientName: Scalars['String']
  emailCampaignId: Scalars['Int']
  emailCampaign?: Maybe<EmailCampaignInput>
  cohort?: Maybe<Array<Scalars['Int']>>
  sharing?: Maybe<Array<Scalars['Int']>>
}

export type MutationDeleteEmailCampaignArgs = {
  clientName: Scalars['String']
  emailCampaignId: Scalars['Int']
}

export type MutationDeleteEmailCampaignSectionImageArgs = {
  clientName: Scalars['String']
  emailCampaignSectionImageId: Scalars['Int']
}

export type MutationSendEmailCampaignArgs = {
  clientName: Scalars['String']
  emailCampaignId: Scalars['Int']
  ownerId: Scalars['Int']
  testEmailAddress?: Maybe<Scalars['String']>
}

export type MutationSendFollowUpEmailArgs = {
  clientName: Scalars['String']
  emailCampaignId: Scalars['Int']
  ownerId: Scalars['Int']
  emails?: Maybe<Array<Scalars['String']>>
  content?: Maybe<Scalars['String']>
}

export type MutationToggleCommunityBuyOnlineEnabledArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
  enabled: Scalars['Boolean']
}

export type MutationUpdateEmailCampaignLeadStatusArgs = {
  clientName: Scalars['String']
  emailCampaignId: Scalars['Int']
  prospectId: Scalars['Int']
  status: EmailCampaignCohortStatus
}

export type MutationToggleBuyOnlineEnabledArgs = {
  clientId: Scalars['Int']
  enabled: Scalars['Boolean']
}

export type MutationToggleAllowMultipleReservationsArgs = {
  clientId: Scalars['Int']
  enabled: Scalars['Boolean']
}

export type MutationUpdateReservationCustomTemplateFieldsArgs = {
  clientId: Scalars['Int']
  templateId: Scalars['String']
}

export type MutationAddOrUpdateCommunityBuyOnlineCustomConfigTemplateValuesArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
  configValues: Scalars['JSON']
}

export type MutationLockLotSignArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
  lotId: Scalars['Int']
  prospectEmail: Scalars['String']
}

export type MutationUnlockLotSignArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
  lotId: Scalars['Int']
  prospectEmail: Scalars['String']
}

export type MutationAssignProspectToColonnadeAgentArgs = {
  clientName: Scalars['String']
  assignments: Array<ColonnadeProspectAssignment>
}

export type MutationUnassignProspectFromColonnadeAgentArgs = {
  clientName: Scalars['String']
  unassignments: Array<ColonnadeProspectAssignment>
}

export type MutationImportProspectsToColonnadeAgentsArgs = {
  clientName: Scalars['String']
  assignments: Array<ColonnadeAgentImport>
}

export type MutationUpdateColonnadeSubscriptionDateArgs = {
  newSubscriptionDate: Scalars['Date']
  clientId: Scalars['Int']
}

export type MutationUpdateUserClientRoleArgs = {
  clientName: Scalars['String']
  input: UpdateUserClientRoleInput
}

export type MutationRequestColonnadeLicenseUpgradeArgs = {
  clientName: Scalars['String']
  numberOfRequestedLicense: Scalars['Int']
  requesterEmail: Scalars['String']
}

export type MutationUpdateRoleAppConfigArgs = {
  clientName: Scalars['String']
  roleId: Scalars['Int']
  propertiesArray?: Maybe<Array<Scalars['String']>>
}

export type MutationAddFlagshipRoleAppConfigArgs = {
  clientId: Scalars['Int']
}

export type MutationGenerateClientApiKeyArgs = {
  input: GenerateClientApiKeyInput
}

export type MutationGenerateAppApiKeyArgs = {
  input: GenerateAppApiKeyInput
}

export type MutationDeleteClientApiKeyArgs = {
  apiKey: Scalars['String']
}

export type MutationDeleteAppApiKeyArgs = {
  apiKey: Scalars['String']
  originIds?: Maybe<Array<Maybe<Scalars['Int']>>>
}

export type MutationUpdateOriginsArgs = {
  apiKey: Scalars['String']
  origins?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type MutationAddColonnadeUserArgs = {
  clientName: Scalars['String']
  input: AddColonnadeUserInput
}

export type MutationImportAgentsAsColonnadeUsersArgs = {
  clientName: Scalars['String']
  input: Array<ImportAgentInput>
}

export type MutationAddUserArgs = {
  clientName: Scalars['String']
  input: AddUserInput
}

export type MutationUpdateUserArgs = {
  input: UpdateUserInput
}

export type MutationAddOrUpdateClientSubscriptionArgs = {
  clientId: Scalars['Int']
  input: ClientSubscriptionInput
}

export type MutationAddOrUpdateClientCommunitySubscriptionArgs = {
  clientId: Scalars['Int']
  input: ClientCommunitySubscriptionInput
}

export type MutationAddColonnadeClientEmailReportInfoArgs = {
  clientId: Scalars['Int']
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  email: Scalars['String']
  frequency: ColonnadeReportFrequencyEnum
}

export type MutationUpdateColonnadeClientEmailReportInfoArgs = {
  reportId: Scalars['Int']
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  email: Scalars['String']
  frequency: ColonnadeReportFrequencyEnum
}

export type MutationDeleteColonnadeClientEmailReportInfoArgs = {
  reportId: Scalars['Int']
}

export type MutationDeleteClientCommunitySubscriptionArgs = {
  clientId: Scalars['Int']
  communityId: Scalars['Int']
}

export type MutationDeleteColonnadeUserArgs = {
  callerUserId: Scalars['Int']
  userId: Scalars['Int']
}

export type MutationRequestSubscriptionUpgradeArgs = {
  callerUserId: Scalars['Int']
  requestedSubscriptionLevel: ColonnadeSubscriptionLevelsEnum
}

export type MutationSetColonnadeClientSubsLevelArgs = {
  clientId: Scalars['Int']
  subscriptionLevel: ColonnadeSubscriptionLevelsEnum
}

export type MutationSetColonnadeClientLicensesArgs = {
  clientId: Scalars['Int']
  numberOfLicenses: Scalars['Int']
}

export type MutationSetColSubsAnnivNotifArgs = {
  clientId: Scalars['Int']
  statement: Scalars['Boolean']
}

export type MutationSetRolePermissionArgs = {
  roleName: ColonnadeRolesEnum
  privilege: Array<ColonnadeRolePrivilegesEnum>
}

export type MutationUpdateColonnadeUserArgs = {
  callerUserId: Scalars['Int']
  userId: Scalars['Int']
  input: UpdateColonnadeUserInput
}

export type MutationDeleteUserArgs = {
  userId: Scalars['Int']
}

export type MutationAddColonnadeSubscriptionClientArgs = {
  clientId: Scalars['Int']
}

export type MutationDeleteColonnadeSubscriptionClientArgs = {
  clientId: Scalars['Int']
}

export type MutationUpsertAssignDateAgentProspectArgs = {
  agentEmails: Array<Scalars['String']>
}

export type MutationSendForgotEmailNotifToAdminArgs = {
  firstName: Scalars['String']
  lastName: Scalars['String']
  backUpEmail: Scalars['String']
}

export type MutationSendForgotEmailNotifRetrieveArgs = {
  firstName: Scalars['String']
  lastName: Scalars['String']
  backUpEmail: Scalars['String']
  clientName: Scalars['String']
  email: Scalars['String']
}

export type MutationSendPasswordResetEmailArgs = {
  email: Scalars['String']
  source?: Maybe<Scalars['String']>
}

export type MutationSetPasswordArgs = {
  email: Scalars['String']
  password: Scalars['String']
  clientName: Scalars['String']
  source?: Maybe<Scalars['String']>
}

export type MutationAddExcludedIpArgs = {
  clientName: Scalars['String']
  ips: Array<Maybe<Scalars['String']>>
  ipCidrNotation: Scalars['String']
}

export type MutationRemoveExcludedIpArgs = {
  clientName: Scalars['String']
  ipCidrNotation: Scalars['String']
}

export type NexusAgent = {
  __typename?: 'NexusAgent'
  id: Scalars['Int']
  firstName: Scalars['String']
  lastName: Scalars['String']
  email: Scalars['String']
  phone?: Maybe<Scalars['String']>
  picture?: Maybe<Scalars['String']>
  listIndex?: Maybe<Scalars['Int']>
  communityId?: Maybe<Scalars['Int']>
}

export type NexusAgentProspect = {
  __typename?: 'NexusAgentProspect'
  agentId: Scalars['Int']
  prospectId: Scalars['Int']
  agentName: Scalars['String']
  prospectName: Scalars['String']
  agentEmail: Scalars['String']
  prospectEmail: Scalars['String']
  prospectPhone?: Maybe<Scalars['String']>
  clientName?: Maybe<Scalars['String']>
}

export type NexusCommunity = {
  __typename?: 'NexusCommunity'
  id: Scalars['Int']
  clientName: Scalars['String']
  active?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  pricing?: Maybe<Scalars['Boolean']>
  agents?: Maybe<Array<Maybe<NexusAgent>>>
}

export type NexusCommunityAgentsArgs = {
  clientName: Scalars['String']
}

export enum NexusCommunityType {
  Default = 'DEFAULT',
  PropertyManagement = 'PROPERTY_MANAGEMENT',
}

export enum NexusContactMethodType {
  Email = 'EMAIL',
  Phone = 'PHONE',
  Text = 'TEXT',
  VideoConference = 'VIDEO_CONFERENCE',
}

export enum NexusCountry {
  Us = 'US',
  Can = 'CAN',
}

export enum NexusCurrency {
  Us = 'US',
  Can = 'CAN',
}

export type NexusElevation = {
  __typename?: 'NexusElevation'
  id: Scalars['Int']
  clientName: Scalars['String']
  active?: Maybe<Scalars['Boolean']>
  communityId?: Maybe<Scalars['Int']>
  planId: Scalars['Int']
  planName?: Maybe<Scalars['String']>
  planDisplayName?: Maybe<Scalars['String']>
  caption?: Maybe<Scalars['String']>
  thumb?: Maybe<Scalars['String']>
  bed?: Maybe<Scalars['Int']>
  bath?: Maybe<Scalars['Float']>
  size?: Maybe<Scalars['Int']>
  cost?: Maybe<Scalars['Int']>
  svgMirroring?: Maybe<Scalars['Boolean']>
  garagePosition?: Maybe<NexusGaragePosition>
  defaultGaragePosition?: Maybe<NexusGaragePosition>
}

export enum NexusGaragePosition {
  Left = 'LEFT',
  Right = 'RIGHT',
}

export type NexusInventory = {
  __typename?: 'NexusInventory'
  id: Scalars['Int']
  clientName: Scalars['String']
  lotId: Scalars['Int']
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  communityId?: Maybe<Scalars['Int']>
  photoFolder?: Maybe<Scalars['String']>
  photos?: Maybe<Array<NexusInventoryPhoto>>
  price?: Maybe<Scalars['Int']>
  sqft?: Maybe<Scalars['Int']>
  beds?: Maybe<Scalars['Int']>
  baths?: Maybe<Scalars['Float']>
}

export type NexusInventoryPhotosArgs = {
  clientName: Scalars['String']
}

export type NexusInventoryPhoto = {
  __typename?: 'NexusInventoryPhoto'
  id: Scalars['Int']
  src: Scalars['String']
  listIndex?: Maybe<Scalars['Int']>
}

export type NexusLot = {
  __typename?: 'NexusLot'
  id: Scalars['Int']
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  name: Scalars['String']
  salesStatus: NexusLotSalesStatus
  externalId?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  premium?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Float']>
  inventory?: Maybe<NexusInventory>
  geoJson?: Maybe<Scalars['JSON']>
  availableSchemes?: Maybe<Array<Maybe<NexusScheme>>>
}

export type NexusLotInventoryArgs = {
  clientName: Scalars['String']
}

export type NexusLotAvailableSchemesArgs = {
  clientName: Scalars['String']
}

export enum NexusLotSalesStatus {
  Available = 'AVAILABLE',
  Inventory = 'INVENTORY',
  Model = 'MODEL',
  Reserved = 'RESERVED',
  Sold = 'SOLD',
  Unavailable = 'UNAVAILABLE',
  UnderConstruction = 'UNDER_CONSTRUCTION',
  UnderContract = 'UNDER_CONTRACT',
  HomesiteOnly = 'HOMESITE_ONLY',
  Closed = 'CLOSED',
  Unreleased = 'UNRELEASED',
  BeingReserved = 'BEING_RESERVED',
  FutureInventory = 'FUTURE_INVENTORY',
}

export type NexusPlan = {
  __typename?: 'NexusPlan'
  id: Scalars['Int']
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  displayName?: Maybe<Scalars['String']>
}

export type NexusProspect = {
  __typename?: 'NexusProspect'
  id: Scalars['Int']
  clientName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  address2?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  zipCode?: Maybe<Scalars['String']>
  country?: Maybe<NexusCountry>
  state?: Maybe<Scalars['String']>
  picture?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  preferredContactMethods?: Maybe<Array<NexusContactMethodType>>
  brochures?: Maybe<Array<NexusProspectBrochures>>
  registrationDate?: Maybe<Scalars['Date']>
  favorites?: Maybe<Array<NexusProspectFavorite>>
  consultantName?: Maybe<Scalars['String']>
  consultantEmail?: Maybe<Scalars['String']>
  consultantPhone?: Maybe<Scalars['String']>
  communityId?: Maybe<Scalars['Int']>
  communityExternalId?: Maybe<Scalars['String']>
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  schemeId?: Maybe<Scalars['Int']>
  lotId?: Maybe<Scalars['Int']>
  lotExternalId?: Maybe<Scalars['String']>
  inventoryId?: Maybe<Scalars['Int']>
  status?: Maybe<NexusProspectStatus>
  sgtAppointments?: Maybe<Array<NexusProspectSgtAppointment>>
  receivedFavorites?: Maybe<Array<NexusReceivedFavorites>>
  agents?: Maybe<Array<NexusAgentProspect>>
  prospectEmailExists?: Maybe<Scalars['Boolean']>
  clientRating?: Maybe<Scalars['String']>
  onlineReservations?: Maybe<Array<NexusProspectReservation>>
  receiveCampaignEmails?: Maybe<Scalars['Boolean']>
  isFirstSignIn: Scalars['Boolean']
  isRegistered: Scalars['Boolean']
}

export type NexusProspectReceiveCampaignEmailsArgs = {
  clientName: Scalars['String']
}

export type NexusProspectBrochures = {
  __typename?: 'NexusProspectBrochures'
  url?: Maybe<Scalars['String']>
  creationDate?: Maybe<Scalars['String']>
}

export type NexusProspectFavorite = {
  __typename?: 'NexusProspectFavorite'
  id: Scalars['Int']
  prospectId: Scalars['Int']
  communityId: Scalars['Int']
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  lotId?: Maybe<Scalars['Int']>
  schemeId?: Maybe<Scalars['Int']>
  colorSelections?: Maybe<Scalars['JSON']>
  fpOptSelections?: Maybe<Scalars['JSON']>
  interiorDesignSelections?: Maybe<Scalars['JSON']>
  source: Scalars['String']
  clientName: Scalars['String']
  community?: Maybe<NexusCommunity>
  plan?: Maybe<NexusPlan>
  elevation?: Maybe<NexusElevation>
  lot?: Maybe<NexusLot>
  scheme?: Maybe<NexusScheme>
  garagePosition?: Maybe<NexusGaragePosition>
  isMirrored?: Maybe<Scalars['Boolean']>
}

export type NexusProspectFavoriteCommunityArgs = {
  clientName: Scalars['String']
}

export type NexusProspectFavoritePlanArgs = {
  clientName: Scalars['String']
}

export type NexusProspectFavoriteElevationArgs = {
  clientName: Scalars['String']
}

export type NexusProspectFavoriteLotArgs = {
  clientName: Scalars['String']
}

export type NexusProspectFavoriteSchemeArgs = {
  clientName: Scalars['String']
}

export type NexusProspectReservation = {
  __typename?: 'NexusProspectReservation'
  id: Scalars['String']
  prospectReservationId?: Maybe<Scalars['Int']>
  favoriteId: Scalars['Int']
  favorite: NexusProspectFavorite
  reservationStatus: NexusReservationBuilderStatus
  rejectReason?: Maybe<Scalars['String']>
  errorArray?: Maybe<Scalars['JSON']>
  prospectId?: Maybe<Scalars['Int']>
  prospect?: Maybe<NexusProspect>
  primaryBuyerId?: Maybe<Scalars['Int']>
  signatureDate?: Maybe<Scalars['Date']>
  paymentDate?: Maybe<Scalars['Date']>
  termsAndConditionsAcceptDate?: Maybe<Scalars['Date']>
  paymentsTransactionId?: Maybe<Scalars['String']>
  signTransactionId?: Maybe<Scalars['String']>
  secondaryBuyerReservations?: Maybe<Array<NexusProspectReservation>>
  isDeleted?: Maybe<Scalars['Boolean']>
}

export type NexusProspectSgtAppointment = {
  __typename?: 'NexusProspectSgtAppointment'
  id: Scalars['Int']
  prospectId: Scalars['Int']
  inventoryId: Scalars['Int']
  preferredDate?: Maybe<Scalars['Date']>
  createdOn?: Maybe<Scalars['Date']>
  timezoneOffset?: Maybe<Scalars['Int']>
}

export enum NexusProspectStatus {
  New = 'NEW',
  Open = 'OPEN',
  InProgress = 'IN_PROGRESS',
  OpenDeal = 'OPEN_DEAL',
  Unqualified = 'UNQUALIFIED',
  AttemptedToContact = 'ATTEMPTED_TO_CONTACT',
  Connected = 'CONNECTED',
  BadTiming = 'BAD_TIMING',
  False = 'FALSE',
  Purchaser = 'PURCHASER',
}

export type NexusReceivedFavorites = {
  __typename?: 'NexusReceivedFavorites'
  sentByProspectName: Scalars['String']
  sentByProspectEmail: Scalars['String']
  sentByProspectId: Scalars['Int']
  receivedByProspectName: Scalars['String']
  receivedByProspectEmail: Scalars['String']
  clientId: Scalars['Int']
  clientAltName: Scalars['String']
}

export enum NexusReservationBuilderStatus {
  InProgress = 'IN_PROGRESS',
  ApprovedByBuilder = 'APPROVED_BY_BUILDER',
  RejectedByBuilder = 'REJECTED_BY_BUILDER',
  RejectedByProspect = 'REJECTED_BY_PROSPECT',
  ReservationExpired = 'RESERVATION_EXPIRED',
}

export type NexusScheme = {
  __typename?: 'NexusScheme'
  id: Scalars['Int']
  name: Scalars['String']
  clientName: Scalars['String']
  materials?: Maybe<Array<Maybe<NexusSchemeElement>>>
}

export type NexusSchemeMaterialsArgs = {
  clientName?: Maybe<Scalars['String']>
}

export type NexusSchemeElement = {
  __typename?: 'NexusSchemeElement'
  id: Scalars['Int']
  colorId?: Maybe<Scalars['Int']>
  brickId?: Maybe<Scalars['Int']>
  stoneId?: Maybe<Scalars['Int']>
  customOverlayId?: Maybe<Scalars['Int']>
  materialId: Scalars['Int']
  material: Scalars['String']
  materialCategory?: Maybe<Scalars['String']>
  name: Scalars['String']
  displayName?: Maybe<Scalars['String']>
  hex?: Maybe<Scalars['String']>
  swatch?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  masonryLib?: Maybe<Scalars['Boolean']>
  paletteIds?: Maybe<Array<Maybe<Scalars['Int']>>>
}

export type NexusSchemeElementPaletteIdsArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
}

export type NotificationConfig = {
  __typename?: 'NotificationConfig'
  service?: Maybe<Scalars['String']>
  enabled: Scalars['Boolean']
  frequency?: Maybe<Scalars['Int']>
}

export type NotificationEmailsToSend = {
  __typename?: 'NotificationEmailsToSend'
  clientId: Scalars['Int']
  clientName: Scalars['String']
  accountManagerEmail?: Maybe<Scalars['String']>
  app: Scalars['String']
  divisionManagerEmail?: Maybe<Scalars['String']>
  billingDate: Scalars['String']
  communityId?: Maybe<Scalars['String']>
  communityName?: Maybe<Scalars['String']>
  subscriptionNotes?: Maybe<Scalars['String']>
  notes?: Maybe<Scalars['String']>
}

export type OnlineReservationFilterInput = {
  buyerInfo?: Maybe<Scalars['String']>
  communityId?: Maybe<Scalars['Int']>
  status?: Maybe<ReservationBuilderStatus>
  includeDeleted: Scalars['Boolean']
}

export type OnlineReservationIntegration = {
  __typename?: 'OnlineReservationIntegration'
  id: Scalars['Int']
  reservationIntegrationInfo?: Maybe<OnlineReservationIntegrationInfo>
}

export type OnlineReservationIntegrationInfo = {
  __typename?: 'OnlineReservationIntegrationInfo'
  id: Scalars['Int']
  stripeAccountId?: Maybe<Scalars['String']>
  stripeAccountReady: Scalars['Boolean']
  signingAgreementTemplateId?: Maybe<Scalars['UUID']>
  nonRefundableAmount?: Maybe<Scalars['Int']>
  depositAmount?: Maybe<Scalars['Int']>
  allowMultipleReservations?: Maybe<Scalars['Boolean']>
  anewgoFee?: Maybe<Scalars['Int']>
  primaryBuyOnlineContactEmail?: Maybe<Scalars['EmailAddress']>
  primaryBuyOnlineContactName?: Maybe<Scalars['String']>
  reservationConfig?: Maybe<Scalars['JSON']>
  daysToProveFinancing?: Maybe<Scalars['Int']>
  envelopeExpireDays?: Maybe<Scalars['Int']>
  additionalBuilders: Array<AdditionalBuilder>
  onboardingComplete: Scalars['Boolean']
}

export enum Order {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type OrderBy = {
  sortBy: Scalars['String']
  order: Order
}

export type Origin = {
  __typename?: 'Origin'
  id: Scalars['Int']
  url: Scalars['String']
}

export type OverlayInput = {
  elevationOverlayId?: Maybe<Scalars['Int']>
  addOverlayInput?: Maybe<AddOverlayInput>
  src: Scalars['String']
}

export enum OwnerConstraintEnum {
  AgentProspect = 'AGENT_PROSPECT',
  ClientProspect = 'CLIENT_PROSPECT',
}

export enum PackageType {
  RoomPackage = 'ROOM_PACKAGE',
  HousePackage = 'HOUSE_PACKAGE',
}

export type PageReferrals = {
  __typename?: 'PageReferrals'
  clientName: Scalars['String']
  sessions: Scalars['Int']
  numBounces: Scalars['Int']
  bounceRate: Scalars['Float']
  sumDuration: Scalars['Int']
  avgDuration: Scalars['Float']
  sumEvents: Scalars['Int']
  avgEvents: Scalars['Float']
  referrer?: Maybe<Scalars['String']>
  registers: Scalars['Int']
  visitors: Scalars['Int']
  users: Scalars['Int']
}

export type PageView = {
  __typename?: 'PageView'
  id: Scalars['String']
  anonymousId: Scalars['String']
  sentAt?: Maybe<Scalars['Timestamp']>
  path: Scalars['String']
  referrer?: Maybe<Scalars['String']>
  userAgent?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type PageViewsFilter = {
  baseInfo: BaseFilter
  userAgent?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  user?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type PaginateCursor = {
  __typename?: 'PaginateCursor'
  offset: Scalars['Int']
}

export type PaginatedCities = {
  __typename?: 'PaginatedCities'
  totalCount?: Maybe<Scalars['Int']>
  cities: Array<CityInfo>
}

export type PaginatedLeadRanking = {
  __typename?: 'PaginatedLeadRanking'
  totalCount: Scalars['Int']
  leads: Array<LeadRanking>
}

export type PaginatedLots = {
  __typename?: 'PaginatedLots'
  cursor: PaginateCursor
  lots: Array<Lot>
}

export type PaginatedMetros = {
  __typename?: 'PaginatedMetros'
  totalCount?: Maybe<Scalars['Int']>
  metros: Array<MetroInfo>
}

export type PaginatedPlans = {
  __typename?: 'PaginatedPlans'
  cursor: PaginateCursor
  plans: Array<Plan>
}

export type PaginatedPlansPlansArgs = {
  communityIds?: Maybe<Array<Scalars['Int']>>
}

export type PaginatedProspects = {
  __typename?: 'PaginatedProspects'
  totalCount: Scalars['Int']
  prospects: Array<Prospect>
}

export type Pagination = {
  offset: Scalars['Int']
  limit: Scalars['Int']
}

export type Palette = {
  __typename?: 'Palette'
  id: Scalars['Int']
  clientName: Scalars['String']
  name?: Maybe<Scalars['String']>
  internalName?: Maybe<Scalars['String']>
  elevationId?: Maybe<Scalars['Int']>
  materialId?: Maybe<Scalars['Int']>
  materials: Array<BlendElement>
  selections: Array<PaletteSelection>
}

export type PaletteMaterialsArgs = {
  clientName: Scalars['String']
  elevationId?: Maybe<Scalars['Int']>
}

export type PaletteSelectionsArgs = {
  clientName: Scalars['String']
  elevationId?: Maybe<Scalars['Int']>
}

export type PaletteElementSelection = {
  __typename?: 'PaletteElementSelection'
  inventoryId: Scalars['Int']
  elementId: Scalars['Int']
  paletteId: Scalars['Int']
  paletteSelection: PaletteSelection
}

export type PaletteElementSelectionInput = {
  elementId: Scalars['Int']
  selectionId: Scalars['Int']
}

export type PaletteSelection = {
  __typename?: 'PaletteSelection'
  id: Scalars['Int']
  colorId?: Maybe<Scalars['Int']>
  brickId?: Maybe<Scalars['Int']>
  stoneId?: Maybe<Scalars['Int']>
  customOverlayId?: Maybe<Scalars['Int']>
  vendorId?: Maybe<Scalars['Int']>
  vendorName?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  blendMode?: Maybe<BlendModeTypes>
  hex?: Maybe<Scalars['String']>
  identifier?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  swatch?: Maybe<Scalars['String']>
  masonryLib?: Maybe<Scalars['Boolean']>
}

export type PaletteSelectionInput = {
  colorId?: Maybe<Scalars['Int']>
  brickId?: Maybe<Scalars['Int']>
  stoneId?: Maybe<Scalars['Int']>
  customOverlayId?: Maybe<Scalars['Int']>
  blendMode: BlendModeTypes
}

export type Photo = {
  __typename?: 'Photo'
  src?: Maybe<Scalars['String']>
  listIndex?: Maybe<Scalars['Int']>
}

export enum PhotoType {
  Interior = 'INTERIOR',
  Exterior = 'EXTERIOR',
}

export type Plan = {
  __typename?: 'Plan'
  id: Scalars['Int']
  externalId?: Maybe<Scalars['String']>
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  displayName?: Maybe<Scalars['String']>
  videoUrl?: Maybe<Scalars['String']>
  defaultElevationId?: Maybe<Scalars['Int']>
  bed?: Maybe<Scalars['Int']>
  bedMin?: Maybe<Scalars['Int']>
  bedMax?: Maybe<Scalars['Int']>
  bedRange?: Maybe<IntRange>
  bath?: Maybe<Scalars['Float']>
  bathMin?: Maybe<Scalars['Float']>
  bathMax?: Maybe<Scalars['Float']>
  bathRange?: Maybe<FloatRange>
  size?: Maybe<Scalars['Int']>
  sizeMin?: Maybe<Scalars['Int']>
  sizeMax?: Maybe<Scalars['Int']>
  sizeRange?: Maybe<IntRange>
  client?: Maybe<Client>
  cost?: Maybe<Scalars['Int']>
  costMin?: Maybe<Scalars['Int']>
  costMax?: Maybe<Scalars['Int']>
  costRange?: Maybe<IntRange>
  communities: Array<Community>
  description?: Maybe<Scalars['String']>
  interiorPhotos: Array<Photo>
  exteriorAssets: Array<Asset>
  elevations: Array<Elevation>
  inventories: Array<Inventory>
  floorplans: Array<Floorplan>
  fpGroups: Array<FloorplanGroup>
  interactiveInteriorIds: Array<Scalars['Int']>
  internalNotes?: Maybe<Scalars['String']>
  svgMirroring?: Maybe<Scalars['Boolean']>
  garagePosition?: Maybe<GaragePosition>
  homeType?: Maybe<HomeType>
  popularityMetrics: PlanPopularityMetrics
}

export type PlanBedRangeArgs = {
  clientName?: Maybe<Scalars['String']>
  communityId?: Maybe<Scalars['Int']>
  communityIds?: Maybe<Array<Scalars['Int']>>
}

export type PlanBathRangeArgs = {
  clientName?: Maybe<Scalars['String']>
  communityId?: Maybe<Scalars['Int']>
  communityIds?: Maybe<Array<Scalars['Int']>>
}

export type PlanSizeRangeArgs = {
  clientName?: Maybe<Scalars['String']>
  communityId?: Maybe<Scalars['Int']>
  communityIds?: Maybe<Array<Scalars['Int']>>
}

export type PlanClientArgs = {
  clientName?: Maybe<Scalars['String']>
}

export type PlanCostRangeArgs = {
  clientName?: Maybe<Scalars['String']>
  communityId?: Maybe<Scalars['Int']>
  communityIds?: Maybe<Array<Scalars['Int']>>
}

export type PlanCommunitiesArgs = {
  clientName?: Maybe<Scalars['String']>
  filters?: Maybe<PlanCommunitiesFilter>
}

export type PlanInteriorPhotosArgs = {
  clientName: Scalars['String']
}

export type PlanExteriorAssetsArgs = {
  clientName: Scalars['String']
}

export type PlanElevationsArgs = {
  clientName?: Maybe<Scalars['String']>
  communityId?: Maybe<Scalars['Int']>
  communityIds?: Maybe<Array<Scalars['Int']>>
  active?: Maybe<Scalars['Boolean']>
}

export type PlanInventoriesArgs = {
  clientName?: Maybe<Scalars['String']>
}

export type PlanFloorplansArgs = {
  clientName: Scalars['String']
  filters?: Maybe<FloorplanFilters>
}

export type PlanFpGroupsArgs = {
  clientName: Scalars['String']
  filters?: Maybe<FloorplanFilters>
}

export type PlanInteractiveInteriorIdsArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
}

export type PlanPopularityMetricsArgs = {
  filter: CommonQueryFilter
  selectedMetrics?: Maybe<Array<Scalars['String']>>
}

export type PlanCommunitiesFilter = {
  active?: Maybe<Scalars['Boolean']>
}

export type PlanElevation = {
  __typename?: 'PlanElevation'
  planId: Scalars['Int']
  elevationId: Scalars['Int']
  planName?: Maybe<Scalars['String']>
  planDisplayName?: Maybe<Scalars['String']>
  elevationCaption?: Maybe<Scalars['String']>
}

export type PlanElevationInput = {
  planId: Scalars['Int']
  elevationId: Scalars['Int']
  communityId?: Maybe<Scalars['Int']>
}

export type PlanElevInvInput = {
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  inventoryId?: Maybe<Scalars['Int']>
  communityId?: Maybe<Scalars['Int']>
}

export type PlanPopularityMetrics = {
  __typename?: 'PlanPopularityMetrics'
  byDuration?: Maybe<DurationInfo>
  byFrequency?: Maybe<Scalars['Int']>
  byPopularity?: Maybe<Popularity>
  byBrowsingData?: Maybe<Browsing>
}

export type PlanTagAssignment = {
  __typename?: 'PlanTagAssignment'
  communityId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  inventoryId?: Maybe<Scalars['Int']>
  tagId?: Maybe<Scalars['Int']>
  tagCategoryId?: Maybe<Scalars['Int']>
}

export type PlanTagAssignmentInput = {
  planElevInvInput: Array<PlanElevInvInput>
  planTagInput: Array<PlanTagInput>
}

export type PlanTagCategory = {
  __typename?: 'PlanTagCategory'
  id: Scalars['Int']
  name: Scalars['String']
  plansByTagCategoryId: Array<Plan>
}

export type PlanTagCategoryPlansByTagCategoryIdArgs = {
  clientName: Scalars['String']
}

export type PlanTagElevation = {
  __typename?: 'PlanTagElevation'
  communityId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  inventoryId?: Maybe<Scalars['Int']>
  tagCategoryId?: Maybe<Scalars['Int']>
  tagId?: Maybe<Scalars['Int']>
  categoryName?: Maybe<Scalars['String']>
  tagName?: Maybe<Scalars['String']>
}

export type PlanTagInput = {
  id: Scalars['Int']
  categoryId: Scalars['Int']
}

export type PlanUpdateInput = {
  externalId?: Maybe<Scalars['String']>
  planId?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  bed?: Maybe<Scalars['Float']>
  bath?: Maybe<Scalars['Float']>
  size?: Maybe<Scalars['Int']>
  cost?: Maybe<Scalars['Float']>
  displayName?: Maybe<Scalars['String']>
  bedMin?: Maybe<Scalars['Float']>
  bedMax?: Maybe<Scalars['Float']>
  bathMin?: Maybe<Scalars['Float']>
  bathMax?: Maybe<Scalars['Float']>
  sizeMin?: Maybe<Scalars['Float']>
  sizeMax?: Maybe<Scalars['Float']>
  costMin?: Maybe<Scalars['Float']>
  costMax?: Maybe<Scalars['Float']>
}

export type PlanUpdateResult = {
  __typename?: 'PlanUpdateResult'
  planId?: Maybe<Scalars['Int']>
  externalId?: Maybe<Scalars['String']>
  plan?: Maybe<Scalars['String']>
  size?: Maybe<Scalars['Int']>
  updateStatus?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
}

export type PmFloorplan = {
  __typename?: 'PMFloorplan'
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  size?: Maybe<Scalars['Int']>
  cost?: Maybe<Scalars['Int']>
  bed?: Maybe<Scalars['Int']>
  bath?: Maybe<Scalars['Float']>
  videoUrl?: Maybe<Scalars['String']>
  primarySrc?: Maybe<Scalars['String']>
  secondarySrc?: Maybe<Scalars['String']>
}

export type PmFloorplanInput = {
  id?: Maybe<Scalars['Int']>
  name: Scalars['String']
  size?: Maybe<Scalars['Int']>
  cost?: Maybe<Scalars['Int']>
  bed?: Maybe<Scalars['Int']>
  bath?: Maybe<Scalars['Float']>
  videoUrl?: Maybe<Scalars['String']>
  primarySrc?: Maybe<Scalars['String']>
  secondarySrc?: Maybe<Scalars['String']>
}

export type Point = {
  __typename?: 'Point'
  x?: Maybe<Scalars['Float']>
  y?: Maybe<Scalars['Float']>
}

export type PopularColorSchemesFilter = {
  baseInfo: BaseFilter
  communityName?: Maybe<Scalars['String']>
  elevationId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
  eventWeight?: Maybe<Scalars['Float']>
  myHomeWeight?: Maybe<Scalars['Float']>
  pdfWeight?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Int']>
}

export type PopularCommunityByCount = PopularCommunityIf & {
  __typename?: 'PopularCommunityByCount'
  clientName: Scalars['String']
  communityId: Scalars['Int']
  communityName?: Maybe<Scalars['String']>
  community?: Maybe<Community>
  count: Scalars['Int']
}

export type PopularCommunityByDuration = PopularCommunityIf & {
  __typename?: 'PopularCommunityByDuration'
  clientName: Scalars['String']
  communityId: Scalars['Int']
  communityName?: Maybe<Scalars['String']>
  community?: Maybe<Community>
  durationInfo?: Maybe<DurationInfo>
}

export type PopularCommunityByFavoritesCount = PopularCommunityIf & {
  __typename?: 'PopularCommunityByFavoritesCount'
  clientName: Scalars['String']
  communityId: Scalars['Int']
  communityName?: Maybe<Scalars['String']>
  community?: Maybe<Community>
  favoritesCount?: Maybe<Scalars['Int']>
}

export type PopularCommunityByPopularity = PopularCommunityIf & {
  __typename?: 'PopularCommunityByPopularity'
  clientName: Scalars['String']
  communityId: Scalars['Int']
  communityName?: Maybe<Scalars['String']>
  community?: Maybe<Community>
  popularity?: Maybe<Scalars['Int']>
  clicks?: Maybe<Scalars['Int']>
  brochure?: Maybe<Scalars['Int']>
  cta?: Maybe<Scalars['Int']>
}

export type PopularCommunityFilter = {
  baseInfo: BaseFilter
  limit?: Maybe<Scalars['Int']>
  metric?: Maybe<PopularityMetric>
  elevationId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
}

export type PopularCommunityIf = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
  communityName?: Maybe<Scalars['String']>
  community?: Maybe<Community>
}

export type PopularElevationByCount = PopularElevationIf & {
  __typename?: 'PopularElevationByCount'
  clientName: Scalars['String']
  elevationId: Scalars['Int']
  elevationCaption: Scalars['String']
  planId: Scalars['Int']
  planName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  plan?: Maybe<Plan>
  elevation?: Maybe<Elevation>
  count: Scalars['Int']
}

export type PopularElevationByDuration = PopularElevationIf & {
  __typename?: 'PopularElevationByDuration'
  clientName: Scalars['String']
  elevationId: Scalars['Int']
  elevationCaption: Scalars['String']
  planId: Scalars['Int']
  planName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  plan?: Maybe<Plan>
  elevation?: Maybe<Elevation>
  durationInfo?: Maybe<DurationInfo>
}

export type PopularElevationByPopularity = PopularElevationIf & {
  __typename?: 'PopularElevationByPopularity'
  clientName: Scalars['String']
  elevationId: Scalars['Int']
  elevationCaption: Scalars['String']
  planId: Scalars['Int']
  planName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  plan?: Maybe<Plan>
  elevation?: Maybe<Elevation>
  popularity: Scalars['Int']
  clicks: Scalars['Int']
  brochure: Scalars['Int']
  cta: Scalars['Int']
}

export type PopularElevationIf = {
  clientName: Scalars['String']
  elevationId: Scalars['Int']
  elevationCaption: Scalars['String']
  planId: Scalars['Int']
  planName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  plan?: Maybe<Plan>
  elevation?: Maybe<Elevation>
}

export type PopularElevationSpec = {
  __typename?: 'PopularElevationSpec'
  bed: Array<Specification>
  bath: Array<Specification>
  size: Array<Specification>
  price: Array<Specification>
}

export type PopularFloorPlanOptions = {
  __typename?: 'PopularFloorPlanOptions'
  floorPlanOptionName: Scalars['String']
  count: Scalars['Int']
  popularity: Scalars['Int']
}

export type PopularFloorplans = {
  __typename?: 'PopularFloorplans'
  clientName?: Maybe<Scalars['String']>
  communityName?: Maybe<Scalars['String']>
  floorplanIds?: Maybe<Array<Maybe<Scalars['Int']>>>
  floorplans?: Maybe<Array<Maybe<Floorplan>>>
  popularity?: Maybe<Scalars['Int']>
  clicks?: Maybe<Scalars['Int']>
  brochure?: Maybe<Scalars['Int']>
  cta?: Maybe<Scalars['Int']>
}

export type PopularHour = {
  __typename?: 'PopularHour'
  hour: Scalars['String']
  count?: Maybe<Scalars['Int']>
}

export type PopularHourFilter = {
  baseInfo: BaseFilter
}

export type PopularInteriorSelections = {
  __typename?: 'PopularInteriorSelections'
  clientName?: Maybe<Scalars['String']>
  interiorId?: Maybe<Scalars['Int']>
  elementId?: Maybe<Scalars['Int']>
  listElementId?: Maybe<Scalars['Int']>
  optionId?: Maybe<Scalars['Int']>
  clicks?: Maybe<Scalars['Int']>
  brochure?: Maybe<Scalars['Int']>
  cta?: Maybe<Scalars['Int']>
  events?: Maybe<Scalars['Int']>
  popularity?: Maybe<Scalars['Int']>
  interior?: Maybe<Interior>
}

export type Popularity = {
  __typename?: 'Popularity'
  clicks: Scalars['Int']
  popularity: Scalars['Float']
  brochure: Scalars['Int']
  cta: Scalars['Int']
}

/** Common metrics */
export enum PopularityMetric {
  Favoritescount = 'FAVORITESCOUNT',
  Frequency = 'FREQUENCY',
  Popularity = 'POPULARITY',
  Duration = 'DURATION',
  Browsingdata = 'BROWSINGDATA',
}

export type PopularLotsFilter = {
  baseInfo: BaseFilter
}

export type PopularMaterialColors = {
  __typename?: 'PopularMaterialColors'
  clientName?: Maybe<Scalars['String']>
  materialId?: Maybe<Scalars['Int']>
  materialName?: Maybe<Scalars['String']>
  materialCategory?: Maybe<Scalars['String']>
  colorId?: Maybe<Scalars['Int']>
  colorName?: Maybe<Scalars['String']>
  paletteSelectionId?: Maybe<Scalars['Int']>
  brickId?: Maybe<Scalars['Int']>
  stoneId?: Maybe<Scalars['Int']>
  colorMasonryLib?: Maybe<Scalars['Boolean']>
  customOverlayId?: Maybe<Scalars['Int']>
  source?: Maybe<Scalars['String']>
  clicks?: Maybe<Scalars['Int']>
  brochure?: Maybe<Scalars['Int']>
  cta?: Maybe<Scalars['Int']>
  color?: Maybe<Color>
  texture?: Maybe<Texture>
  customOverlay?: Maybe<CustomOverlay>
  count?: Maybe<Scalars['Int']>
  popularity?: Maybe<Scalars['Int']>
}

export type PopularMaterialColorsFilter = {
  baseInfo: BaseFilter
  communityName?: Maybe<Scalars['String']>
  communityId?: Maybe<Scalars['Int']>
  planName?: Maybe<Scalars['String']>
  planId?: Maybe<Scalars['Int']>
  elevationCaption?: Maybe<Scalars['String']>
  elevationId?: Maybe<Scalars['Int']>
  eventWeight?: Maybe<Scalars['Float']>
  myHomeWeight?: Maybe<Scalars['Float']>
  pdfWeight?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Int']>
}

export type PopularSearch = {
  __typename?: 'PopularSearch'
  field: Scalars['String']
  value?: Maybe<Scalars['Int']>
  count?: Maybe<Scalars['Int']>
}

export enum PopularSearchField {
  Br = 'BR',
  Ba = 'BA',
  SizeMin = 'SIZE_MIN',
  SizeMax = 'SIZE_MAX',
  PriceMin = 'PRICE_MIN',
  PriceMax = 'PRICE_MAX',
}

export type PopularSearchFilter = {
  baseInfo: BaseFilter
  field: PopularSearchField
  limit?: Maybe<Scalars['Int']>
}

export type PortalCommunityTag = {
  __typename?: 'PortalCommunityTag'
  id: Scalars['Int']
  categoryId: Scalars['Int']
  name: Scalars['String']
}

export type PortalCommunityTagAssignment = {
  __typename?: 'PortalCommunityTagAssignment'
  communityId: Scalars['Int']
  categoryId: Scalars['Int']
  tagId: Scalars['Int']
  name?: Maybe<Scalars['String']>
  categoryName?: Maybe<Scalars['String']>
}

export type PortalCommunityTagAssignmentInput = {
  tagId: Scalars['Int']
  communityId: Scalars['Int']
}

export type PortalCommunityTagCategory = {
  __typename?: 'PortalCommunityTagCategory'
  id: Scalars['Int']
  name: Scalars['String']
}

export type PortalFloorplanTag = {
  __typename?: 'PortalFloorplanTag'
  id: Scalars['Int']
  name: Scalars['String']
  portalFloorplanTagCategoryId: Scalars['Int']
  synonyms: Array<Scalars['String']>
}

export type PortalFloorplanTagAssignment = {
  __typename?: 'PortalFloorplanTagAssignment'
  floorplanId: Scalars['Int']
  portalFloorplanTagId: Scalars['Int']
}

export type PortalFloorplanTagCategory = {
  __typename?: 'PortalFloorplanTagCategory'
  id: Scalars['Int']
  name: Scalars['String']
  portalFloorplanTags: Array<PortalFloorplanTag>
}

export type PortalFloorplanTagCategoryUpdate = {
  __typename?: 'PortalFloorplanTagCategoryUpdate'
  id: Scalars['Int']
  name: Scalars['String']
  portalFloorplanTagCategoryId: Scalars['Int']
  oldCategoryId?: Maybe<Scalars['Int']>
}

export type PortalFloorplanTagForElevation = {
  __typename?: 'PortalFloorplanTagForElevation'
  tagId: Scalars['Int']
  elevationId: Scalars['Int']
}

export type PortalFloorplanTagSynonym = {
  __typename?: 'PortalFloorplanTagSynonym'
  name: Scalars['String']
  portalFloorplanTagId: Scalars['Int']
}

export type PortalPlanTagCategory = {
  __typename?: 'PortalPlanTagCategory'
  id: Scalars['Int']
  name: Scalars['String']
}

export type PortalPlanTagElevation = {
  __typename?: 'PortalPlanTagElevation'
  communityId: Scalars['Int']
  planId: Scalars['Int']
  elevationId: Scalars['Int']
  inventoryId?: Maybe<Scalars['Int']>
  portalPlanTagCategoryId: Scalars['Int']
  portalPlanTagId: Scalars['Int']
  categoryName: Scalars['String']
  tagName: Scalars['String']
}

export type PrimaryBuyerInput = {
  prospectId: Scalars['Int']
  firstName: Scalars['String']
  lastName: Scalars['String']
  phone: Scalars['String']
}

export type ProductFilter = {
  communityMatch?: Maybe<Scalars['Boolean']>
  planMatch?: Maybe<Scalars['Boolean']>
  elevationMatch?: Maybe<Scalars['Boolean']>
  autoSelect?: Maybe<Scalars['Boolean']>
  bedRange?: Maybe<Scalars['Int']>
  bathRange?: Maybe<Scalars['Float']>
  sizeRange?: Maybe<Scalars['Int']>
  costRange?: Maybe<Scalars['Int']>
  distanceRange?: Maybe<Scalars['Int']>
}

export type Prospect = {
  __typename?: 'Prospect'
  id: Scalars['Int']
  clientName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  address2?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  zipCode?: Maybe<Scalars['String']>
  country?: Maybe<Country>
  state?: Maybe<Scalars['String']>
  picture?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  preferredContactMethods?: Maybe<Array<ContactMethodType>>
  brochures?: Maybe<Array<ProspectBrochures>>
  registrationDate?: Maybe<Scalars['Date']>
  favorites?: Maybe<Array<ProspectFavorite>>
  consultantName?: Maybe<Scalars['String']>
  consultantEmail?: Maybe<Scalars['String']>
  consultantPhone?: Maybe<Scalars['String']>
  communityId?: Maybe<Scalars['Int']>
  communityExternalId?: Maybe<Scalars['String']>
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  schemeId?: Maybe<Scalars['Int']>
  lotId?: Maybe<Scalars['Int']>
  lotExternalId?: Maybe<Scalars['String']>
  inventoryId?: Maybe<Scalars['Int']>
  status?: Maybe<ProspectStatus>
  sgtAppointments?: Maybe<Array<ProspectSgtAppointment>>
  receivedFavorites?: Maybe<Array<ReceivedFavorites>>
  agents?: Maybe<Array<AgentProspect>>
  prospectEmailExists?: Maybe<Scalars['Boolean']>
  clientRating?: Maybe<Scalars['String']>
  onlineReservations?: Maybe<Array<ProspectReservation>>
  receiveCampaignEmails?: Maybe<Scalars['Boolean']>
  isFirstSignIn: Scalars['Boolean']
  isRegistered: Scalars['Boolean']
  colonnadeAgents: Array<Scalars['Int']>
  statistics?: Maybe<ProspectAnalytic>
  interactions?: Maybe<Array<Event>>
}

export type ProspectReceiveCampaignEmailsArgs = {
  clientName: Scalars['String']
}

export type ProspectStatisticsArgs = {
  clientName: Scalars['String']
}

export type ProspectInteractionsArgs = {
  start: Scalars['Timestamp']
  end: Scalars['Timestamp']
}

export type ProspectAnalytic = {
  __typename?: 'ProspectAnalytic'
  user: Scalars['String']
  clientName: Scalars['String']
  lastSeen: Scalars['Timestamp']
  firstSeen: Scalars['Timestamp']
  selectedFloorplans?: Maybe<Array<Maybe<ProspectFloorplan>>>
  selectedColorSchemes?: Maybe<Array<ProspectScheme>>
  selectedMaterialColors?: Maybe<Array<ProspectMaterialColor>>
  brochuresCreated?: Maybe<Array<ProspectTopPlan>>
  ctaClicks?: Maybe<Array<ProspectCtaEvent>>
  favorites?: Maybe<Array<ProspectFavoriteEvent>>
  communitiesBrowsed?: Maybe<Array<ProspectTopCommunity>>
  plansBrowsed?: Maybe<Array<ProspectTopPlan>>
  communitiesStatistics?: Maybe<Array<ProspectTopCommunity>>
  plansStatistics?: Maybe<Array<ProspectTopPlan>>
  signIns?: Maybe<Array<ProspectSignIn>>
  signInsGrouped?: Maybe<Array<ProspectSignInGrouped>>
  appointmentRequests?: Maybe<Array<ProspectRequestAppointment>>
  camberPlanElevation?: Maybe<Array<CamberPlanElevation>>
  totalEvents: Scalars['Int']
  totalDuration: Scalars['Float']
  averageDuration: Scalars['Float']
  score: Scalars['Float']
  hotLead?: Maybe<Scalars['Boolean']>
  numSessions: Scalars['Int']
  numBrochuresCreated: Scalars['Int']
  numBrochureDownloads: Scalars['Int']
  numPlansShared: Scalars['Int']
  numContactsUs: Scalars['Int']
  numSignIns: Scalars['Int']
  numAppointments: Scalars['Int']
  numMyHomePageVisited: Scalars['Int']
  country?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
}

export type ProspectAppointmentData = {
  id: Scalars['Int']
  clientId: Scalars['Int']
  name: Scalars['String']
  email?: Maybe<Array<Scalars['String']>>
  phone?: Maybe<Scalars['String']>
  preferredContactMethod?: Maybe<Array<ContactMethodType>>
  comment?: Maybe<Scalars['String']>
}

export type ProspectBaseInfo = {
  __typename?: 'ProspectBaseInfo'
  id: Scalars['Int']
  email?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  preferredContactMethods?: Maybe<Array<ContactMethodType>>
  phone?: Maybe<Scalars['String']>
}

export type ProspectBrochures = {
  __typename?: 'ProspectBrochures'
  url?: Maybe<Scalars['String']>
  creationDate?: Maybe<Scalars['String']>
}

export type ProspectCtaEvent = {
  __typename?: 'ProspectCtaEvent'
  clientName?: Maybe<Scalars['String']>
  communityName?: Maybe<Scalars['String']>
  communityId?: Maybe<Scalars['Int']>
  community?: Maybe<Community>
  planName?: Maybe<Scalars['String']>
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  elevationCaption?: Maybe<Scalars['String']>
  plan?: Maybe<Plan>
  action?: Maybe<Scalars['String']>
  event?: Maybe<Scalars['String']>
  timestamp?: Maybe<Scalars['Timestamp']>
}

export type ProspectFavorite = {
  __typename?: 'ProspectFavorite'
  id: Scalars['Int']
  prospectId: Scalars['Int']
  communityId: Scalars['Int']
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  lotId?: Maybe<Scalars['Int']>
  schemeId?: Maybe<Scalars['Int']>
  colorSelections?: Maybe<Scalars['JSON']>
  fpOptSelections?: Maybe<Scalars['JSON']>
  interiorDesignSelections?: Maybe<Scalars['JSON']>
  source: Scalars['String']
  clientName: Scalars['String']
  community?: Maybe<Community>
  plan?: Maybe<Plan>
  elevation?: Maybe<Elevation>
  lot?: Maybe<Lot>
  scheme?: Maybe<Scheme>
  inventory?: Maybe<Inventory>
  interiors?: Maybe<Array<Interior>>
  garagePosition?: Maybe<GaragePosition>
  isMirrored?: Maybe<Scalars['Boolean']>
  lastUpdate?: Maybe<Scalars['Date']>
  mortgage?: Maybe<ProspectFavoriteMortgage>
}

export type ProspectFavoriteCommunityArgs = {
  clientName: Scalars['String']
}

export type ProspectFavoritePlanArgs = {
  clientName: Scalars['String']
}

export type ProspectFavoriteElevationArgs = {
  clientName: Scalars['String']
}

export type ProspectFavoriteLotArgs = {
  clientName: Scalars['String']
}

export type ProspectFavoriteSchemeArgs = {
  clientName: Scalars['String']
}

export type ProspectFavoriteInventoryArgs = {
  clientName: Scalars['String']
}

export type ProspectFavoriteInteriorsArgs = {
  clientName: Scalars['String']
  interior?: Maybe<InteriorInput>
}

export type ProspectFavoriteEvent = {
  __typename?: 'ProspectFavoriteEvent'
  id?: Maybe<Scalars['Int']>
  clientName?: Maybe<Scalars['String']>
  communityName?: Maybe<Scalars['String']>
  communityId?: Maybe<Scalars['Int']>
  community?: Maybe<Community>
  planName?: Maybe<Scalars['String']>
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  elevationCaption?: Maybe<Scalars['String']>
  plan?: Maybe<Plan>
  event?: Maybe<Scalars['String']>
  timestamp?: Maybe<Scalars['Timestamp']>
}

export type ProspectFavoriteInput = {
  id?: Maybe<Scalars['Int']>
  communityId: Scalars['Int']
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  lotId?: Maybe<Scalars['Int']>
  schemeId?: Maybe<Scalars['Int']>
  colorSelections?: Maybe<Scalars['JSON']>
  fpOptSelections?: Maybe<Scalars['JSON']>
  interiorDesignSelections?: Maybe<Scalars['JSON']>
  source: Scalars['String']
  garagePosition?: Maybe<GaragePosition>
  isMirrored?: Maybe<Scalars['Boolean']>
  mortgage?: Maybe<ProspectFavoriteMortgageInput>
}

export type ProspectFavoriteMortgage = {
  __typename?: 'ProspectFavoriteMortgage'
  downPayment?: Maybe<Scalars['Float']>
  downPercent?: Maybe<Scalars['Float']>
  interest: Scalars['Float']
  term: Scalars['Float']
}

export type ProspectFavoriteMortgageInput = {
  downPayment?: Maybe<Scalars['Float']>
  downPercent?: Maybe<Scalars['Float']>
  interest: Scalars['Float']
  term: Scalars['Float']
}

export type ProspectFloorplan = {
  __typename?: 'ProspectFloorplan'
  clientName?: Maybe<Scalars['String']>
  floorplanName?: Maybe<Scalars['String']>
  floorplanIds?: Maybe<Array<Maybe<Scalars['Int']>>>
  floorplans?: Maybe<Array<Maybe<Floorplan>>>
  popularity?: Maybe<Scalars['Int']>
  popularities?: Maybe<Array<Maybe<Scalars['Int']>>>
  planName?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ProspectInput = {
  email: Scalars['String']
  source: Scalars['String']
  contactMethods?: Maybe<Array<ContactMethodType>>
  isAutoLogin?: Maybe<Scalars['Boolean']>
  isRegistration?: Maybe<Scalars['Boolean']>
  identifier?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['Zip']>
  postCode?: Maybe<Scalars['String']>
  floorplanOfInterest?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  picture?: Maybe<Scalars['String']>
  consultantName?: Maybe<Scalars['String']>
  consultantEmail?: Maybe<Scalars['String']>
  consultantPhone?: Maybe<Scalars['String']>
  communityId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  schemeId?: Maybe<Scalars['Int']>
  lotId?: Maybe<Scalars['Int']>
  inventoryId?: Maybe<Scalars['Int']>
  planName?: Maybe<Scalars['String']>
}

export type ProspectMaterialColor = {
  __typename?: 'ProspectMaterialColor'
  clientName?: Maybe<Scalars['String']>
  materialId?: Maybe<Scalars['Int']>
  materialName?: Maybe<Scalars['String']>
  materialCategory?: Maybe<Scalars['String']>
  colorId?: Maybe<Scalars['Int']>
  colorName?: Maybe<Scalars['String']>
  paletteSelectionId?: Maybe<Scalars['Int']>
  brickId?: Maybe<Scalars['Int']>
  stoneId?: Maybe<Scalars['Int']>
  colorMasonryLib?: Maybe<Scalars['Boolean']>
  customOverlayId?: Maybe<Scalars['Int']>
  source?: Maybe<Scalars['String']>
  color?: Maybe<Color>
  texture?: Maybe<Texture>
  customOverlay?: Maybe<CustomOverlay>
}

export type ProspectRequestAppointment = {
  __typename?: 'ProspectRequestAppointment'
  clientName: Scalars['String']
  timestamp: Scalars['Timestamp']
  agentEmails?: Maybe<Array<Maybe<Scalars['String']>>>
  inventoryId: Scalars['Int']
  preferredDates?: Maybe<Array<Maybe<Scalars['Timestamp']>>>
  inventoryAddress?: Maybe<Scalars['String']>
  imgUrl?: Maybe<Scalars['String']>
  inventoryUrl?: Maybe<Scalars['String']>
  inventory?: Maybe<Inventory>
}

export type ProspectReservation = {
  __typename?: 'ProspectReservation'
  id: Scalars['String']
  prospectReservationId?: Maybe<Scalars['Int']>
  favoriteId: Scalars['Int']
  favorite: ProspectFavorite
  reservationStatus: ReservationBuilderStatus
  rejectReason?: Maybe<Scalars['String']>
  errorArray?: Maybe<Scalars['JSON']>
  prospectId?: Maybe<Scalars['Int']>
  prospect?: Maybe<Prospect>
  primaryBuyerId?: Maybe<Scalars['Int']>
  signatureDate?: Maybe<Scalars['Date']>
  paymentDate?: Maybe<Scalars['Date']>
  termsAndConditionsAcceptDate?: Maybe<Scalars['Date']>
  paymentsTransactionId?: Maybe<Scalars['String']>
  signTransactionId?: Maybe<Scalars['String']>
  secondaryBuyerReservations?: Maybe<Array<ProspectReservation>>
  isDeleted?: Maybe<Scalars['Boolean']>
  createdAt?: Maybe<Scalars['Timestamp']>
  totalPrice?: Maybe<Scalars['Int']>
}

export type ProspectScheme = {
  __typename?: 'ProspectScheme'
  clientName?: Maybe<Scalars['String']>
  schemeId?: Maybe<Scalars['Int']>
  scheme?: Maybe<Scheme>
}

export type ProspectSgtAppointment = {
  __typename?: 'ProspectSgtAppointment'
  id: Scalars['Int']
  prospectId: Scalars['Int']
  inventoryId: Scalars['Int']
  preferredDate?: Maybe<Scalars['Date']>
  createdOn?: Maybe<Scalars['Date']>
  timezoneOffset?: Maybe<Scalars['Int']>
}

export type ProspectSgtApptInput = {
  prospect: ProspectAppointmentData
  inventory: InventoryAppointmentData
  preferredDate?: Maybe<Array<Scalars['Date']>>
  agentEmail?: Maybe<Array<Scalars['String']>>
  createdOn?: Maybe<Scalars['Date']>
}

export type ProspectSgtData = {
  __typename?: 'ProspectSgtData'
  id: Scalars['Int']
  prospectId: Scalars['Int']
  name?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  inventoryId: Scalars['Int']
  preferredDate?: Maybe<Scalars['Date']>
  createdOn: Scalars['Date']
}

export type ProspectSignIn = {
  __typename?: 'ProspectSignIn'
  timestamp: Scalars['Timestamp']
  anonymousId: Scalars['String']
  userAction: Scalars['String']
  source: Scalars['String']
}

export type ProspectSignInGrouped = {
  __typename?: 'ProspectSignInGrouped'
  day: Scalars['String']
  count: Scalars['Int']
}

export enum ProspectStatus {
  New = 'NEW',
  Open = 'OPEN',
  InProgress = 'IN_PROGRESS',
  OpenDeal = 'OPEN_DEAL',
  Unqualified = 'UNQUALIFIED',
  AttemptedToContact = 'ATTEMPTED_TO_CONTACT',
  Connected = 'CONNECTED',
  BadTiming = 'BAD_TIMING',
  False = 'FALSE',
  Purchaser = 'PURCHASER',
}

export type ProspectToken = {
  __typename?: 'ProspectToken'
  flagshipToken: Scalars['String']
  tokenExp: Scalars['Date']
  isRegistered: Scalars['Boolean']
  isFirstSignIn: Scalars['Boolean']
  prospect?: Maybe<NexusProspect>
}

export type ProspectTokenValidationResult = {
  __typename?: 'ProspectTokenValidationResult'
  isValid: Scalars['Boolean']
  isRegistered: Scalars['Boolean']
  isFirstSignIn: Scalars['Boolean']
}

export type ProspectTopCommunity = {
  __typename?: 'ProspectTopCommunity'
  clientName: Scalars['String']
  communityName?: Maybe<Scalars['String']>
  communityId: Scalars['Int']
  community?: Maybe<Community>
  events?: Maybe<Scalars['Int']>
  duration?: Maybe<Scalars['Float']>
  timestamp?: Maybe<Scalars['Timestamp']>
}

export type ProspectTopPlan = {
  __typename?: 'ProspectTopPlan'
  clientName: Scalars['String']
  communityName?: Maybe<Scalars['String']>
  communityId?: Maybe<Scalars['Int']>
  community?: Maybe<Community>
  planName: Scalars['String']
  planId: Scalars['Int']
  elevationId: Scalars['Int']
  elevationCaption?: Maybe<Scalars['String']>
  plan?: Maybe<Plan>
  events?: Maybe<Scalars['Int']>
  duration?: Maybe<Scalars['Float']>
  timestamp?: Maybe<Scalars['Timestamp']>
}

export type ProspectUpdatedDataInput = {
  firstName: Scalars['String']
  lastName: Scalars['String']
  address: Scalars['String']
  address2?: Maybe<Scalars['String']>
  city: Scalars['String']
  country: Country
  state: Scalars['String']
  zipCode: Scalars['String']
}

export type Province = {
  __typename?: 'Province'
  id: Scalars['Int']
  code: Scalars['String']
  name: Scalars['String']
}

export type Query = {
  __typename?: 'Query'
  helloQuery?: Maybe<Scalars['String']>
  testCommonQuery?: Maybe<Scalars['String']>
  getProspectByEmail?: Maybe<ProspectBaseInfo>
  elevationElements: Array<ElevationElement>
  prospectsByClient: Array<Prospect>
  getAllBuilderPageImages: Array<BuilderPageImage>
  getAgentProspectAllClients: Array<AgentProspect>
  testPrivateQuery?: Maybe<Scalars['String']>
  getProspectAgent: AgentProspect
  getLeadBrowseNotificationConfig?: Maybe<NotificationConfig>
  getNewLeadNotificationConfig?: Maybe<NotificationConfig>
  getPortalFloorplanTagMatchesForSvg: Array<PortalFloorplanTag>
  interiorElementOptionAlternateGroup?: Maybe<InteriorAlternateGroup>
  libraryVendors: Array<LibraryVendor>
  portalFloorplanTagCategories: Array<PortalFloorplanTagCategory>
  portalFloorplanTagsForElevations: Array<PortalFloorplanTagForElevation>
  portalFloorplanTagsForFloorplan: Array<PortalFloorplanTag>
  allCounties: Array<County>
  allDistricts: Array<District>
  testClientAccountQuery?: Maybe<Scalars['String']>
  testSystemQuery?: Maybe<Scalars['String']>
  inventorySgtAppointments: Array<ProspectSgtAppointment>
  prospects: Array<Prospect>
  prospectsByCommunityExternalId: Array<Prospect>
  sgtAppointments: Array<ProspectSgtAppointment>
  communityFloorplans: Array<CommunityFloorplan>
  allFloorplans: Array<Floorplan>
  allElevations: Array<Elevation>
  allClients: Array<Client>
  isAgentOfProspect?: Maybe<Scalars['Boolean']>
  isProspectOfClient?: Maybe<Scalars['Boolean']>
  reservationStatus?: Maybe<ReservationStatus>
  isBuyingAllowed: Scalars['Boolean']
  getPrimaryProspectForReservation?: Maybe<Prospect>
  getReservationIntegrationBaseInfo: ReservationIntegrationBaseInfo
  getFavoriteById: ProspectFavorite
  getProspectReservations: Array<ProspectReservation>
  clientById?: Maybe<Client>
  clientByIds: Array<Maybe<Client>>
  allPlans: Array<Plan>
  agentsByClient: Array<Agent>
  builderPage?: Maybe<BuilderPage>
  getUnpublishedBuilderPage?: Maybe<BuilderPage>
  floorplanTagsForCategory: Array<FloorplanTag>
  baseInventoryFloorplans: Array<Floorplan>
  baseLotPlanElevations: Array<PlanElevation>
  baseLotSchemes: Array<Scheme>
  blendElements: Array<BlendElement>
  builderAppConfig?: Maybe<Scalars['JSON']>
  citiesByCommunityMetro?: Maybe<MetroInfo>
  citiesByMetro?: Maybe<MetroInfo>
  clientCustomizations: Array<Customization>
  clientSocials?: Maybe<ClientSocials>
  getUnpublishedClientSocials?: Maybe<ClientSocials>
  clientByName?: Maybe<Client>
  collections: Array<Collection>
  color?: Maybe<Color>
  communities: Array<Community>
  /** @deprecated The communitiesByNameOrId query has been deprecated and will be removed in a future release. Please use community or communityByName. */
  communitiesByNameOrId?: Maybe<Array<Maybe<Community>>>
  community?: Maybe<Community>
  communityByName?: Maybe<Community>
  /** @deprecated The communityBuildYourLot query has been deprecated and will be removed in a future release. */
  communityBuildYourLot?: Maybe<Community>
  communityPoiCategory?: Maybe<CommunityPoiCategory>
  communitySalesAppConfig: Scalars['String']
  currency?: Maybe<Scalars['Currency']>
  customization?: Maybe<Customization>
  areaMetric?: Maybe<AreaMetric>
  customOverlays: Array<Texture>
  defaultCommunity?: Maybe<Community>
  elevation?: Maybe<Elevation>
  elevations: Array<Elevation>
  floorplans: Array<Floorplan>
  elevationByName?: Maybe<Elevation>
  excludedLotPlanElevations: Array<PlanElevation>
  excludedLotSchemes: Array<Scheme>
  exteriorTags: Array<ExteriorTag>
  allAnewgoPlanTags: Array<AnewgoPlanTag>
  allAnewgoPortalPlanTags: Array<AnewgoPortalPlanTag>
  allClientPlanTags: Array<ClientPlanTag>
  allPlanTagCategories: Array<PlanTagCategory>
  allClientPlanTagCategories: Array<PlanTagCategory>
  allPortalPlanTagCategories: Array<PortalPlanTagCategory>
  plansByTagCategoryId: Array<Plan>
  tagsAssignedToPlan: Array<PlanTagElevation>
  portalTagsAssignedToPlan: Array<PortalPlanTagElevation>
  plansByTagId: Array<Plan>
  floorplan?: Maybe<Floorplan>
  floorplanAlternate?: Maybe<FloorplanAlternate>
  floorplansByElevation?: Maybe<ElevationFloorplans>
  floorplansByElevationId?: Maybe<ElevationFloorplans>
  fpGroup?: Maybe<FloorplanGroup>
  gallery?: Maybe<CommunityGallery>
  highlight?: Maybe<CommunityHighlight>
  homeCategory?: Maybe<HomeCategory>
  homeCategories: Array<HomeCategory>
  homesites: Array<Homesite>
  homeStyles: Array<HomeStyle>
  hotspot: SiteplanHotspot
  includedInventoryFloorplans: Array<Floorplan>
  interiors?: Maybe<Array<Interior>>
  interior?: Maybe<Interior>
  activeInteriors?: Maybe<Array<Interior>>
  activeInterior?: Maybe<Interior>
  interiorElementOptions: Array<InteriorElementOption>
  interiorElement?: Maybe<InteriorElement>
  interiorListElement?: Maybe<InteriorListElement>
  interiorAssignments: Array<InteriorAssignment>
  interiorPresets: Array<InteriorPreset>
  interiorElementOptionAlternateGroups: Array<InteriorAlternateGroup>
  inventories: Array<Inventory>
  inventory?: Maybe<Inventory>
  libraries: Array<Library>
  lot?: Maybe<Lot>
  masterCommunityByName?: Maybe<MasterCommunity>
  masterCommunityById?: Maybe<MasterCommunity>
  masterCommunities: Array<MasterCommunity>
  subCommunities: Array<Community>
  masterCommunitySalesAppConfig: Scalars['String']
  materials: Array<BlendElement>
  metros: Array<MetroInfo>
  metrosCitiesCommunities: Array<MetroInfo>
  mlsServicesEnabled?: Maybe<Scalars['Boolean']>
  palette?: Maybe<Palette>
  palettes: Array<Palette>
  plan?: Maybe<Plan>
  planByName?: Maybe<Plan>
  planExteriorAssets: Array<Photo>
  planInteriorPhotos: Array<Photo>
  plans: Array<Plan>
  paginatedPlans?: Maybe<PaginatedPlans>
  paginatedInventoryLots?: Maybe<PaginatedLots>
  paginatedMetros?: Maybe<PaginatedMetros>
  paginatedCities?: Maybe<PaginatedCities>
  plansByCommunities: Array<Plan>
  planTagsByCommunities: Array<ClientPlanTag>
  pmFloorplan?: Maybe<PmFloorplan>
  prospectByEmail?: Maybe<Prospect>
  prospectById?: Maybe<Prospect>
  prospectBaseInfo?: Maybe<ProspectBaseInfo>
  prospectSgtAppointments: Array<ProspectSgtData>
  scheme?: Maybe<Array<Scheme>>
  schemes: Array<Scheme>
  searchCities: Array<CityInfo>
  siteplan?: Maybe<Siteplan>
  siteplanById?: Maybe<Siteplan>
  activeSiteplanByCommunityId?: Maybe<Siteplan>
  siteplansByCommunityId: Array<Siteplan>
  siteplans: Array<Siteplan>
  sgtVendors: Array<SgtVendor>
  sgtVendor?: Maybe<SgtVendor>
  stdFeatureCategories: Array<StdFeatureCategory>
  stdFeatures: Array<StdFeature>
  stdFeatureAssignments: Array<StdFeatureAssignment>
  communityTagCategories: Array<CommunityTagCategory>
  communityTags: Array<CommunityTag>
  communityTagAssignments: Array<CommunityTagAssignment>
  communityTagAssignmentsByCommunityId: Array<CommunityTagAssignment>
  portalCommunityTagCategories: Array<PortalCommunityTagCategory>
  portalCommunityTags: Array<PortalCommunityTag>
  portalCommunityTagAssignments: Array<PortalCommunityTagAssignment>
  portalCommunityTagAssignmentsByCommunityId: Array<PortalCommunityTagAssignment>
  builderTagCategories: Array<BuilderTagCategory>
  builderTags: Array<BuilderTag>
  builderTagAssignments: Array<BuilderTagAssignment>
  stories: Array<Story>
  testPublicQuery?: Maybe<Scalars['String']>
  texture?: Maybe<Texture>
  getHomeSelection?: Maybe<HomeSelection>
  cities: Array<CityInfo>
  colonnadeApiProspect: Array<Prospect>
  colonnadeApiPaginatedProspects?: Maybe<PaginatedProspects>
  colonnadeApiProspectByEmail?: Maybe<Prospect>
  getProspectActivities?: Maybe<Scalars['JSON']>
  getCommunityBuyOnlineCustomConfig?: Maybe<CommunityBuyOnlineConfig>
  getNewLeadAgentNotificationConfig?: Maybe<NotificationConfig>
  getLeadBrowseAgentNotificationConfig?: Maybe<NotificationConfig>
  getOnlineReservations?: Maybe<Array<ProspectReservation>>
  getReservationsDropOfRate?: Maybe<ReservationDropOfSteps>
  getReservationStatusOverview: ReservationStatusOverview
  getReservationStatusCommunitiesOverview: Array<ReservationStatusCommunityOverview>
  hasCommunityInProgressReservation?: Maybe<Scalars['Boolean']>
  getDocuSignTemplatePreview: Scalars['URL']
  getDocumentLinkForBuilder: Scalars['URL']
  getReservationIntegration?: Maybe<OnlineReservationIntegration>
  getSubscriptionEndpoint: Scalars['String']
  getEmailCampaign?: Maybe<EmailCampaign>
  getEmailCampaigns: Array<EmailCampaign>
  verifyAndFilterPlanCommunities: Array<RecommendedTopKPlans>
  getIsLotSignLocked?: Maybe<LotSignLock>
  colonnadeRoles: Array<ColonnadeRole>
  colonnadeUsers: Array<User>
  notificationEmailsToSend: Array<NotificationEmailsToSend>
  getColonnadeClientSubscriptionInfo: ColonnadeClientSubscriptionInfo
  getClientEmailReportInfo: Array<ClientEmailReportInfo>
  getAllColonnadeReportEmails: Array<ClientEmailReportInfo>
  notificationEmailsToSendColonnade: Array<EmailDataAnniversaryNotif>
  isUserAnewgoAdmin: Scalars['Boolean']
  allRoles?: Maybe<AllRoles>
  allRoleAppConfigKeys?: Maybe<Array<RoleAppConfigKey>>
  userInfo?: Maybe<User>
  usersWithRoles?: Maybe<UsersWithRoles>
  getFlagshipSubscriptionByClientId?: Maybe<ClientSubscription>
  getCommunitySubscriptionsByClientId?: Maybe<
    Array<Maybe<ClientCommunitySubscription>>
  >
  clientsForUser?: Maybe<ClientsForUser>
  appConfig?: Maybe<Scalars['JSON']>
  clientUserEmails: Array<Scalars['String']>
  roleAppConfigs?: Maybe<Array<RoleAppConfig>>
  roleAppConfig?: Maybe<RoleAppConfig>
  getClientApiKey?: Maybe<Array<Maybe<ClientApiKey>>>
  getAllClientApiKeys?: Maybe<Array<Maybe<ClientApiKey>>>
  getAppApiKeys?: Maybe<Array<Maybe<AppApiKey>>>
  authenticateByToken: Scalars['String']
  authenticate?: Maybe<Scalars['String']>
  authenticateProspect?: Maybe<ProspectToken>
  authenticateApiKey?: Maybe<Scalars['String']>
  createToken?: Maybe<Scalars['String']>
  isAuthorized?: Maybe<Scalars['Boolean']>
  isExistingEmail?: Maybe<Scalars['Boolean']>
  validateProspectToken: ProspectTokenValidationResult
  renewToken?: Maybe<Scalars['String']>
  verifyToken?: Maybe<Scalars['JSON']>
  isUserSuperAdmin?: Maybe<Scalars['Boolean']>
  userHasSuperClientRole?: Maybe<Scalars['Boolean']>
  pageViews: Array<PageView>
  sessions: Array<Session>
  sessionsSummary: SessionsSummary
  sessionsSummaryTrends: Array<SessionsSummaryDate>
  engagementRate: Engagement
  sessionsSummaryTrendsByCommunity: Array<SessionsSummaryByCommunity>
  popularCommunities: Array<Maybe<PopularCommunityIf>>
  popularHours: Array<Maybe<PopularHour>>
  popularLots: Array<Maybe<Lot>>
  popularFloorplans: Array<Maybe<PopularFloorplans>>
  popularMaterialColors: Array<Maybe<PopularMaterialColors>>
  popularInteriorSelections: Array<Maybe<PopularInteriorSelections>>
  camberPopularMaterialColors: Array<Maybe<PopularMaterialColors>>
  getCamberUsageSummary: Array<Maybe<CamberUsageSummary>>
  popularSearches: Array<Maybe<PopularSearch>>
  popularColorSchemes: Array<Maybe<Scheme>>
  camberPopularColorSchemes: Array<Maybe<Scheme>>
  getColorTrends: ColorTrends
  getCommunitiesTrends: CommunitiesTrends
  getElevationTrends: ElevationTrends
  getFloorplanTrends: FloorplanTrends
  leadRankings: PaginatedLeadRanking
  prospectAnalytics: Array<ProspectAnalytic>
  visitorTrends: Array<VisitorTrend>
  visitorTimes: Array<VisitorTime>
  leadTimeRanking: Array<LeadTimeRanking>
  leadsByElevation: Array<LeadByElevation>
  leadsByProduct: Array<LeadByProduct>
  industrySessionTrends: Array<IndustrySessionTrends>
  industrySessionSummary: IndustrySessionSummary
  popularFloorPlanOptions: Array<PopularFloorPlanOptions>
  getSchemeTrends: SchemeTrends
  popularElevations: Array<Maybe<PopularElevationIf>>
  popularElevationSpecs: PopularElevationSpec
  popularIndustryElevationSpecs: PopularElevationSpec
  getCamberElevationTrends: CamberElevationTrends
  getCamberUsersLogins: CamberUsersLogins
  getCamberFloorplanTrends: CamberFloorplanTrends
  camberPopularLots: Array<Maybe<Lot>>
  getAllLocations: Array<Maybe<Location>>
  getVisitorLocations: Array<VisitorLocation>
  excludedIps: Array<Maybe<IpAddress>>
  getFavorites: Favorites
  getMfRecommendedPlans: Array<MfRecommendedPlans>
  getLotLeads?: Maybe<Array<LotLead>>
  getLotClicks?: Maybe<Array<LotClicks>>
  pageReferrals: Array<Maybe<PageReferrals>>
  getCommunitiesByLocation: Array<CommunityByLocation>
  getEnvelopesCreated?: Maybe<EnvelopesCreated>
}

export type QueryHelloQueryArgs = {
  name?: Maybe<Scalars['String']>
}

export type QueryGetProspectByEmailArgs = {
  email: Scalars['EmailAddress']
}

export type QueryElevationElementsArgs = {
  clientName: Scalars['String']
  elevationId: Scalars['Int']
}

export type QueryProspectsByClientArgs = {
  clientName: Scalars['String']
  registrationDateTimeFilter?: Maybe<RegDateTimeFilter>
}

export type QueryGetAllBuilderPageImagesArgs = {
  clientName: Scalars['String']
}

export type QueryGetProspectAgentArgs = {
  clientName: Scalars['String']
}

export type QueryGetLeadBrowseNotificationConfigArgs = {
  clientName: Scalars['String']
}

export type QueryGetNewLeadNotificationConfigArgs = {
  clientName: Scalars['String']
}

export type QueryGetPortalFloorplanTagMatchesForSvgArgs = {
  clientName: Scalars['String']
  svgContent?: Maybe<Scalars['JSON']>
}

export type QueryInteriorElementOptionAlternateGroupArgs = {
  clientName: Scalars['String']
  groupId: Scalars['Int']
}

export type QueryLibraryVendorsArgs = {
  library: LibraryType
  vendorIds?: Maybe<Array<Scalars['Int']>>
}

export type QueryPortalFloorplanTagsForElevationsArgs = {
  clientName: Scalars['String']
  planId: Scalars['Int']
}

export type QueryPortalFloorplanTagsForFloorplanArgs = {
  clientName: Scalars['String']
  floorplanId: Scalars['Int']
}

export type QueryInventorySgtAppointmentsArgs = {
  clientName: Scalars['String']
  inventoryId: Scalars['Int']
}

export type QueryProspectsArgs = {
  clientName: Scalars['String']
}

export type QueryProspectsByCommunityExternalIdArgs = {
  clientName: Scalars['String']
  communityExternalId: Scalars['String']
}

export type QuerySgtAppointmentsArgs = {
  clientName: Scalars['String']
}

export type QueryCommunityFloorplansArgs = {
  clientName: Scalars['String']
}

export type QueryAllFloorplansArgs = {
  clientName: Scalars['String']
}

export type QueryAllElevationsArgs = {
  clientName: Scalars['String']
}

export type QueryAllClientsArgs = {
  statuses?: Maybe<Array<ClientStatus>>
  kinds?: Maybe<Array<ClientType>>
  filter?: Maybe<ClientFilter>
}

export type QueryIsAgentOfProspectArgs = {
  userId: Scalars['Int']
  prospectId: Scalars['Int']
  clientName: Scalars['String']
}

export type QueryIsProspectOfClientArgs = {
  clientId: Scalars['Int']
  prospectId: Scalars['Int']
}

export type QueryReservationStatusArgs = {
  clientName: Scalars['String']
  id: Scalars['UUID']
}

export type QueryIsBuyingAllowedArgs = {
  clientId: Scalars['Int']
  lotId?: Maybe<Scalars['Int']>
  communityId: Scalars['Int']
}

export type QueryGetPrimaryProspectForReservationArgs = {
  clientName: Scalars['String']
  reservationId: Scalars['UUID']
}

export type QueryGetReservationIntegrationBaseInfoArgs = {
  clientName: Scalars['String']
}

export type QueryGetFavoriteByIdArgs = {
  clientName: Scalars['String']
  favoriteId: Scalars['Int']
}

export type QueryGetProspectReservationsArgs = {
  clientName: Scalars['String']
}

export type QueryClientByIdArgs = {
  clientId: Scalars['Int']
}

export type QueryClientByIdsArgs = {
  clientIds: Array<Scalars['Int']>
}

export type QueryAllPlansArgs = {
  clientName: Scalars['String']
  buildYourLot?: Maybe<Scalars['Boolean']>
  active?: Maybe<Scalars['Boolean']>
}

export type QueryAgentsByClientArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  communityIds?: Maybe<Array<Scalars['Int']>>
}

export type QueryBuilderPageArgs = {
  clientName: Scalars['String']
  id: Scalars['Int']
}

export type QueryGetUnpublishedBuilderPageArgs = {
  clientName: Scalars['String']
}

export type QueryFloorplanTagsForCategoryArgs = {
  floorplanTagCategoryId: Scalars['Int']
}

export type QueryBaseInventoryFloorplansArgs = {
  clientName: Scalars['String']
  inventoryId: Scalars['Int']
}

export type QueryBaseLotPlanElevationsArgs = {
  clientName: Scalars['String']
  lotId: Scalars['Int']
}

export type QueryBaseLotSchemesArgs = {
  clientName: Scalars['String']
  lotId: Scalars['Int']
}

export type QueryBlendElementsArgs = {
  clientName?: Maybe<Scalars['String']>
}

export type QueryBuilderAppConfigArgs = {
  clientName: Scalars['String']
}

export type QueryCitiesByCommunityMetroArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
}

export type QueryCitiesByMetroArgs = {
  metroId: Scalars['Int']
}

export type QueryClientCustomizationsArgs = {
  clientName: Scalars['String']
}

export type QueryClientSocialsArgs = {
  clientName: Scalars['String']
  id: Scalars['Int']
}

export type QueryGetUnpublishedClientSocialsArgs = {
  clientName: Scalars['String']
}

export type QueryClientByNameArgs = {
  clientName: Scalars['String']
}

export type QueryCollectionsArgs = {
  clientName: Scalars['String']
}

export type QueryColorArgs = {
  colorId: Scalars['Int']
}

export type QueryCommunitiesArgs = {
  clientName: Scalars['String']
  communityIds?: Maybe<Array<Scalars['Int']>>
  active?: Maybe<Scalars['Boolean']>
  bonafide?: Maybe<Scalars['Boolean']>
  def?: Maybe<Scalars['Boolean']>
}

export type QueryCommunitiesByNameOrIdArgs = {
  clientName: Scalars['String']
  communityName?: Maybe<Scalars['String']>
  communityIds: Array<Maybe<Scalars['Int']>>
}

export type QueryCommunityArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
  planId?: Maybe<Scalars['Int']>
}

export type QueryCommunityByNameArgs = {
  clientName: Scalars['String']
  communityName: Scalars['String']
}

export type QueryCommunityBuildYourLotArgs = {
  clientName: Scalars['String']
}

export type QueryCommunityPoiCategoryArgs = {
  clientName: Scalars['String']
  poiCategoryId: Scalars['Int']
}

export type QueryCommunitySalesAppConfigArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
}

export type QueryCurrencyArgs = {
  clientName?: Maybe<Scalars['String']>
}

export type QueryCustomizationArgs = {
  clientName?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type QueryAreaMetricArgs = {
  clientName?: Maybe<Scalars['String']>
}

export type QueryCustomOverlaysArgs = {
  clientName: Scalars['String']
}

export type QueryDefaultCommunityArgs = {
  clientName: Scalars['String']
}

export type QueryElevationArgs = {
  clientName: Scalars['String']
  elevationId: Scalars['Int']
  planId?: Maybe<Scalars['Int']>
  communityId?: Maybe<Scalars['Int']>
}

export type QueryElevationsArgs = {
  clientName: Scalars['String']
  assignments?: Maybe<Array<ElevationAssignment>>
}

export type QueryFloorplansArgs = {
  clientName: Scalars['String']
}

export type QueryElevationByNameArgs = {
  clientName: Scalars['String']
  elevationId: Scalars['Int']
  planName: Scalars['String']
  communityName: Scalars['String']
}

export type QueryExcludedLotPlanElevationsArgs = {
  clientName: Scalars['String']
  lotId: Scalars['Int']
}

export type QueryExcludedLotSchemesArgs = {
  clientName: Scalars['String']
  lotId: Scalars['Int']
}

export type QueryExteriorTagsArgs = {
  clientName: Scalars['String']
}

export type QueryAllClientPlanTagsArgs = {
  clientName: Scalars['String']
}

export type QueryAllClientPlanTagCategoriesArgs = {
  clientName: Scalars['String']
}

export type QueryPlansByTagCategoryIdArgs = {
  clientName: Scalars['String']
  categoryId: Scalars['Int']
}

export type QueryTagsAssignedToPlanArgs = {
  clientName: Scalars['String']
  planId: Scalars['Int']
}

export type QueryPortalTagsAssignedToPlanArgs = {
  clientName: Scalars['String']
  planId: Scalars['Int']
}

export type QueryPlansByTagIdArgs = {
  clientName: Scalars['String']
  tagId: Scalars['Int']
}

export type QueryFloorplanArgs = {
  clientName: Scalars['String']
  floorplanId: Scalars['Int']
  communityId?: Maybe<Scalars['Int']>
}

export type QueryFloorplanAlternateArgs = {
  clientName: Scalars['String']
  floorplanAltId: Scalars['Int']
}

export type QueryFloorplansByElevationArgs = {
  clientName: Scalars['String']
  communityName: Scalars['String']
  planName: Scalars['String']
  elevationCaption: Scalars['String']
}

export type QueryFloorplansByElevationIdArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
  planId: Scalars['Int']
  elevationId: Scalars['Int']
}

export type QueryFpGroupArgs = {
  clientName: Scalars['String']
  fpGroupId: Scalars['Int']
}

export type QueryGalleryArgs = {
  clientName: Scalars['String']
  galleryId: Scalars['Int']
}

export type QueryHighlightArgs = {
  clientName: Scalars['String']
  highlightId: Scalars['Int']
}

export type QueryHomeCategoryArgs = {
  categoryId: Scalars['Int']
}

export type QueryHomesitesArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
  quickMoveInOnly?: Maybe<Scalars['Boolean']>
}

export type QueryHotspotArgs = {
  clientName: Scalars['String']
  siteplanHotspotId: Scalars['Int']
}

export type QueryIncludedInventoryFloorplansArgs = {
  clientName: Scalars['String']
  inventoryId: Scalars['Int']
}

export type QueryInteriorsArgs = {
  clientName: Scalars['String']
  interior?: Maybe<InteriorInput>
}

export type QueryInteriorArgs = {
  clientName: Scalars['String']
  interiorId?: Maybe<Scalars['Int']>
}

export type QueryActiveInteriorsArgs = {
  clientName: Scalars['String']
  interior?: Maybe<InteriorInput>
}

export type QueryActiveInteriorArgs = {
  clientName: Scalars['String']
  interiorId?: Maybe<Scalars['Int']>
}

export type QueryInteriorElementOptionsArgs = {
  clientName: Scalars['String']
  interiorListElementId?: Maybe<Scalars['Int']>
}

export type QueryInteriorElementArgs = {
  clientName: Scalars['String']
  interiorListElementId: Scalars['Int']
}

export type QueryInteriorListElementArgs = {
  clientName: Scalars['String']
  interiorListElementId: Scalars['Int']
}

export type QueryInteriorAssignmentsArgs = {
  clientName: Scalars['String']
  interiorAssignmentFilterInput?: Maybe<InteriorAssignmentFilterInput>
}

export type QueryInteriorPresetsArgs = {
  clientName: Scalars['String']
  interiorId: Scalars['Int']
}

export type QueryInteriorElementOptionAlternateGroupsArgs = {
  clientName: Scalars['String']
  interiorId: Scalars['Int']
}

export type QueryInventoriesArgs = {
  clientName: Scalars['String']
  inventoryIds: Array<Scalars['Int']>
}

export type QueryInventoryArgs = {
  clientName: Scalars['String']
  inventoryId: Scalars['Int']
}

export type QueryLibrariesArgs = {
  blendMode?: Maybe<BlendModeTypes>
}

export type QueryLotArgs = {
  clientName: Scalars['String']
  lotId: Scalars['Int']
}

export type QueryMasterCommunityByNameArgs = {
  clientName: Scalars['String']
  name: Scalars['String']
}

export type QueryMasterCommunityByIdArgs = {
  clientName: Scalars['String']
  id: Scalars['Int']
}

export type QueryMasterCommunitiesArgs = {
  clientName: Scalars['String']
}

export type QuerySubCommunitiesArgs = {
  clientName: Scalars['String']
  masterCommunityId: Scalars['Int']
}

export type QueryMasterCommunitySalesAppConfigArgs = {
  clientName: Scalars['String']
  masterCommunityId: Scalars['Int']
}

export type QueryMaterialsArgs = {
  clientName: Scalars['String']
}

export type QueryMetrosCitiesCommunitiesArgs = {
  clientName: Scalars['String']
}

export type QueryMlsServicesEnabledArgs = {
  clientName: Scalars['String']
}

export type QueryPaletteArgs = {
  clientName: Scalars['String']
  paletteId?: Maybe<Scalars['Int']>
}

export type QueryPalettesArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
}

export type QueryPlanArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  planId: Scalars['Int']
}

export type QueryPlanByNameArgs = {
  clientName: Scalars['String']
  communityName: Scalars['String']
  planName: Scalars['String']
}

export type QueryPlanExteriorAssetsArgs = {
  clientName: Scalars['String']
  planId: Scalars['Int']
}

export type QueryPlanInteriorPhotosArgs = {
  clientName: Scalars['String']
  planId: Scalars['Int']
}

export type QueryPlansArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
}

export type QueryPaginatedPlansArgs = {
  clientName: Scalars['String']
  communityIds?: Maybe<Array<Scalars['Int']>>
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy: Scalars['String']
  sortOrder: Scalars['String']
  filter?: Maybe<SpecFilter>
}

export type QueryPaginatedInventoryLotsArgs = {
  clientName: Scalars['String']
  communityIds: Array<Scalars['Int']>
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy: Scalars['String']
  filter?: Maybe<SpecFilter>
}

export type QueryPaginatedMetrosArgs = {
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy: Scalars['String']
  order: Scalars['String']
  filters?: Maybe<Scalars['JSON']>
}

export type QueryPaginatedCitiesArgs = {
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy: Scalars['String']
  order: Scalars['String']
  filters?: Maybe<Scalars['JSON']>
}

export type QueryPlansByCommunitiesArgs = {
  clientName: Scalars['String']
  communityIds: Array<Scalars['Int']>
}

export type QueryPlanTagsByCommunitiesArgs = {
  clientName: Scalars['String']
  communityIds: Array<Scalars['Int']>
}

export type QueryPmFloorplanArgs = {
  clientName: Scalars['String']
  pmFloorplanId: Scalars['Int']
}

export type QueryProspectByEmailArgs = {
  clientName: Scalars['String']
  email: Scalars['String']
}

export type QueryProspectByIdArgs = {
  clientName: Scalars['String']
  id: Scalars['Int']
}

export type QueryProspectBaseInfoArgs = {
  email: Scalars['String']
}

export type QueryProspectSgtAppointmentsArgs = {
  clientName: Scalars['String']
  prospectId: Scalars['Int']
  inventoryId?: Maybe<Scalars['Int']>
}

export type QuerySchemeArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
  schemeId: Scalars['Int']
}

export type QuerySchemesArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
}

export type QuerySearchCitiesArgs = {
  queryString: Scalars['String']
}

export type QuerySiteplanArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  communityName?: Maybe<Scalars['String']>
  masterCommunityId?: Maybe<Scalars['Int']>
  siteplanId?: Maybe<Scalars['Int']>
  siteplanName?: Maybe<Scalars['String']>
  active?: Maybe<Scalars['Boolean']>
}

export type QuerySiteplanByIdArgs = {
  clientName: Scalars['String']
  id: Scalars['Int']
}

export type QueryActiveSiteplanByCommunityIdArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
  master: Scalars['Boolean']
}

export type QuerySiteplansByCommunityIdArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
  master?: Maybe<Scalars['Boolean']>
  active?: Maybe<Scalars['Boolean']>
}

export type QuerySiteplansArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  master?: Maybe<Scalars['Boolean']>
  active?: Maybe<Scalars['Boolean']>
}

export type QuerySgtVendorsArgs = {
  clientName?: Maybe<Scalars['String']>
}

export type QuerySgtVendorArgs = {
  sgtVendorId: Scalars['Int']
}

export type QueryStdFeatureCategoriesArgs = {
  clientName: Scalars['String']
}

export type QueryStdFeaturesArgs = {
  clientName: Scalars['String']
  sortByName?: Maybe<Scalars['Boolean']>
}

export type QueryStdFeatureAssignmentsArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
}

export type QueryCommunityTagCategoriesArgs = {
  clientName: Scalars['String']
}

export type QueryCommunityTagsArgs = {
  clientName: Scalars['String']
  sortByName?: Maybe<Scalars['Boolean']>
}

export type QueryCommunityTagAssignmentsArgs = {
  clientName: Scalars['String']
}

export type QueryCommunityTagAssignmentsByCommunityIdArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
}

export type QueryPortalCommunityTagCategoriesArgs = {
  clientName: Scalars['String']
}

export type QueryPortalCommunityTagsArgs = {
  clientName: Scalars['String']
  sortByName?: Maybe<Scalars['Boolean']>
}

export type QueryPortalCommunityTagAssignmentsArgs = {
  clientName: Scalars['String']
}

export type QueryPortalCommunityTagAssignmentsByCommunityIdArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
}

export type QueryBuilderTagsArgs = {
  sortByName?: Maybe<Scalars['Boolean']>
}

export type QueryBuilderTagAssignmentsArgs = {
  clientName: Scalars['String']
}

export type QueryStoriesArgs = {
  clientName: Scalars['String']
}

export type QueryTextureArgs = {
  clientName?: Maybe<Scalars['String']>
  library?: Maybe<LibraryType>
  textureId: Scalars['Int']
}

export type QueryGetHomeSelectionArgs = {
  clientName: Scalars['String']
  prospectIdentifier?: Maybe<Scalars['String']>
  homeIdentifier?: Maybe<Scalars['String']>
  favoriteIdentifier?: Maybe<Scalars['Int']>
  communityName?: Maybe<Scalars['String']>
  planName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  prospectId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
}

export type QueryColonnadeApiProspectArgs = {
  clientName: Scalars['String']
  registrationDateTimeFilter?: Maybe<RegDateTimeFilter>
  communityIds?: Maybe<Array<Scalars['Int']>>
  excludeClientUsers?: Maybe<Scalars['Boolean']>
}

export type QueryColonnadeApiPaginatedProspectsArgs = {
  statuses?: Maybe<Array<ProspectStatus>>
  searchedText?: Maybe<Scalars['String']>
  leadFilter?: Maybe<LeadFilter>
  pagination?: Maybe<Pagination>
  orderBy?: Maybe<OrderBy>
  clientName: Scalars['String']
  registrationDateTimeFilter?: Maybe<RegDateTimeFilter>
  communityIds?: Maybe<Array<Scalars['Int']>>
  excludeClientUsers?: Maybe<Scalars['Boolean']>
}

export type QueryColonnadeApiProspectByEmailArgs = {
  clientName: Scalars['String']
  email: Scalars['String']
}

export type QueryGetProspectActivitiesArgs = {
  clientName: Scalars['String']
  email: Scalars['String']
}

export type QueryGetCommunityBuyOnlineCustomConfigArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
}

export type QueryGetNewLeadAgentNotificationConfigArgs = {
  clientName: Scalars['String']
}

export type QueryGetLeadBrowseAgentNotificationConfigArgs = {
  clientName: Scalars['String']
}

export type QueryGetOnlineReservationsArgs = {
  clientName: Scalars['String']
  filter?: Maybe<OnlineReservationFilterInput>
}

export type QueryGetReservationsDropOfRateArgs = {
  clientName: Scalars['String']
  filter?: Maybe<ReservationDropOfFilterInput>
}

export type QueryGetReservationStatusOverviewArgs = {
  clientName: Scalars['String']
  filter?: Maybe<ReservationStatusOverviewFilterInput>
}

export type QueryGetReservationStatusCommunitiesOverviewArgs = {
  clientName: Scalars['String']
  filter?: Maybe<ReservationStatusOverviewFilterInput>
}

export type QueryHasCommunityInProgressReservationArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
}

export type QueryGetDocuSignTemplatePreviewArgs = {
  clientId: Scalars['Int']
}

export type QueryGetDocumentLinkForBuilderArgs = {
  clientName: Scalars['String']
  reservationId: Scalars['UUID']
}

export type QueryGetReservationIntegrationArgs = {
  clientId: Scalars['Int']
}

export type QueryGetSubscriptionEndpointArgs = {
  clientName: Scalars['String']
}

export type QueryGetEmailCampaignArgs = {
  clientName: Scalars['String']
  emailCampaignId: Scalars['Int']
}

export type QueryGetEmailCampaignsArgs = {
  clientName: Scalars['String']
  shared?: Maybe<Scalars['Boolean']>
}

export type QueryVerifyAndFilterPlanCommunitiesArgs = {
  clientName: Scalars['String']
  topKPlans: Array<TopKPlans>
}

export type QueryGetIsLotSignLockedArgs = {
  clientName: Scalars['String']
  communityId: Scalars['Int']
  lotId: Scalars['Int']
}

export type QueryColonnadeUsersArgs = {
  clientName: Scalars['String']
}

export type QueryGetColonnadeClientSubscriptionInfoArgs = {
  clientId: Scalars['Int']
}

export type QueryGetClientEmailReportInfoArgs = {
  clientId: Scalars['Int']
}

export type QueryIsUserAnewgoAdminArgs = {
  userId: Scalars['Int']
}

export type QueryUserInfoArgs = {
  userId: Scalars['Int']
}

export type QueryUsersWithRolesArgs = {
  clientName: Scalars['String']
}

export type QueryGetFlagshipSubscriptionByClientIdArgs = {
  clientId: Scalars['Int']
}

export type QueryGetCommunitySubscriptionsByClientIdArgs = {
  clientId: Scalars['Int']
}

export type QueryClientsForUserArgs = {
  userId: Scalars['Int']
  statuses?: Maybe<Array<Maybe<Scalars['String']>>>
  kinds?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryAppConfigArgs = {
  app: Scalars['String']
}

export type QueryClientUserEmailsArgs = {
  clientId: Scalars['Int']
  includeAnewgoUsers?: Maybe<Scalars['Boolean']>
}

export type QueryRoleAppConfigsArgs = {
  clientName: Scalars['String']
}

export type QueryGetClientApiKeyArgs = {
  clientName: Scalars['String']
}

export type QueryAuthenticateByTokenArgs = {
  existingToken: Scalars['String']
  targetDomain: TargetDomainEnum
}

export type QueryAuthenticateArgs = {
  email: Scalars['String']
  password: Scalars['String']
  clientName?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
}

export type QueryAuthenticateProspectArgs = {
  clientName: Scalars['String']
  source: Scalars['String']
  accessToken: Scalars['String']
  identityProvider: IdentityProvider
  communityId?: Maybe<Scalars['Int']>
  planName?: Maybe<Scalars['String']>
  consultantName?: Maybe<Scalars['String']>
  consultantEmail?: Maybe<Scalars['String']>
  consultantPhone?: Maybe<Scalars['String']>
}

export type QueryAuthenticateApiKeyArgs = {
  apiKey: Scalars['String']
}

export type QueryCreateTokenArgs = {
  userInfo: Scalars['JSON']
  expiresIn?: Maybe<Scalars['String']>
}

export type QueryIsAuthorizedArgs = {
  token: Scalars['String']
  resourceClientName: Scalars['String']
  resourcePrivilege: Scalars['String']
  resourceName?: Maybe<Scalars['String']>
}

export type QueryIsExistingEmailArgs = {
  email: Scalars['String']
}

export type QueryValidateProspectTokenArgs = {
  token: Scalars['String']
  clientName: Scalars['String']
  hasConsent?: Maybe<Scalars['Boolean']>
}

export type QueryRenewTokenArgs = {
  token: Scalars['String']
}

export type QueryVerifyTokenArgs = {
  token: Scalars['String']
}

export type QueryIsUserSuperAdminArgs = {
  userId: Scalars['Int']
}

export type QueryUserHasSuperClientRoleArgs = {
  userId: Scalars['Int']
}

export type QueryPageViewsArgs = {
  filter: PageViewsFilter
}

export type QuerySessionsArgs = {
  filter: SessionFilter
}

export type QuerySessionsSummaryArgs = {
  filter: SessionFilter
}

export type QuerySessionsSummaryTrendsArgs = {
  filter: SessionFilter
}

export type QueryEngagementRateArgs = {
  filter: SessionFilter
}

export type QuerySessionsSummaryTrendsByCommunityArgs = {
  filter: SessionFilter
}

export type QueryPopularCommunitiesArgs = {
  filter: CommonQueryFilter
}

export type QueryPopularHoursArgs = {
  filter: CommonQueryFilter
}

export type QueryPopularLotsArgs = {
  filter: CommonQueryFilter
}

export type QueryPopularFloorplansArgs = {
  filter: CommonQueryFilter
}

export type QueryPopularMaterialColorsArgs = {
  filter: CommonQueryFilter
}

export type QueryPopularInteriorSelectionsArgs = {
  clientName: Scalars['String']
  filter: CommonQueryFilter
}

export type QueryCamberPopularMaterialColorsArgs = {
  filter: CommonQueryFilter
}

export type QueryGetCamberUsageSummaryArgs = {
  filter: CommonQueryFilter
}

export type QueryPopularSearchesArgs = {
  filter: PopularSearchFilter
}

export type QueryPopularColorSchemesArgs = {
  filter: CommonQueryFilter
}

export type QueryCamberPopularColorSchemesArgs = {
  filter: CommonQueryFilter
}

export type QueryGetColorTrendsArgs = {
  filter: ColorTrendsFilter
}

export type QueryGetCommunitiesTrendsArgs = {
  filter: CommunitiesTrendsFilter
}

export type QueryGetElevationTrendsArgs = {
  filter: CommonQueryFilter
}

export type QueryGetFloorplanTrendsArgs = {
  filter: CommonQueryFilter
}

export type QueryLeadRankingsArgs = {
  filter: LeadsQueryFilter
  pagination?: Maybe<Pagination>
  orderBy?: Maybe<OrderBy>
}

export type QueryProspectAnalyticsArgs = {
  filter: LeadsQueryFilter
  clientName: Scalars['String']
}

export type QueryVisitorTrendsArgs = {
  filter: VisitorTrendsQueryFilter
}

export type QueryVisitorTimesArgs = {
  filter: CommonQueryFilter
  minuteUTCDifference: Scalars['Int']
}

export type QueryLeadTimeRankingArgs = {
  clientName: Scalars['String']
  leadEmail: Scalars['String']
  timeFrame: Scalars['String']
}

export type QueryLeadsByElevationArgs = {
  filter: LeadsQueryFilter
}

export type QueryLeadsByProductArgs = {
  filter: LeadsByProductQueryFilter
  clientName: Scalars['String']
}

export type QueryIndustrySessionTrendsArgs = {
  filter: CommonQueryFilter
}

export type QueryIndustrySessionSummaryArgs = {
  filter: CommonQueryFilter
}

export type QueryPopularFloorPlanOptionsArgs = {
  filter: CommonQueryFilter
}

export type QueryGetSchemeTrendsArgs = {
  filter: CommonQueryFilter
}

export type QueryPopularElevationsArgs = {
  filter: CommonQueryFilter
}

export type QueryPopularElevationSpecsArgs = {
  filter: CommonQueryFilter
}

export type QueryPopularIndustryElevationSpecsArgs = {
  filter: CommonQueryFilter
}

export type QueryGetCamberElevationTrendsArgs = {
  filter: CommonQueryFilter
}

export type QueryGetCamberUsersLoginsArgs = {
  filter: CommonQueryFilter
}

export type QueryGetCamberFloorplanTrendsArgs = {
  filter: CommonQueryFilter
}

export type QueryCamberPopularLotsArgs = {
  filter: CommonQueryFilter
}

export type QueryGetVisitorLocationsArgs = {
  filter: CommonQueryFilter
}

export type QueryExcludedIpsArgs = {
  clientName: Scalars['String']
}

export type QueryGetFavoritesArgs = {
  filter: LeadsQueryFilter
}

export type QueryGetMfRecommendedPlansArgs = {
  clientName: Scalars['String']
  filter: Array<Maybe<MfRecommendedPlansInput>>
  plansToBeExcluded: Array<Scalars['Int']>
  topK: Scalars['Int']
  leadEmail: Scalars['String']
}

export type QueryGetLotLeadsArgs = {
  filter: CommonQueryFilter
  siteplanId: Scalars['Int']
  lotId: Scalars['Int']
}

export type QueryGetLotClicksArgs = {
  filter: CommonQueryFilter
  siteplanId: Scalars['Int']
  lotId: Scalars['Int']
}

export type QueryPageReferralsArgs = {
  filter: CommonQueryFilter
}

export type QueryGetCommunitiesByLocationArgs = {
  filter?: Maybe<CommonQueryFilter>
}

export type QueryGetEnvelopesCreatedArgs = {
  filter: CommonQueryFilter
}

export type QueryArgs = {
  startTime: Scalars['Timestamp']
  endTime: Scalars['Timestamp']
  metric?: Maybe<PopularityMetric>
  limit?: Maybe<Scalars['Int']>
}

export type ReceivedFavorites = {
  __typename?: 'ReceivedFavorites'
  sentByProspectName: Scalars['String']
  sentByProspectEmail: Scalars['String']
  sentByProspectId: Scalars['Int']
  receivedByProspectName: Scalars['String']
  receivedByProspectEmail: Scalars['String']
  clientId: Scalars['Int']
  clientAltName: Scalars['String']
}

export type RecommendedPlan = {
  communityId: Scalars['Int']
  communityName: Scalars['String']
  elevationCaption: Scalars['String']
  elevationId: Scalars['Int']
  planId: Scalars['Int']
  planName: Scalars['String']
  thumb: Scalars['String']
  bed: Scalars['Int']
  bath: Scalars['Float']
  size: Scalars['Int']
}

export type RecommendedTopKPlans = {
  __typename?: 'RecommendedTopKPlans'
  communityId: Scalars['Int']
  communityName: Scalars['String']
  elevationCaption: Scalars['String']
  elevationId: Scalars['Int']
  thumb?: Maybe<Scalars['String']>
  planId: Scalars['Int']
  planName: Scalars['String']
  bed?: Maybe<Scalars['Int']>
  bath?: Maybe<Scalars['Float']>
  size?: Maybe<Scalars['Int']>
}

export type RegDateTimeFilter = {
  start?: Maybe<Scalars['Date']>
  end?: Maybe<Scalars['Date']>
}

export type RemovedInteriorElementOptionAlternate = {
  __typename?: 'RemovedInteriorElementOptionAlternate'
  interiorId: Scalars['Int']
  primaryElementOptionId: Scalars['Int']
  memberElementOptionId: Scalars['Int']
  elementOptionGroupId: Scalars['Int']
}

export type RemoveInteriorElementOptionAlternateInput = {
  interiorId: Scalars['Int']
  primaryElementOptionId: Scalars['Int']
  memberElementOptionId: Scalars['Int']
  elementOptionGroupId: Scalars['Int']
}

export enum ReservationBuilderStatus {
  InProgress = 'IN_PROGRESS',
  ApprovedByBuilder = 'APPROVED_BY_BUILDER',
  RejectedByBuilder = 'REJECTED_BY_BUILDER',
  RejectedByProspect = 'REJECTED_BY_PROSPECT',
  ReservationExpired = 'RESERVATION_EXPIRED',
}

export type ReservationDropOfFilterInput = {
  isPrimaryBuyer: Scalars['Boolean']
  fromDate: Scalars['Date']
  toDate: Scalars['Date']
}

export type ReservationDropOfSteps = {
  __typename?: 'ReservationDropOfSteps'
  termsAndCondition: Scalars['Int']
  signedDocument: Scalars['Int']
  payment?: Maybe<Scalars['Int']>
  completed: Scalars['Int']
}

export type ReservationIntegrationBaseInfo = {
  __typename?: 'ReservationIntegrationBaseInfo'
  id: Scalars['Int']
  nonRefundableAmount?: Maybe<Scalars['Int']>
  depositAmount?: Maybe<Scalars['Int']>
  anewgoFee?: Maybe<Scalars['Int']>
  primaryBuyOnlineContactEmail?: Maybe<Scalars['EmailAddress']>
  primaryBuyOnlineContactName?: Maybe<Scalars['String']>
  daysToProveFinancing?: Maybe<Scalars['Int']>
}

export type ReservationStatus = {
  __typename?: 'ReservationStatus'
  id: Scalars['UUID']
  isPrimaryBuyer: Scalars['Boolean']
  currentStep: ReservationStep
  builderStatus?: Maybe<ReservationBuilderStatus>
  favorite: ProspectFavorite
  onlineReservations: Array<ProspectReservation>
  rejectReason?: Maybe<Scalars['String']>
}

export type ReservationStatusFavoriteArgs = {
  clientName: Scalars['String']
}

export type ReservationStatusOnlineReservationsArgs = {
  clientName: Scalars['String']
}

export type ReservationStatusCommunityOverview = {
  __typename?: 'ReservationStatusCommunityOverview'
  communityName: Scalars['String']
  status: ReservationStatusOverview
}

export type ReservationStatusOverview = {
  __typename?: 'ReservationStatusOverview'
  inProgress: Scalars['Int']
  approvedByBuilder: Scalars['Int']
  rejectedByBuilder: Scalars['Int']
  rejectedByProspect: Scalars['Int']
}

export type ReservationStatusOverviewFilterInput = {
  fromDate: Scalars['Date']
  toDate: Scalars['Date']
}

export enum ReservationStep {
  TermsAndConditions = 'TERMS_AND_CONDITIONS',
  SignDocument = 'SIGN_DOCUMENT',
  MakePayment = 'MAKE_PAYMENT',
  BuilderApproval = 'BUILDER_APPROVAL',
  Done = 'DONE',
}

export enum ResourcePrivilege {
  Public = 'PUBLIC',
  AnewgoApps = 'ANEWGO_APPS',
  Protected = 'PROTECTED',
  Private = 'PRIVATE',
  ClientAccount = 'CLIENT_ACCOUNT',
  System = 'SYSTEM',
}

export type RoleAppConfig = {
  __typename?: 'RoleAppConfig'
  roleId: Scalars['Int']
  app: Scalars['String']
  config: Scalars['JSON']
}

export type RoleAppConfigKey = {
  __typename?: 'RoleAppConfigKey'
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type RoleInfo = {
  __typename?: 'RoleInfo'
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type Scheme = {
  __typename?: 'Scheme'
  id: Scalars['Int']
  clientName?: Maybe<Scalars['String']>
  name: Scalars['String']
  internalName?: Maybe<Scalars['String']>
  cost?: Maybe<Scalars['Int']>
  materials: Array<SchemeElement>
  communities: Array<Community>
  communityElevations: Array<CommunityElevationInfo>
  count?: Maybe<Scalars['Int']>
  popularity?: Maybe<Scalars['Float']>
}

export type SchemeMaterialsArgs = {
  clientName?: Maybe<Scalars['String']>
}

export type SchemeCommunitiesArgs = {
  clientName?: Maybe<Scalars['String']>
}

export type SchemeCommunityElevationsArgs = {
  clientName?: Maybe<Scalars['String']>
}

export type SchemeElement = {
  __typename?: 'SchemeElement'
  id: Scalars['Int']
  colorId?: Maybe<Scalars['Int']>
  brickId?: Maybe<Scalars['Int']>
  stoneId?: Maybe<Scalars['Int']>
  customOverlayId?: Maybe<Scalars['Int']>
  materialId: Scalars['Int']
  material: Scalars['String']
  materialCategory?: Maybe<Scalars['String']>
  name: Scalars['String']
  displayName?: Maybe<Scalars['String']>
  blendMode?: Maybe<BlendModeTypes>
  hex?: Maybe<Scalars['String']>
  swatch?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  masonryLib?: Maybe<Scalars['Boolean']>
  paletteIds: Array<Scalars['Int']>
}

export type SchemeElementPaletteIdsArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
}

export type SchemeElementInput = {
  materialId: Scalars['Int']
  blendMode: BlendModeTypes
  colorId?: Maybe<Scalars['Int']>
  brickId?: Maybe<Scalars['Int']>
  stoneId?: Maybe<Scalars['Int']>
  customOverlayId?: Maybe<Scalars['Int']>
  paletteIds?: Maybe<Array<Scalars['Int']>>
}

export type SchemeInput = {
  id: Scalars['Int']
}

export type SchemeTrend = {
  __typename?: 'SchemeTrend'
  clientName?: Maybe<Scalars['String']>
  schemeId?: Maybe<Scalars['Int']>
  schemeName?: Maybe<Scalars['String']>
  scheme?: Maybe<Scheme>
  clicks?: Maybe<Scalars['Int']>
  brochure?: Maybe<Scalars['Int']>
  cta?: Maybe<Scalars['Int']>
  count?: Maybe<Scalars['Int']>
  popularity?: Maybe<Scalars['Int']>
  dailyMetrics?: Maybe<TimeSeries>
}

export type SchemeTrends = {
  __typename?: 'SchemeTrends'
  clientName: Scalars['String']
  startTimestamp: Scalars['Timestamp']
  endTimestamp: Scalars['Timestamp']
  schemes?: Maybe<Array<SchemeTrend>>
}

export type Session = {
  __typename?: 'Session'
  id: Scalars['String']
  startAt: Scalars['Timestamp']
  endAt: Scalars['Timestamp']
  duration: Scalars['Int']
  ipAddress?: Maybe<Scalars['String']>
  location?: Maybe<Location>
}

export type SessionBasedTimeSeriesFrame = {
  __typename?: 'SessionBasedTimeSeriesFrame'
  date: Scalars['Timestamp']
  durationSum?: Maybe<Scalars['Float']>
  durationAvg?: Maybe<Scalars['Float']>
  durationMin?: Maybe<Scalars['Float']>
  durationMax?: Maybe<Scalars['Float']>
  durationMedian?: Maybe<Scalars['Float']>
  eventsCount?: Maybe<Scalars['Int']>
  sessionsCount?: Maybe<Scalars['Int']>
}

export type SessionFilter = {
  baseInfo: BaseFilter
  includeDurationZero?: Maybe<Scalars['Boolean']>
  anonymous_ids?: Maybe<Array<Maybe<Scalars['String']>>>
  community_ids?: Maybe<Array<Maybe<Scalars['Int']>>>
  email?: Maybe<Scalars['String']>
}

export type SessionsSummary = SessionsSummaryIf & {
  __typename?: 'SessionsSummary'
  clientName: Scalars['String']
  startAt: Scalars['Float']
  endAt: Scalars['Float']
  sessions?: Maybe<Scalars['Int']>
  visitors?: Maybe<Scalars['Int']>
  returnVisitors?: Maybe<Scalars['Int']>
  newVisitors?: Maybe<Scalars['Int']>
  averageDuration?: Maybe<Scalars['Float']>
  bounceRate?: Maybe<Scalars['Float']>
  registered?: Maybe<Scalars['Int']>
}

export type SessionsSummaryByCommunity = {
  __typename?: 'SessionsSummaryByCommunity'
  communityId?: Maybe<Scalars['Int']>
  stats?: Maybe<Array<Maybe<SessionTrendsStats>>>
}

export type SessionsSummaryDate = SessionsSummaryIf & {
  __typename?: 'SessionsSummaryDate'
  clientName: Scalars['String']
  date: Scalars['String']
  sessions?: Maybe<Scalars['Int']>
  visitors?: Maybe<Scalars['Int']>
  returnVisitors?: Maybe<Scalars['Int']>
  newVisitors?: Maybe<Scalars['Int']>
  averageDuration?: Maybe<Scalars['Float']>
  bounceRate?: Maybe<Scalars['Float']>
  registered?: Maybe<Scalars['Int']>
}

export type SessionsSummaryIf = {
  clientName: Scalars['String']
  sessions?: Maybe<Scalars['Int']>
  visitors?: Maybe<Scalars['Int']>
  returnVisitors?: Maybe<Scalars['Int']>
  newVisitors?: Maybe<Scalars['Int']>
  averageDuration?: Maybe<Scalars['Float']>
  bounceRate?: Maybe<Scalars['Float']>
  registered?: Maybe<Scalars['Int']>
}

export type SessionTrendsStats = {
  __typename?: 'SessionTrendsStats'
  date: Scalars['String']
  numSessions?: Maybe<Scalars['Int']>
  avgDuration?: Maybe<Scalars['Float']>
  numBounces?: Maybe<Scalars['Float']>
  numVisitors?: Maybe<Scalars['Int']>
  returnVisitors?: Maybe<Scalars['Int']>
  newVisitors?: Maybe<Scalars['Int']>
  numRegistered?: Maybe<Scalars['Int']>
}

export type SgtVendor = {
  __typename?: 'SgtVendor'
  id: Scalars['Int']
  icon?: Maybe<Scalars['String']>
  logo?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  extendedData?: Maybe<Scalars['JSON']>
  clients: Array<Client>
}

export type SgtVendorBuilderInput = {
  sgtVendorId: Scalars['Int']
  clientId: Scalars['Int']
}

export type Siteplan = {
  __typename?: 'Siteplan'
  id: Scalars['Int']
  communityId?: Maybe<Scalars['Int']>
  masterCommunityId?: Maybe<Scalars['Int']>
  collectionId?: Maybe<Scalars['Int']>
  lotFontSize?: Maybe<Scalars['Int']>
  lotMetric?: Maybe<Scalars['String']>
  lotWidth?: Maybe<Scalars['Int']>
  lotHeight?: Maybe<Scalars['Int']>
  master?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  displayName?: Maybe<Scalars['String']>
  src?: Maybe<Scalars['String']>
  svg?: Maybe<Svg>
  active?: Maybe<Scalars['Boolean']>
  siteplanType?: Maybe<SiteplanTypes>
  unreleasedLotsHandle?: Maybe<UnreleasedLotsHandleTypes>
  showUnreleasedLotNumber?: Maybe<Scalars['Boolean']>
  geoInfo?: Maybe<SiteplanGeoInfo>
  info?: Maybe<SiteplanInfo>
  lots: Array<Lot>
  lotLegend: Array<LotLegendEntry>
  hotspots: Array<SiteplanHotspot>
  subSiteplans?: Maybe<Array<Siteplan>>
  popularLots?: Maybe<Array<Maybe<Lot>>>
}

export type SiteplanGeoInfoArgs = {
  clientName: Scalars['String']
}

export type SiteplanInfoArgs = {
  clientName: Scalars['String']
}

export type SiteplanLotsArgs = {
  clientName: Scalars['String']
  sgtOnly?: Maybe<Scalars['Boolean']>
}

export type SiteplanLotLegendArgs = {
  clientName?: Maybe<Scalars['String']>
}

export type SiteplanHotspotsArgs = {
  clientName?: Maybe<Scalars['String']>
}

export type SiteplanSubSiteplansArgs = {
  clientName: Scalars['String']
  active?: Maybe<Scalars['Boolean']>
}

export type SiteplanPopularLotsArgs = {
  filter: CommonQueryFilter
}

export type SiteplanGeoInfo = {
  __typename?: 'SiteplanGeoInfo'
  id: Scalars['Int']
  siteplanId: Scalars['Int']
  neLatitude?: Maybe<Scalars['Float']>
  neLongitude?: Maybe<Scalars['Float']>
  swLatitude?: Maybe<Scalars['Float']>
  swLongitude?: Maybe<Scalars['Float']>
  geoJson?: Maybe<Scalars['JSON']>
}

export type SiteplanHotspot = {
  __typename?: 'SiteplanHotspot'
  id: Scalars['Int']
  siteplanId: Scalars['Int']
  name?: Maybe<Scalars['String']>
  width?: Maybe<Scalars['Int']>
  height?: Maybe<Scalars['Int']>
  thumb?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  x?: Maybe<Scalars['Float']>
  y?: Maybe<Scalars['Float']>
  assets: Array<SiteplanHotspotAsset>
}

export type SiteplanHotspotAssetsArgs = {
  clientName?: Maybe<Scalars['String']>
}

export type SiteplanHotspotAsset = {
  __typename?: 'SiteplanHotspotAsset'
  id: Scalars['Int']
  siteplanHotspotId: Scalars['Int']
  listIndex?: Maybe<Scalars['Int']>
  src?: Maybe<Scalars['String']>
  videoUrl?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  info?: Maybe<Scalars['JSON']>
}

export type SiteplanHotspotAssetInput = {
  description?: Maybe<Scalars['String']>
  videoUrl?: Maybe<Scalars['String']>
  fileName?: Maybe<Scalars['String']>
}

export type SiteplanHotspotInput = {
  name?: Maybe<Scalars['String']>
  x?: Maybe<Scalars['Float']>
  y?: Maybe<Scalars['Float']>
  width?: Maybe<Scalars['Int']>
  height?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  fileName?: Maybe<Scalars['String']>
}

export type SiteplanInfo = {
  __typename?: 'SiteplanInfo'
  siteplanId: Scalars['Int']
  thumb?: Maybe<Scalars['String']>
  fontSize: Scalars['Int']
  x: Scalars['Float']
  y: Scalars['Float']
  whiteLabel: Scalars['Boolean']
  showsThumb: Scalars['Boolean']
}

export type SiteplanInfoInput = {
  clientDirName?: Maybe<Scalars['String']>
  fileName?: Maybe<Scalars['String']>
  fontSize?: Maybe<Scalars['Int']>
  x?: Maybe<Scalars['Float']>
  y?: Maybe<Scalars['Float']>
  whiteLabel?: Maybe<Scalars['Boolean']>
  showsThumb?: Maybe<Scalars['Boolean']>
}

export enum SiteplanTypes {
  Image = 'IMAGE',
  Geo = 'GEO',
}

export type SpecFilter = {
  minSqft?: Maybe<Scalars['Int']>
  maxSqft?: Maybe<Scalars['Int']>
  minCost?: Maybe<Scalars['Int']>
  maxCost?: Maybe<Scalars['Int']>
  minBed?: Maybe<Scalars['Float']>
  maxBed?: Maybe<Scalars['Float']>
  minBath?: Maybe<Scalars['Int']>
  maxBath?: Maybe<Scalars['Int']>
  planTagIds?: Maybe<Array<Scalars['Int']>>
  sgtOnly?: Maybe<Scalars['Boolean']>
  stories?: Maybe<Array<Scalars['Int']>>
  exteriorTags?: Maybe<Array<Scalars['Int']>>
  activeSiteplanOnly?: Maybe<Scalars['Boolean']>
}

export type Specification = {
  __typename?: 'Specification'
  value: Scalars['Float']
  count: Scalars['Int']
  durationInfo?: Maybe<DurationInfo>
}

export type SrcAndIndex = {
  src: Scalars['String']
  listIndex: Scalars['Int']
}

export type StandardFeatureAssignmentInput = {
  categoryId: Scalars['Int']
  featureId: Scalars['Int']
  communityId: Scalars['Int']
}

export type State = {
  __typename?: 'State'
  id: Scalars['Int']
  code: Scalars['String']
  name: Scalars['String']
}

export type StdFeature = {
  __typename?: 'StdFeature'
  id: Scalars['Int']
  categoryId?: Maybe<Scalars['Int']>
  name: Scalars['String']
  listIndex?: Maybe<Scalars['Int']>
}

export type StdFeatureAssignment = {
  __typename?: 'StdFeatureAssignment'
  communityId: Scalars['Int']
  categoryId: Scalars['Int']
  featureId: Scalars['Int']
  name?: Maybe<Scalars['String']>
  categoryName?: Maybe<Scalars['String']>
}

export type StdFeatureCategory = {
  __typename?: 'StdFeatureCategory'
  id: Scalars['Int']
  name: Scalars['String']
  listIndex?: Maybe<Scalars['Int']>
  features: Array<StdFeature>
}

export type StdFeatureCategoryFeaturesArgs = {
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  communityName?: Maybe<Scalars['String']>
}

export type StdTagsInput = {
  id: Scalars['Int']
  categoryId: Scalars['Int']
}

export type Story = {
  __typename?: 'Story'
  id: Scalars['Int']
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  communityId?: Maybe<Scalars['Int']>
  fnum: Scalars['Int']
  name: Scalars['String']
  optional?: Maybe<Scalars['Boolean']>
  fpGroups: Array<FloorplanGroup>
  floorplans: Array<Floorplan>
}

export type StoryFpGroupsArgs = {
  clientName: Scalars['String']
}

export type StoryFloorplansArgs = {
  clientName: Scalars['String']
}

export type Svg = {
  __typename?: 'SVG'
  viewBox: SvgViewBox
  style?: Maybe<Scalars['JSON']>
  frame?: Maybe<SvgShapeAttributes>
  shapes: Array<SvgShape>
}

export type SvgInput = {
  viewBox: SvgViewBoxInput
  style?: Maybe<Scalars['JSON']>
  frame?: Maybe<SvgShapeAttributesInput>
  shapes: Array<SvgShapeInput>
}

export type SvgShape = {
  __typename?: 'SVGShape'
  tagName: Scalars['String']
  attributes: SvgShapeAttributes
}

export type SvgShapeAttributes = {
  __typename?: 'SVGShapeAttributes'
  className?: Maybe<Scalars['String']>
  dataName?: Maybe<Scalars['String']>
  x?: Maybe<Scalars['String']>
  y?: Maybe<Scalars['String']>
  width?: Maybe<Scalars['String']>
  height?: Maybe<Scalars['String']>
  transform?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['String']>
  d?: Maybe<Scalars['String']>
}

export type SvgShapeAttributesInput = {
  className?: Maybe<Scalars['String']>
  dataName?: Maybe<Scalars['String']>
  x?: Maybe<Scalars['String']>
  y?: Maybe<Scalars['String']>
  width?: Maybe<Scalars['String']>
  height?: Maybe<Scalars['String']>
  transform?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['String']>
  d?: Maybe<Scalars['String']>
  fill?: Maybe<Scalars['String']>
  fontSize?: Maybe<Scalars['String']>
  fontFamily?: Maybe<Scalars['String']>
  fontWeight?: Maybe<Scalars['String']>
  letterSpacing?: Maybe<Scalars['String']>
  stroke?: Maybe<Scalars['String']>
  strokeLinecap?: Maybe<Scalars['String']>
  strokeMiterlimit?: Maybe<Scalars['String']>
  style?: Maybe<Scalars['String']>
}

export type SvgShapeInput = {
  tagName: Scalars['String']
  attributes: SvgShapeAttributesInput
  content?: Maybe<Scalars['String']>
  hash?: Maybe<Scalars['String']>
}

export type SvgViewBox = {
  __typename?: 'SVGViewBox'
  x: Scalars['String']
  y: Scalars['String']
  width: Scalars['String']
  height: Scalars['String']
}

export type SvgViewBoxInput = {
  x: Scalars['String']
  y: Scalars['String']
  width: Scalars['String']
  height: Scalars['String']
}

export enum TargetDomainEnum {
  Colonnade = 'COLONNADE',
  Flagship = 'FLAGSHIP',
}

export type Texture = {
  __typename?: 'Texture'
  id: Scalars['Int']
  library?: Maybe<LibraryType>
  status?: Maybe<LibraryStatus>
  vendorId?: Maybe<Scalars['Int']>
  vendorName?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  color: Scalars['String']
  alt1?: Maybe<Scalars['Int']>
  alt2?: Maybe<Scalars['Int']>
  client?: Maybe<Scalars['String']>
  src: Scalars['String']
  c4d?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
}

export type TextureDataInput = {
  brickId?: Maybe<Scalars['Int']>
  stoneId?: Maybe<Scalars['Int']>
  customOverlayId?: Maybe<Scalars['Int']>
}

export enum TextureLibraryType {
  Brick = 'BRICK',
  Stone = 'STONE',
}

export enum TimeField {
  FirstSeen = 'FIRST_SEEN',
  LastSeen = 'LAST_SEEN',
}

export type TimeSeries = {
  __typename?: 'TimeSeries'
  frames?: Maybe<Array<TimeSeriesFrame>>
}

export type TimeSeriesFrame =
  | EventBasedTimeSeriesFrame
  | SessionBasedTimeSeriesFrame

export type TopKPlans = {
  communityId?: Maybe<Scalars['Int']>
  communityName?: Maybe<Scalars['String']>
  elevationCaption?: Maybe<Scalars['String']>
  elevationId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
  planName?: Maybe<Scalars['String']>
}

export enum UnreleasedLotsHandleTypes {
  ShowBlank = 'SHOW_BLANK',
  DontShow = 'DONT_SHOW',
}

export type UpdateAgentInput = {
  id: Scalars['Int']
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  picture?: Maybe<Scalars['String']>
  listIndex?: Maybe<Scalars['Int']>
  communityId?: Maybe<Scalars['Int']>
}

export type UpdateBuilderPageInput = {
  id?: Maybe<Scalars['Int']>
  template?: Maybe<BuilderPageTemplates>
  slogan?: Maybe<Scalars['String']>
  about?: Maybe<Scalars['String']>
  learnMore?: Maybe<Scalars['String']>
  learnMoreLinkText?: Maybe<Scalars['String']>
  learnMoreLinkUrl?: Maybe<Scalars['String']>
  heroSrc?: Maybe<Scalars['String']>
  altLogoSrc?: Maybe<Scalars['String']>
  primaryColor?: Maybe<Scalars['String']>
  secondaryColor?: Maybe<Scalars['String']>
}

export type UpdateCityInput = {
  id: Scalars['Int']
  name?: Maybe<Scalars['String']>
  lat?: Maybe<Scalars['Float']>
  lon?: Maybe<Scalars['Float']>
  metroId?: Maybe<Scalars['Int']>
  countyId?: Maybe<Scalars['Int']>
  zipCode?: Maybe<Scalars['Int']>
  districtId?: Maybe<Scalars['Int']>
  postCode?: Maybe<Scalars['String']>
}

export type UpdateColonnadeUserInput = {
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  organizationRole?: Maybe<Scalars['String']>
  licensed?: Maybe<Scalars['Boolean']>
  roleId?: Maybe<Scalars['Int']>
}

export type UpdateColorInput = {
  id: Scalars['Int']
  name?: Maybe<Scalars['String']>
  identifier?: Maybe<Scalars['String']>
  hex?: Maybe<Scalars['String']>
  notes?: Maybe<Scalars['String']>
}

export type UpdateCommunityFloorplanExternalIdInput = {
  floorplanId: Scalars['Int']
  communityId: Scalars['Int']
  elevationId: Scalars['Int']
  planId: Scalars['Int']
  externalId?: Maybe<Scalars['String']>
}

export type UpdateCommunityInfoInput = {
  metroId?: Maybe<Scalars['Int']>
  cityId?: Maybe<Scalars['Int']>
  latitude?: Maybe<Scalars['Float']>
  longitude?: Maybe<Scalars['Float']>
  logo?: Maybe<Scalars['String']>
  logoWhite?: Maybe<Scalars['String']>
  mapMarker?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
}

export type UpdateCommunityInput = {
  externalId?: Maybe<Scalars['String']>
  buildYourLot?: Maybe<Scalars['Boolean']>
  bonafide?: Maybe<Scalars['Boolean']>
  def?: Maybe<Scalars['Boolean']>
  active?: Maybe<Scalars['Boolean']>
  pricing?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  colormtd?: Maybe<Scalars['String']>
  communityType?: Maybe<CommunityType>
  sortType?: Maybe<Scalars['String']>
  sortOrder?: Maybe<Scalars['String']>
  caption?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  thumb?: Maybe<Scalars['String']>
  crmId?: Maybe<Scalars['String']>
  salesCenterActive?: Maybe<Scalars['Boolean']>
  internalNotes?: Maybe<Scalars['String']>
}

export type UpdateCommunityPoiCategoryInput = {
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  icon?: Maybe<Scalars['String']>
}

export type UpdateCommunityPoiInput = {
  id?: Maybe<Scalars['Int']>
  address?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  latitude?: Maybe<Scalars['Float']>
  longitude?: Maybe<Scalars['Float']>
}

export type UpdateElevationInput = {
  externalId?: Maybe<Scalars['String']>
  caption?: Maybe<Scalars['String']>
  thumb?: Maybe<Scalars['String']>
  base?: Maybe<Scalars['String']>
  defaultFloor?: Maybe<Scalars['Int']>
  internalName?: Maybe<Scalars['String']>
  internalNotes?: Maybe<Scalars['String']>
  bed?: Maybe<Scalars['Int']>
  computeBed?: Maybe<Scalars['Boolean']>
  bedMin?: Maybe<Scalars['Int']>
  bedMax?: Maybe<Scalars['Int']>
  bath?: Maybe<Scalars['Float']>
  computeBath?: Maybe<Scalars['Boolean']>
  bathMin?: Maybe<Scalars['Float']>
  bathMax?: Maybe<Scalars['Float']>
  size?: Maybe<Scalars['Int']>
  computeSize?: Maybe<Scalars['Boolean']>
  sizeMin?: Maybe<Scalars['Int']>
  sizeMax?: Maybe<Scalars['Int']>
  cost?: Maybe<Scalars['Int']>
  computeCost?: Maybe<Scalars['Boolean']>
  costMin?: Maybe<Scalars['Int']>
  costMax?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  mirrorElevationId?: Maybe<Scalars['Int']>
  mirrorRolePrimary?: Maybe<Scalars['Boolean']>
  active?: Maybe<Scalars['Boolean']>
  svgMirroring?: Maybe<Scalars['Boolean']>
  garagePosition?: Maybe<GaragePosition>
}

export type UpdateElevationsInput = {
  elevationId: Scalars['Int']
  communityId?: Maybe<Scalars['Int']>
  svgMirroring?: Maybe<Scalars['Boolean']>
  garagePosition?: Maybe<GaragePosition>
}

export type UpdateFloorplanInput = {
  id: Scalars['Int']
  base?: Maybe<Scalars['Boolean']>
  optional?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  cost?: Maybe<Scalars['Int']>
  communityId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  labelSvg?: Maybe<SvgInput>
  size?: Maybe<Scalars['Int']>
  bed?: Maybe<Scalars['Int']>
  bath?: Maybe<Scalars['Float']>
  src?: Maybe<Scalars['String']>
  notes?: Maybe<Scalars['String']>
  lastUpdateTime?: Maybe<Scalars['Date']>
  externalId?: Maybe<Scalars['String']>
  communityFloorplanExternalId?: Maybe<Scalars['String']>
  storyId?: Maybe<Scalars['Int']>
}

export type UpdateHomeStylePhotoSortInput = {
  homeStyleId: Scalars['Int']
  oldIndex: Scalars['Int']
  newIndex: Scalars['Int']
}

export type UpdateInteriorElementInput = {
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  maskSrc?: Maybe<Scalars['String']>
  optionType?: Maybe<InteriorElementOptionType>
  listIndex?: Maybe<Scalars['Int']>
  renderIndex?: Maybe<Scalars['Int']>
}

export type UpdateInteriorElementOptionInput = {
  id: Scalars['Int']
  elementId?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  listIndex?: Maybe<Scalars['Int']>
  cost?: Maybe<Scalars['Float']>
  hex?: Maybe<Scalars['String']>
  overlaySwatchSrc?: Maybe<Scalars['String']>
  overlaySrc?: Maybe<Scalars['String']>
}

export type UpdateInteriorInput = {
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  internalName?: Maybe<Scalars['String']>
  baseImageSrc?: Maybe<Scalars['String']>
  thumbnailSrc?: Maybe<Scalars['String']>
  disclaimer?: Maybe<Scalars['String']>
  active?: Maybe<Scalars['Boolean']>
}

export type UpdateInteriorListElementInput = {
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  cost?: Maybe<Scalars['Int']>
  elementType?: Maybe<InteriorElementListType>
  overlaySwatchSrc?: Maybe<Scalars['String']>
  interiorElement?: Maybe<InteriorElementInput>
}

export type UpdateInteriorPresetInput = {
  id: Scalars['Int']
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
}

export type UpdateInventoryInput = {
  id: Scalars['Int']
  lotId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
  elevationId?: Maybe<Scalars['Int']>
  mlsId?: Maybe<Scalars['Int']>
  photoFolder?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Int']>
  sqft?: Maybe<Scalars['Int']>
  beds?: Maybe<Scalars['Int']>
  baths?: Maybe<Scalars['Float']>
  features?: Maybe<Scalars['String']>
  mlsUrl?: Maybe<Scalars['String']>
  mlsStatus?: Maybe<MlsStatus>
  garageType?: Maybe<GarageType>
  garageCapacity?: Maybe<Scalars['Float']>
  floors?: Maybe<Scalars['Int']>
  homeStyleId?: Maybe<Scalars['Int']>
  homeType?: Maybe<HomeType>
  virTourUrl?: Maybe<Scalars['String']>
  schemeId?: Maybe<Scalars['Int']>
  closingDate?: Maybe<Scalars['Date']>
  constructionStatus?: Maybe<InventoryConstructionStatus>
  appointmentsEnabled?: Maybe<Scalars['Boolean']>
  reserveHomeUrl?: Maybe<Scalars['String']>
}

export type UpdateInventorySgtDataInput = {
  sgtVendorId?: Maybe<Scalars['Int']>
  sgtExternalId?: Maybe<Scalars['String']>
  sgtData?: Maybe<Scalars['JSON']>
}

export type UpdateLibraryVendorInput = {
  library: LibraryType
  id: Scalars['Int']
  vendorName: Scalars['String']
}

export type UpdateListElementListIndexInput = {
  listElementId: Scalars['Int']
  listIndex: Scalars['Int']
}

export type UpdateLotInput = {
  id: Scalars['Int']
  name?: Maybe<Scalars['String']>
  dataName?: Maybe<Scalars['String']>
  salesStatus?: Maybe<LotSalesStatus>
  externalId?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  premium?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Float']>
  plan?: Maybe<Scalars['String']>
  garagePosition?: Maybe<GaragePosition>
  latitude?: Maybe<Scalars['Float']>
  longitude?: Maybe<Scalars['Float']>
  geoJson?: Maybe<Scalars['JSON']>
  zip?: Maybe<Scalars['Zip']>
  postCode?: Maybe<Scalars['String']>
}

export type UpdateLotLegendEntryInput = {
  id: Scalars['Int']
  siteplanId?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  code?: Maybe<LotSalesStatus>
  hex?: Maybe<Scalars['String']>
  listIndex?: Maybe<Scalars['Int']>
}

export type UpdateLotPositionInput = {
  lotId: Scalars['Int']
  siteplanId: Scalars['Int']
  x: Scalars['Float']
  y: Scalars['Float']
}

export type UpdateMasterCommunityInput = {
  name?: Maybe<Scalars['String']>
  caption?: Maybe<Scalars['String']>
  caption2?: Maybe<Scalars['String']>
  logo?: Maybe<Scalars['String']>
  mainImage?: Maybe<Scalars['String']>
  mainVideo1?: Maybe<Scalars['String']>
  mainVideo2?: Maybe<Scalars['String']>
  mapMarker?: Maybe<Scalars['String']>
  active?: Maybe<Scalars['Boolean']>
}

export type UpdateMetroInput = {
  id: Scalars['Int']
  countryCode?: Maybe<Scalars['String']>
  metroCode?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  metroState?: Maybe<Scalars['String']>
}

export type UpdateOnlineReservationIntegrationInput = {
  signingAgreementTemplateId?: Maybe<Scalars['UUID']>
  nonRefundableAmount?: Maybe<Scalars['Int']>
  depositAmount?: Maybe<Scalars['Int']>
  allowMultipleReservation?: Maybe<Scalars['Boolean']>
  anewgoFee?: Maybe<Scalars['Int']>
  primaryBuyOnlineContactEmail?: Maybe<Scalars['EmailAddress']>
  primaryBuyOnlineContactName?: Maybe<Scalars['String']>
  reservationConfig?: Maybe<Scalars['JSON']>
  daysToProveFinancing?: Maybe<Scalars['Int']>
  envelopeExpireDays?: Maybe<Scalars['Int']>
  additionalBuilders?: Maybe<Array<AdditionalBuilderInput>>
}

export type UpdatePlanInput = {
  name?: Maybe<Scalars['String']>
  externalId?: Maybe<Scalars['String']>
  displayName?: Maybe<Scalars['String']>
  bonafide?: Maybe<Scalars['Boolean']>
  videoUrl?: Maybe<Scalars['String']>
  defaultElevationId?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  bed?: Maybe<Scalars['Int']>
  bedMin?: Maybe<Scalars['Int']>
  bedMax?: Maybe<Scalars['Int']>
  bath?: Maybe<Scalars['Float']>
  bathMin?: Maybe<Scalars['Float']>
  bathMax?: Maybe<Scalars['Float']>
  size?: Maybe<Scalars['Int']>
  sizeMin?: Maybe<Scalars['Int']>
  sizeMax?: Maybe<Scalars['Int']>
  cost?: Maybe<Scalars['Int']>
  costMin?: Maybe<Scalars['Int']>
  costMax?: Maybe<Scalars['Int']>
  active?: Maybe<Scalars['Boolean']>
  internalNotes?: Maybe<Scalars['String']>
  homeType?: Maybe<HomeType>
}

export type UpdateProspectInput = {
  id: Scalars['Int']
  status?: Maybe<ProspectStatus>
}

export type UpdateSchemeInput = {
  name: Scalars['String']
  internalName?: Maybe<Scalars['String']>
  cost: Scalars['Int']
}

export type UpdateSgtVendorInput = {
  id: Scalars['Int']
  name?: Maybe<Scalars['String']>
  logo?: Maybe<Scalars['String']>
  icon?: Maybe<Scalars['String']>
  extendedData?: Maybe<Scalars['JSON']>
}

export type UpdateSiteplanInput = {
  id: Scalars['Int']
  communityId?: Maybe<Scalars['Int']>
  collectionId?: Maybe<Scalars['Int']>
  lotFontSize?: Maybe<Scalars['Int']>
  lotMetric?: Maybe<Scalars['String']>
  lotWidth?: Maybe<Scalars['Int']>
  lotHeight?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  displayName?: Maybe<Scalars['String']>
  src?: Maybe<Scalars['String']>
  svg?: Maybe<SvgInput>
  active?: Maybe<Scalars['Boolean']>
  neLatitude?: Maybe<Scalars['Float']>
  neLongitude?: Maybe<Scalars['Float']>
  swLatitude?: Maybe<Scalars['Float']>
  swLongitude?: Maybe<Scalars['Float']>
  geoJson?: Maybe<Scalars['JSON']>
  unreleasedLotsHandle?: Maybe<UnreleasedLotsHandleTypes>
  showUnreleasedLotNumber?: Maybe<Scalars['Boolean']>
}

export type UpdateSocialsInput = {
  id: Scalars['Int']
  builderPageId: Scalars['Int']
  facebook?: Maybe<Scalars['String']>
  instagram?: Maybe<Scalars['String']>
  twitter?: Maybe<Scalars['String']>
  linkedIn?: Maybe<Scalars['String']>
}

export type UpdateTextureInput = {
  textureLibrary: TextureLibraryType
  id: Scalars['Int']
  status?: Maybe<LibraryStatus>
  type?: Maybe<Scalars['String']>
  color?: Maybe<Scalars['String']>
  alt1?: Maybe<Scalars['Int']>
  alt2?: Maybe<Scalars['Int']>
  client?: Maybe<Scalars['String']>
  src?: Maybe<Scalars['String']>
  c4d?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
}

export type UpdateUserClientRoleInput = {
  roleId: Scalars['Int']
  userId: Scalars['Int']
}

export type UpdateUserInput = {
  id: Scalars['Int']
  firstName: Scalars['String']
  lastName: Scalars['String']
}

export type UpsertAgentInput = {
  email: Scalars['String']
  phone?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type UsCityRegionInfo = {
  __typename?: 'USCityRegionInfo'
  stateId?: Maybe<Scalars['Int']>
  stateCode?: Maybe<Scalars['String']>
  stateName?: Maybe<Scalars['String']>
  countyId?: Maybe<Scalars['Int']>
  countyCode?: Maybe<Scalars['Int']>
  countyName?: Maybe<Scalars['String']>
  metroId?: Maybe<Scalars['Int']>
  metroName?: Maybe<Scalars['String']>
  metroCustomName?: Maybe<Scalars['String']>
  metroSrc?: Maybe<Scalars['String']>
  zipCode?: Maybe<Scalars['Zip']>
}

export type User = {
  __typename?: 'User'
  id?: Maybe<Scalars['Int']>
  roleId?: Maybe<Scalars['Int']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  organizationRole?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  status: UserStatus
  licensed?: Maybe<Scalars['Boolean']>
}

export enum UserStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Banned = 'BANNED',
}

export type UsersWithRoles = {
  __typename?: 'UsersWithRoles'
  id?: Maybe<Scalars['Int']>
  users?: Maybe<Array<Maybe<User>>>
}

export type VisitorLocation = {
  __typename?: 'VisitorLocation'
  latitude?: Maybe<Scalars['Float']>
  longitude?: Maybe<Scalars['Float']>
}

export type VisitorTime = {
  __typename?: 'VisitorTime'
  hour: Scalars['Int']
  dayCounts: Array<DayCounts>
}

export type VisitorTrend = {
  __typename?: 'VisitorTrend'
  date?: Maybe<Scalars['String']>
  anonymous?: Maybe<Scalars['Int']>
  registered?: Maybe<Scalars['Int']>
  cumulativeRegistered?: Maybe<Scalars['Int']>
}

export type VisitorTrendsQueryFilter = {
  common: CommonQueryFilter
  clientDomain: Scalars['String']
}

export type CommunityFieldsFragment = { __typename?: 'Community' } & Pick<
  Community,
  | 'id'
  | 'name'
  | 'thumb'
  | 'bonafide'
  | 'buildYourLot'
  | 'caption'
  | 'colormtd'
  | 'description'
  | 'pricing'
  | 'logo'
  | 'longitude'
  | 'latitude'
  | 'address'
  | 'sortType'
  | 'sortOrder'
> & {
    cityLocation?: Maybe<
      { __typename?: 'CityInfo' } & CityFieldsMinimalFragment
    >
    agents: Array<
      { __typename?: 'Agent' } & Pick<
        Agent,
        'id' | 'email' | 'phone' | 'firstName' | 'lastName'
      >
    >
  }

export type PlanFieldsFragment = { __typename?: 'Plan' } & Pick<
  Plan,
  | 'id'
  | 'displayName'
  | 'name'
  | 'communityId'
  | 'defaultElevationId'
  | 'description'
  | 'bed'
  | 'bath'
  | 'cost'
  | 'size'
  | 'videoUrl'
  | 'interactiveInteriorIds'
> & {
    bedRange?: Maybe<
      { __typename?: 'IntRange' } & Pick<IntRange, 'min' | 'max'>
    >
    bathRange?: Maybe<
      { __typename?: 'FloatRange' } & Pick<FloatRange, 'min' | 'max'>
    >
    costRange?: Maybe<
      { __typename?: 'IntRange' } & Pick<IntRange, 'min' | 'max'>
    >
    sizeRange?: Maybe<
      { __typename?: 'IntRange' } & Pick<IntRange, 'min' | 'max'>
    >
  }

export type ElevationFieldsFragment = { __typename?: 'Elevation' } & Pick<
  Elevation,
  | 'id'
  | 'mirrorElevationId'
  | 'mirrorRolePrimary'
  | 'communityId'
  | 'planName'
  | 'planDisplayName'
  | 'planId'
  | 'caption'
  | 'thumb'
  | 'bed'
  | 'bath'
  | 'size'
  | 'cost'
  | 'defaultFloor'
  | 'description'
  | 'svgMirroring'
  | 'garagePosition'
  | 'defaultGaragePosition'
> & {
    bedRange?: Maybe<
      { __typename?: 'IntRange' } & Pick<IntRange, 'min' | 'max'>
    >
    bathRange?: Maybe<
      { __typename?: 'FloatRange' } & Pick<FloatRange, 'min' | 'max'>
    >
    costRange?: Maybe<
      { __typename?: 'IntRange' } & Pick<IntRange, 'min' | 'max'>
    >
    sizeRange?: Maybe<
      { __typename?: 'IntRange' } & Pick<IntRange, 'min' | 'max'>
    >
    tags: Array<
      { __typename?: 'PlanTagElevation' } & Pick<
        PlanTagElevation,
        'categoryName' | 'tagName'
      >
    >
  }

export type LotFieldsFragment = { __typename?: 'Lot' } & Pick<
  Lot,
  | 'id'
  | 'communityId'
  | 'dataName'
  | 'name'
  | 'salesStatus'
  | 'premium'
  | 'externalId'
  | 'address'
  | 'size'
  | 'cityName'
  | 'stateCode'
  | 'zip'
  | 'postCode'
> & {
    availableSchemes: Array<
      { __typename?: 'Scheme' } & Pick<Scheme, 'id' | 'name'>
    >
  }

export type InventoryFieldsFragment = { __typename?: 'Inventory' } & Pick<
  Inventory,
  | 'id'
  | 'lotId'
  | 'planId'
  | 'elevationId'
  | 'communityId'
  | 'price'
  | 'sqft'
  | 'beds'
  | 'baths'
  | 'features'
  | 'constructionStatus'
  | 'closingDate'
  | 'mlsId'
  | 'garageType'
  | 'garageCapacity'
  | 'floors'
  | 'schemeId'
  | 'photoFolder'
  | 'appointmentsEnabled'
  | 'reserveHomeUrl'
> & {
    plan?: Maybe<
      { __typename?: 'Plan' } & Pick<
        Plan,
        'id' | 'communityId' | 'name' | 'displayName'
      >
    >
    sgtData?: Maybe<
      { __typename?: 'InventorySgtData' } & Pick<
        InventorySgtData,
        'id' | 'sgtVendorId' | 'sgtExternalId' | 'sgtData'
      >
    >
  }

export type SiteplanSvgFieldsFragment = { __typename?: 'Siteplan' } & {
  svg?: Maybe<
    { __typename?: 'SVG' } & Pick<Svg, 'style'> & {
        viewBox: { __typename?: 'SVGViewBox' } & Pick<
          SvgViewBox,
          'x' | 'y' | 'width' | 'height'
        >
        frame?: Maybe<
          { __typename?: 'SVGShapeAttributes' } & Pick<
            SvgShapeAttributes,
            'x' | 'y' | 'width' | 'height'
          >
        >
        shapes: Array<
          { __typename?: 'SVGShape' } & Pick<SvgShape, 'tagName'> & {
              attributes: { __typename?: 'SVGShapeAttributes' } & Pick<
                SvgShapeAttributes,
                | 'className'
                | 'dataName'
                | 'x'
                | 'y'
                | 'width'
                | 'height'
                | 'transform'
                | 'points'
                | 'd'
              >
            }
        >
      }
  >
}

export type AddHomeSelectionMutationVariables = Exact<{
  clientName: Scalars['String']
  input: HomeSelectionInput
}>

export type AddHomeSelectionMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'addHomeSelection'
>

export type RequireRegistrationQueryVariables = Exact<{
  clientName: Scalars['String']
}>

export type RequireRegistrationQuery = { __typename?: 'Query' } & {
  customization?: Maybe<
    { __typename?: 'Customization' } & Pick<
      Customization,
      'id' | 'name' | 'value'
    >
  >
}

export type GetCommunityByNameLite2QueryVariables = Exact<{
  clientName: Scalars['String']
  communityName: Scalars['String']
}>

export type GetCommunityByNameLite2Query = { __typename?: 'Query' } & {
  communityByName?: Maybe<
    { __typename?: 'Community' } & Pick<
      Community,
      | 'id'
      | 'name'
      | 'thumb'
      | 'bonafide'
      | 'buildYourLot'
      | 'caption'
      | 'colormtd'
      | 'description'
      | 'pricing'
      | 'logo'
      | 'longitude'
      | 'latitude'
      | 'address'
      | 'sortType'
      | 'sortOrder'
    > & {
        cityLocation?: Maybe<
          { __typename?: 'CityInfo' } & CityFieldsMinimalFragment
        >
        agents: Array<
          { __typename?: 'Agent' } & Pick<
            Agent,
            'id' | 'email' | 'phone' | 'firstName' | 'lastName'
          >
        >
        siteplans: Array<
          { __typename?: 'Siteplan' } & Pick<Siteplan, 'id' | 'communityId'>
        >
        primarySiteplan?: Maybe<
          { __typename?: 'Siteplan' } & Pick<Siteplan, 'id' | 'lotMetric'>
        >
        imgs: Array<
          { __typename?: 'CommunityImg' } & Pick<CommunityImg, 'id' | 'src'>
        >
        stdFeatureCategories: Array<
          { __typename?: 'StdFeatureCategory' } & Pick<
            StdFeatureCategory,
            'id' | 'name'
          > & {
              features: Array<
                { __typename?: 'StdFeature' } & Pick<StdFeature, 'id' | 'name'>
              >
            }
        >
      }
  >
}

export type GetElevationLayersQueryVariables = Exact<{
  clientName: Scalars['String']
  elevationId: Scalars['Int']
  planId: Scalars['Int']
  communityId?: Maybe<Scalars['Int']>
}>

export type GetElevationLayersQuery = { __typename?: 'Query' } & {
  elevation?: Maybe<
    { __typename?: 'Elevation' } & Pick<
      Elevation,
      'id' | 'planId' | 'caption' | 'communityId' | 'base' | 'thumb'
    > & {
        layers: Array<
          { __typename?: 'Layer' } & Pick<Layer, 'materialId' | 'name' | 'src'>
        >
        materialPalettes: Array<
          { __typename?: 'MaterialPalette' } & Pick<
            MaterialPalette,
            | 'communityId'
            | 'planId'
            | 'elevationId'
            | 'materialId'
            | 'materialCategory'
            | 'name'
          > & {
              palettes: Array<
                { __typename?: 'Palette' } & Pick<Palette, 'id' | 'name'> & {
                    selections: Array<
                      { __typename?: 'PaletteSelection' } & Pick<
                        PaletteSelection,
                        | 'id'
                        | 'name'
                        | 'hex'
                        | 'swatch'
                        | 'type'
                        | 'masonryLib'
                        | 'colorId'
                        | 'brickId'
                        | 'stoneId'
                        | 'customOverlayId'
                      >
                    >
                  }
              >
            }
        >
        schemes: Array<
          { __typename?: 'Scheme' } & Pick<Scheme, 'id' | 'name'> & {
              materials: Array<
                { __typename?: 'SchemeElement' } & Pick<
                  SchemeElement,
                  | 'id'
                  | 'materialId'
                  | 'materialCategory'
                  | 'material'
                  | 'name'
                  | 'hex'
                  | 'type'
                  | 'swatch'
                  | 'masonryLib'
                  | 'colorId'
                  | 'brickId'
                  | 'stoneId'
                  | 'customOverlayId'
                  | 'paletteIds'
                >
              >
            }
        >
      }
  >
}

export type GetHomeSelectionQueryVariables = Exact<{
  clientName: Scalars['String']
  prospectIdentifier: Scalars['String']
  homeIdentifier: Scalars['String']
}>

export type GetHomeSelectionQuery = { __typename?: 'Query' } & {
  getHomeSelection?: Maybe<
    { __typename?: 'HomeSelection' } & Pick<HomeSelection, 'json'>
  >
}

export type GetHomeSelectionByFavoriteIdQueryVariables = Exact<{
  clientName: Scalars['String']
  favoriteIdentifier?: Maybe<Scalars['Int']>
}>

export type GetHomeSelectionByFavoriteIdQuery = { __typename?: 'Query' } & {
  getHomeSelection?: Maybe<
    { __typename?: 'HomeSelection' } & Pick<HomeSelection, 'json'>
  >
}

export type InteriorsQueryVariables = Exact<{
  clientName: Scalars['String']
  interior: InteriorInput
}>

export type InteriorsQuery = { __typename?: 'Query' } & {
  interiors?: Maybe<
    Array<
      { __typename?: 'Interior' } & Pick<
        Interior,
        'id' | 'name' | 'baseImageSrc' | 'disclaimer'
      > & {
          elementTrees: Array<
            { __typename?: 'InteriorListElementTree' } & Pick<
              InteriorListElementTree,
              'id' | 'name'
            > & {
                options?: Maybe<
                  Array<
                    { __typename?: 'InteriorElementOption' } & Pick<
                      InteriorElementOption,
                      'id' | 'name' | 'overlaySwatchSrc' | 'hex' | 'cost'
                    >
                  >
                >
              }
          >
        }
    >
  >
}

export type GetPlanByNameQueryVariables = Exact<{
  clientName: Scalars['String']
  communityName: Scalars['String']
  planName: Scalars['String']
  active?: Maybe<Scalars['Boolean']>
}>

export type GetPlanByNameQuery = { __typename?: 'Query' } & {
  planByName?: Maybe<
    { __typename?: 'Plan' } & {
      exteriorAssets: Array<
        { __typename?: 'Asset' } & Pick<Asset, 'src' | 'listIndex' | 'videoSrc'>
      >
      interiorPhotos: Array<
        { __typename?: 'Photo' } & Pick<Photo, 'src' | 'listIndex'>
      >
      elevations: Array<
        { __typename?: 'Elevation' } & {
          collection?: Maybe<
            { __typename?: 'Collection' } & Pick<
              Collection,
              'id' | 'communityId' | 'name'
            > & {
                siteplan?: Maybe<
                  { __typename?: 'Siteplan' } & Pick<Siteplan, 'id' | 'name'>
                >
              }
          >
          communities: Array<
            { __typename?: 'Community' } & CommunityFieldsFragment
          >
        } & ElevationFieldsFragment
      >
    } & PlanFieldsFragment
  >
}

export type GetFloorplansByElevationIdQueryVariables = Exact<{
  clientName: Scalars['String']
  communityId: Scalars['Int']
  planId: Scalars['Int']
  elevationId: Scalars['Int']
}>

export type GetFloorplansByElevationIdQuery = { __typename?: 'Query' } & {
  floorplansByElevationId?: Maybe<
    { __typename?: 'ElevationFloorplans' } & Pick<
      ElevationFloorplans,
      'planId' | 'communityId' | 'elevationId' | 'defaultFloor'
    > & {
        stories: Array<
          { __typename?: 'Story' } & Pick<
            Story,
            | 'id'
            | 'communityId'
            | 'planId'
            | 'elevationId'
            | 'name'
            | 'fnum'
            | 'optional'
          > & {
              floorplans: Array<
                { __typename?: 'Floorplan' } & Pick<
                  Floorplan,
                  | 'id'
                  | 'groupId'
                  | 'name'
                  | 'base'
                  | 'optional'
                  | 'standard'
                  | 'cost'
                  | 'size'
                  | 'bed'
                  | 'bath'
                  | 'listIndex'
                  | 'src'
                  | 'src2'
                  | 'labelSvg'
                  | 'fnum'
                >
              >
              fpGroups: Array<
                { __typename?: 'FloorplanGroup' } & Pick<
                  FloorplanGroup,
                  'id' | 'name' | 'groupType'
                > & {
                    primary: Array<
                      { __typename?: 'Floorplan' } & Pick<
                        Floorplan,
                        'id' | 'groupId' | 'standard' | 'name'
                      >
                    >
                    members: Array<
                      { __typename?: 'Floorplan' } & Pick<
                        Floorplan,
                        'id' | 'name' | 'groupId' | 'standard' | 'fnum'
                      > & {
                          alternate?: Maybe<
                            { __typename?: 'FloorplanAlternate' } & Pick<
                              FloorplanAlternate,
                              | 'id'
                              | 'src'
                              | 'src2'
                              | 'labelSvg'
                              | 'cost'
                              | 'size'
                              | 'bed'
                              | 'bath'
                            >
                          >
                        }
                    >
                  }
              >
            }
        >
      }
  >
}

export type GetClientByIdQueryVariables = Exact<{
  clientId: Scalars['Int']
}>

export type GetClientByIdQuery = { __typename?: 'Query' } & {
  clientById?: Maybe<
    { __typename?: 'Client' } & Pick<
      Client,
      | 'id'
      | 'name'
      | 'altName'
      | 'directoryName'
      | 'disclaimer'
      | 'trackingId'
      | 'logo'
      | 'website'
    >
  >
}

export type GetClientQueryVariables = Exact<{
  clientName: Scalars['String']
}>

export type GetClientQuery = { __typename?: 'Query' } & {
  clientByName?: Maybe<
    { __typename?: 'Client' } & Pick<
      Client,
      | 'id'
      | 'name'
      | 'altName'
      | 'directoryName'
      | 'disclaimer'
      | 'trackingId'
      | 'logo'
      | 'website'
    > & {
        sgtVendors: Array<
          { __typename?: 'SgtVendor' } & Pick<
            SgtVendor,
            'id' | 'icon' | 'logo' | 'name' | 'extendedData'
          >
        >
      }
  >
}

export type GetBuilderAppConfigQueryVariables = Exact<{
  clientName: Scalars['String']
}>

export type GetBuilderAppConfigQuery = { __typename?: 'Query' } & Pick<
  Query,
  'builderAppConfig'
> & {
    clientCustomizations: Array<
      { __typename?: 'Customization' } & Pick<
        Customization,
        'id' | 'name' | 'value'
      >
    >
  }

export type GetLotWithInventoryQueryVariables = Exact<{
  clientName: Scalars['String']
  lotId: Scalars['Int']
}>

export type GetLotWithInventoryQuery = { __typename?: 'Query' } & {
  lot?: Maybe<
    { __typename?: 'Lot' } & {
      inventory?: Maybe<
        { __typename?: 'Inventory' } & Pick<
          Inventory,
          'virTourUrl' | 'mlsPhotos'
        > & {
            photos: Array<
              { __typename?: 'InventoryPhoto' } & Pick<
                InventoryPhoto,
                'id' | 'src' | 'listIndex'
              >
            >
            includedFloorplans: Array<
              { __typename?: 'Floorplan' } & Pick<
                Floorplan,
                'id' | 'groupId' | 'standard' | 'name'
              >
            >
            plan?: Maybe<{ __typename?: 'Plan' } & PlanFieldsFragment>
            elevation?: Maybe<
              { __typename?: 'Elevation' } & ElevationFieldsFragment
            >
          } & InventoryFieldsFragment
      >
    } & LotFieldsFragment
  >
}

export type RequestSgtAppointmentsMutationVariables = Exact<{
  clientName: Scalars['String']
  prospectSgtApptInput: ProspectSgtApptInput
  timezoneOffset: Scalars['Int']
}>

export type RequestSgtAppointmentsMutation = { __typename?: 'Mutation' } & {
  requestSgtAppointment: Array<
    { __typename?: 'ProspectSgtAppointment' } & Pick<
      ProspectSgtAppointment,
      'id' | 'prospectId' | 'inventoryId' | 'preferredDate' | 'createdOn'
    >
  >
}

export type GetProspectSgtAppointmentsQueryVariables = Exact<{
  clientName: Scalars['String']
  prospectId: Scalars['Int']
  inventoryId: Scalars['Int']
}>

export type GetProspectSgtAppointmentsQuery = { __typename?: 'Query' } & {
  prospectSgtAppointments: Array<
    { __typename?: 'ProspectSgtData' } & Pick<ProspectSgtData, 'preferredDate'>
  >
}

export type GetCommunityNameQueryVariables = Exact<{
  clientName: Scalars['String']
  communityId: Scalars['Int']
}>

export type GetCommunityNameQuery = { __typename?: 'Query' } & {
  community?: Maybe<
    { __typename?: 'Community' } & Pick<Community, 'id' | 'name'>
  >
}

export type GetElevationWithStoriesOnlyQueryVariables = Exact<{
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['Int']>
  elevationId: Scalars['Int']
}>

export type GetElevationWithStoriesOnlyQuery = { __typename?: 'Query' } & {
  elevation?: Maybe<
    { __typename?: 'Elevation' } & Pick<
      Elevation,
      'id' | 'communityId' | 'planId'
    > & {
        stories: Array<
          { __typename?: 'Story' } & Pick<
            Story,
            | 'id'
            | 'communityId'
            | 'planId'
            | 'elevationId'
            | 'name'
            | 'fnum'
            | 'optional'
          >
        >
      }
  >
}

export type LotWithInventoryExteriorConfigurationOnlyQueryVariables = Exact<{
  clientName: Scalars['String']
  lotId: Scalars['Int']
}>

export type LotWithInventoryExteriorConfigurationOnlyQuery = {
  __typename?: 'Query'
} & {
  lot?: Maybe<
    { __typename?: 'Lot' } & Pick<Lot, 'id'> & {
        inventory?: Maybe<
          { __typename?: 'Inventory' } & Pick<Inventory, 'id'> & {
              exteriorConfiguration: {
                __typename?: 'InventoryExteriorConfiguration'
              } & Pick<InventoryExteriorConfiguration, 'inventoryId'> & {
                  scheme?: Maybe<
                    { __typename?: 'Scheme' } & Pick<Scheme, 'id' | 'name'> & {
                        materials: Array<
                          { __typename?: 'SchemeElement' } & Pick<
                            SchemeElement,
                            | 'id'
                            | 'materialId'
                            | 'materialCategory'
                            | 'material'
                            | 'name'
                            | 'hex'
                            | 'type'
                            | 'swatch'
                            | 'masonryLib'
                            | 'colorId'
                            | 'brickId'
                            | 'stoneId'
                            | 'customOverlayId'
                          >
                        >
                      }
                  >
                  paletteElementSelections: Array<
                    { __typename?: 'PaletteElementSelection' } & Pick<
                      PaletteElementSelection,
                      'inventoryId' | 'elementId' | 'paletteId'
                    > & {
                        paletteSelection: {
                          __typename?: 'PaletteSelection'
                        } & Pick<PaletteSelection, 'id'>
                      }
                  >
                }
            }
        >
      }
  >
}

export type GetLotWithSiteplanInfoQueryVariables = Exact<{
  clientName: Scalars['String']
  lotId: Scalars['Int']
}>

export type GetLotWithSiteplanInfoQuery = { __typename?: 'Query' } & {
  lot?: Maybe<
    { __typename?: 'Lot' } & Pick<Lot, 'siteplanName'> & {
        siteplanInfo?: Maybe<
          { __typename?: 'LotSiteplanInfo' } & Pick<
            LotSiteplanInfo,
            'lotId' | 'siteplanId' | 'x' | 'y'
          >
        >
      } & LotFieldsFragment
  >
}

export type GetSchemeQueryVariables = Exact<{
  clientName: Scalars['String']
  communityId?: Maybe<Scalars['Int']>
  schemeId: Scalars['Int']
}>

export type GetSchemeQuery = { __typename?: 'Query' } & {
  scheme?: Maybe<
    Array<
      { __typename?: 'Scheme' } & Pick<Scheme, 'id' | 'name'> & {
          materials: Array<
            { __typename?: 'SchemeElement' } & Pick<
              SchemeElement,
              | 'id'
              | 'materialId'
              | 'materialCategory'
              | 'material'
              | 'name'
              | 'hex'
              | 'type'
              | 'swatch'
              | 'masonryLib'
              | 'colorId'
              | 'brickId'
              | 'stoneId'
              | 'customOverlayId'
            >
          >
        }
    >
  >
}

export type GetSiteplanLiteQueryVariables = Exact<{
  clientName: Scalars['String']
  id: Scalars['Int']
}>

export type GetSiteplanLiteQuery = { __typename?: 'Query' } & {
  siteplanById?: Maybe<
    { __typename?: 'Siteplan' } & Pick<
      Siteplan,
      | 'id'
      | 'name'
      | 'lotFontSize'
      | 'lotMetric'
      | 'lotWidth'
      | 'lotHeight'
      | 'master'
      | 'src'
    > & {
        lotLegend: Array<
          { __typename?: 'LotLegendEntry' } & Pick<
            LotLegendEntry,
            'id' | 'code' | 'name' | 'hex'
          >
        >
        hotspots: Array<
          { __typename?: 'SiteplanHotspot' } & Pick<
            SiteplanHotspot,
            'id' | 'siteplanId' | 'name' | 'x' | 'y' | 'description' | 'thumb'
          > & {
              assets: Array<
                { __typename?: 'SiteplanHotspotAsset' } & Pick<
                  SiteplanHotspotAsset,
                  'id' | 'listIndex' | 'src' | 'description'
                >
              >
            }
        >
      } & SiteplanSvgFieldsFragment
  >
}

export type GetColonnadeClientSubscriptionInfoQueryVariables = Exact<{
  clientId: Scalars['Int']
}>

export type GetColonnadeClientSubscriptionInfoQuery = {
  __typename?: 'Query'
} & {
  getColonnadeClientSubscriptionInfo: {
    __typename?: 'ColonnadeClientSubscriptionInfo'
  } & Pick<
    ColonnadeClientSubscriptionInfo,
    | 'numberOfLicenses'
    | 'subscriptionLevel'
    | 'subscriptionDate'
    | 'rolePrivilegeObject'
    | 'anniversaryNotificationState'
  >
}

export type GetClientReportInfoQueryVariables = Exact<{
  clientId: Scalars['Int']
}>

export type GetClientReportInfoQuery = { __typename?: 'Query' } & {
  getClientEmailReportInfo: Array<
    { __typename?: 'ClientEmailReportInfo' } & Pick<
      ClientEmailReportInfo,
      'reportId' | 'firstName' | 'lastName' | 'email' | 'frequency' | 'clientId'
    >
  >
}

export type SetColonnadeClientsLicensesMutationVariables = Exact<{
  clientId: Scalars['Int']
  numberOfLicenses: Scalars['Int']
}>

export type SetColonnadeClientsLicensesMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'setColonnadeClientLicenses'>

export type DeleteColonnadeClientEmailReportInfoMutationVariables = Exact<{
  reportId: Scalars['Int']
}>

export type DeleteColonnadeClientEmailReportInfoMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'deleteColonnadeClientEmailReportInfo'>

export type AddColonnadeClientEmailReportInfoMutationVariables = Exact<{
  clientId: Scalars['Int']
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  email: Scalars['String']
  frequency: ColonnadeReportFrequencyEnum
}>

export type AddColonnadeClientEmailReportInfoMutation = {
  __typename?: 'Mutation'
} & {
  addColonnadeClientEmailReportInfo?: Maybe<
    { __typename?: 'ClientEmailReportInfo' } & Pick<
      ClientEmailReportInfo,
      'reportId' | 'firstName' | 'lastName' | 'email' | 'frequency' | 'clientId'
    >
  >
}

export type UpdateColonnadeClientEmailReportInfoMutationVariables = Exact<{
  reportId: Scalars['Int']
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  email: Scalars['String']
  frequency: ColonnadeReportFrequencyEnum
}>

export type UpdateColonnadeClientEmailReportInfoMutation = {
  __typename?: 'Mutation'
} & {
  updateColonnadeClientEmailReportInfo?: Maybe<
    { __typename?: 'ClientEmailReportInfo' } & Pick<
      ClientEmailReportInfo,
      'reportId' | 'firstName' | 'lastName' | 'email' | 'frequency' | 'clientId'
    >
  >
}

export type IsUserSuperAdminQueryVariables = Exact<{
  userId: Scalars['Int']
}>

export type IsUserSuperAdminQuery = { __typename?: 'Query' } & Pick<
  Query,
  'isUserSuperAdmin'
>

export type IsUserAnewgoAdminQueryVariables = Exact<{
  userId: Scalars['Int']
}>

export type IsUserAnewgoAdminQuery = { __typename?: 'Query' } & Pick<
  Query,
  'isUserAnewgoAdmin'
>

export type SetColonnadeClientsSubsLevelMutationVariables = Exact<{
  clientId: Scalars['Int']
  subscriptionLevel: ColonnadeSubscriptionLevelsEnum
}>

export type SetColonnadeClientsSubsLevelMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'setColonnadeClientSubsLevel'>

export type SetColonnadeRolePermissionMutationVariables = Exact<{
  roleName: ColonnadeRolesEnum
  privilege: Array<ColonnadeRolePrivilegesEnum> | ColonnadeRolePrivilegesEnum
}>

export type SetColonnadeRolePermissionMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'setRolePermission'>

export type SetColSubsAnnivNotifMutationVariables = Exact<{
  clientId: Scalars['Int']
  statement: Scalars['Boolean']
}>

export type SetColSubsAnnivNotifMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'setColSubsAnnivNotif'
>

export type RequestColonnadeLicenseUpgradeMutationVariables = Exact<{
  clientName: Scalars['String']
  numberOfRequestedLicense: Scalars['Int']
  requesterEmail: Scalars['String']
}>

export type RequestColonnadeLicenseUpgradeMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'requestColonnadeLicenseUpgrade'>

export type UpdateColonnadeSubscriptionDateMutationVariables = Exact<{
  newSubscriptionDate: Scalars['Date']
  clientId: Scalars['Int']
}>

export type UpdateColonnadeSubscriptionDateMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'updateColonnadeSubscriptionDate'>

export type AuthenticateQueryVariables = Exact<{
  email: Scalars['String']
  password: Scalars['String']
}>

export type AuthenticateQuery = { __typename?: 'Query' } & Pick<
  Query,
  'authenticate'
>

export type IsExistingEmailQueryVariables = Exact<{
  email: Scalars['String']
}>

export type IsExistingEmailQuery = { __typename?: 'Query' } & Pick<
  Query,
  'isExistingEmail'
>

export type VerifyTokenQueryVariables = Exact<{
  token: Scalars['String']
}>

export type VerifyTokenQuery = { __typename?: 'Query' } & Pick<
  Query,
  'verifyToken'
>

export type GetRolesQueryVariables = Exact<{ [key: string]: never }>

export type GetRolesQuery = { __typename?: 'Query' } & {
  colonnadeRoles: Array<
    { __typename?: 'ColonnadeRole' } & Pick<ColonnadeRole, 'id' | 'role'>
  >
}

export type GetUsersQueryVariables = Exact<{
  clientName: Scalars['String']
}>

export type GetUsersQuery = { __typename?: 'Query' } & {
  colonnadeUsers: Array<
    { __typename?: 'User' } & Pick<
      User,
      | 'id'
      | 'firstName'
      | 'lastName'
      | 'email'
      | 'organizationRole'
      | 'status'
      | 'roleId'
      | 'licensed'
    >
  >
}

export type AddAgentsProspectMutationVariables = Exact<{
  prospectEmail: Scalars['String']
  agentIds: Array<Scalars['Int']> | Scalars['Int']
  clientName: Scalars['String']
  source: Scalars['String']
}>

export type AddAgentsProspectMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'colonnadeApiBatchAddAgentProspect'
>

export type AddColonnadeUserMutationVariables = Exact<{
  clientName: Scalars['String']
  input: AddColonnadeUserInput
}>

export type AddColonnadeUserMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'addColonnadeUser'
>

export type AddProspectActivitiesMutationVariables = Exact<{
  clientName: Scalars['String']
  email: Scalars['String']
  activities: Scalars['JSON']
}>

export type AddProspectActivitiesMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'addProspectActivities'
>

export type AssignProspectToColonnadeAgentMutationVariables = Exact<{
  clientName: Scalars['String']
  assignments: Array<ColonnadeProspectAssignment> | ColonnadeProspectAssignment
}>

export type AssignProspectToColonnadeAgentMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'assignProspectToColonnadeAgent'>

export type UnassignProspectFromColonnadeAgentMutationVariables = Exact<{
  clientName: Scalars['String']
  unassignments:
    | Array<ColonnadeProspectAssignment>
    | ColonnadeProspectAssignment
}>

export type UnassignProspectFromColonnadeAgentMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'unassignProspectFromColonnadeAgent'>

export type ImportProspectsToColonnadeAgentsMutationVariables = Exact<{
  clientName: Scalars['String']
  assignments: Array<ColonnadeAgentImport> | ColonnadeAgentImport
}>

export type ImportProspectsToColonnadeAgentsMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'importProspectsToColonnadeAgents'>

export type CreateEmailCampaignMutationVariables = Exact<{
  clientName: Scalars['String']
  emailCampaign: EmailCampaignInput
  cohort?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
}>

export type CreateEmailCampaignMutation = { __typename?: 'Mutation' } & {
  createEmailCampaign?: Maybe<
    { __typename?: 'EmailCampaign' } & Pick<
      EmailCampaign,
      | 'id'
      | 'communityId'
      | 'planId'
      | 'elevationId'
      | 'lotId'
      | 'inventoryId'
      | 'ownerId'
      | 'templateId'
      | 'name'
      | 'mainImage'
      | 'replyTo'
      | 'replyToName'
      | 'subject'
      | 'body'
      | 'signature'
      | 'active'
      | 'createdAt'
      | 'lastUpdated'
    > & {
        cohort: Array<
          { __typename?: 'EmailCampaignCohort' } & Pick<
            EmailCampaignCohort,
            'id' | 'prospectId' | 'status' | 'active'
          > & {
              prospect?: Maybe<
                { __typename?: 'Prospect' } & Pick<
                  Prospect,
                  | 'id'
                  | 'status'
                  | 'registrationDate'
                  | 'name'
                  | 'firstName'
                  | 'lastName'
                  | 'email'
                >
              >
            }
        >
        sections: Array<
          { __typename?: 'EmailCampaignSection' } & Pick<
            EmailCampaignSection,
            'id' | 'content' | 'index'
          > & {
              images: Array<
                { __typename?: 'EmailCampaignSectionImage' } & Pick<
                  EmailCampaignSectionImage,
                  'id' | 'emailCampaignSectionId' | 'index' | 'src'
                >
              >
            }
        >
      }
  >
}

export type DeleteColonnadeUserMutationVariables = Exact<{
  callerUserId: Scalars['Int']
  userId: Scalars['Int']
}>

export type DeleteColonnadeUserMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteColonnadeUser'
>

export type DeleteEmailCampaignMutationVariables = Exact<{
  clientName: Scalars['String']
  emailCampaignId: Scalars['Int']
}>

export type DeleteEmailCampaignMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteEmailCampaign'
>

export type DeleteEmailCampaignSectionImageMutationVariables = Exact<{
  clientName: Scalars['String']
  emailCampaignSectionImageId: Scalars['Int']
}>

export type DeleteEmailCampaignSectionImageMutation = {
  __typename?: 'Mutation'
} & {
  deleteEmailCampaignSectionImage?: Maybe<
    { __typename?: 'EmailCampaignSection' } & Pick<
      EmailCampaignSection,
      'id' | 'content' | 'index'
    > & {
        images: Array<
          { __typename?: 'EmailCampaignSectionImage' } & Pick<
            EmailCampaignSectionImage,
            'id' | 'emailCampaignSectionId' | 'index' | 'src'
          >
        >
      }
  >
}

export type AddExcludedIpMutationVariables = Exact<{
  clientName: Scalars['String']
  ips: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
  ipCidrNotation: Scalars['String']
}>

export type AddExcludedIpMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'addExcludedIp'
>

export type RemoveExcludedIpMutationVariables = Exact<{
  clientName: Scalars['String']
  ipCidrNotation: Scalars['String']
}>

export type RemoveExcludedIpMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'removeExcludedIp'
>

export type ImportAgentsMutationVariables = Exact<{
  clientName: Scalars['String']
  input: Array<ImportAgentInput> | ImportAgentInput
}>

export type ImportAgentsMutation = { __typename?: 'Mutation' } & {
  importAgentsAsColonnadeUsers: Array<
    | ({ __typename: 'ImportAgentSuccess' } & Pick<
        ImportAgentSuccess,
        'agentId'
      > & {
          user: { __typename?: 'User' } & Pick<
            User,
            | 'id'
            | 'roleId'
            | 'firstName'
            | 'lastName'
            | 'organizationRole'
            | 'email'
            | 'status'
            | 'licensed'
          >
        })
    | ({ __typename: 'ImportAgentError' } & Pick<
        ImportAgentError,
        'agentId' | 'message'
      >)
  >
}

export type RequestSubscriptionUpgradeMutationVariables = Exact<{
  callerUserId: Scalars['Int']
  requestedSubscriptionLevel: ColonnadeSubscriptionLevelsEnum
}>

export type RequestSubscriptionUpgradeMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'requestSubscriptionUpgrade'>

export type SendPasswordResetEmailMutationVariables = Exact<{
  email: Scalars['String']
  source?: Maybe<Scalars['String']>
}>

export type SendPasswordResetEmailMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'sendPasswordResetEmail'
>

export type SetPasswordMutationVariables = Exact<{
  email: Scalars['String']
  password: Scalars['String']
  clientName: Scalars['String']
  source?: Maybe<Scalars['String']>
}>

export type SetPasswordMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'setPassword'
>

export type RestoreDeletedOnlineReservationMutationVariables = Exact<{
  clientName: Scalars['String']
  reservationId: Scalars['UUID']
}>

export type RestoreDeletedOnlineReservationMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'restoreDeletedOnlineReservation'>

export type SendForgotEmailNotifRetrieveMutationVariables = Exact<{
  firstName: Scalars['String']
  lastName: Scalars['String']
  backUpEmail: Scalars['String']
  clientName: Scalars['String']
  email: Scalars['String']
}>

export type SendForgotEmailNotifRetrieveMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'sendForgotEmailNotifRetrieve'>

export type SendForgotEmailNotifToAdminMutationVariables = Exact<{
  firstName: Scalars['String']
  lastName: Scalars['String']
  backUpEmail: Scalars['String']
}>

export type SendForgotEmailNotifToAdminMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'sendForgotEmailNotifToAdmin'>

export type SendEmailCampaignMutationVariables = Exact<{
  clientName: Scalars['String']
  emailCampaignId: Scalars['Int']
  ownerId: Scalars['Int']
  testEmailAddress?: Maybe<Scalars['String']>
}>

export type SendEmailCampaignMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'sendEmailCampaign'
>

export type SendFollowUpEmailMutationVariables = Exact<{
  clientName: Scalars['String']
  emailCampaignId: Scalars['Int']
  ownerId: Scalars['Int']
  emails?: Maybe<Array<Scalars['String']> | Scalars['String']>
  content: Scalars['String']
}>

export type SendFollowUpEmailMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'sendFollowUpEmail'
>

export type SendInsightsMarketingAutomationInquiryMutationVariables = Exact<{
  clientName: Scalars['String']
  userEmail: Scalars['EmailAddress']
  userPhone?: Maybe<Scalars['String']>
}>

export type SendInsightsMarketingAutomationInquiryMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'sendInsightsMarketingAutomationInquiry'>

export type SendInsightsOnlineReservationInquiryMutationVariables = Exact<{
  clientName: Scalars['String']
  userEmail: Scalars['EmailAddress']
  userPhone?: Maybe<Scalars['String']>
}>

export type SendInsightsOnlineReservationInquiryMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'sendInsightsOnlineReservationInquiry'>

export type SendRecommendedPlansEmailMutationVariables = Exact<{
  clientName: Scalars['String']
  prospectName: Scalars['String']
  prospectEmail: Scalars['String']
  agentEmail: Scalars['String']
  agentName: Scalars['String']
  plans: Array<RecommendedPlan> | RecommendedPlan
}>

export type SendRecommendedPlansEmailMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'sendRecommendedPlansEmail'>

export type SetLeadBrowseAgentNotificationConfigMutationVariables = Exact<{
  clientName: Scalars['String']
  enabled: Scalars['Boolean']
  frequency: Scalars['Int']
}>

export type SetLeadBrowseAgentNotificationConfigMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'setLeadBrowseAgentNotificationConfig'>

export type SetNewLeadAgentNotificationConfigMutationVariables = Exact<{
  clientName: Scalars['String']
  enabled: Scalars['Boolean']
}>

export type SetNewLeadAgentNotificationConfigMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'setNewLeadAgentNotificationConfig'>

export type SetProspectClientRatingMutationVariables = Exact<{
  clientName: Scalars['String']
  prospectEmail: Scalars['String']
  clientRating: Scalars['String']
}>

export type SetProspectClientRatingMutation = { __typename?: 'Mutation' } & {
  setProspectClientRating?: Maybe<
    { __typename?: 'Prospect' } & Pick<
      Prospect,
      'id' | 'email' | 'clientRating'
    >
  >
}

export type SetSubscriptionEndpointMutationVariables = Exact<{
  clientName: Scalars['String']
  endpoint: Scalars['String']
}>

export type SetSubscriptionEndpointMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'setSubscriptionEndpoint'>

export type TestSubscriptionEndpointMutationVariables = Exact<{
  clientName: Scalars['String']
  subscriptionLevel?: Maybe<Scalars['String']>
}>

export type TestSubscriptionEndpointMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'testSubscriptionEndpoint'>

export type UpdateColonnadeUserMutationVariables = Exact<{
  callerUserId: Scalars['Int']
  userId: Scalars['Int']
  input: UpdateColonnadeUserInput
}>

export type UpdateColonnadeUserMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'updateColonnadeUser'
>

export type UpdateEmailCampaignMutationVariables = Exact<{
  clientName: Scalars['String']
  emailCampaignId: Scalars['Int']
  emailCampaign: EmailCampaignInput
  cohort?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
  sharing?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
}>

export type UpdateEmailCampaignMutation = { __typename?: 'Mutation' } & {
  updateEmailCampaign?: Maybe<
    { __typename?: 'EmailCampaign' } & Pick<
      EmailCampaign,
      | 'id'
      | 'communityId'
      | 'planId'
      | 'elevationId'
      | 'lotId'
      | 'inventoryId'
      | 'ownerId'
      | 'templateId'
      | 'name'
      | 'mainImage'
      | 'replyTo'
      | 'replyToName'
      | 'subject'
      | 'body'
      | 'signature'
      | 'active'
      | 'createdAt'
      | 'lastUpdated'
      | 'searchCommunity'
      | 'searchPlan'
    > & {
        cohort: Array<
          { __typename?: 'EmailCampaignCohort' } & Pick<
            EmailCampaignCohort,
            'id' | 'prospectId' | 'status' | 'active'
          > & {
              prospect?: Maybe<
                { __typename?: 'Prospect' } & Pick<
                  Prospect,
                  | 'id'
                  | 'status'
                  | 'registrationDate'
                  | 'name'
                  | 'firstName'
                  | 'lastName'
                  | 'email'
                >
              >
            }
        >
        sections: Array<
          { __typename?: 'EmailCampaignSection' } & Pick<
            EmailCampaignSection,
            'id' | 'content' | 'index'
          > & {
              images: Array<
                { __typename?: 'EmailCampaignSectionImage' } & Pick<
                  EmailCampaignSectionImage,
                  'id' | 'emailCampaignSectionId' | 'index' | 'src'
                >
              >
            }
        >
      }
  >
}

export type UpdateProspectMutationVariables = Exact<{
  clientName: Scalars['String']
  prospect: UpdateProspectInput
}>

export type UpdateProspectMutation = { __typename?: 'Mutation' } & {
  updateProspect?: Maybe<
    { __typename?: 'Prospect' } & Pick<Prospect, 'id' | 'status'>
  >
}

export type GetAgentsQueryVariables = Exact<{
  clientName: Scalars['String']
  communityIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
}>

export type GetAgentsQuery = { __typename?: 'Query' } & {
  agentsByClient: Array<
    { __typename?: 'Agent' } & Pick<
      Agent,
      'firstName' | 'lastName' | 'picture' | 'email' | 'id'
    >
  >
}

export type CityFieldsMinimalFragment = { __typename?: 'CityInfo' } & Pick<
  CityInfo,
  'id' | 'name' | 'customName'
> & {
    region?: Maybe<
      | ({ __typename: 'USCityRegionInfo' } & Pick<
          UsCityRegionInfo,
          | 'stateCode'
          | 'stateName'
          | 'metroName'
          | 'metroCustomName'
          | 'zipCode'
        >)
      | ({ __typename: 'CANCityRegionInfo' } & Pick<
          CanCityRegionInfo,
          | 'provinceCode'
          | 'provinceName'
          | 'districtCode'
          | 'districtName'
          | 'postCode'
        >)
    >
  }

export type CityFieldsFullFragment = { __typename?: 'CityInfo' } & Pick<
  CityInfo,
  'id' | 'name' | 'customName'
> & {
    region?: Maybe<
      | ({ __typename: 'USCityRegionInfo' } & Pick<
          UsCityRegionInfo,
          | 'stateCode'
          | 'stateName'
          | 'countyCode'
          | 'countyName'
          | 'metroId'
          | 'metroName'
          | 'metroCustomName'
          | 'zipCode'
        >)
      | ({ __typename: 'CANCityRegionInfo' } & Pick<
          CanCityRegionInfo,
          | 'provinceCode'
          | 'provinceName'
          | 'districtCode'
          | 'districtName'
          | 'postCode'
        >)
    >
  }

export type CommunitiesQueryVariables = Exact<{
  clientName: Scalars['String']
}>

export type CommunitiesQuery = { __typename?: 'Query' } & {
  communities: Array<
    { __typename?: 'Community' } & Pick<
      Community,
      | 'id'
      | 'clientName'
      | 'name'
      | 'latitude'
      | 'longitude'
      | 'logo'
      | 'active'
      | 'thumb'
    > & {
        cityLocation?: Maybe<
          { __typename?: 'CityInfo' } & CityFieldsFullFragment
        >
      }
  >
}

export type CamberCommunityIdsQueryVariables = Exact<{
  clientName: Scalars['String']
}>

export type CamberCommunityIdsQuery = { __typename?: 'Query' } & {
  communities: Array<
    { __typename?: 'Community' } & Pick<
      Community,
      'id' | 'salesCenterActive' | 'name'
    >
  >
}

export type CommunitiesByIdsQueryVariables = Exact<{
  clientName: Scalars['String']
  communityIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
  filter: CommonQueryFilter
}>

export type CommunitiesByIdsQuery = { __typename?: 'Query' } & {
  communities: Array<
    { __typename?: 'Community' } & Pick<
      Community,
      | 'id'
      | 'clientName'
      | 'name'
      | 'latitude'
      | 'longitude'
      | 'logo'
      | 'active'
    > & {
        cityLocation?: Maybe<
          { __typename?: 'CityInfo' } & CityFieldsFullFragment
        >
        plans: Array<
          { __typename?: 'Plan' } & Pick<
            Plan,
            | 'id'
            | 'clientName'
            | 'communityId'
            | 'name'
            | 'displayName'
            | 'defaultElevationId'
            | 'bed'
            | 'bedMin'
            | 'bedMax'
            | 'bath'
            | 'bathMin'
            | 'bathMax'
            | 'size'
            | 'sizeMin'
            | 'sizeMax'
            | 'cost'
            | 'costMin'
            | 'costMax'
            | 'description'
          > & {
              bedRange?: Maybe<
                { __typename: 'IntRange' } & Pick<IntRange, 'min' | 'max'>
              >
              bathRange?: Maybe<
                { __typename?: 'FloatRange' } & Pick<FloatRange, 'min' | 'max'>
              >
              sizeRange?: Maybe<
                { __typename?: 'IntRange' } & Pick<IntRange, 'min' | 'max'>
              >
              costRange?: Maybe<
                { __typename?: 'IntRange' } & Pick<IntRange, 'min' | 'max'>
              >
              elevations: Array<
                { __typename?: 'Elevation' } & Pick<
                  Elevation,
                  | 'id'
                  | 'planDisplayName'
                  | 'internalName'
                  | 'planName'
                  | 'active'
                >
              >
            }
        >
        siteplans: Array<
          { __typename?: 'Siteplan' } & Pick<
            Siteplan,
            | 'id'
            | 'communityId'
            | 'name'
            | 'src'
            | 'lotWidth'
            | 'lotHeight'
            | 'lotFontSize'
            | 'lotMetric'
            | 'master'
          > & {
              svg?: Maybe<
                { __typename?: 'SVG' } & Pick<Svg, 'style'> & {
                    shapes: Array<
                      { __typename: 'SVGShape' } & Pick<SvgShape, 'tagName'> & {
                          attributes: {
                            __typename: 'SVGShapeAttributes'
                          } & Pick<
                            SvgShapeAttributes,
                            | 'className'
                            | 'dataName'
                            | 'x'
                            | 'y'
                            | 'width'
                            | 'height'
                            | 'transform'
                            | 'points'
                            | 'd'
                          >
                        }
                    >
                    viewBox: { __typename?: 'SVGViewBox' } & Pick<
                      SvgViewBox,
                      'x' | 'y' | 'width' | 'height'
                    >
                  }
              >
              lotLegend: Array<
                { __typename?: 'LotLegendEntry' } & Pick<
                  LotLegendEntry,
                  'id' | 'code' | 'name' | 'hex'
                >
              >
              hotspots: Array<
                { __typename?: 'SiteplanHotspot' } & Pick<
                  SiteplanHotspot,
                  | 'id'
                  | 'siteplanId'
                  | 'name'
                  | 'x'
                  | 'y'
                  | 'width'
                  | 'height'
                  | 'description'
                  | 'thumb'
                >
              >
              popularLots?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'Lot' } & Pick<
                      Lot,
                      | 'id'
                      | 'communityId'
                      | 'dataName'
                      | 'name'
                      | 'salesStatus'
                      | 'premium'
                      | 'externalId'
                      | 'address'
                      | 'size'
                      | 'cityName'
                      | 'stateCode'
                      | 'zip'
                      | 'postCode'
                      | 'popularity'
                      | 'count'
                    > & {
                        siteplanInfo?: Maybe<
                          { __typename?: 'LotSiteplanInfo' } & Pick<
                            LotSiteplanInfo,
                            'lotId' | 'siteplanId' | 'x' | 'y'
                          >
                        >
                        availableSchemes: Array<
                          { __typename?: 'Scheme' } & Pick<
                            Scheme,
                            'id' | 'name'
                          >
                        >
                        excludedPlanElevations: Array<
                          { __typename?: 'PlanElevation' } & Pick<
                            PlanElevation,
                            | 'planId'
                            | 'elevationId'
                            | 'planName'
                            | 'planDisplayName'
                            | 'elevationCaption'
                          >
                        >
                        availablePlanElevations: Array<
                          { __typename?: 'PlanElevation' } & Pick<
                            PlanElevation,
                            | 'planId'
                            | 'elevationId'
                            | 'planName'
                            | 'planDisplayName'
                            | 'elevationCaption'
                          >
                        >
                        inventory?: Maybe<
                          { __typename?: 'Inventory' } & Pick<
                            Inventory,
                            | 'id'
                            | 'lotId'
                            | 'planId'
                            | 'elevationId'
                            | 'communityId'
                            | 'price'
                            | 'sqft'
                            | 'beds'
                            | 'baths'
                            | 'features'
                            | 'constructionStatus'
                            | 'closingDate'
                            | 'mlsId'
                            | 'garageType'
                            | 'garageCapacity'
                            | 'floors'
                            | 'schemeId'
                            | 'photoFolder'
                            | 'appointmentsEnabled'
                            | 'reserveHomeUrl'
                          > & {
                              plan?: Maybe<
                                { __typename?: 'Plan' } & Pick<
                                  Plan,
                                  | 'id'
                                  | 'communityId'
                                  | 'name'
                                  | 'displayName'
                                  | 'defaultElevationId'
                                  | 'description'
                                  | 'bed'
                                  | 'bath'
                                  | 'cost'
                                  | 'size'
                                  | 'videoUrl'
                                  | 'interactiveInteriorIds'
                                > & {
                                    bedRange?: Maybe<
                                      { __typename?: 'IntRange' } & Pick<
                                        IntRange,
                                        'min' | 'max'
                                      >
                                    >
                                    bathRange?: Maybe<
                                      { __typename?: 'FloatRange' } & Pick<
                                        FloatRange,
                                        'min' | 'max'
                                      >
                                    >
                                    costRange?: Maybe<
                                      { __typename?: 'IntRange' } & Pick<
                                        IntRange,
                                        'min' | 'max'
                                      >
                                    >
                                    sizeRange?: Maybe<
                                      { __typename?: 'IntRange' } & Pick<
                                        IntRange,
                                        'min' | 'max'
                                      >
                                    >
                                  }
                              >
                              sgtData?: Maybe<
                                { __typename?: 'InventorySgtData' } & Pick<
                                  InventorySgtData,
                                  | 'id'
                                  | 'sgtVendorId'
                                  | 'sgtExternalId'
                                  | 'sgtData'
                                >
                              >
                              photos: Array<
                                { __typename?: 'InventoryPhoto' } & Pick<
                                  InventoryPhoto,
                                  'id' | 'src' | 'listIndex'
                                >
                              >
                              elevation?: Maybe<
                                { __typename?: 'Elevation' } & Pick<
                                  Elevation,
                                  | 'id'
                                  | 'mirrorElevationId'
                                  | 'mirrorRolePrimary'
                                  | 'communityId'
                                  | 'planName'
                                  | 'planDisplayName'
                                  | 'planId'
                                  | 'caption'
                                  | 'thumb'
                                  | 'bed'
                                  | 'bath'
                                  | 'size'
                                  | 'cost'
                                  | 'defaultFloor'
                                  | 'description'
                                  | 'svgMirroring'
                                  | 'garagePosition'
                                  | 'defaultGaragePosition'
                                > & {
                                    bedRange?: Maybe<
                                      { __typename?: 'IntRange' } & Pick<
                                        IntRange,
                                        'min' | 'max'
                                      >
                                    >
                                    bathRange?: Maybe<
                                      { __typename?: 'FloatRange' } & Pick<
                                        FloatRange,
                                        'min' | 'max'
                                      >
                                    >
                                    costRange?: Maybe<
                                      { __typename?: 'IntRange' } & Pick<
                                        IntRange,
                                        'min' | 'max'
                                      >
                                    >
                                    sizeRange?: Maybe<
                                      { __typename?: 'IntRange' } & Pick<
                                        IntRange,
                                        'min' | 'max'
                                      >
                                    >
                                    tags: Array<
                                      {
                                        __typename?: 'PlanTagElevation'
                                      } & Pick<
                                        PlanTagElevation,
                                        'categoryName' | 'tagName'
                                      >
                                    >
                                  }
                              >
                            }
                        >
                      }
                  >
                >
              >
            }
        >
      }
  >
}

export type CommunitiesByIdsSiteplansInfoQueryVariables = Exact<{
  clientName: Scalars['String']
  communityIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
  filter: CommonQueryFilter
}>

export type CommunitiesByIdsSiteplansInfoQuery = { __typename?: 'Query' } & {
  communities: Array<
    { __typename?: 'Community' } & Pick<
      Community,
      | 'id'
      | 'clientName'
      | 'name'
      | 'latitude'
      | 'longitude'
      | 'logo'
      | 'active'
    > & {
        siteplans: Array<
          { __typename?: 'Siteplan' } & Pick<
            Siteplan,
            | 'id'
            | 'communityId'
            | 'name'
            | 'src'
            | 'lotWidth'
            | 'lotHeight'
            | 'lotFontSize'
            | 'lotMetric'
            | 'master'
          > & {
              geoInfo?: Maybe<
                { __typename?: 'SiteplanGeoInfo' } & Pick<
                  SiteplanGeoInfo,
                  | 'id'
                  | 'siteplanId'
                  | 'neLatitude'
                  | 'neLongitude'
                  | 'swLatitude'
                  | 'swLongitude'
                  | 'geoJson'
                >
              >
              svg?: Maybe<
                { __typename?: 'SVG' } & Pick<Svg, 'style'> & {
                    shapes: Array<
                      { __typename: 'SVGShape' } & Pick<SvgShape, 'tagName'> & {
                          attributes: {
                            __typename: 'SVGShapeAttributes'
                          } & Pick<
                            SvgShapeAttributes,
                            | 'className'
                            | 'dataName'
                            | 'x'
                            | 'y'
                            | 'width'
                            | 'height'
                            | 'transform'
                            | 'points'
                            | 'd'
                          >
                        }
                    >
                    viewBox: { __typename?: 'SVGViewBox' } & Pick<
                      SvgViewBox,
                      'x' | 'y' | 'width' | 'height'
                    >
                  }
              >
              lotLegend: Array<
                { __typename?: 'LotLegendEntry' } & Pick<
                  LotLegendEntry,
                  'id' | 'code' | 'name' | 'hex'
                >
              >
              hotspots: Array<
                { __typename?: 'SiteplanHotspot' } & Pick<
                  SiteplanHotspot,
                  | 'id'
                  | 'siteplanId'
                  | 'name'
                  | 'x'
                  | 'y'
                  | 'width'
                  | 'height'
                  | 'description'
                  | 'thumb'
                >
              >
              lots: Array<
                { __typename?: 'Lot' } & Pick<
                  Lot,
                  | 'id'
                  | 'communityId'
                  | 'dataName'
                  | 'name'
                  | 'salesStatus'
                  | 'premium'
                  | 'externalId'
                  | 'address'
                  | 'size'
                  | 'cityName'
                  | 'stateCode'
                  | 'zip'
                  | 'postCode'
                  | 'geoJson'
                > & {
                    siteplanInfo?: Maybe<
                      { __typename?: 'LotSiteplanInfo' } & Pick<
                        LotSiteplanInfo,
                        'lotId' | 'siteplanId' | 'x' | 'y'
                      >
                    >
                  }
              >
              popularLots?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'Lot' } & Pick<
                      Lot,
                      | 'id'
                      | 'communityId'
                      | 'dataName'
                      | 'name'
                      | 'salesStatus'
                      | 'premium'
                      | 'externalId'
                      | 'address'
                      | 'size'
                      | 'cityName'
                      | 'stateCode'
                      | 'zip'
                      | 'postCode'
                      | 'popularity'
                      | 'count'
                      | 'leadsCount'
                    > & {
                        siteplanInfo?: Maybe<
                          { __typename?: 'LotSiteplanInfo' } & Pick<
                            LotSiteplanInfo,
                            'lotId' | 'siteplanId' | 'x' | 'y'
                          >
                        >
                      }
                  >
                >
              >
            }
        >
      }
  >
}

export type CommunitiesWithImagesQueryVariables = Exact<{
  clientName: Scalars['String']
  active?: Maybe<Scalars['Boolean']>
}>

export type CommunitiesWithImagesQuery = { __typename?: 'Query' } & {
  communities: Array<
    { __typename?: 'Community' } & Pick<
      Community,
      | 'id'
      | 'clientName'
      | 'name'
      | 'latitude'
      | 'longitude'
      | 'logo'
      | 'active'
      | 'thumb'
    > & {
        cityLocation?: Maybe<
          { __typename?: 'CityInfo' } & CityFieldsFullFragment
        >
        plans: Array<
          { __typename?: 'Plan' } & Pick<
            Plan,
            'id' | 'clientName' | 'communityId' | 'name' | 'displayName'
          > & {
              elevations: Array<
                { __typename?: 'Elevation' } & Pick<
                  Elevation,
                  | 'id'
                  | 'planDisplayName'
                  | 'internalName'
                  | 'planName'
                  | 'active'
                >
              >
            }
        >
        imgs: Array<
          { __typename?: 'CommunityImg' } & Pick<
            CommunityImg,
            'src' | 'listIndex' | 'communityId' | 'videoSrc'
          >
        >
        mainPhotos: Array<
          { __typename?: 'CommunityMainPhoto' } & Pick<
            CommunityMainPhoto,
            'id' | 'src' | 'listIndex'
          >
        >
        overviewPhotos: Array<
          { __typename?: 'CommunityOverviewPhoto' } & Pick<
            CommunityOverviewPhoto,
            'id' | 'src' | 'listIndex'
          >
        >
        siteplans: Array<
          { __typename?: 'Siteplan' } & Pick<
            Siteplan,
            | 'id'
            | 'name'
            | 'lotFontSize'
            | 'lotMetric'
            | 'lotWidth'
            | 'lotHeight'
            | 'master'
            | 'src'
          > & {
              lots: Array<
                { __typename?: 'Lot' } & Pick<
                  Lot,
                  | 'id'
                  | 'communityId'
                  | 'dataName'
                  | 'name'
                  | 'salesStatus'
                  | 'premium'
                  | 'externalId'
                  | 'address'
                  | 'size'
                  | 'cityName'
                  | 'stateCode'
                  | 'zip'
                  | 'postCode'
                > & {
                    siteplanInfo?: Maybe<
                      { __typename?: 'LotSiteplanInfo' } & Pick<
                        LotSiteplanInfo,
                        'lotId' | 'siteplanId' | 'x' | 'y'
                      >
                    >
                    inventory?: Maybe<
                      { __typename?: 'Inventory' } & Pick<
                        Inventory,
                        | 'id'
                        | 'lotId'
                        | 'planId'
                        | 'elevationId'
                        | 'communityId'
                        | 'price'
                        | 'sqft'
                        | 'beds'
                        | 'baths'
                        | 'features'
                        | 'constructionStatus'
                        | 'closingDate'
                        | 'mlsId'
                        | 'garageType'
                        | 'garageCapacity'
                        | 'floors'
                        | 'schemeId'
                        | 'photoFolder'
                        | 'appointmentsEnabled'
                        | 'reserveHomeUrl'
                      > & {
                          photos: Array<
                            { __typename?: 'InventoryPhoto' } & Pick<
                              InventoryPhoto,
                              'id' | 'src' | 'listIndex'
                            >
                          >
                          elevation?: Maybe<
                            { __typename?: 'Elevation' } & Pick<
                              Elevation,
                              | 'id'
                              | 'mirrorElevationId'
                              | 'mirrorRolePrimary'
                              | 'communityId'
                              | 'planName'
                              | 'planDisplayName'
                              | 'planId'
                              | 'caption'
                              | 'thumb'
                              | 'bed'
                              | 'bath'
                              | 'size'
                              | 'cost'
                              | 'defaultFloor'
                              | 'description'
                              | 'svgMirroring'
                              | 'garagePosition'
                              | 'defaultGaragePosition'
                            > & {
                                bedRange?: Maybe<
                                  { __typename?: 'IntRange' } & Pick<
                                    IntRange,
                                    'min' | 'max'
                                  >
                                >
                                bathRange?: Maybe<
                                  { __typename?: 'FloatRange' } & Pick<
                                    FloatRange,
                                    'min' | 'max'
                                  >
                                >
                                costRange?: Maybe<
                                  { __typename?: 'IntRange' } & Pick<
                                    IntRange,
                                    'min' | 'max'
                                  >
                                >
                                sizeRange?: Maybe<
                                  { __typename?: 'IntRange' } & Pick<
                                    IntRange,
                                    'min' | 'max'
                                  >
                                >
                                tags: Array<
                                  { __typename?: 'PlanTagElevation' } & Pick<
                                    PlanTagElevation,
                                    'categoryName' | 'tagName'
                                  >
                                >
                              }
                          >
                        }
                    >
                  }
              >
            }
        >
      }
  >
}

export type CommunityQueryVariables = Exact<{
  clientName: Scalars['String']
  communityId: Scalars['Int']
  filter: CommonQueryFilter
}>

export type CommunityQuery = { __typename?: 'Query' } & {
  community?: Maybe<
    { __typename?: 'Community' } & Pick<Community, 'id' | 'name'> & {
        siteplans: Array<
          { __typename?: 'Siteplan' } & Pick<
            Siteplan,
            | 'id'
            | 'name'
            | 'src'
            | 'lotWidth'
            | 'lotHeight'
            | 'lotFontSize'
            | 'lotMetric'
            | 'master'
          > & {
              svg?: Maybe<
                { __typename?: 'SVG' } & Pick<Svg, 'style'> & {
                    shapes: Array<
                      { __typename: 'SVGShape' } & Pick<SvgShape, 'tagName'> & {
                          attributes: {
                            __typename: 'SVGShapeAttributes'
                          } & Pick<
                            SvgShapeAttributes,
                            | 'className'
                            | 'dataName'
                            | 'x'
                            | 'y'
                            | 'width'
                            | 'height'
                            | 'transform'
                            | 'points'
                            | 'd'
                          >
                        }
                    >
                    viewBox: { __typename?: 'SVGViewBox' } & Pick<
                      SvgViewBox,
                      'x' | 'y' | 'width' | 'height'
                    >
                  }
              >
              lotLegend: Array<
                { __typename?: 'LotLegendEntry' } & Pick<
                  LotLegendEntry,
                  'id' | 'code' | 'name' | 'hex'
                >
              >
              hotspots: Array<
                { __typename?: 'SiteplanHotspot' } & Pick<
                  SiteplanHotspot,
                  | 'id'
                  | 'siteplanId'
                  | 'name'
                  | 'x'
                  | 'y'
                  | 'width'
                  | 'height'
                  | 'description'
                  | 'thumb'
                >
              >
              popularLots?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'Lot' } & Pick<
                      Lot,
                      | 'id'
                      | 'communityId'
                      | 'dataName'
                      | 'name'
                      | 'salesStatus'
                      | 'premium'
                      | 'externalId'
                      | 'address'
                      | 'size'
                      | 'cityName'
                      | 'stateCode'
                      | 'zip'
                      | 'postCode'
                      | 'popularity'
                      | 'count'
                    > & {
                        siteplanInfo?: Maybe<
                          { __typename?: 'LotSiteplanInfo' } & Pick<
                            LotSiteplanInfo,
                            'lotId' | 'siteplanId' | 'x' | 'y'
                          >
                        >
                        availableSchemes: Array<
                          { __typename?: 'Scheme' } & Pick<
                            Scheme,
                            'id' | 'name'
                          >
                        >
                        excludedPlanElevations: Array<
                          { __typename?: 'PlanElevation' } & Pick<
                            PlanElevation,
                            | 'planId'
                            | 'elevationId'
                            | 'planName'
                            | 'planDisplayName'
                            | 'elevationCaption'
                          >
                        >
                        availablePlanElevations: Array<
                          { __typename?: 'PlanElevation' } & Pick<
                            PlanElevation,
                            | 'planId'
                            | 'elevationId'
                            | 'planName'
                            | 'planDisplayName'
                            | 'elevationCaption'
                          >
                        >
                        inventory?: Maybe<
                          { __typename?: 'Inventory' } & Pick<
                            Inventory,
                            | 'id'
                            | 'lotId'
                            | 'planId'
                            | 'elevationId'
                            | 'communityId'
                            | 'price'
                            | 'sqft'
                            | 'beds'
                            | 'baths'
                            | 'features'
                            | 'constructionStatus'
                            | 'closingDate'
                            | 'mlsId'
                            | 'garageType'
                            | 'garageCapacity'
                            | 'floors'
                            | 'schemeId'
                            | 'photoFolder'
                            | 'appointmentsEnabled'
                            | 'reserveHomeUrl'
                          > & {
                              plan?: Maybe<
                                { __typename?: 'Plan' } & Pick<
                                  Plan,
                                  | 'id'
                                  | 'communityId'
                                  | 'name'
                                  | 'displayName'
                                  | 'defaultElevationId'
                                  | 'description'
                                  | 'bed'
                                  | 'bath'
                                  | 'cost'
                                  | 'size'
                                  | 'videoUrl'
                                  | 'interactiveInteriorIds'
                                > & {
                                    bedRange?: Maybe<
                                      { __typename?: 'IntRange' } & Pick<
                                        IntRange,
                                        'min' | 'max'
                                      >
                                    >
                                    bathRange?: Maybe<
                                      { __typename?: 'FloatRange' } & Pick<
                                        FloatRange,
                                        'min' | 'max'
                                      >
                                    >
                                    costRange?: Maybe<
                                      { __typename?: 'IntRange' } & Pick<
                                        IntRange,
                                        'min' | 'max'
                                      >
                                    >
                                    sizeRange?: Maybe<
                                      { __typename?: 'IntRange' } & Pick<
                                        IntRange,
                                        'min' | 'max'
                                      >
                                    >
                                  }
                              >
                              sgtData?: Maybe<
                                { __typename?: 'InventorySgtData' } & Pick<
                                  InventorySgtData,
                                  | 'id'
                                  | 'sgtVendorId'
                                  | 'sgtExternalId'
                                  | 'sgtData'
                                >
                              >
                              photos: Array<
                                { __typename?: 'InventoryPhoto' } & Pick<
                                  InventoryPhoto,
                                  'id' | 'src' | 'listIndex'
                                >
                              >
                              elevation?: Maybe<
                                { __typename?: 'Elevation' } & Pick<
                                  Elevation,
                                  | 'id'
                                  | 'mirrorElevationId'
                                  | 'mirrorRolePrimary'
                                  | 'communityId'
                                  | 'planName'
                                  | 'planDisplayName'
                                  | 'planId'
                                  | 'caption'
                                  | 'thumb'
                                  | 'bed'
                                  | 'bath'
                                  | 'size'
                                  | 'cost'
                                  | 'defaultFloor'
                                  | 'description'
                                  | 'svgMirroring'
                                  | 'garagePosition'
                                  | 'defaultGaragePosition'
                                > & {
                                    bedRange?: Maybe<
                                      { __typename?: 'IntRange' } & Pick<
                                        IntRange,
                                        'min' | 'max'
                                      >
                                    >
                                    bathRange?: Maybe<
                                      { __typename?: 'FloatRange' } & Pick<
                                        FloatRange,
                                        'min' | 'max'
                                      >
                                    >
                                    costRange?: Maybe<
                                      { __typename?: 'IntRange' } & Pick<
                                        IntRange,
                                        'min' | 'max'
                                      >
                                    >
                                    sizeRange?: Maybe<
                                      { __typename?: 'IntRange' } & Pick<
                                        IntRange,
                                        'min' | 'max'
                                      >
                                    >
                                    tags: Array<
                                      {
                                        __typename?: 'PlanTagElevation'
                                      } & Pick<
                                        PlanTagElevation,
                                        'categoryName' | 'tagName'
                                      >
                                    >
                                  }
                              >
                            }
                        >
                      }
                  >
                >
              >
            }
        >
      }
  >
}

export type GetEmailCampaignQueryVariables = Exact<{
  clientName: Scalars['String']
  emailCampaignId: Scalars['Int']
}>

export type GetEmailCampaignQuery = { __typename?: 'Query' } & {
  getEmailCampaign?: Maybe<
    { __typename?: 'EmailCampaign' } & Pick<
      EmailCampaign,
      | 'id'
      | 'communityId'
      | 'planId'
      | 'elevationId'
      | 'lotId'
      | 'inventoryId'
      | 'clientName'
      | 'ownerId'
      | 'templateId'
      | 'name'
      | 'mainImage'
      | 'replyTo'
      | 'replyToName'
      | 'subject'
      | 'body'
      | 'opener'
      | 'signature'
      | 'active'
      | 'createdAt'
      | 'lastUpdated'
      | 'searchCommunity'
      | 'searchPlan'
    > & {
        cohort: Array<
          { __typename?: 'EmailCampaignCohort' } & Pick<
            EmailCampaignCohort,
            | 'id'
            | 'emailCampaignId'
            | 'prospectId'
            | 'status'
            | 'lastFollowUp'
            | 'active'
          > & {
              prospect?: Maybe<
                { __typename?: 'Prospect' } & Pick<
                  Prospect,
                  | 'id'
                  | 'status'
                  | 'registrationDate'
                  | 'name'
                  | 'firstName'
                  | 'lastName'
                  | 'email'
                  | 'receiveCampaignEmails'
                >
              >
            }
        >
        sections: Array<
          { __typename?: 'EmailCampaignSection' } & Pick<
            EmailCampaignSection,
            'id' | 'content' | 'index'
          > & {
              images: Array<
                { __typename?: 'EmailCampaignSectionImage' } & Pick<
                  EmailCampaignSectionImage,
                  'id' | 'emailCampaignSectionId' | 'index' | 'src'
                >
              >
            }
        >
        community?: Maybe<
          { __typename?: 'Community' } & Pick<Community, 'id' | 'name'>
        >
        lot?: Maybe<
          { __typename?: 'Lot' } & Pick<
            Lot,
            | 'id'
            | 'name'
            | 'address'
            | 'metroName'
            | 'cityName'
            | 'stateCode'
            | 'zip'
            | 'postCode'
            | 'countryCode'
          >
        >
        inventory?: Maybe<
          { __typename?: 'Inventory' } & Pick<Inventory, 'id'> & {
              plan?: Maybe<
                { __typename?: 'Plan' } & Pick<
                  Plan,
                  'id' | 'name' | 'displayName'
                >
              >
              elevation?: Maybe<
                { __typename?: 'Elevation' } & Pick<
                  Elevation,
                  'id' | 'caption' | 'internalName'
                >
              >
              lot?: Maybe<
                { __typename?: 'Lot' } & Pick<
                  Lot,
                  | 'id'
                  | 'address'
                  | 'metroName'
                  | 'cityName'
                  | 'stateCode'
                  | 'zip'
                  | 'postCode'
                  | 'countryCode'
                  | 'name'
                >
              >
            }
        >
        plan?: Maybe<
          { __typename?: 'Plan' } & Pick<Plan, 'id' | 'name' | 'displayName'>
        >
        elevation?: Maybe<
          { __typename?: 'Elevation' } & Pick<
            Elevation,
            'id' | 'caption' | 'internalName'
          >
        >
      }
  >
}

export type GetEmailCampaignCohortStatusQueryVariables = Exact<{
  clientName: Scalars['String']
  emailCampaignId: Scalars['Int']
}>

export type GetEmailCampaignCohortStatusQuery = { __typename?: 'Query' } & {
  getEmailCampaign?: Maybe<
    { __typename?: 'EmailCampaign' } & Pick<EmailCampaign, 'id'> & {
        cohort: Array<
          { __typename?: 'EmailCampaignCohort' } & Pick<
            EmailCampaignCohort,
            'id' | 'status'
          >
        >
      }
  >
}

export type GetEmailCampaignsQueryVariables = Exact<{
  clientName: Scalars['String']
  shared?: Maybe<Scalars['Boolean']>
}>

export type GetEmailCampaignsQuery = { __typename?: 'Query' } & {
  getEmailCampaigns: Array<
    { __typename?: 'EmailCampaign' } & Pick<
      EmailCampaign,
      | 'id'
      | 'communityId'
      | 'planId'
      | 'elevationId'
      | 'lotId'
      | 'inventoryId'
      | 'clientName'
      | 'ownerId'
      | 'templateId'
      | 'name'
      | 'mainImage'
      | 'replyTo'
      | 'replyToName'
      | 'subject'
      | 'body'
      | 'opener'
      | 'signature'
      | 'active'
      | 'createdAt'
      | 'lastUpdated'
      | 'searchCommunity'
      | 'searchPlan'
    > & {
        cohort: Array<
          { __typename?: 'EmailCampaignCohort' } & Pick<
            EmailCampaignCohort,
            | 'id'
            | 'emailCampaignId'
            | 'prospectId'
            | 'status'
            | 'lastFollowUp'
            | 'active'
          > & {
              prospect?: Maybe<
                { __typename?: 'Prospect' } & Pick<
                  Prospect,
                  | 'id'
                  | 'status'
                  | 'registrationDate'
                  | 'name'
                  | 'firstName'
                  | 'lastName'
                  | 'email'
                >
              >
            }
        >
        sections: Array<
          { __typename?: 'EmailCampaignSection' } & Pick<
            EmailCampaignSection,
            'id' | 'content' | 'index'
          > & {
              images: Array<
                { __typename?: 'EmailCampaignSectionImage' } & Pick<
                  EmailCampaignSectionImage,
                  'id' | 'emailCampaignSectionId' | 'index' | 'src'
                >
              >
            }
        >
        community?: Maybe<
          { __typename?: 'Community' } & Pick<Community, 'id' | 'name'>
        >
        lot?: Maybe<
          { __typename?: 'Lot' } & Pick<
            Lot,
            | 'id'
            | 'name'
            | 'address'
            | 'metroName'
            | 'cityName'
            | 'stateCode'
            | 'zip'
            | 'postCode'
            | 'countryCode'
          >
        >
        inventory?: Maybe<
          { __typename?: 'Inventory' } & Pick<Inventory, 'id'> & {
              plan?: Maybe<
                { __typename?: 'Plan' } & Pick<
                  Plan,
                  'id' | 'name' | 'displayName'
                >
              >
              elevation?: Maybe<
                { __typename?: 'Elevation' } & Pick<
                  Elevation,
                  'id' | 'caption' | 'internalName'
                >
              >
              lot?: Maybe<
                { __typename?: 'Lot' } & Pick<
                  Lot,
                  | 'id'
                  | 'name'
                  | 'address'
                  | 'metroName'
                  | 'cityName'
                  | 'stateCode'
                  | 'zip'
                  | 'postCode'
                  | 'countryCode'
                >
              >
            }
        >
        plan?: Maybe<
          { __typename?: 'Plan' } & Pick<Plan, 'id' | 'name' | 'displayName'>
        >
        elevation?: Maybe<
          { __typename?: 'Elevation' } & Pick<
            Elevation,
            'id' | 'caption' | 'internalName'
          >
        >
      }
  >
}

export type GetLeadBrowseAgentNotificationConfigQueryVariables = Exact<{
  clientName: Scalars['String']
}>

export type GetLeadBrowseAgentNotificationConfigQuery = {
  __typename?: 'Query'
} & {
  getLeadBrowseAgentNotificationConfig?: Maybe<
    { __typename?: 'NotificationConfig' } & Pick<
      NotificationConfig,
      'service' | 'enabled' | 'frequency'
    >
  >
}

export type GetNewLeadAgentNotificationConfigQueryVariables = Exact<{
  clientName: Scalars['String']
}>

export type GetNewLeadAgentNotificationConfigQuery = {
  __typename?: 'Query'
} & {
  getNewLeadAgentNotificationConfig?: Maybe<
    { __typename?: 'NotificationConfig' } & Pick<
      NotificationConfig,
      'service' | 'enabled'
    >
  >
}

export type GetSubscriptionEndpointQueryVariables = Exact<{
  clientName: Scalars['String']
}>

export type GetSubscriptionEndpointQuery = { __typename?: 'Query' } & Pick<
  Query,
  'getSubscriptionEndpoint'
>

export type InteriorElementOptionsQueryVariables = Exact<{
  clientName: Scalars['String']
}>

export type InteriorElementOptionsQuery = { __typename?: 'Query' } & {
  interiorElementOptions: Array<
    { __typename?: 'InteriorElementOption' } & Pick<
      InteriorElementOption,
      | 'id'
      | 'name'
      | 'description'
      | 'cost'
      | 'hex'
      | 'interiorListElementId'
      | 'overlaySrc'
      | 'overlaySwatchSrc'
      | 'listIndex'
    >
  >
}

export type LiveProspectsQueryVariables = Exact<{
  clientName: Scalars['String']
  registrationDateTimeFilter?: Maybe<RegDateTimeFilter>
}>

export type LiveProspectsQuery = { __typename?: 'Query' } & {
  colonnadeApiProspect: Array<
    { __typename?: 'Prospect' } & Pick<
      Prospect,
      | 'id'
      | 'email'
      | 'name'
      | 'firstName'
      | 'lastName'
      | 'registrationDate'
      | 'phone'
      | 'picture'
      | 'status'
      | 'colonnadeAgents'
      | 'preferredContactMethods'
    > & {
        agents?: Maybe<
          Array<
            { __typename?: 'AgentProspect' } & Pick<AgentProspect, 'agentId'>
          >
        >
      }
  >
}

export type PaginatedLiveProspectsQueryVariables = Exact<{
  clientName: Scalars['String']
  registrationDateTimeFilter?: Maybe<RegDateTimeFilter>
  statuses?: Maybe<Array<ProspectStatus> | ProspectStatus>
  searchedText?: Maybe<Scalars['String']>
  leadFilter?: Maybe<LeadFilter>
  pagination?: Maybe<Pagination>
  orderBy?: Maybe<OrderBy>
}>

export type PaginatedLiveProspectsQuery = { __typename?: 'Query' } & {
  colonnadeApiPaginatedProspects?: Maybe<
    { __typename?: 'PaginatedProspects' } & Pick<
      PaginatedProspects,
      'totalCount'
    > & {
        prospects: Array<
          { __typename?: 'Prospect' } & Pick<
            Prospect,
            | 'id'
            | 'email'
            | 'name'
            | 'firstName'
            | 'lastName'
            | 'registrationDate'
            | 'phone'
            | 'picture'
            | 'status'
            | 'preferredContactMethods'
          > & {
              agents?: Maybe<
                Array<
                  { __typename?: 'AgentProspect' } & Pick<
                    AgentProspect,
                    'agentId'
                  >
                >
              >
            }
        >
      }
  >
}

export type MasterCommunitiesQueryVariables = Exact<{
  clientName: Scalars['String']
  filter: CommonQueryFilter
}>

export type MasterCommunitiesQuery = { __typename?: 'Query' } & {
  masterCommunities: Array<
    { __typename?: 'MasterCommunity' } & Pick<
      MasterCommunity,
      'id' | 'name'
    > & {
        siteplan?: Maybe<
          { __typename?: 'Siteplan' } & Pick<
            Siteplan,
            | 'id'
            | 'communityId'
            | 'name'
            | 'src'
            | 'lotWidth'
            | 'lotHeight'
            | 'lotFontSize'
            | 'lotMetric'
            | 'master'
          > & {
              svg?: Maybe<
                { __typename?: 'SVG' } & Pick<Svg, 'style'> & {
                    shapes: Array<
                      { __typename: 'SVGShape' } & Pick<SvgShape, 'tagName'> & {
                          attributes: {
                            __typename: 'SVGShapeAttributes'
                          } & Pick<
                            SvgShapeAttributes,
                            | 'className'
                            | 'dataName'
                            | 'x'
                            | 'y'
                            | 'width'
                            | 'height'
                            | 'transform'
                            | 'points'
                            | 'd'
                          >
                        }
                    >
                    viewBox: { __typename?: 'SVGViewBox' } & Pick<
                      SvgViewBox,
                      'x' | 'y' | 'width' | 'height'
                    >
                  }
              >
              lotLegend: Array<
                { __typename?: 'LotLegendEntry' } & Pick<
                  LotLegendEntry,
                  'id' | 'code' | 'name' | 'hex'
                >
              >
              popularLots?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'Lot' } & Pick<
                      Lot,
                      | 'id'
                      | 'communityId'
                      | 'dataName'
                      | 'name'
                      | 'salesStatus'
                      | 'premium'
                      | 'externalId'
                      | 'address'
                      | 'size'
                      | 'cityName'
                      | 'stateCode'
                      | 'zip'
                      | 'postCode'
                      | 'popularity'
                      | 'count'
                    > & {
                        siteplanInfo?: Maybe<
                          { __typename?: 'LotSiteplanInfo' } & Pick<
                            LotSiteplanInfo,
                            'lotId' | 'siteplanId' | 'x' | 'y'
                          >
                        >
                      }
                  >
                >
              >
            }
        >
        communities: Array<
          { __typename?: 'Community' } & Pick<
            Community,
            'id' | 'name' | 'latitude' | 'longitude' | 'logo' | 'active'
          > & {
              siteplans: Array<
                { __typename?: 'Siteplan' } & Pick<
                  Siteplan,
                  | 'id'
                  | 'communityId'
                  | 'name'
                  | 'src'
                  | 'lotWidth'
                  | 'lotHeight'
                  | 'lotFontSize'
                  | 'lotMetric'
                  | 'master'
                > & {
                    svg?: Maybe<
                      { __typename?: 'SVG' } & Pick<Svg, 'style'> & {
                          shapes: Array<
                            { __typename: 'SVGShape' } & Pick<
                              SvgShape,
                              'tagName'
                            > & {
                                attributes: {
                                  __typename: 'SVGShapeAttributes'
                                } & Pick<
                                  SvgShapeAttributes,
                                  | 'className'
                                  | 'dataName'
                                  | 'x'
                                  | 'y'
                                  | 'width'
                                  | 'height'
                                  | 'transform'
                                  | 'points'
                                  | 'd'
                                >
                              }
                          >
                          viewBox: { __typename?: 'SVGViewBox' } & Pick<
                            SvgViewBox,
                            'x' | 'y' | 'width' | 'height'
                          >
                        }
                    >
                    lotLegend: Array<
                      { __typename?: 'LotLegendEntry' } & Pick<
                        LotLegendEntry,
                        'id' | 'code' | 'name' | 'hex'
                      >
                    >
                    popularLots?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'Lot' } & Pick<
                            Lot,
                            | 'id'
                            | 'communityId'
                            | 'dataName'
                            | 'name'
                            | 'salesStatus'
                            | 'premium'
                            | 'externalId'
                            | 'address'
                            | 'size'
                            | 'cityName'
                            | 'stateCode'
                            | 'zip'
                            | 'postCode'
                            | 'popularity'
                            | 'count'
                          > & {
                              siteplanInfo?: Maybe<
                                { __typename?: 'LotSiteplanInfo' } & Pick<
                                  LotSiteplanInfo,
                                  'lotId' | 'siteplanId' | 'x' | 'y'
                                >
                              >
                            }
                        >
                      >
                    >
                  }
              >
            }
        >
      }
  >
}

export type PlanQueryVariables = Exact<{
  clientName: Scalars['String']
  planId: Scalars['Int']
}>

export type PlanQuery = { __typename?: 'Query' } & {
  plan?: Maybe<
    { __typename?: 'Plan' } & Pick<Plan, 'name'> & {
        elevations: Array<
          { __typename?: 'Elevation' } & Pick<
            Elevation,
            | 'id'
            | 'caption'
            | 'internalName'
            | 'mirrorElevationId'
            | 'svgMirroring'
          > & {
              layers: Array<
                { __typename?: 'Layer' } & Pick<
                  Layer,
                  'materialId' | 'name' | 'src'
                >
              >
            }
        >
      }
  >
}

export type PlansWithImagesQueryVariables = Exact<{
  clientName: Scalars['String']
}>

export type PlansWithImagesQuery = { __typename?: 'Query' } & {
  plans: Array<
    { __typename?: 'Plan' } & Pick<Plan, 'id' | 'name' | 'displayName'> & {
        elevations: Array<
          { __typename?: 'Elevation' } & Pick<
            Elevation,
            'id' | 'caption' | 'internalName' | 'thumb'
          >
        >
        exteriorAssets: Array<
          { __typename?: 'Asset' } & Pick<Asset, 'src' | 'listIndex'>
        >
        interiorPhotos: Array<
          { __typename?: 'Photo' } & Pick<Photo, 'src' | 'listIndex'>
        >
      }
  >
}

export type ProspectQueryVariables = Exact<{
  clientName: Scalars['String']
  email: Scalars['String']
}>

export type ProspectQuery = { __typename?: 'Query' } & {
  colonnadeApiProspectByEmail?: Maybe<
    { __typename?: 'Prospect' } & Pick<
      Prospect,
      | 'id'
      | 'email'
      | 'name'
      | 'firstName'
      | 'lastName'
      | 'phone'
      | 'picture'
      | 'source'
      | 'status'
      | 'preferredContactMethods'
      | 'registrationDate'
      | 'consultantName'
      | 'consultantEmail'
      | 'consultantPhone'
      | 'clientRating'
    > & {
        brochures?: Maybe<
          Array<
            { __typename?: 'ProspectBrochures' } & Pick<
              ProspectBrochures,
              'url' | 'creationDate'
            >
          >
        >
        favorites?: Maybe<
          Array<
            { __typename?: 'ProspectFavorite' } & Pick<
              ProspectFavorite,
              | 'id'
              | 'prospectId'
              | 'communityId'
              | 'planId'
              | 'elevationId'
              | 'lotId'
              | 'source'
              | 'clientName'
              | 'fpOptSelections'
              | 'lastUpdate'
            > & {
                lot?: Maybe<
                  { __typename?: 'Lot' } & Pick<
                    Lot,
                    | 'id'
                    | 'name'
                    | 'dataName'
                    | 'salesStatus'
                    | 'premium'
                    | 'address'
                    | 'size'
                    | 'cityName'
                    | 'stateCode'
                    | 'zip'
                    | 'postCode'
                  > & {
                      siteplan?: Maybe<
                        { __typename?: 'Siteplan' } & Pick<
                          Siteplan,
                          'lotMetric'
                        >
                      >
                      siteplanInfo?: Maybe<
                        { __typename?: 'LotSiteplanInfo' } & Pick<
                          LotSiteplanInfo,
                          'x' | 'y'
                        >
                      >
                    }
                >
                scheme?: Maybe<
                  { __typename?: 'Scheme' } & Pick<Scheme, 'id' | 'name'> & {
                      materials: Array<
                        { __typename?: 'SchemeElement' } & Pick<
                          SchemeElement,
                          | 'id'
                          | 'materialId'
                          | 'materialCategory'
                          | 'material'
                          | 'name'
                          | 'hex'
                          | 'swatch'
                          | 'masonryLib'
                        >
                      >
                    }
                >
                plan?: Maybe<
                  { __typename?: 'Plan' } & Pick<
                    Plan,
                    | 'id'
                    | 'clientName'
                    | 'name'
                    | 'displayName'
                    | 'defaultElevationId'
                    | 'bed'
                    | 'bedMin'
                    | 'bedMax'
                    | 'bath'
                    | 'bathMin'
                    | 'bathMax'
                    | 'size'
                    | 'sizeMin'
                    | 'sizeMax'
                    | 'cost'
                    | 'costMin'
                    | 'costMax'
                    | 'description'
                  > & {
                      bedRange?: Maybe<
                        { __typename?: 'IntRange' } & Pick<
                          IntRange,
                          'min' | 'max'
                        >
                      >
                      bathRange?: Maybe<
                        { __typename?: 'FloatRange' } & Pick<
                          FloatRange,
                          'min' | 'max'
                        >
                      >
                      sizeRange?: Maybe<
                        { __typename?: 'IntRange' } & Pick<
                          IntRange,
                          'min' | 'max'
                        >
                      >
                      costRange?: Maybe<
                        { __typename?: 'IntRange' } & Pick<
                          IntRange,
                          'min' | 'max'
                        >
                      >
                      elevations: Array<
                        { __typename?: 'Elevation' } & Pick<
                          Elevation,
                          | 'id'
                          | 'thumb'
                          | 'active'
                          | 'planId'
                          | 'planName'
                          | 'caption'
                        >
                      >
                    }
                >
                community?: Maybe<
                  { __typename?: 'Community' } & Pick<
                    Community,
                    'clientName' | 'id' | 'name'
                  >
                >
              }
          >
        >
        statistics?: Maybe<
          { __typename?: 'ProspectAnalytic' } & Pick<
            ProspectAnalytic,
            | 'user'
            | 'clientName'
            | 'lastSeen'
            | 'firstSeen'
            | 'totalEvents'
            | 'totalDuration'
            | 'averageDuration'
            | 'score'
            | 'hotLead'
            | 'numSessions'
            | 'numBrochuresCreated'
            | 'numBrochureDownloads'
            | 'numPlansShared'
            | 'numContactsUs'
            | 'numSignIns'
            | 'numAppointments'
            | 'country'
            | 'state'
            | 'city'
          > & {
              camberPlanElevation?: Maybe<
                Array<
                  { __typename?: 'camberPlanElevation' } & Pick<
                    CamberPlanElevation,
                    'timestamp' | 'planName' | 'elevationCaption'
                  >
                >
              >
              appointmentRequests?: Maybe<
                Array<
                  { __typename?: 'ProspectRequestAppointment' } & Pick<
                    ProspectRequestAppointment,
                    | 'clientName'
                    | 'timestamp'
                    | 'agentEmails'
                    | 'inventoryId'
                    | 'preferredDates'
                    | 'inventoryAddress'
                    | 'imgUrl'
                    | 'inventoryUrl'
                  > & {
                      inventory?: Maybe<
                        { __typename?: 'Inventory' } & Pick<
                          Inventory,
                          'clientName' | 'id' | 'lotId' | 'mlsUrl'
                        > & {
                            lot?: Maybe<
                              { __typename?: 'Lot' } & Pick<
                                Lot,
                                | 'name'
                                | 'salesStatus'
                                | 'address'
                                | 'cityName'
                                | 'stateCode'
                                | 'countryCode'
                                | 'zip'
                                | 'postCode'
                              >
                            >
                            plan?: Maybe<
                              { __typename?: 'Plan' } & Pick<
                                Plan,
                                'displayName' | 'name'
                              >
                            >
                            elevation?: Maybe<
                              { __typename?: 'Elevation' } & Pick<
                                Elevation,
                                | 'id'
                                | 'clientName'
                                | 'caption'
                                | 'bed'
                                | 'bath'
                                | 'size'
                                | 'cost'
                              >
                            >
                          }
                      >
                    }
                >
              >
              communitiesStatistics?: Maybe<
                Array<
                  { __typename?: 'ProspectTopCommunity' } & Pick<
                    ProspectTopCommunity,
                    | 'clientName'
                    | 'communityName'
                    | 'communityId'
                    | 'timestamp'
                    | 'events'
                    | 'duration'
                  >
                >
              >
              plansStatistics?: Maybe<
                Array<
                  { __typename?: 'ProspectTopPlan' } & Pick<
                    ProspectTopPlan,
                    | 'clientName'
                    | 'communityName'
                    | 'communityId'
                    | 'planName'
                    | 'planId'
                    | 'elevationId'
                    | 'elevationCaption'
                    | 'timestamp'
                    | 'events'
                    | 'duration'
                  > & {
                      plan?: Maybe<
                        { __typename?: 'Plan' } & Pick<
                          Plan,
                          | 'id'
                          | 'clientName'
                          | 'name'
                          | 'displayName'
                          | 'defaultElevationId'
                          | 'bed'
                          | 'bedMin'
                          | 'bedMax'
                          | 'bath'
                          | 'bathMin'
                          | 'bathMax'
                          | 'size'
                          | 'sizeMin'
                          | 'sizeMax'
                          | 'cost'
                          | 'costMin'
                          | 'costMax'
                          | 'description'
                        > & {
                            bedRange?: Maybe<
                              { __typename?: 'IntRange' } & Pick<
                                IntRange,
                                'min' | 'max'
                              >
                            >
                            bathRange?: Maybe<
                              { __typename?: 'FloatRange' } & Pick<
                                FloatRange,
                                'min' | 'max'
                              >
                            >
                            sizeRange?: Maybe<
                              { __typename?: 'IntRange' } & Pick<
                                IntRange,
                                'min' | 'max'
                              >
                            >
                            costRange?: Maybe<
                              { __typename?: 'IntRange' } & Pick<
                                IntRange,
                                'min' | 'max'
                              >
                            >
                            elevations: Array<
                              { __typename?: 'Elevation' } & Pick<
                                Elevation,
                                | 'id'
                                | 'thumb'
                                | 'active'
                                | 'planId'
                                | 'planName'
                                | 'caption'
                              >
                            >
                          }
                      >
                    }
                >
              >
              plansBrowsed?: Maybe<
                Array<
                  { __typename?: 'ProspectTopPlan' } & Pick<
                    ProspectTopPlan,
                    | 'clientName'
                    | 'communityName'
                    | 'communityId'
                    | 'planName'
                    | 'planId'
                    | 'elevationId'
                    | 'elevationCaption'
                    | 'timestamp'
                  >
                >
              >
              communitiesBrowsed?: Maybe<
                Array<
                  { __typename?: 'ProspectTopCommunity' } & Pick<
                    ProspectTopCommunity,
                    | 'clientName'
                    | 'communityName'
                    | 'communityId'
                    | 'events'
                    | 'duration'
                    | 'timestamp'
                  >
                >
              >
              selectedFloorplans?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'ProspectFloorplan' } & Pick<
                      ProspectFloorplan,
                      | 'clientName'
                      | 'floorplanName'
                      | 'floorplanIds'
                      | 'popularity'
                    > & {
                        floorplans?: Maybe<
                          Array<
                            Maybe<
                              { __typename?: 'Floorplan' } & Pick<
                                Floorplan,
                                | 'name'
                                | 'clientName'
                                | 'src'
                                | 'popularity'
                                | 'planName'
                              >
                            >
                          >
                        >
                      }
                  >
                >
              >
              selectedColorSchemes?: Maybe<
                Array<
                  { __typename?: 'ProspectScheme' } & Pick<
                    ProspectScheme,
                    'clientName' | 'schemeId'
                  > & {
                      scheme?: Maybe<
                        { __typename?: 'Scheme' } & Pick<
                          Scheme,
                          'id' | 'clientName' | 'name' | 'internalName'
                        > & {
                            materials: Array<
                              { __typename?: 'SchemeElement' } & Pick<
                                SchemeElement,
                                | 'id'
                                | 'materialId'
                                | 'materialCategory'
                                | 'material'
                                | 'name'
                                | 'hex'
                                | 'swatch'
                                | 'masonryLib'
                              >
                            >
                          }
                      >
                    }
                >
              >
              selectedMaterialColors?: Maybe<
                Array<
                  { __typename?: 'ProspectMaterialColor' } & Pick<
                    ProspectMaterialColor,
                    | 'clientName'
                    | 'materialId'
                    | 'materialName'
                    | 'materialCategory'
                    | 'colorId'
                    | 'colorName'
                    | 'paletteSelectionId'
                    | 'brickId'
                    | 'stoneId'
                    | 'colorMasonryLib'
                    | 'customOverlayId'
                    | 'source'
                  > & {
                      color?: Maybe<
                        { __typename?: 'Color' } & Pick<
                          Color,
                          'id' | 'name' | 'hex'
                        >
                      >
                      texture?: Maybe<
                        { __typename?: 'Texture' } & Pick<
                          Texture,
                          'id' | 'library' | 'type' | 'color' | 'src'
                        >
                      >
                      customOverlay?: Maybe<
                        { __typename?: 'CustomOverlay' } & Pick<
                          CustomOverlay,
                          'id' | 'clientName' | 'color' | 'src'
                        >
                      >
                    }
                >
              >
              ctaClicks?: Maybe<
                Array<
                  { __typename?: 'ProspectCtaEvent' } & Pick<
                    ProspectCtaEvent,
                    | 'communityId'
                    | 'communityName'
                    | 'planId'
                    | 'planName'
                    | 'elevationId'
                    | 'elevationCaption'
                    | 'event'
                    | 'action'
                    | 'timestamp'
                  >
                >
              >
              signIns?: Maybe<
                Array<
                  { __typename?: 'ProspectSignIn' } & Pick<
                    ProspectSignIn,
                    'timestamp' | 'anonymousId' | 'userAction' | 'source'
                  >
                >
              >
              signInsGrouped?: Maybe<
                Array<
                  { __typename?: 'ProspectSignInGrouped' } & Pick<
                    ProspectSignInGrouped,
                    'day' | 'count'
                  >
                >
              >
              favorites?: Maybe<
                Array<
                  { __typename?: 'ProspectFavoriteEvent' } & Pick<
                    ProspectFavoriteEvent,
                    | 'id'
                    | 'clientName'
                    | 'communityName'
                    | 'communityId'
                    | 'planName'
                    | 'planId'
                    | 'elevationId'
                    | 'elevationCaption'
                    | 'event'
                    | 'timestamp'
                  >
                >
              >
              brochuresCreated?: Maybe<
                Array<
                  { __typename?: 'ProspectTopPlan' } & Pick<
                    ProspectTopPlan,
                    | 'clientName'
                    | 'communityName'
                    | 'communityId'
                    | 'planName'
                    | 'planId'
                    | 'elevationId'
                    | 'elevationCaption'
                    | 'timestamp'
                  >
                >
              >
            }
        >
      }
  >
}

export type ProspectWithInteractionsQueryVariables = Exact<{
  clientName: Scalars['String']
  email: Scalars['String']
  start: Scalars['Timestamp']
  end: Scalars['Timestamp']
}>

export type ProspectWithInteractionsQuery = { __typename?: 'Query' } & {
  colonnadeApiProspectByEmail?: Maybe<
    { __typename?: 'Prospect' } & Pick<Prospect, 'id' | 'email'> & {
        interactions?: Maybe<
          Array<
            { __typename?: 'Event' } & Pick<
              Event,
              'id' | 'timestamp' | 'name' | 'contextPagePath'
            >
          >
        >
      }
  >
}

export type ProspectAgentQueryVariables = Exact<{
  clientName: Scalars['String']
  email: Scalars['String']
}>

export type ProspectAgentQuery = { __typename?: 'Query' } & {
  prospectByEmail?: Maybe<
    { __typename?: 'Prospect' } & {
      agents?: Maybe<
        Array<{ __typename?: 'AgentProspect' } & Pick<AgentProspect, 'agentId'>>
      >
    }
  >
}

export type GetProspectActivitiesQueryVariables = Exact<{
  clientName: Scalars['String']
  email: Scalars['String']
}>

export type GetProspectActivitiesQuery = { __typename?: 'Query' } & Pick<
  Query,
  'getProspectActivities'
>

export type GetProspectsQueryVariables = Exact<{
  clientName: Scalars['String']
  communityIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
}>

export type GetProspectsQuery = { __typename?: 'Query' } & {
  colonnadeApiProspect: Array<
    { __typename?: 'Prospect' } & Pick<
      Prospect,
      | 'id'
      | 'email'
      | 'name'
      | 'firstName'
      | 'lastName'
      | 'registrationDate'
      | 'phone'
      | 'picture'
      | 'status'
      | 'preferredContactMethods'
      | 'colonnadeAgents'
    > & {
        agents?: Maybe<
          Array<
            { __typename?: 'AgentProspect' } & Pick<
              AgentProspect,
              | 'agentId'
              | 'prospectId'
              | 'agentName'
              | 'prospectName'
              | 'agentEmail'
              | 'prospectEmail'
              | 'prospectPhone'
            >
          >
        >
      }
  >
}

export type GetDropOfRateQueryVariables = Exact<{
  clientName: Scalars['String']
  filter?: Maybe<ReservationDropOfFilterInput>
}>

export type GetDropOfRateQuery = { __typename?: 'Query' } & {
  getReservationsDropOfRate?: Maybe<
    { __typename?: 'ReservationDropOfSteps' } & Pick<
      ReservationDropOfSteps,
      'termsAndCondition' | 'signedDocument' | 'payment' | 'completed'
    >
  >
}

export type GetReservationStatusOverviewQueryVariables = Exact<{
  clientName: Scalars['String']
  filter?: Maybe<ReservationStatusOverviewFilterInput>
}>

export type GetReservationStatusOverviewQuery = { __typename?: 'Query' } & {
  getReservationStatusOverview: {
    __typename?: 'ReservationStatusOverview'
  } & Pick<
    ReservationStatusOverview,
    | 'inProgress'
    | 'approvedByBuilder'
    | 'rejectedByBuilder'
    | 'rejectedByProspect'
  >
}

export type GetReservationStatusCommunitiesOverviewQueryVariables = Exact<{
  clientName: Scalars['String']
  filter?: Maybe<ReservationStatusOverviewFilterInput>
}>

export type GetReservationStatusCommunitiesOverviewQuery = {
  __typename?: 'Query'
} & {
  getReservationStatusCommunitiesOverview: Array<
    { __typename?: 'ReservationStatusCommunityOverview' } & Pick<
      ReservationStatusCommunityOverview,
      'communityName'
    > & {
        status: { __typename?: 'ReservationStatusOverview' } & Pick<
          ReservationStatusOverview,
          | 'inProgress'
          | 'approvedByBuilder'
          | 'rejectedByBuilder'
          | 'rejectedByProspect'
        >
      }
  >
}

export type GetClientReservationIntegrationQueryVariables = Exact<{
  clientId: Scalars['Int']
}>

export type GetClientReservationIntegrationQuery = { __typename?: 'Query' } & {
  getReservationIntegration?: Maybe<
    { __typename?: 'OnlineReservationIntegration' } & Pick<
      OnlineReservationIntegration,
      'id'
    > & {
        reservationIntegrationInfo?: Maybe<
          { __typename?: 'OnlineReservationIntegrationInfo' } & Pick<
            OnlineReservationIntegrationInfo,
            | 'id'
            | 'stripeAccountId'
            | 'stripeAccountReady'
            | 'depositAmount'
            | 'nonRefundableAmount'
            | 'anewgoFee'
            | 'signingAgreementTemplateId'
            | 'allowMultipleReservations'
            | 'reservationConfig'
            | 'primaryBuyOnlineContactEmail'
            | 'primaryBuyOnlineContactName'
            | 'daysToProveFinancing'
            | 'envelopeExpireDays'
            | 'onboardingComplete'
          > & {
              additionalBuilders: Array<
                { __typename?: 'AdditionalBuilder' } & Pick<
                  AdditionalBuilder,
                  'name' | 'email'
                >
              >
            }
        >
      }
  >
}

export type GetAllProspectReservationsQueryVariables = Exact<{
  clientName: Scalars['String']
  filter?: Maybe<OnlineReservationFilterInput>
}>

export type GetAllProspectReservationsQuery = { __typename?: 'Query' } & {
  getOnlineReservations?: Maybe<
    Array<
      { __typename?: 'ProspectReservation' } & Pick<
        ProspectReservation,
        | 'id'
        | 'primaryBuyerId'
        | 'favoriteId'
        | 'reservationStatus'
        | 'prospectId'
        | 'rejectReason'
        | 'isDeleted'
        | 'createdAt'
        | 'totalPrice'
        | 'errorArray'
        | 'signatureDate'
        | 'paymentDate'
        | 'signTransactionId'
        | 'paymentsTransactionId'
      > & {
          favorite: { __typename?: 'ProspectFavorite' } & Pick<
            ProspectFavorite,
            'id'
          > & {
              elevation?: Maybe<
                { __typename?: 'Elevation' } & Pick<
                  Elevation,
                  'id' | 'planName' | 'caption'
                >
              >
              community?: Maybe<
                { __typename?: 'Community' } & Pick<Community, 'id' | 'name'>
              >
              lot?: Maybe<{ __typename?: 'Lot' } & Pick<Lot, 'id' | 'name'>>
            }
          prospect?: Maybe<
            { __typename?: 'Prospect' } & Pick<
              Prospect,
              'id' | 'email' | 'firstName' | 'lastName' | 'phone' | 'name'
            >
          >
          secondaryBuyerReservations?: Maybe<
            Array<
              { __typename?: 'ProspectReservation' } & Pick<
                ProspectReservation,
                'signatureDate' | 'signTransactionId' | 'prospectId'
              > & {
                  prospect?: Maybe<
                    { __typename?: 'Prospect' } & Pick<
                      Prospect,
                      | 'id'
                      | 'email'
                      | 'name'
                      | 'firstName'
                      | 'lastName'
                      | 'phone'
                    >
                  >
                }
            >
          >
        }
    >
  >
}

export type AddOnlineReservationIntegrationMutationVariables = Exact<{
  clientId: Scalars['Int']
}>

export type AddOnlineReservationIntegrationMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'createReservationIntegration'>

export type RejectReservationMutationVariables = Exact<{
  clientName: Scalars['String']
  reservationId: Scalars['UUID']
  rejectReason?: Maybe<Scalars['String']>
}>

export type RejectReservationMutation = { __typename?: 'Mutation' } & {
  rejectOnlineReservation?: Maybe<
    { __typename?: 'ProspectReservation' } & Pick<
      ProspectReservation,
      'id' | 'reservationStatus'
    >
  >
}

export type UpdateOnlineReservationIntegrationInfoMutationVariables = Exact<{
  clientId: Scalars['Int']
  input: UpdateOnlineReservationIntegrationInput
}>

export type UpdateOnlineReservationIntegrationInfoMutation = {
  __typename?: 'Mutation'
} & {
  updateReservationIntegrationInfo: {
    __typename?: 'OnlineReservationIntegration'
  } & Pick<OnlineReservationIntegration, 'id'> & {
      reservationIntegrationInfo?: Maybe<
        { __typename?: 'OnlineReservationIntegrationInfo' } & Pick<
          OnlineReservationIntegrationInfo,
          | 'id'
          | 'stripeAccountId'
          | 'depositAmount'
          | 'nonRefundableAmount'
          | 'anewgoFee'
          | 'signingAgreementTemplateId'
          | 'allowMultipleReservations'
          | 'reservationConfig'
          | 'primaryBuyOnlineContactEmail'
          | 'primaryBuyOnlineContactName'
          | 'daysToProveFinancing'
          | 'envelopeExpireDays'
        > & {
            additionalBuilders: Array<
              { __typename?: 'AdditionalBuilder' } & Pick<
                AdditionalBuilder,
                'email' | 'name'
              >
            >
          }
      >
    }
}

export type CreateStripeAccountMutationVariables = Exact<{
  clientId: Scalars['Int']
}>

export type CreateStripeAccountMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'createStripeAccount'
>

export type GetDocumentPreviewUrlQueryVariables = Exact<{
  clientId: Scalars['Int']
}>

export type GetDocumentPreviewUrlQuery = { __typename?: 'Query' } & Pick<
  Query,
  'getDocuSignTemplatePreview'
>

export type GetBuyerDocumentLinkQueryVariables = Exact<{
  clientName: Scalars['String']
  reservationId: Scalars['UUID']
}>

export type GetBuyerDocumentLinkQuery = { __typename?: 'Query' } & Pick<
  Query,
  'getDocumentLinkForBuilder'
>

export type UpdateReservationCustomTemplateFieldsMutationVariables = Exact<{
  clientId: Scalars['Int']
  templateId: Scalars['String']
}>

export type UpdateReservationCustomTemplateFieldsMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'updateReservationCustomTemplateFields'>

export type ToggleCommunityByOnlineEnabledMutationVariables = Exact<{
  clientName: Scalars['String']
  communityId: Scalars['Int']
  enabled: Scalars['Boolean']
}>

export type ToggleCommunityByOnlineEnabledMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'toggleCommunityBuyOnlineEnabled'>

export type ToggleByOnlineEnabledMutationVariables = Exact<{
  clientId: Scalars['Int']
  enabled: Scalars['Boolean']
}>

export type ToggleByOnlineEnabledMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'toggleBuyOnlineEnabled'
>

export type ToggleAllowMultipleReservationsMutationVariables = Exact<{
  clientId: Scalars['Int']
  enabled: Scalars['Boolean']
}>

export type ToggleAllowMultipleReservationsMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'toggleAllowMultipleReservations'>

export type AddOrUpdateCommunityBuyOnlineCustomConfigurationTemplateValuesMutationVariables = Exact<{
  clientName: Scalars['String']
  communityId: Scalars['Int']
  configValues: Scalars['JSON']
}>

export type AddOrUpdateCommunityBuyOnlineCustomConfigurationTemplateValuesMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'addOrUpdateCommunityBuyOnlineCustomConfigTemplateValues'>

export type GetCommunityBuyOnlineCustomConfigQueryVariables = Exact<{
  clientName: Scalars['String']
  communityId: Scalars['Int']
}>

export type GetCommunityBuyOnlineCustomConfigQuery = {
  __typename?: 'Query'
} & {
  getCommunityBuyOnlineCustomConfig?: Maybe<
    { __typename?: 'CommunityBuyOnlineConfig' } & Pick<
      CommunityBuyOnlineConfig,
      'communityId' | 'buyOnlineConfig' | 'buyOnlineEnabled'
    >
  >
}

export type DeleteOnlineReservationMutationVariables = Exact<{
  clientName: Scalars['String']
  reservationId: Scalars['UUID']
}>

export type DeleteOnlineReservationMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'deleteOnlineReservation'>

export type HasCommunityInProgressReservationQueryVariables = Exact<{
  clientName: Scalars['String']
  communityId: Scalars['Int']
}>

export type HasCommunityInProgressReservationQuery = {
  __typename?: 'Query'
} & Pick<Query, 'hasCommunityInProgressReservation'>

export type IsLotSignLockedQueryVariables = Exact<{
  clientName: Scalars['String']
  communityId: Scalars['Int']
  lotId: Scalars['Int']
}>

export type IsLotSignLockedQuery = { __typename?: 'Query' } & {
  getIsLotSignLocked?: Maybe<
    { __typename?: 'LotSignLock' } & Pick<LotSignLock, 'isLocked' | 'lockedBy'>
  >
}

export type LockLotSignMutationVariables = Exact<{
  clientName: Scalars['String']
  communityId: Scalars['Int']
  lotId: Scalars['Int']
  prospectEmail: Scalars['String']
}>

export type LockLotSignMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'lockLotSign'
>

export type GetAllLocationsQueryVariables = Exact<{ [key: string]: never }>

export type GetAllLocationsQuery = { __typename?: 'Query' } & {
  getAllLocations: Array<
    Maybe<
      { __typename?: 'Location' } & Pick<
        Location,
        'clientName' | 'countryCode' | 'stateCode' | 'region' | 'city'
      >
    >
  >
}

export type GetCamberElevationTrendsQueryVariables = Exact<{
  filter: CommonQueryFilter
}>

export type GetCamberElevationTrendsQuery = { __typename?: 'Query' } & {
  getCamberElevationTrends: { __typename?: 'CamberElevationTrends' } & Pick<
    CamberElevationTrends,
    'clientName' | 'startTimestamp' | 'endTimestamp'
  > & {
      elevations?: Maybe<
        Array<
          { __typename?: 'CamberElevationTrend' } & Pick<
            CamberElevationTrend,
            'clientName' | 'planId' | 'elevationId'
          > & {
              elevation?: Maybe<
                { __typename?: 'Elevation' } & Pick<
                  Elevation,
                  'id' | 'planId' | 'planName' | 'caption'
                >
              >
              dailyMetrics?: Maybe<
                { __typename?: 'TimeSeries' } & {
                  frames?: Maybe<
                    Array<
                      | ({ __typename?: 'EventBasedTimeSeriesFrame' } & Pick<
                          EventBasedTimeSeriesFrame,
                          'date' | 'value'
                        >)
                      | ({ __typename?: 'SessionBasedTimeSeriesFrame' } & Pick<
                          SessionBasedTimeSeriesFrame,
                          | 'date'
                          | 'durationSum'
                          | 'durationAvg'
                          | 'durationMin'
                          | 'durationMax'
                          | 'durationMedian'
                          | 'eventsCount'
                        >)
                    >
                  >
                }
              >
            }
        >
      >
    }
}

export type GetCamberFloorplanTrendsQueryVariables = Exact<{
  filter: CommonQueryFilter
}>

export type GetCamberFloorplanTrendsQuery = { __typename?: 'Query' } & {
  getCamberFloorplanTrends: { __typename?: 'CamberFloorplanTrends' } & Pick<
    CamberFloorplanTrends,
    'clientName' | 'startTimestamp' | 'endTimestamp'
  > & {
      floorplans?: Maybe<
        Array<
          { __typename?: 'FloorplanTrend' } & Pick<
            FloorplanTrend,
            'clientName' | 'planId' | 'planName' | 'floorplanName'
          > & {
              dailyMetrics?: Maybe<
                { __typename?: 'TimeSeries' } & {
                  frames?: Maybe<
                    Array<
                      | ({ __typename?: 'EventBasedTimeSeriesFrame' } & Pick<
                          EventBasedTimeSeriesFrame,
                          'date' | 'value'
                        >)
                      | ({ __typename?: 'SessionBasedTimeSeriesFrame' } & Pick<
                          SessionBasedTimeSeriesFrame,
                          | 'date'
                          | 'durationSum'
                          | 'durationAvg'
                          | 'durationMin'
                          | 'durationMax'
                          | 'durationMedian'
                          | 'eventsCount'
                        >)
                    >
                  >
                }
              >
            }
        >
      >
    }
}

export type CamberPopularColorSchemesQueryVariables = Exact<{
  filter: CommonQueryFilter
}>

export type CamberPopularColorSchemesQuery = { __typename?: 'Query' } & {
  camberPopularColorSchemes: Array<
    Maybe<
      { __typename?: 'Scheme' } & Pick<
        Scheme,
        'id' | 'name' | 'clientName' | 'count' | 'popularity'
      > & {
          materials: Array<
            { __typename?: 'SchemeElement' } & Pick<
              SchemeElement,
              | 'id'
              | 'materialId'
              | 'materialCategory'
              | 'material'
              | 'name'
              | 'hex'
              | 'swatch'
              | 'masonryLib'
            >
          >
        }
    >
  >
}

export type CamberPopularLotsQueryVariables = Exact<{
  filter: CommonQueryFilter
}>

export type CamberPopularLotsQuery = { __typename?: 'Query' } & {
  camberPopularLots: Array<
    Maybe<
      { __typename?: 'Lot' } & Pick<
        Lot,
        | 'id'
        | 'clientName'
        | 'communityId'
        | 'dataName'
        | 'salesStatus'
        | 'address'
        | 'name'
        | 'count'
        | 'popularity'
      > & {
          siteplan?: Maybe<
            { __typename?: 'Siteplan' } & Pick<
              Siteplan,
              'id' | 'name' | 'src' | 'lotWidth' | 'lotHeight' | 'lotFontSize'
            > & {
                svg?: Maybe<
                  { __typename?: 'SVG' } & {
                    shapes: Array<
                      { __typename?: 'SVGShape' } & Pick<
                        SvgShape,
                        'tagName'
                      > & {
                          attributes: {
                            __typename?: 'SVGShapeAttributes'
                          } & Pick<
                            SvgShapeAttributes,
                            | 'dataName'
                            | 'x'
                            | 'y'
                            | 'width'
                            | 'height'
                            | 'transform'
                            | 'points'
                            | 'd'
                          >
                        }
                    >
                  }
                >
                lotLegend: Array<
                  { __typename?: 'LotLegendEntry' } & Pick<
                    LotLegendEntry,
                    'code' | 'hex'
                  >
                >
              }
          >
          siteplanInfo?: Maybe<
            { __typename?: 'LotSiteplanInfo' } & Pick<
              LotSiteplanInfo,
              'lotId' | 'siteplanId' | 'x' | 'y'
            >
          >
        }
    >
  >
}

export type CamberPopularMaterialColorsQueryVariables = Exact<{
  filter: CommonQueryFilter
}>

export type CamberPopularMaterialColorsQuery = { __typename?: 'Query' } & {
  camberPopularMaterialColors: Array<
    Maybe<
      { __typename?: 'PopularMaterialColors' } & Pick<
        PopularMaterialColors,
        | 'clientName'
        | 'materialId'
        | 'materialName'
        | 'materialCategory'
        | 'colorName'
        | 'colorId'
        | 'brickId'
        | 'stoneId'
        | 'customOverlayId'
        | 'colorMasonryLib'
        | 'source'
        | 'clicks'
        | 'brochure'
        | 'cta'
        | 'count'
        | 'popularity'
      > & {
          color?: Maybe<
            { __typename?: 'Color' } & Pick<Color, 'id' | 'name' | 'hex'>
          >
          texture?: Maybe<
            { __typename?: 'Texture' } & Pick<
              Texture,
              'id' | 'library' | 'type' | 'color' | 'src'
            >
          >
          customOverlay?: Maybe<
            { __typename?: 'CustomOverlay' } & Pick<
              CustomOverlay,
              'id' | 'clientName' | 'color' | 'src'
            >
          >
        }
    >
  >
}

export type CamberUsageSummaryQueryVariables = Exact<{
  filter: CommonQueryFilter
}>

export type CamberUsageSummaryQuery = { __typename?: 'Query' } & {
  getCamberUsageSummary: Array<
    Maybe<
      { __typename?: 'CamberUsageSummary' } & Pick<
        CamberUsageSummary,
        | 'clientName'
        | 'communityId'
        | 'communityName'
        | 'pageViews'
        | 'brochure'
        | 'registrations'
        | 'planClicks'
        | 'eventsCount'
      > & {
          durationInfo?: Maybe<
            { __typename?: 'DurationInfo' } & Pick<
              DurationInfo,
              'avg' | 'median' | 'min' | 'max' | 'sessionCount' | 'total'
            >
          >
          community?: Maybe<
            { __typename?: 'Community' } & Pick<
              Community,
              | 'name'
              | 'id'
              | 'clientName'
              | 'latitude'
              | 'longitude'
              | 'logo'
              | 'active'
            >
          >
        }
    >
  >
}

export type GetCamberUsersLoginsQueryVariables = Exact<{
  filter: CommonQueryFilter
}>

export type GetCamberUsersLoginsQuery = { __typename?: 'Query' } & {
  getCamberUsersLogins: { __typename?: 'CamberUsersLogins' } & Pick<
    CamberUsersLogins,
    'clientName' | 'startTimestamp' | 'endTimestamp'
  > & {
      logins?: Maybe<
        Array<{ __typename?: 'login' } & Pick<Login, 'date' | 'value'>>
      >
    }
}

export type ClientsForUserQueryVariables = Exact<{
  userId: Scalars['Int']
}>

export type ClientsForUserQuery = { __typename?: 'Query' } & {
  clientsForUser?: Maybe<
    { __typename?: 'ClientsForUser' } & Pick<ClientsForUser, 'id'> & {
        clients?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ClientIdAndName' } & Pick<
                ClientIdAndName,
                | 'id'
                | 'altName'
                | 'name'
                | 'email'
                | 'phone'
                | 'directoryName'
                | 'logo'
              >
            >
          >
        >
      }
  >
}

export type GetColorTrendsQueryVariables = Exact<{
  filter: ColorTrendsFilter
}>

export type GetColorTrendsQuery = { __typename?: 'Query' } & {
  getColorTrends: { __typename?: 'ColorTrends' } & Pick<
    ColorTrends,
    'clientName' | 'startTimestamp' | 'endTimestamp'
  > & {
      colors?: Maybe<
        Array<
          { __typename?: 'ColorTrend' } & Pick<
            ColorTrend,
            | 'clientName'
            | 'materialId'
            | 'materialName'
            | 'materialCategory'
            | 'colorName'
            | 'colorId'
            | 'brickId'
            | 'stoneId'
            | 'customOverlayId'
            | 'colorMasonryLib'
            | 'clicks'
            | 'brochure'
            | 'cta'
          > & {
              color?: Maybe<
                { __typename?: 'Color' } & Pick<Color, 'id' | 'name' | 'hex'>
              >
              texture?: Maybe<
                { __typename?: 'Texture' } & Pick<
                  Texture,
                  'id' | 'library' | 'type' | 'color' | 'src'
                >
              >
              customOverlay?: Maybe<
                { __typename?: 'CustomOverlay' } & Pick<
                  CustomOverlay,
                  'id' | 'clientName' | 'color' | 'src'
                >
              >
              dailyMetrics?: Maybe<
                { __typename?: 'TimeSeries' } & {
                  frames?: Maybe<
                    Array<
                      | ({ __typename?: 'EventBasedTimeSeriesFrame' } & Pick<
                          EventBasedTimeSeriesFrame,
                          'date' | 'value'
                        >)
                      | ({ __typename?: 'SessionBasedTimeSeriesFrame' } & Pick<
                          SessionBasedTimeSeriesFrame,
                          | 'date'
                          | 'durationSum'
                          | 'durationAvg'
                          | 'durationMin'
                          | 'durationMax'
                          | 'durationMedian'
                          | 'eventsCount'
                        >)
                    >
                  >
                }
              >
            }
        >
      >
    }
}

export type GetCommunitiesTrendsQueryVariables = Exact<{
  filter: CommunitiesTrendsFilter
}>

export type GetCommunitiesTrendsQuery = { __typename?: 'Query' } & {
  getCommunitiesTrends: { __typename?: 'CommunitiesTrends' } & Pick<
    CommunitiesTrends,
    'clientName' | 'startTimestamp' | 'endTimestamp'
  > & {
      communities?: Maybe<
        Array<
          { __typename?: 'CommunityTrend' } & Pick<
            CommunityTrend,
            'clientName' | 'communityId' | 'communityName'
          > & {
              community?: Maybe<
                { __typename?: 'Community' } & Pick<Community, 'id'>
              >
              dailyMetrics?: Maybe<
                { __typename?: 'TimeSeries' } & {
                  frames?: Maybe<
                    Array<
                      | ({ __typename?: 'EventBasedTimeSeriesFrame' } & Pick<
                          EventBasedTimeSeriesFrame,
                          'date' | 'value'
                        >)
                      | ({ __typename?: 'SessionBasedTimeSeriesFrame' } & Pick<
                          SessionBasedTimeSeriesFrame,
                          | 'date'
                          | 'durationSum'
                          | 'durationAvg'
                          | 'durationMin'
                          | 'durationMax'
                          | 'durationMedian'
                          | 'eventsCount'
                          | 'sessionsCount'
                        >)
                    >
                  >
                }
              >
            }
        >
      >
    }
}

export type ClientCommunityListQueryVariables = Exact<{
  clientName: Scalars['String']
}>

export type ClientCommunityListQuery = { __typename?: 'Query' } & {
  communities: Array<{ __typename?: 'Community' } & Pick<Community, 'name'>>
}

export type GetElevationTrendsQueryVariables = Exact<{
  filter: CommonQueryFilter
}>

export type GetElevationTrendsQuery = { __typename?: 'Query' } & {
  getElevationTrends: { __typename?: 'ElevationTrends' } & Pick<
    ElevationTrends,
    'clientName' | 'startTimestamp' | 'endTimestamp'
  > & {
      elevations?: Maybe<
        Array<
          { __typename?: 'ElevationTrend' } & Pick<
            ElevationTrend,
            | 'clientName'
            | 'planId'
            | 'planName'
            | 'elevationId'
            | 'elevationCaption'
          > & {
              elevation?: Maybe<
                { __typename?: 'Elevation' } & Pick<
                  Elevation,
                  'id' | 'planId' | 'planName' | 'caption'
                >
              >
              dailyMetrics?: Maybe<
                { __typename?: 'TimeSeries' } & {
                  frames?: Maybe<
                    Array<
                      | ({ __typename?: 'EventBasedTimeSeriesFrame' } & Pick<
                          EventBasedTimeSeriesFrame,
                          'date' | 'value'
                        >)
                      | ({ __typename?: 'SessionBasedTimeSeriesFrame' } & Pick<
                          SessionBasedTimeSeriesFrame,
                          | 'date'
                          | 'durationSum'
                          | 'durationAvg'
                          | 'durationMin'
                          | 'durationMax'
                          | 'durationMedian'
                          | 'eventsCount'
                        >)
                    >
                  >
                }
              >
            }
        >
      >
    }
}

export type EngagementRateQueryVariables = Exact<{
  filter: SessionFilter
}>

export type EngagementRateQuery = { __typename?: 'Query' } & {
  engagementRate: { __typename?: 'Engagement' } & Pick<
    Engagement,
    'community' | 'plan' | 'elevation' | 'brochure'
  >
}

export type GetEnvelopesCreatedQueryVariables = Exact<{
  filter: CommonQueryFilter
}>

export type GetEnvelopesCreatedQuery = { __typename?: 'Query' } & {
  getEnvelopesCreated?: Maybe<
    { __typename?: 'EnvelopesCreated' } & Pick<EnvelopesCreated, 'count'>
  >
}

export type ExcludedIpsQueryVariables = Exact<{
  clientName: Scalars['String']
}>

export type ExcludedIpsQuery = { __typename?: 'Query' } & {
  excludedIps: Array<
    Maybe<
      { __typename?: 'ipAddress' } & Pick<IpAddress, 'ip' | 'ipCidrNotation'>
    >
  >
}

export type GetFavoritesQueryVariables = Exact<{
  filter: LeadsQueryFilter
}>

export type GetFavoritesQuery = { __typename?: 'Query' } & {
  getFavorites: { __typename?: 'Favorites' } & Pick<Favorites, 'clientName'> & {
      favorites?: Maybe<
        Array<
          { __typename?: 'Favorite' } & Pick<
            Favorite,
            | 'date'
            | 'communityName'
            | 'planName'
            | 'elevationCaption'
            | 'country'
            | 'state'
            | 'city'
            | 'user'
          > & {
              prospect?: Maybe<
                { __typename?: 'Prospect' } & Pick<
                  Prospect,
                  'id' | 'email' | 'name' | 'firstName' | 'lastName'
                >
              >
            }
        >
      >
    }
}

export type GetFloorplanTrendsQueryVariables = Exact<{
  filter: CommonQueryFilter
}>

export type GetFloorplanTrendsQuery = { __typename?: 'Query' } & {
  getFloorplanTrends: { __typename?: 'FloorplanTrends' } & Pick<
    FloorplanTrends,
    'clientName' | 'startTimestamp' | 'endTimestamp'
  > & {
      floorplans?: Maybe<
        Array<
          { __typename?: 'FloorplanTrend' } & Pick<
            FloorplanTrend,
            'clientName' | 'planId' | 'planName' | 'floorplanName'
          > & {
              dailyMetrics?: Maybe<
                { __typename?: 'TimeSeries' } & {
                  frames?: Maybe<
                    Array<
                      | ({ __typename?: 'EventBasedTimeSeriesFrame' } & Pick<
                          EventBasedTimeSeriesFrame,
                          'date' | 'value'
                        >)
                      | ({ __typename?: 'SessionBasedTimeSeriesFrame' } & Pick<
                          SessionBasedTimeSeriesFrame,
                          | 'date'
                          | 'durationSum'
                          | 'durationAvg'
                          | 'durationMin'
                          | 'durationMax'
                          | 'durationMedian'
                          | 'eventsCount'
                        >)
                    >
                  >
                }
              >
            }
        >
      >
    }
}

export type GetCommunitiesByLocationQueryVariables = Exact<{
  filter: CommonQueryFilter
}>

export type GetCommunitiesByLocationQuery = { __typename?: 'Query' } & {
  getCommunitiesByLocation: Array<
    { __typename?: 'CommunityByLocation' } & Pick<
      CommunityByLocation,
      'communityId' | 'count'
    >
  >
}

export type GetVisitorLocationsQueryVariables = Exact<{
  filter: CommonQueryFilter
}>

export type GetVisitorLocationsQuery = { __typename?: 'Query' } & {
  getVisitorLocations: Array<
    { __typename?: 'VisitorLocation' } & Pick<
      VisitorLocation,
      'latitude' | 'longitude'
    >
  >
}

export type IndustrySessionTrendsQueryVariables = Exact<{
  filter: CommonQueryFilter
}>

export type IndustrySessionTrendsQuery = { __typename?: 'Query' } & {
  industrySessionTrends: Array<
    { __typename?: 'IndustrySessionTrends' } & Pick<
      IndustrySessionTrends,
      | 'date'
      | 'sessions'
      | 'visitors'
      | 'registered'
      | 'returnVisitors'
      | 'newVisitors'
      | 'averageDuration'
      | 'bounceRate'
      | 'industrySessions'
      | 'industryVisitors'
      | 'industryRegistered'
      | 'industryReturnVisitors'
      | 'industryNewVisitors'
      | 'industryAverageDuration'
      | 'industryBounceRate'
    >
  >
}

export type IndustrySessionSummaryQueryVariables = Exact<{
  filter: CommonQueryFilter
}>

export type IndustrySessionSummaryQuery = { __typename?: 'Query' } & {
  industrySessionSummary: { __typename?: 'IndustrySessionSummary' } & Pick<
    IndustrySessionSummary,
    | 'sessions'
    | 'visitors'
    | 'registered'
    | 'returnVisitors'
    | 'newVisitors'
    | 'averageDuration'
    | 'bounceRate'
  >
}

export type PopularFloorPlanOptionsQueryVariables = Exact<{
  filter: CommonQueryFilter
}>

export type PopularFloorPlanOptionsQuery = { __typename?: 'Query' } & {
  popularFloorPlanOptions: Array<
    { __typename?: 'PopularFloorPlanOptions' } & Pick<
      PopularFloorPlanOptions,
      'floorPlanOptionName' | 'count' | 'popularity'
    >
  >
}

export type LeadRankingsQueryVariables = Exact<{
  filter: LeadsQueryFilter
  pagination?: Maybe<Pagination>
  orderBy?: Maybe<OrderBy>
}>

export type LeadRankingsQuery = { __typename?: 'Query' } & {
  leadRankings: { __typename?: 'PaginatedLeadRanking' } & Pick<
    PaginatedLeadRanking,
    'totalCount'
  > & {
      leads: Array<
        { __typename?: 'LeadRanking' } & Pick<
          LeadRanking,
          | 'user'
          | 'clientName'
          | 'lastSeen'
          | 'firstSeen'
          | 'date'
          | 'sessions'
          | 'totalEvents'
          | 'totalDuration'
          | 'averageDuration'
          | 'brochuresCreated'
          | 'ctaClicks'
          | 'favorites'
          | 'signIns'
          | 'appointments'
          | 'contactsUs'
          | 'score'
          | 'hotLead'
          | 'country'
          | 'state'
          | 'city'
          | 'numCommunities'
          | 'numPlans'
          | 'prospectId'
          | 'phone'
          | 'status'
          | 'registrationDate'
          | 'name'
          | 'email'
          | 'clientRating'
          | 'favoriteCommunities'
        > & {
            plans?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'ProspectTopPlan' } & Pick<
                    ProspectTopPlan,
                    | 'clientName'
                    | 'communityName'
                    | 'communityId'
                    | 'planName'
                    | 'planId'
                    | 'elevationId'
                    | 'elevationCaption'
                    | 'timestamp'
                  >
                >
              >
            >
            communities?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'ProspectTopCommunity' } & Pick<
                    ProspectTopCommunity,
                    | 'clientName'
                    | 'communityName'
                    | 'communityId'
                    | 'events'
                    | 'duration'
                    | 'timestamp'
                  >
                >
              >
            >
          }
      >
    }
}

export type LeadTimeRankingQueryVariables = Exact<{
  clientName: Scalars['String']
  leadEmail: Scalars['String']
  timeFrame: Scalars['String']
}>

export type LeadTimeRankingQuery = { __typename?: 'Query' } & {
  leadTimeRanking: Array<
    { __typename?: 'leadTimeRanking' } & Pick<
      LeadTimeRanking,
      'date' | 'score' | 'overallScore'
    >
  >
}

export type LeadsByElevationQueryVariables = Exact<{
  filter: LeadsQueryFilter
}>

export type LeadsByElevationQuery = { __typename?: 'Query' } & {
  leadsByElevation: Array<
    { __typename?: 'LeadByElevation' } & Pick<
      LeadByElevation,
      'clientName' | 'user' | 'lastActivity' | 'numBrowsed' | 'numFavorited'
    > & {
        events?: Maybe<
          Array<
            Maybe<
              { __typename?: 'LeadElevationInterestEvent' } & Pick<
                LeadElevationInterestEvent,
                'action' | 'timestamp'
              >
            >
          >
        >
        prospect?: Maybe<
          { __typename?: 'Prospect' } & Pick<
            Prospect,
            | 'id'
            | 'email'
            | 'name'
            | 'firstName'
            | 'lastName'
            | 'phone'
            | 'picture'
            | 'preferredContactMethods'
            | 'registrationDate'
            | 'status'
            | 'clientRating'
          >
        >
      }
  >
}

export type LeadsByProductQueryVariables = Exact<{
  filter: LeadsByProductQueryFilter
  clientName: Scalars['String']
}>

export type LeadsByProductQuery = { __typename?: 'Query' } & {
  leadsByProduct: Array<
    { __typename?: 'LeadByProduct' } & Pick<
      LeadByProduct,
      | 'clientName'
      | 'user'
      | 'lastSeen'
      | 'score'
      | 'avgDistance'
      | 'minDistance'
      | 'maxDistance'
      | 'sessions'
      | 'duration'
      | 'myHomeFrequency'
      | 'communities'
      | 'plans'
    > & {
        prospect?: Maybe<
          { __typename?: 'Prospect' } & Pick<
            Prospect,
            | 'id'
            | 'name'
            | 'firstName'
            | 'lastName'
            | 'email'
            | 'status'
            | 'receiveCampaignEmails'
          >
        >
      }
  >
}

export type GetLotClicksQueryVariables = Exact<{
  filter: CommonQueryFilter
  siteplanId: Scalars['Int']
  lotId: Scalars['Int']
}>

export type GetLotClicksQuery = { __typename?: 'Query' } & {
  getLotClicks?: Maybe<
    Array<{ __typename?: 'LotClicks' } & Pick<LotClicks, 'day' | 'count'>>
  >
}

export type GetLotLeadsQueryVariables = Exact<{
  filter: CommonQueryFilter
  siteplanId: Scalars['Int']
  lotId: Scalars['Int']
}>

export type GetLotLeadsQuery = { __typename?: 'Query' } & {
  getLotLeads?: Maybe<
    Array<
      { __typename?: 'LotLead' } & Pick<LotLead, 'clientName' | 'date'> & {
          prospect?: Maybe<
            { __typename?: 'Prospect' } & Pick<
              Prospect,
              | 'id'
              | 'email'
              | 'name'
              | 'firstName'
              | 'lastName'
              | 'phone'
              | 'picture'
              | 'preferredContactMethods'
              | 'registrationDate'
              | 'status'
              | 'clientRating'
            >
          >
        }
    >
  >
}

export type GetMfRecommendedPlansQueryVariables = Exact<{
  clientName: Scalars['String']
  filter: Array<Maybe<MfRecommendedPlansInput>> | Maybe<MfRecommendedPlansInput>
  plansToBeExcluded: Array<Scalars['Int']> | Scalars['Int']
  topK: Scalars['Int']
  leadEmail: Scalars['String']
}>

export type GetMfRecommendedPlansQuery = { __typename?: 'Query' } & {
  getMfRecommendedPlans: Array<
    { __typename?: 'MFRecommendedPlans' } & Pick<
      MfRecommendedPlans,
      | 'planId'
      | 'planName'
      | 'communityId'
      | 'communityName'
      | 'elevationId'
      | 'elevationCaption'
      | 'thumb'
      | 'bed'
      | 'bath'
      | 'size'
    >
  >
}

export type PageReferralsQueryVariables = Exact<{
  filter: CommonQueryFilter
}>

export type PageReferralsQuery = { __typename?: 'Query' } & {
  pageReferrals: Array<
    Maybe<
      { __typename?: 'PageReferrals' } & Pick<
        PageReferrals,
        | 'clientName'
        | 'sessions'
        | 'numBounces'
        | 'bounceRate'
        | 'sumDuration'
        | 'avgDuration'
        | 'sumEvents'
        | 'avgEvents'
        | 'referrer'
        | 'registers'
        | 'visitors'
        | 'users'
      >
    >
  >
}

export type PaginatedInventoryLotsQueryVariables = Exact<{
  clientName: Scalars['String']
  communityIds: Array<Scalars['Int']> | Scalars['Int']
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy: Scalars['String']
  filter?: Maybe<SpecFilter>
}>

export type PaginatedInventoryLotsQuery = { __typename?: 'Query' } & {
  paginatedInventoryLots?: Maybe<
    { __typename?: 'PaginatedLots' } & {
      cursor: { __typename?: 'PaginateCursor' } & Pick<PaginateCursor, 'offset'>
      lots: Array<
        { __typename?: 'Lot' } & Pick<
          Lot,
          | 'id'
          | 'clientName'
          | 'communityId'
          | 'name'
          | 'dataName'
          | 'salesStatus'
          | 'externalId'
          | 'address'
          | 'premium'
          | 'size'
          | 'plan'
          | 'garagePosition'
          | 'latitude'
          | 'longitude'
          | 'metroName'
          | 'cityName'
          | 'stateCode'
          | 'zip'
          | 'postCode'
          | 'countryCode'
        > & {
            inventory?: Maybe<
              { __typename?: 'Inventory' } & Pick<
                Inventory,
                | 'clientName'
                | 'id'
                | 'lotId'
                | 'planId'
                | 'elevationId'
                | 'communityId'
                | 'mlsId'
                | 'price'
                | 'sqft'
                | 'beds'
                | 'baths'
                | 'features'
                | 'homeType'
                | 'constructionStatus'
              > & {
                  plan?: Maybe<
                    { __typename?: 'Plan' } & Pick<
                      Plan,
                      'id' | 'clientName' | 'name' | 'displayName'
                    >
                  >
                  elevation?: Maybe<
                    { __typename?: 'Elevation' } & Pick<
                      Elevation,
                      'id' | 'planId' | 'clientName' | 'caption'
                    >
                  >
                }
            >
          }
      >
    }
  >
}

export type PaginatedPlansQueryVariables = Exact<{
  clientName: Scalars['String']
  communityIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy: Scalars['String']
  filter?: Maybe<SpecFilter>
  commonFilter: CommonQueryFilter
  selectedMetrics?: Maybe<Array<Scalars['String']> | Scalars['String']>
  sortOrder: Scalars['String']
}>

export type PaginatedPlansQuery = { __typename?: 'Query' } & {
  paginatedPlans?: Maybe<
    { __typename?: 'PaginatedPlans' } & {
      cursor: { __typename?: 'PaginateCursor' } & Pick<PaginateCursor, 'offset'>
      plans: Array<
        { __typename?: 'Plan' } & Pick<
          Plan,
          | 'id'
          | 'clientName'
          | 'communityId'
          | 'name'
          | 'displayName'
          | 'defaultElevationId'
          | 'bed'
          | 'bedMin'
          | 'bedMax'
          | 'bath'
          | 'bathMin'
          | 'bathMax'
          | 'size'
          | 'sizeMin'
          | 'sizeMax'
          | 'cost'
          | 'costMin'
          | 'costMax'
          | 'description'
        > & {
            bedRange?: Maybe<
              { __typename?: 'IntRange' } & Pick<IntRange, 'min' | 'max'>
            >
            bathRange?: Maybe<
              { __typename?: 'FloatRange' } & Pick<FloatRange, 'min' | 'max'>
            >
            sizeRange?: Maybe<
              { __typename?: 'IntRange' } & Pick<IntRange, 'min' | 'max'>
            >
            costRange?: Maybe<
              { __typename?: 'IntRange' } & Pick<IntRange, 'min' | 'max'>
            >
            elevations: Array<
              { __typename?: 'Elevation' } & Pick<
                Elevation,
                'id' | 'thumb' | 'active' | 'caption' | 'planId'
              > & {
                  communities: Array<
                    { __typename?: 'Community' } & Pick<
                      Community,
                      'id' | 'thumb' | 'name' | 'active'
                    >
                  >
                }
            >
            popularityMetrics: { __typename?: 'PlanPopularityMetrics' } & Pick<
              PlanPopularityMetrics,
              'byFrequency'
            > & {
                byPopularity?: Maybe<
                  { __typename?: 'Popularity' } & Pick<
                    Popularity,
                    'clicks' | 'popularity' | 'brochure' | 'cta'
                  >
                >
                byDuration?: Maybe<
                  { __typename?: 'DurationInfo' } & Pick<
                    DurationInfo,
                    'sessionCount' | 'total' | 'avg' | 'min' | 'max' | 'median'
                  >
                >
                byBrowsingData?: Maybe<
                  { __typename?: 'Browsing' } & Pick<
                    Browsing,
                    'favorites' | 'leadFavorites' | 'leadViews'
                  >
                >
              }
          }
      >
    }
  >
}

export type PopularColorSchemesQueryVariables = Exact<{
  filter: CommonQueryFilter
}>

export type PopularColorSchemesQuery = { __typename?: 'Query' } & {
  popularColorSchemes: Array<
    Maybe<
      { __typename?: 'Scheme' } & Pick<
        Scheme,
        'id' | 'name' | 'clientName' | 'count' | 'popularity'
      > & {
          materials: Array<
            { __typename?: 'SchemeElement' } & Pick<
              SchemeElement,
              | 'id'
              | 'materialId'
              | 'materialCategory'
              | 'material'
              | 'name'
              | 'hex'
              | 'swatch'
              | 'masonryLib'
            >
          >
        }
    >
  >
}

export type PopularCommunitiesQueryVariables = Exact<{
  filter: CommonQueryFilter
  clientName?: Maybe<Scalars['String']>
}>

export type PopularCommunitiesQuery = { __typename?: 'Query' } & {
  popularCommunities: Array<
    Maybe<
      | ({ __typename?: 'PopularCommunityByCount' } & Pick<
          PopularCommunityByCount,
          'count' | 'clientName' | 'communityId' | 'communityName'
        > & {
            community?: Maybe<
              { __typename?: 'Community' } & Pick<
                Community,
                | 'name'
                | 'id'
                | 'clientName'
                | 'latitude'
                | 'longitude'
                | 'logo'
                | 'active'
              > & {
                  cityLocation?: Maybe<
                    { __typename?: 'CityInfo' } & CityFieldsFullFragment
                  >
                }
            >
          })
      | ({ __typename?: 'PopularCommunityByDuration' } & Pick<
          PopularCommunityByDuration,
          'clientName' | 'communityId' | 'communityName'
        > & {
            durationInfo?: Maybe<
              { __typename?: 'DurationInfo' } & Pick<
                DurationInfo,
                'avg' | 'median' | 'min' | 'max' | 'sessionCount' | 'total'
              >
            >
            community?: Maybe<
              { __typename?: 'Community' } & Pick<
                Community,
                | 'name'
                | 'id'
                | 'clientName'
                | 'latitude'
                | 'longitude'
                | 'logo'
                | 'active'
              > & {
                  cityLocation?: Maybe<
                    { __typename?: 'CityInfo' } & CityFieldsFullFragment
                  >
                }
            >
          })
      | ({ __typename?: 'PopularCommunityByFavoritesCount' } & Pick<
          PopularCommunityByFavoritesCount,
          'favoritesCount' | 'clientName' | 'communityId' | 'communityName'
        > & {
            community?: Maybe<
              { __typename?: 'Community' } & Pick<
                Community,
                | 'name'
                | 'id'
                | 'clientName'
                | 'latitude'
                | 'longitude'
                | 'logo'
                | 'active'
              > & {
                  cityLocation?: Maybe<
                    { __typename?: 'CityInfo' } & CityFieldsFullFragment
                  >
                }
            >
          })
      | ({ __typename?: 'PopularCommunityByPopularity' } & Pick<
          PopularCommunityByPopularity,
          'clientName' | 'communityId' | 'communityName'
        > & {
            community?: Maybe<
              { __typename?: 'Community' } & Pick<
                Community,
                | 'name'
                | 'id'
                | 'clientName'
                | 'latitude'
                | 'longitude'
                | 'logo'
                | 'active'
              > & {
                  cityLocation?: Maybe<
                    { __typename?: 'CityInfo' } & CityFieldsFullFragment
                  >
                }
            >
          })
    >
  >
}

export type PopularElevationSpecsQueryVariables = Exact<{
  filter: CommonQueryFilter
}>

export type PopularElevationSpecsQuery = { __typename?: 'Query' } & {
  popularElevationSpecs: { __typename?: 'PopularElevationSpec' } & {
    bed: Array<
      { __typename?: 'Specification' } & Pick<Specification, 'value' | 'count'>
    >
    bath: Array<
      { __typename?: 'Specification' } & Pick<Specification, 'value' | 'count'>
    >
    size: Array<
      { __typename?: 'Specification' } & Pick<Specification, 'value' | 'count'>
    >
    price: Array<
      { __typename?: 'Specification' } & Pick<Specification, 'value' | 'count'>
    >
  }
}

export type PopularElevationsQueryVariables = Exact<{
  filter: CommonQueryFilter
}>

export type PopularElevationsQuery = { __typename?: 'Query' } & {
  popularElevations: Array<
    Maybe<
      | ({ __typename?: 'PopularElevationByCount' } & Pick<
          PopularElevationByCount,
          | 'count'
          | 'clientName'
          | 'planId'
          | 'planName'
          | 'elevationId'
          | 'elevationCaption'
        > & {
            plan?: Maybe<{ __typename?: 'Plan' } & Pick<Plan, 'id' | 'name'>>
            elevation?: Maybe<
              { __typename?: 'Elevation' } & Pick<
                Elevation,
                | 'id'
                | 'caption'
                | 'thumb'
                | 'bathMin'
                | 'bathMax'
                | 'bedMin'
                | 'bedMax'
                | 'costMin'
                | 'costMax'
                | 'sizeMin'
                | 'sizeMax'
              >
            >
          })
      | ({ __typename?: 'PopularElevationByDuration' } & Pick<
          PopularElevationByDuration,
          | 'clientName'
          | 'planId'
          | 'planName'
          | 'elevationId'
          | 'elevationCaption'
        > & {
            durationInfo?: Maybe<
              { __typename?: 'DurationInfo' } & Pick<
                DurationInfo,
                'avg' | 'median' | 'min' | 'max' | 'sessionCount' | 'total'
              >
            >
            plan?: Maybe<{ __typename?: 'Plan' } & Pick<Plan, 'id' | 'name'>>
            elevation?: Maybe<
              { __typename?: 'Elevation' } & Pick<
                Elevation,
                | 'id'
                | 'caption'
                | 'thumb'
                | 'bathMin'
                | 'bathMax'
                | 'bedMin'
                | 'bedMax'
                | 'costMin'
                | 'costMax'
                | 'sizeMin'
                | 'sizeMax'
              >
            >
          })
      | ({ __typename?: 'PopularElevationByPopularity' } & Pick<
          PopularElevationByPopularity,
          | 'clientName'
          | 'planId'
          | 'planName'
          | 'elevationId'
          | 'elevationCaption'
        > & {
            plan?: Maybe<{ __typename?: 'Plan' } & Pick<Plan, 'id' | 'name'>>
            elevation?: Maybe<
              { __typename?: 'Elevation' } & Pick<
                Elevation,
                | 'id'
                | 'caption'
                | 'thumb'
                | 'bathMin'
                | 'bathMax'
                | 'bedMin'
                | 'bedMax'
                | 'costMin'
                | 'costMax'
                | 'sizeMin'
                | 'sizeMax'
              >
            >
          })
    >
  >
}

export type PopularFloorplansQueryVariables = Exact<{
  filter: CommonQueryFilter
  clientName: Scalars['String']
}>

export type PopularFloorplansQuery = { __typename?: 'Query' } & {
  popularFloorplans: Array<
    Maybe<
      { __typename?: 'PopularFloorplans' } & Pick<
        PopularFloorplans,
        | 'clientName'
        | 'communityName'
        | 'floorplanIds'
        | 'popularity'
        | 'clicks'
        | 'brochure'
        | 'cta'
      > & {
          floorplans?: Maybe<
            Array<
              Maybe<
                { __typename?: 'Floorplan' } & Pick<
                  Floorplan,
                  | 'name'
                  | 'clientName'
                  | 'src'
                  | 'popularity'
                  | 'planName'
                  | 'elevationCaption'
                > & {
                    plans: Array<
                      { __typename?: 'Plan' } & Pick<Plan, 'id' | 'name'>
                    >
                  }
              >
            >
          >
        }
    >
  >
}

export type PopularIndustryElevationSpecsQueryVariables = Exact<{
  filter: CommonQueryFilter
}>

export type PopularIndustryElevationSpecsQuery = { __typename?: 'Query' } & {
  popularIndustryElevationSpecs: { __typename?: 'PopularElevationSpec' } & {
    bed: Array<
      { __typename?: 'Specification' } & Pick<Specification, 'value' | 'count'>
    >
    bath: Array<
      { __typename?: 'Specification' } & Pick<Specification, 'value' | 'count'>
    >
    size: Array<
      { __typename?: 'Specification' } & Pick<Specification, 'value' | 'count'>
    >
    price: Array<
      { __typename?: 'Specification' } & Pick<Specification, 'value' | 'count'>
    >
  }
}

export type PopularInteriorSelectionsQueryVariables = Exact<{
  clientName: Scalars['String']
  filter: CommonQueryFilter
}>

export type PopularInteriorSelectionsQuery = { __typename?: 'Query' } & {
  popularInteriorSelections: Array<
    Maybe<
      { __typename?: 'PopularInteriorSelections' } & Pick<
        PopularInteriorSelections,
        | 'clientName'
        | 'interiorId'
        | 'elementId'
        | 'listElementId'
        | 'optionId'
        | 'clicks'
        | 'brochure'
        | 'cta'
        | 'events'
        | 'popularity'
      > & {
          interior?: Maybe<
            { __typename?: 'Interior' } & Pick<
              Interior,
              | 'id'
              | 'clientName'
              | 'name'
              | 'internalName'
              | 'baseImageSrc'
              | 'thumbnailSrc'
              | 'active'
            > & {
                elementTrees: Array<
                  { __typename?: 'InteriorListElementTree' } & Pick<
                    InteriorListElementTree,
                    'id' | 'name' | 'description' | 'cost' | 'overlaySwatchSrc'
                  > & {
                      options?: Maybe<
                        Array<
                          { __typename?: 'InteriorElementOption' } & Pick<
                            InteriorElementOption,
                            | 'id'
                            | 'name'
                            | 'description'
                            | 'cost'
                            | 'hex'
                            | 'interiorListElementId'
                            | 'overlaySrc'
                            | 'overlaySwatchSrc'
                            | 'listIndex'
                          >
                        >
                      >
                    }
                >
              }
          >
        }
    >
  >
}

export type PopularLotsQueryVariables = Exact<{
  filter: CommonQueryFilter
}>

export type PopularLotsQuery = { __typename?: 'Query' } & {
  popularLots: Array<
    Maybe<
      { __typename?: 'Lot' } & Pick<
        Lot,
        | 'id'
        | 'clientName'
        | 'communityId'
        | 'dataName'
        | 'salesStatus'
        | 'address'
        | 'name'
        | 'count'
        | 'popularity'
      > & {
          siteplan?: Maybe<
            { __typename?: 'Siteplan' } & Pick<
              Siteplan,
              'id' | 'name' | 'src' | 'lotWidth' | 'lotHeight' | 'lotFontSize'
            > & {
                svg?: Maybe<
                  { __typename?: 'SVG' } & {
                    shapes: Array<
                      { __typename?: 'SVGShape' } & Pick<
                        SvgShape,
                        'tagName'
                      > & {
                          attributes: {
                            __typename?: 'SVGShapeAttributes'
                          } & Pick<
                            SvgShapeAttributes,
                            | 'dataName'
                            | 'x'
                            | 'y'
                            | 'width'
                            | 'height'
                            | 'transform'
                            | 'points'
                            | 'd'
                          >
                        }
                    >
                  }
                >
                lotLegend: Array<
                  { __typename?: 'LotLegendEntry' } & Pick<
                    LotLegendEntry,
                    'code' | 'hex'
                  >
                >
              }
          >
          siteplanInfo?: Maybe<
            { __typename?: 'LotSiteplanInfo' } & Pick<
              LotSiteplanInfo,
              'lotId' | 'siteplanId' | 'x' | 'y'
            >
          >
        }
    >
  >
}

export type PopularMaterialColorsQueryVariables = Exact<{
  filter: CommonQueryFilter
}>

export type PopularMaterialColorsQuery = { __typename?: 'Query' } & {
  popularMaterialColors: Array<
    Maybe<
      { __typename?: 'PopularMaterialColors' } & Pick<
        PopularMaterialColors,
        | 'clientName'
        | 'materialId'
        | 'materialName'
        | 'materialCategory'
        | 'colorName'
        | 'colorId'
        | 'brickId'
        | 'stoneId'
        | 'customOverlayId'
        | 'colorMasonryLib'
        | 'source'
        | 'clicks'
        | 'brochure'
        | 'cta'
        | 'count'
        | 'popularity'
      > & {
          color?: Maybe<
            { __typename?: 'Color' } & Pick<Color, 'id' | 'name' | 'hex'>
          >
          texture?: Maybe<
            { __typename?: 'Texture' } & Pick<
              Texture,
              'id' | 'library' | 'type' | 'color' | 'src'
            >
          >
          customOverlay?: Maybe<
            { __typename?: 'CustomOverlay' } & Pick<
              CustomOverlay,
              'id' | 'clientName' | 'color' | 'src'
            >
          >
        }
    >
  >
}

export type GetSchemeTrendsQueryVariables = Exact<{
  filter: CommonQueryFilter
}>

export type GetSchemeTrendsQuery = { __typename?: 'Query' } & {
  getSchemeTrends: { __typename?: 'SchemeTrends' } & Pick<
    SchemeTrends,
    'clientName' | 'startTimestamp' | 'endTimestamp'
  > & {
      schemes?: Maybe<
        Array<
          { __typename?: 'SchemeTrend' } & Pick<
            SchemeTrend,
            | 'clientName'
            | 'schemeId'
            | 'schemeName'
            | 'clicks'
            | 'brochure'
            | 'cta'
          > & {
              scheme?: Maybe<
                { __typename?: 'Scheme' } & Pick<
                  Scheme,
                  'id' | 'name' | 'clientName'
                > & {
                    materials: Array<
                      { __typename?: 'SchemeElement' } & Pick<
                        SchemeElement,
                        | 'id'
                        | 'materialId'
                        | 'materialCategory'
                        | 'material'
                        | 'name'
                        | 'hex'
                        | 'swatch'
                        | 'masonryLib'
                      >
                    >
                  }
              >
              dailyMetrics?: Maybe<
                { __typename?: 'TimeSeries' } & {
                  frames?: Maybe<
                    Array<
                      | ({ __typename?: 'EventBasedTimeSeriesFrame' } & Pick<
                          EventBasedTimeSeriesFrame,
                          'date' | 'value'
                        >)
                      | ({ __typename?: 'SessionBasedTimeSeriesFrame' } & Pick<
                          SessionBasedTimeSeriesFrame,
                          | 'date'
                          | 'durationSum'
                          | 'durationAvg'
                          | 'durationMin'
                          | 'durationMax'
                          | 'durationMedian'
                          | 'eventsCount'
                        >)
                    >
                  >
                }
              >
            }
        >
      >
    }
}

export type SessionsQueryVariables = Exact<{
  filter: SessionFilter
}>

export type SessionsQuery = { __typename?: 'Query' } & {
  sessions: Array<
    { __typename?: 'Session' } & Pick<
      Session,
      'id' | 'startAt' | 'endAt' | 'duration'
    >
  >
}

export type SessionsSummaryQueryVariables = Exact<{
  filter: SessionFilter
}>

export type SessionsSummaryQuery = { __typename?: 'Query' } & {
  sessionsSummary: { __typename?: 'SessionsSummary' } & Pick<
    SessionsSummary,
    | 'clientName'
    | 'startAt'
    | 'endAt'
    | 'sessions'
    | 'visitors'
    | 'registered'
    | 'returnVisitors'
    | 'newVisitors'
    | 'averageDuration'
    | 'bounceRate'
  >
}

export type SessionsSummaryTrendsQueryVariables = Exact<{
  filter: SessionFilter
}>

export type SessionsSummaryTrendsQuery = { __typename?: 'Query' } & {
  sessionsSummaryTrends: Array<
    { __typename?: 'SessionsSummaryDate' } & Pick<
      SessionsSummaryDate,
      | 'clientName'
      | 'date'
      | 'sessions'
      | 'visitors'
      | 'registered'
      | 'returnVisitors'
      | 'newVisitors'
      | 'averageDuration'
      | 'bounceRate'
    >
  >
}

export type SessionsSummaryTrendsByCommunityQueryVariables = Exact<{
  filter: SessionFilter
}>

export type SessionsSummaryTrendsByCommunityQuery = { __typename?: 'Query' } & {
  sessionsSummaryTrendsByCommunity: Array<
    { __typename?: 'SessionsSummaryByCommunity' } & Pick<
      SessionsSummaryByCommunity,
      'communityId'
    > & {
        stats?: Maybe<
          Array<
            Maybe<
              { __typename?: 'SessionTrendsStats' } & Pick<
                SessionTrendsStats,
                | 'date'
                | 'numSessions'
                | 'avgDuration'
                | 'numBounces'
                | 'numVisitors'
                | 'returnVisitors'
                | 'newVisitors'
                | 'numRegistered'
              >
            >
          >
        >
      }
  >
}

export type CombinedTimeSeriesFragment = { __typename?: 'TimeSeries' } & {
  frames?: Maybe<
    Array<
      | ({ __typename?: 'EventBasedTimeSeriesFrame' } & Pick<
          EventBasedTimeSeriesFrame,
          'date' | 'value'
        >)
      | ({ __typename?: 'SessionBasedTimeSeriesFrame' } & Pick<
          SessionBasedTimeSeriesFrame,
          | 'date'
          | 'durationSum'
          | 'durationAvg'
          | 'durationMin'
          | 'durationMax'
          | 'durationMedian'
          | 'eventsCount'
        >)
    >
  >
}

export type VisitorTimesQueryVariables = Exact<{
  filter: CommonQueryFilter
  minuteUTCDifference: Scalars['Int']
}>

export type VisitorTimesQuery = { __typename?: 'Query' } & {
  visitorTimes: Array<
    { __typename?: 'VisitorTime' } & Pick<VisitorTime, 'hour'> & {
        dayCounts: Array<
          { __typename?: 'dayCounts' } & Pick<
            DayCounts,
            'day_count' | 'day_index'
          >
        >
      }
  >
}

export type VisitorTrendsQueryVariables = Exact<{
  filter: VisitorTrendsQueryFilter
}>

export type VisitorTrendsQuery = { __typename?: 'Query' } & {
  visitorTrends: Array<
    { __typename?: 'VisitorTrend' } & Pick<
      VisitorTrend,
      'date' | 'anonymous' | 'registered' | 'cumulativeRegistered'
    >
  >
}

export const CityFieldsMinimalFragmentDoc = gql`
  fragment CityFieldsMinimal on CityInfo {
    id
    name
    customName
    region(clientName: $clientName) {
      __typename
      ... on USCityRegionInfo {
        stateCode
        stateName
        metroName
        metroCustomName
        zipCode
      }
      ... on CANCityRegionInfo {
        provinceCode
        provinceName
        districtCode
        districtName
        postCode
      }
    }
  }
`
export const CommunityFieldsFragmentDoc = gql`
  fragment CommunityFields on Community {
    id
    name
    thumb
    bonafide
    buildYourLot
    caption
    colormtd
    description
    pricing
    logo
    longitude
    latitude
    address
    sortType
    sortOrder
    cityLocation(clientName: $clientName) {
      ...CityFieldsMinimal
    }
    agents(clientName: $clientName) {
      id
      email
      phone
      firstName
      lastName
    }
  }
  ${CityFieldsMinimalFragmentDoc}
`
export const PlanFieldsFragmentDoc = gql`
  fragment PlanFields on Plan {
    id
    displayName
    name
    communityId
    defaultElevationId
    description
    bed
    bath
    cost
    size
    videoUrl
    interactiveInteriorIds(clientName: $clientName)
    bedRange(clientName: $clientName) {
      min
      max
    }
    bathRange(clientName: $clientName) {
      min
      max
    }
    costRange(clientName: $clientName) {
      min
      max
    }
    sizeRange(clientName: $clientName) {
      min
      max
    }
  }
`
export const ElevationFieldsFragmentDoc = gql`
  fragment ElevationFields on Elevation {
    id
    mirrorElevationId
    mirrorRolePrimary
    communityId
    planName
    planDisplayName
    planId
    caption
    thumb
    bed
    bath
    size
    cost
    defaultFloor
    description
    svgMirroring
    garagePosition
    defaultGaragePosition
    bedRange(clientName: $clientName) {
      min
      max
    }
    bathRange(clientName: $clientName) {
      min
      max
    }
    costRange(clientName: $clientName) {
      min
      max
    }
    sizeRange(clientName: $clientName) {
      min
      max
    }
    tags(clientName: $clientName) {
      categoryName
      tagName
    }
  }
`
export const LotFieldsFragmentDoc = gql`
  fragment LotFields on Lot {
    id
    communityId
    dataName
    name
    salesStatus
    premium
    externalId
    address
    size
    cityName
    stateCode
    zip
    postCode
    availableSchemes(clientName: $clientName) {
      id
      name
    }
  }
`
export const InventoryFieldsFragmentDoc = gql`
  fragment InventoryFields on Inventory {
    id
    lotId
    planId
    elevationId
    communityId
    price
    sqft
    beds
    baths
    features
    constructionStatus
    closingDate
    mlsId
    garageType
    garageCapacity
    floors
    schemeId
    photoFolder
    plan(clientName: $clientName) {
      id
      communityId
      name
      displayName
    }
    sgtData(clientName: $clientName) {
      id
      sgtVendorId
      sgtExternalId
      sgtData
    }
    appointmentsEnabled
    reserveHomeUrl
  }
`
export const SiteplanSvgFieldsFragmentDoc = gql`
  fragment SiteplanSVGFields on Siteplan {
    svg {
      viewBox {
        x
        y
        width
        height
      }
      style
      frame {
        x
        y
        width
        height
      }
      shapes {
        tagName
        attributes {
          className
          dataName
          x
          y
          width
          height
          transform
          points
          d
        }
      }
    }
  }
`
export const CityFieldsFullFragmentDoc = gql`
  fragment CityFieldsFull on CityInfo {
    id
    name
    customName
    region(clientName: $clientName) {
      __typename
      ... on USCityRegionInfo {
        stateCode
        stateName
        countyCode
        countyName
        metroId
        metroName
        metroCustomName
        zipCode
      }
      ... on CANCityRegionInfo {
        provinceCode
        provinceName
        districtCode
        districtName
        postCode
      }
    }
  }
`
export const CombinedTimeSeriesFragmentDoc = gql`
  fragment CombinedTimeSeries on TimeSeries {
    frames {
      ... on EventBasedTimeSeriesFrame {
        date
        value
      }
      ... on SessionBasedTimeSeriesFrame {
        date
        durationSum
        durationAvg
        durationMin
        durationMax
        durationMedian
        eventsCount
      }
    }
  }
`
export const AddHomeSelectionDocument = gql`
  mutation AddHomeSelection($clientName: String!, $input: HomeSelectionInput!) {
    addHomeSelection(clientName: $clientName, input: $input)
  }
`
export type AddHomeSelectionMutationFn = ApolloReactCommon.MutationFunction<
  AddHomeSelectionMutation,
  AddHomeSelectionMutationVariables
>

/**
 * __useAddHomeSelectionMutation__
 *
 * To run a mutation, you first call `useAddHomeSelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddHomeSelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addHomeSelectionMutation, { data, loading, error }] = useAddHomeSelectionMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddHomeSelectionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddHomeSelectionMutation,
    AddHomeSelectionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddHomeSelectionMutation,
    AddHomeSelectionMutationVariables
  >(AddHomeSelectionDocument, baseOptions)
}
export type AddHomeSelectionMutationHookResult = ReturnType<
  typeof useAddHomeSelectionMutation
>
export type AddHomeSelectionMutationResult = ApolloReactCommon.MutationResult<AddHomeSelectionMutation>
export type AddHomeSelectionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddHomeSelectionMutation,
  AddHomeSelectionMutationVariables
>
export const RequireRegistrationDocument = gql`
  query RequireRegistration($clientName: String!) {
    customization(clientName: $clientName, name: "requireRegister") {
      id
      name
      value
    }
  }
`

/**
 * __useRequireRegistrationQuery__
 *
 * To run a query within a React component, call `useRequireRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequireRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequireRegistrationQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useRequireRegistrationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    RequireRegistrationQuery,
    RequireRegistrationQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    RequireRegistrationQuery,
    RequireRegistrationQueryVariables
  >(RequireRegistrationDocument, baseOptions)
}
export function useRequireRegistrationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RequireRegistrationQuery,
    RequireRegistrationQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    RequireRegistrationQuery,
    RequireRegistrationQueryVariables
  >(RequireRegistrationDocument, baseOptions)
}
export type RequireRegistrationQueryHookResult = ReturnType<
  typeof useRequireRegistrationQuery
>
export type RequireRegistrationLazyQueryHookResult = ReturnType<
  typeof useRequireRegistrationLazyQuery
>
export type RequireRegistrationQueryResult = ApolloReactCommon.QueryResult<
  RequireRegistrationQuery,
  RequireRegistrationQueryVariables
>
export const GetCommunityByNameLite2Document = gql`
  query GetCommunityByNameLite2($clientName: String!, $communityName: String!) {
    communityByName(clientName: $clientName, communityName: $communityName) {
      id
      name
      thumb
      bonafide
      buildYourLot
      caption
      colormtd
      description
      pricing
      logo
      longitude
      latitude
      address
      sortType
      sortOrder
      cityLocation(clientName: $clientName) {
        ...CityFieldsMinimal
      }
      agents(clientName: $clientName) {
        id
        email
        phone
        firstName
        lastName
      }
      siteplans(clientName: $clientName, active: true) {
        id
        communityId
      }
      primarySiteplan(clientName: $clientName, active: true) {
        id
        lotMetric
      }
      imgs(clientName: $clientName) {
        id
        src
      }
      stdFeatureCategories(clientName: $clientName) {
        id
        name
        features(clientName: $clientName, communityName: $communityName) {
          id
          name
        }
      }
    }
  }
  ${CityFieldsMinimalFragmentDoc}
`

/**
 * __useGetCommunityByNameLite2Query__
 *
 * To run a query within a React component, call `useGetCommunityByNameLite2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityByNameLite2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityByNameLite2Query({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      communityName: // value for 'communityName'
 *   },
 * });
 */
export function useGetCommunityByNameLite2Query(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetCommunityByNameLite2Query,
    GetCommunityByNameLite2QueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCommunityByNameLite2Query,
    GetCommunityByNameLite2QueryVariables
  >(GetCommunityByNameLite2Document, baseOptions)
}
export function useGetCommunityByNameLite2LazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCommunityByNameLite2Query,
    GetCommunityByNameLite2QueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetCommunityByNameLite2Query,
    GetCommunityByNameLite2QueryVariables
  >(GetCommunityByNameLite2Document, baseOptions)
}
export type GetCommunityByNameLite2QueryHookResult = ReturnType<
  typeof useGetCommunityByNameLite2Query
>
export type GetCommunityByNameLite2LazyQueryHookResult = ReturnType<
  typeof useGetCommunityByNameLite2LazyQuery
>
export type GetCommunityByNameLite2QueryResult = ApolloReactCommon.QueryResult<
  GetCommunityByNameLite2Query,
  GetCommunityByNameLite2QueryVariables
>
export const GetElevationLayersDocument = gql`
  query GetElevationLayers(
    $clientName: String!
    $elevationId: Int!
    $planId: Int!
    $communityId: Int
  ) {
    elevation(
      clientName: $clientName
      elevationId: $elevationId
      planId: $planId
      communityId: $communityId
    ) {
      id
      planId
      caption
      communityId
      base
      thumb
      layers(clientName: $clientName) {
        materialId
        name
        src
      }
      materialPalettes(
        clientName: $clientName
        planId: $planId
        communityId: $communityId
      ) {
        communityId
        planId
        elevationId
        materialId
        materialCategory
        name
        palettes {
          id
          name
          selections(clientName: $clientName) {
            id
            name
            hex
            swatch
            type
            masonryLib
            colorId
            brickId
            stoneId
            customOverlayId
          }
        }
      }
      schemes(
        clientName: $clientName
        planId: $planId
        communityId: $communityId
      ) {
        id
        name
        materials(clientName: $clientName) {
          id
          materialId
          materialCategory
          material
          name
          hex
          type
          swatch
          masonryLib
          colorId
          brickId
          stoneId
          customOverlayId
          paletteIds(clientName: $clientName, communityId: $communityId)
        }
      }
    }
  }
`

/**
 * __useGetElevationLayersQuery__
 *
 * To run a query within a React component, call `useGetElevationLayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetElevationLayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetElevationLayersQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      elevationId: // value for 'elevationId'
 *      planId: // value for 'planId'
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useGetElevationLayersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetElevationLayersQuery,
    GetElevationLayersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetElevationLayersQuery,
    GetElevationLayersQueryVariables
  >(GetElevationLayersDocument, baseOptions)
}
export function useGetElevationLayersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetElevationLayersQuery,
    GetElevationLayersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetElevationLayersQuery,
    GetElevationLayersQueryVariables
  >(GetElevationLayersDocument, baseOptions)
}
export type GetElevationLayersQueryHookResult = ReturnType<
  typeof useGetElevationLayersQuery
>
export type GetElevationLayersLazyQueryHookResult = ReturnType<
  typeof useGetElevationLayersLazyQuery
>
export type GetElevationLayersQueryResult = ApolloReactCommon.QueryResult<
  GetElevationLayersQuery,
  GetElevationLayersQueryVariables
>
export const GetHomeSelectionDocument = gql`
  query GetHomeSelection(
    $clientName: String!
    $prospectIdentifier: String!
    $homeIdentifier: String!
  ) {
    getHomeSelection(
      clientName: $clientName
      prospectIdentifier: $prospectIdentifier
      homeIdentifier: $homeIdentifier
    ) {
      json
    }
  }
`

/**
 * __useGetHomeSelectionQuery__
 *
 * To run a query within a React component, call `useGetHomeSelectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeSelectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeSelectionQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      prospectIdentifier: // value for 'prospectIdentifier'
 *      homeIdentifier: // value for 'homeIdentifier'
 *   },
 * });
 */
export function useGetHomeSelectionQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetHomeSelectionQuery,
    GetHomeSelectionQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetHomeSelectionQuery,
    GetHomeSelectionQueryVariables
  >(GetHomeSelectionDocument, baseOptions)
}
export function useGetHomeSelectionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHomeSelectionQuery,
    GetHomeSelectionQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetHomeSelectionQuery,
    GetHomeSelectionQueryVariables
  >(GetHomeSelectionDocument, baseOptions)
}
export type GetHomeSelectionQueryHookResult = ReturnType<
  typeof useGetHomeSelectionQuery
>
export type GetHomeSelectionLazyQueryHookResult = ReturnType<
  typeof useGetHomeSelectionLazyQuery
>
export type GetHomeSelectionQueryResult = ApolloReactCommon.QueryResult<
  GetHomeSelectionQuery,
  GetHomeSelectionQueryVariables
>
export const GetHomeSelectionByFavoriteIdDocument = gql`
  query GetHomeSelectionByFavoriteId(
    $clientName: String!
    $favoriteIdentifier: Int
  ) {
    getHomeSelection(
      clientName: $clientName
      favoriteIdentifier: $favoriteIdentifier
    ) {
      json
    }
  }
`

/**
 * __useGetHomeSelectionByFavoriteIdQuery__
 *
 * To run a query within a React component, call `useGetHomeSelectionByFavoriteIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeSelectionByFavoriteIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeSelectionByFavoriteIdQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      favoriteIdentifier: // value for 'favoriteIdentifier'
 *   },
 * });
 */
export function useGetHomeSelectionByFavoriteIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetHomeSelectionByFavoriteIdQuery,
    GetHomeSelectionByFavoriteIdQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetHomeSelectionByFavoriteIdQuery,
    GetHomeSelectionByFavoriteIdQueryVariables
  >(GetHomeSelectionByFavoriteIdDocument, baseOptions)
}
export function useGetHomeSelectionByFavoriteIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHomeSelectionByFavoriteIdQuery,
    GetHomeSelectionByFavoriteIdQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetHomeSelectionByFavoriteIdQuery,
    GetHomeSelectionByFavoriteIdQueryVariables
  >(GetHomeSelectionByFavoriteIdDocument, baseOptions)
}
export type GetHomeSelectionByFavoriteIdQueryHookResult = ReturnType<
  typeof useGetHomeSelectionByFavoriteIdQuery
>
export type GetHomeSelectionByFavoriteIdLazyQueryHookResult = ReturnType<
  typeof useGetHomeSelectionByFavoriteIdLazyQuery
>
export type GetHomeSelectionByFavoriteIdQueryResult = ApolloReactCommon.QueryResult<
  GetHomeSelectionByFavoriteIdQuery,
  GetHomeSelectionByFavoriteIdQueryVariables
>
export const InteriorsDocument = gql`
  query interiors($clientName: String!, $interior: InteriorInput!) {
    interiors(clientName: $clientName, interior: $interior) {
      id
      name
      baseImageSrc
      disclaimer
      elementTrees {
        id
        name
        options {
          id
          name
          overlaySwatchSrc
          hex
          cost
        }
      }
    }
  }
`

/**
 * __useInteriorsQuery__
 *
 * To run a query within a React component, call `useInteriorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInteriorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInteriorsQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      interior: // value for 'interior'
 *   },
 * });
 */
export function useInteriorsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    InteriorsQuery,
    InteriorsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<InteriorsQuery, InteriorsQueryVariables>(
    InteriorsDocument,
    baseOptions
  )
}
export function useInteriorsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    InteriorsQuery,
    InteriorsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<InteriorsQuery, InteriorsQueryVariables>(
    InteriorsDocument,
    baseOptions
  )
}
export type InteriorsQueryHookResult = ReturnType<typeof useInteriorsQuery>
export type InteriorsLazyQueryHookResult = ReturnType<
  typeof useInteriorsLazyQuery
>
export type InteriorsQueryResult = ApolloReactCommon.QueryResult<
  InteriorsQuery,
  InteriorsQueryVariables
>
export const GetPlanByNameDocument = gql`
  query GetPlanByName(
    $clientName: String!
    $communityName: String!
    $planName: String!
    $active: Boolean
  ) {
    planByName(
      clientName: $clientName
      communityName: $communityName
      planName: $planName
    ) {
      ...PlanFields
      exteriorAssets(clientName: $clientName) {
        src
        listIndex
        videoSrc
      }
      interiorPhotos(clientName: $clientName) {
        src
        listIndex
      }
      elevations(clientName: $clientName, active: $active) {
        ...ElevationFields
        collection(clientName: $clientName) {
          id
          communityId
          name
          siteplan(clientName: $clientName, active: true) {
            id
            name
          }
        }
        communities(
          clientName: $clientName
          excludeDefaultCommunity: true
          active: true
        ) {
          ...CommunityFields
        }
      }
    }
  }
  ${PlanFieldsFragmentDoc}
  ${ElevationFieldsFragmentDoc}
  ${CommunityFieldsFragmentDoc}
`

/**
 * __useGetPlanByNameQuery__
 *
 * To run a query within a React component, call `useGetPlanByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanByNameQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      communityName: // value for 'communityName'
 *      planName: // value for 'planName'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useGetPlanByNameQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetPlanByNameQuery,
    GetPlanByNameQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetPlanByNameQuery,
    GetPlanByNameQueryVariables
  >(GetPlanByNameDocument, baseOptions)
}
export function useGetPlanByNameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPlanByNameQuery,
    GetPlanByNameQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetPlanByNameQuery,
    GetPlanByNameQueryVariables
  >(GetPlanByNameDocument, baseOptions)
}
export type GetPlanByNameQueryHookResult = ReturnType<
  typeof useGetPlanByNameQuery
>
export type GetPlanByNameLazyQueryHookResult = ReturnType<
  typeof useGetPlanByNameLazyQuery
>
export type GetPlanByNameQueryResult = ApolloReactCommon.QueryResult<
  GetPlanByNameQuery,
  GetPlanByNameQueryVariables
>
export const GetFloorplansByElevationIdDocument = gql`
  query GetFloorplansByElevationId(
    $clientName: String!
    $communityId: Int!
    $planId: Int!
    $elevationId: Int!
  ) {
    floorplansByElevationId(
      clientName: $clientName
      communityId: $communityId
      planId: $planId
      elevationId: $elevationId
    ) {
      planId
      communityId
      elevationId
      defaultFloor
      stories {
        id
        communityId
        planId
        elevationId
        name
        fnum
        optional
        floorplans(clientName: $clientName) {
          id
          groupId
          name
          base
          optional
          standard
          cost
          size
          bed
          bath
          listIndex
          src
          src2
          labelSvg
          fnum
        }
        fpGroups(clientName: $clientName) {
          id
          name
          groupType
          primary(clientName: $clientName) {
            id
            groupId
            standard
            name
          }
          members(clientName: $clientName) {
            id
            name
            groupId
            standard
            fnum
            alternate(clientName: $clientName) {
              id
              src
              src2
              labelSvg
              cost
              size
              bed
              bath
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetFloorplansByElevationIdQuery__
 *
 * To run a query within a React component, call `useGetFloorplansByElevationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFloorplansByElevationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFloorplansByElevationIdQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      communityId: // value for 'communityId'
 *      planId: // value for 'planId'
 *      elevationId: // value for 'elevationId'
 *   },
 * });
 */
export function useGetFloorplansByElevationIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetFloorplansByElevationIdQuery,
    GetFloorplansByElevationIdQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetFloorplansByElevationIdQuery,
    GetFloorplansByElevationIdQueryVariables
  >(GetFloorplansByElevationIdDocument, baseOptions)
}
export function useGetFloorplansByElevationIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFloorplansByElevationIdQuery,
    GetFloorplansByElevationIdQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetFloorplansByElevationIdQuery,
    GetFloorplansByElevationIdQueryVariables
  >(GetFloorplansByElevationIdDocument, baseOptions)
}
export type GetFloorplansByElevationIdQueryHookResult = ReturnType<
  typeof useGetFloorplansByElevationIdQuery
>
export type GetFloorplansByElevationIdLazyQueryHookResult = ReturnType<
  typeof useGetFloorplansByElevationIdLazyQuery
>
export type GetFloorplansByElevationIdQueryResult = ApolloReactCommon.QueryResult<
  GetFloorplansByElevationIdQuery,
  GetFloorplansByElevationIdQueryVariables
>
export const GetClientByIdDocument = gql`
  query GetClientById($clientId: Int!) {
    clientById(clientId: $clientId) {
      id
      name
      altName
      directoryName
      disclaimer
      trackingId
      logo
      website
    }
  }
`

/**
 * __useGetClientByIdQuery__
 *
 * To run a query within a React component, call `useGetClientByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientByIdQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetClientByIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetClientByIdQuery,
    GetClientByIdQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetClientByIdQuery,
    GetClientByIdQueryVariables
  >(GetClientByIdDocument, baseOptions)
}
export function useGetClientByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetClientByIdQuery,
    GetClientByIdQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetClientByIdQuery,
    GetClientByIdQueryVariables
  >(GetClientByIdDocument, baseOptions)
}
export type GetClientByIdQueryHookResult = ReturnType<
  typeof useGetClientByIdQuery
>
export type GetClientByIdLazyQueryHookResult = ReturnType<
  typeof useGetClientByIdLazyQuery
>
export type GetClientByIdQueryResult = ApolloReactCommon.QueryResult<
  GetClientByIdQuery,
  GetClientByIdQueryVariables
>
export const GetClientDocument = gql`
  query GetClient($clientName: String!) {
    clientByName(clientName: $clientName) {
      id
      name
      altName
      directoryName
      disclaimer
      trackingId
      logo
      website
      sgtVendors(clientName: $clientName) {
        id
        icon
        logo
        name
        extendedData
      }
    }
  }
`

/**
 * __useGetClientQuery__
 *
 * To run a query within a React component, call `useGetClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useGetClientQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetClientQuery,
    GetClientQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetClientQuery, GetClientQueryVariables>(
    GetClientDocument,
    baseOptions
  )
}
export function useGetClientLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetClientQuery,
    GetClientQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetClientQuery, GetClientQueryVariables>(
    GetClientDocument,
    baseOptions
  )
}
export type GetClientQueryHookResult = ReturnType<typeof useGetClientQuery>
export type GetClientLazyQueryHookResult = ReturnType<
  typeof useGetClientLazyQuery
>
export type GetClientQueryResult = ApolloReactCommon.QueryResult<
  GetClientQuery,
  GetClientQueryVariables
>
export const GetBuilderAppConfigDocument = gql`
  query GetBuilderAppConfig($clientName: String!) {
    builderAppConfig(clientName: $clientName)
    clientCustomizations(clientName: $clientName) {
      id
      name
      value
    }
  }
`

/**
 * __useGetBuilderAppConfigQuery__
 *
 * To run a query within a React component, call `useGetBuilderAppConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuilderAppConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuilderAppConfigQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useGetBuilderAppConfigQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetBuilderAppConfigQuery,
    GetBuilderAppConfigQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetBuilderAppConfigQuery,
    GetBuilderAppConfigQueryVariables
  >(GetBuilderAppConfigDocument, baseOptions)
}
export function useGetBuilderAppConfigLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetBuilderAppConfigQuery,
    GetBuilderAppConfigQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetBuilderAppConfigQuery,
    GetBuilderAppConfigQueryVariables
  >(GetBuilderAppConfigDocument, baseOptions)
}
export type GetBuilderAppConfigQueryHookResult = ReturnType<
  typeof useGetBuilderAppConfigQuery
>
export type GetBuilderAppConfigLazyQueryHookResult = ReturnType<
  typeof useGetBuilderAppConfigLazyQuery
>
export type GetBuilderAppConfigQueryResult = ApolloReactCommon.QueryResult<
  GetBuilderAppConfigQuery,
  GetBuilderAppConfigQueryVariables
>
export const GetLotWithInventoryDocument = gql`
  query GetLotWithInventory($clientName: String!, $lotId: Int!) {
    lot(clientName: $clientName, lotId: $lotId) {
      ...LotFields
      inventory(clientName: $clientName) {
        ...InventoryFields
        virTourUrl
        photos(clientName: $clientName) {
          id
          src
          listIndex
        }
        mlsPhotos
        includedFloorplans(clientName: $clientName) {
          id
          groupId
          standard
          name
        }
        plan(clientName: $clientName) {
          ...PlanFields
        }
        elevation(clientName: $clientName) {
          ...ElevationFields
        }
      }
    }
  }
  ${LotFieldsFragmentDoc}
  ${InventoryFieldsFragmentDoc}
  ${PlanFieldsFragmentDoc}
  ${ElevationFieldsFragmentDoc}
`

/**
 * __useGetLotWithInventoryQuery__
 *
 * To run a query within a React component, call `useGetLotWithInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLotWithInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLotWithInventoryQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      lotId: // value for 'lotId'
 *   },
 * });
 */
export function useGetLotWithInventoryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetLotWithInventoryQuery,
    GetLotWithInventoryQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetLotWithInventoryQuery,
    GetLotWithInventoryQueryVariables
  >(GetLotWithInventoryDocument, baseOptions)
}
export function useGetLotWithInventoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetLotWithInventoryQuery,
    GetLotWithInventoryQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetLotWithInventoryQuery,
    GetLotWithInventoryQueryVariables
  >(GetLotWithInventoryDocument, baseOptions)
}
export type GetLotWithInventoryQueryHookResult = ReturnType<
  typeof useGetLotWithInventoryQuery
>
export type GetLotWithInventoryLazyQueryHookResult = ReturnType<
  typeof useGetLotWithInventoryLazyQuery
>
export type GetLotWithInventoryQueryResult = ApolloReactCommon.QueryResult<
  GetLotWithInventoryQuery,
  GetLotWithInventoryQueryVariables
>
export const RequestSgtAppointmentsDocument = gql`
  mutation RequestSgtAppointments(
    $clientName: String!
    $prospectSgtApptInput: ProspectSgtApptInput!
    $timezoneOffset: Int!
  ) {
    requestSgtAppointment(
      clientName: $clientName
      prospectSgtApptInput: $prospectSgtApptInput
      timezoneOffset: $timezoneOffset
    ) {
      id
      prospectId
      inventoryId
      preferredDate
      createdOn
    }
  }
`
export type RequestSgtAppointmentsMutationFn = ApolloReactCommon.MutationFunction<
  RequestSgtAppointmentsMutation,
  RequestSgtAppointmentsMutationVariables
>

/**
 * __useRequestSgtAppointmentsMutation__
 *
 * To run a mutation, you first call `useRequestSgtAppointmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestSgtAppointmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestSgtAppointmentsMutation, { data, loading, error }] = useRequestSgtAppointmentsMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      prospectSgtApptInput: // value for 'prospectSgtApptInput'
 *      timezoneOffset: // value for 'timezoneOffset'
 *   },
 * });
 */
export function useRequestSgtAppointmentsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RequestSgtAppointmentsMutation,
    RequestSgtAppointmentsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RequestSgtAppointmentsMutation,
    RequestSgtAppointmentsMutationVariables
  >(RequestSgtAppointmentsDocument, baseOptions)
}
export type RequestSgtAppointmentsMutationHookResult = ReturnType<
  typeof useRequestSgtAppointmentsMutation
>
export type RequestSgtAppointmentsMutationResult = ApolloReactCommon.MutationResult<RequestSgtAppointmentsMutation>
export type RequestSgtAppointmentsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RequestSgtAppointmentsMutation,
  RequestSgtAppointmentsMutationVariables
>
export const GetProspectSgtAppointmentsDocument = gql`
  query GetProspectSgtAppointments(
    $clientName: String!
    $prospectId: Int!
    $inventoryId: Int!
  ) {
    prospectSgtAppointments(
      clientName: $clientName
      prospectId: $prospectId
      inventoryId: $inventoryId
    ) {
      preferredDate
    }
  }
`

/**
 * __useGetProspectSgtAppointmentsQuery__
 *
 * To run a query within a React component, call `useGetProspectSgtAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProspectSgtAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProspectSgtAppointmentsQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      prospectId: // value for 'prospectId'
 *      inventoryId: // value for 'inventoryId'
 *   },
 * });
 */
export function useGetProspectSgtAppointmentsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetProspectSgtAppointmentsQuery,
    GetProspectSgtAppointmentsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetProspectSgtAppointmentsQuery,
    GetProspectSgtAppointmentsQueryVariables
  >(GetProspectSgtAppointmentsDocument, baseOptions)
}
export function useGetProspectSgtAppointmentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetProspectSgtAppointmentsQuery,
    GetProspectSgtAppointmentsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetProspectSgtAppointmentsQuery,
    GetProspectSgtAppointmentsQueryVariables
  >(GetProspectSgtAppointmentsDocument, baseOptions)
}
export type GetProspectSgtAppointmentsQueryHookResult = ReturnType<
  typeof useGetProspectSgtAppointmentsQuery
>
export type GetProspectSgtAppointmentsLazyQueryHookResult = ReturnType<
  typeof useGetProspectSgtAppointmentsLazyQuery
>
export type GetProspectSgtAppointmentsQueryResult = ApolloReactCommon.QueryResult<
  GetProspectSgtAppointmentsQuery,
  GetProspectSgtAppointmentsQueryVariables
>
export const GetCommunityNameDocument = gql`
  query GetCommunityName($clientName: String!, $communityId: Int!) {
    community(clientName: $clientName, communityId: $communityId) {
      id
      name
    }
  }
`

/**
 * __useGetCommunityNameQuery__
 *
 * To run a query within a React component, call `useGetCommunityNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityNameQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useGetCommunityNameQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetCommunityNameQuery,
    GetCommunityNameQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCommunityNameQuery,
    GetCommunityNameQueryVariables
  >(GetCommunityNameDocument, baseOptions)
}
export function useGetCommunityNameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCommunityNameQuery,
    GetCommunityNameQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetCommunityNameQuery,
    GetCommunityNameQueryVariables
  >(GetCommunityNameDocument, baseOptions)
}
export type GetCommunityNameQueryHookResult = ReturnType<
  typeof useGetCommunityNameQuery
>
export type GetCommunityNameLazyQueryHookResult = ReturnType<
  typeof useGetCommunityNameLazyQuery
>
export type GetCommunityNameQueryResult = ApolloReactCommon.QueryResult<
  GetCommunityNameQuery,
  GetCommunityNameQueryVariables
>
export const GetElevationWithStoriesOnlyDocument = gql`
  query GetElevationWithStoriesOnly(
    $clientName: String!
    $communityId: Int
    $planId: Int
    $elevationId: Int!
  ) {
    elevation(
      clientName: $clientName
      communityId: $communityId
      planId: $planId
      elevationId: $elevationId
    ) {
      id
      communityId
      planId
      stories(clientName: $clientName) {
        id
        communityId
        planId
        elevationId
        name
        fnum
        optional
      }
    }
  }
`

/**
 * __useGetElevationWithStoriesOnlyQuery__
 *
 * To run a query within a React component, call `useGetElevationWithStoriesOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetElevationWithStoriesOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetElevationWithStoriesOnlyQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      communityId: // value for 'communityId'
 *      planId: // value for 'planId'
 *      elevationId: // value for 'elevationId'
 *   },
 * });
 */
export function useGetElevationWithStoriesOnlyQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetElevationWithStoriesOnlyQuery,
    GetElevationWithStoriesOnlyQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetElevationWithStoriesOnlyQuery,
    GetElevationWithStoriesOnlyQueryVariables
  >(GetElevationWithStoriesOnlyDocument, baseOptions)
}
export function useGetElevationWithStoriesOnlyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetElevationWithStoriesOnlyQuery,
    GetElevationWithStoriesOnlyQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetElevationWithStoriesOnlyQuery,
    GetElevationWithStoriesOnlyQueryVariables
  >(GetElevationWithStoriesOnlyDocument, baseOptions)
}
export type GetElevationWithStoriesOnlyQueryHookResult = ReturnType<
  typeof useGetElevationWithStoriesOnlyQuery
>
export type GetElevationWithStoriesOnlyLazyQueryHookResult = ReturnType<
  typeof useGetElevationWithStoriesOnlyLazyQuery
>
export type GetElevationWithStoriesOnlyQueryResult = ApolloReactCommon.QueryResult<
  GetElevationWithStoriesOnlyQuery,
  GetElevationWithStoriesOnlyQueryVariables
>
export const LotWithInventoryExteriorConfigurationOnlyDocument = gql`
  query LotWithInventoryExteriorConfigurationOnly(
    $clientName: String!
    $lotId: Int!
  ) {
    lot(clientName: $clientName, lotId: $lotId) {
      id
      inventory(clientName: $clientName) {
        id
        exteriorConfiguration(clientName: $clientName) {
          inventoryId
          scheme {
            id
            name
            materials(clientName: $clientName) {
              id
              materialId
              materialCategory
              material
              name
              hex
              type
              swatch
              masonryLib
              colorId
              brickId
              stoneId
              customOverlayId
            }
          }
          paletteElementSelections {
            inventoryId
            elementId
            paletteId
            paletteSelection {
              id
            }
          }
        }
      }
    }
  }
`

/**
 * __useLotWithInventoryExteriorConfigurationOnlyQuery__
 *
 * To run a query within a React component, call `useLotWithInventoryExteriorConfigurationOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useLotWithInventoryExteriorConfigurationOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLotWithInventoryExteriorConfigurationOnlyQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      lotId: // value for 'lotId'
 *   },
 * });
 */
export function useLotWithInventoryExteriorConfigurationOnlyQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    LotWithInventoryExteriorConfigurationOnlyQuery,
    LotWithInventoryExteriorConfigurationOnlyQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    LotWithInventoryExteriorConfigurationOnlyQuery,
    LotWithInventoryExteriorConfigurationOnlyQueryVariables
  >(LotWithInventoryExteriorConfigurationOnlyDocument, baseOptions)
}
export function useLotWithInventoryExteriorConfigurationOnlyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LotWithInventoryExteriorConfigurationOnlyQuery,
    LotWithInventoryExteriorConfigurationOnlyQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    LotWithInventoryExteriorConfigurationOnlyQuery,
    LotWithInventoryExteriorConfigurationOnlyQueryVariables
  >(LotWithInventoryExteriorConfigurationOnlyDocument, baseOptions)
}
export type LotWithInventoryExteriorConfigurationOnlyQueryHookResult = ReturnType<
  typeof useLotWithInventoryExteriorConfigurationOnlyQuery
>
export type LotWithInventoryExteriorConfigurationOnlyLazyQueryHookResult = ReturnType<
  typeof useLotWithInventoryExteriorConfigurationOnlyLazyQuery
>
export type LotWithInventoryExteriorConfigurationOnlyQueryResult = ApolloReactCommon.QueryResult<
  LotWithInventoryExteriorConfigurationOnlyQuery,
  LotWithInventoryExteriorConfigurationOnlyQueryVariables
>
export const GetLotWithSiteplanInfoDocument = gql`
  query GetLotWithSiteplanInfo($clientName: String!, $lotId: Int!) {
    lot(clientName: $clientName, lotId: $lotId) {
      ...LotFields
      siteplanName(clientName: $clientName)
      siteplanInfo {
        lotId
        siteplanId
        x
        y
      }
    }
  }
  ${LotFieldsFragmentDoc}
`

/**
 * __useGetLotWithSiteplanInfoQuery__
 *
 * To run a query within a React component, call `useGetLotWithSiteplanInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLotWithSiteplanInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLotWithSiteplanInfoQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      lotId: // value for 'lotId'
 *   },
 * });
 */
export function useGetLotWithSiteplanInfoQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetLotWithSiteplanInfoQuery,
    GetLotWithSiteplanInfoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetLotWithSiteplanInfoQuery,
    GetLotWithSiteplanInfoQueryVariables
  >(GetLotWithSiteplanInfoDocument, baseOptions)
}
export function useGetLotWithSiteplanInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetLotWithSiteplanInfoQuery,
    GetLotWithSiteplanInfoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetLotWithSiteplanInfoQuery,
    GetLotWithSiteplanInfoQueryVariables
  >(GetLotWithSiteplanInfoDocument, baseOptions)
}
export type GetLotWithSiteplanInfoQueryHookResult = ReturnType<
  typeof useGetLotWithSiteplanInfoQuery
>
export type GetLotWithSiteplanInfoLazyQueryHookResult = ReturnType<
  typeof useGetLotWithSiteplanInfoLazyQuery
>
export type GetLotWithSiteplanInfoQueryResult = ApolloReactCommon.QueryResult<
  GetLotWithSiteplanInfoQuery,
  GetLotWithSiteplanInfoQueryVariables
>
export const GetSchemeDocument = gql`
  query GetScheme($clientName: String!, $communityId: Int, $schemeId: Int!) {
    scheme(
      clientName: $clientName
      communityId: $communityId
      schemeId: $schemeId
    ) {
      id
      name
      materials(clientName: $clientName) {
        id
        materialId
        materialCategory
        material
        name
        hex
        type
        swatch
        masonryLib
        colorId
        brickId
        stoneId
        customOverlayId
      }
    }
  }
`

/**
 * __useGetSchemeQuery__
 *
 * To run a query within a React component, call `useGetSchemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchemeQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      communityId: // value for 'communityId'
 *      schemeId: // value for 'schemeId'
 *   },
 * });
 */
export function useGetSchemeQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetSchemeQuery,
    GetSchemeQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetSchemeQuery, GetSchemeQueryVariables>(
    GetSchemeDocument,
    baseOptions
  )
}
export function useGetSchemeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSchemeQuery,
    GetSchemeQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetSchemeQuery, GetSchemeQueryVariables>(
    GetSchemeDocument,
    baseOptions
  )
}
export type GetSchemeQueryHookResult = ReturnType<typeof useGetSchemeQuery>
export type GetSchemeLazyQueryHookResult = ReturnType<
  typeof useGetSchemeLazyQuery
>
export type GetSchemeQueryResult = ApolloReactCommon.QueryResult<
  GetSchemeQuery,
  GetSchemeQueryVariables
>
export const GetSiteplanLiteDocument = gql`
  query GetSiteplanLite($clientName: String!, $id: Int!) {
    siteplanById(clientName: $clientName, id: $id) {
      id
      name
      lotFontSize
      lotMetric
      lotWidth
      lotHeight
      master
      src
      ...SiteplanSVGFields
      lotLegend(clientName: $clientName) {
        id
        code
        name
        hex
      }
      hotspots {
        id
        siteplanId
        name
        x
        y
        description
        thumb
        assets {
          id
          listIndex
          src
          description
        }
      }
    }
  }
  ${SiteplanSvgFieldsFragmentDoc}
`

/**
 * __useGetSiteplanLiteQuery__
 *
 * To run a query within a React component, call `useGetSiteplanLiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteplanLiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteplanLiteQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSiteplanLiteQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetSiteplanLiteQuery,
    GetSiteplanLiteQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetSiteplanLiteQuery,
    GetSiteplanLiteQueryVariables
  >(GetSiteplanLiteDocument, baseOptions)
}
export function useGetSiteplanLiteLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSiteplanLiteQuery,
    GetSiteplanLiteQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetSiteplanLiteQuery,
    GetSiteplanLiteQueryVariables
  >(GetSiteplanLiteDocument, baseOptions)
}
export type GetSiteplanLiteQueryHookResult = ReturnType<
  typeof useGetSiteplanLiteQuery
>
export type GetSiteplanLiteLazyQueryHookResult = ReturnType<
  typeof useGetSiteplanLiteLazyQuery
>
export type GetSiteplanLiteQueryResult = ApolloReactCommon.QueryResult<
  GetSiteplanLiteQuery,
  GetSiteplanLiteQueryVariables
>
export const GetColonnadeClientSubscriptionInfoDocument = gql`
  query getColonnadeClientSubscriptionInfo($clientId: Int!) {
    getColonnadeClientSubscriptionInfo(clientId: $clientId) {
      numberOfLicenses
      subscriptionLevel
      subscriptionDate
      rolePrivilegeObject
      anniversaryNotificationState
    }
  }
`

/**
 * __useGetColonnadeClientSubscriptionInfoQuery__
 *
 * To run a query within a React component, call `useGetColonnadeClientSubscriptionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetColonnadeClientSubscriptionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetColonnadeClientSubscriptionInfoQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetColonnadeClientSubscriptionInfoQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetColonnadeClientSubscriptionInfoQuery,
    GetColonnadeClientSubscriptionInfoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetColonnadeClientSubscriptionInfoQuery,
    GetColonnadeClientSubscriptionInfoQueryVariables
  >(GetColonnadeClientSubscriptionInfoDocument, baseOptions)
}
export function useGetColonnadeClientSubscriptionInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetColonnadeClientSubscriptionInfoQuery,
    GetColonnadeClientSubscriptionInfoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetColonnadeClientSubscriptionInfoQuery,
    GetColonnadeClientSubscriptionInfoQueryVariables
  >(GetColonnadeClientSubscriptionInfoDocument, baseOptions)
}
export type GetColonnadeClientSubscriptionInfoQueryHookResult = ReturnType<
  typeof useGetColonnadeClientSubscriptionInfoQuery
>
export type GetColonnadeClientSubscriptionInfoLazyQueryHookResult = ReturnType<
  typeof useGetColonnadeClientSubscriptionInfoLazyQuery
>
export type GetColonnadeClientSubscriptionInfoQueryResult = ApolloReactCommon.QueryResult<
  GetColonnadeClientSubscriptionInfoQuery,
  GetColonnadeClientSubscriptionInfoQueryVariables
>
export const GetClientReportInfoDocument = gql`
  query getClientReportInfo($clientId: Int!) {
    getClientEmailReportInfo(clientId: $clientId) {
      reportId
      firstName
      lastName
      email
      frequency
      clientId
    }
  }
`

/**
 * __useGetClientReportInfoQuery__
 *
 * To run a query within a React component, call `useGetClientReportInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientReportInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientReportInfoQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetClientReportInfoQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetClientReportInfoQuery,
    GetClientReportInfoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetClientReportInfoQuery,
    GetClientReportInfoQueryVariables
  >(GetClientReportInfoDocument, baseOptions)
}
export function useGetClientReportInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetClientReportInfoQuery,
    GetClientReportInfoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetClientReportInfoQuery,
    GetClientReportInfoQueryVariables
  >(GetClientReportInfoDocument, baseOptions)
}
export type GetClientReportInfoQueryHookResult = ReturnType<
  typeof useGetClientReportInfoQuery
>
export type GetClientReportInfoLazyQueryHookResult = ReturnType<
  typeof useGetClientReportInfoLazyQuery
>
export type GetClientReportInfoQueryResult = ApolloReactCommon.QueryResult<
  GetClientReportInfoQuery,
  GetClientReportInfoQueryVariables
>
export const SetColonnadeClientsLicensesDocument = gql`
  mutation SET_COLONNADE_CLIENTS_LICENSES(
    $clientId: Int!
    $numberOfLicenses: Int!
  ) {
    setColonnadeClientLicenses(
      clientId: $clientId
      numberOfLicenses: $numberOfLicenses
    )
  }
`
export type SetColonnadeClientsLicensesMutationFn = ApolloReactCommon.MutationFunction<
  SetColonnadeClientsLicensesMutation,
  SetColonnadeClientsLicensesMutationVariables
>

/**
 * __useSetColonnadeClientsLicensesMutation__
 *
 * To run a mutation, you first call `useSetColonnadeClientsLicensesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetColonnadeClientsLicensesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setColonnadeClientsLicensesMutation, { data, loading, error }] = useSetColonnadeClientsLicensesMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      numberOfLicenses: // value for 'numberOfLicenses'
 *   },
 * });
 */
export function useSetColonnadeClientsLicensesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetColonnadeClientsLicensesMutation,
    SetColonnadeClientsLicensesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SetColonnadeClientsLicensesMutation,
    SetColonnadeClientsLicensesMutationVariables
  >(SetColonnadeClientsLicensesDocument, baseOptions)
}
export type SetColonnadeClientsLicensesMutationHookResult = ReturnType<
  typeof useSetColonnadeClientsLicensesMutation
>
export type SetColonnadeClientsLicensesMutationResult = ApolloReactCommon.MutationResult<SetColonnadeClientsLicensesMutation>
export type SetColonnadeClientsLicensesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetColonnadeClientsLicensesMutation,
  SetColonnadeClientsLicensesMutationVariables
>
export const DeleteColonnadeClientEmailReportInfoDocument = gql`
  mutation DELETE_COLONNADE_CLIENT_EMAIL_REPORT_INFO($reportId: Int!) {
    deleteColonnadeClientEmailReportInfo(reportId: $reportId)
  }
`
export type DeleteColonnadeClientEmailReportInfoMutationFn = ApolloReactCommon.MutationFunction<
  DeleteColonnadeClientEmailReportInfoMutation,
  DeleteColonnadeClientEmailReportInfoMutationVariables
>

/**
 * __useDeleteColonnadeClientEmailReportInfoMutation__
 *
 * To run a mutation, you first call `useDeleteColonnadeClientEmailReportInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteColonnadeClientEmailReportInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteColonnadeClientEmailReportInfoMutation, { data, loading, error }] = useDeleteColonnadeClientEmailReportInfoMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useDeleteColonnadeClientEmailReportInfoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteColonnadeClientEmailReportInfoMutation,
    DeleteColonnadeClientEmailReportInfoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteColonnadeClientEmailReportInfoMutation,
    DeleteColonnadeClientEmailReportInfoMutationVariables
  >(DeleteColonnadeClientEmailReportInfoDocument, baseOptions)
}
export type DeleteColonnadeClientEmailReportInfoMutationHookResult = ReturnType<
  typeof useDeleteColonnadeClientEmailReportInfoMutation
>
export type DeleteColonnadeClientEmailReportInfoMutationResult = ApolloReactCommon.MutationResult<DeleteColonnadeClientEmailReportInfoMutation>
export type DeleteColonnadeClientEmailReportInfoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteColonnadeClientEmailReportInfoMutation,
  DeleteColonnadeClientEmailReportInfoMutationVariables
>
export const AddColonnadeClientEmailReportInfoDocument = gql`
  mutation ADD_COLONNADE_CLIENT_EMAIL_REPORT_INFO(
    $clientId: Int!
    $firstName: String
    $lastName: String
    $email: String!
    $frequency: ColonnadeReportFrequencyEnum!
  ) {
    addColonnadeClientEmailReportInfo(
      clientId: $clientId
      firstName: $firstName
      lastName: $lastName
      email: $email
      frequency: $frequency
    ) {
      reportId
      firstName
      lastName
      email
      frequency
      clientId
    }
  }
`
export type AddColonnadeClientEmailReportInfoMutationFn = ApolloReactCommon.MutationFunction<
  AddColonnadeClientEmailReportInfoMutation,
  AddColonnadeClientEmailReportInfoMutationVariables
>

/**
 * __useAddColonnadeClientEmailReportInfoMutation__
 *
 * To run a mutation, you first call `useAddColonnadeClientEmailReportInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddColonnadeClientEmailReportInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addColonnadeClientEmailReportInfoMutation, { data, loading, error }] = useAddColonnadeClientEmailReportInfoMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      frequency: // value for 'frequency'
 *   },
 * });
 */
export function useAddColonnadeClientEmailReportInfoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddColonnadeClientEmailReportInfoMutation,
    AddColonnadeClientEmailReportInfoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddColonnadeClientEmailReportInfoMutation,
    AddColonnadeClientEmailReportInfoMutationVariables
  >(AddColonnadeClientEmailReportInfoDocument, baseOptions)
}
export type AddColonnadeClientEmailReportInfoMutationHookResult = ReturnType<
  typeof useAddColonnadeClientEmailReportInfoMutation
>
export type AddColonnadeClientEmailReportInfoMutationResult = ApolloReactCommon.MutationResult<AddColonnadeClientEmailReportInfoMutation>
export type AddColonnadeClientEmailReportInfoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddColonnadeClientEmailReportInfoMutation,
  AddColonnadeClientEmailReportInfoMutationVariables
>
export const UpdateColonnadeClientEmailReportInfoDocument = gql`
  mutation UPDATE_COLONNADE_CLIENT_EMAIL_REPORT_INFO(
    $reportId: Int!
    $firstName: String
    $lastName: String
    $email: String!
    $frequency: ColonnadeReportFrequencyEnum!
  ) {
    updateColonnadeClientEmailReportInfo(
      reportId: $reportId
      firstName: $firstName
      lastName: $lastName
      email: $email
      frequency: $frequency
    ) {
      reportId
      firstName
      lastName
      email
      frequency
      clientId
    }
  }
`
export type UpdateColonnadeClientEmailReportInfoMutationFn = ApolloReactCommon.MutationFunction<
  UpdateColonnadeClientEmailReportInfoMutation,
  UpdateColonnadeClientEmailReportInfoMutationVariables
>

/**
 * __useUpdateColonnadeClientEmailReportInfoMutation__
 *
 * To run a mutation, you first call `useUpdateColonnadeClientEmailReportInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateColonnadeClientEmailReportInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateColonnadeClientEmailReportInfoMutation, { data, loading, error }] = useUpdateColonnadeClientEmailReportInfoMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      frequency: // value for 'frequency'
 *   },
 * });
 */
export function useUpdateColonnadeClientEmailReportInfoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateColonnadeClientEmailReportInfoMutation,
    UpdateColonnadeClientEmailReportInfoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateColonnadeClientEmailReportInfoMutation,
    UpdateColonnadeClientEmailReportInfoMutationVariables
  >(UpdateColonnadeClientEmailReportInfoDocument, baseOptions)
}
export type UpdateColonnadeClientEmailReportInfoMutationHookResult = ReturnType<
  typeof useUpdateColonnadeClientEmailReportInfoMutation
>
export type UpdateColonnadeClientEmailReportInfoMutationResult = ApolloReactCommon.MutationResult<UpdateColonnadeClientEmailReportInfoMutation>
export type UpdateColonnadeClientEmailReportInfoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateColonnadeClientEmailReportInfoMutation,
  UpdateColonnadeClientEmailReportInfoMutationVariables
>
export const IsUserSuperAdminDocument = gql`
  query isUserSuperAdmin($userId: Int!) {
    isUserSuperAdmin(userId: $userId)
  }
`

/**
 * __useIsUserSuperAdminQuery__
 *
 * To run a query within a React component, call `useIsUserSuperAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUserSuperAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUserSuperAdminQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useIsUserSuperAdminQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IsUserSuperAdminQuery,
    IsUserSuperAdminQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IsUserSuperAdminQuery,
    IsUserSuperAdminQueryVariables
  >(IsUserSuperAdminDocument, baseOptions)
}
export function useIsUserSuperAdminLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IsUserSuperAdminQuery,
    IsUserSuperAdminQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IsUserSuperAdminQuery,
    IsUserSuperAdminQueryVariables
  >(IsUserSuperAdminDocument, baseOptions)
}
export type IsUserSuperAdminQueryHookResult = ReturnType<
  typeof useIsUserSuperAdminQuery
>
export type IsUserSuperAdminLazyQueryHookResult = ReturnType<
  typeof useIsUserSuperAdminLazyQuery
>
export type IsUserSuperAdminQueryResult = ApolloReactCommon.QueryResult<
  IsUserSuperAdminQuery,
  IsUserSuperAdminQueryVariables
>
export const IsUserAnewgoAdminDocument = gql`
  query isUserAnewgoAdmin($userId: Int!) {
    isUserAnewgoAdmin(userId: $userId)
  }
`

/**
 * __useIsUserAnewgoAdminQuery__
 *
 * To run a query within a React component, call `useIsUserAnewgoAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUserAnewgoAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUserAnewgoAdminQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useIsUserAnewgoAdminQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IsUserAnewgoAdminQuery,
    IsUserAnewgoAdminQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IsUserAnewgoAdminQuery,
    IsUserAnewgoAdminQueryVariables
  >(IsUserAnewgoAdminDocument, baseOptions)
}
export function useIsUserAnewgoAdminLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IsUserAnewgoAdminQuery,
    IsUserAnewgoAdminQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IsUserAnewgoAdminQuery,
    IsUserAnewgoAdminQueryVariables
  >(IsUserAnewgoAdminDocument, baseOptions)
}
export type IsUserAnewgoAdminQueryHookResult = ReturnType<
  typeof useIsUserAnewgoAdminQuery
>
export type IsUserAnewgoAdminLazyQueryHookResult = ReturnType<
  typeof useIsUserAnewgoAdminLazyQuery
>
export type IsUserAnewgoAdminQueryResult = ApolloReactCommon.QueryResult<
  IsUserAnewgoAdminQuery,
  IsUserAnewgoAdminQueryVariables
>
export const SetColonnadeClientsSubsLevelDocument = gql`
  mutation SET_COLONNADE_CLIENTS_SUBS_LEVEL(
    $clientId: Int!
    $subscriptionLevel: ColonnadeSubscriptionLevelsEnum!
  ) {
    setColonnadeClientSubsLevel(
      clientId: $clientId
      subscriptionLevel: $subscriptionLevel
    )
  }
`
export type SetColonnadeClientsSubsLevelMutationFn = ApolloReactCommon.MutationFunction<
  SetColonnadeClientsSubsLevelMutation,
  SetColonnadeClientsSubsLevelMutationVariables
>

/**
 * __useSetColonnadeClientsSubsLevelMutation__
 *
 * To run a mutation, you first call `useSetColonnadeClientsSubsLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetColonnadeClientsSubsLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setColonnadeClientsSubsLevelMutation, { data, loading, error }] = useSetColonnadeClientsSubsLevelMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      subscriptionLevel: // value for 'subscriptionLevel'
 *   },
 * });
 */
export function useSetColonnadeClientsSubsLevelMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetColonnadeClientsSubsLevelMutation,
    SetColonnadeClientsSubsLevelMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SetColonnadeClientsSubsLevelMutation,
    SetColonnadeClientsSubsLevelMutationVariables
  >(SetColonnadeClientsSubsLevelDocument, baseOptions)
}
export type SetColonnadeClientsSubsLevelMutationHookResult = ReturnType<
  typeof useSetColonnadeClientsSubsLevelMutation
>
export type SetColonnadeClientsSubsLevelMutationResult = ApolloReactCommon.MutationResult<SetColonnadeClientsSubsLevelMutation>
export type SetColonnadeClientsSubsLevelMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetColonnadeClientsSubsLevelMutation,
  SetColonnadeClientsSubsLevelMutationVariables
>
export const SetColonnadeRolePermissionDocument = gql`
  mutation SET_COLONNADE_ROLE_PERMISSION(
    $roleName: ColonnadeRolesEnum!
    $privilege: [ColonnadeRolePrivilegesEnum!]!
  ) {
    setRolePermission(roleName: $roleName, privilege: $privilege)
  }
`
export type SetColonnadeRolePermissionMutationFn = ApolloReactCommon.MutationFunction<
  SetColonnadeRolePermissionMutation,
  SetColonnadeRolePermissionMutationVariables
>

/**
 * __useSetColonnadeRolePermissionMutation__
 *
 * To run a mutation, you first call `useSetColonnadeRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetColonnadeRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setColonnadeRolePermissionMutation, { data, loading, error }] = useSetColonnadeRolePermissionMutation({
 *   variables: {
 *      roleName: // value for 'roleName'
 *      privilege: // value for 'privilege'
 *   },
 * });
 */
export function useSetColonnadeRolePermissionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetColonnadeRolePermissionMutation,
    SetColonnadeRolePermissionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SetColonnadeRolePermissionMutation,
    SetColonnadeRolePermissionMutationVariables
  >(SetColonnadeRolePermissionDocument, baseOptions)
}
export type SetColonnadeRolePermissionMutationHookResult = ReturnType<
  typeof useSetColonnadeRolePermissionMutation
>
export type SetColonnadeRolePermissionMutationResult = ApolloReactCommon.MutationResult<SetColonnadeRolePermissionMutation>
export type SetColonnadeRolePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetColonnadeRolePermissionMutation,
  SetColonnadeRolePermissionMutationVariables
>
export const SetColSubsAnnivNotifDocument = gql`
  mutation setColSubsAnnivNotif($clientId: Int!, $statement: Boolean!) {
    setColSubsAnnivNotif(clientId: $clientId, statement: $statement)
  }
`
export type SetColSubsAnnivNotifMutationFn = ApolloReactCommon.MutationFunction<
  SetColSubsAnnivNotifMutation,
  SetColSubsAnnivNotifMutationVariables
>

/**
 * __useSetColSubsAnnivNotifMutation__
 *
 * To run a mutation, you first call `useSetColSubsAnnivNotifMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetColSubsAnnivNotifMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setColSubsAnnivNotifMutation, { data, loading, error }] = useSetColSubsAnnivNotifMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      statement: // value for 'statement'
 *   },
 * });
 */
export function useSetColSubsAnnivNotifMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetColSubsAnnivNotifMutation,
    SetColSubsAnnivNotifMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SetColSubsAnnivNotifMutation,
    SetColSubsAnnivNotifMutationVariables
  >(SetColSubsAnnivNotifDocument, baseOptions)
}
export type SetColSubsAnnivNotifMutationHookResult = ReturnType<
  typeof useSetColSubsAnnivNotifMutation
>
export type SetColSubsAnnivNotifMutationResult = ApolloReactCommon.MutationResult<SetColSubsAnnivNotifMutation>
export type SetColSubsAnnivNotifMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetColSubsAnnivNotifMutation,
  SetColSubsAnnivNotifMutationVariables
>
export const RequestColonnadeLicenseUpgradeDocument = gql`
  mutation requestColonnadeLicenseUpgrade(
    $clientName: String!
    $numberOfRequestedLicense: Int!
    $requesterEmail: String!
  ) {
    requestColonnadeLicenseUpgrade(
      clientName: $clientName
      numberOfRequestedLicense: $numberOfRequestedLicense
      requesterEmail: $requesterEmail
    )
  }
`
export type RequestColonnadeLicenseUpgradeMutationFn = ApolloReactCommon.MutationFunction<
  RequestColonnadeLicenseUpgradeMutation,
  RequestColonnadeLicenseUpgradeMutationVariables
>

/**
 * __useRequestColonnadeLicenseUpgradeMutation__
 *
 * To run a mutation, you first call `useRequestColonnadeLicenseUpgradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestColonnadeLicenseUpgradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestColonnadeLicenseUpgradeMutation, { data, loading, error }] = useRequestColonnadeLicenseUpgradeMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      numberOfRequestedLicense: // value for 'numberOfRequestedLicense'
 *      requesterEmail: // value for 'requesterEmail'
 *   },
 * });
 */
export function useRequestColonnadeLicenseUpgradeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RequestColonnadeLicenseUpgradeMutation,
    RequestColonnadeLicenseUpgradeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RequestColonnadeLicenseUpgradeMutation,
    RequestColonnadeLicenseUpgradeMutationVariables
  >(RequestColonnadeLicenseUpgradeDocument, baseOptions)
}
export type RequestColonnadeLicenseUpgradeMutationHookResult = ReturnType<
  typeof useRequestColonnadeLicenseUpgradeMutation
>
export type RequestColonnadeLicenseUpgradeMutationResult = ApolloReactCommon.MutationResult<RequestColonnadeLicenseUpgradeMutation>
export type RequestColonnadeLicenseUpgradeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RequestColonnadeLicenseUpgradeMutation,
  RequestColonnadeLicenseUpgradeMutationVariables
>
export const UpdateColonnadeSubscriptionDateDocument = gql`
  mutation updateColonnadeSubscriptionDate(
    $newSubscriptionDate: Date!
    $clientId: Int!
  ) {
    updateColonnadeSubscriptionDate(
      newSubscriptionDate: $newSubscriptionDate
      clientId: $clientId
    )
  }
`
export type UpdateColonnadeSubscriptionDateMutationFn = ApolloReactCommon.MutationFunction<
  UpdateColonnadeSubscriptionDateMutation,
  UpdateColonnadeSubscriptionDateMutationVariables
>

/**
 * __useUpdateColonnadeSubscriptionDateMutation__
 *
 * To run a mutation, you first call `useUpdateColonnadeSubscriptionDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateColonnadeSubscriptionDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateColonnadeSubscriptionDateMutation, { data, loading, error }] = useUpdateColonnadeSubscriptionDateMutation({
 *   variables: {
 *      newSubscriptionDate: // value for 'newSubscriptionDate'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useUpdateColonnadeSubscriptionDateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateColonnadeSubscriptionDateMutation,
    UpdateColonnadeSubscriptionDateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateColonnadeSubscriptionDateMutation,
    UpdateColonnadeSubscriptionDateMutationVariables
  >(UpdateColonnadeSubscriptionDateDocument, baseOptions)
}
export type UpdateColonnadeSubscriptionDateMutationHookResult = ReturnType<
  typeof useUpdateColonnadeSubscriptionDateMutation
>
export type UpdateColonnadeSubscriptionDateMutationResult = ApolloReactCommon.MutationResult<UpdateColonnadeSubscriptionDateMutation>
export type UpdateColonnadeSubscriptionDateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateColonnadeSubscriptionDateMutation,
  UpdateColonnadeSubscriptionDateMutationVariables
>
export const AuthenticateDocument = gql`
  query Authenticate($email: String!, $password: String!) {
    authenticate(email: $email, password: $password, source: "colonnade")
  }
`

/**
 * __useAuthenticateQuery__
 *
 * To run a query within a React component, call `useAuthenticateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthenticateQuery({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAuthenticateQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AuthenticateQuery,
    AuthenticateQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AuthenticateQuery,
    AuthenticateQueryVariables
  >(AuthenticateDocument, baseOptions)
}
export function useAuthenticateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AuthenticateQuery,
    AuthenticateQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AuthenticateQuery,
    AuthenticateQueryVariables
  >(AuthenticateDocument, baseOptions)
}
export type AuthenticateQueryHookResult = ReturnType<
  typeof useAuthenticateQuery
>
export type AuthenticateLazyQueryHookResult = ReturnType<
  typeof useAuthenticateLazyQuery
>
export type AuthenticateQueryResult = ApolloReactCommon.QueryResult<
  AuthenticateQuery,
  AuthenticateQueryVariables
>
export const IsExistingEmailDocument = gql`
  query isExistingEmail($email: String!) {
    isExistingEmail(email: $email)
  }
`

/**
 * __useIsExistingEmailQuery__
 *
 * To run a query within a React component, call `useIsExistingEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsExistingEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsExistingEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useIsExistingEmailQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IsExistingEmailQuery,
    IsExistingEmailQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IsExistingEmailQuery,
    IsExistingEmailQueryVariables
  >(IsExistingEmailDocument, baseOptions)
}
export function useIsExistingEmailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IsExistingEmailQuery,
    IsExistingEmailQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IsExistingEmailQuery,
    IsExistingEmailQueryVariables
  >(IsExistingEmailDocument, baseOptions)
}
export type IsExistingEmailQueryHookResult = ReturnType<
  typeof useIsExistingEmailQuery
>
export type IsExistingEmailLazyQueryHookResult = ReturnType<
  typeof useIsExistingEmailLazyQuery
>
export type IsExistingEmailQueryResult = ApolloReactCommon.QueryResult<
  IsExistingEmailQuery,
  IsExistingEmailQueryVariables
>
export const VerifyTokenDocument = gql`
  query VerifyToken($token: String!) {
    verifyToken(token: $token)
  }
`

/**
 * __useVerifyTokenQuery__
 *
 * To run a query within a React component, call `useVerifyTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyTokenQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    VerifyTokenQuery,
    VerifyTokenQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<VerifyTokenQuery, VerifyTokenQueryVariables>(
    VerifyTokenDocument,
    baseOptions
  )
}
export function useVerifyTokenLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    VerifyTokenQuery,
    VerifyTokenQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    VerifyTokenQuery,
    VerifyTokenQueryVariables
  >(VerifyTokenDocument, baseOptions)
}
export type VerifyTokenQueryHookResult = ReturnType<typeof useVerifyTokenQuery>
export type VerifyTokenLazyQueryHookResult = ReturnType<
  typeof useVerifyTokenLazyQuery
>
export type VerifyTokenQueryResult = ApolloReactCommon.QueryResult<
  VerifyTokenQuery,
  VerifyTokenQueryVariables
>
export const GetRolesDocument = gql`
  query GetRoles {
    colonnadeRoles {
      id
      role
    }
  }
`

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetRolesQuery,
    GetRolesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetRolesQuery, GetRolesQueryVariables>(
    GetRolesDocument,
    baseOptions
  )
}
export function useGetRolesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetRolesQuery,
    GetRolesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(
    GetRolesDocument,
    baseOptions
  )
}
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>
export type GetRolesLazyQueryHookResult = ReturnType<
  typeof useGetRolesLazyQuery
>
export type GetRolesQueryResult = ApolloReactCommon.QueryResult<
  GetRolesQuery,
  GetRolesQueryVariables
>
export const GetUsersDocument = gql`
  query GetUsers($clientName: String!) {
    colonnadeUsers(clientName: $clientName) {
      id
      firstName
      lastName
      email
      organizationRole
      status
      roleId
      licensed
    }
  }
`

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetUsersQuery,
    GetUsersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    baseOptions
  )
}
export function useGetUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetUsersQuery,
    GetUsersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    baseOptions
  )
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>
export type GetUsersLazyQueryHookResult = ReturnType<
  typeof useGetUsersLazyQuery
>
export type GetUsersQueryResult = ApolloReactCommon.QueryResult<
  GetUsersQuery,
  GetUsersQueryVariables
>
export const AddAgentsProspectDocument = gql`
  mutation addAgentsProspect(
    $prospectEmail: String!
    $agentIds: [Int!]!
    $clientName: String!
    $source: String!
  ) {
    colonnadeApiBatchAddAgentProspect(
      prospectEmail: $prospectEmail
      agentIds: $agentIds
      clientName: $clientName
      source: $source
    )
  }
`
export type AddAgentsProspectMutationFn = ApolloReactCommon.MutationFunction<
  AddAgentsProspectMutation,
  AddAgentsProspectMutationVariables
>

/**
 * __useAddAgentsProspectMutation__
 *
 * To run a mutation, you first call `useAddAgentsProspectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAgentsProspectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAgentsProspectMutation, { data, loading, error }] = useAddAgentsProspectMutation({
 *   variables: {
 *      prospectEmail: // value for 'prospectEmail'
 *      agentIds: // value for 'agentIds'
 *      clientName: // value for 'clientName'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useAddAgentsProspectMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddAgentsProspectMutation,
    AddAgentsProspectMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddAgentsProspectMutation,
    AddAgentsProspectMutationVariables
  >(AddAgentsProspectDocument, baseOptions)
}
export type AddAgentsProspectMutationHookResult = ReturnType<
  typeof useAddAgentsProspectMutation
>
export type AddAgentsProspectMutationResult = ApolloReactCommon.MutationResult<AddAgentsProspectMutation>
export type AddAgentsProspectMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddAgentsProspectMutation,
  AddAgentsProspectMutationVariables
>
export const AddColonnadeUserDocument = gql`
  mutation ADD_COLONNADE_USER(
    $clientName: String!
    $input: AddColonnadeUserInput!
  ) {
    addColonnadeUser(clientName: $clientName, input: $input)
  }
`
export type AddColonnadeUserMutationFn = ApolloReactCommon.MutationFunction<
  AddColonnadeUserMutation,
  AddColonnadeUserMutationVariables
>

/**
 * __useAddColonnadeUserMutation__
 *
 * To run a mutation, you first call `useAddColonnadeUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddColonnadeUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addColonnadeUserMutation, { data, loading, error }] = useAddColonnadeUserMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddColonnadeUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddColonnadeUserMutation,
    AddColonnadeUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddColonnadeUserMutation,
    AddColonnadeUserMutationVariables
  >(AddColonnadeUserDocument, baseOptions)
}
export type AddColonnadeUserMutationHookResult = ReturnType<
  typeof useAddColonnadeUserMutation
>
export type AddColonnadeUserMutationResult = ApolloReactCommon.MutationResult<AddColonnadeUserMutation>
export type AddColonnadeUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddColonnadeUserMutation,
  AddColonnadeUserMutationVariables
>
export const AddProspectActivitiesDocument = gql`
  mutation addProspectActivities(
    $clientName: String!
    $email: String!
    $activities: JSON!
  ) {
    addProspectActivities(
      clientName: $clientName
      email: $email
      activities: $activities
    )
  }
`
export type AddProspectActivitiesMutationFn = ApolloReactCommon.MutationFunction<
  AddProspectActivitiesMutation,
  AddProspectActivitiesMutationVariables
>

/**
 * __useAddProspectActivitiesMutation__
 *
 * To run a mutation, you first call `useAddProspectActivitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProspectActivitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProspectActivitiesMutation, { data, loading, error }] = useAddProspectActivitiesMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      email: // value for 'email'
 *      activities: // value for 'activities'
 *   },
 * });
 */
export function useAddProspectActivitiesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddProspectActivitiesMutation,
    AddProspectActivitiesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddProspectActivitiesMutation,
    AddProspectActivitiesMutationVariables
  >(AddProspectActivitiesDocument, baseOptions)
}
export type AddProspectActivitiesMutationHookResult = ReturnType<
  typeof useAddProspectActivitiesMutation
>
export type AddProspectActivitiesMutationResult = ApolloReactCommon.MutationResult<AddProspectActivitiesMutation>
export type AddProspectActivitiesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddProspectActivitiesMutation,
  AddProspectActivitiesMutationVariables
>
export const AssignProspectToColonnadeAgentDocument = gql`
  mutation AssignProspectToColonnadeAgent(
    $clientName: String!
    $assignments: [ColonnadeProspectAssignment!]!
  ) {
    assignProspectToColonnadeAgent(
      clientName: $clientName
      assignments: $assignments
    )
  }
`
export type AssignProspectToColonnadeAgentMutationFn = ApolloReactCommon.MutationFunction<
  AssignProspectToColonnadeAgentMutation,
  AssignProspectToColonnadeAgentMutationVariables
>

/**
 * __useAssignProspectToColonnadeAgentMutation__
 *
 * To run a mutation, you first call `useAssignProspectToColonnadeAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignProspectToColonnadeAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignProspectToColonnadeAgentMutation, { data, loading, error }] = useAssignProspectToColonnadeAgentMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      assignments: // value for 'assignments'
 *   },
 * });
 */
export function useAssignProspectToColonnadeAgentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AssignProspectToColonnadeAgentMutation,
    AssignProspectToColonnadeAgentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AssignProspectToColonnadeAgentMutation,
    AssignProspectToColonnadeAgentMutationVariables
  >(AssignProspectToColonnadeAgentDocument, baseOptions)
}
export type AssignProspectToColonnadeAgentMutationHookResult = ReturnType<
  typeof useAssignProspectToColonnadeAgentMutation
>
export type AssignProspectToColonnadeAgentMutationResult = ApolloReactCommon.MutationResult<AssignProspectToColonnadeAgentMutation>
export type AssignProspectToColonnadeAgentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AssignProspectToColonnadeAgentMutation,
  AssignProspectToColonnadeAgentMutationVariables
>
export const UnassignProspectFromColonnadeAgentDocument = gql`
  mutation UnassignProspectFromColonnadeAgent(
    $clientName: String!
    $unassignments: [ColonnadeProspectAssignment!]!
  ) {
    unassignProspectFromColonnadeAgent(
      clientName: $clientName
      unassignments: $unassignments
    )
  }
`
export type UnassignProspectFromColonnadeAgentMutationFn = ApolloReactCommon.MutationFunction<
  UnassignProspectFromColonnadeAgentMutation,
  UnassignProspectFromColonnadeAgentMutationVariables
>

/**
 * __useUnassignProspectFromColonnadeAgentMutation__
 *
 * To run a mutation, you first call `useUnassignProspectFromColonnadeAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignProspectFromColonnadeAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignProspectFromColonnadeAgentMutation, { data, loading, error }] = useUnassignProspectFromColonnadeAgentMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      unassignments: // value for 'unassignments'
 *   },
 * });
 */
export function useUnassignProspectFromColonnadeAgentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UnassignProspectFromColonnadeAgentMutation,
    UnassignProspectFromColonnadeAgentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UnassignProspectFromColonnadeAgentMutation,
    UnassignProspectFromColonnadeAgentMutationVariables
  >(UnassignProspectFromColonnadeAgentDocument, baseOptions)
}
export type UnassignProspectFromColonnadeAgentMutationHookResult = ReturnType<
  typeof useUnassignProspectFromColonnadeAgentMutation
>
export type UnassignProspectFromColonnadeAgentMutationResult = ApolloReactCommon.MutationResult<UnassignProspectFromColonnadeAgentMutation>
export type UnassignProspectFromColonnadeAgentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UnassignProspectFromColonnadeAgentMutation,
  UnassignProspectFromColonnadeAgentMutationVariables
>
export const ImportProspectsToColonnadeAgentsDocument = gql`
  mutation ImportProspectsToColonnadeAgents(
    $clientName: String!
    $assignments: [ColonnadeAgentImport!]!
  ) {
    importProspectsToColonnadeAgents(
      clientName: $clientName
      assignments: $assignments
    )
  }
`
export type ImportProspectsToColonnadeAgentsMutationFn = ApolloReactCommon.MutationFunction<
  ImportProspectsToColonnadeAgentsMutation,
  ImportProspectsToColonnadeAgentsMutationVariables
>

/**
 * __useImportProspectsToColonnadeAgentsMutation__
 *
 * To run a mutation, you first call `useImportProspectsToColonnadeAgentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportProspectsToColonnadeAgentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importProspectsToColonnadeAgentsMutation, { data, loading, error }] = useImportProspectsToColonnadeAgentsMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      assignments: // value for 'assignments'
 *   },
 * });
 */
export function useImportProspectsToColonnadeAgentsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ImportProspectsToColonnadeAgentsMutation,
    ImportProspectsToColonnadeAgentsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ImportProspectsToColonnadeAgentsMutation,
    ImportProspectsToColonnadeAgentsMutationVariables
  >(ImportProspectsToColonnadeAgentsDocument, baseOptions)
}
export type ImportProspectsToColonnadeAgentsMutationHookResult = ReturnType<
  typeof useImportProspectsToColonnadeAgentsMutation
>
export type ImportProspectsToColonnadeAgentsMutationResult = ApolloReactCommon.MutationResult<ImportProspectsToColonnadeAgentsMutation>
export type ImportProspectsToColonnadeAgentsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ImportProspectsToColonnadeAgentsMutation,
  ImportProspectsToColonnadeAgentsMutationVariables
>
export const CreateEmailCampaignDocument = gql`
  mutation createEmailCampaign(
    $clientName: String!
    $emailCampaign: EmailCampaignInput!
    $cohort: [Int!]
  ) {
    createEmailCampaign(
      clientName: $clientName
      emailCampaign: $emailCampaign
      cohort: $cohort
    ) {
      id
      communityId
      planId
      elevationId
      lotId
      inventoryId
      ownerId
      templateId
      name
      mainImage
      replyTo
      replyToName
      subject
      body
      signature
      active
      createdAt
      lastUpdated
      cohort(clientName: $clientName) {
        id
        prospectId
        status
        active
        prospect(clientName: $clientName) {
          id
          status
          registrationDate
          name
          firstName
          lastName
          email
        }
      }
      sections(clientName: $clientName) {
        id
        content
        index
        images(clientName: $clientName) {
          id
          emailCampaignSectionId
          index
          src
        }
      }
    }
  }
`
export type CreateEmailCampaignMutationFn = ApolloReactCommon.MutationFunction<
  CreateEmailCampaignMutation,
  CreateEmailCampaignMutationVariables
>

/**
 * __useCreateEmailCampaignMutation__
 *
 * To run a mutation, you first call `useCreateEmailCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmailCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmailCampaignMutation, { data, loading, error }] = useCreateEmailCampaignMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      emailCampaign: // value for 'emailCampaign'
 *      cohort: // value for 'cohort'
 *   },
 * });
 */
export function useCreateEmailCampaignMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateEmailCampaignMutation,
    CreateEmailCampaignMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateEmailCampaignMutation,
    CreateEmailCampaignMutationVariables
  >(CreateEmailCampaignDocument, baseOptions)
}
export type CreateEmailCampaignMutationHookResult = ReturnType<
  typeof useCreateEmailCampaignMutation
>
export type CreateEmailCampaignMutationResult = ApolloReactCommon.MutationResult<CreateEmailCampaignMutation>
export type CreateEmailCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateEmailCampaignMutation,
  CreateEmailCampaignMutationVariables
>
export const DeleteColonnadeUserDocument = gql`
  mutation DELETE_COLONNADE_USER($callerUserId: Int!, $userId: Int!) {
    deleteColonnadeUser(callerUserId: $callerUserId, userId: $userId)
  }
`
export type DeleteColonnadeUserMutationFn = ApolloReactCommon.MutationFunction<
  DeleteColonnadeUserMutation,
  DeleteColonnadeUserMutationVariables
>

/**
 * __useDeleteColonnadeUserMutation__
 *
 * To run a mutation, you first call `useDeleteColonnadeUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteColonnadeUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteColonnadeUserMutation, { data, loading, error }] = useDeleteColonnadeUserMutation({
 *   variables: {
 *      callerUserId: // value for 'callerUserId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteColonnadeUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteColonnadeUserMutation,
    DeleteColonnadeUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteColonnadeUserMutation,
    DeleteColonnadeUserMutationVariables
  >(DeleteColonnadeUserDocument, baseOptions)
}
export type DeleteColonnadeUserMutationHookResult = ReturnType<
  typeof useDeleteColonnadeUserMutation
>
export type DeleteColonnadeUserMutationResult = ApolloReactCommon.MutationResult<DeleteColonnadeUserMutation>
export type DeleteColonnadeUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteColonnadeUserMutation,
  DeleteColonnadeUserMutationVariables
>
export const DeleteEmailCampaignDocument = gql`
  mutation deleteEmailCampaign($clientName: String!, $emailCampaignId: Int!) {
    deleteEmailCampaign(
      clientName: $clientName
      emailCampaignId: $emailCampaignId
    )
  }
`
export type DeleteEmailCampaignMutationFn = ApolloReactCommon.MutationFunction<
  DeleteEmailCampaignMutation,
  DeleteEmailCampaignMutationVariables
>

/**
 * __useDeleteEmailCampaignMutation__
 *
 * To run a mutation, you first call `useDeleteEmailCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmailCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmailCampaignMutation, { data, loading, error }] = useDeleteEmailCampaignMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      emailCampaignId: // value for 'emailCampaignId'
 *   },
 * });
 */
export function useDeleteEmailCampaignMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteEmailCampaignMutation,
    DeleteEmailCampaignMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteEmailCampaignMutation,
    DeleteEmailCampaignMutationVariables
  >(DeleteEmailCampaignDocument, baseOptions)
}
export type DeleteEmailCampaignMutationHookResult = ReturnType<
  typeof useDeleteEmailCampaignMutation
>
export type DeleteEmailCampaignMutationResult = ApolloReactCommon.MutationResult<DeleteEmailCampaignMutation>
export type DeleteEmailCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteEmailCampaignMutation,
  DeleteEmailCampaignMutationVariables
>
export const DeleteEmailCampaignSectionImageDocument = gql`
  mutation deleteEmailCampaignSectionImage(
    $clientName: String!
    $emailCampaignSectionImageId: Int!
  ) {
    deleteEmailCampaignSectionImage(
      clientName: $clientName
      emailCampaignSectionImageId: $emailCampaignSectionImageId
    ) {
      id
      content
      index
      images(clientName: $clientName) {
        id
        emailCampaignSectionId
        index
        src
      }
    }
  }
`
export type DeleteEmailCampaignSectionImageMutationFn = ApolloReactCommon.MutationFunction<
  DeleteEmailCampaignSectionImageMutation,
  DeleteEmailCampaignSectionImageMutationVariables
>

/**
 * __useDeleteEmailCampaignSectionImageMutation__
 *
 * To run a mutation, you first call `useDeleteEmailCampaignSectionImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmailCampaignSectionImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmailCampaignSectionImageMutation, { data, loading, error }] = useDeleteEmailCampaignSectionImageMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      emailCampaignSectionImageId: // value for 'emailCampaignSectionImageId'
 *   },
 * });
 */
export function useDeleteEmailCampaignSectionImageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteEmailCampaignSectionImageMutation,
    DeleteEmailCampaignSectionImageMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteEmailCampaignSectionImageMutation,
    DeleteEmailCampaignSectionImageMutationVariables
  >(DeleteEmailCampaignSectionImageDocument, baseOptions)
}
export type DeleteEmailCampaignSectionImageMutationHookResult = ReturnType<
  typeof useDeleteEmailCampaignSectionImageMutation
>
export type DeleteEmailCampaignSectionImageMutationResult = ApolloReactCommon.MutationResult<DeleteEmailCampaignSectionImageMutation>
export type DeleteEmailCampaignSectionImageMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteEmailCampaignSectionImageMutation,
  DeleteEmailCampaignSectionImageMutationVariables
>
export const AddExcludedIpDocument = gql`
  mutation addExcludedIp(
    $clientName: String!
    $ips: [String]!
    $ipCidrNotation: String!
  ) {
    addExcludedIp(
      clientName: $clientName
      ips: $ips
      ipCidrNotation: $ipCidrNotation
    )
  }
`
export type AddExcludedIpMutationFn = ApolloReactCommon.MutationFunction<
  AddExcludedIpMutation,
  AddExcludedIpMutationVariables
>

/**
 * __useAddExcludedIpMutation__
 *
 * To run a mutation, you first call `useAddExcludedIpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddExcludedIpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addExcludedIpMutation, { data, loading, error }] = useAddExcludedIpMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      ips: // value for 'ips'
 *      ipCidrNotation: // value for 'ipCidrNotation'
 *   },
 * });
 */
export function useAddExcludedIpMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddExcludedIpMutation,
    AddExcludedIpMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddExcludedIpMutation,
    AddExcludedIpMutationVariables
  >(AddExcludedIpDocument, baseOptions)
}
export type AddExcludedIpMutationHookResult = ReturnType<
  typeof useAddExcludedIpMutation
>
export type AddExcludedIpMutationResult = ApolloReactCommon.MutationResult<AddExcludedIpMutation>
export type AddExcludedIpMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddExcludedIpMutation,
  AddExcludedIpMutationVariables
>
export const RemoveExcludedIpDocument = gql`
  mutation removeExcludedIp($clientName: String!, $ipCidrNotation: String!) {
    removeExcludedIp(clientName: $clientName, ipCidrNotation: $ipCidrNotation)
  }
`
export type RemoveExcludedIpMutationFn = ApolloReactCommon.MutationFunction<
  RemoveExcludedIpMutation,
  RemoveExcludedIpMutationVariables
>

/**
 * __useRemoveExcludedIpMutation__
 *
 * To run a mutation, you first call `useRemoveExcludedIpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveExcludedIpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeExcludedIpMutation, { data, loading, error }] = useRemoveExcludedIpMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      ipCidrNotation: // value for 'ipCidrNotation'
 *   },
 * });
 */
export function useRemoveExcludedIpMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveExcludedIpMutation,
    RemoveExcludedIpMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RemoveExcludedIpMutation,
    RemoveExcludedIpMutationVariables
  >(RemoveExcludedIpDocument, baseOptions)
}
export type RemoveExcludedIpMutationHookResult = ReturnType<
  typeof useRemoveExcludedIpMutation
>
export type RemoveExcludedIpMutationResult = ApolloReactCommon.MutationResult<RemoveExcludedIpMutation>
export type RemoveExcludedIpMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveExcludedIpMutation,
  RemoveExcludedIpMutationVariables
>
export const ImportAgentsDocument = gql`
  mutation IMPORT_AGENTS($clientName: String!, $input: [ImportAgentInput!]!) {
    importAgentsAsColonnadeUsers(clientName: $clientName, input: $input) {
      __typename
      ... on ImportAgentSuccess {
        agentId
        user {
          id
          roleId
          firstName
          lastName
          organizationRole
          email
          status
          licensed
        }
      }
      ... on ImportAgentError {
        agentId
        message
      }
    }
  }
`
export type ImportAgentsMutationFn = ApolloReactCommon.MutationFunction<
  ImportAgentsMutation,
  ImportAgentsMutationVariables
>

/**
 * __useImportAgentsMutation__
 *
 * To run a mutation, you first call `useImportAgentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportAgentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importAgentsMutation, { data, loading, error }] = useImportAgentsMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportAgentsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ImportAgentsMutation,
    ImportAgentsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ImportAgentsMutation,
    ImportAgentsMutationVariables
  >(ImportAgentsDocument, baseOptions)
}
export type ImportAgentsMutationHookResult = ReturnType<
  typeof useImportAgentsMutation
>
export type ImportAgentsMutationResult = ApolloReactCommon.MutationResult<ImportAgentsMutation>
export type ImportAgentsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ImportAgentsMutation,
  ImportAgentsMutationVariables
>
export const RequestSubscriptionUpgradeDocument = gql`
  mutation REQUEST_SUBSCRIPTION_UPGRADE(
    $callerUserId: Int!
    $requestedSubscriptionLevel: ColonnadeSubscriptionLevelsEnum!
  ) {
    requestSubscriptionUpgrade(
      callerUserId: $callerUserId
      requestedSubscriptionLevel: $requestedSubscriptionLevel
    )
  }
`
export type RequestSubscriptionUpgradeMutationFn = ApolloReactCommon.MutationFunction<
  RequestSubscriptionUpgradeMutation,
  RequestSubscriptionUpgradeMutationVariables
>

/**
 * __useRequestSubscriptionUpgradeMutation__
 *
 * To run a mutation, you first call `useRequestSubscriptionUpgradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestSubscriptionUpgradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestSubscriptionUpgradeMutation, { data, loading, error }] = useRequestSubscriptionUpgradeMutation({
 *   variables: {
 *      callerUserId: // value for 'callerUserId'
 *      requestedSubscriptionLevel: // value for 'requestedSubscriptionLevel'
 *   },
 * });
 */
export function useRequestSubscriptionUpgradeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RequestSubscriptionUpgradeMutation,
    RequestSubscriptionUpgradeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RequestSubscriptionUpgradeMutation,
    RequestSubscriptionUpgradeMutationVariables
  >(RequestSubscriptionUpgradeDocument, baseOptions)
}
export type RequestSubscriptionUpgradeMutationHookResult = ReturnType<
  typeof useRequestSubscriptionUpgradeMutation
>
export type RequestSubscriptionUpgradeMutationResult = ApolloReactCommon.MutationResult<RequestSubscriptionUpgradeMutation>
export type RequestSubscriptionUpgradeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RequestSubscriptionUpgradeMutation,
  RequestSubscriptionUpgradeMutationVariables
>
export const SendPasswordResetEmailDocument = gql`
  mutation sendPasswordResetEmail($email: String!, $source: String) {
    sendPasswordResetEmail(email: $email, source: $source)
  }
`
export type SendPasswordResetEmailMutationFn = ApolloReactCommon.MutationFunction<
  SendPasswordResetEmailMutation,
  SendPasswordResetEmailMutationVariables
>

/**
 * __useSendPasswordResetEmailMutation__
 *
 * To run a mutation, you first call `useSendPasswordResetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordResetEmailMutation, { data, loading, error }] = useSendPasswordResetEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useSendPasswordResetEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendPasswordResetEmailMutation,
    SendPasswordResetEmailMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SendPasswordResetEmailMutation,
    SendPasswordResetEmailMutationVariables
  >(SendPasswordResetEmailDocument, baseOptions)
}
export type SendPasswordResetEmailMutationHookResult = ReturnType<
  typeof useSendPasswordResetEmailMutation
>
export type SendPasswordResetEmailMutationResult = ApolloReactCommon.MutationResult<SendPasswordResetEmailMutation>
export type SendPasswordResetEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendPasswordResetEmailMutation,
  SendPasswordResetEmailMutationVariables
>
export const SetPasswordDocument = gql`
  mutation setPassword(
    $email: String!
    $password: String!
    $clientName: String!
    $source: String
  ) {
    setPassword(
      email: $email
      password: $password
      clientName: $clientName
      source: $source
    )
  }
`
export type SetPasswordMutationFn = ApolloReactCommon.MutationFunction<
  SetPasswordMutation,
  SetPasswordMutationVariables
>

/**
 * __useSetPasswordMutation__
 *
 * To run a mutation, you first call `useSetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPasswordMutation, { data, loading, error }] = useSetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      clientName: // value for 'clientName'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useSetPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetPasswordMutation,
    SetPasswordMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SetPasswordMutation,
    SetPasswordMutationVariables
  >(SetPasswordDocument, baseOptions)
}
export type SetPasswordMutationHookResult = ReturnType<
  typeof useSetPasswordMutation
>
export type SetPasswordMutationResult = ApolloReactCommon.MutationResult<SetPasswordMutation>
export type SetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetPasswordMutation,
  SetPasswordMutationVariables
>
export const RestoreDeletedOnlineReservationDocument = gql`
  mutation RESTORE_DELETED_ONLINE_RESERVATION(
    $clientName: String!
    $reservationId: UUID!
  ) {
    restoreDeletedOnlineReservation(
      clientName: $clientName
      reservationId: $reservationId
    )
  }
`
export type RestoreDeletedOnlineReservationMutationFn = ApolloReactCommon.MutationFunction<
  RestoreDeletedOnlineReservationMutation,
  RestoreDeletedOnlineReservationMutationVariables
>

/**
 * __useRestoreDeletedOnlineReservationMutation__
 *
 * To run a mutation, you first call `useRestoreDeletedOnlineReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreDeletedOnlineReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreDeletedOnlineReservationMutation, { data, loading, error }] = useRestoreDeletedOnlineReservationMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useRestoreDeletedOnlineReservationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RestoreDeletedOnlineReservationMutation,
    RestoreDeletedOnlineReservationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RestoreDeletedOnlineReservationMutation,
    RestoreDeletedOnlineReservationMutationVariables
  >(RestoreDeletedOnlineReservationDocument, baseOptions)
}
export type RestoreDeletedOnlineReservationMutationHookResult = ReturnType<
  typeof useRestoreDeletedOnlineReservationMutation
>
export type RestoreDeletedOnlineReservationMutationResult = ApolloReactCommon.MutationResult<RestoreDeletedOnlineReservationMutation>
export type RestoreDeletedOnlineReservationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RestoreDeletedOnlineReservationMutation,
  RestoreDeletedOnlineReservationMutationVariables
>
export const SendForgotEmailNotifRetrieveDocument = gql`
  mutation sendForgotEmailNotifRetrieve(
    $firstName: String!
    $lastName: String!
    $backUpEmail: String!
    $clientName: String!
    $email: String!
  ) {
    sendForgotEmailNotifRetrieve(
      firstName: $firstName
      lastName: $lastName
      backUpEmail: $backUpEmail
      email: $email
      clientName: $clientName
    )
  }
`
export type SendForgotEmailNotifRetrieveMutationFn = ApolloReactCommon.MutationFunction<
  SendForgotEmailNotifRetrieveMutation,
  SendForgotEmailNotifRetrieveMutationVariables
>

/**
 * __useSendForgotEmailNotifRetrieveMutation__
 *
 * To run a mutation, you first call `useSendForgotEmailNotifRetrieveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendForgotEmailNotifRetrieveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendForgotEmailNotifRetrieveMutation, { data, loading, error }] = useSendForgotEmailNotifRetrieveMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      backUpEmail: // value for 'backUpEmail'
 *      clientName: // value for 'clientName'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendForgotEmailNotifRetrieveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendForgotEmailNotifRetrieveMutation,
    SendForgotEmailNotifRetrieveMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SendForgotEmailNotifRetrieveMutation,
    SendForgotEmailNotifRetrieveMutationVariables
  >(SendForgotEmailNotifRetrieveDocument, baseOptions)
}
export type SendForgotEmailNotifRetrieveMutationHookResult = ReturnType<
  typeof useSendForgotEmailNotifRetrieveMutation
>
export type SendForgotEmailNotifRetrieveMutationResult = ApolloReactCommon.MutationResult<SendForgotEmailNotifRetrieveMutation>
export type SendForgotEmailNotifRetrieveMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendForgotEmailNotifRetrieveMutation,
  SendForgotEmailNotifRetrieveMutationVariables
>
export const SendForgotEmailNotifToAdminDocument = gql`
  mutation sendForgotEmailNotifToAdmin(
    $firstName: String!
    $lastName: String!
    $backUpEmail: String!
  ) {
    sendForgotEmailNotifToAdmin(
      firstName: $firstName
      lastName: $lastName
      backUpEmail: $backUpEmail
    )
  }
`
export type SendForgotEmailNotifToAdminMutationFn = ApolloReactCommon.MutationFunction<
  SendForgotEmailNotifToAdminMutation,
  SendForgotEmailNotifToAdminMutationVariables
>

/**
 * __useSendForgotEmailNotifToAdminMutation__
 *
 * To run a mutation, you first call `useSendForgotEmailNotifToAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendForgotEmailNotifToAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendForgotEmailNotifToAdminMutation, { data, loading, error }] = useSendForgotEmailNotifToAdminMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      backUpEmail: // value for 'backUpEmail'
 *   },
 * });
 */
export function useSendForgotEmailNotifToAdminMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendForgotEmailNotifToAdminMutation,
    SendForgotEmailNotifToAdminMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SendForgotEmailNotifToAdminMutation,
    SendForgotEmailNotifToAdminMutationVariables
  >(SendForgotEmailNotifToAdminDocument, baseOptions)
}
export type SendForgotEmailNotifToAdminMutationHookResult = ReturnType<
  typeof useSendForgotEmailNotifToAdminMutation
>
export type SendForgotEmailNotifToAdminMutationResult = ApolloReactCommon.MutationResult<SendForgotEmailNotifToAdminMutation>
export type SendForgotEmailNotifToAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendForgotEmailNotifToAdminMutation,
  SendForgotEmailNotifToAdminMutationVariables
>
export const SendEmailCampaignDocument = gql`
  mutation sendEmailCampaign(
    $clientName: String!
    $emailCampaignId: Int!
    $ownerId: Int!
    $testEmailAddress: String
  ) {
    sendEmailCampaign(
      clientName: $clientName
      emailCampaignId: $emailCampaignId
      ownerId: $ownerId
      testEmailAddress: $testEmailAddress
    )
  }
`
export type SendEmailCampaignMutationFn = ApolloReactCommon.MutationFunction<
  SendEmailCampaignMutation,
  SendEmailCampaignMutationVariables
>

/**
 * __useSendEmailCampaignMutation__
 *
 * To run a mutation, you first call `useSendEmailCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailCampaignMutation, { data, loading, error }] = useSendEmailCampaignMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      emailCampaignId: // value for 'emailCampaignId'
 *      ownerId: // value for 'ownerId'
 *      testEmailAddress: // value for 'testEmailAddress'
 *   },
 * });
 */
export function useSendEmailCampaignMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendEmailCampaignMutation,
    SendEmailCampaignMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SendEmailCampaignMutation,
    SendEmailCampaignMutationVariables
  >(SendEmailCampaignDocument, baseOptions)
}
export type SendEmailCampaignMutationHookResult = ReturnType<
  typeof useSendEmailCampaignMutation
>
export type SendEmailCampaignMutationResult = ApolloReactCommon.MutationResult<SendEmailCampaignMutation>
export type SendEmailCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendEmailCampaignMutation,
  SendEmailCampaignMutationVariables
>
export const SendFollowUpEmailDocument = gql`
  mutation sendFollowUpEmail(
    $clientName: String!
    $emailCampaignId: Int!
    $ownerId: Int!
    $emails: [String!]
    $content: String!
  ) {
    sendFollowUpEmail(
      clientName: $clientName
      emailCampaignId: $emailCampaignId
      ownerId: $ownerId
      emails: $emails
      content: $content
    )
  }
`
export type SendFollowUpEmailMutationFn = ApolloReactCommon.MutationFunction<
  SendFollowUpEmailMutation,
  SendFollowUpEmailMutationVariables
>

/**
 * __useSendFollowUpEmailMutation__
 *
 * To run a mutation, you first call `useSendFollowUpEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendFollowUpEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendFollowUpEmailMutation, { data, loading, error }] = useSendFollowUpEmailMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      emailCampaignId: // value for 'emailCampaignId'
 *      ownerId: // value for 'ownerId'
 *      emails: // value for 'emails'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useSendFollowUpEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendFollowUpEmailMutation,
    SendFollowUpEmailMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SendFollowUpEmailMutation,
    SendFollowUpEmailMutationVariables
  >(SendFollowUpEmailDocument, baseOptions)
}
export type SendFollowUpEmailMutationHookResult = ReturnType<
  typeof useSendFollowUpEmailMutation
>
export type SendFollowUpEmailMutationResult = ApolloReactCommon.MutationResult<SendFollowUpEmailMutation>
export type SendFollowUpEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendFollowUpEmailMutation,
  SendFollowUpEmailMutationVariables
>
export const SendInsightsMarketingAutomationInquiryDocument = gql`
  mutation sendInsightsMarketingAutomationInquiry(
    $clientName: String!
    $userEmail: EmailAddress!
    $userPhone: String
  ) {
    sendInsightsMarketingAutomationInquiry(
      clientName: $clientName
      userEmail: $userEmail
      userPhone: $userPhone
    )
  }
`
export type SendInsightsMarketingAutomationInquiryMutationFn = ApolloReactCommon.MutationFunction<
  SendInsightsMarketingAutomationInquiryMutation,
  SendInsightsMarketingAutomationInquiryMutationVariables
>

/**
 * __useSendInsightsMarketingAutomationInquiryMutation__
 *
 * To run a mutation, you first call `useSendInsightsMarketingAutomationInquiryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInsightsMarketingAutomationInquiryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInsightsMarketingAutomationInquiryMutation, { data, loading, error }] = useSendInsightsMarketingAutomationInquiryMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      userEmail: // value for 'userEmail'
 *      userPhone: // value for 'userPhone'
 *   },
 * });
 */
export function useSendInsightsMarketingAutomationInquiryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendInsightsMarketingAutomationInquiryMutation,
    SendInsightsMarketingAutomationInquiryMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SendInsightsMarketingAutomationInquiryMutation,
    SendInsightsMarketingAutomationInquiryMutationVariables
  >(SendInsightsMarketingAutomationInquiryDocument, baseOptions)
}
export type SendInsightsMarketingAutomationInquiryMutationHookResult = ReturnType<
  typeof useSendInsightsMarketingAutomationInquiryMutation
>
export type SendInsightsMarketingAutomationInquiryMutationResult = ApolloReactCommon.MutationResult<SendInsightsMarketingAutomationInquiryMutation>
export type SendInsightsMarketingAutomationInquiryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendInsightsMarketingAutomationInquiryMutation,
  SendInsightsMarketingAutomationInquiryMutationVariables
>
export const SendInsightsOnlineReservationInquiryDocument = gql`
  mutation sendInsightsOnlineReservationInquiry(
    $clientName: String!
    $userEmail: EmailAddress!
    $userPhone: String
  ) {
    sendInsightsOnlineReservationInquiry(
      clientName: $clientName
      userEmail: $userEmail
      userPhone: $userPhone
    )
  }
`
export type SendInsightsOnlineReservationInquiryMutationFn = ApolloReactCommon.MutationFunction<
  SendInsightsOnlineReservationInquiryMutation,
  SendInsightsOnlineReservationInquiryMutationVariables
>

/**
 * __useSendInsightsOnlineReservationInquiryMutation__
 *
 * To run a mutation, you first call `useSendInsightsOnlineReservationInquiryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInsightsOnlineReservationInquiryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInsightsOnlineReservationInquiryMutation, { data, loading, error }] = useSendInsightsOnlineReservationInquiryMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      userEmail: // value for 'userEmail'
 *      userPhone: // value for 'userPhone'
 *   },
 * });
 */
export function useSendInsightsOnlineReservationInquiryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendInsightsOnlineReservationInquiryMutation,
    SendInsightsOnlineReservationInquiryMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SendInsightsOnlineReservationInquiryMutation,
    SendInsightsOnlineReservationInquiryMutationVariables
  >(SendInsightsOnlineReservationInquiryDocument, baseOptions)
}
export type SendInsightsOnlineReservationInquiryMutationHookResult = ReturnType<
  typeof useSendInsightsOnlineReservationInquiryMutation
>
export type SendInsightsOnlineReservationInquiryMutationResult = ApolloReactCommon.MutationResult<SendInsightsOnlineReservationInquiryMutation>
export type SendInsightsOnlineReservationInquiryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendInsightsOnlineReservationInquiryMutation,
  SendInsightsOnlineReservationInquiryMutationVariables
>
export const SendRecommendedPlansEmailDocument = gql`
  mutation sendRecommendedPlansEmail(
    $clientName: String!
    $prospectName: String!
    $prospectEmail: String!
    $agentEmail: String!
    $agentName: String!
    $plans: [RecommendedPlan!]!
  ) {
    sendRecommendedPlansEmail(
      clientName: $clientName
      prospectName: $prospectName
      prospectEmail: $prospectEmail
      agentEmail: $agentEmail
      agentName: $agentName
      plans: $plans
    )
  }
`
export type SendRecommendedPlansEmailMutationFn = ApolloReactCommon.MutationFunction<
  SendRecommendedPlansEmailMutation,
  SendRecommendedPlansEmailMutationVariables
>

/**
 * __useSendRecommendedPlansEmailMutation__
 *
 * To run a mutation, you first call `useSendRecommendedPlansEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendRecommendedPlansEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendRecommendedPlansEmailMutation, { data, loading, error }] = useSendRecommendedPlansEmailMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      prospectName: // value for 'prospectName'
 *      prospectEmail: // value for 'prospectEmail'
 *      agentEmail: // value for 'agentEmail'
 *      agentName: // value for 'agentName'
 *      plans: // value for 'plans'
 *   },
 * });
 */
export function useSendRecommendedPlansEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendRecommendedPlansEmailMutation,
    SendRecommendedPlansEmailMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SendRecommendedPlansEmailMutation,
    SendRecommendedPlansEmailMutationVariables
  >(SendRecommendedPlansEmailDocument, baseOptions)
}
export type SendRecommendedPlansEmailMutationHookResult = ReturnType<
  typeof useSendRecommendedPlansEmailMutation
>
export type SendRecommendedPlansEmailMutationResult = ApolloReactCommon.MutationResult<SendRecommendedPlansEmailMutation>
export type SendRecommendedPlansEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendRecommendedPlansEmailMutation,
  SendRecommendedPlansEmailMutationVariables
>
export const SetLeadBrowseAgentNotificationConfigDocument = gql`
  mutation setLeadBrowseAgentNotificationConfig(
    $clientName: String!
    $enabled: Boolean!
    $frequency: Int!
  ) {
    setLeadBrowseAgentNotificationConfig(
      clientName: $clientName
      enabled: $enabled
      frequency: $frequency
    )
  }
`
export type SetLeadBrowseAgentNotificationConfigMutationFn = ApolloReactCommon.MutationFunction<
  SetLeadBrowseAgentNotificationConfigMutation,
  SetLeadBrowseAgentNotificationConfigMutationVariables
>

/**
 * __useSetLeadBrowseAgentNotificationConfigMutation__
 *
 * To run a mutation, you first call `useSetLeadBrowseAgentNotificationConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLeadBrowseAgentNotificationConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLeadBrowseAgentNotificationConfigMutation, { data, loading, error }] = useSetLeadBrowseAgentNotificationConfigMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      enabled: // value for 'enabled'
 *      frequency: // value for 'frequency'
 *   },
 * });
 */
export function useSetLeadBrowseAgentNotificationConfigMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetLeadBrowseAgentNotificationConfigMutation,
    SetLeadBrowseAgentNotificationConfigMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SetLeadBrowseAgentNotificationConfigMutation,
    SetLeadBrowseAgentNotificationConfigMutationVariables
  >(SetLeadBrowseAgentNotificationConfigDocument, baseOptions)
}
export type SetLeadBrowseAgentNotificationConfigMutationHookResult = ReturnType<
  typeof useSetLeadBrowseAgentNotificationConfigMutation
>
export type SetLeadBrowseAgentNotificationConfigMutationResult = ApolloReactCommon.MutationResult<SetLeadBrowseAgentNotificationConfigMutation>
export type SetLeadBrowseAgentNotificationConfigMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetLeadBrowseAgentNotificationConfigMutation,
  SetLeadBrowseAgentNotificationConfigMutationVariables
>
export const SetNewLeadAgentNotificationConfigDocument = gql`
  mutation setNewLeadAgentNotificationConfig(
    $clientName: String!
    $enabled: Boolean!
  ) {
    setNewLeadAgentNotificationConfig(
      clientName: $clientName
      enabled: $enabled
    )
  }
`
export type SetNewLeadAgentNotificationConfigMutationFn = ApolloReactCommon.MutationFunction<
  SetNewLeadAgentNotificationConfigMutation,
  SetNewLeadAgentNotificationConfigMutationVariables
>

/**
 * __useSetNewLeadAgentNotificationConfigMutation__
 *
 * To run a mutation, you first call `useSetNewLeadAgentNotificationConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNewLeadAgentNotificationConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNewLeadAgentNotificationConfigMutation, { data, loading, error }] = useSetNewLeadAgentNotificationConfigMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useSetNewLeadAgentNotificationConfigMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetNewLeadAgentNotificationConfigMutation,
    SetNewLeadAgentNotificationConfigMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SetNewLeadAgentNotificationConfigMutation,
    SetNewLeadAgentNotificationConfigMutationVariables
  >(SetNewLeadAgentNotificationConfigDocument, baseOptions)
}
export type SetNewLeadAgentNotificationConfigMutationHookResult = ReturnType<
  typeof useSetNewLeadAgentNotificationConfigMutation
>
export type SetNewLeadAgentNotificationConfigMutationResult = ApolloReactCommon.MutationResult<SetNewLeadAgentNotificationConfigMutation>
export type SetNewLeadAgentNotificationConfigMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetNewLeadAgentNotificationConfigMutation,
  SetNewLeadAgentNotificationConfigMutationVariables
>
export const SetProspectClientRatingDocument = gql`
  mutation setProspectClientRating(
    $clientName: String!
    $prospectEmail: String!
    $clientRating: String!
  ) {
    setProspectClientRating(
      clientName: $clientName
      prospectEmail: $prospectEmail
      clientRating: $clientRating
    ) {
      id
      email
      clientRating
    }
  }
`
export type SetProspectClientRatingMutationFn = ApolloReactCommon.MutationFunction<
  SetProspectClientRatingMutation,
  SetProspectClientRatingMutationVariables
>

/**
 * __useSetProspectClientRatingMutation__
 *
 * To run a mutation, you first call `useSetProspectClientRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProspectClientRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProspectClientRatingMutation, { data, loading, error }] = useSetProspectClientRatingMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      prospectEmail: // value for 'prospectEmail'
 *      clientRating: // value for 'clientRating'
 *   },
 * });
 */
export function useSetProspectClientRatingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetProspectClientRatingMutation,
    SetProspectClientRatingMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SetProspectClientRatingMutation,
    SetProspectClientRatingMutationVariables
  >(SetProspectClientRatingDocument, baseOptions)
}
export type SetProspectClientRatingMutationHookResult = ReturnType<
  typeof useSetProspectClientRatingMutation
>
export type SetProspectClientRatingMutationResult = ApolloReactCommon.MutationResult<SetProspectClientRatingMutation>
export type SetProspectClientRatingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetProspectClientRatingMutation,
  SetProspectClientRatingMutationVariables
>
export const SetSubscriptionEndpointDocument = gql`
  mutation SET_SUBSCRIPTION_ENDPOINT($clientName: String!, $endpoint: String!) {
    setSubscriptionEndpoint(clientName: $clientName, endpoint: $endpoint)
  }
`
export type SetSubscriptionEndpointMutationFn = ApolloReactCommon.MutationFunction<
  SetSubscriptionEndpointMutation,
  SetSubscriptionEndpointMutationVariables
>

/**
 * __useSetSubscriptionEndpointMutation__
 *
 * To run a mutation, you first call `useSetSubscriptionEndpointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSubscriptionEndpointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSubscriptionEndpointMutation, { data, loading, error }] = useSetSubscriptionEndpointMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      endpoint: // value for 'endpoint'
 *   },
 * });
 */
export function useSetSubscriptionEndpointMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetSubscriptionEndpointMutation,
    SetSubscriptionEndpointMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SetSubscriptionEndpointMutation,
    SetSubscriptionEndpointMutationVariables
  >(SetSubscriptionEndpointDocument, baseOptions)
}
export type SetSubscriptionEndpointMutationHookResult = ReturnType<
  typeof useSetSubscriptionEndpointMutation
>
export type SetSubscriptionEndpointMutationResult = ApolloReactCommon.MutationResult<SetSubscriptionEndpointMutation>
export type SetSubscriptionEndpointMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetSubscriptionEndpointMutation,
  SetSubscriptionEndpointMutationVariables
>
export const TestSubscriptionEndpointDocument = gql`
  mutation testSubscriptionEndpoint(
    $clientName: String!
    $subscriptionLevel: String
  ) {
    testSubscriptionEndpoint(
      clientName: $clientName
      subscriptionLevel: $subscriptionLevel
    )
  }
`
export type TestSubscriptionEndpointMutationFn = ApolloReactCommon.MutationFunction<
  TestSubscriptionEndpointMutation,
  TestSubscriptionEndpointMutationVariables
>

/**
 * __useTestSubscriptionEndpointMutation__
 *
 * To run a mutation, you first call `useTestSubscriptionEndpointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestSubscriptionEndpointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testSubscriptionEndpointMutation, { data, loading, error }] = useTestSubscriptionEndpointMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      subscriptionLevel: // value for 'subscriptionLevel'
 *   },
 * });
 */
export function useTestSubscriptionEndpointMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TestSubscriptionEndpointMutation,
    TestSubscriptionEndpointMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    TestSubscriptionEndpointMutation,
    TestSubscriptionEndpointMutationVariables
  >(TestSubscriptionEndpointDocument, baseOptions)
}
export type TestSubscriptionEndpointMutationHookResult = ReturnType<
  typeof useTestSubscriptionEndpointMutation
>
export type TestSubscriptionEndpointMutationResult = ApolloReactCommon.MutationResult<TestSubscriptionEndpointMutation>
export type TestSubscriptionEndpointMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TestSubscriptionEndpointMutation,
  TestSubscriptionEndpointMutationVariables
>
export const UpdateColonnadeUserDocument = gql`
  mutation UPDATE_COLONNADE_USER(
    $callerUserId: Int!
    $userId: Int!
    $input: UpdateColonnadeUserInput!
  ) {
    updateColonnadeUser(
      callerUserId: $callerUserId
      userId: $userId
      input: $input
    )
  }
`
export type UpdateColonnadeUserMutationFn = ApolloReactCommon.MutationFunction<
  UpdateColonnadeUserMutation,
  UpdateColonnadeUserMutationVariables
>

/**
 * __useUpdateColonnadeUserMutation__
 *
 * To run a mutation, you first call `useUpdateColonnadeUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateColonnadeUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateColonnadeUserMutation, { data, loading, error }] = useUpdateColonnadeUserMutation({
 *   variables: {
 *      callerUserId: // value for 'callerUserId'
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateColonnadeUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateColonnadeUserMutation,
    UpdateColonnadeUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateColonnadeUserMutation,
    UpdateColonnadeUserMutationVariables
  >(UpdateColonnadeUserDocument, baseOptions)
}
export type UpdateColonnadeUserMutationHookResult = ReturnType<
  typeof useUpdateColonnadeUserMutation
>
export type UpdateColonnadeUserMutationResult = ApolloReactCommon.MutationResult<UpdateColonnadeUserMutation>
export type UpdateColonnadeUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateColonnadeUserMutation,
  UpdateColonnadeUserMutationVariables
>
export const UpdateEmailCampaignDocument = gql`
  mutation updateEmailCampaign(
    $clientName: String!
    $emailCampaignId: Int!
    $emailCampaign: EmailCampaignInput!
    $cohort: [Int!]
    $sharing: [Int!]
  ) {
    updateEmailCampaign(
      clientName: $clientName
      emailCampaignId: $emailCampaignId
      emailCampaign: $emailCampaign
      cohort: $cohort
      sharing: $sharing
    ) {
      id
      communityId
      planId
      elevationId
      lotId
      inventoryId
      ownerId
      templateId
      name
      mainImage
      replyTo
      replyToName
      subject
      body
      signature
      active
      createdAt
      lastUpdated
      cohort(clientName: $clientName) {
        id
        prospectId
        status
        active
        prospect(clientName: $clientName) {
          id
          status
          registrationDate
          name
          firstName
          lastName
          email
        }
      }
      sections(clientName: $clientName) {
        id
        content
        index
        images(clientName: $clientName) {
          id
          emailCampaignSectionId
          index
          src
        }
      }
      searchCommunity
      searchPlan
    }
  }
`
export type UpdateEmailCampaignMutationFn = ApolloReactCommon.MutationFunction<
  UpdateEmailCampaignMutation,
  UpdateEmailCampaignMutationVariables
>

/**
 * __useUpdateEmailCampaignMutation__
 *
 * To run a mutation, you first call `useUpdateEmailCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailCampaignMutation, { data, loading, error }] = useUpdateEmailCampaignMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      emailCampaignId: // value for 'emailCampaignId'
 *      emailCampaign: // value for 'emailCampaign'
 *      cohort: // value for 'cohort'
 *      sharing: // value for 'sharing'
 *   },
 * });
 */
export function useUpdateEmailCampaignMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateEmailCampaignMutation,
    UpdateEmailCampaignMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateEmailCampaignMutation,
    UpdateEmailCampaignMutationVariables
  >(UpdateEmailCampaignDocument, baseOptions)
}
export type UpdateEmailCampaignMutationHookResult = ReturnType<
  typeof useUpdateEmailCampaignMutation
>
export type UpdateEmailCampaignMutationResult = ApolloReactCommon.MutationResult<UpdateEmailCampaignMutation>
export type UpdateEmailCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateEmailCampaignMutation,
  UpdateEmailCampaignMutationVariables
>
export const UpdateProspectDocument = gql`
  mutation updateProspect(
    $clientName: String!
    $prospect: UpdateProspectInput!
  ) {
    updateProspect(clientName: $clientName, prospect: $prospect) {
      id
      status
    }
  }
`
export type UpdateProspectMutationFn = ApolloReactCommon.MutationFunction<
  UpdateProspectMutation,
  UpdateProspectMutationVariables
>

/**
 * __useUpdateProspectMutation__
 *
 * To run a mutation, you first call `useUpdateProspectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProspectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProspectMutation, { data, loading, error }] = useUpdateProspectMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      prospect: // value for 'prospect'
 *   },
 * });
 */
export function useUpdateProspectMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateProspectMutation,
    UpdateProspectMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateProspectMutation,
    UpdateProspectMutationVariables
  >(UpdateProspectDocument, baseOptions)
}
export type UpdateProspectMutationHookResult = ReturnType<
  typeof useUpdateProspectMutation
>
export type UpdateProspectMutationResult = ApolloReactCommon.MutationResult<UpdateProspectMutation>
export type UpdateProspectMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateProspectMutation,
  UpdateProspectMutationVariables
>
export const GetAgentsDocument = gql`
  query getAgents($clientName: String!, $communityIds: [Int!]) {
    agentsByClient(clientName: $clientName, communityIds: $communityIds) {
      firstName
      lastName
      picture
      email
      id
    }
  }
`

/**
 * __useGetAgentsQuery__
 *
 * To run a query within a React component, call `useGetAgentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgentsQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      communityIds: // value for 'communityIds'
 *   },
 * });
 */
export function useGetAgentsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetAgentsQuery,
    GetAgentsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetAgentsQuery, GetAgentsQueryVariables>(
    GetAgentsDocument,
    baseOptions
  )
}
export function useGetAgentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAgentsQuery,
    GetAgentsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetAgentsQuery, GetAgentsQueryVariables>(
    GetAgentsDocument,
    baseOptions
  )
}
export type GetAgentsQueryHookResult = ReturnType<typeof useGetAgentsQuery>
export type GetAgentsLazyQueryHookResult = ReturnType<
  typeof useGetAgentsLazyQuery
>
export type GetAgentsQueryResult = ApolloReactCommon.QueryResult<
  GetAgentsQuery,
  GetAgentsQueryVariables
>
export const CommunitiesDocument = gql`
  query Communities($clientName: String!) {
    communities(clientName: $clientName, active: true) {
      id
      clientName
      name
      latitude
      longitude
      logo
      active
      thumb
      cityLocation(clientName: $clientName) {
        ...CityFieldsFull
      }
    }
  }
  ${CityFieldsFullFragmentDoc}
`

/**
 * __useCommunitiesQuery__
 *
 * To run a query within a React component, call `useCommunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunitiesQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useCommunitiesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CommunitiesQuery,
    CommunitiesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<CommunitiesQuery, CommunitiesQueryVariables>(
    CommunitiesDocument,
    baseOptions
  )
}
export function useCommunitiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CommunitiesQuery,
    CommunitiesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CommunitiesQuery,
    CommunitiesQueryVariables
  >(CommunitiesDocument, baseOptions)
}
export type CommunitiesQueryHookResult = ReturnType<typeof useCommunitiesQuery>
export type CommunitiesLazyQueryHookResult = ReturnType<
  typeof useCommunitiesLazyQuery
>
export type CommunitiesQueryResult = ApolloReactCommon.QueryResult<
  CommunitiesQuery,
  CommunitiesQueryVariables
>
export const CamberCommunityIdsDocument = gql`
  query CamberCommunityIds($clientName: String!) {
    communities(clientName: $clientName, active: true) {
      id
      salesCenterActive
      name
    }
  }
`

/**
 * __useCamberCommunityIdsQuery__
 *
 * To run a query within a React component, call `useCamberCommunityIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCamberCommunityIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCamberCommunityIdsQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useCamberCommunityIdsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CamberCommunityIdsQuery,
    CamberCommunityIdsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CamberCommunityIdsQuery,
    CamberCommunityIdsQueryVariables
  >(CamberCommunityIdsDocument, baseOptions)
}
export function useCamberCommunityIdsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CamberCommunityIdsQuery,
    CamberCommunityIdsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CamberCommunityIdsQuery,
    CamberCommunityIdsQueryVariables
  >(CamberCommunityIdsDocument, baseOptions)
}
export type CamberCommunityIdsQueryHookResult = ReturnType<
  typeof useCamberCommunityIdsQuery
>
export type CamberCommunityIdsLazyQueryHookResult = ReturnType<
  typeof useCamberCommunityIdsLazyQuery
>
export type CamberCommunityIdsQueryResult = ApolloReactCommon.QueryResult<
  CamberCommunityIdsQuery,
  CamberCommunityIdsQueryVariables
>
export const CommunitiesByIdsDocument = gql`
  query CommunitiesByIds(
    $clientName: String!
    $communityIds: [Int!]
    $filter: CommonQueryFilter!
  ) {
    communities(clientName: $clientName, communityIds: $communityIds) {
      id
      clientName
      name
      latitude
      longitude
      logo
      active
      cityLocation(clientName: $clientName) {
        ...CityFieldsFull
      }
      plans {
        id
        clientName
        communityId
        name
        displayName
        defaultElevationId
        bed
        bedMin
        bedMax
        bedRange {
          min
          max
          __typename
        }
        bath
        bathMin
        bathMax
        bathRange {
          min
          max
        }
        size
        sizeMin
        sizeMax
        sizeRange {
          min
          max
        }
        cost
        costMin
        costMax
        costRange {
          min
          max
        }
        description
        elevations(clientName: $clientName) {
          id
          planDisplayName
          internalName
          planName
          active
        }
      }
      siteplans(clientName: $clientName) {
        id
        communityId
        name
        src
        lotWidth
        lotHeight
        lotFontSize
        lotMetric
        master
        svg {
          shapes {
            tagName
            attributes {
              className
              dataName
              x
              y
              width
              height
              transform
              points
              d
              __typename
            }
            __typename
          }
          style
          viewBox {
            x
            y
            width
            height
          }
        }
        lotLegend {
          id
          code
          name
          hex
        }
        hotspots(clientName: $clientName) {
          id
          siteplanId
          name
          x
          y
          width
          height
          description
          thumb
        }
        popularLots(filter: $filter) {
          id
          communityId
          dataName
          name
          salesStatus
          premium
          externalId
          address
          size
          cityName
          stateCode
          zip
          postCode
          popularity
          count
          siteplanInfo {
            lotId
            siteplanId
            x
            y
          }
          availableSchemes(clientName: $clientName) {
            id
            name
          }
          excludedPlanElevations(clientName: $clientName) {
            planId
            elevationId
            planName
            planDisplayName
            elevationCaption
          }
          availablePlanElevations(clientName: $clientName) {
            planId
            elevationId
            planName
            planDisplayName
            elevationCaption
          }
          inventory(clientName: $clientName) {
            id
            lotId
            planId
            elevationId
            communityId
            price
            sqft
            beds
            baths
            features
            constructionStatus
            closingDate
            mlsId
            garageType
            garageCapacity
            floors
            schemeId
            photoFolder
            plan(clientName: $clientName) {
              id
              communityId
              name
              displayName
              defaultElevationId
              description
              bed
              bath
              cost
              size
              videoUrl
              interactiveInteriorIds(clientName: $clientName)
              bedRange(clientName: $clientName) {
                min
                max
              }
              bathRange(clientName: $clientName) {
                min
                max
              }
              costRange(clientName: $clientName) {
                min
                max
              }
              sizeRange(clientName: $clientName) {
                min
                max
              }
            }
            sgtData(clientName: $clientName) {
              id
              sgtVendorId
              sgtExternalId
              sgtData
            }
            appointmentsEnabled
            reserveHomeUrl
            photos(clientName: $clientName) {
              id
              src
              listIndex
            }
            plan(clientName: $clientName) {
              id
              displayName
              name
              communityId
              defaultElevationId
              description
              bed
              bath
              cost
              size
              videoUrl
              interactiveInteriorIds(clientName: $clientName)
              bedRange(clientName: $clientName) {
                min
                max
              }
              bathRange(clientName: $clientName) {
                min
                max
              }
              costRange(clientName: $clientName) {
                min
                max
              }
              sizeRange(clientName: $clientName) {
                min
                max
              }
            }
            elevation(clientName: $clientName) {
              id
              mirrorElevationId
              mirrorRolePrimary
              communityId
              planName
              planDisplayName
              planId
              caption
              thumb
              bed
              bath
              size
              cost
              defaultFloor
              description
              svgMirroring
              garagePosition
              defaultGaragePosition
              bedRange(clientName: $clientName) {
                min
                max
              }
              bathRange(clientName: $clientName) {
                min
                max
              }
              costRange(clientName: $clientName) {
                min
                max
              }
              sizeRange(clientName: $clientName) {
                min
                max
              }
              tags(clientName: $clientName) {
                categoryName
                tagName
              }
            }
          }
        }
      }
    }
  }
  ${CityFieldsFullFragmentDoc}
`

/**
 * __useCommunitiesByIdsQuery__
 *
 * To run a query within a React component, call `useCommunitiesByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunitiesByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunitiesByIdsQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      communityIds: // value for 'communityIds'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCommunitiesByIdsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CommunitiesByIdsQuery,
    CommunitiesByIdsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CommunitiesByIdsQuery,
    CommunitiesByIdsQueryVariables
  >(CommunitiesByIdsDocument, baseOptions)
}
export function useCommunitiesByIdsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CommunitiesByIdsQuery,
    CommunitiesByIdsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CommunitiesByIdsQuery,
    CommunitiesByIdsQueryVariables
  >(CommunitiesByIdsDocument, baseOptions)
}
export type CommunitiesByIdsQueryHookResult = ReturnType<
  typeof useCommunitiesByIdsQuery
>
export type CommunitiesByIdsLazyQueryHookResult = ReturnType<
  typeof useCommunitiesByIdsLazyQuery
>
export type CommunitiesByIdsQueryResult = ApolloReactCommon.QueryResult<
  CommunitiesByIdsQuery,
  CommunitiesByIdsQueryVariables
>
export const CommunitiesByIdsSiteplansInfoDocument = gql`
  query CommunitiesByIdsSiteplansInfo(
    $clientName: String!
    $communityIds: [Int!]
    $filter: CommonQueryFilter!
  ) {
    communities(clientName: $clientName, communityIds: $communityIds) {
      id
      clientName
      name
      latitude
      longitude
      logo
      active
      siteplans(clientName: $clientName, active: true) {
        id
        communityId
        name
        src
        lotWidth
        lotHeight
        lotFontSize
        lotMetric
        master
        geoInfo(clientName: $clientName) {
          id
          siteplanId
          neLatitude
          neLongitude
          swLatitude
          swLongitude
          geoJson
        }
        svg {
          shapes {
            tagName
            attributes {
              className
              dataName
              x
              y
              width
              height
              transform
              points
              d
              __typename
            }
            __typename
          }
          style
          viewBox {
            x
            y
            width
            height
          }
        }
        lotLegend {
          id
          code
          name
          hex
        }
        hotspots(clientName: $clientName) {
          id
          siteplanId
          name
          x
          y
          width
          height
          description
          thumb
        }
        lots(clientName: $clientName) {
          id
          communityId
          dataName
          name
          salesStatus
          premium
          externalId
          address
          size
          cityName
          stateCode
          zip
          postCode
          geoJson
          siteplanInfo {
            lotId
            siteplanId
            x
            y
          }
        }
        popularLots(filter: $filter) {
          id
          communityId
          dataName
          name
          salesStatus
          premium
          externalId
          address
          size
          cityName
          stateCode
          zip
          postCode
          popularity
          count
          leadsCount
          siteplanInfo {
            lotId
            siteplanId
            x
            y
          }
        }
      }
    }
  }
`

/**
 * __useCommunitiesByIdsSiteplansInfoQuery__
 *
 * To run a query within a React component, call `useCommunitiesByIdsSiteplansInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunitiesByIdsSiteplansInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunitiesByIdsSiteplansInfoQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      communityIds: // value for 'communityIds'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCommunitiesByIdsSiteplansInfoQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CommunitiesByIdsSiteplansInfoQuery,
    CommunitiesByIdsSiteplansInfoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CommunitiesByIdsSiteplansInfoQuery,
    CommunitiesByIdsSiteplansInfoQueryVariables
  >(CommunitiesByIdsSiteplansInfoDocument, baseOptions)
}
export function useCommunitiesByIdsSiteplansInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CommunitiesByIdsSiteplansInfoQuery,
    CommunitiesByIdsSiteplansInfoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CommunitiesByIdsSiteplansInfoQuery,
    CommunitiesByIdsSiteplansInfoQueryVariables
  >(CommunitiesByIdsSiteplansInfoDocument, baseOptions)
}
export type CommunitiesByIdsSiteplansInfoQueryHookResult = ReturnType<
  typeof useCommunitiesByIdsSiteplansInfoQuery
>
export type CommunitiesByIdsSiteplansInfoLazyQueryHookResult = ReturnType<
  typeof useCommunitiesByIdsSiteplansInfoLazyQuery
>
export type CommunitiesByIdsSiteplansInfoQueryResult = ApolloReactCommon.QueryResult<
  CommunitiesByIdsSiteplansInfoQuery,
  CommunitiesByIdsSiteplansInfoQueryVariables
>
export const CommunitiesWithImagesDocument = gql`
  query CommunitiesWithImages($clientName: String!, $active: Boolean) {
    communities(clientName: $clientName, active: $active) {
      id
      clientName
      name
      latitude
      longitude
      logo
      active
      thumb
      cityLocation(clientName: $clientName) {
        ...CityFieldsFull
      }
      plans {
        id
        clientName
        communityId
        name
        displayName
        elevations(clientName: $clientName) {
          id
          planDisplayName
          internalName
          planName
          active
        }
      }
      imgs(clientName: $clientName) {
        src
        listIndex
        communityId
        videoSrc
      }
      mainPhotos(clientName: $clientName) {
        id
        src
        listIndex
      }
      overviewPhotos(clientName: $clientName) {
        id
        src
        listIndex
      }
      siteplans(clientName: $clientName, active: true) {
        id
        name
        lotFontSize
        lotMetric
        lotWidth
        lotHeight
        master
        src
        lots(clientName: $clientName) {
          id
          communityId
          dataName
          name
          salesStatus
          premium
          externalId
          address
          size
          cityName
          stateCode
          zip
          postCode
          siteplanInfo {
            lotId
            siteplanId
            x
            y
          }
          inventory(clientName: $clientName) {
            id
            lotId
            planId
            elevationId
            communityId
            price
            sqft
            beds
            baths
            features
            constructionStatus
            closingDate
            mlsId
            garageType
            garageCapacity
            floors
            schemeId
            photoFolder
            appointmentsEnabled
            reserveHomeUrl
            photos(clientName: $clientName) {
              id
              src
              listIndex
            }
            elevation(clientName: $clientName) {
              id
              mirrorElevationId
              mirrorRolePrimary
              communityId
              planName
              planDisplayName
              planId
              caption
              thumb
              bed
              bath
              size
              cost
              defaultFloor
              description
              svgMirroring
              garagePosition
              defaultGaragePosition
              bedRange(clientName: $clientName) {
                min
                max
              }
              bathRange(clientName: $clientName) {
                min
                max
              }
              costRange(clientName: $clientName) {
                min
                max
              }
              sizeRange(clientName: $clientName) {
                min
                max
              }
              tags(clientName: $clientName) {
                categoryName
                tagName
              }
            }
          }
        }
      }
    }
  }
  ${CityFieldsFullFragmentDoc}
`

/**
 * __useCommunitiesWithImagesQuery__
 *
 * To run a query within a React component, call `useCommunitiesWithImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunitiesWithImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunitiesWithImagesQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useCommunitiesWithImagesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CommunitiesWithImagesQuery,
    CommunitiesWithImagesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CommunitiesWithImagesQuery,
    CommunitiesWithImagesQueryVariables
  >(CommunitiesWithImagesDocument, baseOptions)
}
export function useCommunitiesWithImagesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CommunitiesWithImagesQuery,
    CommunitiesWithImagesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CommunitiesWithImagesQuery,
    CommunitiesWithImagesQueryVariables
  >(CommunitiesWithImagesDocument, baseOptions)
}
export type CommunitiesWithImagesQueryHookResult = ReturnType<
  typeof useCommunitiesWithImagesQuery
>
export type CommunitiesWithImagesLazyQueryHookResult = ReturnType<
  typeof useCommunitiesWithImagesLazyQuery
>
export type CommunitiesWithImagesQueryResult = ApolloReactCommon.QueryResult<
  CommunitiesWithImagesQuery,
  CommunitiesWithImagesQueryVariables
>
export const CommunityDocument = gql`
  query Community(
    $clientName: String!
    $communityId: Int!
    $filter: CommonQueryFilter!
  ) {
    community(clientName: $clientName, communityId: $communityId) {
      id
      name
      siteplans(clientName: $clientName) {
        id
        name
        src
        lotWidth
        lotHeight
        lotFontSize
        lotMetric
        master
        svg {
          shapes {
            tagName
            attributes {
              className
              dataName
              x
              y
              width
              height
              transform
              points
              d
              __typename
            }
            __typename
          }
          style
          viewBox {
            x
            y
            width
            height
          }
        }
        lotLegend {
          id
          code
          name
          hex
        }
        hotspots(clientName: $clientName) {
          id
          siteplanId
          name
          x
          y
          width
          height
          description
          thumb
        }
        popularLots(filter: $filter) {
          id
          communityId
          dataName
          name
          salesStatus
          premium
          externalId
          address
          size
          cityName
          stateCode
          zip
          postCode
          popularity
          count
          siteplanInfo {
            lotId
            siteplanId
            x
            y
          }
          availableSchemes(clientName: $clientName) {
            id
            name
          }
          excludedPlanElevations(clientName: $clientName) {
            planId
            elevationId
            planName
            planDisplayName
            elevationCaption
          }
          availablePlanElevations(clientName: $clientName) {
            planId
            elevationId
            planName
            planDisplayName
            elevationCaption
          }
          inventory(clientName: $clientName) {
            id
            lotId
            planId
            elevationId
            communityId
            price
            sqft
            beds
            baths
            features
            constructionStatus
            closingDate
            mlsId
            garageType
            garageCapacity
            floors
            schemeId
            photoFolder
            plan(clientName: $clientName) {
              id
              communityId
              name
              displayName
              defaultElevationId
              description
              bed
              bath
              cost
              size
              videoUrl
              interactiveInteriorIds(clientName: $clientName)
              bedRange(clientName: $clientName) {
                min
                max
              }
              bathRange(clientName: $clientName) {
                min
                max
              }
              costRange(clientName: $clientName) {
                min
                max
              }
              sizeRange(clientName: $clientName) {
                min
                max
              }
            }
            sgtData(clientName: $clientName) {
              id
              sgtVendorId
              sgtExternalId
              sgtData
            }
            appointmentsEnabled
            reserveHomeUrl
            photos(clientName: $clientName) {
              id
              src
              listIndex
            }
            plan(clientName: $clientName) {
              id
              displayName
              name
              communityId
              defaultElevationId
              description
              bed
              bath
              cost
              size
              videoUrl
              interactiveInteriorIds(clientName: $clientName)
              bedRange(clientName: $clientName) {
                min
                max
              }
              bathRange(clientName: $clientName) {
                min
                max
              }
              costRange(clientName: $clientName) {
                min
                max
              }
              sizeRange(clientName: $clientName) {
                min
                max
              }
            }
            elevation(clientName: $clientName) {
              id
              mirrorElevationId
              mirrorRolePrimary
              communityId
              planName
              planDisplayName
              planId
              caption
              thumb
              bed
              bath
              size
              cost
              defaultFloor
              description
              svgMirroring
              garagePosition
              defaultGaragePosition
              bedRange(clientName: $clientName) {
                min
                max
              }
              bathRange(clientName: $clientName) {
                min
                max
              }
              costRange(clientName: $clientName) {
                min
                max
              }
              sizeRange(clientName: $clientName) {
                min
                max
              }
              tags(clientName: $clientName) {
                categoryName
                tagName
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useCommunityQuery__
 *
 * To run a query within a React component, call `useCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      communityId: // value for 'communityId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCommunityQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CommunityQuery,
    CommunityQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<CommunityQuery, CommunityQueryVariables>(
    CommunityDocument,
    baseOptions
  )
}
export function useCommunityLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CommunityQuery,
    CommunityQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<CommunityQuery, CommunityQueryVariables>(
    CommunityDocument,
    baseOptions
  )
}
export type CommunityQueryHookResult = ReturnType<typeof useCommunityQuery>
export type CommunityLazyQueryHookResult = ReturnType<
  typeof useCommunityLazyQuery
>
export type CommunityQueryResult = ApolloReactCommon.QueryResult<
  CommunityQuery,
  CommunityQueryVariables
>
export const GetEmailCampaignDocument = gql`
  query getEmailCampaign($clientName: String!, $emailCampaignId: Int!) {
    getEmailCampaign(
      clientName: $clientName
      emailCampaignId: $emailCampaignId
    ) {
      id
      communityId
      planId
      elevationId
      lotId
      inventoryId
      clientName
      ownerId
      templateId
      name
      mainImage
      replyTo
      replyToName
      subject
      body
      opener
      signature
      active
      createdAt
      lastUpdated
      cohort(clientName: $clientName) {
        id
        emailCampaignId
        prospectId
        status
        lastFollowUp
        active
        prospect(clientName: $clientName) {
          id
          status
          registrationDate
          name
          firstName
          lastName
          email
          receiveCampaignEmails(clientName: $clientName)
        }
      }
      sections(clientName: $clientName) {
        id
        content
        index
        images(clientName: $clientName) {
          id
          emailCampaignSectionId
          index
          src
        }
      }
      community(clientName: $clientName) {
        id
        name
      }
      lot(clientName: $clientName) {
        id
        name
        address
        metroName
        cityName
        stateCode
        zip
        postCode
        countryCode
      }
      inventory(clientName: $clientName) {
        id
        plan(clientName: $clientName) {
          id
          name
          displayName
        }
        elevation(clientName: $clientName) {
          id
          caption
          internalName
        }
        lot(clientName: $clientName) {
          id
          address
          metroName
          cityName
          stateCode
          zip
          postCode
          countryCode
          name
        }
      }
      plan(clientName: $clientName) {
        id
        name
        displayName
      }
      elevation(clientName: $clientName) {
        id
        caption
        internalName
      }
      searchCommunity
      searchPlan
    }
  }
`

/**
 * __useGetEmailCampaignQuery__
 *
 * To run a query within a React component, call `useGetEmailCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailCampaignQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      emailCampaignId: // value for 'emailCampaignId'
 *   },
 * });
 */
export function useGetEmailCampaignQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetEmailCampaignQuery,
    GetEmailCampaignQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetEmailCampaignQuery,
    GetEmailCampaignQueryVariables
  >(GetEmailCampaignDocument, baseOptions)
}
export function useGetEmailCampaignLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetEmailCampaignQuery,
    GetEmailCampaignQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetEmailCampaignQuery,
    GetEmailCampaignQueryVariables
  >(GetEmailCampaignDocument, baseOptions)
}
export type GetEmailCampaignQueryHookResult = ReturnType<
  typeof useGetEmailCampaignQuery
>
export type GetEmailCampaignLazyQueryHookResult = ReturnType<
  typeof useGetEmailCampaignLazyQuery
>
export type GetEmailCampaignQueryResult = ApolloReactCommon.QueryResult<
  GetEmailCampaignQuery,
  GetEmailCampaignQueryVariables
>
export const GetEmailCampaignCohortStatusDocument = gql`
  query getEmailCampaignCohortStatus(
    $clientName: String!
    $emailCampaignId: Int!
  ) {
    getEmailCampaign(
      clientName: $clientName
      emailCampaignId: $emailCampaignId
    ) {
      id
      cohort(clientName: $clientName) {
        id
        status
      }
    }
  }
`

/**
 * __useGetEmailCampaignCohortStatusQuery__
 *
 * To run a query within a React component, call `useGetEmailCampaignCohortStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailCampaignCohortStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailCampaignCohortStatusQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      emailCampaignId: // value for 'emailCampaignId'
 *   },
 * });
 */
export function useGetEmailCampaignCohortStatusQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetEmailCampaignCohortStatusQuery,
    GetEmailCampaignCohortStatusQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetEmailCampaignCohortStatusQuery,
    GetEmailCampaignCohortStatusQueryVariables
  >(GetEmailCampaignCohortStatusDocument, baseOptions)
}
export function useGetEmailCampaignCohortStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetEmailCampaignCohortStatusQuery,
    GetEmailCampaignCohortStatusQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetEmailCampaignCohortStatusQuery,
    GetEmailCampaignCohortStatusQueryVariables
  >(GetEmailCampaignCohortStatusDocument, baseOptions)
}
export type GetEmailCampaignCohortStatusQueryHookResult = ReturnType<
  typeof useGetEmailCampaignCohortStatusQuery
>
export type GetEmailCampaignCohortStatusLazyQueryHookResult = ReturnType<
  typeof useGetEmailCampaignCohortStatusLazyQuery
>
export type GetEmailCampaignCohortStatusQueryResult = ApolloReactCommon.QueryResult<
  GetEmailCampaignCohortStatusQuery,
  GetEmailCampaignCohortStatusQueryVariables
>
export const GetEmailCampaignsDocument = gql`
  query getEmailCampaigns($clientName: String!, $shared: Boolean) {
    getEmailCampaigns(clientName: $clientName, shared: $shared) {
      id
      communityId
      planId
      elevationId
      lotId
      inventoryId
      clientName
      ownerId
      templateId
      name
      mainImage
      replyTo
      replyToName
      subject
      body
      opener
      signature
      active
      createdAt
      lastUpdated
      cohort(clientName: $clientName) {
        id
        emailCampaignId
        prospectId
        status
        lastFollowUp
        active
        prospect(clientName: $clientName) {
          id
          status
          registrationDate
          name
          firstName
          lastName
          email
        }
      }
      sections(clientName: $clientName) {
        id
        content
        index
        images(clientName: $clientName) {
          id
          emailCampaignSectionId
          index
          src
        }
      }
      community(clientName: $clientName) {
        id
        name
      }
      lot(clientName: $clientName) {
        id
        name
        address
        metroName
        cityName
        stateCode
        zip
        postCode
        countryCode
      }
      inventory(clientName: $clientName) {
        id
        plan(clientName: $clientName) {
          id
          name
          displayName
        }
        elevation(clientName: $clientName) {
          id
          caption
          internalName
        }
        lot(clientName: $clientName) {
          id
          name
          address
          metroName
          cityName
          stateCode
          zip
          postCode
          countryCode
        }
      }
      plan(clientName: $clientName) {
        id
        name
        displayName
      }
      elevation(clientName: $clientName) {
        id
        caption
        internalName
      }
      searchCommunity
      searchPlan
    }
  }
`

/**
 * __useGetEmailCampaignsQuery__
 *
 * To run a query within a React component, call `useGetEmailCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailCampaignsQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      shared: // value for 'shared'
 *   },
 * });
 */
export function useGetEmailCampaignsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetEmailCampaignsQuery,
    GetEmailCampaignsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetEmailCampaignsQuery,
    GetEmailCampaignsQueryVariables
  >(GetEmailCampaignsDocument, baseOptions)
}
export function useGetEmailCampaignsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetEmailCampaignsQuery,
    GetEmailCampaignsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetEmailCampaignsQuery,
    GetEmailCampaignsQueryVariables
  >(GetEmailCampaignsDocument, baseOptions)
}
export type GetEmailCampaignsQueryHookResult = ReturnType<
  typeof useGetEmailCampaignsQuery
>
export type GetEmailCampaignsLazyQueryHookResult = ReturnType<
  typeof useGetEmailCampaignsLazyQuery
>
export type GetEmailCampaignsQueryResult = ApolloReactCommon.QueryResult<
  GetEmailCampaignsQuery,
  GetEmailCampaignsQueryVariables
>
export const GetLeadBrowseAgentNotificationConfigDocument = gql`
  query getLeadBrowseAgentNotificationConfig($clientName: String!) {
    getLeadBrowseAgentNotificationConfig(clientName: $clientName) {
      service
      enabled
      frequency
    }
  }
`

/**
 * __useGetLeadBrowseAgentNotificationConfigQuery__
 *
 * To run a query within a React component, call `useGetLeadBrowseAgentNotificationConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeadBrowseAgentNotificationConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeadBrowseAgentNotificationConfigQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useGetLeadBrowseAgentNotificationConfigQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetLeadBrowseAgentNotificationConfigQuery,
    GetLeadBrowseAgentNotificationConfigQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetLeadBrowseAgentNotificationConfigQuery,
    GetLeadBrowseAgentNotificationConfigQueryVariables
  >(GetLeadBrowseAgentNotificationConfigDocument, baseOptions)
}
export function useGetLeadBrowseAgentNotificationConfigLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetLeadBrowseAgentNotificationConfigQuery,
    GetLeadBrowseAgentNotificationConfigQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetLeadBrowseAgentNotificationConfigQuery,
    GetLeadBrowseAgentNotificationConfigQueryVariables
  >(GetLeadBrowseAgentNotificationConfigDocument, baseOptions)
}
export type GetLeadBrowseAgentNotificationConfigQueryHookResult = ReturnType<
  typeof useGetLeadBrowseAgentNotificationConfigQuery
>
export type GetLeadBrowseAgentNotificationConfigLazyQueryHookResult = ReturnType<
  typeof useGetLeadBrowseAgentNotificationConfigLazyQuery
>
export type GetLeadBrowseAgentNotificationConfigQueryResult = ApolloReactCommon.QueryResult<
  GetLeadBrowseAgentNotificationConfigQuery,
  GetLeadBrowseAgentNotificationConfigQueryVariables
>
export const GetNewLeadAgentNotificationConfigDocument = gql`
  query getNewLeadAgentNotificationConfig($clientName: String!) {
    getNewLeadAgentNotificationConfig(clientName: $clientName) {
      service
      enabled
    }
  }
`

/**
 * __useGetNewLeadAgentNotificationConfigQuery__
 *
 * To run a query within a React component, call `useGetNewLeadAgentNotificationConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewLeadAgentNotificationConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewLeadAgentNotificationConfigQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useGetNewLeadAgentNotificationConfigQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetNewLeadAgentNotificationConfigQuery,
    GetNewLeadAgentNotificationConfigQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetNewLeadAgentNotificationConfigQuery,
    GetNewLeadAgentNotificationConfigQueryVariables
  >(GetNewLeadAgentNotificationConfigDocument, baseOptions)
}
export function useGetNewLeadAgentNotificationConfigLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetNewLeadAgentNotificationConfigQuery,
    GetNewLeadAgentNotificationConfigQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetNewLeadAgentNotificationConfigQuery,
    GetNewLeadAgentNotificationConfigQueryVariables
  >(GetNewLeadAgentNotificationConfigDocument, baseOptions)
}
export type GetNewLeadAgentNotificationConfigQueryHookResult = ReturnType<
  typeof useGetNewLeadAgentNotificationConfigQuery
>
export type GetNewLeadAgentNotificationConfigLazyQueryHookResult = ReturnType<
  typeof useGetNewLeadAgentNotificationConfigLazyQuery
>
export type GetNewLeadAgentNotificationConfigQueryResult = ApolloReactCommon.QueryResult<
  GetNewLeadAgentNotificationConfigQuery,
  GetNewLeadAgentNotificationConfigQueryVariables
>
export const GetSubscriptionEndpointDocument = gql`
  query getSubscriptionEndpoint($clientName: String!) {
    getSubscriptionEndpoint(clientName: $clientName)
  }
`

/**
 * __useGetSubscriptionEndpointQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionEndpointQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionEndpointQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionEndpointQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useGetSubscriptionEndpointQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetSubscriptionEndpointQuery,
    GetSubscriptionEndpointQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetSubscriptionEndpointQuery,
    GetSubscriptionEndpointQueryVariables
  >(GetSubscriptionEndpointDocument, baseOptions)
}
export function useGetSubscriptionEndpointLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSubscriptionEndpointQuery,
    GetSubscriptionEndpointQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetSubscriptionEndpointQuery,
    GetSubscriptionEndpointQueryVariables
  >(GetSubscriptionEndpointDocument, baseOptions)
}
export type GetSubscriptionEndpointQueryHookResult = ReturnType<
  typeof useGetSubscriptionEndpointQuery
>
export type GetSubscriptionEndpointLazyQueryHookResult = ReturnType<
  typeof useGetSubscriptionEndpointLazyQuery
>
export type GetSubscriptionEndpointQueryResult = ApolloReactCommon.QueryResult<
  GetSubscriptionEndpointQuery,
  GetSubscriptionEndpointQueryVariables
>
export const InteriorElementOptionsDocument = gql`
  query InteriorElementOptions($clientName: String!) {
    interiorElementOptions(clientName: $clientName) {
      id
      name
      description
      cost
      hex
      interiorListElementId
      overlaySrc
      overlaySwatchSrc
      listIndex
    }
  }
`

/**
 * __useInteriorElementOptionsQuery__
 *
 * To run a query within a React component, call `useInteriorElementOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInteriorElementOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInteriorElementOptionsQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useInteriorElementOptionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    InteriorElementOptionsQuery,
    InteriorElementOptionsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    InteriorElementOptionsQuery,
    InteriorElementOptionsQueryVariables
  >(InteriorElementOptionsDocument, baseOptions)
}
export function useInteriorElementOptionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    InteriorElementOptionsQuery,
    InteriorElementOptionsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    InteriorElementOptionsQuery,
    InteriorElementOptionsQueryVariables
  >(InteriorElementOptionsDocument, baseOptions)
}
export type InteriorElementOptionsQueryHookResult = ReturnType<
  typeof useInteriorElementOptionsQuery
>
export type InteriorElementOptionsLazyQueryHookResult = ReturnType<
  typeof useInteriorElementOptionsLazyQuery
>
export type InteriorElementOptionsQueryResult = ApolloReactCommon.QueryResult<
  InteriorElementOptionsQuery,
  InteriorElementOptionsQueryVariables
>
export const LiveProspectsDocument = gql`
  query liveProspects(
    $clientName: String!
    $registrationDateTimeFilter: RegDateTimeFilter
  ) {
    colonnadeApiProspect(
      clientName: $clientName
      registrationDateTimeFilter: $registrationDateTimeFilter
    ) {
      id
      email
      name
      firstName
      lastName
      registrationDate
      phone
      picture
      status
      colonnadeAgents
      preferredContactMethods
      agents {
        agentId
      }
    }
  }
`

/**
 * __useLiveProspectsQuery__
 *
 * To run a query within a React component, call `useLiveProspectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiveProspectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveProspectsQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      registrationDateTimeFilter: // value for 'registrationDateTimeFilter'
 *   },
 * });
 */
export function useLiveProspectsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    LiveProspectsQuery,
    LiveProspectsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    LiveProspectsQuery,
    LiveProspectsQueryVariables
  >(LiveProspectsDocument, baseOptions)
}
export function useLiveProspectsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LiveProspectsQuery,
    LiveProspectsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    LiveProspectsQuery,
    LiveProspectsQueryVariables
  >(LiveProspectsDocument, baseOptions)
}
export type LiveProspectsQueryHookResult = ReturnType<
  typeof useLiveProspectsQuery
>
export type LiveProspectsLazyQueryHookResult = ReturnType<
  typeof useLiveProspectsLazyQuery
>
export type LiveProspectsQueryResult = ApolloReactCommon.QueryResult<
  LiveProspectsQuery,
  LiveProspectsQueryVariables
>
export const PaginatedLiveProspectsDocument = gql`
  query paginatedLiveProspects(
    $clientName: String!
    $registrationDateTimeFilter: RegDateTimeFilter
    $statuses: [ProspectStatus!]
    $searchedText: String
    $leadFilter: LeadFilter
    $pagination: Pagination
    $orderBy: OrderBy
  ) {
    colonnadeApiPaginatedProspects(
      clientName: $clientName
      registrationDateTimeFilter: $registrationDateTimeFilter
      excludeClientUsers: true
      statuses: $statuses
      searchedText: $searchedText
      leadFilter: $leadFilter
      pagination: $pagination
      orderBy: $orderBy
    ) {
      totalCount
      prospects {
        id
        email
        name
        firstName
        lastName
        registrationDate
        phone
        picture
        status
        preferredContactMethods
        agents {
          agentId
        }
      }
    }
  }
`

/**
 * __usePaginatedLiveProspectsQuery__
 *
 * To run a query within a React component, call `usePaginatedLiveProspectsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedLiveProspectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedLiveProspectsQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      registrationDateTimeFilter: // value for 'registrationDateTimeFilter'
 *      statuses: // value for 'statuses'
 *      searchedText: // value for 'searchedText'
 *      leadFilter: // value for 'leadFilter'
 *      pagination: // value for 'pagination'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function usePaginatedLiveProspectsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PaginatedLiveProspectsQuery,
    PaginatedLiveProspectsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    PaginatedLiveProspectsQuery,
    PaginatedLiveProspectsQueryVariables
  >(PaginatedLiveProspectsDocument, baseOptions)
}
export function usePaginatedLiveProspectsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PaginatedLiveProspectsQuery,
    PaginatedLiveProspectsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    PaginatedLiveProspectsQuery,
    PaginatedLiveProspectsQueryVariables
  >(PaginatedLiveProspectsDocument, baseOptions)
}
export type PaginatedLiveProspectsQueryHookResult = ReturnType<
  typeof usePaginatedLiveProspectsQuery
>
export type PaginatedLiveProspectsLazyQueryHookResult = ReturnType<
  typeof usePaginatedLiveProspectsLazyQuery
>
export type PaginatedLiveProspectsQueryResult = ApolloReactCommon.QueryResult<
  PaginatedLiveProspectsQuery,
  PaginatedLiveProspectsQueryVariables
>
export const MasterCommunitiesDocument = gql`
  query MasterCommunities($clientName: String!, $filter: CommonQueryFilter!) {
    masterCommunities(clientName: $clientName) {
      id
      name
      siteplan(clientName: $clientName) {
        id
        communityId
        name
        src
        lotWidth
        lotHeight
        lotFontSize
        lotMetric
        master
        svg {
          shapes {
            tagName
            attributes {
              className
              dataName
              x
              y
              width
              height
              transform
              points
              d
              __typename
            }
            __typename
          }
          style
          viewBox {
            x
            y
            width
            height
          }
        }
        lotLegend {
          id
          code
          name
          hex
        }
        popularLots(filter: $filter) {
          id
          communityId
          dataName
          name
          salesStatus
          premium
          externalId
          address
          size
          cityName
          stateCode
          zip
          postCode
          popularity
          count
          siteplanInfo {
            lotId
            siteplanId
            x
            y
          }
        }
      }
      communities(clientName: $clientName) {
        id
        name
        latitude
        longitude
        logo
        active
        siteplans(clientName: $clientName) {
          id
          communityId
          name
          src
          lotWidth
          lotHeight
          lotFontSize
          lotMetric
          master
          svg {
            shapes {
              tagName
              attributes {
                className
                dataName
                x
                y
                width
                height
                transform
                points
                d
                __typename
              }
              __typename
            }
            style
            viewBox {
              x
              y
              width
              height
            }
          }
          lotLegend {
            id
            code
            name
            hex
          }
          popularLots(filter: $filter) {
            id
            communityId
            dataName
            name
            salesStatus
            premium
            externalId
            address
            size
            cityName
            stateCode
            zip
            postCode
            popularity
            count
            siteplanInfo {
              lotId
              siteplanId
              x
              y
            }
          }
        }
      }
    }
  }
`

/**
 * __useMasterCommunitiesQuery__
 *
 * To run a query within a React component, call `useMasterCommunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMasterCommunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMasterCommunitiesQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMasterCommunitiesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    MasterCommunitiesQuery,
    MasterCommunitiesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    MasterCommunitiesQuery,
    MasterCommunitiesQueryVariables
  >(MasterCommunitiesDocument, baseOptions)
}
export function useMasterCommunitiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MasterCommunitiesQuery,
    MasterCommunitiesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    MasterCommunitiesQuery,
    MasterCommunitiesQueryVariables
  >(MasterCommunitiesDocument, baseOptions)
}
export type MasterCommunitiesQueryHookResult = ReturnType<
  typeof useMasterCommunitiesQuery
>
export type MasterCommunitiesLazyQueryHookResult = ReturnType<
  typeof useMasterCommunitiesLazyQuery
>
export type MasterCommunitiesQueryResult = ApolloReactCommon.QueryResult<
  MasterCommunitiesQuery,
  MasterCommunitiesQueryVariables
>
export const PlanDocument = gql`
  query Plan($clientName: String!, $planId: Int!) {
    plan(clientName: $clientName, planId: $planId) {
      name
      elevations(clientName: $clientName) {
        id
        caption
        internalName
        layers(clientName: $clientName) {
          materialId
          name
          src
        }
        mirrorElevationId
        svgMirroring
      }
    }
  }
`

/**
 * __usePlanQuery__
 *
 * To run a query within a React component, call `usePlanQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function usePlanQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PlanQuery, PlanQueryVariables>
) {
  return ApolloReactHooks.useQuery<PlanQuery, PlanQueryVariables>(
    PlanDocument,
    baseOptions
  )
}
export function usePlanLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PlanQuery,
    PlanQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<PlanQuery, PlanQueryVariables>(
    PlanDocument,
    baseOptions
  )
}
export type PlanQueryHookResult = ReturnType<typeof usePlanQuery>
export type PlanLazyQueryHookResult = ReturnType<typeof usePlanLazyQuery>
export type PlanQueryResult = ApolloReactCommon.QueryResult<
  PlanQuery,
  PlanQueryVariables
>
export const PlansWithImagesDocument = gql`
  query PlansWithImages($clientName: String!) {
    plans(clientName: $clientName) {
      id
      name
      displayName
      elevations(clientName: $clientName) {
        id
        caption
        internalName
        thumb
      }
      exteriorAssets(clientName: $clientName) {
        src
        listIndex
      }
      interiorPhotos(clientName: $clientName) {
        src
        listIndex
      }
    }
  }
`

/**
 * __usePlansWithImagesQuery__
 *
 * To run a query within a React component, call `usePlansWithImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlansWithImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlansWithImagesQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function usePlansWithImagesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PlansWithImagesQuery,
    PlansWithImagesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    PlansWithImagesQuery,
    PlansWithImagesQueryVariables
  >(PlansWithImagesDocument, baseOptions)
}
export function usePlansWithImagesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PlansWithImagesQuery,
    PlansWithImagesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    PlansWithImagesQuery,
    PlansWithImagesQueryVariables
  >(PlansWithImagesDocument, baseOptions)
}
export type PlansWithImagesQueryHookResult = ReturnType<
  typeof usePlansWithImagesQuery
>
export type PlansWithImagesLazyQueryHookResult = ReturnType<
  typeof usePlansWithImagesLazyQuery
>
export type PlansWithImagesQueryResult = ApolloReactCommon.QueryResult<
  PlansWithImagesQuery,
  PlansWithImagesQueryVariables
>
export const ProspectDocument = gql`
  query Prospect($clientName: String!, $email: String!) {
    colonnadeApiProspectByEmail(clientName: $clientName, email: $email) {
      id
      email
      name
      firstName
      lastName
      phone
      picture
      source
      status
      preferredContactMethods
      brochures {
        url
        creationDate
      }
      registrationDate
      consultantName
      consultantEmail
      consultantPhone
      clientRating
      favorites {
        id
        prospectId
        communityId
        planId
        elevationId
        lotId
        source
        clientName
        fpOptSelections
        lastUpdate
        lot(clientName: $clientName) {
          id
          name
          dataName
          salesStatus
          premium
          address
          size
          cityName
          stateCode
          zip
          postCode
          siteplan {
            lotMetric
          }
          siteplanInfo {
            x
            y
          }
        }
        scheme(clientName: $clientName) {
          id
          name
          materials {
            id
            materialId
            materialCategory
            material
            name
            hex
            swatch
            masonryLib
          }
        }
        plan(clientName: $clientName) {
          id
          clientName
          name
          displayName
          defaultElevationId
          bed
          bedMin
          bedMax
          bedRange {
            min
            max
          }
          bath
          bathMin
          bathMax
          bathRange {
            min
            max
          }
          size
          sizeMin
          sizeMax
          sizeRange {
            min
            max
          }
          cost
          costMin
          costMax
          costRange {
            min
            max
          }
          description
          elevations(clientName: $clientName) {
            id
            thumb
            active
            planId
            planName
            caption
          }
        }
        community(clientName: $clientName) {
          clientName
          id
          name
        }
      }
      statistics(clientName: $clientName) {
        user
        clientName
        lastSeen
        firstSeen
        totalEvents
        totalDuration
        averageDuration
        score
        hotLead
        numSessions
        numBrochuresCreated
        numBrochureDownloads
        numPlansShared
        numContactsUs
        numSignIns
        numAppointments
        country
        state
        city
        camberPlanElevation {
          timestamp
          planName
          elevationCaption
        }
        appointmentRequests {
          clientName
          timestamp
          agentEmails
          inventoryId
          preferredDates
          inventoryAddress
          imgUrl
          inventoryUrl
          inventory {
            clientName
            id
            lotId
            mlsUrl
            lot(clientName: $clientName) {
              name
              salesStatus
              address
              cityName
              stateCode
              countryCode
              zip
              postCode
            }
            plan(clientName: $clientName) {
              displayName
              name
            }
            elevation(clientName: $clientName) {
              id
              clientName
              caption
              bed
              bath
              size
              cost
            }
          }
        }
        communitiesStatistics {
          clientName
          communityName
          communityId
          timestamp
          events
          duration
        }
        plansStatistics {
          clientName
          communityName
          communityId
          planName
          planId
          elevationId
          elevationCaption
          timestamp
          events
          duration
          plan {
            id
            clientName
            name
            displayName
            defaultElevationId
            bed
            bedMin
            bedMax
            bedRange {
              min
              max
            }
            bath
            bathMin
            bathMax
            bathRange {
              min
              max
            }
            size
            sizeMin
            sizeMax
            sizeRange {
              min
              max
            }
            cost
            costMin
            costMax
            costRange {
              min
              max
            }
            description
            elevations(clientName: $clientName) {
              id
              thumb
              active
              planId
              planName
              caption
            }
          }
        }
        plansBrowsed {
          clientName
          communityName
          communityId
          planName
          planId
          elevationId
          elevationCaption
          timestamp
        }
        communitiesBrowsed {
          clientName
          communityName
          communityId
          events
          duration
          timestamp
        }
        selectedFloorplans {
          clientName
          floorplanName
          floorplanIds
          popularity
          floorplans {
            name
            clientName
            src
            popularity
            planName
          }
        }
        selectedColorSchemes {
          clientName
          schemeId
          scheme {
            id
            clientName
            name
            internalName
            materials {
              id
              materialId
              materialCategory
              material
              name
              hex
              swatch
              masonryLib
            }
          }
        }
        selectedMaterialColors {
          clientName
          materialId
          materialName
          materialCategory
          colorId
          colorName
          paletteSelectionId
          brickId
          stoneId
          colorMasonryLib
          customOverlayId
          source
          color {
            id
            name
            hex
          }
          texture {
            id
            library
            type
            color
            src
          }
          customOverlay {
            id
            clientName
            color
            src
          }
        }
        ctaClicks {
          communityId
          communityName
          planId
          planName
          elevationId
          elevationCaption
          event
          action
          timestamp
        }
        signIns {
          timestamp
          anonymousId
          userAction
          source
        }
        signInsGrouped {
          day
          count
        }
        favorites {
          id
          clientName
          communityName
          communityId
          planName
          planId
          elevationId
          elevationCaption
          event
          timestamp
        }
        brochuresCreated {
          clientName
          communityName
          communityId
          planName
          planId
          elevationId
          elevationCaption
          timestamp
        }
      }
    }
  }
`

/**
 * __useProspectQuery__
 *
 * To run a query within a React component, call `useProspectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProspectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProspectQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useProspectQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ProspectQuery,
    ProspectQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ProspectQuery, ProspectQueryVariables>(
    ProspectDocument,
    baseOptions
  )
}
export function useProspectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProspectQuery,
    ProspectQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<ProspectQuery, ProspectQueryVariables>(
    ProspectDocument,
    baseOptions
  )
}
export type ProspectQueryHookResult = ReturnType<typeof useProspectQuery>
export type ProspectLazyQueryHookResult = ReturnType<
  typeof useProspectLazyQuery
>
export type ProspectQueryResult = ApolloReactCommon.QueryResult<
  ProspectQuery,
  ProspectQueryVariables
>
export const ProspectWithInteractionsDocument = gql`
  query ProspectWithInteractions(
    $clientName: String!
    $email: String!
    $start: Timestamp!
    $end: Timestamp!
  ) {
    colonnadeApiProspectByEmail(clientName: $clientName, email: $email) {
      id
      email
      interactions(start: $start, end: $end) {
        id
        timestamp
        name
        contextPagePath
      }
    }
  }
`

/**
 * __useProspectWithInteractionsQuery__
 *
 * To run a query within a React component, call `useProspectWithInteractionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProspectWithInteractionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProspectWithInteractionsQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      email: // value for 'email'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useProspectWithInteractionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ProspectWithInteractionsQuery,
    ProspectWithInteractionsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ProspectWithInteractionsQuery,
    ProspectWithInteractionsQueryVariables
  >(ProspectWithInteractionsDocument, baseOptions)
}
export function useProspectWithInteractionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProspectWithInteractionsQuery,
    ProspectWithInteractionsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ProspectWithInteractionsQuery,
    ProspectWithInteractionsQueryVariables
  >(ProspectWithInteractionsDocument, baseOptions)
}
export type ProspectWithInteractionsQueryHookResult = ReturnType<
  typeof useProspectWithInteractionsQuery
>
export type ProspectWithInteractionsLazyQueryHookResult = ReturnType<
  typeof useProspectWithInteractionsLazyQuery
>
export type ProspectWithInteractionsQueryResult = ApolloReactCommon.QueryResult<
  ProspectWithInteractionsQuery,
  ProspectWithInteractionsQueryVariables
>
export const ProspectAgentDocument = gql`
  query prospectAgent($clientName: String!, $email: String!) {
    prospectByEmail(clientName: $clientName, email: $email) {
      agents {
        agentId
      }
    }
  }
`

/**
 * __useProspectAgentQuery__
 *
 * To run a query within a React component, call `useProspectAgentQuery` and pass it any options that fit your needs.
 * When your component renders, `useProspectAgentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProspectAgentQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useProspectAgentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ProspectAgentQuery,
    ProspectAgentQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ProspectAgentQuery,
    ProspectAgentQueryVariables
  >(ProspectAgentDocument, baseOptions)
}
export function useProspectAgentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProspectAgentQuery,
    ProspectAgentQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ProspectAgentQuery,
    ProspectAgentQueryVariables
  >(ProspectAgentDocument, baseOptions)
}
export type ProspectAgentQueryHookResult = ReturnType<
  typeof useProspectAgentQuery
>
export type ProspectAgentLazyQueryHookResult = ReturnType<
  typeof useProspectAgentLazyQuery
>
export type ProspectAgentQueryResult = ApolloReactCommon.QueryResult<
  ProspectAgentQuery,
  ProspectAgentQueryVariables
>
export const GetProspectActivitiesDocument = gql`
  query getProspectActivities($clientName: String!, $email: String!) {
    getProspectActivities(clientName: $clientName, email: $email)
  }
`

/**
 * __useGetProspectActivitiesQuery__
 *
 * To run a query within a React component, call `useGetProspectActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProspectActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProspectActivitiesQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetProspectActivitiesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetProspectActivitiesQuery,
    GetProspectActivitiesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetProspectActivitiesQuery,
    GetProspectActivitiesQueryVariables
  >(GetProspectActivitiesDocument, baseOptions)
}
export function useGetProspectActivitiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetProspectActivitiesQuery,
    GetProspectActivitiesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetProspectActivitiesQuery,
    GetProspectActivitiesQueryVariables
  >(GetProspectActivitiesDocument, baseOptions)
}
export type GetProspectActivitiesQueryHookResult = ReturnType<
  typeof useGetProspectActivitiesQuery
>
export type GetProspectActivitiesLazyQueryHookResult = ReturnType<
  typeof useGetProspectActivitiesLazyQuery
>
export type GetProspectActivitiesQueryResult = ApolloReactCommon.QueryResult<
  GetProspectActivitiesQuery,
  GetProspectActivitiesQueryVariables
>
export const GetProspectsDocument = gql`
  query getProspects($clientName: String!, $communityIds: [Int!]) {
    colonnadeApiProspect(
      clientName: $clientName
      communityIds: $communityIds
      excludeClientUsers: true
    ) {
      id
      email
      name
      firstName
      lastName
      registrationDate
      phone
      picture
      status
      preferredContactMethods
      agents {
        agentId
        prospectId
        agentName
        prospectName
        agentEmail
        prospectEmail
        prospectPhone
      }
      colonnadeAgents
    }
  }
`

/**
 * __useGetProspectsQuery__
 *
 * To run a query within a React component, call `useGetProspectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProspectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProspectsQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      communityIds: // value for 'communityIds'
 *   },
 * });
 */
export function useGetProspectsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetProspectsQuery,
    GetProspectsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetProspectsQuery,
    GetProspectsQueryVariables
  >(GetProspectsDocument, baseOptions)
}
export function useGetProspectsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetProspectsQuery,
    GetProspectsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetProspectsQuery,
    GetProspectsQueryVariables
  >(GetProspectsDocument, baseOptions)
}
export type GetProspectsQueryHookResult = ReturnType<
  typeof useGetProspectsQuery
>
export type GetProspectsLazyQueryHookResult = ReturnType<
  typeof useGetProspectsLazyQuery
>
export type GetProspectsQueryResult = ApolloReactCommon.QueryResult<
  GetProspectsQuery,
  GetProspectsQueryVariables
>
export const GetDropOfRateDocument = gql`
  query GET_DROP_OF_RATE(
    $clientName: String!
    $filter: ReservationDropOfFilterInput
  ) {
    getReservationsDropOfRate(clientName: $clientName, filter: $filter) {
      termsAndCondition
      signedDocument
      payment
      completed
    }
  }
`

/**
 * __useGetDropOfRateQuery__
 *
 * To run a query within a React component, call `useGetDropOfRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDropOfRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDropOfRateQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetDropOfRateQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetDropOfRateQuery,
    GetDropOfRateQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetDropOfRateQuery,
    GetDropOfRateQueryVariables
  >(GetDropOfRateDocument, baseOptions)
}
export function useGetDropOfRateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetDropOfRateQuery,
    GetDropOfRateQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetDropOfRateQuery,
    GetDropOfRateQueryVariables
  >(GetDropOfRateDocument, baseOptions)
}
export type GetDropOfRateQueryHookResult = ReturnType<
  typeof useGetDropOfRateQuery
>
export type GetDropOfRateLazyQueryHookResult = ReturnType<
  typeof useGetDropOfRateLazyQuery
>
export type GetDropOfRateQueryResult = ApolloReactCommon.QueryResult<
  GetDropOfRateQuery,
  GetDropOfRateQueryVariables
>
export const GetReservationStatusOverviewDocument = gql`
  query GET_RESERVATION_STATUS_OVERVIEW(
    $clientName: String!
    $filter: ReservationStatusOverviewFilterInput
  ) {
    getReservationStatusOverview(clientName: $clientName, filter: $filter) {
      inProgress
      approvedByBuilder
      rejectedByBuilder
      rejectedByProspect
    }
  }
`

/**
 * __useGetReservationStatusOverviewQuery__
 *
 * To run a query within a React component, call `useGetReservationStatusOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationStatusOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationStatusOverviewQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetReservationStatusOverviewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetReservationStatusOverviewQuery,
    GetReservationStatusOverviewQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetReservationStatusOverviewQuery,
    GetReservationStatusOverviewQueryVariables
  >(GetReservationStatusOverviewDocument, baseOptions)
}
export function useGetReservationStatusOverviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetReservationStatusOverviewQuery,
    GetReservationStatusOverviewQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetReservationStatusOverviewQuery,
    GetReservationStatusOverviewQueryVariables
  >(GetReservationStatusOverviewDocument, baseOptions)
}
export type GetReservationStatusOverviewQueryHookResult = ReturnType<
  typeof useGetReservationStatusOverviewQuery
>
export type GetReservationStatusOverviewLazyQueryHookResult = ReturnType<
  typeof useGetReservationStatusOverviewLazyQuery
>
export type GetReservationStatusOverviewQueryResult = ApolloReactCommon.QueryResult<
  GetReservationStatusOverviewQuery,
  GetReservationStatusOverviewQueryVariables
>
export const GetReservationStatusCommunitiesOverviewDocument = gql`
  query GET_RESERVATION_STATUS_COMMUNITIES_OVERVIEW(
    $clientName: String!
    $filter: ReservationStatusOverviewFilterInput
  ) {
    getReservationStatusCommunitiesOverview(
      clientName: $clientName
      filter: $filter
    ) {
      communityName
      status {
        inProgress
        approvedByBuilder
        rejectedByBuilder
        rejectedByProspect
      }
    }
  }
`

/**
 * __useGetReservationStatusCommunitiesOverviewQuery__
 *
 * To run a query within a React component, call `useGetReservationStatusCommunitiesOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationStatusCommunitiesOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationStatusCommunitiesOverviewQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetReservationStatusCommunitiesOverviewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetReservationStatusCommunitiesOverviewQuery,
    GetReservationStatusCommunitiesOverviewQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetReservationStatusCommunitiesOverviewQuery,
    GetReservationStatusCommunitiesOverviewQueryVariables
  >(GetReservationStatusCommunitiesOverviewDocument, baseOptions)
}
export function useGetReservationStatusCommunitiesOverviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetReservationStatusCommunitiesOverviewQuery,
    GetReservationStatusCommunitiesOverviewQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetReservationStatusCommunitiesOverviewQuery,
    GetReservationStatusCommunitiesOverviewQueryVariables
  >(GetReservationStatusCommunitiesOverviewDocument, baseOptions)
}
export type GetReservationStatusCommunitiesOverviewQueryHookResult = ReturnType<
  typeof useGetReservationStatusCommunitiesOverviewQuery
>
export type GetReservationStatusCommunitiesOverviewLazyQueryHookResult = ReturnType<
  typeof useGetReservationStatusCommunitiesOverviewLazyQuery
>
export type GetReservationStatusCommunitiesOverviewQueryResult = ApolloReactCommon.QueryResult<
  GetReservationStatusCommunitiesOverviewQuery,
  GetReservationStatusCommunitiesOverviewQueryVariables
>
export const GetClientReservationIntegrationDocument = gql`
  query GET_CLIENT_RESERVATION_INTEGRATION($clientId: Int!) {
    getReservationIntegration(clientId: $clientId) {
      id
      reservationIntegrationInfo {
        id
        stripeAccountId
        stripeAccountReady
        depositAmount
        nonRefundableAmount
        anewgoFee
        signingAgreementTemplateId
        allowMultipleReservations
        reservationConfig
        primaryBuyOnlineContactEmail
        primaryBuyOnlineContactName
        daysToProveFinancing
        envelopeExpireDays
        onboardingComplete
        additionalBuilders {
          name
          email
        }
      }
    }
  }
`

/**
 * __useGetClientReservationIntegrationQuery__
 *
 * To run a query within a React component, call `useGetClientReservationIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientReservationIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientReservationIntegrationQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetClientReservationIntegrationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetClientReservationIntegrationQuery,
    GetClientReservationIntegrationQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetClientReservationIntegrationQuery,
    GetClientReservationIntegrationQueryVariables
  >(GetClientReservationIntegrationDocument, baseOptions)
}
export function useGetClientReservationIntegrationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetClientReservationIntegrationQuery,
    GetClientReservationIntegrationQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetClientReservationIntegrationQuery,
    GetClientReservationIntegrationQueryVariables
  >(GetClientReservationIntegrationDocument, baseOptions)
}
export type GetClientReservationIntegrationQueryHookResult = ReturnType<
  typeof useGetClientReservationIntegrationQuery
>
export type GetClientReservationIntegrationLazyQueryHookResult = ReturnType<
  typeof useGetClientReservationIntegrationLazyQuery
>
export type GetClientReservationIntegrationQueryResult = ApolloReactCommon.QueryResult<
  GetClientReservationIntegrationQuery,
  GetClientReservationIntegrationQueryVariables
>
export const GetAllProspectReservationsDocument = gql`
  query GET_ALL_PROSPECT_RESERVATIONS(
    $clientName: String!
    $filter: OnlineReservationFilterInput
  ) {
    getOnlineReservations(clientName: $clientName, filter: $filter) {
      id
      primaryBuyerId
      favoriteId
      reservationStatus
      prospectId
      rejectReason
      isDeleted
      createdAt
      totalPrice
      favorite {
        id
        elevation(clientName: $clientName) {
          id
          planName
          caption
        }
        community(clientName: $clientName) {
          id
          name
        }
        lot(clientName: $clientName) {
          id
          name
        }
      }
      prospect {
        id
        email
        firstName
        lastName
        phone
        name
      }
      errorArray
      signatureDate
      paymentDate
      signTransactionId
      paymentsTransactionId
      secondaryBuyerReservations {
        signatureDate
        signTransactionId
        prospectId
        prospect {
          id
          email
          name
          firstName
          lastName
          phone
        }
      }
    }
  }
`

/**
 * __useGetAllProspectReservationsQuery__
 *
 * To run a query within a React component, call `useGetAllProspectReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProspectReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProspectReservationsQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAllProspectReservationsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetAllProspectReservationsQuery,
    GetAllProspectReservationsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetAllProspectReservationsQuery,
    GetAllProspectReservationsQueryVariables
  >(GetAllProspectReservationsDocument, baseOptions)
}
export function useGetAllProspectReservationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllProspectReservationsQuery,
    GetAllProspectReservationsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetAllProspectReservationsQuery,
    GetAllProspectReservationsQueryVariables
  >(GetAllProspectReservationsDocument, baseOptions)
}
export type GetAllProspectReservationsQueryHookResult = ReturnType<
  typeof useGetAllProspectReservationsQuery
>
export type GetAllProspectReservationsLazyQueryHookResult = ReturnType<
  typeof useGetAllProspectReservationsLazyQuery
>
export type GetAllProspectReservationsQueryResult = ApolloReactCommon.QueryResult<
  GetAllProspectReservationsQuery,
  GetAllProspectReservationsQueryVariables
>
export const AddOnlineReservationIntegrationDocument = gql`
  mutation ADD_ONLINE_RESERVATION_INTEGRATION($clientId: Int!) {
    createReservationIntegration(clientId: $clientId)
  }
`
export type AddOnlineReservationIntegrationMutationFn = ApolloReactCommon.MutationFunction<
  AddOnlineReservationIntegrationMutation,
  AddOnlineReservationIntegrationMutationVariables
>

/**
 * __useAddOnlineReservationIntegrationMutation__
 *
 * To run a mutation, you first call `useAddOnlineReservationIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOnlineReservationIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOnlineReservationIntegrationMutation, { data, loading, error }] = useAddOnlineReservationIntegrationMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useAddOnlineReservationIntegrationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddOnlineReservationIntegrationMutation,
    AddOnlineReservationIntegrationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddOnlineReservationIntegrationMutation,
    AddOnlineReservationIntegrationMutationVariables
  >(AddOnlineReservationIntegrationDocument, baseOptions)
}
export type AddOnlineReservationIntegrationMutationHookResult = ReturnType<
  typeof useAddOnlineReservationIntegrationMutation
>
export type AddOnlineReservationIntegrationMutationResult = ApolloReactCommon.MutationResult<AddOnlineReservationIntegrationMutation>
export type AddOnlineReservationIntegrationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddOnlineReservationIntegrationMutation,
  AddOnlineReservationIntegrationMutationVariables
>
export const RejectReservationDocument = gql`
  mutation REJECT_RESERVATION(
    $clientName: String!
    $reservationId: UUID!
    $rejectReason: String
  ) {
    rejectOnlineReservation(
      clientName: $clientName
      reservationId: $reservationId
      rejectReason: $rejectReason
    ) {
      id
      reservationStatus
    }
  }
`
export type RejectReservationMutationFn = ApolloReactCommon.MutationFunction<
  RejectReservationMutation,
  RejectReservationMutationVariables
>

/**
 * __useRejectReservationMutation__
 *
 * To run a mutation, you first call `useRejectReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectReservationMutation, { data, loading, error }] = useRejectReservationMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      reservationId: // value for 'reservationId'
 *      rejectReason: // value for 'rejectReason'
 *   },
 * });
 */
export function useRejectReservationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RejectReservationMutation,
    RejectReservationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RejectReservationMutation,
    RejectReservationMutationVariables
  >(RejectReservationDocument, baseOptions)
}
export type RejectReservationMutationHookResult = ReturnType<
  typeof useRejectReservationMutation
>
export type RejectReservationMutationResult = ApolloReactCommon.MutationResult<RejectReservationMutation>
export type RejectReservationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RejectReservationMutation,
  RejectReservationMutationVariables
>
export const UpdateOnlineReservationIntegrationInfoDocument = gql`
  mutation UPDATE_ONLINE_RESERVATION_INTEGRATION_INFO(
    $clientId: Int!
    $input: UpdateOnlineReservationIntegrationInput!
  ) {
    updateReservationIntegrationInfo(clientId: $clientId, input: $input) {
      id
      reservationIntegrationInfo {
        id
        stripeAccountId
        depositAmount
        nonRefundableAmount
        anewgoFee
        signingAgreementTemplateId
        allowMultipleReservations
        reservationConfig
        primaryBuyOnlineContactEmail
        primaryBuyOnlineContactName
        daysToProveFinancing
        envelopeExpireDays
        additionalBuilders {
          email
          name
        }
      }
    }
  }
`
export type UpdateOnlineReservationIntegrationInfoMutationFn = ApolloReactCommon.MutationFunction<
  UpdateOnlineReservationIntegrationInfoMutation,
  UpdateOnlineReservationIntegrationInfoMutationVariables
>

/**
 * __useUpdateOnlineReservationIntegrationInfoMutation__
 *
 * To run a mutation, you first call `useUpdateOnlineReservationIntegrationInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnlineReservationIntegrationInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnlineReservationIntegrationInfoMutation, { data, loading, error }] = useUpdateOnlineReservationIntegrationInfoMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOnlineReservationIntegrationInfoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateOnlineReservationIntegrationInfoMutation,
    UpdateOnlineReservationIntegrationInfoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateOnlineReservationIntegrationInfoMutation,
    UpdateOnlineReservationIntegrationInfoMutationVariables
  >(UpdateOnlineReservationIntegrationInfoDocument, baseOptions)
}
export type UpdateOnlineReservationIntegrationInfoMutationHookResult = ReturnType<
  typeof useUpdateOnlineReservationIntegrationInfoMutation
>
export type UpdateOnlineReservationIntegrationInfoMutationResult = ApolloReactCommon.MutationResult<UpdateOnlineReservationIntegrationInfoMutation>
export type UpdateOnlineReservationIntegrationInfoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateOnlineReservationIntegrationInfoMutation,
  UpdateOnlineReservationIntegrationInfoMutationVariables
>
export const CreateStripeAccountDocument = gql`
  mutation CREATE_STRIPE_ACCOUNT($clientId: Int!) {
    createStripeAccount(clientId: $clientId)
  }
`
export type CreateStripeAccountMutationFn = ApolloReactCommon.MutationFunction<
  CreateStripeAccountMutation,
  CreateStripeAccountMutationVariables
>

/**
 * __useCreateStripeAccountMutation__
 *
 * To run a mutation, you first call `useCreateStripeAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeAccountMutation, { data, loading, error }] = useCreateStripeAccountMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useCreateStripeAccountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateStripeAccountMutation,
    CreateStripeAccountMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateStripeAccountMutation,
    CreateStripeAccountMutationVariables
  >(CreateStripeAccountDocument, baseOptions)
}
export type CreateStripeAccountMutationHookResult = ReturnType<
  typeof useCreateStripeAccountMutation
>
export type CreateStripeAccountMutationResult = ApolloReactCommon.MutationResult<CreateStripeAccountMutation>
export type CreateStripeAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateStripeAccountMutation,
  CreateStripeAccountMutationVariables
>
export const GetDocumentPreviewUrlDocument = gql`
  query GET_DOCUMENT_PREVIEW_URL($clientId: Int!) {
    getDocuSignTemplatePreview(clientId: $clientId)
  }
`

/**
 * __useGetDocumentPreviewUrlQuery__
 *
 * To run a query within a React component, call `useGetDocumentPreviewUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentPreviewUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentPreviewUrlQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetDocumentPreviewUrlQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetDocumentPreviewUrlQuery,
    GetDocumentPreviewUrlQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetDocumentPreviewUrlQuery,
    GetDocumentPreviewUrlQueryVariables
  >(GetDocumentPreviewUrlDocument, baseOptions)
}
export function useGetDocumentPreviewUrlLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetDocumentPreviewUrlQuery,
    GetDocumentPreviewUrlQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetDocumentPreviewUrlQuery,
    GetDocumentPreviewUrlQueryVariables
  >(GetDocumentPreviewUrlDocument, baseOptions)
}
export type GetDocumentPreviewUrlQueryHookResult = ReturnType<
  typeof useGetDocumentPreviewUrlQuery
>
export type GetDocumentPreviewUrlLazyQueryHookResult = ReturnType<
  typeof useGetDocumentPreviewUrlLazyQuery
>
export type GetDocumentPreviewUrlQueryResult = ApolloReactCommon.QueryResult<
  GetDocumentPreviewUrlQuery,
  GetDocumentPreviewUrlQueryVariables
>
export const GetBuyerDocumentLinkDocument = gql`
  query GET_BUYER_DOCUMENT_LINK($clientName: String!, $reservationId: UUID!) {
    getDocumentLinkForBuilder(
      clientName: $clientName
      reservationId: $reservationId
    )
  }
`

/**
 * __useGetBuyerDocumentLinkQuery__
 *
 * To run a query within a React component, call `useGetBuyerDocumentLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuyerDocumentLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuyerDocumentLinkQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useGetBuyerDocumentLinkQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetBuyerDocumentLinkQuery,
    GetBuyerDocumentLinkQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetBuyerDocumentLinkQuery,
    GetBuyerDocumentLinkQueryVariables
  >(GetBuyerDocumentLinkDocument, baseOptions)
}
export function useGetBuyerDocumentLinkLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetBuyerDocumentLinkQuery,
    GetBuyerDocumentLinkQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetBuyerDocumentLinkQuery,
    GetBuyerDocumentLinkQueryVariables
  >(GetBuyerDocumentLinkDocument, baseOptions)
}
export type GetBuyerDocumentLinkQueryHookResult = ReturnType<
  typeof useGetBuyerDocumentLinkQuery
>
export type GetBuyerDocumentLinkLazyQueryHookResult = ReturnType<
  typeof useGetBuyerDocumentLinkLazyQuery
>
export type GetBuyerDocumentLinkQueryResult = ApolloReactCommon.QueryResult<
  GetBuyerDocumentLinkQuery,
  GetBuyerDocumentLinkQueryVariables
>
export const UpdateReservationCustomTemplateFieldsDocument = gql`
  mutation UPDATE_RESERVATION_CUSTOM_TEMPLATE_FIELDS(
    $clientId: Int!
    $templateId: String!
  ) {
    updateReservationCustomTemplateFields(
      clientId: $clientId
      templateId: $templateId
    )
  }
`
export type UpdateReservationCustomTemplateFieldsMutationFn = ApolloReactCommon.MutationFunction<
  UpdateReservationCustomTemplateFieldsMutation,
  UpdateReservationCustomTemplateFieldsMutationVariables
>

/**
 * __useUpdateReservationCustomTemplateFieldsMutation__
 *
 * To run a mutation, you first call `useUpdateReservationCustomTemplateFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReservationCustomTemplateFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReservationCustomTemplateFieldsMutation, { data, loading, error }] = useUpdateReservationCustomTemplateFieldsMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useUpdateReservationCustomTemplateFieldsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateReservationCustomTemplateFieldsMutation,
    UpdateReservationCustomTemplateFieldsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateReservationCustomTemplateFieldsMutation,
    UpdateReservationCustomTemplateFieldsMutationVariables
  >(UpdateReservationCustomTemplateFieldsDocument, baseOptions)
}
export type UpdateReservationCustomTemplateFieldsMutationHookResult = ReturnType<
  typeof useUpdateReservationCustomTemplateFieldsMutation
>
export type UpdateReservationCustomTemplateFieldsMutationResult = ApolloReactCommon.MutationResult<UpdateReservationCustomTemplateFieldsMutation>
export type UpdateReservationCustomTemplateFieldsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateReservationCustomTemplateFieldsMutation,
  UpdateReservationCustomTemplateFieldsMutationVariables
>
export const ToggleCommunityByOnlineEnabledDocument = gql`
  mutation toggleCommunityByOnlineEnabled(
    $clientName: String!
    $communityId: Int!
    $enabled: Boolean!
  ) {
    toggleCommunityBuyOnlineEnabled(
      clientName: $clientName
      communityId: $communityId
      enabled: $enabled
    )
  }
`
export type ToggleCommunityByOnlineEnabledMutationFn = ApolloReactCommon.MutationFunction<
  ToggleCommunityByOnlineEnabledMutation,
  ToggleCommunityByOnlineEnabledMutationVariables
>

/**
 * __useToggleCommunityByOnlineEnabledMutation__
 *
 * To run a mutation, you first call `useToggleCommunityByOnlineEnabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleCommunityByOnlineEnabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleCommunityByOnlineEnabledMutation, { data, loading, error }] = useToggleCommunityByOnlineEnabledMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      communityId: // value for 'communityId'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useToggleCommunityByOnlineEnabledMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ToggleCommunityByOnlineEnabledMutation,
    ToggleCommunityByOnlineEnabledMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ToggleCommunityByOnlineEnabledMutation,
    ToggleCommunityByOnlineEnabledMutationVariables
  >(ToggleCommunityByOnlineEnabledDocument, baseOptions)
}
export type ToggleCommunityByOnlineEnabledMutationHookResult = ReturnType<
  typeof useToggleCommunityByOnlineEnabledMutation
>
export type ToggleCommunityByOnlineEnabledMutationResult = ApolloReactCommon.MutationResult<ToggleCommunityByOnlineEnabledMutation>
export type ToggleCommunityByOnlineEnabledMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ToggleCommunityByOnlineEnabledMutation,
  ToggleCommunityByOnlineEnabledMutationVariables
>
export const ToggleByOnlineEnabledDocument = gql`
  mutation toggleByOnlineEnabled($clientId: Int!, $enabled: Boolean!) {
    toggleBuyOnlineEnabled(clientId: $clientId, enabled: $enabled)
  }
`
export type ToggleByOnlineEnabledMutationFn = ApolloReactCommon.MutationFunction<
  ToggleByOnlineEnabledMutation,
  ToggleByOnlineEnabledMutationVariables
>

/**
 * __useToggleByOnlineEnabledMutation__
 *
 * To run a mutation, you first call `useToggleByOnlineEnabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleByOnlineEnabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleByOnlineEnabledMutation, { data, loading, error }] = useToggleByOnlineEnabledMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useToggleByOnlineEnabledMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ToggleByOnlineEnabledMutation,
    ToggleByOnlineEnabledMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ToggleByOnlineEnabledMutation,
    ToggleByOnlineEnabledMutationVariables
  >(ToggleByOnlineEnabledDocument, baseOptions)
}
export type ToggleByOnlineEnabledMutationHookResult = ReturnType<
  typeof useToggleByOnlineEnabledMutation
>
export type ToggleByOnlineEnabledMutationResult = ApolloReactCommon.MutationResult<ToggleByOnlineEnabledMutation>
export type ToggleByOnlineEnabledMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ToggleByOnlineEnabledMutation,
  ToggleByOnlineEnabledMutationVariables
>
export const ToggleAllowMultipleReservationsDocument = gql`
  mutation toggleAllowMultipleReservations(
    $clientId: Int!
    $enabled: Boolean!
  ) {
    toggleAllowMultipleReservations(clientId: $clientId, enabled: $enabled)
  }
`
export type ToggleAllowMultipleReservationsMutationFn = ApolloReactCommon.MutationFunction<
  ToggleAllowMultipleReservationsMutation,
  ToggleAllowMultipleReservationsMutationVariables
>

/**
 * __useToggleAllowMultipleReservationsMutation__
 *
 * To run a mutation, you first call `useToggleAllowMultipleReservationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleAllowMultipleReservationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleAllowMultipleReservationsMutation, { data, loading, error }] = useToggleAllowMultipleReservationsMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useToggleAllowMultipleReservationsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ToggleAllowMultipleReservationsMutation,
    ToggleAllowMultipleReservationsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ToggleAllowMultipleReservationsMutation,
    ToggleAllowMultipleReservationsMutationVariables
  >(ToggleAllowMultipleReservationsDocument, baseOptions)
}
export type ToggleAllowMultipleReservationsMutationHookResult = ReturnType<
  typeof useToggleAllowMultipleReservationsMutation
>
export type ToggleAllowMultipleReservationsMutationResult = ApolloReactCommon.MutationResult<ToggleAllowMultipleReservationsMutation>
export type ToggleAllowMultipleReservationsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ToggleAllowMultipleReservationsMutation,
  ToggleAllowMultipleReservationsMutationVariables
>
export const AddOrUpdateCommunityBuyOnlineCustomConfigurationTemplateValuesDocument = gql`
  mutation ADD_OR_UPDATE_COMMUNITY_BUY_ONLINE_CUSTOM_CONFIGURATION_TEMPLATE_VALUES(
    $clientName: String!
    $communityId: Int!
    $configValues: JSON!
  ) {
    addOrUpdateCommunityBuyOnlineCustomConfigTemplateValues(
      clientName: $clientName
      communityId: $communityId
      configValues: $configValues
    )
  }
`
export type AddOrUpdateCommunityBuyOnlineCustomConfigurationTemplateValuesMutationFn = ApolloReactCommon.MutationFunction<
  AddOrUpdateCommunityBuyOnlineCustomConfigurationTemplateValuesMutation,
  AddOrUpdateCommunityBuyOnlineCustomConfigurationTemplateValuesMutationVariables
>

/**
 * __useAddOrUpdateCommunityBuyOnlineCustomConfigurationTemplateValuesMutation__
 *
 * To run a mutation, you first call `useAddOrUpdateCommunityBuyOnlineCustomConfigurationTemplateValuesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrUpdateCommunityBuyOnlineCustomConfigurationTemplateValuesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrUpdateCommunityBuyOnlineCustomConfigurationTemplateValuesMutation, { data, loading, error }] = useAddOrUpdateCommunityBuyOnlineCustomConfigurationTemplateValuesMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      communityId: // value for 'communityId'
 *      configValues: // value for 'configValues'
 *   },
 * });
 */
export function useAddOrUpdateCommunityBuyOnlineCustomConfigurationTemplateValuesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddOrUpdateCommunityBuyOnlineCustomConfigurationTemplateValuesMutation,
    AddOrUpdateCommunityBuyOnlineCustomConfigurationTemplateValuesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddOrUpdateCommunityBuyOnlineCustomConfigurationTemplateValuesMutation,
    AddOrUpdateCommunityBuyOnlineCustomConfigurationTemplateValuesMutationVariables
  >(
    AddOrUpdateCommunityBuyOnlineCustomConfigurationTemplateValuesDocument,
    baseOptions
  )
}
export type AddOrUpdateCommunityBuyOnlineCustomConfigurationTemplateValuesMutationHookResult = ReturnType<
  typeof useAddOrUpdateCommunityBuyOnlineCustomConfigurationTemplateValuesMutation
>
export type AddOrUpdateCommunityBuyOnlineCustomConfigurationTemplateValuesMutationResult = ApolloReactCommon.MutationResult<AddOrUpdateCommunityBuyOnlineCustomConfigurationTemplateValuesMutation>
export type AddOrUpdateCommunityBuyOnlineCustomConfigurationTemplateValuesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddOrUpdateCommunityBuyOnlineCustomConfigurationTemplateValuesMutation,
  AddOrUpdateCommunityBuyOnlineCustomConfigurationTemplateValuesMutationVariables
>
export const GetCommunityBuyOnlineCustomConfigDocument = gql`
  query GET_COMMUNITY_BUY_ONLINE_CUSTOM_CONFIG(
    $clientName: String!
    $communityId: Int!
  ) {
    getCommunityBuyOnlineCustomConfig(
      clientName: $clientName
      communityId: $communityId
    ) {
      communityId
      buyOnlineConfig
      buyOnlineEnabled
    }
  }
`

/**
 * __useGetCommunityBuyOnlineCustomConfigQuery__
 *
 * To run a query within a React component, call `useGetCommunityBuyOnlineCustomConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityBuyOnlineCustomConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityBuyOnlineCustomConfigQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useGetCommunityBuyOnlineCustomConfigQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetCommunityBuyOnlineCustomConfigQuery,
    GetCommunityBuyOnlineCustomConfigQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCommunityBuyOnlineCustomConfigQuery,
    GetCommunityBuyOnlineCustomConfigQueryVariables
  >(GetCommunityBuyOnlineCustomConfigDocument, baseOptions)
}
export function useGetCommunityBuyOnlineCustomConfigLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCommunityBuyOnlineCustomConfigQuery,
    GetCommunityBuyOnlineCustomConfigQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetCommunityBuyOnlineCustomConfigQuery,
    GetCommunityBuyOnlineCustomConfigQueryVariables
  >(GetCommunityBuyOnlineCustomConfigDocument, baseOptions)
}
export type GetCommunityBuyOnlineCustomConfigQueryHookResult = ReturnType<
  typeof useGetCommunityBuyOnlineCustomConfigQuery
>
export type GetCommunityBuyOnlineCustomConfigLazyQueryHookResult = ReturnType<
  typeof useGetCommunityBuyOnlineCustomConfigLazyQuery
>
export type GetCommunityBuyOnlineCustomConfigQueryResult = ApolloReactCommon.QueryResult<
  GetCommunityBuyOnlineCustomConfigQuery,
  GetCommunityBuyOnlineCustomConfigQueryVariables
>
export const DeleteOnlineReservationDocument = gql`
  mutation DELETE_ONLINE_RESERVATION(
    $clientName: String!
    $reservationId: UUID!
  ) {
    deleteOnlineReservation(
      clientName: $clientName
      reservationId: $reservationId
    )
  }
`
export type DeleteOnlineReservationMutationFn = ApolloReactCommon.MutationFunction<
  DeleteOnlineReservationMutation,
  DeleteOnlineReservationMutationVariables
>

/**
 * __useDeleteOnlineReservationMutation__
 *
 * To run a mutation, you first call `useDeleteOnlineReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOnlineReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOnlineReservationMutation, { data, loading, error }] = useDeleteOnlineReservationMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useDeleteOnlineReservationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteOnlineReservationMutation,
    DeleteOnlineReservationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteOnlineReservationMutation,
    DeleteOnlineReservationMutationVariables
  >(DeleteOnlineReservationDocument, baseOptions)
}
export type DeleteOnlineReservationMutationHookResult = ReturnType<
  typeof useDeleteOnlineReservationMutation
>
export type DeleteOnlineReservationMutationResult = ApolloReactCommon.MutationResult<DeleteOnlineReservationMutation>
export type DeleteOnlineReservationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteOnlineReservationMutation,
  DeleteOnlineReservationMutationVariables
>
export const HasCommunityInProgressReservationDocument = gql`
  query HAS_COMMUNITY_IN_PROGRESS_RESERVATION(
    $clientName: String!
    $communityId: Int!
  ) {
    hasCommunityInProgressReservation(
      clientName: $clientName
      communityId: $communityId
    )
  }
`

/**
 * __useHasCommunityInProgressReservationQuery__
 *
 * To run a query within a React component, call `useHasCommunityInProgressReservationQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasCommunityInProgressReservationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasCommunityInProgressReservationQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useHasCommunityInProgressReservationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    HasCommunityInProgressReservationQuery,
    HasCommunityInProgressReservationQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    HasCommunityInProgressReservationQuery,
    HasCommunityInProgressReservationQueryVariables
  >(HasCommunityInProgressReservationDocument, baseOptions)
}
export function useHasCommunityInProgressReservationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    HasCommunityInProgressReservationQuery,
    HasCommunityInProgressReservationQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    HasCommunityInProgressReservationQuery,
    HasCommunityInProgressReservationQueryVariables
  >(HasCommunityInProgressReservationDocument, baseOptions)
}
export type HasCommunityInProgressReservationQueryHookResult = ReturnType<
  typeof useHasCommunityInProgressReservationQuery
>
export type HasCommunityInProgressReservationLazyQueryHookResult = ReturnType<
  typeof useHasCommunityInProgressReservationLazyQuery
>
export type HasCommunityInProgressReservationQueryResult = ApolloReactCommon.QueryResult<
  HasCommunityInProgressReservationQuery,
  HasCommunityInProgressReservationQueryVariables
>
export const IsLotSignLockedDocument = gql`
  query IS_LOT_SIGN_LOCKED(
    $clientName: String!
    $communityId: Int!
    $lotId: Int!
  ) {
    getIsLotSignLocked(
      clientName: $clientName
      communityId: $communityId
      lotId: $lotId
    ) {
      isLocked
      lockedBy
    }
  }
`

/**
 * __useIsLotSignLockedQuery__
 *
 * To run a query within a React component, call `useIsLotSignLockedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsLotSignLockedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsLotSignLockedQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      communityId: // value for 'communityId'
 *      lotId: // value for 'lotId'
 *   },
 * });
 */
export function useIsLotSignLockedQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IsLotSignLockedQuery,
    IsLotSignLockedQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IsLotSignLockedQuery,
    IsLotSignLockedQueryVariables
  >(IsLotSignLockedDocument, baseOptions)
}
export function useIsLotSignLockedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IsLotSignLockedQuery,
    IsLotSignLockedQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IsLotSignLockedQuery,
    IsLotSignLockedQueryVariables
  >(IsLotSignLockedDocument, baseOptions)
}
export type IsLotSignLockedQueryHookResult = ReturnType<
  typeof useIsLotSignLockedQuery
>
export type IsLotSignLockedLazyQueryHookResult = ReturnType<
  typeof useIsLotSignLockedLazyQuery
>
export type IsLotSignLockedQueryResult = ApolloReactCommon.QueryResult<
  IsLotSignLockedQuery,
  IsLotSignLockedQueryVariables
>
export const LockLotSignDocument = gql`
  mutation LOCK_LOT_SIGN(
    $clientName: String!
    $communityId: Int!
    $lotId: Int!
    $prospectEmail: String!
  ) {
    lockLotSign(
      clientName: $clientName
      communityId: $communityId
      lotId: $lotId
      prospectEmail: $prospectEmail
    )
  }
`
export type LockLotSignMutationFn = ApolloReactCommon.MutationFunction<
  LockLotSignMutation,
  LockLotSignMutationVariables
>

/**
 * __useLockLotSignMutation__
 *
 * To run a mutation, you first call `useLockLotSignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLockLotSignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lockLotSignMutation, { data, loading, error }] = useLockLotSignMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      communityId: // value for 'communityId'
 *      lotId: // value for 'lotId'
 *      prospectEmail: // value for 'prospectEmail'
 *   },
 * });
 */
export function useLockLotSignMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LockLotSignMutation,
    LockLotSignMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    LockLotSignMutation,
    LockLotSignMutationVariables
  >(LockLotSignDocument, baseOptions)
}
export type LockLotSignMutationHookResult = ReturnType<
  typeof useLockLotSignMutation
>
export type LockLotSignMutationResult = ApolloReactCommon.MutationResult<LockLotSignMutation>
export type LockLotSignMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LockLotSignMutation,
  LockLotSignMutationVariables
>
export const GetAllLocationsDocument = gql`
  query getAllLocations {
    getAllLocations {
      clientName
      countryCode
      stateCode
      region
      city
    }
  }
`

/**
 * __useGetAllLocationsQuery__
 *
 * To run a query within a React component, call `useGetAllLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllLocationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAllLocationsQuery,
    GetAllLocationsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetAllLocationsQuery,
    GetAllLocationsQueryVariables
  >(GetAllLocationsDocument, baseOptions)
}
export function useGetAllLocationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllLocationsQuery,
    GetAllLocationsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetAllLocationsQuery,
    GetAllLocationsQueryVariables
  >(GetAllLocationsDocument, baseOptions)
}
export type GetAllLocationsQueryHookResult = ReturnType<
  typeof useGetAllLocationsQuery
>
export type GetAllLocationsLazyQueryHookResult = ReturnType<
  typeof useGetAllLocationsLazyQuery
>
export type GetAllLocationsQueryResult = ApolloReactCommon.QueryResult<
  GetAllLocationsQuery,
  GetAllLocationsQueryVariables
>
export const GetCamberElevationTrendsDocument = gql`
  query GetCamberElevationTrends($filter: CommonQueryFilter!) {
    getCamberElevationTrends(filter: $filter) {
      clientName
      startTimestamp
      endTimestamp
      elevations {
        clientName
        planId
        elevationId
        elevation {
          id
          planId
          planName
          caption
        }
        dailyMetrics {
          frames {
            ... on EventBasedTimeSeriesFrame {
              date
              value
            }
            ... on SessionBasedTimeSeriesFrame {
              date
              durationSum
              durationAvg
              durationMin
              durationMax
              durationMedian
              eventsCount
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetCamberElevationTrendsQuery__
 *
 * To run a query within a React component, call `useGetCamberElevationTrendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCamberElevationTrendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCamberElevationTrendsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCamberElevationTrendsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetCamberElevationTrendsQuery,
    GetCamberElevationTrendsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCamberElevationTrendsQuery,
    GetCamberElevationTrendsQueryVariables
  >(GetCamberElevationTrendsDocument, baseOptions)
}
export function useGetCamberElevationTrendsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCamberElevationTrendsQuery,
    GetCamberElevationTrendsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetCamberElevationTrendsQuery,
    GetCamberElevationTrendsQueryVariables
  >(GetCamberElevationTrendsDocument, baseOptions)
}
export type GetCamberElevationTrendsQueryHookResult = ReturnType<
  typeof useGetCamberElevationTrendsQuery
>
export type GetCamberElevationTrendsLazyQueryHookResult = ReturnType<
  typeof useGetCamberElevationTrendsLazyQuery
>
export type GetCamberElevationTrendsQueryResult = ApolloReactCommon.QueryResult<
  GetCamberElevationTrendsQuery,
  GetCamberElevationTrendsQueryVariables
>
export const GetCamberFloorplanTrendsDocument = gql`
  query GetCamberFloorplanTrends($filter: CommonQueryFilter!) {
    getCamberFloorplanTrends(filter: $filter) {
      clientName
      startTimestamp
      endTimestamp
      floorplans {
        clientName
        planId
        planName
        floorplanName
        dailyMetrics {
          frames {
            ... on EventBasedTimeSeriesFrame {
              date
              value
            }
            ... on SessionBasedTimeSeriesFrame {
              date
              durationSum
              durationAvg
              durationMin
              durationMax
              durationMedian
              eventsCount
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetCamberFloorplanTrendsQuery__
 *
 * To run a query within a React component, call `useGetCamberFloorplanTrendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCamberFloorplanTrendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCamberFloorplanTrendsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCamberFloorplanTrendsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetCamberFloorplanTrendsQuery,
    GetCamberFloorplanTrendsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCamberFloorplanTrendsQuery,
    GetCamberFloorplanTrendsQueryVariables
  >(GetCamberFloorplanTrendsDocument, baseOptions)
}
export function useGetCamberFloorplanTrendsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCamberFloorplanTrendsQuery,
    GetCamberFloorplanTrendsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetCamberFloorplanTrendsQuery,
    GetCamberFloorplanTrendsQueryVariables
  >(GetCamberFloorplanTrendsDocument, baseOptions)
}
export type GetCamberFloorplanTrendsQueryHookResult = ReturnType<
  typeof useGetCamberFloorplanTrendsQuery
>
export type GetCamberFloorplanTrendsLazyQueryHookResult = ReturnType<
  typeof useGetCamberFloorplanTrendsLazyQuery
>
export type GetCamberFloorplanTrendsQueryResult = ApolloReactCommon.QueryResult<
  GetCamberFloorplanTrendsQuery,
  GetCamberFloorplanTrendsQueryVariables
>
export const CamberPopularColorSchemesDocument = gql`
  query CamberPopularColorSchemes($filter: CommonQueryFilter!) {
    camberPopularColorSchemes(filter: $filter) {
      id
      name
      clientName
      count
      popularity
      materials {
        id
        materialId
        materialCategory
        material
        name
        hex
        swatch
        masonryLib
      }
    }
  }
`

/**
 * __useCamberPopularColorSchemesQuery__
 *
 * To run a query within a React component, call `useCamberPopularColorSchemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCamberPopularColorSchemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCamberPopularColorSchemesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCamberPopularColorSchemesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CamberPopularColorSchemesQuery,
    CamberPopularColorSchemesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CamberPopularColorSchemesQuery,
    CamberPopularColorSchemesQueryVariables
  >(CamberPopularColorSchemesDocument, baseOptions)
}
export function useCamberPopularColorSchemesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CamberPopularColorSchemesQuery,
    CamberPopularColorSchemesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CamberPopularColorSchemesQuery,
    CamberPopularColorSchemesQueryVariables
  >(CamberPopularColorSchemesDocument, baseOptions)
}
export type CamberPopularColorSchemesQueryHookResult = ReturnType<
  typeof useCamberPopularColorSchemesQuery
>
export type CamberPopularColorSchemesLazyQueryHookResult = ReturnType<
  typeof useCamberPopularColorSchemesLazyQuery
>
export type CamberPopularColorSchemesQueryResult = ApolloReactCommon.QueryResult<
  CamberPopularColorSchemesQuery,
  CamberPopularColorSchemesQueryVariables
>
export const CamberPopularLotsDocument = gql`
  query CamberPopularLots($filter: CommonQueryFilter!) {
    camberPopularLots(filter: $filter) {
      id
      clientName
      communityId
      dataName
      salesStatus
      address
      name
      count
      popularity
      siteplan {
        id
        name
        src
        lotWidth
        lotHeight
        lotFontSize
        svg {
          shapes {
            tagName
            attributes {
              dataName
              x
              y
              width
              height
              transform
              points
              d
            }
          }
        }
        lotLegend {
          code
          hex
        }
      }
      siteplanInfo {
        lotId
        siteplanId
        x
        y
      }
    }
  }
`

/**
 * __useCamberPopularLotsQuery__
 *
 * To run a query within a React component, call `useCamberPopularLotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCamberPopularLotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCamberPopularLotsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCamberPopularLotsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CamberPopularLotsQuery,
    CamberPopularLotsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CamberPopularLotsQuery,
    CamberPopularLotsQueryVariables
  >(CamberPopularLotsDocument, baseOptions)
}
export function useCamberPopularLotsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CamberPopularLotsQuery,
    CamberPopularLotsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CamberPopularLotsQuery,
    CamberPopularLotsQueryVariables
  >(CamberPopularLotsDocument, baseOptions)
}
export type CamberPopularLotsQueryHookResult = ReturnType<
  typeof useCamberPopularLotsQuery
>
export type CamberPopularLotsLazyQueryHookResult = ReturnType<
  typeof useCamberPopularLotsLazyQuery
>
export type CamberPopularLotsQueryResult = ApolloReactCommon.QueryResult<
  CamberPopularLotsQuery,
  CamberPopularLotsQueryVariables
>
export const CamberPopularMaterialColorsDocument = gql`
  query CamberPopularMaterialColors($filter: CommonQueryFilter!) {
    camberPopularMaterialColors(filter: $filter) {
      clientName
      materialId
      materialName
      materialCategory
      colorName
      colorId
      brickId
      stoneId
      customOverlayId
      colorMasonryLib
      source
      clicks
      brochure
      cta
      color {
        id
        name
        hex
      }
      texture {
        id
        library
        type
        color
        src
      }
      customOverlay {
        id
        clientName
        color
        src
      }
      count
      popularity
    }
  }
`

/**
 * __useCamberPopularMaterialColorsQuery__
 *
 * To run a query within a React component, call `useCamberPopularMaterialColorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCamberPopularMaterialColorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCamberPopularMaterialColorsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCamberPopularMaterialColorsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CamberPopularMaterialColorsQuery,
    CamberPopularMaterialColorsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CamberPopularMaterialColorsQuery,
    CamberPopularMaterialColorsQueryVariables
  >(CamberPopularMaterialColorsDocument, baseOptions)
}
export function useCamberPopularMaterialColorsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CamberPopularMaterialColorsQuery,
    CamberPopularMaterialColorsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CamberPopularMaterialColorsQuery,
    CamberPopularMaterialColorsQueryVariables
  >(CamberPopularMaterialColorsDocument, baseOptions)
}
export type CamberPopularMaterialColorsQueryHookResult = ReturnType<
  typeof useCamberPopularMaterialColorsQuery
>
export type CamberPopularMaterialColorsLazyQueryHookResult = ReturnType<
  typeof useCamberPopularMaterialColorsLazyQuery
>
export type CamberPopularMaterialColorsQueryResult = ApolloReactCommon.QueryResult<
  CamberPopularMaterialColorsQuery,
  CamberPopularMaterialColorsQueryVariables
>
export const CamberUsageSummaryDocument = gql`
  query CamberUsageSummary($filter: CommonQueryFilter!) {
    getCamberUsageSummary(filter: $filter) {
      clientName
      communityId
      communityName
      pageViews
      brochure
      registrations
      planClicks
      eventsCount
      durationInfo {
        avg
        median
        min
        max
        sessionCount
        total
      }
      community {
        name
        id
        clientName
        latitude
        longitude
        logo
        active
      }
    }
  }
`

/**
 * __useCamberUsageSummaryQuery__
 *
 * To run a query within a React component, call `useCamberUsageSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCamberUsageSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCamberUsageSummaryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCamberUsageSummaryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CamberUsageSummaryQuery,
    CamberUsageSummaryQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CamberUsageSummaryQuery,
    CamberUsageSummaryQueryVariables
  >(CamberUsageSummaryDocument, baseOptions)
}
export function useCamberUsageSummaryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CamberUsageSummaryQuery,
    CamberUsageSummaryQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CamberUsageSummaryQuery,
    CamberUsageSummaryQueryVariables
  >(CamberUsageSummaryDocument, baseOptions)
}
export type CamberUsageSummaryQueryHookResult = ReturnType<
  typeof useCamberUsageSummaryQuery
>
export type CamberUsageSummaryLazyQueryHookResult = ReturnType<
  typeof useCamberUsageSummaryLazyQuery
>
export type CamberUsageSummaryQueryResult = ApolloReactCommon.QueryResult<
  CamberUsageSummaryQuery,
  CamberUsageSummaryQueryVariables
>
export const GetCamberUsersLoginsDocument = gql`
  query GetCamberUsersLogins($filter: CommonQueryFilter!) {
    getCamberUsersLogins(filter: $filter) {
      clientName
      startTimestamp
      endTimestamp
      logins {
        date
        value
      }
    }
  }
`

/**
 * __useGetCamberUsersLoginsQuery__
 *
 * To run a query within a React component, call `useGetCamberUsersLoginsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCamberUsersLoginsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCamberUsersLoginsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCamberUsersLoginsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetCamberUsersLoginsQuery,
    GetCamberUsersLoginsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCamberUsersLoginsQuery,
    GetCamberUsersLoginsQueryVariables
  >(GetCamberUsersLoginsDocument, baseOptions)
}
export function useGetCamberUsersLoginsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCamberUsersLoginsQuery,
    GetCamberUsersLoginsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetCamberUsersLoginsQuery,
    GetCamberUsersLoginsQueryVariables
  >(GetCamberUsersLoginsDocument, baseOptions)
}
export type GetCamberUsersLoginsQueryHookResult = ReturnType<
  typeof useGetCamberUsersLoginsQuery
>
export type GetCamberUsersLoginsLazyQueryHookResult = ReturnType<
  typeof useGetCamberUsersLoginsLazyQuery
>
export type GetCamberUsersLoginsQueryResult = ApolloReactCommon.QueryResult<
  GetCamberUsersLoginsQuery,
  GetCamberUsersLoginsQueryVariables
>
export const ClientsForUserDocument = gql`
  query ClientsForUser($userId: Int!) {
    clientsForUser(userId: $userId) {
      id
      clients {
        id
        altName
        name
        email
        phone
        directoryName
        logo
      }
    }
  }
`

/**
 * __useClientsForUserQuery__
 *
 * To run a query within a React component, call `useClientsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientsForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useClientsForUserQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ClientsForUserQuery,
    ClientsForUserQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ClientsForUserQuery,
    ClientsForUserQueryVariables
  >(ClientsForUserDocument, baseOptions)
}
export function useClientsForUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ClientsForUserQuery,
    ClientsForUserQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ClientsForUserQuery,
    ClientsForUserQueryVariables
  >(ClientsForUserDocument, baseOptions)
}
export type ClientsForUserQueryHookResult = ReturnType<
  typeof useClientsForUserQuery
>
export type ClientsForUserLazyQueryHookResult = ReturnType<
  typeof useClientsForUserLazyQuery
>
export type ClientsForUserQueryResult = ApolloReactCommon.QueryResult<
  ClientsForUserQuery,
  ClientsForUserQueryVariables
>
export const GetColorTrendsDocument = gql`
  query GetColorTrends($filter: ColorTrendsFilter!) {
    getColorTrends(filter: $filter) {
      clientName
      startTimestamp
      endTimestamp
      colors {
        clientName
        materialId
        materialName
        materialCategory
        colorName
        colorId
        brickId
        stoneId
        customOverlayId
        colorMasonryLib
        clicks
        brochure
        cta
        color {
          id
          name
          hex
        }
        texture {
          id
          library
          type
          color
          src
        }
        customOverlay {
          id
          clientName
          color
          src
        }
        dailyMetrics {
          frames {
            ... on EventBasedTimeSeriesFrame {
              date
              value
            }
            ... on SessionBasedTimeSeriesFrame {
              date
              durationSum
              durationAvg
              durationMin
              durationMax
              durationMedian
              eventsCount
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetColorTrendsQuery__
 *
 * To run a query within a React component, call `useGetColorTrendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetColorTrendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetColorTrendsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetColorTrendsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetColorTrendsQuery,
    GetColorTrendsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetColorTrendsQuery,
    GetColorTrendsQueryVariables
  >(GetColorTrendsDocument, baseOptions)
}
export function useGetColorTrendsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetColorTrendsQuery,
    GetColorTrendsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetColorTrendsQuery,
    GetColorTrendsQueryVariables
  >(GetColorTrendsDocument, baseOptions)
}
export type GetColorTrendsQueryHookResult = ReturnType<
  typeof useGetColorTrendsQuery
>
export type GetColorTrendsLazyQueryHookResult = ReturnType<
  typeof useGetColorTrendsLazyQuery
>
export type GetColorTrendsQueryResult = ApolloReactCommon.QueryResult<
  GetColorTrendsQuery,
  GetColorTrendsQueryVariables
>
export const GetCommunitiesTrendsDocument = gql`
  query GetCommunitiesTrends($filter: CommunitiesTrendsFilter!) {
    getCommunitiesTrends(filter: $filter) {
      clientName
      startTimestamp
      endTimestamp
      communities {
        clientName
        communityId
        communityName
        community {
          id
        }
        dailyMetrics {
          frames {
            ... on EventBasedTimeSeriesFrame {
              date
              value
            }
            ... on SessionBasedTimeSeriesFrame {
              date
              durationSum
              durationAvg
              durationMin
              durationMax
              durationMedian
              eventsCount
              sessionsCount
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetCommunitiesTrendsQuery__
 *
 * To run a query within a React component, call `useGetCommunitiesTrendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunitiesTrendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunitiesTrendsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCommunitiesTrendsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetCommunitiesTrendsQuery,
    GetCommunitiesTrendsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCommunitiesTrendsQuery,
    GetCommunitiesTrendsQueryVariables
  >(GetCommunitiesTrendsDocument, baseOptions)
}
export function useGetCommunitiesTrendsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCommunitiesTrendsQuery,
    GetCommunitiesTrendsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetCommunitiesTrendsQuery,
    GetCommunitiesTrendsQueryVariables
  >(GetCommunitiesTrendsDocument, baseOptions)
}
export type GetCommunitiesTrendsQueryHookResult = ReturnType<
  typeof useGetCommunitiesTrendsQuery
>
export type GetCommunitiesTrendsLazyQueryHookResult = ReturnType<
  typeof useGetCommunitiesTrendsLazyQuery
>
export type GetCommunitiesTrendsQueryResult = ApolloReactCommon.QueryResult<
  GetCommunitiesTrendsQuery,
  GetCommunitiesTrendsQueryVariables
>
export const ClientCommunityListDocument = gql`
  query ClientCommunityList($clientName: String!) {
    communities(clientName: $clientName, active: true, bonafide: true) {
      name
    }
  }
`

/**
 * __useClientCommunityListQuery__
 *
 * To run a query within a React component, call `useClientCommunityListQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientCommunityListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientCommunityListQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useClientCommunityListQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ClientCommunityListQuery,
    ClientCommunityListQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ClientCommunityListQuery,
    ClientCommunityListQueryVariables
  >(ClientCommunityListDocument, baseOptions)
}
export function useClientCommunityListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ClientCommunityListQuery,
    ClientCommunityListQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ClientCommunityListQuery,
    ClientCommunityListQueryVariables
  >(ClientCommunityListDocument, baseOptions)
}
export type ClientCommunityListQueryHookResult = ReturnType<
  typeof useClientCommunityListQuery
>
export type ClientCommunityListLazyQueryHookResult = ReturnType<
  typeof useClientCommunityListLazyQuery
>
export type ClientCommunityListQueryResult = ApolloReactCommon.QueryResult<
  ClientCommunityListQuery,
  ClientCommunityListQueryVariables
>
export const GetElevationTrendsDocument = gql`
  query GetElevationTrends($filter: CommonQueryFilter!) {
    getElevationTrends(filter: $filter) {
      clientName
      startTimestamp
      endTimestamp
      elevations {
        clientName
        planId
        planName
        elevationId
        elevationCaption
        elevation {
          id
          planId
          planName
          caption
        }
        dailyMetrics {
          frames {
            ... on EventBasedTimeSeriesFrame {
              date
              value
            }
            ... on SessionBasedTimeSeriesFrame {
              date
              durationSum
              durationAvg
              durationMin
              durationMax
              durationMedian
              eventsCount
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetElevationTrendsQuery__
 *
 * To run a query within a React component, call `useGetElevationTrendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetElevationTrendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetElevationTrendsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetElevationTrendsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetElevationTrendsQuery,
    GetElevationTrendsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetElevationTrendsQuery,
    GetElevationTrendsQueryVariables
  >(GetElevationTrendsDocument, baseOptions)
}
export function useGetElevationTrendsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetElevationTrendsQuery,
    GetElevationTrendsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetElevationTrendsQuery,
    GetElevationTrendsQueryVariables
  >(GetElevationTrendsDocument, baseOptions)
}
export type GetElevationTrendsQueryHookResult = ReturnType<
  typeof useGetElevationTrendsQuery
>
export type GetElevationTrendsLazyQueryHookResult = ReturnType<
  typeof useGetElevationTrendsLazyQuery
>
export type GetElevationTrendsQueryResult = ApolloReactCommon.QueryResult<
  GetElevationTrendsQuery,
  GetElevationTrendsQueryVariables
>
export const EngagementRateDocument = gql`
  query EngagementRate($filter: SessionFilter!) {
    engagementRate(filter: $filter) {
      community
      plan
      elevation
      brochure
    }
  }
`

/**
 * __useEngagementRateQuery__
 *
 * To run a query within a React component, call `useEngagementRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useEngagementRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEngagementRateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEngagementRateQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EngagementRateQuery,
    EngagementRateQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    EngagementRateQuery,
    EngagementRateQueryVariables
  >(EngagementRateDocument, baseOptions)
}
export function useEngagementRateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EngagementRateQuery,
    EngagementRateQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    EngagementRateQuery,
    EngagementRateQueryVariables
  >(EngagementRateDocument, baseOptions)
}
export type EngagementRateQueryHookResult = ReturnType<
  typeof useEngagementRateQuery
>
export type EngagementRateLazyQueryHookResult = ReturnType<
  typeof useEngagementRateLazyQuery
>
export type EngagementRateQueryResult = ApolloReactCommon.QueryResult<
  EngagementRateQuery,
  EngagementRateQueryVariables
>
export const GetEnvelopesCreatedDocument = gql`
  query getEnvelopesCreated($filter: CommonQueryFilter!) {
    getEnvelopesCreated(filter: $filter) {
      count
    }
  }
`

/**
 * __useGetEnvelopesCreatedQuery__
 *
 * To run a query within a React component, call `useGetEnvelopesCreatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnvelopesCreatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnvelopesCreatedQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetEnvelopesCreatedQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetEnvelopesCreatedQuery,
    GetEnvelopesCreatedQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetEnvelopesCreatedQuery,
    GetEnvelopesCreatedQueryVariables
  >(GetEnvelopesCreatedDocument, baseOptions)
}
export function useGetEnvelopesCreatedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetEnvelopesCreatedQuery,
    GetEnvelopesCreatedQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetEnvelopesCreatedQuery,
    GetEnvelopesCreatedQueryVariables
  >(GetEnvelopesCreatedDocument, baseOptions)
}
export type GetEnvelopesCreatedQueryHookResult = ReturnType<
  typeof useGetEnvelopesCreatedQuery
>
export type GetEnvelopesCreatedLazyQueryHookResult = ReturnType<
  typeof useGetEnvelopesCreatedLazyQuery
>
export type GetEnvelopesCreatedQueryResult = ApolloReactCommon.QueryResult<
  GetEnvelopesCreatedQuery,
  GetEnvelopesCreatedQueryVariables
>
export const ExcludedIpsDocument = gql`
  query ExcludedIps($clientName: String!) {
    excludedIps(clientName: $clientName) {
      ip
      ipCidrNotation
    }
  }
`

/**
 * __useExcludedIpsQuery__
 *
 * To run a query within a React component, call `useExcludedIpsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExcludedIpsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExcludedIpsQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useExcludedIpsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ExcludedIpsQuery,
    ExcludedIpsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ExcludedIpsQuery, ExcludedIpsQueryVariables>(
    ExcludedIpsDocument,
    baseOptions
  )
}
export function useExcludedIpsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ExcludedIpsQuery,
    ExcludedIpsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ExcludedIpsQuery,
    ExcludedIpsQueryVariables
  >(ExcludedIpsDocument, baseOptions)
}
export type ExcludedIpsQueryHookResult = ReturnType<typeof useExcludedIpsQuery>
export type ExcludedIpsLazyQueryHookResult = ReturnType<
  typeof useExcludedIpsLazyQuery
>
export type ExcludedIpsQueryResult = ApolloReactCommon.QueryResult<
  ExcludedIpsQuery,
  ExcludedIpsQueryVariables
>
export const GetFavoritesDocument = gql`
  query getFavorites($filter: LeadsQueryFilter!) {
    getFavorites(filter: $filter) {
      clientName
      favorites {
        date
        communityName
        planName
        elevationCaption
        country
        state
        city
        user
        prospect {
          id
          email
          name
          firstName
          lastName
        }
      }
    }
  }
`

/**
 * __useGetFavoritesQuery__
 *
 * To run a query within a React component, call `useGetFavoritesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoritesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoritesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetFavoritesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetFavoritesQuery,
    GetFavoritesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetFavoritesQuery,
    GetFavoritesQueryVariables
  >(GetFavoritesDocument, baseOptions)
}
export function useGetFavoritesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFavoritesQuery,
    GetFavoritesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetFavoritesQuery,
    GetFavoritesQueryVariables
  >(GetFavoritesDocument, baseOptions)
}
export type GetFavoritesQueryHookResult = ReturnType<
  typeof useGetFavoritesQuery
>
export type GetFavoritesLazyQueryHookResult = ReturnType<
  typeof useGetFavoritesLazyQuery
>
export type GetFavoritesQueryResult = ApolloReactCommon.QueryResult<
  GetFavoritesQuery,
  GetFavoritesQueryVariables
>
export const GetFloorplanTrendsDocument = gql`
  query GetFloorplanTrends($filter: CommonQueryFilter!) {
    getFloorplanTrends(filter: $filter) {
      clientName
      startTimestamp
      endTimestamp
      floorplans {
        clientName
        planId
        planName
        floorplanName
        dailyMetrics {
          frames {
            ... on EventBasedTimeSeriesFrame {
              date
              value
            }
            ... on SessionBasedTimeSeriesFrame {
              date
              durationSum
              durationAvg
              durationMin
              durationMax
              durationMedian
              eventsCount
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetFloorplanTrendsQuery__
 *
 * To run a query within a React component, call `useGetFloorplanTrendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFloorplanTrendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFloorplanTrendsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetFloorplanTrendsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetFloorplanTrendsQuery,
    GetFloorplanTrendsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetFloorplanTrendsQuery,
    GetFloorplanTrendsQueryVariables
  >(GetFloorplanTrendsDocument, baseOptions)
}
export function useGetFloorplanTrendsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFloorplanTrendsQuery,
    GetFloorplanTrendsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetFloorplanTrendsQuery,
    GetFloorplanTrendsQueryVariables
  >(GetFloorplanTrendsDocument, baseOptions)
}
export type GetFloorplanTrendsQueryHookResult = ReturnType<
  typeof useGetFloorplanTrendsQuery
>
export type GetFloorplanTrendsLazyQueryHookResult = ReturnType<
  typeof useGetFloorplanTrendsLazyQuery
>
export type GetFloorplanTrendsQueryResult = ApolloReactCommon.QueryResult<
  GetFloorplanTrendsQuery,
  GetFloorplanTrendsQueryVariables
>
export const GetCommunitiesByLocationDocument = gql`
  query getCommunitiesByLocation($filter: CommonQueryFilter!) {
    getCommunitiesByLocation(filter: $filter) {
      communityId
      count
    }
  }
`

/**
 * __useGetCommunitiesByLocationQuery__
 *
 * To run a query within a React component, call `useGetCommunitiesByLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunitiesByLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunitiesByLocationQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCommunitiesByLocationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetCommunitiesByLocationQuery,
    GetCommunitiesByLocationQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCommunitiesByLocationQuery,
    GetCommunitiesByLocationQueryVariables
  >(GetCommunitiesByLocationDocument, baseOptions)
}
export function useGetCommunitiesByLocationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCommunitiesByLocationQuery,
    GetCommunitiesByLocationQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetCommunitiesByLocationQuery,
    GetCommunitiesByLocationQueryVariables
  >(GetCommunitiesByLocationDocument, baseOptions)
}
export type GetCommunitiesByLocationQueryHookResult = ReturnType<
  typeof useGetCommunitiesByLocationQuery
>
export type GetCommunitiesByLocationLazyQueryHookResult = ReturnType<
  typeof useGetCommunitiesByLocationLazyQuery
>
export type GetCommunitiesByLocationQueryResult = ApolloReactCommon.QueryResult<
  GetCommunitiesByLocationQuery,
  GetCommunitiesByLocationQueryVariables
>
export const GetVisitorLocationsDocument = gql`
  query getVisitorLocations($filter: CommonQueryFilter!) {
    getVisitorLocations(filter: $filter) {
      latitude
      longitude
    }
  }
`

/**
 * __useGetVisitorLocationsQuery__
 *
 * To run a query within a React component, call `useGetVisitorLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVisitorLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVisitorLocationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetVisitorLocationsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetVisitorLocationsQuery,
    GetVisitorLocationsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetVisitorLocationsQuery,
    GetVisitorLocationsQueryVariables
  >(GetVisitorLocationsDocument, baseOptions)
}
export function useGetVisitorLocationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetVisitorLocationsQuery,
    GetVisitorLocationsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetVisitorLocationsQuery,
    GetVisitorLocationsQueryVariables
  >(GetVisitorLocationsDocument, baseOptions)
}
export type GetVisitorLocationsQueryHookResult = ReturnType<
  typeof useGetVisitorLocationsQuery
>
export type GetVisitorLocationsLazyQueryHookResult = ReturnType<
  typeof useGetVisitorLocationsLazyQuery
>
export type GetVisitorLocationsQueryResult = ApolloReactCommon.QueryResult<
  GetVisitorLocationsQuery,
  GetVisitorLocationsQueryVariables
>
export const IndustrySessionTrendsDocument = gql`
  query IndustrySessionTrends($filter: CommonQueryFilter!) {
    industrySessionTrends(filter: $filter) {
      date
      sessions
      visitors
      registered
      returnVisitors
      newVisitors
      averageDuration
      bounceRate
      industrySessions
      industryVisitors
      industryRegistered
      industryReturnVisitors
      industryNewVisitors
      industryAverageDuration
      industryBounceRate
    }
  }
`

/**
 * __useIndustrySessionTrendsQuery__
 *
 * To run a query within a React component, call `useIndustrySessionTrendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndustrySessionTrendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndustrySessionTrendsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useIndustrySessionTrendsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IndustrySessionTrendsQuery,
    IndustrySessionTrendsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IndustrySessionTrendsQuery,
    IndustrySessionTrendsQueryVariables
  >(IndustrySessionTrendsDocument, baseOptions)
}
export function useIndustrySessionTrendsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IndustrySessionTrendsQuery,
    IndustrySessionTrendsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IndustrySessionTrendsQuery,
    IndustrySessionTrendsQueryVariables
  >(IndustrySessionTrendsDocument, baseOptions)
}
export type IndustrySessionTrendsQueryHookResult = ReturnType<
  typeof useIndustrySessionTrendsQuery
>
export type IndustrySessionTrendsLazyQueryHookResult = ReturnType<
  typeof useIndustrySessionTrendsLazyQuery
>
export type IndustrySessionTrendsQueryResult = ApolloReactCommon.QueryResult<
  IndustrySessionTrendsQuery,
  IndustrySessionTrendsQueryVariables
>
export const IndustrySessionSummaryDocument = gql`
  query industrySessionSummary($filter: CommonQueryFilter!) {
    industrySessionSummary(filter: $filter) {
      sessions
      visitors
      registered
      returnVisitors
      newVisitors
      averageDuration
      bounceRate
    }
  }
`

/**
 * __useIndustrySessionSummaryQuery__
 *
 * To run a query within a React component, call `useIndustrySessionSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndustrySessionSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndustrySessionSummaryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useIndustrySessionSummaryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IndustrySessionSummaryQuery,
    IndustrySessionSummaryQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IndustrySessionSummaryQuery,
    IndustrySessionSummaryQueryVariables
  >(IndustrySessionSummaryDocument, baseOptions)
}
export function useIndustrySessionSummaryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IndustrySessionSummaryQuery,
    IndustrySessionSummaryQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IndustrySessionSummaryQuery,
    IndustrySessionSummaryQueryVariables
  >(IndustrySessionSummaryDocument, baseOptions)
}
export type IndustrySessionSummaryQueryHookResult = ReturnType<
  typeof useIndustrySessionSummaryQuery
>
export type IndustrySessionSummaryLazyQueryHookResult = ReturnType<
  typeof useIndustrySessionSummaryLazyQuery
>
export type IndustrySessionSummaryQueryResult = ApolloReactCommon.QueryResult<
  IndustrySessionSummaryQuery,
  IndustrySessionSummaryQueryVariables
>
export const PopularFloorPlanOptionsDocument = gql`
  query popularFloorPlanOptions($filter: CommonQueryFilter!) {
    popularFloorPlanOptions(filter: $filter) {
      floorPlanOptionName
      count
      popularity
    }
  }
`

/**
 * __usePopularFloorPlanOptionsQuery__
 *
 * To run a query within a React component, call `usePopularFloorPlanOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularFloorPlanOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularFloorPlanOptionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePopularFloorPlanOptionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PopularFloorPlanOptionsQuery,
    PopularFloorPlanOptionsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    PopularFloorPlanOptionsQuery,
    PopularFloorPlanOptionsQueryVariables
  >(PopularFloorPlanOptionsDocument, baseOptions)
}
export function usePopularFloorPlanOptionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PopularFloorPlanOptionsQuery,
    PopularFloorPlanOptionsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    PopularFloorPlanOptionsQuery,
    PopularFloorPlanOptionsQueryVariables
  >(PopularFloorPlanOptionsDocument, baseOptions)
}
export type PopularFloorPlanOptionsQueryHookResult = ReturnType<
  typeof usePopularFloorPlanOptionsQuery
>
export type PopularFloorPlanOptionsLazyQueryHookResult = ReturnType<
  typeof usePopularFloorPlanOptionsLazyQuery
>
export type PopularFloorPlanOptionsQueryResult = ApolloReactCommon.QueryResult<
  PopularFloorPlanOptionsQuery,
  PopularFloorPlanOptionsQueryVariables
>
export const LeadRankingsDocument = gql`
  query LeadRankings(
    $filter: LeadsQueryFilter!
    $pagination: Pagination
    $orderBy: OrderBy
  ) {
    leadRankings(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
      totalCount
      leads {
        user
        clientName
        lastSeen
        firstSeen
        date
        sessions
        totalEvents
        totalDuration
        averageDuration
        brochuresCreated
        ctaClicks
        favorites
        signIns
        appointments
        contactsUs
        score
        hotLead
        country
        state
        city
        numCommunities
        numPlans
        plans {
          clientName
          communityName
          communityId
          planName
          planId
          elevationId
          elevationCaption
          timestamp
        }
        communities {
          clientName
          communityName
          communityId
          events
          duration
          timestamp
        }
        prospectId
        phone
        status
        registrationDate
        name
        email
        clientRating
        favoriteCommunities
      }
    }
  }
`

/**
 * __useLeadRankingsQuery__
 *
 * To run a query within a React component, call `useLeadRankingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeadRankingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeadRankingsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useLeadRankingsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    LeadRankingsQuery,
    LeadRankingsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    LeadRankingsQuery,
    LeadRankingsQueryVariables
  >(LeadRankingsDocument, baseOptions)
}
export function useLeadRankingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LeadRankingsQuery,
    LeadRankingsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    LeadRankingsQuery,
    LeadRankingsQueryVariables
  >(LeadRankingsDocument, baseOptions)
}
export type LeadRankingsQueryHookResult = ReturnType<
  typeof useLeadRankingsQuery
>
export type LeadRankingsLazyQueryHookResult = ReturnType<
  typeof useLeadRankingsLazyQuery
>
export type LeadRankingsQueryResult = ApolloReactCommon.QueryResult<
  LeadRankingsQuery,
  LeadRankingsQueryVariables
>
export const LeadTimeRankingDocument = gql`
  query leadTimeRanking(
    $clientName: String!
    $leadEmail: String!
    $timeFrame: String!
  ) {
    leadTimeRanking(
      clientName: $clientName
      leadEmail: $leadEmail
      timeFrame: $timeFrame
    ) {
      date
      score
      overallScore
    }
  }
`

/**
 * __useLeadTimeRankingQuery__
 *
 * To run a query within a React component, call `useLeadTimeRankingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeadTimeRankingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeadTimeRankingQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      leadEmail: // value for 'leadEmail'
 *      timeFrame: // value for 'timeFrame'
 *   },
 * });
 */
export function useLeadTimeRankingQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    LeadTimeRankingQuery,
    LeadTimeRankingQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    LeadTimeRankingQuery,
    LeadTimeRankingQueryVariables
  >(LeadTimeRankingDocument, baseOptions)
}
export function useLeadTimeRankingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LeadTimeRankingQuery,
    LeadTimeRankingQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    LeadTimeRankingQuery,
    LeadTimeRankingQueryVariables
  >(LeadTimeRankingDocument, baseOptions)
}
export type LeadTimeRankingQueryHookResult = ReturnType<
  typeof useLeadTimeRankingQuery
>
export type LeadTimeRankingLazyQueryHookResult = ReturnType<
  typeof useLeadTimeRankingLazyQuery
>
export type LeadTimeRankingQueryResult = ApolloReactCommon.QueryResult<
  LeadTimeRankingQuery,
  LeadTimeRankingQueryVariables
>
export const LeadsByElevationDocument = gql`
  query LeadsByElevation($filter: LeadsQueryFilter!) {
    leadsByElevation(filter: $filter) {
      clientName
      user
      events {
        action
        timestamp
      }
      lastActivity
      numBrowsed
      numFavorited
      prospect {
        id
        email
        name
        firstName
        lastName
        phone
        picture
        preferredContactMethods
        registrationDate
        status
        clientRating
      }
    }
  }
`

/**
 * __useLeadsByElevationQuery__
 *
 * To run a query within a React component, call `useLeadsByElevationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeadsByElevationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeadsByElevationQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLeadsByElevationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    LeadsByElevationQuery,
    LeadsByElevationQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    LeadsByElevationQuery,
    LeadsByElevationQueryVariables
  >(LeadsByElevationDocument, baseOptions)
}
export function useLeadsByElevationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LeadsByElevationQuery,
    LeadsByElevationQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    LeadsByElevationQuery,
    LeadsByElevationQueryVariables
  >(LeadsByElevationDocument, baseOptions)
}
export type LeadsByElevationQueryHookResult = ReturnType<
  typeof useLeadsByElevationQuery
>
export type LeadsByElevationLazyQueryHookResult = ReturnType<
  typeof useLeadsByElevationLazyQuery
>
export type LeadsByElevationQueryResult = ApolloReactCommon.QueryResult<
  LeadsByElevationQuery,
  LeadsByElevationQueryVariables
>
export const LeadsByProductDocument = gql`
  query LeadsByProduct(
    $filter: LeadsByProductQueryFilter!
    $clientName: String!
  ) {
    leadsByProduct(filter: $filter, clientName: $clientName) {
      clientName
      user
      lastSeen
      score
      avgDistance
      minDistance
      maxDistance
      sessions
      duration
      myHomeFrequency
      communities
      plans
      prospect {
        id
        name
        firstName
        lastName
        email
        status
        receiveCampaignEmails(clientName: $clientName)
      }
    }
  }
`

/**
 * __useLeadsByProductQuery__
 *
 * To run a query within a React component, call `useLeadsByProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeadsByProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeadsByProductQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useLeadsByProductQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    LeadsByProductQuery,
    LeadsByProductQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    LeadsByProductQuery,
    LeadsByProductQueryVariables
  >(LeadsByProductDocument, baseOptions)
}
export function useLeadsByProductLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LeadsByProductQuery,
    LeadsByProductQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    LeadsByProductQuery,
    LeadsByProductQueryVariables
  >(LeadsByProductDocument, baseOptions)
}
export type LeadsByProductQueryHookResult = ReturnType<
  typeof useLeadsByProductQuery
>
export type LeadsByProductLazyQueryHookResult = ReturnType<
  typeof useLeadsByProductLazyQuery
>
export type LeadsByProductQueryResult = ApolloReactCommon.QueryResult<
  LeadsByProductQuery,
  LeadsByProductQueryVariables
>
export const GetLotClicksDocument = gql`
  query getLotClicks(
    $filter: CommonQueryFilter!
    $siteplanId: Int!
    $lotId: Int!
  ) {
    getLotClicks(filter: $filter, siteplanId: $siteplanId, lotId: $lotId) {
      day
      count
    }
  }
`

/**
 * __useGetLotClicksQuery__
 *
 * To run a query within a React component, call `useGetLotClicksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLotClicksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLotClicksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      siteplanId: // value for 'siteplanId'
 *      lotId: // value for 'lotId'
 *   },
 * });
 */
export function useGetLotClicksQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetLotClicksQuery,
    GetLotClicksQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetLotClicksQuery,
    GetLotClicksQueryVariables
  >(GetLotClicksDocument, baseOptions)
}
export function useGetLotClicksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetLotClicksQuery,
    GetLotClicksQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetLotClicksQuery,
    GetLotClicksQueryVariables
  >(GetLotClicksDocument, baseOptions)
}
export type GetLotClicksQueryHookResult = ReturnType<
  typeof useGetLotClicksQuery
>
export type GetLotClicksLazyQueryHookResult = ReturnType<
  typeof useGetLotClicksLazyQuery
>
export type GetLotClicksQueryResult = ApolloReactCommon.QueryResult<
  GetLotClicksQuery,
  GetLotClicksQueryVariables
>
export const GetLotLeadsDocument = gql`
  query getLotLeads(
    $filter: CommonQueryFilter!
    $siteplanId: Int!
    $lotId: Int!
  ) {
    getLotLeads(filter: $filter, siteplanId: $siteplanId, lotId: $lotId) {
      clientName
      date
      prospect {
        id
        email
        name
        firstName
        lastName
        phone
        picture
        preferredContactMethods
        registrationDate
        status
        clientRating
      }
    }
  }
`

/**
 * __useGetLotLeadsQuery__
 *
 * To run a query within a React component, call `useGetLotLeadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLotLeadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLotLeadsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      siteplanId: // value for 'siteplanId'
 *      lotId: // value for 'lotId'
 *   },
 * });
 */
export function useGetLotLeadsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetLotLeadsQuery,
    GetLotLeadsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetLotLeadsQuery, GetLotLeadsQueryVariables>(
    GetLotLeadsDocument,
    baseOptions
  )
}
export function useGetLotLeadsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetLotLeadsQuery,
    GetLotLeadsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetLotLeadsQuery,
    GetLotLeadsQueryVariables
  >(GetLotLeadsDocument, baseOptions)
}
export type GetLotLeadsQueryHookResult = ReturnType<typeof useGetLotLeadsQuery>
export type GetLotLeadsLazyQueryHookResult = ReturnType<
  typeof useGetLotLeadsLazyQuery
>
export type GetLotLeadsQueryResult = ApolloReactCommon.QueryResult<
  GetLotLeadsQuery,
  GetLotLeadsQueryVariables
>
export const GetMfRecommendedPlansDocument = gql`
  query getMfRecommendedPlans(
    $clientName: String!
    $filter: [MFRecommendedPlansInput]!
    $plansToBeExcluded: [Int!]!
    $topK: Int!
    $leadEmail: String!
  ) {
    getMfRecommendedPlans(
      clientName: $clientName
      filter: $filter
      plansToBeExcluded: $plansToBeExcluded
      topK: $topK
      leadEmail: $leadEmail
    ) {
      planId
      planName
      communityId
      communityName
      elevationId
      elevationCaption
      thumb
      bed
      bath
      size
    }
  }
`

/**
 * __useGetMfRecommendedPlansQuery__
 *
 * To run a query within a React component, call `useGetMfRecommendedPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMfRecommendedPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMfRecommendedPlansQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      filter: // value for 'filter'
 *      plansToBeExcluded: // value for 'plansToBeExcluded'
 *      topK: // value for 'topK'
 *      leadEmail: // value for 'leadEmail'
 *   },
 * });
 */
export function useGetMfRecommendedPlansQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetMfRecommendedPlansQuery,
    GetMfRecommendedPlansQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetMfRecommendedPlansQuery,
    GetMfRecommendedPlansQueryVariables
  >(GetMfRecommendedPlansDocument, baseOptions)
}
export function useGetMfRecommendedPlansLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMfRecommendedPlansQuery,
    GetMfRecommendedPlansQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetMfRecommendedPlansQuery,
    GetMfRecommendedPlansQueryVariables
  >(GetMfRecommendedPlansDocument, baseOptions)
}
export type GetMfRecommendedPlansQueryHookResult = ReturnType<
  typeof useGetMfRecommendedPlansQuery
>
export type GetMfRecommendedPlansLazyQueryHookResult = ReturnType<
  typeof useGetMfRecommendedPlansLazyQuery
>
export type GetMfRecommendedPlansQueryResult = ApolloReactCommon.QueryResult<
  GetMfRecommendedPlansQuery,
  GetMfRecommendedPlansQueryVariables
>
export const PageReferralsDocument = gql`
  query PageReferrals($filter: CommonQueryFilter!) {
    pageReferrals(filter: $filter) {
      clientName
      sessions
      numBounces
      bounceRate
      sumDuration
      avgDuration
      sumEvents
      avgEvents
      referrer
      registers
      visitors
      users
    }
  }
`

/**
 * __usePageReferralsQuery__
 *
 * To run a query within a React component, call `usePageReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageReferralsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePageReferralsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PageReferralsQuery,
    PageReferralsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    PageReferralsQuery,
    PageReferralsQueryVariables
  >(PageReferralsDocument, baseOptions)
}
export function usePageReferralsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PageReferralsQuery,
    PageReferralsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    PageReferralsQuery,
    PageReferralsQueryVariables
  >(PageReferralsDocument, baseOptions)
}
export type PageReferralsQueryHookResult = ReturnType<
  typeof usePageReferralsQuery
>
export type PageReferralsLazyQueryHookResult = ReturnType<
  typeof usePageReferralsLazyQuery
>
export type PageReferralsQueryResult = ApolloReactCommon.QueryResult<
  PageReferralsQuery,
  PageReferralsQueryVariables
>
export const PaginatedInventoryLotsDocument = gql`
  query paginatedInventoryLots(
    $clientName: String!
    $communityIds: [Int!]!
    $limit: Int!
    $offset: Int!
    $sortBy: String!
    $filter: SpecFilter
  ) {
    paginatedInventoryLots(
      clientName: $clientName
      communityIds: $communityIds
      limit: $limit
      offset: $offset
      sortBy: $sortBy
      filter: $filter
    ) {
      cursor {
        offset
      }
      lots {
        id
        clientName
        communityId
        name
        dataName
        salesStatus
        externalId
        address
        premium
        size
        plan
        garagePosition
        inventory(clientName: $clientName) {
          clientName
          id
          lotId
          planId
          plan(clientName: $clientName) {
            id
            clientName
            name
            displayName
          }
          elevationId
          elevation(clientName: $clientName) {
            id
            planId
            clientName
            caption
          }
          communityId
          mlsId
          price
          sqft
          beds
          baths
          features
          homeType
          constructionStatus
        }
        latitude
        longitude
        metroName
        cityName
        stateCode
        zip
        postCode
        countryCode
      }
    }
  }
`

/**
 * __usePaginatedInventoryLotsQuery__
 *
 * To run a query within a React component, call `usePaginatedInventoryLotsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedInventoryLotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedInventoryLotsQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      communityIds: // value for 'communityIds'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePaginatedInventoryLotsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PaginatedInventoryLotsQuery,
    PaginatedInventoryLotsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    PaginatedInventoryLotsQuery,
    PaginatedInventoryLotsQueryVariables
  >(PaginatedInventoryLotsDocument, baseOptions)
}
export function usePaginatedInventoryLotsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PaginatedInventoryLotsQuery,
    PaginatedInventoryLotsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    PaginatedInventoryLotsQuery,
    PaginatedInventoryLotsQueryVariables
  >(PaginatedInventoryLotsDocument, baseOptions)
}
export type PaginatedInventoryLotsQueryHookResult = ReturnType<
  typeof usePaginatedInventoryLotsQuery
>
export type PaginatedInventoryLotsLazyQueryHookResult = ReturnType<
  typeof usePaginatedInventoryLotsLazyQuery
>
export type PaginatedInventoryLotsQueryResult = ApolloReactCommon.QueryResult<
  PaginatedInventoryLotsQuery,
  PaginatedInventoryLotsQueryVariables
>
export const PaginatedPlansDocument = gql`
  query paginatedPlans(
    $clientName: String!
    $communityIds: [Int!]
    $limit: Int!
    $offset: Int!
    $sortBy: String!
    $filter: SpecFilter
    $commonFilter: CommonQueryFilter!
    $selectedMetrics: [String!] = []
    $sortOrder: String!
  ) {
    paginatedPlans(
      clientName: $clientName
      communityIds: $communityIds
      limit: $limit
      offset: $offset
      sortBy: $sortBy
      filter: $filter
      sortOrder: $sortOrder
    ) {
      cursor {
        offset
      }
      plans(communityIds: $communityIds) {
        id
        clientName
        communityId
        name
        displayName
        defaultElevationId
        bed
        bedMin
        bedMax
        bedRange {
          min
          max
        }
        bath
        bathMin
        bathMax
        bathRange {
          min
          max
        }
        size
        sizeMin
        sizeMax
        sizeRange {
          min
          max
        }
        cost
        costMin
        costMax
        costRange {
          min
          max
        }
        description
        elevations(
          clientName: $clientName
          communityIds: $communityIds
          active: true
        ) {
          id
          thumb
          active
          caption
          planId
          communities(
            clientName: $clientName
            excludeDefaultCommunity: true
            active: true
          ) {
            id
            thumb
            name
            active
          }
        }
        popularityMetrics(
          selectedMetrics: $selectedMetrics
          filter: $commonFilter
        ) {
          byPopularity {
            clicks
            popularity
            brochure
            cta
          }
          byDuration {
            sessionCount
            total
            avg
            min
            max
            median
          }
          byFrequency
          byBrowsingData {
            favorites
            leadFavorites
            leadViews
          }
        }
      }
    }
  }
`

/**
 * __usePaginatedPlansQuery__
 *
 * To run a query within a React component, call `usePaginatedPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedPlansQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      communityIds: // value for 'communityIds'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *      commonFilter: // value for 'commonFilter'
 *      selectedMetrics: // value for 'selectedMetrics'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function usePaginatedPlansQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PaginatedPlansQuery,
    PaginatedPlansQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    PaginatedPlansQuery,
    PaginatedPlansQueryVariables
  >(PaginatedPlansDocument, baseOptions)
}
export function usePaginatedPlansLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PaginatedPlansQuery,
    PaginatedPlansQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    PaginatedPlansQuery,
    PaginatedPlansQueryVariables
  >(PaginatedPlansDocument, baseOptions)
}
export type PaginatedPlansQueryHookResult = ReturnType<
  typeof usePaginatedPlansQuery
>
export type PaginatedPlansLazyQueryHookResult = ReturnType<
  typeof usePaginatedPlansLazyQuery
>
export type PaginatedPlansQueryResult = ApolloReactCommon.QueryResult<
  PaginatedPlansQuery,
  PaginatedPlansQueryVariables
>
export const PopularColorSchemesDocument = gql`
  query PopularColorSchemes($filter: CommonQueryFilter!) {
    popularColorSchemes(filter: $filter) {
      id
      name
      clientName
      count
      popularity
      materials {
        id
        materialId
        materialCategory
        material
        name
        hex
        swatch
        masonryLib
      }
    }
  }
`

/**
 * __usePopularColorSchemesQuery__
 *
 * To run a query within a React component, call `usePopularColorSchemesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularColorSchemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularColorSchemesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePopularColorSchemesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PopularColorSchemesQuery,
    PopularColorSchemesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    PopularColorSchemesQuery,
    PopularColorSchemesQueryVariables
  >(PopularColorSchemesDocument, baseOptions)
}
export function usePopularColorSchemesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PopularColorSchemesQuery,
    PopularColorSchemesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    PopularColorSchemesQuery,
    PopularColorSchemesQueryVariables
  >(PopularColorSchemesDocument, baseOptions)
}
export type PopularColorSchemesQueryHookResult = ReturnType<
  typeof usePopularColorSchemesQuery
>
export type PopularColorSchemesLazyQueryHookResult = ReturnType<
  typeof usePopularColorSchemesLazyQuery
>
export type PopularColorSchemesQueryResult = ApolloReactCommon.QueryResult<
  PopularColorSchemesQuery,
  PopularColorSchemesQueryVariables
>
export const PopularCommunitiesDocument = gql`
  query PopularCommunities($filter: CommonQueryFilter!, $clientName: String) {
    popularCommunities(filter: $filter) {
      clientName
      communityId
      communityName
      ... on PopularCommunityByCount {
        count
      }
      ... on PopularCommunityByDuration {
        durationInfo {
          avg
          median
          min
          max
          sessionCount
          total
        }
      }
      ... on PopularCommunityByFavoritesCount {
        favoritesCount
      }
      community {
        name
        id
        clientName
        latitude
        longitude
        cityLocation {
          ...CityFieldsFull
        }
        logo
        active
      }
    }
  }
  ${CityFieldsFullFragmentDoc}
`

/**
 * __usePopularCommunitiesQuery__
 *
 * To run a query within a React component, call `usePopularCommunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularCommunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularCommunitiesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function usePopularCommunitiesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PopularCommunitiesQuery,
    PopularCommunitiesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    PopularCommunitiesQuery,
    PopularCommunitiesQueryVariables
  >(PopularCommunitiesDocument, baseOptions)
}
export function usePopularCommunitiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PopularCommunitiesQuery,
    PopularCommunitiesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    PopularCommunitiesQuery,
    PopularCommunitiesQueryVariables
  >(PopularCommunitiesDocument, baseOptions)
}
export type PopularCommunitiesQueryHookResult = ReturnType<
  typeof usePopularCommunitiesQuery
>
export type PopularCommunitiesLazyQueryHookResult = ReturnType<
  typeof usePopularCommunitiesLazyQuery
>
export type PopularCommunitiesQueryResult = ApolloReactCommon.QueryResult<
  PopularCommunitiesQuery,
  PopularCommunitiesQueryVariables
>
export const PopularElevationSpecsDocument = gql`
  query PopularElevationSpecs($filter: CommonQueryFilter!) {
    popularElevationSpecs(filter: $filter) {
      bed {
        value
        count
      }
      bath {
        value
        count
      }
      size {
        value
        count
      }
      price {
        value
        count
      }
    }
  }
`

/**
 * __usePopularElevationSpecsQuery__
 *
 * To run a query within a React component, call `usePopularElevationSpecsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularElevationSpecsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularElevationSpecsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePopularElevationSpecsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PopularElevationSpecsQuery,
    PopularElevationSpecsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    PopularElevationSpecsQuery,
    PopularElevationSpecsQueryVariables
  >(PopularElevationSpecsDocument, baseOptions)
}
export function usePopularElevationSpecsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PopularElevationSpecsQuery,
    PopularElevationSpecsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    PopularElevationSpecsQuery,
    PopularElevationSpecsQueryVariables
  >(PopularElevationSpecsDocument, baseOptions)
}
export type PopularElevationSpecsQueryHookResult = ReturnType<
  typeof usePopularElevationSpecsQuery
>
export type PopularElevationSpecsLazyQueryHookResult = ReturnType<
  typeof usePopularElevationSpecsLazyQuery
>
export type PopularElevationSpecsQueryResult = ApolloReactCommon.QueryResult<
  PopularElevationSpecsQuery,
  PopularElevationSpecsQueryVariables
>
export const PopularElevationsDocument = gql`
  query PopularElevations($filter: CommonQueryFilter!) {
    popularElevations(filter: $filter) {
      clientName
      planId
      planName
      elevationId
      elevationCaption
      ... on PopularElevationByCount {
        count
      }
      ... on PopularElevationByDuration {
        durationInfo {
          avg
          median
          min
          max
          sessionCount
          total
        }
      }
      plan {
        id
        name
      }
      elevation {
        id
        caption
        thumb
        bathMin
        bathMax
        bedMin
        bedMax
        costMin
        costMax
        sizeMin
        sizeMax
      }
    }
  }
`

/**
 * __usePopularElevationsQuery__
 *
 * To run a query within a React component, call `usePopularElevationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularElevationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularElevationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePopularElevationsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PopularElevationsQuery,
    PopularElevationsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    PopularElevationsQuery,
    PopularElevationsQueryVariables
  >(PopularElevationsDocument, baseOptions)
}
export function usePopularElevationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PopularElevationsQuery,
    PopularElevationsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    PopularElevationsQuery,
    PopularElevationsQueryVariables
  >(PopularElevationsDocument, baseOptions)
}
export type PopularElevationsQueryHookResult = ReturnType<
  typeof usePopularElevationsQuery
>
export type PopularElevationsLazyQueryHookResult = ReturnType<
  typeof usePopularElevationsLazyQuery
>
export type PopularElevationsQueryResult = ApolloReactCommon.QueryResult<
  PopularElevationsQuery,
  PopularElevationsQueryVariables
>
export const PopularFloorplansDocument = gql`
  query PopularFloorplans($filter: CommonQueryFilter!, $clientName: String!) {
    popularFloorplans(filter: $filter) {
      clientName
      communityName
      floorplanIds
      floorplans {
        name
        clientName
        src
        popularity
        planName
        elevationCaption
        plans(clientName: $clientName) {
          id
          name
        }
      }
      popularity
      clicks
      brochure
      cta
    }
  }
`

/**
 * __usePopularFloorplansQuery__
 *
 * To run a query within a React component, call `usePopularFloorplansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularFloorplansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularFloorplansQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function usePopularFloorplansQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PopularFloorplansQuery,
    PopularFloorplansQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    PopularFloorplansQuery,
    PopularFloorplansQueryVariables
  >(PopularFloorplansDocument, baseOptions)
}
export function usePopularFloorplansLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PopularFloorplansQuery,
    PopularFloorplansQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    PopularFloorplansQuery,
    PopularFloorplansQueryVariables
  >(PopularFloorplansDocument, baseOptions)
}
export type PopularFloorplansQueryHookResult = ReturnType<
  typeof usePopularFloorplansQuery
>
export type PopularFloorplansLazyQueryHookResult = ReturnType<
  typeof usePopularFloorplansLazyQuery
>
export type PopularFloorplansQueryResult = ApolloReactCommon.QueryResult<
  PopularFloorplansQuery,
  PopularFloorplansQueryVariables
>
export const PopularIndustryElevationSpecsDocument = gql`
  query PopularIndustryElevationSpecs($filter: CommonQueryFilter!) {
    popularIndustryElevationSpecs(filter: $filter) {
      bed {
        value
        count
      }
      bath {
        value
        count
      }
      size {
        value
        count
      }
      price {
        value
        count
      }
    }
  }
`

/**
 * __usePopularIndustryElevationSpecsQuery__
 *
 * To run a query within a React component, call `usePopularIndustryElevationSpecsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularIndustryElevationSpecsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularIndustryElevationSpecsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePopularIndustryElevationSpecsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PopularIndustryElevationSpecsQuery,
    PopularIndustryElevationSpecsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    PopularIndustryElevationSpecsQuery,
    PopularIndustryElevationSpecsQueryVariables
  >(PopularIndustryElevationSpecsDocument, baseOptions)
}
export function usePopularIndustryElevationSpecsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PopularIndustryElevationSpecsQuery,
    PopularIndustryElevationSpecsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    PopularIndustryElevationSpecsQuery,
    PopularIndustryElevationSpecsQueryVariables
  >(PopularIndustryElevationSpecsDocument, baseOptions)
}
export type PopularIndustryElevationSpecsQueryHookResult = ReturnType<
  typeof usePopularIndustryElevationSpecsQuery
>
export type PopularIndustryElevationSpecsLazyQueryHookResult = ReturnType<
  typeof usePopularIndustryElevationSpecsLazyQuery
>
export type PopularIndustryElevationSpecsQueryResult = ApolloReactCommon.QueryResult<
  PopularIndustryElevationSpecsQuery,
  PopularIndustryElevationSpecsQueryVariables
>
export const PopularInteriorSelectionsDocument = gql`
  query PopularInteriorSelections(
    $clientName: String!
    $filter: CommonQueryFilter!
  ) {
    popularInteriorSelections(clientName: $clientName, filter: $filter) {
      clientName
      interiorId
      elementId
      listElementId
      optionId
      clicks
      brochure
      cta
      events
      popularity
      interior {
        id
        clientName
        name
        internalName
        baseImageSrc
        thumbnailSrc
        active
        elementTrees(excludeChildElements: false) {
          id
          name
          description
          cost
          overlaySwatchSrc
          options(clientName: $clientName) {
            id
            name
            description
            cost
            hex
            interiorListElementId
            overlaySrc
            overlaySwatchSrc
            listIndex
          }
        }
      }
    }
  }
`

/**
 * __usePopularInteriorSelectionsQuery__
 *
 * To run a query within a React component, call `usePopularInteriorSelectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularInteriorSelectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularInteriorSelectionsQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePopularInteriorSelectionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PopularInteriorSelectionsQuery,
    PopularInteriorSelectionsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    PopularInteriorSelectionsQuery,
    PopularInteriorSelectionsQueryVariables
  >(PopularInteriorSelectionsDocument, baseOptions)
}
export function usePopularInteriorSelectionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PopularInteriorSelectionsQuery,
    PopularInteriorSelectionsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    PopularInteriorSelectionsQuery,
    PopularInteriorSelectionsQueryVariables
  >(PopularInteriorSelectionsDocument, baseOptions)
}
export type PopularInteriorSelectionsQueryHookResult = ReturnType<
  typeof usePopularInteriorSelectionsQuery
>
export type PopularInteriorSelectionsLazyQueryHookResult = ReturnType<
  typeof usePopularInteriorSelectionsLazyQuery
>
export type PopularInteriorSelectionsQueryResult = ApolloReactCommon.QueryResult<
  PopularInteriorSelectionsQuery,
  PopularInteriorSelectionsQueryVariables
>
export const PopularLotsDocument = gql`
  query PopularLots($filter: CommonQueryFilter!) {
    popularLots(filter: $filter) {
      id
      clientName
      communityId
      dataName
      salesStatus
      address
      name
      count
      popularity
      siteplan {
        id
        name
        src
        lotWidth
        lotHeight
        lotFontSize
        svg {
          shapes {
            tagName
            attributes {
              dataName
              x
              y
              width
              height
              transform
              points
              d
            }
          }
        }
        lotLegend {
          code
          hex
        }
      }
      siteplanInfo {
        lotId
        siteplanId
        x
        y
      }
    }
  }
`

/**
 * __usePopularLotsQuery__
 *
 * To run a query within a React component, call `usePopularLotsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularLotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularLotsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePopularLotsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PopularLotsQuery,
    PopularLotsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<PopularLotsQuery, PopularLotsQueryVariables>(
    PopularLotsDocument,
    baseOptions
  )
}
export function usePopularLotsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PopularLotsQuery,
    PopularLotsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    PopularLotsQuery,
    PopularLotsQueryVariables
  >(PopularLotsDocument, baseOptions)
}
export type PopularLotsQueryHookResult = ReturnType<typeof usePopularLotsQuery>
export type PopularLotsLazyQueryHookResult = ReturnType<
  typeof usePopularLotsLazyQuery
>
export type PopularLotsQueryResult = ApolloReactCommon.QueryResult<
  PopularLotsQuery,
  PopularLotsQueryVariables
>
export const PopularMaterialColorsDocument = gql`
  query PopularMaterialColors($filter: CommonQueryFilter!) {
    popularMaterialColors(filter: $filter) {
      clientName
      materialId
      materialName
      materialCategory
      colorName
      colorId
      brickId
      stoneId
      customOverlayId
      colorMasonryLib
      source
      clicks
      brochure
      cta
      color {
        id
        name
        hex
      }
      texture {
        id
        library
        type
        color
        src
      }
      customOverlay {
        id
        clientName
        color
        src
      }
      count
      popularity
    }
  }
`

/**
 * __usePopularMaterialColorsQuery__
 *
 * To run a query within a React component, call `usePopularMaterialColorsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularMaterialColorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularMaterialColorsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePopularMaterialColorsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PopularMaterialColorsQuery,
    PopularMaterialColorsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    PopularMaterialColorsQuery,
    PopularMaterialColorsQueryVariables
  >(PopularMaterialColorsDocument, baseOptions)
}
export function usePopularMaterialColorsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PopularMaterialColorsQuery,
    PopularMaterialColorsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    PopularMaterialColorsQuery,
    PopularMaterialColorsQueryVariables
  >(PopularMaterialColorsDocument, baseOptions)
}
export type PopularMaterialColorsQueryHookResult = ReturnType<
  typeof usePopularMaterialColorsQuery
>
export type PopularMaterialColorsLazyQueryHookResult = ReturnType<
  typeof usePopularMaterialColorsLazyQuery
>
export type PopularMaterialColorsQueryResult = ApolloReactCommon.QueryResult<
  PopularMaterialColorsQuery,
  PopularMaterialColorsQueryVariables
>
export const GetSchemeTrendsDocument = gql`
  query GetSchemeTrends($filter: CommonQueryFilter!) {
    getSchemeTrends(filter: $filter) {
      clientName
      startTimestamp
      endTimestamp
      schemes {
        clientName
        schemeId
        schemeName
        clicks
        brochure
        cta
        scheme {
          id
          name
          clientName
          materials {
            id
            materialId
            materialCategory
            material
            name
            hex
            swatch
            masonryLib
          }
        }
        dailyMetrics {
          frames {
            ... on EventBasedTimeSeriesFrame {
              date
              value
            }
            ... on SessionBasedTimeSeriesFrame {
              date
              durationSum
              durationAvg
              durationMin
              durationMax
              durationMedian
              eventsCount
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetSchemeTrendsQuery__
 *
 * To run a query within a React component, call `useGetSchemeTrendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchemeTrendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchemeTrendsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetSchemeTrendsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetSchemeTrendsQuery,
    GetSchemeTrendsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetSchemeTrendsQuery,
    GetSchemeTrendsQueryVariables
  >(GetSchemeTrendsDocument, baseOptions)
}
export function useGetSchemeTrendsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSchemeTrendsQuery,
    GetSchemeTrendsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetSchemeTrendsQuery,
    GetSchemeTrendsQueryVariables
  >(GetSchemeTrendsDocument, baseOptions)
}
export type GetSchemeTrendsQueryHookResult = ReturnType<
  typeof useGetSchemeTrendsQuery
>
export type GetSchemeTrendsLazyQueryHookResult = ReturnType<
  typeof useGetSchemeTrendsLazyQuery
>
export type GetSchemeTrendsQueryResult = ApolloReactCommon.QueryResult<
  GetSchemeTrendsQuery,
  GetSchemeTrendsQueryVariables
>
export const SessionsDocument = gql`
  query Sessions($filter: SessionFilter!) {
    sessions(filter: $filter) {
      id
      startAt
      endAt
      duration
    }
  }
`

/**
 * __useSessionsQuery__
 *
 * To run a query within a React component, call `useSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSessionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SessionsQuery,
    SessionsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<SessionsQuery, SessionsQueryVariables>(
    SessionsDocument,
    baseOptions
  )
}
export function useSessionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SessionsQuery,
    SessionsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<SessionsQuery, SessionsQueryVariables>(
    SessionsDocument,
    baseOptions
  )
}
export type SessionsQueryHookResult = ReturnType<typeof useSessionsQuery>
export type SessionsLazyQueryHookResult = ReturnType<
  typeof useSessionsLazyQuery
>
export type SessionsQueryResult = ApolloReactCommon.QueryResult<
  SessionsQuery,
  SessionsQueryVariables
>
export const SessionsSummaryDocument = gql`
  query SessionsSummary($filter: SessionFilter!) {
    sessionsSummary(filter: $filter) {
      clientName
      startAt
      endAt
      sessions
      visitors
      registered
      returnVisitors
      newVisitors
      averageDuration
      bounceRate
    }
  }
`

/**
 * __useSessionsSummaryQuery__
 *
 * To run a query within a React component, call `useSessionsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionsSummaryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSessionsSummaryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SessionsSummaryQuery,
    SessionsSummaryQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    SessionsSummaryQuery,
    SessionsSummaryQueryVariables
  >(SessionsSummaryDocument, baseOptions)
}
export function useSessionsSummaryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SessionsSummaryQuery,
    SessionsSummaryQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    SessionsSummaryQuery,
    SessionsSummaryQueryVariables
  >(SessionsSummaryDocument, baseOptions)
}
export type SessionsSummaryQueryHookResult = ReturnType<
  typeof useSessionsSummaryQuery
>
export type SessionsSummaryLazyQueryHookResult = ReturnType<
  typeof useSessionsSummaryLazyQuery
>
export type SessionsSummaryQueryResult = ApolloReactCommon.QueryResult<
  SessionsSummaryQuery,
  SessionsSummaryQueryVariables
>
export const SessionsSummaryTrendsDocument = gql`
  query SessionsSummaryTrends($filter: SessionFilter!) {
    sessionsSummaryTrends(filter: $filter) {
      clientName
      date
      sessions
      visitors
      registered
      returnVisitors
      newVisitors
      averageDuration
      bounceRate
      date
    }
  }
`

/**
 * __useSessionsSummaryTrendsQuery__
 *
 * To run a query within a React component, call `useSessionsSummaryTrendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionsSummaryTrendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionsSummaryTrendsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSessionsSummaryTrendsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SessionsSummaryTrendsQuery,
    SessionsSummaryTrendsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    SessionsSummaryTrendsQuery,
    SessionsSummaryTrendsQueryVariables
  >(SessionsSummaryTrendsDocument, baseOptions)
}
export function useSessionsSummaryTrendsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SessionsSummaryTrendsQuery,
    SessionsSummaryTrendsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    SessionsSummaryTrendsQuery,
    SessionsSummaryTrendsQueryVariables
  >(SessionsSummaryTrendsDocument, baseOptions)
}
export type SessionsSummaryTrendsQueryHookResult = ReturnType<
  typeof useSessionsSummaryTrendsQuery
>
export type SessionsSummaryTrendsLazyQueryHookResult = ReturnType<
  typeof useSessionsSummaryTrendsLazyQuery
>
export type SessionsSummaryTrendsQueryResult = ApolloReactCommon.QueryResult<
  SessionsSummaryTrendsQuery,
  SessionsSummaryTrendsQueryVariables
>
export const SessionsSummaryTrendsByCommunityDocument = gql`
  query SessionsSummaryTrendsByCommunity($filter: SessionFilter!) {
    sessionsSummaryTrendsByCommunity(filter: $filter) {
      communityId
      stats {
        date
        numSessions
        avgDuration
        numBounces
        numVisitors
        returnVisitors
        newVisitors
        numRegistered
      }
    }
  }
`

/**
 * __useSessionsSummaryTrendsByCommunityQuery__
 *
 * To run a query within a React component, call `useSessionsSummaryTrendsByCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionsSummaryTrendsByCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionsSummaryTrendsByCommunityQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSessionsSummaryTrendsByCommunityQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SessionsSummaryTrendsByCommunityQuery,
    SessionsSummaryTrendsByCommunityQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    SessionsSummaryTrendsByCommunityQuery,
    SessionsSummaryTrendsByCommunityQueryVariables
  >(SessionsSummaryTrendsByCommunityDocument, baseOptions)
}
export function useSessionsSummaryTrendsByCommunityLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SessionsSummaryTrendsByCommunityQuery,
    SessionsSummaryTrendsByCommunityQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    SessionsSummaryTrendsByCommunityQuery,
    SessionsSummaryTrendsByCommunityQueryVariables
  >(SessionsSummaryTrendsByCommunityDocument, baseOptions)
}
export type SessionsSummaryTrendsByCommunityQueryHookResult = ReturnType<
  typeof useSessionsSummaryTrendsByCommunityQuery
>
export type SessionsSummaryTrendsByCommunityLazyQueryHookResult = ReturnType<
  typeof useSessionsSummaryTrendsByCommunityLazyQuery
>
export type SessionsSummaryTrendsByCommunityQueryResult = ApolloReactCommon.QueryResult<
  SessionsSummaryTrendsByCommunityQuery,
  SessionsSummaryTrendsByCommunityQueryVariables
>
export const VisitorTimesDocument = gql`
  query VisitorTimes($filter: CommonQueryFilter!, $minuteUTCDifference: Int!) {
    visitorTimes(filter: $filter, minuteUTCDifference: $minuteUTCDifference) {
      hour
      dayCounts {
        day_count
        day_index
      }
    }
  }
`

/**
 * __useVisitorTimesQuery__
 *
 * To run a query within a React component, call `useVisitorTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitorTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitorTimesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      minuteUTCDifference: // value for 'minuteUTCDifference'
 *   },
 * });
 */
export function useVisitorTimesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    VisitorTimesQuery,
    VisitorTimesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    VisitorTimesQuery,
    VisitorTimesQueryVariables
  >(VisitorTimesDocument, baseOptions)
}
export function useVisitorTimesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    VisitorTimesQuery,
    VisitorTimesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    VisitorTimesQuery,
    VisitorTimesQueryVariables
  >(VisitorTimesDocument, baseOptions)
}
export type VisitorTimesQueryHookResult = ReturnType<
  typeof useVisitorTimesQuery
>
export type VisitorTimesLazyQueryHookResult = ReturnType<
  typeof useVisitorTimesLazyQuery
>
export type VisitorTimesQueryResult = ApolloReactCommon.QueryResult<
  VisitorTimesQuery,
  VisitorTimesQueryVariables
>
export const VisitorTrendsDocument = gql`
  query VisitorTrends($filter: VisitorTrendsQueryFilter!) {
    visitorTrends(filter: $filter) {
      date
      anonymous
      registered
      cumulativeRegistered
    }
  }
`

/**
 * __useVisitorTrendsQuery__
 *
 * To run a query within a React component, call `useVisitorTrendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitorTrendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitorTrendsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useVisitorTrendsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    VisitorTrendsQuery,
    VisitorTrendsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    VisitorTrendsQuery,
    VisitorTrendsQueryVariables
  >(VisitorTrendsDocument, baseOptions)
}
export function useVisitorTrendsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    VisitorTrendsQuery,
    VisitorTrendsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    VisitorTrendsQuery,
    VisitorTrendsQueryVariables
  >(VisitorTrendsDocument, baseOptions)
}
export type VisitorTrendsQueryHookResult = ReturnType<
  typeof useVisitorTrendsQuery
>
export type VisitorTrendsLazyQueryHookResult = ReturnType<
  typeof useVisitorTrendsLazyQuery
>
export type VisitorTrendsQueryResult = ApolloReactCommon.QueryResult<
  VisitorTrendsQuery,
  VisitorTrendsQueryVariables
>
