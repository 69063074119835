import { LocationsSelect } from './LocationsSelect'
import { ReactElement, useCallback } from 'react'
import React from 'react'
import {
  LocationSelectionTypes,
  OptionMap,
  SetLocationSelectionTypes,
} from '../Shared'

interface CountryFilterArgs {
  countries: OptionMap<string>
  selection: LocationSelectionTypes
  setSelection: SetLocationSelectionTypes
  larger?: boolean
  industryTrendsSelect?: boolean
  locationsFilterSelect?: boolean
  leadsLocationSelect?: boolean
}

export default function CountryFilter({
  countries,
  selection,
  setSelection,
  larger = false,
  industryTrendsSelect = false,
  locationsFilterSelect = false,
  leadsLocationSelect = false,
}: CountryFilterArgs): ReactElement | null {
  const handleSelect = useCallback(
    (country?: string): void => {
      setSelection({
        country,
        us: {},
        can: {},
      })
    },
    [setSelection]
  )

  return (
    <LocationsSelect<string>
      field="country"
      name="Country"
      items={countries}
      selected={selection.country}
      onSelect={handleSelect}
      locationsFilterSelect={locationsFilterSelect}
      industryTrendsSelect={industryTrendsSelect}
      leadsLocationSelect={leadsLocationSelect}
      larger={larger}
    />
  )
}
