import hydrationStore from 'store/HydrationStore'
/**
 * @param {FormData} formData the data to upload.
 */
export const uploadForm = async (formData: FormData): Promise<Response> => {
  // Use an actual REST request.
  const backupUrl =
    process.env.NODE_ENV === 'production'
      ? 'https://nexus.development.anewgo.com'
      : 'http://localhost:5000'
  const baseUrl = process.env.REACT_APP_UPLOAD_SERVER || backupUrl
  return fetch(`${baseUrl}/api/upload`, {
    method: 'POST',
    body: formData,
    headers: {
      authorization: `Bearer ${hydrationStore.token}`,
    },
  })
}
