import React from 'react'
import { styled } from '@mui/material/styles'
import ReactDom from 'react-dom'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'
import { useSpring, animated, config } from 'react-spring'
import clsx from 'clsx'
import { MODAL_DRAWER_HEADER_HT } from '../constants'

const PREFIX = 'ModalDrawer'

const classes = {
  drawer: `${PREFIX}-drawer`,
  clearButton: `${PREFIX}-clearButton`,
  drawerContent: `${PREFIX}-drawerContent`,
  sideDrawer: `${PREFIX}-sideDrawer`,
  topDrawer: `${PREFIX}-topDrawer`,
  header: `${PREFIX}-header`,
}

const ModalDrawerStyled = styled(animated.div)(({ theme }) => ({
  // TODO: Check on this style. Not sure if correct. May prefer styling below this
  [`& .${classes.drawer}`]: {
    backgroundColor: '#F5F5F5',
    zIndex: 1,
    position: 'absolute',
    width: DEFAULT_DRAWER_WD,
    left: 0,
    top: 0,
    height: '100%',
    transition: '0.4s all ease-in-out',
    overflowX: 'hidden',
    overflowY: 'auto',
    [theme.breakpoints.up('xl')]: {
      width: DEFAULT_DRAWER_XL_WD,
    },
  },

  // drawer: {
  //   width: '100%',
  //   height: '100%',
  //   position: 'absolute',
  //   backgroundColor: 'white',
  //   top: '100%',
  //   left: 0,
  //   zIndex: 1,
  //   willChange: 'top',
  // },
  [`& .${classes.clearButton}`]: {
    width: 60,
  },

  [`& .${classes.drawerContent}`]: {
    justifyContent: 'flex-end',
    userSelect: 'none',
    '& div': {
      userSelect: 'none',
    },
    '& p': {
      userSelect: 'none',
    },
  },

  [`& .${classes.sideDrawer}`]: {
    width: SIDE_DRAWER_WIDTH,
    height: '100%',
    position: 'absolute',
    backgroundColor: 'white',
    top: 0,
    right: -SIDE_DRAWER_WIDTH,
    zIndex: 1000,
    willChange: 'right',
    overflowY: 'auto',
  },

  [`& .${classes.topDrawer}`]: {
    top: '-100%',
  },

  [`& .${classes.header}`]: {
    height: MODAL_DRAWER_HEADER_HT,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    userSelect: 'none',
    '& div': {
      userSelect: 'none',
    },
  },
}))

const DEFAULT_DRAWER_WD = 300
const DEFAULT_DRAWER_XL_WD = 420
const SIDE_DRAWER_WIDTH = 320

type ModalDrawerProps = {
  uiConfig: any
  style?: Record<string, string>
  children?: React.ReactNode[] | React.ReactNode
  variant: 'side' | 'top'
  open: boolean
  header?: boolean
  slideFrom?: 'top' | 'right'
  onSetOpen: (open: boolean) => void
}

export default function ModalDrawer({
  uiConfig,
  style = {},
  children,
  variant,
  header,
  slideFrom,
  open,
  onSetOpen,
}: ModalDrawerProps): JSX.Element | null {
  const topDrawer = variant === 'top'
  const sideDrawer = variant === 'side' || !topDrawer // if neither is true, use sideDrawer

  const { appRef, headerHeight, mortgageCalculatorOpen } = uiConfig

  const offsetWd = style.width || SIDE_DRAWER_WIDTH
  const drawerHt = appRef?.current?.offsetHeight
    ? appRef.current.offsetHeight - headerHeight
    : undefined
  const drawerSide = slideFrom || 'right'
  const sideDrawerStyle = useSpring({
    [drawerSide]: open ? `0px` : `-${offsetWd}px`,
    height: style?.height || drawerHt || '100%',
    marginTop: header ? '215px' : headerHeight,
  })
  const drawerStyle = useSpring({
    top: open ? '0px' : topDrawer ? '-200%' : '100%',
    config: config.default,
  })
  const activeStyle = sideDrawer ? sideDrawerStyle : drawerStyle

  if (!appRef) {
    return null
  }

  return ReactDom.createPortal(
    <ModalDrawerStyled
      style={{ ...activeStyle, ...style }}
      className={
        sideDrawer
          ? classes.sideDrawer
          : topDrawer
          ? clsx(classes.drawer, classes.topDrawer)
          : classes.topDrawer
      }
    >
      <div className={classes.header}>
        <IconButton
          className={classes.clearButton}
          aria-label="Clear"
          color="primary"
          onClick={() => onSetOpen(false)}
          size="large"
        >
          <ClearIcon />
        </IconButton>
      </div>
      <div
        className={classes.drawerContent}
        style={{
          height:
            !mortgageCalculatorOpen && drawerHt
              ? drawerHt - MODAL_DRAWER_HEADER_HT
              : 'auto',
        }}
      >
        {children}
      </div>
    </ModalDrawerStyled>,
    appRef.current
  )
}
