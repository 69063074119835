import React, { useEffect } from 'react'
import {
  useGetCamberElevationTrendsQuery,
  CommonQueryFilter,
  ElevationTrend,
  Elevation,
} from 'graphql/gen-types'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import ElevationTrends from './ElevationTrends'

interface CamberElevationTrendsProps {
  filter: CommonQueryFilter
  setSelectedElevation?: (c: Elevation[]) => void
  areaChart?: boolean | false
  height?: number | null
  width?: number | null
}

function CamberElevationTrends(props: CamberElevationTrendsProps): JSX.Element {
  const { data, error, loading } = useGetCamberElevationTrendsQuery({
    variables: {
      filter: props.filter,
    },
  })

  const popularElevations: ElevationTrend[] = data?.getCamberElevationTrends
    .elevations as ElevationTrend[]
  useSetSelectedElevation(props.setSelectedElevation, popularElevations || [])

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized
          message={error.toString()}
          imageName={'PopularElevations'}
        ></Unauthorized>
      )
    }
    console.error(error)
    return <div>Error while loading popular elevations</div>
  }

  return (
    <ElevationTrends
      {...props}
      popularElevations={popularElevations || []}
      loading={loading}
      error={error}
    />
  )
}

export default CamberElevationTrends

/**
 * inspects GraphQL response and builds Elevation[] from available fields
 */
function useSetSelectedElevation(
  setSelectedElevation: ((c: Elevation[]) => void) | undefined,
  data: ElevationTrend[]
): void {
  useEffect(() => {
    if (setSelectedElevation && data) {
      let elevations: Elevation[] = []
      elevations = data.map((e) => {
        return e.elevation
          ? e.elevation
          : {
              id: e.elevationId,
              planId: e.planId,
              planName: e.planName,
              caption: e.elevationCaption,
              clientName: e.clientName,
              name: `${e.planName} ${e.elevationCaption}`,
            }
      }) as Elevation[]
      setSelectedElevation(elevations)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setSelectedElevation])
}
