import TimesSeriesLineChart from 'components/common/time-series/TimeSeriesLineChart'
import {
  PopularityMetric,
  useGetLotLeadsQuery,
  EventBasedTimeSeriesFrame,
  Lot,
  useGetLotClicksQuery,
  LotLead,
} from 'graphql/gen-types'
import React, { useContext } from 'react'
import { addZeroCountDates } from 'components/common/time-series/TimeSeriesFormatter'
import ComponentCard from 'components/common/layout/ComponentCard'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { AnalyticsStore } from 'store/analyticsStore'

export interface LotTrendsProps {
  areaChart?: boolean | false
  height?: number | null
  width?: number | null
  lot: Lot | null
  clientName: string
}

const getLotFavoritedMetric = (
  lotLeads?: LotLead[] | null
): EventBasedTimeSeriesFrame[] => {
  const lotLeadsDailyMetric: EventBasedTimeSeriesFrame[] = []

  lotLeads?.forEach((lotLead) => {
    const currentDate = new Date(lotLead.date)
    const currentDateTransformed = new Date(
      Date.UTC(
        currentDate.getUTCFullYear(),
        currentDate.getUTCMonth(),
        currentDate.getUTCDate()
      )
    )

    const existingIndex = lotLeadsDailyMetric.findIndex(
      (metric) =>
        new Date(metric.date).getTime() ===
        new Date(currentDateTransformed).getTime()
    )

    if (existingIndex >= 0) {
      lotLeadsDailyMetric[existingIndex].value += 1
    } else {
      lotLeadsDailyMetric.push({
        date: currentDateTransformed.getTime(),
        value: 1,
      })
    }
  })

  return lotLeadsDailyMetric
}

export default function LotTrends({
  height,
  width,
  lot,
  clientName,
}: LotTrendsProps): JSX.Element | null {
  const { analyticsState } = useContext(AnalyticsStore)
  const { endDate, startDate } = analyticsState

  const yAxisDataKey = 'value'
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const { data: lotLeadsData, loading, error } = useGetLotLeadsQuery({
    variables: {
      filter: {
        endTime: endDate,
        startTime: startDate,
        communityId: lot?.communityId,
        clientName,
      },
      siteplanId: lot?.siteplanInfo?.siteplanId || 0,
      lotId: lot?.id || 0,
    },
    skip: !lot,
  })

  const {
    data: lotClicksData,
    loading: loadingLotClicks,
    error: lotClicksError,
  } = useGetLotClicksQuery({
    variables: {
      filter: {
        endTime: endDate,
        startTime: startDate,
        communityId: lot?.communityId,
        clientName,
      },
      siteplanId: lot?.siteplanInfo?.siteplanId || 0,
      lotId: lot?.id || 0,
    },
    skip: !lot,
  })

  if (!lot) {
    return null
  }

  const lotLeadsDailyMetric = getLotFavoritedMetric(lotLeadsData?.getLotLeads)

  const lotLeads = {
    trendName: `Favorited`,
    strokeColor: `#ff0000`,
    dailyMetrics: addZeroCountDates(
      { frames: lotLeadsDailyMetric },
      PopularityMetric.Frequency,
      startDate,
      endDate
    ),
  }

  const lotClicksDailyMetric: EventBasedTimeSeriesFrame[] = []
  lotClicksData?.getLotClicks?.forEach((lotClick) => {
    lotClicksDailyMetric.push({
      date: new Date(lotClick.day || '').getTime(),
      value: lotClick.count || 0,
    })
  })

  const lotClicks = {
    trendName: `Clicked`,
    strokeColor: `#00ff00`,
    dailyMetrics: addZeroCountDates(
      {
        frames: lotClicksDailyMetric,
      },
      PopularityMetric.Frequency,
      startDate,
      endDate
    ),
  }

  const chartData = []

  if (lotLeadsDailyMetric.length) {
    chartData.push(lotLeads)
  }

  if (lotClicksDailyMetric.length) {
    chartData.push(lotClicks)
  }

  const cardContent = (
    <TimesSeriesLineChart
      data={chartData}
      height={height}
      width={width}
      yAxisDataKey={yAxisDataKey}
      tooltipDataKey={'trendName'}
      xAxisDomain={{
        lower: startDate.getTime(),
        upper: new Date(
          Date.UTC(
            endDate.getUTCFullYear(),
            endDate.getUTCMonth(),
            endDate.getUTCDate() - 1
          )
        ).getTime(),
      }}
      useCustomLegend={false}
      metric={PopularityMetric.Frequency}
      areaChart={false}
    />
  )

  const componentCardData = lotLeadsData?.getLotLeads?.length
    ? lotLeadsData?.getLotLeads
    : lotClicksData?.getLotClicks

  return (
    <ComponentCard
      title="Trends"
      result={{
        data: componentCardData,
        error: error || lotClicksError,
        loading: loading || loadingLotClicks,
      }}
      skeletonHeight={400}
      height={isMobile ? 450 : undefined}
      style={{ minWidth: 500 }}
    >
      {cardContent}
    </ComponentCard>
  )
}
