import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import Typography from '@mui/material/Typography'

import {
  Community,
  ProspectReservation,
  ReservationBuilderStatus,
  useCommunitiesQuery,
  useGetAllProspectReservationsLazyQuery,
  useRejectReservationMutation,
} from '../../graphql/gen-types'
import { AppStore } from '../../store'
import { GET_ALL_PROSPECT_RESERVATIONS } from '../../graphql/nexus-queries/reservationIntegration'
import { useQuery, useSubscription } from '@apollo/client'
import { CLIENT_PROSPECT_RESERVATION_UPDATE } from '../../graphql/nexus-subscriptions/onlineReservationSubscriptions'
import hydrationStore from '../../store/HydrationStore'
import RejectReservationModal from '../../components/buy-online/reservations/RejectReservationModal'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  filterChangeArgs,
  OrderEnum,
  ReservationIntegrationIdEnum,
} from '../../components/buy-online/reservations/ReservationsListTypes'
import ReservationsListHeader from '../../components/buy-online/reservations/ReservationsListHeader'
import { StyledCard, StyledDiv, classes } from './BuyOnlineReservations.styles'
import ReservationsListBody from '../../components/buy-online/reservations/ReservationsListBody'
import ReservationsListFilters from '../../components/buy-online/reservations/ReservationsListFilters'
import { useRemoveLocationQuery } from '../../hooks/location'
import { useEventTracker } from '../../hooks/tracking'
import { ReservationTrackEvents } from '../../components/buy-online/reservationTrackEvents'
import { AuthContext } from '../../auth/AuthContext'
import { GET_IS_USER_ANEWGO_ADMIN } from '../../graphql/auth-queries/authDb'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import { UnauthorizedCard } from 'components/auth/Unauthorized'

const rowsPerPageOptions = [5, 10, 20, 50]

const BuyOnlineReservations: React.FC = () => {
  const { appState } = useContext(AppStore)
  const { user } = useContext(AuthContext)
  const clientName = appState?.selectedClient?.altName || ''
  const location = useLocation()
  const navigate = useNavigate()
  const params = new URLSearchParams(location.search)
  const isSignDeclined = params.get('event') === 'decline'
  const isSignCompleted = params.get('event') === 'signing_complete'
  const shouldBeTracked = params.has('track')
  const reservationIdParam = params.get('reservationId')
  const partiallySignedParam = params.get('partiallySigned')
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const removeLocationQuery = useRemoveLocationQuery()
  const track = useEventTracker()

  // Table state
  const [rowsPerPage, setRowPerPage] = useState<number>(rowsPerPageOptions[1])
  const [page, setPage] = useState<number>(0)
  const [orderBy, setOrderBy] = useState<ReservationIntegrationIdEnum>()
  const [order, setOrder] = useState<OrderEnum>()

  const { data: isAdminData } = useQuery(GET_IS_USER_ANEWGO_ADMIN, {
    variables: {
      userId: user?.userId,
    },
  })

  const communitiesQuery = useCommunitiesQuery({
    variables: {
      clientName,
    },
    skip: !clientName,
  })

  const communities = (communitiesQuery?.data?.communities || []) as Community[]
  const isUserAnewgoAdmin = isAdminData?.isUserAnewgoAdmin ?? false
  const isUserAdmin = user.roleId === 3
  const [
    getProspectReservation,
    { data, loading, error },
  ] = useGetAllProspectReservationsLazyQuery({
    fetchPolicy: 'cache-and-network',
  })
  useSubscription(CLIENT_PROSPECT_RESERVATION_UPDATE, {
    variables: {
      clientName: appState?.selectedClient?.altName,
      token: hydrationStore.token || '',
    },
  })

  const [
    rejectReservationMutation,
    { loading: rejectLoading },
  ] = useRejectReservationMutation()

  const rejectReservation = (id: string | null, rejectReason: string) => {
    rejectReservationMutation({
      variables: {
        clientName: appState?.selectedClient?.altName || '',
        reservationId: id || '',
        rejectReason: rejectReason || null,
      },
      refetchQueries: [
        {
          query: GET_ALL_PROSPECT_RESERVATIONS,
          variables: {
            clientName: appState?.selectedClient?.altName,
          },
        },
      ],
    })
  }

  const cleanDocusignParams = () => {
    params.delete('event')
    params.delete('reservationId')
    navigate(
      {
        search: `?${params.toString()}`,
      },
      {
        replace: true,
      }
    )
  }

  useEffect(() => {
    if (shouldBeTracked) {
      removeLocationQuery('track')

      if (shouldBeTracked && isSignCompleted) {
        track(ReservationTrackEvents.SIGNED_IN_DOCUSIGN, {
          reservationId: reservationIdParam,
        })
      } else if (shouldBeTracked && isSignDeclined) {
        track(ReservationTrackEvents.DECLINED_IN_DOCUSIGN, {
          reservationId: reservationIdParam,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Until we receive subscriptions after builder sing/reject document
  // We display loader
  // If new subscription is received check status and cleanup params
  useEffect(() => {
    if (isSignDeclined || isSignCompleted) {
      const { getOnlineReservations } = data || {}

      if (isSignDeclined) {
        const rejectedReservation = getOnlineReservations?.find(
          (reservation) =>
            reservation.reservationStatus ===
              ReservationBuilderStatus.RejectedByBuilder &&
            reservation.id === reservationIdParam
        )
        if (rejectedReservation) {
          cleanDocusignParams()
        }
      }

      if (isSignCompleted) {
        const completedReseration = getOnlineReservations?.find(
          (reservation) =>
            reservation.reservationStatus ===
              ReservationBuilderStatus.ApprovedByBuilder &&
            reservation.id === reservationIdParam
        )
        if (completedReseration) {
          cleanDocusignParams()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const [isDialogOpened, setIsDialogOpened] = useState(false)
  const [reservationId, setReservationId] = useState('')

  const handleSubmitDialog = (rejectReason: string) => {
    setIsDialogOpened(false)
    rejectReservation(reservationId, rejectReason)
  }

  const handleClose = () => {
    setIsDialogOpened(false)
  }

  const onlineReservationLength = data?.getOnlineReservations?.length || 0
  const areOnlineReservationsPresented = onlineReservationLength > 0

  const getPaginatedOnlineReservation = (
    allOnlineReservation: ProspectReservation[],
    page: number,
    rowsPerPage: number,
    order: OrderEnum | undefined,
    orderBy: ReservationIntegrationIdEnum | undefined
  ): ProspectReservation[] => {
    const sortedReservation = getSortedOnlineReservation(
      allOnlineReservation,
      order,
      orderBy
    )

    return sortedReservation.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
  }

  const getSortedOnlineReservation = (
    allOnlineReservation: ProspectReservation[],
    order: OrderEnum | undefined,
    orderBy: ReservationIntegrationIdEnum | undefined
  ) => {
    const newAllOnlineReservation = [...allOnlineReservation]

    newAllOnlineReservation.sort(
      (prospectReservation1, prospectReservation2) => {
        let comparisonParam1
        let comparisonParam2

        switch (orderBy) {
          case ReservationIntegrationIdEnum.CREATED_ON:
            comparisonParam1 = prospectReservation1?.createdAt
            comparisonParam2 = prospectReservation2?.createdAt
            break
          case ReservationIntegrationIdEnum.BUYER:
            comparisonParam1 = (
              (prospectReservation1?.prospect?.firstName ?? '') +
              ' ' +
              (prospectReservation1?.prospect?.lastName ?? '')
            ).trim()
            comparisonParam2 = (
              (prospectReservation2?.prospect?.firstName ?? '') +
              ' ' +
              (prospectReservation2?.prospect?.lastName ?? '')
            ).trim()
            break
          case ReservationIntegrationIdEnum.BUYER_PHONE:
            comparisonParam1 = prospectReservation1?.prospect?.phone
            comparisonParam2 = prospectReservation2?.prospect?.phone
            break
          case ReservationIntegrationIdEnum.BUYER_EMAIL:
            comparisonParam1 = prospectReservation1?.prospect?.email
            comparisonParam2 = prospectReservation2?.prospect?.email
            break
          case ReservationIntegrationIdEnum.COMMUNITY:
            comparisonParam1 = prospectReservation1?.favorite?.community?.name
            comparisonParam2 = prospectReservation2?.favorite?.community?.name
            break
          case ReservationIntegrationIdEnum.PLAN:
            comparisonParam1 =
              prospectReservation1?.favorite?.elevation?.planName
            comparisonParam2 =
              prospectReservation2?.favorite?.elevation?.planName
            break
          case ReservationIntegrationIdEnum.ELEVATION:
            comparisonParam1 =
              prospectReservation1?.favorite?.elevation?.caption
            comparisonParam2 =
              prospectReservation2?.favorite?.elevation?.caption
            break
          case ReservationIntegrationIdEnum.LOT:
            comparisonParam1 = prospectReservation1?.favorite?.lot?.name
            comparisonParam2 = prospectReservation2?.favorite?.lot?.name
            break
          case ReservationIntegrationIdEnum.TOTAL_PRICE:
            comparisonParam1 = prospectReservation1?.totalPrice
            comparisonParam2 = prospectReservation2?.totalPrice
            break
          case ReservationIntegrationIdEnum.RESERVATION_STATUS:
            comparisonParam1 = prospectReservation1?.reservationStatus
            comparisonParam2 = prospectReservation2?.reservationStatus
            break
          case ReservationIntegrationIdEnum.PAYMENT_STATUS:
            comparisonParam1 = prospectReservation1?.paymentDate ? '0' : '1'
            comparisonParam2 = prospectReservation2?.paymentDate ? '0' : '1'
            break
          case ReservationIntegrationIdEnum.SIGNING_STATUS:
            comparisonParam1 =
              prospectReservation1?.signatureDate &&
              prospectReservation1.secondaryBuyerReservations?.every(
                (secondaryBuyer) => secondaryBuyer.signatureDate
              )
                ? '0'
                : '1'

            comparisonParam2 =
              prospectReservation2?.signatureDate &&
              prospectReservation2.secondaryBuyerReservations?.every(
                (secondaryBuyer) => secondaryBuyer.signatureDate
              )
                ? '0'
                : '1'
            break
        }
        if (
          comparisonParam1 &&
          comparisonParam2 &&
          comparisonParam1 < comparisonParam2
        ) {
          return order === OrderEnum.ASC ? -1 : 1
        }
        if (
          comparisonParam1 &&
          comparisonParam2 &&
          comparisonParam1 > comparisonParam2
        ) {
          return order === OrderEnum.ASC ? 1 : -1
        }
        return 0
      }
    )
    return newAllOnlineReservation
  }

  const handlePaginationChange = (
    e: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleRowPerPageChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRowPerPage(Number(event.target.value))
    resetPagination()
  }

  const onFilterChange = (filter: filterChangeArgs) => {
    resetPagination()
    setIsFilterApplied(
      !!(
        filter.buyerInfo ||
        filter.status ||
        filter.communityId ||
        filter.includeDeleted
      )
    )
    getProspectReservation({
      variables: {
        clientName: appState?.selectedClient?.altName || '',
        filter,
      },
    })
  }

  const handleOrderChange = (
    id: ReservationIntegrationIdEnum,
    order: OrderEnum | undefined
  ) => {
    const isAsc = order ? orderBy === id && order === OrderEnum.ASC : false
    setOrder(isAsc ? OrderEnum.DESC : OrderEnum.ASC)
    setOrderBy(id)
  }

  const resetPagination = () => {
    setPage(0)
  }

  if (error && verifyUserAuthError(error.toString()))
    return (
      <UnauthorizedCard
        title="Online reservation"
        message={error.toString()}
        imageName={'buyOnlineCommunitiesStatusOverview'}
      />
    )

  return (
    <StyledDiv className={classes.root}>
      <StyledCard>
        <CardHeader title="Reservations" />
        <CardContent>
          <ReservationsListFilters
            communities={communities}
            onFilterChange={onFilterChange}
          />
          <>
            <TableContainer component={Paper}>
              <Table stickyHeader>
                <ReservationsListHeader
                  order={order}
                  orderBy={orderBy}
                  setSort={(key) => handleOrderChange(key, order)}
                  isUserAnewgoAdmin={isUserAnewgoAdmin}
                  isUserAdmin={isUserAdmin}
                  isOnlineReservationFetching={loading}
                />
                {areOnlineReservationsPresented && (
                  <ReservationsListBody
                    setReservationId={setReservationId}
                    reservationId={reservationId}
                    setIsDialogOpened={setIsDialogOpened}
                    rejectLoading={rejectLoading}
                    onlineReservations={getPaginatedOnlineReservation(
                      data?.getOnlineReservations as ProspectReservation[],
                      page,
                      rowsPerPage,
                      order,
                      orderBy
                    )}
                    isSignDeclined={isSignDeclined}
                    isSignCompleted={isSignCompleted}
                    reservationIdParam={reservationIdParam}
                    partiallySignedParam={partiallySignedParam}
                    isUserAnewgoAdmin={isUserAnewgoAdmin}
                  />
                )}
              </Table>
              {!loading && !areOnlineReservationsPresented && (
                <Typography
                  variant="h4"
                  align="center"
                  className={classes.noReservationHeader}
                >
                  {isFilterApplied
                    ? 'No Reservations were found for current filters'
                    : 'No Reservations found'}
                </Typography>
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={onlineReservationLength}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePaginationChange}
              onRowsPerPageChange={handleRowPerPageChange}
            />
            <RejectReservationModal
              reservationId={reservationId}
              isDialogOpened={isDialogOpened}
              handleSubmitDialog={handleSubmitDialog}
              handleClose={handleClose}
            />
          </>
        </CardContent>
      </StyledCard>
    </StyledDiv>
  )
}

export default BuyOnlineReservations
