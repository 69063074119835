import { gql } from '@apollo/client'
import { cityFieldsFull } from './city'

const COMMUNITIES_BY_IDS = gql`
  query CommunitiesByIds(
    $clientName: String!
    $communityIds: [Int!]
    $filter: CommonQueryFilter!
  ) {
    communities(clientName: $clientName, communityIds: $communityIds) {
      id
      clientName
      name
      latitude
      longitude
      logo
      active
      cityLocation(clientName: $clientName) {
        ...CityFieldsFull
      }
      plans {
        id
        clientName
        communityId
        name
        displayName
        defaultElevationId
        bed
        bedMin
        bedMax
        bedRange {
          min
          max
          __typename
        }
        bath
        bathMin
        bathMax
        bathRange {
          min
          max
        }
        size
        sizeMin
        sizeMax
        sizeRange {
          min
          max
        }
        cost
        costMin
        costMax
        costRange {
          min
          max
        }
        description
        elevations(clientName: $clientName) {
          id
          planDisplayName
          internalName
          planName
          active
        }
      }
      siteplans(clientName: $clientName) {
        id
        communityId
        name
        src
        lotWidth
        lotHeight
        lotFontSize
        lotMetric
        master
        svg {
          shapes {
            tagName
            attributes {
              className
              dataName
              x
              y
              width
              height
              transform
              points
              d
              __typename
            }
            __typename
          }
          style
          viewBox {
            x
            y
            width
            height
          }
        }
        lotLegend {
          id
          code
          name
          hex
        }
        hotspots(clientName: $clientName) {
          id
          siteplanId
          name
          x
          y
          width
          height
          description
          thumb
        }
        popularLots(filter: $filter) {
          id
          communityId
          dataName
          name
          salesStatus
          premium
          externalId
          address
          size
          cityName
          stateCode
          zip
          postCode
          popularity
          count
          siteplanInfo {
            lotId
            siteplanId
            x
            y
          }
          availableSchemes(clientName: $clientName) {
            id
            name
          }
          excludedPlanElevations(clientName: $clientName) {
            planId
            elevationId
            planName
            planDisplayName
            elevationCaption
          }
          availablePlanElevations(clientName: $clientName) {
            planId
            elevationId
            planName
            planDisplayName
            elevationCaption
          }
          inventory(clientName: $clientName) {
            id
            lotId
            planId
            elevationId
            communityId
            price
            sqft
            beds
            baths
            features
            constructionStatus
            closingDate
            mlsId
            garageType
            garageCapacity
            floors
            schemeId
            photoFolder
            plan(clientName: $clientName) {
              id
              communityId
              name
              displayName
            }
            sgtData(clientName: $clientName) {
              id
              sgtVendorId
              sgtExternalId
              sgtData
            }
            appointmentsEnabled
            reserveHomeUrl
            photos(clientName: $clientName) {
              id
              src
              listIndex
            }
            plan(clientName: $clientName) {
              id
              displayName
              name
              communityId
              defaultElevationId
              description
              bed
              bath
              cost
              size
              videoUrl
              interactiveInteriorIds(clientName: $clientName)
              bedRange(clientName: $clientName) {
                min
                max
              }
              bathRange(clientName: $clientName) {
                min
                max
              }
              costRange(clientName: $clientName) {
                min
                max
              }
              sizeRange(clientName: $clientName) {
                min
                max
              }
            }
            elevation(clientName: $clientName) {
              id
              mirrorElevationId
              mirrorRolePrimary
              communityId
              planName
              planDisplayName
              planId
              caption
              thumb
              bed
              bath
              size
              cost
              defaultFloor
              description
              svgMirroring
              garagePosition
              defaultGaragePosition
              bedRange(clientName: $clientName) {
                min
                max
              }
              bathRange(clientName: $clientName) {
                min
                max
              }
              costRange(clientName: $clientName) {
                min
                max
              }
              sizeRange(clientName: $clientName) {
                min
                max
              }
              tags(clientName: $clientName) {
                categoryName
                tagName
              }
            }
          }
        }
      }
    }
  }
  ${cityFieldsFull}
`

export const COMMUNITIES_BY_IDS_SITEPLANS_INFO = gql`
  query CommunitiesByIdsSiteplansInfo(
    $clientName: String!
    $communityIds: [Int!]
    $filter: CommonQueryFilter!
  ) {
    communities(clientName: $clientName, communityIds: $communityIds) {
      id
      clientName
      name
      latitude
      longitude
      logo
      active
      siteplans(clientName: $clientName, active: true) {
        id
        communityId
        name
        src
        lotWidth
        lotHeight
        lotFontSize
        lotMetric
        master
        geoInfo(clientName: $clientName) {
          id
          siteplanId
          neLatitude
          neLongitude
          swLatitude
          swLongitude
          geoJson
        }
        svg {
          shapes {
            tagName
            attributes {
              className
              dataName
              x
              y
              width
              height
              transform
              points
              d
              __typename
            }
            __typename
          }
          style
          viewBox {
            x
            y
            width
            height
          }
        }
        lotLegend {
          id
          code
          name
          hex
        }
        hotspots(clientName: $clientName) {
          id
          siteplanId
          name
          x
          y
          width
          height
          description
          thumb
        }
        lots(clientName: $clientName) {
          id
          communityId
          dataName
          name
          salesStatus
          premium
          externalId
          address
          size
          cityName
          stateCode
          zip
          postCode
          geoJson
          siteplanInfo {
            lotId
            siteplanId
            x
            y
          }
        }
        popularLots(filter: $filter) {
          id
          communityId
          dataName
          name
          salesStatus
          premium
          externalId
          address
          size
          cityName
          stateCode
          zip
          postCode
          popularity
          count
          leadsCount
          siteplanInfo {
            lotId
            siteplanId
            x
            y
          }
        }
      }
    }
  }
`

export default COMMUNITIES_BY_IDS
