import React, { useContext, useEffect, useRef } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import {
  PopularCommunityByCount,
  PopularCommunityByDuration,
  PopularCommunityByFavoritesCount,
} from 'graphql/gen-types'
import { AppStore } from 'store'
import clsx from 'clsx'
import chroma from 'chroma-js'
import { getTextColor } from 'utils/functions'
import { StyledGrid, classes } from './PopularCommunitiesMap.styles'
import ComponentCard from '../../common/layout/ComponentCard'
import { useTheme, useMediaQuery } from '@mui/material'

const COMPONENT_HEIGHT = 700
const COMPONENT_HEIGHT_MOBILE = '70vh'

const colors: string[][] = []

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY || ''

type Point = {
  type: 'Point'
  coordinates: number[]
}
type MapFeature = {
  type: string
  geometry: Point
  properties: {
    title: string
    idx: number
  }
}

type FeatureCollection = {
  type: string
  features: MapFeature[]
}

const constructLocationData = (
  popularCommunities:
    | PopularCommunityByCount[]
    | PopularCommunityByDuration[]
    | PopularCommunityByFavoritesCount[]
): FeatureCollection => {
  const features: MapFeature[] = []
  popularCommunities.forEach(
    (
      popularCommunity:
        | PopularCommunityByCount
        | PopularCommunityByDuration
        | PopularCommunityByFavoritesCount,
      idx: number
    ) => {
      if (popularCommunity.community) {
        features.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [
              popularCommunity.community.longitude || 0,
              popularCommunity.community.latitude || 0,
            ],
          },
          properties: {
            title: popularCommunity.community?.name || '',
            idx,
          },
        })
      }
    }
  )
  const data = {
    type: 'FeatureCollection',
    features,
  }
  return data
}

const fitBoundsForMap = (map: any, locations: any): void => {
  const MARKERS_PADDING = 24
  const MARKERS_COUNT_LIMIT = 2
  const bounds = new mapboxgl.LngLatBounds()
  let markersCount = 0
  locations.features.forEach(function (marker: any) {
    bounds.extend(
      new mapboxgl.LngLat(
        marker.geometry.coordinates[0],
        marker.geometry.coordinates[1]
      )
    )
    markersCount += 1
  })
  if (markersCount > MARKERS_COUNT_LIMIT) {
    map.fitBounds(bounds, { padding: MARKERS_PADDING })
  } else if (markersCount <= MARKERS_COUNT_LIMIT && markersCount > 0) {
    map.fitBounds(bounds, { padding: MARKERS_PADDING, maxZoom: 8 })
  }
}

const removeMarkers = (markers: any) => {
  if (!markers) {
    return
  }
  markers.forEach((commMarker: any) => {
    commMarker.marker.remove()
  })
}

const createMarker = (
  classes: any,
  popularCommunity:
    | PopularCommunityByCount
    | PopularCommunityByDuration
    | PopularCommunityByFavoritesCount
    | null,
  mapRef: any,
  index: number | null,
  popularCommunityLength: number
): any => {
  const el = window.document.createElement('div')
  el.className = clsx(classes.marker)
  el.style.width = '30px'
  el.style.height = '30px'
  let marker = {}
  if (popularCommunity && popularCommunity.community) {
    if (index !== null) {
      el.style.backgroundColor = colors[index][0]
      el.style.color = colors[index][1]
      el.style.zIndex = (popularCommunityLength - 1 - index).toString()

      // features for top 5 only
      if ([0, 1, 2, 3, 4].includes(index)) {
        el.style.border = '3px solid skyblue'
        el.innerHTML = index !== null ? `<h2>${index + 1}</h2>` : ''
        el.style.width = '40px'
        el.style.height = '40px'
      }

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        className: 'popularCommunitiesMapPopup',
      })

      el.addEventListener('mouseenter', function (e: any) {
        // Populate the popup and set its coordinates
        // based on the feature found.
        el.style.zIndex = '1000'
        popup
          .setLngLat({
            lng: popularCommunity?.community?.longitude || 0,
            lat: popularCommunity?.community?.latitude || 0,
          })
          .setHTML(`<span>Name: ${popularCommunity.communityName}</span>`)
          .addTo(mapRef.current)
      })

      el.addEventListener('mouseleave', function () {
        popup.remove()
        el.style.zIndex = (popularCommunityLength - 1 - index).toString()
      })
    }

    marker = new mapboxgl.Marker(el)
      .setLngLat({
        lng: popularCommunity.community.longitude || 0,
        lat: popularCommunity.community.latitude || 0,
      })
      .addTo(mapRef.current)
  }

  return { popularCommunity, marker }
}

const constructMarkers = (
  directoryName: string,
  popularCommunities:
    | PopularCommunityByCount[]
    | PopularCommunityByDuration[]
    | PopularCommunityByFavoritesCount[],
  classes: any,
  mapRef: any
): any => {
  const newMarkers: any = []

  popularCommunities.forEach(
    (
      popularCommunity:
        | PopularCommunityByCount
        | PopularCommunityByDuration
        | PopularCommunityByFavoritesCount,
      idx: number
    ) => {
      if (
        !popularCommunity.community ||
        popularCommunity.community.latitude === null ||
        popularCommunity.community.longitude === null
      ) {
        return
      }
      newMarkers.push(
        createMarker(
          classes,
          popularCommunity,
          mapRef,
          idx,
          popularCommunities.length
        )
      )
    }
  )
  return newMarkers
}

interface PopularCommunitiesProps {
  activeTab?: number
  popularCommunities:
    | PopularCommunityByCount[]
    | PopularCommunityByDuration[]
    | PopularCommunityByFavoritesCount[]
  setActiveTab?: React.Dispatch<React.SetStateAction<number>>
}

function PopularCommunitiesMap(props: PopularCommunitiesProps): JSX.Element {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { appState } = useContext(AppStore)
  const { selectedClient } = appState
  const { popularCommunities, activeTab } = props
  const directoryName = selectedClient ? selectedClient.directoryName : ''

  const mapRef: any = useRef(null)
  const locationDataRef: any = useRef(null)
  const markersRef: any = useRef([])
  const mapContainerRef: any = useRef(null)

  const scaleBgColor = chroma.scale(['#e65400', '#f7f7ba'])
  const bgColors = scaleBgColor.colors(popularCommunities.length)

  const constructMap = (): void => {
    const map = mapRef.current

    // populate the range of colors
    bgColors.forEach((color, i) => {
      colors.push([color, getTextColor(color)])
    })

    locationDataRef.current = constructLocationData(popularCommunities)
    if (map.getLayer('communityPoints') !== undefined) {
      map.removeLayer('communityPoints')
    }
    if (map.getSource('communityPoints') !== undefined) {
      map.removeSource('communityPoints')
    }

    map.addLayer({
      id: 'communityPoints',
      type: 'symbol',
      source: {
        type: 'geojson',
        data: locationDataRef.current,
      },
      layout: {
        'text-field': '{title}',
        'text-offset': [0, 2.4],
        'text-size': 12,
      },
    })

    if (appState.darkMode) {
      map.setStyle('mapbox://styles/mapbox/dark-v10')
    }

    // also add markers on the map
    // fitBoundsForMap(comp.map, comp.locationData)
    removeMarkers(markersRef.current)
    markersRef.current = constructMarkers(
      directoryName,
      popularCommunities,
      classes,
      mapRef
    )

    fitBoundsForMap(mapRef.current, locationDataRef.current)
  }

  useEffect(() => {
    if (popularCommunities.length === 0) {
      return
    }
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      doubleClickZoom: false,
      center: [-78.8354547, 35.6499533], // starting pos
      style: 'mapbox://styles/lion-keng/cklv43yd829e618obmuofh4qx',
      // style: 'mapbox://styles/mapbox/streets-v11',
    })
    mapRef.current.on('load', () => {
      constructMap()
    })
    return (): void => {
      removeMarkers(markersRef.current)
      markersRef.current = null
      mapRef.current.remove()
      mapRef.current = null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => {
      if (mapRef.current && mapRef.current.loaded()) {
        constructMap()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [popularCommunities, mapRef.current]
  )

  const handleTabChange = (
    ev: unknown,
    newValue: React.SetStateAction<number>
  ): void => {
    props.setActiveTab && props.setActiveTab(newValue)
  }

  // r e n d e r
  const styles = {
    map: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '100%',
    } as React.CSSProperties,
    parent: {
      width: '100%',
      height: '100%',
      position: 'relative',
      textAlign: 'justify',
    } as React.CSSProperties,
  }

  return (
    <ComponentCard
      title="Popular Communities Map"
      height={isMobile ? COMPONENT_HEIGHT_MOBILE : COMPONENT_HEIGHT}
      result={{ data: popularCommunities, loading: false, error: undefined }}
    >
      {activeTab !== undefined && (
        <Tabs
          centered
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant={isMobile ? 'fullWidth' : 'standard'}
        >
          <Tab label="By Views" />
          <Tab
            data-testid="popularCommunitiesMapDurationTab"
            label="By Duration"
          />
          <Tab
            data-testid="popularCommunitiesMapFavoritesCountTab"
            label="By Favorites Count"
          />
        </Tabs>
      )}
      <StyledGrid
        container
        className={classes.gridRoot}
        justifyContent="center"
        spacing={0}
      >
        <div style={styles.parent}>
          <div style={styles.map} ref={mapContainerRef} />
        </div>
      </StyledGrid>
    </ComponentCard>
  )
}

export default PopularCommunitiesMap
