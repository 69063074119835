import React from 'react'
import {
  CommonQueryFilter,
  PageReferrals,
  usePageReferralsQuery,
} from 'graphql/gen-types'
import ComponentCard from 'components/common/layout/ComponentCard'
import ResponsiveTable from 'components/common/tables/ResponsiveTable'
import { humanTimeDuration } from 'utils/functions'
import chroma from 'chroma-js'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { PieChart, Pie, Cell, Tooltip } from 'recharts'
import { CustomLabelProps } from 'components/common/charts/chart-types'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import {
  CustomTooltipStyled,
  classes,
  HelpBlock,
  HelpText,
} from './PageReferrals.style'

const NO_REFFERER = 'No Referrer'
interface PageReferralsProps {
  filter: CommonQueryFilter
}

type Payload = {
  payload: PageReferrals
  name: string
  value: number
}

type CustomTooltipProps = {
  payload?: Payload[]
  active?: boolean
}

export default function PageReferralsTable({
  filter,
}: PageReferralsProps): JSX.Element {
  const { data } = usePageReferralsQuery({ variables: { filter } })

  const pageReferrals: PageReferrals[] = (data?.pageReferrals ||
    []) as PageReferrals[]

  const scale = chroma.scale(['#0088FE', '#00C49F', '#FFBB28', '#FF8042'])
  const colors = scale.colors(pageReferrals.length)

  const chartData = pageReferrals.map((row) => ({
    name: row.referrer,
    value: row.visitors,
  }))
  return (
    <ComponentCard
      title="Acquisitions"
      subtitle="Which sites users are being referred/acquired from."
    >
      <Grid container justifyContent="center">
        <PieChart width={500} height={400}>
          <Pie
            data={chartData}
            dataKey="value"
            labelLine={false}
            label={renderCustomizedLabel}
          >
            {chartData.map((entry, index) => (
              <Cell key={`cell-${entry?.name || index}`} fill={colors[index]} />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>

        <Grid item xs={12}>
          <ResponsiveTable
            data={pageReferrals}
            columns={[
              'Index',
              'Referrer',
              'Visitors',
              'Sessions',
              'Bounce Rate',
              'Avg. Duration',
              'Registrations',
            ]}
            row={(row, i) => [
              <Grid container justifyContent="center">
                <Avatar
                  style={{
                    backgroundColor: colors[pageReferrals.indexOf(row) || 0],
                    height: '20px',
                    width: '20px',
                    margin: '2px',
                  }}
                >
                  {' '}
                </Avatar>
              </Grid>,
              row.referrer || NO_REFFERER,
              row.visitors,
              row.sessions,
              `${Math.round(row.bounceRate * 100)}%`,
              humanTimeDuration(row.avgDuration),
              row.registers,
            ]}
            dense={true}
            initialRows={5}
          ></ResponsiveTable>
          <HelpBlock>
            <HelpOutlineIcon />
            <HelpText variant="caption">
              "No Referrer" includes direct users as well as unknown sources
              where the origin can not be determined.
            </HelpText>
          </HelpBlock>
        </Grid>
      </Grid>
    </ComponentCard>
  )
}

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: CustomLabelProps): JSX.Element | null => {
  // This snippet of code and calculation comes from here:
  // http://recharts.org/en-US/examples/PieChartWithCustomizedLabel
  const RADIAN = Math.PI / 180
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  // If the pie slice is going to be small (10% or less), we are not going to display the percent
  // text because it can overlap into other slices.
  if (percent * 100 <= 10) {
    return null
  }

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

const CustomTooltip = (props: CustomTooltipProps): JSX.Element | null => {
  const { active, payload } = props
  if (!payload) {
    return null
  }
  if (active) {
    return (
      <CustomTooltipStyled className={classes.customTooltip}>
        <h4 style={{ paddingTop: '0px' }}>{payload[0].name || NO_REFFERER}</h4>
      </CustomTooltipStyled>
    )
  }

  return null
}
