import React from 'react'
import {
  Prospect,
  DurationInfo,
  ProspectFavorite,
  ProspectTopPlan,
  Maybe,
  ProspectFavoriteEvent,
  PlanPopularityMetrics,
} from '../../../graphql/gen-types'
import ProspectFavoritesGrid from 'components/leads/prospect-page/ProspectFavoritesGrid'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ProspectFavoritesComparison from 'components/leads/prospect-page/ProspectFavoritesComparison'

import { ProspectPlan } from '../../common/by-plan/PlansGrid'
import { isArchived } from 'utils/functions'

import { TabsStyled } from './styles/ProspectFavorites.styles'

interface ProspectFavoritesProps {
  hideNotBrowsedBanner?: boolean
  data: Prospect | undefined | null
  loading?: boolean
  error?: Error | undefined
}

function ProspectFavorites({
  hideNotBrowsedBanner,
  data,
  loading,
  error,
}: ProspectFavoritesProps): JSX.Element {
  const [value, setValue] = React.useState(0)
  const favorites = data?.favorites || []
  const planStatistics = data?.statistics?.plansStatistics || []
  const favoritedEvents = data?.statistics?.favorites || []
  const favoritePlans = getFavoritePlansWithDetails(
    favorites,
    planStatistics,
    favoritedEvents
  )

  const handleChange = (
    ev: unknown,
    newValue: React.SetStateAction<number>
  ): void => {
    setValue(newValue)
  }

  return (
    <div>
      <TabsStyled
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label="Favorites summary"></Tab>
        <Tab label="Compare favorites"></Tab>
      </TabsStyled>
      <TabPanel value={value} index={0}>
        <ProspectFavoritesGrid
          hideNotBrowsedBanner={hideNotBrowsedBanner}
          data={data}
          loading={loading}
          error={error}
          favoritePlans={favoritePlans}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ProspectFavoritesComparison
          data={data}
          loading={loading}
          error={error}
          favoritePlans={favoritePlans}
        />
      </TabPanel>
    </div>
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps): JSX.Element {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

interface fpOpt {
  name: string
  children: { name: string }[]
}

interface fpOptChild {
  name: string
}

export function getFavoritePlansWithDetails(
  favorites: ProspectFavorite[],
  planStatistics: Maybe<ProspectTopPlan>[],
  favoritedEvents: Maybe<ProspectFavoriteEvent>[]
): ProspectPlan[] {
  // Get all prospect favorited plans, with elevation just as the favorited elevation (not array of all available)
  const favoritePlans: ProspectPlan[] = []
  favorites?.forEach((fav: ProspectFavorite) => {
    //Create list of fp options names
    const fpOptsList: string[] = []
    const floors = Object.keys(fav.fpOptSelections)
    floors.forEach((floor: string) => {
      fav?.fpOptSelections[floor].forEach((fpOpt: fpOpt) => {
        if (fpOpt?.name) {
          fpOptsList.push(fpOpt.name)
        }
        if (fpOpt.children && fpOpt.children.length > 0) {
          fpOpt.children.forEach((child: fpOptChild) => {
            if (child.name) {
              fpOptsList.push(child.name)
            }
          })
        }
      })
    })
    const elevation = fav.plan?.elevations?.find(
      (elev) => elev?.id === fav.elevationId
    )
    // If both plan and elevation are not archived, add to list
    if (
      !isArchived(fav.plan?.name || '') &&
      !isArchived(elevation?.caption || '')
    ) {
      favoritePlans.push({
        favoriteId: fav.id,
        ...fav.plan,
        elevations: elevation ? [elevation] : [],
        communityName: fav?.community?.name,
        fpOptsList,
        scheme: fav?.scheme,
        source: fav.source,
      } as ProspectPlan)
    }
  })

  // Get prospect plan statistics with popularityMetrics. This is data on all view plans.
  const viewedPlans =
    planStatistics?.map((p) => {
      return {
        ...p?.plan,
        popularityMetrics: getPlanStatisticsMetric(p),
      } as ProspectPlan
    }) || []

  // favorite plans with data
  return favoritePlans.map((favPlan) => {
    // Get the viewedPlan statistics (metrics) for this favorite plan, if it exists
    const metric = viewedPlans.find((plan) => plan.id === favPlan.id)
      ?.popularityMetrics

    // Grab timestamp when plan was favorited
    const favoritedTimestamp = favoritedEvents?.find(
      (event) =>
        event?.planId === favPlan.id &&
        event?.elevationId === ((favPlan?.elevations || [])[0]?.id || 0)
    )?.timestamp

    // return favorite plan with prospect metric data and timestamp
    return {
      ...favPlan,
      popularityMetrics: metric,
      timestamp: favoritedTimestamp,
    } as ProspectPlan
  })
}

function getPlanStatisticsMetric(
  prospectPlan?: ProspectTopPlan | null
): PlanPopularityMetrics {
  const zeroDuration: DurationInfo = {
    total: 0,
    avg: 0,
    min: 0,
    max: 0,
    sessionCount: 0,
    median: 0,
  }
  return {
    byFrequency: prospectPlan?.events || 0,
    byDuration: {
      ...zeroDuration,
      total: prospectPlan?.duration || 0,
    },
  }
}

export default ProspectFavorites
export const testingVariables = {
  getFavoritePlansWithDetails,
  getPlanStatisticsMetric,
}
