import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import { SchemeSwatch } from '@anewgo/interactive-exterior-ng'
import ComponentCard from 'components/common/layout/ComponentCard'
import { Scheme } from 'graphql/gen-types'
import ResponsiveTable from 'components/common/tables/ResponsiveTable'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TableImageDialog from 'components/common/misc/TableImageDialog'
import { ResponsiveCell } from '../tables/ResponsiveTable/ResponsiveTable.styles'

const COMPONENT_TITLE = 'Color Schemes'
const RESPONSE_SIZE = 'sm'
const ROW_HEIGHT = 90
const SWATCH_WIDTH = 62.5 // these dimensions allow the height to match the floorplans table for consistent height on the lead details page.
const SWATCH_HEIGHT = 62.5
const SWATCH_DIALOG_WIDTH = 300
const SWATCH_DIALOG_HEIGHT = 300
interface SchemesDisplayProps {
  data: Scheme[]
  loading?: boolean
  error?: Error | undefined
}

function SchemesDisplay({
  data,
  loading,
  error,
}: SchemesDisplayProps): JSX.Element {
  const [imageDialogOpened, setImageDialogOpened] = useState(false)
  const [selectedImage, setSelectedImage] = useState<JSX.Element>()

  // If the scheme object has popularity, then show the fields.
  const showPopularity = data && data[0]?.popularity !== undefined
  const displaySchemes = data || []

  const tableHeadCells: (JSX.Element | null)[] = [
    <ResponsiveCell
      align="center"
      key="header-cell-scheme"
      responseSize={RESPONSE_SIZE}
      rowHeight={ROW_HEIGHT}
    >
      Scheme
    </ResponsiveCell>,
    <ResponsiveCell
      align="center"
      colSpan={2}
      key="header-cell-name"
      responseSize={RESPONSE_SIZE}
      rowHeight={ROW_HEIGHT}
    >
      Name
    </ResponsiveCell>,
    showPopularity ? (
      <ResponsiveCell
        align="right"
        key="header-cell-popularity"
        responseSize={RESPONSE_SIZE}
        rowHeight={ROW_HEIGHT}
      >
        Popularity
      </ResponsiveCell>
    ) : null,
  ]

  const handleImageClick = (img: JSX.Element): void => {
    setSelectedImage(img)
    setImageDialogOpened(true)
  }

  const ResponsiveTableRow = ({
    row,
    index,
  }: {
    row: Scheme
    index?: number
  }): JSX.Element => {
    const name = row?.name

    const schemeSwatchImg = (
      width: number | string = SWATCH_WIDTH,
      height: number | string = SWATCH_HEIGHT
    ): JSX.Element => (
      <Box style={{ width, height, cursor: 'pointer' }}>
        <SchemeSwatch
          directoryName={row?.clientName}
          materials={row?.materials || []}
        />
      </Box>
    )
    return (
      <>
        <ResponsiveCell
          align="center"
          responseSize={RESPONSE_SIZE}
          rowHeight={ROW_HEIGHT}
        >
          <Grid container justifyContent="center">
            <Box
              onClick={(): void =>
                handleImageClick(
                  schemeSwatchImg(SWATCH_DIALOG_WIDTH, SWATCH_DIALOG_HEIGHT)
                )
              }
            >
              {schemeSwatchImg()}
            </Box>
          </Grid>
        </ResponsiveCell>
        <ResponsiveCell
          align="center"
          component="th"
          scope="row"
          responseSize={RESPONSE_SIZE}
          rowHeight={ROW_HEIGHT}
        >
          <Typography noWrap>{name}</Typography>
        </ResponsiveCell>

        {showPopularity ? (
          <ResponsiveCell
            align="right"
            responseSize={RESPONSE_SIZE}
            rowHeight={ROW_HEIGHT}
          >
            {row.popularity}
          </ResponsiveCell>
        ) : null}
      </>
    )
  }

  return (
    <>
      <ComponentCard title={COMPONENT_TITLE} result={{ data, loading, error }}>
        <ResponsiveTable
          data={displaySchemes}
          generateRow={(dataRow, index): JSX.Element => (
            <ResponsiveTableRow row={dataRow} index={index} />
          )}
          columns={tableHeadCells}
          responseSize={RESPONSE_SIZE}
          rowHeight={ROW_HEIGHT}
          initialRows={5}
        />
      </ComponentCard>
      <TableImageDialog
        open={imageDialogOpened}
        onClose={(): void => setImageDialogOpened(false)}
        image={selectedImage}
      />
    </>
  )
}

export default SchemesDisplay
