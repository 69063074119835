import { styled } from '@mui/material/styles'
import { MAIN_APP_BAR_HEIGHT } from '../../constants'
import LinearProgress from '@mui/material/LinearProgress'

const PREFIX = 'ReservationIntegration'

export const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  contentDisallowed: `${PREFIX}-contentDisallowed`,
  helpButton: `${PREFIX}-helpButton`,
  progressBar: `${PREFIX}-progressBar`,
  disclaimer: `${PREFIX}-disclaimer`,
  bar: `${PREFIX}-bar`,
  toggleBuyOnline: `${PREFIX}-toggleBuyOnline`,
}

export const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'relative',
    overflowY: 'scroll',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',
    width: '100%',
    height: `calc(100vh - ${MAIN_APP_BAR_HEIGHT}px)`,
    padding: 20,
  },

  [`& .${classes.paper}`]: {
    position: 'relative',
    overflow: 'hidden',
    padding: 20,
    margin: '1.5em 0',
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.light
        : theme.palette.primary.main,
  },

  [`& .${classes.contentDisallowed}`]: {
    filter: 'blur(3px)',
    pointerEvents: 'none',
  },

  [`& .${classes.helpButton}`]: {
    display: 'flex',
    marginLeft: 'auto',
  },

  [`& .${classes.progressBar}`]: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },

  [`& .${classes.disclaimer}`]: {
    margin: theme.spacing(3, 0),
    '& a': {
      color: theme.palette.primary.main,
    },
  },

  [`& .${classes.toggleBuyOnline}`]: {
    float: 'right',
    marginTop: '10px',
  },
}))

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  [`& .${classes.bar}`]: {
    borderRadius: 5,
    backgroundColor: (props: { value: number }) => {
      const { value } = props
      if (value < 25) {
        return theme.palette.error.main
      }

      if (value < 50) {
        return theme.palette.warning.main
      }

      if (value < 100) {
        return theme.palette.warning.light
      }

      if (value === 100) {
        return theme.palette.success.main
      }

      return theme.palette.primary.main
    },
  },
}))
//TODO: rewrite this style according to material-ui 5
//     toggleBuyOnline: {
//       float: 'right',
//       marginTop: '10px',
//     },
//   })
// )
