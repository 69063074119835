import React, { useContext } from 'react'
import UserManagement from '../../components/account/user-management/UserManagement'
import { Root, classes } from './Admin.styles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { AuthContext } from 'auth/AuthContext'
import AnewgoAdminSettings from 'components/account/AnewgoAdminSettings'
import {
  isAnewgoMember,
  isAdminOrAnewgoMember,
} from 'utils/authorizationHelpers'
import WebhookSubscription from 'components/account/WebhookSubscription'
import PermanentFilters from 'components/account/PermanentFilters'
import AdminSettings from 'components/account/AdminSettings'

function tabProps(label: string) {
  return {
    id: `simple-tab-${label}`,
    'aria-controls': `simple-tabpanel-${label}`,
    value: label,
  }
}

enum TabNames {
  UserManagement = 'userManagement',
  AnewgoAdminSettings = 'anewgoAdminSettings',
  AdminSettings = 'adminSettings',
  WebHook = 'webHook',
  PermanentFiters = 'permanentFiters',
}

function Admin(): JSX.Element {
  const [value, setValue] = React.useState(TabNames.UserManagement)
  const { user } = useContext(AuthContext)
  const { roleId } = user

  const handleChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    newValue: TabNames
  ) => {
    setValue(newValue)
  }

  return (
    <Root className={classes.root}>
      <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, v) => handleChange(e, v)}
          aria-label="simple tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="User Management" {...tabProps(TabNames.UserManagement)} />

          {isAnewgoMember(roleId) ? (
            <Tab
              label="Anewgo Admin Settings"
              {...tabProps(TabNames.AnewgoAdminSettings)}
            />
          ) : null}
          {isAdminOrAnewgoMember(roleId) && (
            <Tab label="WebHook Subscription" {...tabProps(TabNames.WebHook)} />
          )}
          <Tab
            label="Permanent filters"
            {...tabProps(TabNames.PermanentFiters)}
          />
          {<Tab label="Admin Settings" {...tabProps(TabNames.AdminSettings)} />}
        </Tabs>
      </Paper>
      <div
        role="tabpanel"
        hidden={value !== TabNames.UserManagement}
        id={`simple-tab-${TabNames.UserManagement}`}
        aria-labelledby={`simple-tab-${TabNames.UserManagement}`}
      >
        {value === TabNames.UserManagement && (
          <Box sx={{ p: 3 }} component="div">
            <UserManagement />
          </Box>
        )}
      </div>
      <div
        role="tabpanel"
        hidden={value !== TabNames.AnewgoAdminSettings}
        id={`simple-tab-${TabNames.AnewgoAdminSettings}`}
        aria-labelledby={`simple-tab-${TabNames.AnewgoAdminSettings}`}
      >
        {value === TabNames.AnewgoAdminSettings && (
          <Box sx={{ p: 3 }} component="div">
            <AnewgoAdminSettings />
          </Box>
        )}
      </div>
      <div
        role="tabpanel"
        hidden={value !== TabNames.AdminSettings}
        id={`simple-tab-${TabNames.AdminSettings}`}
        aria-labelledby={`simple-tab-${TabNames.AdminSettings}`}
      >
        {value === TabNames.AdminSettings && (
          <Box sx={{ p: 3 }} component="div">
            <AdminSettings />
          </Box>
        )}
      </div>
      <div
        role="tabpanel"
        hidden={value !== TabNames.WebHook}
        id={`simple-tab-${TabNames.WebHook}`}
        aria-labelledby={`simple-tab-${TabNames.WebHook}`}
      >
        {value === TabNames.WebHook && (
          <Box sx={{ p: 3 }} component="div">
            <WebhookSubscription />
          </Box>
        )}
      </div>
      <div
        role="tabpanel"
        hidden={value !== TabNames.PermanentFiters}
        id={`simple-tab-${TabNames.PermanentFiters}`}
        aria-labelledby={`simple-tab-${TabNames.PermanentFiters}`}
      >
        {value === TabNames.PermanentFiters && (
          <Box sx={{ p: 3 }} component="div">
            <PermanentFilters />
          </Box>
        )}
      </div>
    </Root>
  )
}

export default Admin
