import React, { useEffect } from 'react'
import { useIntersect } from 'utils/hooks'

interface BoundaryObserverProps {
  done: boolean
  loading: boolean
  root: HTMLElement | null
  rootMargin?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loadMore(...args: any): any
  height?: number
}

const BOUNDARY_OBSERVER_HEIGHT = 80

function BoundaryObserver({
  done,
  loading,
  root,
  rootMargin = '0px 0px 0px 0px',
  loadMore,
  height,
}: BoundaryObserverProps): JSX.Element {
  const width = 'inherit'
  const targetHeight = height || BOUNDARY_OBSERVER_HEIGHT
  const [ref, entry] = useIntersect({ root, rootMargin, threshold: 0 })
  const isIntersecting = entry ? entry.isIntersecting : false

  function __loadMore(): void {
    if (isIntersecting && !loading && !done) {
      loadMore()
    }
  }

  useEffect(() => {
    __loadMore()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntersecting])

  return (
    <div
      ref={ref}
      style={{ width, height: targetHeight, backgroundColor: 'transparent' }}
    />
  )
}

export default BoundaryObserver
