import Card from '@mui/material/Card'
import { styled } from '@mui/material/styles'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import React from 'react'
import { useState } from 'react'
import { animated, useSpring } from 'react-spring'

const PREFIX = 'FlipCard'

const defaultClasses = {
  root: `${PREFIX}-root`,
  animationWrapper: `${PREFIX}-animationWrapper`,
  card: `${PREFIX}-card`,
  cardContent: `${PREFIX}-cardContent`,
  flipping: `${PREFIX}-flipping`,
  shared: `${PREFIX}-shared`,
  cardActions: `${PREFIX}-cardActions`,
  media: `${PREFIX}-media`,
}

const Root = styled('div')<{
  favoritecardstyles?: boolean
  planelevationcardstyles?: boolean
}>(({ theme, favoritecardstyles, planelevationcardstyles }) => ({
  [`&.${defaultClasses.root}`]: {
    position: 'relative',
    ...(favoritecardstyles && {
      height: '428px',
      width: '320px',
    }),
    ...(planelevationcardstyles && {
      height: '360px',
      width: '240px',
    }),
  },

  [`& .${defaultClasses.animationWrapper}`]: {
    position: 'absolute',
  },

  [`& .${defaultClasses.card}`]: {
    display: 'flex',
    flexDirection: 'column',
    ...(favoritecardstyles && {
      height: '428px',
      width: '320px',
    }),
    ...(planelevationcardstyles && {
      height: '360px',
      width: '240px',
      overflowY: 'auto',
    }),
  },

  [`& .${defaultClasses.cardContent}`]: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
    paddingTop: '8px',
    paddingBottom: '8px',
    ...((favoritecardstyles || planelevationcardstyles) && {
      height: '166px',
    }),
  },

  [`& .${defaultClasses.flipping}`]: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
    cursor: 'pointer',
    ...((favoritecardstyles || planelevationcardstyles) && {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }),
  },

  [`& .${defaultClasses.shared}`]: {
    display: 'flex',
    flex: '1 0 auto',
    ...(favoritecardstyles && {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
    }),
    ...(planelevationcardstyles && {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(1),
    }),
  },

  [`& .${defaultClasses.cardActions}`]: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '0px',
    paddingBottom: '12px',
  },

  [`& .${defaultClasses.media}`]: {
    cursor: 'pointer',
    height: 200,
  },
}))

export interface FlipCardProps {
  frontSide: React.ReactNode
  backSide: React.ReactNode
  shared?: React.ReactNode
  actions?: React.ReactNode
  media?: {
    url: string
    title: string
    frontSideOnly: boolean
    altText?: string
  }
  favoriteCardStyles?: boolean | undefined
  planElevationCardStyles?: boolean | undefined
}

export const FlipCard: React.FC<FlipCardProps> = ({
  shared,
  frontSide,
  backSide,
  media,
  actions,
  favoriteCardStyles = false,
  planElevationCardStyles = false,
}) => {
  const styles = defaultClasses
  const [flipped, setFlipped] = useState<boolean>(false)

  const { transform, opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `perspective(1400px) rotateY(${flipped ? 180 : 0}deg)`,
    config: { mass: 6, tension: 500, friction: 80 },
  })

  function shouldRenderMedia(): boolean {
    if (media) {
      return !(flipped && media.frontSideOnly)
    }
    return false
  }

  function renderCard(): JSX.Element {
    return (
      <Card className={styles.card}>
        {media && shouldRenderMedia() && (
          <CardMedia
            onClick={(): void => setFlipped((state) => !state)}
            image={media.url}
            title={media.title}
            className={styles.media}
          />
        )}
        <CardContent className={styles.cardContent}>
          <div
            onClick={(): void => setFlipped((state) => !state)}
            className={styles.flipping}
          >
            {flipped ? backSide : frontSide}
          </div>
          {shared && <div className={styles.shared}>{shared}</div>}
        </CardContent>
        {actions && (
          <CardActions className={styles.cardActions}>{actions}</CardActions>
        )}
      </Card>
    )
  }

  return (
    <Root
      className={styles.root}
      favoritecardstyles={favoriteCardStyles}
      planelevationcardstyles={planElevationCardStyles}
    >
      <animated.div
        className={styles.animationWrapper}
        style={{
          opacity: opacity.interpolate((o) => 1 - (o as number)),
          transform,
        }}
      >
        {renderCard()}
      </animated.div>
      <animated.div
        className={styles.animationWrapper}
        style={{
          opacity,
          transform: transform.interpolate((t) => `${t} rotateY(180deg)`),
        }}
      >
        {renderCard()}
      </animated.div>
    </Root>
  )
}
