import React, { useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from 'auth/AuthContext'
import TokenExpiredModal from 'auth/TokenExpiredModal'
import ReactDOM from 'react-dom'
import { getCookies } from 'utils/functions'

const TokenExpireWrapper = (props: { children: JSX.Element[] }) => {
  const [createdTokenChecker, setCreatedTokenChecker] = useState(false)
  const { userId } = useContext(AuthContext).user
  const userIdRef = useRef(userId)

  useEffect(() => {
    userIdRef.current = userId
  }, [userId])

  function verifyToken() {
    setTimeout(() => {
      const { token_expire } = getCookies()
      if (token_expire && userIdRef.current) {
        const expireDate = parseInt(token_expire)
        const currentDate = new Date().getTime()
        if (currentDate > expireDate) {
          const container = document.createElement('div')
          ReactDOM.render(<TokenExpiredModal />, container)
        }
      }
      verifyToken()
    }, 5000)
  }

  if (!createdTokenChecker) {
    setCreatedTokenChecker(true)
    verifyToken()
  }

  return <>{props.children}</>
}

export default TokenExpireWrapper
