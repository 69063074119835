import React from 'react'
import { ProspectCtaEvent } from 'graphql/gen-types'

import DateFnsAdapter from '@date-io/date-fns'
import { Maybe } from 'graphql/jsutils/Maybe'

import ResponsiveTable, {
  ResponsiveCell,
} from 'components/common/tables/ResponsiveTable'
import ComponentCard from 'components/common/layout/ComponentCard'

const COMPONENT_TITLE = 'Shared Plans'
const dateFns = new DateFnsAdapter()

function formatDateTime(
  date: string | number | Date | null | undefined
): string {
  return dateFns.format(new Date(date || new Date()), 'hh:mm MMM do yyyy')
}

export default function CtaEventsTable({
  data,
  loading,
  error,
}: {
  data: Maybe<ProspectCtaEvent>[] | undefined | null
  loading: boolean | undefined
  error: Error | undefined
}): JSX.Element {
  const columns = [
    <ResponsiveCell responseSize="md" align="center" key={1}>
      <strong>Community</strong>
    </ResponsiveCell>,
    <ResponsiveCell responseSize="md" align="center" key={2}>
      <strong>Plan</strong>
    </ResponsiveCell>,
    <ResponsiveCell responseSize="md" align="center" key={3}>
      <strong>Elevation</strong>
    </ResponsiveCell>,

    <ResponsiveCell responseSize="md" align="center" key={5}>
      <strong>Shared To</strong>
    </ResponsiveCell>,

    <ResponsiveCell responseSize="md" align="right" key={6}>
      <strong>Timestamp</strong>
    </ResponsiveCell>,
  ]

  const generateRow = (
    dataRow: ProspectCtaEvent,
    index?: number
  ): JSX.Element => {
    return (
      <>
        <ResponsiveCell
          responseSize="md"
          align="center"
          key={`${dataRow?.planName}-${dataRow?.planId}-${index}-1`}
        >
          {dataRow?.communityName}
        </ResponsiveCell>
        <ResponsiveCell
          responseSize="md"
          align="center"
          key={`${dataRow?.planName}-${dataRow?.planId}-${index}-2`}
        >
          {dataRow?.planName}
        </ResponsiveCell>
        <ResponsiveCell
          responseSize="md"
          align="center"
          key={`${dataRow?.planName}-${dataRow?.planId}-${index}-3`}
        >
          {dataRow?.elevationCaption}
        </ResponsiveCell>

        <ResponsiveCell
          responseSize="md"
          align="center"
          key={`${dataRow?.planName}-${dataRow?.planId}-${index}-5`}
        >
          {dataRow?.action || 0}
        </ResponsiveCell>

        <ResponsiveCell
          responseSize="md"
          align="right"
          key={`${dataRow?.planName}-${dataRow?.planId}-${index}-6`}
        >
          {formatDateTime(dataRow?.timestamp)}
        </ResponsiveCell>
      </>
    )
  }

  // Only include events which action is a sharing event.
  // The CtaEvents also includes events with actions of
  //    - page_cta_button, from create brochure events
  //    - vertical_stepper, not sure.
  //    - print, printing is creating brochure, not sharing.
  //    - favorites page, Adding to favorites I believe

  const shareActions = ['tweet', 'facebook', 'email', 'contact']
  const shareEvents =
    data?.filter((event) => shareActions.includes(event?.action || '')) || []

  const filteredShareEvents = []

  // This part of code is addressing COL-1153, due to the change on MyHomeApp
  // where we require sign in before lead can share plans but we were tracking
  // sharing even when user was not signed in. MyHomeApp was fixed with COL-1153
  // but old data may still be corrupted which we address with this code to filter
  // these incorrect events, this code can be removed after some time when this old
  // lead data wont be that relevant.
  for (let i = 0; i < shareEvents.length; i++) {
    let duplicity = false

    for (let y = i + 1; y < shareEvents.length; y++) {
      const comparedElement = shareEvents[i]
      const currentElement = shareEvents[y]

      // Check plan and elevation is exactly same
      if (
        currentElement?.planId === comparedElement?.planId &&
        currentElement?.elevationId === comparedElement?.elevationId
      ) {
        // Check if timestamp is in same day
        if (
          dateFns.format(new Date(currentElement?.timestamp), 'MMM do yyyy') ===
          dateFns.format(new Date(comparedElement?.timestamp), 'MMM do yyyy')
        ) {
          duplicity = true
          break
        }
      }
    }

    if (!duplicity) {
      filteredShareEvents.push(shareEvents[i])
    }
  }

  return (
    <ComponentCard
      title={COMPONENT_TITLE}
      result={{ data: shareEvents, loading, error }}
    >
      <ResponsiveTable
        data={filteredShareEvents as ProspectCtaEvent[]}
        columns={columns}
        generateRow={generateRow}
        dense={true}
      />
    </ComponentCard>
  )
}
