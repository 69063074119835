import React from 'react'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import { PopularMaterialColors } from 'graphql/gen-types'
import { getImagePrefix, texturesPrefix } from '@anewgo/utils'
import ResponsiveTable from 'components/common/tables/ResponsiveTable'
import ResponsiveCell from './ResponsiveTable/ResponsiveCell'
import Grid from '@mui/material/Grid'

const PREFIX = 'MaterialColorTable'

const classes = {
  avatar: `${PREFIX}-avatar`,
}

const StyledAvatar = styled(Avatar)(() => ({
  [`&.${classes.avatar}`]: {
    border: '1px solid lightgray',
  },
}))

const NUM_DISPLAY_ROWS = 10
const RESPONSE_SIZE = 'md'
const ROW_HEIGHT = 73

type MaterialColorTableProps = {
  materialColors: PopularMaterialColors[]
  title?: string
}

type DisplayColor = {
  id: number
  name: string
  hex: string
  swatch: string
}

export function MaterialColorTable({
  materialColors,
  title,
}: MaterialColorTableProps): JSX.Element {
  const showPopularity = materialColors[0]?.popularity !== undefined
  const tableHeadCells: (JSX.Element | null)[] = [
    <ResponsiveCell
      align="center"
      key="header-cell-color"
      responseSize={RESPONSE_SIZE}
      rowHeight={ROW_HEIGHT}
    >
      Color
    </ResponsiveCell>,
    <ResponsiveCell
      align="center"
      key="header-cell-name"
      responseSize={RESPONSE_SIZE}
      rowHeight={ROW_HEIGHT}
    >
      Name
    </ResponsiveCell>,
    showPopularity ? (
      <ResponsiveCell
        align="center"
        key="header-cell-popularity"
        responseSize={RESPONSE_SIZE}
        rowHeight={ROW_HEIGHT}
      >
        Popularity
      </ResponsiveCell>
    ) : null,
  ]

  return (
    <div style={{ padding: '5px' }}>
      {title && (
        <Typography
          variant="h5"
          color="primary"
          style={{ paddingBottom: '10px' }}
        >
          {title}
        </Typography>
      )}
      <ResponsiveTable
        data={materialColors}
        columns={tableHeadCells}
        generateRow={(dataRow, index): JSX.Element => (
          <ResponsiveTableRow row={dataRow} index={index} />
        )}
        responseSize={RESPONSE_SIZE}
        rowHeight={ROW_HEIGHT}
        initialRows={NUM_DISPLAY_ROWS}
      />
    </div>
  )
}

function ResponsiveTableRow({
  row,
  index,
}: {
  row: PopularMaterialColors
  index?: number
}): JSX.Element {
  const displayColor = getDisplayColor(row)

  const showPopularity = row?.popularity !== undefined
  return (
    // Only display active color selections
    displayColor && (
      <>
        <ResponsiveCell
          align="center"
          responseSize={RESPONSE_SIZE}
          rowHeight={ROW_HEIGHT}
        >
          <Grid container justifyContent="center">
            {/* If display color has a hex, show the color */}
            {displayColor?.hex !== '' ? (
              <StyledAvatar
                style={{ backgroundColor: `#${displayColor?.hex}` }}
                className={classes.avatar}
                alt={`${displayColor.name?.charAt(0)}`}
              >
                {' '}
              </StyledAvatar>
            ) : (
              // If swatch is defined, display image
              <StyledAvatar
                src={displayColor.swatch}
                alt={`${displayColor.name?.charAt(0)}`} // Show the first character of the color name if swatch image doesn't load.
                className={classes.avatar}
              >
                {' '}
              </StyledAvatar>
            )}
          </Grid>
        </ResponsiveCell>
        <ResponsiveCell
          align="center"
          component="th"
          scope="row"
          responseSize={RESPONSE_SIZE}
          rowHeight={ROW_HEIGHT}
        >
          <Typography>{row.colorName}</Typography>
        </ResponsiveCell>
        {showPopularity ? (
          <ResponsiveCell
            align="center"
            responseSize={RESPONSE_SIZE}
            rowHeight={ROW_HEIGHT}
          >
            <Typography>{row.popularity}</Typography>
          </ResponsiveCell>
        ) : null}
      </>
    )
  )
}

function getDisplayColor(data: PopularMaterialColors): DisplayColor {
  // Get id and name
  const id =
    data.colorId || data.brickId || data.stoneId || data.customOverlayId || 0
  const name = data.colorName || ''
  const hex = data.color?.hex || ''
  let swatch = ''
  // If color is a brick that has defined texture and src, use it
  if (data.texture?.src) {
    swatch = `${texturesPrefix}/${data.texture.src}`

    // If color is a customOverlay with a src, use it
  } else if (data.customOverlay?.src) {
    // Get the swatch from customOverlay
    swatch = `${getImagePrefix(data.clientName)}/${data.customOverlay.src}`
  }

  return {
    id,
    name,
    hex,
    swatch,
  }
}

export default MaterialColorTable
