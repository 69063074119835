import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

import useMediaQuery from '@mui/material/useMediaQuery'
import Tooltip from '@mui/material/Tooltip'
import StarIcon from '@mui/icons-material/Star'

import {
  LeadRanking,
  Prospect,
  Agent,
  Pagination,
  OrderBy,
  Order,
} from 'graphql/gen-types'

import LeadDetailModal from './LeadDetailModal'

import ResponsiveTable, {
  ResponsiveCell,
} from 'components/common/tables/ResponsiveTable'
import ProspectStatusSelect from 'components/common/custom-inputs/ProspectStatusSelect'
import { AuthContext } from 'auth/AuthContext'
import {
  BRANDING_COLOR_PASTEL_YELLOW,
  BRANDING_COLOR_DARK_MODE_YELLOW,
  LEAD_SCORE_OUT_OF,
} from 'constants/index'
import { AppStore } from 'store'
import { formatDateTime, getNamesFromFullName } from 'utils/functions'
import Badge from '@mui/material/Badge'
import Typography from '@mui/material/Typography'
import {
  TableSortLabelStyled,
  classes,
  VisuallyHidden,
} from './LeadRankings.style'
import TableFooter from '@mui/material/TableFooter'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import { isValidLead } from './LeadRankings'

const DATE_FORMAT = 'MM-dd-yyyy'

const StyledBadge = styled(Badge)({
  right: -8,
  top: -2,
})

interface HeadCell {
  id: LeadKeys
  value: string | JSX.Element
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined
  lineHeight?: string
}

type ProspectModel = Pick<
  Prospect,
  | 'email'
  | 'phone'
  | 'registrationDate'
  | 'firstName'
  | 'lastName'
  | 'preferredContactMethods'
  | 'status'
  | 'clientRating'
>

export type LeadKeys = keyof LeadRanking | keyof ProspectModel

type LeadTableProps = {
  data: LeadRanking[]
  agents?: Agent[]
  showcaseRecentlyActive?: boolean
  setCsv?: React.Dispatch<React.SetStateAction<string>>
  highlightLeads: boolean
  pagination: Pagination
  setPagination: Dispatch<SetStateAction<Pagination>>
  orderBy: OrderBy
  setOrderBy: Dispatch<SetStateAction<OrderBy>>
  dataTotalCount: number
}

const MAX_TOOLTIP_ARRAY_SIZE = 10
const TOOLTOP_EXTEND_TEXT = ', and more . . .'
/**
 * This table contains sorting, pagination, and is responsive.
 * TODO: There is a lot of sorting done here. We may want to add sorting functionality to the ResponsiveTable component.
 * @param param - props data
 */
export default function LeadRankingTable({
  data,
  agents = [],
  showcaseRecentlyActive = false,
  setCsv,
  highlightLeads,
  pagination,
  setPagination,
  orderBy,
  setOrderBy,
  dataTotalCount,
}: LeadTableProps): JSX.Element {
  const [email, setEmail] = useState('')
  const [leadDetailModalOpen, setLeadDetailModalOpen] = useState(false)
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.down('lg'))
  const { user } = useContext(AuthContext)
  const { roleId } = user
  const { appState } = useContext(AppStore)

  const navigate = useNavigate()

  const handleSortClick = (property: LeadKeys): void => {
    const isAsc = orderBy.sortBy === property && orderBy.order === Order.Asc

    setOrderBy({
      order: isAsc ? Order.Desc : Order.Asc,
      sortBy: property,
    })
  }
  const handleLeadClick = (email: string): void => {
    setEmail(email)
    setLeadDetailModalOpen(true)
  }

  const handleLeadDetailModalClose = (): void => {
    setLeadDetailModalOpen(false)
    setEmail('')
  }
  const handleLeadDetailModalSubmit = (): void => {
    navigate(`../prospect?prospectId=${encodeURIComponent(email)}`)
  }

  let tableHeadings: HeadCell[] = [
    { id: 'name', value: 'Name', align: md ? 'center' : 'left' },
    { id: 'email', value: 'Email', align: md ? 'center' : 'left' },
  ]
  const commonHeadCells: HeadCell[] = [
    { id: 'status', value: 'Status', align: 'center', lineHeight: '39.5px' },

    { id: 'numCommunities', value: 'Communities', align: 'center' },
    { id: 'numPlans', value: 'Plans', align: 'center' },
    { id: 'appointments', value: 'Request Appts.', align: 'center' },
  ]
  const activityHeadCells: HeadCell[] = [
    { id: 'registrationDate', value: 'Registered', align: 'center' },
    { id: 'firstSeen', value: 'First Seen', align: 'center' },
    { id: 'lastSeen', value: 'Last Sign-In', align: 'center' },
    {
      id: 'signIns',
      value: (
        <>
          <span style={{ display: 'block' }}>Sign Ins</span>
          <span style={{ display: 'block' }}>(within time frame)</span>
        </>
      ),
      align: 'center',
    },
  ]
  const scoringHeadCells: HeadCell[] = [
    {
      id: 'score',
      value: (
        <Tooltip
          title={`How engaged a lead is. Higher is better. Out of ${LEAD_SCORE_OUT_OF}`}
          children={
            <>
              <span style={{ display: 'block' }}>Engagement</span>
              <span style={{ display: 'block' }}>Score</span>
            </>
          }
        />
      ),

      align: 'center',
    },
    { id: 'clientRating', value: 'Custom Rating', align: 'center' },
  ]
  if (showcaseRecentlyActive) {
    const newTableHeadings = tableHeadings.slice()
    newTableHeadings.unshift(...activityHeadCells)
    newTableHeadings.push(...scoringHeadCells, ...commonHeadCells)
    tableHeadings = newTableHeadings
  } else {
    const newTableHeadings = tableHeadings.slice()
    newTableHeadings.unshift(...scoringHeadCells)
    newTableHeadings.push(...commonHeadCells, ...activityHeadCells)
    tableHeadings = newTableHeadings
  }

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPagination((prev) => ({
      ...prev,
      offset: 0,
      limit: Number(event.target.value),
    }))
  }

  const handlePageChange = (event: unknown, newPage: number) => {
    setPagination((prev) => ({
      ...prev,
      offset: Number(newPage) * pagination.limit,
    }))
  }

  const tableHeaders = tableHeadings.map((head, index) => (
    <LeadTableHeadCell
      head={head}
      key={index}
      index={index}
      active={head.id === orderBy.sortBy}
      onClick={handleSortClick}
      order={orderBy.order}
    />
  ))

  useEffect(() => {
    const rows = data.map((row) => {
      const name = row.name
      const [firstName, lastName] = getNamesFromFullName(row.name)
      const communityNames = row.favoriteCommunities
        ? [...row.favoriteCommunities]
        : []
      return [
        name || '-',
        firstName || '-',
        lastName || '-',
        formatDateTime(row.registrationDate, DATE_FORMAT) || '-',
        row.phone || '-',
        row.email || '-',
        communityNames?.sort().join(', ') || '-',
      ]
    })

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      'Name,First name, Last name, Registration date, Phone, Email, Communities\n' +
      rows.map((row) => row.join(',')).join('\n')

    setCsv && setCsv(csvContent)
  }, [setCsv])

  return (
    <>
      <LeadDetailModal
        data={data.find((prospect) => prospect.user === email)}
        open={leadDetailModalOpen}
        onClose={handleLeadDetailModalClose}
        onSubmit={handleLeadDetailModalSubmit}
        agents={agents}
      />
      <ResponsiveTable
        data={data}
        columns={tableHeaders}
        generateRow={(lead: LeadRanking, index?: number): JSX.Element => (
          <LeadTableRow
            lead={lead}
            roleId={roleId}
            index={index}
            isValidLead={(lead: LeadRanking) => isValidLead(lead)}
            showcaseRecentlyActive={showcaseRecentlyActive}
          />
        )}
        responseSize={'md'}
        onRowClick={(row: unknown): void => {
          const lead = row as LeadRanking
          if (isValidLead(lead)) {
            handleLeadClick((row as LeadRanking)?.user)
          }
        }}
        dense={false}
        applyRowStyling={(row: unknown): boolean => {
          const lead = row as LeadRanking
          const highlightStatuses = [
            'NEW',
            'OPEN',
            'ATTEMPTED_TO_CONTACT',
            'BAD_TIMING',
          ]
          const highlightRow =
            highlightLeads &&
            lead.hotLead &&
            highlightStatuses.includes(lead?.status || '')
          return highlightRow || false
        }}
        rowStyle={{
          backgroundColor: appState.darkMode
            ? BRANDING_COLOR_DARK_MODE_YELLOW
            : BRANDING_COLOR_PASTEL_YELLOW,
          fontWeight: 'bold',
        }}
        disableRow={(row: unknown): boolean => {
          const lead = row as LeadRanking
          return !isValidLead(lead)
        }}
        customPagination={
          <TableFooter>
            <TableRow>
              <TablePagination
                count={dataTotalCount}
                page={pagination.offset / pagination.limit}
                rowsPerPage={pagination.limit}
                rowsPerPageOptions={[5, 10, 25]}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </TableRow>
          </TableFooter>
        }
      />
    </>
  )
}

function LeadTableHeadCell({
  head,
  index,
  active,
  onClick,
  order,
}: {
  head: HeadCell
  index: number
  active: boolean
  onClick: (id: LeadKeys) => void
  order: Order
}): JSX.Element {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <ResponsiveCell
      responseSize="md"
      align={head.align}
      id={head.id}
      key={index}
    >
      <TableSortLabelStyled
        classes={{
          root: classes.cellLabel,
          active: classes.tableSortLabelActive,
          icon: classes.tableSortIcon,
        }}
        active={active}
        direction={active ? (order === Order.Asc ? 'asc' : 'desc') : 'asc'}
        onClick={(): void => onClick(head.id)}
        style={head.lineHeight ? { height: 'auto' } : undefined}
      >
        <span
          style={
            md && head.lineHeight ? { lineHeight: head.lineHeight } : undefined
          }
        >
          {head.value}
        </span>

        {active ? (
          <VisuallyHidden>
            {order === Order.Desc ? 'sorted descending' : 'sorted ascending'}
          </VisuallyHidden>
        ) : null}
      </TableSortLabelStyled>
    </ResponsiveCell>
  )
}

function LeadTableRow({
  lead,
  roleId,
  index,
  isValidLead,
  showcaseRecentlyActive,
}: {
  lead: LeadRanking
  roleId: number
  index?: number
  isValidLead: (lead: LeadRanking) => boolean
  showcaseRecentlyActive?: boolean
}): JSX.Element {
  const name = lead?.name
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.down('lg'))
  const displayLeadScoring: JSX.Element = (
    <>
      <ResponsiveCell
        responseSize="md"
        align="center"
        style={{ justifyContent: 'center' }}
      >
        <StyledBadge
          badgeContent={
            lead.contactsUs ? (
              <Tooltip
                title={
                  <Typography variant="subtitle2">
                    This lead has sent an email to you
                  </Typography>
                }
              >
                <StarIcon style={{ color: 'orange' }} fontSize="small" />
              </Tooltip>
            ) : null
          }
        >
          <span>{Math.round((lead.score || 0) * LEAD_SCORE_OUT_OF)}</span>
        </StyledBadge>
      </ResponsiveCell>
      <ResponsiveCell responseSize="md" align="center">
        {lead.clientRating || '-'}
      </ResponsiveCell>
    </>
  )
  const displayLeadActivity: JSX.Element = (
    <>
      <ResponsiveCell responseSize="md" align="center">
        {formatDateTime(lead?.registrationDate, DATE_FORMAT)}
      </ResponsiveCell>
      <ResponsiveCell responseSize="md" align="center">
        {formatDateTime(lead.firstSeen, DATE_FORMAT)}
      </ResponsiveCell>
      <ResponsiveCell responseSize="md" align="center">
        {lead.lastSeen // if last seen and first seen both are null then we take prospect registration date from prospect
          ? formatDateTime(lead.lastSeen, DATE_FORMAT)
          : lead.firstSeen
          ? formatDateTime(lead.firstSeen, DATE_FORMAT)
          : formatDateTime(lead?.registrationDate, DATE_FORMAT)}
      </ResponsiveCell>
      <ResponsiveCell responseSize="md" align="center">
        {lead.signIns || 0}
      </ResponsiveCell>
    </>
  )
  const displayLeadIdentity: JSX.Element = (
    <>
      <ResponsiveCell responseSize="md" align={md ? 'center' : 'left'}>
        {name}
      </ResponsiveCell>
      <ResponsiveCell responseSize="md" align={md ? 'center' : 'left'}>
        {lead.user}
      </ResponsiveCell>
    </>
  )
  const displayCommonCells: JSX.Element = (
    <>
      <ResponsiveCell responseSize="md" align="center">
        <ProspectStatusSelect
          disabled={roleId > 4 || !isValidLead(lead)}
          prospectId={lead?.prospectId}
          prospectStatus={lead?.status}
          prospectEmail={lead?.user}
          hideLabel
        />
      </ResponsiveCell>
      <ResponsiveCell responseSize="md" align="center">
        <Tooltip
          title={`${lead?.communities
            ?.slice(0, MAX_TOOLTIP_ARRAY_SIZE)
            ?.map((c) => c?.communityName)
            ?.join(', ')} ${
            (lead?.communities?.length || 0) > MAX_TOOLTIP_ARRAY_SIZE
              ? TOOLTOP_EXTEND_TEXT
              : ''
          }`}
        >
          <span>{`${lead.communities?.length || 0}`}</span>
        </Tooltip>
      </ResponsiveCell>
      <ResponsiveCell responseSize="md" align="center">
        <Tooltip
          title={`${lead?.plans
            ?.slice(0, MAX_TOOLTIP_ARRAY_SIZE)
            ?.map((p) => `${p?.planName} ${p?.elevationCaption}`)
            ?.join(', ')} ${
            (lead?.plans?.length || 0) > MAX_TOOLTIP_ARRAY_SIZE
              ? TOOLTOP_EXTEND_TEXT
              : ''
          }`}
        >
          <span>{`${lead?.plans?.length || 0}`}</span>
        </Tooltip>
      </ResponsiveCell>
      <ResponsiveCell responseSize="md" align="center">
        {lead.appointments || 0}
      </ResponsiveCell>
    </>
  )
  if (showcaseRecentlyActive) {
    return (
      <>
        {displayLeadActivity}
        {displayLeadIdentity}
        {displayLeadScoring}
        {displayCommonCells}
      </>
    )
  }
  return (
    <>
      {displayLeadScoring}
      {displayLeadIdentity}
      {displayCommonCells}
      {displayLeadActivity}
    </>
  )
}
