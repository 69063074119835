import React from 'react'
import LotSVG from './LotSVG'
import { Lot, Siteplan } from '../../../graphql/gen-types'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { UncontrolledReactSVGPanZoom } from 'react-svg-pan-zoom'

interface SVGSitePlanProps {
  siteplan: Siteplan
  lots: Lot[]
  image: HTMLImageElement
  width: number
  height: number
  minPopularity: number
  maxPopularity: number
  loading: boolean
  scale?: number
  colorMode?: boolean
  heatMapMode?: boolean
  highlightMode?: boolean
  borderColor?: string
  borderWidth?: number
  hideText?: boolean
  grayScale?: number
  onLotClick?: (lot: Lot) => void
  enableZoom?: boolean
}

export default function SVGSitePlan({
  siteplan,
  lots,
  image,
  width,
  height,
  loading,
  minPopularity,
  maxPopularity,
  scale = 1,
  colorMode = false,
  heatMapMode = false,
  highlightMode = false,
  borderColor = '',
  borderWidth = 2,
  hideText = false,
  grayScale = 0.8,
  onLotClick,
  enableZoom = false,
}: SVGSitePlanProps): JSX.Element {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('md'))
  const isMasterSitePlan = siteplan.master

  const renderLots = () => {
    const renderLot = (lot: Lot, i: number) => {
      return (
        <LotSVG
          key={i}
          siteplan={siteplan}
          lot={lot}
          colorMode={colorMode}
          heatMapMode={heatMapMode}
          highlightMode={highlightMode}
          borderColor={borderColor}
          borderWidth={borderWidth}
          hideText={hideText}
          maxPopularity={maxPopularity}
          minPopularity={minPopularity}
          onLotClick={onLotClick}
        />
      )
    }
    // For a master, render multiple svgs
    return (
      <>
        {!isMasterSitePlan &&
          lots.map((lot: Lot, i: number) => renderLot(lot, i))}
      </>
    )
  }

  const content = (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      style={{
        width: sm ? '100%' : width,
        height: sm ? '100%' : height,
        backgroundColor: 'white',
        border: sm ? '' : '1px solid #cdcdcd',
        borderRadius: '10px',
        marginBottom: '15px',
      }}
    >
      <g transform={`scale(${scale}) `}>
        <filter id={'siteplan-filter'}>
          <feColorMatrix type="saturate" values={grayScale.toString()} />
        </filter>
        {loading ? (
          <g>
            <text textAnchor="middle" fill="blue">
              Loading image...
            </text>
          </g>
        ) : (
          <image
            filter={'url(#siteplan-filter)'}
            xlinkHref={image?.src}
            width={image?.width}
            height={image?.height}
          />
        )}
        {!loading && renderLots()}
      </g>
    </svg>
  )

  if (enableZoom) {
    return (
      <UncontrolledReactSVGPanZoom
        width={width}
        height={height}
        miniatureProps={{
          position: 'none',
          background: '',
          width: 100,
          height: 80,
        }}
        detectAutoPan={false}
      >
        {content}
      </UncontrolledReactSVGPanZoom>
    )
  }

  return content
}
