import {
  PopularityMetric,
  TimeSeries,
  TimeSeriesFrame,
} from 'graphql/gen-types'

/**
 * Generates array of Dates for given interval
 *
 */
export function generateDateInterval(start: Date, end: Date): Date[] {
  const dates = []
  for (
    let currentDate = new Date(start);
    currentDate <= end;
    currentDate.setDate(currentDate.getDate() + 1)
  ) {
    dates.push(
      new Date(
        Date.UTC(
          currentDate.getUTCFullYear(),
          currentDate.getUTCMonth(),
          currentDate.getUTCDate()
        )
      )
    )
  }
  return dates
}

/**
 * This function is not much but it's' honest work
 *
 * Add missing time-series frames.
 * Adding missing frames improves chart readability
 */
export function addZeroCountDates(
  timeSeries: TimeSeries | undefined | null,
  metric: PopularityMetric | undefined | null,
  start: Date,
  end: Date
): TimeSeries {
  const dateArray = generateDateInterval(start, end)
  const timeSeriesWithAllDates: TimeSeries = { frames: [] }
  for (const date of dateArray) {
    // Try to find Time Series frame for date
    const originalTimesSeriesFrame:
      | TimeSeriesFrame
      | undefined = timeSeries?.frames?.find(
      (frame) => new Date(frame.date).getTime() === date.getTime()
    )

    // If frame exists for date, add it to frames
    if (originalTimesSeriesFrame) {
      timeSeriesWithAllDates.frames?.push(originalTimesSeriesFrame)
    } else {
      // If frame doesn't exist for date, add empty frame.
      if (
        metric === PopularityMetric.Frequency ||
        metric === PopularityMetric.Popularity
      ) {
        timeSeriesWithAllDates.frames?.push({
          __typename: 'EventBasedTimeSeriesFrame',
          date: date.getTime(),
          value: 0,
        })
      } else if (metric === PopularityMetric.Duration) {
        timeSeriesWithAllDates.frames?.push({
          date: date.getTime(),
          durationSum: 0,
          durationAvg: 0,
          durationMin: 0,
          durationMax: 0,
          durationMedian: 0,
          eventsCount: 0,
          sessionsCount: 0,
        })
      }
    }
  }

  timeSeriesWithAllDates.frames = timeSeriesWithAllDates.frames?.sort(
    (a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime()
    }
  )
  return timeSeriesWithAllDates
}
