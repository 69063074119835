import {
  useEffect,
  useState,
  useContext,
  useLayoutEffect,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import { AuthContext } from 'auth/AuthContext'

export const useIsAuthenticated = (): boolean => {
  const { authenticated } = useContext(AuthContext)
  const [localAuth, setLocalAuth] = useState(authenticated)
  useEffect(() => {
    setLocalAuth(authenticated)
  }, [authenticated])
  return localAuth
}

async function __checkAuthorization(
  setAuthorized: {
    (value: SetStateAction<boolean>): void
    (arg0: boolean): void
  },
  setEvaluating: {
    (value: SetStateAction<boolean>): void
    (arg0: boolean): void
  },
  userId: number,
  action: string,
  actionData?: Record<string, unknown>
): Promise<void> {
  // TBD
  setEvaluating(false)
  setAuthorized(true)
}

/**
 *
 * @param action - this is the action/rule that will be evaluated txo see
 * if the current user is authorized to access. The action can be any UI.
 *
 * @param actionData - this is dependent on the type of action that the caller
 * is seeking authorization to.
 *
 * @returns an array with two booleans. The first boolean determines if a user
 * has access. The second boolean informs the caller of this hook whether this
 * hook has completed its determination of a user's access control.
 */
export const useIsAuthorized = (
  action: string,
  actionData?: Record<string, unknown>
): [boolean, boolean] => {
  const [authorized, setAuthorized] = useState(false)
  const [evaluating, setEvaluating] = useState(false)
  const { authenticated, user } = useContext(AuthContext)
  useEffect(() => {
    if (authenticated) {
      const { userId } = user
      __checkAuthorization(
        setAuthorized,
        setEvaluating,
        userId,
        action,
        actionData
      )
    }
  }, [action, actionData, authenticated, user])
  return [authorized, evaluating]
}

interface UseIntersectProps {
  root: HTMLElement | null
  rootMargin: string
  threshold: number
}

// we need to add comment here to the code
export const useIntersect = ({
  root = null,
  rootMargin,
  threshold = 0,
}: UseIntersectProps): [
  Dispatch<SetStateAction<Element | null>>,
  IntersectionObserverEntry | null
] => {
  const [entry, updateEntry] = useState<IntersectionObserverEntry | null>(null)
  const [node, setNode] = useState<Element | null>(null)
  const observer = useRef<IntersectionObserver | null>(null)

  useEffect(() => {
    if (observer.current !== null) {
      observer.current.disconnect()
    }
    observer.current = new window.IntersectionObserver(
      ([entry]) => updateEntry(entry),
      {
        root,
        rootMargin,
        threshold,
      }
    )
    const { current: currentObserver } = observer
    if (node) {
      currentObserver.observe(node)
    }
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    return () => currentObserver.disconnect()
  }, [node, root, rootMargin, threshold])
  return [setNode, entry]
}

export const useResizeObserver = (
  ref: React.MutableRefObject<Element | null>
): [number, number] => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useLayoutEffect(() => {
    const { current } = ref
    const resizeObserver = new ResizeObserver(
      (entries: { contentRect: { width: number; height: number } }[]) => {
        const { width, height } = entries[0].contentRect
        setWidth(width)
        setHeight(height)
      }
    )
    if (current !== null) {
      resizeObserver.observe(current)
    }
    return (): void => {
      resizeObserver.disconnect()
    }
  }, [ref])

  return [width, height]
}
