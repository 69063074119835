import hydrationStore from './HydrationStore'
import { ClientIdAndName, LeadFilter } from '../graphql/gen-types'
import { ProspectModel } from 'components/leads/LeadsTable'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

// common enum space for all stores
export enum AppActionType {
  //
  // G L O B A L
  //
  SET_SELECTED_CLIENT,
  SET_SELECTED_APP,
  SET_APOLLO_CLIENT,
  SET_DARK_MODE,

  //
  // A N A L Y T I C S
  //
  SET_DATE_RANGE,
  SET_SELECTED_TABS,

  //
  // L E A D S
  //
  SET_SELECTED_PROSPECT,
  SET_NEW_LEADS_FILTER,
  SET_RETURNING_LEADS_FILTER,
  SET_ACTIVE_LEADS_FILTER,
}

// shared interface across all the stores
export interface AppAction {
  type: AppActionType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any
}

// Global App State
export interface AppState {
  darkMode: boolean
  appHeight: number
  selectedClient: ClientIdAndName | null
  selectedApp: string | null
  apolloClient: ApolloClient<NormalizedCacheObject>
}

// Analytics App State
export interface AnalyticsState {
  startDate: Date
  endDate: Date
  selectedTabs: Array<string>
}

export enum leadsFilterEnum {
  None = 'None',
  Unassigned = 'Unassigned',
  MyLeadsOnly = 'MyLeadsOnly',
}

export interface LeadsState {
  selectedProspect: ProspectModel | null
  startDate: Date
  endDate: Date
  defaultLeadFilters: {
    newLeadsFilter: LeadFilter | null
    returningLeadsFilter: LeadFilter | null
    activeLeadsFilter: LeadFilter | null
  }
}

////////////////////////////////////////////////////////////////////////////////
//
//
// G L O B A L
//
//
////////////////////////////////////////////////////////////////////////////////

function __setSelectedClient(
  state: AppState,
  client: ClientIdAndName
): AppState {
  // persist selected client too
  hydrationStore.selectedClient = client
  return {
    ...state,
    selectedClient: client,
  }
}

function __setSelectedApp(state: AppState, targetApp: string): AppState {
  // persist selected app state too
  hydrationStore.selectedApp = targetApp
  return {
    ...state,
    selectedApp: targetApp,
  }
}

function __setApolloClient(
  state: AppState,
  client: ApolloClient<NormalizedCacheObject>
): AppState {
  return {
    ...state,
    apolloClient: client,
  }
}

function __setDarkMode(state: AppState, darkMode: boolean): AppState {
  return {
    ...state,
    darkMode,
  }
}

export const reducer = (state: AppState, action: AppAction): AppState => {
  switch (action.type) {
    case AppActionType.SET_SELECTED_CLIENT:
      return __setSelectedClient(state, action.payload)
    case AppActionType.SET_SELECTED_APP:
      return __setSelectedApp(state, action.payload)
    case AppActionType.SET_APOLLO_CLIENT:
      return __setApolloClient(state, action.payload)
    case AppActionType.SET_DARK_MODE:
      return __setDarkMode(state, action.payload)
    default:
      alert(`reducer: unhandled action ${action.type}`)
      return state // no change
  }
}

////////////////////////////////////////////////////////////////////////////////
//
//
// A N A L Y T I C S
//
//
////////////////////////////////////////////////////////////////////////////////

function __setDateRange(
  state: AnalyticsState,
  dates: { startDate: Date; endDate: Date }
): AnalyticsState {
  return {
    ...state,
    startDate: dates.startDate,
    endDate: dates.endDate,
  }
}
function __setSelectedTabs(
  state: AnalyticsState,
  selectedTabs: Array<string>
): AnalyticsState {
  return {
    ...state,
    selectedTabs,
  }
}

export const analyticsReducer = (
  state: AnalyticsState,
  action: AppAction
): AnalyticsState => {
  switch (action.type) {
    case AppActionType.SET_DATE_RANGE:
      return __setDateRange(state, action.payload)
    case AppActionType.SET_SELECTED_TABS:
      return __setSelectedTabs(state, action.payload)
    default:
      alert(`analyticsReducer: unhandled action ${action.type}`)
      return state // no change
  }
}

////////////////////////////////////////////////////////////////////////////////
//
//
// L E A D S
//
//
////////////////////////////////////////////////////////////////////////////////

function __setNewLeadsFilter(
  state: LeadsState,
  newLeadsFilter: leadsFilterEnum
): LeadsState {
  return {
    ...state,
    defaultLeadFilters: { ...state.defaultLeadFilters, newLeadsFilter },
  }
}
function __setReturningLeadsFilter(
  state: LeadsState,
  returningLeadsFilter: leadsFilterEnum
): LeadsState {
  return {
    ...state,
    defaultLeadFilters: { ...state.defaultLeadFilters, returningLeadsFilter },
  }
}
function __setActiveLeadsFilter(
  state: LeadsState,
  activeLeadsFilter: leadsFilterEnum
): LeadsState {
  return {
    ...state,
    defaultLeadFilters: { ...state.defaultLeadFilters, activeLeadsFilter },
  }
}

export const leadsReducer = (
  state: LeadsState,
  action: AppAction
): LeadsState => {
  switch (action.type) {
    case AppActionType.SET_SELECTED_PROSPECT:
      return { ...state, selectedProspect: action.payload }
    case AppActionType.SET_NEW_LEADS_FILTER:
      return __setNewLeadsFilter(state, action.payload)
    case AppActionType.SET_RETURNING_LEADS_FILTER:
      return __setReturningLeadsFilter(state, action.payload)
    case AppActionType.SET_ACTIVE_LEADS_FILTER:
      return __setActiveLeadsFilter(state, action.payload)
    default:
      console.log(`leadsReducer: unhandled action ${action.type} `)
      return state // no change
  }
}
