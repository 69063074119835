import React, { useState } from 'react'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import MuiAlert from '@mui/material/Alert'
import { useAtom } from 'jotai'
import { gql, useMutation } from '@apollo/client'
import { checkIsEmail } from 'utils/functions'
import { snackbarConfigAtom } from 'store/atoms'
import {
  ClientEmailReportInfo,
  ColonnadeReportFrequencyEnum,
} from 'graphql/gen-types'
import { ADD_COLONNADE_CLIENT_EMAIL_REPORT_INFO } from 'graphql/auth-queries/authDb'
import hydrationStore from 'store/HydrationStore'
import { classes, StyledDialog } from './AddReportInfoDialog.styles'

interface AddReportInfoDialogProps {
  addReportInfoDialogOpen: boolean
  setAddReportInfoDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  frequencies: ColonnadeReportFrequencyEnum[]
  reportInfos: ClientEmailReportInfo[]
}

export default function AddReportInfoDialog(
  props: AddReportInfoDialogProps
): JSX.Element {
  const [snackbarConfig, setSnackbarConfig] = useAtom(snackbarConfigAtom)

  const clientId = hydrationStore.selectedClient?.id
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [frequency, setFrequency] = useState<ColonnadeReportFrequencyEnum>(
    ColonnadeReportFrequencyEnum.Never
  )
  const [warn, setWarn] = useState('')

  // mutation hook
  const [addColonnadeReportInfo] = useMutation(
    ADD_COLONNADE_CLIENT_EMAIL_REPORT_INFO,
    {
      update(cache, { data: addColonnadeClientEmailReportInfo }) {
        cache.modify({
          fields: {
            getClientEmailReportInfo(existingReports = []) {
              const newReportRef = cache.writeFragment({
                data:
                  addColonnadeClientEmailReportInfo.addColonnadeClientEmailReportInfo,
                fragment: gql`
                  fragment NewReportInfo on ReportInfo {
                    reportId
                  }
                `,
              })
              return [...existingReports, newReportRef]
            },
          },
        })
      },
    }
  )

  // Add ReportInfo handlers
  const handleAddReportInfoSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault()

    if (!checkIsEmail(email)) {
      setWarn('Please enter a valid email address.')
      return
    } else if (
      props.reportInfos.find(
        (reportInfo: ClientEmailReportInfo) =>
          reportInfo.email === email.toLowerCase() &&
          reportInfo.frequency === frequency
      ) !== undefined
    ) {
      setWarn(
        `An Insights report is already being sent to ${email} every ${frequency}.
To add additional reports, choose another email or update the frequency.
`
      )
      return
    }

    addColonnadeReportInfo({
      variables: {
        clientId,
        firstName,
        lastName,
        frequency,
        email,
      },
    })
      .then((result) => {
        if (!result?.data?.addColonnadeClientEmailReportInfo) {
          setSnackbarConfig({
            ...snackbarConfig,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            autoHideDuration: 6000,
            open: true,
            message: 'Insights recipient info update failed!',
            severity: 'error',
          })
        } else {
          setSnackbarConfig({
            ...snackbarConfig,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            autoHideDuration: 6000,
            open: true,
            message: 'Insights recipient info updated successfully!',
            severity: 'success',
          })
        }
        clearFields()
        handleAddReportInfoClose()
      })
      .catch((err) => {
        console.log(err)
        setSnackbarConfig({
          ...snackbarConfig,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          autoHideDuration: 6000,
          open: true,
          message: err.message.replace(/.*[Ee]rror: /, ''),
          severity: 'error',
        })
      })
  }

  const clearFields = (): void => {
    setFirstName('')
    setLastName('')
    setEmail('')
    setFrequency(ColonnadeReportFrequencyEnum.Never)
  }

  const handleAddReportInfoClose = (): void => {
    setWarn('')
    props.setAddReportInfoDialogOpen(false)
  }

  const handleAddReportInfoFrequencyChange = (
    e: SelectChangeEvent<ColonnadeReportFrequencyEnum>
  ): void => {
    const selectedRole = e.target.value as ColonnadeReportFrequencyEnum
    setFrequency(selectedRole)
  }

  const handleFirstNameChange = (input: string): void => {
    setFirstName(input)
  }

  const handleLastNameChange = (input: string): void => {
    setLastName(input)
  }

  const handleEmailChange = (input: string): void => {
    setEmail(input)
  }

  return (
    <StyledDialog
      classes={{
        paper: classes.addReportInfoDialog,
      }}
      open={props.addReportInfoDialogOpen}
      onClose={handleAddReportInfoClose}
      aria-labelledby="reportInfo-add-title"
    >
      {props.addReportInfoDialogOpen && (
        <div>
          <DialogTitle id="reportInfo-add-title">
            Add a New Recipient
          </DialogTitle>
          <DialogContent>
            <form onSubmit={handleAddReportInfoSubmit}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  {warn ? <MuiAlert severity="warning">{warn}</MuiAlert> : null}
                </Grid>
                <Grid item className={classes.gridItem}>
                  <FormControl
                    className={classes.formControlAddReportInfo}
                    variant="standard"
                  >
                    <TextField
                      variant="standard"
                      data-testid="addReportInfoEmailField"
                      id="addReportInfoEmailField"
                      label={'Email'}
                      value={email}
                      required
                      onChange={(ev): void =>
                        handleEmailChange(ev.target.value)
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid container direction="row">
                  <Grid item xs={12} md={6}>
                    <FormControl
                      required
                      className={classes.formControlAddReportInfo}
                      variant="standard"
                    >
                      <TextField
                        variant="standard"
                        data-testid="addReportInfoFirstNameField"
                        id="addReportInfoFirstNameField"
                        className={classes.addReportInfoFirstNameInputField}
                        label={'First Name'}
                        required
                        value={firstName}
                        onChange={(ev): void =>
                          handleFirstNameChange(ev.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      required
                      className={classes.formControlAddReportInfo}
                      variant="standard"
                    >
                      <TextField
                        variant="standard"
                        data-testid="addReportInfoLastNameField"
                        id="addReportInfoLastNameField"
                        className={classes.addReportInfoLastNameInputField}
                        label={'Last Name'}
                        required
                        value={lastName}
                        onChange={(ev): void =>
                          handleLastNameChange(ev.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item className={classes.gridItem}>
                  <FormControl
                    required
                    className={classes.formControlAddReportInfo}
                    variant="standard"
                  >
                    <InputLabel required htmlFor="addReportInfoFrequencySelect">
                      {'Frequency'}
                    </InputLabel>
                    <Select
                      variant="standard"
                      data-testid="addReportInfoFrequencySelect"
                      onChange={handleAddReportInfoFrequencyChange}
                      inputProps={{
                        name: 'addReportInfoFrequencySelect',
                        id: 'addReportInfoFrequencySelect',
                      }}
                      value={frequency}
                      required
                    >
                      {props.frequencies.map((frequency) => (
                        <MenuItem key={frequency} value={frequency}>
                          {frequency}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between">
                <Button
                  onClick={handleAddReportInfoClose}
                  variant="contained"
                  color="primary"
                >
                  Close
                </Button>
                <Button
                  aria-label="submit-button"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </Grid>
            </form>
          </DialogContent>
          {/* Kept for padding */}
          <DialogActions />
        </div>
      )}
    </StyledDialog>
  )
}
