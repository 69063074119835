import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import CloseIcon from '@mui/icons-material/Close'
import LeftIcon from '@mui/icons-material/ChevronLeft'
import RightIcon from '@mui/icons-material/ChevronRight'
import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'
import MobileStepper from '@mui/material/MobileStepper'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import FormLabel from '@mui/material/FormLabel'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Message from './Message'
import { GET_PROSPECT_SGT_APPOINTMENTS } from '../queries'
import { EVT_SUBMITTED_REQUEST_SGT_APPOINTMENT_FORM } from '../constants/eventTracking'
import DateTimePicker, { DatePick } from './DateTimePicker'
import clsx from 'clsx'
import Grid from '@mui/material/Grid'

import {
  Client,
  Community,
  ContactMethodType,
  Lot,
  Maybe,
  Prospect,
  ProspectSgtData,
  SgtVendor,
  useGetProspectSgtAppointmentsLazyQuery,
  useRequestSgtAppointmentsMutation,
} from 'graphql/gen-types'

const PREFIX = 'ProspectSgtAppointmentsDialog'

const classes = {
  formControl: `${PREFIX}-formControl`,
  appointmentDatePicker: `${PREFIX}-appointmentDatePicker`,
  hideImage: `${PREFIX}-hideImage`,
  imageContainer: `${PREFIX}-imageContainer`,
  instructionImage: `${PREFIX}-instructionImage`,
  mobileStepper: `${PREFIX}-mobileStepper`,
  navButton: `${PREFIX}-navButton`,
  navButtonLeft: `${PREFIX}-navButtonLeft`,
  navButtonRight: `${PREFIX}-navButtonRight`,
  paper: `${PREFIX}-paper`,
  commentInput: `${PREFIX}-commentInput`,
}

const ProspectSgtAppointmentsDialogStyled = styled('div')(({ theme }) => ({
  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
    marginLeft: 0,
    marginRight: 0,
    width: '100%',
  },

  [`& .${classes.appointmentDatePicker}`]: {
    margin: theme.spacing(1),
    display: 'block',
  },

  [`& .${classes.hideImage}`]: {
    display: 'none',
  },

  [`& .${classes.imageContainer}`]: {
    paddingTop: '16px',
  },

  [`& .${classes.instructionImage}`]: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    '& img': {
      height: '100%',
      width: '100%',
      objectFit: 'contain',
      webkitUserDrag: 'none',
      khtmlUserDrag: 'none',
      mozUserDrag: 'none',
      oUserDrag: 'none',
      userDrag: 'none',
    },
  },

  [`& .${classes.mobileStepper}`]: {
    display: 'flex',
    justifyContent: 'center',
  },

  [`& .${classes.navButton}`]: {
    background: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '0px',
    position: 'absolute',
    top: '48%',
    minWidth: '45px',
    height: '34px',
    padding: '15px 0px 54px 0px',
  },

  [`& .${classes.navButtonLeft}`]: {
    left: '0',
  },

  [`& .${classes.navButtonRight}`]: {
    right: '0',
  },

  [`& .${classes.paper}`]: {
    overflow: 'hidden',
  },

  [`& .${classes.commentInput}`]: {
    marginTop: '5px',
  },
}))

type ProspectSgtAppointmentsDialogProps = {
  client: Client
  uiConfig: any
  prospect: Prospect
  appointment: any
  isOpen: boolean
  setOpen: (open: boolean) => void
  agentEmail: string[]
  imgUrl: string
  lot: Lot
  community: Community
  onSetDialogData?: () => void
  onSetProspect?: (prospect: Prospect) => void // TODO: audit this. I don't think it is needed.
  onSetAppointment?: (appointment: Date) => void
  onTrackEvent?: (event: string, data: unknown) => void
}

interface sgtVendorExtendedData {
  appointmentsEnabled: boolean
  instructionsOnly: boolean
  instructionImages: string[]
  autoScrollImages: boolean
  autoScrollInterval: number
  maxAppointments: number
}

export default function ProspectSgtAppointmentsDialog({
  client,
  uiConfig,
  prospect,
  appointment,
  isOpen,
  setOpen,
  agentEmail,
  imgUrl,
  lot,
  community,
  onSetDialogData,
  onSetProspect,
  onSetAppointment,
  onTrackEvent,
}: ProspectSgtAppointmentsDialogProps): JSX.Element {
  const { mobile } = uiConfig
  const [openMessage, setOpenMessage] = useState(false)
  const [modalHide, setModalHide] = useState(false)
  const [activeImageIdx, setActiveImageIdx] = useState(0)
  // const track = useEventTracker()
  const clientName = client.altName || ''
  const hasSgtData = lot.inventory?.sgtData
  const sgtVendors = client.sgtVendors
  const sgtVendorData: sgtVendorExtendedData = sgtVendors?.find(
    (vendor: Maybe<SgtVendor>) =>
      vendor && vendor.id === hasSgtData?.sgtVendorId
  )?.extendedData
  const appointmentsEnabled = sgtVendorData?.appointmentsEnabled || true
  const instructionsOnly = sgtVendorData?.instructionsOnly
  const instructionImages = sgtVendorData?.instructionImages
  const maxIndex = instructionImages?.length - 1 || 0
  // const autoScrollImages = sgtVendorData?.autoScrollImages
  // const autoScrollInterval = sgtVendorData?.autoScrollInterval || 3000
  const maxAppointments = sgtVendorData?.maxAppointments || 3
  const inventoryId = lot.inventory?.id || 0

  const [preferredDates, setPreferredDates] = useState<Date[]>()
  const [preferredDatesError, setPreferredDatesError] = useState(false)

  const handleTrackEvent = (event: string, data: unknown) => {
    onTrackEvent && onTrackEvent(event, data)
  }

  const [
    getProspectSgtAppointments,
    getProspectSgtAppointmentsResult,
  ] = useGetProspectSgtAppointmentsLazyQuery()
  const [requestSgtAppointmentsMutation] = useRequestSgtAppointmentsMutation()

  const getAppointments = (
    clientName: string,
    prospectId: number,
    inventoryId: number
  ) => {
    getProspectSgtAppointments({
      variables: {
        clientName,
        prospectId,
        inventoryId,
      },
    })

    const appointments =
      getProspectSgtAppointmentsResult?.data?.prospectSgtAppointments
        ?.filter((p): p is ProspectSgtData => p !== undefined) // remove undefined in arrays
        ?.map((appointment: ProspectSgtData) => appointment.preferredDate) || []

    const initialPreferredDates = [...appointments].sort((a, b) => a - b)
    const maxNewAppointments = maxAppointments - appointments.length
    for (let i = 0; i < maxNewAppointments; i++) {
      initialPreferredDates.push(null)
    }
    setPreferredDates(initialPreferredDates)
  }

  useEffect(() => {
    getAppointments(clientName, prospect.id, inventoryId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const initialName = appointment ? appointment.name : prospect.name
  const [name, setName] = useState(initialName)
  const [nameError, setNameError] = useState('')

  const initialEmails = appointment ? appointment.emails : prospect.email
  const [emails, setEmails] = useState(initialEmails)
  const [emailsError, setEmailsError] = useState('')

  const initialPhone = appointment ? appointment.phone : prospect.phone
  const [phone, setPhone] = useState(initialPhone)
  const [phoneError, setPhoneError] = useState('')
  const [phoneRequired, setPhoneRequired] = useState(false)

  const initialComment = appointment ? appointment.comment : ''
  const [comment, setComment] = useState(initialComment)

  const initialPreferredContactMethods: ContactMethodType[] =
    appointment?.preferredContactMethods ||
    prospect.preferredContactMethods ||
    []
  const [preferredContactMethods, setPreferredContactMethods] = useState(
    initialPreferredContactMethods
  )

  const validateEmailAddress = (emailAddress: string) => {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress)
  }

  const handleSetProspect = (prospect: Prospect) => {
    onSetProspect && onSetProspect(prospect)
  }
  const handleSetAppointment = (appointment: Date) => {
    onSetAppointment && onSetAppointment(appointment)
  }

  const handleSetDialogData = () => {
    onSetDialogData && onSetDialogData()
  }

  const handleClose = (cancel: boolean) => {
    handleSetProspect(prospect)
    handleSetAppointment(appointment)
    if (cancel) {
      setOpen(false)
      handleSetDialogData()
      return
    }
    if (!name) {
      setNameError('Please provide your name')
    }
    const emailsArray = emails.split(',')
    const validatedEmails = []
    if (!emails) {
      setEmailsError('Please provide at least one email address')
    } else {
      for (const emailAddress of emailsArray) {
        if (!validateEmailAddress(emailAddress.trim())) {
          setEmailsError(
            'Please provide valid email addresses separated by ", "'
          )
          return
        } else {
          //for some reason emails.trim().split(',') is not trimming the string
          validatedEmails.push(emailAddress.trim())
        }
      }
    }
    if (phoneRequired && !phone) {
      setPhoneError('Please provide your phone number')
    }
    if (nameError || emailsError || phoneError || preferredDatesError) {
      return
    }
    const communityName = community.name?.replace(/ /g, '%20')
    const inventoryUrl = `${process.env.REACT_APP_TRUSS}/client/${client.altName}/community/${communityName}/inventory?inventoryId=${inventoryId}`
    const timezoneOffset = new Date().getTimezoneOffset()
    const prospectAppointmentData = {
      prospect: {
        id: prospect.id,
        clientId: client.id || 0,
        name,
        email: validatedEmails,
        phone,
        preferredContactMethod: preferredContactMethods,
        comment,
      },
      inventory: {
        id: inventoryId,
        address: lot.address,
        imgUrl,
        inventoryUrl,
      },
      preferredDate: preferredDates?.filter((date) => date != null),
      agentEmail,
    }

    handleTrackEvent(
      EVT_SUBMITTED_REQUEST_SGT_APPOINTMENT_FORM,
      prospectAppointmentData
    )

    // apolloClient
    // .mutate({
    //   mutation: REQUEST_SGT_APPOINTMENT,
    //   refetchQueries: [
    //     {
    //       query: GET_PROSPECT_SGT_APPOINTMENTS,
    //       variables: {
    //         clientName,
    //         prospectId: prospect.id,
    //         inventoryId,
    //       },
    //     },
    //   ],
    //   variables: {
    //     clientName,
    //     prospectSgtApptInput: prospectAppointmentData,
    //     timezoneOffset,
    //   },
    // })

    requestSgtAppointmentsMutation({
      refetchQueries: [
        {
          query: GET_PROSPECT_SGT_APPOINTMENTS,
          variables: {
            clientName,
            prospectId: prospect.id,
            inventoryId,
          },
        },
      ],
      variables: {
        clientName,
        prospectSgtApptInput: prospectAppointmentData,
        timezoneOffset,
      },
    })
      .then(() => {
        setOpenMessage(true)
        setModalHide(true)
        setTimeout(() => {
          setOpen(false)
          handleSetDialogData()
        }, 4000)
      })
      .catch((error) => {
        console.error('Error submitting appointment request:', error)
      })
  }

  const handleNameChange = (nameInput: string) => {
    setNameError('')
    setName(nameInput)
  }

  const handleEmailsChange = (emailsInput: string) => {
    setEmailsError('')
    setEmails(emailsInput)
  }

  const handlePhoneChange = (phoneInput: string) => {
    setPhone(phoneInput)
  }

  const handleCommentChange = (commentInput: string) => {
    setComment(commentInput)
  }

  const handleContactMethodsChange = (
    contactMethodInput: ContactMethodType
  ) => {
    const updatedContactMethods = preferredContactMethods.includes(
      contactMethodInput
    )
      ? preferredContactMethods.filter(
          (contactMethod: string) => contactMethod !== contactMethodInput
        )
      : [...preferredContactMethods, contactMethodInput]
    if (
      updatedContactMethods.includes(ContactMethodType.Phone) ||
      updatedContactMethods.includes(ContactMethodType.Text)
    ) {
      setPhoneRequired(true)
    } else {
      setPhoneRequired(false)
    }
    setPreferredContactMethods(updatedContactMethods)
  }

  const handlePreferredDatesChange = (datePick: DatePick, index: number) => {
    if (!datePick.date) {
      setPreferredDatesError(true)
    } else {
      setPreferredDatesError(false)
      const updatedPreferredDates = [...(preferredDates || [])]
      updatedPreferredDates[index] = datePick.date
      setPreferredDates(updatedPreferredDates)
    }
  }

  const renderPreferredDates = () => {
    return (
      <React.Fragment>
        {preferredDates?.map((appointment, i) => (
          <DateTimePicker
            uiConfig={uiConfig}
            dateTime={appointment || null}
            label={`Preferred Date # ${i + 1}`}
            onChange={(datePick) => handlePreferredDatesChange(datePick, i)}
          />
        ))}
      </React.Fragment>
    )
  }

  const prevImageIndex = activeImageIdx === 0 ? null : activeImageIdx - 1
  const nextImageIndex = activeImageIdx === maxIndex ? null : activeImageIdx + 1

  const [slideCompleted, setSlideCompleted] = React.useState(0)
  const progressJump = instructionImages && 100 / (instructionImages.length - 1)

  const updateImageIdx = (direction: string) => {
    if (direction === 'forward') {
      setActiveImageIdx(activeImageIdx === maxIndex ? 0 : activeImageIdx + 1)
      setSlideCompleted(slideCompleted + progressJump)
    } else if (direction === 'back') {
      setActiveImageIdx(activeImageIdx === 0 ? maxIndex : activeImageIdx - 1)
      setSlideCompleted(slideCompleted - progressJump)
    } else {
      setActiveImageIdx(0)
      setSlideCompleted(0)
    }
  }
  return (
    <ProspectSgtAppointmentsDialogStyled>
      <Dialog
        open={!modalHide ? isOpen : !modalHide}
        onClose={(ev) => handleClose(true)}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        classes={{ paper: classes.paper }}
      >
        <IconButton
          style={{
            position: 'absolute',
            right: 0,
            top: 4,
            color: 'rgba(0, 0, 0, 0.54)',
          }}
          color="inherit"
          onClick={(ev) => handleClose(true)}
          aria-label="Close"
          size="large"
        >
          <CloseIcon />
        </IconButton>
        {instructionsOnly && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">
              <Typography variant="h4" style={{ paddingRight: '24px' }}>
                Tour this inventory home on your own!
              </Typography>
              {instructionImages && (
                // TODO fix swipeable views.
                <span>
                  Autoplay Image Gallery is currently being worked on. Please
                  check back at a later date.
                </span>
                // <AutoPlaySwipeableViews
                //   autoplay={autoScrollImages}
                //   interval={autoScrollInterval}
                //   className={classes.imageContainer}
                //   containerStyle={{ height: '66vh' }}
                //   enableMouseEvents
                //   index={activeImageIdx}
                //   onChangeIndex={(index: number, lastIndex: number) => {
                //     if (index === 0 && lastIndex === maxIndex) {
                //       updateImageIdx('first')
                //     } else if (index < lastIndex) {
                //       updateImageIdx('back')
                //     } else {
                //       updateImageIdx('forward')
                //     }
                //   }}
                // >
                //   {instructionImages.map((image, index) => {
                //     return (
                //       <div
                //         key={image + index}
                //         className={classes.instructionImage}
                //       >
                //         <img
                //           src={`${anewgoCloudinaryPrefix}/assets/custom/${image}`}
                //           alt={image}
                //         />
                //       </div>
                //     )
                //   })}
                // </AutoPlaySwipeableViews>
              )}
              {!mobile && (
                <React.Fragment>
                  {prevImageIndex !== null && (
                    <Button
                      className={clsx(classes.navButton, classes.navButtonLeft)}
                      onClick={() => updateImageIdx('back')}
                    >
                      <LeftIcon style={{ fontSize: '2.5rem' }} />
                    </Button>
                  )}
                  {nextImageIndex !== null && (
                    <Button
                      className={clsx(
                        classes.navButton,
                        classes.navButtonRight
                      )}
                      onClick={() => updateImageIdx('forward')}
                    >
                      <RightIcon style={{ fontSize: '2.5rem' }} />
                    </Button>
                  )}
                </React.Fragment>
              )}
            </DialogTitle>
            {!mobile && (
              <LinearProgress variant="determinate" value={slideCompleted} />
            )}
            {mobile && instructionImages && (
              <MobileStepper
                variant="dots"
                steps={maxIndex + 1}
                position="static"
                activeStep={activeImageIdx}
                className={classes.mobileStepper}
                nextButton={
                  <span></span>
                  // <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                  //   Next
                  //   {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                  // </Button>
                }
                backButton={
                  <span></span>
                  // <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                  //   {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                  //   Back
                  // </Button>
                }
              />
            )}
          </React.Fragment>
        )}
        {!instructionsOnly && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">
              <Typography
                variant="h3"
                style={{ paddingRight: '24px' }}
              >{`Request ${
                hasSgtData ? 'a Self-Guided Tour' : 'an appointment'
              }`}</Typography>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    label="Name"
                    helperText={nameError}
                    error={!!nameError}
                    type="text"
                    margin="dense"
                    fullWidth
                    required
                    value={name}
                    onChange={(ev) => handleNameChange(ev.target.value)}
                  />
                  <TextField
                    variant="standard"
                    label="Email Addresses"
                    helperText={emailsError}
                    error={!!emailsError}
                    type="text"
                    margin="dense"
                    fullWidth
                    required
                    value={emails}
                    onChange={(ev) => handleEmailsChange(ev.target.value)}
                  />
                  <TextField
                    variant="standard"
                    label="Phone #"
                    helperText={phoneError}
                    error={!!phoneError}
                    type="phone"
                    margin="dense"
                    fullWidth
                    required={phoneRequired}
                    value={phone ? phone : ''}
                    onChange={(ev) => handlePhoneChange(ev.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.commentInput}>
                  <TextField
                    label="Comment"
                    variant="outlined"
                    type="text"
                    margin="dense"
                    fullWidth
                    rows={7}
                    multiline
                    value={comment}
                    onChange={(ev) => handleCommentChange(ev.target.value)}
                  />
                </Grid>
              </Grid>
              <FormControl variant="standard" className={classes.formControl}>
                <FormLabel>Preferred Contact</FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={preferredContactMethods.includes(
                          ContactMethodType.Email
                        )}
                        onChange={(ev) =>
                          handleContactMethodsChange(
                            ev.target.name as ContactMethodType
                          )
                        }
                        name="EMAIL"
                      />
                    }
                    label="EMAIL"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={preferredContactMethods.includes(
                          ContactMethodType.Phone
                        )}
                        onChange={(ev) =>
                          handleContactMethodsChange(
                            ev.target.name as ContactMethodType
                          )
                        }
                        name="PHONE"
                      />
                    }
                    label="PHONE"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={preferredContactMethods.includes(
                          ContactMethodType.Text
                        )}
                        onChange={(ev) =>
                          handleContactMethodsChange(
                            ev.target.name as ContactMethodType
                          )
                        }
                        name="TEXT"
                      />
                    }
                    label="TEXT"
                  />
                </FormGroup>
              </FormControl>
              {appointmentsEnabled && renderPreferredDates()}
            </DialogContent>
            <DialogActions>
              <Button onClick={(ev) => handleClose(true)} color="secondary">
                Cancel
              </Button>
              <Button onClick={(ev) => handleClose(false)} color="primary">
                Send
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      </Dialog>
      <Message
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openMessage}
        autoHideDuration={4000}
        onClose={() => {
          setOpenMessage(false)
        }}
        variant="success"
        message="Sent appointment request!"
      />
    </ProspectSgtAppointmentsDialogStyled>
  )
}
