import { styled } from '@mui/material/styles'
import Popover from '@mui/material/Popover'

const PREFIX = 'ReservationIntegrationDeletionPopover'

export const classes = {
  deletePopoverContext: `${PREFIX}-deletePopoverContext`,
  deletePopoverButtonHolder: `${PREFIX}-deletePopoverButtonHolder`,
}

export const StyledPopover = styled(Popover)(() => ({
  [`& .${classes.deletePopoverContext}`]: {
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  [`& .${classes.deletePopoverButtonHolder}`]: {
    alignSelf: 'flex-end',
    marginTop: 15,
  },
}))
