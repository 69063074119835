import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { getImagePrefix, getPlanSpecs } from '@anewgo/utils'
import { Plan, Prospect } from 'graphql/gen-types'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'

import { SortOptions } from 'components/common/custom-inputs/SortSelect'
import { InfoButton } from 'components/common/custom-inputs/InfoButton'
import { FlipCard } from './FlipCard'
import { formatDateTime, humanTimeDuration } from '../../../utils/functions'

const PREFIX = 'FavoriteCard'

const classes = {
  notBrowsedBanner: `${PREFIX}-notBrowsedBanner`,
}

const StyledTypography = styled(Typography)(() => ({
  [`&.${classes.notBrowsedBanner}`]: {
    backgroundColor: '#ff5722',
    borderRadius: '4px 4px 0px 0px',
    color: 'white',
    fontSize: '0.8rem',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
}))

// Interface to allow additional fields be displayed, for instance if Plan has Prospect data too (when it was favorited).
export interface ProspectPlan extends Plan {
  timestamp?: string
  favoriteId: number
  communityName?: string
  fpOptsList?: string[]
  source?: string
  scheme?: {
    id: number
    name: string
    materials: {
      hex: string
      id: number
      material: string
      materialCategory: string
      materialId: number
      name: string
      swatch: string
    }[]
  }
}
const MAX_PLANCARD_MEDIA_HT = 200
const INFO_BUTTON_SIZE = 25 // width and height of info button

export interface FavoriteCardProps {
  hideNotBrowsedBanner?: boolean
  plan: ProspectPlan
  prospect?: Prospect | null
  frontPageMetric: SortOptions
}

export const FavoriteCard: React.FC<FavoriteCardProps> = ({
  hideNotBrowsedBanner,
  plan,
  prospect,
  frontPageMetric: metric,
}: FavoriteCardProps) => {
  const [metricValue, setMetricValue] = useState<number>(0)
  const navigate = useNavigate()

  // get selection related to plan
  const elevation = plan?.elevations && plan.elevations[0]
  useEffect(() => {
    switch (metric) {
      case SortOptions.Frequency: {
        setMetricValue(plan?.popularityMetrics?.byFrequency || 0)
        break
      }
      case SortOptions.Duration: {
        setMetricValue(plan?.popularityMetrics?.byDuration?.total || 0)
        break
      }
      case SortOptions.Popularity: {
        setMetricValue(plan?.popularityMetrics?.byPopularity?.popularity || 0)
        break
      }
      case SortOptions.MyHomeHits: {
        setMetricValue(plan?.popularityMetrics?.byPopularity?.brochure || 0)
        break
      }
      case SortOptions.CtaHits: {
        setMetricValue(plan?.popularityMetrics?.byPopularity?.cta || 0)
        break
      }
    }
  }, [plan, metric])

  // If plan has no elevations, do not display it.
  if (!plan?.elevations?.length) {
    return null
  }

  const planTitle = plan?.displayName || plan?.name || ''
  const elevationTitle = elevation?.caption || ''

  const hasTimestamp = (plan as ProspectPlan)?.timestamp !== undefined // If type ProspectPlan has timestamp

  // Get plan values
  const { bed, bath, size, cost } = getPlanSpecs([plan])
  const specs = [size, bed, bath].filter((opt) => opt).join(' | ')
  const title = `${planTitle} ${elevationTitle}`
  const thumb = elevation?.thumb || ''
  const img = `${getImagePrefix(
    plan.clientName,
    `c_scale,h_${MAX_PLANCARD_MEDIA_HT}`
  )}/${thumb}`

  const frequency = plan?.popularityMetrics?.byFrequency
  const duration = humanTimeDuration(
    plan?.popularityMetrics?.byDuration?.total || 0
  )
  const popularity = plan?.popularityMetrics?.byPopularity?.popularity
  const brochure = plan?.popularityMetrics?.byPopularity?.brochure
  const cta = plan?.popularityMetrics?.byPopularity?.cta
  // Wrapper element/function to conditionally display metric field.
  const MetricItem = (props: {
    data: number | string | undefined | null
    name: string
  }): JSX.Element | null =>
    props.data !== undefined && props.data !== null ? (
      <Grid item xs={12}>
        <Typography
          variant="caption"
          align="center"
        >{`${props.name}: ${props.data}`}</Typography>
      </Grid>
    ) : null

  const timestampDisplay = (
    <Typography key="TimeStamp">{`${formatDateTime(
      (plan as ProspectPlan)?.timestamp,
      'MMM do yyyy'
    )}`}</Typography>
  )

  let appendToMetric = ''
  if (metric === 'Frequency' && metricValue === 0) {
    appendToMetric = ' (no events)'
  }

  const bodyContent = [
    <Typography variant="h6" key={`plan-title-${plan.id}`}>
      {title}
    </Typography>,
    <Typography
      variant="subtitle1"
      noWrap={true}
      key={`plan-community-name-${plan.id}`}
    >
      {plan.communityName}
    </Typography>,
    <Typography variant="caption" key={`plan-cost-${plan.id}`}>
      {cost}
    </Typography>,
    <Typography variant="caption" component="h6" key={`plan-specs-${plan.id}`}>
      {specs}
    </Typography>,
    <Typography variant="caption" align="center" key={`plan-metric-${plan.id}`}>
      {`${metric}: ${metricValue}${appendToMetric}`}
    </Typography>,
  ]

  if (!hideNotBrowsedBanner && duration === '0s') {
    bodyContent.push(
      <StyledTypography
        variant="caption"
        align="center"
        className={classes.notBrowsedBanner}
        key={`plan-favorite-${plan.id}`}
        data-testid="favoriteNotDesignedBanner"
      >
        Elevation was favorited but not designed.
      </StyledTypography>
    )
  }

  return (
    <FlipCard
      frontSide={[hasTimestamp ? timestampDisplay : null]}
      backSide={[
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          data-testid="flippedSide"
          key="BackSideGrid"
        >
          <MetricItem data={frequency} name="Frequency" />
          <MetricItem data={duration} name="Duration" />
          <MetricItem data={popularity} name="Popularity" />
          <MetricItem data={brochure} name="Brochure Hits" />
          <MetricItem data={cta} name="CTA hits" />
        </Grid>,
      ]}
      shared={bodyContent}
      media={{
        title: title,
        url: img,
        altText: 'Plan',
        frontSideOnly: true,
      }}
      actions={
        <InfoButton
          onClickHandler={(): void => {
            navigate(
              `../prospect/favorite-details?prospectId=${prospect?.name}<${prospect?.email}>&favoriteId=${plan.favoriteId}`
            )
          }}
          height={INFO_BUTTON_SIZE}
          width={INFO_BUTTON_SIZE}
          title="Open Brochure"
        />
      }
      favoriteCardStyles
    />
  )
}
