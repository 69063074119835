import React from 'react'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'

import CloseIcon from '@mui/icons-material/Close'

const PREFIX = 'ReservationIntegrationDisableForCommunityDialog'

const classes = {
  closeButton: `${PREFIX}-closeButton`,
  text: `${PREFIX}-text`,
}

const StyledDialog = styled(Dialog)(() => ({
  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: 0,
    top: 4,
    color: 'rgba(0, 0, 0, 0.54)',
  },

  [`& .${classes.text}`]: {
    marginBottom: 20,
  },
}))

export interface ReservationIntegrationDisableForCommunityDialogProps {
  onClose: () => void
  onConfirm: () => void
  opened: boolean
}

const ReservationIntegrationDisableForCommunityDialog: React.FC<ReservationIntegrationDisableForCommunityDialogProps> = ({
  onClose,
  onConfirm,
  opened,
}) => {
  return (
    <StyledDialog
      open={opened}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      fullWidth
    >
      <IconButton
        className={classes.closeButton}
        color="inherit"
        onClick={onClose}
        aria-label="Close"
        size="large"
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>Confirmation to disable Reserve Online</DialogTitle>
      <DialogContent className={classes.text}>
        There are reservations in progress for this community. Once the feature
        is disabled for the community the reservations will become invalid. Did
        you still want to continue?
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button variant="outlined" color="primary" onClick={onConfirm}>
          Disable Reserve Online
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export default ReservationIntegrationDisableForCommunityDialog
