import React from 'react'
import { styled } from '@mui/material/styles'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { PopularityMetric } from 'graphql/gen-types'
import { capitalize } from 'utils/functions'

const PREFIX = 'PopularityMetricSelect'

const classes = {
  formControl: `${PREFIX}-formControl`,
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`&.${classes.formControl}`]: {
    margin: `0px ${theme.spacing(1)}`,
  },
}))

export interface PopularityMetricSelectProps {
  metric: PopularityMetric
  setMetric: (selectedMetric: PopularityMetric) => void
  defaultAvailableMetrics?: PopularityMetric[]
}

export default function PopularityMetricSelect({
  defaultAvailableMetrics,
  metric,
  setMetric,
}: PopularityMetricSelectProps): JSX.Element {
  const availableMetrics =
    defaultAvailableMetrics?.map((m) => capitalize(m)) ||
    Object.keys(PopularityMetric)

  return (
    <StyledFormControl className={classes.formControl} variant="standard">
      <InputLabel id="metric-select-label">Metric</InputLabel>
      <Select
        variant="standard"
        labelId="metric-select-label"
        id="metric-select"
        value={metric}
        onChange={(e) => setMetric(e.target.value as PopularityMetric)}
      >
        {availableMetrics.map((metric) => (
          <MenuItem key={metric} value={PopularityMetric[metric]}>
            {metric}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  )
}
