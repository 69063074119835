import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import { useGetProspectActivitiesQuery } from 'graphql/gen-types'
import React from 'react'
import DateFnsAdapter from '@date-io/date-fns'

import { PaperStyled, TitleStyled } from './styles/ProspectInquiry.styles'

interface ProspectInquiryProps {
  clientName: string
  email?: string | null
}

type Activity = {
  content?: string
  inquiry?: string
  communitiesSelected?: string
  lotAddress?: string
  lotName?: string
  notes?: string
  planName?: string
  date: string
  title: string
}

const COMPONENT_TITLE = 'Lead Inquiries'

const dateFns = new DateFnsAdapter()

function ProspectInquiry({
  clientName,
  email,
}: ProspectInquiryProps): JSX.Element | null {
  const { data: activitiesData, loading } = useGetProspectActivitiesQuery({
    variables: {
      clientName: clientName,
      email: email || '',
    },
    skip: !email,
  })

  const filteredActivities:
    | Activity[]
    | undefined = activitiesData?.getProspectActivities?.activities?.filter(
    (activity: Activity) => activity.title === 'Send Inquiry'
  )

  if (loading || !email) {
    return (
      <PaperStyled>
        <TitleStyled variant="h5">{COMPONENT_TITLE}</TitleStyled>
        Loading...
      </PaperStyled>
    )
  }

  if (!filteredActivities) {
    return (
      <PaperStyled>
        <TitleStyled variant="h5">{COMPONENT_TITLE}</TitleStyled>
        No Inquiry Received
      </PaperStyled>
    )
  }

  return filteredActivities?.length > 0 ? (
    <PaperStyled>
      <TitleStyled variant="h5">{COMPONENT_TITLE}</TitleStyled>
      <List>
        {filteredActivities.map((activity) => (
          <ListItem>
            <ListItemText>
              <Typography>
                {dateFns.format(new Date(activity?.date), 'MMM do yyyy')}
              </Typography>
              {activity.inquiry || activity.content}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </PaperStyled>
  ) : null
}

export default ProspectInquiry
