import React from 'react'
import { getProductSpecs, siteplans } from '@anewgo/utils'
import { identity } from '@anewgo/functions'
import { ExteriorImage } from '@anewgo/interactive-exterior-ng'
import LineSeparator from '../components/LineSeparator'
import ReserveButton from '../components/ReserveButton'
import {
  mirrorElevationImage,
  applyMirrorContent,
  getInventoryImage,
} from '../utils'
import {
  InstructionText,
  SectionContainer,
  SpecsPanel,
  SpecsPanelText,
  ElevationSection,
} from '../MyHome.styles'
import {
  Client,
  Community,
  Elevation,
  Inventory,
  Plan,
} from 'graphql/gen-types'
import { HomeSelection, MyHomePageVariants } from '../MyHome'
import { API_KEY } from '../constants'
import { cityFormatter } from '../../../../utils/formatters'

const { isInventoryLot } = siteplans

type MyHomeElevationsProps = {
  variant?: MyHomePageVariants
  uiConfig: any
  client: Client
  selection: HomeSelection
  community: Community
  plan: Plan
  elevation: Elevation
  elevationColors: Elevation
  mirrorElevation: Elevation
  onRequestToChange: (target: string) => void
}

export default function MyHomeElevations({
  variant,
  uiConfig,
  client,
  selection,
  community,
  plan,
  elevation,
  elevationColors,
  mirrorElevation,
  onRequestToChange,
}: MyHomeElevationsProps): JSX.Element {
  const planName = plan?.name
  const name = planName
    ? `Home Plan: The ${planName} ${elevation.caption}`
    : 'Home Plan'
  const directoryName = client?.directoryName || ''

  const lot = selection.lot
  const isInventory = lot && isInventoryLot(lot)
  const isMyHome = variant === 'myHome'
  const isEditable = !isInventory && isMyHome

  const { size, bed, bath, baseCost, cost } = getProductSpecs(selection)
  const specs = [size, bed, bath].filter(identity).join(' | ')
  let lotInfo = lot?.name ? `Lot ${lot.name}` : ''
  if (lot?.size) {
    lotInfo += ` | ${lot.size} ${community?.primarySiteplan?.lotMetric}`
  }

  const inventory: Inventory | null = lot?.inventory || null

  // function handleRequestToChange(target: string) {
  //   onRequestToChange(target)
  // }

  const renderExteriorImage = () => {
    const width = uiConfig.mobile
      ? window.innerWidth * 0.8
      : window.innerWidth * 0.5 - 64 // padding * 2
    const height = 0.66667 * width
    const myColors = selection.colorSelections
    const myScheme = selection.scheme

    const inventoryPhotoUrl = getInventoryImage(
      directoryName,
      elevation,
      inventory
    )

    let mirrorImageDisplay = mirrorElevationImage(elevation, uiConfig)
    const [modifiedElevation, useMirrorImageDisplay] = applyMirrorContent(
      elevationColors,
      mirrorElevation
    )
    if (!mirrorImageDisplay && useMirrorImageDisplay) {
      mirrorImageDisplay = true
    }

    if (
      elevation &&
      mirrorElevation &&
      !elevation.mirrorRolePrimary &&
      !selection.scheme &&
      Object.keys(selection.colorSelections || {}).length === 0
    ) {
      mirrorImageDisplay = true
    }
    return isInventory ? (
      inventoryPhotoUrl ? (
        <img src={inventoryPhotoUrl} alt="inventory" />
      ) : null
    ) : planName ? (
      <ExteriorImage
        flip={mirrorImageDisplay}
        maxWidth={width}
        engineUrl={`${process.env.REACT_APP_HEMI_ENGINE}/api/exterior`}
        selectedScheme={myScheme}
        directoryName={client.directoryName || ''}
        selectedColors={myColors}
        colorMtd={community.colormtd || ''}
        elevation={modifiedElevation}
        plan={planName}
        community={community.name || ''}
        client={client.altName || ''}
        height={height}
        width={width}
        apiKey={API_KEY}
      />
    ) : null
  }

  const specsPanel = () => {
    const address1 = lot ? lot.address : ''
    const { cityLocation } = community
    const address2 = cityFormatter.address(cityLocation)
    const priceListing = isEditable ? `Starting at ${cost}` : baseCost
    const isBuildOnYourLot = community.buildYourLot
    return (
      <React.Fragment>
        <SpecsPanelText variant="h5">{specs}</SpecsPanelText>
        {!isBuildOnYourLot && (
          <SpecsPanelText variant="h5">{lotInfo}</SpecsPanelText>
        )}
        {!isBuildOnYourLot && address1 !== '' && (
          <SpecsPanelText variant="h5">{address1}</SpecsPanelText>
        )}
        {!isBuildOnYourLot && (
          <SpecsPanelText variant="h5">{address2}</SpecsPanelText>
        )}
        {baseCost && (
          <SpecsPanelText style={{ fontWeight: 'bold' }} variant="h5">
            {priceListing}
          </SpecsPanelText>
        )}
      </React.Fragment>
    )
  }
  return (
    <SectionContainer>
      <LineSeparator title={name} />
      <ElevationSection>
        {renderExteriorImage()}
        {isEditable ? (
          <InstructionText>click image to design</InstructionText>
        ) : null}
        <SpecsPanel>
          {specsPanel()}
          <ReserveButton location="My Home" lot={lot} />
        </SpecsPanel>
      </ElevationSection>
    </SectionContainer>
  )
}
