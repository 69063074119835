import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import MuiDialogTitle from '@mui/material/DialogTitle'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import InteractiveSiteplan from './InteractiveSiteplan'
import SiteplanGrid from './SiteplanGrid'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import {
  Siteplan,
  Lot,
  Community,
  MasterCommunity,
} from '../../../graphql/gen-types'
import LotLeadsDialog from './LotLeadsDialog'
import { GOLDEN_RATIO } from '../../../constants'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="right" ref={ref} {...props} />
})
/* Input can be either community or master community */
type CommunityType = Community | MasterCommunity
interface SiteplanDetailsDialogProps {
  community: CommunityType
  open: boolean
  setOpen: (open: boolean) => void
  handleCommunitySelect: (community: Community) => void
}

export default function SiteplanDetailsDialog({
  community,
  open,
  setOpen,
  handleCommunitySelect,
}: SiteplanDetailsDialogProps): JSX.Element {
  const handleClose = () => {
    setOpen(false)
  }
  const theme = useTheme()
  const lg = useMediaQuery(theme.breakpoints.down(1400))
  const [selectedCommunity, setSelectedCommunity] = useState(community)
  const [previousCommunity, setPreviousCommunity] = useState<CommunityType>()
  const [selectedLot, setSelectedLot] = useState<Lot | null>(null)
  useEffect(() => {
    if (community !== selectedCommunity) {
      setPreviousCommunity(selectedCommunity)
      setSelectedCommunity(community)
    }
  }, [community, selectedCommunity])
  const handleGoBack = () => {
    if (previousCommunity) {
      setSelectedCommunity(previousCommunity)
      setPreviousCommunity(undefined)
    } else {
      setOpen(false)
    }
  }

  const width = lg ? 450 : 800
  const height = width * GOLDEN_RATIO

  const DialogTitle = ({
    id,
    children,
    onGoBack,
  }: {
    id: string
    children: JSX.Element | string | null
    onGoBack: () => void
  }): JSX.Element => {
    return (
      <MuiDialogTitle id={id} disableTypography style={{ display: 'flex' }}>
        {onGoBack ? (
          <IconButton aria-label="back" onClick={onGoBack} size="large">
            <ArrowBackIcon />
          </IconButton>
        ) : null}
        <Typography variant="h6" style={{ marginTop: '7px' }}>
          {children}
        </Typography>
      </MuiDialogTitle>
    )
  }

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth={false}
        fullWidth
      >
        {isCommunity(selectedCommunity) && (
          <DialogTitle id="alert-dialog-slide-title" onGoBack={handleGoBack}>
            {`${selectedCommunity.name}${
              selectedCommunity.siteplans?.[0]?.name !== selectedCommunity.name
                ? ' - ' + selectedCommunity.siteplans[0].name
                : ''
            }`}
          </DialogTitle>
        )}
        <DialogContent style={{ width: '100%' }}>
          {isCommunity(selectedCommunity) && (
            <LotLeadsDialog
              lot={selectedLot}
              onClose={() => setSelectedLot(null)}
              clientName={selectedCommunity.clientName}
            />
          )}
          {/*Master Community*/}
          {isMasterCommunity(selectedCommunity) && (
            <SiteplanGrid
              communities={selectedCommunity.communities as Community[]}
              handleCommunitySelect={handleCommunitySelect}
            />
          )}
          {/* Master Siteplan, community with multiple siteplans */}
          {isCommunity(selectedCommunity) &&
            selectedCommunity.siteplans?.length > 1 && (
              <SiteplanGrid
                handleCommunitySelect={handleCommunitySelect}
                communities={selectedCommunity.siteplans
                  .filter((siteplan: Siteplan) => !siteplan.master)
                  .map((siteplan: Siteplan) => ({
                    ...selectedCommunity,
                    siteplans: [siteplan],
                  }))}
              />
            )}
          {/* Siteplan, community with one siteplans */}
          {isCommunity(selectedCommunity) &&
            selectedCommunity.siteplans?.length === 1 &&
            selectedCommunity.siteplans[0] && (
              <div>
                <InteractiveSiteplan
                  siteplan={selectedCommunity.siteplans[0]}
                  heatMapMode={true}
                  onLotClick={(lot) => setSelectedLot(lot as Lot)}
                  interactive={true}
                  width={width}
                  height={height}
                  enableZoom
                />
              </div>
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

function isCommunity(community: CommunityType): community is Community {
  return (community as Community).siteplans !== undefined
}

function isMasterCommunity(
  community: CommunityType
): community is MasterCommunity {
  return (community as MasterCommunity).communities !== undefined
}
