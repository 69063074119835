import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from 'react'
import { AuthProvider, initialState, UserProps } from './AuthContext'
import AUTHENTICATE_QUERY from 'graphql/auth-queries/authenticate'
import IS_EXISTING_EMAIL_QUERY from 'graphql/auth-queries/isExistingEmail'
import VERIFY_TOKEN_QUERY from 'graphql/auth-queries/verifyToken'
import CLIENTS_FOR_USER_QUERY from 'graphql/queries/clientsForUser'
import { ApolloQueryResult, useSubscription } from '@apollo/client'
import jwtDecode from 'jwt-decode'
import hydrationStore from 'store/HydrationStore'
import { AppStore } from 'store'
import { AppActionType } from 'store/reducers'
import { ClientIdAndName } from 'graphql/gen-types'
import SUBSCRIPTION_CHANGED_SUBSCRIPTION from 'graphql/nexus-subscriptions/subscriptionChanged'
import TokenExpiredModal from './TokenExpiredModal'

interface AuthProps {
  noHydration?: boolean
  defaultClientName?: string
  children: React.ReactNode[] | React.ReactNode
}

function Auth(props: AuthProps): JSX.Element {
  const { noHydration, defaultClientName, children } = props
  const [authenticated, setAuthenticated] = useState(false)
  const [user, setUser] = useState({} as UserProps)
  const [accessToken, setAccessToken] = useState(hydrationStore.token)
  const { dispatch, appState } = useContext(AppStore)
  const hydratedToken = hydrationStore.token
  const authClient = appState.apolloClient

  const colonnadeToken = new URL(window.location.href).searchParams.get(
    'colonnadeToken'
  )

  if (colonnadeToken) {
    hydrationStore.token = colonnadeToken

    // This will prompt the user page to reload on the root origin.
    // since we have a token now, it will handle all the information needed to be acquired on a higher level with token information.
    window.location.href = window.location.origin
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function __isExistingEmail(email: string): Promise<ApolloQueryResult<any>> {
    return authClient
      .query({
        fetchPolicy: 'no-cache',
        query: IS_EXISTING_EMAIL_QUERY,
        variables: { email },
      })
      .then((result: ApolloQueryResult<any>) => {
        const { data } = result
        return data.isExistingEmail
      })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function __verifyToken(token: string): Promise<ApolloQueryResult<any>> {
    return authClient
      .query({
        fetchPolicy: 'no-cache',
        query: VERIFY_TOKEN_QUERY,
        variables: {
          token,
        },
      })
      .then((result: ApolloQueryResult<any>) => {
        const { data } = result
        return data.verifyToken
      })
      .catch((err: Error) => {
        console.error(err)
        return null
      })
  }

  const __memoizedValidateToken = useCallback(async (): Promise<boolean> => {
    const token = hydrationStore.token
    if (!token) {
      return false // not signed in
    }
    const decodedToken = await __verifyToken(token)
    if (decodedToken) {
      return true // yes persisted sign-in
    }
    // we don't need to clear any cache or anything because that is handled in TokenExpiredModal Component
    return false // not signed in
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hydrated = useMemo(() => {
    if (noHydration) {
      return false
    }
    if (!hydratedToken) {
      return false // not previously signed in
    }
    const {
      firstName,
      lastName,
      colonnadeRoleId,
      colonnadeSubscriptionLevel,
      userId,
      clientName,
      userEmail,
    } = jwtDecode(hydratedToken)
    setAccessToken(hydratedToken)
    setUser({
      firstName,
      lastName,
      roleId: colonnadeRoleId,
      subscriptionLevel: colonnadeSubscriptionLevel,
      userId,
      clientName,
      userEmail,
    })
    setAuthenticated(true)
    return true
  }, [hydratedToken, noHydration])

  const __handleSignOut = (): void => {
    setAccessToken(null)
    setUser({} as UserProps)
    setAuthenticated(false)
  }

  // Prospect added subscription
  const { data: subscriptionData } = useSubscription(
    SUBSCRIPTION_CHANGED_SUBSCRIPTION,
    {
      variables: {
        clientName: user.clientName || '',
        token: hydrationStore.token || '',
      },
    }
  )
  const subscriptionChangedData = subscriptionData?.colonnadeSubscriptionChanged

  useEffect(() => {
    const selectedClient = hydrationStore.selectedClient
    const selectedApp = hydrationStore.selectedApp
    if (selectedClient) {
      dispatch({
        type: AppActionType.SET_SELECTED_CLIENT,
        payload: selectedClient,
      })
    }
    if (selectedApp) {
      dispatch({
        type: AppActionType.SET_SELECTED_APP,
        payload: selectedApp,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (hydrated) {
      __memoizedValidateToken()
    }
  }, [__memoizedValidateToken, hydrated])

  async function __authenticate(
    email: string,
    password: string
  ): Promise<string | null> {
    // Takes current time and adds 12 hours to it.
    document.cookie = `token_expire=${new Date().getTime() + 43200000 + ''}`
    let result = await authClient.query({
      fetchPolicy: 'no-cache',
      query: AUTHENTICATE_QUERY,
      variables: {
        email,
        password,
      },
    })
    const { data } = result
    const token = data.authenticate
    if (!token) {
      setAuthenticated(false)
      return null
    }
    const {
      firstName,
      lastName,
      colonnadeRoleId,
      colonnadeSubscriptionLevel,
      userId,
      clientName,
      userEmail,
    } = jwtDecode(token)
    // set selectedClient
    result = await authClient.query({
      query: CLIENTS_FOR_USER_QUERY,
      variables: { userId },
    })
    const clients = result?.data?.clientsForUser?.clients
    let selectedClient = null
    if (clients) {
      if (clients.length === 1) {
        selectedClient = clients[0]
      } else {
        if (defaultClientName) {
          selectedClient = clients.find(
            (tgt: ClientIdAndName): boolean =>
              tgt.altName.localeCompare(defaultClientName) === 0
          )
        }
      }
    }
    dispatch({
      type: AppActionType.SET_SELECTED_CLIENT,
      payload: selectedClient,
    })

    hydrationStore.token = token // store this token in localStorage
    setAccessToken(token)
    setUser({
      firstName,
      lastName,
      roleId: colonnadeRoleId,
      subscriptionLevel: colonnadeSubscriptionLevel,
      userId,
      clientName,
      userEmail,
    })
    setAuthenticated(true)
    return token
  }

  if (subscriptionChangedData) {
    return (
      <TokenExpiredModal
        message="Your subscription level has been changed by anewgo."
        timer={7000}
      />
    )
  }
  const authProviderValue = {
    ...initialState,
    authenticated,
    user,
    accessToken,
    authenticate: __authenticate,
    isExistingEmail: __isExistingEmail,
    verifyToken: __verifyToken,
    handleSignOut: __handleSignOut,
  }
  return <AuthProvider value={authProviderValue}>{children}</AuthProvider>
}

export default Auth
