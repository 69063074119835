import { gql } from '@apollo/client'

export const cityFieldsMinimal = gql`
  fragment CityFieldsMinimal on CityInfo {
    id
    name
    customName
    region(clientName: $clientName) {
      __typename
      ... on USCityRegionInfo {
        stateCode
        stateName
        metroName
        metroCustomName
        zipCode
      }
      ... on CANCityRegionInfo {
        provinceCode
        provinceName
        districtCode
        districtName
        postCode
      }
    }
  }
`

export const cityFieldsFull = gql`
  fragment CityFieldsFull on CityInfo {
    id
    name
    customName
    region(clientName: $clientName) {
      __typename
      ... on USCityRegionInfo {
        stateCode
        stateName
        countyCode
        countyName
        metroId
        metroName
        metroCustomName
        zipCode
      }
      ... on CANCityRegionInfo {
        provinceCode
        provinceName
        districtCode
        districtName
        postCode
      }
    }
  }
`
