import React from 'react'
import Switch from '@mui/material/Switch/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useToggleAllowMultipleReservationsMutation } from '../../../graphql/gen-types'
import { gql } from '@apollo/client'

interface ReservationIntegrationAllowMultipleReservationsToggleProps {
  reservationIntegrationId: number
  clientId: number
  enabled: boolean
}

export default function ReservationIntegrationAllowMultipleReservationsToggle({
  reservationIntegrationId,
  clientId,
  enabled,
}: ReservationIntegrationAllowMultipleReservationsToggleProps) {
  const [
    toggleMultipleReservationsAllowed,
  ] = useToggleAllowMultipleReservationsMutation()

  const handleToggle = (_: unknown, checked: boolean) => {
    toggleMultipleReservationsAllowed({
      variables: {
        clientId,
        enabled: checked,
      },
      optimisticResponse: {
        toggleAllowMultipleReservations: checked,
      },
      update(cache, result) {
        cache.writeFragment({
          id: `OnlineReservationIntegrationInfo:${reservationIntegrationId}`,
          fragment: gql`
            fragment UpdateAllowMultipleReservations on OnlineReservationIntegrationInfo {
              allowMultipleReservations
            }
          `,
          data: {
            allowMultipleReservations:
              result.data?.toggleAllowMultipleReservations,
          },
        })
      },
    })
  }

  return (
    <FormControlLabel
      control={<Switch />}
      label="Allow Multiple Reservations For Each Lot"
      onChange={handleToggle}
      checked={enabled}
    />
  )
}
