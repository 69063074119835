import { gql } from '@apollo/client'

export const ADD_AGENTS_PROSPECT = gql`
  mutation addAgentsProspect(
    $prospectEmail: String!
    $agentIds: [Int!]!
    $clientName: String!
    $source: String!
  ) {
    colonnadeApiBatchAddAgentProspect(
      prospectEmail: $prospectEmail
      agentIds: $agentIds
      clientName: $clientName
      source: $source
    )
  }
`
