import React, { useContext, useEffect, useMemo, useState } from 'react'
import LiveLeads from 'components/leads/live-leads/LiveLeads'
import ReturningLeads from 'components/leads/lead-rankings/ReturningLeads'
import { AppStore } from 'store'
import { AnalyticsStore } from 'store/analyticsStore'
import {
  CommonQueryFilter,
  Community,
  useGetAgentsQuery,
  Agent,
  DateFilterField,
  LeadScores,
  LeadStatus,
} from '../../graphql/gen-types'
import Grid from '@mui/material/Grid'
import LeadsFilterBar, {
  LeadStatusSelection,
  LeadScoreSelection,
  Selection,
} from 'components/leads/filter-bar/LeadsFilterBar'
import { SubscriptionLevelInvalidNotif } from 'components/utils/SubscriptionLevelInvalidNotif'
import { classes, Root } from './Leads.styles'
import LeadRankings from 'components/leads/lead-rankings/LeadRankings'
import { AuthContext } from '../../auth/AuthContext'
import { isAdminOrAnewgoMember } from '../../utils/authorizationHelpers'
import { useCommonQueryFilter } from '../../components/analytics/locations-filters/hooks/useCommonQueryFilter'
import { LocationSelection } from '../../components/analytics/locations-filters/Shared'
import { useRouteStateLocationSelection } from '../../components/analytics/locations-filters/hooks/useRouteStateLocationSelection'

const initialAgentsState: Agent[] = []

export type LeadsFilter = {
  search: string
  scores?: LeadScores[]
  statuses?: LeadStatus[]
}

function getEnumValues<T>(enums: Selection<T>[]): T[] {
  return enums.map((statusObj: Selection<T>) => statusObj.value)
}

function Leads(): JSX.Element {
  const { appState } = useContext(AppStore)
  const { selectedClient } = appState
  const clientName = selectedClient?.altName
  const { analyticsState } = useContext(AnalyticsStore)
  const { startDate, endDate } = analyticsState

  const { user } = useContext(AuthContext)
  const { roleId } = user
  const isAdminOrAnewgo = isAdminOrAnewgoMember(roleId)

  const { location, routeLocation } = useRouteStateLocationSelection()

  const [selectedCommunities, setSelectedCommunities] = useState<Community[]>(
    location.state?.communities || []
  )
  const [selectedScores, setSelectedScores] = useState<LeadScoreSelection[]>([])
  const [search, setSearch] = useState('')
  const [selectedStatuses, setSelectedStatuses] = useState<
    LeadStatusSelection[]
  >([])

  const [agents, setAgents] = useState(initialAgentsState)
  const [locationSelection, setLocationSelection] = useState<LocationSelection>(
    routeLocation
  )

  const [dateFilterField, setdateFilterField] = useState<DateFilterField>(
    location?.state?.dateFilterField || DateFilterField.LastSeen
  )

  const commonFilter: CommonQueryFilter = useCommonQueryFilter({
    clientName: clientName || '',
    startDate,
    endDate,
    selection: locationSelection,
    limit: 10000,
    communities:
      selectedCommunities.length > 0
        ? selectedCommunities.map((comm: Community) => comm.id)
        : undefined,
  })

  const leadsFilter: LeadsFilter = useMemo(
    () => ({
      scores:
        selectedScores.length > 0
          ? getEnumValues<LeadScores>(selectedScores)
          : undefined,
      search,
      statuses:
        selectedStatuses.length > 0
          ? getEnumValues<LeadStatus>(selectedStatuses)
          : undefined,
    }),
    [selectedScores, search, selectedStatuses]
  )

  // Get client agents
  const agentsQuery = useGetAgentsQuery({
    variables: {
      clientName: selectedClient ? selectedClient.altName : '',
      communityIds: selectedCommunities.map((comm) => comm.id),
    },
  })

  useEffect(() => {
    if (agentsQuery.data) {
      setAgents(agentsQuery.data?.agentsByClient as Agent[])
    }
  }, [agentsQuery.data, setAgents])

  return (
    <>
      <LeadsFilterBar
        onSelectedScores={setSelectedScores}
        onSelectedStatuses={setSelectedStatuses}
        onSearch={setSearch}
        onSetLeadChartSelectedCommunities={(communities) =>
          setSelectedCommunities(communities || [])
        }
        onLocationSelection={setLocationSelection}
      />
      <SubscriptionLevelInvalidNotif />
      <Root className={classes.root}>
        <Grid container className={classes.componentContainer} spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <LiveLeads
              agents={agents}
              search={search}
              selectedStatuses={selectedStatuses}
              isAdmin={isAdminOrAnewgo}
            />
            <ReturningLeads
              agents={agents}
              commonFilter={commonFilter}
              leadsFilter={leadsFilter}
              isAdmin={isAdminOrAnewgo}
            />
            <LeadRankings
              agents={agents}
              commonFilter={commonFilter}
              leadsFilter={leadsFilter}
              dateFilterField={dateFilterField}
              setdateFilterField={setdateFilterField}
              isAdmin={isAdminOrAnewgo}
            />
          </Grid>
        </Grid>
      </Root>
    </>
  )
}

export default Leads
