import React from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { getImagePrefix, getPlanSpecs } from '@anewgo/utils'
import { Plan } from 'graphql/gen-types'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Tooltip from '@mui/material/Tooltip'
import { SortOptions } from 'components/common/custom-inputs/SortSelect'
import { humanTimeDuration } from 'utils/functions'
import { PLAN_SCORE_OUT_OF } from 'constants/index'

const PREFIX = 'PlanCard'

const classes = {
  card: `${PREFIX}-card`,
  metricData: `${PREFIX}-metricData`,
  label: `${PREFIX}-label`,
  data: `${PREFIX}-data`,
}

const StyledCard = styled(Card)(() => ({
  [`&.${classes.card}`]: {
    width: '280px',
    cursor: 'pointer',
  },

  [`& .${classes.metricData}`]: {
    margin: '8px',
  },

  [`& .${classes.label}`]: {
    height: 22,
    fontWeight: 700,
    fontSize: 14,
  },

  [`& .${classes.data}`]: {
    height: 22,
    fontSize: 14,
  },
}))

const MAX_PLANCARD_MEDIA_HT = 200
export interface PlanCardProps {
  plan: Plan
  frontPageMetric: SortOptions
}

export const PlanCard: React.FC<PlanCardProps> = ({
  plan,
  frontPageMetric: metric,
}: PlanCardProps) => {
  const navigate = useNavigate()

  // If plan has no elevations, do not display it.
  if (!plan?.elevations?.length) {
    return null
  }

  // Get elevation
  const elevation = plan.elevations[0]

  // Get plan values
  const { bed, bath, size, cost } = getPlanSpecs([plan])
  const specs = [size, bed, bath].filter((opt) => opt).join(' | ')
  const title = plan?.displayName || plan?.name || ''
  const thumb = elevation?.thumb || ''
  const img = `${getImagePrefix(
    plan.clientName,
    `c_scale,h_${MAX_PLANCARD_MEDIA_HT}`
  )}/${thumb}`

  const frequency = plan?.popularityMetrics?.byFrequency
  const duration = plan?.popularityMetrics?.byDuration?.total || 0
  const engagementMetric =
    plan?.popularityMetrics?.byPopularity?.popularity || 0
  const brochure = plan?.popularityMetrics?.byPopularity?.brochure
  const cta = plan?.popularityMetrics?.byPopularity?.cta
  const favorites = plan?.popularityMetrics?.byBrowsingData?.favorites
  const leadFavorites = plan?.popularityMetrics?.byBrowsingData?.leadFavorites
  const leadViews = plan?.popularityMetrics?.byBrowsingData?.leadViews

  return (
    <StyledCard
      className={classes.card}
      onClick={() => navigate(`../plan?planId=${plan.id}`)}
    >
      <Grid container>
        <Grid container direction="column" alignItems="center">
          <Typography variant="h6">{title}</Typography>
          <Typography variant="caption">{cost || '-'}</Typography>
          <Typography variant="caption" component="h6">
            {specs}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <img src={img} alt="" />
      </Grid>
      <Grid
        container
        direction="row"
        className={classes.metricData}
        alignItems="center"
      >
        <Grid item xs={6}>
          <Grid container direction="column" alignItems="flex-end">
            <div className={classes.label}>Frequency:</div>
            <div className={classes.label}>Duration:</div>
            <div className={classes.label}>
              <Tooltip title="How engaged users are with this plan in relation to others.">
                <span>Engagement Metric</span>
              </Tooltip>
            </div>
            <div className={classes.label}>Brochure Hits:</div>
            <div className={classes.label}>CTA Hits:</div>
            <div className={classes.label}>All Favorites:</div>
            <div className={classes.label}>Lead Favorites:</div>
            <div className={classes.label}>Lead Views:</div>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container direction="column" alignItems="center">
            <div className={classes.data}>{frequency}</div>
            <div className={classes.data}>{humanTimeDuration(duration)}</div>
            <div className={classes.data}>
              {`${Math.floor(engagementMetric * PLAN_SCORE_OUT_OF)} / 100`}
            </div>
            <div className={classes.data}>{brochure}</div>
            <div className={classes.data}>{cta}</div>
            <div className={classes.data}>{favorites}</div>
            <div className={classes.data}>{leadFavorites}</div>
            <div className={classes.data}>{leadViews}</div>
          </Grid>
        </Grid>
      </Grid>
    </StyledCard>
  )
}
