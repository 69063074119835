import React, { Fragment } from 'react'
import FormLabel from '@mui/material/FormLabel'
import {
  Slider as ReactSlider,
  Rail,
  Handles,
  Tracks,
  Ticks,
} from 'react-compound-slider'
import { MuiRail, MuiHandle, MuiTrack, MuiTick } from './MuiSliderHelper'
import { styled } from '@mui/material/styles'

const SliderContainer = styled('div')({
  '& .MuiFormLabel-root': {
    width: '90%',
    height: 80,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
  },
})

const SliderLabel = styled(FormLabel)({
  position: 'absolute',
  left: -6,
  top: -4,
  transform: 'scale(0.75)',
})

type SliderProps = {
  sliderLabel: string
  domain: number[]
  initialValues: number[]
  step?: number
  width?: string | number
  tickCount?: number
  tickValues?: number[]
  mode?: 1 | 2 | 3
  format: (value: number, index: number, count: number) => string
  onChange: (value: number) => void
  formatHandle: (value: number) => string
}

export default function Slider({
  sliderLabel,
  domain,
  initialValues,
  step = 1,
  width,
  onChange,
  format,
  formatHandle,
  tickCount = 4,
  tickValues = [],
  mode,
}: SliderProps): JSX.Element {
  const handleChange = (value: readonly number[]) => {
    onChange(value[0])
  }

  return (
    <Fragment>
      <SliderLabel htmlFor="sqft-slider">{sliderLabel}</SliderLabel>
      <SliderContainer>
        <ReactSlider
          mode={mode}
          step={step}
          domain={domain}
          rootStyle={{
            position: 'absolute',
            width: width || '80%',
            top: 30,
          }}
          onChange={(values) => handleChange(values)}
          values={initialValues}
        >
          <Rail>
            {({ getRailProps }) => <MuiRail getRailProps={getRailProps} />}
          </Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className="slider-handles">
                {handles.map((handle) => (
                  <MuiHandle
                    key={handle.id}
                    handle={handle}
                    formatHandle={formatHandle}
                    domain={domain}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <MuiTrack
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
          <Ticks count={tickCount} values={tickValues}>
            {({ ticks }) => (
              <div className="slider-ticks">
                {ticks.map((tick, index) => (
                  <MuiTick
                    index={index}
                    key={tick.id}
                    tick={tick}
                    count={ticks.length}
                    format={format}
                  />
                ))}
              </div>
            )}
          </Ticks>
        </ReactSlider>
      </SliderContainer>
    </Fragment>
  )
}
