import React, {
  useState,
  useContext,
  useRef,
  useMemo,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react'
import { styled } from '@mui/material/styles'
import { useAtom } from 'jotai'

import {
  LeadRanking,
  Community,
  Agent,
  DateFilterField,
  ColonnadeSubscriptionLevelsEnum,
  OrderBy,
  Order,
  Pagination,
  LeadFilter,
  LeadsQueryFilter,
  CommonQueryFilter,
  LeadsFilter,
  useLeadRankingsQuery,
} from 'graphql/gen-types'

import ComponentCard from '../../common/layout/ComponentCard'
import { Unauthorized } from 'components/auth/Unauthorized'
import {
  userHasSubscriptionAccess,
  verifyUserAuthError,
} from 'utils/authorizationHelpers'
import LeadRankingTable from './LeadRankingTable'
import { ApolloError } from '@apollo/client'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import InfoIcon from '@mui/icons-material/Info'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Radio from '@mui/material/Radio'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Switch from '@mui/material/Switch'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import { SubscriptionTooltip } from 'components/icons/SubscriptionTooltip'
import {
  BRANDING_COLOR_DARK_MODE_YELLOW,
  BRANDING_COLOR_PASTEL_YELLOW,
  STARTER_INVALID_FEATURE_MESSAGE,
} from '../../../constants'
import { AuthContext } from 'auth/AuthContext'
import { snackbarConfigAtom } from 'store/atoms'
import { LeadsStore } from '../../../store/leadsStore'
import { AppActionType } from '../../../store/reducers'
import { AnalyticsStore } from '../../../store/analyticsStore'

const PREFIX = 'LeadRankingsDisplay'

const classes = {
  title: `${PREFIX}-title`,
  alert: `${PREFIX}-alert`,
}

const LeadRankingsDisplayStyled = styled(ComponentCard)(({ theme }) => ({
  [`& .${classes.title}`]: {
    fontWeight: 500,
    padding: 16,
    marginLeft: 8,
    textAlign: 'left',
  },

  [`& .${classes.alert}`]: {
    fontSize: '1rem',
    backgroundColor:
      theme.palette.mode === 'dark'
        ? BRANDING_COLOR_DARK_MODE_YELLOW
        : BRANDING_COLOR_PASTEL_YELLOW,
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.light
        : theme.palette.common.black,
  },
}))

export const isValidLead = (lead: LeadRanking) => {
  if (!lead?.prospectId) {
    return false
  }

  return true
}

const COMPONENT_TITLE = 'Active Leads'

interface LeadRankingsProps {
  agents: Agent[]
  commonFilter: CommonQueryFilter
  leadsFilter: LeadsFilter
  dateFilterField: DateFilterField
  setdateFilterField: Dispatch<SetStateAction<DateFilterField>>
  isAdmin?: boolean
}

const initialOrderBy: OrderBy = {
  order: Order.Desc,
  sortBy: 'lastSeen',
}

const noHighValueLeadsMessage =
  'None of the displayed leads is considered high value.'

function LeadRankingsDisplay({
  agents,
  commonFilter,
  leadsFilter,
  dateFilterField,
  setdateFilterField,
  isAdmin,
}: LeadRankingsProps): JSX.Element {
  const { leadsState, dispatch } = useContext(LeadsStore)
  const { analyticsState } = useContext(AnalyticsStore)
  const defaultActiveFilter =
    leadsState?.defaultLeadFilters?.activeLeadsFilter ||
    (isAdmin ? LeadFilter.None : LeadFilter.MyLeadsOnly)
  const { startDate, endDate } = analyticsState
  const [snackbarConfig, setSnackbarConfig] = useAtom(snackbarConfigAtom)
  const [csv, setCsv] = useState<string>('')
  const [showAllTimeLeads, setShowAllTimeLeads] = useState(false)
  const [highlightLeads, setHighlightLeads] = useState<boolean>(false)
  const [openMenu, setOpenMenu] = useState(false)
  const { user } = useContext(AuthContext)
  const buttonRef = useRef(null)

  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down('sm'))
  const PRO_OR_ENTERPRISE_SUBSCRIBED = userHasSubscriptionAccess([
    ColonnadeSubscriptionLevelsEnum.Pro,
    ColonnadeSubscriptionLevelsEnum.Enterprise,
  ])

  const [orderBy, setOrderBy] = useState<OrderBy>(initialOrderBy)
  const [pagination, setPagination] = useState<Pagination>({
    limit: 10,
    offset: 0,
  })

  const [leadFilter, setLeadFilter] = useState<LeadFilter>(defaultActiveFilter)

  const filter: LeadsQueryFilter = useMemo(
    () => ({
      common: commonFilter,
      leads: {
        ...leadsFilter,
        lead: leadFilter,
        dateFilterField,
        allTimeLeads: showAllTimeLeads,
      },
    }),
    [commonFilter, leadsFilter, dateFilterField, leadFilter, showAllTimeLeads]
  )

  // Fetch LeadRankings
  const { data, loading, error, refetch } = useLeadRankingsQuery({
    variables: {
      filter,
      pagination,
      orderBy,
    },
  })

  useEffect(() => {
    refetch()
  }, [orderBy, pagination, filter])

  const { leads, totalCount } = data?.leadRankings || { totalCount: 0 }

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized
          message={error.toString()}
          imageName={'LeadRankings'}
        ></Unauthorized>
      )
    }
    console.error(error)
    return <div>Error while loading lead rankings</div>
  }

  const exportCsv = () => {
    const encodedUri = encodeURI(csv)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'my_leads.csv')
    link.click()
    link.remove()
  }

  const triggerSnackbar = (
    message: string,
    severity: 'error' | 'success' | 'info' | 'warning' | undefined
  ) => {
    setSnackbarConfig({
      ...snackbarConfig,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      autoHideDuration: 6000,
      open: true,
      message,
      severity,
    })
  }

  const header: JSX.Element = (
    <Grid
      container
      alignItems="center"
      justifyContent={xs ? 'space-evenly' : 'flex-end'}
      spacing={1}
    >
      <Grid item lg={3}>
        <Typography className={classes.title} variant="h5" color="primary">
          {COMPONENT_TITLE}
        </Typography>
      </Grid>
      <Grid item lg={3} style={{ position: 'relative' }}>
        <FormControlLabel
          control={
            <Switch
              checked={highlightLeads}
              color="primary"
              disabled={!PRO_OR_ENTERPRISE_SUBSCRIBED}
              onChange={() => {
                const highValueLeads = leads?.filter(
                  (lead) => lead.hotLead && isValidLead(lead)
                )
                if (
                  user.subscriptionLevel === 'PRO' ||
                  user.subscriptionLevel === 'ENTERPRISE'
                ) {
                  if (!highValueLeads || highValueLeads?.length === 0) {
                    triggerSnackbar(noHighValueLeadsMessage, 'info')
                    setHighlightLeads(!highlightLeads)
                    return
                  }
                  if (highlightLeads) {
                    triggerSnackbar('High value leads were hidden.', 'info')
                  } else {
                    triggerSnackbar(
                      `${highValueLeads.length} high value lead${
                        highValueLeads.length > 1 ? 's were' : ' was'
                      } highlighted!`,
                      'info'
                    )
                  }
                  setHighlightLeads(!highlightLeads)
                } else {
                  triggerSnackbar(
                    'This is a pro-level/enterprise-level feature',
                    'info'
                  )
                }
              }}
            />
          }
          label="Highlight high value leads"
        />
        {!PRO_OR_ENTERPRISE_SUBSCRIBED && (
          <SubscriptionTooltip
            iconStyle={'highlightValueLeadsIcon'}
            message={STARTER_INVALID_FEATURE_MESSAGE}
          />
        )}
      </Grid>
      <Grid item lg={3}>
        <Button variant="contained" color="primary" onClick={() => exportCsv()}>
          Export csv leads
        </Button>
      </Grid>
      <Grid item lg={3} style={{ display: 'flex' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenMenu(true)}
          ref={buttonRef}
          style={{ marginLeft: 'auto' }}
        >
          Leads Filters
        </Button>
        <Menu
          style={{ marginTop: 65 }}
          open={openMenu}
          keepMounted
          onClose={() => setOpenMenu(false)}
          anchorEl={buttonRef.current ? buttonRef.current : null}
        >
          <div style={{ padding: '5px 20px 10px 20px' }}>
            <MenuItem>
              <Tooltip
                title={'Show leads with no recent browsing data.'}
                placement="top-end"
              >
                <span>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showAllTimeLeads}
                        onChange={() => {
                          setOpenMenu(false)
                          setShowAllTimeLeads((prev) => !prev)
                        }}
                        name="showLeadsNotInBQ"
                      />
                    }
                    label="Show All-Time Leads"
                  />
                  <InfoIcon color="primary" />
                </span>
              </Tooltip>
            </MenuItem>
            <MenuItem>
              <FormControl fullWidth>
                <InputLabel id="returning-leads-filter">Lead Filter</InputLabel>
                <Select
                  labelId="returning-leads-filter"
                  label="Lead Filter"
                  value={leadFilter}
                  onChange={(event) => {
                    setLeadFilter(event.target.value)
                    setOpenMenu(false)
                    dispatch({
                      type: AppActionType.SET_ACTIVE_LEADS_FILTER,
                      payload: event.target.value,
                    })
                  }}
                >
                  <MenuItem value={LeadFilter.None}>None</MenuItem>
                  <MenuItem value={LeadFilter.Unassigned}>
                    Unassigned Leads
                  </MenuItem>
                  <MenuItem value={LeadFilter.MyLeadsOnly}>
                    My Leads Only
                  </MenuItem>
                </Select>
              </FormControl>
            </MenuItem>
            {setdateFilterField && dateFilterField && (
              <fieldset>
                <legend>Date Filter Field</legend>
                <MenuItem>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={dateFilterField === 'last_seen'}
                        onChange={() => {
                          setOpenMenu(false)
                          if (dateFilterField === 'first_seen') {
                            setdateFilterField(DateFilterField.LastSeen)
                          } else {
                            setdateFilterField(DateFilterField.FirstSeen)
                          }
                        }}
                      />
                    }
                    label="Filter by Last Seen"
                  />
                </MenuItem>
                <MenuItem>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={dateFilterField === 'first_seen'}
                        onChange={() => {
                          setOpenMenu(false)
                          if (dateFilterField === 'first_seen') {
                            setdateFilterField(DateFilterField.LastSeen)
                          } else {
                            setdateFilterField(DateFilterField.FirstSeen)
                          }
                        }}
                      />
                    }
                    label="Filter by Registered"
                  />
                </MenuItem>
              </fieldset>
            )}
          </div>
        </Menu>
      </Grid>
    </Grid>
  )

  // Return component with data
  return (
    <LeadRankingsDisplayStyled
      title={header}
      subtitle="Leads with engagement analysis"
      result={{ data: leads, loading, error }}
    >
      <LeadRankingTable
        data={leads || []}
        agents={agents}
        setCsv={setCsv}
        highlightLeads={highlightLeads}
        pagination={pagination}
        setPagination={setPagination}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        dataTotalCount={totalCount}
      />
    </LeadRankingsDisplayStyled>
  )
}

export default LeadRankingsDisplay
