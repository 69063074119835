import {
  ANEWGO_ADMIN_ROLE_ID,
  ANEWGO_STAFF_ROLE_ID,
  ADMIN_ROLE_ID,
  SALES_ADMIN_ROLE_ID,
} from '../constants'
import jwtDecode from 'jwt-decode'
import hydrationStore from 'store/HydrationStore'
import {
  ColonnadeRolePrivilegesEnum,
  ColonnadeSubscriptionLevelsEnum,
} from 'graphql/gen-types'

export const UNAUTHORIZED_KEYWORDS = [
  'Insufficient subscription level',
  'Insufficient privileges',
  'Insufficient authorization',
  'Insufficient Role level',
  'Not enough permission to access the data',
]

// verify if the error is because of insuficient subscription/privilege
export const verifyUserAuthError = (error: string): boolean => {
  for (const keyword of UNAUTHORIZED_KEYWORDS) {
    if (error.includes(keyword)) {
      return true
    }
  }
  return false
}

export const userHasSubscriptionAccess = (
  requiredSubscriptionLevel: ColonnadeSubscriptionLevelsEnum[]
): boolean => {
  const { colonnadeSubscriptionLevel } = jwtDecode(hydrationStore.token || '')

  if (colonnadeSubscriptionLevel) {
    return requiredSubscriptionLevel.includes(
      colonnadeSubscriptionLevel.toUpperCase()
    )
  } else {
    throw new Error('colonnadeSubscriptionLevel is not found within the token')
  }
}

export const userHasPrivilegeAccess = (
  requiredPrivileges: ColonnadeRolePrivilegesEnum[]
): boolean => {
  const { colonnadePrivileges } = jwtDecode(hydrationStore.token || '') as {
    colonnadePrivileges: ColonnadeRolePrivilegesEnum[] | undefined
  }

  if (colonnadePrivileges) {
    return colonnadePrivileges.some((actualPrivilege) =>
      requiredPrivileges.some(
        (requiredPrivilege) => actualPrivilege === requiredPrivilege
      )
    )
  } else {
    throw new Error('colonnadePrivileges is not found within the token')
  }
}

export const getUnauthorizedAccessReason = (
  requiredPrivileges: ColonnadeRolePrivilegesEnum[] | null,
  requiredSubscriptionLevel: ColonnadeSubscriptionLevelsEnum[] | null
): string | null => {
  if (
    requiredSubscriptionLevel &&
    !userHasSubscriptionAccess(requiredSubscriptionLevel)
  ) {
    return `Subscription level of ${[
      requiredSubscriptionLevel.slice(0, -1),
      requiredSubscriptionLevel[requiredSubscriptionLevel.length - 1],
    ]
      .filter(Boolean)
      .join(
        ' or '
      )} is required for this feature. Please contact us for more information.`
  }

  if (requiredPrivileges && !userHasPrivilegeAccess(requiredPrivileges)) {
    return 'Insufficient permissions for this feature. Please contact an Anewgo admin to get access.'
  }

  return null
}

export const isAnewgoMember = (roleId: number): boolean => {
  return roleId === ANEWGO_ADMIN_ROLE_ID || roleId === ANEWGO_STAFF_ROLE_ID
}

export const isAdminOrAnewgoMember = (roleId: number): boolean => {
  return (
    roleId === ANEWGO_ADMIN_ROLE_ID ||
    roleId === ANEWGO_STAFF_ROLE_ID ||
    roleId === ADMIN_ROLE_ID ||
    roleId === SALES_ADMIN_ROLE_ID
  )
}

export const isSalesAdmin = (roleId: number): boolean => {
  return roleId === SALES_ADMIN_ROLE_ID
}

export const userSubscriptionHasAccess = (
  userSubscription: ColonnadeSubscriptionLevelsEnum,
  requiredSubscriptions: ColonnadeSubscriptionLevelsEnum[]
): boolean => {
  return requiredSubscriptions.includes(userSubscription)
}
