import { gql } from '@apollo/client'

export const SEND_FORGOT_EMAIL_NOTIF_RETRIEVE = gql`
  mutation sendForgotEmailNotifRetrieve(
    $firstName: String!
    $lastName: String!
    $backUpEmail: String!
    $clientName: String!
    $email: String!
  ) {
    sendForgotEmailNotifRetrieve(
      firstName: $firstName
      lastName: $lastName
      backUpEmail: $backUpEmail
      email: $email
      clientName: $clientName
    )
  }
`

export const SEND_FORGOT_EMAIL_NOTIF_TO_ADMIN = gql`
  mutation sendForgotEmailNotifToAdmin(
    $firstName: String!
    $lastName: String!
    $backUpEmail: String!
  ) {
    sendForgotEmailNotifToAdmin(
      firstName: $firstName
      lastName: $lastName
      backUpEmail: $backUpEmail
    )
  }
`
