import { styled } from '@mui/material/styles'

const PREFIX = 'MarketingAutomation'

export const classes = {
  componentContainer: `${PREFIX}-componentContainer`,
  home: `${PREFIX}-home`,
}

export const Root = styled('div')(({ theme }) => ({
  [`& .${classes.componentContainer}`]: {
    justifyContent: 'center',
    marginTop: '1em',
    padding: '2em',
    [theme.breakpoints.down('md')]: {
      padding: '0em',
    },
  },

  [`&.${classes.home}`]: {
    overflowY: 'scroll',
    height: 'calc(100vh - 56px)', // seems to be
  },
}))
