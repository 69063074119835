import React from 'react'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import InfoIcon from '@mui/icons-material/Info'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar'
import SnackbarContent from '@mui/material/SnackbarContent'
import WarningIcon from '@mui/icons-material/Warning'

import { green, amber } from '@mui/material/colors'

const PREFIX = 'Message'

const classes = {
  success: `${PREFIX}-success`,
  error: `${PREFIX}-error`,
  info: `${PREFIX}-info`,
  warning: `${PREFIX}-warning`,
  icon: `${PREFIX}-icon`,
  iconVariant: `${PREFIX}-iconVariant`,
  message: `${PREFIX}-message`,
}

const SnackbarStyled = styled(Snackbar)(({ theme }) => ({
  [`& .${classes.success}`]: {
    backgroundColor: green[600],
  },

  [`& .${classes.error}`]: {
    backgroundColor: theme.palette.error.main,
  },

  [`& .${classes.info}`]: {
    backgroundColor: theme.palette.primary.main,
  },

  [`& .${classes.warning}`]: {
    backgroundColor: amber[700],
  },

  [`& .${classes.icon}`]: {
    fontSize: 20,
  },

  [`& .${classes.iconVariant}`]: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },

  [`& .${classes.message}`]: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

type MessageProps = {
  anchorClassName?: string
  anchorOrigin: SnackbarOrigin | undefined
  open: boolean
  autoHideDuration: number | null | undefined
  onClose: () => void
  variant: 'success' | 'warning' | 'error' | 'info'
  message: string
}
function Message({
  anchorClassName,
  anchorOrigin,
  open,
  autoHideDuration,
  onClose,
  variant,
  message,
}: MessageProps): JSX.Element {
  const Icon = variantIcon[variant]

  const handleClose = () => {
    onClose()
  }

  return (
    <SnackbarStyled
      className={anchorClassName || 'snackbar'}
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <SnackbarContent
        className={classes[variant]}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
            size="large"
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </SnackbarStyled>
  )
}

export default Message
