import ComponentCard from 'components/common/layout/ComponentCard'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { AppStore } from 'store'
import { AnalyticsStore } from 'store/analyticsStore'
import {
  CommonQueryFilter,
  Community,
  Elevation,
  LeadByProduct,
  Lot,
  PaginatedPlansQueryVariables,
  Plan,
  ProductFilter,
  ProspectStatus,
  useCommunitiesQuery,
  useLeadsByProductQuery,
  usePaginatedInventoryLotsQuery,
  usePaginatedPlansQuery,
} from '../../../graphql/gen-types'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import SelectCommunityModal from './SelectCommunityModal'
import SelectProductModal, { PlanProduct } from './SelectProductModal'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import LeadsByProductTable from './components/LeadsByProductTable'
import DatePicker from '@mui/lab/DatePicker'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { getProspectTag, isDefined } from 'utils/functions'
import EmailCompositionModal from './modals/EmailCompositionModal'
import SelectProspectStatus from 'components/common/custom-inputs/SelectProspectStatus'
interface LeadsByProductProps {
  filter: CommonQueryFilter
}

const COMPONENT_TITLE = 'Find Leads by Product'
const LEAD_SCORE_OUT_OF = 100
const SIX_MONTHS_MILLIS = 1000 * 60 * 60 * 24 * 30 * 6 // 6 Months ago in milliseconds.
const initialLastSeen = new Date(new Date().getTime() - SIX_MONTHS_MILLIS)

export default function LeadsByProduct({
  filter,
}: LeadsByProductProps): JSX.Element {
  const { appState } = useContext(AppStore)
  const { analyticsState } = useContext(AnalyticsStore)
  const { selectedClient } = appState
  const clientName = selectedClient?.altName || ''
  const { startDate, endDate } = analyticsState

  /* Modals Open State */
  const [selectProductOpen, setSelectProductOpen] = useState(false)
  const [selectCommunityOpen, setSelectCommunityOpen] = useState(false)
  const [createCampaignOpen, setCreateCampaignOpen] = useState(false)

  /* Selected State */
  const [leadsByProduct, setLeadsByProduct] = useState<LeadByProduct[]>([])
  const [selectedLeads, setSelectedLeads] = useState<LeadByProduct[]>([])
  const [selectedCommunities, setSelectedCommunities] = useState<Community[]>(
    []
  )
  const [selectedPlanProducts, setSelectedPlanProducts] = useState<
    PlanProduct[]
  >([])
  const [selectedInventoryProducts, setSelectedInventoryProducts] = useState<
    Lot[]
  >([])
  const [options, setOptions] = useState<ProductFilter>({})
  const [searchCommunity, setSearchCommunity] = useState('')
  const [searchPlan, setSearchPlan] = useState('')

  /* Lead Filtering */
  const [searchUser, setSearchUser] = useState('')
  const [selectStatus, setSelectStatus] = useState(ProspectStatus.New)
  const [mininumEngagement, setMinimumEngagement] = useState<number>()
  const [mininumMetric, setMinimumMetric] = useState<number>()
  const [lastSeen, setLastSeen] = useState<Date>(initialLastSeen)

  // Fetch Communities
  const communitiesResult = useCommunitiesQuery({
    variables: {
      clientName,
    },
  })
  const communities =
    (communitiesResult.data?.communities?.filter(isDefined) as Community[]) ||
    []
  const communityIds = communities.map((comm) => comm.id)

  // Paginated params for plans and inventories
  const paginatedParams = {
    clientName,
    communityIds,
    limit: 1000,
    offset: 0,
    filter: {},
  }
  const paginatedPlansArgs: PaginatedPlansQueryVariables = {
    ...paginatedParams,
    sortBy: 'name',
    commonFilter: {
      startTime: startDate, // dateFns.format(startDate, 'MM-dd-yyyy-HH:mm:ss'),
      endTime: endDate, // dateFns.format(endDate, 'MM-dd-yyyy-HH:mm:ss'),
      clientName: clientName,
    },
    selectedMetrics: ['popularity', 'duration', 'frequency'],
    sortOrder: 'ASC',
  }

  // Fetch plans
  const paginatedPlansResult = usePaginatedPlansQuery({
    variables: { ...paginatedPlansArgs },
  })
  const plans =
    (paginatedPlansResult.data?.paginatedPlans?.plans?.filter(
      isDefined
    ) as Plan[]) || []
  // Fetch inventories
  const getPaginatedInventoriesLotResult = usePaginatedInventoryLotsQuery({
    variables: {
      ...paginatedParams,
      sortBy: 'inventory_price',
    },
  })
  const inventoriesResult =
    getPaginatedInventoriesLotResult.data?.paginatedInventoryLots?.lots || []
  const inventories = inventoriesResult.filter(
    (inv): inv is Lot => inv !== undefined && inv !== null
  )
  const selectedPlans = useMemo(
    () => [
      ...selectedPlanProducts.map((product) => product.plan),
      ...selectedInventoryProducts
        .map((lot) => lot.inventory?.plan)
        .filter(isDefined),
    ],
    [selectedInventoryProducts, selectedPlanProducts]
  )
  // Get Inventory elevations
  let selectedElevations: Elevation[] = [
    ...selectedInventoryProducts
      .map((lot) => lot.inventory?.elevation)
      .filter(isDefined),
  ]
  // Concat Product elevations
  selectedPlanProducts.forEach(
    (product) =>
      (selectedElevations = selectedElevations.concat(product.elevations))
  )
  /* Selection ids for query */
  const selectedPlanIds = selectedPlans?.map((plan) => plan.id)
  const selectedElevationIds = selectedElevations.length
    ? selectedElevations?.map((elev) => elev.id)
    : undefined
  const selectedComunityIds = selectedCommunities.length
    ? selectedCommunities.map((comm) => comm.id)
    : undefined

  // Fetch Leads
  const { data, error, loading } = useLeadsByProductQuery({
    variables: {
      clientName,
      filter: {
        common: {
          ...filter,
          planId: selectedPlanIds?.length ? selectedPlanIds : [0],
          communityIdCollection: selectedComunityIds,
          elevationIdCollection: selectedElevationIds,
        },
        product: {
          ...options,
        },
      },
    },
  })

  useEffect(() => {
    if (data?.leadsByProduct) {
      setLeadsByProduct(
        (data?.leadsByProduct.filter(
          (lead) => lead.prospect?.receiveCampaignEmails
        ) || []) as LeadByProduct[]
      )
    }
  }, [data])

  /* Handle Modal state */
  const handleCreateCampaignOpen = () => {
    setCreateCampaignOpen(true)
  }
  const handleCreateCampaignClose = () => {
    setCreateCampaignOpen(false)
  }
  const handleSelectCommunityOpen = () => {
    setSelectCommunityOpen(true)
  }
  const handleSelectCommunityClose = () => {
    setSelectCommunityOpen(false)
  }
  const handleSelectProductOpen = () => {
    setSelectProductOpen(true)
  }
  const handleSelectProductClose = () => {
    setSelectProductOpen(false)
  }
  const handleSelectCommunitySubmit = (
    communities: Community[],
    communityOptions: ProductFilter
  ) => {
    setSelectedCommunities(communities)
    setOptions({ ...options, ...communityOptions })
    handleSelectCommunityClose()
  }

  const handleSelectProductSubmit = (
    planProducts: PlanProduct[],
    inventoryProducts: Lot[],
    productOptions: ProductFilter
  ) => {
    setSelectedPlanProducts(planProducts)
    setSelectedInventoryProducts(inventoryProducts)
    setOptions({ ...options, ...productOptions })
    handleSelectProductClose()
  }

  /* Handle lead search state */
  const handleSearchUserChange = (user: string) => {
    setSearchUser(user)
  }
  const handleMinimumEngagementChange = (engagement: string) => {
    const num = parseFloat(engagement)
    const newEngagement = isNaN(num) ? undefined : num
    setMinimumEngagement(newEngagement)
  }
  const handleMinimumMetricChange = (metric: string) => {
    const num = parseFloat(metric)
    const newMetric = isNaN(num) ? undefined : num
    setMinimumMetric(newMetric)
  }
  const handleSelectLeadChange = (leads: LeadByProduct[]) => {
    setSelectedLeads(leads)
  }
  const handleLastSeenChange = (date: MaterialUiPickersDate) => {
    if (date === null) {
      return
    }
    setLastSeen(date)
  }

  const communitySelected = selectedCommunities.length > 0
  const productSelected = communitySelected || selectedPlanIds?.length

  const optName = ['Beds', 'Baths', 'SQFT', 'Price']
  const productOptionSelection = [
    options.bedRange,
    options.bathRange,
    options.sizeRange,
    options.costRange,
  ]
    .map((opt, i) => (opt ? `+/- ${opt} ${optName[i]}` : undefined))
    .filter((opt) => opt)

  useEffect(() => {
    if (!selectedCommunities.length) {
      setSearchCommunity('No Selection')
      return
    }

    let searchTerm = selectedCommunities.map((comm) => comm.name).join(', ')
    if (
      selectedCommunities.length &&
      !options.communityMatch &&
      options.distanceRange
    ) {
      searchTerm += `, and any within ${options.distanceRange} miles.`
    }
    setSearchCommunity(searchTerm)
  }, [options.communityMatch, options.distanceRange, selectedCommunities])

  useEffect(() => {
    if (!selectedPlans.length) {
      setSearchPlan('No Selection')
      return
    }
    let searchTerm = selectedPlans
      .map((plan) => plan.displayName || plan.name)
      .join(', ')

    if (selectedPlans?.length && !options?.planMatch) {
      searchTerm += `
      Including similar plans${productOptionSelection.length ? ':' : ''}
      ${productOptionSelection.join(', ')}`
    }
    //     <span style={{ whiteSpace: 'pre-line' }}>
    //     </span>

    setSearchPlan(searchTerm)
  }, [options?.planMatch, productOptionSelection, selectedPlans])

  function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0')
  }

  function formatDate(date: Date) {
    return [
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
      date.getFullYear(),
    ].join('/')
  }
  // Filter users
  const isSearchUser = (lead: LeadByProduct) =>
    getProspectTag(lead?.prospect)
      .toLowerCase()
      .includes(searchUser.toLowerCase()) ||
    getProspectTag(lead?.user).toLowerCase().includes(searchUser.toLowerCase())
  const isSelectStatus = (lead: LeadByProduct) =>
    lead?.prospect?.status === selectStatus
  const isMinEngagement = (lead: LeadByProduct) =>
    (lead?.score || 0) * LEAD_SCORE_OUT_OF >= (mininumEngagement || 0)
  const isMinMetric = (lead: LeadByProduct) =>
    (lead.duration || 0) >= (mininumMetric || 0) * 60
  const isRecent = (lead: LeadByProduct) => {
    return (
      (formatDate(new Date(lead?.lastSeen)) || 0) >=
        formatDate(new Date(lastSeen.getTime())) || 0
    )
  }

  const filterSortLeads = () =>
    leadsByProduct.filter(
      (lead) =>
        isSearchUser(lead) &&
        isSelectStatus(lead) &&
        isMinEngagement(lead) &&
        isMinMetric(lead) &&
        isRecent(lead)
    )

  const displayLeads = filterSortLeads()
  return (
    <>
      <SelectCommunityModal
        communities={communities}
        selectedCommunities={selectedCommunities}
        open={selectCommunityOpen}
        onClose={handleSelectCommunityClose}
        onSubmit={handleSelectCommunitySubmit}
      />
      <SelectProductModal
        plans={plans}
        inventories={inventories}
        selectedPlanProducts={selectedPlanProducts}
        open={selectProductOpen}
        onClose={handleSelectProductClose}
        onSubmit={handleSelectProductSubmit}
      />
      <EmailCompositionModal
        selectedLeads={selectedLeads}
        searchTerms={{ community: searchCommunity, plan: searchPlan }}
        open={createCampaignOpen}
        onClose={handleCreateCampaignClose}
      />
      <ComponentCard
        title={COMPONENT_TITLE}
        subtitle={
          'Find a cohort of leads by their interest in a specific product or a range of products.'
        }
        result={{ error, loading }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Paper>
                  <Box p={1}>
                    <Typography variant="body1">Communities</Typography>
                    <Box p={1}>
                      <strong>{searchCommunity}</strong>
                    </Box>
                    <Grid container justifyContent="center" alignItems="center">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSelectCommunityOpen}
                      >
                        Select
                      </Button>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper>
                  <Box p={1}>
                    <Typography>Plans</Typography>
                    <Box p={1}>
                      <strong>{searchPlan}</strong>
                    </Box>
                    <Grid container justifyContent="center">
                      <Tooltip
                        title={`${
                          !communitySelected
                            ? 'Please first select a community.'
                            : ''
                        }`}
                      >
                        <Box>
                          {/* This box is needed to have the tooltip */}
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSelectProductOpen}
                            disabled={!communitySelected}
                          >
                            Select
                          </Button>
                        </Box>
                      </Tooltip>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {!productSelected ? (
              <Paper>
                <Box p={3}>
                  <Typography variant="h6">
                    Select a product to view leads.
                  </Typography>
                </Box>
              </Paper>
            ) : (
              <Paper style={{ padding: '1em' }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h5" color="primary">
                      Select Leads
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2} justifyContent="space-evenly">
                      <Grid item>
                        <TextField
                          variant="standard"
                          label="Email / Name"
                          value={searchUser}
                          onChange={(e) =>
                            handleSearchUserChange(e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item>
                        <SelectProspectStatus onSelect={setSelectStatus} />
                      </Grid>
                      <Grid item>
                        <TextField
                          variant="standard"
                          label="Min. Score"
                          value={
                            mininumEngagement !== undefined
                              ? mininumEngagement
                              : ''
                          }
                          type="number"
                          onChange={(e) =>
                            handleMinimumEngagementChange(e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          variant="standard"
                          style={{ minWidth: '200px' }}
                          label="Min. Duration (minutes)"
                          value={
                            mininumMetric !== undefined ? mininumMetric : ''
                          }
                          type="number"
                          onChange={(e) =>
                            handleMinimumMetricChange(e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item>
                        <DatePicker
                          label="Last Seen"
                          inputFormat="MM/dd/yyyy"
                          showToolbar
                          value={lastSeen}
                          InputAdornmentProps={{ position: 'start' }}
                          maxDate={new Date()}
                          onChange={handleLastSeenChange}
                          renderInput={(params) => (
                            <TextField variant="standard" {...params} />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <LeadsByProductTable
                      leads={displayLeads}
                      selectedLeads={selectedLeads}
                      onChange={handleSelectLeadChange}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box p={3}>
                      {!selectedLeads?.length
                        ? 'No Leads Selected'
                        : selectedLeads.map((lead) => lead.user).join(', ')}
                    </Box>
                    {selectedLeads?.length ? (
                      <Grid container justifyContent="flex-end">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleCreateCampaignOpen}
                        >
                          Create Campaign
                        </Button>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              </Paper>
            )}
          </Grid>
        </Grid>
      </ComponentCard>
    </>
  )
}
