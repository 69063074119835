import Tooltip from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import React from 'react'
import { useAtom } from 'jotai'
import LockIcon from '@mui/icons-material/Lock'
import { snackbarConfigAtom } from 'store/atoms'

const PREFIX = 'SubscriptionTooltip'

const classes = {
  industryTrendsIcon: `${PREFIX}-industryTrendsIcon`,
  byLocationIcon: `${PREFIX}-byLocationIcon`,
  byPlanIcon: `${PREFIX}-byPlanIcon`,
  marketingAutomationIcon: `${PREFIX}-marketingAutomationIcon`,
  salesCenterIcon: `${PREFIX}-salesCenterIcon`,
  openDetailsPageIcon: `${PREFIX}-openDetailsPageIcon`,
  highlightValueLeadsIcon: `${PREFIX}-highlightValueLeadsIcon`,
  suggestPlansIcon: `${PREFIX}-suggestPlansIcon`,
}

const makeItClickableInWidth = {
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'flex-end',
}

const hoverFeature = {
  '&:hover': {
    color: 'skyblue',
    cursor: 'pointer',
  },
}

const AnalyticsNavCommonStyle = {
  ...makeItClickableInWidth,
  height: '26px',
  opacity: 0.5,
  position: 'absolute',
  right: '6px',
  ...hoverFeature,
}

const StyledDiv = styled('div')(() => ({
  [`&.${classes.industryTrendsIcon}`]: {
    ...AnalyticsNavCommonStyle,
    top: '17px',
  },

  [`&.${classes.byLocationIcon}`]: {
    ...AnalyticsNavCommonStyle,
    top: '8px',
  },

  [`&.${classes.byPlanIcon}`]: {
    ...AnalyticsNavCommonStyle,
    top: '55px',
  },

  [`&.${classes.marketingAutomationIcon}`]: {
    ...AnalyticsNavCommonStyle,
    top: '30px',
  },

  [`&.${classes.salesCenterIcon}`]: {
    ...AnalyticsNavCommonStyle,
    top: '105px',
  },

  [`&.${classes.openDetailsPageIcon}`]: {
    position: 'absolute',
    top: '5px',
    left: 0,
    right: 0,
    margin: 'auto',
    ...hoverFeature,
    ...makeItClickableInWidth,
    justifyContent: 'center',
  },

  [`&.${classes.highlightValueLeadsIcon}`]: {
    position: 'absolute',
    top: '9px',
    margin: 'auto',
    ...hoverFeature,
    ...makeItClickableInWidth,
    justifyContent: 'center',
    maxWidth: '250px',
  },

  [`&.${classes.suggestPlansIcon}`]: {
    position: 'absolute',
    top: '16px',
    margin: 'auto',
    ...hoverFeature,
    ...makeItClickableInWidth,
    justifyContent: 'center',
  },
}))

interface SubscriptionTooltipProps {
  message: string
  iconStyle:
    | 'industryTrendsIcon'
    | 'byLocationIcon'
    | 'byPlanIcon'
    | 'marketingAutomationIcon'
    | 'salesCenterIcon'
    | 'openDetailsPageIcon'
    | 'highlightValueLeadsIcon'
    | 'suggestPlansIcon'
}

export const SubscriptionTooltip = ({
  message,
  iconStyle,
}: SubscriptionTooltipProps): JSX.Element => {
  const [snackbarConfig, setSnackbarConfig] = useAtom(snackbarConfigAtom)

  return (
    <Tooltip title={message} placement="top-start">
      <StyledDiv
        className={classes[iconStyle]}
        onClick={() => {
          setSnackbarConfig({
            ...snackbarConfig,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            autoHideDuration: 6000,
            open: true,
            message,
            severity: 'error',
          })
        }}
      >
        <LockIcon color="primary"></LockIcon>
      </StyledDiv>
    </Tooltip>
  )
}
