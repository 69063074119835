import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'

const PREFIX = 'SalesCenterSelect'

export const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  select: `${PREFIX}-select`,
}

export const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    margin: theme.spacing(1),
    [theme.breakpoints.down(900)]: {
      justifyContent: 'center',
    },
  },

  [`& .${classes.button}`]: {
    height: 55,
  },

  [`& .${classes.select}`]: {
    width: 250,
  },
}))
