import { styled } from '@mui/material/styles'

import { MAIN_APP_BAR_HEIGHT } from 'constants/index'
import { PLANS_FILTER_BAR_HEIGHT } from 'components/common/by-plan/PlansFilterBar.styles'
import { MOBILE_FILTER_BAR_HEIGHT } from 'components/common/filter-bar/MobileFilterBar'

const headerHeight = MAIN_APP_BAR_HEIGHT + PLANS_FILTER_BAR_HEIGHT
const headerHeightMobile = MAIN_APP_BAR_HEIGHT + MOBILE_FILTER_BAR_HEIGHT

const PREFIX = 'Plans'

export const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
}

export const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    overflowY: 'hidden',
    overflowX: 'hidden',
    width: '100%',
    height: `calc(100vh - ${headerHeight}px)`,
    [theme.breakpoints.down('md')]: {
      height: `calc(100vh - ${headerHeightMobile}px)`,
    },
  },

  [`& .${classes.container}`]: {
    width: '100%',
    height: '90vh',
  },
}))
