import React from 'react'
import Typography from '@mui/material/Typography'
import LineSeparator from '../components/LineSeparator'
import CardContent from '@mui/material/CardContent'
import { getImagePrefix, getAssetsPrefix } from '@anewgo/utils'

import {
  AgentCardPos,
  AgentCardStyled,
  AgentCardTitle,
  CommunityDescription,
  CommunityDescriptionText,
  CommunityLogo,
  CommunityLocation,
  HeroImage,
  CommunitySectionContainer,
  CommunitySection,
} from '../MyHome.styles'
import { Agent, Client, Community } from 'graphql/gen-types'
import { HomeSelection } from '../MyHome'
import { cityFormatter } from '../../../../utils/formatters'

type MyHomeCommunityProps = {
  uiConfig: any
  client?: Client
  selection: HomeSelection
  community: Community
}

export default function MyHomeCommunity({
  uiConfig,
  client,
  selection,
  community,
}: MyHomeCommunityProps): JSX.Element | null {
  const directoryName = client?.directoryName || ''

  if (!community?.bonafide) {
    return null
  }
  const name = `Community: ${community.name}`
  const { cityLocation } = community
  const loc = cityFormatter.address(cityLocation)

  // const agents = useMemo(() => {
  //   const { localAgent } = uiConfig
  //   const communityAgents =
  //     community?.agents || selection?.community?.agents || []
  //   const tgtAgents = []
  //   communityAgents.forEach((agent: Agent) =>
  //     tgtAgents.push({
  //       name: `${agent.firstName} ${agent.lastName}`,
  //       email: agent.email,
  //     })
  //   )
  //   if (localAgent) {
  //     tgtAgents.unshift(localAgent)
  //   }
  //   return tgtAgents
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [uiConfig, selection])

  const { localAgent } = uiConfig
  const communityAgents =
    community?.agents || selection?.community?.agents || []
  const tgtAgents = []
  communityAgents
    .filter((a): a is Agent => a !== undefined && a !== null) // remove Maybe<>
    .forEach((agent: Agent) =>
      tgtAgents.push({
        name: `${agent.firstName} ${agent.lastName}`,
        email: agent.email,
      })
    )
  if (localAgent) {
    tgtAgents.unshift(localAgent)
  }
  const agents = tgtAgents

  const communityHero = () => {
    const firstImageSrc =
      community?.imgs && community?.imgs?.length > 0
        ? community.imgs[0]?.src
        : ''
    let heroImage = ''
    if (firstImageSrc) {
      heroImage = `${getImagePrefix(directoryName)}/${firstImageSrc}`
    }
    return (
      <div>
        {heroImage ? <HeroImage src={heroImage} alt={'Community'} /> : null}
      </div>
    )
  }

  const communityDescription = () => {
    const { logo } = community
    const logoUrl = logo
      ? `${getAssetsPrefix(directoryName)}/custom/${logo}`
      : undefined
    const div = document.createElement('div')
    const descr = community.description
    div.innerHTML = descr || ''
    const description = div.innerHTML || div.textContent || div.innerText || ''
    return (
      <CommunityDescription>
        {logoUrl && <CommunityLogo alt="" src={logoUrl} />}
        {!logoUrl && <Typography variant="h3">{community.name}</Typography>}
        <CommunityDescriptionText
          variant="body1"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </CommunityDescription>
    )
  }

  const AgentCard = ({ agents }: { agents: Agent[] }) => {
    if (agents.length === 0) {
      return null
    }
    const designatedAgent = agents[0]

    const agentName = designatedAgent.firstName // was designatedAgent.name - name does not exist on Agent. Is this an agent or not?
    const agentEmail = designatedAgent.email
    const agentPhone = designatedAgent.phone
    return (
      <AgentCardStyled>
        <CardContent>
          <AgentCardTitle color="textSecondary" gutterBottom>
            New Home Consultant
          </AgentCardTitle>
          {agentName && (
            <Typography variant="h5" component="h2">
              {agentName}
            </Typography>
          )}
          {agentEmail && (
            <AgentCardPos color="textSecondary">{agentEmail}</AgentCardPos>
          )}
          {agentPhone && (
            <AgentCardPos color="textSecondary">{agentPhone}</AgentCardPos>
          )}
        </CardContent>
      </AgentCardStyled>
    )
  }

  return (
    <CommunitySectionContainer>
      <div>
        <LineSeparator title={name} />
        <CommunityLocation variant="h5">{loc}</CommunityLocation>
      </div>
      <AgentCard agents={agents} />
      <CommunitySection>
        {communityHero()}
        {communityDescription()}
      </CommunitySection>
    </CommunitySectionContainer>
  )
}
