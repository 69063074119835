import { LocationsSelect } from '../LocationsSelect'
import { ReactElement, useCallback } from 'react'
import React from 'react'
import {
  LocationSelectionTypes,
  OptionMap,
  SetLocationSelectionTypes,
  SharedFilterArgs,
} from '../../Shared'

interface ProvinceFilterArgs extends SharedFilterArgs {
  provinces: OptionMap<string>
  selection: LocationSelectionTypes
  setSelection: SetLocationSelectionTypes
}

export default function ProvinceFilter({
  provinces,
  selection,
  setSelection,
  larger = false,
  industryTrendsSelect = false,
  locationsFilterSelect = false,
  leadsLocationSelect = false,
}: ProvinceFilterArgs): ReactElement | null {
  const handleSelect = useCallback(
    (province?: string): void => {
      setSelection((prev: LocationSelectionTypes) => {
        return {
          country: prev.country,
          us: {},
          can: {
            province: province,
          },
        }
      })
    },
    [setSelection]
  )

  return (
    <LocationsSelect<string>
      field="province"
      name="Province"
      items={provinces}
      selected={selection.can?.province}
      onSelect={handleSelect}
      locationsFilterSelect={locationsFilterSelect}
      industryTrendsSelect={industryTrendsSelect}
      leadsLocationSelect={leadsLocationSelect}
      larger={larger}
    />
  )
}
