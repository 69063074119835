import React from 'react'
import Typography from '@mui/material/Typography'
import { classes, Root } from './Spinner.styles'

interface SpinnerProps {
  message?: string
}

function Spinner({ message }: SpinnerProps): JSX.Element {
  return (
    <Root className={classes.root}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={classes.spinner}
        width="100px"
        height="100px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <g>
          <circle cx="47.8367" cy="50" r="4" fill="#667395">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              values="95;35"
              keyTimes="0;1"
              begin="-0.67s"
            />
            <animate
              attributeName="fill-opacity"
              repeatCount="indefinite"
              dur="1s"
              values="0;1;1"
              keyTimes="0;0.2;1"
              begin="-0.67s"
            />
          </circle>
          <circle cx="68.2367" cy="50" r="4" fill="#667395">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              values="95;35"
              keyTimes="0;1"
              begin="-0.33s"
            />
            <animate
              attributeName="fill-opacity"
              repeatCount="indefinite"
              dur="1s"
              values="0;1;1"
              keyTimes="0;0.2;1"
              begin="-0.33s"
            />
          </circle>
          <circle cx="88.0367" cy="50" r="4" fill="#667395">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              values="95;35"
              keyTimes="0;1"
              begin="0s"
            />
            <animate
              attributeName="fill-opacity"
              repeatCount="indefinite"
              dur="1s"
              values="0;1;1"
              keyTimes="0;0.2;1"
              begin="0s"
            />
          </circle>
        </g>
        <g transform="translate(-15 0)">
          <path
            d="M50 50L20 50A30 30 0 0 0 80 50Z"
            fill="#292664"
            transform="rotate(90 50 50)"
          />
          <path
            d="M50 50L20 50A30 30 0 0 0 80 50Z"
            fill="#292664"
            transform="rotate(10.445 50 50)"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              repeatCount="indefinite"
              dur="1s"
              values="0 50 50;45 50 50;0 50 50"
              keyTimes="0;0.5;1"
            />
          </path>
          <path
            d="M50 50L20 50A30 30 0 0 1 80 50Z"
            fill="#292664"
            transform="rotate(-10.445 50 50)"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              repeatCount="indefinite"
              dur="1s"
              values="0 50 50;-45 50 50;0 50 50"
              keyTimes="0;0.5;1"
            />
          </path>
        </g>
      </svg>
      {message && <Typography variant="overline">{message}</Typography>}
    </Root>
  )
}

export default Spinner
