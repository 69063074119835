import React from 'react'
import { classes } from '../../styles/ProspectPipeline.styles'

import Button from '@mui/material/Button'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

import { Milestone } from '../ProspectPipeline'

/**
 * Modal for editing milestones, such as re-ordering.
 */
export default function EditModal({
  sortable,
  unEditable,
  open,
  onClose,
}: {
  sortable: unknown
  unEditable: Milestone[]
  open: boolean
  onClose: () => void
}): JSX.Element {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit</DialogTitle>
      <DialogContent>
        <DialogContentText>Drag/Drop to re-order milestones</DialogContentText>
        {/* Display static milestones */}
        <List dense={true}>
          {unEditable.map((value, index) => (
            <ListItem disabled={true} key={`${value.title}-${index}`}>
              <ListItem className={classes.editList}>
                <ListItemText>{value.title}</ListItemText>
              </ListItem>
            </ListItem>
          ))}
        </List>
        Editable Milestones
        {sortable}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
