import React from 'react'
import Dialog from '@mui/material/Dialog'

export interface TableImageDialogProps {
  open: boolean
  onClose: () => void
  image: JSX.Element | undefined
}

export default function TableImageDialog({
  onClose,
  open,
  image,
}: TableImageDialogProps): JSX.Element {
  const handleClose = (): void => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} open={open} maxWidth={'lg'}>
      {image}
    </Dialog>
  )
}
