import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded'
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded'
import Tooltip from '@mui/material/Tooltip'
import { SessionsSummary } from 'graphql/gen-types'
import { StyledGrid, classes } from './SessionsSummaryDisplay.styles'

// Type of keys of SessionsSummary. Not sure if there is a
// better way to do this -- i.e. not manually
type sessionSummaryKeys =
  | 'sessions'
  | 'averageDuration'
  | 'visitors'
  | 'registered'
  | 'returnVisitors'
  | 'newVisitors'
  | 'bounceRate'

// Maps gql field names to readable names
const nameMap: Record<
  sessionSummaryKeys,
  { title: string; tooltip: string }
> = {
  sessions: {
    title: 'Sessions',
    tooltip:
      'A session is the time period a user is actively engaged with your website.',
  },
  visitors: {
    title: 'Visitors',
    tooltip:
      'The number of users that have initiated a session on your website.',
  },
  registered: {
    title: 'Registered Users',
    tooltip:
      'The number of users that have registered an account using credentials to provide their identity.',
  },
  returnVisitors: {
    title: 'Returning Visitors',
    tooltip:
      'The number of users that have previously been to your website in a predetermined time frame and have started another session on the same browser and device.',
  },
  newVisitors: {
    title: 'New Visitors',
    tooltip: 'The number of users that have never been to your website before.',
  },
  averageDuration: {
    title: 'Avg Duration',
    tooltip: 'Average length of a session in minutes.',
  },
  bounceRate: {
    title: 'Bounce Rate',
    tooltip:
      'The percent of sessions that have a duration of 10 seconds or less.',
  },
}

interface SessionSummaryProps {
  data: SessionsSummary
  previousData: SessionsSummary
  report?: boolean
}

export default function SessionsSummaryDisplay({
  data,
  previousData,
  report,
}: SessionSummaryProps): JSX.Element {
  const hiddenFields = ['__typename', 'clientName', 'startAt', 'endAt']
  return (
    <StyledGrid
      container
      className={report ? classes.gridRootReport : classes.gridRoot}
      justifyContent="center"
      spacing={0}
    >
      {/* Sessions Summary boxes */}
      {/* Loop through sessionSummary */}
      {Object.keys(data).map((k) => {
        // Remove the typename. There should be a neater way to do this.
        // Typename can be removed from object returned by graphQL
        // by adding field
        // 'addTypename: false' to 'cache' in the apollo 'client'.
        if (hiddenFields.includes(k)) {
          return ``
        }

        // String cannot be used to index field, so cast to known keys
        const key = k as sessionSummaryKeys

        const curr = data[key] || 1
        const prev = previousData[key] || 1

        const percentChange = Math.round((100 * (curr - prev)) / prev)
        let colorGreen = percentChange > 0
        let displayValue: null | undefined | number | string = data[key]
        if (key === 'averageDuration') {
          displayValue = data[key]?.toFixed(2)
        }
        if (key === 'bounceRate') {
          displayValue = Math.round((data[key] || 0) * 100) + '%'
          colorGreen = !colorGreen
        }
        let displayPrevValue: null | undefined | number | string =
          previousData[key]
        if (key === 'averageDuration') {
          displayPrevValue = previousData[key]?.toFixed(2)
        }
        if (key === 'bounceRate') {
          displayPrevValue = Math.round((previousData[key] || 0) * 100) + '%'
        }
        return (
          <Tooltip
            title={`${nameMap[key].tooltip} The value from the previous date range is ${displayPrevValue}.`}
            placement="top"
            arrow
          >
            <Grid key={key} item>
              <Paper
                className={report ? classes.reportPaper : classes.paper}
                square={true}
                variant="outlined"
              >
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  className={classes.sessionsSummaryValueCont}
                >
                  <Grid item xs={8}>
                    <Typography
                      className={classes.sessionsSummaryValue}
                      variant={report ? 'h6' : 'h4'}
                      align="right"
                      data-testid={`sessionsSummaryValue-${key}`}
                    >
                      {displayValue}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="caption" align="right">
                      <Paper
                        variant="outlined"
                        className={classes.percentPaper}
                      >
                        <Grid
                          container
                          spacing={0}
                          alignItems="center"
                          justifyContent="center"
                          className={
                            colorGreen ? classes.positive : classes.negative
                          }
                        >
                          {percentChange > 0 ? (
                            <ArrowUpwardRoundedIcon
                              fontSize="small"
                              className={classes.arrow}
                              data-testid={`ArrowUpwardRoundedIcon-${key}`}
                            />
                          ) : (
                            <ArrowDownwardRoundedIcon
                              fontSize="small"
                              className={classes.arrow}
                              data-testid={`ArrowDownwardRoundedIcon-${key}`}
                            />
                          )}
                          <Grid data-testid={`percentChange-${key}`} item>
                            <span
                              style={{ fontSize: report ? 8 : 'inherit' }}
                            >{`${percentChange}%`}</span>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Typography>
                  </Grid>
                </Grid>
                <Box className={classes.sessionsSummaryLabelCont}>
                  <Typography className={classes.sessionsSummaryLabel}>
                    {nameMap[key].title}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          </Tooltip>
        )
      })}
    </StyledGrid>
  )
}
