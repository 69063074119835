import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'

const PREFIX = 'EditReportInfoDialog'

export const classes = {
  editReportInfoDialog: `${PREFIX}-editReportInfoDialog`,
  editReportInfoFirstNameInputField: `${PREFIX}-editReportInfoFirstNameInputField`,
  editReportInfoLastNameInputField: `${PREFIX}-editReportInfoLastNameInputField`,
  formControlEditReportInfo: `${PREFIX}-formControlEditReportInfo`,
}

export const StyledDialog = styled(Dialog)(() => ({
  [`& .MuiDialog-paper`]: {
    minWidth: '500px',
  },

  [`& .${classes.editReportInfoFirstNameInputField}`]: {
    width: 'calc(100% - 10px)',
  },

  [`& .${classes.editReportInfoLastNameInputField}`]: {
    marginLeft: '10px',
  },

  [`& .${classes.formControlEditReportInfo}`]: {
    marginBottom: '20px',
    width: '100%',
  },
}))
