import React, { Dispatch } from 'react'
import { LeadsState, AppAction } from './reducers'
import DateFnsAdapter from '@date-io/date-fns'

const dateFns = new DateFnsAdapter()
interface LeadsContextProps {
  leadsState: LeadsState
  dispatch: Dispatch<AppAction>
}

/**
 * Usually we will have a 1-1 relationship between signed-in user and client.
 * However, in some cases, such as RH user, or a user with multiple clients
 * It can be a 1-n user to client mapping.
 *
 */
export const initialState: LeadsState = {
  selectedProspect: null, // prospect viewed
  startDate: dateFns.startOfDay(dateFns.addDays(dateFns.date(new Date()), -7)),
  endDate: dateFns.endOfDay(new Date()),
  defaultLeadFilters: {
    newLeadsFilter: null,
    returningLeadsFilter: null,
    activeLeadsFilter: null,
  },
}

export const LeadsStore: React.Context<LeadsContextProps> = React.createContext(
  {} as LeadsContextProps
)
