import React, { Dispatch, SetStateAction } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'

interface AlertDialogInterface {
  setIsConfirmationOpen: Dispatch<SetStateAction<boolean>>
  isConfirmationOpen: boolean
  message: string
  callbackWhenPromptAgree: () => Promise<void>
  callbackWhenPromptCancel: () => Promise<void>
  isErrorWhenApiCall: boolean
}

export const voidFunction = () => async (): Promise<void> => {
  return
}

export const ConfirmAlert = ({
  setIsConfirmationOpen,
  isConfirmationOpen,
  message,
  callbackWhenPromptAgree,
  callbackWhenPromptCancel,
  isErrorWhenApiCall,
}: AlertDialogInterface): JSX.Element => {
  const handleAgreeButton = (): void => {
    callbackWhenPromptAgree()
  }

  const handleCancelButton = (): void => {
    callbackWhenPromptCancel()
    setIsConfirmationOpen(false)
  }

  return (
    <div>
      <Dialog
        open={isConfirmationOpen}
        onClose={(): void => setIsConfirmationOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
        <DialogActions>
          {isErrorWhenApiCall ? (
            <Button
              onClick={(): void => setIsConfirmationOpen(false)}
              color="primary"
            >
              Close
            </Button>
          ) : (
            <>
              <Button onClick={handleCancelButton} color="primary">
                Cancel
              </Button>
              <Button onClick={handleAgreeButton} color="primary" autoFocus>
                Submit
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  )
}
