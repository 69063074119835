import React, { Suspense } from 'react'
import { classes, Root } from './ErrorPage.styles'
const ErrorPage = React.lazy(() => import('./ErrorPage'))
const url =
  'https://res.cloudinary.com/renderinghouse/image/upload/v1592959282/anewgo/stock/confused-dog.jpg'

function NotFound(): JSX.Element {
  return (
    <div>
      <Suspense fallback={<Root className={classes.root}>Loading...</Root>}>
        <ErrorPage message="Error 404: That page is not found" image={url} />
      </Suspense>
    </div>
  )
}

export default NotFound
