import React, { useEffect } from 'react'
import useFetchImage from './UseFetchImage.hook'
import { Lot, ClientIdAndName } from 'graphql/gen-types'

import LotSVG from '../../common/siteplan/LotSVG'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { StyledTypography, classes } from './CustomXAxis.styled'

export interface CustomXAxisTickProps {
  data: Array<Lot | null> | undefined
  client?: ClientIdAndName
  onHeightChange(height: number): void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [prop: string]: any //for properties provided by recharts
}

export const CustomXAxisTick: React.FC<CustomXAxisTickProps> = ({
  x: tickX,
  y: tickY,
  width: chartWidth,
  height: chartHeight,
  payload,
  data,
  client,
  onHeightChange,
}) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down('sm'))

  const renderInsetText = (): JSX.Element => {
    return (
      <foreignObject
        width={insetWidth}
        height={54}
        transform={`translate(0,${insetHeight})`}
        xmlns="http://www.w3.org/1999/xhtml"
      >
        <StyledTypography className={classes.typography}>
          Lot {lot?.name}
        </StyledTypography>
        {!xs && (
          <div>
            <StyledTypography className={classes.typography}>
              {lot?.siteplan?.name}
            </StyledTypography>
            <StyledTypography className={classes.typography}>
              {lot?.address}
            </StyledTypography>
          </div>
        )}
      </foreignObject>
    )
  }

  const lots = data?.filter((lot) => lot !== null) || []
  const lot = lots.find((lot) => lot?.id === payload?.value)

  const insetWidth = (chartWidth / lots.length) * 0.7 // 70% of tick width
  let insetHeight = (insetWidth * 9) / 16 // 16:9 ratio

  const { image, loading, error } = useFetchImage(
    client?.directoryName,
    lot?.siteplan?.src
  )

  useEffect(() => {
    onHeightChange(insetHeight + 64)
  }, [insetHeight, onHeightChange])

  if (loading) {
    return (
      <g transform={`translate(${tickX},${tickY})`}>
        <text textAnchor="middle" y={chartHeight / 2} fill="blue">
          Loading image...
        </text>
      </g>
    )
  }

  if (
    error ||
    !image ||
    !lot?.siteplan ||
    !lot?.siteplan?.lotWidth ||
    !lot?.siteplan?.lotHeight ||
    !lot?.siteplanInfo?.x ||
    !lot?.siteplanInfo?.y
  ) {
    insetHeight = 0
    onHeightChange(insetHeight + 64)
    return (
      <g transform={`translate(${tickX - insetWidth / 2},${tickY})`}>
        {renderInsetText()}
      </g>
    )
  } else {
    // Show a quarter of the siteplan by width
    // TODO: (this should be adjsused depending on siteplan size)
    const scale = image.width / (4 * insetWidth)

    const x =
      lot?.siteplanInfo?.x -
      (insetWidth * scale) / 2 +
      lot?.siteplan?.lotWidth / 2
    const y =
      lot?.siteplanInfo?.y -
      (insetHeight * scale) / 2 +
      lot?.siteplan?.lotHeight / 2
    const viewBox = `${x} ${y} ${insetWidth * scale} ${insetHeight * scale}`

    return (
      <g transform={`translate(${tickX - insetWidth / 2},${tickY})`}>
        <svg viewBox={viewBox} width={insetWidth} height={insetHeight}>
          <image
            xlinkHref={image.src}
            width={image.width}
            height={image.height}
          />
          <LotSVG
            key={lot.id}
            lot={lot}
            siteplan={lot.siteplan}
            highlightMode={true}
            borderColor={'lime'}
            borderWidth={6}
          />
        </svg>
        {renderInsetText()}
      </g>
    )
  }
}
