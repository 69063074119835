import React, { useState } from 'react'
import clsx from 'clsx'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import CloseIcon from '@mui/icons-material/Close'
import { Editor } from '@tinymce/tinymce-react'
import {
  EmailCampaign,
  EmailCampaignInput,
  EmailCampaignSectionImage,
  useDeleteEmailCampaignSectionImageMutation,
} from '../../../../graphql/gen-types'

interface EmailCampaignSectionTwoProps {
  classes: any
  clientName: string
  emailCampaign: EmailCampaign
  emailCampaignUpdates: EmailCampaignInput
  setEmailCampaignUpdates: (updates: EmailCampaignInput) => void
  handleDropboxChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    uploadTarget: string
  ) => void
  handleImportClick: (importMode: string) => void
  setWarn: (warn: string) => void
}

// Every email campaign, once created, will have 3 sections in their sections array.
// Section 1 will be the first in the array with an index of 0, section 2 has an index of 1,
// and section 3 has an index of 2
const sect1Index = 0
const sect2Index = 1
const sect3Index = 2

export default function EmailCompositionModalStepOne({
  classes,
  clientName,
  emailCampaign,
  emailCampaignUpdates,
  setEmailCampaignUpdates,
  handleDropboxChange,
  handleImportClick,
  setWarn,
}: EmailCampaignSectionTwoProps): JSX.Element {
  const [
    imageToBeDeleted,
    setImageToBeDeleted,
  ] = useState<EmailCampaignSectionImage | null>(null)

  const [
    deleteEmailCampaignSectionImageMutation,
  ] = useDeleteEmailCampaignSectionImageMutation()

  const handleDeleteImage = (): void => {
    if (!imageToBeDeleted) {
      return
    }
    deleteEmailCampaignSectionImageMutation({
      variables: {
        clientName,
        emailCampaignSectionImageId: imageToBeDeleted.id,
      },
    })
      .then(() => {
        setImageToBeDeleted(null)
      })
      .catch((error) => {
        console.error('failed to DELETE_EMAIL_CAMPAIGN', error)
        setWarn(
          "Failed to delete email campaign. Please check your internet connection and notify an admin if that doesn't seem to be the issue."
        )
      })
  }

  const handleEditorChange = (evt: any, type: string) => {
    const content = evt.target.getContent()
    if (type.indexOf('body') !== -1) {
      let sectionUpdates = emailCampaignUpdates.sections
        ? [...emailCampaignUpdates.sections]
        : []
      const index = parseInt(type.substring(4), 10) - 1
      const mySection =
        sectionUpdates.find((sect) => sect.index === index) || {}
      const updatedSection = {
        ...mySection,
        index,
        content,
      }
      if (mySection.index !== undefined) {
        sectionUpdates = sectionUpdates.filter(
          (update) => update.index !== index
        )
      }
      sectionUpdates.push(updatedSection)
      setEmailCampaignUpdates({
        ...emailCampaignUpdates,
        sections: sectionUpdates,
      })
      return
    }
    setEmailCampaignUpdates({ ...emailCampaignUpdates, [type]: content })
  }

  const handleReplyToNameChange = (input: string): void => {
    setEmailCampaignUpdates({ ...emailCampaignUpdates, replyToName: input })
  }

  const handleReplyToEmailChange = (input: string): void => {
    setEmailCampaignUpdates({ ...emailCampaignUpdates, replyTo: input })
  }

  const handleSubjectChange = (input: string): void => {
    setEmailCampaignUpdates({ ...emailCampaignUpdates, subject: input })
  }

  const editorSettings = {
    branding: false,
    height: '200px',
    plugins: 'link image code lists advlist',
    toolbar:
      'undo redo | bold italic underline | alignleft ' +
      'aligncenter alignright | bullist | code',
  }

  return (
    <>
      <Typography variant="h6" color="primary" style={{ marginLeft: '-4px' }}>
        Email Content
      </Typography>
      <Typography
        className={classes.subtitle}
        variant="subtitle2"
        color="textSecondary"
        style={{ marginLeft: '-4px', marginBottom: '12px' }}
      >
        Any instance of the text <b>[lead name]</b> in the input fields below
        will be replaced with the Lead's first name.
      </Typography>
      <Grid item>
        <FormControl
          className={classes.formControl}
          required
          variant="standard"
        >
          <TextField
            variant="standard"
            data-testid="emailCompReplyToName"
            id="emailCompReplyToName"
            label={'ReplyTo Name'}
            required
            value={
              emailCampaignUpdates.replyToName !== undefined
                ? emailCampaignUpdates.replyToName
                : emailCampaign.replyToName || ''
            }
            onChange={(ev): void => handleReplyToNameChange(ev.target.value)}
          />
        </FormControl>
        <FormControl
          className={classes.formControl}
          required
          variant="standard"
        >
          <TextField
            variant="standard"
            data-testid="emailCompReplyToEmail"
            id="emailCompReplyToEmail"
            label={'ReplyTo Email'}
            required
            value={
              emailCampaignUpdates.replyTo !== undefined
                ? emailCampaignUpdates.replyTo
                : emailCampaign.replyTo || ''
            }
            onChange={(ev): void => handleReplyToEmailChange(ev.target.value)}
          />
        </FormControl>
        <FormControl className={classes.formControl} variant="standard">
          <TextField
            variant="standard"
            data-testid="emailCompSubject"
            id="emailCompSubject"
            label={'Subject'}
            required
            value={
              emailCampaignUpdates.subject !== undefined
                ? emailCampaignUpdates.subject
                : emailCampaign.subject || ''
            }
            onChange={(ev): void => handleSubjectChange(ev.target.value)}
          />
        </FormControl>
        <FormControl
          style={{ marginTop: '40px', width: '100%' }}
          variant="standard"
        >
          <InputLabel
            id="email-campaign-opener-label"
            style={{
              marginTop: '-50px',
              fontSize: '16px',
            }}
          >
            Opener
          </InputLabel>
          <Editor
            id={`${emailCampaign.id}emailCampaignOpener`}
            initialValue={
              emailCampaign.opener || '<p>Hi [lead name],</p><br />'
            }
            key={`${emailCampaign.id}emailCampaignOpener`}
            init={editorSettings}
            onBlur={(evt) => handleEditorChange(evt, 'opener')}
          />
        </FormControl>
        {(emailCampaignUpdates.templateId === 3 ||
          emailCampaign.templateId === 3) && (
          <Typography
            variant="h6"
            color="primary"
            style={{ marginLeft: '-4px', marginTop: '16px' }}
          >
            Section 1
          </Typography>
        )}
        {emailCampaignUpdates.templateId !== 3 &&
          emailCampaign.templateId !== 3 && (
            <FormControl className={classes.formControl} variant="standard">
              <InputLabel
                id="email-campaign-main-image-label"
                style={{
                  fontSize: '16px',
                }}
              >
                Main Image
              </InputLabel>
              <label
                className={clsx(
                  classes.uploadDropbox,
                  classes.uploadDropboxMainImage
                )}
                style={{ height: '400px' }}
                htmlFor="main-image-dropbox"
              >
                <input
                  accept="image/*"
                  type="file"
                  id="main-image-dropbox"
                  onChange={(evt) => handleDropboxChange(evt, 'mainImage')}
                  hidden
                />
                {emailCampaign?.mainImage ? (
                  <Typography variant="body1" className={classes.dropboxText}>
                    <div className={classes.mainImageDiv}>
                      <img
                        className={classes.sectImage}
                        src={emailCampaign?.mainImage}
                        alt={`${emailCampaign?.mainImage} Preview`}
                      />
                    </div>
                    Click here to replace image or{' '}
                    <Button
                      className={classes.inlineImportButton}
                      variant="contained"
                      color="primary"
                      onClick={() => handleImportClick('mainImage')}
                    >
                      Import
                    </Button>
                  </Typography>
                ) : (
                  <Typography variant="body1">
                    Click here to upload image or{' '}
                    <Button
                      className={classes.inlineImportButton}
                      variant="contained"
                      color="primary"
                      onClick={() => handleImportClick('mainImage')}
                    >
                      Import
                    </Button>
                  </Typography>
                )}
              </label>
            </FormControl>
          )}
        {emailCampaignUpdates.templateId !== 1 &&
          emailCampaign.templateId !== 1 && (
            <>
              <FormControl className={classes.formControl} variant="standard">
                <InputLabel
                  id="email-campaign-images-label"
                  style={{
                    fontSize: '16px',
                  }}
                >
                  {emailCampaignUpdates.templateId === 3 ||
                  emailCampaign.templateId === 3
                    ? 'Section 1 Gallery Images'
                    : 'Gallery Images'}
                </InputLabel>
                <Button
                  className={classes.importButton}
                  variant="contained"
                  color="primary"
                  onClick={() => handleImportClick('sect1')}
                >
                  Import
                </Button>
                <div className={classes.sectImageContainer}>
                  <label
                    className={classes.uploadDropbox}
                    htmlFor="email-campaign-sect-one-image-dropbox"
                  >
                    <input
                      accept="image/*"
                      type="file"
                      id="email-campaign-sect-one-image-dropbox"
                      onChange={(evt) => handleDropboxChange(evt, 'sect1')}
                      hidden
                    />
                    <Typography variant="body1" className={classes.dropboxText}>
                      Click here to upload image
                    </Typography>
                  </label>
                  {emailCampaign?.sections?.[sect1Index]?.images?.map(
                    (section1Image) => (
                      <div className={classes.sectImageDiv}>
                        <img
                          className={classes.sectImage}
                          src={section1Image.src}
                          alt={`${section1Image.src} Import`}
                        />
                        <Button
                          aria-label="delete"
                          variant="contained"
                          color="secondary"
                          onClick={(): void =>
                            setImageToBeDeleted(section1Image)
                          }
                          className={classes.imageDeleteButton}
                        >
                          <CloseIcon />
                        </Button>
                      </div>
                    )
                  ) || null}
                </div>
              </FormControl>
            </>
          )}
        <FormControl
          style={{ marginTop: '30px', width: '100%' }}
          variant="standard"
        >
          <InputLabel
            id="email-campaign-body-label"
            style={{
              marginTop: '-50px',
              fontSize: '16px',
            }}
          >
            {emailCampaignUpdates.templateId === 3 ||
            emailCampaign.templateId === 3
              ? 'Section 1 Body'
              : 'Body'}
          </InputLabel>
          <Editor
            id={`${emailCampaign.id}emailCampaignBody1`}
            initialValue={
              emailCampaign?.sections
                ? emailCampaign?.sections[sect1Index]?.content || undefined
                : undefined
            }
            key={`${emailCampaign.id}emailCampaignBody1`}
            init={editorSettings}
            onBlur={(evt) => handleEditorChange(evt, 'body1')}
          />
        </FormControl>
        {(emailCampaignUpdates.templateId === 3 ||
          emailCampaign.templateId === 3) && (
          <>
            <Typography
              variant="h6"
              color="primary"
              style={{ marginLeft: '-4px', marginTop: '16px' }}
            >
              Section 2
            </Typography>
            <FormControl className={classes.formControl} variant="standard">
              <InputLabel
                id="email-campaign-images-2-label"
                style={{
                  fontSize: '16px',
                }}
              >
                Section 2 Gallery Images
              </InputLabel>
              <Button
                className={classes.importButton}
                variant="contained"
                color="primary"
                onClick={() => handleImportClick('sect2')}
              >
                Import
              </Button>
              <div className={classes.sectImageContainer}>
                <label
                  className={classes.uploadDropbox}
                  htmlFor="email-campaign-sect-two-image-dropbox"
                >
                  <input
                    accept="image/*"
                    type="file"
                    id="email-campaign-sect-two-image-dropbox"
                    onChange={(evt) => handleDropboxChange(evt, 'sect2')}
                    hidden
                  />
                  <Typography variant="body1" className={classes.dropboxText}>
                    Click here to upload image
                  </Typography>
                </label>
                {emailCampaign?.sections?.[sect2Index]?.images?.map(
                  (section2Image) => (
                    <div className={classes.sectImageDiv}>
                      <img
                        className={classes.sectImage}
                        src={section2Image.src}
                        alt={`${section2Image.src} Import`}
                      />
                      <Button
                        aria-label="delete"
                        variant="contained"
                        color="secondary"
                        onClick={(): void => setImageToBeDeleted(section2Image)}
                        className={classes.imageDeleteButton}
                      >
                        <CloseIcon />
                      </Button>
                    </div>
                  )
                ) || null}
              </div>
            </FormControl>
            <FormControl
              style={{ marginTop: '30px', width: '100%' }}
              variant="standard"
            >
              <InputLabel
                id="email-campaign-body-2-label"
                style={{
                  marginTop: '-50px',
                  fontSize: '16px',
                }}
              >
                Section 2 Body
              </InputLabel>
              <Editor
                id={`${emailCampaign.id}emailCampaignBody2`}
                initialValue={
                  emailCampaign?.sections
                    ? emailCampaign?.sections[sect2Index]?.content || undefined
                    : undefined
                }
                key={`${emailCampaign.id}emailCampaignBody2`}
                init={editorSettings}
                onBlur={(evt) => handleEditorChange(evt, 'body2')}
              />
            </FormControl>

            <Typography
              variant="h6"
              color="primary"
              style={{ marginLeft: '-4px', marginTop: '16px' }}
            >
              Section 3
            </Typography>
            <FormControl className={classes.formControl} variant="standard">
              <InputLabel
                id="email-campaign-images-3-label"
                style={{
                  fontSize: '16px',
                }}
              >
                Section 3 Gallery Images
              </InputLabel>
              <Button
                className={classes.importButton}
                variant="contained"
                color="primary"
                onClick={() => handleImportClick('sect3')}
              >
                Import
              </Button>
              <div className={classes.sectImageContainer}>
                <label
                  className={classes.uploadDropbox}
                  htmlFor="email-campaign-sect-three-image-dropbox"
                >
                  <input
                    accept="image/*"
                    type="file"
                    id="email-campaign-sect-three-image-dropbox"
                    onChange={(evt) => handleDropboxChange(evt, 'sect3')}
                    hidden
                  />
                  <Typography variant="body1" className={classes.dropboxText}>
                    Click here to upload image
                  </Typography>
                </label>
                {emailCampaign?.sections?.[sect3Index]?.images?.map(
                  (section3Image) => (
                    <div className={classes.sectImageDiv}>
                      <img
                        className={classes.sectImage}
                        src={section3Image.src}
                        alt={`${section3Image.src} Import`}
                      />
                      <Button
                        aria-label="delete"
                        variant="contained"
                        color="secondary"
                        onClick={(): void => setImageToBeDeleted(section3Image)}
                        className={classes.imageDeleteButton}
                      >
                        <CloseIcon />
                      </Button>
                    </div>
                  )
                ) || null}
              </div>
            </FormControl>
            <FormControl
              style={{ marginTop: '30px', width: '100%' }}
              variant="standard"
            >
              <InputLabel
                id="email-campaign-body-3-label"
                style={{
                  marginTop: '-50px',
                  fontSize: '16px',
                }}
              >
                Section 3 Body
              </InputLabel>
              <Editor
                id={`${emailCampaign.id}emailCampaignBody3`}
                initialValue={
                  emailCampaign?.sections
                    ? emailCampaign?.sections[sect3Index]?.content || undefined
                    : undefined
                }
                key={`${emailCampaign.id}emailCampaignBody3`}
                init={editorSettings}
                onBlur={(evt) => handleEditorChange(evt, 'body3')}
              />
            </FormControl>
          </>
        )}
        <FormControl
          style={{ marginTop: '50px', width: '100%' }}
          variant="standard"
        >
          <InputLabel
            id="email-campaign-signature-label"
            style={{ marginTop: '-50px', fontSize: '16px' }}
          >
            Signature
          </InputLabel>
          <Editor
            id={`${emailCampaign.id}emailCampaignSig`}
            initialValue={emailCampaign.signature || undefined}
            key={`${emailCampaign.id}emailCampaignSig`}
            init={editorSettings}
            onBlur={(evt) => handleEditorChange(evt, 'signature')}
          />
        </FormControl>
      </Grid>
      <Dialog
        open={imageToBeDeleted !== null}
        onClose={(): void => setImageToBeDeleted(null)}
        aria-labelledby="image-delete-title"
        aria-describedby="image-delete-message"
      >
        {imageToBeDeleted !== null && (
          <div>
            <DialogTitle id="image-delete-title">
              Remove this image from the email campaign?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="image-delete-message">
                This action cannot be undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(): void => setImageToBeDeleted(null)}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                data-testid="delete-image-button"
                color="secondary"
                onClick={handleDeleteImage}
                autoFocus
              >
                Remove
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    </>
  )
}
