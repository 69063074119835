import { styled } from '@mui/material/styles'

const PREFIX = 'LotLeadsTable'

export const classes = {
  tableContainer: `${PREFIX}-tableContainer`,
}

export const Root = styled('div')(({ theme }) => ({
  [`&.${classes.tableContainer}`]: {
    width: 400,
    marginBottom: 8,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))
