import { styled } from '@mui/material/styles'
import { TableHead } from '@mui/material'

const PREFIX = 'ReservationIntegrationListHeader'

export const classes = {
  loader: `${PREFIX}-loader`,
  tableHead: `${PREFIX}-tableHead`,
}

export const StyledTableHead = styled(TableHead)(() => ({
  [`& .${classes.loader}`]: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 3,
  },
  [`&.${classes.tableHead}`]: {
    position: 'relative',
  },
}))
