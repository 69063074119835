import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import InputLabel, { InputLabelProps } from '@mui/material/InputLabel'
import Tooltip from '@mui/material/Tooltip'
const PREFIX = 'OverflowInputLabel'

const classes = {
  label: `${PREFIX}-label`,
}

const StyledInputLabel = styled(InputLabel)(() => ({
  [`&.${classes.label}`]: {
    width: 'calc(100%)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

export type OverflowInputLabelProps = InputLabelProps
export const OverflowInputLabel = (props: OverflowInputLabelProps) => {
  const [labelFits, setLabelFits] = useState(true)

  return (
    <Tooltip
      placement="top"
      title={<>{props.children}</>}
      disableFocusListener={labelFits}
      disableHoverListener={labelFits}
      disableTouchListener={labelFits}
      enterTouchDelay={0}
      leaveTouchDelay={3000}
    >
      <StyledInputLabel
        {...props}
        className={classes.label}
        ref={(ref) =>
          setLabelFits(ref ? ref.clientWidth >= ref.scrollWidth : false)
        }
      />
    </Tooltip>
  )
}
