import React from 'react'

import { useTheme } from '@mui/material/styles'

import useMediaQuery from '@mui/material/useMediaQuery'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Grid from '@mui/material/Grid'
import { menuDialogConfigAtom } from 'store/atoms'
import { useAtom } from 'jotai'
import { classes, Root, RootContent } from './MenuDialog.styles'

type menuDialogueProps = {
  alwaysShowButton?: boolean | false
  children?: JSX.Element | null
  buttonSize?: 'small' | 'medium' | 'large' | undefined
  buttonText?: string | null
  testId?: string
}
function MenuDialog({
  alwaysShowButton,
  buttonSize,
  buttonText,
  children,
  testId,
}: menuDialogueProps): JSX.Element {
  const theme = useTheme()
  const [menuDialogConfig, setMenuDialogConfig] = useAtom(menuDialogConfigAtom)
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Root>
      <Button
        className={classes.menuDialogueButton}
        variant="outlined"
        color="primary"
        onClick={(): Promise<void> | void =>
          setMenuDialogConfig({ open: true })
        }
        style={alwaysShowButton ? { display: 'flex' } : undefined}
        size={buttonSize ? buttonSize : 'medium'}
      >
        {buttonText || 'Open Filter Menu'}
      </Button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="sm"
        fullWidth={true}
        open={menuDialogConfig.open}
        onClose={(): Promise<void> | void =>
          setMenuDialogConfig({ open: false })
        }
        aria-labelledby="form-dialog-title"
        data-testid={testId}
      >
        <RootContent>
          <DialogTitle id="form-dialog-title">
            Filter Menu
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={(e): Promise<void> | void =>
                setMenuDialogConfig({ open: false })
              }
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {children}
            <Grid container justifyContent="center">
              <Grid item>
                <Button
                  variant="contained"
                  onClick={(): Promise<void> | void =>
                    setMenuDialogConfig({ open: false })
                  }
                  color="primary"
                  style={{ marginTop: '2em' }}
                  data-testid="CloseBtn"
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions></DialogActions>
        </RootContent>
      </Dialog>
    </Root>
  )
}

export default MenuDialog
