import { gql } from '@apollo/client'

const GET_EMAIL_CAMPAIGNS = gql`
  query getEmailCampaigns($clientName: String!, $shared: Boolean) {
    getEmailCampaigns(clientName: $clientName, shared: $shared) {
      id
      communityId
      planId
      elevationId
      lotId
      inventoryId
      clientName
      ownerId
      templateId
      name
      mainImage
      replyTo
      replyToName
      subject
      body
      opener
      signature
      active
      createdAt
      lastUpdated
      cohort(clientName: $clientName) {
        id
        emailCampaignId
        prospectId
        status
        lastFollowUp
        active
        prospect(clientName: $clientName) {
          id
          status
          registrationDate
          name
          firstName
          lastName
          email
        }
      }
      sections(clientName: $clientName) {
        id
        content
        index
        images(clientName: $clientName) {
          id
          emailCampaignSectionId
          index
          src
        }
      }
      community(clientName: $clientName) {
        id
        name
      }
      lot(clientName: $clientName) {
        id
        name
        address
        metroName
        cityName
        stateCode
        zip
        postCode
        countryCode
      }
      inventory(clientName: $clientName) {
        id
        plan(clientName: $clientName) {
          id
          name
          displayName
        }
        elevation(clientName: $clientName) {
          id
          caption
          internalName
        }
        lot(clientName: $clientName) {
          id
          name
          address
          metroName
          cityName
          stateCode
          zip
          postCode
          countryCode
        }
      }
      plan(clientName: $clientName) {
        id
        name
        displayName
      }
      elevation(clientName: $clientName) {
        id
        caption
        internalName
      }
      searchCommunity
      searchPlan
    }
  }
`

export default GET_EMAIL_CAMPAIGNS
