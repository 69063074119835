import React, { useState } from 'react'
import {
  usePopularLotsQuery,
  CommonQueryFilter,
  ClientIdAndName,
  Lot,
} from 'graphql/gen-types'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import PopularLots from './PopularLots'

interface TrussPopularLotsProps {
  client: ClientIdAndName | null
  filter: CommonQueryFilter
  communityName?: string
  containerHeight: number
}

function TrussPopularLots(props: TrussPopularLotsProps): JSX.Element {
  const [showUnpopularLots, setShowUnpopularLots] = useState<boolean>(false)
  const { data, error, loading } = usePopularLotsQuery({
    variables: {
      filter: {
        ...props.filter,
        ascending: showUnpopularLots,
      },
    },
  })

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized message={error.toString()} imageName={'PopularColors'} />
      )
    }
    console.error(error)
    return <div>Error while loading popular lots</div>
  }

  const popularLots: Lot[] = data?.popularLots as Lot[]

  return (
    <PopularLots
      {...props}
      popularLots={popularLots}
      loading={loading}
      error={error}
      setShowUnpopularLots={setShowUnpopularLots}
      showUnpopularLots={showUnpopularLots}
    />
  )
}

export default TrussPopularLots
