import React from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

export enum SortOptions {
  Popularity = 'Engagement Metric',
  Frequency = 'Frequency',
  Duration = 'Duration',
  CtaHits = 'CTA Hits',
  MyHomeHits = 'My Home hits',
  Favorites = 'All Favorites',
  LeadFavorites = 'Lead Favorites',
  LeadViews = 'Lead Views',
}

export interface SortSelectProps {
  selectedOption: SortOptions
  setSelectedOption: (state: SortOptions) => void
}

export default function SortSelect({
  selectedOption,
  setSelectedOption,
}: SortSelectProps): JSX.Element {
  const sortOptions = Object.keys(SortOptions)

  return (
    <FormControl variant="standard">
      <InputLabel htmlFor="select-sort">Sort by</InputLabel>
      <Select
        variant="standard"
        value={selectedOption}
        onChange={(e): void => {
          setSelectedOption(e.target.value as SortOptions)
        }}
        inputProps={{
          name: 'sort-option',
          id: 'sort-option',
        }}
      >
        {sortOptions.map((option) => (
          <MenuItem key={option} value={SortOptions[option]}>
            {SortOptions[option]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
