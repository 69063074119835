import { styled } from '@mui/material/styles'
import { deepPurple, blueGrey, lightBlue } from '@mui/material/colors'
import Dialog from '@mui/material/Dialog'

const PREFIX = 'EmailCompositionModal'

export const classes = {
  section: `${PREFIX}-section`,
  imageDeleteButton: `${PREFIX}-imageDeleteButton`,
  inactiveSection: `${PREFIX}-inactiveSection`,
  activeSection: `${PREFIX}-activeSection`,
  activeImportImageDiv: `${PREFIX}-activeImportImageDiv`,
  breadcrumbs: `${PREFIX}-breadcrumbs`,
  button: `${PREFIX}-button`,
  buttonsSection: `${PREFIX}-buttonsSection`,
  componentCardContainer: `${PREFIX}-componentCardContainer`,
  dropboxText: `${PREFIX}-dropboxText`,
  formControl: `${PREFIX}-formControl`,
  sectImageContainer: `${PREFIX}-sectImageContainer`,
  sectImage: `${PREFIX}-sectImage`,
  sectImageDiv: `${PREFIX}-sectImageDiv`,
  mainImageDiv: `${PREFIX}-mainImageDiv`,
  importButton: `${PREFIX}-importButton`,
  inlineImportButton: `${PREFIX}-inlineImportButton`,
  importDialog: `${PREFIX}-importDialog`,
  importDialogAppBar: `${PREFIX}-importDialogAppBar`,
  importDialogTitle: `${PREFIX}-importDialogTitle`,
  importImagesContainer: `${PREFIX}-importImagesContainer`,
  importImage: `${PREFIX}-importImage`,
  importImageDiv: `${PREFIX}-importImageDiv`,
  inputFormControl: `${PREFIX}-inputFormControl`,
  purpleButton: `${PREFIX}-purpleButton`,
  radioButton: `${PREFIX}-radioButton`,
  select: `${PREFIX}-select`,
  templatePreview: `${PREFIX}-templatePreview`,
  templatePreviewLabel: `${PREFIX}-templatePreviewLabel`,
  templatePreviewText: `${PREFIX}-templatePreviewText`,
  sentTestButton: `${PREFIX}-sentTestButton`,
  stepsCounter: `${PREFIX}-stepsCounter`,
  stepsCounterActive: `${PREFIX}-stepsCounterActive`,
  stepsLabel: `${PREFIX}-stepsLabel`,
  stepsLabelActive: `${PREFIX}-stepsLabelActive`,
  stepsSeparator: `${PREFIX}-stepsSeparator`,
  subtitle: `${PREFIX}-subtitle`,
  titleSection: `${PREFIX}-titleSection`,
  uploadDropbox: `${PREFIX}-uploadDropbox`,
  uploadDropboxMainImage: `${PREFIX}-uploadDropboxMainImage`,
}

export const DialogStyled = styled(Dialog)(({ theme }) => ({
  [`& .${classes.section}`]: {
    opacity: 0,
    transition: 'opacity 0.5s',
  },

  [`& .${classes.imageDeleteButton}`]: {
    borderRadius: '1px',
    position: 'absolute',
    bottom: 0,
    right: 0,
    height: '38px',
  },

  [`& .${classes.inactiveSection}`]: {
    height: 0,
    overflowY: 'hidden',
  },

  [`& .${classes.activeSection}`]: {
    opacity: 1,
    transition: 'opacity 1.5s',
  },

  [`& .${classes.activeImportImageDiv}`]: {
    outline: '4px solid #1890ff',
  },

  [`& .${classes.breadcrumbs}`]: {
    textAlign: 'center',
    margin: '20px auto 0px',
  },

  [`& .${classes.button}`]: {
    marginLeft: '10px',
  },

  [`& .${classes.buttonsSection}`]: {
    flex: '0 0 auto',
    display: 'flex',
    padding: '8px',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  [`& .${classes.componentCardContainer}`]: {
    display: 'flex',
    maxWidth: '800px',
    justifyContent: 'center',
    alignContent: 'center',
  },

  [`& .${classes.dropboxText}`]: {
    color: 'rgba(0, 0, 0, 0.87)',
  },

  [`& .${classes.formControl}`]: {
    marginBottom: '25px',
    width: '100%',
  },

  [`& .${classes.sectImageContainer}`]: {
    backgroundColor: 'whitesmoke',
    border: '1px solid rgba(0, 0, 0, 0.42)',
    borderRadius: '5px',
    maxHeight: '380px',
    minHeight: '250px',
    marginTop: '60px',
    overflowY: 'auto',
  },

  [`& .${classes.sectImage}`]: {
    alignSelf: 'center',
    width: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    margin: 'auto',
  },

  [`& .${classes.sectImageDiv}`]: {
    display: 'flex',
    float: 'left',
    height: '200px',
    width: '300px',
    margin: '14px 0px 0px 14px',
    position: 'relative',
  },

  [`& .${classes.mainImageDiv}`]: {
    display: 'flex',
    height: '300px',
    width: '600px',
    margin: '20px auto',
    position: 'relative',
  },

  [`& .${classes.importButton}`]: {
    width: '220px',
    position: 'absolute',
    right: '0px',
    marginTop: '20px',
  },

  [`& .${classes.inlineImportButton}`]: {
    margin: '0px 10px',
    width: '220px',
  },

  [`& .${classes.importDialogAppBar}`]: {
    position: 'relative',
  },

  [`& .${classes.importDialogTitle}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  [`& .${classes.importImagesContainer}`]: {
    overflowY: 'auto',
  },

  [`& .${classes.importImage}`]: {
    alignSelf: 'center',
    width: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    margin: 'auto',
  },

  [`& .${classes.importImageDiv}`]: {
    display: 'flex',
    float: 'left',
    height: '300px',
    width: '400px',
    margin: '14px 0px 0px 14px',
  },

  [`& .${classes.inputFormControl}`]: {
    maxWidth: 450,
  },

  [`& .${classes.purpleButton}`]: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    '&:hover': {
      backgroundColor: deepPurple[700],
    },
  },

  [`& .${classes.radioButton}`]: {
    alignSelf: 'flex-start',
    marginTop: '180px',
  },

  [`& .${classes.select}`]: {
    minWidth: 150,
    maxWidth: 450,
  },

  [`& .${classes.templatePreview}`]: {
    border: '1px solid black',
    maxWidth: '230px',
  },

  [`& .${classes.templatePreviewLabel}`]: {
    alignSelf: 'flex-start',
    marginLeft: '100px',
  },

  [`& .${classes.templatePreviewText}`]: {
    textAlign: 'center',
    marginBottom: '4px',
  },

  [`& .${classes.sentTestButton}`]: {
    width: '220px',
    position: 'absolute',
    right: '0px',
    marginTop: '-44px',
  },

  [`& .${classes.stepsCounter}`]: {
    border: '2px solid rgba(0, 0, 0, 0.54)',
    cursor: 'pointer',
    color: theme.palette.getContrastText(blueGrey[400]),
    backgroundColor: blueGrey[400],
    transition: 'all 200ms',
    width: '55px',
    height: '55px',
    fontSize: '1.5rem',
    fontWeight: 600,
    marginBottom: '5px',
  },

  [`& .${classes.stepsCounterActive}`]: {
    border: '2px solid rgba(0, 0, 0, 0.54)',
    cursor: 'pointer',
    color: theme.palette.getContrastText(lightBlue[600]),
    backgroundColor: lightBlue[600],
    transition: 'all 200ms',
    width: '55px',
    height: '55px',
    fontSize: '1.5rem',
    fontWeight: 700,
    marginBottom: '5px',
  },

  [`& .${classes.stepsLabel}`]: {
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.contrastText
        : theme.palette.primary.main,
  },

  [`& .${classes.stepsLabelActive}`]: {
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.contrastText
        : theme.palette.primary.main,
    fontWeight: 700,
  },

  [`& .${classes.stepsSeparator}`]: {
    fontSize: '38px',
    margin: '0px 10px 20px 10px',
    transform: 'scaleX(-1)',
  },

  [`& .${classes.subtitle}`]: {
    fontWeight: 500,
  },

  [`& .${classes.titleSection}`]: {
    padding: '16px 24px',
  },

  [`& .${classes.uploadDropbox}`]: {
    backgroundColor: '#fafafa',
    border: '1px dashed #d9d9d9',
    borderRadius: '2px',
    cursor: 'pointer',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    float: 'left',
    height: '200px',
    width: '300px',
    margin: '14px 0px 0px 14px',
    transition: 'border-color .3s',
    '&:hover': {
      borderColor: '#40a9ff',
    },
  },

  [`& .${classes.uploadDropboxMainImage}`]: {
    marginTop: '54px',
    width: 'auto',
  },
}))
