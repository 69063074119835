import React, {
  useContext,
  useState,
  ChangeEvent,
  SyntheticEvent,
  useEffect,
} from 'react'
import { useNavigate } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'
import { AppStore } from 'store'
import {
  useClientsForUserQuery,
  ClientIdAndName,
  Maybe,
} from 'graphql/gen-types'
import Spinner from 'components/common/misc/Spinner'
import { AppActionType } from 'store/reducers'
import { AuthConsumer, AuthContext } from 'auth/AuthContext'
import { classes, Root } from './Home.styles'

const HomeContent = (): JSX.Element | null => {
  //
  // H O O K S   &   S T A T E
  //
  const navigate = useNavigate()

  const [
    selectedClient,
    setSelectedClient,
  ] = useState<Maybe<ClientIdAndName> | null>(null)
  const { dispatch } = useContext(AppStore)
  const { user } = useContext(AuthContext)
  const { userId } = user

  const { data, loading, error } = useClientsForUserQuery({
    variables: {
      userId,
    },
  })

  const clients = data?.clientsForUser?.clients
  const tgtClient = clients && clients.length === 1 ? clients[0] : null
  const tgtClientId = tgtClient ? tgtClient.id : null

  useEffect(() => {
    if (tgtClientId) {
      dispatch({
        type: AppActionType.SET_SELECTED_CLIENT,
        payload: tgtClient,
      })
      navigate(`/client/${tgtClient?.altName}/home`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tgtClientId])

  //
  // H A N D L E R S
  //
  function __handleChange(
    // eslint-disable-next-line
    evt: ChangeEvent<{}>,
    value: Maybe<ClientIdAndName>
  ): void {
    setSelectedClient(value)
  }

  function __handleButtonClick(evt: SyntheticEvent): void {
    dispatch({
      type: AppActionType.SET_SELECTED_CLIENT,
      payload: selectedClient,
    })
    navigate(`/client/${selectedClient?.altName}/home`)
  }
  if (loading) {
    return (
      <Root className={classes.root}>
        <Spinner message={'Loading...'} />
      </Root>
    )
  }

  if (error) {
    return <div>{error.toString()}</div>
  }

  if (!clients) {
    return null
  }

  return (
    <Root className={classes.root}>
      <Autocomplete
        id="combo-box-select-client"
        options={clients}
        getOptionLabel={(option): string => option?.name || ''}
        style={{ width: 300 }}
        onChange={__handleChange}
        renderInput={(params): JSX.Element => (
          <TextField {...params} label="Select Builder" variant="outlined" />
        )}
      />
      {selectedClient !== null && (
        <Button
          classes={{ root: classes.button }}
          size="large"
          variant="contained"
          onClick={__handleButtonClick}
        >{`Let's Go`}</Button>
      )}
    </Root>
  )
}

const Home = (): JSX.Element | null => {
  //
  // M A I N   R E N D E R I N G
  //
  return (
    <AuthConsumer>
      {({ authenticated }): JSX.Element | null => {
        if (!authenticated) {
          return null
        }
        return <HomeContent />
      }}
    </AuthConsumer>
  )
}

export default Home
