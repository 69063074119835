import React, { useState, useContext, useEffect } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Button from '@mui/material/Button'
import HomeWork from '@mui/icons-material/HomeWork'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Assessment from '@mui/icons-material/Assessment'
import TrendingUp from '@mui/icons-material/TrendingUp'
import LocationOn from '@mui/icons-material/LocationOn'
import Settings from '@mui/icons-material/Settings'
import ExitToApp from '@mui/icons-material/ExitToApp'
import AssessmentIcon from '@mui/icons-material/Assessment'
import Assignment from '@mui/icons-material/Assignment'
import { useNavigate, useLocation } from 'react-router-dom'
import { matchPath } from 'react-router'
import UserInfo from './UserInfo'
import { AppStore } from 'store'
import { AnalyticsStore } from 'store/analyticsStore'
import { AppAction, AppActionType } from '../../../store/reducers'
import LeadsInsightIcon from 'components/icons/LeadsInsightIcon'
import AnalyticsInsightIcon from 'components/icons/AnalyticsInsightIcon'
import hydrationStore from 'store/HydrationStore'
import { AuthContext, HandleSignOutFn } from 'auth/AuthContext'
import {
  ANALYTICS_APP,
  ANALYTICS_OVERVIEW_AUTH,
  ANALYTICS_SUBMODULE_AUTH,
  BUY_ONLINE_APP,
  LEADS_APP,
  LEAD_ANALYTICS_OVERVIEW_AUTH,
  LEAD_OVERVIEW_AUTH,
  MARKETING_APP,
  MARKETING_AUTH,
  STARTER_INVALID_FEATURE_MESSAGE,
  STARTER_OR_PRO_INVALID_FEATURE_MESSAGE,
} from '../../../constants'
import { NavigateFunction } from 'react-router'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import {
  ColonnadeRolePrivilegesEnum,
  ColonnadeRolesEnum,
  ColonnadeSubscriptionLevelsEnum,
} from '../../../graphql/gen-types'
import ExploreIcon from '@mui/icons-material/Explore'
// import jwtDecode from 'jwt-decode'
import ModeSwitch from 'components/common/custom-inputs/ModeSwitch'
import {
  getUnauthorizedAccessReason,
  isAnewgoMember,
  userHasSubscriptionAccess,
  isSalesAdmin,
} from 'utils/authorizationHelpers'
import { SubscriptionTooltip } from 'components/icons/SubscriptionTooltip'
import MarketingMenu from './drawers/MarketingMenu'
import MarketingAutomationIcon from 'components/icons/MarketingAutomationIcon'
import { classes, Root } from './MainDrawerContent.styles'
import BuyOnlineMenu from './drawers/BuyOnlineMenu'
import BuyOnlineIcon from '../../icons/BuyOnlineIcon'
import {
  analyticsDrawerMenuIndexes,
  buyOnlineDrawerMenuIndexes,
  leadsDrawerRoutesMenuIndexes,
  marketingDrawerMenuIndexes,
  OVERVIEW_INDEX,
  REPORTS_BY_LOCATION_INDEX,
  REPORTS_BY_PLAN_INDEX,
  REPORTS_BY_SALESCENTER_INDEX,
  ADMIN_INDEX,
  SIGN_OUT_INDEX,
  LEADS_REPORT_INDEX,
  AGENT_PROSPECT_INDEX,
  INDUSTRY_TREND_INDEX,
} from './constants'
import AuthorizationListItem from 'components/utils/AuthorizationListItem'

const ASD_URL =
  'https://anewgo.atlassian.net/servicedesk/customer/portal/3/group/4/create'

function __openInNewTab(url: string): void {
  const win = window.open(url, '_blank')
  win?.focus()
}
export interface MenuIf {
  selectedMenuIndex: number
  selectedClientName: string
  classes: Record<'selected' | 'toolbar' | 'nested' | 'iconNormalColor', string>
  navigate: NavigateFunction
  handleSignOut: HandleSignOutFn
  dispatch: React.Dispatch<AppAction>
  handleInsightClick: (type: string) => void
  handleMarketingAutomationClick: () => void
  handleSelectBuilderClick: () => void
  roleId: number
  handleDrawerToggle?: () => void
}

const roles = Object.keys(ColonnadeRolesEnum)

const getRole = (roleId: number) => {
  return roles[roleId - 1].split(/(?=[A-Z])/).join(' ')
}

const AnalyticsMenu = ({
  selectedMenuIndex,
  selectedClientName,
  classes,
  navigate,
  handleSignOut,
  dispatch,
  handleInsightClick,
  handleMarketingAutomationClick,
  handleSelectBuilderClick,
  roleId,
  handleDrawerToggle,
}: MenuIf): JSX.Element => {
  const [openReports, setOpenReports] = useState(true)
  const handleClick = (): void => setOpenReports(!openReports)

  // ENABLE THIS BACK ON WHEN WE ARE READY TO RELEASE INDUSTRY TRENDS
  // NOTE THIS STILL NEEDS RENDERNIG HOUSE TO BE ON ENTERPRISE SUBSCRIPTION BECAUSE OF AUTH-DIRECTIVES CHECK
  // ONLY ALLOW ANEWGO MEMBERS TO ACCESS THIS FEATURE FOR NOW
  // const industryTrendsEnabled = userHasSubscriptionAccess(['ENTERPRISE'])
  const industryTrendsEnabled = isAnewgoMember(roleId) ? true : false

  const overviewUnauthorized = getUnauthorizedAccessReason(
    ANALYTICS_OVERVIEW_AUTH.privileges,
    ANALYTICS_OVERVIEW_AUTH.subscription
  )

  const analyticsUnauthorized = getUnauthorizedAccessReason(
    ANALYTICS_SUBMODULE_AUTH.privileges,
    ANALYTICS_SUBMODULE_AUTH.subscription
  )

  const marketingUnauthorized = getUnauthorizedAccessReason(
    MARKETING_AUTH.privileges,
    MARKETING_AUTH.subscription
  )
  const ENTERPRISE_SUBSCRIBED = userHasSubscriptionAccess([
    ColonnadeSubscriptionLevelsEnum.Enterprise,
  ])
  const buyOnlineEnabled = ENTERPRISE_SUBSCRIBED
  const handleAnalyticsMenuClick = (
    index: React.SetStateAction<number>
  ): void => {
    // re-route
    let route = ''
    let tabs = ['']
    switch (index) {
      case OVERVIEW_INDEX:
        route = `analytics/client/${selectedClientName}/overview`
        tabs = ['Overview']
        break
      case REPORTS_BY_LOCATION_INDEX:
        route = `analytics/client/${selectedClientName}/report/locations`
        tabs = ['Locations']
        break
      case REPORTS_BY_PLAN_INDEX:
        route = `analytics/client/${selectedClientName}/report/plans`
        tabs = ['Plans']
        break
      case REPORTS_BY_SALESCENTER_INDEX:
        route = `analytics/client/${selectedClientName}/salescenter/overview`
        tabs = ['SalesCenter']
        break
      case ADMIN_INDEX:
        route = `analytics/client/${selectedClientName}/admin`
        tabs = ['Admin']
        break
      case INDUSTRY_TREND_INDEX:
        route = `analytics/client/${selectedClientName}/industryTrends`
        tabs = ['Admin']
        break
      case SIGN_OUT_INDEX:
        route = '/login'
        hydrationStore.clearAll() // clear all local storage variables
        handleSignOut() // reInitialize props
        break
      default:
        route = '/'
        break
    }
    dispatch({
      type: AppActionType.SET_SELECTED_TABS,
      payload: tabs,
    })
    navigate(route)

    if (handleDrawerToggle) {
      handleDrawerToggle()
    }
  }

  return (
    <>
      <List
        component="nav"
        aria-labelledby="mha-analytics-reports"
        subheader={
          <ListSubheader component="div" id="mha-analytics-reports">
            My Home App™ Analytics
          </ListSubheader>
        }
      >
        <List>
          <AuthorizationListItem
            classes={{ selected: classes.selected }}
            onClick={(): void => handleAnalyticsMenuClick(OVERVIEW_INDEX)}
            selected={selectedMenuIndex === OVERVIEW_INDEX}
            icon={<Assignment />}
            disabled={!!overviewUnauthorized || isSalesAdmin(roleId)}
            disabledReason={overviewUnauthorized}
            title="Overview"
          />
        </List>
        <ListItem
          button
          onClick={handleClick}
          classes={{ selected: classes.selected }}
        >
          <ListItemIcon>
            <Assessment />
          </ListItemIcon>
          <ListItemText primary="Reports" />
          {openReports ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openReports} timeout="auto" unmountOnExit>
          <List component="div">
            <AuthorizationListItem
              style={{ paddingLeft: 35 }}
              classes={{ selected: classes.selected }}
              onClick={(): void =>
                handleAnalyticsMenuClick(REPORTS_BY_LOCATION_INDEX)
              }
              selected={selectedMenuIndex === REPORTS_BY_LOCATION_INDEX}
              icon={<LocationOn />}
              disabled={!!analyticsUnauthorized || isSalesAdmin(roleId)}
              disabledReason={analyticsUnauthorized}
              title="By Location"
            />
            <AuthorizationListItem
              style={{ paddingLeft: 35 }}
              classes={{ selected: classes.selected }}
              onClick={(): void =>
                handleAnalyticsMenuClick(REPORTS_BY_PLAN_INDEX)
              }
              selected={selectedMenuIndex === REPORTS_BY_PLAN_INDEX}
              icon={<HomeWork />}
              disabled={!!analyticsUnauthorized || isSalesAdmin(roleId)}
              disabledReason={analyticsUnauthorized}
              title="By Plan"
            />
            <AuthorizationListItem
              style={{ paddingLeft: 35 }}
              classes={{ selected: classes.selected }}
              onClick={(): void =>
                handleAnalyticsMenuClick(REPORTS_BY_SALESCENTER_INDEX)
              }
              selected={selectedMenuIndex === REPORTS_BY_SALESCENTER_INDEX}
              icon={<ExploreIcon />}
              disabled={!!analyticsUnauthorized || isSalesAdmin(roleId)}
              disabledReason={analyticsUnauthorized}
              title="By Sales Center"
            />
          </List>
        </Collapse>
      </List>
      <Divider />
      <List>
        <ListItem
          button
          // disabled={!leadsEnabled}
          classes={{ selected: classes.selected }}
          onClick={() => handleInsightClick(LEADS_APP)}
        >
          <ListItemIcon classes={{ root: classes.iconNormalColor }}>
            <LeadsInsightIcon width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={'Leads Insights'} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <AuthorizationListItem
          classes={{ selected: classes.selected }}
          onClick={() => handleMarketingAutomationClick()}
          icon={<MarketingAutomationIcon width={24} height={24} />}
          disabled={!!marketingUnauthorized || isSalesAdmin(roleId)}
          disabledReason={STARTER_INVALID_FEATURE_MESSAGE}
          title="Targeted Marketing"
        />
      </List>
      <Divider />
      <List>
        <AuthorizationListItem
          classes={{ selected: classes.selected }}
          onClick={() => handleInsightClick(BUY_ONLINE_APP)}
          icon={<BuyOnlineIcon width={24} height={24} />}
          disabled={!buyOnlineEnabled || isSalesAdmin(roleId)}
          disabledReason={STARTER_OR_PRO_INVALID_FEATURE_MESSAGE}
          title="Reserve Online"
        />
      </List>
      <Divider />
      <List>
        <AuthorizationListItem
          classes={{ selected: classes.selected }}
          onClick={() => handleAnalyticsMenuClick(INDUSTRY_TREND_INDEX)}
          icon={<TrendingUp width={24} height={24} />}
          disabled={!industryTrendsEnabled}
          disabledReason={'Coming Soon!'}
          title="Industry Trends"
        />
      </List>
      <Divider />
      <List>
        {isAnewgoMember(roleId) && (
          <ListItem
            classes={{ selected: classes.selected }}
            button
            onClick={(): void => handleSelectBuilderClick()}
          >
            <ListItemIcon>
              <KeyboardReturnIcon />
            </ListItemIcon>
            <ListItemText primary={'Select Builder'} />
          </ListItem>
        )}
        <ListItem
          classes={{ selected: classes.selected }}
          button
          onClick={(): void => handleAnalyticsMenuClick(ADMIN_INDEX)}
          selected={selectedMenuIndex === ADMIN_INDEX}
        >
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText primary={'Admin'} />
        </ListItem>
        <ListItem
          button
          classes={{ selected: classes.selected }}
          onClick={(): void => handleAnalyticsMenuClick(SIGN_OUT_INDEX)}
        >
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary={'Sign Out'} />
        </ListItem>
      </List>
    </>
  )
}

const LeadsMenu = ({
  selectedMenuIndex,
  selectedClientName,
  classes,
  navigate,
  handleSignOut,
  handleInsightClick,
  handleMarketingAutomationClick,
  handleSelectBuilderClick,
  roleId,
  handleDrawerToggle,
}: MenuIf): JSX.Element => {
  const handleLeadsMenuClick = (index: React.SetStateAction<number>): void => {
    // re-route
    let route = ''
    switch (index) {
      case OVERVIEW_INDEX:
        route = `leads/client/${selectedClientName}/overview`
        break
      case LEADS_REPORT_INDEX:
        route = `leads/client/${selectedClientName}/analytics`
        break
      case ADMIN_INDEX:
        route = `leads/client/${selectedClientName}/admin`
        break
      case AGENT_PROSPECT_INDEX:
        route = `leads/client/${selectedClientName}/agentAssignments`
        break
      case SIGN_OUT_INDEX:
        route = '/login'
        hydrationStore.clearAll() // clear all local storage variables
        handleSignOut() // reInitialize props
        break
      default:
        route = '/'
        break
    }
    navigate(route)

    if (handleDrawerToggle) {
      handleDrawerToggle()
    }
  }

  // Lead overview is only accessible to roles with LEAD_READ or LEAD_READ_WRITE permissions
  const overviewUnauthorized = getUnauthorizedAccessReason(
    LEAD_OVERVIEW_AUTH.privileges,
    LEAD_OVERVIEW_AUTH.subscription
  )

  // Analytics overview can be accessed by roles with ANALYTICS_READ as well
  const analyticsUnathorized = getUnauthorizedAccessReason(
    LEAD_ANALYTICS_OVERVIEW_AUTH.privileges,
    LEAD_ANALYTICS_OVERVIEW_AUTH.subscription
  )

  const marketingUnauthorized = getUnauthorizedAccessReason(
    MARKETING_AUTH.privileges,
    MARKETING_AUTH.subscription
  )
  const ENTERPRISE_SUBSCRIBED = userHasSubscriptionAccess([
    ColonnadeSubscriptionLevelsEnum.Enterprise,
  ])
  const buyOnlineEnabled = ENTERPRISE_SUBSCRIBED

  return (
    <>
      <List
        component="nav"
        aria-labelledby="leads-reports"
        subheader={
          <ListSubheader component="div" id="leads-reports">
            Leads
          </ListSubheader>
        }
      >
        <List>
          <AuthorizationListItem
            classes={{ selected: classes.selected }}
            onClick={(): void => handleLeadsMenuClick(OVERVIEW_INDEX)}
            selected={selectedMenuIndex === OVERVIEW_INDEX}
            icon={<Assignment />}
            disabled={!!overviewUnauthorized}
            disabledReason={overviewUnauthorized}
            title="Lead Overview"
          />
          <AuthorizationListItem
            classes={{ selected: classes.selected }}
            onClick={(): void => handleLeadsMenuClick(LEADS_REPORT_INDEX)}
            selected={selectedMenuIndex === LEADS_REPORT_INDEX}
            icon={<AssessmentIcon />}
            disabled={!!analyticsUnathorized}
            disabledReason={analyticsUnathorized}
            title="Lead Analytics"
          />
        </List>
      </List>
      <Divider />
      <List>
        <AuthorizationListItem
          classes={{ selected: classes.selected }}
          onClick={() => handleInsightClick(ANALYTICS_APP)}
          selected={selectedMenuIndex === OVERVIEW_INDEX}
          icon={<AnalyticsInsightIcon width={24} height={24} />}
          title="Analytics Insights"
          disabled={isSalesAdmin(roleId)}
          disabledReason={STARTER_INVALID_FEATURE_MESSAGE}
        />
      </List>
      <Divider />
      <List>
        <AuthorizationListItem
          classes={{ selected: classes.selected }}
          onClick={() => handleMarketingAutomationClick()}
          icon={<MarketingAutomationIcon width={24} height={24} />}
          disabled={!!marketingUnauthorized || isSalesAdmin(roleId)}
          disabledReason={STARTER_INVALID_FEATURE_MESSAGE}
          title="Targeted Marketing"
        />
      </List>
      <Divider />
      <List>
        <AuthorizationListItem
          classes={{ selected: classes.selected }}
          onClick={() => handleInsightClick(BUY_ONLINE_APP)}
          icon={<BuyOnlineIcon width={24} height={24} />}
          disabled={!buyOnlineEnabled || isSalesAdmin(roleId)}
          disabledReason={STARTER_OR_PRO_INVALID_FEATURE_MESSAGE}
          title="Reserve Online"
        />
      </List>
      <Divider />
      <List>
        <ListItem
          button
          classes={{ selected: classes.selected }}
          onClick={(): void => handleLeadsMenuClick(AGENT_PROSPECT_INDEX)}
          selected={selectedMenuIndex === AGENT_PROSPECT_INDEX}
        >
          <ListItemIcon>
            <AssignmentIndIcon />
          </ListItemIcon>
          <ListItemText primary={'Lead Assignments'} />
        </ListItem>
        <ListItem
          classes={{ selected: classes.selected }}
          button
          onClick={(): void => handleLeadsMenuClick(ADMIN_INDEX)}
          selected={selectedMenuIndex === ADMIN_INDEX}
        >
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText primary={'Admin'} />
        </ListItem>
        {isAnewgoMember(roleId) && (
          <ListItem
            classes={{ selected: classes.selected }}
            button
            onClick={(): void => handleSelectBuilderClick()}
          >
            <ListItemIcon>
              <KeyboardReturnIcon />
            </ListItemIcon>
            <ListItemText primary={'Select Builder'} />
          </ListItem>
        )}
        <ListItem
          button
          classes={{ selected: classes.selected }}
          onClick={(): void => handleLeadsMenuClick(SIGN_OUT_INDEX)}
        >
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary={'Sign Out'} />
        </ListItem>
      </List>
    </>
  )
}

interface MainDrawerContentI {
  handleDrawerToggle?: () => void
}

const MainDrawerContent = ({
  handleDrawerToggle,
}: MainDrawerContentI): JSX.Element => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down('sm'))
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0)

  const navigate = useNavigate()
  const { handleSignOut, user } = useContext(AuthContext)
  const { appState } = useContext(AppStore)
  const { dispatch } = useContext(AnalyticsStore)
  const { selectedClient, selectedApp } = appState
  const selectedClientName = selectedClient ? selectedClient.altName : ''
  const appStore = useContext(AppStore)
  const location = useLocation()
  const { pathname } = location

  const recalculateLeadsBar = () => {
    if (selectedApp !== LEADS_APP) {
      appStore.dispatch({
        type: AppActionType.SET_SELECTED_APP,
        payload: LEADS_APP,
      })
    }
    leadsDrawerRoutesMenuIndexes.forEach((row) => {
      const match = matchPath(row.route, pathname)
      if (match) setSelectedMenuIndex(row.index)
    })
  }

  const recalculateAnalyticsBar = () => {
    if (selectedApp !== ANALYTICS_APP) {
      appStore.dispatch({
        type: AppActionType.SET_SELECTED_APP,
        payload: ANALYTICS_APP,
      })
    }
    analyticsDrawerMenuIndexes.forEach((row) => {
      const match = matchPath(row.route, pathname)
      if (match) setSelectedMenuIndex(row.index)
    })
  }

  const recalculateMarketingBar = () => {
    if (selectedApp !== MARKETING_APP) {
      appStore.dispatch({
        type: AppActionType.SET_SELECTED_APP,
        payload: MARKETING_APP,
      })
    }
    marketingDrawerMenuIndexes.forEach((row) => {
      const match = matchPath(row.route, pathname)
      if (match) setSelectedMenuIndex(row.index)
    })
  }

  const recalculateBuyOnlineBar = () => {
    if (selectedApp !== BUY_ONLINE_APP) {
      appStore.dispatch({
        type: AppActionType.SET_SELECTED_APP,
        payload: BUY_ONLINE_APP,
      })
    }
    buyOnlineDrawerMenuIndexes.forEach((row) => {
      const match = matchPath(row.route, pathname)
      if (match) setSelectedMenuIndex(row.index)
    })
  }

  useEffect(() => {
    if (pathname.includes('leads/client')) {
      recalculateLeadsBar()
    } else if (pathname.includes('analytics/client')) {
      recalculateAnalyticsBar()
    } else if (pathname.includes('targeted-marketing/client')) {
      recalculateMarketingBar()
    } else if (pathname.includes('reserve-online/client')) {
      recalculateBuyOnlineBar()
    }
  }, [pathname])

  const handleInsightClick = (appType: string): void => {
    let route = ''
    switch (appType) {
      case ANALYTICS_APP:
        route = `/analytics/client/${selectedClientName}/overview`
        break
      case LEADS_APP:
        route = `/leads/client/${selectedClientName}/overview`
        break
      case BUY_ONLINE_APP:
        route = `/reserve-online/client/${selectedClientName}/integration`
        break
      default:
        break
    }
    appStore.dispatch({
      type: AppActionType.SET_SELECTED_APP,
      payload: appType,
    })
    navigate(route)
  }

  const handleMarketingAutomationClick = (): void => {
    const route = `/targeted-marketing/client/${selectedClientName}/email-campaign`
    appStore.dispatch({
      type: AppActionType.SET_SELECTED_APP,
      payload: MARKETING_APP,
    })
    navigate(route)
  }

  const handleSelectBuilderClick = (): void => {
    navigate(`/home`)
  }

  let menuListComponent: JSX.Element = <></>
  switch (selectedApp) {
    case ANALYTICS_APP:
      menuListComponent = (
        <AnalyticsMenu
          selectedClientName={selectedClientName}
          selectedMenuIndex={selectedMenuIndex}
          classes={classes}
          navigate={navigate}
          handleSignOut={handleSignOut}
          dispatch={dispatch}
          handleInsightClick={handleInsightClick}
          handleMarketingAutomationClick={handleMarketingAutomationClick}
          handleSelectBuilderClick={handleSelectBuilderClick}
          roleId={user.roleId}
          handleDrawerToggle={handleDrawerToggle}
        />
      )
      break

    case LEADS_APP:
      menuListComponent = (
        <LeadsMenu
          selectedClientName={selectedClientName}
          selectedMenuIndex={selectedMenuIndex}
          classes={classes}
          navigate={navigate}
          handleSignOut={handleSignOut}
          dispatch={dispatch}
          handleInsightClick={handleInsightClick}
          handleMarketingAutomationClick={handleMarketingAutomationClick}
          handleSelectBuilderClick={handleSelectBuilderClick}
          roleId={user.roleId}
          handleDrawerToggle={handleDrawerToggle}
        />
      )
      break
    case MARKETING_APP:
      menuListComponent = (
        <MarketingMenu
          selectedClientName={selectedClientName}
          selectedMenuIndex={selectedMenuIndex}
          classes={classes}
          navigate={navigate}
          handleSignOut={handleSignOut}
          dispatch={dispatch}
          handleInsightClick={handleInsightClick}
          handleMarketingAutomationClick={handleMarketingAutomationClick}
          handleSelectBuilderClick={handleSelectBuilderClick}
          roleId={user.roleId}
          handleDrawerToggle={handleDrawerToggle}
        />
      )
      break
    case BUY_ONLINE_APP:
      menuListComponent = (
        <BuyOnlineMenu
          selectedClientName={selectedClientName}
          selectedMenuIndex={selectedMenuIndex}
          classes={classes}
          navigate={navigate}
          handleSignOut={handleSignOut}
          dispatch={dispatch}
          handleInsightClick={handleInsightClick}
          handleMarketingAutomationClick={handleMarketingAutomationClick}
          handleSelectBuilderClick={handleSelectBuilderClick}
          roleId={user.roleId}
          handleDrawerToggle={handleDrawerToggle}
        />
      )
      break
  }

  return (
    <Root className={classes.mainDrawer}>
      <div className={classes.toolbar}>{xs && <ModeSwitch />}</div>
      <Divider />
      <UserInfo
        name={`${user.firstName} ${user.lastName}`}
        role={getRole(user.roleId)}
      />
      {menuListComponent}
      <Button
        onClick={(e) => __openInNewTab(ASD_URL)}
        className={classes.feedback}
        variant="contained"
      >
        Feedback
      </Button>
    </Root>
  )
}

export default MainDrawerContent
