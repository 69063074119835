import { styled } from '@mui/material/styles'
import ComponentCard from 'components/common/layout/ComponentCard'

const PREFIX = 'PopularColors'

export const classes = {
  tooltip: `${PREFIX}-tooltip`,
}

export const StyledComponentCard = styled(ComponentCard)(({ theme }) => ({
  [`& .${classes.tooltip}`]: {
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.secondary.light
        : theme.palette.secondary.dark,
  },
}))
