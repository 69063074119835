import React, { useContext, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { Editor } from '@tinymce/tinymce-react'
import { useAtom } from 'jotai'
import { snackbarConfigAtom } from 'store/atoms'
import {
  EmailCampaignCohort,
  useSendFollowUpEmailMutation,
} from '../../../../graphql/gen-types'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import ResponsiveTable from 'components/common/tables/ResponsiveTable'
import { EmailCampaignStatusTitles } from '../EmailCampaigns'
import { AuthContext } from 'auth/AuthContext'
import { AppStore } from 'store'
import { getNamesFromFullName, isDefined } from 'utils/functions'

interface FollowUpEmailModalProps {
  emailCampaignId: number
  leads: EmailCampaignCohort[]
  open: boolean
  onClose?: () => void
}

export default function FollowUpEmailModal({
  emailCampaignId,
  leads,
  open,
  onClose,
}: FollowUpEmailModalProps): JSX.Element {
  const { user } = useContext(AuthContext)
  const { appState } = useContext(AppStore)
  const { selectedClient } = appState
  const clientName = selectedClient?.altName || ''
  const [content, setContent] = useState('')
  const [snackbarConfig, setSnackbarConfig] = useAtom(snackbarConfigAtom)

  const [sendEmailCampaignMutation] = useSendFollowUpEmailMutation()

  const editorSettings = {
    branding: false,
    height: '350px',
    plugins: 'link image code lists advlist',
    toolbar:
      'undo redo | bold italic underline | alignleft ' +
      'aligncenter alignright | bullist | code',
  }

  const triggerSnackbar = (
    message: string,
    severity: 'error' | 'success' | 'info' | 'warning' | undefined
  ) => {
    setSnackbarConfig({
      ...snackbarConfig,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      autoHideDuration: 6000,
      open: true,
      message,
      severity,
    })
  }

  const handleSubmit = async () => {
    const emails = leads.map((lead) => lead?.prospect?.email).filter(isDefined)

    try {
      await sendEmailCampaignMutation({
        variables: {
          clientName,
          emailCampaignId,
          ownerId: user.userId,
          emails,
          content,
        },
      })
      triggerSnackbar('Follow up email(s) sent successfully', 'success')
      onClose?.()
    } catch (e: any) {
      triggerSnackbar(
        `Encountered error sending follow up emails: ${e.message}`,
        'error'
      )
    }
  }

  return (
    <Dialog open={open} maxWidth="lg" fullWidth disableEnforceFocus>
      <DialogTitle>
        <Typography component="div" variant="h5" color="primary">
          Follow Up Email
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Leads</Typography>
            This email will be sent to the following leads
          </Grid>
          <Grid item xs={12}>
            <ResponsiveTable
              data={leads}
              columns={['Name', 'Email', 'Status']}
              row={(lead: unknown) => [
                getNamesFromFullName(
                  (lead as EmailCampaignCohort)?.prospect?.name
                )[0],
                (lead as EmailCampaignCohort)?.prospect?.email,
                EmailCampaignStatusTitles[
                  (lead as EmailCampaignCohort)?.status
                ],
              ]}
              dense
              initialRows={5}
            ></ResponsiveTable>
          </Grid>

          <Grid item xs={12}>
            <Editor
              initialValue={content}
              init={editorSettings}
              onBlur={(e) => setContent(e.target.getContent())}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => onClose?.()}
        >
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={handleSubmit}>
          Send
        </Button>
      </DialogActions>
    </Dialog>
  )
}
