import React, { ReactElement, useCallback } from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select/Select'
import clsx from 'clsx'
import { BuildStyles } from './LocationsSelect.styles'

const PREFIX = 'LocationsSelect'
const { classes, StyledFormControl } = BuildStyles(PREFIX)

export type LocationsSelectTypes = string | number

export interface LocationsSelectProps<T extends LocationsSelectTypes> {
  field: string
  name: string
  items: Map<T, string>
  selected?: T
  onSelect: (value?: T) => void
  larger?: boolean
  multiple?: boolean
  industryTrendsSelect?: boolean
  locationsFilterSelect?: boolean
  leadsLocationSelect?: boolean
}

export function LocationsSelect<T extends LocationsSelectTypes>({
  field,
  name,
  items,
  selected,
  onSelect,
  larger = false,
  industryTrendsSelect = false,
  locationsFilterSelect = false,
  leadsLocationSelect = false,
}: LocationsSelectProps<T>): ReactElement | null {
  const values: [T | string, string][] = [...items.entries()]
  if (values.length > 1) values.unshift(['all', 'View All'])

  let selectedValue = selected || ''
  if (!selectedValue && values.length >= 1) selectedValue = values[0][0]

  const selectionHandler = useCallback(
    (value) => onSelect?.(value === 'all' ? undefined : (value as T)),
    [onSelect]
  )

  return (
    <StyledFormControl
      className={classes.formControl}
      variant="standard"
      industryTrendsSelect={industryTrendsSelect}
      locationsFilterSelect={locationsFilterSelect}
      leadsLocationSelect={leadsLocationSelect}
    >
      <InputLabel htmlFor="select-region">Select {name}</InputLabel>
      <Select
        variant="standard"
        data-testid={`${field}Select`}
        className={
          larger ? clsx(classes.select, classes.selectLarger) : classes.select
        }
        value={selectedValue}
        onChange={(e) => selectionHandler(e.target.value)}
        inputProps={{
          name: field,
          id: `select-${field}`,
        }}
      >
        {values.map(([key, value]) => (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  )
}
