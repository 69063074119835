import React from 'react'
import { useTheme } from '@mui/material/styles'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import useMediaQuery from '@mui/material/useMediaQuery'
import Alert from '@mui/material/Alert'

import ProspectProfile from 'components/leads/prospect-page/ProspectProfile'
import {
  LeadRanking,
  ProspectTopCommunity,
  ProspectTopPlan,
  Agent,
  ColonnadeSubscriptionLevelsEnum,
  Prospect,
} from 'graphql/gen-types'

import ResponsiveTable, {
  ResponsiveCell,
} from 'components/common/tables/ResponsiveTable'
import Typography from '@mui/material/Typography'
import { STARTER_INVALID_FEATURE_MESSAGE } from '../../../constants'
import { SubscriptionTooltip } from 'components/icons/SubscriptionTooltip'
import { userHasSubscriptionAccess } from 'utils/authorizationHelpers'
import JourneyBar, {
  createStepsFromLeadRankingData,
} from 'components/common/journey-bar/JourneyBar'
import ProspectAgentAssign from '../prospect-page/ProspectAgentAssign'
import { useProspectQuery } from '../../../graphql/gen-types'
import Skeleton from '@mui/material/Skeleton'

const RESPONSE_WIDTH = 150 // at 150 pixels, change to responsive table. Putting 0 defaults to 'md' size.
const INITIAL_ROWS = 5

/**
 * Modal for adding new milestones.
 */
export default function LeadDetailModal({
  data,
  open,
  onClose,
  onSubmit,
  agents,
}: {
  data: LeadRanking | null | undefined
  open: boolean
  onClose: () => void
  onSubmit: () => void
  agents: Agent[]
}): JSX.Element {
  const theme = useTheme()
  const fullWidth = useMediaQuery(theme.breakpoints.down('lg'))
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const prospectEmail = data?.email
  const clientName = data?.clientName
  const { data: prospectData, loading, error } = useProspectQuery({
    skip: !prospectEmail || !clientName,
    variables: {
      clientName,
      email: prospectEmail,
    },
  })

  const PRO_OR_ENTERPRISE_SUBSCRIBED = userHasSubscriptionAccess([
    ColonnadeSubscriptionLevelsEnum.Pro,
    ColonnadeSubscriptionLevelsEnum.Enterprise,
  ])

  const isInvalidProspect = data?.lastSeen === undefined
  const prospect = prospectData?.colonnadeApiProspectByEmail as Prospect

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      scroll="paper"
    >
      <DialogTitle id="form-dialog-title">Lead Details</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {isInvalidProspect && (
            <Grid item xs={12} lg={12}>
              <Alert severity="warning">
                This Lead has not been updated yet. Additional details are not
                available. Please try again later.
              </Alert>
            </Grid>
          )}
          <Grid item xs={12} md={12} lg={12}>
            {!loading ? (
              <JourneyBar
                steps={createStepsFromLeadRankingData(
                  data,
                  prospect as Prospect
                )}
                responseSize="sm"
              />
            ) : (
              <Skeleton
                variant="text"
                width="100%"
                height={140}
                data-testid="LeadRankingDataLoading"
              />
            )}
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <ProspectAgentAssign
              prospect={prospect}
              agents={agents}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <ProspectProfile
              data={
                {
                  ...prospect,
                  // nest location data into statistics field
                  statistics: {
                    country: data?.country,
                    state: data?.state,
                    city: data?.city,
                  },
                } as Prospect
              }
              rankingData={data}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant="h6">Browsed Communities</Typography>
            <ResponsiveTable
              data={data?.communities || []}
              columns={[
                <ResponsiveCell
                  responseSize={RESPONSE_WIDTH}
                  align="left"
                  key="header-cell-community"
                >
                  Community
                </ResponsiveCell>,
              ]}
              generateRow={(
                dataRow: ProspectTopCommunity | null
              ): JSX.Element | null => {
                if (!dataRow) {
                  return null
                }
                return (
                  <>
                    <ResponsiveCell
                      responseSize={RESPONSE_WIDTH}
                      align="left"
                      key="row-cell-community-name"
                    >
                      {dataRow.communityName}
                    </ResponsiveCell>
                  </>
                )
              }}
              dense={true}
              responseSize={RESPONSE_WIDTH}
              initialRows={INITIAL_ROWS}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant="h6">Browsed Plans</Typography>
            <ResponsiveTable
              data={data?.plans || []}
              columns={[
                <ResponsiveCell
                  responseSize={RESPONSE_WIDTH}
                  align="left"
                  key="header-cell-plan"
                >
                  Plan
                </ResponsiveCell>,
                <ResponsiveCell
                  responseSize={RESPONSE_WIDTH}
                  align="left"
                  key="header-cell-elevation"
                >
                  Elevation
                </ResponsiveCell>,
              ]}
              generateRow={(dataRow: unknown): JSX.Element => {
                const row = dataRow as ProspectTopPlan
                return (
                  <>
                    <ResponsiveCell
                      responseSize={RESPONSE_WIDTH}
                      align="left"
                      key="row-cell-plan-name"
                    >
                      {row?.planName}
                    </ResponsiveCell>
                    <ResponsiveCell
                      responseSize={RESPONSE_WIDTH}
                      align="left"
                      key="row-cell-elev-caption"
                    >
                      {row?.elevationCaption}
                    </ResponsiveCell>
                  </>
                )
              }}
              dense={true}
              responseSize={RESPONSE_WIDTH}
              initialRows={INITIAL_ROWS}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          Close
        </Button>
        <Button
          onClick={onSubmit}
          color="primary"
          variant="contained"
          disabled={isInvalidProspect || !PRO_OR_ENTERPRISE_SUBSCRIBED}
        >
          Open Details Page
        </Button>
        {!PRO_OR_ENTERPRISE_SUBSCRIBED && (
          <SubscriptionTooltip
            iconStyle={'openDetailsPageIcon'}
            message={STARTER_INVALID_FEATURE_MESSAGE}
          />
        )}
      </DialogActions>
    </Dialog>
  )
}
