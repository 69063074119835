import { styled } from '@mui/material/styles'

const PREFIX = 'PlansGrid'

export const classes = {
  root: `${PREFIX}-root`,
}

export const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',
    width: '100%',
    height: '100%',
    paddingTop: '20px',
  },
}))
