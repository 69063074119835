import React from 'react'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import Snackbar from '@mui/material/Snackbar'
import CloseIcon from '@mui/icons-material/Close'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { snackbarConfigAtom } from 'store/atoms'
import { useAtom } from 'jotai'

const PREFIX = 'SnackbarNotification'

const classes = {
  snackbar: `${PREFIX}-snackbar`,
}

const StyledSnackbar = styled(Snackbar)(() => ({
  [`&.${classes.snackbar}`]: {
    margin: '40px 40px 0px 0px',
  },
}))

function Alert(props: AlertProps): JSX.Element {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function SnackbarNotification(): JSX.Element {
  const [snackbarConfig, setSnackbarConfig] = useAtom(snackbarConfigAtom)

  const handleClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false })
  }

  return (
    <StyledSnackbar
      className={classes ? classes.snackbar : undefined}
      key={'appSnackbar'}
      anchorOrigin={
        snackbarConfig.anchorOrigin || {
          vertical: 'top',
          horizontal: 'center',
        }
      }
      autoHideDuration={snackbarConfig.autoHideDuration || undefined}
      open={snackbarConfig.open}
      onClose={handleClose}
      message={snackbarConfig.message}
      action={
        snackbarConfig.closeButton ? (
          <div>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
        ) : undefined
      }
    >
      {snackbarConfig.severity ? (
        <div>
          <Alert
            onClose={handleClose}
            severity={snackbarConfig.severity}
            style={
              snackbarConfig.severity === 'info'
                ? {
                    backgroundColor: '#0052CC',
                    fontSize: '1rem',
                  }
                : undefined
            }
          >
            {snackbarConfig.message}
          </Alert>
        </div>
      ) : undefined}
    </StyledSnackbar>
  )
}
