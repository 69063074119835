import React, { useState, useEffect, useContext } from 'react'
import { useAtom } from 'jotai'
import {
  CommonQueryFilter,
  useGetFavoritesQuery,
  Favorite,
} from 'graphql/gen-types'
import ComponentCard from 'components/common/layout/ComponentCard'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import ResponsiveTable, {
  ResponsiveCell,
} from 'components/common/tables/ResponsiveTable'
import DateFnsAdapter from '@date-io/date-fns'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { LEADS_APP } from '../../../constants'
import { AppStore } from 'store'
import { AppActionType } from '../../../store/reducers'
import { useNavigate } from 'react-router-dom'
import { snackbarConfigAtom } from 'store/atoms'
import { getNamesFromFullName } from '../../../utils/functions'

const dateFns = new DateFnsAdapter()

const COMPONENT_TITLE = 'Recent Favorites'

export interface LiveFavoritesProps {
  filter: CommonQueryFilter
}

export default function LiveFavorites(props: LiveFavoritesProps): JSX.Element {
  const appStore = useContext(AppStore)
  const [snackbarConfig, setSnackbarConfig] = useAtom(snackbarConfigAtom)
  const navigate = useNavigate()
  const [favorites, setFavorites] = useState<Favorite[]>([])

  const { data, loading, error } = useGetFavoritesQuery({
    variables: {
      filter: {
        common: props.filter,
        leads: {},
      },
    },
  })

  useEffect(() => {
    if (data?.getFavorites.favorites) {
      setFavorites(data.getFavorites.favorites as Favorite[])
    }
  }, [data])

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized message={error.toString()} imageName={'LiveFavorites'} />
      )
    }
    console.error(error)
    return <div>Error while loading favorites</div>
  }

  const navigateToLeadDetails = (row: Favorite) => {
    const email = row.prospect?.email
    if (!email) {
      setSnackbarConfig({
        ...snackbarConfig,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        autoHideDuration: 6000,
        open: true,
        message: 'Selected row does not contain prospect data.',
        severity: 'info',
      })
      return
    }
    const route = `/leads/client/${
      props.filter.clientName
    }/prospect?prospectId=${encodeURIComponent(email)}`
    appStore.dispatch({
      type: AppActionType.SET_SELECTED_APP,
      payload: LEADS_APP,
    })
    navigate(route)
  }

  const columns = [
    <ResponsiveCell align="left" key="community-head">
      <strong>Community</strong>
    </ResponsiveCell>,

    <ResponsiveCell align="left" key="plan-head">
      <strong>Plan (Elevation)</strong>
    </ResponsiveCell>,

    <ResponsiveCell align="left" key="name-head">
      <strong>User</strong>
    </ResponsiveCell>,

    <ResponsiveCell align="left" key="location-head">
      <strong>Browsed From</strong>
    </ResponsiveCell>,

    <ResponsiveCell align="center" key="liked-head">
      <strong>Liked</strong>
    </ResponsiveCell>,
  ]

  const generateRow = (row: Favorite): JSX.Element => {
    const [firstName, lastName] = getNamesFromFullName(row?.prospect?.name)

    const plan = row?.planName || '-'
    const elevation = row?.elevationCaption ? `(${row.elevationCaption})` : ''
    const location = row?.country
      ? `${row?.city || 'unknown'}, ${row?.state || 'unknown'}, ${
          row.country || 'unknown'
        }`
      : '-'
    return (
      <>
        <ResponsiveCell align="left" key="row-cell-community-name">
          {row?.communityName || '-'}
        </ResponsiveCell>
        <ResponsiveCell align="left" key="row-cell-plan">
          {`${plan} ${elevation}`}
        </ResponsiveCell>
        <ResponsiveCell align="left" key="row-cell-user-prospect">
          {row?.prospect ? (
            <>
              {firstName + ' ' + lastName}
              <Typography
                variant="subtitle2"
                component="span"
                style={{ color: 'gray' }}
              >
                {row?.user ? ` (${row.user})` : '-'}
              </Typography>
            </>
          ) : (
            row.user
          )}
        </ResponsiveCell>
        <ResponsiveCell align="left" key="row-cell-favorite-location">
          {location}
        </ResponsiveCell>
        <ResponsiveCell align="center" key="row-cell-favorite-timestamp">
          {row?.date
            ? dateFns.format(new Date(row?.date), "MMM do hh:mm aaaaa'm'")
            : 'No data available'}
        </ResponsiveCell>
      </>
    )
  }

  const header: JSX.Element = (
    <Grid container>
      <Grid item lg={6}>
        <Typography variant="h5" color="primary">
          {COMPONENT_TITLE}
        </Typography>
      </Grid>
    </Grid>
  )

  return (
    <ComponentCard
      title={header}
      result={{ data: favorites, loading, error }}
      skeletonHeight={500}
    >
      <ResponsiveTable
        data={favorites}
        columns={columns}
        generateRow={generateRow}
        onRowClick={navigateToLeadDetails}
        dense={true}
      />
    </ComponentCard>
  )
}
