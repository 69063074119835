import React, { useContext, useEffect, useState } from 'react'
import PopularCommunitiesList from './PopularCommunitiesList'
import PopularCommunitiesMap from './PopularCommunitiesMap'
import {
  Community,
  CommonQueryFilter,
  PopularCommunityByCount,
  PopularCommunityByDuration,
  PopularCommunityByFavoritesCount,
  PopularityMetric,
  useCommunitiesQuery,
  usePopularCommunitiesQuery,
  Elevation,
} from 'graphql/gen-types'
import Grid from '@mui/material/Grid'
import { AppStore } from 'store'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import Skeleton from '@mui/material/Skeleton'
import ComponentCard from 'components/common/layout/ComponentCard'
import { StyledTabPanel, classes } from './PopularCommunities.styles'

const initialState: Community[] = []
const initialCountState: PopularCommunityByCount[] = []
const initialDurationState: PopularCommunityByDuration[] = []
const initialFavoritesCountState: PopularCommunityByFavoritesCount[] = []

export const POPULAR_COMMUNITIES_DISPLAY_MAP = 1
export const POPULAR_COMMUNITIES_DISPLAY_LIST = 2

interface Props {
  filter: CommonQueryFilter
  elevation?: Elevation | undefined | null
  planId?: number | undefined | null
  mode?: number
  report?: boolean
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps): JSX.Element {
  const { children, value, index, ...other } = props
  return (
    <StyledTabPanel
      role="tabpanel"
      hidden={value !== index}
      aria-labelledby={`community-tabpanel-${index}`}
      {...other}
    >
      {value === index && children}
    </StyledTabPanel>
  )
}

function PopularCommunities(props: Props): JSX.Element {
  const { appState } = useContext(AppStore)
  const { selectedClient } = appState

  const [activeTab, setActiveTab] = React.useState(0)

  const [communities, setCommunities] = useState(initialState)
  const [communitiesByCount, setCommunitiesByCount] = useState(
    initialCountState
  )
  const [communitiesByDuration, setCommunitiesByDuration] = useState(
    initialDurationState
  )
  const [
    communitiesByFavoritesCount,
    setCommunitiesByFavoritesCount,
  ] = useState(initialFavoritesCountState)

  const countFilter = {
    ...props.filter,
    ...{ metric: PopularityMetric.Frequency },
    planId: props.planId ? [props.planId] : undefined,
    elevationId: props?.elevation?.id,
    limit: 1000,
  }
  const countQuery = usePopularCommunitiesQuery({
    variables: {
      filter: countFilter,
      clientName: countFilter.clientName,
    },
  })

  const durationFilter = {
    ...props.filter,
    ...{ metric: PopularityMetric.Duration },
    planId: props.planId ? [props.planId] : undefined,
    elevationId: props?.elevation?.id,
    limit: 1000,
  }
  const durationQuery = usePopularCommunitiesQuery({
    variables: {
      filter: durationFilter,
      clientName: countFilter.clientName,
    },
  })

  const favoritesCountFilter = {
    ...props.filter,
    ...{ metric: PopularityMetric.Favoritescount },
    planId: props.planId ? [props.planId] : undefined,
    elevationId: props?.elevation?.id,
    limit: 1000,
  }
  const favoritesCountQuery = usePopularCommunitiesQuery({
    variables: {
      filter: favoritesCountFilter,
      clientName: countFilter.clientName,
    },
  })

  const communitiesQuery = useCommunitiesQuery({
    variables: {
      clientName: selectedClient ? selectedClient.altName : '',
    },
    skip: !selectedClient,
  })

  useEffect(() => {
    if (communitiesQuery.data && communitiesQuery.data.communities) {
      const filteredCommunities = communitiesQuery.data.communities.filter(
        (community) => community?.active === true
      )
      setCommunities(filteredCommunities as Community[])
    }
  }, [communitiesQuery.data])

  useEffect(() => {
    if (countQuery.data && countQuery.data.popularCommunities) {
      const filteredCommunities = countQuery.data.popularCommunities.filter(
        (communityObject) => communityObject?.community?.active === true
      )
      setCommunitiesByCount(filteredCommunities as PopularCommunityByCount[])
    }
  }, [countQuery.data])

  useEffect(() => {
    if (durationQuery.data && durationQuery.data.popularCommunities) {
      const filteredCommunities = durationQuery.data.popularCommunities.filter(
        (communityObject) => communityObject?.community?.active === true
      )
      setCommunitiesByDuration(
        filteredCommunities as PopularCommunityByDuration[]
      )
    }
  }, [durationQuery.data])

  useEffect(() => {
    if (
      favoritesCountQuery.data &&
      favoritesCountQuery.data.popularCommunities
    ) {
      const filteredCommunities = favoritesCountQuery.data.popularCommunities.filter(
        (communityObject) => communityObject?.community?.active === true
      )
      setCommunitiesByFavoritesCount(
        filteredCommunities as PopularCommunityByFavoritesCount[]
      )
    }
  }, [favoritesCountQuery.data])

  // private function to display both list and map
  const __displayPopularCommunities = () => {
    return (
      <div style={{ width: '100%' }}>
        <Grid
          container
          justifyContent="center"
          spacing={2}
          className={classes.componentContainer}
        >
          <Grid item sm={12} md={activeTab === 0 ? 5 : 7}>
            <TabPanel value={activeTab} index={0}>
              <PopularCommunitiesList
                activeTab={activeTab}
                popularityMetric={PopularityMetric.Frequency}
                communities={communitiesByCount}
                setActiveTab={setActiveTab}
              />
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <PopularCommunitiesList
                activeTab={activeTab}
                popularityMetric={PopularityMetric.Duration}
                communities={communitiesByDuration}
                setActiveTab={setActiveTab}
              />
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
              <PopularCommunitiesList
                activeTab={activeTab}
                popularityMetric={PopularityMetric.Favoritescount}
                communities={communitiesByFavoritesCount}
                setActiveTab={setActiveTab}
              />
            </TabPanel>
          </Grid>
          <Grid item sm={12} md={activeTab === 0 ? 7 : 5}>
            {activeTab === 0 && communitiesByCount.length > 0 && (
              <PopularCommunitiesMap popularCommunities={communitiesByCount} />
            )}
            {activeTab === 1 && communitiesByDuration.length > 0 && (
              <PopularCommunitiesMap
                popularCommunities={communitiesByDuration}
              />
            )}
            {activeTab === 2 && communitiesByFavoritesCount.length > 0 && (
              <PopularCommunitiesMap
                popularCommunities={communitiesByFavoritesCount}
              />
            )}
          </Grid>
        </Grid>
      </div>
    )
  }

  // private function to render either list or map of communities
  const __displayPopularCommunitiesSingleMode = (mode: number) => {
    if (props.report) {
      return (
        <div style={{ width: '100%' }}>
          {mode === POPULAR_COMMUNITIES_DISPLAY_LIST && (
            <PopularCommunitiesList
              activeTab={activeTab}
              popularityMetric={PopularityMetric.Frequency}
              communities={communitiesByCount}
              setActiveTab={setActiveTab}
              report={props.report}
            />
          )}
          {mode === POPULAR_COMMUNITIES_DISPLAY_MAP &&
            communities.length + communitiesByCount.length > 0 && (
              <PopularCommunitiesMap
                activeTab={activeTab}
                popularCommunities={communitiesByDuration}
                setActiveTab={setActiveTab}
              />
            )}
        </div>
      )
    }
    return (
      <div style={{ width: '100%' }}>
        <TabPanel value={activeTab} index={0}>
          {mode === POPULAR_COMMUNITIES_DISPLAY_LIST && (
            <PopularCommunitiesList
              activeTab={activeTab}
              popularityMetric={PopularityMetric.Frequency}
              communities={communitiesByCount}
              setActiveTab={setActiveTab}
            />
          )}
          {mode === POPULAR_COMMUNITIES_DISPLAY_MAP &&
            communities.length + communitiesByCount.length > 0 && (
              <PopularCommunitiesMap
                activeTab={activeTab}
                popularCommunities={communitiesByCount}
                setActiveTab={setActiveTab}
              />
            )}
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          {mode === POPULAR_COMMUNITIES_DISPLAY_LIST && (
            <PopularCommunitiesList
              activeTab={activeTab}
              popularityMetric={PopularityMetric.Duration}
              communities={communitiesByDuration}
              setActiveTab={setActiveTab}
            />
          )}
          {mode === POPULAR_COMMUNITIES_DISPLAY_MAP &&
            communities.length + communitiesByCount.length > 0 && (
              <PopularCommunitiesMap
                activeTab={activeTab}
                popularCommunities={communitiesByDuration}
                setActiveTab={setActiveTab}
              />
            )}
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          {mode === POPULAR_COMMUNITIES_DISPLAY_LIST && (
            <PopularCommunitiesList
              activeTab={activeTab}
              popularityMetric={PopularityMetric.Favoritescount}
              communities={communitiesByFavoritesCount}
              setActiveTab={setActiveTab}
            />
          )}
          {mode === POPULAR_COMMUNITIES_DISPLAY_MAP &&
            communities.length + communitiesByFavoritesCount.length > 0 && (
              <PopularCommunitiesMap
                activeTab={activeTab}
                popularCommunities={communitiesByFavoritesCount}
                setActiveTab={setActiveTab}
              />
            )}
        </TabPanel>
      </div>
    )
  }

  // r e n d e r
  if (communitiesQuery.loading || countQuery.loading || durationQuery.loading) {
    return (
      <ComponentCard>
        <Skeleton variant="text" width={200} height={50} />
        <Skeleton variant="rectangular" width={'100%'} height={400} />
      </ComponentCard>
    )
  }

  if (communitiesQuery.error) {
    if (verifyUserAuthError(communitiesQuery.error.toString())) {
      return (
        <Unauthorized
          message={communitiesQuery.error.toString()}
          imageName={'PopularCommunities'}
        />
      )
    }
    return <div>{communitiesQuery.error.toString()}</div>
  }

  if (countQuery.error) {
    if (verifyUserAuthError(countQuery.error.toString())) {
      return (
        <Unauthorized
          message={countQuery.error.toString()}
          imageName={'PopularCommunities'}
        />
      )
    }
    return <div>{countQuery.error.toString()}</div>
  }

  if (durationQuery.error) {
    if (verifyUserAuthError(durationQuery.error.toString())) {
      return (
        <Unauthorized
          message={durationQuery.error.toString()}
          imageName={'PopularCommunities'}
        />
      )
    }
    return <div>{durationQuery.error.toString()}</div>
  }

  if (props.mode !== undefined) {
    return __displayPopularCommunitiesSingleMode(props.mode)
  }
  return __displayPopularCommunities()
}

export default PopularCommunities
