import React, { useState, useEffect } from 'react'
import {
  CommonQueryFilter,
  PopularFloorplans,
  usePopularFloorplansQuery,
} from 'graphql/gen-types'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import FloorplansDisplay from 'components/common/displays/FloorplansDisplay'
const initialState: PopularFloorplans[] = []

interface PopularFloorplansProps {
  filter: CommonQueryFilter
}

function PopularFloorplansDisplay(props: PopularFloorplansProps): JSX.Element {
  const [floorplans, setFloorplans] = useState(initialState)

  const { data, loading, error } = usePopularFloorplansQuery({
    variables: {
      clientName: props.filter.clientName,
      filter: props.filter,
    },
  })

  useEffect(() => {
    if (data && data.popularFloorplans) {
      // Issue with types. Casting with as
      setFloorplans(data.popularFloorplans as PopularFloorplans[])
    }
  }, [data])

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized
          message={error.toString()}
          imageName={'PopularFloorplansDisplay'}
        />
      )
    }
    return <div>{error.toString()}</div>
  }

  return <FloorplansDisplay data={floorplans} loading={loading} error={error} />
}

export default PopularFloorplansDisplay
