import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import isEqual from 'lodash.isequal'
import {
  ResponsiveContainer,
  FunnelChart,
  Funnel,
  Cell,
  LabelList,
  Tooltip,
} from 'recharts'
import {
  SessionFilter,
  Engagement,
  useEngagementRateQuery,
} from 'graphql/gen-types'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import { StyledGrid, classes } from './EngagementRate.styles'
import ComponentCard from 'components/common/layout/ComponentCard'

const REPORT_FUNNEL_CHART_HEIGHT = 300
const FUNNEL_CHART_HEIGHT = 400
const FUNNEL_CHART_WIDTH = 400

const initialState: Engagement = {
  community: 0,
  plan: 0,
  elevation: 0,
  brochure: 0,
}

interface EngagementRateProps {
  filter: SessionFilter
  report?: boolean
}

function __isEmptyEngagement(data: Engagement): boolean {
  return isEqual(data, initialState)
}

function EngagementRate(props: EngagementRateProps): JSX.Element {
  const theme = useTheme()
  const [engagement, setEngagement] = useState(initialState)
  const { data, loading, error } = useEngagementRateQuery({
    variables: {
      filter: props.filter,
    },
  })
  // TODO: Look into this StackOverflow issue as a possible solution... should not pass a stringified object to useEffect...
  // https://stackoverflow.com/questions/55808749/use-object-in-useeffect-2nd-param-without-having-to-stringify-it-to-json
  useEffect(() => {
    if (data && data.engagementRate) {
      setEngagement(data.engagementRate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [String(data)])

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized message={error.toString()} imageName={'EngagementRate'} />
      )
    }
    return <div>{error.toString()}</div>
  }

  if (__isEmptyEngagement(engagement)) {
    // the funnel chart cannot be displayed with all values of 0
    // it will run into an egregious stack overflow issue
    // with its render method
    return <div>Loading...</div>
  }

  const dataObj = getValuePairs(engagement)
  // const colors = ['#1D4E63', '#2D7A9C', '#689AB0', '#49C7FC']
  const colors = ['#1D4E63', '#4A6E7D', '#2D7A9C', '#49C7FC']
  const stroke =
    theme.palette.mode === 'dark'
      ? theme.palette.primary.contrastText
      : theme.palette.primary.light
  // since the last cat is usually narrow, we want the stroke to have
  // sufficient contrast
  const lastStroke =
    theme.palette.mode === 'dark'
      ? theme.palette.primary.contrastText
      : theme.palette.primary.main
  return (
    <ComponentCard
      title="Engagement Rate"
      result={{ error, loading }}
      skeletonHeight={FUNNEL_CHART_HEIGHT}
    >
      <Grid container>
        <Grid item xs={10}>
          <ResponsiveContainer
            width={`${props.report ? '80%' : '100%'}`}
            height={
              props.report ? REPORT_FUNNEL_CHART_HEIGHT : FUNNEL_CHART_HEIGHT
            }
          >
            <FunnelChart
              width={FUNNEL_CHART_WIDTH}
              height={
                props.report ? REPORT_FUNNEL_CHART_HEIGHT : FUNNEL_CHART_HEIGHT
              }
            >
              <Tooltip />
              <Funnel dataKey="value" data={dataObj} activeShape={<Cell />}>
                <LabelList data={dataObj} dataKey="name" />
                {dataObj.map((entry, index) => {
                  // Stroke changes label and cell outline color.
                  // Not sure how to customize label much.
                  const strokeColor =
                    index === dataObj.length - 1 ? lastStroke : stroke
                  return (
                    <Cell
                      key={`cell-${index}`}
                      fill={colors[index]}
                      stroke={strokeColor}
                    />
                  )
                })}
              </Funnel>
            </FunnelChart>
          </ResponsiveContainer>
        </Grid>
        <StyledGrid item xs={2}>
          <div className={classes.percentValueWrapper}>
            {dataObj.map((entry, index) => (
              <Typography
                key={`engagement-rate-val-${index}`}
                className={classes.percentValue}
                color="primary"
              >
                {`${Math.round(entry.value * 100)}%`}
              </Typography>
            ))}
          </div>
        </StyledGrid>
      </Grid>
    </ComponentCard>
  )
}

type ValuePair = {
  name: string
  value: number
}

function getValuePairs(data: Engagement): ValuePair[] {
  if (!data) {
    return []
  }
  // Fields to not include
  const hiddenFields = ['__typename']
  const restrictedValue = 'Engagement' // this value is mostly for type checking.

  const rest: ValuePair[] = []
  Object.entries(data).forEach((row) => {
    const [key, value] = row
    if (!hiddenFields.includes(key) && value !== restrictedValue) {
      rest.push({ name: key, value: value || 0 })
    }
  })
  return rest
}

export default EngagementRate
