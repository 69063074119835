import React, { useContext, useEffect, useState } from 'react'

import {
  Agent,
  Community,
  LeadFilter,
  LeadStatus,
  Order,
  OrderBy,
  Pagination,
  usePaginatedLiveProspectsQuery,
} from 'graphql/gen-types'
import PROSPECT_ADDED_SUBSCRIPTION from 'graphql/nexus-subscriptions/prospectAdded'
import TokenExpiredModal from 'auth/TokenExpiredModal'
import DateFnsAdapter from '@date-io/date-fns'
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import ComponentCard from '../../common/layout/ComponentCard'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import LiveLeadsTable from './LiveLeadsTable'
import LiveIcon from '@mui/icons-material/TrackChanges'
import useMediaQuery from '@mui/material/useMediaQuery'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { AppStore } from '../../../store'
import {
  LeadStatusSelection,
  ProspectStatusSelection,
} from '../filter-bar/LeadsFilterBar'
import hydrationStore from '../../../store/HydrationStore'
import { useSubscription } from '@apollo/client'
import add from 'date-fns/add'
import { ProspectStatus } from '@anewgo/types'
import { LeadsStore } from '../../../store/leadsStore'
import { AppActionType } from '../../../store/reducers'

const dateFns = new DateFnsAdapter()

const COMPONENT_TITLE = 'New Leads'
const initialADayAgo = add(dateFns.date(new Date()), { hours: -24 })
const initialCurrentDate = dateFns.date(new Date())
const initialOrderBy: OrderBy = {
  order: Order.Desc,
  sortBy: 'registrationDate',
}

interface LiveLeadsProps {
  communities?: Community[]
  agents: Agent[]
  search: string
  selectedStatuses: LeadStatusSelection[]
  isAdmin?: boolean
}

function LiveLeadsDisplay({
  agents,
  search,
  selectedStatuses,
  isAdmin,
}: LiveLeadsProps): JSX.Element {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('md'))
  const { leadsState, dispatch } = useContext(LeadsStore)
  const defaultNewFilter =
    leadsState?.defaultLeadFilters?.newLeadsFilter ||
    (isAdmin ? LeadFilter.None : LeadFilter.MyLeadsOnly)

  const [aDayAgo, setADayAgo] = useState(initialADayAgo)
  const [currentDate, setCurrentDate] = useState(initialCurrentDate)
  const { appState } = useContext(AppStore)
  const { selectedClient } = appState
  const clientName = selectedClient?.altName
  const [filter, setFilter] = useState<LeadFilter>(defaultNewFilter)
  const [orderBy, setOrderBy] = useState<OrderBy>(initialOrderBy)
  const [pagination, setPagination] = useState<Pagination>({
    limit: 10,
    offset: 0,
  })

  // Fetch prospects from within the past 24 hours
  const { data, loading, error, refetch } = usePaginatedLiveProspectsQuery({
    variables: {
      clientName: clientName || '',
      registrationDateTimeFilter: {
        start: aDayAgo,
        end: currentDate,
      },
      statuses: (selectedStatuses.length
        ? selectedStatuses.map((status) => status.value)
        : null) as ProspectStatus[] | null,
      searchedText: search.length ? search : null,
      leadFilter: filter,
      pagination,
      orderBy,
    },
    skip: !clientName,
  })

  // Prospect added subscription
  const {
    data: subscriptionData,
    error: subscriptionDataError,
  } = useSubscription(PROSPECT_ADDED_SUBSCRIPTION, {
    variables: { clientName, token: hydrationStore.token || '' },
  })
  const prospectAddedData = subscriptionData?.prospectAdded

  useEffect(() => {
    const newADayAgo = add(dateFns.date(new Date()), { hours: -24 })
    // If after initial render, dates don't match, then update the dates.
    if (newADayAgo !== initialADayAgo) {
      const newCurrentDate = dateFns.date(new Date())
      setADayAgo(newADayAgo)
      setCurrentDate(newCurrentDate)
    }
  }, [])

  useEffect(() => {
    if (prospectAddedData) {
      // If we get a new prospects subscription push, updating the dates will trigger a refetch
      const newADayAgo = add(dateFns.date(new Date()), { hours: -24 })
      const newCurrentDate = dateFns.date(new Date())
      setADayAgo(newADayAgo)
      setCurrentDate(newCurrentDate)
    }
  }, [prospectAddedData])

  useEffect(() => {
    refetch()
  }, [
    selectedStatuses,
    search,
    orderBy,
    pagination,
    filter,
    aDayAgo,
    currentDate,
  ])

  const { prospects, totalCount } = data?.colonnadeApiPaginatedProspects || {
    totalCount: 0,
  }

  if (
    subscriptionDataError &&
    subscriptionDataError.message.includes('jwt token expired')
  ) {
    return <TokenExpiredModal />
  }

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized
          message={error.toString()}
          imageName={'NewLeads'}
        ></Unauthorized>
      )
    }
  }

  const header: JSX.Element = (
    <>
      {COMPONENT_TITLE}
      <LiveIcon style={{ marginLeft: '5px', marginBottom: '-4px' }} />
      <Grid
        container
        alignItems="center"
        justifyContent={'flex-end'}
        spacing={1}
        style={{ marginBottom: sm ? '-4px' : '-14px' }}
      >
        <Grid
          item
          xs={12}
          md={5}
          lg={3}
          style={{ position: 'relative', marginTop: sm ? '0px' : '-32px' }}
        >
          <FormControl>
            <InputLabel id="live-leads-filter">Lead Filter</InputLabel>
            <Select
              labelId="live-leads-filter"
              label="Lead Filter"
              value={filter}
              onChange={(event) => {
                setFilter(event.target.value as LeadFilter)
                dispatch({
                  type: AppActionType.SET_NEW_LEADS_FILTER,
                  payload: event.target.value,
                })
              }}
            >
              <MenuItem value={LeadFilter.None}>None</MenuItem>
              <MenuItem value={LeadFilter.Unassigned}>
                Unassigned Leads
              </MenuItem>
              <MenuItem value={LeadFilter.MyLeadsOnly}>My Leads Only</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  )

  // Return component with data
  return (
    <div style={{ marginBottom: '20px' }}>
      <ComponentCard
        title={header}
        subtitle="Leads registered in the past 24 hours"
        result={{ data: prospects, loading, error }}
        style={{ marginBottom: '20px' }}
      >
        <LiveLeadsTable
          data={prospects || []}
          dataTotalCount={totalCount}
          agents={agents}
          pagination={pagination}
          setPagination={setPagination}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
        />
      </ComponentCard>
    </div>
  )
}

export default LiveLeadsDisplay
