import { styled } from '@mui/material/styles'

const PREFIX = 'ReservationIntegrationListFilters'

export const classes = {
  root: `${PREFIX}-root`,
  filterHeader: `${PREFIX}-filterHeader`,
  input: `${PREFIX}-input`,
  checkboxControl: `${PREFIX}-checkboxControl`,
  button: `${PREFIX}-button`,
}

export const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    marginTop: 30,
  },

  [`& .${classes.filterHeader}`]: {
    marginBottom: 15,
  },

  [`& .${classes.input}`]: {
    marginLeft: 5,
    marginRight: 5,
    marginBottom: 10,
    width: '100%',
  },

  [`& .${classes.checkboxControl}`]: {
    marginTop: 13,
  },
  [`& .${classes.button}`]: {
    alignSelf: 'center',
    textAlign: 'center',
  },
}))
