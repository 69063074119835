import { useMemo } from 'react'
import { Location } from '../../../graphql/gen-types'
import {
  COUNTRIES,
  prepareOptions,
} from '../../analytics/locations-filters/hooks/useLocationSelection'
import {
  ITLocationSelection,
  ITLocationSelectionResult,
} from '../../analytics/locations-filters/Shared'

export const prepareRegionalInfo = (
  data: ITLocationSelectionResult
): ITLocationSelectionResult => {
  return {
    countries: prepareOptions(data.countries),
    cities: prepareOptions(data.cities),
    us: {
      states: prepareOptions(data.us.states),
      metros: prepareOptions(data.us.metros),
    },
    can: {
      provinces: prepareOptions(data.can.provinces),
      districts: prepareOptions(data.can.districts),
    },
  }
}

const locationParseMethods = {
  US: (
    location: Location,
    data: ITLocationSelectionResult,
    selection: ITLocationSelection
  ) => {
    const { city, stateCode, region } = location

    if (selection?.country && selection.country !== 'US') return

    if (stateCode) data.us.states.set(stateCode, stateCode)

    const addRegions = !selection.us?.state || selection.us.state === stateCode
    if (!addRegions) return
    if (region) data.us.metros.set(region, region)

    const addCity =
      (!selection.us?.metro || selection.us.metro === region) && city

    if (!addCity) return
    data.cities.set(city, city)
  },
  CA: (
    location: Location,
    data: ITLocationSelectionResult,
    selection: ITLocationSelection
  ) => {
    const { city, stateCode, region } = location

    if (selection?.country && selection.country !== 'CA') return

    if (stateCode) data.can.provinces.set(stateCode, stateCode)

    const addRegions =
      !selection.can?.province || selection.can.province === stateCode
    if (!addRegions) return
    if (region) data.can.districts.set(region, region)

    const addCity =
      (!selection.can?.district || selection.can.district === region) && city

    if (!addCity) return
    data.cities.set(city, city)
  },
}

export function useLocationSelection(
  locations: Location[],
  selection: ITLocationSelection,
  showBuilderLocationsOnly: boolean,
  clientName: string
): ITLocationSelectionResult {
  return useMemo(() => {
    const data = {
      countries: new Map(),
      cities: new Map(),
      us: {
        states: new Map(),
        metros: new Map(),
      },
      can: {
        provinces: new Map(),
        districts: new Map(),
      },
    }

    locations.forEach((location) => {
      if (showBuilderLocationsOnly && location.clientName !== clientName) return

      const country = location.countryCode
      if (!country) {
        return
      }

      if (country in locationParseMethods) {
        data.countries.set(country, COUNTRIES[country])
        locationParseMethods[country](location, data, selection)
      }
    })

    return prepareRegionalInfo(data)
  }, [locations, selection, showBuilderLocationsOnly, clientName])
}
