import ResponsiveTable, {
  ResponsiveCell,
} from 'components/common/tables/ResponsiveTable'
import React, { useState } from 'react'
import { LeadByProduct } from '../../../../graphql/gen-types'
import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@mui/material/Tooltip'
import {
  formatDateTime,
  getProspectName,
  humanTimeDuration,
} from 'utils/functions'
interface LeadsByProductTableProps {
  leads: LeadByProduct[]
  selectedLeads: LeadByProduct[]
  onChange: (leads: LeadByProduct[]) => void
}

const LEAD_SCORE_OUT_OF = 100

export default function LeadsByProductTable({
  leads,
  selectedLeads,
  onChange,
}: LeadsByProductTableProps): JSX.Element {
  const [selected, setSelected] = useState(selectedLeads)

  const selectedLeadEmails = selected.map((lead) => lead.user)
  const isSelected = (lead: LeadByProduct) =>
    selectedLeadEmails.includes(lead.user)

  const handleLeadClick = (row: unknown) => {
    const lead = (row as LeadByProduct) || undefined
    const newSelected = [...selected]
    const index = selectedLeadEmails.indexOf(lead?.user || '')

    if (lead?.user && index === -1) {
      newSelected.push(lead)
    } else if (index !== -1) {
      newSelected.splice(index, 1)
    }
    setSelected(newSelected)
    onChange(newSelected)
  }

  const handleSelectAll = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation()
    let newSelected = leads
    if (selected.length === leads.length) {
      newSelected = []
    }
    setSelected(newSelected)
    onChange(newSelected)
  }

  const generateRow = (
    dataRow: LeadByProduct,
    index: number | undefined
  ): JSX.Element => {
    const name = getProspectName(dataRow?.prospect || undefined, '-')
    return (
      <>
        <ResponsiveCell responseSize="sm" align="center">
          {name}
        </ResponsiveCell>
        <ResponsiveCell responseSize="sm" align="center">
          {dataRow?.user}
        </ResponsiveCell>
        <ResponsiveCell responseSize="sm" align="center">
          {dataRow?.prospect?.status || '-'}
        </ResponsiveCell>
        <ResponsiveCell responseSize="sm" align="center">
          {Math.round((dataRow?.score || 0) * LEAD_SCORE_OUT_OF)}
        </ResponsiveCell>
        <ResponsiveCell responseSize="sm" align="center">
          {humanTimeDuration(dataRow?.duration || 0)}
        </ResponsiveCell>
        <ResponsiveCell responseSize="sm" align="center">
          {formatDateTime(dataRow?.lastSeen, 'MM/dd/yyy')}
        </ResponsiveCell>
        <ResponsiveCell responseSize="sm" align="center">
          <Checkbox checked={isSelected(dataRow)}></Checkbox>
        </ResponsiveCell>
      </>
    )
  }

  const columns: JSX.Element[] = [
    <ResponsiveCell responseSize="sm" align="center" key="header-cell-name">
      Name
    </ResponsiveCell>,
    <ResponsiveCell responseSize="sm" align="center" key="header-cell-email">
      Email
    </ResponsiveCell>,
    <ResponsiveCell responseSize="sm" align="center" key="header-cell-status">
      Status
    </ResponsiveCell>,
    <ResponsiveCell responseSize="sm" align="center" key="header-cell-score">
      Score
    </ResponsiveCell>,
    <ResponsiveCell responseSize="sm" align="center" key="header-cell-duration">
      Duration
    </ResponsiveCell>,

    <ResponsiveCell responseSize="sm" align="center" key="header-cell-lastSeen">
      Last Visit
    </ResponsiveCell>,
    <ResponsiveCell responseSize="sm" align="center" key="header-cell-selected">
      Selected
      <Tooltip title="Select All">
        <Checkbox
          checked={selected.length === leads.length}
          onClick={handleSelectAll}
        ></Checkbox>
      </Tooltip>
    </ResponsiveCell>,
  ]

  return (
    <ResponsiveTable
      data={leads}
      generateRow={generateRow}
      columns={columns}
      initialRows={5}
      onRowClick={(row: unknown): void => handleLeadClick(row)}
      responseSize={'sm'}
      dense={true}
    ></ResponsiveTable>
  )
}
