import React from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
// import { useEventTracker, useHomeIdentifier } from '../utils/hooks'
// import { EVT_CLICKED_RESERVE_HOME_BUTTON } from '../constants/eventTracking'
import { Lot, Prospect } from 'graphql/gen-types'

const ReserveButtonStyled = styled(Button)({
  background: '#E2736C',
  color: 'white',
  '&:hover': {
    background: '#b33c34',
  },
})

type ReserveButtonProps = {
  lot: Lot | null
  prospect?: Prospect
  location?: string
  onSetBuyerAuthCallback?: (callback?: () => void) => void
  onSetBuyerLoginDialogOpen?: (open: boolean) => void
}

export default function ReserveButton({
  lot,
  prospect,
  location,
  onSetBuyerAuthCallback,
  onSetBuyerLoginDialogOpen,
}: ReserveButtonProps): JSX.Element {
  // const track = useEventTracker()
  // const homeIdentifier = useHomeIdentifier()

  const handleSetBuyerAuthCallback = (callback?: () => void) => {
    onSetBuyerAuthCallback && onSetBuyerAuthCallback(callback)
  }

  const handleSetBuyerLoginDialogOpen = (open: boolean) => {
    onSetBuyerLoginDialogOpen && onSetBuyerLoginDialogOpen(open)
  }

  const handleAuthenticated = () => {
    handleSetBuyerAuthCallback() // reset
    const url = lot?.inventory?.reserveHomeUrl
    url && window.open(url, '_blank')
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // track(EVT_CLICKED_RESERVE_HOME_BUTTON, {
    //   lotId: lot?.id,
    //   lotName: lot?.name,
    //   lotAddress: lot?.address,
    //   inventoryId: lot?.inventory?.id,
    //   homeIdentifier,
    //   prospectEmail: prospect ? prospect.email : null,
    //   loc: location, // called 'loc' since that is what the variable was initially called, now BQ expects it.
    // })

    if (prospect) {
      handleAuthenticated()
    } else {
      handleSetBuyerAuthCallback(handleAuthenticated)
      handleSetBuyerLoginDialogOpen(true)
    }
  }

  const renderButton =
    lot &&
    lot.salesStatus.trim().toLowerCase() !== 'sold' &&
    lot.inventory &&
    lot.inventory.reserveHomeUrl

  // R E N D E R
  return (
    <>
      {renderButton && (
        <ReserveButtonStyled variant="contained" onClick={handleClick}>
          Reserve Now
        </ReserveButtonStyled>
      )}
    </>
  )
}
