import React, { useEffect, useState } from 'react'
import { GridStyled } from './styles/ProspectFavoritesGrid.styles'
import {
  ColonnadeSubscriptionLevelsEnum,
  Plan,
  Prospect,
} from '../../../graphql/gen-types'
import PlansGrid, { ProspectPlan } from '../../common/by-plan/PlansGrid'
import { SortOptions } from 'components/common/custom-inputs/SortSelect'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Unauthorized } from 'components/auth/Unauthorized'
import {
  userHasSubscriptionAccess,
  verifyUserAuthError,
} from 'utils/authorizationHelpers'
import ComponentCard from 'components/common/layout/ComponentCard'
import { MultiOptionAutoComplete } from '../../common/custom-inputs/MultiOptionsAutocomplete'
import ProspectPlanSuggestionsDialog from './dialogs/ProspectPlanSuggestionsDialog'
import { SubscriptionTooltip } from 'components/icons/SubscriptionTooltip'
import { STARTER_INVALID_FEATURE_MESSAGE } from '../../../constants'

const COMPONENT_HEIGHT = 1000
interface ProspectFavoritesGridProps {
  hideNotBrowsedBanner?: boolean
  data: Prospect | undefined | null
  loading?: boolean
  error?: Error | undefined
  favoritePlans: ProspectPlan[]
}
interface Source {
  name: string
  value: string
}

function ProspectFavoritesGrid({
  hideNotBrowsedBanner,
  data,
  loading,
  error,
  favoritePlans,
}: ProspectFavoritesGridProps): JSX.Element {
  const sources: Source[] = [
    { name: 'My Home App', value: 'truss' },
    { name: 'Sales Kiosk', value: 'camber' },
  ]
  const [selectedSources, setSelectedSources] = useState<Source[]>(sources)
  const [selectedPlans, setSelectedPlans] = useState<Plan[]>(favoritePlans)
  const [dialogOpen, setDialogOpen] = useState(false)

  const PRO_OR_ENTERPRISE_SUBSCRIBED = userHasSubscriptionAccess([
    ColonnadeSubscriptionLevelsEnum.Pro,
    ColonnadeSubscriptionLevelsEnum.Enterprise,
  ])

  useEffect(() => {
    const selectedSourcesValues = selectedSources.map(
      (source: Source) => source.value
    )
    const filteredPlans = favoritePlans.filter(
      (plan: ProspectPlan) =>
        plan.source && selectedSourcesValues.includes(plan.source)
    )
    setSelectedPlans(filteredPlans)
  }, [favoritePlans, selectedSources])

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized
          message={error.toString()}
          imageName={'ProspectFavoritesGrid'}
        ></Unauthorized>
      )
    }
  }

  const componentTitle = (
    <Grid
      container
      alignItems="center"
      spacing={4}
      justifyContent="space-between"
    >
      <Grid item xs={12} md={3} lg={2}>
        <Typography variant={'h5'}>Active Favorites</Typography>
      </Grid>
      <Grid item xs={12} md={3} lg={2}>
        <div style={{ position: 'relative' }}>
          <Button
            variant="outlined"
            onClick={() => setDialogOpen(true)}
            disabled={!PRO_OR_ENTERPRISE_SUBSCRIBED}
          >
            Suggest Plans
          </Button>
          {!PRO_OR_ENTERPRISE_SUBSCRIBED && (
            <SubscriptionTooltip
              iconStyle={'suggestPlansIcon'}
              message={STARTER_INVALID_FEATURE_MESSAGE}
            />
          )}
        </div>
      </Grid>
      <Grid item xs={12} md={9} lg={8}>
        <MultiOptionAutoComplete
          inputLabel={'Select Sources'}
          options={sources}
          selectedOptions={selectedSources}
          setSelectedOptions={setSelectedSources}
          optionLabelAccessor={(source: Source): string =>
            source.name || 'unknown'
          }
        />
      </Grid>
    </Grid>
  )

  return (
    <React.Fragment>
      <ComponentCard
        title={componentTitle}
        height={COMPONENT_HEIGHT}
        style={{ paddingRight: 5 }} // Keep PlansGrid scrollbar from touch edge of paper
        result={{ data: data?.favorites, loading, error }}
      >
        <GridStyled container>
          <PlansGrid
            hideNotBrowsedBanner={hideNotBrowsedBanner}
            prefetchedPlans={selectedPlans}
            prospect={data}
            selectedMetric={SortOptions.Frequency}
            loading={loading || false}
            isFavorites={true}
          />
        </GridStyled>
      </ComponentCard>
      <ProspectPlanSuggestionsDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        prospect={data as Prospect}
      />
    </React.Fragment>
  )
}

export default ProspectFavoritesGrid
