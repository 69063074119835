import React from 'react'
import TimesSeriesLineChart from 'components/common/time-series/TimeSeriesLineChart'
import {
  ElevationTrend,
  CommonQueryFilter,
  PopularityMetric,
} from 'graphql/gen-types'
import { addZeroCountDates } from 'components/common/time-series/TimeSeriesFormatter'
import ComponentCard from 'components/common/layout/ComponentCard'

export interface ElevationTrendsProps {
  areaChart?: boolean | false
  popularElevations: ElevationTrend[]
  loading?: boolean
  error?: Error | undefined
  filter: CommonQueryFilter
  height?: number | null
  width?: number | null
}

export default function ElevationTrends({
  areaChart,
  popularElevations,
  loading,
  error,
  filter,
  height,
  width,
}: ElevationTrendsProps): JSX.Element {
  const yAxisDataKey =
    filter.metric === PopularityMetric.Frequency ? 'value' : 'durationSum'

  const elevations = popularElevations.map((elevation: ElevationTrend) => {
    return {
      clientName: elevation.clientName,
      planId: elevation.planId,
      planName: elevation.elevation?.planName,
      elevationId: elevation.elevationId,
      elevationCaption:
        elevation?.elevationCaption || elevation.elevation?.caption,
      name: `${elevation.elevation?.planName} ${
        elevation?.elevationCaption || elevation.elevation?.caption || ''
      }`,
      dailyMetrics: addZeroCountDates(
        elevation.dailyMetrics,
        filter.metric,
        new Date(filter.startTime),
        new Date(filter.endTime)
      ),
    } as ElevationTrend
  })

  return (
    <ComponentCard
      title="Elevation Trends"
      result={{
        data: popularElevations,
        error,
        loading,
      }}
      skeletonHeight={400}
    >
      <TimesSeriesLineChart
        data={elevations}
        yAxisDataKey={yAxisDataKey}
        tooltipDataKey={'name'}
        xAxisDomain={{
          lower: filter.startTime,
          upper: filter.endTime,
        }}
        metric={filter.metric}
        areaChart={areaChart}
        height={height}
        width={width}
      />
    </ComponentCard>
  )
}
