import React from 'react'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import { classes, Root } from './UserInfo.styles'
interface Props {
  photo?: string
  name: string
  role: string
}

function UserInfo(props: Props): JSX.Element {
  const { name, role, photo } = props

  return (
    <Root className={classes.root}>
      <Avatar
        alt={`${name}`}
        src={photo}
        className={classes.orange}
        classes={{ root: classes.avatar }}
      >
        {name.slice(0, 1)}
      </Avatar>
      <Typography variant="body2">{name}</Typography>
      <Typography variant="caption">{role}</Typography>
    </Root>
  )
}

export default UserInfo
