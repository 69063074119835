import { styled } from '@mui/material/styles'

const PREFIX = 'LeadsAnalytics'

export const classes = {
  componentContainer: `${PREFIX}-componentContainer`,
}

export const Root = styled('div')(({ theme }) => ({
  [`& .${classes.componentContainer}`]: {
    justifyContent: 'center',
    marginTop: '1em',
    padding: '2em',
    [theme.breakpoints.down('md')]: {
      padding: '0em',
    },
  },
}))
