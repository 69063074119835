import { styled } from '@mui/material/styles'
import { MAIN_APP_BAR_HEIGHT } from '../../constants'

const PREFIX = 'FavoriteDetails'

export const classes = {
  root: `${PREFIX}-root`,
}

export const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',
    width: '100%',
    height: `calc(100vh - ${MAIN_APP_BAR_HEIGHT}px)`,
  },
}))
