import React from 'react'
import Typography from '@mui/material/Typography'
import { useMetaTags } from '@anewgo/hooks'

import { determineGaragePositionCaption } from '../utils'
// import { useGATrackingId, usePageView, useEventTracker } from '../utils/hooks'
import { HeaderSection, HomeSpecs, HomeSpecsContainer } from '../MyHome.styles'
import { Client, Prospect } from 'graphql/gen-types'
import { HomeSelection, MyHomePageVariants } from '../MyHome'

import { getProductSpecs } from '@anewgo/utils'
import { identity } from '@anewgo/functions'
import MortgageCalculator from '../components/MortgageCalculator'
import ProspectProfile from 'components/leads/prospect-page/ProspectProfile'
import Grid from '@mui/material/Grid'

type MyHomeHeaderProps = {
  variant?: MyHomePageVariants
  title?: string
  client: Client
  prospect: Prospect | undefined
  selection: HomeSelection
  homeIdentifier?: string
  favoriteIdentifier?: number
  requireRegistration: boolean
  isSignedIn: boolean
  isInventoryWithNoPlanElevation: boolean
  uiConfig: any
  onClickSignIn: () => void
  onSetOpenMessage: (open: boolean) => void
  onOpenContactDialog: () => void
  onSetOpenProgressModal: (open: boolean) => void
  onSetInfoMessage: (message: string) => void
  onSetProgressModalTitle: (title: string) => void
}

export default function MyHomeHeader({
  variant,
  title,
  selection,
  client,
  prospect,
  isInventoryWithNoPlanElevation,
  uiConfig,
}: MyHomeHeaderProps): JSX.Element {
  const { size, bed, bath, costValue } = (selection &&
    getProductSpecs(selection)) || {
    size: 0,
    bed: 0,
    bath: 0,
  }
  const specs = [size, bed, bath].filter(identity).join(' | ')

  const clientName = client.name || ''
  const communityName = selection.community.name || ''
  const planName = selection.plan.name || ''
  const elevationCaption = selection.elevation.caption || ''
  const lot = selection?.lot

  let lotInfo = lot?.name ? `Lot ${lot.name}` : ''
  if (selection.lot?.size) {
    lotInfo += ` | ${selection.lot.size || ''} ${
      selection.community.primarySiteplan?.lotMetric || ''
    }` // TODO: check if safe to use selection.community instead of full community object. (Does query have all fields? May need to add.)
  }

  /* Set Meta Tags */
  const metaTitle =
    prospect && prospect.name
      ? `Can't wait to move in! ${prospect.name}'s new home in ${communityName}`
      : `Super excited! This is my new home in ${communityName}`
  let metaDescription = isInventoryWithNoPlanElevation
    ? `Super excited! My new home is ` +
      `built in ${communityName}, with ${specs}. It is built by ${clientName}`
    : `Super excited! My new home is a beautiful ${planName} ${
        elevationCaption || ''
      } `.trim() +
      `built in ${communityName}, with ${specs}. It is built by ${clientName}`
  if (lotInfo) {
    metaDescription += ` on ${lotInfo}.`
  } else {
    metaDescription += '.'
  }
  // useMetaTags({ property: 'og:url', content: window.location.href })
  useMetaTags({ property: 'og:title', content: metaTitle })
  useMetaTags({ property: 'og:description', content: metaDescription })

  const homeSpecs = () => {
    let name
    if (!isInventoryWithNoPlanElevation) {
      const garagePosition = determineGaragePositionCaption(
        selection.elevation,
        uiConfig
      )
      name = `${planName} ${elevationCaption || ''} ${
        garagePosition || ''
      }`.trim()
    }
    return (
      <HomeSpecsContainer>
        <HomeSpecs>
          <Typography variant={'h5'}>{communityName}</Typography>
          {name && <Typography variant={'h6'}>{name}</Typography>}
          <Typography>{specs}</Typography>
          {lotInfo && <Typography>{lotInfo}</Typography>}

          {costValue > 0 && variant === 'myHome' && (
            <MortgageCalculator
              uiConfig={uiConfig}
              cost={costValue || 1000000}
              slideFrom="right"
              myHome={true}
            />
          )}
        </HomeSpecs>
      </HomeSpecsContainer>
    )
  }

  return (
    <HeaderSection>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
              <ProspectProfile data={prospect} size="small" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid container justifyContent="center">
            <Typography variant="h2">{title}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4}>
          {homeSpecs()}
        </Grid>
      </Grid>
    </HeaderSection>
  )
}
