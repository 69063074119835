import { styled } from '@mui/material/styles'

const PREFIX = 'CustomXAxisTick'

export const classes = {
  typography: `${PREFIX}-typography`,
  foreignObject: `${PREFIX}-foreignObject`,
}

export const CustomXAxisTickStyled = styled('g')(({ theme }) => ({
  [`& .${classes.typography}`]: {
    fontSize: 12,
    textAlign: 'center',
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.contrastText
        : theme.palette.primary.main,
    overflow: 'hidden',
    padding: '5px',
  },

  [`& .${classes.foreignObject}`]: {
    width: '100%',
    height: '100%',
  },
}))
