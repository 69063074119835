import React, { useEffect, useState } from 'react'
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  Area,
  CartesianGrid,
} from 'recharts'
import ComponentCard from 'components/common/layout/ComponentCard'
import {
  useGetCamberUsersLoginsQuery,
  Login,
  CommonQueryFilter,
} from 'graphql/gen-types'
import { formatDateTime } from '../../../utils/functions'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'

const COMPONENT_TITLE = 'Users Login'
export interface UsersLoginsPropsType {
  filter: CommonQueryFilter
  containerHeight: number
}

export default function UsersLoginsProps(
  props: UsersLoginsPropsType
): JSX.Element {
  const [logins, setLogins] = useState<Login[]>([])
  const { data, loading, error } = useGetCamberUsersLoginsQuery({
    variables: {
      filter: props.filter,
    },
  })
  // TODO: Look into this StackOverflow issue as a possible solution... should not pass a stringified object to useEffect...
  // https://stackoverflow.com/questions/55808749/use-object-in-useeffect-2nd-param-without-having-to-stringify-it-to-json
  useEffect(() => {
    const loginsRaw = data?.getCamberUsersLogins.logins || []
    const loginData = loginsRaw.map((login) => ({
      value: login.value,
      date: formatDateTime(login.date, 'MM/dd/yyyy'),
    }))
    setLogins(loginData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [String(data?.getCamberUsersLogins)])

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <div data-testid="unauthorized">
          <Unauthorized message={error.toString()} imageName={'SchemeTrends'} />
        </div>
      )
    }

    return <div>Error while loading users login data</div>
  }

  return (
    <ComponentCard
      title={COMPONENT_TITLE}
      result={{ data: logins, error, loading }}
      skeletonHeight={400}
    >
      <ResponsiveContainer width="100%" height={400}>
        <AreaChart width={500} height={400} data={logins}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="value"
            stroke="#8884d8"
            fill="#8884d8"
          />
        </AreaChart>
      </ResponsiveContainer>
    </ComponentCard>
  )
}
