import React, { useState } from 'react'
import {
  CommonQueryFilter,
  InteriorElementOption,
  InteriorListElementTree,
  Maybe,
  PopularInteriorSelections,
  usePopularInteriorSelectionsQuery,
  useInteriorElementOptionsQuery,
} from 'graphql/gen-types'
import Grid from '@mui/material/Grid/Grid'
import { useTheme } from '@mui/material/styles'
import { isDefined } from 'utils/functions'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import ComponentCard from 'components/common/layout/ComponentCard'
import InteriorSelectionsTable from './InteriorSelectionsTable'

const COMPONENT_TITLE = 'Popular Interior Selections'

interface InteriorSelectionsProps {
  filter: CommonQueryFilter
}

export function InteriorSelections({
  filter,
}: InteriorSelectionsProps): JSX.Element {
  const theme = useTheme()
  const [tabValue, setTabValue] = useState(0)
  const smallSize = useMediaQuery(theme.breakpoints.down('md'))
  const [selectElements, setSelectElements] = useState<string[]>([])
  const [selectOptions, setSelectOptions] = useState<string[]>([])

  const handleTabChange = (newValue: number): void => {
    setTabValue(newValue)
  }
  const handleSelectElementsChange = (elements: string[]) => {
    setSelectElements(elements)
  }
  const handleSelectOptionsChange = (options: string[]) => {
    setSelectOptions(options)
  }

  const { data, loading, error } = usePopularInteriorSelectionsQuery({
    variables: {
      clientName: filter.clientName || '',
      filter,
    },
  })

  const {
    data: optionsData,
    loading: optionsLoading,
    error: optionsError,
  } = useInteriorElementOptionsQuery({
    variables: {
      clientName: filter.clientName || '',
    },
  })

  const optionSelections = optionsData?.interiorElementOptions.map(
    (option) =>
      new Object({
        optionSelectionId: option?.id,
        optionSelectionListElementId: option?.interiorListElementId,
        optionName: option?.name,
        optionSwatch: option?.overlaySwatchSrc,
      })
  )

  const interiorSelections: PopularInteriorSelections[] =
    data?.popularInteriorSelections?.filter(isDefined) || []

  const merged = []
  for (let i = 0; i < interiorSelections?.length; i++) {
    merged.push({
      ...interiorSelections[i],
      ...optionSelections?.find(
        (itmInner) =>
          itmInner?.optionSelectionId === interiorSelections[i].optionId
      ),
    })
  }

  /**
   * Get distinct string array
   */
  const getDistinct = (data: (Maybe<string> | undefined)[]): string[] => {
    const distinct = {}
    data.filter(isDefined).forEach((row) => {
      if (row) {
        distinct[row] = true
      }
    })
    return Object.keys(distinct)
  }

  const distinctInteriors = getDistinct(
    merged.map((interiorSelection) => interiorSelection?.interior?.name)
  )

  const filterElements = (data: PopularInteriorSelections) => {
    const el = getSelectedElement(data)
    return (
      el && (!selectElements.length || selectElements.includes(el?.name || ''))
    )
  }

  const filterOptions = (data: PopularInteriorSelections) => {
    const op = getSelectedOption(data)
    return (
      op && (!selectOptions.length || selectOptions.includes(op?.name || ''))
    )
  }

  function getAvailableOptions(
    elements: (Maybe<InteriorListElementTree> | undefined)[]
  ): string[] {
    return elements
      .filter(
        (el) =>
          !selectElements.length || selectElements.includes(el?.name || '')
      )
      .map((el) => el?.options)
      .flat(1)
      .map((op) => op?.name)
      .filter(isDefined)
  }

  return (
    <ComponentCard
      title={COMPONENT_TITLE}
      result={{ data: distinctInteriors, loading, error }}
      skeletonHeight={611}
    >
      {smallSize ? (
        <Grid container>
          <Grid item xs={12}>
            <FormControl variant="standard">
              <InputLabel>Interior</InputLabel>
              <Select
                variant="standard"
                value={distinctInteriors[tabValue]}
                onChange={(e) =>
                  handleTabChange(
                    distinctInteriors.indexOf(e.target.value as string)
                  )
                }
                style={{ minWidth: '250px' }}
              >
                {distinctInteriors.map((key, i) => {
                  return (
                    <MenuItem value={key} key={`interiors-menu-item-${i}`}>
                      {key}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      ) : (
        <>
          <Tabs
            value={tabValue}
            onChange={(e, value) => handleTabChange(value)}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            {distinctInteriors.map((key, i) => {
              return <Tab label={key} key={`interiors-tab-${i}`} />
            })}
          </Tabs>
        </>
      )}
      {distinctInteriors.map((key, i) => {
        // Excuse the following mess, I am dealing with Truss Interior Selections, which are nested 3 layers deep.
        // A cleaner implementation of this would require its own nexus resolvers for interior options.
        // We may want to do that.

        // Get all interior selections that are from the interior selected in the UI
        // Kitchen, Kithen 2, Bathroom, etc.
        const interiorsData = merged.filter((row) => row.interior?.name === key)

        // Get array of elements across all selected interiors
        const elements = interiorsData
          .map((interior) =>
            interior?.interior?.elementTrees?.filter(isDefined)
          )
          .flat(1)

        // Get distinct element and option names array. This is used for the Autocomplete Multi-select options
        const elementNames = elements.map((element) => element?.name)
        const availableOptionNames = getAvailableOptions(elements)
        const distinctElements = getDistinct(elementNames)
        const distinctOptions = getDistinct(availableOptionNames)

        // Filter the interiors down by the selections. Filter by interiors that do not have selections of
        // selected elements or options
        const displayInteriors = [...interiorsData].filter(
          (data) => filterElements(data) && filterOptions(data)
        )

        return (
          <TabPanel key={`interior-tabpanel-${i}`} value={tabValue} index={i}>
            <Grid
              container
              justifyContent="space-around"
              spacing={smallSize ? 2 : 4}
            >
              <Grid item xs={12} sm={6} md={6}>
                <Autocomplete
                  multiple
                  limitTags={smallSize ? 1 : 2}
                  options={distinctElements}
                  getOptionLabel={(option) => option}
                  value={selectElements}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Select List Elements"
                      placeholder="Add Element"
                    />
                  )}
                  data-testid="select-list-element"
                  onChange={(e, value) => handleSelectElementsChange(value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Autocomplete
                  multiple
                  limitTags={smallSize ? 1 : 2}
                  options={distinctOptions}
                  getOptionLabel={(option) => option}
                  value={selectOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Select Options"
                      placeholder="Add Option"
                    />
                  )}
                  data-testid="select-option"
                  onChange={(e, value) => handleSelectOptionsChange(value)}
                />
              </Grid>
              <Grid item xs={12}>
                <InteriorSelectionsTable data={displayInteriors} />
              </Grid>
            </Grid>
          </TabPanel>
        )
      })}
    </ComponentCard>
  )
}

export function getSelectedElement(
  data: PopularInteriorSelections
): Maybe<InteriorListElementTree> | undefined {
  return data.interior?.elementTrees?.find(
    (el) => el?.id === data.optionSelectionListElementId
  )
}
export function getSelectedOption(
  data: PopularInteriorSelections
): Maybe<InteriorElementOption> | undefined {
  return getSelectedElement(data)?.options?.find(
    (op) => op?.id === data.optionSelectionId
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: number
  value: number
}

function TabPanel(props: TabPanelProps): JSX.Element {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  )
}

export default InteriorSelections
