import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'

const PREFIX = 'AddUserDialog'

export const classes = {
  addUserDialog: `${PREFIX}-addUserDialog`,
  addUserFirstNameInputField: `${PREFIX}-addUserFirstNameInputField`,
  addUserLastNameInputField: `${PREFIX}-addUserLastNameInputField`,
  formControlAddUser: `${PREFIX}-formControlAddUser`,
  gridItem: `${PREFIX}-gridItem`,
}

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .MuiDialog-paper`]: {
    minWidth: '500px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '90vw',
    },
  },

  [`& .${classes.addUserFirstNameInputField}`]: {
    width: 'calc(100% - 10px)',
  },

  [`& .${classes.addUserLastNameInputField}`]: {
    [theme.breakpoints.up('md')]: {
      marginLeft: '10px',
    },
  },

  [`& .${classes.formControlAddUser}`]: {
    marginBottom: '20px',
    width: '100%',
  },
  [`& .${classes.gridItem}`]: {
    paddingLeft: '10px !important',
  },
}))
