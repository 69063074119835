import { useEffect } from 'react'
import { prepareSelection } from '../../analytics/locations-filters/hooks/usePrepareLocationSelection'
import {
  ITLocationSelection,
  ITLocationSelectionResult,
  SetState,
} from '../../analytics/locations-filters/Shared'

export default function usePrepareLocationSelection(
  locations: ITLocationSelectionResult,
  selection: ITLocationSelection,
  setSelection: SetState<ITLocationSelection> | undefined
): void {
  return useEffect(() => {
    if (!setSelection || !locations?.cities?.size) return
    const has = { changed: false }
    const preparedSelection = {
      cities: prepareSelection(locations.cities, selection.cities, has),
      country: prepareSelection(locations.countries, selection.country, has),
      us: {
        state: prepareSelection(locations.us.states, selection.us?.state, has),
        metro: prepareSelection(locations.us.metros, selection.us?.metro, has),
      },
      can: {
        province: prepareSelection(
          locations.can.provinces,
          selection.can?.province,
          has
        ),
        district: prepareSelection(
          locations.can.districts,
          selection.can?.district,
          has
        ),
      },
    }
    if (has.changed) setSelection(preparedSelection)
  }, [locations, selection, setSelection])
}
