import { styled } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'

const PREFIX = 'FilterBar'

export const classes = {
  filterBar: `${PREFIX}-filterBar`,
  filterBarRow: `${PREFIX}-filterBarRow`,
  gridRoot: `${PREFIX}-gridRoot`,
  paper: `${PREFIX}-paper`,
  dialogButtonsContainer: `${PREFIX}-dialogButtonsContainer`,
  calenderContainer: `${PREFIX}-calenderContainer`,
}

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  [`&.${classes.filterBar}`]: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    // height: `${FILTER_BAR_HEIGHT}px`, // Not setting height since Location filter depends on the height adjusting
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '56px',
  },

  [`& .${classes.filterBarRow}`]: {
    display: 'flex',
  },

  [`& .${classes.gridRoot}`]: {
    height: '100%',
  },

  [`& .${classes.paper}`]: {
    width: '100%',
    height: '100%',
    padding: '1em',
  },

  [`& .${classes.dialogButtonsContainer}`]: {
    width: '100%',
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: '15px',
    paddingRight: '10px',
  },

  [`& .${classes.calenderContainer}`]: {
    border: `1px solid ${
      theme.palette.mode === 'dark'
        ? theme.palette.primary.light
        : theme.palette.primary.dark
    }`,
    borderRadius: '2px',
    margin: '1em',
    width: 325,
  },
}))

export const FILTER_BAR_HEIGHT = 77 // This is not always the height, by default
export const FILTER_BAR_DOUBLE_HEIGHT = 161 // This is not always the height, by default with extra row
