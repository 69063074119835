import React, { useContext } from 'react'
import ComponentCard from 'components/common/layout/ComponentCard'

import VerticalComparisonTable from 'components/common/tables/VerticalComparisonTable'

import { AnalyticsStore } from 'store/analyticsStore'
import { AppStore } from 'store'
import {
  CamberUsageSummary,
  useCamberUsageSummaryQuery,
} from 'graphql/gen-types'
import { humanTimeDuration } from '../../../utils/functions'

interface Heading {
  key: string
  heading: string
}

const tableHeadings: Heading[] = [
  { key: 'eventsCount', heading: 'Events' },
  { key: 'planClicks', heading: 'Plan/Elevation Clicks' },
  { key: 'brochure', heading: 'Brochure Downloads' },
  { key: 'pageViews', heading: 'Page Views' },
  { key: 'duration', heading: 'Duration' },
]

function SalesCenterUsageSummary(): JSX.Element {
  const { appState } = useContext(AppStore)
  const { analyticsState } = useContext(AnalyticsStore)
  const { selectedClient } = appState
  const clientName = selectedClient?.altName
  const { startDate, endDate } = analyticsState

  const { data, loading, error } = useCamberUsageSummaryQuery({
    variables: {
      filter: {
        clientName: clientName || '',
        startTime: startDate.getTime(),
        endTime: endDate.getTime(),
      },
    },
    skip: !selectedClient,
  })
  const summaryData = data?.getCamberUsageSummary
    ? data?.getCamberUsageSummary.slice().sort((a, b) => {
        const aName = a?.communityName || ''
        const bName = b?.communityName || ''
        return aName.localeCompare(bName, undefined, { numeric: true })
      })
    : []

  return (
    <ComponentCard
      title="Sales Kiosk Usage Summaries"
      result={{ data: summaryData, error, loading }}
      skeletonHeight={400}
    >
      <VerticalComparisonTable
        data={data?.getCamberUsageSummary || []}
        tableHeadings={tableHeadings}
        columnHeaders={summaryData.map((comm) => ({
          id: comm?.communityId || 0,
          name: comm?.communityName || '0',
        }))}
        generateRow={(
          community: unknown,
          key: string,
          index?: number
        ): JSX.Element => (
          <UsageSummaryTableRow
            community={community as CamberUsageSummary}
            rowKey={key}
            index={index}
          />
        )}
        responseSize={'md'}
        dense={false}
      />
    </ComponentCard>
  )
}

function UsageSummaryTableRow({
  community,
  rowKey,
  index,
}: {
  community: CamberUsageSummary
  rowKey: string
  index?: number
}): JSX.Element {
  if (rowKey === 'duration') {
    return (
      <>
        <p>
          <b>Total</b>
          <br />
          {community.durationInfo
            ? humanTimeDuration(community?.durationInfo?.total || 0)
            : 0}
        </p>
        <p>
          <b>Average</b>
          <br />
          {community.durationInfo
            ? humanTimeDuration(community?.durationInfo?.avg || 0)
            : 0}
        </p>
        <p>
          <b>Median</b>
          <br />
          {community.durationInfo
            ? humanTimeDuration(community?.durationInfo?.median || 0)
            : 0}
        </p>
      </>
    )
  }
  return <span>{`${community[rowKey] || '-'}`}</span>
}

export default SalesCenterUsageSummary
