import { styled } from '@mui/material/styles'
import ComponentCard from 'components/common/layout/ComponentCard'
import Typography from '@mui/material/Typography'

const PREFIX = 'PopularCommunitiesList'

export const classes = {
  paperCont: `${PREFIX}-paperCont`,
  gridRoot: `${PREFIX}-gridRoot`,
  grid: `${PREFIX}-grid`,
  rankColorCircle: `${PREFIX}-rankColorCircle`,
  tableContainer: `${PREFIX}-tableContainer`,
  reporTableContainer: `${PREFIX}-reporTableContainer`,
}

export const StyledComponentCard = styled(ComponentCard)({
  [`& .${classes.paperCont}`]: {
    maxHeight: 600,
    marginTop: '20px',
  },

  [`& .${classes.gridRoot}`]: {
    flexGrow: 1,
    padding: '10px 0px',
    height: '90%',
  },

  [`& .${classes.grid}`]: {
    height: '100%',
    overflowY: 'auto',
  },

  [`& .${classes.rankColorCircle}`]: {
    height: '30px',
    width: '30px',
    border: '1px solid rgba(132,132,132,1)',
    borderRadius: '50%',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.tableContainer}`]: {
    maxHeight: 440,
  },

  [`& .${classes.reporTableContainer}`]: {
    height: 256,
  },
})

export const HelpBlock = styled('div')({
  width: '100%',
  marginTop: '0.25em',
  marginBottom: '0.25em',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
})

export const HelpText = styled(Typography)({
  fontSize: '14px',
  marginLeft: '0.25em',
  marginTop: '0.1em',
})
