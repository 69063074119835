import React from 'react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

import {
  classes,
  StyledPaper,
} from './styles/ProspectSessionsOverTimeChart.styles'
import { Maybe, ProspectSignInGrouped } from 'graphql/gen-types'
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  ComposedChart,
} from 'recharts'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import { Unauthorized } from 'components/auth/Unauthorized'

const COMPONENT_TITLE = 'Sign-Ins Over Time'

interface BarChartProps {
  data: unknown[]
  onBarClick?: (month: Date) => void
  height?: number | null
  width?: number | null
}

interface SessionsOverTimeChartProps {
  data: Maybe<ProspectSignInGrouped>[] | []
  loading?: boolean
  error?: Error | undefined
}

export default function SessionsOverTimeChart(
  props: SessionsOverTimeChartProps
): JSX.Element {
  if (props.loading) {
    return (
      <Paper className={classes.container}>
        <Typography variant="h5" className={classes.title}>
          {COMPONENT_TITLE}
        </Typography>
        Loading...
      </Paper>
    )
  }

  if (props.error) {
    if (verifyUserAuthError(props.error.toString())) {
      return (
        <Unauthorized
          message={props.error.toString()}
          imageName={'ProspectStatistics'}
        />
      )
    }
    return <div>{props.error.toString()}</div>
  }

  if (!props.data) {
    return (
      <Paper className={classes.container}>
        <Typography variant="h5" className={classes.title}>
          {COMPONENT_TITLE}
        </Typography>
        No Sign-Ins Data
      </Paper>
    )
  }
  const sortedArray = [...props.data].sort((a, b) => {
    if (!a?.day && !b?.day) {
      return 0
    } else if (!a?.day || !b?.day) {
      return a?.day ? -1 : 1
    }
    return a?.day < b?.day ? -1 : 1
  })
  return (
    <StyledPaper className={classes.container}>
      <Typography variant="h5" className={classes.title}>
        {COMPONENT_TITLE}
      </Typography>
      <BarChart data={sortedArray} />
    </StyledPaper>
  )
}

function BarChart(props: BarChartProps): JSX.Element {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.down('md'))
  const fillColor =
    theme.palette.mode === 'dark'
      ? theme.palette.primary.light
      : theme.palette.primary.dark

  const displayName = 'Sign-Ins'
  const dataName = 'count'
  const renderColorfulLegendText = (
    value: Record<string, string>
  ): JSX.Element => {
    return (
      <span
        style={{
          color: fillColor,
        }}
      >
        {value}
      </span>
    )
  }
  return (
    <Grid container justify="center" spacing={0}>
      <ResponsiveContainer
        width={props.width || '100%'}
        height={props.height || (md ? 250 : 400)}
        style={{ marginLeft: '-50px' }}
      >
        <ComposedChart
          data={props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="day"
            tick={{
              fill: fillColor,
            }}
            angle={-15}
            textAnchor="end"
          />
          <YAxis
            tick={{
              fill: fillColor,
            }}
          />
          <Legend
            formatter={renderColorfulLegendText}
            wrapperStyle={{ position: 'relative', top: -20 }}
          />
          <Bar
            style={props.onBarClick && !md ? { cursor: 'pointer' } : {}}
            dataKey={dataName}
            stackId="anoAndRegisteredUsers"
            fill={theme.palette.primary.main}
            name={displayName}
            onClick={(e: any) => {
              if (props.onBarClick && e.date && !md) {
                const month = new Date(e.date)
                props.onBarClick(month)
              }
            }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Grid>
  )
}
