import { atom } from 'jotai'

// common enum space for atomic actions
export enum AtomActionType {
  SET_SNACKBAR_CONFIG,
}

interface SnackbarConfig {
  anchorOrigin?: AnchorOrigin
  autoHideDuration?: number
  classes?: string
  closeButton?: boolean
  open: boolean
  message: string | JSX.Element
  severity?: 'error' | 'success' | 'info' | 'warning' | undefined
}

interface AnchorOrigin {
  vertical: 'bottom' | 'top'
  horizontal: 'left' | 'right' | 'center'
}

interface menuDialogConfig {
  open: boolean
}

export const snackbarConfigAtom = atom<SnackbarConfig>({
  open: false,
  message: '',
})

export const menuDialogConfigAtom = atom<menuDialogConfig>({
  open: false,
})
