import { Map, LngLatBoundsLike, GeoJSONSource } from 'mapbox-gl'
import { Geometry } from 'geojson'
import 'mapbox-gl/dist/mapbox-gl.css'
import {
  Lot,
  LotLegendEntry,
  SiteplanGeoInfo,
} from '../../../../graphql/gen-types'
import {
  GeolibGeoJSONPoint,
  GeolibInputCoordinates,
  GeolibLatitudeInputValue,
  GeolibLongitudeInputValue,
} from 'geolib/es/types'
import * as geolib from 'geolib'
import chroma from 'chroma-js'

const BOUNDS_OFFSET = 0.0005
const GRAY_FILL = '#d3d3d3'
const SITEPLAN_FILL = '#ff6600'
const SITEPLAN_OPACITY = 0.025

// type Point = [number, number]
type MapFeature = {
  type: 'Feature'
  geometry: Geometry
  properties: {
    title: string
    idx: number
    name?: string
  }
}

/* GEOJSON FUNCTIONS */
export const drawSitePlan = (
  map: Map,
  sitePlanGeoInfo: SiteplanGeoInfo
): void => {
  const sitePlanSource: GeoJSONSource = map.getSource(
    'siteplan'
  ) as GeoJSONSource
  if (sitePlanSource) {
    sitePlanSource.setData({
      type: 'FeatureCollection',
      features: [sitePlanGeoInfo.geoJson],
    })
  } else {
    map.addSource('siteplan', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [sitePlanGeoInfo.geoJson],
      },
    })

    map.addLayer({
      id: 'siteplan-fill',
      type: 'fill',
      source: 'siteplan',
      layout: {},
      paint: {
        'fill-color': SITEPLAN_FILL,
        'fill-opacity': SITEPLAN_OPACITY,
      },
    })

    map.addLayer({
      id: 'siteplan-outline',
      type: 'line',
      source: 'siteplan',
      layout: {},
      paint: {
        'line-color': '#000',
        'line-width': 3,
        'line-dasharray': [1, 2],
      },
    })
  }
}

export const drawLotShapes = (map: Map, drawLots: Lot['geoJson']): void => {
  const lotsSource: GeoJSONSource = map.getSource('lot-shapes') as GeoJSONSource
  if (lotsSource) {
    lotsSource.setData({
      type: 'FeatureCollection',
      features: drawLots,
    })
  } else {
    map.addSource('lot-shapes', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: drawLots,
      },
    })

    map.addLayer({
      id: 'lot-shapes-fill',
      type: 'fill',
      source: 'lot-shapes',
      layout: {},
      paint: {
        'fill-color': ['get', 'hex'],
        'fill-opacity': ['get', 'hexOpacity'],
      },
      filter: ['==', '$type', 'Polygon'],
    })

    map.addLayer({
      id: 'lot-points',
      type: 'circle',
      source: 'lot-shapes',
      paint: {
        'circle-radius': 10,
        'circle-color': ['get', 'hex'],
        'circle-stroke-width': ['get', 'outlineWidth'],
        'circle-stroke-color': ['get', 'outlineColor'],
        'circle-opacity': ['get', 'hexOpacity'],
      },
      filter: ['all', ['==', '$type', 'Point'], ['has', 'hex']],
    })

    map.addLayer({
      id: 'lot-shapes-outline',
      type: 'line',
      source: 'lot-shapes',
      layout: {},
      paint: {
        'line-color': ['get', 'outlineColor'],
        'line-width': ['get', 'outlineWidth'],
      },
      filter: ['==', '$type', 'Polygon'],
    })
  }
}

export const drawLotNames = (map: Map, drawLots: Lot['geoJson']): void => {
  const lotNameFeatures = drawLots.map((lot: Lot['geoJson']) => {
    if (lot.geometry.type === 'Point') {
      return {
        type: 'Feature',
        properties: {
          name: lot.properties.name,
          lotType: 'Point',
        },
        geometry: {
          type: 'Point',
          coordinates: lot.geometry.coordinates,
        },
      }
    }

    const labelCoordinates: GeolibInputCoordinates = calculateVisualCenterOfLot(
      lot
    )

    return {
      type: 'Feature',
      properties: {
        name: lot.properties.name,
      },
      geometry: {
        type: 'Point',
        coordinates: [labelCoordinates?.longitude, labelCoordinates?.latitude],
      },
    }
  }) as MapFeature[]

  const lotNamesSource: GeoJSONSource = map.getSource(
    'lot-names'
  ) as GeoJSONSource
  if (lotNamesSource) {
    lotNamesSource.setData({
      type: 'FeatureCollection',
      features: lotNameFeatures,
    })
  } else {
    map.addSource('lot-names', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: lotNameFeatures,
      },
    })

    map.addLayer({
      id: 'lot-names',
      type: 'symbol',
      source: 'lot-names',
      layout: {
        'text-field': ['get', 'name'],
        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
        'text-justify': 'center',
      },
      filter: ['!=', 'lotType', 'Point'],
    })

    map.addLayer({
      id: 'lot-point-names',
      type: 'symbol',
      source: 'lot-names',
      layout: {
        'text-field': ['get', 'name'],
        'text-offset': [1, -1],
      },
      filter: ['==', 'lotType', 'Point'],
    })
  }
}

export const getLotsDrawData = (
  lots: Lot[],
  legend: LotLegendEntry[],
  mode: boolean,
  min: number,
  max: number
): Lot['geoJson'] => {
  return lots.map((lot) => {
    const popularity = lot.popularity || 0
    const outlineColor = '#000'
    const outlineWidth = 1
    const hexOpacity = 0.7
    const heatMapHex = popularity
      ? heatMapScale(popularity / max).hex()
      : GRAY_FILL
    let hex
    if (mode) {
      hex = heatMapHex
    } else {
      hex =
        '#' + legend.find((entry) => entry.code === lot.salesStatus)?.hex ||
        '#eee'
    }

    return {
      ...lot.geoJson,
      properties: {
        name: lot.name,
        code: lot.salesStatus,
        hex,
        id: lot.id,
        outlineColor,
        outlineWidth,
        hexOpacity,
      },
    }
  })
}

export const calculateVisualCenterOfLot = (
  lot: Lot['geoJson']
): {
  longitude: GeolibLongitudeInputValue
  latitude: GeolibLatitudeInputValue
} => {
  const lats: number[] = []
  const lngs: number[] = []

  lot.geometry.coordinates[0].forEach((point: GeolibGeoJSONPoint) => {
    lats.push(point[1] as number)
    lngs.push(point[0] as number)
  })

  const minLat = Math.min(...lats)
  const minLng = Math.min(...lngs)
  const maxLat = Math.max(...lats)
  const maxLng = Math.max(...lngs)

  const latStep = (maxLat - minLat) / 10
  const lngStep = (maxLng - minLng) / 10

  // We 'wrapped' polygon with rectangle and we calculated 10% of its length and height.
  // Now we want to calculate 81 test points that are in this rectangle.
  const testPoints: GeolibInputCoordinates[] = []

  for (let i = 1; i < 10; i++) {
    for (let j = 1; j < 10; j++) {
      testPoints.push({
        latitude: minLat + latStep * i,
        longitude: minLng + lngStep * j,
      })
    }
  }

  // We need array of all polygon edges.
  const polyLines: {
    point1: GeolibInputCoordinates
    point2: GeolibInputCoordinates
  }[] = []
  for (let k = 0; k < lot.geometry.coordinates[0].length - 1; k++) {
    polyLines.push({
      point1: {
        latitude: lot.geometry.coordinates[0][k][1],
        longitude: lot.geometry.coordinates[0][k][0],
      },
      point2: {
        latitude: lot.geometry.coordinates[0][k + 1][1],
        longitude: lot.geometry.coordinates[0][k + 1][0],
      },
    })
  }

  // In geolib library, polygon is defiend by array of its point.
  const polyPoints = lot.geometry.coordinates[0].map(
    (point: GeolibGeoJSONPoint) => {
      return {
        latitude: point[1],
        longitude: point[0],
      }
    }
  )

  let bigistPossibleRad = 0
  let bestPoint: GeolibInputCoordinates = { latitude: 0, longitude: 0 }

  // Now we test each point to see if it is in best visual center.
  testPoints.forEach((point) => {
    // Point must be inside polygon, so we test that first.
    if (geolib.isPointInPolygon(point, polyPoints)) {
      // We calculate distance of point from all polygon edges.
      const distances: number[] = []
      polyLines.forEach((line) => {
        const pointDist = geolib.getDistanceFromLine(
          point,
          line.point1,
          line.point2
        )
        distances.push(pointDist)
      })
      // minDist represents radian of bigist circle we can fit into polygon from this coordinartes.
      const minDist = Math.min(...distances)
      if (minDist > bigistPossibleRad) {
        // The larger the circle, the more in the visual center it is.
        bigistPossibleRad = minDist
        bestPoint = point
      }
    }
  })

  return bestPoint
}

export function getMapBounds(geoJson: SiteplanGeoInfo): LngLatBoundsLike {
  return [
    [
      (geoJson.neLongitude || 0) + BOUNDS_OFFSET,
      (geoJson.neLatitude || 0) + BOUNDS_OFFSET,
    ],
    [
      (geoJson.swLongitude || 0) - BOUNDS_OFFSET,
      (geoJson.swLatitude || 0) - BOUNDS_OFFSET,
    ],
  ]
}

/* FUNCTIONS */
export const heatMapScale = chroma
  .scale(['#fff33b', '#fdc70c', '#f3903f', '#ed683c', '#e93e3a'])
  .correctLightness()
