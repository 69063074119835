import { initTracker } from '@anewgo/tracking'

export const initializeTracking = async (): Promise<void> => {
  try {
    await initTracker(
      process?.env?.REACT_APP_TRACK_ANALYTICS?.toLowerCase() === 'true',
      process.env.REACT_APP_ANALYTICS_WRITE_KEY || ''
    )
  } catch (ex) {
    console.error('failed to initialize tracker', ex)
  }
}
