import React, { useState, useEffect } from 'react'
import { Elevation } from 'graphql/gen-types'

import ResponsiveTable, {
  ResponsiveCell,
} from 'components/common/tables/ResponsiveTable'
import DateFnsAdapter from '@date-io/date-fns'

const dateFns = new DateFnsAdapter()

type ElevationWithTimeStamps = {
  timestamp: number | null
  elevation: Elevation | undefined | null
}

export default function ElevationsTable({
  elevations,
}: {
  elevations: ElevationWithTimeStamps[] | undefined | null
}): JSX.Element {
  const [sortedElevations, setSortedElevations] = useState<
    ElevationWithTimeStamps[]
  >([])

  useEffect(() => {
    if (!elevations) {
      setSortedElevations([])
      return
    }
    const sortElevations = elevations
      ?.slice()
      .sort((a: ElevationWithTimeStamps, b: ElevationWithTimeStamps) => {
        if (!a.timestamp || !b.timestamp) {
          if (a.timestamp && !b.timestamp) {
            return -1
          } else if (b.timestamp && !a.timestamp) {
            return 1
          } else {
            return 0
          }
        }

        if (a.timestamp > b.timestamp) {
          return -1
        } else if (a.timestamp < b.timestamp) {
          return 1
        }
        return 0
      })

    setSortedElevations(sortElevations)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(elevations)])

  const columns = [
    <ResponsiveCell responseSize="md" align="center" key={1}>
      <strong>Plan</strong>
    </ResponsiveCell>,

    <ResponsiveCell responseSize="md" align="center" key={2}>
      <strong>Elevation</strong>
    </ResponsiveCell>,

    <ResponsiveCell responseSize="md" align="center" key={2}>
      <strong>Updated</strong>
    </ResponsiveCell>,
  ]

  const generateRow = (
    dataRow: ElevationWithTimeStamps,
    index?: number
  ): JSX.Element => {
    return (
      <>
        <ResponsiveCell
          responseSize="md"
          align="center"
          key="row-cell-plan-name"
        >
          {dataRow?.elevation?.planName}
        </ResponsiveCell>
        <ResponsiveCell
          responseSize="md"
          align="center"
          key="row-cell-elevation-name"
        >
          {dataRow?.elevation?.caption}
        </ResponsiveCell>
        <ResponsiveCell
          responseSize="md"
          align="center"
          key="row-cell-favorite-timestamp"
        >
          {dataRow?.timestamp
            ? dateFns.format(new Date(dataRow?.timestamp), 'MMM do yyyy')
            : 'No data available'}
        </ResponsiveCell>
      </>
    )
  }

  return (
    <ResponsiveTable
      data={
        sortedElevations ? (sortedElevations as ElevationWithTimeStamps[]) : []
      }
      columns={columns}
      generateRow={generateRow}
      dense={true}
    />
  )
}
