import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import MuiAlert from '@mui/material/Alert'
import { useAtom } from 'jotai'
import { useMutation } from '@apollo/client'
import React, { useContext, useState, ReactText } from 'react'
import { ColonnadeRole, User } from 'graphql/gen-types'
import { ADD_COLONNADE_USER } from 'graphql/mutation/addColonnadeUser'
import USERS_QUERY from 'graphql/colonnade-queries/users'
import { AppStore } from 'store'
import { checkIsEmail, snakeCaseToTitleCase } from 'utils/functions'
import { AuthContext } from 'auth/AuthContext'
import { ANEWGO_ADMIN_ROLE_ID, ANEWGO_STAFF_ROLE_ID } from '../../../constants'
import { snackbarConfigAtom } from 'store/atoms'
import { classes, StyledDialog } from './AddUserDialog.styles'

interface AddUserDialogProps {
  addUserDialogOpen: boolean
  setAddUserDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  roles: ColonnadeRole[]
  users: User[]
}

export default function AddUserDialog(props: AddUserDialogProps): JSX.Element {
  const { user } = useContext(AuthContext)
  const [snackbarConfig, setSnackbarConfig] = useAtom(snackbarConfigAtom)
  const { appState } = useContext(AppStore)
  const clientName = appState.selectedClient?.altName || ''

  const [firstName, setFirstName] = useState('')

  const [lastName, setLastName] = useState('')

  const [email, setEmail] = useState('')

  const [organizationRole, setOrganizationRole] = useState('')

  const [role, setRole] = useState<number | string>('')

  const [warn, setWarn] = useState('')

  // mutation hook
  const [addColonnadeUser] = useMutation(ADD_COLONNADE_USER)

  // Add User handlers
  const handleAddUserSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault()

    if (!checkIsEmail(email)) {
      setWarn('Please enter a valid email address.')
      return
    } else if (
      props.users.find((user: User) => user.email === email.toLowerCase()) !==
      undefined
    ) {
      setWarn(
        'An Insights user with that email already exists in your dashboard.'
      )
      return
    }

    addColonnadeUser({
      variables: {
        clientName,
        input: {
          firstName,
          lastName,
          organizationRole,
          email,
          roleId: role,
        },
      },
      refetchQueries: [{ query: USERS_QUERY, variables: { clientName } }],
    })
      .then(() => {
        setSnackbarConfig({
          ...snackbarConfig,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          autoHideDuration: 6000,
          open: true,
          message: 'Insights user added successfully!',
          severity: 'success',
        })
        clearFields()
        handleAddUserClose()
      })
      .catch((err) => {
        console.log(err)
        if (err.message.includes('An Insights user with the email')) {
          // Handle the specific error where the user already exists
          setWarn(
            'An Insights user with that email already exists in your dashboard.'
          )
        } else {
          setSnackbarConfig({
            ...snackbarConfig,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            autoHideDuration: 6000,
            open: true,
            message: err.message.replace(/.*[Ee]rror: /, ''),
            severity: 'error',
          })
        }
      })
  }

  const clearFields = (): void => {
    setFirstName('')
    setLastName('')
    setEmail('')
    setOrganizationRole('')
    setRole('')
  }

  const handleAddUserClose = (): void => {
    setWarn('')
    props.setAddUserDialogOpen(false)
  }

  const handleAddUserRoleChange = (e: SelectChangeEvent<ReactText>): void => {
    const selectedRole = e.target.value as number | string
    setRole(selectedRole)
  }

  const handleFirstNameChange = (input: string): void => {
    setFirstName(input)
  }

  const handleLastNameChange = (input: string): void => {
    setLastName(input)
  }

  const handleEmailChange = (input: string): void => {
    setEmail(input)
  }

  const handleOrganizationRoleChange = (input: string): void => {
    setOrganizationRole(input)
  }

  return (
    <StyledDialog
      open={props.addUserDialogOpen}
      onClose={handleAddUserClose}
      aria-labelledby="user-add-title"
    >
      {props.addUserDialogOpen && (
        <div>
          <DialogTitle id="user-add-title">Add a New User</DialogTitle>
          <DialogContent>
            <form onSubmit={handleAddUserSubmit}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  {warn ? <MuiAlert severity="warning">{warn}</MuiAlert> : null}
                </Grid>
                <Grid item>
                  <FormControl
                    className={classes.formControlAddUser}
                    variant="standard"
                  >
                    <TextField
                      variant="standard"
                      data-testid="addUserEmailField"
                      id="addUserEmailField"
                      label={'Email'}
                      value={email}
                      required
                      onChange={(ev): void =>
                        handleEmailChange(ev.target.value)
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid container direction="row">
                  <Grid item xs={12} md={6} className={classes.gridItem}>
                    <FormControl
                      required
                      className={classes.formControlAddUser}
                      variant="standard"
                    >
                      <TextField
                        variant="standard"
                        data-testid="addUserFirstNameField"
                        id="addUserFirstNameField"
                        className={classes.addUserFirstNameInputField}
                        label={'First Name'}
                        required
                        value={firstName}
                        onChange={(ev): void =>
                          handleFirstNameChange(ev.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      required
                      className={classes.formControlAddUser}
                      variant="standard"
                    >
                      <TextField
                        variant="standard"
                        data-testid="addUserLastNameField"
                        id="addUserLastNameField"
                        className={classes.addUserLastNameInputField}
                        label={'Last Name'}
                        required
                        value={lastName}
                        onChange={(ev): void =>
                          handleLastNameChange(ev.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item>
                  <FormControl className={classes.formControlAddUser}>
                    <TextField
                      variant="standard"
                      data-testid="addUserOrganizationRoleField"
                      id="addUserOrganizationRoleField"
                      label={'Organizational Role'}
                      value={organizationRole}
                      onChange={(ev): void =>
                        handleOrganizationRoleChange(ev.target.value)
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl required className={classes.formControlAddUser}>
                    <InputLabel
                      required
                      htmlFor="addUserRoleSelect"
                      variant="standard"
                    >
                      {'Role'}
                    </InputLabel>
                    <Select
                      variant="standard"
                      data-testid="addUserRoleSelect"
                      onChange={handleAddUserRoleChange}
                      inputProps={{
                        name: 'addUserRoleSelect',
                        id: 'addUserRoleSelect',
                      }}
                      value={role}
                      required
                    >
                      {props.roles.map((role) => {
                        const item = (
                          <MenuItem key={role.id} value={role.id}>
                            {snakeCaseToTitleCase(role.role)}
                          </MenuItem>
                        )
                        const roleIsAdmin = role.role === 'ANEWGO_ADMIN'
                        const roleIsStaff = role.role === 'ANEWGO_STAFF'
                        const roleIsAnewgo = roleIsAdmin || roleIsStaff
                        // If user is anewgo admin, display all
                        if (user.roleId === ANEWGO_ADMIN_ROLE_ID) {
                          return item
                          // If user is a anewgo staff, display all except admin
                        } else if (user.roleId === ANEWGO_STAFF_ROLE_ID) {
                          return roleIsAdmin ? null : item
                          // If user is not anewgo admin or staff
                          // if role is not anewgo, display it.
                        } else if (!roleIsAnewgo) {
                          return item
                          // Otherwise null
                        } else {
                          return null
                        }
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between">
                <Button
                  onClick={handleAddUserClose}
                  variant="contained"
                  color="primary"
                >
                  Close
                </Button>
                <Button
                  aria-label="submit-button"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </Grid>
            </form>
          </DialogContent>
        </div>
      )}
    </StyledDialog>
  )
}
