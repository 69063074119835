import React from 'react'
import FormControl, { FormControlProps } from '@mui/material/FormControl'
import { styled } from '@mui/material/styles'
import { StyledComponent } from '@mui/styles/styled'

interface StyledFormControlArgs {
  industryTrendsSelect?: boolean
  locationsFilterSelect?: boolean
  leadsLocationSelect?: boolean
}

interface BuildStylesResult {
  classes: {
    formControl: string
    select: string
    selectLarger: string
  }
  StyledFormControl: StyledComponent<StyledFormControlArgs & FormControlProps>
}

export function BuildStyles(prefix: string): BuildStylesResult {
  const classes = {
    formControl: `${prefix}-formControl`,
    select: `${prefix}-select`,
    selectLarger: `${prefix}-selectLarger`,
  }

  // Styled props has to be filtered out, otherwise React error warning occurs.
  // https://gist.github.com/meddokss/009cdb5192e487654c0d90cd8f692214
  const StyledFormControl = styled(
    ({
      industryTrendsSelect,
      locationsFilterSelect,
      leadsLocationSelect,
      ...props
    }: StyledFormControlArgs & FormControlProps) => <FormControl {...props} />
  )<StyledFormControlArgs>(
    ({
      theme,
      industryTrendsSelect,
      locationsFilterSelect,
      leadsLocationSelect,
    }) => ({
      [`&.${classes.formControl}`]: {
        ...((industryTrendsSelect ||
          locationsFilterSelect ||
          leadsLocationSelect) && {
          margin: `0px ${theme.spacing(1)}`,
          minWidth: 130,
          maxWidth: 300,
        }),
        ...(industryTrendsSelect && {
          [theme.breakpoints.down('sm')]: {
            minWidth: 150,
            maxWidth: 220,
          },
        }),
      },
      [`& .${classes.select}`]: {
        ...(industryTrendsSelect && {
          minWidth: 150,
          maxWidth: 200,
          [theme.breakpoints.down('sm')]: {
            minWidth: 100,
            maxWidth: 140,
          },
        }),
        ...((locationsFilterSelect || leadsLocationSelect) && {
          maxWidth: 200,
        }),
      },
      [`& .${classes.selectLarger}`]: {
        ...((locationsFilterSelect || leadsLocationSelect) && {
          minWidth: 150,
          maxWidth: 200,
        }),
      },
    })
  )

  return {
    classes,
    StyledFormControl,
  }
}
