import React, { useState, useEffect } from 'react'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DatePicker from '@mui/lab/DatePicker'
import { Milestone } from '../ProspectPipeline'

/**
 * Modal for editing individual milstones.
 */
export default function EditMilestoneModal({
  value,
  step,
  open,
  onClose,
  onSubmit,
  onDelete,
}: {
  value: Milestone
  step: number
  open: boolean
  onClose: () => void
  onSubmit: (milestone: Milestone) => void
  onDelete: (step: number) => void
}): JSX.Element {
  const [title, setTitle] = useState('')
  const [notes, setNotes] = useState('')
  const [date, setDate] = useState(new Date())

  /* Handles for updating text fields / input value state */
  const handleTitleChange = (title: string): void => {
    setTitle(title)
  }
  const handleNotesChange = (notes: string): void => {
    setNotes(notes)
  }
  const handleDateChange = (date: Date | null): void => {
    if (date) {
      setDate(date)
    }
  }
  const handleDelete = (): void => {
    onDelete(step)
  }
  // Set values on load
  useEffect(() => {
    setTitle(value?.title)
    setNotes(value?.notes)
    setDate(value?.date || new Date())
  }, [value])
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    onSubmit({ ...value, title, date, notes })
  }
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit Milestone</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Make edits to the current Milestone
        </DialogContentText>
        <Grid container justifyContent="center">
          <Grid item>
            <DatePicker
              showToolbar
              label="Date"
              inputFormat="MM/dd/yyyy"
              value={date}
              onChange={handleDateChange}
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
            />
          </Grid>
        </Grid>
        <TextField
          autoFocus
          id="name"
          label="Milestone Name"
          type="input"
          fullWidth
          variant="outlined"
          margin="normal"
          defaultValue={value?.title}
          onChange={(e): void => handleTitleChange(e.target.value)}
        />
        <TextField
          id="outlined-multiline-static"
          label="Notes"
          multiline
          rows={4}
          fullWidth
          variant="outlined"
          margin="normal"
          defaultValue={value?.notes}
          onChange={(e): void => handleNotesChange(e.target.value)}
        />

        <Button onClick={handleDelete} color="primary" variant="contained">
          Delete Milestone
        </Button>
      </DialogContent>
      <form onSubmit={handleSubmit}>
        <DialogActions>
          <Button onClick={onClose} color="primary" variant="contained">
            Cancel
          </Button>
          <Button
            onClick={onClose}
            color="primary"
            variant="contained"
            type="submit"
          >
            Save Changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
