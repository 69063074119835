//TODO this works only for up to 5 elements right now
// this is not final solution but "skeleton"
export function getPalette(numberOfElements: number): string[] {
  return [
    '#00876c',
    '#84b76e',
    '#f4e07f',
    '#ef9250',
    '#d43d51',
    '#7300e6',
    '#1a66ff',
    '#ff80ff',
    '#ff0066',
    '#33ffbb',
  ]
}
