import { gql } from '@apollo/client'

export const SEND_RECOMMENDED_PLANS = gql`
  mutation sendRecommendedPlansEmail(
    $clientName: String!
    $prospectName: String!
    $prospectEmail: String!
    $agentEmail: String!
    $agentName: String!
    $plans: [RecommendedPlan!]!
  ) {
    sendRecommendedPlansEmail(
      clientName: $clientName
      prospectName: $prospectName
      prospectEmail: $prospectEmail
      agentEmail: $agentEmail
      agentName: $agentName
      plans: $plans
    )
  }
`
