import React from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Tooltip from '@mui/material/Tooltip'
import LockIcon from '@mui/icons-material/Lock'

export interface AuthorizationListItemProps {
  style?: Record<any, any>
  classes?: Record<any, any>
  onClick?: () => void
  selected?: boolean
  disabled: boolean
  disabledReason: string | null
  icon: React.ReactNode
  title: string
}

export default function AuthorizationListItem({
  style,
  classes,
  onClick,
  selected,
  disabled,
  disabledReason,
  icon,
  title,
}: AuthorizationListItemProps): JSX.Element {
  const inner = (
    <>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={title} />

      {disabled && <LockIcon color="primary" />}
    </>
  )

  return (
    <Tooltip
      title={disabledReason || ''}
      disableFocusListener={!disabled}
      disableHoverListener={!disabled}
      disableTouchListener={!disabled}
    >
      {disabled ? (
        <ListItem
          style={style}
          classes={classes}
          selected={selected}
          disabled={disabled}
        >
          {inner}
        </ListItem>
      ) : (
        <ListItemButton
          style={style}
          classes={classes}
          onClick={onClick}
          selected={selected}
          disabled={disabled}
        >
          {inner}
        </ListItemButton>
      )}
    </Tooltip>
  )
}
