import React, { useMemo, useRef, useState, useEffect } from 'react'
import Sections from 'components/common/layout/Sections'
import BoundaryObserver from './BoundaryObserver'
import { classes, Root } from './InfiniteScroll.styles'

export interface SectionProps {
  className: string
}

export interface InfiniteScrollProps {
  loading?: boolean
  sectionComponents: JSX.Element[]
  rootless?: boolean
}

/**
 * InfiniteScroll is a container component that contains many sections.
 * On mount, it will only render the first section that is in the view port.
 * When a user scrolls the container, it will make use of a boundary observer
 * to detect when the boundary observer intersects with the container. When
 * that occurs, it will add to the DOM subtree the following section to
 * render.
 * The props is defined by the interface InfiniteScrollProps
 * @param loading - boolean to tell InfiniteScroll to wait until data
 * loading is complete. If you don't care, you can ignore setting this
 * @param sectionComponents - an array of JSX.Elements representing all
 * the sections you want to render in the container
 * @param root - set this to true if you want the parent container to be
 * the root
 */
function InfiniteScroll({
  loading = false,
  sectionComponents,
  rootless,
}: InfiniteScrollProps): JSX.Element {
  const rootRef = useRef(null)
  const [done, setDone] = useState(false)
  const [sections, setSections] = useState([] as Array<SectionProps>)
  const [displayedSections, setDisplayedSections] = useState(
    [] as Array<SectionProps>
  )
  const numberOfSections = useMemo(() => {
    return sectionComponents.length
  }, [sectionComponents])

  useEffect(() => {
    const mySections: Array<SectionProps> = []
    for (let i = 0; i < numberOfSections; i++) {
      mySections.push({ className: classes.section })
    }
    setSections(mySections)
    const newDisplayedSections = [mySections[0]]
    setDisplayedSections(newDisplayedSections)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classes.section, numberOfSections])

  const loadMore = (): void => {
    if (displayedSections.length === sections.length) {
      setDone(done)
      return
    }
    const nextIndex = displayedSections.length
    const newSections = [...displayedSections, sections[nextIndex]]
    setDisplayedSections(newSections)
  }

  const renderContent = (): JSX.Element => {
    return (
      <React.Fragment>
        <Sections
          sections={displayedSections}
          sectionComponents={sectionComponents}
        />
        <BoundaryObserver
          loading={loading}
          loadMore={loadMore}
          done={done}
          root={rootRef.current}
        />
      </React.Fragment>
    )
  }

  if (rootless) {
    return renderContent()
  }
  return <Root className={classes.root}>{renderContent()}</Root>
}

export default InfiniteScroll
