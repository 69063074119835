import React, { useContext, useState, useEffect, SyntheticEvent } from 'react'
import { useCamberCommunityIdsQuery, Community } from 'graphql/gen-types'
import { AppStore } from 'store'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { StyledGrid, classes } from './SalesCenterSelect.styles'

function SalesCenterSelect(): JSX.Element {
  const navigate = useNavigate()
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down(900))
  const { appState } = useContext(AppStore)
  const clientName = appState.selectedClient?.altName || ''
  const initialState: Community[] = []
  const [camberCommunities, setCamberCommunities] = useState(initialState)
  const [selectedCommunityId, setSelectedCommunityId] = useState<
    number | undefined
  >()

  const { data } = useCamberCommunityIdsQuery({
    variables: {
      clientName,
    },
  })

  const handleCommunityChange = (
    __: SyntheticEvent<Element, Event>,
    value: Community | null
  ) => {
    if (value?.id) {
      setSelectedCommunityId(value.id)
    } else {
      setSelectedCommunityId(undefined)
    }
  }

  const navigateToCommunity = () => {
    navigate(`../community?id=${selectedCommunityId}`)
  }
  // TODO: Look into this StackOverflow issue as a possible solution... should not pass a stringified object to useEffect...
  // https://stackoverflow.com/questions/55808749/use-object-in-useeffect-2nd-param-without-having-to-stringify-it-to-json
  useEffect(() => {
    if (data?.communities && data.communities.length !== 0) {
      const filteredCommunities = data.communities.filter(
        (comm) => comm?.salesCenterActive
      )
      setCamberCommunities(filteredCommunities as Community[])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [String(data?.communities)])

  useEffect(() => {
    if (sm) {
      navigateToCommunity()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunityId])

  return (
    <StyledGrid container spacing={2} className={classes.root}>
      <Grid item>
        <Autocomplete
          id="combo-box-agent"
          options={camberCommunities}
          className={classes.select}
          getOptionLabel={(comm) => comm.name || ''}
          renderInput={(params) => (
            <TextField
              data-testid="selectCommunity"
              {...params}
              label="Select Community"
              variant="outlined"
            />
          )}
          onChange={handleCommunityChange}
        />
      </Grid>
      {!sm && (
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disabled={!selectedCommunityId}
            className={classes.button}
            onClick={navigateToCommunity}
          >
            Inspect Community
          </Button>
        </Grid>
      )}
    </StyledGrid>
  )
}

export default SalesCenterSelect
