import React, { useCallback, useContext, useState, ReactElement } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import {
  Community,
  LeadScores,
  LeadStatus,
  ProspectStatus,
} from '../../../graphql/gen-types'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'

import FilterBar from 'components/common/filter-bar/FilterBar'
import { MultiOptionAutoComplete } from 'components/common/custom-inputs/MultiOptionsAutocomplete'
import { AppStore } from 'store'
import { snakeCaseToTitleCase } from 'utils/functions'
import useMediaQuery from '@mui/material/useMediaQuery'
import MobileFilterBar from '../../common/filter-bar/MobileFilterBar'
import { debounce } from '../../utils/debounce'
import AlertDialog from '../../analytics/locations-filters/components/AlertDialog'
import LocationsFilters from '../../analytics/locations-filters/LocationsFilters'
import { AnalyticsStore } from '../../../store/analyticsStore'
import useLocationFilters from 'components/analytics/locations-filters/hooks/useLocationFilters'
import {
  LocationSelection,
  SetState,
} from '../../analytics/locations-filters/Shared'

const PREFIX = 'LeadsFilterBar'

const classes = {
  filtersContainer: `${PREFIX}-filtersContainer`,
  filters: `${PREFIX}-filters`,
  formControl: `${PREFIX}-formControl`,
  chips: `${PREFIX}-chips`,
  chip: `${PREFIX}-chip`,
  noLabel: `${PREFIX}-noLabel`,
  selectCountries: `${PREFIX}-selectCountries`,
  selectCities: `${PREFIX}-selectCities`,
  selectCitiesLarger: `${PREFIX}-selectCitiesLarger`,
  selectCounty: `${PREFIX}-selectCounty`,
  selectPostCode: `${PREFIX}-selectPostCode`,
  selectRegion: `${PREFIX}-selectRegion`,
  selectRegionLarger: `${PREFIX}-selectRegionLarger`,
  selectState: `${PREFIX}-selectState`,
  selectZip: `${PREFIX}-selectZip`,
  componentContainer: `${PREFIX}-componentContainer`,
}

const LeadsFilterBarStyled = styled('div')(({ theme }) => ({
  [`& .${classes.filtersContainer}`]: {
    display: 'flex',
    gap: '10px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginBottom: theme.spacing(1),
    },
  },

  [`& .${classes.filters}`]: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.formControl}`]: {
    margin: `0px ${theme.spacing(1)}`,
    minWidth: 130,
    maxWidth: 300,
  },

  [`& .${classes.chips}`]: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  [`& .${classes.chip}`]: {
    margin: 2,
  },

  [`& .${classes.noLabel}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.selectCountries}`]: {
    minWidth: 150,
    maxWidth: 160,
  },

  [`& .${classes.selectCities}`]: {
    maxWidth: 220,
  },

  [`& .${classes.selectCitiesLarger}`]: {
    minWidth: 150,
  },

  [`& .${classes.selectCounty}`]: {
    minWidth: 150,
    maxWidth: 200,
  },

  [`& .${classes.selectPostCode}`]: {
    minWidth: 150,
    maxWidth: 200,
  },

  [`& .${classes.selectRegion}`]: {
    maxWidth: 200,
  },

  [`& .${classes.selectRegionLarger}`]: {
    minWidth: 150,
    maxWidth: 200,
  },

  [`& .${classes.selectState}`]: {
    minWidth: 150,
    maxWidth: 200,
  },

  [`& .${classes.selectZip}`]: {
    minWidth: 150,
    maxWidth: 200,
  },

  [`& .${classes.componentContainer}`]: {
    marginTop: '1em',
    marginBottom: '1em',
    padding: '2em',
    justifyContent: 'center',
  },
}))
export interface Selection<T = string> {
  value: T
  name: string
}

export type LeadScoreSelection = Selection<LeadScores>

export type ProspectStatusSelection = Selection<ProspectStatus>

export type LeadStatusSelection = Selection<LeadStatus>

interface LeadsFilterBarProps {
  onSelectedScores?: (selectedScores: LeadScoreSelection[]) => void
  onSelectedStatuses?: (selectedStatuses: LeadStatusSelection[]) => void
  onSearch?: (search: string) => void
  onSetLeadChartSelectedCommunities?: (
    selectedCommunities: Community[] | undefined
  ) => void
  onLocationSelection?: SetState<LocationSelection>
}

const statuses: LeadStatusSelection[] = Object.entries(LeadStatus)
  .sort((a, b) => a[1].localeCompare(b[1], undefined, { numeric: true }))
  .map(
    (row): LeadStatusSelection => {
      const value = row[1]
      return {
        name: snakeCaseToTitleCase(value),
        value,
      }
    }
  )

export default function LeadsFilterBar({
  onSelectedScores,
  onSelectedStatuses,
  onSearch,
  onSetLeadChartSelectedCommunities,
  onLocationSelection,
}: LeadsFilterBarProps): ReactElement | null {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down(900))
  const { appState } = useContext(AppStore)
  const { selectedClient } = appState
  const { analyticsState } = useContext(AnalyticsStore)
  const { startDate, endDate } = analyticsState
  const clientName = selectedClient?.altName || ''

  const {
    allCommunitiesData,
    filteredCommunitiesData,
    selection,
    setSelection,
    setSelectedCommunities,
    selectedCommunities,
    handleCommunitiesSelection,
    openDialog,
    setOpenDialog,
    dialogMessage,
    setDialogMessage,
  } = useLocationFilters({
    clientName,
    startDate,
    endDate,
    onSelectedCommunities: onSetLeadChartSelectedCommunities,
  })

  const filterProps = {
    selection,
    setSelection,
    leadsLocationSelect: true,
  }

  const [selectedScores, setSelectedScores] = useState<LeadScoreSelection[]>([])

  const [selectedStatuses, setSelectedStatuses] = useState<
    LeadStatusSelection[]
  >([])
  const [search, setSearch] = useState('')

  const handleDebounceSearch = useCallback(
    debounce((search) => onSearch?.(search), 500),
    []
  )

  const handleSelectedScores = useCallback(
    (selectedScores: LeadScoreSelection[]) => {
      setSelectedScores(selectedScores)
      onSelectedScores?.(selectedScores)
    },
    [setSelectedScores, onSelectedScores]
  )

  const handleSelectedStatuses = useCallback(
    (selectedStatuses: LeadStatusSelection[]) => {
      setSelectedStatuses(selectedStatuses)
      onSelectedStatuses?.(selectedStatuses)
    },
    [setSelectedStatuses, onSelectedStatuses]
  )

  const handleSearch = useCallback(
    (search: string) => {
      setSearch(search)
      handleDebounceSearch?.(search)
    },
    [setSearch, onSearch]
  )

  // Values for score multioption autocomplete
  const scores: LeadScoreSelection[] = [
    { value: LeadScores.Less_30, name: 'less than 30' },
    { value: LeadScores.Between_30_60, name: 'between 30 and 60' },
    { value: LeadScores.Greater_60, name: 'greater than 60' },
  ]

  const Filters: JSX.Element = (
    <Grid
      spacing={2}
      container
      style={
        !sm
          ? {
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              gridTemplateRows: '1fr',
              gap: '0px 0px',
              gridTemplateAreas: '. . .',
            }
          : { justifyContent: 'center' }
      }
    >
      {scores && (
        <Grid item>
          <MultiOptionAutoComplete
            inputLabel={'Select engagement score'}
            options={scores}
            selectedOptions={selectedScores}
            setSelectedOptions={handleSelectedScores}
            optionLabelAccessor={(score: LeadScoreSelection): string =>
              score.name || 'unknown'
            }
          />
        </Grid>
      )}
      <Grid item>
        <MultiOptionAutoComplete
          inputLabel={'Select Status'}
          options={statuses}
          selectedOptions={selectedStatuses}
          setSelectedOptions={handleSelectedStatuses}
          optionLabelAccessor={(status: LeadStatusSelection): string =>
            status.name || 'unknown'
          }
        />
      </Grid>
      {onSearch && (
        <Grid item>
          <TextField
            variant="standard"
            fullWidth
            label="Name / Email"
            value={search}
            onChange={(e): void => {
              handleSearch(e.target.value)
            }}
          />
        </Grid>
      )}
    </Grid>
  )

  const CommunityFilter = (expanded: boolean): ReactElement | null => (
    <div className={classes.filtersContainer}>
      <LocationsFilters
        expanded={expanded}
        allCommunities={allCommunitiesData}
        filteredCommunities={filteredCommunitiesData}
        selection={selection}
        setSelectedCommunities={setSelectedCommunities}
        selectedCommunities={selectedCommunities}
        onSelectedCommunities={onSetLeadChartSelectedCommunities}
        onLocationSelection={onLocationSelection}
        filterProps={filterProps}
        setDialogMessage={setDialogMessage}
        setOpenDialog={setOpenDialog}
      />
      <MultiOptionAutoComplete
        inputLabel={'Select Community'}
        fullWidth
        options={filteredCommunitiesData}
        selectedOptions={selectedCommunities || []}
        setSelectedOptions={handleCommunitiesSelection}
        optionLabelAccessor={(community: Community): string =>
          community.name || 'unknown'
        }
      />
    </div>
  )

  const mobileFilters = () => {
    return (
      <>
        {filteredCommunitiesData && CommunityFilter(false)}
        {Filters}
      </>
    )
  }

  return (
    <LeadsFilterBarStyled>
      {sm ? (
        <MobileFilterBar filters={mobileFilters} />
      ) : (
        <FilterBar
          customFilters={filteredCommunitiesData && CommunityFilter(true)}
          customFiltersRow={Filters}
        />
      )}
      <AlertDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        dialogMessage={dialogMessage}
      />
    </LeadsFilterBarStyled>
  )
}
