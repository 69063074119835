import React from 'react'

interface IconProps {
  width?: number
  height?: number
}

function SalesInsight(props: IconProps): JSX.Element {
  const { width, height } = props
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      height={height || 512}
      width={width || 512}
      viewBox="0 0 512 512"
    >
      <path
        fill="#E50027"
        d="M150,321.64v120c0,24.899-20.099,45-45,45s-45-20.101-45-45v-120c0-8.401,6.599-15,15-15h60
	C143.401,306.64,150,313.239,150,321.64z"
      />
      <g>
        <path
          fill="#C1001F"
          d="M165,396.64h-30c-8.291,0-15-6.709-15-15s6.709-15,15-15h30c8.276,0,15-6.724,15-15v-27.48
		c0-8.291,6.709-15,15-15s15,6.709,15,15v27.48C210,376.455,189.814,396.64,165,396.64z"
        />
        <path
          fill="#C1001F"
          d="M150,321.64v120c0,24.899-20.099,45-45,45v-180h30C143.401,306.64,150,313.239,150,321.64z"
        />
      </g>
      <path
        fill="#E50027"
        d="M90,156.64v150l-30,28.5c-34.2-6.901-60-37.202-60-73.5v-60c0-36.301,25.8-66.601,60-73.5L90,156.64z
	"
      />
      <path
        fill="#C1001F"
        d="M90,231.64v75l-30,28.5c-34.2-6.901-60-37.202-60-73.5v-30L90,231.64L90,231.64z"
      />
      <path
        fill="#E50027"
        d="M512,231.64c0,41.4-33.6,75-75,75c-8.401,0-15-6.601-15-15v-120c0-8.401,6.599-15,15-15
	C478.4,156.64,512,190.24,512,231.64z"
      />
      <path
        fill="#FD003A"
        d="M452,70.24v322.8c0,15.899-8.101,30-21.301,38.399c-7.2,4.501-15.599,6.601-23.699,6.601
	c-5.099,0-10.201-1.201-15-2.701l-30-44.399V72.34l30-44.101c12.9-4.799,27.001-3.6,38.699,3.6C443.899,40.24,452,54.339,452,70.24z
	"
      />
      <path
        fill="#C1001F"
        d="M512,231.64c0,41.4-33.6,75-75,75c-8.401,0-15-6.601-15-15v-60L512,231.64L512,231.64z"
      />
      <path
        fill="#E50027"
        d="M452,231.64v161.4c0,15.899-8.101,30-21.301,38.399c-7.2,4.501-15.599,6.601-23.699,6.601
	c-5.099,0-10.201-1.201-15-2.701l-30-44.399v-159.3H452z"
      />
      <polygon
        fill="#CAE8F9"
        points="392,28.239 392,435.34 210,336.64 210,126.64 "
      />
      <path
        fill="#FD003A"
        d="M210,126.64v210H90c-11.1,0-20.099,0.599-30-1.5v-207c9.901-2.1,18.9-1.5,30-1.5H210z"
      />
      <polygon
        fill="#B7E0F6"
        points="392,231.64 392,435.34 210,336.64 210,231.64 "
      />
      <path
        fill="#E50027"
        d="M210,231.64v105H90c-11.1,0-20.099,0.599-30-1.5v-103.5L210,231.64L210,231.64z"
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  )
}

export default SalesInsight
