import { ClientIdAndName } from '../graphql/gen-types'

const TOKEN_KEY = 'colonnade-token'
const SELECTED_CLIENT_ALT_NAME = 'selected-client_alt_name'
const SELECTED_CLIENT_NAME = 'selected-client_name'
const SELECTED_CLIENT_ID = 'selected-client_id'
const SELECTED_CLIENT_DIRECTORY_NAME = 'selected-client_directoryName'
const SELECTED_CLIENT_EMAIL = 'selected-client_email'
const SELECTED_CLIENT_PHONE = 'selected-client_phone'
const SELECTED_APP = 'selected-colonnade-app'

class HydrationStore {
  get token(): string | null {
    return localStorage.getItem(TOKEN_KEY)
  }
  set token(newToken: string | null) {
    if (newToken !== null) {
      localStorage.setItem(TOKEN_KEY, newToken)
    }
  }

  get selectedClient(): ClientIdAndName | null {
    const stringedId = localStorage.getItem(SELECTED_CLIENT_ID)
    const data = {
      altName: localStorage.getItem(SELECTED_CLIENT_ALT_NAME) || '',
      name: localStorage.getItem(SELECTED_CLIENT_NAME) || '',
      directoryName: localStorage.getItem(SELECTED_CLIENT_DIRECTORY_NAME) || '',
      email: localStorage.getItem(SELECTED_CLIENT_EMAIL) || '',
      phone: localStorage.getItem(SELECTED_CLIENT_PHONE) || '',
      id: parseInt(stringedId || '', 10),
    }
    if (data.altName === '') {
      return null
    }
    return data
  }

  set selectedClient(client: ClientIdAndName | null) {
    if (client !== null) {
      localStorage.setItem(SELECTED_CLIENT_NAME, client.name)
      localStorage.setItem(SELECTED_CLIENT_ID, client.id.toString())
      localStorage.setItem(SELECTED_CLIENT_ALT_NAME, client.altName)
      localStorage.setItem(SELECTED_CLIENT_DIRECTORY_NAME, client.directoryName)
      localStorage.setItem(SELECTED_CLIENT_EMAIL, client.email || '')
      localStorage.setItem(SELECTED_CLIENT_PHONE, client.phone || '')
    }
  }

  get selectedApp(): string | null {
    return localStorage.getItem(SELECTED_APP)
  }

  set selectedApp(targetApp: string | null) {
    if (targetApp) {
      localStorage.setItem(SELECTED_APP, targetApp)
    }
  }

  public clearToken(): void {
    localStorage.removeItem(TOKEN_KEY)
  }

  public clearSelectedClientName(): void {
    localStorage.removeItem(SELECTED_CLIENT_NAME)
    localStorage.removeItem(SELECTED_CLIENT_ALT_NAME)
    localStorage.removeItem(SELECTED_CLIENT_ID)
    localStorage.removeItem(SELECTED_CLIENT_DIRECTORY_NAME)
    localStorage.removeItem(SELECTED_CLIENT_EMAIL)
    localStorage.removeItem(SELECTED_CLIENT_PHONE)
  }

  public clearAll(): void {
    this.clearToken()
    this.clearSelectedClientName()
  }
}

const hydrationStore = new HydrationStore()

export default hydrationStore
