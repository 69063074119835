import React, { useContext, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputBase from '@mui/material/InputBase'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import { useTheme } from '@mui/material/styles'
import SettingsIcon from '@mui/icons-material/Settings'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import TextField from '@mui/material/TextField/TextField'
import {
  GET_CLIENT_SUBSCRIPTION_INFO,
  GET_IS_USER_ANEWGO_ADMIN,
  SET_COLONNADE_CLIENTS_LICENSES,
  SET_COLONNADE_CLIENTS_SUBS_LEVEL,
  SET_COLONNADE_ROLE_PERMISSION,
  SET_COL_SUB_ANNIV_NOTIF,
  UPDATE_COLONNADE_SUBSCRIPTION_DATE,
} from 'graphql/auth-queries/authDb'
import {
  ColonnadeClientSubscriptionInfo,
  ColonnadeRolePrivilegesEnum,
  ColonnadeRolesEnum,
} from 'graphql/gen-types'
import hydrationStore from 'store/HydrationStore'
import { ConfirmAlert, voidFunction } from 'components/utils/ConfirmAlert'
import { AuthContext } from 'auth/AuthContext'
import { useMutation, useQuery } from '@apollo/client'
import useMediaQuery from '@mui/material/useMediaQuery'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import DatePicker from '@mui/lab/DatePicker'
import { isValid } from 'date-fns'

const PREFIX = 'AnewgoAdminSettings'

const classes = {
  centeredTitle: `${PREFIX}-centeredTitle`,
  root: `${PREFIX}-root`,
  formControl: `${PREFIX}-formControl`,
  selectEmpty: `${PREFIX}-selectEmpty`,
  table: `${PREFIX}-table`,
  tableRow: `${PREFIX}-tableRow`,
  margin: `${PREFIX}-margin`,
  numberLicenseField: `${PREFIX}-numberLicenseField`,
  paper: `${PREFIX}-paper`,
  button: `${PREFIX}-button`,
  clientName: `${PREFIX}-clientName`,
  privilegeLabel: `${PREFIX}-privilegeLabel`,
  userIconHeader: `${PREFIX}-userIconHeader`,
  autoMargin: `${PREFIX}-autoMargin`,
  arrowUp: `${PREFIX}-arrowUp`,
  arrowDown: `${PREFIX}-arrowDown`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.centeredTitle}`]: {
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.contrastText
        : theme.palette.primary.main,
    padding: '10px',
    textAlign: 'center',
    marginBottom: '10px',
  },

  [`& .${classes.root}`]: {
    margin: 'auto',
  },

  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  [`& .${classes.selectEmpty}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.table}`]: {
    maxWidth: '1300px',
    margin: 'auto',
    padding: '20px',
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.light
        : theme.palette.primary.main,
  },

  [`& .${classes.tableRow}`]: {
    color: theme.palette.background.default,
  },

  [`& .${classes.margin}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.numberLicenseField}`]: {
    maxWidth: '100px',
  },

  [`& .${classes.paper}`]: {
    width: 400,
    height: 300,
    overflow: 'auto',
    border: theme.palette.mode === 'dark' ? '1px solid #ced4da' : '',
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(0.5, 0),
  },

  [`& .${classes.clientName}`]: {
    marginLeft: 'auto',
  },

  [`& .${classes.privilegeLabel}`]: {
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.light
        : theme.palette.primary.main,
    marginLeft: '80px',
  },

  [`& .${classes.userIconHeader}`]: {
    marginRight: '5px',
    verticalAlign: 'middle',
  },

  [`& .${classes.autoMargin}`]: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
    },
  },

  [`& .${classes.arrowUp}`]: {
    transform: 'rotate(-90deg)',
  },

  [`& .${classes.arrowDown}`]: {
    transform: 'rotate(90deg)',
  },
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  [`& .MuiInputBase-root`]: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },

  [`& .MuiInputBase-input`]: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))

const StyledSwitch = styled(Switch)(({ theme }) => ({
  [`& .MuiSwitch-root`]: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'inline-flex',
  },

  [`& .MuiSwitch-switchBase`]: {
    padding: 2,
    color: theme.palette.grey[500],
    transform: 'translate(10px, 11px)',
  },

  [`& .Mui-checked`]: {
    color: theme.palette.common.white,
    transform: 'translate(32px, 11px)',
    '& + $track': {
      opacity: 1,
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },

  [`& .MuiSwitch-thumb`]: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },

  [`& .MuiSwitch-track`]: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    height: 14,
    backgroundColor: theme.palette.common.white,
  },
}))

const AnewgoAdminSettings = (): JSX.Element => {
  const { userId } = useContext(AuthContext).user
  const clientId = hydrationStore.selectedClient?.id

  const theme = useTheme()
  const mediumSize = useMediaQuery(theme.breakpoints.up('md'))
  const largeSize = useMediaQuery(theme.breakpoints.up('lg'))

  // all mutations
  const [setColonnadeRolePermission] = useMutation(
    SET_COLONNADE_ROLE_PERMISSION
  )
  const [setColonnadeSubscriptionLevel] = useMutation(
    SET_COLONNADE_CLIENTS_SUBS_LEVEL
  )
  const [setColonnadeNumberOfLicenses] = useMutation(
    SET_COLONNADE_CLIENTS_LICENSES
  )
  const [setColonnadeAnniversaryNotif] = useMutation(SET_COL_SUB_ANNIV_NOTIF)
  const [setColonnadeSubscriptionDate] = useMutation(
    UPDATE_COLONNADE_SUBSCRIPTION_DATE
  )

  // all subscription info
  const [subscription, setSubscription] = useState<string>('NONE')
  const [numberOfLicenses, setNumberOfLicenses] = useState<number>(0)
  const [anniversaryStatus, setAnniversaryStatus] = useState<boolean>(false)

  const [isUserAnewgoAdmin, setIsUserAnewgoAdmin] = useState<boolean>(false)
  const [requestedNumOfLicenses, setRequestedNumOfLicenses] = useState<number>(
    numberOfLicenses || 0
  )
  const [subscriptionDate, setSubscriptionDate] = useState<Date | null>(null)

  const { data: subInfo, loading } = useQuery(GET_CLIENT_SUBSCRIPTION_INFO, {
    variables: {
      clientId,
    },
  })

  useEffect(() => {
    if (!subInfo) {
      return
    }

    const {
      getColonnadeClientSubscriptionInfo,
    }: {
      getColonnadeClientSubscriptionInfo: ColonnadeClientSubscriptionInfo
    } = subInfo

    setSubscription(
      getColonnadeClientSubscriptionInfo.subscriptionLevel || 'NONE'
    )
    setNumberOfLicenses(
      getColonnadeClientSubscriptionInfo.numberOfLicenses || 0
    )
    setRequestedNumOfLicenses(
      getColonnadeClientSubscriptionInfo.numberOfLicenses || 0
    )
    setAnniversaryStatus(
      getColonnadeClientSubscriptionInfo.anniversaryNotificationState || false
    )
    setSubscriptionDate(
      getColonnadeClientSubscriptionInfo.subscriptionDate || null
    )

    if (getColonnadeClientSubscriptionInfo.rolePrivilegeObject != null) {
      const privilegeCollection = {}
      Object.keys(
        getColonnadeClientSubscriptionInfo.rolePrivilegeObject
      ).forEach((role) => {
        privilegeCollection[role] =
          getColonnadeClientSubscriptionInfo.rolePrivilegeObject[role]
      })
      Object.keys(privilegeCollection).length &&
        setSelectedPrivileges(
          privilegeCollection as Record<
            ColonnadeRolesEnumValues,
            ColonnadeRolePrivilegesEnumValues[]
          >
        )
    }
  }, [subInfo])

  const { data: isAdminData } = useQuery(GET_IS_USER_ANEWGO_ADMIN, {
    variables: {
      userId,
    },
  })

  useEffect(() => {
    if (isAdminData?.isUserAnewgoAdmin) {
      setIsUserAnewgoAdmin(isAdminData?.isUserAnewgoAdmin)
    } else {
      setIsUserAnewgoAdmin(false)
    }
  }, [isAdminData])

  // all confirmation dialog
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [callbackWhenPromptAgree, setCallbackWhenPromptAgree] = useState(
    voidFunction
  )
  const [callbackWhenPromptCancel, setCallbackWhenPromptCancel] = useState(
    voidFunction
  )
  const [isErrorWhenApiCall, setIsErrorWhenApiCall] = useState<boolean>(false)

  // all privileges related
  type ColonnadeRolesEnumValues = typeof ColonnadeRolesEnum[keyof typeof ColonnadeRolesEnum]
  type ColonnadeRolesEnumKeys = keyof typeof ColonnadeRolesEnum
  type ColonnadeRolePrivilegesEnumValues = typeof ColonnadeRolePrivilegesEnum[keyof typeof ColonnadeRolePrivilegesEnum]
  type privilegeObjectType = Record<
    ColonnadeRolesEnumValues,
    ColonnadeRolePrivilegesEnumValues[]
  >

  const [
    selectedPrivileges,
    setSelectedPrivileges,
  ] = useState<privilegeObjectType>()
  const [
    notSelectedPrivileges,
    setNotSelectedPrivileges,
  ] = useState<privilegeObjectType>()
  const [
    currentSelectedRole,
    setCurrentSelectedRole,
  ] = useState<ColonnadeRolesEnumKeys>('Admin')

  const [checked, setChecked] = useState<ColonnadeRolePrivilegesEnum[]>([])
  const availablePrivileges = Object.values(ColonnadeRolePrivilegesEnum)

  const populateNotSelectedPrivileges = (): void => {
    const privilegeCollection = {}

    // take in what is not inside the selected privileges
    selectedPrivileges &&
      Object.keys(selectedPrivileges).forEach((role) => {
        privilegeCollection[role] = availablePrivileges.filter(
          (privilege) => !selectedPrivileges[role].includes(privilege)
        )
      })
    privilegeCollection &&
      setNotSelectedPrivileges(
        privilegeCollection as Record<
          ColonnadeRolesEnumValues,
          ColonnadeRolePrivilegesEnumValues[]
        >
      )
  }

  useEffect(() => {
    populateNotSelectedPrivileges()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPrivileges])

  // helper function
  const dialogHelperFunction = (
    message: string,
    functionIfAccept: () => () => Promise<void>,
    functionIfCancel: () => () => Promise<void> = voidFunction
  ): void => {
    setIsErrorWhenApiCall(false)
    setMessage(message) // what message is in modal
    setCallbackWhenPromptAgree(functionIfAccept) // if they submit, this function should run
    setCallbackWhenPromptCancel(functionIfCancel) // if they cancel, this function should run
    setIsConfirmationOpen(true) // open the comfirmation modal
  }

  const dialogError = (message: string): void => {
    setIsErrorWhenApiCall(true)
    setMessage(message)
    setIsConfirmationOpen(true)
  }

  // handlers for subscription info change
  const handleSubscriptionLevelChange = async (
    subscriptionLevel: string
  ): Promise<void> => {
    const clientId = hydrationStore.selectedClient?.id
    subscriptionLevel === 'NONE' && setSubscriptionDate(null) // reset date
    const functionIfAccept = () => async (): Promise<void> => {
      setColonnadeSubscriptionLevel({
        variables: {
          clientId,
          subscriptionLevel,
        },
      })
        .then(({ data }) => {
          if (data.setColonnadeClientSubsLevel) {
            setSubscription(subscriptionLevel)
            setIsConfirmationOpen(false)
          }
        })
        .catch((err) => {
          dialogError(
            `Failed to update subscription level. Error Message : ${err}`
          )
        })
    }

    dialogHelperFunction(
      `Change subscription level to ${subscriptionLevel}?`,
      functionIfAccept
    )
  }

  const handleNumberOfLicenseChange = async (): Promise<void> => {
    const clientId = hydrationStore.selectedClient?.id

    if (requestedNumOfLicenses === numberOfLicenses) return
    if (requestedNumOfLicenses !== 0 && !requestedNumOfLicenses) {
      dialogError(`Cannot leave the field blank`)
      setRequestedNumOfLicenses(numberOfLicenses)
      return
    }
    const functionIfAccept = () => async (): Promise<void> => {
      setColonnadeNumberOfLicenses({
        variables: {
          clientId,
          numberOfLicenses: requestedNumOfLicenses,
        },
      })
        .then(({ data }) => {
          if (data.setColonnadeClientLicenses) {
            setNumberOfLicenses(requestedNumOfLicenses)
            setIsConfirmationOpen(false)
          }
        })
        .catch((err) => {
          dialogError(
            `Failed to update number of licenses. Error Message : ${err}`
          )
        })
    }

    const functionIfCancel = () => async (): Promise<void> => {
      setRequestedNumOfLicenses(numberOfLicenses)
    }

    dialogHelperFunction(
      `Change number of licenses to ${requestedNumOfLicenses}?`,
      functionIfAccept,
      functionIfCancel
    )
  }

  const handleAnniversaryStatusChange = async (
    status: boolean
  ): Promise<void> => {
    const clientId = hydrationStore.selectedClient?.id

    const statusInWord = status ? 'On' : 'Off'
    const functionIfAccept = () => async (): Promise<void> => {
      setColonnadeAnniversaryNotif({
        variables: {
          clientId,
          statement: status,
        },
      })
        .then(({ data }) => {
          if (data?.setColSubsAnnivNotif) {
            setAnniversaryStatus(status)
            setIsConfirmationOpen(false)
          }
        })
        .catch((err) => {
          dialogError(
            `Failed to update anniversary notifiation state. Error Message : ${err}`
          )
        })
    }

    dialogHelperFunction(
      `Change anniversary notifiaction to ${statusInWord}`,
      functionIfAccept
    )
  }

  // ALL PRIVILEGE SPECIFIC
  const handleRolePrivilegeChange = async (
    privileges: ColonnadeRolePrivilegesEnumValues[]
  ): Promise<void> => {
    const roleNameEnum = ColonnadeRolesEnum[currentSelectedRole]

    setColonnadeRolePermission({
      variables: {
        roleName: roleNameEnum,
        privilege: privileges,
      },
      refetchQueries: [
        { query: GET_CLIENT_SUBSCRIPTION_INFO, variables: { clientId } },
      ],
    })
      .then(({ data }) => {
        if (data.setRolePermission) {
          setIsConfirmationOpen(false)
          setChecked([])
        }
      })
      .catch((error) => {
        dialogError(`Failed to update privileges. Error message: ${error}`)
      })
  }

  const handleOnRoleChange = (role: ColonnadeRolesEnumKeys): void => {
    setCurrentSelectedRole(role)
  }

  const handleSelectAllPrivileges = (): void => {
    const functionIfAccept = () => async (): Promise<void> => {
      // send the api
      await handleRolePrivilegeChange(availablePrivileges)
    }

    dialogHelperFunction(
      `Change the privileges for role ${ColonnadeRolesEnum[currentSelectedRole]}?`,
      functionIfAccept
    )
  }

  const handleUnselectAllPrivileges = (): void => {
    const functionIfAccept = () => async (): Promise<void> => {
      // send the api
      await handleRolePrivilegeChange([])
    }

    dialogHelperFunction(
      `Change the privileges for role ${ColonnadeRolesEnum[currentSelectedRole]}?`,
      functionIfAccept
    )
  }

  const handlePrivilegeToggle = (value: ColonnadeRolePrivilegesEnum): void => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const handleCheckedNotSelectedPrivileges = (): void => {
    const functionIfAccept = () => async (): Promise<void> => {
      // get the privileges that are to be implemented.
      const selectedPrivilegesChecked: ColonnadeRolePrivilegesEnumValues[] =
        notSelectedPrivileges?.[
          ColonnadeRolesEnum[currentSelectedRole]
        ].filter((privilege: ColonnadeRolePrivilegesEnum) =>
          checked.includes(privilege)
        ) || []

      const allSelectedPrivileges =
        selectedPrivileges?.[ColonnadeRolesEnum[currentSelectedRole]].concat(
          selectedPrivilegesChecked
        ) || []

      // send the api
      await handleRolePrivilegeChange(allSelectedPrivileges)
    }

    dialogHelperFunction(
      `Change the privileges for role ${ColonnadeRolesEnum[currentSelectedRole]}?`,
      functionIfAccept
    )
  }

  const handleCheckedSelectedPrivileges = (): void => {
    const functionIfAccept = () => async (): Promise<void> => {
      // get the priviledges that are to remain selected.
      const selectedPrivilegesChecked: ColonnadeRolePrivilegesEnumValues[] =
        selectedPrivileges?.[ColonnadeRolesEnum[currentSelectedRole]].filter(
          (privilege: ColonnadeRolePrivilegesEnum) =>
            !checked.includes(privilege)
        ) || []

      // send the api
      await handleRolePrivilegeChange(selectedPrivilegesChecked)
    }

    dialogHelperFunction(
      `Change the privileges for role ${ColonnadeRolesEnum[currentSelectedRole]}?`,
      functionIfAccept
    )
  }

  const handleSubscriptionDateChange = (newDate: Date | null): void => {
    if (newDate && isValid(newDate)) {
      if (newDate < new Date()) {
        dialogError(`Please select date in the future`)
        return
      }
      setColonnadeSubscriptionDate({
        variables: {
          clientId,
          newSubscriptionDate: newDate,
        },
      })
        .then((res) => (res ? setSubscriptionDate(newDate) : null))
        .catch((err) => {
          dialogError(
            `Failed to update subscription level. Error Message : ${err}`
          )
        })
    }
  }

  const customList = (items: privilegeObjectType | undefined): JSX.Element => (
    <Paper className={classes.paper}>
      <List dense component="div" role="list" data-testid="privileges-list">
        {items?.[ColonnadeRolesEnum[currentSelectedRole]]?.map(
          (value: ColonnadeRolePrivilegesEnum) => {
            const labelId = `transfer-list-item-${value}-label`

            return (
              <ListItem
                key={value}
                role="listitem"
                button
                onClick={(): void => handlePrivilegeToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.replace('_', ' ')} />
              </ListItem>
            )
          }
        )}
        <ListItem />
      </List>
    </Paper>
  )
  if (!loading && isUserAnewgoAdmin !== null) {
    const ConfirmAlertProps = {
      setIsConfirmationOpen,
      isConfirmationOpen,
      callbackWhenPromptAgree,
      callbackWhenPromptCancel,
      message,
      isErrorWhenApiCall,
    }

    return (
      <Root>
        <TableContainer component={Paper} className={classes.table}>
          <Typography variant="h5" className={classes.centeredTitle}>
            <SupervisorAccountIcon
              fontSize="large"
              className={classes.userIconHeader}
            />
            Anewgo Admin Panel
          </Typography>
          {isConfirmationOpen ? <ConfirmAlert {...ConfirmAlertProps} /> : null}
          <Typography variant="h5" className={classes.clientName}>
            <SettingsIcon fontSize="large" className={classes.userIconHeader} />
            {hydrationStore.selectedClient?.name}
          </Typography>
          <Table
            aria-label="simple table"
            style={{
              borderTop: '1px solid rgba(224, 224, 224, 1)',
              marginTop: '20px',
            }}
          >
            <TableBody>
              <TableRow key="subscription-level" className={classes.tableRow}>
                <TableCell component="th" scope="row">
                  <Typography variant="h6">Subscription Level</Typography>
                </TableCell>

                <TableCell align={mediumSize ? 'right' : 'left'}>
                  <FormControl className={classes.margin} variant="standard">
                    <Select
                      variant="standard"
                      data-testid="customized-select"
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      value={subscription}
                      onChange={(e): Promise<void> =>
                        handleSubscriptionLevelChange(e.target.value as string)
                      }
                      input={<StyledInputBase />}
                    >
                      <MenuItem value={'NONE'}>NONE</MenuItem>
                      <MenuItem value={'STARTER'}>STARTER</MenuItem>
                      <MenuItem value={'PRO'}>PRO</MenuItem>
                      <MenuItem value={'ENTERPRISE'}>ENTERPRISE</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>

              <TableRow key="number-of-license" className={classes.tableRow}>
                <TableCell component="th" scope="row">
                  <Typography variant="h6">Number Of Licenses</Typography>
                </TableCell>

                <TableCell align={mediumSize ? 'right' : 'left'}>
                  <TextField
                    variant="standard"
                    data-testid="licences-input"
                    id="standard-number"
                    label="License"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    value={requestedNumOfLicenses}
                    onChange={(e) =>
                      setRequestedNumOfLicenses(parseInt(e.target.value))
                    }
                    onBlur={handleNumberOfLicenseChange}
                    className={classes.numberLicenseField}
                  />
                </TableCell>
              </TableRow>

              <TableRow
                key="anniversary-notification"
                className={classes.tableRow}
              >
                <TableCell component="th" scope="row">
                  <Typography variant="h6">Anniversary Notification</Typography>
                </TableCell>

                <TableCell align={mediumSize ? 'right' : 'left'}>
                  <Typography component="div">
                    <Grid
                      container
                      alignItems="center"
                      // spacing={1}
                    >
                      <Grid className={classes.autoMargin} item>
                        Off
                      </Grid>
                      <Grid item>
                        <StyledSwitch
                          data-testid="anniversary-switch"
                          checked={anniversaryStatus || false}
                          onChange={(): Promise<void> =>
                            handleAnniversaryStatusChange(!anniversaryStatus)
                          }
                          name="checked"
                        />
                      </Grid>
                      <Grid item>On</Grid>
                    </Grid>
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow key="subscription-date" className={classes.tableRow}>
                <TableCell component="th" scope="row">
                  <Typography variant="h6"> Subscription Date </Typography>
                </TableCell>

                <TableCell align={mediumSize ? 'right' : 'left'}>
                  <DatePicker
                    inputFormat="MM/dd/yyyy"
                    showToolbar
                    id="subscription-date-picker"
                    label="Subscription Date"
                    value={subscriptionDate}
                    onChange={handleSubscriptionDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    disabled={subscription === 'NONE'}
                    renderInput={(params) => (
                      <TextField variant="standard" {...params} />
                    )}
                  />
                </TableCell>
              </TableRow>
              {isUserAnewgoAdmin ? (
                <TableRow key="role-privileges" className={classes.tableRow}>
                  <TableCell component="th" scope="row">
                    <FormControl className={classes.margin} variant="standard">
                      <Select
                        variant="standard"
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        value={currentSelectedRole}
                        onChange={(e): void =>
                          handleOnRoleChange(
                            e.target.value as ColonnadeRolesEnumKeys
                          )
                        }
                        input={<StyledInputBase />}
                      >
                        <MenuItem value={'AnewgoAdmin'}>ANEWGO ADMIN</MenuItem>
                        <MenuItem value={'AnewgoStaff'}>ANEWGO STAFF</MenuItem>
                        <MenuItem value={'Admin'}>ADMIN</MenuItem>
                        <MenuItem value={'Agent'}>AGENT</MenuItem>
                        <MenuItem value={'AnalyticsMember'}>
                          ANALYTICS MEMBER
                        </MenuItem>
                        <MenuItem value={'SalesAdmin'}>SALES ADMIN</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <Grid
                    container
                    spacing={1}
                    justifyContent="center"
                    alignItems="center"
                    className={classes.root}
                  >
                    <Grid item>
                      <Typography
                        variant="h6"
                        className={classes.privilegeLabel}
                      >
                        Available Privileges
                      </Typography>
                      {customList(notSelectedPrivileges)}
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction={largeSize ? 'column' : 'row'}
                        alignItems="center"
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          className={classes.button}
                          onClick={handleUnselectAllPrivileges}
                          aria-label="move all left"
                        >
                          {largeSize ? (
                            '≪'
                          ) : (
                            <DoubleArrowIcon
                              className={classes.arrowUp}
                              fontSize="small"
                            />
                          )}
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          className={classes.button}
                          onClick={handleCheckedSelectedPrivileges}
                          aria-label="move selected left"
                        >
                          {largeSize ? (
                            <div>&lt;</div>
                          ) : (
                            <ExpandLessIcon fontSize="small" />
                          )}
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          className={classes.button}
                          onClick={handleCheckedNotSelectedPrivileges}
                          aria-label="move selected right"
                        >
                          {largeSize ? (
                            <div>&gt;</div>
                          ) : (
                            <ExpandMoreIcon fontSize="small" />
                          )}
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          className={classes.button}
                          onClick={handleSelectAllPrivileges}
                          aria-label="move all right"
                        >
                          {largeSize ? (
                            '≫'
                          ) : (
                            <DoubleArrowIcon
                              className={classes.arrowDown}
                              fontSize="small"
                            />
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="h6"
                        className={classes.privilegeLabel}
                      >
                        Selected Privileges
                      </Typography>
                      {customList(selectedPrivileges)}
                    </Grid>
                  </Grid>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Root>
    )
  } else {
    return <div>Loading...</div>
  }
}

export default AnewgoAdminSettings
