import { useParams } from 'react-router-dom'
import { useEventTracker as useEventTrackerOrig } from '@anewgo/tracking'
import { useContext } from 'react'
import { AuthContext } from '../auth/AuthContext'
import { TrackAction } from '@anewgo/tracking/lib/types'

export const useEventTracker = (): TrackAction => {
  const { clientName } = useParams()
  const { userEmail: email } = useContext(AuthContext).user

  return useEventTrackerOrig({ email }, undefined, clientName)
}
