import React from 'react'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import { UnauthorizedStyled, classes } from './Unauthorized.styles'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'

interface UnauthorizedInterface {
  message: string
  imageName?: string
}

/**
 * @description - this will display a static blurred image in the background and an erorr message when unauthorized from directive error
 *
 * @param message - error.toString() of the error in graphql message.
 * @param imageName - generally we use the className as our imageName, IE: (PopularLots --> PopularLots.PNG) to be located in our FS.
 */
export function Unauthorized({ message }: UnauthorizedInterface): JSX.Element {
  let finalMessage = ''

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  // const imageFileLocation = require(`images/${imageName}.PNG`)

  if (message.includes('Insufficient authorization')) {
    finalMessage += `Insufficient permission for this feature. Please contact an Anewgo admin to get access.`
  }

  if (message.includes('Insufficient Role level')) {
    finalMessage += `Insufficient Role level. Please contact your admin to get this access.`
  }

  // check if the error is data ownership / admin message.
  if (message.includes('Not enough permission to access the data')) {
    finalMessage += `Insufficient permission for this data. You need to be an admin or associated to the client to access this data.`
  }

  return (
    <UnauthorizedStyled className={classes.root}>
      <img
        src={'https://wallpapercave.com/wp/wp3194552.png'}
        // src={imageFileLocation}
        className={classes.backgroundImage}
        alt="dummy background"
      />
      <Alert variant="filled" severity="info" className={classes.messageBar}>
        <Typography>{finalMessage}</Typography>
      </Alert>
    </UnauthorizedStyled>
  )
}

interface UnauthorizedCardInterface extends UnauthorizedInterface {
  title: string
}

export function UnauthorizedCard({
  title,
  ...unauthorizedProps
}: UnauthorizedCardInterface): JSX.Element {
  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <Unauthorized {...unauthorizedProps} />
      </CardContent>
    </Card>
  )
}
