import { styled } from '@mui/material/styles'

const PREFIX = 'SalesCenterCommunity'

export const classes = {
  componentContainer: `${PREFIX}-componentContainer`,
  filters: `${PREFIX}-filters`,
  mobileSwitch: `${PREFIX}-mobileSwitch`,
}

export const Root = styled('div')(({ theme }) => ({
  [`& .${classes.componentContainer}`]: {
    marginTop: '1em',
    marginBottom: '1em',
    padding: '2em',
    [theme.breakpoints.down('md')]: {
      padding: '0em',
    },
    justifyContent: 'center',
  },

  [`& .${classes.filters}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },

  [`& .${classes.mobileSwitch}`]: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
}))
