import { Lot } from 'graphql/gen-types'
import React from 'react'

type SiteplanShapeSVGProps = {
  className?: string
  shape: { tagName: string; attributes: ShapeAttribute }
  key: string
  fill: string
  stroke: string
  strokeWidth: number
  lot: Lot
  onLotClick?: (lot: Lot) => void
}

type ShapeAttribute = {
  dataName?: string | null
  x?: string | null
  y?: string | null
  width?: string | null
  height?: string | null
  transform?: string | null
  points?: string | null
  d?: string | null
}

const SiteplanShapeSVG = ({
  className,
  shape,
  fill,
  stroke,
  strokeWidth,
  lot,
  onLotClick,
}: SiteplanShapeSVGProps): JSX.Element => {
  const {
    dataName,
    x,
    y,
    width,
    height,
    transform,
    points,
    d,
  } = shape.attributes
  const { tagName } = shape
  const shapeProps: Record<string, unknown> = {
    className,
    key: dataName,
    id: dataName,
    fill,
    stroke,
    strokeWidth,
  }

  switch (tagName) {
    case 'rect':
      shapeProps.x = x
      shapeProps.y = y
      shapeProps.width = width
      shapeProps.height = height
      shapeProps.transform = transform
      shapeProps.cursor = 'pointer'
      break
    case 'polygon':
      shapeProps.points = points
      shapeProps.cursor = 'pointer'
      break
    case 'path':
      shapeProps.d = d
      shapeProps.cursor = 'pointer'
      break
    default:
      break
  }

  return React.createElement(tagName, {
    ...shapeProps,
    onClick: () => {
      if (onLotClick) {
        onLotClick(lot)
      }
    },
  })
}

export default SiteplanShapeSVG
