import React from 'react'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'

import { InfoIcon } from '@anewgo/icons'

const PREFIX = 'InfoButton'

const classes = {
  iconButton: `${PREFIX}-iconButton`,
}

const StyledIconButton = styled(IconButton)(() => ({
  [`&.${classes.iconButton}`]: {
    borderRadius: '50%',
    border: '1px solid #CDCDCD',
  },
}))

export interface InfoButtonProps {
  title: string
  width: number
  height: number
  onClickHandler: (e: React.MouseEvent) => void
}

export const InfoButton: React.FC<InfoButtonProps> = ({
  onClickHandler,
  title,
  width,
  height,
}) => {
  return (
    <Tooltip title={title} placement="top">
      <StyledIconButton
        aria-label={title}
        onClick={onClickHandler}
        className={classes.iconButton}
        size="large"
      >
        <InfoIcon width={width} height={height} fillColor="currentColor" />
      </StyledIconButton>
    </Tooltip>
  )
}
