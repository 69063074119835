import React, { useReducer } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import ColonnadeApp from './components/ColonnadeApp'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import './stylesheets/app.css'
import { AppStore, initialState } from 'store'
import { reducer as AppReducer } from 'store/reducers'
import { client } from './graphql/client'
import { useAppTheme } from './theme'
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client'
import {
  StyledEngineProvider,
  ThemeProvider,
  Theme,
} from '@mui/material/styles'
import Auth from 'auth/Auth'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

function ErrorFallback({ error, componentStack }: FallbackProps): JSX.Element {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error?.message}</pre>
      <pre>{componentStack}</pre>
    </div>
  )
}

function errorHandler(error: Error, componentStack: string): void {
  // if we have an error handling service we can send the error
  console.log('Encountered error', error)
}

function App(): JSX.Element {
  const appHeight = window.innerHeight
  const [appState, dispatch] = useReducer(AppReducer, initialState)
  const theme = useAppTheme(appState.darkMode)
  const apolloClient: ApolloClient<NormalizedCacheObject> = client()
  initialState.apolloClient = apolloClient

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
      <Router>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <ApolloProvider client={apolloClient}>
                <AppStore.Provider
                  value={{
                    appState: { ...appState, ...{ appHeight } },
                    dispatch,
                  }}
                >
                  <Auth>
                    <ColonnadeApp />
                  </Auth>
                </AppStore.Provider>
              </ApolloProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Router>
    </ErrorBoundary>
  )
}

export default App
