import React from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'

const PREFIX = 'AutoSuggestTopkPlanDialog'

const classes = {
  centerItem: `${PREFIX}-centerItem`,
  buttonIncreaseWidth: `${PREFIX}-buttonIncreaseWidth`,
  topKSelections: `${PREFIX}-topKSelections`,
}

const AutoSuggestTopKPlanDialogStyled = styled(Dialog)(({ theme }) => ({
  [`& .${classes.centerItem}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },

  [`& .${classes.buttonIncreaseWidth}`]: {
    minWidth: '165px',
  },

  [`& .${classes.topKSelections}`]: {
    overflowY: 'hidden',
    height: '70px',
    [theme.breakpoints.down('md')]: {
      height: '200px',
    },
  },
}))

interface AutoSuggestTopkPlanDialogInterface {
  handleChangeTopK: (topK: number) => void
  topKDialogOpen: boolean
  setTopKDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const AutoSuggestTopkPlanDialog = ({
  handleChangeTopK,
  topKDialogOpen,
  setTopKDialogOpen,
}: AutoSuggestTopkPlanDialogInterface): JSX.Element => {
  const handleOnKClick = (numberOfK: number): void => {
    handleChangeTopK(numberOfK)
    setTopKDialogOpen(false)
  }

  return (
    <AutoSuggestTopKPlanDialogStyled
      open={topKDialogOpen}
      onClose={() => setTopKDialogOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        We use Artificial Intelligence(AI) to show the recommended plans.
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          How many plans do you want to recommend?
        </DialogContentText>
      </DialogContent>
      <DialogContent className={classes.topKSelections}>
        <Grid container spacing={3} justifyContent={'center'}>
          <Grid item md={4}>
            <div className={classes.centerItem}>
              <Button
                data-testid="top3-Button"
                className={classes.buttonIncreaseWidth}
                variant="outlined"
                onClick={() => handleOnKClick(3)}
                color="primary"
              >
                Top 3
              </Button>
            </div>
          </Grid>

          <Grid item md={4}>
            <div className={classes.centerItem}>
              <Button
                className={classes.buttonIncreaseWidth}
                variant="outlined"
                onClick={() => handleOnKClick(5)}
                color="primary"
              >
                Top 5
              </Button>
            </div>
          </Grid>

          <Grid item md={4}>
            <div className={classes.centerItem}>
              <Button
                className={classes.buttonIncreaseWidth}
                variant="outlined"
                onClick={() => handleOnKClick(10)}
                color="primary"
              >
                Top 10
              </Button>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </AutoSuggestTopKPlanDialogStyled>
  )
}
