import React, { useState, useEffect, useContext } from 'react'
import { useLocation, Navigate, useParams } from 'react-router-dom'
import {
  ColonnadeSubscriptionLevelsEnum,
  Prospect,
  Scheme,
  useProspectQuery,
} from '../../graphql/gen-types'

import ProspectProfile from 'components/leads/prospect-page/ProspectProfile'
import ProspectStatistics from 'components/leads/prospect-page/ProspectStatistics'
import ProspectPipeline from 'components/leads/prospect-page/ProspectPipeline/ProspectPipeline'
import Grid from '@mui/material/Grid'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import FloorplansDisplay from 'components/common/displays/FloorplansDisplay'
import SchemesDisplay from 'components/common/displays/SchemesDisplay'
import MaterialColorsDisplay from 'components/common/displays/MaterialColorsDisplay'
import CtaEventsTable from 'components/leads/prospect-page/CtaEventsTable'
import ProspectFavorites from 'components/leads/prospect-page/ProspectFavorites'
import { Unauthorized } from 'components/auth/Unauthorized'
import LeadTimeRanking from 'components/leads/lead-rankings/LeadTimeRanking'
import {
  userHasSubscriptionAccess,
  verifyUserAuthError,
} from 'utils/authorizationHelpers'
import BrochuresTable from 'components/leads/prospect-page/BrochuresTable'
import { STARTER_INVALID_FEATURE_MESSAGE } from '../../constants'
import { AppStore } from 'store'
import JourneyBar, {
  createStepsFromProspectData,
} from 'components/common/journey-bar/JourneyBar'
import SessionsOverTimeChart from 'components/leads/prospect-page/ProspectSessionsOverTimeChart'
import ProspectInquiry from 'components/leads/prospect-page/ProspectInquiry'
import { classes, Root } from './LeadDetails.styles'
import { StyledTab, StyledTabs } from './Leads.styles'
function useRouteQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search)
}

const initialProspect: Prospect = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  statistics: null,
  isFirstSignIn: false,
  isRegistered: false,
  colonnadeAgents: [],
}

function LeadDetail(): JSX.Element {
  const [value, setValue] = React.useState(0)
  const handleChange = (
    // eslint-disable-next-line
    event: React.ChangeEvent<{}>,
    newValue: number
  ): void => {
    setValue(newValue)
  }

  const query = useRouteQuery()

  const [prospect, setProspect] = useState(initialProspect)
  const [milestoneSelectedStep, setMilestoneSelectedStep] = useState(0)

  const handleSelectedStep = (step: number): void => {
    setMilestoneSelectedStep(step)
  }
  const { clientName } = useParams()

  const email = query.get('prospectId') || ''
  const { appState } = useContext(AppStore)
  const { selectedClient } = appState
  const PRO_OR_ENTERPRISE_SUBSCRIBED = userHasSubscriptionAccess([
    ColonnadeSubscriptionLevelsEnum.Pro,
    ColonnadeSubscriptionLevelsEnum.Enterprise,
  ])

  const { data, loading, error } = useProspectQuery({
    skip: !email || !clientName,
    variables: {
      clientName,
      email,
    },
  })

  useEffect(() => {
    if (data?.colonnadeApiProspectByEmail) {
      setProspect(data.colonnadeApiProspectByEmail as Prospect)
    }
  }, [data?.colonnadeApiProspectByEmail])

  if (!PRO_OR_ENTERPRISE_SUBSCRIBED) {
    const selectedClientName = selectedClient ? selectedClient.altName : ''
    return (
      <Navigate
        to={`/leads/client/${selectedClientName}/overview?subscriptionLevelErrorMessage=${STARTER_INVALID_FEATURE_MESSAGE}`}
      />
    )
  }

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized message={error.toString()} imageName={'UserManagement'} />
      )
    }

    if (!clientName) {
      const error = 'clientName not found in params'
      console.error(error)
      return <div>Error while loading Lead details, client name not found</div>
    }
  }

  return (
    <div>
      <AppBar position="static">
        <StyledTabs value={value} onChange={handleChange}>
          <StyledTab label="Profile" {...a11yProps(0)} />
          <StyledTab label="Sales Funnel" {...a11yProps(1)} />
          <StyledTab label="Notes" {...a11yProps(2)} />
        </StyledTabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <ProfileTabPage data={prospect} loading={loading} error={error} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ProfilePipelinePage
          clientName={clientName}
          email={email}
          data={prospect}
          loading={loading}
          error={error}
          onSelectStep={handleSelectedStep}
          selectedStep={milestoneSelectedStep}
        />
        {/* <ProspectActivityTab
          prospect={prospect}
          clientName={clientName}
        ></ProspectActivityTab> */}
      </TabPanel>
      <TabPanel value={value} index={2} />
    </div>
  )
}

interface ProfileTabPageProps {
  data: Prospect | undefined | null
  loading?: boolean
  error?: Error | undefined
}
function ProfileTabPage({
  data,
  loading,
  error,
}: ProfileTabPageProps): JSX.Element {
  const { clientName } = useParams()

  return (
    <Root className={classes.root}>
      <Grid container className={classes.componentContainer} spacing={4}>
        <Grid item xs={12}>
          <JourneyBar
            steps={createStepsFromProspectData(data)}
            responseSize="sm"
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6} lg={6}>
              <ProspectProfile data={data} />
              <ProspectInquiry clientName={clientName} email={data?.email} />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <ProspectStatistics data={data} loading={loading} error={error} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <LeadTimeRanking
                clientName={clientName}
                leadEmail={data?.email || ''}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <SessionsOverTimeChart
                data={data?.statistics?.signInsGrouped || []}
                loading={loading}
                error={error}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <FloorplansDisplay
                data={data?.statistics?.selectedFloorplans || []}
                loading={loading}
                error={error}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <SchemesDisplay
                data={
                  data?.statistics?.selectedColorSchemes?.map(
                    (scheme) => scheme.scheme
                  ) as Scheme[]
                }
                loading={loading}
                error={error}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <MaterialColorsDisplay
                data={data?.statistics?.selectedMaterialColors || []}
                loading={loading}
                error={error}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <BrochuresTable
                data={data?.brochures || []}
                loading={loading}
                error={error}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CtaEventsTable
                data={data?.statistics?.ctaClicks || []}
                loading={loading}
                error={error}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.componentContainer}>
        <Grid item xs={12} md={12} lg={12}>
          <ProspectFavorites data={data} loading={loading} error={error} />
        </Grid>
      </Grid>
    </Root>
  )
}

interface ProfilePipelinePageProps {
  clientName: string
  email: string
  data: Prospect | undefined | null
  loading?: boolean
  error?: Error | undefined
  onSelectStep?: (step: number) => void
  selectedStep?: number
}
function ProfilePipelinePage({
  clientName,
  email,
  data,
  loading,
  error,
  onSelectStep,
  selectedStep,
}: ProfilePipelinePageProps): JSX.Element {
  return (
    <Root className={classes.root}>
      <Grid container className={classes.componentContainer} spacing={4}>
        <Grid item xs={12}>
          <ProspectPipeline
            clientName={clientName}
            email={email}
            data={data}
            loading={loading}
            error={error}
            onSelectStep={onSelectStep}
            selectedStep={selectedStep}
          />
        </Grid>
      </Grid>
    </Root>
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps): JSX.Element {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number): Record<string, string> {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default LeadDetail
