import { styled } from '@mui/material/styles'

const PREFIX = 'PopularElevations'

export const classes = {
  cell: `${PREFIX}-cell`,
  colorBox: `${PREFIX}-colorBox`,
  customTooltip: `${PREFIX}-customTooltip`,
  elevationListItem: `${PREFIX}-elevationListItem`,
  elevTypography: `${PREFIX}-elevTypography`,
  help: `${PREFIX}-help`,
  helpText: `${PREFIX}-helpText`,
  imgBox: `${PREFIX}-imgBox`,
  label: `${PREFIX}-label`,
  list: `${PREFIX}-list`,
  metricSwitch: `${PREFIX}-metricSwitch`,
  percentInLegend: `${PREFIX}-percentInLegend`,
  percentInTooltip: `${PREFIX}-percentInTooltip`,
  tableContainer: `${PREFIX}-tableContainer`,
  table: `${PREFIX}-table`,
}

export const PopularElevationsStyled = styled('div')(({ theme }) => ({
  [`& .${classes.cell}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },

  [`& .${classes.colorBox}`]: {
    border: '1px solid rgb(204, 204, 204)',
    display: 'inline-block',
    height: '40px',
    width: '60px',
  },

  [`& .${classes.customTooltip}`]: {
    backgroundColor: theme.palette.background.default,
    border: '1px solid rgb(204, 204, 204)',
    margin: '0px',
    padding: '10px',
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.light
        : theme.palette.common.black,
  },

  [`& .${classes.elevationListItem}`]: {
    padding: '10px 0px',
  },

  [`& .${classes.elevTypography}`]: {
    display: 'inline-block',
    paddingRight: '5px',
  },

  [`& .${classes.help}`]: {
    display: 'flex',
    flexDirection: 'row',
  },

  [`& .${classes.helpText}`]: {
    lineHeight: 2,
    marginLeft: '0.25rem',
  },

  [`& .${classes.imgBox}`]: {
    border: '1px solid rgb(204, 204, 204)',
    display: 'inline-block',
    height: '40px',
    marginLeft: '5px',
    width: '60px',
  },

  [`& .${classes.label}`]: {
    margin: 0,
  },

  [`& .${classes.list}`]: {
    width: '320px',
  },

  [`& .${classes.metricSwitch}`]: {
    paddingRight: '15px',
  },

  [`& .${classes.percentInLegend}`]: {
    color: 'white',
    fontSize: '14px',
    fontWeight: 600,
    height: '100%',
    width: '100%',
    lineHeight: '36px',
    textAlign: 'center',
  },

  [`& .${classes.percentInTooltip}`]: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: 700,
  },

  [`& .${classes.tableContainer}`]: {
    maxHeight: 440,
  },

  [`& .${classes.table}`]: {
    maxWidth: 400,
  },
}))
