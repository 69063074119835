import React, { useState, useEffect, useContext } from 'react'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { useExcludedIpsQuery } from 'graphql/gen-types'
import {
  ADD_EXCLUDED_IP,
  REMOVE_EXCLUDED_IP,
} from 'graphql/mutation/excludedIpsMutations'
import { EXCLUDED_IPS } from 'graphql/queries/excludedIpsQueries'
import { useMutation } from '@apollo/client'
import { AppStore } from 'store'

const PREFIX = 'PermanentFilters'

const classes = {
  centeredTitle: `${PREFIX}-centeredTitle`,
  paper: `${PREFIX}-paper`,
  ipInput: `${PREFIX}-ipInput`,
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.paper}`]: {
    width: '95%',
    height: '100%',
    overflow: 'auto',
    border: theme.palette.mode === 'dark' ? '1px solid #ced4da' : '',
    padding: 20,
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.light
        : theme.palette.primary.main,
  },

  [`& .${classes.centeredTitle}`]: {
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.contrastText
        : theme.palette.primary.main,
    padding: '10px',
    textAlign: 'center',
  },

  [`& .${classes.ipInput}`]: {
    display: 'flex',
    gap: '20px',
    alignItems: 'flex-end',
    marginTop: '10px',
  },
}))

const ipv4BlockRegex = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){2}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.((([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5]))\/(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])))$/

const PermanentFilters = (): JSX.Element => {
  const { appState } = useContext(AppStore)
  const clientName = appState.selectedClient?.altName || ''

  const [excludedIps, setExcludedIps] = useState<string[]>([])
  const [ip, setIp] = useState<string>('')
  const [validIp, setValidIp] = useState<boolean>(true)
  const [refetching, setRefetching] = useState<boolean>(false)
  const [clientIp, setClientIp] = useState<string>('')

  const [removeExcludedIp] = useMutation(REMOVE_EXCLUDED_IP)
  const [addExcludedIp] = useMutation(ADD_EXCLUDED_IP)

  const { data, loading } = useExcludedIpsQuery({
    variables: {
      clientName,
    },
  })

  useEffect(() => {
    const request = new XMLHttpRequest()
    request.open('GET', 'https://api.ipify.org')
    request.setRequestHeader('Accept', 'application/json')
    request.onreadystatechange = function () {
      if (this.readyState === 4) {
        setClientIp(this.responseText)
      }
    }
    request.send()
  }, [])

  useEffect(() => {
    if (data?.excludedIps) {
      const ips: string[] = []
      data.excludedIps.forEach((ipAddress) => {
        if (
          ipAddress?.ipCidrNotation &&
          !ips.includes(ipAddress?.ipCidrNotation)
        ) {
          ips.push(ipAddress?.ipCidrNotation)
        }
      })
      setExcludedIps(ips as string[])
    }
  }, [data?.excludedIps])

  useEffect(() => {
    setRefetching(false)
  }, [excludedIps])

  const disableAdd = () => {
    let incorrectBlockFormat = false
    if (ipv4BlockRegex.test(ip)) {
      const range = ip.split('.')[3].split('/')
      if (parseInt(range[0]) > parseInt(range[1])) {
        incorrectBlockFormat = true
      }
    }
    return (
      loading ||
      refetching ||
      !validIp ||
      !ip ||
      excludedIps.includes(ip) ||
      incorrectBlockFormat
    )
  }

  const validateIp = (ip: string) => {
    const ipv6Regex = /^(?:[a-fA-F0-9]{1,4}:){7}[a-fA-F0-9]{1,4}$/
    const ipv4Regex = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){2}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5]))\/(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])))$/
    const valid = ipv4Regex.test(ip) || ipv6Regex.test(ip)
    setValidIp(valid)
  }

  const excludeIp = () => {
    const ips = []
    if (ipv4BlockRegex.test(ip)) {
      const ipParts = ip.split('.')
      const range = ipParts[3].split('/')
      const min = parseInt(range[0])
      const max = parseInt(range[1])
      for (let i = min; i <= max; i++) {
        ips.push(ipParts[0] + '.' + ipParts[1] + '.' + ipParts[2] + '.' + i)
      }
    } else {
      ips.push(ip)
    }
    addExcludedIp({
      variables: {
        clientName,
        ips,
        ipCidrNotation: ip,
      },
      refetchQueries: [{ query: EXCLUDED_IPS, variables: { clientName } }],
    })
    setIp('')
    setRefetching(true)
  }

  const removeIp = (ip: string) => {
    removeExcludedIp({
      variables: {
        clientName,
        ipCidrNotation: ip,
      },
      refetchQueries: [{ query: EXCLUDED_IPS, variables: { clientName } }],
    })
    setRefetching(true)
  }

  const ipChange = (ip: string) => {
    setIp(ip)
  }

  return (
    <StyledPaper className={classes.paper}>
      <Typography variant="h5" className={classes.centeredTitle}>
        Exclude IP Addresses
      </Typography>
      <Typography variant="body1">Your IP address is: {clientIp}</Typography>
      <div className={classes.ipInput}>
        <TextField
          variant="standard"
          data-testid="ip-input"
          id="ip-input"
          label="IP Address"
          value={ip}
          onChange={(e) => ipChange(e.target.value)}
          onBlur={(e) => validateIp(e.target.value)}
          error={!validIp}
          helperText={!validIp ? 'Input must be valid IP address.' : ''}
          style={{ minWidth: '25%' }}
        />
        <Button
          data-testid="exclude-address-button"
          variant="contained"
          color="primary"
          style={{ height: 45 }}
          onClick={() => excludeIp()}
          disabled={disableAdd()}
        >
          Exclude address
        </Button>
      </div>
      <Typography variant="h5" style={{ marginTop: 25 }}>
        Excluded IPs:
      </Typography>
      {!loading && !refetching ? (
        <List>
          {excludedIps.map((ip) => (
            <ListItem key={ip}>
              <Typography variant="h6">{ip}</Typography>
              <Button
                data-testid="remove-ip-button"
                style={{ marginLeft: 20 }}
                variant="contained"
                color="secondary"
                onClick={() => removeIp(ip)}
              >
                <DeleteForeverIcon />
              </Button>
            </ListItem>
          ))}
        </List>
      ) : (
        <div>Loading...</div>
      )}
    </StyledPaper>
  )
}

export default PermanentFilters
