import { useMemo } from 'react'
import { LocationSelectionTypes, OptionMap } from '../Shared'

export const useCountries = (
  selection: LocationSelectionTypes,
  countries: OptionMap<string>
): {
  us: boolean
  can: boolean
} => {
  return useMemo(() => {
    const selectedCountry =
      selection?.country ||
      (countries.size === 1 && countries.keys().next().value)
    if (!selectedCountry && countries.size > 1)
      return {
        us: true,
        can: true,
      }

    return {
      us: selectedCountry === 'US',
      can: selectedCountry === 'CA',
    }
  }, [selection.country, countries])
}
