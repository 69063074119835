import { styled } from '@mui/material/styles'

const PREFIX = 'ProspectPipleline'

export const classes = {
  root: `${PREFIX}-root`,
  stepper: `${PREFIX}-stepper`,
  button: `${PREFIX}-button`,
  actionsContainer: `${PREFIX}-actionsContainer`,
  resetContainer: `${PREFIX}-resetContainer`,
  container: `${PREFIX}-container`,
  gridRoot: `${PREFIX}-gridRoot`,
  title: `${PREFIX}-title`,
  list: `${PREFIX}-list`,
  checkboxList: `${PREFIX}-checkboxList`,
  avatarItem: `${PREFIX}-avatarItem`,
  avatar: `${PREFIX}-avatar`,
  check: `${PREFIX}-check`,
  pointer: `${PREFIX}-pointer`,
  line: `${PREFIX}-line`,
  editList: `${PREFIX}-editList`,
}

export const ProspectPiplelineStyled = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.stepper}`]: {
    width: 30,
    height: 30,
  },

  [`& .${classes.button}`]: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  [`& .${classes.actionsContainer}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.resetContainer}`]: {
    padding: theme.spacing(3),
  },

  [`& .${classes.container}`]: {
    padding: 16,
    [theme.breakpoints.down('lg')]: {
      padding: 8,
    },
  },

  [`& .${classes.gridRoot}`]: {
    flexGrow: 1,
    paddingLeft: '1em',
    height: '90%',
  },

  [`& .${classes.title}`]: {
    textAlign: 'center',
  },

  [`& .${classes.list}`]: {
    '& li': {
      paddingTop: 2,
      paddingBottom: 0,
    },
  },

  [`& .${classes.checkboxList}`]: {
    '& li': {
      paddingLeft: 0,
      paddingBottom: 0,
      paddingTop: 0,
    },
    '& div': {
      margin: 2,
    },
  },

  [`& .${classes.avatarItem}`]: {
    marginBottom: 0,
  },

  [`& .${classes.avatar}`]: {
    width: 60,
    height: 60,
    marginRight: 12,
    marginBottom: 12,
  },

  [`& .${classes.check}`]: {
    height: 20,
  },

  [`& .${classes.pointer}`]: {
    cursor: 'pointer',
  },

  [`& .${classes.line}`]: {
    minHeight: 0,
    border: 'none',
  },

  [`& .${classes.editList}`]: {
    backgroundColor: theme.palette.background.default,
    cursor: 'pointer',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '3px',
  },
}))
