import React, { useState, SyntheticEvent } from 'react'
import { Navigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import ClearIcon from '@mui/icons-material/KeyboardArrowDown'
import LinearProgress from '@mui/material/LinearProgress'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Slide from '@mui/material/Slide'
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar'
import SnackbarContent from '@mui/material/SnackbarContent'
import initialState, * as reducers from './Login.reducers'
import {
  AuthConsumer,
  AuthenticateFn,
  IsExistingEmailFn,
} from 'auth/AuthContext'
import ForgotEmail from '../components/account/ForgotEmail'
import ForgotPassword from '../components/account/ForgotPassword'
import { classes, Root } from './Login.styles'
type ErrorMessageProps = {
  open: boolean
  message: string
  handleCloseErrorMessage: (
    event: SyntheticEvent<unknown, Event>,
    reason: SnackbarCloseReason
  ) => void
  classes: Record<string, string>
}

function ErrorMessage(props: ErrorMessageProps): JSX.Element {
  const { open, message, handleCloseErrorMessage } = props
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={10000}
      open={open}
      onClose={handleCloseErrorMessage}
    >
      <SnackbarContent
        classes={{ root: classes.errorSnackbarContent }}
        message={message}
      />
    </Snackbar>
  )
}

function Login(): JSX.Element {
  const [state, setState] = useState(initialState)

  //
  //
  // Handlers
  //

  const handleEmailReset = (): void => {
    setState({ ...state, loading: true, validatedEmail: '', email: '' })
    // Delay setting state.loading to false to keep UI grayed out while sliding
    setTimeout(() => {
      setState({ ...state, loading: false, validatedEmail: '', email: '' })
      setState(reducers.setPassword(''))
    }, 500)
  }

  const handleForgotEmailClick = (): void => {
    setState({
      ...state,
      isForgotEmail: !state.isForgotEmail,
    })
  }

  const handleForgotPasswordClick = (): void => {
    setState({
      ...state,
      isForgotPassword: !state.isForgotPassword,
    })
  }

  const handleCloseErrorMessage = (): void => {
    setState(reducers.dismissErrorMessage())
  }

  const handleSubmit = async (
    evt: SyntheticEvent,
    authenticate: AuthenticateFn,
    isExistingEmail: IsExistingEmailFn
  ): Promise<void> => {
    evt.preventDefault()
    const { email, password, validatedEmail } = state

    setState({ ...state, loading: true })
    if (!validatedEmail) {
      if (!email) {
        setState({ ...state, emailErrorMsg: 'Enter an email', loading: false })
        return
      }
      isExistingEmail(email)
        .then((result: unknown) => {
          if (result) {
            setState({
              ...state,
              loading: true,
              validatedEmail: email,
              emailErrorMsg: '',
            })
            // Delay setting state.loading to false to keep UI grayed out while sliding
            setTimeout(() => {
              setState({
                ...state,
                loading: false,
                validatedEmail: email,
                emailErrorMsg: '',
              })
            }, 500)
          } else {
            setState({
              ...state,
              email,
              emailErrorMsg: `Couldn't find your anewgo account`,
              loading: false,
            })
          }
        })
        .catch(() => {
          setState(
            reducers.displayErrorMessage(
              'An error occurred. Please try again later.'
            )
          )
        })
      return
    }

    if (!password) {
      setState({
        ...state,
        passwordErrorMsg: 'Enter a password',
        loading: false,
      })
      return
    }
    let token
    try {
      token = await authenticate(email, password)
      if (!token) {
        setState({
          ...state,
          passwordErrorMsg:
            'Wrong password. Try again or click Forgot password to reset it.',
          loading: false,
        })
      }
    } catch (err) {
      setState(reducers.displayErrorMessage(err.message))
    }
  }

  function renderLayout(
    authenticate: AuthenticateFn,
    isExistingEmail: IsExistingEmailFn
  ): JSX.Element {
    return (
      <Root className={classes.root}>
        <div className={classes.login}>
          {state.loading && <LinearProgress />}
          <div className={loginBodyStyle}>
            <div className={classes.imageContainer}>
              <img
                className={classes.logoImg}
                src="https://res.cloudinary.com/renderinghouse/image/upload/anewgo/assets/custom/anewgo-logo.png"
                alt="Anewgo Logo"
              />
            </div>
            <form
              id="login-form"
              onSubmit={(e): Promise<void> =>
                handleSubmit(e, authenticate, isExistingEmail)
              }
            >
              <Slide
                direction="right"
                in={!state.validatedEmail && !state.isForgotEmail}
                timeout={
                  state.validatedEmail === null && state.isForgotEmail === null
                    ? 0
                    : 300
                }
              >
                <div>
                  <Typography
                    classes={{
                      root: classes.typography,
                    }}
                    variant="h5"
                  >
                    {!state.validatedEmail ? 'Sign in' : 'Welcome'}
                  </Typography>
                  <Typography
                    classes={{
                      root: classes.typography,
                    }}
                    variant="subtitle1"
                  >
                    to continue to your dashboard
                  </Typography>
                  <TextField
                    autoFocus
                    classes={{ root: classes.textField }}
                    margin="normal"
                    id="email"
                    autoComplete="username email"
                    variant="outlined"
                    label="Email"
                    value={state.email}
                    error={!!state.emailErrorMsg}
                    helperText={
                      state.emailErrorMsg ? state.emailErrorMsg : undefined
                    }
                    onChange={(evt): void => {
                      setState(reducers.setEmail(evt.target.value))
                    }}
                  />
                  <Link
                    href="#"
                    className={classes.forgot}
                    onClick={(): unknown => handleForgotEmailClick()}
                    underline="hover"
                  >
                    Forgot Email?
                  </Link>
                  <Button
                    classes={{ root: classes.button }}
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submit"
                  >
                    Next
                  </Button>
                </div>
              </Slide>
              <Slide
                direction="right"
                in={!!state.validatedEmail && !state.isForgotPassword}
                timeout={300}
              >
                <div>
                  {/* Outmost div receives Slide css, Inner div receives passwordDiv style */}
                  <div className={classes.passwordDiv}>
                    <Typography
                      classes={{
                        root: classes.typography,
                      }}
                      variant="h5"
                    >
                      Welcome
                    </Typography>
                    <Button
                      variant="outlined"
                      className={classes.validEmailButton}
                      endIcon={<ClearIcon />}
                      onClick={handleEmailReset}
                    >
                      {state.validatedEmail}
                    </Button>
                    {!!state.validatedEmail && (
                      <TextField
                        autoFocus={true}
                        classes={{ root: classes.textField }}
                        margin="normal"
                        id="password"
                        variant="outlined"
                        type="password"
                        label="Password"
                        value={state.password}
                        error={!!state.passwordErrorMsg}
                        helperText={
                          state.passwordErrorMsg
                            ? state.passwordErrorMsg
                            : undefined
                        }
                        onChange={(evt): void => {
                          setState(reducers.setPassword(evt.target.value))
                        }}
                      />
                    )}
                    <Link
                      href="#"
                      className={classes.forgot}
                      onClick={(): unknown => handleForgotPasswordClick()}
                      underline="hover"
                    >
                      Forgot Password?
                    </Link>
                    <Button
                      classes={{ root: classes.button }}
                      variant="contained"
                      size="large"
                      color="primary"
                      type="submit"
                    >
                      Sign In
                    </Button>
                  </div>
                </div>
              </Slide>
              <Slide
                direction="left"
                in={state.isForgotEmail || false}
                timeout={300}
              >
                <div>
                  <ForgotEmail
                    handleForgotEmailClick={handleForgotEmailClick}
                  />
                </div>
              </Slide>
              <Slide direction="left" in={state.isForgotPassword} timeout={300}>
                <div>
                  <ForgotPassword
                    handleForgotPasswordClick={handleForgotPasswordClick}
                  />
                </div>
              </Slide>
            </form>
          </div>
        </div>
        <ErrorMessage
          open={errorMessageOpen}
          message={errorMessage}
          handleCloseErrorMessage={handleCloseErrorMessage}
          classes={classes}
        />
      </Root>
    )
  }

  //
  //
  // Render
  //
  const { errorMessage, errorMessageOpen } = state
  const loginBodyStyle = !state.loading
    ? classes.loginBody
    : `${classes.loginBody} ${classes.loadingLoginBody}`
  return (
    <AuthConsumer>
      {({
        authenticated,
        authenticate,
        isExistingEmail,
        user,
        accessToken,
      }): JSX.Element => {
        if (!authenticated || !user.userId || !accessToken) {
          return renderLayout(authenticate, isExistingEmail)
        }
        return <Navigate to="/home" />
      }}
    </AuthConsumer>
  )
}

export default Login
