import React, { useEffect, useState } from 'react'
import { ProspectTopPlan, CamberPlanElevation } from 'graphql/gen-types'

import DateFnsAdapter from '@date-io/date-fns'
import { Maybe } from 'graphql/jsutils/Maybe'

import ResponsiveTable, {
  ResponsiveCell,
} from 'components/common/tables/ResponsiveTable'

const dateFns = new DateFnsAdapter()

function formatDateTime(
  date: string | number | Date | null | undefined
): string {
  return dateFns.format(new Date(date || new Date()), 'haaa MM-dd-yyyy')
}

type DefinedPlan = Maybe<ProspectTopPlan> | Maybe<CamberPlanElevation>

type Plans =
  | Maybe<ProspectTopPlan>[]
  | Maybe<CamberPlanElevation>[]
  | undefined
  | null

export default function PlansTable({
  dataSource,
  plans,
  showCommunities = false,
  showStatistics = false,
  showTimestamps = false,
}: {
  dataSource?: string
  plans: Plans
  showCommunities?: boolean
  showStatistics?: boolean
  showTimestamps?: boolean
}): JSX.Element {
  const [sortedPlans, setSortedPlans] = useState<Plans>([])
  // TODO: Look into this StackOverflow issue as a possible solution... should not pass a stringified object to useEffect...
  // https://stackoverflow.com/questions/55808749/use-object-in-useeffect-2nd-param-without-having-to-stringify-it-to-json
  useEffect(() => {
    if (!plans) {
      setSortedPlans([])
      return
    }
    const sortPlans = plans?.slice().sort((a: DefinedPlan, b: DefinedPlan) => {
      if (a?.timestamp > b?.timestamp) {
        return -1
      } else if (a?.timestamp < b?.timestamp) {
        return 1
      }
      return 0
    })

    setSortedPlans(sortPlans)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(plans)])

  const columns = [
    showCommunities ? (
      <ResponsiveCell responseSize="md" align="center" key={1}>
        <strong>Community</strong>
      </ResponsiveCell>
    ) : null,
    <ResponsiveCell responseSize="md" align="center" key={2}>
      <strong>Plan</strong>
    </ResponsiveCell>,
    <ResponsiveCell responseSize="md" align="center" key={3}>
      <strong>Elevation</strong>
    </ResponsiveCell>,

    showStatistics ? (
      <ResponsiveCell responseSize="md" align="center" key={4}>
        <strong>Duration</strong>
      </ResponsiveCell>
    ) : null,

    showStatistics ? (
      <ResponsiveCell responseSize="md" align="center" key={5}>
        <strong>Events</strong>
      </ResponsiveCell>
    ) : null,

    showTimestamps ? (
      <ResponsiveCell responseSize="md" align="right" key={6}>
        <strong>Timestamp</strong>
      </ResponsiveCell>
    ) : null,
  ]

  const generateRow = (
    dataRow: ProspectTopPlan | CamberPlanElevation | undefined | null,
    index?: number
  ): JSX.Element => {
    let topPlanRow
    if (dataSource !== 'Camber') {
      topPlanRow = dataRow as ProspectTopPlan
    }
    return (
      <>
        {showCommunities ? (
          <ResponsiveCell
            responseSize="md"
            align="center"
            key={`${dataRow?.planName}-${topPlanRow?.planId}-${index}-1`}
          >
            {topPlanRow?.communityName}
          </ResponsiveCell>
        ) : null}
        <ResponsiveCell
          responseSize="md"
          align="center"
          key={`${dataRow?.planName}-${topPlanRow?.planId || '0'}-${index}-2`}
        >
          {dataRow?.planName}
        </ResponsiveCell>
        <ResponsiveCell
          responseSize="md"
          align="center"
          key={`${dataRow?.planName}-${topPlanRow?.planId || '0'}-${index}-3`}
        >
          {dataRow?.elevationCaption}
        </ResponsiveCell>

        {showStatistics ? (
          <ResponsiveCell
            responseSize="md"
            align="center"
            key={`${dataRow?.planName}-${topPlanRow?.planId || '0'}-${index}-4`}
          >
            {topPlanRow?.duration || 0}
          </ResponsiveCell>
        ) : null}

        {showStatistics ? (
          <ResponsiveCell
            responseSize="md"
            align="center"
            key={`${dataRow?.planName}-${topPlanRow?.planId || '0'}-${index}-5`}
          >
            {topPlanRow?.events || 0}
          </ResponsiveCell>
        ) : null}

        {showTimestamps ? (
          <ResponsiveCell
            responseSize="md"
            align="right"
            key={`${dataRow?.planName}-${topPlanRow?.planId || '0'}-${index}-6`}
          >
            {formatDateTime(dataRow?.timestamp)}
          </ResponsiveCell>
        ) : null}
      </>
    )
  }

  if (dataSource === 'Camber') {
    return (
      <ResponsiveTable<CamberPlanElevation>
        data={sortedPlans ? (sortedPlans as CamberPlanElevation[]) : []}
        columns={columns}
        generateRow={generateRow}
        dense={true}
      />
    )
  } else {
    return (
      <ResponsiveTable<ProspectTopPlan>
        data={sortedPlans ? (sortedPlans as ProspectTopPlan[]) : []}
        columns={columns}
        generateRow={generateRow}
        dense={true}
      />
    )
  }
}
