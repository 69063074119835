import { useNavigate, useLocation } from 'react-router-dom'

export const useRemoveLocationQuery = () => {
  const location = useLocation()
  const navigate = useNavigate()

  return (queryName: string) => {
    const queryParams = new URLSearchParams(location.search)

    if (queryParams.has(queryName)) {
      queryParams.delete(queryName)
      navigate(
        {
          search: `?${queryParams.toString()}`,
        },
        {
          replace: true,
        }
      )
    }
  }
}
