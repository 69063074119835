import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Community, PopularityMetric } from 'graphql/gen-types'
import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import FilterBar from 'components/common/filter-bar/FilterBar'

import { MultiOptionAutoComplete } from 'components/common/custom-inputs/MultiOptionsAutocomplete'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch/Switch'
import MobileFilterBar from '../../common/filter-bar/MobileFilterBar'
import { AnalyticsStore } from 'store/analyticsStore'
import { AppStore } from 'store'
import PopularityMetricFilter from './components/PopularityMetricFilter'
import AlertDialog from './components/AlertDialog'
import LocationsFilters from './LocationsFilters'
import useLocationFilters from './hooks/useLocationFilters'
import useAreaChartChange from './hooks/useAreaChartChange'

interface LocationsFiltersBarProps {
  selectTopNCommunities?: number
  onSelectedCommunities?: (communities: Community[] | undefined) => void
  onSelectedPopularityMetric?: (popularityMetric: PopularityMetric) => void
  onAreaChartChange?: (areaChart: boolean) => void
}

function LocationsFiltersBar({
  selectTopNCommunities,
  onSelectedCommunities,
  onSelectedPopularityMetric,
  onAreaChartChange,
}: LocationsFiltersBarProps): ReactElement | null {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down(900))
  const { appState } = useContext(AppStore)
  const { analyticsState } = useContext(AnalyticsStore)
  const { startDate, endDate } = analyticsState
  const clientName = appState?.selectedClient?.altName || ''

  const {
    allCommunitiesData,
    filteredCommunitiesData,
    selection,
    setSelection,
    setSelectedCommunities,
    selectedCommunities,
    handleCommunitiesSelection,
    openDialog,
    setOpenDialog,
    dialogMessage,
    setDialogMessage,
  } = useLocationFilters({
    clientName,
    startDate,
    endDate,
    onSelectedCommunities,
  })

  const filterProps = {
    selection,
    setSelection,
    locationsFilterSelect: true,
  }

  const [popularityMetric, setPopularityMetric] = useState(
    PopularityMetric.Frequency
  )
  const [areaChart, setAreaChart] = useState(false)

  const handleAreaChartChange = useAreaChartChange({
    setAreaChart,
    onAreaChartChange,
  })

  const displayLocationFilters = (expanded: boolean): JSX.Element => {
    return (
      <LocationsFilters
        expanded={expanded}
        allCommunities={allCommunitiesData}
        filteredCommunities={filteredCommunitiesData}
        selection={selection}
        selectedCommunities={selectedCommunities}
        filterProps={filterProps}
        setDialogMessage={setDialogMessage}
        setOpenDialog={setOpenDialog}
        setSelectedCommunities={setSelectedCommunities}
        onSelectedCommunities={onSelectedCommunities}
        onLocationSelection={setSelection}
      >
        <PopularityMetricFilter
          popularityMetric={popularityMetric}
          setPopularityMetric={setPopularityMetric}
          onSelectedPopularityMetric={onSelectedPopularityMetric}
        />
      </LocationsFilters>
    )
  }

  // WARNING: Horrible hack ahead
  // The `Locations.tsx` page needs some communities to be selected.
  // This is because there is an upper limit to how many communities it can handle before crashing.
  // But if no communities are displayed, it's basically an empty page.
  // TODO: Move filters/selection to a context higher up the tree.

  // Update communities top 5 default selected
  useEffect(() => {
    // do not process communities change if the result is loading.
    if (!selectTopNCommunities) return
    if (filteredCommunitiesData.length === 0) return
    handleCommunitiesSelection(
      filteredCommunitiesData.slice(0, selectTopNCommunities)
    ) // set top5 communities by default
  }, [
    selectTopNCommunities,
    filteredCommunitiesData,
    handleCommunitiesSelection,
  ])

  return (
    <div>
      {sm ? (
        <MobileFilterBar filters={() => displayLocationFilters(false)} />
      ) : (
        <FilterBar
          customFilters={displayLocationFilters(true)}
          customFiltersRow={
            <Grid container justifyContent="space-evenly">
              <Grid item xs={12} md={8} lg={9}>
                <MultiOptionAutoComplete
                  inputLabel={'Select Community'}
                  options={filteredCommunitiesData}
                  selectedOptions={selectedCommunities || []}
                  setSelectedOptions={handleCommunitiesSelection}
                  optionLabelAccessor={(community): string =>
                    community.name || 'unknown'
                  }
                  limitTags={3}
                />
              </Grid>
              {/* Area Chart Toggle */}
              {!sm && (
                <Grid item xs={12} md={3} lg={2}>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignContent="center"
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          data-testid={'areaChartSwitch'}
                          checked={areaChart}
                          onChange={handleAreaChartChange}
                          name="chartSwitch"
                        />
                      }
                      label="Area Chart"
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          }
        />
      )}
      {/* Area Chart Toggle Mobile*/}
      {sm && (
        <Grid container justifyContent="flex-end">
          <FormControlLabel
            control={
              <Switch
                checked={areaChart}
                onChange={handleAreaChartChange}
                name="chartSwitch"
              />
            }
            label="Area Chart"
          />
        </Grid>
      )}
      <AlertDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        dialogMessage={dialogMessage}
      />
    </div>
  )
}

export default LocationsFiltersBar
