import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'

interface DisplaySelectionsProps {
  selectionNames: string[]
}

export default function DisplaySelections({
  selectionNames,
}: DisplaySelectionsProps): JSX.Element {
  return (
    <Paper>
      <Box p={1}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <strong>
              {selectionNames.length ? 'Selection:' : 'No  Selections'}
            </strong>
          </Grid>
          <Grid item xs={12}>
            <Box p={1}>
              {selectionNames.map((name, i) => (
                <p
                  key={`display-selection-${name}-${i}`}
                  style={{
                    margin: '0.25em 0.5em',
                    paddingLeft: '2em',
                    textIndent: '-2em',
                  }}
                >
                  {name}
                </p>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}
