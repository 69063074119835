import React, { useState, useEffect } from 'react'
import DateFnsAdapter from '@date-io/date-fns'
import {
  SessionFilter,
  SessionsSummary as SessionsSummaryType,
  useSessionsSummaryQuery,
} from 'graphql/gen-types'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import SessionsSummaryDisplay from './SessionsSummaryDisplay'

const dateFns = new DateFnsAdapter()

const initialState: SessionsSummaryType = {
  clientName: '',
  startAt: 0,
  endAt: 0,
  sessions: 0,
  visitors: 0,
  registered: 0,
  returnVisitors: 0,
  newVisitors: 0,
  averageDuration: 0,
  bounceRate: 0,
}
const previousInitialState: SessionsSummaryType = {
  clientName: '',
  startAt: 1,
  endAt: 1,
  sessions: 1,
  visitors: 1,
  registered: 1,
  returnVisitors: 1,
  newVisitors: 1,
  averageDuration: 1,
  bounceRate: 1,
}

const initialFilter: SessionFilter = {
  baseInfo: {
    clientName: '',
    dateTimeRange: {
      start: dateFns.format(
        new Date(Date.now() - 604800000), // Subtract 1 week (in milliseconds)
        'MM-dd-yyyy-HH:mm:ss'
      ),
      end: dateFns.format(new Date(Date.now()), 'MM-dd-yyyy-HH:mm:ss'),
    },
  },
  includeDurationZero: false,
}

interface SessionSummaryProps {
  filter: SessionFilter
  report?: boolean
}

// Functional Component
export default function SessionsSummary(
  props: SessionSummaryProps
): JSX.Element {
  const [sessionsSummary, setSessionsSummary] = useState(initialState)
  const [prevSessionsSummary, setPrevSessionsSummary] = useState(
    previousInitialState
  )
  const [previousFilter, setPreviousFilter] = useState(
    getPreviousFilter(props.filter)
  )
  const { data: sessionData, error: sessionError } = useSessionsSummaryQuery({
    variables: {
      filter: props.filter,
      key: JSON.stringify(props.filter),
    },
  })
  const {
    data: prevSessionData,
    error: prevSessionError,
  } = useSessionsSummaryQuery({
    variables: {
      filter: previousFilter,
      key: JSON.stringify(previousFilter),
    },
  })
  // TODO: Look into this StackOverflow issue as a possible solution... should not pass a stringified object to useEffect...
  // https://stackoverflow.com/questions/55808749/use-object-in-useeffect-2nd-param-without-having-to-stringify-it-to-json
  useEffect(() => {
    if (sessionData && sessionData.sessionsSummary) {
      setSessionsSummary(sessionData.sessionsSummary)
    }
    if (prevSessionData && prevSessionData.sessionsSummary) {
      setPrevSessionsSummary(prevSessionData.sessionsSummary)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(sessionData), JSON.stringify(prevSessionData)])

  useEffect(() => {
    if (props.filter) {
      setPreviousFilter(getPreviousFilter(props.filter))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.filter)])
  // Initialize data to initialState. No special loading state / spinner
  // now, but can be added.
  if (sessionError) {
    if (verifyUserAuthError(sessionError.toString())) {
      return (
        <Unauthorized
          message={sessionError.toString()}
          imageName={'SessionsSummaryDisplay'}
        />
      )
    }
    return <div>{sessionError.toString()}</div>
  }

  if (prevSessionError) {
    if (verifyUserAuthError(prevSessionError.toString())) {
      return (
        <Unauthorized
          message={prevSessionError.toString()}
          imageName={'SessionsSummaryDisplay'}
        />
      )
    }

    return <div>{prevSessionError.toString()}</div>
  }
  return (
    <SessionsSummaryDisplay
      data={sessionsSummary}
      previousData={prevSessionsSummary}
      report={props.report}
    />
  )
}

function getPreviousFilter(filter: SessionFilter): SessionFilter {
  // Find new time. Use start as the new end,
  // and new start as the same difference in time
  // For Safari's sake, the dashes in the date must be replaced with slashes and
  // a space between the date and time. Otherwise it will throw an error.
  const startDate = new Date(
    filter.baseInfo.dateTimeRange.start
      .replace(/-([^-]*)$/, ' $1')
      .replace(/-/g, '/')
  )
  const endDate = new Date(
    filter.baseInfo.dateTimeRange.end
      .replace(/-([^-]*)$/, ' $1')
      .replace(/-/g, '/')
  )
  const timeDiff = Math.abs(endDate.getTime() - startDate.getTime()) // Milliseconds
  const newStart = new Date(startDate.getTime() - timeDiff)
  // Make copy of filter, just with new times
  // This is verbose, but ensures our new object does not point to the old.
  const newFilter = initialFilter
  newFilter.baseInfo.clientName = filter.baseInfo.clientName
  newFilter.includeDurationZero = filter.includeDurationZero
  newFilter.baseInfo.dateTimeRange = {
    start: dateFns.format(newStart, 'MM-dd-yyyy-HH:mm:ss'),
    end: dateFns.format(startDate, 'MM-dd-yyyy-HH:mm:ss'),
  }
  return newFilter
}
