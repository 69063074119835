import { styled } from '@mui/material/styles'

const PREFIX = 'LeadDetail'

export const classes = {
  root: `${PREFIX}-root`,
  componentContainer: `${PREFIX}-componentContainer`,
}

export const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',
    width: '100%',
    height: `calc(100vh - 116px)`,
  },

  [`& .${classes.componentContainer}`]: {
    justifyContent: 'center',
    padding: '2em',
    [theme.breakpoints.down('md')]: {
      padding: '0em',
    },
  },
}))
