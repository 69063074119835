import React from 'react'
import { Client, Interior } from 'graphql/gen-types'
import { getImagePrefix } from '@anewgo/utils'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

import ResponsiveTable, {
  ResponsiveCell,
} from 'components/common/tables/ResponsiveTable'
import { formatCost, getInteriorUrl } from '../utils'
import LineSeparator from '../components/LineSeparator'
import {
  InterionImage,
  InteriorImageWrapper,
  InteriorLineBreakSection,
  InteriorNameText,
  InteriorOptionsWrapper,
  InteriorSection,
} from '../MyHome.styles'
import { HomeSelection } from '../MyHome'

type MyHomeInteriorProps = {
  client?: Client
  selection: HomeSelection
  interiors: Interior[]
}

export default function MyHomeInterior({
  client,
  selection,
  interiors,
}: MyHomeInteriorProps): JSX.Element {
  const clientName = client?.directoryName || ''
  const imagePrefix = getImagePrefix(clientName)
  const theme = useTheme()
  const mediumSize = useMediaQuery(theme.breakpoints.down('lg'))
  const columns = [
    <ResponsiveCell responseSize="md" align="center" key={0}>
      <strong>Material Name</strong>
    </ResponsiveCell>,
    <ResponsiveCell responseSize="md" align="center" key={1}>
      <strong>Selection Name</strong>
    </ResponsiveCell>,
    <ResponsiveCell responseSize="md" align="center" key={3}>
      <strong>Cost</strong>
    </ResponsiveCell>,
    <ResponsiveCell
      responseSize="md"
      align="center"
      style={mediumSize ? { height: '5rem' } : {}}
      key={4}
    >
      <strong>Swatch</strong>
    </ResponsiveCell>,
  ]

  const generateRow = (item: any, index?: number): JSX.Element => {
    let cost = '-'
    if (item?.sel?.cost && item?.sel?.parentElementOptionCost) {
      cost = formatCost(item.sel.cost + item.sel.parentElementOptionCost)
    } else if (item.sel.cost && !item.sel.parentElementOptionCost) {
      cost = formatCost(item.sel.cost)
    } else if (!item.sel.cost && item.sel.parentElementOptionCost) {
      cost = formatCost(item.sel.parentElementOptionCost)
    }

    let swatchSrc
    if (item?.sel?.overlaySwatchSrc) {
      swatchSrc = `${imagePrefix}/${item?.sel?.overlaySwatchSrc}`
    }
    return (
      <>
        <ResponsiveCell responseSize="md" align="center" key={`${index}-name`}>
          {item.mat.name}
        </ResponsiveCell>

        <ResponsiveCell
          responseSize="md"
          align="center"
          key={`${index}-selection`}
        >
          {item.sel.name}
        </ResponsiveCell>

        <ResponsiveCell responseSize="md" align="center" key={`${index}-cost`}>
          {cost}
        </ResponsiveCell>

        <ResponsiveCell
          responseSize="md"
          align="center"
          style={mediumSize ? { height: '5rem' } : {}}
          key={`${index}-swatch`}
        >
          <img src={swatchSrc} alt="swatch" style={{ height: 50 }} />
        </ResponsiveCell>
      </>
    )
  }

  const interiorSections = interiors.map((interior) => {
    const { interiorDesignSelections } = selection

    const interiorName = interior.name
    const { engineUrl, selectionsForInterior } = getInteriorUrl(
      clientName,
      interior,
      interiorDesignSelections
    )

    const selectionsForInteriorArray = Object.values(
      selectionsForInterior || {}
    )
    let totalInteriorOptsCost = 0
    selectionsForInteriorArray.forEach((item: any) => {
      if (item) {
        if (!item.sel && item.cost) {
          totalInteriorOptsCost += item.cost
        }
        if (item?.sel?.cost) {
          totalInteriorOptsCost += item.sel.cost
        }
        if (item?.sel?.parentElementOptionCost) {
          totalInteriorOptsCost += item.sel.parentElementOptionCost
        }
      }
    })
    return (
      <InteriorSection key={`interior-${interior.id}`}>
        {selectionsForInteriorArray && selectionsForInteriorArray.length ? (
          <>
            <InteriorNameText variant="h4">{interiorName}</InteriorNameText>
            <InteriorOptionsWrapper>
              <ResponsiveTable
                data={selectionsForInteriorArray}
                columns={columns}
                generateRow={generateRow}
                rowsPerPageOptions={[5]}
                initialRows={5}
              />
              {/* // TODO: Resolve selections for interior type fix .sel what is this type? */}
              {totalInteriorOptsCost > 0 && (
                <div
                  style={{
                    marginTop: 25,
                    display: 'flex',
                    justifyContent: mediumSize ? 'flex-start' : 'space-between',
                  }}
                >
                  <InteriorNameText variant="h5">Total:</InteriorNameText>
                  <InteriorNameText
                    variant="h5"
                    style={mediumSize ? { marginLeft: 5 } : { marginRight: 25 }}
                  >
                    {`${formatCost(totalInteriorOptsCost)}`}
                  </InteriorNameText>
                </div>
              )}
            </InteriorOptionsWrapper>
            <InteriorImageWrapper>
              <InterionImage alt="" src={`${engineUrl}`} />
            </InteriorImageWrapper>
          </>
        ) : null}
      </InteriorSection>
    )
  })
  return (
    <React.Fragment>
      <InteriorLineBreakSection>
        <LineSeparator title={'Interior Design'} />
      </InteriorLineBreakSection>
      {interiorSections}
    </React.Fragment>
  )
}
