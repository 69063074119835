import React, { useRef, useEffect, useState } from 'react'
import mapboxgl, { Map } from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { Lot, Siteplan } from '../../../graphql/gen-types'
import { isDefined } from 'utils/functions'

import {
  drawLotNames,
  drawLotShapes,
  drawSitePlan,
  getLotsDrawData,
  getMapBounds,
} from './utils/functions'

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY || ''
type Point = [number, number]
interface InteractiveSiteplanGeoJsonProps {
  siteplan: Siteplan
  lots: Lot[]
  interactive?: boolean
  minPopularity?: number
  maxPopularity?: number
  width: number
  height: number
  heatMap?: boolean
  onLotClick?: (lot: Lot) => void
}

export default function InteractiveSiteplanGeoJson({
  siteplan,
  lots,
  interactive = false,
  minPopularity,
  maxPopularity,
  width,
  height,
  heatMap = false,
  onLotClick,
}: InteractiveSiteplanGeoJsonProps): JSX.Element {
  const CLICK_OFFSET = 5 // 'padding' to expand the search area for click events
  const mapContainerRef = useRef(null)
  const map = useRef<Map>()
  const [mapLoaded, setMapLoaded] = useState(false)

  const geoJson = siteplan?.geoInfo

  useEffect(() => {
    if (map.current) return // initialize map only once
    if (!mapContainerRef.current || !geoJson) return // return if there is no container or geoInfo

    map.current = new mapboxgl.Map({
      container: mapContainerRef.current || '',
      doubleClickZoom: false,
      center: [-78.8354547, 35.6499533],
      style: 'mapbox://styles/lion-keng/cklv43yd829e618obmuofh4qx',
      bounds: getMapBounds(geoJson),
      maxZoom: 20,
      minZoom: 3,
      interactive: interactive,
      attributionControl: false,
    })
    map.current?.on?.('load', () => setMapLoaded(true))
    map.current?.on?.(
      'click',
      (
        e: mapboxgl.MapMouseEvent &
          mapboxgl.EventData & { originalEvent: React.MouseEvent }
      ) => handleMapClick(e)
    )

    // Function is in useEffect since it is called within the useEffect.
    const handleMapClick = (e: mapboxgl.MapMouseEvent) => {
      const bbox: [Point, Point] = [
        [e.point.x - CLICK_OFFSET, e.point.y - CLICK_OFFSET],
        [e.point.x + CLICK_OFFSET, e.point.y + CLICK_OFFSET],
      ]

      const features = map.current?.queryRenderedFeatures(bbox, {
        layers: ['lot-shapes-fill', 'lot-points'],
      })
      if (features?.[0].properties) {
        const lotId = features[0].properties.id
        const lot = siteplan.lots?.find((lot) => lot?.id === lotId)
        lot && onLotClick?.(lot)
      }
    }
  }, [
    geoJson,
    interactive,
    mapContainerRef,
    mapLoaded,
    onLotClick,
    siteplan.lots,
  ])

  // Draw siteplan
  useEffect(() => {
    if (map.current && mapLoaded && geoJson) {
      drawSitePlan(map.current, geoJson)
    }
  }, [geoJson, mapLoaded])

  // Draw Lots
  useEffect(() => {
    const legend = siteplan.lotLegend?.filter(isDefined) || []
    if (map.current && mapLoaded) {
      const lotsDrawData = getLotsDrawData(
        lots,
        legend,
        heatMap,
        minPopularity || 0,
        maxPopularity || 1
      )
      drawLotShapes(map.current, lotsDrawData)
      drawLotNames(map.current, lotsDrawData)
    }
  }, [siteplan, mapLoaded, minPopularity, maxPopularity, heatMap, lots])

  return (
    <div
      style={{ height, minWidth: width, marginBottom: '15px' }}
      ref={mapContainerRef}
      data-testid="mapContainerRef"
    />
  )
}
