import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  LotSignLock,
  ProspectFavorite,
  ProspectReservation,
  ReservationBuilderStatus,
  useLockLotSignMutation,
} from 'graphql/gen-types'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import Tooltip from '@mui/material/Tooltip'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'
import CheckIcon from '@mui/icons-material/Check'
import DeleteIcon from '@mui/icons-material/Delete'
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useLazyQuery } from '@apollo/client'
import { GET_BUYER_DOCUMENT_LINK } from '../../../graphql/nexus-queries/reservationIntegration'
import { AppStore } from 'store'
import LoadingButton from 'components/common/custom-inputs/LoadingButton'
import { isReservationDone } from './ReservationsFunctions'
import ReservationsDeletionPopover from './ReservationsDeletionPopover'
import ReservationsRestoreDeletionPopover from './ReservationsRestoreDeletionPopover'
import { ReservationTrackEvents } from '../reservationTrackEvents'
import { useEventTracker } from '../../../hooks/tracking'
import { AuthContext } from '../../../auth/AuthContext'
import { useIslotLocked } from './hooks'
import { classes, StyledTableBody } from './ReservationsListBodyStyles'

interface ReservationsListBodyProps {
  setReservationId: Dispatch<SetStateAction<string>>
  reservationId: string
  setIsDialogOpened: Dispatch<SetStateAction<boolean>>
  rejectLoading: boolean
  onlineReservations: ProspectReservation[] | undefined
  isSignDeclined: boolean
  isSignCompleted: boolean
  reservationIdParam: string | null
  partiallySignedParam: string | null
  isUserAnewgoAdmin: boolean | null
}

const ReservationsListBody: React.FC<ReservationsListBodyProps> = ({
  setReservationId,
  reservationId,
  setIsDialogOpened,
  rejectLoading,
  onlineReservations,
  isSignDeclined,
  isSignCompleted,
  reservationIdParam,
  partiallySignedParam,
  isUserAnewgoAdmin,
}): JSX.Element => {
  const { appState } = useContext(AppStore)
  const { user } = useContext(AuthContext)
  const track = useEventTracker()
  const [popoverAnchor, setPopoverAnchor] = useState<
    (EventTarget & SVGSVGElement) | null
  >(null)
  const [
    restoreDeletionPopoverAnchor,
    setRestoreDeletionPopoverAnchor,
  ] = useState<(EventTarget & SVGSVGElement) | null>(null)

  const [reservationIdToDelete, setReservationIdToDelete] = useState<
    string | null
  >(null)
  const [reservationIdToRestore, setReservationIdToRestore] = useState<
    string | null
  >(null)
  const reservationLinkLoadingId = useRef<string | null>(null)

  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [confirmDialogContent, setConfirmDialogContent] = useState('')
  const [currentReservationId, setCurrentReservationId] = useState<
    string | null
  >(null)

  const [
    getDocumentLink,
    { loading: isDocumentLinkLoading, data: documentLinkResponse },
  ] = useLazyQuery(GET_BUYER_DOCUMENT_LINK, {
    fetchPolicy: 'network-only',
  })
  const documentLink = documentLinkResponse?.getDocumentLinkForBuilder

  const isLotLocked = useIslotLocked()
  const [lockLot] = useLockLotSignMutation()

  const getLotLock = async (
    selectedFavorite: ProspectFavorite | undefined
  ): Promise<LotSignLock> => {
    const lotSignLock: LotSignLock = {
      isLocked: false,
      lockedBy: '',
    }

    if (!selectedFavorite?.lot?.id || !selectedFavorite?.community?.id)
      return lotSignLock

    await isLotLocked({
      clientName: appState?.selectedClient?.altName || '',
      communityId: selectedFavorite.community.id,
      lotId: selectedFavorite.lot.id,
    }).then(({ data }) => {
      const lotLock = data?.getIsLotSignLocked as LotSignLock

      lotSignLock.isLocked = lotLock?.isLocked || false
      lotSignLock.lockedBy = lotLock?.lockedBy || ''
    })

    return lotSignLock
  }

  const redirectToDocument = () => {
    if (documentLink) {
      window.location.href = documentLink
    }
  }

  useEffect(redirectToDocument, [documentLink])

  const handleOpenDialog = (id: string | '') => {
    track(ReservationTrackEvents.CLICKED_REJECT_RESERVATION, {
      reservationId: id,
    })
    setReservationId(id)
    setIsDialogOpened(true)
  }

  const isLotReserved = (
    selectedReservation: ProspectReservation | undefined
  ): boolean => {
    if (!selectedReservation || !onlineReservations) return false

    for (let i = 0; i < onlineReservations?.length; i++) {
      const row = onlineReservations[i]
      if (row.id !== selectedReservation.id) {
        const selectedFavorite = selectedReservation.favorite
        if (
          row?.favorite?.community?.id === selectedFavorite.community?.id &&
          row.favorite.lot?.id === selectedFavorite.lot?.id &&
          row.reservationStatus === ReservationBuilderStatus.ApprovedByBuilder
        ) {
          return true
        }
      }
    }

    return false
  }

  const proceedWithSign = (id: string | null) => {
    const selectedFavorite = onlineReservations?.find((row) => row.id === id)
    if (
      selectedFavorite?.favorite?.lot?.id &&
      selectedFavorite?.favorite?.community?.id
    ) {
      lockLot({
        variables: {
          clientName: appState?.selectedClient?.altName || '',
          communityId: selectedFavorite.favorite.community.id,
          lotId: selectedFavorite.favorite.lot.id,
          prospectEmail: selectedFavorite.prospect?.email || '',
        },
      })
    }

    reservationLinkLoadingId.current = id
    getDocumentLink({
      variables: {
        clientName: appState?.selectedClient?.altName,
        reservationId: id,
      },
    })
  }

  const signReservation = async (id: string | null) => {
    track(ReservationTrackEvents.CLICKED_SIGN_RESERVATION, {
      reservationId: id,
    })
    const selectedReservation = onlineReservations?.find((row) => row.id === id)

    if (isLotReserved(selectedReservation)) {
      setShowConfirmDialog(true)
      setConfirmDialogContent(
        'Are you sure you want to sign this reservation? Reservation for this lot was already signed.'
      )
      setCurrentReservationId(id)
      return
    }

    const lotLock = await getLotLock(selectedReservation?.favorite)
    if (
      lotLock.isLocked &&
      lotLock.lockedBy !== selectedReservation?.prospect?.email
    ) {
      setShowConfirmDialog(true)
      setConfirmDialogContent(
        `Are you sure you want to sign this reservation? Reservation approval for this lot is already in process with Buyer email: ${lotLock.lockedBy}`
      )
      setCurrentReservationId(id)
      return
    }

    proceedWithSign(id)
  }

  const renderReservationStatus = (
    reservation: ProspectReservation,
    isSignDeclined: boolean,
    isSignCompleted: boolean,
    reservationIdParam: string | null
  ): JSX.Element => {
    const { reservationStatus, id, rejectReason } = reservation

    if (
      // If buyer is redirected back from docusign
      ((isSignDeclined || isSignCompleted) &&
        reservationIdParam === id &&
        partiallySignedParam === null) ||
      // if buyer reject reservation from Insight UI
      (id === reservationId && rejectLoading)
    ) {
      return <CircularProgress />
    }

    if (
      reservationStatus === ReservationBuilderStatus.InProgress &&
      isReservationDone(reservation) &&
      !reservation.isDeleted
    ) {
      return (
        <>
          <div>Requires approval</div>
          <ButtonGroup className={classes.rowActions}>
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={() => handleOpenDialog(id ? id : '')}
            >
              REJECT
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              size="small"
              onClick={() => signReservation(id)}
              loading={
                isDocumentLinkLoading && reservationLinkLoadingId.current === id
              }
            >
              Sign
            </LoadingButton>
          </ButtonGroup>
        </>
      )
    }

    if (reservationStatus === ReservationBuilderStatus.InProgress) {
      return <span className={classes.inProgressText}>IN PROGRESS</span>
    }

    if (
      reservationStatus === ReservationBuilderStatus.RejectedByBuilder ||
      reservationStatus === ReservationBuilderStatus.RejectedByProspect
    ) {
      const rejectedByWho =
        reservationStatus === ReservationBuilderStatus.RejectedByBuilder
          ? 'BUILDER'
          : 'PROSPECT'

      return (
        <Tooltip
          title={
            rejectReason ? (
              <Typography variant="body2">Reason: {rejectReason}</Typography>
            ) : (
              ''
            )
          }
          placement="top"
        >
          <span className={classes.rejectedText}>
            REJECTED BY {rejectedByWho}
          </span>
        </Tooltip>
      )
    }

    if (reservationStatus === ReservationBuilderStatus.ReservationExpired) {
      return <span className={classes.rejectedText}>RESERVATION EXPIRED</span>
    }

    return <span className={classes.approvedText}>APPROVED</span>
  }

  const getDeleteButtonTooltip = (reservation: ProspectReservation) => {
    if (canDeleteReservation(reservation)) {
      return 'Delete reservation.'
    }

    if (
      reservation.reservationStatus === ReservationBuilderStatus.InProgress &&
      !isReservationDone(reservation)
    ) {
      return 'Deleting "In Progress" reservations is not allowed.'
    }

    return 'Only rejected reservations can be deleted.'
  }

  const canDeleteReservation = (reservation: ProspectReservation) => {
    return (
      reservation.reservationStatus ===
        ReservationBuilderStatus.RejectedByBuilder ||
      reservation.reservationStatus ===
        ReservationBuilderStatus.RejectedByProspect ||
      reservation.reservationStatus ===
        ReservationBuilderStatus.ReservationExpired
    )
  }

  const closeDeletePopover = () => {
    setPopoverAnchor(null)
    setReservationIdToDelete(null)
  }

  const openDeletePopOver = (
    element: EventTarget & SVGSVGElement,
    reservationId: string
  ) => {
    track(ReservationTrackEvents.CLICKED_DELETE_RESERVATION, { reservationId })
    setPopoverAnchor(element)
    setReservationIdToDelete(reservationId)
  }
  const formatPrice = (price?: number | null): string =>
    price
      ? price.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0,
        })
      : ''

  const openRestoreDeletedPopOver = (
    element: EventTarget & SVGSVGElement,
    reservationId: string
  ) => {
    track(ReservationTrackEvents.CLICKED_RESTORE_DELETED_RESERVATION, {
      reservationId,
    })
    setRestoreDeletionPopoverAnchor(element)
    setReservationIdToRestore(reservationId)
  }

  const closeRestoreDeletedPopOver = () => {
    setRestoreDeletionPopoverAnchor(null)
    setReservationIdToRestore(null)
  }

  const errorIcon: JSX.Element = <ClearIcon className={classes.errorIcon} />
  const successIcon: JSX.Element = <CheckIcon className={classes.successIcon} />
  const tableRow = appState.darkMode
    ? classes.tableRowDarkMode
    : classes.tableRow
  return (
    <>
      <ReservationsDeletionPopover
        popoverAnchor={popoverAnchor}
        reservationIdToDelete={reservationIdToDelete}
        closeDeletePopover={closeDeletePopover}
      />
      <ReservationsRestoreDeletionPopover
        popoverAnchor={restoreDeletionPopoverAnchor}
        reservationIdToRestore={reservationIdToRestore}
        closeRestoreDeletedPopOver={closeRestoreDeletedPopOver}
      />
      <StyledTableBody>
        {onlineReservations?.map((reservation) => (
          <TableRow
            key={reservation.id}
            className={`${tableRow} ${
              reservation.isDeleted ? classes.tableRowDeleted : ''
            }`}
          >
            {(isUserAnewgoAdmin || user.roleId === 3) && (
              <TableCell align="right">
                {!reservation.isDeleted ? (
                  <Tooltip
                    title={getDeleteButtonTooltip(reservation)}
                    placement="left"
                  >
                    <IconButton
                      aria-label="Delete Reservation"
                      size="large"
                      disabled={!canDeleteReservation(reservation)}
                    >
                      <DeleteIcon
                        color={
                          canDeleteReservation(reservation)
                            ? 'error'
                            : 'disabled'
                        }
                        onClick={(event: React.MouseEvent<SVGSVGElement>) =>
                          openDeletePopOver(event.currentTarget, reservation.id)
                        }
                      />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Restore deleted reservation" placement="left">
                    <IconButton
                      aria-label="Restore deleted Reservation"
                      size="large"
                    >
                      <RestoreFromTrashIcon
                        onClick={(event: React.MouseEvent<SVGSVGElement>) => {
                          openRestoreDeletedPopOver(
                            event.currentTarget,
                            reservation.id
                          )
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
            )}
            <TableCell align="center">
              {renderReservationStatus(
                reservation as ProspectReservation,
                isSignDeclined,
                isSignCompleted,
                reservationIdParam
              )}
            </TableCell>
            <TableCell>{reservation?.rejectReason ?? '-'}</TableCell>
            <TableCell align="center">
              {reservation.paymentDate ? successIcon : errorIcon}
            </TableCell>
            <TableCell align="center">
              {reservation.signatureDate &&
              reservation.secondaryBuyerReservations?.every(
                (reservation) => !!reservation.signatureDate
              )
                ? successIcon
                : errorIcon}
            </TableCell>
            <TableCell>
              <div className={classes.noWrap}>
                {new Date(reservation?.createdAt).toLocaleString()}
              </div>
            </TableCell>
            <TableCell>
              <div className={classes.noWrap}>
                <strong>
                  {reservation?.prospect?.firstName}{' '}
                  {reservation?.prospect?.lastName}
                </strong>
              </div>
              {reservation.secondaryBuyerReservations?.map(
                (additionalBuyer) => (
                  <div
                    className={classes.noWrap}
                    key={`${reservation.id}-${additionalBuyer.prospectId}`}
                  >
                    {additionalBuyer?.prospect?.firstName &&
                    additionalBuyer?.prospect?.lastName
                      ? additionalBuyer?.prospect?.firstName +
                        ' ' +
                        additionalBuyer?.prospect?.lastName
                      : 'Unconfirmed additional buyer'}
                  </div>
                )
              )}
            </TableCell>
            <TableCell>
              <div className={classes.noWrap}>
                <strong>{reservation?.prospect?.phone}</strong>
              </div>
              {reservation.secondaryBuyerReservations?.map(
                (additionalBuyer) => (
                  <div
                    className={classes.noWrap}
                    key={`${reservation.id}-${additionalBuyer.prospectId}`}
                  >
                    {additionalBuyer?.prospect?.phone ?? '-'}
                  </div>
                )
              )}
            </TableCell>
            <TableCell>
              <div className={classes.noWrap}>
                <strong>{reservation?.prospect?.email}</strong>
              </div>
              {reservation.secondaryBuyerReservations?.map(
                (additionalBuyer) => (
                  <div
                    className={classes.noWrap}
                    key={`${reservation.id}-${additionalBuyer.prospectId}`}
                  >
                    {additionalBuyer?.prospect?.email ?? '-'}
                  </div>
                )
              )}
            </TableCell>
            <TableCell>{reservation.favorite.community?.name}</TableCell>
            <TableCell>{reservation.favorite.elevation?.planName}</TableCell>
            <TableCell>{reservation.favorite.elevation?.caption}</TableCell>
            <TableCell>{reservation.favorite.lot?.name}</TableCell>
            <TableCell>{formatPrice(reservation.totalPrice)}</TableCell>
          </TableRow>
        ))}
      </StyledTableBody>
      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title"> Are you sure? </DialogTitle>
        <DialogContent>{confirmDialogContent}</DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmDialog(false)}>Close</Button>
          <Button
            onClick={() => {
              setShowConfirmDialog(false)
              proceedWithSign(currentReservationId)
            }}
          >
            Sign
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ReservationsListBody
