import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'

const PREFIX = 'VisitorLocations'

export const classes = {
  gridRoot: `${PREFIX}-gridRoot`,
  parent: `${PREFIX}-parent`,
  map: `${PREFIX}-map`,
}

export const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.gridRoot}`]: {
    flexGrow: 1,
    height: '90%',
    [theme.breakpoints.down('md')]: {
      height: '80%',
    },
  },

  [`& .${classes.parent}`]: {
    width: '100%',
    height: '100%',
    position: 'relative',
    textAlign: 'justify',
  },

  [`& .${classes.map}`]: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
  },
}))
