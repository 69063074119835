import { gql } from '@apollo/client'

export const GET_DROP_OF_RATE = gql`
  query GET_DROP_OF_RATE(
    $clientName: String!
    $filter: ReservationDropOfFilterInput
  ) {
    getReservationsDropOfRate(clientName: $clientName, filter: $filter) {
      termsAndCondition
      signedDocument
      payment
      completed
    }
  }
`

export const GET_RESERVATION_STATUS_OVERVIEW = gql`
  query GET_RESERVATION_STATUS_OVERVIEW(
    $clientName: String!
    $filter: ReservationStatusOverviewFilterInput
  ) {
    getReservationStatusOverview(clientName: $clientName, filter: $filter) {
      inProgress
      approvedByBuilder
      rejectedByBuilder
      rejectedByProspect
    }
  }
`

export const GET_RESERVATION_STATUS_COMMUNITIES_OVERVIEW = gql`
  query GET_RESERVATION_STATUS_COMMUNITIES_OVERVIEW(
    $clientName: String!
    $filter: ReservationStatusOverviewFilterInput
  ) {
    getReservationStatusCommunitiesOverview(
      clientName: $clientName
      filter: $filter
    ) {
      communityName
      status {
        inProgress
        approvedByBuilder
        rejectedByBuilder
        rejectedByProspect
      }
    }
  }
`
