import React from 'react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { useTheme, useMediaQuery } from '@mui/material'
import {
  PopularCommunityByCount,
  PopularCommunityByDuration,
  PopularCommunityByFavoritesCount,
  PopularityMetric,
} from 'graphql/gen-types'

import { getTextColor, readableTimeFormat } from 'utils/functions'
import chroma from 'chroma-js'
import {
  StyledComponentCard,
  HelpBlock,
  HelpText,
  classes,
} from './PopularCommunitiesList.styles'
import { cityFormatter } from '../../../utils/formatters'
interface PopularCommunitiesProps {
  activeTab: number
  communities:
    | PopularCommunityByCount[]
    | PopularCommunityByDuration[]
    | PopularCommunityByFavoritesCount[]
    | any
  popularityMetric: PopularityMetric
  setActiveTab: React.Dispatch<React.SetStateAction<number>>
  report?: boolean
}

function PopularCommunitiesList(props: PopularCommunitiesProps): JSX.Element {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const scaleBgColor = chroma.scale(['#e65400', '#f7f7ba'])
  const bgColors = scaleBgColor.colors(props.communities.length)
  const colors = bgColors.map((color, i) => [color, getTextColor(color)])

  const handleTabChange = (
    ev: unknown,
    newValue: React.SetStateAction<number>
  ): void => {
    props.setActiveTab(newValue)
  }

  const communitiesToUse = props.communities.slice(
    0,
    props.report
      ? Math.min(5, props.communities.length)
      : props.communities.length
  )

  const displayByCountTable = (): JSX.Element => {
    return (
      <React.Fragment>
        <HelpBlock>
          <HelpOutlineIcon />
          <HelpText variant="caption">
            Times a community has been viewed.
          </HelpText>
        </HelpBlock>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table
            size={props.report ? 'small' : undefined}
            padding={props.report ? 'none' : undefined}
            stickyHeader
            aria-label="Top communities by views"
          >
            <TableHead>
              <TableRow>
                <TableCell>Rank</TableCell>
                <TableCell>Community</TableCell>
                <TableCell>Location</TableCell>
                <TableCell align="right">Views</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {communitiesToUse.map(
                (community: PopularCommunityByCount, index: number) => (
                  <TableRow key={`${community.community?.name}-${index}`}>
                    <TableCell component="th" scope="row">
                      <div
                        className={classes.rankColorCircle}
                        style={{
                          backgroundColor: colors[index][0],
                          color: colors[index][1],
                        }}
                      >
                        {index + 1}
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {community.community?.name}
                    </TableCell>
                    <TableCell>
                      {cityFormatter.regionName(
                        community.community?.cityLocation
                      )}
                    </TableCell>

                    <TableCell align="right">{community.count}</TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
    )
  }

  const displayByDurationTable = (): JSX.Element => {
    return (
      <React.Fragment>
        <HelpBlock>
          <HelpOutlineIcon />
          <HelpText variant="caption">Time spent viewing a community.</HelpText>
        </HelpBlock>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table stickyHeader aria-label="Top communities by duration">
            <TableHead>
              <TableRow>
                <TableCell>Rank</TableCell>
                <TableCell>Community</TableCell>
                <TableCell align="right">Total Duration</TableCell>
                <TableCell align="right">Average</TableCell>
                <TableCell align="right">Median</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.communities.map(
                (community: PopularCommunityByDuration, index: number) => (
                  <TableRow key={`${community.communityName}-${index}`}>
                    <TableCell component="th" scope="row">
                      <div
                        className={classes.rankColorCircle}
                        style={{
                          backgroundColor: colors[index][0],
                          color: colors[index][1],
                        }}
                      >
                        {index + 1}
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {community.community?.name}
                    </TableCell>
                    <TableCell align="right">
                      {community?.durationInfo
                        ? readableTimeFormat(community?.durationInfo?.total)
                        : 0}
                    </TableCell>
                    <TableCell align="right">
                      {community?.durationInfo
                        ? readableTimeFormat(community?.durationInfo?.avg)
                        : 0}
                    </TableCell>
                    <TableCell align="right">
                      {community?.durationInfo
                        ? readableTimeFormat(community?.durationInfo?.median)
                        : 0}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
    )
  }

  const displayByFavoritesCountTable = (): JSX.Element => {
    return (
      <React.Fragment>
        <HelpBlock>
          <HelpOutlineIcon />
          <HelpText variant="caption">
            Times a home in this community has been favorited.
          </HelpText>
        </HelpBlock>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table
            size={props.report ? 'small' : undefined}
            padding={props.report ? 'none' : undefined}
            stickyHeader
            aria-label="Top communities by favorites count"
          >
            <TableHead>
              <TableRow>
                <TableCell>Rank</TableCell>
                <TableCell>Community</TableCell>
                <TableCell>Location</TableCell>
                <TableCell align="right">Favorites Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {communitiesToUse.map(
                (
                  community: PopularCommunityByFavoritesCount,
                  index: number
                ) => (
                  <TableRow key={`${community.community?.name}-${index}`}>
                    <TableCell component="th" scope="row">
                      <div
                        className={classes.rankColorCircle}
                        style={{
                          backgroundColor: colors[index][0],
                          color: colors[index][1],
                        }}
                      >
                        {index + 1}
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {community.community?.name}
                    </TableCell>
                    <TableCell>
                      {cityFormatter.regionName(
                        community.community?.cityLocation
                      )}
                    </TableCell>

                    <TableCell align="right">
                      {community.favoritesCount}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
    )
  }

  return (
    <StyledComponentCard
      height={props.report ? 300 : undefined}
      report={props.report}
      title="Top Communities"
    >
      {!props.report && (
        <Tabs
          centered
          value={props.activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant={isMobile ? 'fullWidth' : 'standard'}
        >
          <Tab label="By Views" />
          <Tab
            data-testid="popularCommunitiesListDurationTab"
            label="By Duration"
          />
          <Tab
            data-testid="popularCommunitiesListFavoritesCountTab"
            label="By Favorites Count"
          />
        </Tabs>
      )}
      <Grid
        container
        className={classes.gridRoot}
        justifyContent="center"
        spacing={0}
      >
        <Grid item className={classes.grid} xs={11}>
          {props.popularityMetric === PopularityMetric.Frequency &&
            displayByCountTable()}
          {props.popularityMetric === PopularityMetric.Duration &&
            displayByDurationTable()}
          {props.popularityMetric === PopularityMetric.Favoritescount &&
            displayByFavoritesCountTable()}
        </Grid>
      </Grid>
    </StyledComponentCard>
  )
}

export default PopularCommunitiesList
