import { styled } from '@mui/material/styles'
import Avatar from '@mui/material/Avatar'

const PREFIX = 'InteriorSelectionsTable'

export const classes = {
  cell: `${PREFIX}-cell`,
  avatar: `${PREFIX}-avatar`,
}

export const StyledAvatar = styled(Avatar)(() => ({
  [`&.${classes.avatar}`]: {
    border: '1px solid lightgray',
    margin: 0,
  },
}))
