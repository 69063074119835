import React, { useState } from 'react'

import Button from '@mui/material/Button'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'

/**
 * Modal for adding new milestones.
 */
export default function AddMilestoneModal({
  open,
  onClose,
  onSubmit,
}: {
  open: boolean
  onClose: () => void
  onSubmit: (title: string) => void
}): JSX.Element {
  const [title, setTitle] = useState('')
  const handleTitleChange = (title: string): void => {
    setTitle(title)
  }
  const handleSubmit = (): void => {
    onSubmit(title)
    setTitle('') // reset
  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add Milestone</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The added Milestone will be inserted into the next custom milestone
          slot.
        </DialogContentText>
        <TextField
          variant="standard"
          autoFocus
          margin="dense"
          id="name"
          label="Milestone Name"
          type="input"
          fullWidth
          onChange={(e): void => handleTitleChange(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          Cancel
        </Button>
        <Button
          data-testid="create-button"
          onClick={handleSubmit}
          color="primary"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}
