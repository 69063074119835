import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import { classes, Root } from './PlansFilterBar.styles'

type filterButtonProps = {
  name: string
  id: string
  onChange: (value: number) => void
  min: number
  max: number
  textAppend?: string
  stepSize?: number
  value?: number
}
function FilterButton({
  name,
  id,
  onChange,
  min,
  max,
  textAppend = '',
  stepSize = 1,
  value = 0,
}: filterButtonProps): JSX.Element {
  const [selected, setSelected] = useState(value)
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >()
  const open = Boolean(anchorEl)
  const popoverId = open ? `${id}-popover` : undefined
  const text = selected === 0 ? `${name}` : `${name}:${selected}${textAppend}`

  useEffect(() => {
    setSelected(value)
  }, [value])

  const handleSelection = (value: number): void => {
    setSelected(value)
    onChange(value)
  }

  const getItemClass = (selectedValue: number): string => {
    return selected === selectedValue
      ? classes.btnGroupSelected
      : classes.btnGroupSelection
  }

  const menuItemValues = []
  for (let i = min; i <= max; i += stepSize) {
    menuItemValues.push(i)
  }
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={(
          event: React.MouseEvent<HTMLButtonElement, MouseEvent>
        ): void => {
          setAnchorEl(event.currentTarget)
        }}
        style={{ marginTop: 10 }}
      >
        {text}
      </Button>
      <Popover
        id={popoverId}
        open={open}
        onClose={(): void => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        disableRestoreFocus
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Root>
          <div
            className={classes.btnGroupMenuContainer}
            data-testid="FilterButtonPopover"
          >
            {menuItemValues.map((value) => (
              <div
                key={value}
                className={getItemClass(value)}
                onClick={(): void => handleSelection(value)}
              >
                {value === 0 ? `Any` : `${value}${textAppend}`}
              </div>
            ))}
          </div>
        </Root>
      </Popover>
    </>
  )
}

export default FilterButton
