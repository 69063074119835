import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { getImagePrefix } from '@anewgo/utils'
import TableImageDialog from 'components/common/misc/TableImageDialog'
import ComponentCard from 'components/common/layout/ComponentCard'
import {
  Floorplan,
  Maybe,
  PopularFloorplans,
  ProspectFloorplan,
} from 'graphql/gen-types'
import ResponsiveTable from 'components/common/tables/ResponsiveTable'
import Modal from '@mui/material/Modal'
import { ResponsiveCell } from '../tables/ResponsiveTable/ResponsiveTable.styles'
const PREFIX = 'FloorplansDisplay'

const classes = {
  modal: `${PREFIX}-modal`,
  paper: `${PREFIX}-paper`,
  modalImage: `${PREFIX}-modalImage`,
  showModalButton: `${PREFIX}-showModalButton`,
  imagesContainer: `${PREFIX}-imagesContainer`,
  imageDialogTitle: `${PREFIX}-imageDialogTitle`,
  imageTitle: `${PREFIX}-imageTitle`,
}

const StyledModal = styled(Modal)(({ theme }) => ({
  [`&.${classes.modal}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 2),
    outline: 'none',
    overflow: 'auto',
    maxHeight: '90vh',
    [theme.breakpoints.up('sm')]: {
      maxHeight: '70vh',
    },
  },

  [`& .${classes.modalImage}`]: {
    padding: theme.spacing(1),
  },

  [`& .${classes.imagesContainer}`]: {
    display: 'grid',
    gap: '20px',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },

  [`& .${classes.imageDialogTitle}`]: {
    fontSize: '15px',
    padding: '10px',
  },

  [`& .${classes.imageTitle}`]: {
    textAlign: 'left',
  },
}))

const StyledDiv = styled('div')(({ theme }) => ({
  [`&.${classes.showModalButton}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    gap: '10px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
    },
  },
}))

const COMPONENT_TITLE = 'Popular Floor Plan Options'
const NUM_DISPLAY_ROWS = 5
const RESPONSE_SIZE = 'sm'
const ROW_HEIGHT = 90

interface FloorplansDisplayProps {
  data: Maybe<PopularFloorplans>[] | Maybe<ProspectFloorplan>[]
  loading?: boolean
  error?: Error | undefined
  showPopularity?: boolean
}

type FloorplanDisplayType = {
  clientName: string
  floorplanIds: number[]
  floorplans: Floorplan[]
  popularity?: number
}

function FloorplansDisplay({
  data,
  loading,
  error,
  showPopularity = true,
}: FloorplansDisplayProps): JSX.Element {
  const [imageDialogOpened, setImageDialogOpened] = useState(false)
  const [selectedImage, setSelectedImage] = useState<JSX.Element>()
  const [openModal, setOpenModal] = useState(false)
  const [selectedFloorplans, setSelectedFloorplans] = useState<Floorplan[]>([])

  const handleImageClick = (src: string, plans?: string): void => {
    setSelectedImage(
      <>
        {plans && (
          <div className={classes.imageDialogTitle}>Plans: {plans}</div>
        )}
        <img src={src} alt="" />
      </>
    )
    setImageDialogOpened(true)
  }

  const displayFloorplans = data as FloorplanDisplayType[]

  const tableHeadCells: (JSX.Element | null)[] = [
    <ResponsiveCell
      align="center"
      key="header-cell-floorplan"
      responseSize={RESPONSE_SIZE}
      rowHeight={ROW_HEIGHT}
    >
      Floorplans
    </ResponsiveCell>,
    <ResponsiveCell
      align="center"
      key="header-cell-name"
      responseSize={RESPONSE_SIZE}
      rowHeight={ROW_HEIGHT}
    >
      Name
    </ResponsiveCell>,
    showPopularity ? (
      <ResponsiveCell
        align="center"
        key="header-cell-popularity"
        responseSize={RESPONSE_SIZE}
        rowHeight={ROW_HEIGHT}
      >
        Popularity
      </ResponsiveCell>
    ) : null,
  ]

  const ResponsiveTableRow = ({
    row,
    index,
  }: {
    row: FloorplanDisplayType
    index?: number
  }): JSX.Element => {
    const imgPrefix = getImagePrefix(
      row.clientName,
      `c_scale,w_60,h_60,g_auto,c_thumb,f_auto`
    )
    const dialogImagePrefix = getImagePrefix(
      row.clientName,
      `c_scale,c_fit,e_trim`
    )
    return (
      <>
        <ResponsiveCell
          align="center"
          responseSize={RESPONSE_SIZE}
          rowHeight={ROW_HEIGHT}
        >
          {row.floorplans?.length === 1 ? (
            <img
              src={`${process.env.REACT_APP_HEMI_ENGINE}/assets-gs-cld/w_60,h_60,g_auto,c_thumb,f_auto/app/${row.clientName}/images/floorplans/${row.floorplans[0]?.src}`}
              alt={`${
                row.floorplans[0]?.name
                  ? `${row.floorplans[0]?.name} Image`
                  : 'Floorplan Image'
              }`}
              style={{ cursor: 'pointer' }}
              onClick={(): void =>
                handleImageClick(
                  `${process.env.REACT_APP_HEMI_ENGINE}/assets-gs-cld/c_scale,c_fit,e_trim/app/${row.clientName}/images/floorplans/${row.floorplans[0]?.src}`,
                  row.floorplans[0].plans
                    ?.map((plan) => plan?.name || '')
                    .join(', ')
                )
              }
            />
          ) : (
            <StyledDiv
              onClick={() => {
                setOpenModal(true)
                setSelectedFloorplans(row.floorplans)
              }}
              className={classes.showModalButton}
            >
              <img
                src={`${process.env.REACT_APP_HEMI_ENGINE}/assets-gs-cld/c_scale,w_60,h_60,g_auto,c_thumb,f_auto/app/${row.clientName}/images/floorplans/${row.floorplans[0]?.src}`}
                alt={`${
                  row.floorplans[0]?.name
                    ? `${row.floorplans[0]?.name} Image`
                    : 'Floorplan Image'
                }`}
              />
              <div>+ {row.floorplans.length - 1}</div>
            </StyledDiv>
          )}
        </ResponsiveCell>
        <ResponsiveCell
          align="center"
          component="th"
          scope="row"
          responseSize={RESPONSE_SIZE}
          rowHeight={ROW_HEIGHT}
        >
          <Typography noWrap>{row.floorplans[0]?.name}</Typography>
        </ResponsiveCell>

        {showPopularity ? (
          <ResponsiveCell
            align="center"
            responseSize={RESPONSE_SIZE}
            rowHeight={ROW_HEIGHT}
          >
            {row.popularity}
          </ResponsiveCell>
        ) : null}
      </>
    )
  }

  return (
    <ComponentCard
      title={COMPONENT_TITLE}
      result={{ data, loading, error }}
      skeletonHeight={600}
    >
      <ResponsiveTable
        data={displayFloorplans}
        generateRow={(dataRow, index): JSX.Element => (
          <ResponsiveTableRow row={dataRow} index={index} />
        )}
        columns={tableHeadCells}
        responseSize={RESPONSE_SIZE}
        initialRows={NUM_DISPLAY_ROWS}
      />
      <TableImageDialog
        open={imageDialogOpened}
        onClose={(): void => setImageDialogOpened(false)}
        image={selectedImage}
      />
      <StyledModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <div className={classes.paper}>
          <h3>Included floorplans</h3>
          <div className={classes.imagesContainer}>
            {selectedFloorplans
              .slice()
              .sort((a, b) => {
                if (a === null && b !== null) {
                  return 1
                }
                if (a !== null && b === null) {
                  return -1
                }
                if (a === null && b === null) {
                  return 0
                }
                if (!a.popularity || !b.popularity) {
                  return 0
                }
                if (a.popularity > b.popularity) {
                  return -1
                }
                return 1
              })
              .map((floorplan, index) => {
                if (!floorplan) {
                  return null
                }
                const imgPrefix = getImagePrefix(
                  floorplan.clientName,
                  `c_scale,w_240,h_240,g_auto,c_thumb,f_auto`
                )
                return (
                  <ComponentCard key={`popularFloorplanImage-${index}`}>
                    <div className={classes.imageTitle}>
                      Plans:{' '}
                      {floorplan.plans
                        ?.map((plan) => plan?.name || '')
                        .join(', ')}
                    </div>

                    <img
                      className={classes.modalImage}
                      src={`${process.env.REACT_APP_HEMI_ENGINE}/assets-gs-cld/c_scale,w_240,h_240,g_auto,c_thumb,f_auto/app/${floorplan.clientName}/images/floorplans/${floorplan?.src}`}
                      alt={`${
                        floorplan?.name
                          ? `${floorplan?.name} Image`
                          : 'Floorplan Image'
                      }`}
                      style={{ cursor: 'pointer' }}
                    />
                  </ComponentCard>
                )
              })}
          </div>
        </div>
      </StyledModal>
    </ComponentCard>
  )
}

export default FloorplansDisplay
