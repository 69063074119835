import { styled, Theme } from '@mui/material/styles'
import TableCell from '@mui/material/TableCell'
import { excludeProps } from 'utils/functions'
import {
  DEFAULT_RESPONSE_SIZE,
  DEFAULT_ROW_HEIGHT,
  ResponsiveTableStyleProps,
} from '.'

type ResponsiveCellProps = ResponsiveTableStyleProps & { theme: Theme }
const ResponsiveCell = styled(TableCell, {
  name: 'ResponsiveCell',
  shouldForwardProp: (prop) =>
    excludeProps(prop, 'responseSize', 'alignText', 'rowHeight'),
})(
  ({
    theme,
    responseSize = DEFAULT_RESPONSE_SIZE,
    alignText,
    rowHeight = DEFAULT_ROW_HEIGHT,
  }: ResponsiveCellProps) => ({
    [theme.breakpoints.down(responseSize)]: {
      display: 'block',
      textAlign: alignText,
      height: rowHeight,
    },
    lineHeight: '1rem',
    whiteSpace: 'nowrap',
    fontWeight: 'inherit',
    textAlign: alignText,
    color: 'inherit',
  })
)

export default ResponsiveCell
