import React, { useContext, useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import InfoIcon from '@mui/icons-material/Info'
import { ENVELOPES_CREATED_QUERY } from '../../../graphql/queries/envelopesCreate'
import { useGetEnvelopesCreatedLazyQuery } from 'graphql/gen-types'
import { AppStore } from '../../../store'
import ReservationFromToDatePicker from './ReservationFromToDatePicker'
import { styled } from '@mui/material/styles'
import { UnauthorizedCard } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'

const PREFIX = 'ReservationEnvelopes'

export const classes = {
  envelopeGrid: `${PREFIX}-envelopeGrid`,
}

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.envelopeGrid}`]: {
    marginTop: 12,
    '& > strong': {
      fontSize: 22,
      marginTop: 2,
      display: 'block',
    },
  },
}))

const date = new Date()

const ReservationEnvelopes: React.FC = () => {
  const { appState } = useContext(AppStore)
  const [dateFrom, setDateFrom] = useState<Date | null>(
    new Date(date.getFullYear(), date.getMonth(), 1)
  )
  const [dateTo, setDateTo] = useState<Date | null>(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  )

  const [
    getEnvelopes,
    { data, loading, error },
  ] = useGetEnvelopesCreatedLazyQuery()

  useEffect(() => {
    if (!dateFrom?.toJSON() || !dateTo?.toJSON()) return

    getEnvelopes({
      variables: {
        filter: {
          startTime: dateFrom?.toJSON().slice(0, 10),
          endTime: dateTo?.toJSON().slice(0, 10),
          clientName: appState?.selectedClient?.altName || '',
        },
      },
    })
  }, [dateFrom, dateTo])

  const envelopesCount = data?.getEnvelopesCreated?.count ?? 0

  const handleDateFromChange = (date: Date | null) => {
    setDateFrom(date)
  }

  const handleDateToChange = (date: Date | null) => {
    setDateTo(date)
  }

  if (error && verifyUserAuthError(error.toString()))
    return (
      <UnauthorizedCard
        title="Agreements Created"
        message={error.toString()}
        imageName={'buyOnlineCommunitiesStatusOverview'}
      />
    )

  return (
    <Card>
      <CardHeader
        title={
          <>
            Agreements Created
            <Tooltip
              arrow
              title="Number of created DocuSign agreements (envelopes) in selected timespan."
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </>
        }
      />
      <CardContent>
        <Grid container spacing={3}>
          <ReservationFromToDatePicker
            fromValue={dateFrom}
            onFromChange={handleDateFromChange}
            toValue={dateTo}
            onToChange={handleDateToChange}
            grid={{ xs: 12, sm: 6, md: 3 }}
          />
          <StyledGrid className={classes.envelopeGrid} item xs={12} md={6}>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <div>Agreements count:</div>
                <strong>{envelopesCount}</strong>
              </>
            )}
          </StyledGrid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ReservationEnvelopes
