import { styled } from '@mui/material/styles'

const PREFIX = 'EmailRetrievePage'

export const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  imageContainer: `${PREFIX}-imageContainer`,
  loadingLoginBody: `${PREFIX}-loadingLoginBody`,
  retrievePass: `${PREFIX}-retrievePass`,
  loginBody: `${PREFIX}-loginBody`,
  logoImg: `${PREFIX}-logoImg`,
  textField: `${PREFIX}-textField`,
  typography: `${PREFIX}-typography`,
  messageSuccess: `${PREFIX}-messageSuccess`,
}

export const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    backgroundColor: 'white',
    top: 0,
    left: 0,
    position: 'fixed',
    overflow: 'hidden',
    textAlign: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.button}`]: {
    marginTop: theme.spacing(4),
    width: '100%',
  },

  [`& .${classes.imageContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '70px',
    paddingTop: '12px',
  },

  [`& .${classes.loadingLoginBody}`]: {
    opacity: 0.4,
  },

  [`& .${classes.retrievePass}`]: {
    border: '1px solid #dadce0',
    borderRadius: '8px',
    display: 'inline-block',
    maxWidth: '350px',
    marginTop: '100px',
    height: '520px',
    overflow: 'hidden',
    padding: '50px',
  },

  [`& .${classes.loginBody}`]: {
    padding: theme.spacing(3),
  },

  [`& .${classes.logoImg}`]: {
    display: 'block',
    margin: 'auto',
    maxWidth: '180px',
  },

  [`& .${classes.textField}`]: {
    width: '100%',
  },

  [`& .${classes.typography}`]: {
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },

  [`& .${classes.messageSuccess}`]: {
    marginTop: '60px',
    marginBottom: '20px',
  },
}))
