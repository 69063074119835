import React from 'react'
import { SectionProps } from 'components/common/layout/InfiniteScroll'
import { classes, Root } from './Sections.styles'

interface SectionsProps {
  sections: Array<SectionProps>
  sectionComponents: Array<JSX.Element>
}

function Sections({ sections, sectionComponents }: SectionsProps): JSX.Element {
  return (
    <Root className={classes.sections}>
      {sections.map((section, i) => (
        <div key={`section-${i}`} className={section.className}>
          {sectionComponents[i]}
        </div>
      ))}
    </Root>
  )
}

export default Sections
