import React, { ReactNode, SyntheticEvent } from 'react'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { useTheme } from '@mui/material/styles'
import { IndustrySessionTrends } from 'graphql/gen-types'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Label,
  Legend,
} from 'recharts'
import { IndustryTrendsYAxisLabel } from 'components/common/charts/custom/CustomAxisLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import useMediaQuery from '@mui/material/useMediaQuery'
import { SessionTrendsContainer } from './SessionTrendsPanel.styles'
import { styled } from '@mui/material/styles'
import FormControl from '@mui/material/FormControl'
import DateFnsAdapter from '@date-io/date-fns'
import { Typography } from '@mui/material'
import { useState } from 'react'

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

const industryKey = {
  sessions: 'industrySessions',
  visitors: 'industryVisitors',
  registered: 'industryRegistered',
  returnVisitors: 'industryReturnVisitors',
  newVisitors: 'industryNewVisitors',
  averageDuration: 'industryAverageDuration',
  bounceRate: 'industryBounceRate',
}

const keyIndustryDisplayName: Record<string, string> = {
  industrySessions: 'Industry Sessions',
  industryVisitors: 'Industry Visitors',
  industryRegistered: 'Industry Users Registered',
  industryReturnVisitors: 'Industry Return Visitors',
  industryNewVisitors: 'Industry New Visitors',
  industryAverageDuration: 'Industry Average Duration',
  industryBounceRate: 'Industry Bounce Rate',
}

// Maps gql field names to readable names
const keySessionDisplayName: Record<string, string> = {
  sessions: 'Sessions',
  visitors: 'Visitors',
  registered: 'Registered Users',
  returnVisitors: 'Returning Visitors',
  newVisitors: 'New Visitors',
  averageDuration: 'Average Duration',
  bounceRate: 'Bounce Rate',
}

type IndustrySessionTrendsPanelProps = {
  children?: ReactNode | ReactNode[] | string | JSX.Element | null
  data: unknown[]
  isIndustry?: boolean | false
}

const PREFIX = 'IndustrySessionsMetricSelect'
const classes = {
  formControl: `${PREFIX}-formControl`,
  formControlMobile: `${PREFIX}-formControlMobile`,
  typography: `${PREFIX}-typography`,
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`&.${classes.formControl}`]: {
    margin: `0px ${theme.spacing(1)}`,
    float: 'right',
  },
  [`&.${classes.formControlMobile}`]: {
    margin: `0px ${theme.spacing(1)}`,
    display: 'inline-block',
  },
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  [`&.${classes.typography}`]: {
    fontWeight: 500,
    padding: '16px',
    marginLeft: '8px',
    textAlign: 'left',
    fontSize: '1.4993rem',
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    color: theme.palette.primary.main,
    letterSpacing: '0em',
  },
}))

export default function IndustrySessionTrendsPanel({
  data,
  isIndustry,
}: IndustrySessionTrendsPanelProps): JSX.Element {
  const [tabValue, setTabValue] = React.useState(0)
  const [selectValue, setSelectValue] = React.useState('sessions')
  const theme = useTheme()
  const smallSize = useMediaQuery(theme.breakpoints.down('md'))

  const keyDisplayName: Record<string, string> = {
    ...keySessionDisplayName,
    ...(isIndustry && keyIndustryDisplayName),
  }

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setSelectValue(event.target.value as string)
  }

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: number
  ): void => {
    setTabValue(newValue)
  }

  // Get formatted data and render
  const [selectedData, setSelectedData] = useState('daily')

  const getData = (value: string) => {
    switch (value) {
      case 'daily':
        return getFormattedData(data as IndustrySessionTrends[])
      case 'monthly':
        return getFormattedDataByMonthOrYear(
          data as IndustrySessionTrends[],
          'MONTH'
        )
      case 'yearly':
        return getFormattedDataByMonthOrYear(
          data as IndustrySessionTrends[],
          'YEAR'
        )
      default:
        return []
    }
  }

  return (
    <>
      {smallSize ? (
        <>
          <StyledTypography className={classes.typography}>
            Industry Session Trends
          </StyledTypography>
          <StyledFormControl
            className={classes.formControlMobile}
            variant="standard"
          >
            <Select
              style={{
                width: '120px',
              }}
              variant="standard"
              labelId="metric-select-label"
              id="metric-select"
              onChange={(event) => setSelectedData(event.target.value)}
              value={selectedData}
            >
              <MenuItem value={'daily'}>Daily</MenuItem>
              <MenuItem value={'monthly'}>Monthly</MenuItem>
              <MenuItem value={'yearly'}>Yearly</MenuItem>
            </Select>
            <Select
              variant="standard"
              value={selectValue}
              onChange={handleSelectChange}
              style={{
                marginBottom: theme.spacing(2),
                marginLeft: theme.spacing(2),
                width: '120px',
              }}
            >
              {Object.entries(keyDisplayName).map((entry, i) => {
                const [key, name] = entry
                return (
                  <MenuItem
                    value={key}
                    key={`sessions-trends-menu-item${key}-${i}`}
                  >
                    {name}
                  </MenuItem>
                )
              })}
            </Select>
          </StyledFormControl>
          <SessionsSummaryTrendsBarChart
            data={getData(selectedData)}
            val={selectValue}
            name={keyDisplayName[selectValue]}
            isIndustry={isIndustry}
          />
        </>
      ) : (
        <>
          <StyledTypography className={classes.typography}>
            Industry Session Trends
            <StyledFormControl
              className={classes.formControl}
              variant="standard"
            >
              <Select
                style={{
                  width: '115px',
                }}
                variant="standard"
                labelId="metric-select-label"
                id="metric-select"
                onChange={(event) => setSelectedData(event.target.value)}
                value={selectedData}
              >
                <MenuItem value={'daily'}>Daily</MenuItem>
                <MenuItem value={'monthly'}>Monthly</MenuItem>
                <MenuItem value={'yearly'}>Yearly</MenuItem>
              </Select>
            </StyledFormControl>
          </StyledTypography>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab label="Sessions" />
            <Tab label="Visitors" />
            <Tab label="Registered" />
            <Tab label="Returning" />
            <Tab label="New Visitors" />
            <Tab label="Duration" />
            <Tab label="Bounce Rate" />
          </Tabs>
          {Object.entries(keyDisplayName).map((entry, i) => {
            const [key, name] = entry
            return (
              <TabPanel key={key} value={tabValue} index={i}>
                <SessionsSummaryTrendsBarChart
                  data={getData(selectedData)}
                  val={key}
                  name={name}
                  isIndustry={isIndustry}
                />
              </TabPanel>
            )
          })}
        </>
      )}
    </>
  )
}

interface TrendsChartProps {
  data: IndustrySessionTrends[]
  val: string
  name: string
  isIndustry?: boolean | false
}

function SessionsSummaryTrendsBarChart({
  data,
  val,
  name,
  isIndustry,
}: TrendsChartProps): JSX.Element {
  const theme = useTheme()
  const stroke =
    theme.palette.primary[theme.palette.mode === 'dark' ? 'light' : 'dark']
  const fill = theme.palette.primary.main

  const industryFill = 'midnightblue'

  const INDUSTRY_COLOR = 'darkslateblue'

  const keyDisplayName: Record<string, string> = {
    ...keySessionDisplayName,
    ...(isIndustry && keyIndustryDisplayName),
  }

  return (
    <SessionTrendsContainer container justifyContent="center">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart margin={{ top: 0, left: 0, right: 0, bottom: 0 }} data={data}>
          <XAxis
            dataKey="date"
            tick={{
              fill: stroke,
            }}
            xAxisId={0}
          />
          <XAxis xAxisId={1} hide={true} />
          <YAxis
            tick={{
              fill: stroke,
            }}
          >
            <Label
              value={name}
              stroke={stroke}
              content={<IndustryTrendsYAxisLabel />}
            />
          </YAxis>
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip labelStyle={{ color: 'black' }} />
          <Legend />
          <Bar
            name={keyDisplayName[industryKey[val]]}
            dataKey={industryKey[val]}
            stroke={industryFill}
            fill={INDUSTRY_COLOR}
          />
          <Bar
            name={keyDisplayName[val]}
            dataKey={val}
            stroke={fill}
            fill={theme.palette.primary.main}
          />
        </BarChart>
      </ResponsiveContainer>
    </SessionTrendsContainer>
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: number
  value: number
}

function TabPanel(props: TabPanelProps): JSX.Element {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

function getFormattedData(
  data: IndustrySessionTrends[]
): IndustrySessionTrends[] {
  // Copy over values to formatted data array
  const formattedData: IndustrySessionTrends[] = []
  data.forEach((summ, i) => {
    formattedData[i] = { ...summ }
  })
  // Sort by date
  formattedData.sort((a, b) => {
    return new Date(a.date).getTime() - new Date(b.date).getTime()
  })
  // Format datetime into `Month Day`
  formattedData.forEach((summ, i) => {
    const dateToFormat =
      summ.date && typeof summ.date === 'string'
        ? summ.date.replace(/-/g, '/').replace(/T.+/, '')
        : summ.date
    const date = new Date(dateToFormat + 86400000)
    const monthIndex = date.getMonth()
    const day = date.getDate()
    formattedData[i].date = `${months[monthIndex]} ${day}`
  })
  return formattedData
}

function getFormattedDataByMonthOrYear(
  data: IndustrySessionTrends[],
  monthOrYear: string
): IndustrySessionTrends[] {
  // Copy over values to formatted data array
  const formattedDataByMonthOrYear: IndustrySessionTrends[] = []
  data.forEach((summ, i) => {
    formattedDataByMonthOrYear[i] = { ...summ }
    // Adding one day to the date in milliseconds because the corresponding dates for each data point is a day behind
    formattedDataByMonthOrYear[i].date =
      formattedDataByMonthOrYear[i].date + 86400000
    // Format datetime into `Month Year` or 'Year' based on toggle
    formattedDataByMonthOrYear[i].date =
      monthOrYear === 'MONTH'
        ? months[new Date(formattedDataByMonthOrYear[i].date).getMonth()] +
          ' ' +
          new Date(formattedDataByMonthOrYear[i].date).getFullYear()
        : new Date(summ?.date).getFullYear()
  })
  // Sums up data based on month/year and finds averages for avg.duration and bounce rate
  return Object.values(
    formattedDataByMonthOrYear.reduce(
      (
        obj,
        {
          date,
          sessions,
          visitors,
          registered,
          returnVisitors,
          newVisitors,
          averageDuration,
          bounceRate,
          industrySessions,
          industryVisitors,
          industryRegistered,
          industryReturnVisitors,
          industryNewVisitors,
          industryAverageDuration,
          industryBounceRate,
        }
      ) => {
        obj[date] = obj[date] || {
          date,
          sessions: 0,
          visitors: 0,
          registered: 0,
          returnVisitors: 0,
          newVisitors: 0,
          averageDuration: 0,
          sumAverageDuration: 0,
          bounceRate: 0,
          sumBounceRate: 0,
          industrySessions: 0,
          industryVisitors: 0,
          industryRegistered: 0,
          industryReturnVisitors: 0,
          industryNewVisitors: 0,
          industryAverageDuration: 0,
          sumIndustryAverageDuration: 0,
          industryBounceRate: 0,
          sumIndustryBounceRate: 0,
          count: 1,
        }
        obj[date].sessions += sessions
        obj[date].visitors += visitors
        obj[date].registered += registered
        obj[date].returnVisitors += returnVisitors
        obj[date].newVisitors += newVisitors
        obj[date].sumAverageDuration += averageDuration
        obj[date].averageDuration = (
          obj[date].sumAverageDuration / obj[date].count
        ).toFixed(2)
        obj[date].sumBounceRate += bounceRate
        obj[date].bounceRate = (
          obj[date].sumBounceRate / obj[date].count
        ).toFixed(2)
        obj[date].industrySessions += industrySessions
        obj[date].industryVisitors += industryVisitors
        obj[date].industryRegistered += industryRegistered
        obj[date].industryReturnVisitors += industryReturnVisitors
        obj[date].industryNewVisitors += industryNewVisitors
        obj[date].sumIndustryAverageDuration += industryAverageDuration
        obj[date].industryAverageDuration = (
          obj[date].sumIndustryAverageDuration / obj[date].count
        ).toFixed(2)
        obj[date].sumIndustryBounceRate += industryBounceRate
        obj[date].industryBounceRate = (
          obj[date].sumIndustryBounceRate / obj[date].count
        ).toFixed(2)
        obj[date].count += 1
        return obj
      },
      {}
    )
  )
}
