import { styled } from '@mui/material/styles'

const PREFIX = 'AppSelect'
const DIALOG_PREFIX = 'Dialog'

export const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  cardActions: `${PREFIX}-cardActions`,
  cardContent: `${PREFIX}-cardContent`,
  gridItem: `${PREFIX}-gridItem`,
  gridRoot: `${PREFIX}-gridRoot`,
  media: `${PREFIX}-media`,
  mediaDisabled: `${DIALOG_PREFIX}-mediaDisabled`,
  dialogHolder: `${DIALOG_PREFIX}-dialogHolder`,
  dialogContent: `${DIALOG_PREFIX}-dialogContent`,
  successIcon: `${DIALOG_PREFIX}-successIcon`,
  modalList: `${DIALOG_PREFIX}-modalList`,
}

export const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    flexGrow: 1,
  },

  [`& .${classes.card}`]: {
    width: 345,
  },

  [`& .${classes.cardActions}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  [`& .${classes.cardContent}`]: {
    height: 160,
    [theme.breakpoints.down('md')]: {
      height: 180,
    },
  },

  [`& .${classes.gridItem}`]: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 400,
  },

  [`& .${classes.gridRoot}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 'inherit',
      overflowY: 'auto',
    },
  },

  [`& .${classes.media}`]: {
    height: 140,
    maxHeight: 140,
    objectFit: 'contain',
    padding: 16,
  },

  [`& .${classes.mediaDisabled}`]: {
    filter: `blur(3px)`,
  },
}))

export const DialogRoot = styled('div')(({ theme }) => ({
  [`&.${classes.dialogHolder}`]: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    color: theme.palette.text.primary,
    position: 'absolute',
    inset: 0,
    paddingTop: 50,
  },

  [`& .${classes.dialogContent}`]: {
    padding: theme.spacing(2, 3, 3),
    width: 500,
    maxWidth: '100%',
  },

  [`& .${classes.successIcon}`]: {
    color: theme.palette.success.main,
    fontSize: '3em',
  },

  [`& .${classes.modalList}`]: {
    paddingLeft: '1.8em',
    marginTop: '.7em',
  },
}))
