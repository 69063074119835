import { styled } from '@mui/material/styles'
import ComponentCard from 'components/common/layout/ComponentCard'

const PREFIX = 'ProspectFavoritesComparison'

export const classes = {
  container: `${PREFIX}-container`,
  headerCell: `${PREFIX}-headerCell`,
  tableCell: `${PREFIX}-tableCell`,
  sideCell: `${PREFIX}-sideCell`,
  cornerCellContent: `${PREFIX}-cornerCellContent`,
  fpOptsListCell: `${PREFIX}-fpOptsListCell`,
  swatchBox: `${PREFIX}-swatchBox`,
}

export const ProspectFavoritesComparisonStyled = styled(ComponentCard)(
  ({ theme }) => ({
    [`& .${classes.container}`]: {
      height: '90%',
      overflowY: 'hidden',
    },

    [`& .${classes.headerCell}`]: {
      fontSize: 24,
      textAlign: 'center',
      backgroundColor: theme.palette.mode === 'dark' ? '#282828' : 'whitesmoke',
    },

    [`& .${classes.tableCell}`]: {
      textAlign: 'center',
    },

    [`& .${classes.sideCell}`]: {
      backgroundColor: theme.palette.mode === 'dark' ? '#282828' : 'whitesmoke',
    },

    [`& .${classes.cornerCellContent}`]: {
      width: 120,
    },

    [`& .${classes.fpOptsListCell}`]: {
      verticalAlign: 'top',
    },

    [`& .${classes.swatchBox}`]: {
      width: '62px',
      height: '62px',
      margin: 'auto',
    },
  })
)
