import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
const PREFIX = 'EngagementRate'

export const classes = {
  percentValueWrapper: `${PREFIX}-percentValueWrapper`,
  percentValue: `${PREFIX}-percentValue`,
}

export const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.percentValueWrapper}`]: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-around',
  },

  [`& .${classes.percentValue}`]: {
    display: 'inline-block',
    textAlign: 'center',
    width: '100%',
  },
}))
