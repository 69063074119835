import TimesSeriesLineChart from 'components/common/time-series/TimeSeriesLineChart'
import {
  CommunityTrend,
  PopularityMetric,
  Community,
  useGetCommunitiesTrendsQuery,
  CommonQueryFilter,
} from 'graphql/gen-types'
import React from 'react'
import { addZeroCountDates } from 'components/common/time-series/TimeSeriesFormatter'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import ComponentCard from 'components/common/layout/ComponentCard'

export interface CommunitiesTrendsProps {
  filter: CommonQueryFilter
  selectedCommunities?: Community[] | undefined
  areaChart?: boolean | false
  height?: number | null
  width?: number | null
}

export default function CommunitiesTrends({
  filter,
  selectedCommunities,
  areaChart,
  height,
  width,
}: CommunitiesTrendsProps): JSX.Element {
  const communityFilter = {
    baseInfo: {
      clientName: filter.clientName || '',
      dateTimeRange: { start: filter.startTime, end: filter.endTime },
    },
    limit: filter.limit,
    metric: filter.metric,
    selectedCommunities: selectedCommunities?.length
      ? (selectedCommunities.map((c) => c.name) as string[])
      : [],
  }

  const { data, loading, error } = useGetCommunitiesTrendsQuery({
    variables: {
      filter: communityFilter,
    },
  })

  const yAxisDataKey =
    filter.metric === PopularityMetric.Frequency ? 'value' : 'durationSum'

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized
          message={error.toString()}
          imageName={'CommunitiesTrends'}
        />
      )
    }
    console.error(error)
    return <div>Error while loading community trends</div>
  }

  const communitiesRaw = data?.getCommunitiesTrends.communities || []
  const communities = communitiesRaw.map((community) => {
    return {
      clientName: community.clientName,
      communityId: community.communityId,
      communityName: community.communityName,
      community: community.community,
      dailyMetrics: addZeroCountDates(
        community.dailyMetrics,
        filter.metric,
        new Date(filter.startTime),
        new Date(filter.endTime)
      ),
    } as CommunityTrend
  })

  return (
    <ComponentCard
      title="Community Trends"
      result={{ data: communitiesRaw, error, loading }}
      skeletonHeight={400}
    >
      <TimesSeriesLineChart
        data={communities}
        yAxisDataKey={yAxisDataKey}
        tooltipDataKey={'communityName'}
        xAxisDomain={{
          lower: filter.startTime,
          upper: filter.endTime,
        }}
        metric={filter.metric}
        areaChart={areaChart}
        height={height}
        width={width}
      />
    </ComponentCard>
  )
}
