import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Dialog from '@mui/material/Dialog'
import { SEND_FORGOT_EMAIL_NOTIF_TO_ADMIN } from 'graphql/mutation/retrieveEmail'
import { useMutation } from '@apollo/client'

const PREFIX = 'ForgotEmail'

const classes = {
  forgotEmailDiv: `${PREFIX}-forgotEmailDiv`,
  typography: `${PREFIX}-typography`,
  textField: `${PREFIX}-textField`,
  button: `${PREFIX}-button`,
  dialogContent: `${PREFIX}-dialogContent`,
  divModal: `${PREFIX}-divModal`,
  modalButton: `${PREFIX}-modalButton`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.forgotEmailDiv}`]: {
    transform: 'translateY(-400px) !important',
  },

  [`& .${classes.typography}`]: {
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },

  [`& .${classes.textField}`]: {
    width: '100%',
  },

  [`& .${classes.button}`]: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
}))

const StyledDialog = styled(Dialog)(() => ({
  [`&.${classes.dialogContent}`]: {
    width: '400px',
    margin: 'auto',
  },

  [`& .${classes.divModal}`]: {
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },

  [`& .${classes.modalButton}`]: {
    marginTop: '20px',
  },
}))

type HandleForgotEmailFn = () => void
interface ForgotEmailInterface {
  handleForgotEmailClick: HandleForgotEmailFn
}

interface ForgotEmailStateInterface {
  firstName: string
  lastName: string
  backUpEmail: string
  isSent: boolean
  isModalOpen: boolean
  modalMessage: string
}

const forgotEmailInitialState = {
  firstName: '',
  lastName: '',
  backUpEmail: '',
  isSent: false,
  isModalOpen: false,
  modalMessage:
    'Please contact the admin of your company(builder) to check their email so that they can approve your request.',
}

const ForgotEmail = ({
  handleForgotEmailClick,
}: ForgotEmailInterface): JSX.Element => {
  const [state, setState] = useState<ForgotEmailStateInterface>(
    forgotEmailInitialState
  )
  const [sendForgotEmailNotifToAdmin] = useMutation(
    SEND_FORGOT_EMAIL_NOTIF_TO_ADMIN
  )

  /**
   * @description - send email to the client admin of `firstName lastName`
   *              - handles error if server is unreachable. See catch statement.
   *              - handles message if user is not found in database.
   */
  const handleForgotEmailSubmit = (e: React.MouseEvent<unknown>) => {
    e.preventDefault()
    sendForgotEmailNotifToAdmin({
      variables: {
        firstName: state.firstName,
        lastName: state.lastName,
        backUpEmail: state.backUpEmail,
      },
    })
      .then(() => {
        setState({
          ...state,
          isModalOpen: true,
          firstName: '',
          lastName: '',
          backUpEmail: '',
        })
      })
      .catch((error) => {
        console.error('failed to query SEND_FORGOT_EMAIL_NOTIF_TO_ADMIN', error)

        setState({
          ...state,
          modalMessage:
            'failed to notify admin, please contact a rendering house representative',
          isModalOpen: true,
          firstName: '',
          lastName: '',
          backUpEmail: '',
        })
      })
  }

  return (
    <Root className={classes.forgotEmailDiv}>
      <StyledDialog
        aria-labelledby="customized-dialog-title"
        open={state.isModalOpen}
        className={classes.dialogContent}
      >
        <div className={classes.divModal}>
          <h2>Confirmation</h2>
          <Typography gutterBottom>{state.modalMessage}</Typography>
          <Button
            autoFocus
            onClick={(): unknown => setState({ ...state, isModalOpen: false })}
            color="primary"
            variant="contained"
            className={classes.modalButton}
          >
            CLOSE
          </Button>
        </div>
      </StyledDialog>
      <div>
        <Typography
          classes={{
            root: classes.typography,
          }}
          variant="h5"
        >
          Forgot Email
        </Typography>
        <Typography
          classes={{
            root: classes.typography,
          }}
          variant="subtitle1"
        >
          <Link
            href="#"
            onClick={(): unknown => handleForgotEmailClick()}
            underline="hover"
          >
            Go Back
          </Link>
        </Typography>

        <TextField
          autoFocus
          classes={{ root: classes.textField }}
          margin="normal"
          data-testid="firstName"
          id="firstName"
          autoComplete="First Name"
          variant="outlined"
          label="First Name"
          value={state.firstName}
          onChange={(e): void =>
            setState({ ...state, firstName: e.target.value })
          }
        />
        <TextField
          autoFocus
          classes={{ root: classes.textField }}
          margin="normal"
          id="lastName"
          data-testid="lastName"
          autoComplete="Last Name"
          variant="outlined"
          label="Last Name"
          value={state.lastName}
          onChange={(e): void =>
            setState({ ...state, lastName: e.target.value })
          }
        />
        <TextField
          autoFocus
          classes={{ root: classes.textField }}
          margin="normal"
          id="backUpEmail"
          data-testid="backUpEmail"
          autoComplete="Email"
          helperText="Email where we will send your information"
          variant="outlined"
          label="Back Up Email"
          value={state.backUpEmail}
          onChange={(e): void =>
            setState({ ...state, backUpEmail: e.target.value })
          }
        />
        <Button
          data-testid="submit-button"
          classes={{ root: classes.button }}
          variant="contained"
          size="large"
          color="primary"
          onClick={(e): unknown => handleForgotEmailSubmit(e)}
        >
          submit
        </Button>
      </div>
    </Root>
  )
}

export default ForgotEmail
