import { styled, Theme } from '@mui/material/styles'
import TableRow from '@mui/material/TableRow'
import { excludeProps } from 'utils/functions'
import { DEFAULT_RESPONSE_SIZE, ResponsiveTableStyleProps } from '.'

type ResponsiveRowProps = Pick<ResponsiveTableStyleProps, 'responseSize'> & {
  theme: Theme
}
const ResponsiveRow = styled(TableRow, {
  name: 'ResponsiveRow',
  shouldForwardProp: (prop) => excludeProps(prop, 'responseSize'),
})(({ theme, responseSize = DEFAULT_RESPONSE_SIZE }: ResponsiveRowProps) => ({
  [theme.breakpoints.down(responseSize)]: {
    display: 'flex',
  },
  '&:nth-of-type(even)': {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.background.default //'#303030'
        : theme.palette.grey[50], //'#f8f8f8',
  },
}))

export default ResponsiveRow
