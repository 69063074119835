import React from 'react'

import { styled } from '@mui/material/styles'

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
const PREFIX = 'VerticalComparisonTable'

const classes = {
  tableCornerCell: `${PREFIX}-tableCornerCell`,
  tableHeaderRow: `${PREFIX}-tableHeaderRow`,
  cell: `${PREFIX}-cell`,
  headerCell: `${PREFIX}-headerCell`,
  rowHeader: `${PREFIX}-rowHeader`,
  tableRow: `${PREFIX}-tableRow`,
  tableSubRow: `${PREFIX}-tableSubRow`,
  pointer: `${PREFIX}-pointer`,
}

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  [`& .${classes.tableCornerCell}`]: {
    position: 'sticky',
    left: 0,
    right: 0,
    zIndex: 2,
    backgroundColor: theme.palette.primary.main,
  },

  [`& .${classes.tableHeaderRow}`]: {
    backgroundColor: theme.palette.primary.main,
  },

  [`& .${classes.cell}`]: (props: VerticalComparisonTableStyleProps) => ({
    display: 'block',
    textAlign: 'center',
    height: props?.rowHeight || DEFAULT_ROW_HEIGHT,
    lineHeight: '1rem',
    whiteSpace: 'nowrap',
    minWidth: '180px',
  }),

  [`& .${classes.headerCell}`]: {
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    minWidth: '180px',
  },

  [`& .${classes.rowHeader}`]: {
    position: 'sticky',
    left: 0,
    right: 0,
    zIndex: 2,
    backgroundColor: theme.palette.grey[50],
  },

  [`& .${classes.tableRow}`]: (props: VerticalComparisonTableStyleProps) => ({
    display: 'flex',
    '&:nth-of-type(even)': {
      backgroundColor:
        theme.palette.mode === 'dark'
          ? theme.palette.background.default //'#303030'
          : theme.palette.grey[50], //'#f8f8f8',
    },
  }),

  [`& .${classes.tableSubRow}`]: {},

  [`& .${classes.pointer}`]: {
    cursor: 'pointer',
  },
}))

const DEFAULT_ROW_HEIGHT = 'inherit'

interface VerticalComparisonTableStyleProps {
  responseSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | number | undefined
  rowHeight?: number | string
}

interface ColumnHeader {
  id: number
  name: string
}

interface Heading {
  key: string
  heading: string
}

type VerticalComparisonTableProps = {
  data: unknown[]
  tableHeadings: Heading[]
  columnHeaders: ColumnHeader[]
  generateRow: (dataRow: unknown, key: string, index?: number) => JSX.Element
  responseSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | number | undefined
  rowHeight?: number | string
  onRowClick?: (row: unknown) => void
  dense?: boolean
  initialRows?: number
}

/**
 * This table contains pagination and is responsive.
 * @param param - props data
 */
export default function VerticalComparisonTable({
  data,
  tableHeadings,
  columnHeaders,
  generateRow,
  responseSize,
  rowHeight,
  onRowClick,
  dense = false,
}: VerticalComparisonTableProps): JSX.Element {
  const size = dense ? 'small' : 'medium'

  return (
    <StyledTableContainer component={Paper}>
      <Table aria-label="simple table" size={size}>
        <TableHead>
          <TableRow classes={{ root: classes.tableHeaderRow }}>
            <TableCell classes={{ root: classes.tableCornerCell }} />
            {columnHeaders.map(({ id, name }) => (
              <TableCell
                key={`verticalCommparisonHeader${id}-${name}`}
                classes={{ root: classes.headerCell }}
              >
                {name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableHeadings.map(({ key, heading }, headingIndex) => {
            return (
              <TableRow
                key={`${key}-${headingIndex}`}
                className={classes.tableSubRow}
                onClick={(): void => onRowClick && onRowClick(data)}
                style={onRowClick && { cursor: 'pointer' }}
              >
                <TableCell classes={{ root: classes.rowHeader }}>
                  {heading}
                </TableCell>
                {data.map((data, index) => (
                  <TableCell key={`${key}-${index}`}>
                    {generateRow(data, key, index)}
                  </TableCell>
                ))}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </StyledTableContainer>
  )
}
