import React, { useContext, useEffect, useState } from 'react'
import {
  ProspectSignIn,
  Session,
  useSessionsLazyQuery,
} from 'graphql/gen-types'
import ResponsiveTable, {
  ResponsiveCell,
} from 'components/common/tables/ResponsiveTable'

import { formatDateTime } from 'utils/functions'
import { AppStore } from '../../../../../store'
import Skeleton from '@mui/material/Skeleton'

const SESSION_LOGIN_MATCH_RANGE = 30000

interface LoginTimesTableProps {
  prospectSignIns: ProspectSignIn[]
  email: string
}

type LoginWithDuration = {
  timestamp: string
  userAction: string
  source: string
  duration: number
}

export default function LoginTimesTable({
  prospectSignIns,
  email,
}: LoginTimesTableProps): JSX.Element {
  const [logins, setLogins] = useState<LoginWithDuration[]>([])
  const { appState } = useContext(AppStore)

  const [getSessions, { data, loading }] = useSessionsLazyQuery()

  useEffect(() => {
    if (prospectSignIns.length > 0) {
      getSessions({
        variables: {
          filter: {
            baseInfo: {
              clientName: appState?.selectedClient?.altName || '',
              dateTimeRange: {
                start: prospectSignIns[0].timestamp - 86400000, // oldest login timestamp - 1 day in miliseconds to be sure we are getting all the sessions
                end: Date.now(),
              },
            },
            email,
          },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const sessions = data?.sessions
    if (sessions) {
      const getSource = (session: Session) => {
        return (
          prospectSignIns.find((signIn) => {
            return (
              signIn.timestamp > session.startAt - SESSION_LOGIN_MATCH_RANGE ||
              signIn.timestamp < session.startAt + SESSION_LOGIN_MATCH_RANGE
            )
          })?.source || 'truss'
        )
      }

      setLogins(
        [
          ...sessions.map((row) => ({
            timestamp: row.startAt,
            userAction: 'sign-in',
            source: getSource(row),
            duration: row.duration,
          })),
        ].sort((a, b) => {
          if (a.timestamp > b.timestamp) {
            return -1
          } else if (a.timestamp < b.timestamp) {
            return 1
          }
          return 0
        })
      )
    }
  }, [data?.sessions, prospectSignIns])

  // function to format or duration from seconds to hh:mm:ss
  function formattedTime(duration: number) {
    const hours = Math.floor(duration / 60 / 60)
    const minutes = Math.floor(duration / 60) - hours * 60
    const seconds = duration % 60
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
  }

  const columns = [
    <ResponsiveCell responseSize="md" align="center" key={1}>
      <strong>Login Time</strong>
    </ResponsiveCell>,
    <ResponsiveCell responseSize="md" align="center" key={2}>
      <strong>Source</strong>
    </ResponsiveCell>,
    <ResponsiveCell responseSize="md" align="center" key={3}>
      <strong>Duration</strong>
    </ResponsiveCell>,
  ]

  const generateRow = (loginData: LoginWithDuration): JSX.Element => {
    const date = new Date(loginData?.timestamp)
    const formatedDate = formatDateTime(date, 'Pp')
    return (
      <>
        <ResponsiveCell responseSize="md" align="center" key="row-cell-date">
          {formatedDate}
        </ResponsiveCell>
        <ResponsiveCell responseSize="md" align="center" key="row-cell-source">
          {loginData.source === 'truss' ? 'My Home' : 'Sales Kiosk'}
        </ResponsiveCell>
        <ResponsiveCell
          responseSize="md"
          align="center"
          key="row-cell-duration"
        >
          {formattedTime(loginData.duration)}
        </ResponsiveCell>
      </>
    )
  }

  return !loading ? (
    <ResponsiveTable
      data={logins || []}
      columns={columns}
      generateRow={generateRow}
      dense={true}
    />
  ) : (
    <Skeleton variant="rectangular" width={700} height={200} />
  )
}
