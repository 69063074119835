import { styled } from '@mui/material/styles'

const PREFIX = 'Login'

export const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  errorSnackbarContent: `${PREFIX}-errorSnackbarContent`,
  forgot: `${PREFIX}-forgot`,
  imageContainer: `${PREFIX}-imageContainer`,
  loadingLoginBody: `${PREFIX}-loadingLoginBody`,
  login: `${PREFIX}-login`,
  loginBody: `${PREFIX}-loginBody`,
  logoImg: `${PREFIX}-logoImg`,
  textField: `${PREFIX}-textField`,
  typography: `${PREFIX}-typography`,
  passwordDiv: `${PREFIX}-passwordDiv`,
  validEmailButton: `${PREFIX}-validEmailButton`,
}

export const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    backgroundColor: 'white',
    top: 0,
    left: 0,
    position: 'fixed',
    overflow: 'hidden',
    textAlign: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.button}`]: {
    marginTop: '32px',
    width: '100%',
  },

  [`& .${classes.errorSnackbarContent}`]: {
    backgroundColor: theme.palette.error.dark,
  },

  [`& .${classes.forgot}`]: {
    float: 'left',
    fontSize: '15px',
    fontWeight: 500,
    paddingTop: '8px',
  },

  [`& .${classes.imageContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    minHeight: '70px',
    paddingTop: '12px',
  },

  [`& .${classes.loadingLoginBody}`]: {
    opacity: 0.4,
  },

  [`& .${classes.login}`]: {
    border: '1px solid #dadce0',
    borderRadius: '8px',
    display: 'inline-block',
    maxWidth: '320px',
    marginTop: '100px',
    height: '560px',
    overflow: 'hidden',
  },

  [`& .${classes.loginBody}`]: {
    padding: theme.spacing(3),
  },

  [`& .${classes.logoImg}`]: {
    display: 'block',
    margin: 'auto',
    maxWidth: '180px',
  },

  [`& .${classes.textField}`]: {
    width: '100%',
  },

  [`& .${classes.typography}`]: {
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },

  [`& .${classes.passwordDiv}`]: { transform: 'translateY(-250px) !important' },

  [`& .${classes.validEmailButton}`]: {
    borderRadius: '16px',
    justifyContent: 'left',
    marginTop: '3px',
    maxWidth: '100%',
    padding: '3px 12px',
    overflow: 'hidden',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    textTransform: 'none',
    wordBreak: 'break-all',
  },
}))
