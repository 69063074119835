import React, { Dispatch, SetStateAction, useContext, useState } from 'react'
import { useTheme } from '@mui/material/styles'

import useMediaQuery from '@mui/material/useMediaQuery'
import { Agent, Order, OrderBy, Pagination, Prospect } from 'graphql/gen-types'

import DateFnsAdapter from '@date-io/date-fns'
import LiveLeadDetailModal from './LiveLeadDetailModal'

import ResponsiveTable, {
  ResponsiveCell,
} from 'components/common/tables/ResponsiveTable'
import ProspectStatusSelect from 'components/common/custom-inputs/ProspectStatusSelect'
import { AuthContext } from 'auth/AuthContext'
import { TableSortLabelStyled, VisuallyHidden } from './LiveLeads.style'
import TableFooter from '@mui/material/TableFooter'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'

const dateFormatter = (date: string | number | Date): string =>
  dateFns.format(new Date(date), 'M-dd-yyyy h:mm a')

interface HeadCell {
  id: ProspectKeys
  value: string | JSX.Element
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined
  lineHeight?: string
  disabled?: boolean
}

type ProspectModel = Pick<
  Prospect,
  | 'id'
  | 'email'
  | 'name'
  | 'firstName'
  | 'lastName'
  | 'registrationDate'
  | 'phone'
  | 'status'
  | 'preferredContactMethods'
>

type ProspectKeys = keyof ProspectModel

type LiveLeadsTableProps = {
  data: Prospect[]
  agents: Agent[]
  pagination: Pagination
  setPagination: Dispatch<SetStateAction<Pagination>>
  orderBy: OrderBy
  setOrderBy: Dispatch<SetStateAction<OrderBy>>
  dataTotalCount: number
}

const dateFns = new DateFnsAdapter()
/**
 * This table contains sorting, pagination, and is responsive.
 * TODO: There is a lot of sorting done here. We may want to add sorting functionality to the ResponsiveTable component.
 * @param param - props data
 */
function LiveLeadsTable({
  data,
  agents,
  pagination,
  setPagination,
  orderBy,
  setOrderBy,
  dataTotalCount,
}: LiveLeadsTableProps): JSX.Element {
  const { user } = useContext(AuthContext)
  const { roleId } = user

  const [email, setEmail] = useState('')
  const [leadDetailModalOpen, setLeadDetailModalOpen] = useState(false)

  const handleSortClick = (property: ProspectKeys): void => {
    const isAsc = orderBy.sortBy === property && orderBy.order === Order.Asc

    setOrderBy({
      order: isAsc ? Order.Desc : Order.Asc,
      sortBy: property,
    })
  }

  const handleProspectClick = (email: string): void => {
    setEmail(email)
    setLeadDetailModalOpen(true)
  }

  const handleLeadDetailModalClose = (): void => {
    setLeadDetailModalOpen(false)
    setTimeout(() => setEmail(''), 300)
  }

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPagination((prev) => ({
      ...prev,
      offset: 0,
      limit: Number(event.target.value),
    }))
  }

  const handlePageChange = (event: unknown, newPage: number) => {
    setPagination((prev) => ({
      ...prev,
      offset: Number(newPage) * pagination.limit,
    }))
  }

  const tableHeadings: HeadCell[] = [
    { id: 'id', value: 'Row', align: 'center', disabled: true },
    { id: 'name', value: 'Name', align: 'left' },
    { id: 'email', value: 'Email', align: 'left' },
    { id: 'phone', value: 'Phone Number', align: 'left' },
    { id: 'status', value: 'Status', align: 'center', lineHeight: '39px' },
    { id: 'registrationDate', value: 'Registration Date', align: 'center' }, // Registration date can be null
  ]

  const tableHeaders = tableHeadings.map((head, index) => (
    <ProspectTableHeadCell
      head={head}
      key={index}
      index={index}
      active={head.id === orderBy.sortBy}
      onClick={handleSortClick}
      order={orderBy.order}
    />
  ))

  return (
    <>
      <LiveLeadDetailModal
        data={data.find((prospect) => prospect.email === email)}
        open={leadDetailModalOpen}
        onClose={handleLeadDetailModalClose}
        agents={agents}
      />
      <ResponsiveTable
        data={data}
        columns={tableHeaders}
        generateRow={(prospect: Prospect, index?: number): JSX.Element => (
          <ProspectTableRow prospect={prospect} roleId={roleId} index={index} />
        )}
        responseSize={'md'}
        onRowClick={(row: unknown): void =>
          handleProspectClick((row as Prospect)?.email || '')
        }
        dense={false}
        customPagination={
          <TableFooter>
            <TableRow>
              <TablePagination
                count={dataTotalCount}
                page={pagination.offset / pagination.limit}
                rowsPerPage={pagination.limit}
                rowsPerPageOptions={[5, 10, 25]}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </TableRow>
          </TableFooter>
        }
      />
    </>
  )
}

function ProspectTableHeadCell({
  head,
  index,
  active,
  onClick,
  order,
}: {
  head: HeadCell
  index: number
  active: boolean
  onClick: (id: ProspectKeys) => void
  order: Order
}): JSX.Element {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <ResponsiveCell
      responseSize="md"
      align={head.align}
      id={head.id}
      key={index}
    >
      <TableSortLabelStyled
        disabled={head.disabled}
        active={active}
        direction={active ? (order === Order.Asc ? 'asc' : 'desc') : 'asc'}
        onClick={(): void => onClick(head.id)}
        style={head.lineHeight ? { height: 'auto' } : undefined}
      >
        <span
          style={
            md && head.lineHeight ? { lineHeight: head.lineHeight } : undefined
          }
        >
          {head.value}
        </span>

        {active ? (
          <VisuallyHidden>
            {order === Order.Desc ? 'sorted descending' : 'sorted ascending'}
          </VisuallyHidden>
        ) : null}
      </TableSortLabelStyled>
    </ResponsiveCell>
  )
}

function ProspectTableRow({
  prospect,
  roleId,
  index,
}: {
  prospect: Prospect
  roleId: number
  index?: number
}): JSX.Element {
  return (
    <>
      <ResponsiveCell responseSize="md" align="center">
        {index !== undefined ? index + 1 : ''}
      </ResponsiveCell>
      <ResponsiveCell responseSize="md" align="left">
        {prospect?.name || '-'}
      </ResponsiveCell>
      <ResponsiveCell responseSize="md" align="left">
        {prospect?.email}
      </ResponsiveCell>
      <ResponsiveCell responseSize="md" align="center">
        {prospect?.phone || '-'}
      </ResponsiveCell>
      <ResponsiveCell responseSize="md" align="center">
        <ProspectStatusSelect
          disabled={roleId > 4}
          prospectId={prospect.id}
          prospectStatus={prospect.status}
          prospectEmail={prospect?.email}
          hideLabel
        />
      </ResponsiveCell>
      <ResponsiveCell responseSize="md" align="center">
        {dateFormatter(prospect?.registrationDate)}
      </ResponsiveCell>
    </>
  )
}

export default LiveLeadsTable
