import React from 'react'
import { ProspectTopCommunity } from 'graphql/gen-types'

import DateFnsAdapter from '@date-io/date-fns'
import { Maybe } from 'graphql/jsutils/Maybe'

import ResponsiveTable, {
  ResponsiveCell,
} from 'components/common/tables/ResponsiveTable'

const dateFns = new DateFnsAdapter()

function formatDateTime(date: string | number | Date): string {
  return dateFns.format(new Date(date || new Date()), 'haaa MM-dd-yyyy')
}

export default function CommunitiesTable({
  communities,
  showStatistics = false,
  showTimestamps = false,
}: {
  communities: Maybe<ProspectTopCommunity>[] | undefined | null
  showStatistics?: boolean
  showTimestamps?: boolean
}): JSX.Element {
  const columns = [
    <ResponsiveCell responseSize="md" align="center" key={1}>
      <strong>Community</strong>
    </ResponsiveCell>,

    showStatistics ? (
      <ResponsiveCell responseSize="md" align="center" key={2}>
        <strong>Duration</strong>
      </ResponsiveCell>
    ) : null,

    showStatistics ? (
      <ResponsiveCell responseSize="md" align="center" key={3}>
        <strong>Events</strong>
      </ResponsiveCell>
    ) : null,

    showTimestamps ? (
      <ResponsiveCell responseSize="md" align="right" key={4}>
        <strong>Timestamp</strong>
      </ResponsiveCell>
    ) : null,
  ]

  const generateRow = (
    dataRow: ProspectTopCommunity,
    index?: number
  ): JSX.Element => {
    return (
      <>
        <ResponsiveCell
          responseSize="md"
          align="center"
          key={`${dataRow?.communityId}-${index}-1`}
        >
          {dataRow?.communityName}
        </ResponsiveCell>

        {showStatistics ? (
          <ResponsiveCell
            responseSize="md"
            align="center"
            key={`${dataRow?.communityId}-${index}-2`}
          >
            {dataRow?.duration}
          </ResponsiveCell>
        ) : null}

        {showStatistics ? (
          <ResponsiveCell
            responseSize="md"
            align="center"
            key={`${dataRow?.communityId}-${index}-3`}
          >
            {dataRow?.events}
          </ResponsiveCell>
        ) : null}

        {showTimestamps ? (
          <ResponsiveCell
            responseSize="md"
            align="right"
            key={`${dataRow?.communityId}-${index}-4`}
          >
            {formatDateTime(dataRow?.timestamp)}
          </ResponsiveCell>
        ) : null}
      </>
    )
  }

  return (
    <ResponsiveTable
      data={communities ? (communities as ProspectTopCommunity[]) : []}
      columns={columns}
      generateRow={generateRow}
      dense={true}
    />
  )
}
