import React from 'react'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import { ProspectRequestAppointment } from 'graphql/gen-types'
import ResponsiveTable, {
  ResponsiveCell,
} from 'components/common/tables/ResponsiveTable'
import { formatDateTime } from 'utils/functions'

type SGTAppointmentTableProps = {
  appointments: ProspectRequestAppointment[] | undefined | null
}

export default function SGTAppointmentsTable({
  appointments,
}: SGTAppointmentTableProps): JSX.Element {
  const columns = [
    <ResponsiveCell responseSize="md" align="center" key={0}>
      <strong>Date</strong>
    </ResponsiveCell>,
    <ResponsiveCell responseSize="md" align="center" key={2}>
      <strong>Requested Dates</strong>
    </ResponsiveCell>,
    <ResponsiveCell responseSize="md" align="center" key={3}>
      <strong>Plan</strong>
    </ResponsiveCell>,
    <ResponsiveCell responseSize="md" align="center" key={4}>
      <strong>Address</strong>
    </ResponsiveCell>,
    <ResponsiveCell responseSize="md" align="center" key={5}>
      <span style={{ lineHeight: '30px' }}>
        <strong>View</strong>
      </span>
    </ResponsiveCell>,
  ]

  const generateRow = (
    row: ProspectRequestAppointment,
    index?: number
  ): JSX.Element => {
    const lot = row.inventory?.lot

    return (
      <>
        <ResponsiveCell
          responseSize="md"
          align="center"
          key={`${row.inventoryId}-${index}-0`}
        >
          {formatDateTime(row.timestamp, 'MMM do yyyy')}
        </ResponsiveCell>
        <ResponsiveCell
          responseSize="md"
          align="center"
          key={`${row.inventoryId}-${index}-2`}
        >
          {row?.preferredDates
            ?.map((date) => formatDateTime(date, 'ha MMM do yyyy'))
            .join(', ')}
        </ResponsiveCell>
        <ResponsiveCell
          responseSize="md"
          align="center"
          key={`${row.inventoryId}-${index}-3`}
        >
          {`${row.inventory?.plan?.name} ${
            row.inventory?.elevation?.caption
              ? row.inventory?.elevation?.caption
              : ''
          }`}
        </ResponsiveCell>
        <ResponsiveCell
          responseSize="md"
          align="center"
          key={`${row.inventoryId}-${index}-4`}
        >
          {`${lot?.address}${lot?.cityName ? `, ${lot?.cityName}` : ''}${
            lot?.stateCode ? ` ${lot?.stateCode}` : ''
          }`}
        </ResponsiveCell>

        <Tooltip
          title={
            row.inventoryUrl || row.inventory?.mlsUrl
              ? 'Opens new window to Inventory page.'
              : 'Inventory page URL was not found.'
          }
        >
          <ResponsiveCell
            responseSize="md"
            align="center"
            key={`${row.inventoryId}-${index}-5`}
          >
            <Button
              onClick={() =>
                window.open(row.inventoryUrl || row.inventory?.mlsUrl || '')
              }
              disabled={!row.inventoryUrl && !row.inventory?.mlsUrl}
              variant="contained"
              size="small"
            >
              Open
            </Button>
          </ResponsiveCell>
        </Tooltip>
      </>
    )
  }

  return (
    <ResponsiveTable
      data={appointments || []}
      columns={columns}
      generateRow={generateRow}
      dense={true}
    />
  )
}
