import React from 'react'

interface IconProps {
  width?: number
  height?: number
}

function BuyOnlineIcon(props: IconProps): JSX.Element {
  const { width, height } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 58 58"
      height={height || 58}
      width={width || 58}
    >
      <g>
        <g>
          <polygon
            fill="#5E3118"
            points="41.528,5.884 55,19.356 54.472,19.884 58,19.884 58,5.884 		"
          />
          <path
            fill="#5E3118"
            d="M24.174,5.884H7c-3.866,0-7,3.134-7,7s3.134,7,7,7h4.23l-0.528-0.528L24.174,5.884z"
          />
        </g>
        <g>
          <path
            fill="#77442B"
            d="M16.543,8.367c-0.641,0.048-1.298,0.08-1.961,0.104c-0.552,0.02-0.983,0.483-0.963,1.035
			c0.02,0.54,0.463,0.964,0.998,0.964c0.013,0,0.024,0,0.037-0.001c0.688-0.024,1.371-0.059,2.037-0.108
			c0.551-0.041,0.964-0.521,0.923-1.071S17.095,8.324,16.543,8.367z"
          />
          <path
            fill="#77442B"
            d="M10.588,8.569c-0.696,0.02-1.379,0.045-2.034,0.086c-0.552,0.034-0.971,0.509-0.937,1.06
			c0.033,0.53,0.473,0.938,0.997,0.938c0.021,0,0.042-0.001,0.063-0.002c0.634-0.039,1.293-0.064,1.966-0.082
			c0.552-0.016,0.987-0.475,0.973-1.027S11.124,8.595,10.588,8.569z"
          />
          <path
            fill="#77442B"
            d="M4.359,9.319c-0.907,0.297-1.602,0.694-2.12,1.213c-0.391,0.391-0.391,1.023,0,1.414
			c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293c0.288-0.287,0.734-0.532,1.329-0.727
			c0.524-0.172,0.811-0.736,0.639-1.262C5.45,9.434,4.889,9.146,4.359,9.319z"
          />
          <path
            fill="#77442B"
            d="M50,8.884h-2c-0.553,0-1,0.447-1,1s0.447,1,1,1h2c0.553,0,1-0.447,1-1S50.553,8.884,50,8.884z"
          />
          <path
            fill="#77442B"
            d="M56,8.884h-2c-0.553,0-1,0.447-1,1s0.447,1,1,1h2c0.553,0,1-0.447,1-1S56.553,8.884,56,8.884z"
          />
        </g>
        <path
          fill="#EAEAD6"
          d="M55.556,19.884L35.8,0.156c-0.054-0.054-0.142-0.054-0.196,0L15.849,19.884H55.556z"
        />
        <path
          fill="#BFBDA3"
          d="M15.876,19.884L32.851,2.909l-2.753-2.753c-0.054-0.054-0.142-0.054-0.196,0L10.147,19.884H15.876z"
        />
        <path
          fill="#6D8C60"
          d="M47.331,20.509l0.309-0.309L35.759,8.335c-0.032-0.032-0.083-0.032-0.115,0L23.769,20.2l0.309,0.309
		H47.331z"
        />
        <g>
          <path
            fill="#77442B"
            d="M0,12.884v38.848c0,3.398,2.754,6.152,6.152,6.152H58v-38H7C3.134,19.884,0,16.75,0,12.884z"
          />
        </g>
        <path
          fill="#91553D"
          d="M52,24.884h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S52.553,24.884,52,24.884z
		 M46,24.884h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S46.553,24.884,46,24.884z M40,24.884h-2
		c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S40.553,24.884,40,24.884z M34,24.884h-2c-0.553,0-1-0.447-1-1
		s0.447-1,1-1h2c0.553,0,1,0.447,1,1S34.553,24.884,34,24.884z M28,24.884h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2
		c0.553,0,1,0.447,1,1S28.553,24.884,28,24.884z M22,24.884h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1
		S22.553,24.884,22,24.884z M16,24.884h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S16.553,24.884,16,24.884z
		 M10,24.884H8c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S10.553,24.884,10,24.884z M4.091,24.254
		c-0.139,0-0.28-0.029-0.415-0.091c-0.693-0.317-1.337-0.731-1.914-1.231C1.345,22.569,1.3,21.937,1.661,21.52
		c0.363-0.417,0.994-0.462,1.411-0.101c0.433,0.376,0.916,0.687,1.436,0.925c0.502,0.229,0.723,0.823,0.493,1.325
		C4.833,24.037,4.471,24.254,4.091,24.254z"
        />
        <path
          fill="#91553D"
          d="M52,54.884h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S52.553,54.884,52,54.884z
		 M46,54.884h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S46.553,54.884,46,54.884z M40,54.884h-2
		c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S40.553,54.884,40,54.884z M34,54.884h-2c-0.553,0-1-0.447-1-1
		s0.447-1,1-1h2c0.553,0,1,0.447,1,1S34.553,54.884,34,54.884z M28,54.884h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2
		c0.553,0,1,0.447,1,1S28.553,54.884,28,54.884z M22,54.884h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1
		S22.553,54.884,22,54.884z M16,54.884h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S16.553,54.884,16,54.884z
		 M10,54.884H8c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S10.553,54.884,10,54.884z M4.091,54.254
		c-0.139,0-0.28-0.029-0.415-0.091c-0.693-0.317-1.337-0.731-1.914-1.231C1.345,52.569,1.3,51.937,1.661,51.52
		c0.363-0.417,0.994-0.462,1.411-0.101c0.433,0.376,0.916,0.687,1.436,0.925c0.502,0.229,0.723,0.823,0.493,1.325
		C4.833,54.037,4.471,54.254,4.091,54.254z"
        />
        <path
          fill="#6D3D25"
          d="M58,45.051H46c-3.314,0-6-2.686-6-6v0c0-3.314,2.686-6,6-6h12V45.051z"
        />
        <path
          fill="#5E3118"
          d="M58,43.884H46c-3.314,0-6-2.686-6-6v0c0-3.314,2.686-6,6-6h12V43.884z"
        />
        <circle fill="#E4C05C" cx="47" cy="37.884" r="3" />
      </g>
    </svg>
  )
}

export default BuyOnlineIcon
