import React, { useState, useContext } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { AppStore } from 'store'
import { AppActionType } from 'store/reducers'
import {
  Elevation,
  usePlanQuery,
  CommonQueryFilter,
  PopularityMetric,
} from 'graphql/gen-types'

import FilterBar from 'components/common/filter-bar/FilterBar'
import TrussPopularColors from 'components/analytics/popular-colors/TrussPopularColors'
import PopularCommunities, {
  POPULAR_COMMUNITIES_DISPLAY_MAP,
  POPULAR_COMMUNITIES_DISPLAY_LIST,
} from 'components/analytics/popular-communities/PopularCommunities'
import PopularFloorplans from 'components/analytics/popular-floorplans/PopularFloorplans'

import CommunitiesTrends from 'components/analytics/communities-trends/CommunitiesTrends'
import TrussElevationTrends from 'components/analytics/elevation-trends/TrussElevationTrends'
import TrussFloorplanTrends from 'components/analytics/floorplan-trends/TrussFloorplanTrends'
import PopularityMetricSelect from 'components/common/custom-inputs/PopularityMetricSelect'
import { AnalyticsStore } from 'store/analyticsStore'
import InfiniteScroll from 'components/common/layout/InfiniteScroll'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import TrussPopularMaterialColors from 'components/analytics/popular-colors/TrussPopularMaterialColors'
import LeadsByElevation from 'components/leads/by-elevation/LeadsByElevation'
import { classes, Root } from './Plan.styles'

function useRouteQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search)
}

const initialElevationsState: Elevation[] = []
const initialElevationState: Elevation | null = null

function Plans(): JSX.Element {
  const { clientName } = useParams()
  const query = useRouteQuery()
  const planId = Number(query.get('planId'))
  const { appState } = useContext(AppStore)
  const { analyticsState, dispatch } = useContext(AnalyticsStore)
  const [planName, setPlanName] = useState('')

  const [elevations, setElevations] = useState(initialElevationsState)
  const [selectedElevation, setSelectedElevation] = useState(
    initialElevationState
  )
  const [
    selectedPopularityMetric,
    setSelectedPopularityMetric,
  ] = useState<PopularityMetric>(PopularityMetric.Frequency)

  const { selectedClient } = appState
  const { startDate, endDate } = analyticsState
  const chartContainerHeight = 600

  const planQuery = usePlanQuery({
    variables: {
      clientName,
      planId,
    },
  })

  if (planQuery.loading) {
    return <div>loading...</div>
  }

  if (planQuery.error) {
    if (verifyUserAuthError(planQuery.error.toString())) {
      return (
        <Unauthorized message={planQuery.error.toString()} imageName={'Plan'} />
      )
    }
    return (
      <div>
        Error ocurred while loading plan info. {planQuery.error.toString()}
      </div>
    )
  }

  if (
    planQuery.data &&
    planQuery.data.plan?.name &&
    planName !== planQuery.data.plan.name
  ) {
    const distinctElevations = combineMirrorElevations(
      planQuery.data.plan.elevations as Elevation[]
    )
    setElevations(distinctElevations)
    setPlanName(planQuery.data.plan.name)
    dispatch({
      type: AppActionType.SET_SELECTED_TABS,
      payload: ['Plan', planQuery.data.plan.name],
    })
  }

  const baseCommonFilter: CommonQueryFilter = {
    // query
    startTime: startDate,
    endTime: endDate,
    metric: selectedPopularityMetric,
    limit: 5,
    // fields
    clientName: selectedClient?.altName || '',
    planId: planId ? [planId] : undefined,
    // Include both elevations in filter to fetch results for default and mirror.
    elevationIdCollection: selectedElevation
      ? [selectedElevation.id, selectedElevation.mirrorElevationId || 0]
      : undefined,
  }

  // each section is rendered when scrolled into view
  // this array contains the component to be rendered for each section
  const sectionComponents = [
    // SECTION ONE - Communities
    <>
      <Grid container className={classes.componentContainer} spacing={4}>
        <Grid item xs={12}>
          <LeadsByElevation filter={baseCommonFilter} />
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <PopularCommunities
            filter={baseCommonFilter}
            planId={planId}
            elevation={selectedElevation}
            mode={POPULAR_COMMUNITIES_DISPLAY_MAP}
          />
        </Grid>
        <Grid item xs={12} md={10} lg={6}>
          <PopularCommunities
            filter={baseCommonFilter}
            planId={planId}
            elevation={selectedElevation}
            mode={POPULAR_COMMUNITIES_DISPLAY_LIST}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <CommunitiesTrends filter={baseCommonFilter} />
        </Grid>
      </Grid>
    </>,

    // SECTION TWO - Elevations
    <>
      <Grid container className={classes.componentContainer} spacing={4}>
        <Grid item xs={12} md={12} lg={10}>
          <TrussElevationTrends filter={baseCommonFilter} />
        </Grid>
      </Grid>
    </>,

    // SECTION THREE - Floorplans
    <>
      <Grid container className={classes.componentContainer} spacing={4}>
        <Grid item xs={12} md={12} lg={6}>
          <PopularFloorplans filter={baseCommonFilter} />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TrussFloorplanTrends filter={baseCommonFilter} />
        </Grid>
      </Grid>
    </>,

    // SECTION FIVE - Colors
    <>
      <Grid
        container
        className={classes.componentContainer}
        spacing={4}
        justifyContent="center"
      >
        <Grid item xs={12} md={12} lg={6}>
          <TrussPopularColors
            filter={baseCommonFilter}
            client={selectedClient}
            containerHeight={chartContainerHeight}
            planId={planId}
            elevation={selectedElevation}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TrussPopularMaterialColors
            filter={{ ...baseCommonFilter, limit: 5000 }}
          />
        </Grid>
      </Grid>
    </>,
  ]

  return (
    <Root>
      <FilterBar
        customFilters={
          <React.Fragment>
            <PopularityMetricSelect
              metric={selectedPopularityMetric}
              setMetric={setSelectedPopularityMetric}
              defaultAvailableMetrics={[
                PopularityMetric.Frequency,
                PopularityMetric.Duration,
              ]}
            />
          </React.Fragment>
        }
        elevations={elevations}
        planName={planName}
        onElevationSelectChange={(e: unknown, val): void =>
          setSelectedElevation(val)
        }
      />
      <InfiniteScroll sectionComponents={sectionComponents} />
    </Root>
  )
}

function combineMirrorElevations(elevations: Elevation[]): Elevation[] {
  const excludeMirrors: number[] = []
  const elevs: Elevation[] = []
  elevations?.forEach((elev) => {
    // current is not already in exlcude mirror array
    const isMirror = excludeMirrors.includes(elev.id)
    const mirrorId = elev?.mirrorElevationId
    mirrorId && excludeMirrors.push(mirrorId)

    if (!isMirror) {
      const mirrorElev = elevations.find((elev) => elev.id === mirrorId)
      // Edit the internal name of the Elevation to include both
      const combinedElevation: Elevation = {
        ...elev,
        caption:
          // This regex removes the left/right word such as 'Caption Left' or 'Caption Right' into 'Caption'.
          // Some clients, WCI in particular, have this naming and this resolves its.
          elev.caption?.replace(/ Left$| Right$/i, '') ||
          mirrorElev?.caption ||
          'Missing Caption',
        internalName: mirrorElev
          ? `${elev.internalName} / ${mirrorElev.internalName}`
          : elev.internalName,
      }
      elevs.push(combinedElevation)
    }
  })

  return elevs
}

export default Plans
