import React, { useContext, useState } from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActionArea from '@mui/material/CardActionArea'
import CardActions from '@mui/material/CardActions'
import Typography from '@mui/material/Typography'
import { useMutation } from '@apollo/client'
import { useAtom } from 'jotai'
import { snackbarConfigAtom } from 'store/atoms'
import Button from '@mui/material/Button'
import { Navigate, useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import {
  ANALYTICS_APP,
  MARKETING_APP,
  LEADS_APP,
  BUY_ONLINE_APP,
} from '../constants'
import { AppStore } from '../store'
import { AppActionType } from '../store/reducers'
import hydrationStore from 'store/HydrationStore'
import { userHasSubscriptionAccess } from 'utils/authorizationHelpers'
import { ColonnadeSubscriptionLevelsEnum } from '../graphql/gen-types'
import { classes, Root, DialogRoot } from './AppSelect.styles'
import { SEND_INSIGHTS_ONLINE_RESERVATION } from '../graphql/mutation/sendInsightsOnlineReservationInquiry'
import { SEND_INSIGHTS_MARKETING_AUTOMATION } from 'graphql/mutation/sendInsightsMarketingAutomationInquiry'
import { AuthContext } from 'auth/AuthContext'
import { isSalesAdmin } from 'utils/authorizationHelpers'

function AppSelect(): JSX.Element {
  const navigate = useNavigate()
  const { appState, dispatch } = useContext(AppStore)
  const { user } = useContext(AuthContext)
  const { selectedClient } = appState
  const hydratedToken = hydrationStore.token || ''
  const salesAdmin = isSalesAdmin(user.roleId)
  const [snackbarConfig, setSnackbarConfig] = useAtom(snackbarConfigAtom)
  const [sendInsightsOnlineReservationInquiry] = useMutation(
    SEND_INSIGHTS_ONLINE_RESERVATION
  )
  const [sendInsightsMarketingAutomationInquiry] = useMutation(
    SEND_INSIGHTS_MARKETING_AUTOMATION
  )
  const showSnackBar = (success: boolean) => {
    setSnackbarConfig({
      ...snackbarConfig,
      autoHideDuration: 4000,
      open: true,
      message: success
        ? 'Inquiry successfuly sent'
        : 'Something went wrong, please try again later.',
      severity: success ? 'success' : 'error',
    })
  }

  const [isBuyOnlineDialogOpen, setBuyOnlineDialogOpen] = useState(false)
  const [
    isMarketingAutomationDialogOpen,
    setMarketingAutomationDialogOpen,
  ] = useState(false)
  const proSubscribed = userHasSubscriptionAccess([
    ColonnadeSubscriptionLevelsEnum.Pro,
  ])
  const enterpriseSubscribed = userHasSubscriptionAccess([
    ColonnadeSubscriptionLevelsEnum.Enterprise,
  ])
  const marketingAutomationEnabled: boolean =
    enterpriseSubscribed || proSubscribed
  const buyOnlineEnabled: boolean = enterpriseSubscribed

  if (!hydratedToken) {
    return <Navigate to="/login" />
  }

  const openBuyOnlineDialog = (): void => {
    setBuyOnlineDialogOpen(true)
  }

  const closeBuyOnlineDialog = (): void => {
    setBuyOnlineDialogOpen(false)
  }

  const openMarketingAutomationDialog = (): void => {
    setMarketingAutomationDialogOpen(true)
  }

  const closeMarketingAutomationDialog = (): void => {
    setMarketingAutomationDialogOpen(false)
  }

  const handleClick = (appType: string): void => {
    const clientName = selectedClient?.altName
    let route = ''
    switch (appType) {
      case ANALYTICS_APP:
        route = `/analytics/client/${clientName}/overview`
        break
      case LEADS_APP:
        route = `/leads/client/${clientName}/overview`
        break
      case MARKETING_APP:
        route = `/targeted-marketing/client/${clientName}/email-campaign`
        break
      case BUY_ONLINE_APP:
        route = `/reserve-online/client/${clientName}/integration`
        break
      default:
        break
    }
    dispatch({
      type: AppActionType.SET_SELECTED_APP,
      payload: appType,
    })
    navigate(route)
  }

  const handleBuyOnlineClick = (appType: string): void => {
    if (buyOnlineEnabled) {
      handleClick(appType)
    } else {
      openBuyOnlineDialog()
    }
  }

  const buyOnlineInterestedButton = (): JSX.Element => {
    return (
      <Button
        size="small"
        color="primary"
        variant="contained"
        onClick={openBuyOnlineDialog}
      >
        Contact Us
      </Button>
    )
  }

  const handleMarketingAutomationClick = (appType: string): void => {
    if (marketingAutomationEnabled) {
      handleClick(appType)
    } else {
      openMarketingAutomationDialog()
    }
  }

  const marketingAutomationInterestedButton = (): JSX.Element => {
    return (
      <Button
        size="small"
        color="primary"
        variant="contained"
        onClick={openMarketingAutomationDialog}
      >
        Contact Us
      </Button>
    )
  }

  const launchButton = (clickedApp: string): JSX.Element => {
    return (
      <Button
        size="small"
        color="primary"
        variant="contained"
        onClick={(): void => handleClick(clickedApp)}
      >
        Launch
      </Button>
    )
  }

  const disabledButton = (): JSX.Element => {
    return (
      <Button size="small" color="primary" variant="outlined" disabled>
        Launch
      </Button>
    )
  }

  const handleBuyOnlineInterestEmail = async () => {
    try {
      await sendInsightsOnlineReservationInquiry({
        variables: {
          clientName: appState.selectedClient?.altName,
          userEmail: user.userEmail,
          userPhone: appState.selectedClient?.phone,
        },
      })
      showSnackBar(true)
    } catch (error) {
      console.error('failed to SEND_INSIGHTS_ONLINE_RESERVATION', error)
    }
  }

  const handleMarketingInterestEmail = async () => {
    try {
      await sendInsightsMarketingAutomationInquiry({
        variables: {
          clientName: appState.selectedClient?.altName,
          userEmail: user.userEmail,
          userPhone: appState.selectedClient?.phone,
        },
      })
      showSnackBar(true)
    } catch (error) {
      console.error('failed to SEND_INSIGHTS_MARKETING_AUTOMATION', error)
    }
  }

  return (
    <Root className={classes.root}>
      <Grid container spacing={2} className={classes.gridRoot}>
        <Grid item sm={6} xs={12} className={classes.gridItem}>
          <Card className={classes.card}>
            <CardActionArea onClick={(): void => handleClick(LEADS_APP)}>
              <CardMedia
                classes={{ root: classes.media }}
                component="img"
                alt="Leads Insights"
                image={`${process.env.PUBLIC_URL}/images/filter.svg`}
                title="Leads Insights"
              />
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5" component="h2">
                  Leads Insights
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  View and manage personalized journeys of leads generated from
                  omni-channels; MyHome App, MyCommunity App and Marketplace
                  Portal.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions className={classes.cardActions}>
              {launchButton(LEADS_APP)}
            </CardActions>
          </Card>
        </Grid>
        <Grid item sm={6} xs={12} className={classes.gridItem}>
          <Card className={classes.card}>
            <CardActionArea
              onClick={(): void => handleClick(ANALYTICS_APP)}
              disabled={salesAdmin}
              disableRipple={salesAdmin}
            >
              <CardMedia
                classes={{ root: classes.media }}
                component="img"
                alt="Analytics Insights"
                image={`${process.env.PUBLIC_URL}/images/stats.svg`}
                title="Analytics Insights"
              />
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5" component="h2">
                  Analytics Insights
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  View product analytics, lead analytics, and shopping data
                  including popularity indices, trends, and industry insights.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions className={classes.cardActions}>
              {salesAdmin ? disabledButton() : launchButton(ANALYTICS_APP)}
            </CardActions>
          </Card>
        </Grid>
        <Grid item sm={6} xs={12} className={classes.gridItem}>
          <Card className={classes.card}>
            <CardActionArea
              disabled={!marketingAutomationEnabled || salesAdmin}
              disableRipple={!marketingAutomationEnabled || salesAdmin}
              onClick={(): void =>
                handleMarketingAutomationClick(MARKETING_APP)
              }
            >
              <CardMedia
                classes={{
                  root: marketingAutomationEnabled
                    ? clsx(classes.media)
                    : clsx(classes.media, classes.mediaDisabled),
                }}
                component="img"
                alt="Targeted Marketing"
                image={`${process.env.PUBLIC_URL}/images/megaphone.svg`}
                title="Targeted Marketing"
              />
              <CardContent className={classes.cardContent}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h2"
                  color={
                    marketingAutomationEnabled ? 'textPrimary' : 'textSecondary'
                  }
                >
                  Targeted Marketing
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Automating targeted marketing, matching products to people.
                  Create, automate, and measure campaigns across channel.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions className={classes.cardActions}>
              {!marketingAutomationEnabled
                ? marketingAutomationInterestedButton()
                : ''}
              {marketingAutomationEnabled && !salesAdmin
                ? launchButton(MARKETING_APP)
                : disabledButton()}
            </CardActions>
          </Card>
        </Grid>
        <Grid item sm={6} xs={12} className={classes.gridItem}>
          <Card className={classes.card}>
            <CardActionArea
              onClick={(): void => handleBuyOnlineClick(BUY_ONLINE_APP)}
              disabled={!buyOnlineEnabled || salesAdmin}
              disableRipple={!buyOnlineEnabled || salesAdmin}
            >
              <CardMedia
                classes={{
                  root: buyOnlineEnabled
                    ? clsx(classes.media)
                    : clsx(classes.media, classes.mediaDisabled),
                }}
                component="img"
                alt="Reserve Online"
                image={`${process.env.PUBLIC_URL}/images/wallet.svg`}
                title="Reserve Online"
              />
              <CardContent className={classes.cardContent}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h2"
                  color={buyOnlineEnabled ? 'textPrimary' : 'textSecondary'}
                >
                  Reserve Online
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Set-up, list of reservations, analytics and all needed
                  features for Reserve Online feature.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions className={classes.cardActions}>
              {!buyOnlineEnabled ? buyOnlineInterestedButton() : ''}
              {buyOnlineEnabled && !salesAdmin
                ? launchButton(BUY_ONLINE_APP)
                : disabledButton()}
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      {((!buyOnlineEnabled && isBuyOnlineDialogOpen) ||
        (!marketingAutomationEnabled && isMarketingAutomationDialogOpen)) && (
        <DialogRoot className={classes.dialogHolder}>
          <Card className={classes.dialogContent} raised>
            {isBuyOnlineDialogOpen && (
              <CardContent>
                <Typography variant="h5" component="h2" gutterBottom>
                  Interested in Reserve Online?
                </Typography>
                <Typography variant="body1" component="p" gutterBottom>
                  Our reserve online feature allows for buyers to go under
                  contract while using the app. Key features include:
                </Typography>
                <Typography
                  variant="body1"
                  component="ul"
                  className={classes.modalList}
                >
                  <li>
                    Custom contracts dynamically generated based on customer
                    selections
                  </li>
                  <li>Ability for buyers execute contracts via e signature</li>
                  <li>Collection of deposits via Stripe</li>
                  <li>Contracts for inventory or spec homes</li>
                  <li>Ability to receive multiple offers</li>
                </Typography>
              </CardContent>
            )}
            {isMarketingAutomationDialogOpen && (
              <CardContent>
                <Typography variant="h5" component="h2" gutterBottom>
                  Interested in Targeted Marketing?
                </Typography>
                <Typography variant="body1" component="p" gutterBottom>
                  Our targeted marketing feature allows you to target campaigns
                  to the right shoppers, with the right message, at the right
                  time. Key features include:
                </Typography>
                <Typography
                  variant="body1"
                  component="ul"
                  className={classes.modalList}
                >
                  <li>Find a cohort of leads by shared interests</li>
                  <li>
                    Create custom Email Campaigns tailored to specific cohorts
                  </li>
                  <li>Share your Email Campaigns with other users</li>
                </Typography>
              </CardContent>
            )}
            <CardActions className={classes.cardActions}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={(e): Promise<void> | void =>
                  isBuyOnlineDialogOpen
                    ? closeBuyOnlineDialog()
                    : closeMarketingAutomationDialog()
                }
              >
                Close
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={
                  isBuyOnlineDialogOpen
                    ? (e): Promise<void> | void => {
                        closeBuyOnlineDialog()
                        handleBuyOnlineInterestEmail()
                      }
                    : (e): Promise<void> | void => {
                        closeMarketingAutomationDialog()
                        handleMarketingInterestEmail()
                      }
                }
              >
                Send Inquiry
              </Button>
            </CardActions>
          </Card>
        </DialogRoot>
      )}
    </Root>
  )
}

export default AppSelect
