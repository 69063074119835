import React, { useState, useContext } from 'react'
import Grid from '@mui/material/Grid'
import FilterBar from 'components/common/filter-bar/FilterBar'
import InfiniteScroll from 'components/common/layout/InfiniteScroll'
import SalesCenterSelect from 'components/analytics/sales-center/SalesCenterSelect'
import UsersLogins from 'components/analytics/users-logins/UsersLogins'
import SalesCenterUsageSummary from 'components/analytics/sales-center/SalesCenterUsageSummary'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch/Switch'
import { AppStore } from 'store'
import {
  ColonnadeSubscriptionLevelsEnum,
  CommonQueryFilter,
  PopularityMetric,
} from 'graphql/gen-types'
import { AnalyticsStore } from 'store/analyticsStore'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import MobileFilterBar from '../../components/common/filter-bar/MobileFilterBar'
import { Navigate } from 'react-router-dom'
import { STARTER_INVALID_FEATURE_MESSAGE } from '../../constants'
import { userHasSubscriptionAccess } from 'utils/authorizationHelpers'
import { classes, Root } from './SalesCenter.styles'
function SalesCenter(): JSX.Element {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down(900))
  const [isAreaChart, setIsAreaChart] = useState(true)
  const { appState } = useContext(AppStore)
  const { analyticsState } = useContext(AnalyticsStore)
  const { selectedClient } = appState
  const { startDate, endDate } = analyticsState
  const [selectedPopularityMetric] = useState<PopularityMetric>(
    PopularityMetric.Frequency
  )

  const PRO_OR_ENTERPRISE_SUBSCRIBED = userHasSubscriptionAccess([
    ColonnadeSubscriptionLevelsEnum.Pro,
    ColonnadeSubscriptionLevelsEnum.Enterprise,
  ])

  if (!PRO_OR_ENTERPRISE_SUBSCRIBED) {
    const selectedClientName = selectedClient ? selectedClient.altName : ''
    return (
      <Navigate
        to={`/analytics/client/${selectedClientName}/overview?subscriptionLevelErrorMessage=${STARTER_INVALID_FEATURE_MESSAGE}`}
      />
    )
  }

  const baseCommonFilter: CommonQueryFilter = {
    startTime: startDate,
    endTime: endDate,
    limit: 5,
    metric: selectedPopularityMetric,
    clientName: selectedClient?.altName || '',
  }

  const handleIsAreaChange = (): void => {
    setIsAreaChart(!isAreaChart)
  }

  const customFilters = (
    <div className={classes.filters}>
      <SalesCenterSelect />
      {!sm && (
        <>
          <Switch
            checked={isAreaChart}
            onChange={handleIsAreaChange}
            name="chartSwitch"
          />
          <Typography>Area Chart</Typography>
        </>
      )}
    </div>
  )

  const sectionComponents = [
    <Root>
      <Grid container className={classes.componentContainer} spacing={2}>
        <Grid item xs={12}>
          <SalesCenterUsageSummary />
        </Grid>
      </Grid>
    </Root>,
    <React.Fragment>
      <Grid container className={classes.componentContainer} spacing={2}>
        <Grid item xs={12} md={9} lg={6}>
          <UsersLogins filter={baseCommonFilter} containerHeight={500} />
        </Grid>
      </Grid>
    </React.Fragment>,
  ]

  return (
    <Root>
      <Grid container>
        {sm ? (
          <MobileFilterBar filters={() => customFilters} />
        ) : (
          <FilterBar customFilters={customFilters} />
        )}
      </Grid>
      {sm && (
        <Grid container justifyContent={'flex-end'}>
          <Grid item className={classes.mobileSwitch}>
            <Switch
              checked={isAreaChart}
              onChange={handleIsAreaChange}
              name="chartSwitch"
            />
            <Typography>Area Chart</Typography>
          </Grid>
        </Grid>
      )}
      <Grid container>
        <InfiniteScroll
          sectionComponents={sectionComponents}
          rootless={false}
        />
      </Grid>
    </Root>
  )
}

export default SalesCenter
