import React from 'react'

import Grid from '@mui/material/Grid'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import DisplaySelections from './DisplaySelections'
import { PlanProduct } from '../SelectProductModal'
import { Lot } from 'graphql/gen-types'
import { PaperWithPadding } from '../SelectProductModal'
interface SelectedProductDetailsProps {
  selectedPlans: PlanProduct[]
  inventories: Lot[]
  bed?: number
  bath?: number
  size?: number
  cost?: number
  exactMatch: boolean
  autoSelect: boolean
  onBedChange: (bed: number | undefined) => void
  onBathChange: (bath: number | undefined) => void
  onSizeChange: (size: number | undefined) => void
  onCostChange: (cost: number | undefined) => void
  onExactMatchChange: (match: boolean) => void
  onAutoSelectChange: (autoSelect: boolean) => void
}
export default function SelectedProductDetails({
  selectedPlans,
  inventories,
  bed,
  bath,
  size,
  cost,
  exactMatch,
  autoSelect,
  onBedChange,
  onBathChange,
  onSizeChange,
  onCostChange,
  onExactMatchChange,
  onAutoSelectChange,
}: SelectedProductDetailsProps): JSX.Element {
  const handleBedChange = (bed: string) => {
    const num = parseInt(bed)
    const newBed = isNaN(num) ? undefined : num
    onBedChange(newBed)
  }

  const handleBathChange = (bath: string) => {
    const num = parseFloat(bath)
    const newBath = isNaN(num) ? undefined : num
    onBathChange(newBath)
  }

  const handleSizeChange = (size: string) => {
    const num = parseInt(size)
    const newSize = isNaN(num) ? undefined : num
    onSizeChange(newSize)
  }

  const handleCostChange = (cost: string) => {
    const num = parseInt(cost)
    const newCost = isNaN(num) ? undefined : num
    onCostChange(newCost)
  }

  const productNames = selectedPlans.map((product) => {
    const plan = product.plan
    const elevs = product.elevations

    const name = `${plan.displayName || plan.name}`
    let elevations = ''
    // If selected all, display string "All"
    if (elevs.length === plan.elevations?.length) {
      elevations = ' (All)'
    } else if (elevs.length) {
      elevations = ` (${elevs.map((e) => e.caption).join(', ')})`
    }
    return `${name}${elevations}`
  })

  const inventoryNames = inventories.map((lot) => {
    const plan = lot.inventory?.plan
    const elev = lot.inventory?.elevation

    if (!plan || !elev) {
      return 'No Inventory Plan'
    }

    const name = `${plan.displayName || plan.name}`
    const elevation = elev.caption || ''

    return `${name} (${elevation})`
  })

  const displayNames = [...productNames, ...inventoryNames]
  const disableInputs = exactMatch || autoSelect
  return (
    <PaperWithPadding>
      <Grid container justifyContent="center" spacing={3}>
        <Typography variant="h5" color="primary">
          Selection Details
        </Typography>

        <Grid item xs={12}>
          <DisplaySelections selectionNames={displayNames} />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <FormControl>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={exactMatch}
                      onChange={() => onExactMatchChange(!exactMatch)}
                    />
                  }
                  label={<strong>Exact Match</strong>}
                ></FormControlLabel>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Similar Plans</Typography>
          <Typography variant="body2">
            {exactMatch
              ? 'Disable "Exact Match" to select similar plans.'
              : 'Include leads who browsed similar plans.'}
          </Typography>
          <Grid item xs={12}>
            <Box p={2} style={{ paddingRight: 0 }}>
              <FormControl>
                <RadioGroup>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={autoSelect}
                        onChange={() => onAutoSelectChange(!autoSelect)}
                        disabled={exactMatch}
                      />
                    }
                    label={<strong>A.I. Similar Plans*</strong>}
                  ></FormControlLabel>
                </RadioGroup>
                * Use our Artificial Intelligence (A.I.) engine to include
                similar plans automatically.
                <RadioGroup>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={!autoSelect}
                        onChange={() => onAutoSelectChange(!autoSelect)}
                        disabled={exactMatch}
                      />
                    }
                    label={<strong>Manual Select</strong>}
                  ></FormControlLabel>
                </RadioGroup>
                Apply +/- values to each selected plan to broaden the range of
                selection.
              </FormControl>
            </Box>
          </Grid>

          <Grid
            container
            spacing={2}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12}>
              <TextField
                variant="standard"
                size="small"
                type="number"
                label="+/- Price"
                disabled={disableInputs}
                value={cost !== undefined ? cost : ''}
                onChange={(e) => handleCostChange(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="standard"
                size="small"
                type="number"
                label="+/- Size"
                disabled={disableInputs}
                value={size !== undefined ? size : ''}
                onChange={(e) => handleSizeChange(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                size="small"
                type="number"
                label="+/- Bed"
                disabled={disableInputs}
                value={bed !== undefined ? bed : ''}
                onChange={(e) => handleBedChange(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                size="small"
                type="number"
                label="+/- Bath"
                disabled={disableInputs}
                value={bath !== undefined ? bath : ''}
                onChange={(e) => handleBathChange(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item xs={12}>
          <Grid container justify="flex-start">
            <Grid item xs={12}>
              <Typography variant="h6">Select Features</Typography>
              <Typography variant="body2">
                * Include leads who browsed these features.
              </Typography>
              <Grid container>
                <Grid item xs={12}>
                  <Paper>
                    <Box height={300} minWidth={200}>
                      Floorplans
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </PaperWithPadding>
  )
}
