import { styled } from '@mui/material/styles'
import { MAIN_APP_BAR_HEIGHT } from '../../../constants'
import { FILTER_BAR_HEIGHT } from '../filter-bar/FilterBar.styles'
const headerHeight = MAIN_APP_BAR_HEIGHT + FILTER_BAR_HEIGHT

const PREFIX = 'InfiniteScroll'

export const classes = {
  root: `${PREFIX}-root`,
  section: `${PREFIX}-section`,
}

export const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',
    position: 'relative',
    width: '100%',
    height: `calc(100vh - ${headerHeight}px)`,
  },

  [`& .${classes.section}`]: {
    width: '100%',
  },
}))
