import uniq from 'lodash.uniq'
import { useMemo } from 'react'
import {
  CanCityRegionInfo,
  CityInfo,
  Community,
  UsCityRegionInfo,
} from '../../../../graphql/gen-types'
import { cityCountry } from '../../../../utils/formatters'
import {
  LocationSelection,
  LocationSelectionResult,
  OptionMap,
} from '../Shared'

export const COUNTRIES = {
  US: 'USA',
  CA: 'Canada',
}

export const stringCompare = (a: string, b: string): number =>
  a.localeCompare(b, 'en', { sensitivity: 'base' })

export const prepareOptions = <TKey extends string | number>(
  arr: OptionMap<TKey>
): OptionMap<TKey> => {
  const data = uniq([...arr]).sort((a, b) => stringCompare(a[1], b[1]))
  return new Map(data)
}

export const prepareRegionalInfo = (
  data: LocationSelectionResult
): LocationSelectionResult => {
  return {
    countries: prepareOptions(data.countries),
    cities: prepareOptions(data.cities),
    us: {
      states: prepareOptions(data.us.states),
      counties: prepareOptions(data.us.counties),
      metros: prepareOptions(data.us.metros),
      zipCodes: prepareOptions(data.us.zipCodes),
    },
    can: {
      provinces: prepareOptions(data.can.provinces),
      districts: prepareOptions(data.can.districts),
      postCodes: prepareOptions(data.can.postCodes),
    },
  }
}
const locationParseMethods = {
  US: (
    city: CityInfo,
    region: UsCityRegionInfo,
    data: LocationSelectionResult,
    selection: LocationSelection
  ) => {
    const {
      stateCode,
      stateName,
      countyName,
      countyCode,
      metroCustomName,
      metroName,
      metroId,
      zipCode,
    } = region
    if (selection?.country && selection.country !== 'US') return

    if (stateCode && stateName) data.us.states.set(stateCode, stateName)

    const addRegions = !selection.us?.state || selection.us.state === stateCode

    if (!addRegions) return

    const metro = metroCustomName || metroName
    if (countyCode && countyName) data.us.counties.set(countyCode, countyName)
    if (metroId && metro) data.us.metros.set(metroId, metro)

    const addCity =
      addRegions &&
      (!selection.us?.county || selection.us.county === countyCode) &&
      (!selection.us?.metro || selection.us.metro === metroId)

    if (!addCity) return

    const cityName = city.customName || city.name
    data.cities.set(cityName!, cityName!)

    const addZipCode =
      addCity &&
      zipCode &&
      (!selection.cities ||
        selection.cities.length === 0 ||
        selection.cities.includes(cityName!))

    if (addZipCode) {
      data.us.zipCodes.set(Number(zipCode), zipCode)
    }
  },
  CA: (
    city: CityInfo,
    region: CanCityRegionInfo,
    data: LocationSelectionResult,
    selection: LocationSelection
  ) => {
    const {
      provinceCode,
      provinceName,
      districtName,
      districtCode,
      postCode,
    } = region
    if (selection?.country && selection.country !== 'CA') return

    if (provinceCode && provinceName)
      data.can.provinces.set(provinceCode, provinceName)

    const addRegions =
      !selection.can?.province || selection.can.province === provinceCode

    if (!addRegions) return

    if (districtCode && districtName)
      data.can.districts.set(districtCode, districtName)

    const addCity =
      addRegions &&
      (!selection.can?.district || selection.can.district === districtCode)

    if (!addCity) return

    const cityName = city.customName || city.name
    data.cities.set(cityName!, cityName!)

    const addPostCode =
      addCity &&
      postCode &&
      (!selection.cities ||
        selection.cities.length === 0 ||
        selection.cities.includes(cityName!))

    if (addPostCode) data.can.postCodes.set(postCode!, postCode!)
  },
}

export function useLocationSelection(
  communities: Community[],
  selection: LocationSelection
): LocationSelectionResult {
  return useMemo(() => {
    const data = {
      countries: new Map(),
      cities: new Map(),
      us: {
        states: new Map(),
        counties: new Map(),
        metros: new Map(),
        zipCodes: new Map(),
      },
      can: {
        provinces: new Map(),
        districts: new Map(),
        postCodes: new Map(),
      },
    }

    communities.forEach((comm) => {
      const city = comm.cityLocation
      let country = cityCountry(city)

      if (!country) {
        return
      } else if (country === 'CAN') {
        country = 'CA'
      }

      if (country in locationParseMethods) {
        data.countries.set(country, COUNTRIES[country])
        locationParseMethods[country](city, city!.region, data, selection)
      }
    })

    return prepareRegionalInfo(data)
  }, [communities, selection])
}
