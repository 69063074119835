import React from 'react'
import {
  useGetCamberFloorplanTrendsQuery,
  CommonQueryFilter,
  FloorplanTrend,
} from 'graphql/gen-types'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import FloorplanTrends from './FloorplanTrends'

interface CamberFloorplanTrendsProps {
  filter: CommonQueryFilter
  areaChart?: boolean | false
  height?: number | null
  width?: number | null
}

function CamberFloorplanTrends(props: CamberFloorplanTrendsProps): JSX.Element {
  const { data, error, loading } = useGetCamberFloorplanTrendsQuery({
    variables: {
      filter: props.filter,
    },
  })

  const floorplansRaw: FloorplanTrend[] = data?.getCamberFloorplanTrends
    .floorplans as FloorplanTrend[]

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized
          message={error.toString()}
          imageName={'PopularFloorplans'}
        />
      )
    }
    console.error(error)
    return <div>Error while loading popular floorplans</div>
  }

  return (
    <FloorplanTrends
      {...props}
      floorplansRaw={floorplansRaw || []}
      loading={loading}
      error={error}
    />
  )
}

export default CamberFloorplanTrends
