import React, { useContext, useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import Alert from '@mui/material/Alert'

import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts'
import { GET_RESERVATION_STATUS_OVERVIEW } from '../../../graphql/nexus-queries/reservationAnalytics'
import { useGetReservationStatusOverviewLazyQuery } from 'graphql/gen-types'
import { AppStore } from '../../../store'
import ReservationFromToDatePicker from './ReservationFromToDatePicker'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import { UnauthorizedCard } from 'components/auth/Unauthorized'

interface customLabelInput {
  cx: number
  cy: number
  midAngle: number
  innerRadius: number
  outerRadius: number
  percent: number
}

const COLORS = ['#3498db', '#2ecc71', '#e74c3c', '#c0392b']
const RADIAN = Math.PI / 180

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: customLabelInput) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  if (!percent) {
    return null
  }

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

const date = new Date()

const ReservationStatusRate: React.FC = () => {
  const { appState } = useContext(AppStore)
  const clientName = appState?.selectedClient?.altName
  const [dateFrom, setDateFrom] = useState<Date | null>(
    new Date(date.getFullYear(), date.getMonth(), 1)
  )
  const [dateTo, setDateTo] = useState<Date | null>(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  )

  const [
    getReservationStatusOverview,
    { data, error },
  ] = useGetReservationStatusOverviewLazyQuery()

  useEffect(() => {
    if (!dateFrom?.toJSON() || !dateTo?.toJSON()) return

    getReservationStatusOverview({
      variables: {
        clientName: clientName || '',
        filter: {
          fromDate: dateFrom?.toJSON().slice(0, 10),
          toDate: dateTo?.toJSON().slice(0, 10),
        },
      },
    })
  }, [dateFrom, dateTo])

  const statusOverview = data?.getReservationStatusOverview

  const handleDateFromChange = (date: Date | null) => {
    setDateFrom(date)
  }

  const handleDateToChange = (date: Date | null) => {
    setDateTo(date)
  }

  if (error && verifyUserAuthError(error.toString()))
    return (
      <UnauthorizedCard
        title="Overview"
        message={error.toString()}
        imageName={'buyOnlineCommunitiesStatusOverview'}
      />
    )

  if (!statusOverview) return null

  const chartData = [
    { name: 'In Progress', value: statusOverview.inProgress },
    { name: 'Approved', value: statusOverview.approvedByBuilder },
    { name: 'Rejected by builder', value: statusOverview.rejectedByBuilder },
    { name: 'Declined by buyer', value: statusOverview.rejectedByProspect },
  ]

  const attritionBase = chartData.reduce(
    (acc, current) => acc + current.value,
    0
  )
  const attritionRate = (
    (statusOverview.rejectedByProspect / attritionBase) *
    100
  ).toFixed(1)

  return (
    <Card>
      <CardHeader title="Overview" />
      <CardContent>
        <Grid container spacing={3}>
          <ReservationFromToDatePicker
            fromValue={dateFrom}
            onFromChange={handleDateFromChange}
            toValue={dateTo}
            onToChange={handleDateToChange}
            grid={{ xs: 12, sm: 6, md: 4 }}
          />
        </Grid>
        <ResponsiveContainer
          width="100%"
          height={300}
          style={{ marginBottom: 21 }}
        >
          <PieChart width="100%" height={300}>
            <Pie
              data={chartData}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={100}
              fill="#8884d8"
              dataKey="value"
            >
              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Legend />
          </PieChart>
        </ResponsiveContainer>
        <Alert severity="info">
          <Tooltip title="Describes how many buyers declined reservation" arrow>
            <ins>Attrition rate</ins>
          </Tooltip>{' '}
          is <strong>{attritionRate}%</strong>
        </Alert>
      </CardContent>
    </Card>
  )
}

export default ReservationStatusRate
