import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

export const LeadButton = styled(Button)(() => ({
  marginLeft: 'auto',
  marginTop: 9,
}))

export const Container = styled(Paper)(({ theme }) => ({
  padding: 16,
}))

export const Title = styled(Typography)(() => ({
  textAlign: 'center',
}))

export const LeadsTable = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflowX: 'hidden',
  [theme.breakpoints.down(undefined as any)]: {
    maxWidth: 620,
  },
  [theme.breakpoints.down('lg')]: {
    marginTop: 30,
  },
}))
