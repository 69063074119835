import React, { ChangeEvent, useContext } from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import useMediaQuery from '@mui/material/useMediaQuery'
import { AppStore } from 'store'
import { AppActionType } from 'store/reducers'

const PREFIX = 'ModeSwitch'

const classes = {
  drawerDarkModeSwitchMobile: `${PREFIX}-drawerDarkModeSwitchMobile`,
  toolbarDarkModeSwitch: `${PREFIX}-toolbarDarkModeSwitch`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.drawerDarkModeSwitchMobile}`]: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      height: '56px',
      justifyContent: 'center',
    },
  },

  [`&.${classes.toolbarDarkModeSwitch}`]: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

export default function ModeSwitch(): JSX.Element {
  const { appState, dispatch } = useContext(AppStore) // global store
  const theme = useTheme()
  const isDrawer = useMediaQuery(theme.breakpoints.down('sm'))

  function handleDarkMode(
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void {
    dispatch({
      type: AppActionType.SET_DARK_MODE,
      payload: !appState.darkMode,
    })
  }

  return (
    <Root
      className={
        isDrawer
          ? classes.drawerDarkModeSwitchMobile
          : classes.toolbarDarkModeSwitch
      }
    >
      <FormControlLabel
        control={
          <Switch
            checked={appState.darkMode}
            onChange={handleDarkMode}
            name={'toggleDark'}
            inputProps={{ 'aria-label': 'toggle dark mode' }}
          />
        }
        label={'Dark Mode'}
      />
    </Root>
  )
}
