import React, { useState, useEffect, useContext } from 'react'
import { styled } from '@mui/material/styles'
import { useAtom } from 'jotai'
import Button from '@mui/material/Button'
import { SET_SUBSCRIPTION_ENDPOINT } from 'graphql/mutation/setSubscriptionEndpoint'
import TEST_SUBSCRIPTION_ENDPOINT from 'graphql/mutation/testSubscriptionEndpoint'
import { useMutation } from '@apollo/client'
import { useGetSubscriptionEndpointQuery } from 'graphql/gen-types'
import SUBSCRIPTION_ENDPOINT from 'graphql/nexus-queries/getSubsriptionEndpoint'
import ComponentCard from 'components/common/layout/ComponentCard'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import hydrationStore from 'store/HydrationStore'
import Grid from '@mui/material/Grid'
import { AuthContext } from 'auth/AuthContext'
import { snackbarConfigAtom } from 'store/atoms'

const PREFIX = 'WebhookSubscription'

const classes = {
  centeredTitle: `${PREFIX}-centeredTitle`,
}

const StyledTypography = styled(Typography)(({ theme }) => ({
  [`&.${classes.centeredTitle}`]: {
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.contrastText
        : theme.palette.primary.main,
    padding: '10px',
    textAlign: 'center',
  },
}))

interface TestEndpointButtonIf {
  clientName: string
  subscriptionLevel: string
  url?: string
}

/**
 * TestEndpointButton is used to trigger a test message to be sent to
 * a webhook endpoint. The data is sent on the server side to avoid CORS
 * error. The call to the server is sent via a graphQL query.
 *
 * @returns JSX Element
 */
const TestEndpointButton = ({
  clientName,
  subscriptionLevel,
  url,
}: TestEndpointButtonIf): JSX.Element => {
  const [snackbarConfig, setSnackbarConfig] = useAtom(snackbarConfigAtom)
  const [testSubscriptionEndpoint] = useMutation(TEST_SUBSCRIPTION_ENDPOINT)

  const __testSubscriptionEndpoint = () => {
    testSubscriptionEndpoint({
      variables: {
        clientName,
        subscriptionLevel,
      },
      refetchQueries: [
        {
          query: SUBSCRIPTION_ENDPOINT,
          variables: { clientName },
        },
      ],
    })
      .then(() => {
        setSnackbarConfig({
          ...snackbarConfig,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          autoHideDuration: 6000,
          open: true,
          message: 'Sent test to subscription endpoint.',
          severity: 'success',
        })
      })
      .catch((err) => {
        setSnackbarConfig({
          ...snackbarConfig,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          autoHideDuration: 6000,
          open: true,
          message: `Failed to send test to subscription endpoint: ${err.message.replace(
            /.*[Ee]rror: /,
            ''
          )}`,
          severity: 'error',
        })
      })
  }

  return (
    <Button
      data-testid="test-button"
      style={{ marginLeft: 15, width: 200, height: 50, margin: 10 }}
      variant="contained"
      onClick={() => __testSubscriptionEndpoint()}
      disabled={!url}
    >
      Test Endpoint
    </Button>
  )
}

const WebhookSubscription = (): JSX.Element => {
  const [snackbarConfig, setSnackbarConfig] = useAtom(snackbarConfigAtom)
  const [url, setUrl] = useState<string>('')
  const [setSubscriptionEndpoint] = useMutation(SET_SUBSCRIPTION_ENDPOINT)
  const { user } = useContext(AuthContext)
  const clientName = hydrationStore.selectedClient?.altName || ''
  const subscriptionLevel = user ? user.subscriptionLevel : 'STARTER'
  const { data, loading, error } = useGetSubscriptionEndpointQuery({
    variables: { clientName },
  })

  const setEndpoint = () => {
    setSubscriptionEndpoint({
      variables: {
        clientName,
        endpoint: url,
      },
      refetchQueries: [
        {
          query: SUBSCRIPTION_ENDPOINT,
          variables: { clientName },
        },
      ],
    })
      .then(() => {
        setSnackbarConfig({
          ...snackbarConfig,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          autoHideDuration: 6000,
          open: true,
          message: 'Subscription Endpoint Updated.',
          severity: 'success',
        })
      })
      .catch((err) => {
        setSnackbarConfig({
          ...snackbarConfig,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          autoHideDuration: 6000,
          open: true,
          message: err.message.replace(/.*[Ee]rror: /, ''),
          severity: 'error',
        })
      })
  }

  useEffect(() => {
    setUrl(data?.getSubscriptionEndpoint as string)
  }, [data])

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized
          message={error.toString()}
          imageName={'LeadRankings'}
        ></Unauthorized>
      )
    }
    console.error(error)
  }

  return (
    <ComponentCard>
      <StyledTypography variant="h5" className={classes.centeredTitle}>
        Set Webhook Endpoint
      </StyledTypography>
      <Grid container justifyContent="center" alignItems="center">
        <TextField
          variant="standard"
          data-testid="endpoint-input"
          label="Endpoint"
          value={url || ''}
          onChange={(e) => setUrl(e.target.value)}
          style={{ width: 300 }}
        />
        <Button
          data-testid="set-button"
          style={{ marginLeft: 15, width: 200, height: 50, margin: 10 }}
          variant="contained"
          color="primary"
          onClick={() => setEndpoint()}
        >
          Set Endpoint
        </Button>
        <TestEndpointButton
          clientName={clientName}
          url={url}
          subscriptionLevel={subscriptionLevel}
        />
      </Grid>
    </ComponentCard>
  )
}

export default WebhookSubscription
