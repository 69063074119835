import { useMemo, useState, useEffect, useCallback } from 'react'
import {
  CommonQueryFilter,
  Community,
  useCommunitiesQuery,
  useGetCommunitiesByLocationQuery,
} from '../../../../graphql/gen-types'
import { useAlertDialog } from '../components/AlertDialog'
import { useCommunitiesSelection } from '../components/CommunitiesFilter'
import { useCommonQueryFilter } from './useCommonQueryFilter'
import { LocationSelection, SetState } from '../Shared'
import { useRouteStateLocationSelection } from './useRouteStateLocationSelection'

interface UseLocationFiltersProps {
  clientName: string
  startDate: Date
  endDate: Date
  onSelectedCommunities?: (communities: Community[] | undefined) => void
  onLocationSelection?: (selection: LocationSelection) => void
}

interface UseLocationFiltersResult {
  commonFilter: CommonQueryFilter
  allCommunitiesData: Community[]
  filteredCommunitiesData: Community[]

  selection: LocationSelection
  setSelection: SetState<LocationSelection>
  selectedCommunities: Community[] | undefined
  setSelectedCommunities: SetState<Community[] | undefined>
  handleCommunitiesSelection: (comms: Community[]) => void

  openDialog: boolean
  setOpenDialog: SetState<boolean>
  dialogMessage: string
  setDialogMessage: SetState<string>
}

const useLocationFilters = ({
  clientName,
  startDate,
  endDate,
  onSelectedCommunities,
  onLocationSelection,
}: UseLocationFiltersProps): UseLocationFiltersResult => {
  // Community State
  const [selectedCommunities, setSelectedCommunities] = useState<
    Community[] | undefined
  >(undefined)

  // Location Selection State
  const { routeLocation } = useRouteStateLocationSelection()
  const [selection, setSelection] = useState<LocationSelection>(
    routeLocation || {
      us: {},
      can: {},
    }
  )

  const wrappedSetSelection = useCallback(
    (newSelection) => {
      setSelection(newSelection)
      onLocationSelection?.(newSelection)
    },
    [setSelection, onLocationSelection]
  )

  const {
    openDialog,
    setOpenDialog,
    dialogMessage,
    setDialogMessage,
  } = useAlertDialog()

  const handleCommunitiesSelection = useCommunitiesSelection({
    setSelectedCommunities,
    setOpenDialog,
    setDialogMessage,
    onSelectedCommunities,
  })

  // Get all communities data from nexus.
  const communitiesQuery = useCommunitiesQuery({
    variables: {
      clientName,
    },
    skip: !clientName,
  })

  // Get communities filtered by location and with popularity information
  const commonFilter = useCommonQueryFilter({
    clientName,
    startDate,
    endDate,
    selection,
  })

  const { data, loading } = useGetCommunitiesByLocationQuery({
    variables: {
      filter: commonFilter,
    },
  })

  const allCommunitiesData = useMemo(
    () => (communitiesQuery?.data?.communities || []) as Community[],
    [communitiesQuery?.data?.communities]
  )

  const filteredCommunitiesData = useMemo(() => {
    // do not process communities change if the result is loading.
    if (loading) return []

    const filteredCommunityIds =
      data?.getCommunitiesByLocation?.map((comm) => comm.communityId) || []

    const filteredCommunities = allCommunitiesData.filter((comm) =>
      filteredCommunityIds.includes(comm.id)
    )

    return filteredCommunities
  }, [allCommunitiesData, loading, data])

  useEffect(() => {
    const hasDirectlySelectedCommunity =
      selectedCommunities?.length &&
      selectedCommunities.length < filteredCommunitiesData.length
    if (filteredCommunitiesData.length > 0 && !hasDirectlySelectedCommunity)
      onSelectedCommunities?.(filteredCommunitiesData)
  }, [filteredCommunitiesData, onSelectedCommunities, selectedCommunities])

  return {
    commonFilter,
    allCommunitiesData,
    filteredCommunitiesData,

    selection,
    setSelection: wrappedSetSelection,
    selectedCommunities,
    setSelectedCommunities,
    handleCommunitiesSelection,

    openDialog,
    setOpenDialog,
    dialogMessage,
    setDialogMessage,
  }
}

export default useLocationFilters
