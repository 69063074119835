import React, { useContext, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Community, useCommunitiesQuery } from '../../../graphql/gen-types'

import FilterBar from 'components/common/filter-bar/FilterBar'
import { MultiOptionAutoComplete } from 'components/common/custom-inputs/MultiOptionsAutocomplete'
import { AppStore } from 'store'
const PREFIX = 'LeadsAssignmentFilterBar'

const classes = {
  filtersContainer: `${PREFIX}-filtersContainer`,
  filters: `${PREFIX}-filters`,
  formControl: `${PREFIX}-formControl`,
  chips: `${PREFIX}-chips`,
  chip: `${PREFIX}-chip`,
  noLabel: `${PREFIX}-noLabel`,
  componentContainer: `${PREFIX}-componentContainer`,
}

const StyledFilterBar = styled(FilterBar)(({ theme }) => ({
  [`& .${classes.filtersContainer}`]: {
    display: 'flex',
    gap: '10px',
  },

  [`& .${classes.filters}`]: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.formControl}`]: {
    margin: `0px ${theme.spacing(1)}`,
    minWidth: 130,
    maxWidth: 300,
  },

  [`& .${classes.chips}`]: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  [`& .${classes.chip}`]: {
    margin: 2,
  },

  [`& .${classes.noLabel}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.componentContainer}`]: {
    marginTop: '1em',
    marginBottom: '1em',
    padding: '2em',
    justifyContent: 'center',
  },
}))

export type ProspectScoreSelection = { value: number; name: string }

type LeadsAssignmentFilterBarProps = {
  onSelectedCommunities?: (selectedCommunities: Community[]) => void
}

export default function LeadsAssignmentFilterBar({
  onSelectedCommunities,
}: LeadsAssignmentFilterBarProps): JSX.Element {
  const { appState } = useContext(AppStore)
  const { selectedClient } = appState
  const clientName = selectedClient?.altName

  const [communities, setCommunities] = useState<Community[]>([])
  const [selectedCommunities, setSelectedCommunities] = useState<Community[]>(
    []
  )

  // Fetch all communities for client
  const communitiesQuery = useCommunitiesQuery({
    variables: { clientName: clientName || '' },
    skip: !selectedClient,
  })

  useEffect(() => {
    if (communitiesQuery?.data?.communities) {
      const filteredCommunities = communitiesQuery.data.communities.filter(
        (community) => community?.active === true
      ) as Community[]
      setCommunities(filteredCommunities)
    }
  }, [communitiesQuery.data])

  const handleSelectedCommunities = (selectedCommunities: Community[]) => {
    const displayCommunities =
      selectedCommunities.length === 0 ? [] : selectedCommunities
    setSelectedCommunities(selectedCommunities)
    onSelectedCommunities && onSelectedCommunities(displayCommunities)
  }

  const CommunityFilter: JSX.Element = (
    <div className={classes.filtersContainer}>
      <MultiOptionAutoComplete
        inputLabel={'Select Community'}
        fullWidth
        options={communities}
        selectedOptions={selectedCommunities}
        setSelectedOptions={handleSelectedCommunities}
        optionLabelAccessor={(community: Community): string =>
          community.name || 'unknown'
        }
      />
    </div>
  )

  return (
    <StyledFilterBar
      customFilters={communities && CommunityFilter}
      hideDateSelect={true}
    />
  )
}
