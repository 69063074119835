import { gql } from '@apollo/client'

export const EXCLUDED_IPS = gql`
  query ExcludedIps($clientName: String!) {
    excludedIps(clientName: $clientName) {
      ip
      ipCidrNotation
    }
  }
`
