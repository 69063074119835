import React from 'react'
import DateFnsAdapter from '@date-io/date-fns'
import {
  CommonQueryFilter,
  SessionsSummary,
  useIndustrySessionSummaryQuery,
} from 'graphql/gen-types'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import SessionsSummaryDisplay from '../analytics/sessions/SessionsSummaryDisplay'
import ComponentCard from 'components/common/layout/ComponentCard'

const dateFns = new DateFnsAdapter()

const initialFilter: CommonQueryFilter = {
  clientName: '',
  startTime: dateFns.format(
    new Date(Date.now() - 604800000), // Subtract 1 week (in milliseconds)
    'MM-dd-yyyy-HH:mm:ss'
  ),
  endTime: dateFns.format(new Date(Date.now()), 'MM-dd-yyyy-HH:mm:ss'),
}

interface SessionSummaryProps {
  filter: CommonQueryFilter
}

export default function IndustrySessionsSummary({
  filter,
}: SessionSummaryProps): JSX.Element {
  const {
    data: sessionData,
    loading: sessionLoading,
    error: sessionError,
  } = useIndustrySessionSummaryQuery({
    variables: {
      filter,
    },
  })
  const sessionsSummary = sessionData?.industrySessionSummary
  const {
    data: prevSessionData,
    loading: previousSessionLoading,
    error: prevSessionError,
  } = useIndustrySessionSummaryQuery({
    variables: {
      filter: getPreviousFilter(filter),
    },
  })
  const previousSessionsSummary = prevSessionData?.industrySessionSummary

  const loading = sessionLoading || previousSessionLoading
  const error = sessionError || prevSessionError
  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized
          message={error.toString()}
          imageName={'SessionsSummaryDisplay'}
        ></Unauthorized>
      )
    }
    return <div>{error.toString()}</div>
  }

  if (loading) {
    return (
      <ComponentCard
        result={{ loading, error }}
        skeletonHeight={100}
      ></ComponentCard>
    )
  }

  return (
    <SessionsSummaryDisplay
      data={sessionsSummary as SessionsSummary}
      previousData={previousSessionsSummary as SessionsSummary}
    ></SessionsSummaryDisplay>
  )
}

function getPreviousFilter(filter: CommonQueryFilter): CommonQueryFilter {
  // Find new time. Use start as the new end,
  // and new start as the same difference in time

  // For Safari's sake, the dashes in the date must be replaced with slashes and
  // a space between the date and time. Otherwise it will throw an error.
  const startDate = replaceDateFormat(filter.startTime)
  const endDate = replaceDateFormat(filter.endTime)
  const timeDiff = Math.abs(endDate.getTime() - startDate.getTime()) // Milliseconds
  const newStart = new Date(startDate.getTime() - timeDiff)
  // Make copy of filter, just with new times
  // This is verbose, but ensures our new object does not point to the old.
  const newFilter = initialFilter
  newFilter.startTime = dateFns.format(newStart, 'MM-dd-yyyy-HH:mm:ss')
  newFilter.endTime = dateFns.format(startDate, 'MM-dd-yyyy-HH:mm:ss')
  return newFilter
}

function replaceDateFormat(date: Date) {
  return new Date(
    date
      .toString()
      .replace(/T([^-]*)Z$/, ' $1')
      .replace(/-/g, '/')
  )
}
