import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import { MAIN_APP_BAR_HEIGHT } from '../../constants'

const PREFIX = 'BuyOnlineReservations'

export const classes = {
  root: `${PREFIX}-root`,
  disclaimer: `${PREFIX}-disclaimer`,
  loaderWrapper: `${PREFIX}-loaderWrapper`,
  noReservationHeader: `${PREFIX}-noReservationHeader`,
}

export const StyledDiv = styled('div')(() => ({
  [`&.${classes.root}`]: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',
    width: '100%',
    height: `calc(100vh - ${MAIN_APP_BAR_HEIGHT}px)`,
    padding: 20,
  },
}))

export const StyledCard = styled(Card)(({ theme }) => ({
  [`& .${classes.disclaimer}`]: {
    margin: theme.spacing(3, 0),
    '& a': {
      color: theme.palette.primary.main,
    },
  },
  [`& .${classes.loaderWrapper}`]: {
    minHeight: 400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  [`& .${classes.noReservationHeader}`]: {
    lineHeight: '300px',
  },
}))
