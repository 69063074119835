import { gql } from '@apollo/client'

export const SEND_INSIGHTS_MARKETING_AUTOMATION = gql`
  mutation sendInsightsMarketingAutomationInquiry(
    $clientName: String!
    $userEmail: EmailAddress!
    $userPhone: String
  ) {
    sendInsightsMarketingAutomationInquiry(
      clientName: $clientName
      userEmail: $userEmail
      userPhone: $userPhone
    )
  }
`
