import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

type SwitchTitleProps = {
  popularTitle: string
  unpopularTitle: string
  setShowUnpopular: React.Dispatch<React.SetStateAction<boolean>>
  showUnpopular: boolean
}

function SwitchTitle({
  popularTitle,
  unpopularTitle,
  setShowUnpopular,
  showUnpopular,
}: SwitchTitleProps): JSX.Element {
  return (
    <Grid container alignItems="center">
      <Grid item xs={6}>
        <Typography variant="h5" color="primary">
          {showUnpopular ? unpopularTitle : popularTitle}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  data-testid="popularElevationsPopularSwitch"
                  checked={!showUnpopular}
                  color="primary"
                  onClick={() => setShowUnpopular(!showUnpopular)}
                />
              }
              label={showUnpopular ? 'Unpopular' : 'Popular'}
              labelPlacement="start"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SwitchTitle
