import React from 'react'
import { styled } from '@mui/material/styles'
import chroma from 'chroma-js'
import { formatDateTime } from 'utils/functions'

const PREFIX = 'CustomToolTip'

const classes = {
  customTooltip: `${PREFIX}-customTooltip`,
  customTooltipItem: `${PREFIX}-customTooltipItem`,
  tooltipLabel: `${PREFIX}-tooltipLabel`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.customTooltip}`]: {
    backgroundColor: 'white',
    border: '1px solid rgb(204, 204, 204)',
    margin: '0px',
    padding: '10px',
  },

  [`& .${classes.customTooltipItem}`]: {
    margin: '4px 0px',
  },

  [`& .${classes.tooltipLabel}`]: {
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.secondary.contrastText
        : theme.palette.secondary.dark,
  },
}))

type Payload = {
  payload: { value: unknown }
  color: string
  name: string
}
type CustomToolTipProps = {
  active?: boolean
  payload?: Payload[]
  label?: string
}

const CustomToolTip = ({
  active,
  payload,
  label,
}: CustomToolTipProps): JSX.Element | null => {
  if (active) {
    return (
      <Root className={classes.customTooltip}>
        <div className={classes.tooltipLabel}>
          {formatDateTime(label, 'MM/dd/yyyy')}
        </div>
        {payload?.map((entry: Payload, index: number) => {
          const value = entry?.payload?.value
          const itemValue =
            value !== undefined ? `: ${entry.payload.value}` : ''

          let itemStyle
          let itemValueStyle
          const lum = chroma(entry.color).luminance()
          if (lum > 0.8) {
            itemStyle = { backgroundColor: '#424242' }
            itemValueStyle = { color: '#e4f5ff' }
          } else {
            itemValueStyle = { color: '#000000' }
          }
          return (
            <div
              key={`tooltipItem-${index}`}
              className={classes.customTooltipItem}
              style={itemStyle}
            >
              <span style={{ color: entry.color }}>{entry.name}</span>
              <span style={itemValueStyle}>{itemValue}</span>
            </div>
          )
        })}
      </Root>
    )
  }

  return null
}

export default CustomToolTip
