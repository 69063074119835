import { styled } from '@mui/material/styles'

const PREFIX = 'Home'

export const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
}

export const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
    position: 'relative',
    zIndex: theme.zIndex.drawer + 2,
  },

  [`& .${classes.button}`]: {
    marginTop: '2rem',
    minHeight: 56,
    minWidth: 300,
    color: theme.palette.primary.contrastText,
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.primary.main
        : theme.palette.primary.dark,
    '&:hover': {
      backgroundColor:
        theme.palette.mode === 'light'
          ? theme.palette.primary.main
          : theme.palette.primary.dark,
    },
  },
}))
