// Helper function to subtract possibly undefiend / null numbers.
import { SortOptions } from '../custom-inputs/SortSelect'
import { Plan } from '../../../graphql/gen-types'
import { ProspectPlan } from './FavoriteCard'

type PlanTypes = Plan | ProspectPlan

const subtract = (
  a: number | undefined | null,
  b: number | undefined | null
): number => (a || 0) - (b || 0)

export function sortPlansByMetric(
  planA: PlanTypes,
  planB: PlanTypes,
  sortBy: SortOptions
): number {
  const metricA = planA.popularityMetrics
  const metricB = planB.popularityMetrics

  switch (sortBy) {
    case SortOptions.Frequency:
      return subtract(metricB?.byFrequency, metricA?.byFrequency)
    case SortOptions.Duration:
      return subtract(metricB?.byDuration?.total, metricA?.byDuration?.total)
    case SortOptions.Popularity:
      return subtract(
        metricB?.byPopularity?.popularity,
        metricA?.byPopularity?.popularity
      )
    case SortOptions.MyHomeHits:
      return subtract(
        metricB?.byPopularity?.brochure,
        metricA?.byPopularity?.brochure
      )
    case SortOptions.CtaHits:
      return subtract(metricB?.byPopularity?.cta, metricA?.byPopularity?.cta)
    case SortOptions.Favorites:
      return subtract(
        metricB?.byBrowsingData?.favorites,
        metricA?.byBrowsingData?.favorites
      )
    case SortOptions.LeadFavorites:
      return subtract(
        metricB?.byBrowsingData?.leadFavorites,
        metricA?.byBrowsingData?.leadFavorites
      )
    case SortOptions.LeadViews:
      return subtract(
        metricB?.byBrowsingData?.leadViews,
        metricA?.byBrowsingData?.leadViews
      )
    default:
      return planB.id - planA.id
  }
}
