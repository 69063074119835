import React from 'react'
import { styled } from '@mui/material/styles'
import { useAtom } from 'jotai'
import { useParams } from 'react-router-dom'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import {
  LeadStatus,
  ProspectStatus,
  useUpdateProspectMutation,
} from '../../../graphql/gen-types'
import { snakeCaseToTitleCase } from 'utils/functions'
import { snackbarConfigAtom } from 'store/atoms'

const PREFIX = 'ProspectStatusSelect'

const classes = {
  statusSelect: `${PREFIX}-statusSelect`,
  smallSelect: `${PREFIX}-smallSelect`,
}

const StyledFormControl = styled(FormControl)(() => ({
  [`&.${classes.statusSelect}`]: {
    width: '100%',
  },

  [`& .MuiSelect-outlined`]: {
    padding: '10px 26px 10px 12px',
    minWidth: '160px',
  },
}))

export interface ProspectStatusSelectProps {
  disabled?: boolean
  hideLabel?: boolean
  prospectId?: number | null
  prospectStatus?: ProspectStatus | LeadStatus | null
  prospectEmail?: string | null
}

export default function ProspectStatusSelect({
  disabled = false,
  hideLabel = false,
  prospectId,
  prospectStatus,
  prospectEmail,
}: ProspectStatusSelectProps): JSX.Element {
  const { clientName } = useParams()
  const [snackbarConfig, setSnackbarConfig] = useAtom(snackbarConfigAtom)

  // Hook for mutating prospect activities
  const [updateProspectMutation] = useUpdateProspectMutation({
    variables: {
      clientName: clientName || '',
      prospect: {
        id: prospectId || 0,
      },
    },
    update(cache, response) {
      if (prospectEmail) {
        cache.modify({
          id: `LeadRanking:${prospectEmail}`,
          fields: {
            status() {
              return response.data?.updateProspect?.status
            },
          },
        })
      }
    },
  })

  const onChange = (e: SelectChangeEvent) => {
    e.preventDefault()
    e.stopPropagation()

    // This is done to satisfy the type check, which needs e.target.value to be a ProspectStatus
    let selectedStatus
    Object.entries(ProspectStatus).forEach((row) => {
      const value = row[1]
      if (value === e.target.value) {
        selectedStatus = value
      }
    })

    if (selectedStatus) {
      updateProspectMutation({
        variables: {
          clientName: clientName || '',
          prospect: {
            id: prospectId || 0,
            status: selectedStatus,
          },
        },
      })
        .then((result) => {
          setSnackbarConfig({
            ...snackbarConfig,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            autoHideDuration: 6000,
            open: true,
            message: 'Updated Lead status successfully!',
            severity: 'success',
          })
        })
        .catch((err) => {
          setSnackbarConfig({
            ...snackbarConfig,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            autoHideDuration: 6000,
            open: true,
            message: err.message.replace(/.*[Ee]rror: /, ''),
            severity: 'error',
          })
        })
    }
  }

  const generateOptions = (): JSX.Element[] => {
    const options: JSX.Element[] = []
    Object.entries(ProspectStatus).forEach((row) => {
      const value = row[1]
      options.push(
        <MenuItem key={value} value={value}>
          {snakeCaseToTitleCase(value)}
        </MenuItem>
      )
    })
    return options
  }
  return (
    <StyledFormControl className={classes.statusSelect} variant="outlined">
      {!hideLabel && (
        <InputLabel
          id={`select-prospect-status-label-${prospectId || ''}`}
          htmlFor={`select-prospect-status-${prospectId || ''}`}
        >
          Status
        </InputLabel>
      )}
      <Select
        disabled={disabled}
        value={prospectStatus || ''}
        onChange={onChange}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        inputProps={{
          name: `select-prospect-status-${prospectId || ''}`,
          id: `select-prospect-status-${prospectId || ''}`,
        }}
        label={hideLabel ? undefined : 'Status'}
      >
        {generateOptions()}
      </Select>
    </StyledFormControl>
  )
}
