import React, { useContext, useEffect, useState } from 'react'
import { useQuery, useSubscription } from '@apollo/client'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import {
  classes,
  Root,
  BorderLinearProgress,
} from './BuyOnlineIntegration.styles'
import ReservationIntegrationDialog from './../../components/buy-online/integration/ReservationIntegrationDialog'
import ReservationIntegrationForm from './../../components/buy-online/integration/ReservationIntegrationForm'
import { AppStore } from '../../store'
import {
  OnlineReservationIntegrationInfo,
  useGetClientReservationIntegrationQuery,
  useCommunitiesQuery,
  Community,
} from '../../graphql/gen-types'
import ReservationIntegrationStripeButton from './../../components/buy-online/integration/ReservationIntegrationStripeButton'
import hydrationStore from '../../store/HydrationStore'
import { RESERVATION_INTEGRATION_UPDATE } from '../../graphql/nexus-subscriptions/onlineReservationSubscriptions'
import ReservationIntegrationCustomDocumentFieldManagement from './../../components/buy-online/integration/ReservationIntegrationCustomDocumentFieldManagement'
import { AuthContext } from '../../auth/AuthContext'
import {
  GET_IS_USER_ANEWGO_ADMIN,
  GET_IS_USER_SUPER_ADMIN,
} from '../../graphql/auth-queries/authDb'
import ReservationIntegrationBuyOnlineToggle from '../../components/buy-online/integration/ReservationIntegrationBuyOnlineToggle'
import ReservationIntegrationAllowMultipleReservationsToggle from '../../components/buy-online/integration/ReservationIntegrationAllowMultipleReservationsToggle'
import { ANEWGO_ADMIN_ROLE_ID, ANEWGO_STAFF_ROLE_ID } from '../../constants'
import { UnauthorizedCard } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'

const getProgress = (
  reservationIntegrationInfo: Partial<OnlineReservationIntegrationInfo>
): number => {
  let success = 0
  let items = 0

  const { depositAmount, nonRefundableAmount, anewgoFee } =
    reservationIntegrationInfo || {}

  // Validate fees
  items++
  if (
    depositAmount &&
    (anewgoFee || anewgoFee === 0) &&
    nonRefundableAmount &&
    depositAmount >= anewgoFee + nonRefundableAmount
  ) {
    success++
  }

  // Validate inputs
  for (const key in reservationIntegrationInfo) {
    const value = reservationIntegrationInfo[key]
    const isValueArray = Array.isArray(value)

    if (typeof value === 'string' && value.length > 0) {
      success++
    } else if (typeof value === 'boolean' && value) {
      success++
    } else if (typeof value === 'number' && value >= 0) {
      success++
    } else if (isValueArray && value.length > 0) {
      if (value.every((val) => Object.values(val).every((item) => !!item))) {
        success++
      }
    }

    // Only count up items if value is not an array or array have some items
    if (!isValueArray || (isValueArray && value.length)) {
      items++
    }
  }
  return Math.round((success / items) * 100)
}

const ReservationIntegration: React.FC = () => {
  // Hooks

  const { appState } = useContext(AppStore)
  const { user } = useContext(AuthContext)
  const userId = user.userId
  const clientName = appState?.selectedClient?.altName || ''
  const clientId = appState?.selectedClient?.id || 0
  const [isUserAnewgoAdmin, setIsUserAnewgoAdmin] = useState<boolean | null>(
    null
  )
  // Requests
  const { data, loading, error } = useGetClientReservationIntegrationQuery({
    variables: {
      clientId: clientId,
    },
  })

  const { data: isAdminData } = useQuery(GET_IS_USER_ANEWGO_ADMIN, {
    variables: {
      userId,
    },
  })
  const { data: isSuperAdmin } = useQuery(GET_IS_USER_SUPER_ADMIN, {
    variables: {
      userId,
    },
  })

  const isAnewgoAdminAndSuperAdmin: boolean =
    (user.roleId === ANEWGO_ADMIN_ROLE_ID && isSuperAdmin?.isUserSuperAdmin) ||
    (user.roleId === ANEWGO_ADMIN_ROLE_ID && isAdminData?.isUserAnewgoAdmin)

  const communitiesQuery = useCommunitiesQuery({
    variables: {
      clientName,
    },
    skip: !clientName,
  })

  // Set if user is anewgo admin
  useEffect(() => {
    if (isAdminData?.isUserAnewgoAdmin) {
      setIsUserAnewgoAdmin(isAdminData?.isUserAnewgoAdmin)
    } else {
      setIsUserAnewgoAdmin(false)
    }
  }, [isAdminData])

  // Constant
  const {
    id: reservationIntegrationId,
    stripeAccountId,
    stripeAccountReady = false,
    signingAgreementTemplateId,
    anewgoFee,
    depositAmount,
    nonRefundableAmount,
    primaryBuyOnlineContactEmail,
    primaryBuyOnlineContactName,
    reservationConfig,
    additionalBuilders,
    onboardingComplete,
    allowMultipleReservations,
  } = data?.getReservationIntegration?.reservationIntegrationInfo || {}

  const progress = getProgress({
    stripeAccountId,
    stripeAccountReady,
    signingAgreementTemplateId,
    anewgoFee,
    depositAmount,
    nonRefundableAmount,
    primaryBuyOnlineContactEmail,
    primaryBuyOnlineContactName,
    additionalBuilders,
  })

  // Subscription will look for the changes to the field stripeAccountReady and will automatically update
  // the UI on redirect from the Stripe Connect UI when builder client is setting up their connected
  // account to the anewgo account
  useSubscription(RESERVATION_INTEGRATION_UPDATE, {
    variables: {
      clientName: appState?.selectedClient?.altName,
      token: hydrationStore.token || '',
    },
  })

  if (error && verifyUserAuthError(error.toString()))
    return (
      <UnauthorizedCard
        title="Online reservation"
        message={error.toString()}
        imageName={'buyOnlineCommunitiesStatusOverview'}
      />
    )

  const communities = (communitiesQuery?.data?.communities || []) as Community[]
  return (
    <Root className={classes.root}>
      <div>
        <Alert severity="info" icon={false} className={classes.disclaimer}>
          <span role="alert" data-testid="termsDisclaimer">
            {`While Anewgo leverages Stripe and Docusign to facilitate buyer reservations, it is not responsible for how a builder represents their products, pricing, legal terms, or completion of services. `}
            <a
              href="https://anewgosell.com/legal/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {`Terms and Conditions`}
            </a>
          </span>
        </Alert>
        <Paper className={classes.paper}>
          <Box className={classes.progressBar}>
            <BorderLinearProgress
              variant="determinate"
              className={classes.progressBar}
              value={progress}
            />
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <ReservationIntegrationStripeButton
                stripeAccountId={stripeAccountId}
                stripeAccountReady={stripeAccountReady}
              />
            </Grid>
            {progress === 100 && (
              <Grid item xs={12} md={6}>
                <Alert severity="success">
                  Online Reservation Onboarding Complete!
                </Alert>
              </Grid>
            )}
            {progress < 100 && (
              <Grid item xs={12} md={6}>
                <Alert severity="warning">
                  Enter all necessary information to start using Reservation.
                  Current progress: <strong>{progress}&nbsp;%</strong>
                </Alert>
              </Grid>
            )}
          </Grid>
          <ReservationIntegrationForm isUserAnewgoAdmin={isUserAnewgoAdmin} />
          {!loading && (
            <Grid item xs={12} md={6} className={classes.toggleBuyOnline}>
              <ReservationIntegrationAllowMultipleReservationsToggle
                reservationIntegrationId={reservationIntegrationId ?? 0}
                enabled={allowMultipleReservations ?? false}
                clientId={clientId}
              />
              <ReservationIntegrationBuyOnlineToggle
                clientId={clientId}
                progress={progress}
                onboardingComplete={onboardingComplete}
                canToggle={isAnewgoAdminAndSuperAdmin}
              />
            </Grid>
          )}
        </Paper>
        {signingAgreementTemplateId && (
          <ReservationIntegrationCustomDocumentFieldManagement
            templateCustomFields={
              reservationConfig?.customDocusignTemplateFields || []
            }
            templateId={signingAgreementTemplateId}
            communities={communities}
          />
        )}
      </div>
    </Root>
  )
}

export default ReservationIntegration
