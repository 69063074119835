import React, { useState } from 'react'
import {
  ClientIdAndName,
  CommonQueryFilter,
  Elevation,
  Scheme,
} from 'graphql/gen-types'
import { useTheme, Theme } from '@mui/material/styles'
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  CartesianGrid,
  Label,
} from 'recharts'
import { CustomXAxisTick } from './CustomXAxisTick'
import { filterMaterialsByLayers } from '@anewgo/utils'
import { CustomYAxisLabel } from 'components/common/charts/custom/CustomAxisLabel'
import useMediaQuery from '@mui/material/useMediaQuery'
import Typography from '@mui/material/Typography'
import { StyledComponentCard, classes } from './PopularColors.styles'

const COMPONENT_TITLE = 'Popular Color Schemes'

export interface PopularColorsProps {
  client: ClientIdAndName | null
  filter: CommonQueryFilter
  communityName?: string
  containerHeight: number
  loading: boolean
  error: Error | undefined
  planId?: number
  elevation?: Elevation | null
  popularColorSchemes: Array<Scheme | null> | undefined
}

function getContrastFillColor(theme: Theme): string {
  return theme.palette.mode === 'dark'
    ? theme.palette.primary.light
    : theme.palette.primary.dark
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function labelFormatter(value: number, data: any[]): string {
  const lot = data?.find((datum) => datum?.payload?.id === value)
  return `Scheme: ${lot?.payload?.name}`
}

export default function PopularColors(
  props: PopularColorsProps
): JSX.Element | null {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down('sm'))
  const fillColor = getContrastFillColor(theme)

  const [xAxisHeight, setXAxisHeight] = useState(1)

  if (!props?.client?.directoryName) {
    return null
  }

  let modifiedChartData: Array<Scheme | null> | undefined

  if (props?.elevation) {
    modifiedChartData = props.popularColorSchemes
      ? props.popularColorSchemes.map((chData) => {
          if (chData && props?.elevation?.layers) {
            const newData = { ...chData }
            newData.materials = filterMaterialsByLayers(
              chData.materials,
              props.elevation.layers
            )
            return newData
          }
          return null
        })
      : []
  }

  return (
    <StyledComponentCard
      title={COMPONENT_TITLE}
      result={{
        data: modifiedChartData || props.popularColorSchemes,
        loading: props.loading,
        error: props.error,
      }}
      skeletonHeight={500}
    >
      <ResponsiveContainer width="100%" height={props.containerHeight}>
        <BarChart
          data={
            modifiedChartData ? modifiedChartData : props.popularColorSchemes
          }
        >
          <XAxis
            height={xAxisHeight}
            dataKey="id"
            tick={
              <CustomXAxisTick
                onHeightChange={(height): void => {
                  if (xAxisHeight !== height) {
                    setXAxisHeight(xs ? 40 : height)
                  }
                }}
                data={
                  modifiedChartData
                    ? modifiedChartData
                    : props.popularColorSchemes
                }
                client={props.client}
                fill={fillColor}
              />
            }
          />
          <YAxis
            tick={{
              fill: fillColor,
            }}
          >
            <Label
              value="Popularity"
              stroke={fillColor}
              content={CustomYAxisLabel}
            />
          </YAxis>
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip
            labelClassName={classes.tooltip}
            labelFormatter={labelFormatter}
          />
          <Bar
            fill={theme.palette.primary.main}
            name="popularity"
            dataKey="popularity"
          />
        </BarChart>
      </ResponsiveContainer>
      {xs && (
        <Typography style={{ paddingTop: 5 }} variant="caption">
          Select bar for more info.
        </Typography>
      )}
    </StyledComponentCard>
  )
}
