import { useCallback } from 'react'
import { SetState } from '../Shared'

const useAreaChartChange = ({
  setAreaChart,
  onAreaChartChange,
}: {
  setAreaChart: SetState<boolean>
  onAreaChartChange?: (areaChart: boolean) => void
}) =>
  useCallback((): void => {
    setAreaChart((areaChart) => {
      onAreaChartChange?.(!areaChart) // call prop function
      return !areaChart
    }) // update state
  }, [setAreaChart, onAreaChartChange])

export default useAreaChartChange
