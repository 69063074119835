import { CommonQueryFilter } from '../../../graphql/gen-types'
import { useMemo } from 'react'
import { cleanFilter } from '../../analytics/locations-filters/hooks/useCommonQueryFilter'
import { ITLocationSelection } from '../../analytics/locations-filters/Shared'

type CommonQueryFilterArgs = {
  clientName: string
  startDate: Date
  endDate: Date
  selection: ITLocationSelection
  [key: string]: unknown
}
export const useCommonQueryFilter = ({
  clientName,
  startDate,
  endDate,
  selection,
  ...args
}: CommonQueryFilterArgs): Exclude<CommonQueryFilter, undefined> => {
  return useMemo(
    () =>
      cleanFilter<CommonQueryFilter>({
        clientName,
        startTime: startDate.getTime(),
        endTime: endDate.getTime(),
        country: selection.country,
        cities:
          selection.cities && selection.cities.length > 0
            ? selection.cities
            : undefined,
        ...(args || {}),

        state: selection.us?.state ? [selection.us.state] : undefined,
        province: selection.can?.province
          ? [selection.can.province]
          : undefined,
        region:
          selection.us?.metro || selection.can?.district
            ? [selection.us?.metro, selection.can?.district]
                .map((x) => x || '')
                .filter(Boolean) || undefined
            : undefined,
      }),
    [clientName, startDate, endDate, selection, args]
  )
}
