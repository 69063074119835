import React, { useContext, useState } from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import jwtDecode from 'jwt-decode'
import hydrationStore from 'store/HydrationStore'
import { SEND_FORGOT_EMAIL_NOTIF_RETRIEVE } from 'graphql/mutation/retrieveEmail'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { AppStore } from '../store'
import { classes, Root } from './EmailRetrievePage.styles'

interface DecodedTokenInterface {
  firstName: string
  lastName: string
  userEmail: string
  clientName: string
  iat: number
  exp: number
}

interface EmailRetrieveStateInterface {
  isLoading: boolean
  isEmailSent: boolean
  message: string
}

const emailRetrievePageInitialState: EmailRetrieveStateInterface = {
  isLoading: false,
  isEmailSent: false,
  message: '',
}

const EmailRetrievePage = (): JSX.Element => {
  const location = useLocation()
  const token =
    location.search.indexOf('token=') !== -1
      ? location.search
          .substr(location.search.indexOf('token=') + 6)
          .split('&')[0]
      : null
  const backUpEmail =
    location.search.indexOf('backupemail=') !== -1
      ? location.search
          .substr(location.search.indexOf('backupemail=') + 12)
          .split('&')[0]
      : null
  const [state, setState] = useState<EmailRetrieveStateInterface>(
    emailRetrievePageInitialState
  )

  const { appState } = useContext(AppStore)
  const authClient = appState.apolloClient

  useEffect(() => {
    if (token) {
      const decodedToken: DecodedTokenInterface = jwtDecode(token)

      setState({
        ...state,
        message: `Your team member ${decodedToken.firstName} ${decodedToken.lastName} has forgotten their email. Click the button below to send their email to ${backUpEmail}.`,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = (): void => {
    if (!token) {
      console.error('no provided token parameter in the url')
    } else {
      setState({ ...state, isLoading: true })
      const decodedToken: DecodedTokenInterface = jwtDecode(token)
      // store the token in local storage
      hydrationStore.token = token
      // api call for reset password
      const result = authClient.mutate({
        mutation: SEND_FORGOT_EMAIL_NOTIF_RETRIEVE,
        variables: {
          firstName: decodedToken.firstName,
          lastName: decodedToken.lastName,
          backUpEmail: backUpEmail,
          clientName: decodedToken.clientName,
          email: decodedToken.userEmail,
        },
      })

      result.then((data) => {
        if (!data) {
          console.error(
            'Failed to perform graphql SEND_FORGOT_EMAIL_NOTIF_RETRIEVE'
          )

          setTimeout(() => {
            setState({
              ...state,
              isLoading: false,
              message: 'Failed to send email',
              isEmailSent: true,
            })
          }, 1000)
        } else {
          setTimeout(() => {
            setState({
              ...state,
              isLoading: false,
              isEmailSent: true,
              message: `Email has been sent to ${decodedToken.firstName} ${decodedToken.lastName}`,
            })
          }, 1000)
        }
      })
    }
  }

  return (
    <Root className={classes.root}>
      <div className={classes.retrievePass}>
        {state.isLoading && <LinearProgress />}
        <div>
          <div className={classes.imageContainer}>
            <img
              className={classes.logoImg}
              src="https://res.cloudinary.com/renderinghouse/image/upload/anewgo/assets/custom/anewgo-logo.png"
              alt="Anewgo Logo"
            />
          </div>
          <div>
            <Typography
              classes={{
                root: `${classes.messageSuccess} ${classes.typography}`,
              }}
            >
              {state.message}
            </Typography>
            {!state.isEmailSent && (
              <Button
                classes={{ root: classes.button }}
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                onClick={(): unknown => handleSubmit()}
              >
                Send Email
              </Button>
            )}
          </div>
        </div>
      </div>
    </Root>
  )
}

export default EmailRetrievePage
