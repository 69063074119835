import { gql } from '@apollo/client'

export const GET_CLIENT_SUBSCRIPTION_INFO = gql`
  query getColonnadeClientSubscriptionInfo($clientId: Int!) {
    getColonnadeClientSubscriptionInfo(clientId: $clientId) {
      numberOfLicenses
      subscriptionLevel
      subscriptionDate
      rolePrivilegeObject
      anniversaryNotificationState
    }
  }
`
export const GET_CLIENT_REPORT_INFO = gql`
  query getClientReportInfo($clientId: Int!) {
    getClientEmailReportInfo(clientId: $clientId) {
      reportId
      firstName
      lastName
      email
      frequency
      clientId
    }
  }
`

export const SET_COLONNADE_CLIENTS_LICENSES = gql`
  mutation SET_COLONNADE_CLIENTS_LICENSES(
    $clientId: Int!
    $numberOfLicenses: Int!
  ) {
    setColonnadeClientLicenses(
      clientId: $clientId
      numberOfLicenses: $numberOfLicenses
    )
  }
`
export const DELETE_COLONNADE_CLIENT_EMAIL_REPORT_INFO = gql`
  mutation DELETE_COLONNADE_CLIENT_EMAIL_REPORT_INFO($reportId: Int!) {
    deleteColonnadeClientEmailReportInfo(reportId: $reportId)
  }
`

export const ADD_COLONNADE_CLIENT_EMAIL_REPORT_INFO = gql`
  mutation ADD_COLONNADE_CLIENT_EMAIL_REPORT_INFO(
    $clientId: Int!
    $firstName: String
    $lastName: String
    $email: String!
    $frequency: ColonnadeReportFrequencyEnum!
  ) {
    addColonnadeClientEmailReportInfo(
      clientId: $clientId
      firstName: $firstName
      lastName: $lastName
      email: $email
      frequency: $frequency
    ) {
      reportId
      firstName
      lastName
      email
      frequency
      clientId
    }
  }
`

export const UPDATE_COLONNADE_CLIENT_EMAIL_REPORT_INFO = gql`
  mutation UPDATE_COLONNADE_CLIENT_EMAIL_REPORT_INFO(
    $reportId: Int!
    $firstName: String
    $lastName: String
    $email: String!
    $frequency: ColonnadeReportFrequencyEnum!
  ) {
    updateColonnadeClientEmailReportInfo(
      reportId: $reportId
      firstName: $firstName
      lastName: $lastName
      email: $email
      frequency: $frequency
    ) {
      reportId
      firstName
      lastName
      email
      frequency
      clientId
    }
  }
`

export const GET_IS_USER_SUPER_ADMIN = gql`
  query isUserSuperAdmin($userId: Int!) {
    isUserSuperAdmin(userId: $userId)
  }
`

export const GET_IS_USER_ANEWGO_ADMIN = gql`
  query isUserAnewgoAdmin($userId: Int!) {
    isUserAnewgoAdmin(userId: $userId)
  }
`

export const SET_COLONNADE_CLIENTS_SUBS_LEVEL = gql`
  mutation SET_COLONNADE_CLIENTS_SUBS_LEVEL(
    $clientId: Int!
    $subscriptionLevel: ColonnadeSubscriptionLevelsEnum!
  ) {
    setColonnadeClientSubsLevel(
      clientId: $clientId
      subscriptionLevel: $subscriptionLevel
    )
  }
`
export const SET_COLONNADE_ROLE_PERMISSION = gql`
  mutation SET_COLONNADE_ROLE_PERMISSION(
    $roleName: ColonnadeRolesEnum!
    $privilege: [ColonnadeRolePrivilegesEnum!]!
  ) {
    setRolePermission(roleName: $roleName, privilege: $privilege)
  }
`

export const SET_COL_SUB_ANNIV_NOTIF = gql`
  mutation setColSubsAnnivNotif($clientId: Int!, $statement: Boolean!) {
    setColSubsAnnivNotif(clientId: $clientId, statement: $statement)
  }
`

export const REQUEST_COLONNADE_LICENSE_UPGRADE = gql`
  mutation requestColonnadeLicenseUpgrade(
    $clientName: String!
    $numberOfRequestedLicense: Int!
    $requesterEmail: String!
  ) {
    requestColonnadeLicenseUpgrade(
      clientName: $clientName
      numberOfRequestedLicense: $numberOfRequestedLicense
      requesterEmail: $requesterEmail
    )
  }
`

export const UPDATE_COLONNADE_SUBSCRIPTION_DATE = gql`
  mutation updateColonnadeSubscriptionDate(
    $newSubscriptionDate: Date!
    $clientId: Int!
  ) {
    updateColonnadeSubscriptionDate(
      newSubscriptionDate: $newSubscriptionDate
      clientId: $clientId
    )
  }
`
