import React, { useContext, useState } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import Divider from '@mui/material/Divider'
import ExitToApp from '@mui/icons-material/ExitToApp'
import PowerIcon from '@mui/icons-material/Power'
import LeadsInsightIcon from 'components/icons/LeadsInsightIcon'
import AnalyticsInsightIcon from 'components/icons/AnalyticsInsightIcon'
import hydrationStore from 'store/HydrationStore'
import {
  ANALYTICS_APP,
  LEADS_APP,
  MARKETING_APP,
  STARTER_OR_PRO_INVALID_FEATURE_MESSAGE,
} from '../../../../constants'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'
import { AppStore } from 'store'

import {
  isAnewgoMember,
  userHasSubscriptionAccess,
} from 'utils/authorizationHelpers'
import { MenuIf } from '../MainDrawerContent'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import { SubscriptionTooltip } from '../../../icons/SubscriptionTooltip'
import MarketingAutomationIcon from '../../../icons/MarketingAutomationIcon'
import { ColonnadeSubscriptionLevelsEnum } from '../../../../graphql/gen-types'
import { AppActionType } from '../../../../store/reducers'
import {
  INTEGRATION_INDEX,
  RESERVATIONS_INDEX,
  ANALYTICS_INDEX,
  SIGN_OUT_INDEX,
} from '../constants'

export default function BuyOnlineMenu({
  selectedClientName,
  classes,
  navigate,
  handleSignOut,
  handleInsightClick,
  handleSelectBuilderClick,
  roleId,
  handleDrawerToggle,
  selectedMenuIndex,
}: MenuIf): JSX.Element {
  const appStore = useContext(AppStore)

  const marketingAutomationEnabled = userHasSubscriptionAccess([
    ColonnadeSubscriptionLevelsEnum.Enterprise,
  ])

  const handleBuyOnlineMenuClick = (
    index: React.SetStateAction<number>
  ): void => {
    // re-route
    let route = ''
    switch (index) {
      case INTEGRATION_INDEX:
        route = `reserve-online/client/${selectedClientName}/integration`
        break
      case RESERVATIONS_INDEX:
        route = `reserve-online/client/${selectedClientName}/reservations`
        break
      case ANALYTICS_INDEX:
        route = `reserve-online/client/${selectedClientName}/analytics`
        break
      case SIGN_OUT_INDEX:
        route = '/login'
        hydrationStore.clearAll() // clear all local storage variables
        handleSignOut() // reInitialize props
        break
      default:
        route = '/'
        break
    }
    navigate(route)

    if (handleDrawerToggle) {
      handleDrawerToggle()
    }
  }

  const handleMarketingAutomationClick = (): void => {
    const route = `/targeted-marketing/client/${selectedClientName}/email-campaign`
    appStore.dispatch({
      type: AppActionType.SET_SELECTED_APP,
      payload: MARKETING_APP,
    })
    navigate(route)
  }

  return (
    <>
      <List
        component="nav"
        aria-labelledby="leads-reports"
        subheader={
          <ListSubheader component="div" id="leads-reports">
            Reserve online
          </ListSubheader>
        }
      >
        <List>
          <ListItem
            classes={{ selected: classes.selected }}
            button
            onClick={(): void => handleBuyOnlineMenuClick(INTEGRATION_INDEX)}
            selected={selectedMenuIndex === INTEGRATION_INDEX}
          >
            <ListItemIcon>
              <PowerIcon />
            </ListItemIcon>
            <ListItemText primary="Integration" />
          </ListItem>
          <ListItem
            classes={{ selected: classes.selected }}
            button
            onClick={(): void => handleBuyOnlineMenuClick(RESERVATIONS_INDEX)}
            selected={selectedMenuIndex === RESERVATIONS_INDEX}
          >
            <ListItemIcon>
              <FormatListBulletedIcon />
            </ListItemIcon>
            <ListItemText primary="Reservations" />
          </ListItem>
          <ListItem
            classes={{ selected: classes.selected }}
            button
            onClick={(): void => handleBuyOnlineMenuClick(ANALYTICS_INDEX)}
            selected={selectedMenuIndex === ANALYTICS_INDEX}
          >
            <ListItemIcon>
              <TrendingUpIcon />
            </ListItemIcon>
            <ListItemText primary="Analytics" />
          </ListItem>
        </List>
      </List>
      <Divider />
      <List>
        <ListItem
          button
          classes={{ selected: classes.selected }}
          onClick={() => handleInsightClick(ANALYTICS_APP)}
        >
          <ListItemIcon classes={{ root: classes.iconNormalColor }}>
            <AnalyticsInsightIcon width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={'Analytics Insights'} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          button
          classes={{ selected: classes.selected }}
          onClick={() => handleInsightClick(LEADS_APP)}
        >
          <ListItemIcon classes={{ root: classes.iconNormalColor }}>
            <LeadsInsightIcon width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={'Leads Insights'} />
        </ListItem>
      </List>
      <Divider />
      <List>
        {!marketingAutomationEnabled && (
          <SubscriptionTooltip
            iconStyle={'marketingAutomationIcon'}
            message={STARTER_OR_PRO_INVALID_FEATURE_MESSAGE}
          />
        )}
        <ListItem
          button
          classes={{ selected: classes.selected }}
          disabled={!marketingAutomationEnabled}
          onClick={() => handleMarketingAutomationClick()}
        >
          <ListItemIcon classes={{ root: classes.iconNormalColor }}>
            <MarketingAutomationIcon width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={'Targeted Marketing'} />
        </ListItem>
      </List>
      <Divider />
      <List>
        {isAnewgoMember(roleId) && (
          <ListItem
            classes={{ selected: classes.selected }}
            button
            onClick={(): void => handleSelectBuilderClick()}
          >
            <ListItemIcon>
              <KeyboardReturnIcon />
            </ListItemIcon>
            <ListItemText primary={'Select Builder'} />
          </ListItem>
        )}
        <ListItem
          button
          classes={{ selected: classes.selected }}
          onClick={(): void => handleBuyOnlineMenuClick(SIGN_OUT_INDEX)}
        >
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary={'Sign Out'} />
        </ListItem>
      </List>
    </>
  )
}
