import React from 'react'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'

const PREFIX = 'LineSeparator'

const classes = {
  rootContainer: `${PREFIX}-rootContainer`,
  title: `${PREFIX}-title`,
  root: `${PREFIX}-root`,
  square: `${PREFIX}-square`,
  moreSquares: `${PREFIX}-moreSquares`,
  rect: `${PREFIX}-rect`,
  smSquare: `${PREFIX}-smSquare`,
  leftSq: `${PREFIX}-leftSq`,
  rightSq: `${PREFIX}-rightSq`,
}

const LineSeparatorStyled = styled('div')(({ theme }) => ({
  [`&.${classes.rootContainer}`]: {
    position: 'relative',
  },

  [`& .${classes.title}`]: {
    fontFamily: 'Roboto, sans-serif',
    position: 'absolute',
    color: '#3c3b3b',
    left: 200,
    top: -14,
    [theme.breakpoints.down('md')]: {
      top: -20,
      left: 84,
      fontSize: '1.2rem',
    },
  },

  [`& .${classes.root}`]: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '80px 80px',
    gridTemplateRows: '1fr',
    height: 80,
    width: 'inherit',
    '&:before': {
      borderTop: baseColor, // TODO: add color to plaette
      //  `${theme.palette.bannerColor.main}`
      //   ? `2px solid ${theme.palette.bannerColor.main}`
      //   : `2px solid ${baseColor}`,
      content: '""',
      position: 'absolute',
      top: '50%',
      left: 90,
      right: 0,
      bottom: 0,
      zIndex: -1,
      width: '94%',
    },
    [theme.breakpoints.down('md')]: {
      height: 40,
      gridTemplateColumns: '40px 40px',
      '&:before': {
        left: 42,
        width: '84%',
      },
    },
  },

  [`& .${classes.square}`]: {
    width: '100%',
    height: '100%',
    backgroundColor: baseColor, // `${theme.palette.bannerColor.main}` || baseColor,
  },

  [`& .${classes.moreSquares}`]: {
    display: 'grid',
    width: '100%',
    height: '100%',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
  },

  [`& .${classes.rect}`]: {
    gridColumnStart: 1,
    gridColumnEnd: 3,
    backgroundColor: baseColor, //  `${theme.palette.bannerColor.main}` || `${baseColor}`,
    margin: 8,
    marginTop: 0,
    marginBottom: 6,
    [theme.breakpoints.down('md')]: {
      margin: 2,
      marginTop: 0,
    },
  },

  [`& .${classes.smSquare}`]: {
    backgroundColor: baseColor, // `${theme.palette.bannerColor.main}` || `${baseColor}`,
    margin: 8,
    [theme.breakpoints.down('md')]: {
      margin: 2,
    },
  },

  [`& .${classes.leftSq}`]: {
    marginRight: 4,
    marginBottom: 4,
    [theme.breakpoints.down('md')]: {
      margin: 2,
      marginTop: 4,
      marginBottom: 0,
    },
  },

  [`& .${classes.rightSq}`]: {
    marginBottom: 4,
    marginLeft: 4,
    [theme.breakpoints.down('md')]: {
      margin: 2,
      marginTop: 4,
      marginBottom: 0,
    },
  },
}))

const baseColor = '#427eeb'

type LineSeparatorProps = {
  title: string
}

export default function LineSeparator({
  title,
}: LineSeparatorProps): JSX.Element {
  return (
    <LineSeparatorStyled className={classes.rootContainer}>
      {title && (
        <h2 className={classes.title}>
          <span>{title}</span>
        </h2>
      )}
      <div className={classes.root}>
        <div className={classes.square} />
        <div className={classes.moreSquares}>
          <div className={classes.rect} />
          <div className={clsx(classes.smSquare, classes.leftSq)} />
          <div className={clsx(classes.smSquare, classes.rightSq)} />
        </div>
      </div>
    </LineSeparatorStyled>
  )
}
