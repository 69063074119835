import {
  split,
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from '@apollo/client/link/ws'
import { onError } from '@apollo/link-error'
import { setContext } from '@apollo/link-context'
import hydrationStore from '../store/HydrationStore'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import ReactDOM from 'react-dom'
import React from 'react'
import UnexpectedErrorDialog, {
  errorMessageToBypass,
} from '../components/common/UnexpectedErrorDialog'
import TokenExpiredModal from '../auth/TokenExpiredModal'

const uri =
  process.env.REACT_APP_FOYER_SERVER || process.env.STORYBOOK_API_SERVER

const websocketUri =
  process.env.REACT_APP_NEXUS_API_SUBSCRIPTIONS_SERVER ||
  'ws://localhost:5001/graphql'

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    let msg = ''
    graphQLErrors.forEach(({ message, locations, path }) => {
      msg += `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
        locations
      )}, Path: ${path}`
      msg += '\n'
    })
    // console.log(msg)
    // Instead of throwing an error to crash the page, we'll simply log them for now.

    if (msg.toLowerCase().includes('jwt')) {
      // The error handler is currently used to set error message to state inside ColonnadeApp
      // If the JWT token is invalid TokenExpiredModal is displayed
      // User will be logged out and hydration store will be cleared
      const container = document.createElement('div')
      ReactDOM.render(<TokenExpiredModal />, container)
    } else if (verifyUserAuthError(msg)) {
      // do nothing since it's handled by unAuthorized component do display if auth-directive error.
      return
      // no need to display a modal for errors popping on login side such as no subscription/license because we use a snackbar error in login.
    } else if (
      !errorMessageToBypass.some((errorBypass) => msg.includes(errorBypass))
    ) {
      const container = document.createElement('div')
      const handleClose = () => {
        ReactDOM.unmountComponentAtNode(container)
      }
      ReactDOM.render(
        <UnexpectedErrorDialog open={true} error={msg} onClose={handleClose} />,
        container
      )
    }
  }
  if (networkError) console.log(`[Network error]: ${networkError}`)
  // throw new Error(`[Network error]: ${networkError}`)
})

const httpLink = new HttpLink({ uri })

const wsLink = new WebSocketLink({
  uri: websocketUri,
  options: {
    reconnect: true,
    // Note that we're assigning a function to connectionParams. This is to make sure we try reconnecting with the newly
    // signed-in user's token after we've failed to connect with the "null" token. See this github issue and solution:
    // https://github.com/apollographql/apollo-link/issues/197
    connectionParams: () => ({
      token: hydrationStore.token || '',
    }),
  },
})

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

const authLink = setContext((_, { headers }) => {
  const token = hydrationStore.token
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

function __createClient(): ApolloClient<NormalizedCacheObject> {
  const client = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        ClientEmailReportInfo: {
          keyFields: (object, context) => {
            return `${context.typename}:${object.reportId}`
          },
        },
        LeadRanking: {
          keyFields: (object, context) => {
            return `${context.typename}:${object.user}`
          },
        },
      },
    }),
    link: errorLink.concat(authLink).concat(splitLink),
    connectToDevTools: true,
  })
  return client
}

const client = (): ApolloClient<NormalizedCacheObject> => __createClient()

export { client }
