import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import ClearIcon from '@mui/icons-material/Clear'
import DatePicker from '@mui/lab/DatePicker'
import DesktopTimePicker from '@mui/lab/DesktopTimePicker'
import DateFnsAdapter from '@date-io/date-fns'
import FormLabel from '@mui/material/FormLabel'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import TextField from '@mui/material/TextField'

const PREFIX = 'DateTimePicker'

const classes = {
  dateTimePicker: `${PREFIX}-dateTimePicker`,
  mobileDateTimePicker: `${PREFIX}-mobileDateTimePicker`,
  timePicker: `${PREFIX}-timePicker`,
  datePicker: `${PREFIX}-datePicker`,
  clearIcon: `${PREFIX}-clearIcon`,
}

const DateTimePickerStyled = styled(FormControl)(({ theme }) => ({
  [`& .${classes.dateTimePicker}`]: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },

  [`& .${classes.mobileDateTimePicker}`]: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.timePicker}`]: {
    display: 'flex',
    flexDirection: 'row',
  },

  [`& .${classes.datePicker}`]: {
    marginRight: theme.spacing(2),
  },

  [`& .${classes.clearIcon}`]: {
    marginTop: theme.spacing(2.5),
    cursor: 'pointer',
  },
}))

const dateFns = new DateFnsAdapter()

export type DatePick = {
  isValid?: boolean
  date?: Date | null
}

type DateTimePickerProps = {
  uiConfig: any
  label: string
  dateTime?: Date | null
  onChange: (pick: DatePick) => void
}

/*
 * This is custom Date Time Picker component that can be used as from control.
 * It uses components from @material-ui/pickers internally.
 * It returns object { isValid: Boolean, date: Date }
 * isValid is used because we need to tell parent component if a valid date is set.
 * It return isValid: false if one input is set but the other is not.
 * It returns date: null if both inputs are empty.
 */
export default function DateTimePicker({
  uiConfig,
  label,
  dateTime,
  onChange,
}: DateTimePickerProps): JSX.Element {
  const DATE_FORMAT = 'MM/DD/YYYY'
  const TIME_FORMAT = 'hh:mm A'

  const initialDateTime = dateTime || new Date()

  const [error, setError] = useState(false)
  const [date, setDate] = useState<Date | null>()
  const [time, setTime] = useState<Date | null>()
  const [dateString, setDateString] = useState(
    dateFns.format(initialDateTime, DATE_FORMAT)
  )
  const [timeString, setTimeString] = useState(
    dateFns.format(initialDateTime, TIME_FORMAT)
  )

  const handleDateChange = (date: MaterialUiPickersDate) => {
    setDate(date)
    if (date !== null) {
      const newDateString = dateFns.format(date, DATE_FORMAT)
      setDateString(newDateString)

      if (time === null) {
        setError(true)
        onChange({ isValid: false })
      } else {
        setError(false)
        const dateTime = new Date(newDateString + ' ' + timeString)
        onChange({ isValid: true, date: dateTime })
      }
    } else {
      setDateString('')
    }
  }

  const handleTimeChange = (time: MaterialUiPickersDate) => {
    setTime(time)
    if (time !== null) {
      const newTimeString = dateFns.format(time, TIME_FORMAT)
      setTimeString(newTimeString)
      if (date === null) {
        setError(true)
        onChange({ isValid: false })
      } else {
        setError(false)
        const dateTime = new Date(dateString + ' ' + newTimeString)
        onChange({ isValid: true, date: dateTime })
      }
    } else {
      setDateString('')
    }
  }

  const clearDateTime = () => {
    setDate(null)
    setTime(null)
    setTimeString('')
    setDateString('')
    onChange({ isValid: true, date: null })
  }

  return (
    <DateTimePickerStyled variant="standard">
      <FormLabel>{label}</FormLabel>
      <div
        className={
          uiConfig.mobile
            ? classes.mobileDateTimePicker
            : classes.dateTimePicker
        }
      >
        <DatePicker
          //fullWidth
          className={classes.datePicker}
          showToolbar
          disablePast
          label="Date"
          value={date}
          onChange={handleDateChange}
          inputFormat={DATE_FORMAT}
          renderInput={(params) => <TextField {...params} variant="standard" />}
        />
        <div className={classes.timePicker}>
          <DesktopTimePicker
            //fullWidth
            label="Time"
            //keyboardIcon={<AccessTimeIcon />}
            value={time}
            onChange={handleTimeChange}
            mask="__:__ _M"
            renderInput={(params) => (
              <TextField {...params} variant="standard" />
            )}
          />
          <ClearIcon onClick={clearDateTime} className={classes.clearIcon} />
        </div>
      </div>
      {error && (
        <FormHelperText error>Please select both date and time.</FormHelperText>
      )}
    </DateTimePickerStyled>
  )
}
