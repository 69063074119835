import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import { StyledDialog, classes } from './RejectReservationModalStyles'
import { useEventTracker } from '../../../hooks/tracking'
import { ReservationTrackEvents } from '../reservationTrackEvents'

interface RejectReservationProps {
  isDialogOpened: boolean
  handleSubmitDialog: (rejectReason: string) => void
  handleClose: () => void
  reservationId: string
}

const defaultReason = {
  value: '',
  error: '',
  warning:
    'Are you sure you want to leave the reason empty? If yes, click "Confirm and Continue" again.',
}

const RejectReservationModal = (props: RejectReservationProps): JSX.Element => {
  const [reason, setReason] = useState<{
    value: string
    error: string
    warning: string
  }>(defaultReason)
  const track = useEventTracker()

  const handleSubmit = () => {
    track(
      ReservationTrackEvents.CLICKED_CONFIRM_AND_CONTINUE_IN_REJECT_DIALOG,
      {
        reservationId: props.reservationId,
      }
    )
    if (!reason.error) {
      props.handleSubmitDialog(reason.value)
      resetReason()
    }
  }

  const setReasonError = (error: string) => {
    setReason((prev) => ({
      ...prev,
      error,
    }))
  }

  const setReasonWarning = (warning: string) => {
    setReason((prev) => ({
      ...prev,
      warning,
    }))
  }

  const setReasonValue = (value: string) => {
    setReason((prev) => ({
      ...prev,
      value,
    }))
  }

  const resetReason = () => setReason(defaultReason)

  // Validate textarea reject reason
  const validate = (reason: string) => {
    let error = ''
    let warning = ''

    if (reason?.length <= 0) {
      warning =
        'Are you sure you want to leave the reason empty? If yes, click "Confirm and Continue".'
    } else if (reason?.length > 255) {
      error = 'Max length is 255 characters!'
    }

    setReasonWarning(warning)
    setReasonError(error)
  }

  const onReasonChange = (newReason: string) => {
    validate(newReason)
    setReasonValue(newReason)
  }

  const handleClose = () => {
    track(ReservationTrackEvents.CLICKED_CANCEL_IN_REJECT_DIALOG, {
      reservationId: props.reservationId,
    })
    resetReason()
    props.handleClose()
  }

  const isMessageDisplayed = !!reason.error || !!reason.warning

  return (
    <StyledDialog
      classes={{ container: classes.container }}
      open={props.isDialogOpened}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
    >
      <IconButton
        className={classes.closeButton}
        color="inherit"
        onClick={props.handleClose}
        aria-label="Close"
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle id="form-dialog-title"> Reject Reservation </DialogTitle>
      <form>
        <DialogContent>
          Are you sure you want to reject this reservation? Once the reservation
          is rejected, the builder will have to start the refund process with
          the buyer.
        </DialogContent>
        <FormControl
          className={classes.textArea}
          error={isMessageDisplayed}
          variant="standard"
        >
          <TextField
            variant="standard"
            label="Reason"
            multiline
            maxRows={4}
            rows={4}
            InputLabelProps={{
              shrink: true,
            }}
            value={reason.value}
            onChange={(e) => onReasonChange(e.target.value)}
          />
          {isMessageDisplayed && (
            <FormHelperText>{reason.error || reason.warning}</FormHelperText>
          )}
        </FormControl>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!!reason.error}
            onClick={handleSubmit}
          >
            Confirm and Continue
          </Button>
        </DialogActions>
      </form>
    </StyledDialog>
  )
}

export default RejectReservationModal
