import Checkbox from '@mui/material/Checkbox'
import { styled } from '@mui/material/styles'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React, { useContext, useMemo } from 'react'

import {
  useGetProspectsQuery,
  useGetAgentsQuery,
  Prospect,
  Agent,
  Community,
  useGetUsersQuery,
  useAssignProspectToColonnadeAgentMutation,
  useUnassignProspectFromColonnadeAgentMutation,
} from 'graphql/gen-types'
import hydrationStore from 'store/HydrationStore'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import jwtDecode from 'jwt-decode'
import ComponentCard from 'components/common/layout/ComponentCard'
import { AppStore } from 'store'
import LeadsAssignmentFilterBar from '../filter-bar/LeadsAssignmentFilterBar'
import { ConfirmAlert } from 'components/utils/ConfirmAlert'

const PREFIX = 'AdminAssignAgentOfProspect'

const classes = {
  table: `${PREFIX}-table`,
  title: `${PREFIX}-title`,
  container: `${PREFIX}-container`,
  root: `${PREFIX}-root`,
  headerFilter: `${PREFIX}-headerFilter`,
  prospectEmail: `${PREFIX}-prospectEmail`,
}

const AdminAssignAgentOfProspectStyled = styled('div')(({ theme }) => ({
  [`& .${classes.table}`]: {
    minWidth: 800,
    height: '100%',
  },

  [`& .${classes.title}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '40px',
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.light
        : theme.palette.primary.main,
  },

  [`& .${classes.container}`]: {
    maxHeight: 700,
  },

  [`& .${classes.root}`]: {
    '& > *': {
      margin: theme.spacing(5),
    },
    border: theme.palette.mode === 'dark' ? '1px solid #ced4da' : '',
    overflowY: 'scroll',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',
    width: '100%',
    height: `calc(100vh - 116px)`,
  },

  [`& .${classes.headerFilter}`]: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '2em',
  },

  [`& .${classes.prospectEmail}`]: {
    color: 'gray',
    fontSize: '11px',
  },
}))

type ProspectInfo = Pick<
  Prospect,
  'name' | 'email' | 'id' | 'agents' | 'colonnadeAgents'
>

interface AdminAssignAgentOfProspectProps {
  selectedCommunities: Community[]
}

interface Named {
  firstName?: string | null
  lastName?: string | null
}

const getName = (user: Named) => {
  return [user.firstName, user.lastName].filter(Boolean).join(' ')
}

async function asyncNoop() {
  return
}

const AdminAssignAgentOfProspect = ({
  selectedCommunities,
}: AdminAssignAgentOfProspectProps): JSX.Element => {
  const { appState } = useContext(AppStore)
  const { selectedClient } = appState
  const clientName = selectedClient?.altName || ''

  // utilities
  const [searchAgent, setSearchAgent] = React.useState<string>('')
  const [searchProspect, setSearchProspect] = React.useState<string>('')

  const [
    showSelectedProspects,
    setShowSelectedProspects,
  ] = React.useState<boolean>(false)
  const [
    showNotSelectedProspects,
    setShowNotSelectedProspects,
  ] = React.useState<boolean>(false)

  // page pagination
  const [prospectPerPage, setProspectPerPage] = React.useState(10)
  const [page, setPage] = React.useState(0)

  const [errorText, setErrorText] = React.useState<string | null>(null)

  const [
    assignProspectToAgentMutation,
  ] = useAssignProspectToColonnadeAgentMutation({
    optimisticResponse: {
      assignProspectToColonnadeAgent: true,
    },
    onError(error) {
      setErrorText(error.message)
    },
  })

  const [
    unassignProspectFromAgentMutation,
  ] = useUnassignProspectFromColonnadeAgentMutation({
    optimisticResponse: {
      unassignProspectFromColonnadeAgent: true,
    },
    onError(error) {
      setErrorText(error.message)
    },
  })

  const assignProspectToAgent = (prospectId: number, userId: number) => {
    assignProspectToAgentMutation({
      variables: {
        assignments: [
          {
            prospectId,
            userId,
          },
        ],
        clientName,
      },
      update(cache, response) {
        if (response.data?.assignProspectToColonnadeAgent) {
          cache.modify({
            id: `Prospect:${prospectId}`,
            fields: {
              colonnadeAgents(ids: number[]) {
                return [...ids, userId]
              },
            },
          })
        } else {
          setErrorText('Unable to assign prospect to agent!')
        }
      },
    })
  }

  const unassignProspectFromAgent = (prospectId: number, userId: number) => {
    unassignProspectFromAgentMutation({
      variables: {
        unassignments: [
          {
            prospectId,
            userId,
          },
        ],
        clientName,
      },
      update(cache, response) {
        if (response.data?.unassignProspectFromColonnadeAgent) {
          cache.modify({
            id: `Prospect:${prospectId}`,
            fields: {
              colonnadeAgents(ids: number[]) {
                return ids.filter((id) => id !== userId)
              },
            },
          })
        } else {
          setErrorText('Unable to unassign prospect from agent!')
        }
      },
    })
  }

  const hydratedToken = hydrationStore.token || ''
  const { colonnadeRoleId } = jwtDecode(hydratedToken)

  // Query all data
  const {
    data: prospectData,
    error: prospectError,
    loading: prospectLoading,
  } = useGetProspectsQuery({
    variables: {
      clientName: clientName || '',
      communityIds: selectedCommunities.map((community) => community.id),
    },
    fetchPolicy: 'cache-first',
  })

  const {
    data: agentsData,
    error: agentsError,
    loading: agentsLoading,
  } = useGetAgentsQuery({
    variables: {
      clientName: clientName || '',
    },
    fetchPolicy: 'cache-first',
  })

  const {
    data: usersData,
    error: usersError,
    loading: usersLoading,
  } = useGetUsersQuery({
    variables: {
      clientName: clientName || '',
    },
    fetchPolicy: 'cache-first',
  })

  const allProspects = useMemo(() => {
    const prospects =
      prospectData?.colonnadeApiProspect?.filter(
        // remove undefined values
        (prospect): prospect is Prospect => !!prospect?.id
      ) || []

    return prospects.map((prospect: Prospect | null) => ({
      ...prospect,
      id: prospect?.id || 0,
      agents: prospect?.agents || [],
      colonnadeAgents: prospect?.colonnadeAgents || [],
    }))
  }, [prospectData?.colonnadeApiProspect])

  const allAgents = useMemo(
    () => agentsData?.agentsByClient?.filter(Boolean) || [],
    [agentsData?.agentsByClient]
  )

  const allUsers = useMemo(
    () => usersData?.colonnadeUsers?.filter(Boolean) || [],
    [usersData?.colonnadeUsers]
  )

  const filteredProspects = useMemo(() => {
    const prospectsByName = searchProspect
      ? allProspects.filter((prospect) =>
          prospect?.name?.toLowerCase()?.includes(searchProspect.toLowerCase())
        )
      : allProspects

    if (showSelectedProspects) {
      return prospectsByName.filter(
        (prospect) =>
          prospect.agents.length > 0 || prospect.colonnadeAgents.length > 0
      )
    } else if (showNotSelectedProspects) {
      return prospectsByName.filter(
        (prospect) =>
          prospect.agents.length === 0 && prospect.colonnadeAgents.length === 0
      )
    } else {
      return prospectsByName
    }
  }, [
    allProspects,
    searchProspect,
    showSelectedProspects,
    showNotSelectedProspects,
  ])

  const filteredUsers = useMemo(() => {
    if (!searchAgent) return allUsers
    return allUsers.filter((user) =>
      getName(user).toLowerCase().includes(searchAgent.toLowerCase())
    )
  }, [allUsers, searchAgent])

  const filteredAgents = useMemo(() => {
    if (!searchAgent) return allAgents
    return allAgents.filter((agent) =>
      getName(agent).toLowerCase().includes(searchAgent.toLowerCase())
    )
  }, [allAgents, searchAgent])

  // only allow ADMIN, ANEWGO_ADMIN, ANEWGO_STAFF, SALES_ADMIN.
  if (colonnadeRoleId === 4 || colonnadeRoleId === 5) {
    return (
      <Unauthorized
        message={'Insufficient Role level'}
        imageName={'AdminAssignAgentOfProspect'}
      ></Unauthorized>
    )
  }

  if (agentsLoading || prospectLoading || usersLoading) {
    return <div>...loading</div>
  }

  if (allAgents.length === 0) {
    return <div>No Agents available</div>
  }

  if (allProspects.length === 0) {
    return <div>No Leads available</div>
  }

  const insufficientRole = colonnadeRoleId === 4 || colonnadeRoleId === 5
  // handle errors
  const error = prospectError || agentsError || insufficientRole

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized
          message={error.toString()}
          imageName={'AdminAssignAgentOfProspect'}
        ></Unauthorized>
      )
    }
    return <div>{error.toString()}</div>
  }

  // ######################################################
  // ################### HELPER FUNTION ###################
  // ######################################################

  const renderProspectRows = (
    prospect: ProspectInfo,
    i: number
  ): JSX.Element | string => {
    const prospectEmail = prospect?.email || ''

    const assignedAgentsId = prospect?.agents?.map(
      (prospectAgent) => prospectAgent.agentId
    )

    const relevantAgents = filteredAgents.filter(
      ({ id }) => id && assignedAgentsId?.includes(id)
    )

    const displayAgent = (agent: Agent) => {
      return (
        <div key={agent.id}>
          {agent.firstName} {agent.lastName}
          <Typography className={classes.prospectEmail}>
            ({agent.email})
          </Typography>
        </div>
      )
    }

    const element = (
      <TableRow key={i}>
        <TableCell component="th" scope="row">
          {`${prospect.name}`}
          <Typography className={classes.prospectEmail}>
            ({prospectEmail})
          </Typography>
        </TableCell>
        <TableCell>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: '12px',
            }}
          >
            {relevantAgents.map(displayAgent)}
          </div>
        </TableCell>
        {filteredUsers.map((user) => {
          const checked =
            !!user.id && prospect.colonnadeAgents.includes(user.id)
          const toggleAssignment = checked
            ? unassignProspectFromAgent
            : assignProspectToAgent

          return (
            <TableCell key={user.id}>
              <Checkbox
                checked={checked}
                onClick={() => toggleAssignment(prospect.id, user.id!)}
                name={`${prospect}${i}`}
                inputProps={{ 'aria-label': 'E' }}
                size="small"
              />
            </TableCell>
          )
        })}
      </TableRow>
    )

    return element
  }

  // ######################################################
  // ################### ALL HANDLERS #####################
  // ######################################################

  const handleSearchProspect = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setSearchProspect(event.target.value)
  }

  const handleSearchAgent = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setSearchAgent(event.target.value)
  }

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage)
  }

  const handleChangeProspectPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setProspectPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <AdminAssignAgentOfProspectStyled>
      <ComponentCard title="Lead Assignments">
        {errorText && (
          <ConfirmAlert
            isConfirmationOpen={!!errorText}
            setIsConfirmationOpen={() => setErrorText(null)}
            isErrorWhenApiCall={true}
            message={errorText}
            callbackWhenPromptAgree={asyncNoop}
            callbackWhenPromptCancel={asyncNoop}
          />
        )}

        <div className={classes.headerFilter}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showSelectedProspects}
                        onChange={() => {
                          setShowSelectedProspects(!showSelectedProspects)
                          setShowNotSelectedProspects(false)
                        }}
                        name="checkProspect"
                        data-testid="assign-agents-assigned-checkbox"
                      />
                    }
                    label="Assigned Prospects"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showNotSelectedProspects}
                        onChange={() => {
                          setShowNotSelectedProspects(!showNotSelectedProspects)
                          setShowSelectedProspects(false)
                        }}
                        name="checkProspect"
                      />
                    }
                    label="Unassigned Prospects"
                    data-testid="assign-agents-unassigned-checkbox"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="outlined-basic"
                    label="Search Prospect"
                    variant="outlined"
                    onChange={handleSearchProspect}
                    data-testid="assign-agents-search-prospect"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="outlined-basic"
                    label="Search Agent"
                    variant="outlined"
                    onChange={handleSearchAgent}
                    data-testid="assign-agents-search-agent"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <TableContainer component={Paper} className={classes.container}>
          <Table
            className={classes.table}
            stickyHeader
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: '8rem' }}>Prospects</TableCell>
                <TableCell style={{ minWidth: '36rem' }}>
                  MyHome Agents
                </TableCell>
                {filteredUsers.map((user) => (
                  <TableCell key={user.id} style={{ minWidth: '8rem' }}>
                    {getName(user)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {showSelectedProspects !== null &&
                filteredProspects
                  .slice(
                    page * prospectPerPage,
                    page * prospectPerPage + prospectPerPage
                  )
                  .map((prospect, i) => renderProspectRows(prospect, i))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredProspects.length}
          rowsPerPage={prospectPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeProspectPerPage}
        />
      </ComponentCard>
    </AdminAssignAgentOfProspectStyled>
  )
}

const AdminAssignAgentOfProspectWrapper = (): JSX.Element => {
  const [selectedCommunities, setSelectedCommunities] = React.useState<
    Community[]
  >([])

  return (
    <React.Fragment>
      <LeadsAssignmentFilterBar
        onSelectedCommunities={setSelectedCommunities}
      />
      <AdminAssignAgentOfProspect selectedCommunities={selectedCommunities} />
    </React.Fragment>
  )
}

export default AdminAssignAgentOfProspectWrapper
