import { ReservationBuilderStatus } from '../../../graphql/gen-types'

export enum ReservationIntegrationIdEnum {
  CREATED_ON = 'createdOn',
  BUYER = 'buyer',
  BUYER_PHONE = 'buyerPhone',
  BUYER_EMAIL = 'buyerEmail',
  COMMUNITY = 'community',
  PLAN = 'plan',
  ELEVATION = 'elevation',
  LOT = 'lot',
  RESERVATION_STATUS = 'reservationStatus',
  TOTAL_PRICE = 'totalPrice',
  PAYMENT_STATUS = 'paymentStatus',
  SIGNING_STATUS = 'signingStatus',
  ACTIONS = 'actions',
  REJECT_REASON = 'rejectReason',
}

export enum OrderEnum {
  ASC = 'asc',
  DESC = 'desc',
}

export type ReservationIntegrationArgs = {
  id: ReservationIntegrationIdEnum
  title: string
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
  visible?: boolean
  sortable?: boolean
}

export interface filterChangeArgs {
  buyerInfo: string | null
  communityId: number | null
  status: ReservationBuilderStatus | null
  includeDeleted: boolean
}
