import { gql } from '@apollo/client'

const TEST_SUBSCRIPTION_ENDPOINT = gql`
  mutation testSubscriptionEndpoint(
    $clientName: String!
    $subscriptionLevel: String
  ) {
    testSubscriptionEndpoint(
      clientName: $clientName
      subscriptionLevel: $subscriptionLevel
    )
  }
`

export default TEST_SUBSCRIPTION_ENDPOINT
