import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import useMediaQuery from '@mui/material/useMediaQuery'

import { Plan, Lot, Elevation, ProductFilter } from 'graphql/gen-types'
import { Theme } from '@mui/material/styles'

import ResponsiveTable, {
  ResponsiveCell,
} from 'components/common/tables/ResponsiveTable'
import Typography from '@mui/material/Typography'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import SelectedProductDetails from './components/SelectProductDetails'
import ElevationMultiSelect from './components/ElevationMultiSelect'
import Checkbox from '@mui/material/Checkbox'
import { isDefined } from 'utils/functions'
import Paper from '@mui/material/Paper'

const padding = ({ theme }: { theme: Theme }) => ({
  padding: '2em',
  [theme.breakpoints.down('lg')]: {
    padding: '1em',
  },
  [theme.breakpoints.down('md')]: {
    padding: '8px',
  },
})

export const PaperWithPadding = styled(Paper)(padding)
const BoxWithPadding = styled(Box)(padding)
const DialogContentWithPadding = styled(DialogContent)(padding)

const RESPONSE_WIDTH = 'md' // at 150 pixels, change to responsive table. Putting 0 defaults to 'md' size.
const INITIAL_ROWS = 5

export type PlanProduct = {
  plan: Plan
  elevations: Elevation[]
}

/**
 * Modal for adding selecting product
 */
export default function SelectProductModal({
  plans,
  inventories,
  selectedPlanProducts,
  open,
  onClose,
  onSubmit,
}: {
  plans: Plan[]
  inventories: Lot[]
  selectedPlanProducts: PlanProduct[]
  open: boolean
  onClose: () => void
  onSubmit: (
    planProducts: PlanProduct[],
    inventories: Lot[],
    productOptons: ProductFilter
  ) => void
}): JSX.Element {
  const theme = useTheme()
  const fullWidth = useMediaQuery(theme.breakpoints.down('lg'))
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const [tab, setTab] = React.useState(0)
  const [planProducts, setPlanProducts] = React.useState(selectedPlanProducts)
  const [inventoryProducts, setInventoryProducts] = React.useState<Lot[]>([])

  const [bed, setBed] = useState<number>()
  const [bath, setBath] = useState<number>()
  const [size, setSize] = useState<number>()
  const [cost, setCost] = useState<number>()
  const [exactMatch, setExactMatch] = useState(true)
  const [autoSelect, setAutoSelect] = useState(true)

  const handleClose = () => {
    setPlanProducts(selectedPlanProducts)
    onClose()
  }

  const planProductIds = planProducts.map((product) => product.plan.id)
  const findSelectedProductPlan = (planProduct: PlanProduct) => {
    return planProductIds.indexOf(planProduct.plan.id)
  }
  const handleTabChange = (event: React.ChangeEvent<unknown>, tab: number) => {
    setTab(tab)
  }

  const handleSelectPlanProduct = (planProduct: PlanProduct) => {
    const planProductsNew = [...planProducts]
    const index = findSelectedProductPlan(planProduct)
    if (index === -1) {
      planProductsNew.push(planProduct)
    } else if (planProduct.elevations.length) {
      planProductsNew.splice(index, 1, planProduct)
    } else {
      planProductsNew.splice(index, 1)
    }
    setPlanProducts(planProductsNew)
  }

  const isSelectedInventory = (inventory: Lot) => {
    return inventoryProducts.map((i) => i.id).includes(inventory.id)
  }

  const handleSelectInventoryProduct = (row: unknown) => {
    const inventoryProduct = row as Lot
    const newInventoryProducts = [...inventoryProducts]
    const index = inventoryProducts
      .map((i) => i.id)
      .indexOf(inventoryProduct.id)
    // Add or remove if it is included or not.
    if (index === -1) {
      newInventoryProducts.push(inventoryProduct)
    } else {
      newInventoryProducts.splice(index, 1)
    }
    setInventoryProducts(newInventoryProducts)
  }

  const planColumns = [
    <ResponsiveCell
      responseSize={RESPONSE_WIDTH}
      align="center"
      key="header-cell-plan-name"
    >
      <strong>Plan</strong>
    </ResponsiveCell>,
    <ResponsiveCell
      responseSize={RESPONSE_WIDTH}
      align="center"
      key="header-cell-plan-spec"
    >
      <strong>Specifications</strong>
    </ResponsiveCell>,
    <ResponsiveCell
      responseSize={RESPONSE_WIDTH}
      align="center"
      key="header-cell-plan-elev"
      style={{ lineHeight: '48px' }}
    >
      <strong>Elevations</strong>
    </ResponsiveCell>,
  ]
  const inventoryColumns = [
    <ResponsiveCell
      responseSize={RESPONSE_WIDTH}
      align="center"
      key="header-cell-inv"
    >
      <strong>Lot Address</strong>
    </ResponsiveCell>,
    <ResponsiveCell
      responseSize={RESPONSE_WIDTH}
      align="center"
      key="header-cell-inv-id"
    >
      <strong>Inventory</strong>
    </ResponsiveCell>,
    <ResponsiveCell
      responseSize={RESPONSE_WIDTH}
      align="center"
      key="header-cell-selected"
      style={{ lineHeight: '42px' }}
    >
      <strong>Select</strong>
    </ResponsiveCell>,
  ]

  const generatePlanRow = (plan: Plan): JSX.Element => {
    const elevations = plan.elevations?.filter(isDefined) || []
    return (
      <>
        <ResponsiveCell
          responseSize={RESPONSE_WIDTH}
          align="center"
          key="plan-cell-plan-name"
        >
          {plan.displayName || plan.name}
        </ResponsiveCell>
        <ResponsiveCell
          responseSize={RESPONSE_WIDTH}
          align="center"
          key="plan-cell-plan-spec"
        >
          {`${plan.bed} BD - ${plan.bath} BA - ${plan.size} SQFT${
            plan.cost ? ` - $${plan.cost}` : ''
          }`}
        </ResponsiveCell>
        <ResponsiveCell
          responseSize={RESPONSE_WIDTH}
          align="center"
          key="plan-cell-plan-id"
        >
          {/* {(plan.elevations?.length && plan.elevations[0]?.caption) || '-'}
           */}
          <ElevationMultiSelect
            plan={plan}
            elevations={elevations}
            selectedElevations={
              planProducts.find((product) => product.plan.id === plan.id)
                ?.elevations || []
            }
            onSelectPlanProduct={handleSelectPlanProduct}
          />
        </ResponsiveCell>
      </>
    )
  }

  const generateInventoryRow = (row: Lot): JSX.Element => {
    const inventoryName = row.inventory?.elevation
      ? `${row.inventory?.plan?.name || 'No plan'} ${
          row.inventory?.elevation?.caption || 'No Elevation'
        }`
      : 'Empty Lot'
    return (
      <>
        <ResponsiveCell
          responseSize={RESPONSE_WIDTH}
          align="center"
          key="row-cell-inv-address"
        >
          {row.address ? `${row.address}` : '-'}
        </ResponsiveCell>
        <ResponsiveCell
          responseSize={RESPONSE_WIDTH}
          align="center"
          key="row-cell-inv-name"
        >
          {inventoryName}
        </ResponsiveCell>
        <ResponsiveCell
          responseSize={RESPONSE_WIDTH}
          align="center"
          key="row-cell-selected"
        >
          <Checkbox checked={isSelectedInventory(row)}></Checkbox>
        </ResponsiveCell>
      </>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      scroll="paper"
    >
      <DialogTitle id="form-dialog-title">
        <Typography variant="h5" color="primary">
          Select Product
        </Typography>
      </DialogTitle>
      <DialogContentWithPadding>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="simple tabs example"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Plan 	&#38; Elevations" {...tabProps(0)} />
          <Tab label="Inventory	&#38; Lot" {...tabProps(1)} disabled={false} />
        </Tabs>
        <TabPanel value={tab} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ResponsiveTable
                data={plans}
                columns={planColumns}
                generateRow={generatePlanRow}
                responseSize={RESPONSE_WIDTH}
                initialRows={INITIAL_ROWS}
                dense={true}
              ></ResponsiveTable>
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectedProductDetails
                selectedPlans={planProducts}
                inventories={inventoryProducts}
                bed={bed}
                bath={bath}
                cost={cost}
                size={size}
                exactMatch={exactMatch}
                autoSelect={autoSelect}
                onBedChange={setBed}
                onBathChange={setBath}
                onCostChange={setCost}
                onSizeChange={setSize}
                onExactMatchChange={setExactMatch}
                onAutoSelectChange={setAutoSelect}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ResponsiveTable
                data={inventories}
                columns={inventoryColumns}
                generateRow={generateInventoryRow}
                responseSize={RESPONSE_WIDTH}
                onRowClick={handleSelectInventoryProduct}
                initialRows={INITIAL_ROWS}
                dense={true}
              ></ResponsiveTable>
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectedProductDetails
                selectedPlans={planProducts}
                inventories={inventoryProducts}
                bed={bed}
                bath={bath}
                cost={cost}
                size={size}
                exactMatch={exactMatch}
                autoSelect={autoSelect}
                onBedChange={setBed}
                onBathChange={setBath}
                onCostChange={setCost}
                onSizeChange={setSize}
                onExactMatchChange={setExactMatch}
                onAutoSelectChange={setAutoSelect}
              />
            </Grid>
          </Grid>
        </TabPanel>
      </DialogContentWithPadding>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          Close
        </Button>
        <span style={{ position: 'relative' }}>
          <Button
            onClick={() =>
              onSubmit(planProducts, inventoryProducts, {
                bedRange: bed,
                bathRange: bath,
                sizeRange: size,
                costRange: cost,
                planMatch: exactMatch,
                elevationMatch: exactMatch,
                autoSelect,
              })
            }
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
        </span>
      </DialogActions>
    </Dialog>
  )
}

function tabProps(index: string | number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number | string
  value: number | string
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <BoxWithPadding>{children}</BoxWithPadding>}
    </div>
  )
}
