import React, { useMemo } from 'react'
import SiteplanShapeSVG from './SiteplanShapeSVG'
import {
  Lot,
  LotLegendEntry,
  Siteplan,
  SvgShape,
} from '../../../graphql/gen-types'
import { heatMapScale } from './utils/functions'
import { getTextColor } from 'utils/functions'

const GRAY_FILL = '#d3d3d3'

interface LotSVGProps {
  lot: Lot //change to Lot once we stop using mock data in storybook
  siteplan: Siteplan //change to Siteplan once we stop using moc data in storybook
  colorMode?: boolean
  heatMapMode?: boolean
  highlightMode?: boolean
  hideText?: boolean
  borderColor?: string
  borderWidth?: number
  minPopularity?: number
  maxPopularity?: number
  onLotClick?: (lot: Lot) => void
}

const LotSVG = ({
  lot,
  siteplan,
  colorMode = false,
  heatMapMode = false,
  highlightMode = false,
  hideText = false,
  borderColor = '',
  borderWidth = 1,
  minPopularity = 0,
  maxPopularity = 0,
  onLotClick,
}: LotSVGProps): JSX.Element => {
  const lotLegendEntry = useMemo(() => {
    return siteplan?.lotLegend?.find(
      (entry: LotLegendEntry | null) => entry?.code === lot.salesStatus
    )
  }, [siteplan, lot])
  const shape = useMemo(
    () =>
      siteplan?.svg?.shapes.filter(
        (shape: SvgShape) => shape?.attributes?.dataName === lot.dataName
      )[0],
    [siteplan, lot]
  )
  let lotColor = GRAY_FILL
  if (lotLegendEntry && (colorMode || (heatMapMode && lot.popularity))) {
    lotColor = heatMapMode
      ? maxPopularity === 0
        ? GRAY_FILL
        : `${heatMapScale(
            lot.popularity
              ? (lot.popularity - minPopularity) *
                  (1 / (maxPopularity - minPopularity))
              : 0
          )}`
      : lotLegendEntry.hex
      ? `#${lotLegendEntry.hex}`
      : GRAY_FILL
  }

  const textColor =
    lotLegendEntry && (colorMode || (heatMapMode && lot.popularity))
      ? getTextColor(lotColor)
      : 'black'

  const { lotFontSize, lotWidth, lotHeight } = siteplan
  const x = lot.siteplanInfo?.x
  const y = lot.siteplanInfo?.y

  return (
    <>
      {shape && (
        <SiteplanShapeSVG
          key="shape"
          fill={lotColor}
          stroke={highlightMode ? borderColor || 'lime' : borderColor}
          strokeWidth={borderWidth}
          shape={shape}
          lot={lot}
          onLotClick={onLotClick}
        />
      )}
      <g
        key="lot"
        transform={`translate(${x},${y})`}
        onClick={() => onLotClick && onLotClick(lot)}
        style={{ cursor: 'pointer' }}
      >
        {!shape && (
          <rect
            rx={2}
            ry={2}
            width={lotWidth || 0}
            height={lotHeight || 0}
            fill={lotColor}
          />
        )}
        {!highlightMode && !hideText && (
          <svg width={lotWidth || 0} height={lotHeight || 0}>
            <text
              x="50%"
              y="50%"
              alignmentBaseline="middle"
              textAnchor="middle"
              fill={textColor}
              fontSize={lotFontSize || 0}
            >
              {lot.name}
            </text>
          </svg>
        )}
      </g>
    </>
  )
}

export default LotSVG
