import { styled } from '@mui/material/styles'

export const CustomTooltipStyled = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: '1px solid rgb(204, 204, 204)',
  margin: '0px',
  padding: '10px',
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.primary.light
      : theme.palette.common.black,
  textAlign: 'center',
}))

export const PercentInTooltip = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '18px',
  fontWeight: 700,
}))
