import TimesSeriesLineChart from 'components/common/time-series/TimeSeriesLineChart'
import {
  useGetColorTrendsQuery,
  ColorTrend,
  ColorTrendsFilter,
  PopularityMetric,
} from 'graphql/gen-types'
import React, { useContext } from 'react'
import { addZeroCountDates } from 'components/common/time-series/TimeSeriesFormatter'
import { getImagePrefix, texturesPrefix } from '@anewgo/utils'
import { AppStore } from 'store'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import ComponentCard from 'components/common/layout/ComponentCard'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

export interface ColorTrendsProps {
  filter: ColorTrendsFilter
  areaChart?: boolean | false
  height?: number | null
  width?: number | null
}

export default function ColorTrends({
  filter,
  areaChart,
  height,
  width,
}: ColorTrendsProps): JSX.Element {
  const { appState } = useContext(AppStore)
  const { selectedClient } = appState
  const directoryName = selectedClient ? selectedClient.directoryName : ''
  const { data, loading, error } = useGetColorTrendsQuery({
    variables: {
      filter: filter,
    },
  })
  const yAxisDataKey = 'value'
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized message={error.toString()} imageName={'ColorTrends'} />
      )
    }
    console.error(error)
    return <div>Error while loading color trends</div>
  }

  const colorsRaw = (data?.getColorTrends.colors as ColorTrend[]) || []
  const colors = colorsRaw.map((colorTrend: ColorTrend) => {
    const { color, customOverlay, texture } = colorTrend
    let trendName =
      (color && color.name) ||
      (customOverlay && customOverlay.color) ||
      (texture && texture.color)
    const strokeColor = color ? `#${color.hex}` : null

    let swatchSrc = null
    if (texture && texture.src) {
      swatchSrc = `${texturesPrefix}/${texture?.src}`
      if (texture.type) {
        trendName = `${trendName}: ${texture.type}`
      }
    } else if (customOverlay && customOverlay.src) {
      // Get the swatch from customOverlay
      swatchSrc = `${getImagePrefix(directoryName)}/${customOverlay.src}`
    }
    return {
      ...colorTrend,
      trendName,
      strokeColor,
      swatchSrc,
      dailyMetrics: addZeroCountDates(
        colorTrend.dailyMetrics,
        filter.commonFilter.metric,
        new Date(filter.commonFilter.startTime),
        new Date(filter.commonFilter.endTime)
      ),
    } as ColorTrend
  })
  const cardContent =
    filter.commonFilter.metric === PopularityMetric.Duration ? (
      <div>Color Trends does not support the 'By Duration" metric.</div>
    ) : (
      <TimesSeriesLineChart
        data={colors}
        height={height}
        width={width}
        yAxisDataKey={yAxisDataKey}
        tooltipDataKey={'trendName'}
        xAxisDomain={{
          lower: filter.commonFilter.startTime,
          upper: filter.commonFilter.endTime,
        }}
        useCustomLegend={true}
        metric={filter.commonFilter.metric}
        areaChart={areaChart}
      />
    )

  return (
    <ComponentCard
      title="Color Trends"
      result={{ data: colorsRaw, error, loading }}
      skeletonHeight={400}
      height={isMobile ? 450 : undefined}
    >
      {cardContent}
    </ComponentCard>
  )
}
