import { gql } from '@apollo/client'

const COLONNADE_CHANGED_SUBSCRIPTION = gql`
  subscription OnColonnadeSubscriptionChanged(
    $clientName: String!
    $token: String!
  ) {
    colonnadeSubscriptionChanged(clientName: $clientName, token: $token) {
      clientName
      status
    }
  }
`

export default COLONNADE_CHANGED_SUBSCRIPTION
