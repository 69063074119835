import { styled } from '@mui/material/styles'
import TableSortLabel from '@mui/material/TableSortLabel'
const PREFIX = 'LeadTable'

export const classes = {
  tableSortIcon: `${PREFIX}-tableSortIcon`,
  cellLabel: `${PREFIX}-cellLabel`,
  tableSortLabelActive: `${PREFIX}-tableSortLabelActive`,
  visuallyHidden: `${PREFIX}-visuallyHidden`,
}

export const VisuallyHidden = styled('span')({
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: 1,
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  top: 20,
  width: 1,
})

export const TableSortLabelStyled = styled(TableSortLabel)({
  [`& .${classes.tableSortIcon}`]: {
    // fill: `${theme.palette.primary.contrastText}`,
    marginRight: '-14px',
  },

  [`& .${classes.cellLabel}`]: {
    height: '12px',
  },

  [`& .${classes.tableSortLabelActive}`]: {
    fontWeight: 'bold',
  },
})
