import React, { Fragment, useState, useEffect } from 'react'
import Backdrop from '@mui/material/Backdrop'

import ResponsiveTable, {
  ResponsiveCell,
} from 'components/common/tables/ResponsiveTable'
import ComponentCard from 'components/common/layout/ComponentCard'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import { Maybe, ProspectBrochures } from 'graphql/gen-types'
import DateFnsAdapter from '@date-io/date-fns'

const COMPONENT_TITLE = 'Active Brochure Links'
const dateFns = new DateFnsAdapter()
const RESPONSE_SIZE = 'md'
const ROW_HEIGHT = 30

export default function BrochuresTable({
  data,
  loading,
  error,
}: {
  data: Maybe<ProspectBrochures>[]
  loading: boolean | undefined
  error: Error | undefined
}): JSX.Element {
  const [snackbarUrl, setSnackbarUrl] = useState<string | null>(null)
  const [brochuresWithCreationDate, setBrochuresWithCreationDate] = useState<
    ProspectBrochures[]
  >([])

  useEffect(() => {
    if (data) {
      // Remove brochures that have creationDate = null,
      // these brochures are outdated and not available
      // for preview/download
      const filterBrochures = data.filter(
        (brochures) => !!brochures?.creationDate
      )

      filterBrochures.sort(function (a, b) {
        return (
          new Date(b?.creationDate || '').valueOf() -
          new Date(a?.creationDate || '').valueOf()
        )
      })

      setBrochuresWithCreationDate(filterBrochures as ProspectBrochures[])
    }
  }, [data])

  const columns = [
    <ResponsiveCell
      align="center"
      key={0}
      responseSize={RESPONSE_SIZE}
      rowHeight={ROW_HEIGHT}
    >
      <strong>Row</strong>
    </ResponsiveCell>,
    <ResponsiveCell
      responseSize={RESPONSE_SIZE}
      rowHeight={40}
      align="center"
      key={1}
    >
      <span style={{ lineHeight: '31px' }}>
        <strong>View Brochure</strong>
      </span>
    </ResponsiveCell>,
    <ResponsiveCell
      responseSize={RESPONSE_SIZE}
      rowHeight={ROW_HEIGHT}
      align="center"
      key={2}
    >
      <span style={{ lineHeight: '31px' }}>
        <strong>Timestamp</strong>
      </span>
    </ResponsiveCell>,
  ]

  const generateRow = (
    dataRow: ProspectBrochures,
    index?: number
  ): JSX.Element => {
    const { url: brochureUrl, creationDate } = dataRow

    const date = dateFns.format(
      new Date(creationDate as string),
      'haaa MMM do yyyy'
    )

    return (
      <>
        <ResponsiveCell
          responseSize={RESPONSE_SIZE}
          rowHeight={ROW_HEIGHT}
          align="center"
          key={`${index}-0`}
        >
          {index !== undefined ? index + 1 : ''}
        </ResponsiveCell>
        <ResponsiveCell
          responseSize={RESPONSE_SIZE}
          rowHeight={40}
          align="center"
          key={`${index}-1`}
        >
          <Button
            onClick={() => setSnackbarUrl(brochureUrl as string)}
            disabled={!brochureUrl}
            variant="contained"
            size="small"
          >
            View
          </Button>
        </ResponsiveCell>
        <ResponsiveCell
          responseSize={RESPONSE_SIZE}
          rowHeight={ROW_HEIGHT}
          align="center"
          key={`${index}-2`}
        >
          {date || null}
        </ResponsiveCell>
      </>
    )
  }

  return (
    <Fragment>
      <Modal
        aria-labelledby="view-brochure"
        aria-describedby="view-brochure"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={!!snackbarUrl}
        onClose={(): void => setSnackbarUrl(null)}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <iframe
          title="Brochure"
          src={snackbarUrl || ''}
          style={{
            height: '75vh',
            width: '75vw',
            zIndex: 1,
          }}
        />
      </Modal>
      <ComponentCard title={COMPONENT_TITLE} result={{ data, loading, error }}>
        <ResponsiveTable
          data={brochuresWithCreationDate}
          columns={columns}
          generateRow={generateRow}
          dense={true}
        />
      </ComponentCard>
    </Fragment>
  )
}
