import React, { useEffect, useState } from 'react'
import Switch from '@mui/material/Switch/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useToggleByOnlineEnabledMutation } from '../../../graphql/gen-types'

interface ReservationIntegrationBuyOnlineToggleProps {
  clientId: number
  progress: number
  onboardingComplete?: boolean
  canToggle: boolean
}

const ReservationIntegrationBuyOnlineToggle: React.FC<ReservationIntegrationBuyOnlineToggleProps> = ({
  clientId,
  progress,
  onboardingComplete,
  canToggle,
}) => {
  const [toggleBuyOnlineEnabled] = useToggleByOnlineEnabledMutation()
  const [isBuyNowLocked, setIsBuyNowLocked] = useState(false)
  const [isBuyNowEnabled, setIsBuyNowEnabled] = useState(onboardingComplete)

  useEffect(() => {
    if (canToggle) {
      if (progress < 100) {
        if (isBuyNowEnabled) {
          handleToggleBuyOnlineEnabled(false)
        }
        setIsBuyNowLocked(true)
      } else {
        setIsBuyNowLocked(false)
      }
    } else {
      setIsBuyNowLocked(true)
    }
  }, [progress, canToggle])

  const handleBuyNowEnable = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    handleToggleBuyOnlineEnabled(event.target.checked)
  }

  const handleToggleBuyOnlineEnabled = (checked: boolean) => {
    // if false, first turn off toggle
    if (!checked) {
      setIsBuyNowEnabled(false)
    }
    if (clientId !== 0) {
      toggleBuyOnlineEnabled({
        variables: {
          clientId: clientId,
          enabled: checked,
        },
      }).then((status) => {
        setIsBuyNowEnabled(status?.data?.toggleBuyOnlineEnabled || false)
      })
    }
  }

  return (
    <FormControlLabel
      control={
        <Switch
          disabled={isBuyNowLocked}
          checked={isBuyNowEnabled}
          onChange={handleBuyNowEnable}
        />
      }
      label="Enable Reserve Online"
    />
  )
}

export default ReservationIntegrationBuyOnlineToggle
