import React, { useContext, useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import InfoIcon from '@mui/icons-material/Info'
import { useTheme } from '@mui/material/styles'

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts'
import { AppStore } from '../../../store'
import {
  useGetReservationStatusCommunitiesOverviewLazyQuery,
  ReservationStatusCommunityOverview,
} from 'graphql/gen-types'
import ReservationFromToDatePicker from './ReservationFromToDatePicker'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import { UnauthorizedCard } from 'components/auth/Unauthorized'

const date = new Date()

const CommunitiesStatusOverview: React.FC = () => {
  const theme = useTheme()
  const stroke =
    theme.palette.primary[theme.palette.mode === 'dark' ? 'light' : 'dark']
  const { appState } = useContext(AppStore)
  const clientName = appState?.selectedClient?.altName
  const [dateFrom, setDateFrom] = useState<Date | null>(
    new Date(date.getFullYear(), date.getMonth(), 1)
  )
  const [dateTo, setDateTo] = useState<Date | null>(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  )

  const [
    getCommunitiesOverview,
    { data, error },
  ] = useGetReservationStatusCommunitiesOverviewLazyQuery()

  useEffect(() => {
    if (!dateFrom?.toJSON() || !dateTo?.toJSON()) return

    getCommunitiesOverview({
      variables: {
        clientName: clientName || '',
        filter: {
          fromDate: dateFrom?.toJSON().slice(0, 10),
          toDate: dateTo?.toJSON().slice(0, 10),
        },
      },
    })
  }, [dateFrom, dateTo])

  if (error && verifyUserAuthError(error.toString()))
    return (
      <UnauthorizedCard
        title="Reservations By Community"
        message={error.toString()}
        imageName={'buyOnlineCommunitiesStatusOverview'}
      />
    )

  const communityStatuses = data?.getReservationStatusCommunitiesOverview

  const handleDateFromChange = (date: Date | null) => {
    setDateFrom(date)
  }

  const handleDateToChange = (date: Date | null) => {
    setDateTo(date)
  }

  if (!communityStatuses) return null

  const chartData = communityStatuses.map(
    (communityStatus: ReservationStatusCommunityOverview) => ({
      communityName: communityStatus.communityName,
      inProgress: communityStatus.status.inProgress,
      approved: communityStatus.status.approvedByBuilder,
      rejected:
        communityStatus.status.rejectedByProspect +
        communityStatus.status.rejectedByBuilder,
    })
  )

  const enableStackType = chartData.length > 5

  return (
    <Card>
      <CardHeader
        title={
          <>
            Reservations By Community
            <Tooltip
              arrow
              title="Shows overview of reservations (Approved/Rejected/In Progress) for each community"
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </>
        }
      />
      <CardContent>
        <Grid container spacing={3}>
          <ReservationFromToDatePicker
            fromValue={dateFrom}
            onFromChange={handleDateFromChange}
            toValue={dateTo}
            onToChange={handleDateToChange}
            grid={{ xs: 12, sm: 6, md: 3 }}
          />
        </Grid>
        <ResponsiveContainer
          width="100%"
          height={350}
          style={{ margin: '29px 0 0' }}
        >
          <BarChart
            width="100%"
            height={350}
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="communityName" tick={{ fill: stroke }} />
            <YAxis allowDecimals={false} tick={{ fill: stroke }} />
            <ChartTooltip />
            <Bar
              dataKey="inProgress"
              name="In Progress"
              fill="#3498db"
              stackId="a"
            />
            <Bar
              dataKey="approved"
              name="Approved"
              fill="#2ecc71"
              stackId={enableStackType ? 'a' : 'b'}
            />
            <Bar
              dataKey="rejected"
              name="Rejected"
              fill="#e74c3c"
              stackId={enableStackType ? 'a' : 'c'}
            />
            <Legend />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  )
}

export default CommunitiesStatusOverview
