import React, { useState } from 'react'
import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { useMutation } from '@apollo/client'
import { useAtom } from 'jotai'
import MuiAlert from '@mui/material/Alert'
import { snackbarConfigAtom } from 'store/atoms'
import { UPDATE_COLONNADE_CLIENT_EMAIL_REPORT_INFO } from 'graphql/auth-queries/authDb'
import { ClientEmailReportInfo } from 'graphql/gen-types'
import { checkIsEmail } from 'utils/functions'
import { useEffect } from 'react'
import { classes, StyledDialog } from './EditReportInfoDialog.styles'

interface EditReportInfoDialogProps {
  reportInfoToBeEdited: ClientEmailReportInfo
  setReportInfoToBeEdited: React.Dispatch<
    React.SetStateAction<ClientEmailReportInfo | null>
  >
  reportInfos: ClientEmailReportInfo[]
}

export default function EditReportInfoDialog(
  props: EditReportInfoDialogProps
): JSX.Element {
  const [snackbarConfig, setSnackbarConfig] = useAtom(snackbarConfigAtom)
  const { reportInfoToBeEdited } = props
  const [firstName, setFirstName] = useState(reportInfoToBeEdited.firstName)
  const [lastName, setLastName] = useState(reportInfoToBeEdited.lastName)
  const [email, setEmail] = useState(reportInfoToBeEdited.email)
  const [warn, setWarn] = useState('')

  // on first render set snackbar config properties
  useEffect(() => {
    setSnackbarConfig({
      ...snackbarConfig,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      autoHideDuration: 6000,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // mutation hook
  const [updateColonnadeClientEmailReportInfo] = useMutation(
    UPDATE_COLONNADE_CLIENT_EMAIL_REPORT_INFO
  )

  // Add ReportInfo handlers
  const handleEditReportInfoSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault()
    if (!checkIsEmail(email)) {
      setWarn('Please enter a valid email address.')
      return
    }
    updateColonnadeClientEmailReportInfo({
      variables: {
        reportId: reportInfoToBeEdited.reportId,
        firstName,
        lastName,
        email,
        frequency: reportInfoToBeEdited.frequency,
      },
    })
      .then((result) => {
        handleEditReportInfoClose()
        if (!result?.data?.updateColonnadeClientEmailReportInfo) {
          setSnackbarConfig({
            ...snackbarConfig,
            open: true,
            message: 'Insights recipient info update failed!',
            severity: 'error',
          })
        } else {
          setSnackbarConfig({
            ...snackbarConfig,
            open: true,
            message: 'Insights recipient info updated successfully!',
            severity: 'success',
          })
        }
        clearFields()
        handleEditReportInfoClose()
      })
      .catch((err) => {
        console.error(err)
        setSnackbarConfig({
          ...snackbarConfig,
          open: true,
          message: err.message.replace(/.*[Ee]rror: /, ''),
          severity: 'error',
        })
      })
  }

  const clearFields = (): void => {
    setFirstName('')
    setLastName('')
    setEmail('')
  }

  const handleEditReportInfoClose = (): void => {
    setWarn('')
    props.setReportInfoToBeEdited(null)
  }

  const handleFirstNameChange = (input: string): void => {
    setFirstName(input)
  }

  const handleLastNameChange = (input: string): void => {
    setLastName(input)
  }

  const handleEmailChange = (input: string): void => {
    setEmail(input)
  }

  return (
    <StyledDialog
      open={!!props.reportInfoToBeEdited}
      onClose={handleEditReportInfoClose}
      aria-labelledby="reportInfo-edit-title"
    >
      {props.reportInfoToBeEdited && (
        <div>
          <DialogTitle id="reportInfo-edit-title">
            Edit Recipient Info
          </DialogTitle>
          <DialogContent>
            <form onSubmit={handleEditReportInfoSubmit}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  {warn ? <MuiAlert severity="warning">{warn}</MuiAlert> : null}
                </Grid>
                <Grid container direction="row">
                  <Grid item xs={6}>
                    <FormControl
                      required
                      className={classes.formControlEditReportInfo}
                      variant="standard"
                    >
                      <TextField
                        variant="standard"
                        data-testid="addReportInfoFirstNameField"
                        id="addReportInfoFirstNameField"
                        className={classes.editReportInfoFirstNameInputField}
                        label={'First Name'}
                        required
                        value={firstName}
                        onChange={(ev): void =>
                          handleFirstNameChange(ev.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      required
                      className={classes.formControlEditReportInfo}
                      variant="standard"
                    >
                      <TextField
                        variant="standard"
                        data-testid="addReportInfoLastNameField"
                        id="addReportInfoLastNameField"
                        className={classes.editReportInfoLastNameInputField}
                        label={'Last Name'}
                        required
                        value={lastName}
                        onChange={(ev): void =>
                          handleLastNameChange(ev.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      className={classes.formControlEditReportInfo}
                      variant="standard"
                    >
                      <TextField
                        variant="standard"
                        data-testid="addReportInfoEmailField"
                        id="addReportInfoEmailField"
                        className={classes.editReportInfoFirstNameInputField}
                        label={'Email'}
                        value={email}
                        onChange={(ev): void =>
                          handleEmailChange(ev.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between">
                <Button
                  onClick={handleEditReportInfoClose}
                  variant="contained"
                  color="primary"
                >
                  Close
                </Button>
                <Button
                  aria-label="submit-button"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </Grid>
            </form>
          </DialogContent>
        </div>
      )}
    </StyledDialog>
  )
}
