import React from 'react'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

// Based on Material Design spec:
// Styles by https://github.com/RafeSacks
// https://material.io/design/components/sliders.html#spec
const trackHeight = 2
const thumbHeight = 12

type SliderValue = {
  id: string
  value: number
  percent: number
}
type MuiRailProps = {
  getRailProps: () => Record<string, string>
}
type MuiHandleProps = {
  domain: number[]
  handle: SliderValue
  getHandleProps: (id: string) => Record<string, string>
  formatHandle: (value: number) => string
}
type MuiTrackProps = {
  source: SliderValue
  target: SliderValue
  getTrackProps: () => Record<string, string>
}
type MuiTickProps = {
  tick: SliderValue
  index: number
  count: number
  format: (value: number, index: number, count: number) => string
}

const MuiRailHotspot = styled('div')({
  // backgroundColor: "green", // for debugging
  width: '100%',
  height: thumbHeight * 2, // Invisible hotspot same size as thumb
  top: thumbHeight * -1,
  position: 'absolute',
  cursor: 'pointer',
})

const MuiRailStyled = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[400],
  width: '100%',
  height: trackHeight,
  position: 'absolute',
  pointerEvents: 'none',
}))

/**
 * Rail Component
 */
export function MuiRail({ getRailProps }: MuiRailProps): JSX.Element {
  return (
    <>
      <MuiRailHotspot {...getRailProps()} />
      <MuiRailStyled />
    </>
  )
}

const MuiHandleStyled = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  marginLeft: thumbHeight * -0.5,
  marginTop: thumbHeight * -0.5,
  width: thumbHeight,
  height: thumbHeight,
  border: 0,
  borderRadius: '50%', // circle
  // boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.2)",
  whiteSpace: 'nowrap', // for child display inline-block to work
  position: 'absolute',
  zIndex: 2,
  cursor: 'pointer',
}))

/**
 * Handle Component
 */
export function MuiHandle({
  domain: [min, max],
  handle: { id, value, percent },
  getHandleProps,
  formatHandle,
}: MuiHandleProps): JSX.Element {
  const val = formatHandle ? formatHandle(value) : value
  return (
    <MuiHandleStyled
      role="slider"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      style={{ left: `${percent}%` }}
      {...getHandleProps(id)}
    >
      <div style={{ fontSize: 10, marginTop: -16 }}>{val}</div>
    </MuiHandleStyled>
  )
}

const MuiTrackStyled = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  height: trackHeight,
  position: 'absolute',
  zIndex: 1,
  pointerEvents: 'none',
}))

const MuiTrackHotspot = styled('div')({
  // backgroundColor: "grey", // for debugging
  height: thumbHeight, // Invisible hotspot same size as thumb
  top: thumbHeight * -0.5,
  position: 'absolute',
  cursor: 'pointer',
})

/**
 * Track Component
 */
export function MuiTrack({
  source,
  target,
  getTrackProps,
}: MuiTrackProps): JSX.Element {
  const left = `${source.percent}%`
  const width = `${target.percent - source.percent}%`

  return (
    <>
      <MuiTrackStyled style={{ left, width }} />
      <MuiTrackHotspot style={{ left, width }} {...getTrackProps()} />
    </>
  )
}

const MuiTickStyled = styled('div')(({ theme }) => ({
  position: 'absolute',
  marginTop: 14,
  width: 1,
  height: 5,
  backgroundColor: theme.palette.grey[400],
}))

const MuiTickLabel = styled(Typography)({
  position: 'absolute',
  marginTop: 22,
  textAlign: 'center',
})

/**
 * Tick Component
 */
export function MuiTick({
  tick,
  index,
  count,
  format = (d, index, count) => (index + 1 === count ? `${d}+` : `${d}`),
}: MuiTickProps): JSX.Element {
  return (
    <div>
      <MuiTickStyled style={{ left: `${tick.percent}%` }} />
      <MuiTickLabel
        variant="caption"
        style={{
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {format(tick.value, index, count)}
      </MuiTickLabel>
    </div>
  )
}
