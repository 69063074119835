import { ReactElement, useCallback } from 'react'
import React from 'react'
import { LocationsSelect } from '../../../analytics/locations-filters/components/LocationsSelect'
import {
  ITLocationSelection,
  OptionMap,
  SetState,
  SharedFilterArgs,
} from '../../../analytics/locations-filters/Shared'

interface ITDistrictFilterArgs extends SharedFilterArgs {
  districts: OptionMap<string>
  selection: ITLocationSelection
  setSelection: SetState<ITLocationSelection>
}

export default function ITDistrictFilter({
  districts,
  selection,
  setSelection,
  larger = false,
  industryTrendsSelect = false,
  locationsFilterSelect = false,
  leadsLocationSelect = false,
}: ITDistrictFilterArgs): ReactElement | null {
  const handleSelect = useCallback(
    (districtCode?: string): void => {
      setSelection((prev) => {
        return {
          country: prev.country,
          us: {},
          can: {
            province: prev.can?.province,
            district: districtCode,
          },
        }
      })
    },
    [setSelection]
  )

  return (
    <LocationsSelect<string>
      field="district"
      name="District"
      items={districts}
      selected={selection.can?.district}
      onSelect={handleSelect}
      locationsFilterSelect={locationsFilterSelect}
      industryTrendsSelect={industryTrendsSelect}
      leadsLocationSelect={leadsLocationSelect}
      larger={larger}
    />
  )
}
