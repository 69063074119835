/* eslint-disable jsx-a11y/alt-text */
import React, { useMemo, useState } from 'react'
import { PieChart, Pie, Cell, Tooltip } from 'recharts'
import chroma from 'chroma-js'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Switch from '@mui/material/Switch/Switch'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import {
  DurationInfo,
  Elevation,
  PopularityMetric,
  CommonQueryFilter,
  usePopularElevationsQuery,
  PopularElevationByDuration,
  PopularElevationByCount,
} from 'graphql/gen-types'
import { getImagePrefix } from '@anewgo/utils'
import { readableTimeFormat } from 'utils/functions'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import ComponentCard from 'components/common/layout/ComponentCard'
import { CustomLabelProps } from 'components/common/charts/chart-types'
import SwitchTitle from 'components/common/switchTitle/SwitchTitle'
import { PopularElevationsStyled, classes } from './PopularElevations.styles'

const POPULAR_ELEVATIONS_TITLE = 'Top 5 Elevations'
const UNPOPULAR_ELEVATIONS_TITLE = 'Bottom 5 Elevations'
interface PopularElevationsProps {
  directoryName: string
  durationData: PopularElevationByDuration[]
  countData: PopularElevationByCount[]
  loading?: boolean | undefined
  error?: Error | undefined
  setShowUnpopularElevations?: React.Dispatch<React.SetStateAction<boolean>>
  showUnpopularElevations?: boolean
}

type PopularElevationPayload = {
  durationInfo: DurationInfo | null
  elevation: Elevation
  name: string
  value: number
}

type Payload = {
  payload: PopularElevationPayload
  name: string
  value: number
}

type CustomTooltipProps = {
  payload?: Payload[]
  active?: boolean
}

const MAX_HEIGHT = 80

export function PopularElevations({
  directoryName,
  durationData,
  countData,
  error,
  loading,
  setShowUnpopularElevations,
  showUnpopularElevations,
}: PopularElevationsProps): JSX.Element {
  const imgPrefix = getImagePrefix(directoryName, `c_scale,h_${MAX_HEIGHT}`)

  const [
    selectedPopularityMetric,
    setSelectedPopularityMetric,
  ] = useState<PopularityMetric>(PopularityMetric.Frequency)

  const handleMetricModeSwitch = (): void => {
    if (selectedPopularityMetric === PopularityMetric.Frequency) {
      setSelectedPopularityMetric(PopularityMetric.Duration)
    } else {
      setSelectedPopularityMetric(PopularityMetric.Frequency)
    }
  }

  const [displayData, totalValue] = useMemo(() => {
    if (selectedPopularityMetric === PopularityMetric.Frequency) {
      return [
        countData.map((data) => ({
          durationInfo: null,
          name: `${data.planName} ${data.elevationCaption}`,
          value: data.count,
          elevation: data.elevation,
        })),
        countData.reduce((acc, data) => data.count + acc, 0),
      ]
    } else {
      return [
        durationData.map((data) => ({
          durationInfo: data.durationInfo,
          name: `${data.planName} ${data.elevationCaption}`,
          value: data.durationInfo?.total,
          elevation: data.elevation,
        })),
        durationData.reduce(
          (acc, data) => (data.durationInfo?.total || 0) + acc,
          0
        ),
      ]
    }
  }, [selectedPopularityMetric, durationData, countData])

  const CustomTooltip = (data: CustomTooltipProps): JSX.Element | null => {
    const { active, payload } = data
    if (!payload) {
      return null
    }
    if (active) {
      const durationInfo = payload[0].payload?.durationInfo
      return (
        <div className={classes.customTooltip}>
          <div className={classes.percentInTooltip}>{`${(
            (payload[0].value / totalValue) *
            100
          ).toFixed(0)}%`}</div>
          <h4 style={{ paddingTop: '0px' }}>{payload[0].name}</h4>
          <p className="label">{`${
            durationInfo ? 'Total' : 'Number of Events'
          }: ${
            durationInfo
              ? readableTimeFormat(payload[0].value)
              : payload[0].value
          }`}</p>
          {durationInfo && (
            <>
              <p>
                Average:{' '}
                {durationInfo ? readableTimeFormat(durationInfo?.avg) : 0}
              </p>
              <p>
                Median:{' '}
                {durationInfo ? readableTimeFormat(durationInfo?.median) : 0}
              </p>
              <p>
                Minimum:{' '}
                {durationInfo ? readableTimeFormat(durationInfo?.min) : 0}
              </p>
              <p>
                Maximum:{' '}
                {durationInfo ? readableTimeFormat(durationInfo?.max) : 0}
              </p>
              <div className={classes.help}>
                <HelpOutlineIcon />
                <Typography
                  variant="caption"
                  classes={{ root: classes.helpText }}
                >
                  This shows how much time shoppers spent
                  <br />
                  viewing this elevation.
                </Typography>
              </div>
            </>
          )}
        </div>
      )
    }

    return null
  }

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }: CustomLabelProps): JSX.Element | null => {
    // This snippet of code and calculation comes from here:
    // http://recharts.org/en-US/examples/PieChartWithCustomizedLabel
    const RADIAN = Math.PI / 180
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    // If the pie slice is going to be small (10% or less), we are not going to display the percent
    // text because it can overlap into other slices.
    if (percent * 100 <= 10) {
      return null
    }

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }

  const scale = chroma.scale(['#FF8042', '#FFBB28', '#00C49F', '#0088FE'])
  const colors = scale.colors(displayData.length)

  return (
    <ComponentCard
      title={
        setShowUnpopularElevations ? (
          <SwitchTitle
            popularTitle={POPULAR_ELEVATIONS_TITLE}
            unpopularTitle={UNPOPULAR_ELEVATIONS_TITLE}
            setShowUnpopular={setShowUnpopularElevations}
            showUnpopular={showUnpopularElevations || false}
          />
        ) : (
          POPULAR_ELEVATIONS_TITLE
        )
      }
      style={{ margin: 'auto', maxWidth: '900px' }}
      result={{ data: durationData || countData, error, loading }}
      skeletonHeight={565}
    >
      <>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <PieChart width={500} height={400}>
              <Pie
                data={displayData}
                dataKey="value"
                labelLine={false}
                label={renderCustomizedLabel}
              >
                {displayData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index]} />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </Grid>
          <Grid item>
            <Grid
              className={classes.metricSwitch}
              component="label"
              container
              alignItems="center"
              justifyContent="flex-start"
              spacing={1}
            >
              <Grid
                style={
                  selectedPopularityMetric === PopularityMetric.Frequency
                    ? { fontWeight: 700 }
                    : undefined
                }
              >
                By Frequency
              </Grid>
              <Grid item>
                <Switch
                  data-testid="popularElevationsMetricSwitch"
                  checked={
                    selectedPopularityMetric !== PopularityMetric.Frequency
                  }
                  color="default"
                  name="modeSwitch"
                  onChange={handleMetricModeSwitch}
                />
              </Grid>
              <Grid
                style={
                  selectedPopularityMetric !== PopularityMetric.Frequency
                    ? { fontWeight: 700 }
                    : undefined
                }
              >
                By Duration
              </Grid>
            </Grid>
            <List dense={true} disablePadding={false} className={classes.list}>
              {displayData.map((entry, index) => (
                <div key={`${entry.name}-${index}`}>
                  <ListItem className={classes.elevationListItem}>
                    <ListItemText className={classes.label}>
                      <Typography className={classes.elevTypography}>
                        {entry.name}
                      </Typography>
                    </ListItemText>
                    <div
                      className={classes.colorBox}
                      style={{ backgroundColor: colors[index] }}
                    >
                      <div className={classes.percentInLegend}>
                        {`${(((entry.value || 0) / totalValue) * 100).toFixed(
                          0
                        )}%`}
                      </div>
                    </div>
                    <img
                      className={classes.imgBox}
                      src={`${imgPrefix}/${entry.elevation?.thumb}`}
                    />
                  </ListItem>
                </div>
              ))}
            </List>
          </Grid>
        </Grid>
      </>
    </ComponentCard>
  )
}

interface PopularElevationsWrapperProps {
  directoryName: string
  filter: CommonQueryFilter
}

export function PopularElevationsWrapper({
  directoryName,
  filter,
}: PopularElevationsWrapperProps): JSX.Element | null {
  const [
    showUnpopularElevations,
    setShowUnpopularElevations,
  ] = useState<boolean>(false)
  const countFilter = {
    ...filter,
    metric: PopularityMetric.Frequency,
    ascending: showUnpopularElevations,
  }
  const durationFilter = {
    ...filter,
    metric: PopularityMetric.Duration,
    ascending: showUnpopularElevations,
  }
  const durationQuery = usePopularElevationsQuery({
    variables: {
      filter: durationFilter,
    },
  })
  const countQuery = usePopularElevationsQuery({
    variables: {
      filter: countFilter,
    },
  })
  if (durationQuery.error !== undefined) {
    if (verifyUserAuthError(durationQuery.error.toString())) {
      return (
        <Unauthorized
          message={durationQuery.error.toString()}
          imageName={'PopularElevationsWrapper'}
        />
      )
    }
    console.error('Error getting duration data. Error: \n', durationQuery.error)
    return null
  }
  if (countQuery.error !== undefined) {
    if (verifyUserAuthError(countQuery.error.toString())) {
      return (
        <Unauthorized
          message={countQuery.error.toString()}
          imageName={'PopularElevationsWrapper'}
        />
      )
    }
    console.error('Error getting count data. Error: \n', countQuery.error)
    return null
  }
  const durationData: PopularElevationByDuration[] = durationQuery?.data
    ?.popularElevations as PopularElevationByDuration[]
  const countData: PopularElevationByCount[] = countQuery?.data
    ?.popularElevations as PopularElevationByCount[]
  return (
    <PopularElevationsStyled>
      <PopularElevations
        directoryName={directoryName}
        durationData={durationData || []}
        countData={countData || []}
        loading={durationQuery.loading && countQuery.loading}
        setShowUnpopularElevations={setShowUnpopularElevations}
        showUnpopularElevations={showUnpopularElevations}
      />
    </PopularElevationsStyled>
  )
}
