import React, { useState } from 'react'
import { ClientIdAndName, Lot } from 'graphql/gen-types'
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  CartesianGrid,
  Label,
} from 'recharts'
import { ResponsiveContainer } from 'recharts'
import { useTheme } from '@mui/material/styles'
import { CustomXAxisTick } from './CustomXAxis'
import { CustomYAxisLabel } from 'components/common/charts/custom/CustomAxisLabel'
import useMediaQuery from '@mui/material/useMediaQuery'
import Typography from '@mui/material/Typography'
import SwitchTitle from 'components/common/switchTitle/SwitchTitle'
import { StyledComponentCard, classes } from './PopularLots.styles'

const POPULAR_COMPONENT_TITLE = 'Popular Lots'
const UNPOPULAR_COMPONENT_TITLE = 'Unpopular Lots'
export interface PopularLotsProps {
  client: ClientIdAndName | null
  communityName?: string
  containerHeight: number
  popularLots: Lot[]
  loading: boolean
  error: Error | undefined
  setShowUnpopularLots?: React.Dispatch<React.SetStateAction<boolean>>
  showUnpopularLots?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function labelFormatter(value: number, data: any[]): string {
  const lot = data?.find((datum) => datum?.payload?.id === value)
  return `${lot?.payload?.siteplan?.name}: Lot ${lot?.payload?.name}`
}

function PopularLots(props: PopularLotsProps): JSX.Element | null {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down('sm'))
  const fillColor =
    theme.palette.mode === 'dark'
      ? theme.palette.primary.light
      : theme.palette.primary.dark

  const [xAxisHeight, setXAxisHeight] = useState(210)

  if (!props?.client?.directoryName) {
    return null
  }

  return (
    <StyledComponentCard
      title={
        props.setShowUnpopularLots ? (
          <SwitchTitle
            popularTitle={POPULAR_COMPONENT_TITLE}
            unpopularTitle={UNPOPULAR_COMPONENT_TITLE}
            setShowUnpopular={props.setShowUnpopularLots}
            showUnpopular={props.showUnpopularLots || false}
          />
        ) : (
          POPULAR_COMPONENT_TITLE
        )
      }
      result={{
        data: props.popularLots,
        error: props.error,
        loading: props.loading,
      }}
      skeletonHeight={props.containerHeight}
    >
      <ResponsiveContainer width="100%" height={props.containerHeight}>
        <BarChart data={props.popularLots}>
          <XAxis
            height={xs ? 70 : xAxisHeight}
            dataKey="id"
            tick={
              <CustomXAxisTick
                onHeightChange={(height): void => setXAxisHeight(height)}
                data={props.popularLots as Lot[]}
                client={props.client}
                fill={fillColor}
              />
            }
          />
          <YAxis
            tick={{
              fill: fillColor,
            }}
          >
            <Label
              value="Popularity"
              stroke={fillColor}
              content={CustomYAxisLabel}
            />
          </YAxis>
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip
            labelClassName={classes.tooltip}
            labelStyle={{ color: 'black' }}
            labelFormatter={labelFormatter}
          />
          <Bar
            fill={theme.palette.primary.main}
            name="popularity"
            dataKey="popularity"
          />
        </BarChart>
      </ResponsiveContainer>
      {xs && (
        <Typography variant="caption">Select bar for more info.</Typography>
      )}
    </StyledComponentCard>
  )
}

export default PopularLots
