import React from 'react'
// These types cannot be used very much since recharts is not strongly typed / difficult to interface with.
type ViewBox = {
  x: number
  y: number
  width: number
  height: number
}

interface CustomLabelProps {
  viewBox?: ViewBox
  value?: string
  stroke?: string
}

interface LabelProps {
  axis: string
  viewBox?: ViewBox
  stroke?: string
  children?: JSX.Element | string | undefined
}

export const CustomXAxisLabel = ({
  viewBox,
  value,
  stroke,
}: CustomLabelProps): JSX.Element => (
  <AxisLabel axis="x" viewBox={viewBox} stroke={stroke}>
    {value}
  </AxisLabel>
)
export const CustomYAxisLabel = ({
  viewBox,
  value,
  stroke,
}: CustomLabelProps): JSX.Element => (
  <AxisLabel axis="y" viewBox={viewBox} stroke={stroke}>
    {value}
  </AxisLabel>
)
export const AxisLabel = ({
  axis,
  viewBox = { x: 0, y: 0, width: 0, height: 0 },
  stroke = 'black',
  children,
}: LabelProps): JSX.Element => {
  const { x, y, width, height } = viewBox
  const isVertical = axis === 'y'
  const calculatedX = isVertical ? x + 6 : x + width / 2
  const calculatedY = isVertical ? height / 2 + y : y + height + 3
  const rot = isVertical ? `270 ${calculatedX} ${calculatedY}` : 0
  const font = 16
  return (
    <text
      x={calculatedX}
      y={calculatedY}
      transform={`rotate(${rot})`}
      textAnchor="middle"
      fill={stroke}
      fontSize={font}
    >
      {children}
    </text>
  )
}

export const IndustryTrendsYAxisLabel = ({
  viewBox,
  value,
  stroke,
}: CustomLabelProps): JSX.Element => (
  <IndustryTrendsAxisLabel axis="y" viewBox={viewBox} stroke={stroke}>
    {value}
  </IndustryTrendsAxisLabel>
)
export const IndustryTrendsAxisLabel = ({
  axis,
  viewBox = { x: 0, y: 0, width: 0, height: 0 },
  stroke = 'black',
  children,
}: LabelProps): JSX.Element => {
  const { x, y, width, height } = viewBox
  const isVertical = axis === 'y'
  const calculatedX = isVertical ? x + 12 : x + width / 2
  const calculatedY = isVertical ? height / 2 + y : y + height + 3
  const rot = isVertical ? `270 ${calculatedX} ${calculatedY}` : 0
  const font = 16
  return (
    <text
      x={calculatedX}
      y={calculatedY}
      transform={`rotate(${rot})`}
      textAnchor="middle"
      fill={stroke}
      fontSize={font}
    >
      {children}
    </text>
  )
}
