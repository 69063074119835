import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { AuthContext } from 'auth/AuthContext'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { useTheme } from '@mui/material/styles'

import AdminAssignAgentOfProspect from 'components/leads/agent-prospect/AdminAssignAgentOfProspect'
import AssignmentNotificationManager from 'components/leads/agent-prospect/AssignmentNotificationManager'
import NewProspectNotificationManager from 'components/leads/agent-prospect/NewProspectNotificationManager'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  isAnewgoMember,
  userSubscriptionHasAccess,
} from 'utils/authorizationHelpers'
import { ColonnadeSubscriptionLevelsEnum as SubscriptionLevels } from 'graphql/gen-types'
import ImportAgents from 'components/leads/agent-prospect/ImportAgents'
import ImportLeads from 'components/leads/agent-prospect/ImportLeads'

const PREFIX = 'AgentAssignments'

const classes = {
  root: `${PREFIX}-root`,
}

const Root = styled('div')(({ theme: Theme }) => ({
  [`&.${classes.root}`]: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',
    width: '100%',
    height: `calc(100vh - 56px)`,
  },
}))

function tabProps(label: string) {
  return {
    id: `tab-${label}`,
    'aria-controls': `tabpanel-${label}`,
    value: label,
  }
}

function AgentAssignments(): JSX.Element {
  const theme = useTheme()
  const [value, setValue] = React.useState('assign')
  const { user } = useContext(AuthContext)
  const { roleId, subscriptionLevel } = user

  const userHasAccess = userSubscriptionHasAccess(
    subscriptionLevel as SubscriptionLevels,
    [SubscriptionLevels.Pro, SubscriptionLevels.Enterprise]
  )

  const panelPadding = useMediaQuery(theme.breakpoints.down('md')) ? 1 : 2

  const handleChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    newValue: string
  ) => {
    setValue(newValue)
  }

  return (
    <Root className={classes.root}>
      <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          {isAnewgoMember(roleId) ? (
            <Tab label="Assignments" {...tabProps('assign')} />
          ) : null}
          <Tab label="Manage" {...tabProps('manage')} />
        </Tabs>
      </Paper>

      <div
        role="tabpanel"
        hidden={value !== 'assign'}
        id={`tab-assign`}
        aria-labelledby={`tab-assign`}
      >
        {value === 'assign' && (
          <Box p={panelPadding}>
            <AdminAssignAgentOfProspect />
          </Box>
        )}
      </div>

      <div role="tabpanel" hidden={value !== 'manage'} id={`tab-manage`}>
        {value === 'manage' && (
          <Box p={panelPadding}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <NewProspectNotificationManager />
              </Grid>
              <Grid item xs={12} md={6}>
                <AssignmentNotificationManager disabled={!userHasAccess} />
              </Grid>
              <Grid item xs={12} md={6}>
                <ImportLeads />
              </Grid>
              <Grid item xs={12} md={6}>
                <ImportAgents />
              </Grid>
            </Grid>
          </Box>
        )}
      </div>
    </Root>
  )
}

export default AgentAssignments
