import React from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import MuiDialogContent from '@mui/material/DialogContent'
import MuiDialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import CancelIcon from '@mui/icons-material/Cancel'

const PREFIX = 'UnexpectedErrorDialog'

const classes = {
  dialogTitleContainer: `${PREFIX}-dialogTitleContainer`,
  dialogTitle: `${PREFIX}-dialogTitle`,
  dialogTitleText: `${PREFIX}-dialogTitleText`,
  dialogContentText: `${PREFIX}-dialogContentText`,
  dialogContentDetails: `${PREFIX}-dialogContentDetails`,
  detailsHeader: `${PREFIX}-detailsHeader`,
  closeButton: `${PREFIX}-closeButton`,
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogTitleContainer}`]: {
    display: 'flex',
  },

  [`& .${classes.dialogTitle}`]: {
    paddingBottom: 0,
    display: 'flex',
  },

  [`& .${classes.dialogTitleText}`]: {
    marginLeft: theme.spacing(1),
    marginTop: '-6px',
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.dialogContentText}`]: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.dialogContentDetails}`]: {
    margin: theme.spacing(2),
    backgroundColor: '#DCDCDC',
  },

  [`& .${classes.detailsHeader}`]: {
    padding: theme.spacing(1),
  },

  [`& .${classes.closeButton}`]: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}))

export const errorMessageToBypass = [
  'Your team currently is not subscribed',
  'Please confirm with your admin that you have a valid license and role for this app.',
  'Document with this TemplateId',
  'Signer with role',
  'An Insights user with the email',
]

interface UnexpectedErrorDialogProps {
  open: boolean
  onClose: () => void
  error: string
}

export default function UnexpectedErrorDialog({
  open,
  onClose,
  error,
}: UnexpectedErrorDialogProps): JSX.Element {
  return (
    <StyledDialog onClose={onClose} open={open}>
      <DialogTitle className={classes.dialogTitle}>
        <div className={classes.dialogTitleContainer}>
          <CancelIcon color="error" fontSize={'small'} />
          <div className={classes.dialogTitleText}>Error</div>
        </div>
      </DialogTitle>
      <MuiDialogContent>
        <Typography className={classes.dialogContentText}>
          The application has encountered an unknown error.
        </Typography>
        <Typography gutterBottom className={classes.dialogContentText}>
          Please contact Anewgo support with the details below.
        </Typography>
        <div className={classes.dialogContentDetails}>
          <Typography className={classes.detailsHeader}>
            Error Details
          </Typography>
          <Typography gutterBottom className={classes.dialogContentText}>
            {error}
          </Typography>
        </div>
      </MuiDialogContent>
      <MuiDialogActions>
        <Button
          autoFocus
          onClick={onClose}
          color="primary"
          variant="outlined"
          className={classes.closeButton}
        >
          Close
        </Button>
      </MuiDialogActions>
    </StyledDialog>
  )
}
