import { styled } from '@mui/material/styles'

const PREFIX = 'Sections'

export const classes = {
  sections: `${PREFIX}-sections`,
}

export const Root = styled('div')(() => ({
  [`&.${classes.sections}`]: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
  },
}))
