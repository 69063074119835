import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { ProspectStatus } from '../../../graphql/gen-types'
import { snakeCaseToTitleCase } from 'utils/functions'

const PREFIX = 'SelectProspectStatus'

const classes = {
  smallSelect: `${PREFIX}-smallSelect`,
}

const StyledSelect = styled(Select)(() => ({
  [`&.${classes.smallSelect}`]: {
    padding: '0px',
    minWidth: '160px',
  },
}))

export interface SelectProspectStatusProps {
  onSelect: (status: ProspectStatus) => void
}

export default function SelectProspectStatus({
  onSelect,
}: SelectProspectStatusProps): JSX.Element {
  const [selected, setSelected] = useState(ProspectStatus.New)
  const onChange = (status: ProspectStatus) => {
    setSelected(status)
    onSelect(status)
  }

  return (
    <FormControl variant="outlined">
      <InputLabel htmlFor={`select-prospect-status-sel`}>Status</InputLabel>
      <StyledSelect
        variant="standard"
        className={classes.smallSelect}
        value={selected}
        onChange={(e) =>
          onChange((e.target.value as unknown) as ProspectStatus)
        }
        inputProps={{
          id: `select-prospect-status-sel`,
        }}
      >
        {Object.entries(ProspectStatus).map(([key, value]) => (
          <MenuItem key={key} value={value}>
            {snakeCaseToTitleCase(value)}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  )
}
