import React, { useContext } from 'react'
import { Client, Community, Prospect } from '../../../graphql/gen-types'
import { ProspectPlan } from '../../common/by-plan/PlansGrid'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { SchemeSwatch } from '@anewgo/interactive-exterior-ng'
import { AppStore } from 'store'
import LotInsetSVGWrapper from '../favorite-details/components/LotInsetSVGWrapper'
import {
  ProspectFavoritesComparisonStyled,
  classes,
} from './styles/ProspectFavoritesComparison.styles'

const COMPONENT_TITLE = 'Compare Favorites'
interface ProspectFavoritesComparisonProps {
  data: Prospect | undefined | null
  loading?: boolean
  error?: Error | undefined
  favoritePlans: ProspectPlan[]
}

interface formatedFavoritesProps {
  name: string | null
  bed: number | null | undefined
  bath: number | null | undefined
  size: number | null | undefined
  cost: number | null | undefined
  frequency: number | null | undefined
  duration: string | null | undefined
  url: string | null | undefined
  communityName: string | null | undefined
}

function ProspectFavoritesComparison({
  data,
  loading,
  error,
  favoritePlans,
}: ProspectFavoritesComparisonProps): JSX.Element {
  const { appState } = useContext(AppStore)
  const { selectedClient } = appState

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized
          message={error.toString()}
          imageName={'ProspectFavoritesComparison'}
        />
      )
    }
  }

  const formatedFavorites = favoritePlans.map((fav) => ({
    name: fav.elevations
      ? `${fav.elevations[0]?.planName} ${fav.elevations[0]?.caption}`
      : null,
    bed: fav.bed,
    bath: fav.bath,
    size: fav.size,
    cost: fav.cost,
    frequency: fav.popularityMetrics?.byFrequency,
    duration: getDuration(fav.popularityMetrics?.byDuration?.total),
    url: fav.elevations
      ? `https://res.cloudinary.com/renderinghouse/image/upload/c_scale,h_200/app/${
          appState.selectedClient?.directoryName || ''
        }/images/${fav.elevations[0]?.thumb}`
      : null,
    communityName: fav.communityName,
  }))

  return (
    <ProspectFavoritesComparisonStyled
      title={COMPONENT_TITLE}
      result={{ data: data?.favorites, loading, error }}
    >
      <div className={classes.container}>
        <Table table-layout="auto">
          <TableHead>
            {RenderRow(
              <div className={classes.cornerCellContent}>Elevation name</div>,
              formatedFavorites,
              'name',
              '',
              false,
              true
            )}
          </TableHead>
          <TableBody>
            {RenderRow(
              'Exterior',
              formatedFavorites,
              '',
              '',
              false,
              false,
              true
            )}
            {RenderRow('Frequency', formatedFavorites, 'frequency', '')}
            {RenderRow('Duration', formatedFavorites, 'duration', '')}
            {RenderRow('Beds', formatedFavorites, 'bed', 'BR')}
            {RenderRow('Baths', formatedFavorites, 'bath', 'BA')}
            {RenderRow('Size', formatedFavorites, 'size', 'SQ FT')}
            {RenderRow('Home Cost', formatedFavorites, 'cost', '$', true)}
            {RenderRow('Community Name', formatedFavorites, 'communityName')}
            <TableRow>
              <TableCell className={classes.sideCell}>Scheme Name</TableCell>
              {favoritePlans.map((plan, index) => {
                if (!plan.scheme) {
                  return (
                    <TableCell
                      className={classes.tableCell}
                      key={'schemes' + index}
                    >
                      -
                    </TableCell>
                  )
                }
                return (
                  <TableCell
                    className={classes.tableCell}
                    key={'schemes' + index}
                    align="center"
                  >
                    {plan.scheme.name}
                    <div className={classes.swatchBox}>
                      <SchemeSwatch
                        directoryName={plan?.clientName}
                        materials={plan.scheme.materials || []}
                      />
                    </div>
                  </TableCell>
                )
              })}
            </TableRow>
            <TableRow>
              <TableCell className={classes.sideCell}>Lot</TableCell>
              {data?.favorites?.map((favorite) => {
                if (!favorite.lot) {
                  return (
                    <TableCell
                      className={classes.tableCell}
                      key={'lot' + favorite?.id}
                    >
                      -
                    </TableCell>
                  )
                }

                let address2 = null

                if (
                  favorite.lot &&
                  favorite.lot.cityName &&
                  favorite.lot.stateCode &&
                  (favorite.lot.zip || favorite.lot.postCode)
                ) {
                  address2 = `${favorite.lot.cityName}, ${
                    favorite.lot.stateCode
                  } ${favorite.lot.zip || favorite.lot.postCode}`
                }

                return (
                  <TableCell
                    className={classes.tableCell}
                    key={'lot' + favorite?.lot?.id}
                  >
                    <div>{`Lot ${favorite.lot.name}`}</div>
                    <div>{favorite.lot.address}</div>
                    {address2 && <div>{address2}</div>}
                    <div>{`Premium $${favorite.lot.premium}`}</div>
                    <div>
                      {favorite.lot.size} {favorite.lot.siteplan?.lotMetric}
                    </div>
                    <div style={{ width: 150, margin: 'auto' }}>
                      <LotInsetSVGWrapper
                        client={selectedClient as Client}
                        community={favorite.community as Community}
                        lot={favorite.lot}
                        height={150}
                        width={150}
                      />
                    </div>
                  </TableCell>
                )
              })}
            </TableRow>
            <TableRow>
              <TableCell className={classes.sideCell}>
                Floor Plan Options
              </TableCell>
              {favoritePlans.map((plan, index) => {
                if (!plan.fpOptsList || plan.fpOptsList.length === 0) {
                  return (
                    <TableCell
                      className={classes.tableCell}
                      key={'fpOpts' + index}
                    >
                      -
                    </TableCell>
                  )
                }
                return (
                  <TableCell
                    className={classes.fpOptsListCell}
                    key={'fpOpts' + index}
                  >
                    <ul>
                      {plan.fpOptsList?.map((fpOpt) => {
                        return <li>{fpOpt}</li>
                      })}
                    </ul>
                  </TableCell>
                )
              })}
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </ProspectFavoritesComparisonStyled>
  )
}

function getDuration(duration: number | null | undefined): string {
  if (!duration) {
    return ''
  }
  const minutes = Math.floor(duration / 60)
  const seconds = duration % 60

  return minutes + 'm ' + seconds + (seconds && 's')
}

function RenderRow(
  firstCell: string | JSX.Element,
  favoritePlans: formatedFavoritesProps[],
  parName: string,
  units?: string,
  unitsAtStart?: boolean,
  header?: boolean,
  img?: boolean
): JSX.Element {
  return (
    <TableRow>
      <TableCell className={classes.sideCell}>{firstCell}</TableCell>
      {favoritePlans.map((favorite, index) => {
        if (!favorite[parName] && !img) {
          return (
            <TableCell key={parName + index} className={classes.tableCell}>
              -
            </TableCell>
          )
        }
        if (img) {
          return (
            <TableCell className={classes.tableCell} key={parName + index}>
              <img src={favorite.url || ''} alt={favorite.name || ''} />
            </TableCell>
          )
        }
        return (
          <TableCell
            className={header ? classes.headerCell : classes.tableCell}
            key={parName + index}
          >
            {unitsAtStart && units}
            {favorite[parName] + ' '}
            {!unitsAtStart && units}
          </TableCell>
        )
      })}
    </TableRow>
  )
}

export default ProspectFavoritesComparison
export const testingVariables = { getDuration }
