import React from 'react'
import {
  usePopularMaterialColorsQuery,
  CommonQueryFilter,
  PopularMaterialColors,
} from 'graphql/gen-types'
import { Unauthorized } from 'components/auth/Unauthorized'
import { verifyUserAuthError } from 'utils/authorizationHelpers'
import MaterialColorsDisplay from 'components/common/displays/MaterialColorsDisplay'

interface TrussPopularMaterialColorsProps {
  filter: CommonQueryFilter
}

function TrussPopularMaterialColors(
  props: TrussPopularMaterialColorsProps
): JSX.Element {
  const { data, loading, error } = usePopularMaterialColorsQuery({
    variables: {
      filter: props.filter,
    },
  })

  if (error) {
    if (verifyUserAuthError(error.toString())) {
      return (
        <Unauthorized
          message={error.toString()}
          imageName="PopularMaterialColors"
        />
      )
    }
    console.error(error)
    return <div>Error while loading popular colors</div>
  }

  const popularMaterialColors: PopularMaterialColors[] =
    (data?.popularMaterialColors as PopularMaterialColors[]) || []
  return (
    <MaterialColorsDisplay
      {...props}
      data={popularMaterialColors}
      loading={loading}
      error={error}
    />
  )
}

export default TrussPopularMaterialColors
