import React, { useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import clsx from 'clsx'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { useMutation } from '@apollo/client'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import EmailIcon from '@mui/icons-material/Email'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { snackbarConfigAtom } from 'store/atoms'
import { classes, Root } from './ReportInfoTable.styles'
import AddReportInfoDialog from './AddReportInfoDialog'
import EditReportInfoDialog from './EditReportInfoDialog'
import {
  DELETE_COLONNADE_CLIENT_EMAIL_REPORT_INFO,
  UPDATE_COLONNADE_CLIENT_EMAIL_REPORT_INFO,
} from 'graphql/auth-queries/authDb'
import {
  ClientEmailReportInfo,
  ColonnadeReportFrequencyEnum,
} from 'graphql/gen-types'

const INITIAL_ROWS_PER_PAGE = 10
const frequencies: ColonnadeReportFrequencyEnum[] = Object.values(
  ColonnadeReportFrequencyEnum
)

interface Header {
  key: string
  name: string
  lineHeight?: string
}

const headers: Header[] = [
  { key: 'firstName', name: 'First Name' },
  { key: 'lastName', name: 'Last Name' },
  { key: 'email', name: 'Email' },
  { key: 'frequency', name: 'Frequency' },
]

interface ReportInfoTableProps {
  emailReportsData: ClientEmailReportInfo[]
  setEmailReportsData: React.Dispatch<
    React.SetStateAction<ClientEmailReportInfo[]>
  >
}

export default function ReportInfoTable(
  props: ReportInfoTableProps
): JSX.Element {
  const { emailReportsData } = props
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('md'))

  const [snackbarConfig, setSnackbarConfig] = useAtom(snackbarConfigAtom)

  const [
    addReportInfoDialogOpen,
    setAddReportInfoDialogOpen,
  ] = useState<boolean>(false)

  const [
    reportInfoToBeDeleted,
    setReportInfoToBeDeleted,
  ] = useState<ClientEmailReportInfo | null>(null)

  const [
    reportInfoToBeEdited,
    setReportInfoToBeEdited,
  ] = useState<ClientEmailReportInfo | null>(null)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  // mutation hooks
  const [deleteColonnadeClientEmailReportInfo] = useMutation(
    DELETE_COLONNADE_CLIENT_EMAIL_REPORT_INFO,
    {
      update(cache, { data }) {
        cache.modify({
          fields: {
            getClientEmailReportInfo(existingReports, { readField }) {
              return existingReports.filter(
                (reportInfo: ClientEmailReportInfo) =>
                  data.deleteColonnadeClientEmailReportInfo !==
                  readField('reportId', reportInfo)
              )
            },
          },
        })
      },
    }
  )
  const [updateColonnadeClientEmailReportInfo] = useMutation(
    UPDATE_COLONNADE_CLIENT_EMAIL_REPORT_INFO
  )

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  // when rows per page is changed
  useEffect(() => {
    // For small, set rowsPerPage to 1 if it is not already
    if (sm && rowsPerPage !== 1) {
      setRowsPerPage(1)
      // When upscaling from small to larger display, set rowsPerPage to 5
    } else if (!sm && rowsPerPage === 1) {
      setRowsPerPage(INITIAL_ROWS_PER_PAGE)
      setPage(0) // reset page to 0 to avoid over indexing
    }
  }, [sm, rowsPerPage])

  const onFrequencyChange = (
    ev: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
    reportInfo: ClientEmailReportInfo
  ): void => {
    if (reportInfo.reportId) {
      updateColonnadeClientEmailReportInfo({
        variables: {
          ...reportInfo,
          frequency: ev.target.value,
        },
      })
        .then((result) => {
          if (!result?.data?.updateColonnadeClientEmailReportInfo) {
            triggerSnackbar('Insights recipient info update failed!', 'error')
          }
          triggerSnackbar('Report frequency updated successfully!', 'success')
        })
        .catch((err) => {
          console.log(err)
          triggerSnackbar(err.message.replace(/.*[Ee]rror: /, ''), 'error')
        })
    }
  }

  const handleDeleteReportInfo = (): void => {
    if (reportInfoToBeDeleted && reportInfoToBeDeleted.reportId) {
      deleteColonnadeClientEmailReportInfo({
        variables: {
          reportId: reportInfoToBeDeleted.reportId,
        },
      })
        .then((result) => {
          if (!result?.data?.deleteColonnadeClientEmailReportInfo) {
            triggerSnackbar('Insights recipient info delete failed!', 'error')
          }
          triggerSnackbar(
            'Insights recipient info deleted successfully!',
            'success'
          )
        })
        .catch((err) => {
          console.log(err)
          triggerSnackbar(err.message.replace(/.*[Ee]rror: /, ''), 'error')
        })
    }
    setReportInfoToBeDeleted(null)
  }

  const handleAddReportInfo = (): void => {
    setAddReportInfoDialogOpen(true)
  }

  const triggerSnackbar = (
    message: string,
    severity: 'error' | 'success' | 'info' | 'warning' | undefined
  ) => {
    setSnackbarConfig({
      ...snackbarConfig,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      autoHideDuration: 6000,
      open: true,
      message,
      severity,
    })
  }

  const genTableHeader = (): JSX.Element => (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headers.map((header) => (
          <TableCell
            key={header.name}
            align="left"
            className={clsx(classes.tableHeadCell, classes.tableCell)}
          >
            {
              <span
                style={
                  header.lineHeight
                    ? { lineHeight: header.lineHeight }
                    : undefined
                }
              >
                {header.name}
              </span>
            }
          </TableCell>
        ))}
        <TableCell
          align="left"
          className={classes.tableCell}
          style={{ height: '81px' }}
        />
        <TableCell
          align="left"
          className={classes.tableCell}
          style={{ height: '81px' }}
        />
      </TableRow>
    </TableHead>
  )

  const genTableRow = (
    colonnadeReportInfo: ClientEmailReportInfo
  ): JSX.Element => {
    return (
      <>
        <TableCell component="th" scope="row" className={classes.tableCell}>
          {colonnadeReportInfo.firstName}
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          {colonnadeReportInfo.lastName}
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          {colonnadeReportInfo.email}
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          <FormControl className={classes.formControl} variant="outlined">
            <Select
              classes={{ outlined: classes.smallSelect }}
              value={colonnadeReportInfo.frequency}
              onChange={(ev): void =>
                onFrequencyChange(ev, colonnadeReportInfo)
              }
              inputProps={{
                name: `role-select-${colonnadeReportInfo.reportId}`,
                id: `role-select-${colonnadeReportInfo.reportId}`,
              }}
            >
              {frequencies.map((frequency) => (
                <MenuItem key={frequency} value={frequency}>
                  {frequency}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          <IconButton
            aria-label="delete"
            onClick={(): void => setReportInfoToBeDeleted(colonnadeReportInfo)}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          <IconButton
            aria-label="edit"
            onClick={(): void => setReportInfoToBeEdited(colonnadeReportInfo)}
            size="large"
          >
            <EditIcon />
          </IconButton>
        </TableCell>
      </>
    )
  }

  return (
    <Root>
      <AddReportInfoDialog
        addReportInfoDialogOpen={addReportInfoDialogOpen}
        setAddReportInfoDialogOpen={setAddReportInfoDialogOpen}
        frequencies={frequencies}
        reportInfos={emailReportsData}
      />
      <Dialog
        open={reportInfoToBeDeleted !== null}
        onClose={(): void => setReportInfoToBeDeleted(null)}
        aria-labelledby="reportInfo-delete-title"
        aria-describedby="reportInfo-delete-message"
      >
        {reportInfoToBeDeleted !== null && (
          <div>
            <DialogTitle id="reportInfo-delete-title">
              {`Remove recipient info "${
                reportInfoToBeDeleted.firstName || ''
              }${reportInfoToBeDeleted.lastName || ''}"?`}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="reportInfo-delete-message">
                This recipient info will be removed from your Insights
                Dashboard.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(): void => setReportInfoToBeDeleted(null)}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                data-testid="remove-reportInfo-button"
                color="secondary"
                onClick={handleDeleteReportInfo}
                autoFocus
              >
                Remove
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
      {reportInfoToBeEdited && (
        <EditReportInfoDialog
          reportInfoToBeEdited={reportInfoToBeEdited}
          setReportInfoToBeEdited={setReportInfoToBeEdited}
          reportInfos={emailReportsData}
        />
      )}
      <TableContainer component={Paper} className={classes.root}>
        <Typography
          variant="h5"
          className={clsx(
            classes.typography,
            classes.typographyTitle,
            classes.centered
          )}
        >
          Email Reports
        </Typography>
        <Typography
          variant="h6"
          className={clsx(classes.typography, classes.typographyTitle)}
        >
          <EmailIcon style={{ marginBottom: '-4px' }} />
          {emailReportsData.length > 0
            ? ` Recipients (${emailReportsData.length})`
            : ' Recipients'}
        </Typography>
        <Grid container direction="row" spacing={1}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              className={classes.addReportInfoButton}
              endIcon={<PersonAddIcon />}
              onClick={handleAddReportInfo}
            >
              Add Recipients
            </Button>
          </Grid>
        </Grid>
        <Table className={classes.table} aria-label="simple table">
          {!sm && genTableHeader()}
          <TableBody>
            {emailReportsData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((reportInfo: ClientEmailReportInfo) => (
                <TableRow
                  className={classes.tableRow}
                  key={`${reportInfo.firstName}${reportInfo.lastName}${reportInfo.email}${reportInfo.frequency}`}
                >
                  {/* Conditionally render row headers for responsive table */}
                  {sm && genTableHeader()}
                  {/* For small factors, add additional TableRow wrapper for responsive table  */}
                  {!sm ? (
                    genTableRow(reportInfo)
                  ) : (
                    <TableRow className={classes.tableSubRow}>
                      {genTableRow(reportInfo)}
                    </TableRow>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={sm ? [] : [10, 25, 50]} // For small screen, do not display rowsPerPage
          component="div"
          count={emailReportsData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Root>
  )
}
