import { styled } from '@mui/material/styles'
import { MAIN_APP_BAR_HEIGHT } from 'constants/index'
import { PLANS_FILTER_BAR_HEIGHT } from 'components/common/by-plan/PlansFilterBar.styles'
import Dialog from '@mui/material/Dialog'

const headerHeight = MAIN_APP_BAR_HEIGHT + PLANS_FILTER_BAR_HEIGHT + 200 // we add 200 to accomodate the other flex excess items.

const PREFIX = 'ProspectPlanSuggestionsDialog'

export const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  displayVertical: `${PREFIX}-displayVertical`,
  dialogContent: `${PREFIX}-dialogContent`,
  overflowYAuto: `${PREFIX}-overflowYAuto`,
  planSelection: `${PREFIX}-planSelection`,
  planSelectionMobile: `${PREFIX}-planSelectionMobile`,
  textAlignCenter: `${PREFIX}-textAlignCenter`,
  selectedItems: `${PREFIX}-selectedItems`,
  selectedItemCard: `${PREFIX}-selectedItemCard`,
  selectedItemCardContent: `${PREFIX}-selectedItemCardContent`,
  bold: `${PREFIX}-bold`,
  dialogButtons: `${PREFIX}-dialogButtons`,
  mobileDialogButtons: `${PREFIX}-mobileDialogButtons`,
  dialogButton: `${PREFIX}-dialogButton`,
  aiRecommendedBanner: `${PREFIX}-aiRecommendedBanner`,
}

export const ProspectPlanSuggestionsDialogStyled = styled(Dialog)(
  ({ theme }) => ({
    [`&.${classes.root}`]: {
      overflowY: 'hidden',
      overflowX: 'hidden',
      width: '100%',
      height: `calc(100vh - ${headerHeight}px)`,
    },

    [`& .${classes.container}`]: {
      width: '100%',
      height: '90vh',
    },

    [`& .${classes.displayVertical}`]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignContent: 'center',
      margin: '20px 0px',
    },

    [`& .${classes.dialogContent}`]: {
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },

    [`& .${classes.overflowYAuto}`]: {
      overflowY: 'auto',
      height: `calc(100vh - ${headerHeight}px)`,
    },

    [`& .${classes.planSelection}`]: {
      display: 'grid',
      gridTemplateColumns: '65% 35%',
    },

    [`& .${classes.planSelectionMobile}`]: {
      display: 'flex',
      flexDirection: 'column',
    },

    [`& .${classes.textAlignCenter}`]: {
      textAlign: 'center',
      marginBottom: '10px',
    },

    [`& .${classes.selectedItems}`]: {
      height: (props: { selectedItemsHeight: number }) =>
        props.selectedItemsHeight - 80 || '100%',
      marginLeft: '20px',
    },

    [`& .${classes.selectedItemCard}`]: {
      margin: theme.spacing(1),
    },

    [`& .${classes.selectedItemCardContent}`]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },

    [`& .${classes.bold}`]: {
      fontWeight: 'bold',
    },

    [`& .${classes.dialogButtons}`]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },

    [`& .${classes.mobileDialogButtons}`]: {
      display: 'flex',
      justifyContent: 'center',
    },

    [`& .${classes.dialogButton}`]: {
      margin: theme.spacing(0.5),
    },

    [`& .${classes.aiRecommendedBanner}`]: {
      background:
        'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,121,107,1) 76%, rgba(0,212,255,1) 100%)',
      borderRadius: '4px 4px 0px 0px',
      color: 'white',
      fontSize: '0.8rem',
      width: '100%',
    },
  })
)
