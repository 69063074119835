import { gql } from '@apollo/client'

const CLIENTS_FOR_USER_QUERY = gql`
  query ClientsForUser($userId: Int!) {
    clientsForUser(userId: $userId) {
      id
      clients {
        id
        altName
        name
        email
        phone
        directoryName
        logo
      }
    }
  }
`

export default CLIENTS_FOR_USER_QUERY
