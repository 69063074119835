import { LocationsSelect } from '../LocationsSelect'
import { ReactElement, useCallback } from 'react'
import React from 'react'
import {
  LocationSelectionTypes,
  OptionMap,
  SetState,
  SharedFilterArgs,
} from '../../Shared'

interface StateFilterArgs<TSelection extends LocationSelectionTypes>
  extends SharedFilterArgs {
  states: OptionMap<string>
  selection: TSelection
  setSelection: SetState<TSelection>
}

export default function StateFilter<TSelection extends LocationSelectionTypes>({
  states,
  selection,
  setSelection,
  larger = false,
  industryTrendsSelect = false,
  locationsFilterSelect = false,
  leadsLocationSelect = false,
}: StateFilterArgs<TSelection>): ReactElement | null {
  const handleSelect = useCallback(
    (state?: string): void => {
      setSelection((prev: TSelection) => {
        if (!state && prev.us?.state) {
          const { state: _, ...us } = prev.us
          return { ...prev, us }
        }
        return {
          country: prev.country,
          us: {
            state: state,
          },
          can: {},
        } as TSelection
      })
    },
    [setSelection]
  )

  return (
    <LocationsSelect<string>
      field="state"
      name="State"
      items={states}
      selected={selection.us?.state}
      onSelect={handleSelect}
      locationsFilterSelect={locationsFilterSelect}
      industryTrendsSelect={industryTrendsSelect}
      leadsLocationSelect={leadsLocationSelect}
      larger={larger}
    />
  )
}
