import React, { useContext, useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Drawer from '@mui/material/Drawer'
import Hidden from '@mui/material/Hidden'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import MainDrawerContent from './MainDrawerContent'
import { AppStore } from 'store'
import { AnalyticsStore } from 'store/analyticsStore'
import {
  ANALYTICS_APP,
  BUY_ONLINE_APP,
  LEADS_APP,
  MARKETING_APP,
} from '../../../constants'
import AppRoutes from 'routing/AppRoutes'
import { useLocation } from 'react-router-dom'
import ModeSwitch from 'components/common/custom-inputs/ModeSwitch'
import qs from 'qs'
import { capitalize } from 'utils/functions'
import { classes, Root } from './BaseLayout.styles'
interface BaseLayoutProps {
  appHeight?: number
  window?: () => Window
}

export default function BaseLayout(props: BaseLayoutProps): JSX.Element | null {
  const { window } = props

  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('md'))
  const { appState } = useContext(AppStore) // global store
  const { analyticsState } = useContext(AnalyticsStore)
  const { selectedClient, selectedApp } = appState
  const [selectedTabs, setSelectedTabs] = useState(
    analyticsState?.selectedTabs || []
  )
  const [mobileOpen, setMobileOpen] = useState(false)

  const location = useLocation()
  // This useEffect makes sure that if user clicks on go back button in browser we still have correct breadcrumbs
  useEffect(() => {
    const routes = location.pathname.split('/')
    // Get search params as object
    const params: Record<string, unknown> = qs.parse(
      location.search.replace('?', '')
    )
    const module = capitalize(routes[1], '-')

    const page = routes[routes.length - 1] // get current page (Last item in route path)
    const parts = page.split('_')
    const reducer = (accum: string, item: string) =>
      accum + ' ' + capitalize(item, '-')
    const tabName = parts.reduce(reducer, '')
    let subModule = ''
    if (analyticsState?.selectedTabs?.includes('SalesCenter')) {
      subModule = 'Sales Center'
    }

    let tabs = [module, subModule, tabName]

    // Only render plan name (Additional tabs after / Plan ) if'Plan' is in tabs, and planId is in search param
    const planPathIndex = analyticsState?.selectedTabs?.indexOf('Plan')
    const planId = params?.planId as number
    if (
      planPathIndex !== -1 &&
      analyticsState?.selectedTabs?.length > planPathIndex + 1 &&
      planId
    ) {
      tabs = tabs.concat(analyticsState?.selectedTabs.slice(planPathIndex + 1))
    }
    const tabsString = tabs.filter((t) => t).join(' / ')
    setSelectedTabs([tabsString])
  }, [location, analyticsState?.selectedTabs])

  const handleDrawerToggle = (): void => {
    setMobileOpen(!mobileOpen)
  }
  const container =
    window !== undefined ? (): Element => window().document.body : undefined

  if (
    selectedApp !== ANALYTICS_APP &&
    selectedApp !== LEADS_APP &&
    selectedApp !== MARKETING_APP &&
    selectedApp !== BUY_ONLINE_APP
  ) {
    return null // this layout is not available for other mini apps
  }

  return (
    <Root className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Breadcrumbs
            aria-label="breadcrumb"
            classes={{ separator: classes.separator }}
          >
            {!sm && (
              <Typography className={classes.typography}>
                {selectedClient?.name}
              </Typography>
            )}
            {selectedTabs.map((tab) => (
              <Typography className={classes.typography} key={tab}>
                {tab}
              </Typography>
            ))}
          </Breadcrumbs>
          <div className={classes.right}>
            <ModeSwitch />
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="menu items">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <MainDrawerContent handleDrawerToggle={handleDrawerToggle} />
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <MainDrawerContent />
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <AppRoutes />
      </main>
    </Root>
  )
}
