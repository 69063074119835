import { styled } from '@mui/material/styles'

const PREFIX = 'MenuDialog'

export const classes = {
  menuDialogueButton: `${PREFIX}-menuDialogueButton`,
  closeButton: `${PREFIX}-closeButton`,
}

export const Root = styled('div')(({ theme }) => ({
  [`& .${classes.menuDialogueButton}`]: {
    display: 'none',
    marginTop: 8,
    width: '100%',
    height: 'inherit',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      marginBottom: '8px',
    },
  },
}))

export const RootContent = styled('div')(({ theme }) => ({
  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))
