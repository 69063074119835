import React, { useContext } from 'react'
import DateFnsAdapter from '@date-io/date-fns'

import SessionsSummary from 'components/analytics/sessions/SessionsSummary'
import SessionsSummaryTrends from 'components/analytics/sessions/SessionsSummaryTrends'
import PopularFloorplans from 'components/analytics/popular-floorplans/PopularFloorplans'
import TrussPopularMaterialColors from 'components/analytics/popular-colors/TrussPopularMaterialColors'
import EngagementRate from 'components/analytics/sessions/EngagementRate'
import PopularCommunities, {
  POPULAR_COMMUNITIES_DISPLAY_LIST,
  POPULAR_COMMUNITIES_DISPLAY_MAP,
} from 'components/analytics/popular-communities/PopularCommunities'
import FilterBar from 'components/common/filter-bar/FilterBar'
import {
  BaseFilter,
  SessionFilter,
  CommonQueryFilter,
  PopularityMetric,
} from '../../graphql/gen-types'

import { AppStore } from 'store'
import Spinner from 'components/common/misc/Spinner'
import TrussPopularLots from 'components/analytics/popular-lots/TrussPopularLots'
import TrussPopularColors from 'components/analytics/popular-colors/TrussPopularColors'
import { AnalyticsStore } from 'store/analyticsStore'
import { useIsAuthorized } from 'utils/hooks'
import InfiniteScroll from 'components/common/layout/InfiniteScroll'
import ElevationSpecifications from 'components/analytics/popular-elevations/ElevationSpecifications'
import { PopularElevationsWrapper } from 'components/analytics/popular-elevations/PopularElevations'
import { SubscriptionLevelInvalidNotif } from 'components/utils/SubscriptionLevelInvalidNotif'
import RecentFavorites from 'components/analytics/recent-favorites/RecentFavorites'
import InteriorSelections from 'components/analytics/interior-selections/InteriorSelections'
import PageReferralsTable from 'components/analytics/page-referrals/PageReferralsTable'
import { classes, StyledGrid } from './Overview.styles'
const dateFns = new DateFnsAdapter()

function Overview(): JSX.Element {
  const { appState } = useContext(AppStore)
  const { analyticsState } = useContext(AnalyticsStore)
  const { selectedClient } = appState
  const { startDate, endDate } = analyticsState
  const selectedClientName = selectedClient ? selectedClient.altName : ''
  const directoryName = selectedClient?.directoryName
  const [isAuthorized, evaluatingAuthorization] = useIsAuthorized(
    'page:overview'
  )

  if (selectedClientName === '') {
    throw Error('There is no selected client name')
  }

  const startTime = dateFns.format(startDate, 'MM-dd-yyyy-HH:mm:ss')
  const endTime = dateFns.format(endDate, 'MM-dd-yyyy-HH:mm:ss')

  const baseInfo: BaseFilter = {
    clientName: selectedClientName,
    dateTimeRange: {
      start: startTime,
      end: endTime,
    },
  }

  const baseCommonFilter: CommonQueryFilter = {
    clientName: selectedClientName,
    startTime,
    endTime,
    limit: 5,
    metric: PopularityMetric.Frequency,
  }
  const baseCommonPopularityFilter: CommonQueryFilter = {
    ...baseCommonFilter,
    metric: PopularityMetric.Popularity,
  }

  // Unchanged filters (for now)
  const sessionFilter: SessionFilter = {
    baseInfo: baseInfo,
    includeDurationZero: false,
  }
  const specsFilter: CommonQueryFilter = {
    ...baseCommonFilter,
    limit: 100,
  }

  const chartContainerHeight = 600
  const GRID_SPACING = 2

  // each section is rendered when scrolled into view
  // this array contains the component to be rendered for each section
  const sectionComponents = [
    <>
      <StyledGrid
        container
        className={classes.componentContainer}
        spacing={GRID_SPACING}
      >
        <StyledGrid item xs={12}>
          <SessionsSummary filter={sessionFilter} />
        </StyledGrid>
      </StyledGrid>
      <StyledGrid
        container
        className={classes.componentContainer}
        spacing={GRID_SPACING}
      >
        <StyledGrid item xs={12} lg={10}>
          <SessionsSummaryTrends filter={sessionFilter} />
        </StyledGrid>
        <StyledGrid item xs={12} md={12} lg={10}>
          <PageReferralsTable filter={baseCommonFilter} />
        </StyledGrid>
        <StyledGrid item xs={12} md={12} lg={6}>
          <EngagementRate filter={sessionFilter} />
        </StyledGrid>
        <StyledGrid item xs={12} md={12} lg={6}>
          <PopularCommunities
            filter={baseCommonPopularityFilter}
            mode={POPULAR_COMMUNITIES_DISPLAY_LIST}
          />
        </StyledGrid>
      </StyledGrid>
    </>, // SECTION TWO
    <React.Fragment>
      <StyledGrid
        container
        className={classes.componentContainer}
        spacing={GRID_SPACING}
      >
        <StyledGrid item xs={12} md={12} lg={12}>
          <PopularCommunities
            filter={baseCommonPopularityFilter}
            mode={POPULAR_COMMUNITIES_DISPLAY_MAP}
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={12} lg={12}>
          <RecentFavorites filter={baseCommonPopularityFilter} />
        </StyledGrid>
      </StyledGrid>
    </React.Fragment>, // SECTION TWO
    <React.Fragment>
      <StyledGrid
        container
        className={classes.componentContainer}
        spacing={GRID_SPACING}
      >
        <StyledGrid item xs={12} md={9} lg={6}>
          <TrussPopularLots
            filter={baseCommonPopularityFilter}
            client={selectedClient}
            containerHeight={chartContainerHeight}
          />
        </StyledGrid>
        <StyledGrid item xs={12}>
          <PopularElevationsWrapper
            directoryName={directoryName || ''}
            filter={baseCommonFilter}
          />
        </StyledGrid>
      </StyledGrid>
    </React.Fragment>, // SECTION THREE
    <React.Fragment>
      <StyledGrid
        container
        className={classes.componentContainer}
        spacing={GRID_SPACING}
      >
        <StyledGrid item xs={12} md={9} lg={6}>
          <ElevationSpecifications filter={specsFilter} />
        </StyledGrid>
        <StyledGrid item xs={12} md={9} lg={6}>
          <PopularFloorplans filter={baseCommonPopularityFilter} />
        </StyledGrid>
        <StyledGrid item xs={12} md={12} lg={12}>
          <TrussPopularMaterialColors
            filter={{ ...baseCommonPopularityFilter, limit: 500 }} // Many different categories, limit of 500 to show ~5 from each if possible.
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={12} lg={12}>
          <InteriorSelections
            filter={{ ...baseCommonPopularityFilter, limit: 100 }} // Client may have many interiors, limit of 100 to show a range across each interior.
          />
        </StyledGrid>
      </StyledGrid>
    </React.Fragment>, // SECTION FOUR
    <React.Fragment>
      <StyledGrid
        container
        className={classes.componentContainer}
        spacing={GRID_SPACING}
      >
        <StyledGrid item xs={12} md={9} lg={9}>
          <TrussPopularColors
            filter={baseCommonPopularityFilter}
            client={selectedClient}
            containerHeight={chartContainerHeight}
          />
        </StyledGrid>
      </StyledGrid>
    </React.Fragment>, // SECTION FIVE
  ]

  if (evaluatingAuthorization) {
    return <Spinner message={'Loading...'} />
  }
  if (!isAuthorized) {
    return <div>Not Authorized!</div>
  }
  return (
    <React.Fragment>
      <SubscriptionLevelInvalidNotif />
      <StyledGrid container>
        <FilterBar />
      </StyledGrid>
      <StyledGrid container>
        <InfiniteScroll
          sectionComponents={sectionComponents}
          rootless={false}
        />
      </StyledGrid>
    </React.Fragment>
  )
}

export default Overview
