import React from 'react'
import { InteractiveFloorplan } from '@anewgo/interactive-floorplan-ng'
import { API_KEY } from '../constants'
import { Client, Community, Plan } from 'graphql/gen-types'

type FloorplanImageProps = {
  client: Client
  width: number | string
  height: number | string
  community: Community
  plan: Plan
  story: any
  elevationId: number
  fpOptSelections: any
  interactive: boolean
  mirrored: boolean
}

const FloorplanImage = ({
  client,
  width,
  height,
  community,
  plan,
  story,
  elevationId,
  fpOptSelections,
  interactive,
  mirrored,
}: FloorplanImageProps): JSX.Element | null => {
  return story.floorplans.length ? (
    <InteractiveFloorplan
      width={width}
      height={height}
      interactive={interactive}
      client={client}
      community={community}
      communityName={community.name || ''}
      plan={plan}
      floorplans={story.floorplans}
      fnum={story.fnum}
      elevation={{ id: elevationId }}
      fpGroups={story.fpGroups}
      fpOptSelections={fpOptSelections || {}}
      engineUrl={`${process.env.REACT_APP_HEMI_ENGINE}/api`}
      API_KEY={API_KEY}
      mirrored={mirrored}
    />
  ) : null
}

export default FloorplanImage
